import { Col, Form, Input, Row, Space, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import Modal from '../../../main/components/Modal';
import ModalContainer from '../../components/ModalContainer';
import ModalHeader from '../../components/ModalHeader';
import DropzoneWithState from '../../components/DropzoneWithState';
import { useFetch } from '../../../main/utils/makeRequest';
import * as Route from '../../support/serverRoutes';
import ModalDeleteConfirmation from '../../components/ModalDeleteConfirmation';
import { CellFullName } from '../../components/UserList';
import SelectUser from '../Selects/SelectUser';
import DateRangeComponent from './DateRangeComponent';
import { Divider } from '../../components/Divider';

const ModalUpdateEstablishment = ({ establishmentId, locales, onClose }) => {
  if (!establishmentId) return <></>;
  const [initialValues, setInitialValues] = useState(null);
  const [
    visibilityModalDeleteConfirmation,
    setVisibilityModalDeleteConfirmation,
  ] = useState(false);
  const [adminOptions, setAdminOptions] = useState([]);

  const {
    admin: {
      adminOptions: { collection: adminList, loading: adminLoading },
      admins: { collection: admins },
    },
    establishment: { data: establishmentData, uploading },
  } = useSelector((state) => state);

  const {
    establishment: { updateEstablishment, updateEstablishmentLogo },
    establishments: { deactivateEstablishment, reload },
    admin: { fetchAdmins, fetchEstablishmentAdmins, addAdmin, removeAdmin },
  } = useDispatch();

  const [data, loading] = useFetch({
    url: Route.establishment(establishmentId),
  });

  const [form] = Form.useForm();

  const handleOpenModalDeleteConfirmation = () =>
    setVisibilityModalDeleteConfirmation(true);

  const handleCloseModalDeleteConfirmation = () =>
    setVisibilityModalDeleteConfirmation(false);

  useEffect(() => {
    fetchAdmins({ establishmentId });
    fetchEstablishmentAdmins({ establishmentId });
  }, []);

  useEffect(() => {
    if (adminList) {
      setAdminOptions(adminList);
    }
  }, [adminList]);

  const handleSelect = (userId) => {
    addAdmin({ establishmentId, userId });
  };

  const handleDelete = (userId) => {
    removeAdmin({ establishmentId, userId });
  };

  const handleLogoChange = (file) => {
    updateEstablishmentLogo({
      id: establishmentId,
      logo: file,
    });
  };

  const handleFinish = (values) => {
    updateEstablishment({
      id: establishmentId,
      values,
      adminIds: admins.map((a) => a.id),
      after: [reload, onClose],
    });
  };

  const handleDeactivateEstablishment = () => {
    deactivateEstablishment({ id: establishmentId });
    handleCloseModalDeleteConfirmation();
    onClose();
  };

  useEffect(() => {
    if (data) {
      setInitialValues({
        ...data,
        licenceLength: data.licenceLength ? Number(data.licenceLength) : null,
        licenceStartDate: data.licenceStartDate
          ? moment(data.licenceStartDate).format('YYYY-MM-DD')
          : null,
        expireAt: data.expireAt
          ? moment(data.expireAt).format('YYYY-MM-DD')
          : null,
      });
    }
  }, [data]);

  return (
    <>
      {establishmentId && initialValues && !loading && (
        <Modal onBlackscreenClick={onClose} className="sm">
          <ModalContainer>
            <ModalHeader title="Update Establishment" onClose={onClose} />
            <Form
              layout="vertical"
              onFinish={handleFinish}
              initialValues={initialValues}
              form={form}
            >
              <div className="mt-20">
                <Row gutter={40}>
                  <Col>
                    <Form.Item
                      label="Name"
                      className="admin-input-label"
                      name="name"
                    >
                      <Input
                        placeholder="Add Name of Establishment"
                        className="admin-input"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Subdomain"
                      className="admin-input-label"
                      name="subdomain"
                    >
                      <Input
                        placeholder="Add Establishment’s Subdomain"
                        className="admin-input"
                      />
                    </Form.Item>
                    <Row gutter={10}>
                      <DateRangeComponent
                        form={form}
                        isUpdate
                        initialValues={initialValues}
                      />
                    </Row>

                    <SelectUser
                      avatarVisible
                      users={adminOptions}
                      loading={uploading || adminLoading}
                      columns={[
                        {
                          dataIndex: 'email',
                          render: (row) => {
                            return <CellFullName user={row} />;
                          },
                        },
                      ]}
                      labelOfSelect="Invite Admins To Join Your Establishment"
                      placeholderOfSelect="Choose admin by email"
                      labelOfList="Admins"
                      selectedIds={admins.map((admin) => admin.id)}
                      onSelect={handleSelect}
                      onDelete={handleDelete}
                    />
                  </Col>
                  <Col>
                    <Form.Item
                      label="Logo"
                      className="admin-input-label"
                      name="file"
                    >
                      <div className="dropzone-size">
                        <DropzoneWithState
                          onChange={handleLogoChange}
                          locales={locales}
                          initUrl={
                            establishmentData
                              ? establishmentData?.logo?.url
                              : initialValues?.logo?.url
                          }
                        />
                      </div>
                    </Form.Item>
                    <Form.Item
                      label="Total Licence"
                      className="admin-input-label"
                      name="maxLicensesCount"
                    >
                      <Input
                        type="number"
                        min={1}
                        placeholder="Days"
                        className="admin-input"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Licence Length"
                      className="admin-input-label"
                      name="licenceLength"
                    >
                      <Input
                        type="number"
                        min={1}
                        placeholder="Days"
                        className="admin-input"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="mt-20">
                <Divider />
              </div>
              <div className="flex space-between mt-20">
                <Space>
                  <Button
                    disabled={data?.studentsCount > 0}
                    type="text"
                    onClick={handleOpenModalDeleteConfirmation}
                    danger
                  >
                    Delete
                  </Button>
                </Space>
                <Space>
                  <Button onClick={onClose} disabled={uploading}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit" loading={uploading}>
                    Save
                  </Button>
                </Space>
              </div>
            </Form>
          </ModalContainer>
        </Modal>
      )}
      <ModalDeleteConfirmation
        visible={visibilityModalDeleteConfirmation}
        confirmationTitle="Are you sure to delete this establishment?"
        onClose={handleCloseModalDeleteConfirmation}
        onDelete={handleDeactivateEstablishment}
      />
    </>
  );
};

ModalUpdateEstablishment.propTypes = {
  onClose: PropTypes.func.isRequired,
  establishmentId: PropTypes.number,
};
export default ModalUpdateEstablishment;
