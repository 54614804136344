import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Dropzone from '../Dropzone';
import { renderImage } from '../../utils/helpers/renderImage';

export default function ImageDrop({
  editMode = false,
  dropzoneMode = false,
  disabled = false,
  locales,
  background,
  backgroundImage,
  templateName,
  aspectRatio,
  firstSvgColor,
  secondSvgColor,
  titleStyle,
  buttonStyle,
  lightButtonStyle,
  sliderColor,
  backgroundModal,
  titleColor,
  buttonSecondColor,
  buttonFirstColor,
  backgroundSecondButton,
  titleImageColor,
  isTemplates = false,
  isColourfulfunTemplate = false,
  card: { id, image, imageCoords },
}) {
  const {
    customerSegment: {
      updateCustomerSegmentImage,
      updateCustomerSegmentImageCoords,
    },
  } = useDispatch();

  const { uploadingImage } = useSelector((state) => state.customerSegment);

  const onImageDrop = ([file], openCropperHandler) => {
    updateCustomerSegmentImage({
      id,
      name: 'image',
      blob: file,
      success: () => {
        openCropperHandler();
      },
    });
  };

  const onImageCropperSave = (croppedAreaPixels, innerCropData, zoom) => {
    updateCustomerSegmentImageCoords({
      id,
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: imageCoords,
    });
  };
  return editMode || dropzoneMode ? (
    <div className="image-hover">
      <Dropzone
        className="image-uploud"
        onDrop={onImageDrop}
        onCropperSave={onImageCropperSave}
        locales={locales}
        image={image}
        coords={imageCoords}
        uploading={uploadingImage === id}
        aspectRatio={aspectRatio}
        disabled={disabled}
        background={background}
        cropper
        firstSvgColor={firstSvgColor}
        secondSvgColor={secondSvgColor}
        titleStyle={titleStyle}
        buttonStyle={buttonStyle}
        lightButtonStyle={lightButtonStyle}
        sliderColor={sliderColor}
        backgroundModal={backgroundModal}
        titleColor={titleColor}
        buttonSecondColor={buttonSecondColor}
        buttonFirstColor={buttonFirstColor}
        backgroundSecondButton={backgroundSecondButton}
        isTemplates={isTemplates}
        isColourfulfunTemplate={isColourfulfunTemplate}
      />
    </div>
  ) : (
    renderImage(
      templateName,
      image,
      backgroundImage,
      locales.portfolio.customerSegment.sectionName,
      aspectRatio,
      titleImageColor,
    )
  );
}
