import React from 'react';

export const renderSegmentList = (title, titleColor) => {
  return (
    <div
      className="bmc-page-card-segmentList custom-font-body"
      style={{ color: titleColor }}
    >
      {title}
    </div>
  );
};
