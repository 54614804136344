import React, { useContext, useEffect, useRef } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import CommentItemComponent from './comment-item-component';
import { CommentContext } from '../context';
import isElementVisible from '../../../utils/isElementVisible';

export default function CommentItemReplyContainer({
  replyCommentId,
  replyComment,
  members,
  currentUserId,
  currentMember,
  onDelete,
  onEdit,
}) {
  const childrenEl = useRef(null);

  const {
    commentState: { scrollStopAt, parentElement, savedActiveAt, activeAt },
  } = useContext(CommentContext);

  const {
    chatGroup: { updateChatGroupMemberActiveAt },
  } = useDispatch();

  const handleUpdateLastActivity = () => {
    if (
      currentMember &&
      (currentMember.activeAt === null ||
        moment(currentMember.activeAt).isBefore(moment(replyComment.createdAt)))
    )
      updateChatGroupMemberActiveAt({
        chatGroupCommentId: replyComment.id,
        chatGroupId: replyComment.chatGroupId,
      });
  };

  useEffect(() => {
    if (parentElement && isElementVisible(parentElement, childrenEl.current)) {
      handleUpdateLastActivity();
    }
  }, [scrollStopAt]);

  const checkingActiveAt = savedActiveAt || activeAt;
  const isSenderMe = replyComment.senderId === currentMember?.userId;
  const wasLastActivityInPast =
    checkingActiveAt !== null &&
    moment(checkingActiveAt).isBefore(moment(replyComment.createdAt));
  const shouldShowAsNewMessage = !isSenderMe && wasLastActivityInPast;

  return (
    <div
      className={classNames('comment-item-reply-container', {
        'status-new': shouldShowAsNewMessage,
      })}
      ref={childrenEl}
    >
      <CommentItemComponent
        key={replyCommentId}
        comment={replyComment}
        members={members}
        currentUserId={currentUserId}
        onEdit={onEdit}
        onDelete={onDelete}
        hideReply
      />
    </div>
  );
}
