import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Page404 from '../../main/containers/Page404';
import ScrollToTop from '../../main/components/ScrollToTop';
import EducatorDashboard from '../containers/Dashboards/EducatorDashboard';
import SuperAdminDashboard from '../containers/Dashboards/SuperAdminDashboard';
import EstablishmentDashboard from '../containers/Dashboards/EstablishmentDashboard';
import ClassroomDashboard from '../containers/Dashboards/ClassroomDashboard';
import BmcDashboard from '../containers/Dashboards/BmcDashboard';

export default function router({ locales }) {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/admin">
          <SuperAdminDashboard locales={locales} />
        </Route>
        <Route exact path="/admin/establishment/:establishmentId">
          <EstablishmentDashboard locales={locales} />
        </Route>
        <Route
          exact
          path="/admin/establishment/:establishmentId/users/:adminId"
        >
          <EstablishmentDashboard locales={locales} />
        </Route>
        <Route exact path="/admin/lecturer/:userId">
          <EducatorDashboard locales={locales} />
        </Route>
        <Route exact path="/admin/classroom/:classroomId">
          <ClassroomDashboard locales={locales} />
        </Route>
        <Route exact path="/admin/bmc/:bmcId">
          <BmcDashboard locales={locales} />
        </Route>
        <Route path="*" component={Page404} />
      </Switch>
    </Router>
  );
}
