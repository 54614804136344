import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../components/Header';
import Tabs from '../../components/Tabs';
import Textarea from '../../components/Textarea';
import TabsCut from '../../components/TabsCut';
import Title from '../../components/Title';
import Text from '../../components/Text';
import Line from '../../components/Line';
import List from '../../components/List';
import Actions from '../../components/Actions';
import TabsList from '../../components/TabsList';
import TabsListItem from '../../components/TabsListItem';
import SvgIcon from '../../components/SvgIcon';
import MobileMenuHeader from '../../components/MobileMenuHeader';

const fields = ['product', 'reason'];

const ProductAndFeatures = ({
  nextClickHandler,
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
  locales,
  locales: {
    common,
    tipCustomerSegment,
    caseStudies,
    valueProposition: {
      name,
      productAndFeatures: { header, title, text, list, tabs },
    },
  },
}) => {
  const [opened, setOpened] = useState(false);
  const icons = ['crossedArrows', 'star'];
  const iconsComponents = icons.map((e) => <SvgIcon name={e} />);
  const {
    valueProposition: { updateAttribute },
  } = useDispatch();
  const { valueProposition } = useSelector((state) => state);

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon={<SvgIcon name="valueProposition" />}
        locales={locales}
        componentIndex={componentIndex}
        tipLocales={tipCustomerSegment}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="VPCanvas" title={name} color="green" />
        }
      />

      <Text align="center">{[text[0]]}</Text>

      <Title text={title[0]} />

      <List items={list} type="horizontal" icons={icons} />

      <Line />

      <h2 className="bmc-page-title">
        <span className="bmc-page-title--green">{title[1]}</span> {title[2]}
      </h2>

      <Text align="center">{[text[1]]}</Text>

      <Tabs
        tabs={[common.yourStrategy, common.caseStudy]}
        withBlur
        className="margin-top-60"
      >
        {[
          <TabsList key="strategy">
            {fields.map((field, index) => (
              <TabsListItem
                key={index}
                title={tabs[field]}
                subtitle={tabs[`${field}Sub`]}
                icon={iconsComponents[index]}
                text={
                  // eslint-disable-next-line
                  <Textarea
                    rows={4}
                    delay={700}
                    placeholder={tabs[`${field}Placeholder`]}
                    value={valueProposition[field]}
                    status={valueProposition[`${field}Status`]}
                    onChange={(event) =>
                      updateAttribute({
                        name: field,
                        value: event.target.value,
                      })
                    }
                  />
                }
              />
            ))}
          </TabsList>,

          <Tabs key="tabs" tabs={caseStudies.map((e) => e.name)} black inner>
            {caseStudies.map((item, index) => (
              <TabsList key={index} preview>
                <TabsCut
                  setOpened={setOpened}
                  opened={opened}
                  caseStudy={item}
                />

                {fields.map((field, i) => (
                  <TabsListItem
                    key={i}
                    icon={iconsComponents[i]}
                    title={tabs[field]}
                    text={item[field]}
                  />
                ))}
              </TabsList>
            ))}
          </Tabs>,
        ]}
      </Tabs>

      <Actions nextClickHandler={nextClickHandler} buttonName={common.next} />
    </div>
  );
};

export default ProductAndFeatures;
