import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { truncate } from 'lodash';
import { useMediaQuery } from '@material-ui/core';

import CustomerSegmentCard from '../../main/containers/customerSegment/CardColourfulArch';
import ImageDrop from '../../main/components/ImageDrop/ImageDropCS';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import {
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  IMAGE_ASPECT_RATIO_CS_ARCH,
  IMAGE_ASPECT_RATIO_ONE,
} from '../../main/utils/const';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';
import { getImageAspectRatio } from '../../main/utils/helpers/getImageAspectRatio';

const CustomerSegment = ({
  locales: {
    common,
    portfolioWeb,
    customerSegment: {
      intro: { card },
    },
  },
  locales,
  backgroundSection,
  backgroundImage,
  isFourthColorPaletteNumber = false,
}) => {
  const [activeCardId, setActiveCardId] = useState(null);
  const { customerSegment } = useSelector((state) => state.portfolio);

  const { collection } = useSelector((state) => state.customerSegment);

  const activeCardData = useMemo(() => {
    if (collection.length && !activeCardId) {
      setActiveCardId(collection[0].id);
      return collection[0];
    }
    return collection.find((i) => i.id === activeCardId);
  }, [collection, activeCardId]);

  const isSmallDesktop = useMediaQuery('(max-width: 1024px)');

  const textColorDependsOnBackground =
    isFourthColorPaletteNumber &&
    getColorDependsOnBackground(backgroundSection);

  const singleCard = activeCardData && (
    <CustomerSegmentCard
      locales={common}
      isPreview
      card={{
        ...card,
        ...activeCardData,
        subtitle: activeCardData.name,
        title: null,
      }}
      textColor={textColorDependsOnBackground}
      subtitleColor={textColorDependsOnBackground}
      segmentListTitleStyle={textColorDependsOnBackground}
    />
  );

  const singleImage = activeCardData && (
    <ImageDrop
      locales={locales}
      backgroundImage={backgroundImage}
      templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
      aspectRatio={getImageAspectRatio(
        isSmallDesktop,
        IMAGE_ASPECT_RATIO_ONE,
        IMAGE_ASPECT_RATIO_CS_ARCH,
      )}
      titleImageColor={
        isFourthColorPaletteNumber &&
        getColorDependsOnBackground(backgroundImage)
      }
      card={{
        ...card,
        ...activeCardData,
        subtitle: activeCardData.name,
        title: null,
      }}
    />
  );

  return (
    <div
      className="customer-segment-architectural-web section-web"
      style={{ backgroundColor: backgroundSection }}
    >
      <div className="left">
        <div className="image-uploud"> {singleImage}</div>
      </div>
      <div className="right">
        <SectionHeaderWeb
          templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
          title={portfolioWeb.customerSegment}
          description={customerSegment.description}
          portfolioWeb={portfolioWeb}
          style={{ color: textColorDependsOnBackground }}
          descriptionColor={textColorDependsOnBackground}
        />
        <div className="cards-section">
          <div className="tabs">
            {collection.map(({ id, name }) => (
              <div
                key={id}
                onClick={() => setActiveCardId(id)}
                className={classnames(
                  'item',
                  {
                    active: activeCardId === id,
                  },
                  'custom-font-body',
                )}
                style={{ color: textColorDependsOnBackground }}
              >
                {truncate(name, { length: 45 })}
              </div>
            ))}
          </div>
          <div className="main">{singleCard}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSegment;
