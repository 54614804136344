import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function Title({ align, className, text }) {
  return (
    <h2
      className={classNames(`bmc-page-title`, className, {
        'bmc-page-title--left': align === 'left',
      })}
    >
      {text}
    </h2>
  );
}

Title.propTypes = {
  align: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};
