import React, { useMemo } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import ChatGroupItemComponent from '../chat-group/chat-group-item-component';

export default function CommentListHeaderContainer() {
  const { chatGroup } = useSelector((selector) => selector);
  const { chatGroupComments, chatGroupMembers } = chatGroup.selectedChatGroup;

  const { unreadCommentCount, mentionCommentCount, lastCommentCreatedAt } =
    useMemo(() => {
      if (!chatGroup.currentUser?.id && chatGroupComments.length === 0)
        return {
          unreadCommentCount: 0,
          mentionCommentCount: 0,
          lastCommentCreatedAt: null,
        };

      const result = {
        unreadCommentCount: 0,
        mentionCommentCount: 0,
        lastCommentCreatedAt: chatGroupComments[0]?.createdAt,
      };

      const member = chatGroupMembers.find(
        (item) => item.userId === chatGroup.currentUser?.id,
      );

      const activeAt = moment(member?.activeAt || 0);

      chatGroupComments.forEach((comment) => {
        const createdAt = moment(comment.createdAt);

        if (moment(result.lastCommentCreatedAt).isBefore(createdAt))
          result.lastCommentCreatedAt = comment.createdAt;

        if (
          comment.senderId !== member?.userId &&
          (activeAt.isBefore(createdAt) || member?.activeAt === null)
        )
          result.unreadCommentCount += 1;

        if (
          comment.senderId !== member?.userId &&
          (activeAt.isBefore(createdAt) || member?.activeAt === null) &&
          comment.mentions.includes(chatGroup.currentUser?.id)
        )
          result.mentionCommentCount += 1;
      });

      return result;
    }, [chatGroup.currentUser, chatGroup.selectedChatGroup, chatGroupMembers]);

  return (
    <div className="comment-list-header-container">
      <ChatGroupItemComponent
        chatGroup={chatGroup.selectedChatGroup}
        unreadCommentCount={unreadCommentCount}
        mentionCommentCount={mentionCommentCount}
        lastCommentCreatedAt={lastCommentCreatedAt}
      />
    </div>
  );
}
