import React from 'react';
import classNames from 'classnames';
import {
  CURRENT_TEMPLATE_NAME,
  COLOURFUL_TEMPLATE_NAME,
  BLOG_FRIENDLY_TEMPLATE_NAME,
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  TECH_MODERN_TEMPLATE_NAME,
  BOLD_TEMPLATE_NAME,
} from '../../utils/const';

export default function Card({
  className,
  card: { icon, title, subtitle, steps, step },
  wBlur = true,
  templateName = CURRENT_TEMPLATE_NAME,
  titleColor,
  textColor,
  circleColor,
  lineColor,
  background,
}) {
  const getItemTitle = (item, index) => {
    const itemTitle = {
      [CURRENT_TEMPLATE_NAME]: item.title || `${step} ${index + 1}`,
      [COLOURFUL_TEMPLATE_NAME]: item.title || `${step} ${index + 1}`,
      [BLOG_FRIENDLY_TEMPLATE_NAME]: item.title || `${step} ${index + 1}`,
      [ARCHITECTURAL_MINIMAL_TEMPLATE_NAME]: item.title || `${step}`,
      [TECH_MODERN_TEMPLATE_NAME]: item.title || `${step} 0${index + 1}`,
      [BOLD_TEMPLATE_NAME]: item.title || `${step} 0${index + 1}`,
    };
    return itemTitle[templateName];
  };

  const isCurrentTemplate = templateName === CURRENT_TEMPLATE_NAME;
  const isBlogFriendlyTemplate = templateName === BLOG_FRIENDLY_TEMPLATE_NAME;
  const isArchTemplate = templateName === ARCHITECTURAL_MINIMAL_TEMPLATE_NAME;

  return (
    <div
      className={classNames('bmc-page-keycard-wrapper', className)}
      style={{ backgroundColor: background }}
    >
      <div className="bmc-page-keycard" style={{ backgroundColor: background }}>
        {title && (
          <div className="bmc-page-keycard-header">
            <div className="bmc-page-keycard-header-icon">
              <img src={icon} alt="" />
            </div>
            <div
              className="bmc-page-keycard-header-title custom-font-title"
              style={{ color: titleColor }}
            >
              {title}
            </div>
          </div>
        )}

        {subtitle && (
          <div
            className="bmc-page-keycard-title custom-font-title"
            style={{ color: titleColor }}
          >
            {subtitle}
          </div>
        )}

        <div className="bmc-page-keycard-steps">
          {steps.map((item, index) => (
            <div key={index} className="bmc-page-keycard-step">
              <div
                className="circle"
                style={{
                  backgroundColor:
                    (isBlogFriendlyTemplate || isCurrentTemplate) &&
                    circleColor,
                  borderColor: circleColor,
                }}
              >
                {isArchTemplate && index + 1}
              </div>
              {!isArchTemplate && index !== steps.length - 1 && (
                <div
                  className="line-key"
                  style={{ backgroundColor: lineColor }}
                />
              )}
              {isArchTemplate && (
                <div
                  className="line-key"
                  style={{ backgroundColor: lineColor }}
                />
              )}
              <div
                className="bmc-page-keycard-step-title custom-font-title"
                style={{ color: titleColor }}
              >
                {getItemTitle(item, index)}
              </div>
              <div
                className="bmc-page-keycard-step-text custom-font-body"
                style={{ color: textColor }}
              >
                {item.description}
              </div>
            </div>
          ))}
        </div>
      </div>
      {wBlur && <div className="bmc-page-keycard-blur" />}
    </div>
  );
}
