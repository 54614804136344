import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../components/Header';
import Title from '../../components/Title';
import Text from '../../components/Text';
import Line from '../../components/Line';
import List from '../../components/List';
import Actions from '../../components/Actions';
import Tabs from '../../components/Tabs';
import RemoveButton from '../../components/RemoveButton';
import { KEY_ACTIVITIES_MAX_COUNT } from '../../utils/const';
import Button from '../../components/Button';
import Textarea from '../../components/Textarea';
import TabsCut from '../../components/TabsCut';
import TabsList from '../../components/TabsList';
import TabsListItem from '../../components/TabsListItem';
import ConfirmationModal from '../../components/ConfirmationModal';
import SvgIcon from '../../components/SvgIcon';
import MobileMenuHeader from '../../components/MobileMenuHeader';

const fields = ['description'];
const icons = ['enter'];
const iconsComponents = icons.map((e) => <SvgIcon name={e} />);

export default function Steps(props) {
  const {
    nextClickHandler,
    menuItems,
    selectComponent,
    componentIndex,
    rules,
    backPath,
    locales,
    locales: {
      caseStudies,
      common,
      tipCustomerSegment,
      keyActivity: {
        name,
        steps: { header, title, text, list, tabs },
      },
    },
  } = props;
  const { collection } = useSelector((state) => state.keyActivity);
  const {
    keyActivity: { addKeyActivity, updateKeyActivity, removeKeyActivity },
  } = useDispatch();
  const [opened, setOpened] = useState(false);

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon="icon-key-activity"
        locales={locales}
        componentIndex={componentIndex}
        className="bmc-page-header--orange"
        tipLocales={tipCustomerSegment}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="KeyThingCanvas" title={name} color="orange" />
        }
      />

      <div className="bmc-page-text bmc-page-text--center">
        <p>
          {text[0]}
          <span className="bmc-page-text--bold"> {text[1]}</span>
        </p>
      </div>

      <Title text={title[0]} align="left" />

      <List items={list} icons={icons} />

      <Line />

      <h2 className="bmc-page-title">
        <span className="bmc-page-title--orange">{title[1]}</span> {title[2]}
      </h2>

      <Text align="center">{[text[2]]}</Text>

      <Tabs
        tabs={[common.yourStrategy, common.caseStudy]}
        withBlur
        className="margin-top-60 bmc-page-tabs--orange"
      >
        {[
          <TabsList key="strategy">
            {collection.map((item, index) => (
              <TabsListItem
                key={item.id}
                title={`${tabs.description} ${index + 1}`}
                subtitle={tabs.descriptionSub[index]}
                icon={iconsComponents[0]}
                text={
                  // eslint-disable-next-line
                  <Textarea
                    rows={1}
                    delay={700}
                    value={item.description}
                    onChange={(event) =>
                      updateKeyActivity({
                        index,
                        name: 'description',
                        value: event.target.value,
                        id: item.id,
                      })
                    }
                    placeholder={tabs.placeholder[index]}
                  />
                }
              >
                {collection.length > 1 && (
                  <ConfirmationModal
                    body="Delete this content. Are you sure?"
                    confirmText="Delete"
                    isBlockScroll
                    onConfirm={() => removeKeyActivity({ index, id: item.id })}
                  >
                    <RemoveButton
                      className="bmc-page-tabs-content-list-remove"
                      active={collection.length > 1}
                    />
                  </ConfirmationModal>
                )}
              </TabsListItem>
            ))}

            {collection.length < KEY_ACTIVITIES_MAX_COUNT && (
              <div className="bmc-page-tabs-content-actions margin-top-40">
                <Button
                  onClick={() => addKeyActivity()}
                  wide
                  icon="icon-Add"
                  title={tabs.addYourStep}
                  className="bmc-button--orange"
                />
              </div>
            )}
          </TabsList>,

          <Tabs
            key="tabs"
            tabs={caseStudies.map((e) => e.name)}
            className="bmc-page-tabs--black bmc-page-tabs--inner"
          >
            {caseStudies.map((item, index) => (
              <div key={index}>
                <TabsCut
                  setOpened={setOpened}
                  opened={opened}
                  caseStudy={item}
                />

                {item.keyActivities.map((strategy, i) => (
                  <TabsList key={i} formStyle preview>
                    {fields.map((field, ii) => (
                      <TabsListItem
                        key={ii}
                        title={`${tabs[field]} ${i + 1}`}
                        text={strategy[field]}
                        icon={iconsComponents[0]}
                      />
                    ))}
                  </TabsList>
                ))}
              </div>
            ))}
          </Tabs>,
        ]}
      </Tabs>

      <Actions nextClickHandler={nextClickHandler} buttonName={common.next} />
    </div>
  );
}
