import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { truncate } from 'lodash';
import useMediaQuery from 'react-use-media-query-hook';

import ImageDrop from '../../../components/ImageDrop/ImageDropChannels';
import Card from '../../channel/CardColourfulBold';
import {
  BOLD_TEMPLATE_NAME,
  BUTTON_LIGHT_MODAL_STYLE_BOLD,
  BUTTON_MODAL_STYLE_BOLD,
  COMMENT_CHANNELS,
  IMAGE_ASPECT_RATIO_CH_BOLD,
  IMAGE_ASPECT_RATIO_ONE,
  TITLE_MODAL_STYLE_BOLD,
} from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import SectionHeader from '../../../components/SectionHeader';
import { getImageAspectRatio } from '../../../utils/helpers/getImageAspectRatio';

const ChannelsBold = ({
  data: { description },
  locales,
  locales: {
    portfolio,
    channel: {
      intro: { card },
    },
  },
  titleColor,
  textColor,
  styleColor,
  titleTabsColor,
  backgroundSection,
  backgroundImage,
  sliderColor,
  backgroundModal,
  buttonSecondColor,
  buttonFirstColor,
  backgroundSecondButton,
  disabled = false,
}) => {
  const { collection } = useSelector((state) => state.channel);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isDesktop = useMediaQuery('(max-width: 1280px)');
  const [activeCardId, setActiveCardId] = useState(null);
  const {
    portfolio: { updateSectionField },
  } = useDispatch();

  const activeCardData = useMemo(() => {
    if (collection.length && !activeCardId) {
      setActiveCardId(collection[0].id);
      return collection[0];
    }
    return collection.find((i) => i.id === activeCardId);
  }, [collection, activeCardId]);

  const singleCard = activeCardData && (
    <Card
      titleColor={titleTabsColor}
      textColor={textColor}
      styleColor={styleColor}
      card={{
        ...card,
        ...activeCardData,
        title: null,
      }}
    />
  );

  const singleImage = activeCardData && (
    <ImageDrop
      locales={locales}
      disabled={disabled}
      editMode={!disabled}
      dropzoneMode
      backgroundImage={backgroundImage}
      aspectRatio={getImageAspectRatio(
        isDesktop,
        IMAGE_ASPECT_RATIO_ONE,
        IMAGE_ASPECT_RATIO_CH_BOLD,
      )}
      templateName={BOLD_TEMPLATE_NAME}
      titleStyle={TITLE_MODAL_STYLE_BOLD}
      buttonStyle={BUTTON_MODAL_STYLE_BOLD}
      lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_BOLD}
      sliderColor={sliderColor}
      backgroundModal={backgroundModal}
      titleColor={titleColor}
      buttonSecondColor={buttonSecondColor}
      buttonFirstColor={buttonFirstColor}
      backgroundSecondButton={backgroundSecondButton}
      isTemplates
      card={{
        ...card,
        ...activeCardData,
        title: null,
      }}
    />
  );

  return (
    <div
      className="channel-bold section relative"
      style={{ backgroundColor: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_CHANNELS} />
      {isMobile ? (
        <>
          <SectionHeader
            templateName={BOLD_TEMPLATE_NAME}
            title={portfolio.channels.sectionName}
            description={description}
            onChange={(e) =>
              updateSectionField({
                sectionName: 'channels',
                field: 'description',
                name: 'channelsText',
                value: e.target.value,
              })
            }
            portfolio={portfolio}
            style={{ color: titleColor }}
            descriptionColor={textColor}
            disabled={disabled}
          />

          <div className="cards-section">
            {collection.map((channel) => {
              return (
                <>
                  <ImageDrop
                    locales={locales}
                    disabled={disabled}
                    editMode={!disabled}
                    backgroundImage={backgroundImage}
                    aspectRatio={getImageAspectRatio(
                      isDesktop,
                      IMAGE_ASPECT_RATIO_ONE,
                      IMAGE_ASPECT_RATIO_CH_BOLD,
                    )}
                    templateName={BOLD_TEMPLATE_NAME}
                    titleStyle={TITLE_MODAL_STYLE_BOLD}
                    buttonStyle={BUTTON_MODAL_STYLE_BOLD}
                    lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_BOLD}
                    sliderColor={sliderColor}
                    backgroundModal={backgroundModal}
                    titleColor={titleColor}
                    buttonSecondColor={buttonSecondColor}
                    buttonFirstColor={buttonFirstColor}
                    backgroundSecondButton={backgroundSecondButton}
                    isTemplates
                    card={{
                      ...card,
                      ...channel,
                      title: null,
                    }}
                  />
                  <Card
                    locales={locales}
                    titleColor={titleTabsColor}
                    textColor={textColor}
                    styleColor={styleColor}
                    card={{
                      ...card,
                      ...channel,
                      title: null,
                    }}
                  />
                </>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <div className="left">{singleImage}</div>
          <div className="right">
            <SectionHeader
              templateName={BOLD_TEMPLATE_NAME}
              title={portfolio.channels.sectionName}
              description={description}
              onChange={(e) =>
                updateSectionField({
                  sectionName: 'channels',
                  field: 'description',
                  name: 'channelsText',
                  value: e.target.value,
                })
              }
              portfolio={portfolio}
              style={{ color: titleColor }}
              descriptionColor={textColor}
              disabled={disabled}
            />

            <div className="cards-section">
              <div className="tabs">
                {collection.map(({ id, name }) => (
                  <div
                    key={id}
                    onClick={() => setActiveCardId(id)}
                    className={classnames(
                      'item',
                      {
                        active: activeCardId === id,
                      },
                      'custom-font-body',
                    )}
                    style={
                      activeCardId === id
                        ? {
                            backgroundColor: styleColor,
                            color: backgroundSection,
                          }
                        : { color: styleColor, borderColor: styleColor }
                    }
                  >
                    {truncate(name, { length: 30 })}
                  </div>
                ))}
              </div>
              <div className="main">{singleCard}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ChannelsBold;
