import React, { useMemo } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import Textarea from '../../components/Textarea';
import {
  CUSTOMER_SEGMENT_TYPE_OPTIONS,
  CUSTOMER_SEGMENT_AGE_RANGE_MAX,
  CUSTOMER_SEGMENT_AGE_RANGE_MIN,
  CUSTOMER_SEGMENT_COMPANY_SIZE_MIN,
  CUSTOMER_SEGMENT_COMPANY_SIZE_MAX,
  CUSTOMER_SEGMENT_B2B,
  SELECT_STYLES_COLOURFUL,
} from '../../utils/const';
import { customerSegmentType, selectValue } from '../../utils/helpers';
import RangeWithInputCurrent from '../../components/RangeWithInputCurrent';

const getName = (type) => {
  return type === CUSTOMER_SEGMENT_B2B ? 'companySize' : 'ageRange';
};

export default function CardColourfulArch({
  editMode = false,
  locales,
  locales: { portfolio },
  card,
  textareaWrapperStyle,
  textareaStyle,
  rangeStyle,
  subtitleColor,
  segmentListTitleStyle,
  textColor,
  card: {
    id,
    subtitle,
    ageRange,
    companySize,
    ageRangeTitle,
    companySizeTitle,
    type,
    typeTitle,
  },
}) {
  const fields = ['description', 'goals', 'frustrates', 'problems'];

  const {
    customerSegment: { updateCustomerSegment },
  } = useDispatch();

  const [cardTitle, cardSizeValue, minValue, maxValue] = useMemo(() => {
    if (type === CUSTOMER_SEGMENT_B2B) {
      return [
        companySizeTitle,
        companySize,
        CUSTOMER_SEGMENT_COMPANY_SIZE_MIN,
        CUSTOMER_SEGMENT_COMPANY_SIZE_MAX,
      ];
    }
    return [
      ageRangeTitle,
      ageRange,
      CUSTOMER_SEGMENT_AGE_RANGE_MIN,
      CUSTOMER_SEGMENT_AGE_RANGE_MAX,
    ];
  }, [type, ageRange, companySize]);

  return (
    <div className="bmc-page-card-inner">
      <div className="bmc-page-card-row">
        {editMode ? (
          <div className={textareaWrapperStyle}>
            <Textarea
              delay={700}
              value={subtitle}
              className={classNames(
                'subtitle',
                textareaStyle,
                'custom-font-title',
              )}
              style={{ color: subtitleColor }}
              placeholder={portfolio.customerSegment.titlePlaceholder}
              onChange={(e) =>
                updateCustomerSegment({
                  name: 'name',
                  value: e.target.value,
                  id,
                })
              }
            />
          </div>
        ) : (
          <div
            className="bmc-page-card-big-title custom-font-title"
            style={{ color: subtitleColor }}
          >
            {subtitle}
          </div>
        )}
      </div>
      <div className="bmc-page-card-row rangeRow">
        {!!type && (
          <div className="bmc-page-card-inner">
            <div
              className="bmc-page-card-segmentList custom-font-body"
              style={{ color: segmentListTitleStyle }}
            >
              {cardTitle}
            </div>
            {editMode ? (
              <RangeWithInputCurrent
                locales={locales}
                enableRange={false}
                value={cardSizeValue}
                minValue={minValue}
                maxValue={maxValue}
                delay={700}
                className={classNames(
                  textareaStyle,
                  rangeStyle,
                  'range custom-font-body',
                )}
                style={{ color: textColor }}
                onChange={(value) =>
                  updateCustomerSegment({
                    name: getName(type),
                    value,
                    id,
                  })
                }
              />
            ) : (
              <div
                className="bmc-page-card-text custom-font-body"
                style={{ color: textColor }}
              >
                {cardSizeValue.min} - {cardSizeValue.max}
              </div>
            )}
          </div>
        )}

        <div className="bmc-page-card-inner select">
          <div
            className="bmc-page-card-segmentList custom-font-body"
            style={{ color: segmentListTitleStyle }}
          >
            {typeTitle}
          </div>
          {editMode ? (
            <div style={{ color: textColor }}>
              <Select
                options={CUSTOMER_SEGMENT_TYPE_OPTIONS}
                styles={SELECT_STYLES_COLOURFUL}
                className="custom-font-body"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  height: '32px',
                })}
                value={selectValue(type, customerSegmentType(type))}
                onChange={({ value }) =>
                  updateCustomerSegment({ id, name: 'type', value })
                }
              />
            </div>
          ) : (
            <div
              className="bmc-page-card-text custom-font-body"
              style={{ color: textColor }}
            >
              {customerSegmentType(type)}
            </div>
          )}
        </div>
      </div>

      {fields.map((field, index) => (
        <div className="bmc-page-card-row" key={index}>
          <div
            className="bmc-page-card-segmentList custom-font-body"
            style={{ color: segmentListTitleStyle }}
          >
            {card[`${field}Title`]}
          </div>
          {editMode ? (
            <div className={textareaWrapperStyle}>
              <Textarea
                value={card[field]}
                delay={700}
                className={classNames(textareaStyle, 'custom-font-body')}
                style={{ color: textColor }}
                placeholder={portfolio.page.informationPlaceholder}
                onChange={(e) =>
                  updateCustomerSegment({
                    id,
                    name: field,
                    value: e.target.value,
                  })
                }
              />
            </div>
          ) : (
            <div
              className="bmc-page-card-text custom-font-body"
              style={{ color: textColor }}
            >
              {card[field]}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
