import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Header from '../../components/Header';
import Text from '../../components/Text';
import Title from '../../components/Title';
import Line from '../../components/Line';
import List from '../../components/List';
import Tabs from '../../components/Tabs';
import Actions from '../../components/Actions';
import Checkbox from '../../components/Checkbox';
import Textarea from '../../components/Textarea';
import Button from '../../components/Button';
import { nameWithDefault } from '../../utils/helpers';
import RangeWithInput from '../../components/RangeWithInputs';
import {
  CUSTOMER_SEGMENT_MAX_COUNT,
  CUSTOMER_SEGMENT_B2C,
  CUSTOMER_SEGMENT_B2B,
  CUSTOMER_SEGMENT_AGE_RANGE_MAX,
  CUSTOMER_SEGMENT_AGE_RANGE_MIN,
  CUSTOMER_SEGMENT_COMPANY_SIZE_MIN,
  CUSTOMER_SEGMENT_COMPANY_SIZE_MAX,
} from '../../utils/const';
import TabsCut from '../../components/TabsCut';
import RemoveButton from '../../components/RemoveButton';
import TabsList from '../../components/TabsList';
import TabsListTitle from '../../components/TabsListTitle';
import TabsListItem from '../../components/TabsListItem';
import ConfirmationModal from '../../components/ConfirmationModal';
import SvgIcon from '../../components/SvgIcon';
import MobileMenuHeader from '../../components/MobileMenuHeader';

const fields = ['name', 'type', 'ageRange', 'companySize', 'description'];

export default function DefineYourCustomers({
  nextClickHandler,
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
  locales,
  locales: {
    common,
    tipCustomerSegment,
    caseStudies,
    customerSegment: {
      name,
      defineYourCustomers: { header, title, text, list, tabs },
    },
  },
}) {
  const [opened, setOpened] = useState(false);
  const icons = ['userSpeaking', 'userTooltip', 'userCard', 'text'];
  const iconsComponents = icons.map((e) => <SvgIcon name={e} />);
  const {
    customerSegment: { collection },
  } = useSelector((state) => state);
  const {
    customerSegment: {
      addCustomerSegment,
      updateCustomerSegment,
      removeCustomerSegment,
    },
  } = useDispatch();

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon="icon-customer-segment"
        locales={locales}
        componentIndex={componentIndex}
        tipLocales={tipCustomerSegment}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="CSCanvas" title={name} color="green" />
        }
      />

      <Text>{[text[0], text[1], text[2]]}</Text>

      <Title text={title[0]} align="left" />

      <List items={list} icons={icons} />

      <Line />

      <h2 className="bmc-page-title">
        <span className="bmc-page-title--green">{title[1]}</span> {title[2]}
      </h2>

      <Text align="center">{[text[3]]}</Text>

      <Tabs
        tabs={[common.yourStrategy, common.caseStudy]}
        withBlur
        className="margin-top-60"
      >
        {[
          <div key="strategy">
            {collection.map((customerSegment, index) => (
              <TabsList key={customerSegment.id} formStyle>
                <TabsListTitle>
                  {nameWithDefault(customerSegment.name, name, index + 1)}
                </TabsListTitle>

                {collection.length > 1 && (
                  <ConfirmationModal
                    body="Delete this content. Are you sure?"
                    confirmText="Delete"
                    isBlockScroll
                    onConfirm={() =>
                      removeCustomerSegment({ index, id: customerSegment.id })
                    }
                  >
                    <RemoveButton
                      className="bmc-page-tabs-content-list-remove"
                      active={collection.length > 1}
                    />
                  </ConfirmationModal>
                )}

                <TabsListItem
                  title={tabs.customerSegmentName}
                  icon={iconsComponents[0]}
                  text={
                    // eslint-disable-next-line
                    <Textarea
                      rows={1}
                      delay={700}
                      placeholder={tabs.customerSegmentNameTextareaPlaceholder}
                      value={customerSegment.name}
                      onChange={(event) =>
                        updateCustomerSegment({
                          name: 'name',
                          value: event.target.value,
                          id: customerSegment.id,
                        })
                      }
                    />
                  }
                />

                <TabsListItem
                  title={tabs.whatTypeOfSegmentIsThis}
                  icon={iconsComponents[1]}
                  text={
                    // eslint-disable-next-line
                    <>
                      <Checkbox
                        className="margin-bottom-30"
                        title={tabs.businessToCustomer}
                        value={customerSegment.type === CUSTOMER_SEGMENT_B2C}
                        onClick={() =>
                          updateCustomerSegment({
                            name: 'type',
                            value: CUSTOMER_SEGMENT_B2C,
                            id: customerSegment.id,
                          })
                        }
                      />

                      <Checkbox
                        title={tabs.businessToBusiness}
                        value={customerSegment.type === CUSTOMER_SEGMENT_B2B}
                        onClick={() =>
                          updateCustomerSegment({
                            name: 'type',
                            value: CUSTOMER_SEGMENT_B2B,
                            id: customerSegment.id,
                          })
                        }
                      />
                    </>
                  }
                />

                {customerSegment.type === CUSTOMER_SEGMENT_B2C && (
                  <TabsListItem
                    title={tabs.howOld}
                    icon={iconsComponents[2]}
                    text={
                      // eslint-disable-next-line
                      <RangeWithInput
                        locales={locales}
                        value={customerSegment.ageRange}
                        minValue={CUSTOMER_SEGMENT_AGE_RANGE_MIN}
                        maxValue={CUSTOMER_SEGMENT_AGE_RANGE_MAX}
                        delay={700}
                        onChange={(value) =>
                          updateCustomerSegment({
                            name: 'ageRange',
                            value,
                            id: customerSegment.id,
                          })
                        }
                      />
                    }
                  />
                )}

                {customerSegment.type === CUSTOMER_SEGMENT_B2B && (
                  <TabsListItem
                    title={tabs.howMany}
                    icon={iconsComponents[2]}
                    text={
                      // eslint-disable-next-line
                      <RangeWithInput
                        locales={locales}
                        value={customerSegment.companySize}
                        minValue={CUSTOMER_SEGMENT_COMPANY_SIZE_MIN}
                        maxValue={CUSTOMER_SEGMENT_COMPANY_SIZE_MAX}
                        delay={700}
                        onChange={(value) =>
                          updateCustomerSegment({
                            name: 'companySize',
                            value,
                            id: customerSegment.id,
                          })
                        }
                      />
                    }
                  />
                )}

                <TabsListItem
                  title={tabs.customerDescription}
                  subtitle={tabs.customerDescriptionSub}
                  icon={iconsComponents[3]}
                  text={
                    // eslint-disable-next-line
                    <Textarea
                      rows={4}
                      delay={700}
                      placeholder={tabs.customerDescriptionTextareaPlaceholder}
                      value={customerSegment.description}
                      onChange={(event) =>
                        updateCustomerSegment({
                          name: 'description',
                          value: event.target.value,
                          id: customerSegment.id,
                        })
                      }
                    />
                  }
                />
              </TabsList>
            ))}

            {collection.length < CUSTOMER_SEGMENT_MAX_COUNT && (
              <div className="bmc-page-tabs-content-actions margin-top-40">
                <Button
                  onClick={addCustomerSegment}
                  wide
                  icon="icon-Add"
                  title={tabs.createNewCustomerSegment}
                  className="bmc-button--green"
                />
              </div>
            )}
          </div>,

          <Tabs key="tabs" tabs={caseStudies.map((e) => e.name)} black inner>
            {caseStudies.map((item, index) => (
              <div key={index}>
                <TabsCut
                  opened={opened}
                  setOpened={setOpened}
                  caseStudy={item}
                />

                {item.customerSegments.map((customerSegment, i) => (
                  <TabsList key={i} formStyle preview>
                    <TabsListTitle>
                      {tabs.title} {i + 1}
                    </TabsListTitle>

                    {fields.map(
                      (field, ii) =>
                        customerSegment[field] && (
                          <TabsListItem
                            icon={iconsComponents[ii]}
                            key={ii}
                            title={tabs[field]}
                            text={customerSegment[field]}
                          />
                        ),
                    )}
                  </TabsList>
                ))}
              </div>
            ))}
          </Tabs>,
        ]}
      </Tabs>

      <Actions nextClickHandler={nextClickHandler} buttonName={common.next} />
    </div>
  );
}
