import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import GKGTabsTemplates from '../../getKeepGrow/GKGTabsTemplates';
import {
  COLORS,
  COMMENT_RELATIONSHIPS,
  TECH_MODERN_TEMPLATE_NAME,
} from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import SectionHeader from '../../../components/SectionHeader';
import { isSecondOrThirdTemplate } from '../../../utils/helpers/isSecondOrThirdTemplate';

const { black, transparentWhite, white } = COLORS;

const CustomerRelationshipsTech = ({
  locales,
  data: { description },
  locales: { portfolio },
  styleColor,
  backgroundSection,
  colorPaletteNumber,
}) => {
  const {
    portfolio: { updateSectionField },
  } = useDispatch();
  const {
    getKeepGrow: {
      intro: { gkgTabs },
    },
  } = locales;
  const gkg = useSelector((state) => state.getKeepGrow);
  const { getStrategies, keepStrategies, growStrategies, referralStrategies } =
    gkg;

  return (
    <div
      className="customer-relationships-tech section relative"
      style={{ background: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_RELATIONSHIPS} />

      <SectionHeader
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolio.customerRelationships.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'customerRelationships',
            field: 'description',
            name: 'gkgText',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        style={{
          color: isSecondOrThirdTemplate(colorPaletteNumber)
            ? white
            : styleColor,
        }}
      />

      <GKGTabsTemplates
        className="margin-top-40"
        portfolioWebMode
        firstColor={black}
        secondColor={
          isSecondOrThirdTemplate(colorPaletteNumber)
            ? `${white}4D`
            : `${styleColor}4D`
        }
        textColor={isSecondOrThirdTemplate(colorPaletteNumber) ? white : black}
        backgroundBlock={
          isSecondOrThirdTemplate(colorPaletteNumber)
            ? `${white}4D`
            : `${styleColor}4D`
        }
        borderColorBlock={transparentWhite}
        content={{
          ...gkgTabs,
          title: null,
          getStrategies: getStrategies || [],
          keepStrategies: keepStrategies || [],
          growStrategies: growStrategies || [],
          referralStrategies: referralStrategies || [],
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  get: state.getKeepGrow.getStrategies,
  keep: state.getKeepGrow.keepStrategies,
  grow: state.getKeepGrow.growStrategies,
  referral: state.getKeepGrow.referralStrategies,
});

export default connect(mapStateToProps)(CustomerRelationshipsTech);
