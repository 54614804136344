import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import classNames from 'classnames';

import Header from '../../../../components/Header';
import Text from '../../../../components/Text';
import TestTypeCard from '../../Components/TestTypeCard';
import Actions from '../../../../components/Actions';
import MobileMenuHeader from '../../../../components/MobileMenuHeader';

const DEFAULT_TEST_TYPE = 'homepage';

const ChooseTest = ({
  nextClickHandler,
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
  locales,
  locales: {
    testing: {
      name,
      chooseTest: { header, text, tabs },
    },
  },
}) => {
  const {
    testing: { test },
  } = useSelector((state) => state);

  const {
    testing: { updateTest },
  } = useDispatch();

  const { bmcId, id } = useParams();

  const [activeTab, setActiveTab] = useState(DEFAULT_TEST_TYPE);

  const cardData = useMemo(() => {
    const currentTab = tabs.find((tab) => tab.name.toLowerCase() === activeTab);
    return currentTab.card;
  }, [activeTab]);

  useEffect(() => {
    if (test.test === '')
      updateTest({ id, bmcId, test: { test: DEFAULT_TEST_TYPE } });
    if (test.test) setActiveTab(test.test);
  }, [test.test]);

  const setActiveTabHandler = (tabName) => {
    if (activeTab !== tabName) {
      updateTest({ id, bmcId, test: { test: tabName } });
    }
    setActiveTab(tabName);
  };

  return (
    <div className="bmc-page testing">
      <Header
        className="bmc-page-header--blue"
        title={header}
        subtitle={name}
        icon="icon-VP"
        locales={locales}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        componentIndex={componentIndex}
        mobileMenuHeader={
          <MobileMenuHeader icon="testingActive" title={name} color="blue" />
        }
        rules={rules}
        backPath={backPath}
      />

      <Text>{[text[0], text[1], text[2]]}</Text>

      <div className="tabs">
        {tabs.map((tab) => (
          <div
            onClick={() => setActiveTabHandler(tab.name.toLowerCase())}
            className={classNames('tab', {
              active: activeTab === tab.name.toLowerCase(),
            })}
            key={tab.name}
          >
            <div className="text">{tab.name}</div>
            <div className="border" />
          </div>
        ))}
      </div>

      <TestTypeCard data={cardData} />
      <Actions
        nextClickHandler={nextClickHandler}
        buttonName={cardData.nextButton}
      />
    </div>
  );
};

export default ChooseTest;
