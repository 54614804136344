import moment from 'moment/moment';

const date = moment();

export function getFutureDateAsString() {
  return date.add(100000, 'days').format('YYYY-MM-DD');
}

export default function getTodayDateAsString() {
  return new Date().toISOString().slice(0, 10);
}
