import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Dropzone from '../../components/Dropzone';
import Textarea from '../../components/Textarea';
import { renderImage } from '../../utils/helpers/renderImage';
import { IMAGE_ASPECT_RATIO_ONE } from '../../utils/const';

export default function CardCurrent({
  className,
  locales,
  styleColor,
  backgroundImage,
  borderCardColor,
  templateName,
  editMode = false,
  disabled = false,
  dropzoneMode = false,
  portfolioMode = false,
  card: { id, title, name, image, imageCoords, description, descriptionTitle },
}) {
  const {
    channel: { updateChannel, updateChannelImage, updateChannelImageCoords },
  } = useDispatch();

  const { uploadingImage } = useSelector((state) => state.channel);

  const onImageDrop = ([file], openCropperHandler) => {
    updateChannelImage({
      id,
      name: 'image',
      blob: file,
      success: () => {
        openCropperHandler();
      },
    });
  };

  const onImageCropperSave = (croppedAreaPixels, innerCropData, zoom) => {
    updateChannelImageCoords({
      id,
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: imageCoords,
    });
  };

  return (
    <div
      className="bmc-page-card-wrapper"
      style={{ backgroundColor: borderCardColor }}
    >
      <div
        className={classNames(
          'bmc-page-card bmc-page-card--channel-mode-current',
          className,
          { 'bmc-page-card--portfolio-mode': portfolioMode },
        )}
      >
        <div className="bmc-page-card-inner">
          {editMode || dropzoneMode ? (
            <div className="bmc-page-card-dropzone">
              <Dropzone
                className="bmc-page-card-image"
                background={{ background: backgroundImage }}
                onDrop={onImageDrop}
                onCropperSave={onImageCropperSave}
                locales={locales}
                image={image}
                coords={imageCoords}
                uploading={uploadingImage === id}
                aspectRatio={IMAGE_ASPECT_RATIO_ONE}
                disabled={disabled}
                cropper
              />
            </div>
          ) : (
            renderImage(
              templateName,
              image,
              backgroundImage,
              locales.portfolio.channels.sectionName,
              IMAGE_ASPECT_RATIO_ONE,
            )
          )}
        </div>

        <div className="bmc-page-card-inner">
          {title && (
            <div className="bmc-page-card-row">
              <img
                src="/images/mpesa_Ch.png"
                className="bmc-page-card-icon"
                alt=""
              />
              <div
                className="bmc-page-card-big-title--with-underline custom-font-body"
                style={{
                  borderBottomColor: styleColor,
                }}
              >
                {title}
              </div>
            </div>
          )}

          <div
            className="bmc-page-card-row bmc-page-card-big-title--with-underline"
            style={{
              borderBottomColor: styleColor,
            }}
          >
            {editMode ? (
              <Textarea
                value={name}
                delay={700}
                className="textarea-current textarea-current-channel textarea-current-channel-title custom-font-body"
                onChange={(e) =>
                  updateChannel({ name: 'name', value: e.target.value, id })
                }
                disabled={disabled}
              />
            ) : (
              <div className="bmc-page-card-big-title bmc-page-card-big-title--customer-title custom-font-body">
                {name}
              </div>
            )}
          </div>

          <div className="bmc-page-card-row">
            <div className="bmc-page-card-title title custom-font-body">
              {descriptionTitle}
            </div>
            {editMode ? (
              <Textarea
                value={description}
                delay={700}
                className="textarea-current textarea-current-channel custom-font-body"
                onChange={(e) =>
                  updateChannel({
                    name: 'description',
                    value: e.target.value,
                    id,
                  })
                }
                disabled={disabled}
              />
            ) : (
              <div className="bmc-page-card-text custom-font-body">
                {description}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
