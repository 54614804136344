import {
  Paragraph,
  ImageRun,
  Table,
  TableRow,
  TableCell,
  AlignmentType,
  WidthType,
  HeightRule,
  TableAnchorType,
  OverlapType,
  TableLayoutType,
  VerticalAlign,
  HorizontalPositionRelativeFrom,
} from 'docx';

import { concat, filter, isEmpty } from 'lodash';
import {
  pageSize,
  convertPixelToTwip,
  convertPixel,
  COLORS,
  transparentBorder,
  shadingBox,
  tableWidth,
  noPageMargin,
} from '../Constants';
import {
  buildSdgTableIcon,
  emptyCell,
  marginTableRow,
  textRun,
} from '../helpers/index';

export default async (locales, portfolio, sustainability, options) => {
  const tableHeaders = [
    'SGD',
    'Social/Environmental Problem',
    'Social/Environmental Value',
  ];

  const buildHeaderCells = () =>
    tableHeaders.map(
      (headerName) =>
        new TableCell({
          width: {
            size: convertPixelToTwip(144),
            type: WidthType.DXA,
          },
          shading: shadingBox(options.backgroundDark),
          borders: transparentBorder(COLORS.BLACK),
          verticalAlign: VerticalAlign.CENTER,
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                textRun(headerName, {
                  size: 16,
                  allCaps: true,
                  bold: true,
                  color: COLORS.WHITE,
                  font: options.bodyFont,
                }),
              ],
            }),
          ],
        }),
    );

  const dataRows = () => {
    const tableHead = new TableRow({
      height: {
        value: convertPixelToTwip(39),
        rule: HeightRule.ATLEAST,
      },
      children: buildHeaderCells(),
    });

    const rowsList = filter(
      sustainability.collection,
      (item) => !isEmpty(item.sdg),
    ).map((sustainabilityItem) => {
      const tableIcon = buildSdgTableIcon(
        +sustainabilityItem.sdg,
        options.sustainability.iconTableColor,
        options.sustainability.circleTableColor,
      );

      return new TableRow({
        height: {
          value: convertPixelToTwip(82),
          rule: HeightRule.ATLEAST,
        },
        children: [
          new TableCell({
            width: {
              size: convertPixelToTwip(144),
              type: WidthType.DXA,
            },
            borders: {
              top: { color: COLORS.PRIMARY },
              left: { color: COLORS.PRIMARY },
              right: { color: COLORS.PRIMARY },
              bottom: {
                color:
                  options.sustainability?.tableBorderColor || COLORS.PRIMARY,
                space: 2,
                style: 'single',
                size: 6,
              },
            },
            verticalAlign: VerticalAlign.CENTER,
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new ImageRun({
                    data: tableIcon,
                    transformation: {
                      width: convertPixel(60),
                      height: convertPixel(60),
                    },
                    floating: {
                      horizontalPosition: {
                        offset: 514400,
                        relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
                      },
                      verticalPosition: {
                        offset: convertPixelToTwip(142),
                      },
                      allowOverLap: true,
                      zIndex: 0,
                    },
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            width: {
              size: convertPixelToTwip(321),
              type: WidthType.DXA,
            },
            margins: {
              right: convertPixelToTwip(5),
              left: convertPixelToTwip(5),
            },
            verticalAlign: VerticalAlign.CENTER,
            borders: {
              top: { color: COLORS.PRIMARY },
              left: { color: COLORS.PRIMARY },
              right: { color: COLORS.PRIMARY },
              bottom: {
                color:
                  options.sustainability?.tableBorderColor || COLORS.PRIMARY,
                space: 2,
                style: 'single',
                size: 6,
              },
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.START,
                children: [
                  textRun(sustainabilityItem.problem, {
                    font: options.bodyFont,
                    color: options.bodyColor || COLORS.BLACK,
                    size: 14,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            width: {
              size: convertPixelToTwip(321),
              type: WidthType.DXA,
            },
            verticalAlign: VerticalAlign.CENTER,
            borders: {
              top: { color: COLORS.PRIMARY },
              left: { color: COLORS.PRIMARY },
              right: { color: COLORS.PRIMARY },
              bottom: {
                color:
                  options.sustainability?.tableBorderColor || COLORS.PRIMARY,
                space: 2,
                style: 'single',
                size: 6,
              },
            },
            margins: {
              right: convertPixelToTwip(5),
              left: convertPixelToTwip(5),
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.START,
                children: [
                  textRun(sustainabilityItem.value, {
                    font: options.bodyFont,
                    color: options.bodyColor || COLORS.BLACK,
                    size: 14,
                  }),
                ],
              }),
            ],
          }),
        ],
      });
    });

    return concat([tableHead], rowsList);
  };

  return {
    properties: {
      page: {
        size: pageSize,
        margin: noPageMargin,
      },
    },
    children: [
      // getPageBg(options.sustainability.tablePageBg),
      // table with data
      new Table({
        float: {
          horizontalAnchor: TableAnchorType.TEXT,
          verticalAnchor: TableAnchorType.TEXT,
          overlap: OverlapType.NEVER,
          absoluteVerticalPosition: convertPixelToTwip(0),
          absoluteHorizontalPosition: 0,
        },
        width: {
          size: convertPixelToTwip(tableWidth),
          type: WidthType.DXA,
        },
        layout: TableLayoutType.AUTOFIT,
        rows: [
          marginTableRow(20, tableWidth, COLORS.PRIMARY, 3),
          // title
          new TableRow({
            height: {
              value: convertPixelToTwip(27),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(28, COLORS.PRIMARY),

              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth - 56),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.PRIMARY),
                children: [
                  // Title
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      textRun(locales.sustainability.sectionName, {
                        font: options.titleFont,
                        color: options.titleColor || COLORS.BLACK,
                        bold: options.sustainability.titleBold,
                        size: 24,
                        allCaps: true,
                      }),
                    ],
                  }),
                ],
              }),

              emptyCell(28, COLORS.PRIMARY),
            ],
          }),
          marginTableRow(10, tableWidth, COLORS.PRIMARY, 3),
          // description row
          new TableRow({
            height: {
              value: convertPixelToTwip(28),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(28, COLORS.PRIMARY),
              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth - 56),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.PRIMARY),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      textRun(portfolio.sustainability.description, {
                        font: options.bodyFont,
                        color: options.bodyColor || COLORS.BLACK,
                      }),
                    ],
                  }),
                ],
              }),
              emptyCell(28, COLORS.PRIMARY),
            ],
          }),
          marginTableRow(30, tableWidth, COLORS.PRIMARY, 3),

          // data
          new TableRow({
            children: [
              emptyCell(28, COLORS.PRIMARY),

              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth - 56),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.PRIMARY),
                children: [
                  new Table({
                    rows: dataRows(),
                  }),
                ],
              }),

              emptyCell(28, COLORS.PRIMARY),
            ],
          }),
        ],
      }),

      // whiteBgImage(options.sustainability.tablePageBg),
    ],
  };
};
