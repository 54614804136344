import * as Route from '../support/serverRoutes';
import makeRequest, { uploadFile } from '../../main/utils/makeRequest';

const users = {
  state: {
    data: [],
    loading: false,
    uploading: false,
    deleteLoading: false,
  },
  reducers: {
    setData(state, { data, meta }) {
      return { ...state, data, meta };
    },
    setLoading(state, loading) {
      return { ...state, loading };
    },
    setDeleteLoading(state, deleteLoading) {
      return { ...state, deleteLoading };
    },
    setUploading(state, uploading) {
      return { ...state, uploading };
    },
  },
  effects: (dispatch) => ({
    fetchUsers(params) {
      dispatch.users.setLoading(true);
      makeRequest({
        dispatch,
        url: Route.users(params),
        success: (response) => {
          dispatch.users.setData({
            data: response.data.data,
            meta: response.data.meta,
          });
        },
        finish: () => {
          dispatch.users.setLoading(false);
        },
      });
    },
    updateImage({ id, image, after = [] }) {
      dispatch.users.setUploading(true);
      uploadFile({
        dispatch,
        url: Route.users(id),
        method: 'put',
        data: image,
        modelName: 'user',
        name: 'image',
        blob: image,
        success: () => {
          after.forEach((callback) => callback());
        },
        finish: () => {
          dispatch.users.setUploading(false);
        },
      });
    },
    updateUser({
      id,
      fields,
      establishmentIds,
      classroomId,
      setDefaultPassword,
      image,
      afterSuccess = () => {},
    }) {
      dispatch.users.setUploading(true);
      const data = {};
      if (fields) data.user = fields;
      if (establishmentIds) data.establishmentIds = establishmentIds;
      if (setDefaultPassword) data.setDefaultPassword = setDefaultPassword;
      if (classroomId) data.classroom = { id: classroomId };
      makeRequest({
        dispatch,
        url: Route.users(id),
        method: 'put',
        data,
        success: () => {
          dispatch.adminLecturers.reload();
          afterSuccess();
        },
        finish: () => {
          if (image) {
            dispatch.users.updateImage({ id, image });
          } else {
            dispatch.users.setUploading(false);
          }
        },
        showSuccessToast: true,
      });
    },
    deleteUser({ userId, confirmationPassword, afterSuccess = () => {} }) {
      dispatch.users.setDeleteLoading(true);
      makeRequest({
        dispatch,
        url: Route.users(userId),
        method: 'delete',
        data: { user: { ...confirmationPassword } },
        showSuccessToast: true,
        success: afterSuccess,
        finish: () => {
          dispatch.users.setDeleteLoading(false);
        },
      });
    },
    deactivateAccount({
      userId,
      confirmationPassword,
      afterSuccess = () => {},
    }) {
      dispatch.users.setDeleteLoading(true);
      makeRequest({
        dispatch,
        url: Route.usersDeactivate(userId),
        method: 'delete',
        data: { user: { ...confirmationPassword } },
        showSuccessToast: true,
        success: afterSuccess,
        finish: () => {
          dispatch.users.setDeleteLoading(false);
        },
      });
    },
  }),
};

export default users;
