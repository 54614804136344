import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dropzone from '../Dropzone';
import { renderImage } from '../../utils/helpers/renderImage';

export default function ImageDropChannels({
  locales,
  editMode = false,
  dropzoneMode = false,
  disabled = false,
  backgroundImage,
  aspectRatio,
  firstSvgColor,
  secondSvgColor,
  titleStyle,
  buttonStyle,
  lightButtonStyle,
  sliderColor,
  backgroundModal,
  titleColor,
  buttonSecondColor,
  buttonFirstColor,
  backgroundSecondButton,
  titleImageColor,
  isTemplates = false,
  isColourfulfunTemplate = false,
  templateName,
  card: { id, image, imageCoords },
}) {
  const {
    channel: { updateChannelImage, updateChannelImageCoords },
  } = useDispatch();

  const { uploadingImage } = useSelector((state) => state.channel);

  const onImageDrop = ([file], openCropperHandler) => {
    updateChannelImage({
      id,
      name: 'image',
      blob: file,
      success: () => {
        openCropperHandler();
      },
    });
  };

  const onImageCropperSave = (croppedAreaPixels, innerCropData, zoom) => {
    updateChannelImageCoords({
      id,
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: imageCoords,
    });
  };

  return editMode || dropzoneMode ? (
    <div className="image-hover">
      <Dropzone
        className="image-uploud"
        onDrop={onImageDrop}
        onCropperSave={onImageCropperSave}
        locales={locales}
        image={image}
        coords={imageCoords}
        background={{ background: backgroundImage }}
        uploading={uploadingImage === id}
        aspectRatio={aspectRatio}
        disabled={disabled}
        cropper
        firstSvgColor={firstSvgColor}
        secondSvgColor={secondSvgColor}
        titleStyle={titleStyle}
        buttonStyle={buttonStyle}
        lightButtonStyle={lightButtonStyle}
        sliderColor={sliderColor}
        backgroundModal={backgroundModal}
        titleColor={titleColor}
        buttonSecondColor={buttonSecondColor}
        buttonFirstColor={buttonFirstColor}
        backgroundSecondButton={backgroundSecondButton}
        isTemplates={isTemplates}
        isColourfulfunTemplate={isColourfulfunTemplate}
      />
    </div>
  ) : (
    <div className="image-uploud">
      {renderImage(
        templateName,
        image,
        backgroundImage,
        locales.portfolio.channels.sectionName,
        aspectRatio,
        titleImageColor,
      )}
    </div>
  );
}
