import React, { useState } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import useMediaQuery from 'react-use-media-query-hook';

import UnitEconomicsTabTech from '../../../components/FinanceTabs/UnitEconomicsTab';
import GraphTabTech from '../../../components/FinanceTabs/GraphTab';
import ForecastTabTech from '../../../components/FinanceTabs/ForecastTab';
import {
  COMMENT_FINANCE,
  TECH_MODERN_TEMPLATE_NAME,
} from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';

const UnitEconomicsTab = 0;
const ForecastTab = 1;
const GraphTab = 2;

const FinanceTech = ({
  data: { description },
  locales: { portfolio },
  titleColor,
  firstChartColor,
  secondChartColor,
  borderCardColor,
  background,
}) => {
  const {
    portfolio: { updateSectionField },
  } = useDispatch();

  const [totalCashIn, setTotalCashIn] = useState([]);
  const [totalCashOut, setTotalCashOut] = useState([]);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [activeTab, setActiveTab] = useState(UnitEconomicsTab);

  const tabs = {
    [UnitEconomicsTab]: (
      <UnitEconomicsTabTech
        locales={portfolio}
        isPreview
        templateName={TECH_MODERN_TEMPLATE_NAME}
        borderCardColor={borderCardColor}
        verticalBorderColor={titleColor}
        backgroundRow={`${background}80`}
      />
    ),
    [ForecastTab]: (
      <ForecastTabTech
        locales={portfolio}
        setTotalCashIn={setTotalCashIn}
        setTotalCashOut={setTotalCashOut}
        totalColor={titleColor}
        templateName={TECH_MODERN_TEMPLATE_NAME}
        borderCardColor={borderCardColor}
        backgroundTable={`${background}80`}
        backgroundRow={`${background}80`}
      />
    ),
    [GraphTab]: (
      <GraphTabTech
        locales={portfolio}
        totalCashIn={totalCashIn}
        totalCashOut={totalCashOut}
        firstChartColor={firstChartColor}
        secondChartColor={secondChartColor}
        templateName={TECH_MODERN_TEMPLATE_NAME}
        borderCardColor={borderCardColor}
      />
    ),
  };
  const allTabs = [tabs[UnitEconomicsTab], tabs[ForecastTab], tabs[GraphTab]];
  const currentTab = tabs[activeTab];
  return (
    <div
      className="portfolio-finance-tech portfolio-finance-tech-temp section relative"
      style={{ backgroundColor: background }}
    >
      <PortfolioCommentIcon title={COMMENT_FINANCE} />
      <SectionHeader
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolio.finance.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'finance',
            field: 'description',
            name: 'financeText',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        style={{ color: titleColor }}
      />
      <div className="tabs-wrapper">
        <div
          className={classnames(
            'tab',
            {
              active: activeTab === UnitEconomicsTab,
            },
            'custom-font-body',
          )}
          onClick={() => setActiveTab(UnitEconomicsTab)}
        >
          {portfolio.finance.tabName}
        </div>
        <div
          className={classnames(
            'tab',
            { active: activeTab === ForecastTab },
            'custom-font-body',
          )}
          onClick={() => setActiveTab(ForecastTab)}
          id="docx-forecast-tab"
        >
          {portfolio.finance.forecast.title}
        </div>
        <div
          className={classnames(
            'tab',
            { active: activeTab === GraphTab },
            'custom-font-body',
          )}
          onClick={() => setActiveTab(GraphTab)}
          id="docx-graph-tab"
        >
          {portfolio.finance.chart.title}
        </div>
      </div>
      {isMobile ? allTabs : currentTab}
    </div>
  );
};

export default FinanceTech;
