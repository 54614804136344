import * as Route from '../support/serverRoutes';
import makeRequest, { uploadFile } from '../../main/utils/makeRequest';

const establishment = {
  state: {
    data: null,
    loading: false,
    uploading: false,
  },
  reducers: {
    setData(state, { data }) {
      return { ...state, data };
    },
    setLoading(state, isLoading) {
      if (isLoading) return { ...state, loading: isLoading, data: null };

      return { ...state, loading: isLoading };
    },
    setUploading(state, isUploading) {
      return { ...state, uploading: isUploading };
    },
  },
  effects: (dispatch) => ({
    fetchEstablishment({ id }) {
      dispatch.establishment.setLoading(true);
      makeRequest({
        dispatch,
        url: Route.establishment(id),
        success: (response) => {
          dispatch.establishment.setData({
            data: response.data,
          });
        },
        finish: () => {
          dispatch.establishment.setLoading(false);
        },
      });
    },
    createEstablishment({ values, after = [] }) {
      dispatch.establishment.setUploading(true);
      makeRequest({
        dispatch,
        url: Route.establishment(),
        method: 'post',
        data: { establishment: values },
        success: (response) => {
          dispatch.establishment.setData({
            data: response.data,
          });
          dispatch.establishment.setUploading(false);
          after.forEach((callback) => callback());
          dispatch.admin.clearSelectedCreatedAdmins();
        },
        error: () => {
          dispatch.establishment.setUploading(false);
        },
      });
    },
    updateEstablishmentLogo({ id, logo, after = [] }) {
      dispatch.establishment.setUploading(true);
      uploadFile({
        dispatch,
        url: Route.establishment(id),
        method: 'put',
        data: logo,
        modelName: 'establishment',
        name: 'logo',
        blob: logo,
        success: () => {
          after.forEach((callback) => callback());
          dispatch.establishment.fetchEstablishment({ id });
        },
        finish: () => {
          dispatch.establishment.setUploading(false);
        },
      });
    },
    updateEstablishment({ id, values, adminIds, after = [] }) {
      dispatch.establishment.setUploading(true);
      makeRequest({
        dispatch,
        url: Route.establishment(id),
        method: 'put',
        data: { establishment: values, adminIds },
        showSuccessToast: true,
        success: () => {
          dispatch.establishment.setUploading(false);
          after.forEach((callback) => callback());
        },
        error: () => {
          dispatch.establishment.setUploading(false);
        },
      });
    },
  }),
};

export default establishment;
