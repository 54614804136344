import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Textarea from '../../components/Textarea';
import Dropzone from '../../components/Dropzone';
import Img from '../../components/Img';
import { IMAGE_ASPECT_RATIO_ONE } from '../../utils/const';

const renderImage = (image, title = '', background, titleImageColor) => {
  const safeTitle = title ? `${title[0]}` : undefined;

  return image.cropped ? (
    <div className="bmc-page-compcard-item-image">
      <Img src={image.cropped} aspectRatio={1} />
    </div>
  ) : (
    <div
      className="bmc-page-compcard-item-image bmc-page-compcard-item-image--no-image custom-font-title"
      style={{ backgroundColor: background, color: titleImageColor }}
    >
      {safeTitle}
    </div>
  );
};

export default function CompetitorsCard({
  className,
  editMode = false,
  isPreview = false,
  locales,
  backgroundRow,
  firstSvgColor,
  secondSvgColor,
  titleStyle,
  buttonStyle,
  textStyle,
  backgroundImage,
  textWithImage,
  titleImageColor,
  isTemplates = false,
  isColourfulfunTemplate = false,
  card: { name, doWell, doBadly, competitors },
}) {
  const {
    competitor: {
      updateCompetitor,
      updateCompetitorImage,
      updateCompetitorImageCoords,
    },
  } = useDispatch();

  const { uploadingImage } = useSelector((state) => state.competitor);

  const onImageDrop = ([file], openCropperHandler, { id }) => {
    updateCompetitorImage({
      id,
      name: 'image',
      blob: file,
      success: () => {
        openCropperHandler();
      },
    });
  };

  const onImageCropperSave = (
    croppedAreaPixels,
    innerCropData,
    zoom,
    { id, imageCoords },
  ) => {
    updateCompetitorImageCoords({
      id,
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: imageCoords,
    });
  };

  return (
    <div className={classNames('bmc-page-compcard', className)}>
      <div className="bmc-page-compcard-header">
        <div
          className={`bmc-page-compcard-header-title ${textStyle} custom-font-body`}
        >
          {name}
        </div>

        <div
          className={`bmc-page-compcard-header-title ${textStyle} custom-font-body`}
        >
          {doWell}
        </div>

        <div
          className={`bmc-page-compcard-header-title ${textStyle} custom-font-body`}
        >
          {doBadly}
        </div>
      </div>

      <div className="bmc-page-compcard-items custom-font-body">
        {competitors.map((competitor, i) =>
          editMode ? (
            <div
              className="bmc-page-compcard-item"
              key={competitor.id}
              style={i % 2 === 0 ? { background: backgroundRow } : {}}
            >
              <div
                className={`bmc-page-compcard-item-text bmc-page-compcard-item-text--with-image ${textWithImage}`}
              >
                <div className="margin-bottom-20">
                  <Dropzone
                    onDrop={(files, openHandler) =>
                      onImageDrop(files, openHandler, competitor)
                    }
                    onCropperSave={(pixels, data, zoom) =>
                      onImageCropperSave(pixels, data, zoom, competitor)
                    }
                    small
                    locales={locales}
                    image={competitor.image}
                    coords={competitor.imageCoords}
                    uploading={uploadingImage === competitor.id}
                    aspectRatio={IMAGE_ASPECT_RATIO_ONE}
                    cropper
                    background={{ background: backgroundImage }}
                    firstSvgColor={firstSvgColor}
                    secondSvgColor={secondSvgColor}
                    titleStyle={titleStyle}
                    buttonStyle={buttonStyle}
                    isColourfulfunTemplate={isColourfulfunTemplate}
                    isTemplates={isTemplates}
                  />
                </div>

                <Textarea
                  value={competitor.name}
                  delay={700}
                  className={`bmc-form-textarea ${textStyle} custom-font-body`}
                  onChange={(e) =>
                    updateCompetitor({
                      id: competitor.id,
                      name: 'name',
                      value: e.target.value,
                    })
                  }
                />
              </div>

              <div className="bmc-page-compcard-item-text">
                <Textarea
                  value={competitor.doWell}
                  delay={700}
                  className={`bmc-form-textarea ${textStyle} custom-font-body`}
                  onChange={(e) =>
                    updateCompetitor({
                      id: competitor.id,
                      name: 'doWell',
                      value: e.target.value,
                    })
                  }
                />
              </div>

              <div className="bmc-page-compcard-item-text">
                <Textarea
                  value={competitor.doBadly}
                  delay={700}
                  className={`bmc-form-textarea ${textStyle} custom-font-body`}
                  onChange={(e) =>
                    updateCompetitor({
                      id: competitor.id,
                      name: 'doBadly',
                      value: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          ) : (
            <div
              className="bmc-page-compcard-item"
              key={competitor.id}
              style={i % 2 === 0 ? { background: backgroundRow } : {}}
            >
              <div
                className={`bmc-page-compcard-item-text bmc-page-compcard-item-text--with-image ${textWithImage} custom-font-body`}
              >
                {isPreview ? (
                  renderImage(
                    competitor.image,
                    competitor.name,
                    backgroundImage,
                    titleImageColor,
                  )
                ) : (
                  <Dropzone
                    onDrop={(files, openHandler) =>
                      onImageDrop(files, openHandler, competitor)
                    }
                    onCropperSave={(pixels, data, zoom) =>
                      onImageCropperSave(pixels, data, zoom, competitor)
                    }
                    small
                    locales={locales}
                    image={competitor.image}
                    coords={competitor.imageCoords}
                    uploading={uploadingImage === competitor.id}
                    aspectRatio={IMAGE_ASPECT_RATIO_ONE}
                    cropper
                    background={{ background: backgroundImage }}
                    firstSvgColor={firstSvgColor}
                    secondSvgColor={secondSvgColor}
                    titleStyle={titleStyle}
                    buttonStyle={buttonStyle}
                    isColourfulfunTemplate={isColourfulfunTemplate}
                    isTemplates={isTemplates}
                  />
                )}
                {competitor.name}
              </div>

              <div
                className={`bmc-page-compcard-item-title ${textStyle} custom-font-body`}
              >
                {doWell}
              </div>

              <div
                className={`bmc-page-compcard-item-text ${textStyle} custom-font-body`}
              >
                {competitor.doWell}
              </div>

              <div
                className={`bmc-page-compcard-item-title ${textStyle} custom-font-body`}
              >
                {doBadly}
              </div>

              <div
                className={`bmc-page-compcard-item-text ${textStyle} custom-font-body`}
              >
                {competitor.doBadly}
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );
}
