import { cloneDeep, some } from 'lodash';

export const sample = (arr) => arr[Math.floor(Math.random() * arr.length)];

export const fieldHasContent = (field) => {
  if (field instanceof Array) {
    return field.some((e) => fieldHasContent(e));
  }
  if (field instanceof Object) {
    return some(field);
  }
  return !!field;
};

export const itemHasAllContent = (item, fields = ['description']) => {
  return fields.every((field) => fieldHasContent(item[field]));
};

export const itemHasContent = (item, fields = ['description']) => {
  return fields.some((field) => fieldHasContent(item[field]));
};

export const collectionEmpty = (collection, fieldNames = ['description']) => {
  return (
    collection.filter((item) => itemHasContent(item, fieldNames)).length === 0
  );
};

export const collectionSomeItemIsFilled = (collection, fields) => {
  return collection.some((item) => itemHasAllContent(item, fields));
};

export const countCollectionItemsIsFilled = (collection, fields) => {
  return collection.filter((item) => itemHasAllContent(item, fields)).length;
};

export const filterCollection = (collection, fieldName) => {
  return collection.filter((e) => e[fieldName]);
};

export const sumArrays = (...arrays) => {
  const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
  const result = Array.from({ length: n });
  return result.map((_, i) =>
    arrays.map((xs) => xs[i] || 0).reduce((sum, x) => sum + x, 0),
  );
};

export const diffArray = (...arrays) => {
  const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
  const result = Array.from({ length: n });
  return result.map((_, i) =>
    arrays.map((xs) => xs[i] || 0).reduce((d, j) => d - j),
  );
};

export const updateCollectionById = (collection, id, data) => {
  const newCollection = cloneDeep(collection);
  const index = newCollection.findIndex((e) => e.id === id);
  const item = newCollection[index];

  newCollection[index] = { ...item, ...data };

  return newCollection;
};
