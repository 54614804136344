import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmationModal from '../../../../components/ConfirmationModal';
import Textarea from '../../../../components/Textarea';
import Dropzone from '../../../../components/Dropzone';

const TeamCard = ({
  teammate,
  locales,
  locales: { portfolio },
  backgroundImage,
  teamNameColor,
  positionColor,
  memberDescriptionColor,
  titleStyle,
  buttonStyle,
  lightButtonStyle,
  sliderColor,
  titleColor,
  backgroundModal,
  buttonSecondColor,
  buttonFirstColor,
  backgroundSecondButton,
  isTemplates = false,
  disabled = false,
}) => {
  const { image, imageCoords, name, description, position, id } = teammate;
  const {
    portfolioTeammate: {
      updatePortfolioTeammate,
      updatePortfolioTeammateImage,
      updatePortfolioTeammateImageCoords,
      removePortfolioTeammate,
    },
  } = useDispatch();

  const { uploadingImage } = useSelector((state) => state.portfolioTeammate);

  const onChange = (e) => {
    updatePortfolioTeammate({
      id,
      name: e.target.name,
      value: e.target.value,
    });
  };

  const onDropImage = ([file], openCropperHandler) => {
    updatePortfolioTeammateImage({
      id,
      name: 'image',
      blob: file,
      success: () => {
        openCropperHandler();
      },
    });
  };

  const onImageCropperSave = (croppedAreaPixels, innerCropData, zoom) => {
    updatePortfolioTeammateImageCoords({
      id,
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: imageCoords,
    });
  };

  return (
    <div className="team-bold-card">
      {!disabled && (
        <div className="header">
          <ConfirmationModal
            body="Delete this content. Are you sure?"
            confirmText="Delete"
            onConfirm={() => removePortfolioTeammate({ id })}
          >
            <div className="icon-section">
              <i className="icon-Delete" />
            </div>
          </ConfirmationModal>
        </div>
      )}

      <div className="image-hover">
        <Dropzone
          className="image-upload"
          onDrop={onDropImage}
          onCropperSave={onImageCropperSave}
          locales={locales}
          image={image}
          coords={imageCoords}
          uploading={uploadingImage === teammate.id}
          aspectRatio={1}
          background={{ backgroundColor: backgroundImage }}
          disabled={disabled}
          titleStyle={titleStyle}
          buttonStyle={buttonStyle}
          lightButtonStyle={lightButtonStyle}
          sliderColor={sliderColor}
          backgroundModal={backgroundModal}
          titleColor={titleColor}
          buttonSecondColor={buttonSecondColor}
          buttonFirstColor={buttonFirstColor}
          backgroundSecondButton={backgroundSecondButton}
          isTemplates={isTemplates}
          cropper
        />
      </div>

      <Textarea
        id="position"
        type="text"
        name="position"
        rows={1}
        value={position}
        placeholder={portfolio.team.position}
        onChange={onChange}
        className="textarea-bold textarea-bold-team-card--position custom-font-body"
        delay={700}
        style={{ color: positionColor }}
        disabled={disabled}
      />
      <div className="fullName">
        <Textarea
          id="fullName"
          value={name}
          onChange={onChange}
          placeholder={portfolio.team.fullName}
          name="name"
          rows={1}
          type="text"
          className="textarea-bold textarea-bold-team-card--name custom-font-title"
          delay={700}
          style={{ color: teamNameColor }}
          disabled={disabled}
        />
      </div>

      <Textarea
        id="description"
        className="textarea-bold textarea-bold-team-card--description custom-font-body"
        name="description"
        placeholder={portfolio.page.descriptionPlaceholder}
        value={description}
        onChange={onChange}
        rows={5}
        delay={700}
        style={{ color: memberDescriptionColor }}
        disabled={disabled}
      />
    </div>
  );
};

export default TeamCard;
