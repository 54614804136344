import React, { useContext } from 'react';
import { CommentContext } from '../context';
import CommentInputReplyComponent from './comment-input-reply-component';

export default function CommentInputReplyContainer({ members, currentUserId }) {
  const { commentState, setCommentState } = useContext(CommentContext);

  const handleClickReplyCancel = () => {
    setCommentState((currentCommentState) => ({
      ...currentCommentState,
      replyComment: null,
    }));
  };

  if (commentState.replyComment)
    return (
      <div className="comment-input-reply-container">
        <div className="comment-input-reply-comment-container">
          <CommentInputReplyComponent
            members={members}
            comment={commentState.replyComment}
            onCancelReply={handleClickReplyCancel}
            currentUserId={currentUserId}
          />
        </div>
      </div>
    );

  return <></>;
}
