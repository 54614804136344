import React from 'react';
import { useSelector } from 'react-redux';
import SvgIcon from '../../main/components/SvgIcon';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import { COLOURFUL_TEMPLATE_NAME } from '../../main/utils/const';

const Feasibility = ({
  locales: { portfolioWeb },
  className,
  mainSvgColor,
  backgroundSection,
  sectionHeaderTitleColor,
  descriptionColor,
  templateName,
}) => {
  const { feasibility } = useSelector((state) => state.portfolio);
  return (
    <div
      className={`${className} section-web`}
      style={{ backgroundColor: backgroundSection }}
    >
      {templateName === COLOURFUL_TEMPLATE_NAME && (
        <div className="svg-funFeasibility" style={{ fill: mainSvgColor }}>
          <SvgIcon name="funFeasibility" />
        </div>
      )}

      <SectionHeaderWeb
        templateName={templateName}
        title={portfolioWeb.feasibility}
        description={feasibility.description}
        style={{ color: sectionHeaderTitleColor }}
        descriptionColor={descriptionColor}
      />
    </div>
  );
};

export default Feasibility;
