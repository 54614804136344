import * as Route from '../support/serverRoutes';
import makeRequest from '../../main/utils/makeRequest';

const lecturers = {
  state: {
    collection: [],
    loading: false,
    uploading: false,
  },
  reducers: {
    fetchLecturersReducer(state, { collection }) {
      return { ...state, collection };
    },
    setLoading(state, loading) {
      return { ...state, loading };
    },
  },
  effects: (dispatch) => ({
    fetchLecturers({ establishmentId }) {
      dispatch.lecturers.setLoading(true);
      makeRequest({
        dispatch,
        url: Route.lecturers({ establishmentId }),
        success: (response) => {
          dispatch.lecturers.fetchLecturersReducer({
            collection: response.data.data,
          });
        },
        finish: () => {
          dispatch.lecturers.setLoading(false);
        },
      });
    },
  }),
};

export default lecturers;
