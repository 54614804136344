import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import Avatars from '../../components/Avatars';
import TableHeader from '../../components/TableHeader';
import Table from '../../components/Table';
import ModalCreateClass from '../CreateClass/ModalCreateClass';
import ButtonSetting from '../../components/ButtonSetting';
import ModalClassSettings from '../CreateClass/ModalClassSettings';

const getCloumns = ({ handleButtonSettings }) => {
  return [
    {
      title: 'Class Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Educators',
      key: 'lecturers',
      render: (_, row) => {
        const images = row.lecturers.map((item) => {
          const fullName = `${item.firstName} ${item.surname}`;
          return {
            alt: fullName,
            url: item.image.icon.url,
            fullName,
          };
        });
        return <Avatars images={images} />;
      },
    },
    {
      title: 'Project',
      dataIndex: 'projectsCount',
      key: 'projectsCount',
    },
    {
      title: 'Students',
      dataIndex: 'studentsCount',
      key: 'studentsCount',
    },
    {
      title: 'Active Students',
      dataIndex: 'activeStudentsCount',
      key: 'activeStudentsCount',
    },
    {
      title: 'Ideas Created',
      dataIndex: 'ideasCount',
      key: 'ideasCount',
    },
    {
      title: 'Canvas Progress',
      dataIndex: 'canvasProgress',
      key: 'canvasProgress',
      render: (canvasProgress) => {
        return canvasProgress ? `${canvasProgress}%` : '0%';
      },
    },
    {
      title: 'Tests Completed',
      dataIndex: 'completedTestsCount',
      key: 'completedTestsCount',
    },
    {
      title: 'Minutes Total',
      dataIndex: 'sumTimeTracks',
      key: 'sumTimeTracks',
    },
    {
      title: 'Minutes (30 days)',
      dataIndex: 'sumLastTimeTracks',
      key: 'sumLastTimeTracks',
    },
    {
      title: '',
      key: 'action',
      render: (row) => (
        <ButtonSetting
          onClick={(event) => {
            event.stopPropagation();
            handleButtonSettings(row.id);
          }}
        />
      ),
    },
  ];
};

const ClassLists = ({
  byId,
  by,
  establishmentId,
  rowClick,
  rowClickable = false,
  disableCreateClassroom = false,
}) => {
  const [search, setSearch] = useState(undefined);
  const [visibleModalCreateClass, setVisibleModalCreateClass] = useState(false);
  const [modalClassSettings, setModalClassSettings] = useState({
    visible: false,
    classroomId: null,
  });
  const {
    classrooms: { data, meta, loading },
    pagination: { pageSize: initPageSize },
  } = useSelector((state) => state);

  const {
    classrooms: { fetchClassrooms, sortClassrooms },
  } = useDispatch();

  useEffect(() => {
    fetchClassrooms({ byId, by, search, limit: initPageSize, establishmentId });
  }, [by, byId, search, initPageSize, establishmentId]);

  const handleOpenModalClassSettings = (classroomId) => {
    setModalClassSettings({
      visible: true,
      classroomId,
    });
  };

  const handleCloseModalClassSettings = () => {
    setModalClassSettings({
      visible: false,
      classroomId: null,
    });
  };

  return (
    <>
      <ModalCreateClass
        visible={visibleModalCreateClass}
        onClose={() => setVisibleModalCreateClass(false)}
      />
      <ModalClassSettings
        visible={modalClassSettings.visible}
        onClose={handleCloseModalClassSettings}
        classroomId={modalClassSettings.classroomId}
      />
      <TableHeader
        title="Classes"
        count={loading ? <Spin /> : meta?.count || 0}
        textCreate="Create New Class"
        onCreate={() => {
          setVisibleModalCreateClass(true);
        }}
        onSearch={(searchValue) => {
          setSearch(searchValue);
        }}
        disableCreate={disableCreateClassroom}
      />
      <Table
        rowClickable={rowClickable}
        columns={getCloumns({
          handleButtonSettings: handleOpenModalClassSettings,
        })}
        dataSource={data}
        loading={loading}
        total={meta?.count || 0}
        current={meta?.page || 1}
        onChange={(pagination) => {
          const { current, pageSize } = pagination;
          fetchClassrooms({
            byId,
            by,
            establishmentId,
            page: current,
            limit: pageSize,
            search,
          });
        }}
        onRow={(record) => ({
          onClick: () => rowClick(record),
        })}
        onSort={sortClassrooms}
        meta={meta}
      />
    </>
  );
};

ClassLists.propTypes = {
  byId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  by: PropTypes.oneOf(['users', 'classrooms', 'establishments']),
  rowClick: PropTypes.func,
  rowClickable: PropTypes.bool,
};
export default ClassLists;
