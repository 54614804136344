import React from 'react';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import DonutChart from './DonutChart';
import { nextComponentHandlerFunction } from '../utils/helpers/nextComponentHandlerFunction';

const BmcMenu = ({
  componentIndex,
  sectionName,
  menuItems,
  locales: { common },
  rules = false,
  backPath,
  progress,
}) => {
  const { bmcId, id } = useParams();
  const {
    bmc: { view },
  } = useSelector((state) => state);

  let sectionNamePartial = sectionName;

  if (id) {
    sectionNamePartial = sectionNamePartial.replace(':id', id);
  }

  return (
    <div className="bmc-menu bmc-menu--default">
      <div className="bmc-menu-actions">
        <Link to={nextComponentHandlerFunction(view, backPath)}>
          <button className="bmc-button bmc-button--with-icon">
            <i className="icon-Back" />
            <span>{common.back}</span>
          </button>
        </Link>
      </div>

      <div className="bmc-menu-items margin-bottom-40">
        {menuItems.map((menuItem, index) => (
          <Link
            to={`/bmcs/${bmcId}/${sectionNamePartial}/${menuItem.sectionName}`}
            key={index}
            className={classNames('bmc-menu-items-item', {
              'bmc-menu-items-item--active': componentIndex === index,
              'bmc-menu-items-item--disabled': rules && rules[index] === false,
            })}
          >
            <div className="bmc-menu-items-item-active-bar" />
            <div
              className={classNames('bmc-menu-items-item-point', {
                'bmc-menu-items-item-point--active': componentIndex === index,
              })}
            />
            <div className="bmc-menu-items-item-title">{menuItem.name}</div>
          </Link>
        ))}
      </div>

      <div className="bmc-menu-progress">
        <DonutChart progress={progress} />
        <div className="bmc-menu-progress-title">{common.sectionProgress}</div>
      </div>
    </div>
  );
};

export default BmcMenu;
