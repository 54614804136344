import { Col, Form, Input, Row, Space, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../main/components/Modal';
import ModalContainer from '../../components/ModalContainer';
import ModalHeader from '../../components/ModalHeader';
import { CellFullName } from '../../components/UserList';
import SelectUser from '../Selects/SelectUser';
import DateRangeComponent from './DateRangeComponent';

const ModalCreateEstablishment = ({
  visible = false,
  onClose,
  onFinish,
  uploading,
}) => {
  const [adminOptions, setAdminOptions] = useState([]);

  const {
    admin: {
      adminOptions: { collection: adminList, loading: adminLoading },
      createdAdmins,
    },
  } = useSelector((state) => state);

  const {
    admin: { fetchAdmins, addCreatedAdmin, removeCreatedAdmin },
  } = useDispatch();

  useEffect(() => {
    const establishmentId = 23;
    fetchAdmins({ establishmentId });
  }, []);

  useEffect(() => {
    if (adminList) {
      setAdminOptions(adminList);
    }
  }, [adminList]);

  const [form] = Form.useForm();

  const handleSelect = (userId) => {
    addCreatedAdmin({ userId });
  };

  const handleDelete = (userId) => {
    removeCreatedAdmin({ userId });
  };

  const handleFinish = (values) => {
    values.maxLicensesCount = Number(values.maxLicensesCount);
    values.licenceLength = Number(values.licenceLength);
    values.createdAdmins = createdAdmins.map((e) => e.id);
    const afterSuccess = () => {
      form.resetFields();
    };
    onFinish(values, afterSuccess);
  };

  return (
    <>
      {visible && (
        <Modal onBlackscreenClick={onClose} className="sm">
          <ModalContainer>
            <ModalHeader title="Create New Establishment" onClose={onClose} />
            <Form form={form} layout="vertical" onFinish={handleFinish}>
              <div className="mt-20">
                <Row gutter={40}>
                  <Col>
                    <Form.Item
                      label="Name"
                      className="admin-input-label"
                      name="name"
                      rules={[{ required: true, message: 'Name is required' }]}
                    >
                      <Input
                        placeholder="Add Name of Establishment"
                        className="admin-input"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Subdomain"
                      className="admin-input-label"
                      name="subdomain"
                      rules={[
                        { required: true, message: 'Subdomain is required' },
                      ]}
                    >
                      <Input
                        placeholder="Add Establishment’s Subdomain"
                        className="admin-input"
                      />
                    </Form.Item>
                    <Row gutter={10}>
                      <DateRangeComponent form={form} />
                    </Row>
                    <SelectUser
                      avatarVisible
                      users={adminOptions}
                      loading={uploading || adminLoading}
                      columns={[
                        {
                          dataIndex: 'email',
                          render: (row) => {
                            return <CellFullName user={row} />;
                          },
                        },
                      ]}
                      labelOfSelect="Invite Admins To Join Your Establishment"
                      placeholderOfSelect="Choose admin by email"
                      labelOfList="Admins"
                      selectedIds={createdAdmins.map((admin) => admin.id)}
                      onSelect={handleSelect}
                      onDelete={handleDelete}
                    />
                  </Col>
                  <Col>
                    <Form.Item
                      label="Total Licence"
                      className="admin-input-label"
                      name="maxLicensesCount"
                      rules={[
                        {
                          pattern: '^[0-9]\\d*$',
                          message: 'The input must be a positive number!',
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder="Add Number"
                        className="admin-input"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Licence Length"
                      className="admin-input-label"
                      name="licenceLength"
                      rules={[
                        {
                          pattern: '^[0-9]\\d*$',
                          message: 'The input must be a positive number!',
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder="Days"
                        className="admin-input"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="flex align-right mt-20">
                <Space>
                  <Button onClick={onClose} disabled={uploading}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit" loading={uploading}>
                    Create
                  </Button>
                </Space>
              </div>
            </Form>
          </ModalContainer>
        </Modal>
      )}
    </>
  );
};

ModalCreateEstablishment.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  uploading: PropTypes.bool,
};
export default ModalCreateEstablishment;
