import React from 'react';
import { useSelector } from 'react-redux';

import { removeNewLines } from '../../main/utils/helpers';
import Button from '../../main/components/Button';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';

const Summary = ({
  locales,
  backgroundSection,
  isFourthColorPaletteNumber = false,
}) => {
  const { projectSummary } = useSelector((state) => state.portfolio);
  const { collection } = useSelector((state) => state.portfolioFile);

  const textColorDependsOnBackground =
    isFourthColorPaletteNumber &&
    getColorDependsOnBackground(backgroundSection);

  return (
    <div
      className="summary-blogFriendly-web"
      style={{ backgroundColor: backgroundSection }}
    >
      <div
        className="blogFriendly-title title custom-font-title"
        style={{ color: textColorDependsOnBackground }}
      >
        {locales.portfolioWeb.summary}
      </div>
      <div
        className="description custom-font-body"
        style={{ color: textColorDependsOnBackground }}
      >
        {projectSummary.description &&
          removeNewLines(projectSummary.description)}
      </div>

      <div className="margin-top-40 file">
        {collection.map((file) => (
          <a
            key={file.id}
            href={file.file}
            target="_blank"
            className="custom-font-body"
          >
            <Button
              icon="icon-Export"
              className="bmc-button button portfolio-file-uploader-file-blog custom-font-body"
              title={file.name}
              style={{
                color: textColorDependsOnBackground,
                borderColor: textColorDependsOnBackground,
              }}
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Summary;
