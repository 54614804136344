import React from 'react';
import classnames from 'classnames';

const ImageBlock = ({ text, src, className }) => {
  return (
    <div className={classnames('bmc-image-block', className)}>
      <img alt={src} className="bmc-image-block__image" src={src} />
      <div className="bmc-image-block__text">{text}</div>
    </div>
  );
};

export default ImageBlock;
