import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Header from '../../components/Header';
import Title from '../../components/Title';
import List from '../../components/List';
import Line from '../../components/Line';
import Text from '../../components/Text';
import Actions from '../../components/Actions';
import Tabs from '../../components/Tabs';
import Textarea from '../../components/Textarea';
import { nameWithDefault } from '../../utils/helpers';
import TabsCut from '../../components/TabsCut';
import TabsList from '../../components/TabsList';
import TabsListTitle from '../../components/TabsListTitle';
import TabsListItem from '../../components/TabsListItem';
import SvgIcon from '../../components/SvgIcon';
import MobileMenuHeader from '../../components/MobileMenuHeader';

const fields = ['goals', 'frustrates', 'problems'];
const icons = ['okLine', 'warningTriangle', 'angryFace'];
const iconsComponents = icons.map((e) => <SvgIcon name={e} />);

const BuildCustomerPersona = ({
  nextClickHandler,
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
  locales,
  locales: {
    common,
    tipCustomerSegment,
    caseStudies,
    customerSegment: {
      name,
      buildCustomerPersona: { header, title, text, list, tabs },
    },
  },
}) => {
  const [opened, setOpened] = useState(false);
  const {
    customerSegment: { collection },
  } = useSelector((state) => state);
  const {
    customerSegment: { updateCustomerSegment },
  } = useDispatch();

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon="icon-customer-segment"
        locales={locales}
        componentIndex={componentIndex}
        tipLocales={tipCustomerSegment}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="CSCanvas" title={name} color="green" />
        }
      />

      <Text>{[text[0]]}</Text>

      <Title text={title[0]} align="left" />

      <List items={list} icons={icons} />

      <Line />

      <h2 className="bmc-page-title">
        <span className="bmc-page-title--green">{title[1]}</span> {title[2]}
      </h2>

      <Text align="center">{[text[1]]}</Text>

      <Tabs
        tabs={[common.yourStrategy, common.caseStudy]}
        withBlur
        className="margin-top-60"
      >
        {[
          collection.map((customerSegment, index) => (
            <TabsList key={index} formStyle>
              <TabsListTitle>
                {nameWithDefault(customerSegment.name, name, index + 1)}
              </TabsListTitle>

              {fields.map((field, i) => (
                <TabsListItem
                  title={tabs[field]}
                  key={i}
                  icon={iconsComponents[i]}
                  text={
                    // eslint-disable-next-line
                    <Textarea
                      rows={4}
                      delay={700}
                      placeholder={tabs[`${field}Placeholder`]}
                      value={customerSegment[field]}
                      onChange={(event) =>
                        updateCustomerSegment({
                          name: field,
                          value: event.target.value,
                          id: customerSegment.id,
                        })
                      }
                    />
                  }
                />
              ))}
            </TabsList>
          )),

          <Tabs key="tabs" tabs={caseStudies.map((e) => e.name)} black inner>
            {caseStudies.map((item, index) => (
              <div key={index}>
                <TabsCut
                  setOpened={setOpened}
                  opened={opened}
                  caseStudy={item}
                />

                {item.customerSegments.map((customerSegment, i) => (
                  <TabsList key={i} formStyle preview>
                    <TabsListTitle>
                      {tabs.title} {i + 1}
                    </TabsListTitle>

                    {fields.map((field, ii) => (
                      <TabsListItem
                        key={ii}
                        title={tabs[field]}
                        text={customerSegment[field]}
                        icon={iconsComponents[ii]}
                      />
                    ))}
                  </TabsList>
                ))}
              </div>
            ))}
          </Tabs>,
        ]}
      </Tabs>

      <Actions nextClickHandler={nextClickHandler} buttonName={common.next} />
    </div>
  );
};

export default BuildCustomerPersona;
