import React from 'react';

import Header from '../../components/Header';
import Title from '../../components/Title';
import Text from '../../components/Text';
import Line from '../../components/Line';
import List from '../../components/List';
import Actions from '../../components/Actions';
import Card from './Card';
import MobileMenuHeader from '../../components/MobileMenuHeader';

export default function Intro(props) {
  const {
    nextClickHandler,
    menuItems,
    selectComponent,
    componentIndex,
    rules,
    backPath,
    locales,
    locales: {
      common,
      tipCustomerSegment,
      sustainability: {
        name,
        intro: { header, title, text, list, card },
      },
    },
  } = props;
  const menuHeader = (
    <MobileMenuHeader icon="SustainabilityCanvas" title={name} color="violet" />
  );

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon="icon-sustainability"
        locales={locales}
        componentIndex={componentIndex}
        className="bmc-page-header--violet"
        tipLocales={tipCustomerSegment}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        mobileMenuHeader={menuHeader}
        rules={rules}
        backPath={backPath}
      />

      <Text>{[text[0], text[1]]}</Text>

      <Title text={title[0]} align="left" />

      <List items={list} />

      <Line />

      <Title text={title[1]} className="margin-top-60" />

      <Text>{[text[2]]}</Text>

      <Card
        locales={locales}
        collection={card.sustainabilities}
        title={card.title}
        icon={card.icon}
        className="margin-wide-40-lg bmc-page-suscard bmc-page-suscard--section"
      />

      <Actions nextClickHandler={nextClickHandler} buttonName={common.next} />
    </div>
  );
}
