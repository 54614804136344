import React from 'react';
import { useSelector } from 'react-redux';

import Header from '../../components/Header';
import Actions from '../../components/Actions';
import Text from '../../components/Text';
import Line from '../../components/Line';
import Title from '../../components/Title';
import GKGTabs from './GKGTabs';
import SvgIcon from '../../components/SvgIcon';
import MobileMenuHeader from '../../components/MobileMenuHeader';

export default function Summary(props) {
  const {
    nextClickHandler,
    menuItems,
    selectComponent,
    componentIndex,
    rules,
    backPath,
    locales,
    locales: {
      common,
      tipCustomerSegment,
      getKeepGrow: {
        name,
        summary: { header, title, text },
        intro: { gkgTabs },
      },
    },
  } = props;
  const {
    getStrategies,
    keepStrategies,
    growStrategies,
    referralStrategies,
  } = useSelector((state) => state.getKeepGrow);

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon={<SvgIcon name="gkg" />}
        locales={locales}
        componentIndex={componentIndex}
        tipLocales={tipCustomerSegment}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="CRCanvas" title={name} color="green" />
        }
      />

      <Text align="center">{[text[0]]}</Text>

      <Line />

      <Title text={title[0]} />

      <GKGTabs
        className="margin-top-60"
        content={{
          ...gkgTabs,
          title: false,
          getStrategies,
          keepStrategies,
          growStrategies,
          referralStrategies,
        }}
      />

      <Actions nextClickHandler={nextClickHandler} buttonName={common.done} />
    </div>
  );
}
