import { itemHasContent } from '../../utils/helpers';

const CS_FIELDS = [
  'name',
  'description',
  'frustrates',
  'goals',
  'problems',
  'type',
];

export default function calculateCSProgress(rootState) {
  const stepsCount = 6;
  const step = 100 / stepsCount;
  let csProgress = 0;

  if (rootState.customerSegment.collection.length) {
    CS_FIELDS.forEach((field) => {
      if (itemHasContent(rootState.customerSegment.collection[0], [field]))
        csProgress += step;
    });
  }

  return csProgress;
}
