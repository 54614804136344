import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SectionHeader from '../SectionHeader';
import Textarea from '../../../components/Textarea';
import TestRow from '../../testing/Components/TestRow';
import Modal from '../../testing/Components/Modal';

const Testing = ({
  data: { description, showStatus },
  locales,
  locales: {
    portfolio,
    testing: { titles },
  },
  disabled = false,
}) => {
  const {
    bmc: {
      current: { id: bmcId },
    },
    testing: { collection: tests },
  } = useSelector((state) => state);

  const {
    portfolio: { updateSectionField, updateSectionFieldReducer },
    testing: { fetchTests, setTestReducer },
  } = useDispatch();

  const [modal, setModal] = useState({
    status: false,
    test: null,
  });

  useEffect(() => {
    if (bmcId) fetchTests(bmcId);
  }, [bmcId, modal.status]);

  const onClick = (test) => {
    setModal({
      status: true,
      test,
    });
    setTestReducer(test);
  };

  const setShowStatus = (status) => {
    updateSectionFieldReducer({
      sectionName: 'testing',
      field: 'showStatus',
      value: status,
    });
  };

  return (
    <div className="testing">
      <Modal
        locales={locales}
        isOpen={modal.status}
        isEditable={!disabled}
        titles={titles}
        setModalStatus={setModal}
      />
      <SectionHeader
        locales={portfolio}
        showStatus={showStatus}
        setShowStatus={setShowStatus}
        sectionName={portfolio.testing.sectionName}
      />
      {showStatus && (
        <>
          <Textarea
            name="textarea"
            rows={5}
            delay={700}
            value={description || ''}
            onChange={(e) =>
              updateSectionField({
                sectionName: 'testing',
                field: 'description',
                name: 'testingText',
                value: e.target.value,
              })
            }
            className="bmc-form-textarea textarea"
            placeholder={portfolio.page.descriptionPlaceholder}
            disabled={disabled}
          />
          <div className="tests-list margin-top-20">
            {!tests.length && <div className="empty-text">No Tests</div>}
            {tests.map((testItem) => (
              <TestRow
                titles={titles}
                test={testItem}
                key={testItem.id}
                onClick={() => onClick(testItem)}
                isPreview={disabled}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Testing;
