import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import Introduction from '../currentComponents/Introduction';
import Summary from '../currentComponents/Summary';
import Team from '../currentComponents/Team';
import BMC from '../mainWebTemplateComponents/BMC';
import Testing from '../mainWebTemplateComponents/Testing';
import CustomerSegment from '../currentComponents/CustomerSegment';
import ValueProposition from '../currentComponents/ValueProposition';
import CompetitorAnalysis from '../mainWebTemplateComponents/CompetitorAnalysis';
import GKG from '../mainWebTemplateComponents/GKG';
import Channels from '../currentComponents/Channels';
import Finance from '../mainWebTemplateComponents/Finance';
import Sustainability from '../mainWebTemplateComponents/Sustainability';
import ContactUs from '../currentComponents/ContactUs';
import { StyledWrapper } from '../../main/utils/helpers';
import {
  COLORS,
  COLOR_PALETTE_ARRAY_CURRENT,
  CURRENT_TEMPLATE_NAME,
  FIRST_COLOR_PALETTE_NUMBER,
} from '../../main/utils/const';
import { getColorTemplate } from '../../main/utils/helpers/getColorTemplate';
import KeyActivities from '../mainWebTemplateComponents/KeyActivities';
import Desirability from '../mainWebTemplateComponents/Desirability';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';
import Feasibility from '../mainWebTemplateComponents/Feasibility';
import Viability from '../mainWebTemplateComponents/Viability';
import { getCurrentSubtitleColor } from '../../main/utils/helpers/getCurrentSubtitleColor';

const { black, lightBlack, lightGreen, white, orange, violet } = COLORS;

const CurrentWebTemplate = ({
  locales,
  bmcEnabled,
  testing,
  currentSettings,
}) => {
  const [showAll, setShowAll] = useState(false);

  const { sections } = useParams();

  const showSections = useMemo(() => {
    if (!sections) {
      setShowAll(true);
      return [];
    }
    const sectionArray = sections.split('-');
    if (!sectionArray.length) {
      setShowAll(true);
      return [];
    }
    return sectionArray;
  }, [sections]);

  const { contactUs } = useSelector((state) => state.portfolio);

  const { shortcuts } = locales.portfolio.export;

  const {
    introduction,
    projectSummary,
    team,
    bmc,
    testing: test,
    desirabilitySection: desirability,
    customerSegment,
    valueProposition,
    competitorAnalysis,
    customerRelationships,
    channels,
    feasibility: feasibilitySection,
    keyActivities,
    viability: viabilitySection,
    finance,
    sustainability,
    contactUs: contact,
  } = shortcuts;

  const isShowSections = (sectionName) => {
    return showAll || showSections.includes(sectionName);
  };

  const {
    portfolioStyle: { templateSettings, activeTemplateName },
    portfolioTemplate: { collection },
  } = useSelector((state) => state);

  const activeTemplate = find(collection, ['sysName', activeTemplateName]);
  const colorTemplate = getColorTemplate(
    templateSettings,
    activeTemplateName,
    activeTemplate,
  );

  if (!colorTemplate) {
    return null;
  }

  const [firstColor, secondColor, thirdColor, fourthColor] = colorTemplate;
  const colorPaletteNumber = COLOR_PALETTE_ARRAY_CURRENT[secondColor];
  const isFourthColorPaletteNumber = !colorPaletteNumber;
  const isFirstColorPallete = colorPaletteNumber === FIRST_COLOR_PALETTE_NUMBER;

  const textColorDependsOnSecondColor =
    isFourthColorPaletteNumber && getColorDependsOnBackground(secondColor);

  const textColorDependsOnThirdColor =
    isFourthColorPaletteNumber && getColorDependsOnBackground(thirdColor);

  const textColorDependsOnFourthColor =
    isFourthColorPaletteNumber && getColorDependsOnBackground(fourthColor);

  const mainStyleColor = !isFirstColorPallete && secondColor;

  const colorSusDependsOnBackgroundOrWhite = isFourthColorPaletteNumber
    ? getColorDependsOnBackground(fourthColor)
    : white;

  return (
    <StyledWrapper
      customtitlefont={currentSettings?.customTitleFont}
      customtitlecolor={currentSettings?.customTitleColor}
      custombodyfont={currentSettings?.customBodyFont}
      custombodycolor={currentSettings?.customBodyColor}
    >
      {isShowSections(introduction) && (
        <Introduction backgroundImage={`${secondColor}80`} />
      )}
      {isShowSections(projectSummary) && (
        <Summary
          locales={locales}
          background={firstColor}
          colorPaletteNumber={colorPaletteNumber}
          isFirstColorPallete={isFirstColorPallete}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}
      {isShowSections(team) && (
        <Team
          locales={locales}
          borderColorName={secondColor}
          isFirstColorPallete={isFirstColorPallete}
        />
      )}
      {bmcEnabled && isShowSections(bmc) && (
        <BMC
          locales={locales}
          className="bmc section section-120"
          sliderStyles="sdg-card sdg-card-modal"
          iconFill={mainStyleColor}
          backgroundIcon={!isFirstColorPallete && `${secondColor}66`}
          styleColor={mainStyleColor}
          backgroundIconTable={!isFirstColorPallete && fourthColor}
          backgroundSliderColor={!isFirstColorPallete && fourthColor}
          backgroundImage={`${secondColor}80`}
          titleColor={mainStyleColor}
          backgroundButton={mainStyleColor}
          sectionHeaderTitleColor={!isFirstColorPallete && lightBlack}
          titleImageColor={lightBlack}
          templateName={CURRENT_TEMPLATE_NAME}
          isProgressBar
        />
      )}
      {testing && isShowSections(test) && (
        <Testing
          locales={locales}
          className="testing section section-106"
          testListStyle="tests-list"
          inputColor={mainStyleColor}
          rateColor={mainStyleColor}
          titleColor={mainStyleColor}
          backgroundButton={mainStyleColor}
          linkColor={mainStyleColor}
          sectionHeaderTitleColor={!isFirstColorPallete && lightBlack}
          templateName={CURRENT_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(desirability) && (
        <Desirability
          locales={locales}
          className="desirability-current-web"
          backgroundSection={secondColor}
          sectionHeaderTitleColor={textColorDependsOnSecondColor}
          descriptionColor={textColorDependsOnSecondColor}
          templateName={CURRENT_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(customerSegment) && (
        <CustomerSegment
          locales={locales}
          styleColor={secondColor}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}

      {bmcEnabled && isShowSections(valueProposition) && (
        <ValueProposition locales={locales} styleColor={secondColor} />
      )}
      {bmcEnabled && isShowSections(competitorAnalysis) && (
        <CompetitorAnalysis
          locales={locales}
          className="competitor-analysis competitor-analysis-current"
          styleColor={secondColor}
          sectionHeaderTitleColor={secondColor}
          backgroundImage={`${secondColor}80`}
          titleImageColor={lightBlack}
          templateName={CURRENT_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(customerRelationships) && (
        <GKG
          locales={locales}
          className="get-keep-grow customer-relationships-current"
          styleColor={secondColor}
          sectionHeaderTitleColor={secondColor}
          firstTabColor={secondColor}
          secondTabColor={lightGreen}
          textBlockColor={black}
          tabColor={secondColor}
          backgroundBlock={white}
          templateName={CURRENT_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(channels) && (
        <Channels
          locales={locales}
          styleColor={secondColor}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}
      {bmcEnabled && isShowSections(feasibilitySection) && (
        <Feasibility
          locales={locales}
          className="feasibility-current-web"
          backgroundSection={thirdColor}
          sectionHeaderTitleColor={textColorDependsOnThirdColor}
          descriptionColor={textColorDependsOnThirdColor}
          templateName={CURRENT_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(keyActivities) && (
        <KeyActivities
          locales={locales}
          className="key-activities-web"
          styleColor={thirdColor}
          sectionHeaderTitleColor={thirdColor}
          titleActivityColor={getCurrentSubtitleColor(
            colorPaletteNumber,
            thirdColor,
            orange,
          )}
          titleResourcesColor={getCurrentSubtitleColor(
            colorPaletteNumber,
            thirdColor,
            orange,
          )}
          templateName={CURRENT_TEMPLATE_NAME}
          circleActivityColor={thirdColor}
          circleResourcesColor={thirdColor}
          lineActivityColor={thirdColor}
        />
      )}
      {bmcEnabled && isShowSections(viabilitySection) && (
        <Viability
          locales={locales}
          className="viability-current-web"
          backgroundSection={fourthColor}
          sectionHeaderTitleColor={textColorDependsOnFourthColor}
          descriptionColor={textColorDependsOnFourthColor}
          templateName={CURRENT_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(finance) && (
        <Finance
          locales={locales}
          className="portfolio-finance portfolio-finance-temp section"
          sectionHeaderTitleColor={fourthColor}
          activeTabColor={getCurrentSubtitleColor(
            colorPaletteNumber,
            fourthColor,
            violet,
          )}
          styleColor={fourthColor}
          firstChartColor={thirdColor}
          secondChartColor={fourthColor}
          separatorColor={getCurrentSubtitleColor(
            colorPaletteNumber,
            fourthColor,
            violet,
          )}
          templateName={CURRENT_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(sustainability) && (
        <Sustainability
          locales={locales}
          className="sustainability"
          cardClassName="bmc-page-suscard bmc-page-suscard--section"
          sliderStyles="sdg-card"
          sectionHeaderTitleColor={fourthColor}
          backgroundSliderColor={!isFirstColorPallete && fourthColor}
          styleColor={fourthColor}
          colorPaletteNumber={colorPaletteNumber}
          iconFill={colorSusDependsOnBackgroundOrWhite}
          iconFillTable={colorSusDependsOnBackgroundOrWhite}
          textSliderColor={colorSusDependsOnBackgroundOrWhite}
          backgroundIcon={!isFirstColorPallete && fourthColor}
          templateName={CURRENT_TEMPLATE_NAME}
        />
      )}
      {isShowSections(contact) && (
        <ContactUs
          data={contactUs}
          locales={locales}
          background={secondColor}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}
    </StyledWrapper>
  );
};

export default CurrentWebTemplate;
