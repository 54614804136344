import React from 'react';

import Img from './Img';

export default function TabsCut({
  caseStudy: { name, icon, description, image },
  opened,
  setOpened,
}) {
  return (
    <div className="bmc-page-tabs-content-cut">
      <div
        className="bmc-page-tabs-content-cut-header"
        onClick={() => setOpened(!opened)}
      >
        <div className="bmc-page-tabs-content-cut-header-icon">
          <img src={icon} alt="" />
        </div>
        <div className="bmc-page-tabs-content-cut-header-title">{name}</div>
        <div className="bmc-page-tabs-content-cut-header-arrow">
          {opened ? (
            <i className="icon-Expand" />
          ) : (
            <i className="icon-Colaps" />
          )}
        </div>
      </div>

      {opened && (
        <div className="bmc-page-tabs-content-cut-content">
          <div className="bmc-page-tabs-content-cut-content-left">
            {description.map((text, index) => (
              <p key={index}>{text}</p>
            ))}
          </div>

          <div className="bmc-page-tabs-content-cut-content-right">
            <Img src={image} />
          </div>
        </div>
      )}
    </div>
  );
}
