import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import Textarea from '../../../components/Textarea';
import Dropzone from '../../../components/Dropzone';

export default function Content({ locales, locales: { account }, className }) {
  const {
    user: {
      current: { firstName, surname, bio, image, imageCoords },
      uploadingImage,
    },
  } = useSelector((state) => state);

  const {
    user: {
      updateCurrentUser,
      updateCurrentUserImage,
      updateCurrentUserImageCoords,
    },
  } = useDispatch();

  const onDrop = ([file], openCropperHandler) => {
    updateCurrentUserImage({
      name: 'image',
      value: URL.createObjectURL(file),
      blob: file,
      success: () => {
        openCropperHandler();
      },
    });
  };

  const onImageCropperSave = (croppedAreaPixels, innerCropData, zoom) => {
    updateCurrentUserImageCoords({
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: imageCoords,
    });
  };

  return (
    <div className={classNames('bmc-account', className)}>
      <div className="bmc-form-big-title">{account.title[1]}</div>

      <div className="bmc-account-row margin-top-20">
        <div className="bmc-account-personal-info">
          <div className="margin-top-10">{account.subtitle}</div>

          <div className="bmc-form-fieldset margin-top-20">
            <label htmlFor="firstName" className="bmc-form-label">
              {account.firstName}
            </label>
            <input
              id="firstName"
              className="bmc-form-input"
              value={firstName}
              onChange={(e) =>
                updateCurrentUser({
                  name: 'firstName',
                  value: e.target.value,
                })
              }
              data-hj-allow
            />
          </div>

          <div className="bmc-form-fieldset margin-top-20">
            <label htmlFor="surname" className="bmc-form-label">
              {account.surname}
            </label>
            <input
              id="surname"
              className="bmc-form-input"
              value={surname}
              onChange={(e) =>
                updateCurrentUser({ name: 'surname', value: e.target.value })
              }
              data-hj-allow
            />
          </div>

          <div className="bmc-form-fieldset margin-top-20">
            <label htmlFor="bio" className="bmc-form-label">
              {account.bio}
            </label>
            <Textarea
              id="bio"
              className="bmc-form-textarea"
              value={bio}
              delay={700}
              rows={5}
              onChange={(e) =>
                updateCurrentUser({ name: 'bio', value: e.target.value })
              }
            />
          </div>
        </div>

        <div className="bmc-account-dropzone">
          <Dropzone
            onDrop={onDrop}
            onCropperSave={onImageCropperSave}
            locales={locales}
            image={image}
            coords={imageCoords}
            uploading={uploadingImage}
            aspectRatio={1}
            cropper
          />
        </div>
      </div>
    </div>
  );
}
