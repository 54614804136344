import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { forceVisible } from 'react-lazyload';

import { find, isEmpty, merge } from 'lodash';
import BmcCanvasContainer from '../bmc/BmcCanvasContainer';
import ChooseTemplateSection from './ChooseTemplateSection';
import ExportModal from './Export';
import FirstTimeTip from '../tips/FirstTimeTip';
import {
  ENABLE_ONBOARDING,
  CURRENT_TEMPLATE_NAME,
  COLOURFUL_TEMPLATE_NAME,
  BLOG_FRIENDLY_TEMPLATE_NAME,
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  TECH_MODERN_TEMPLATE_NAME,
  BOLD_TEMPLATE_NAME,
} from '../../utils/const';
import Spinner from '../../components/Spinner';
import Button from '../../components/Button';
import GlobalContext from '../../utils/GlobalContext';
import CurrentTemplate from './currentTemplate';
import { getPortfolioShortCuts } from '../../utils/helpers/getPortfolioShortCuts';
import ColourfulFunTemplate from './colourfulFunTemplate';
import BlogFriendlyTemplate from './blogFriendly';
import ArchitecturalMinimalTemplate from './architecturalMinimal';
import TechModernTemplate from './techModernTemplate';
import BoldTemplate from './boldTemplate';

const Portfolio = ({ locales }) => {
  const {
    sectionAvailabilitySettings: { bmc, testing },
  } = useContext(GlobalContext);

  const {
    portfolio: { page: pageLocales },
  } = locales;

  const {
    portfolioStyle: { activeTemplateName, templateSettings },
    portfolioTemplate: { collection },
  } = useSelector((state) => state);
  const [exportModalIsOpen, setExportModalIsOpen] = useState(false);

  const {
    bmc: { setBmcView },
    portfolio: { fetchPortfolioData },
    portfolioStyle: { updatePortfolioStyleSettings },
    user: { updateCurrentUser },
  } = useDispatch();

  useEffect(() => {
    setBmcView('portfolio');
  }, []);

  const { portfolio } = useSelector((state) => state);
  const { bmcId } = useParams();

  useEffect(() => {
    setBmcView('portfolio');
  }, []);

  useEffect(() => {
    if (bmcId) {
      updateCurrentUser({ name: 'lastBmcId', value: bmcId });
      fetchPortfolioData(bmcId);
    }
  }, [bmcId]);

  useEffect(() => {
    // set up default templateSettings for current template if not exist

    if (
      !templateSettings ||
      (!isEmpty(templateSettings) && !templateSettings[activeTemplateName]) ||
      !templateSettings[activeTemplateName]?.colorPalette
    ) {
      if (collection.length > 0) {
        const obj = {};
        const currentTemplate = find(collection, [
          'sysName',
          activeTemplateName,
        ]);
        obj[activeTemplateName] = {
          colorPalette: currentTemplate?.colorPalette
            ? currentTemplate.colorPalette[0].join('|')
            : null,
        };
        updatePortfolioStyleSettings(merge(templateSettings, obj), bmcId);
      }
    }
  }, [templateSettings]);

  // we have to force visibility of lazy components
  setTimeout(() => forceVisible(), 1000);

  const portfolioShortCuts = getPortfolioShortCuts(locales);

  const webLink = useMemo(() => {
    return portfolioShortCuts.map((i) => i.name).join('-');
  }, [portfolioShortCuts]);

  return (
    <BmcCanvasContainer isPortfolio locales={locales} withChat>
      {ENABLE_ONBOARDING && (
        <FirstTimeTip page="portfolio" tips="portfolioTip" locales={locales} />
      )}

      <div className="bmc-main portfolio" id="portfolio-width">
        <div className="bmc-main-header" id="scroll">
          <div className="bmc-main-header-title">{pageLocales.title}</div>

          <div className="bmc-main-header-actions">
            {/* todo: temporary disabled <Button
              icon="icon-Comment"
              className="bmc-button--blue bmc-button--just-icon margin-right-10"
            />
            <Button
              icon="icon-Play"
              className="bmc-button--blue bmc-button--just-icon margin-right-10"
            /> */}

            <div className="bmc-main-header-actions-view">
              {portfolio.slug && (
                <a
                  href={`${window.location.origin}/portfolio/${portfolio.slug}/${webLink}`}
                  target="_blank"
                >
                  <Button preview title={pageLocales.preview} />
                </a>
              )}

              <Button
                primary
                share
                title={pageLocales.export}
                onClick={() => setExportModalIsOpen(true)}
              />
            </div>
          </div>
        </div>
        <div className="bmc-canvas">
          <ChooseTemplateSection />
        </div>

        {portfolio.uploading ? (
          <Spinner className="margin-top-60" big />
        ) : (
          (activeTemplateName === CURRENT_TEMPLATE_NAME && (
            <CurrentTemplate
              locales={locales}
              portfolio={portfolio}
              bmc={bmc}
              testing={testing}
            />
          )) ||
          (activeTemplateName === COLOURFUL_TEMPLATE_NAME && (
            <ColourfulFunTemplate
              locales={locales}
              portfolio={portfolio}
              bmc={bmc}
              testing={testing}
            />
          )) ||
          (activeTemplateName === BLOG_FRIENDLY_TEMPLATE_NAME && (
            <BlogFriendlyTemplate
              locales={locales}
              portfolio={portfolio}
              bmc={bmc}
              testing={testing}
            />
          )) ||
          (activeTemplateName === ARCHITECTURAL_MINIMAL_TEMPLATE_NAME && (
            <ArchitecturalMinimalTemplate
              locales={locales}
              portfolio={portfolio}
              bmc={bmc}
              testing={testing}
            />
          )) ||
          (activeTemplateName === BOLD_TEMPLATE_NAME && (
            <BoldTemplate
              locales={locales}
              portfolio={portfolio}
              bmc={bmc}
              testing={testing}
            />
          )) ||
          (activeTemplateName === TECH_MODERN_TEMPLATE_NAME && (
            <TechModernTemplate
              locales={locales}
              portfolio={portfolio}
              bmc={bmc}
              testing={testing}
            />
          ))
        )}
      </div>
      <ExportModal
        locales={locales}
        isOpen={exportModalIsOpen}
        setIsOpen={setExportModalIsOpen}
      />
    </BmcCanvasContainer>
  );
};

export default Portfolio;
