import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BmcCanvasContainer from './BmcCanvasContainer';
import BmcCanvas from './BmcCanvas';
import BmcList from './BmcList';
import FirstTimeTip from '../tips/FirstTimeTip';
import * as AppRoute from '../../utils/appRoutes';
import { ENABLE_ONBOARDING } from '../../utils/const';
import Switcher from '../../components/Switcher';

export default function index({ locales, locales: { canvas }, listMode }) {
  const {
    bmc: {
      current: { id },
      view,
    },
  } = useSelector((state) => state);

  const {
    bmc: { setBmcView },
    user: { updateCurrentUser },
  } = useDispatch();

  useEffect(() => {
    setBmcView(listMode ? 'list' : 'canvas');
  }, [listMode]);

  useEffect(() => {
    if (id) updateCurrentUser({ name: 'lastBmcId', value: id });
  }, [id]);

  return (
    <BmcCanvasContainer locales={locales}>
      {ENABLE_ONBOARDING && (
        <FirstTimeTip page="bmc" tips="bmcTip" locales={locales} />
      )}
      <div className="bmc-main">
        <div className="bmc-main-header">
          <h1 className="bmc-main-header-title">
            {canvas.businessModelCanvas}
          </h1>

          <div className="bmc-main-header-actions bmc-main-header-actions--bmc">
            {/* todo: temporary disabled <Button
              icon="icon-Comment"
              className="bmc-button--blue bmc-button--just-icon margin-right-10"
            />
            <Button
              icon="icon-Hat"
              className="bmc-button--blue bmc-button--just-icon margin-right-10"
            /> */}

            <Switcher
              leftUrl={AppRoute.bmc(id)}
              leftIcon={<i className="icon-BMC-View-1" />}
              leftText={canvas.canvasView}
              leftActive={view === 'canvas'}
              rightUrl={AppRoute.bmcList(id)}
              rightIcon={<i className="icon-BMC-View-2" />}
              rightText={canvas.listView}
              rightActive={view === 'list'}
            />
          </div>
        </div>

        {view === 'canvas' ? (
          <BmcCanvas locales={locales} className="bmc-canvas--mobile" />
        ) : (
          <BmcList locales={locales} />
        )}
      </div>

      <div className="bmc-main-footer">
        <div className="bmc-main-footer-text">
          <span>{canvas.licence}</span>
          {canvas.footer}
        </div>
      </div>
    </BmcCanvasContainer>
  );
}
