import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TestRow from '../../testing/Components/TestRow';
import Modal from '../../testing/Components/Modal';
import {
  COMMENT_TESTING,
  TECH_MODERN_TEMPLATE_NAME,
} from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';

const TestingTech = ({
  data: { description },
  locales,
  locales: {
    portfolio,
    testing: { titles },
  },
  titleColor,
  background,
  borderCardColor,
  iconFill,
  iconStroke,
}) => {
  const {
    bmc: {
      current: { id: bmcId },
    },
    testing: { collection: tests },
  } = useSelector((state) => state);

  const {
    portfolio: { updateSectionField },
    testing: { fetchTests, setTestReducer },
  } = useDispatch();

  const [modal, setModal] = useState({
    status: false,
    test: null,
  });

  useEffect(() => {
    if (bmcId) fetchTests(bmcId);
  }, [bmcId, modal.status]);

  const onClick = (test) => {
    setModal({
      status: true,
      test,
    });
    setTestReducer(test);
  };

  return (
    <div
      className="testing testing-tech section relative"
      style={{ backgroundColor: background }}
    >
      <PortfolioCommentIcon title={COMMENT_TESTING} />
      <Modal
        locales={locales}
        isOpen={modal.status}
        titles={titles}
        setModalStatus={setModal}
      />
      <SectionHeader
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolio.testing.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'testing',
            field: 'description',
            name: 'testingText',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        style={{ color: titleColor }}
      />

      <div className="tests-list margin-top-20">
        {!tests.length && (
          <div className="empty-text custom-font-body">No Tests</div>
        )}
        {tests.map((testItem) => (
          <TestRow
            titles={titles}
            test={testItem}
            key={testItem.id}
            onClick={() => onClick(testItem)}
            isPreview={false}
            borderCardColor={borderCardColor}
            iconFill={iconFill}
            iconStroke={iconStroke}
          />
        ))}
      </div>
    </div>
  );
};

export default TestingTech;
