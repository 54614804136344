import React from 'react';
import { useSelector } from 'react-redux';
import { removeNewLines } from '../../main/utils/helpers';
import {
  CURRENT_TEMPLATE_NAME,
  PORTFOLIO_INTRO_BG_ASPECT_RATIO,
} from '../../main/utils/const';
import { renderLogo } from '../../main/utils/helpers/renderLogo';
import { renderImage } from '../../main/utils/helpers/renderImage';

const Introduction = ({ backgroundImage }) => {
  const { introduction } = useSelector((state) => state.portfolio);
  return (
    <div className="introduction">
      {renderLogo(
        CURRENT_TEMPLATE_NAME,
        introduction.introLogo,
        backgroundImage,
        'Logo',
      )}
      <div className="bg">
        {renderImage(
          CURRENT_TEMPLATE_NAME,
          introduction.introBg,
          backgroundImage,
          'Introduction',
          PORTFOLIO_INTRO_BG_ASPECT_RATIO,
        )}
      </div>
      <div className="info">
        <div className="current-title title custom-font-title">
          {introduction.title}
        </div>
        <div className="description custom-font-body">
          {introduction.description && removeNewLines(introduction.description)}
        </div>
      </div>
    </div>
  );
};

export default Introduction;
