import React from 'react';
import { useSelector } from 'react-redux';

import GKGTabsTemplates from '../../main/containers/getKeepGrow/GKGTabsTemplates';
import SvgIcon from '../../main/components/SvgIcon';
import {
  COLOURFUL_TEMPLATE_NAME,
  CURRENT_TEMPLATE_NAME,
} from '../../main/utils/const';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import { getCurrentCanvasIcon } from '../../main/utils/helpers/getCurrentCanvasIcon';

const GKG = ({
  locales,
  locales: { portfolioWeb },
  className,
  styleColor,
  firstSvgColor,
  secondSvgColor,
  textBlockColor,
  tabColor,
  firstTabColor,
  secondTabColor,
  backgroundSection,
  sectionHeaderTitleColor,
  descriptionColor,
  backgroundBlock,
  borderBlockColor,
  titleTabsColor,
  templateName,
}) => {
  const {
    getKeepGrow: {
      intro: { gkgTabs },
    },
  } = locales;
  const gkg = useSelector((state) => state.getKeepGrow);
  const { getStrategies, keepStrategies, growStrategies, referralStrategies } =
    gkg;
  const { customerRelationships } = useSelector((state) => state.portfolio);
  const isColourfulfunTemplate = templateName === COLOURFUL_TEMPLATE_NAME;
  const isCurrentTemplate = templateName === CURRENT_TEMPLATE_NAME;
  return (
    <div
      className={`${className} section-web`}
      style={{ backgroundColor: backgroundSection }}
    >
      {isCurrentTemplate && getCurrentCanvasIcon('CRCanvas', styleColor)}
      {isColourfulfunTemplate && (
        <>
          <div className="svg-funCRfifteenth" style={{ fill: firstSvgColor }}>
            <SvgIcon name="funCRfifteenth" />
          </div>
          <div className="svg-funCRsixteenth" style={{ fill: secondSvgColor }}>
            <SvgIcon name="funCRsixteenth" />
          </div>
        </>
      )}
      <div className="content">
        <SectionHeaderWeb
          templateName={templateName}
          title={portfolioWeb.customerRelationships}
          description={customerRelationships.description}
          className={isCurrentTemplate && 'green-title'}
          style={{ color: sectionHeaderTitleColor }}
          descriptionColor={descriptionColor}
        />
        <GKGTabsTemplates
          className="margin-top-40"
          portfolioWebMode
          content={{
            ...gkgTabs,
            title: null,
            getStrategies: getStrategies || [],
            keepStrategies: keepStrategies || [],
            growStrategies: growStrategies || [],
            referralStrategies: referralStrategies || [],
          }}
          firstColor={firstTabColor}
          secondColor={secondTabColor}
          textColor={textBlockColor}
          tabColor={tabColor}
          backgroundBlock={backgroundBlock}
          borderBlockColor={borderBlockColor}
          titleTabsColor={titleTabsColor}
        />
      </div>
    </div>
  );
};

export default GKG;
