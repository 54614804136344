import React from 'react';
import { Button, Space } from 'antd';
import Modal from '../../main/components/Modal';
import ModalContainer from './ModalContainer';

const ModalDeleteConfirmation = ({
  visible,
  confirmationTitle,
  onClose,
  onDelete,
}) => {
  return (
    <>
      {visible && (
        <Modal onBlackscreenClick={onClose} className="bmc-modal--small">
          <ModalContainer>
            <div className="confirmation-modal-delete">
              <h2 className="confirmation-modal-delete__header">
                {confirmationTitle}
              </h2>
              <Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="primary" danger onClick={onDelete}>
                  Delete
                </Button>
              </Space>
            </div>
          </ModalContainer>
        </Modal>
      )}
    </>
  );
};

export default ModalDeleteConfirmation;
