import { itemHasContent } from '../../utils/helpers';

const gkgFields = ['name', 'description'];
const sections = [
  'getStrategies',
  'keepStrategies',
  'growStrategies',
  'referralStrategies',
];

export default function calculateGkgProgress(rootState) {
  const stepsCount = 8;
  const step = 100 / stepsCount;
  let gkgProgress = 0;

  sections.forEach((section) => {
    if (rootState.getKeepGrow[section].length) {
      gkgFields.forEach((field) => {
        if (itemHasContent(rootState.getKeepGrow[section][0], [field]))
          gkgProgress += step;
      });
    }
  });

  return gkgProgress;
}
