import React from 'react';
import classNames from 'classnames';

import {
  BLOG_FRIENDLY_TEMPLATE_NAME,
  COLOURFUL_TEMPLATE_NAME,
  CURRENT_TEMPLATE_NAME,
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  TECH_MODERN_TEMPLATE_NAME,
  BOLD_TEMPLATE_NAME,
  PORTFOLIO_INTRO_LOGO_ASPECT_RATIO,
} from '../const';
import Img from '../../components/Img';

export const renderLogo = (
  templateName,
  image,
  backgroundImage,
  title,
  titleImageColor,
  borderImageColor,
) => {
  const currentColourfunArchTechStyles = [
    COLOURFUL_TEMPLATE_NAME,
    CURRENT_TEMPLATE_NAME,
    ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
    TECH_MODERN_TEMPLATE_NAME,
    BOLD_TEMPLATE_NAME,
  ];
  const isCurrentColourfunArchTechStyles =
    currentColourfunArchTechStyles.includes(templateName);

  const isBlogStyle = templateName === BLOG_FRIENDLY_TEMPLATE_NAME;

  return image.cropped ? (
    <div className="logo-upload">
      <Img
        src={image.cropped}
        aspectRatio={PORTFOLIO_INTRO_LOGO_ASPECT_RATIO}
      />
    </div>
  ) : (
    <div className="logo">
      <div className="bmc-page-card-image--no-image-wrapper">
        <div
          className="bmc-page-card-image bmc-page-card-image--no-image"
          style={{ background: backgroundImage, borderColor: borderImageColor }}
        >
          <span
            className={classNames(
              {
                'bmc-page-card-image-icon-title title-logo custom-font-title':
                  isCurrentColourfunArchTechStyles,
              },
              {
                'bmc-page-card-image-icon-title-blog custom-font-title':
                  isBlogStyle,
              },
            )}
            style={{ color: titleImageColor }}
          >
            {title}
          </span>
        </div>
      </div>
    </div>
  );
};
