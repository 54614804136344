import axios from 'axios';

import * as Route from '../utils/serverRoutes';
import { NOTIFICATION_TYPES } from '../utils/const';

const NO_CONNECTION_ID = 'no_connection_id';
const NO_CONNECTION_TEXT = 'Oops... trying to reconnect';

const connection = {
  state: null,
  effects: (dispatch) => ({
    checkConnection() {
      axios({
        url: Route.connection(),
        timeout: 5000,
      })
        .then(() => {
          dispatch.notification.removeNotification(NO_CONNECTION_ID);
        })
        .catch(() => {
          const newNotification = {
            id: NO_CONNECTION_ID,
            text: NO_CONNECTION_TEXT,
            permanent: true,
            type: NOTIFICATION_TYPES.warningBlue,
          };
          dispatch.notification.addNotification(newNotification);
        });
    },
  }),
};

export default connection;
