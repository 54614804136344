import React from 'react';

const StepTitle = ({ currentStepIndex, totalSteps, title }) => {
  return (
    <div className="step-title">
      <span className="step-title__status">
        Step {currentStepIndex} of {totalSteps}:
      </span>
      <span className="step-title__text">{` ${title}`}</span>
    </div>
  );
};

export default StepTitle;
