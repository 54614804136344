import { min } from 'lodash';

import {
  collectionSomeItemIsFilled,
  countCollectionItemsIsFilled,
} from '../../utils/helpers';

const COST_FIELDS = ['name', 'amount', 'period'];
const PRODUCT_FIELDS = ['name', 'costPrice', 'unit'];

export default function calculateCostProgress(rootState) {
  const stepsCount = 3;
  const step = 100 / stepsCount;
  const costMinCount = 2;
  let costProgress = 0;

  const costsCount = countCollectionItemsIsFilled(
    rootState.cost.collection,
    COST_FIELDS,
  );
  costProgress += step * min([costMinCount, costsCount]);

  if (collectionSomeItemIsFilled(rootState.product.collection, PRODUCT_FIELDS))
    costProgress += step;

  return costProgress;
}
