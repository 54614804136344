import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SectionHeader from './SectionHeader';
import Textarea from '../../components/Textarea';
import Card from '../valueProposition/Card';

const ValueProposition = ({
  data: { description, showStatus },
  locales: {
    portfolio,
    valueProposition: {
      intro: { card },
    },
  },
  locales,
  disabled = false,
}) => {
  const {
    valueProposition: { id, product, reason, image, imageCoords },
    uniqueSellingPoint: { collection },
  } = useSelector((state) => state);

  const {
    portfolio: { updateSectionField, updateSectionFieldReducer },
  } = useDispatch();

  const setShowStatus = (status) => {
    updateSectionFieldReducer({
      sectionName: 'valueProposition',
      field: 'showStatus',
      value: status,
    });
  };

  return (
    <div className="value-propositions">
      <SectionHeader
        locales={portfolio}
        showStatus={showStatus}
        sectionName={portfolio.valueProposition.sectionName}
        setShowStatus={setShowStatus}
        mainColor="green"
      />
      {showStatus && (
        <div>
          <Textarea
            name="textarea"
            rows={5}
            delay={700}
            value={description || ''}
            onChange={(e) =>
              updateSectionField({
                sectionName: 'valueProposition',
                field: 'description',
                name: 'vpText',
                value: e.target.value,
              })
            }
            className="bmc-form-textarea textarea"
            placeholder={portfolio.page.descriptionPlaceholder}
            disabled={disabled}
          />

          <Card
            locales={locales}
            className="margin-top-40"
            dropzoneMode
            portfolioMode
            blur={false}
            disabled={disabled}
            card={{
              ...card,
              id,
              title: false,
              image,
              imageCoords,
              solution: product,
              features: reason,
              points: collection,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ValueProposition;
