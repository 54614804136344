import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CardBlog from '../../valueProposition/CardBlog';
import {
  BLOG_FRIENDLY_TEMPLATE_NAME,
  BUTTON_LIGHT_MODAL_STYLE_BLOG,
  BUTTON_MODAL_STYLE_BLOG,
  COLORS,
  COMMENT_PROPOSITION,
  TITLE_MODAL_STYLE_BLOG,
} from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import SectionHeader from '../../../components/SectionHeader';
import { getColorDependsOnBackground } from '../../../utils/helpers/getColorDependsOnBackground';

const { black } = COLORS;

const ValuePropositionBlog = ({
  data: { description },
  locales,
  locales: {
    portfolio,
    valueProposition: {
      intro: { card },
    },
  },
  backgroundSection,
  backgroundCard,
  backgroundModal,
  isFourthColorPaletteNumber = false,
  disabled = false,
}) => {
  const {
    valueProposition: { id, product, reason, image, imageCoords },
    uniqueSellingPoint: { collection },
  } = useSelector((state) => state);

  const {
    portfolio: { updateSectionField },
  } = useDispatch();

  const textColorDependsOnBackground =
    isFourthColorPaletteNumber &&
    getColorDependsOnBackground(backgroundSection);

  const cardTextColorDependsOnBackground =
    isFourthColorPaletteNumber && getColorDependsOnBackground(backgroundCard);

  return (
    <div
      className="value-propositions-blogFriendly relative"
      style={{ backgroundColor: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_PROPOSITION} />
      <SectionHeader
        templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
        title={portfolio.valueProposition.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'valueProposition',
            field: 'description',
            name: 'vpText',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        style={{ color: textColorDependsOnBackground }}
        descriptionColor={textColorDependsOnBackground}
        disabled={disabled}
      />

      <CardBlog
        locales={locales}
        dropzoneMode
        portfolioMode
        background={backgroundCard}
        borderImageColor={cardTextColorDependsOnBackground}
        textColor={cardTextColorDependsOnBackground}
        blur={false}
        disabled={disabled}
        titleStyle={TITLE_MODAL_STYLE_BLOG}
        buttonStyle={BUTTON_MODAL_STYLE_BLOG}
        lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_BLOG}
        sliderColor={black}
        backgroundModal={backgroundModal}
        isTemplates
        card={{
          ...card,
          id,
          title: false,
          image,
          imageCoords,
          solution: product,
          features: reason,
          points: collection,
        }}
      />
    </div>
  );
};

export default ValuePropositionBlog;
