import React from 'react';

import Header from '../../../components/Header';
import Title from '../../../components/Title';
import Line from '../../../components/Line';
import List from '../../../components/List';
import Actions from '../../../components/Actions';
import MobileMenuHeader from '../../../components/MobileMenuHeader';
import ImageSelector from './ImageSelector';

export default function GetInspired(props) {
  const {
    nextClickHandler,
    menuItems,
    selectComponent,
    componentIndex,
    rules,
    backPath,
    locales,
    locales: {
      common,
      ideation: {
        name,
        getInspired: { header, title, text, list, actions },
      },
    },
  } = props;

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon="icon-VP"
        locales={locales}
        componentIndex={componentIndex}
        className="bmc-page-header--blue"
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="ideationActive" title={name} color="blue" />
        }
      />

      <div className="bmc-page-text">
        <p>
          <span className="bmc-page-text--bold"> {text[0]}</span>
        </p>
        <p>{text[1]}</p>
        <p>{text[2]}</p>
      </div>

      <Title text={title[0]} align="left" />

      <List items={list} icons={['balloon', 'userCheck', 'heart']} />

      <Line />

      <h2 className="bmc-page-title">
        <span className="bmc-page-title--blue">{title[1]}</span> {title[2]}
      </h2>

      <div className="bmc-page-text bmc-page-text--center">
        {text[3]}
        <br />
        {text[4]}
        <br />
        {text[5]}
      </div>

      <ImageSelector
        locales={locales}
        className="margin-wide-40-lg margin-top-60"
      />

      <Actions
        nextClickHandler={nextClickHandler}
        buttonName={common.next}
        tooltipIfDisabled={actions.tooltipIfDisabled}
      />
    </div>
  );
}
