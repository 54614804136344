// eslint-disable-next-line import/no-cycle
import { getClearColorVar, getTemplateFonts } from './index';
import { getContrastYIQ } from '../helpers';

export const buildCurrentOptions = (
  colors,
  fonts,
  templateSettings,
  templateData,
  isCustomPalette,
) => {
  const templateColors = getClearColorVar(templateSettings.colorPalette);

  const { titleFont, titleColor, bodyFont, bodyColor } = getTemplateFonts(
    templateSettings,
    templateData,
  );

  const blackPageTitleColor =
    isCustomPalette && !templateSettings.customTitleColor
      ? getContrastYIQ(`#${templateColors[1]}`)
      : false;
  const blackPageBodyColor =
    isCustomPalette && !templateSettings.customBodyColor
      ? getContrastYIQ(`#${templateColors[1]}`)
      : false;

  return {
    titleFont,
    bodyFont,
    titleColor: titleColor || colors.BLACK,
    bodyColor: bodyColor || colors.BLACK,
    fontColor: colors.BLACK,
    backgroundLight: colors.CURRENT_LIGHT,
    backgroundDark: templateColors[1],
    noImgBg: templateColors[1],
    mainPage: {
      mainImgType: 'default',
      imgBg: colors.CURRENT_DARK,
      imgWidth: 840,
      imgHeight: 344,
      titleBg: colors.CURRENT_LIGHT,
      isLogo: true,
      logoPositionX: 600,
      logoPositionY: 0,
      mainImgPositionX: 0,
      mainImgPositionY: 1100,
      paragraphWidth: 840,
      paragraphHeight: 257,
      absoluteVerticalPosition: 340,
    },
    summary: {
      pageBg: colors.CURRENT_LIGHT,
      btnBg: colors.CURRENT_BLUE,
      titleColor: colors.CURRENT_BLUE,
      titleSize: 36,
      titleBold: true,
      quoteImgColor: colors.CURRENT_BLUE,
    },
    team: {
      pageBg: colors.CURRENT_MIDDLE,
      titleColor: colors.CURRENT_BLUE,
      titleBold: true,
      positionColor: colors.CURRENT_LIGHT_GREEN,
      noImgBg: templateColors[1],
      noImgType: 'round',
      imgType: 'round',
    },
    bmc: { titleColor: colors.CURRENT_BLUE, titleBold: true },
    testing: {
      pageBg: colors.WHITE,
      titleColor: colors.CURRENT_DARK,
      titleBold: true,
      statusIconColor: templateColors[1],
    },
    desirability: {
      backgroundDark: templateColors[1],
      titleFont: fonts.NUNITO,
      bodyFont: fonts.NUNITO,
      color: colors.WHITE,
      titleBold: true,
      titleColor: blackPageTitleColor,
      bodyColor: blackPageBodyColor,
    },
    segment: {
      titleColor: colors.CURRENT_DARK,
      titleBold: true,
      noImgType: 'round',
      imgType: 'round',
      noImgBg: templateColors[1],
    },
    valueProposition: {
      pageBg: colors.WHITE,
      titleColor: colors.CURRENT_DARK,
      titleBold: true,
      subtitleColor: colors.CURRENT_BLACK_LIGHT,
      noImgType: 'round',
      noImgBg: templateColors[1],
      imgType: 'round',
    },
    analysis: {
      titleColor: colors.CURRENT_DARK,
      titleBold: true,
      tableBorderColor: templateColors[1],
      tableFont: fonts.NUNITO,
      noImgBg: templateColors[1],
      noImgType: 'round',
      imgType: 'round',
    },
    relationships: {
      pageBg: colors.CURRENT_LIGHT,
      titleColor: colors.CURRENT_DARK,
      titleBold: true,
      activeItemBorder: colors.CURRENT_GRAY,
      iconDarkColor: templateColors[1],
      iconLightColor: colors.PRIMARY,
      activeItemColor: { color: colors.BLACK, bold: true },
      inactiveItemColor: { color: colors.BLACK, bold: false },
    },
    channels: {
      pageBg: colors.WHITE,
      titleColor: colors.CURRENT_DARK,
      titleBold: true,
      nameColor: colors.CURRENT_BLACK_LIGHT,
      noImgType: 'round',
      noImgBg: templateColors[1],
      imgType: 'round',
    },
    feasibility: {
      backgroundDark: templateColors[1],
      titleFont: fonts.NUNITO,
      bodyFont: fonts.NUNITO,
      color: colors.WHITE,
      titleBold: true,
      titleColor: blackPageTitleColor,
      bodyColor: blackPageBodyColor,
    },
    keyActivity: {
      titleColor: colors.CURRENT_ORANGE,
      titleBold: true,
      font: fonts.NUNITO,
    },
    keyResources: {
      lineColor: colors.CURRENT_ORANGE,
      iconBg: templateColors[1],
      pageBg: colors.WHITE,
    },
    viability: {
      backgroundDark: templateColors[1],
      titleFont: fonts.NUNITO,
      bodyFont: fonts.NUNITO,
      color: colors.WHITE,
      titleBold: true,
      titleColor: blackPageTitleColor,
      bodyColor: blackPageBodyColor,
    },
    finance: {
      pageBg: colors.WHITE,
      titleColor: colors.CURRENT_VIOLET,
      titleBold: true,
      borderColor: colors.CURRENT_LIGHT_GREEN,
      descriptionFont: fonts.CONTENT,
    },
    economics: {
      borderColor: colors.CURRENT_LIGHT,
    },
    contact: {
      pageBg: templateColors[1],
      color: colors.WHITE,
      titleColor: colors.WHITE,
      borderColor: templateColors[0],
    },
    sustainability: {
      listPageTitleColor: colors.CURRENT_VIOLET,
      listPageTitleBold: true,
      tablePageBg: colors.WHITE,
      titleColor: colors.CURRENT_DARK,
      titleBold: true,
      listCircleWidth: 169,
      listCircleHeight: 169,
      listCircleColor: colors.WHITE,
      tableBorderColor: templateColors[1],
      iconListColor: templateColors[1],
      iconTableColor: templateColors[1],
      circleTableColor: colors.WHITE,
    },
  };
};
