import { itemHasContent } from '../../utils/helpers';

const CHANNEL_FIELDS = ['name', 'description'];

export default function calculateChannelProgress(rootState) {
  const stepsCount = 2;
  const step = 100 / stepsCount;
  let channelProgress = 0;

  if (rootState.channel.collection.length) {
    CHANNEL_FIELDS.forEach((field) => {
      if (itemHasContent(rootState.channel.collection[0], [field]))
        channelProgress += step;
    });
  }

  return channelProgress;
}
