import React, { useState, useRef, useEffect, useContext } from 'react';
import CommentListComponent from './comment-list-component';

import { CommentContext } from '../context';

let scrollTimer = null;

export default function CommentListContainer({ children }) {
  const refEl = useRef();
  const [mask, setMask] = useState(false);
  const { commentState, setCommentState } = useContext(CommentContext);

  const handleScroll = () => {
    clearTimeout(scrollTimer);

    scrollTimer = setTimeout(() => {
      setCommentState((currentState) => ({
        ...currentState,
        scrollStopAt: new Date(),
        parentElement: refEl.current,
      }));
    }, 250);
  };

  useEffect(() => {
    setTimeout(() => {
      setCommentState((currentState) => ({
        ...currentState,
        scrollStopAt: new Date(),
        parentElement: refEl.current,
      }));
    }, 1000);
  }, []);

  useEffect(() => {
    setMask(!!commentState.replyComment || !!commentState.editComment);
  }, [commentState.replyComment, commentState.editComment]);

  return (
    <div className="comment-list-container">
      <CommentListComponent onScroll={handleScroll} refEl={refEl}>
        {children}
      </CommentListComponent>
      <div
        className={`comment-list-container__mask${mask ? '--active' : ''}`}
      />
    </div>
  );
}
