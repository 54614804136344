import React from 'react';
import Block from './Block';

const BlockList = ({ list }) => {
  return (
    <div className="admin-block-list">
      {list.map((item, index) =>
        Array.isArray(item) ? (
          <Block data={item} key={index} />
        ) : (
          <Block title={item.title} data={item.children} key={index} />
        ),
      )}
    </div>
  );
};

export default BlockList;
