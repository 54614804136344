export const getPortfolioShortCuts = (locales) => {
  const portfolioShort = locales.portfolio.export.shortcuts;
  const portfolioShortCuts = [
    {
      name: portfolioShort.introduction,
    },
    {
      name: portfolioShort.projectSummary,
    },
    {
      name: portfolioShort.team,
    },
    {
      name: portfolioShort.bmc,
    },
    {
      name: portfolioShort.testing,
    },
    {
      name: portfolioShort.desirabilitySection,
    },
    {
      name: portfolioShort.customerSegment,
    },
    {
      name: portfolioShort.valueProposition,
    },
    {
      name: portfolioShort.competitorAnalysis,
    },
    {
      name: portfolioShort.customerRelationships,
    },
    {
      name: portfolioShort.channels,
    },
    {
      name: portfolioShort.feasibility,
    },
    {
      name: portfolioShort.keyActivities,
    },
    {
      name: portfolioShort.viability,
    },
    {
      name: portfolioShort.finance,
    },
    {
      name: portfolioShort.sustainability,
    },
    {
      name: portfolioShort.contactUs,
    },
  ];
  return portfolioShortCuts;
};
