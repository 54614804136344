import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { truncate } from 'lodash';
import useMediaQuery from 'react-use-media-query-hook';

import Card from '../../main/containers/channel/CardColourfulBold';
import ImageDrop from '../../main/components/ImageDrop/ImageDropChannels';
import {
  BOLD_TEMPLATE_NAME,
  IMAGE_ASPECT_RATIO_CH_BOLD,
  IMAGE_ASPECT_RATIO_ONE,
} from '../../main/utils/const';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import { getImageAspectRatio } from '../../main/utils/helpers/getImageAspectRatio';

const ChannelsBoldWeb = ({
  locales,
  locales: {
    portfolioWeb,
    channel: {
      intro: { card },
    },
  },
  titleColor,
  textColor,
  styleColor,
  titleTabsColor,
  backgroundSection,
  backgroundImage,
}) => {
  const [activeCardId, setActiveCardId] = useState(null);
  const { channels } = useSelector((state) => state.portfolio);
  const { collection } = useSelector((state) => state.channel);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isSmallDesktop = useMediaQuery('(max-width: 1024px)');

  const activeCardData = useMemo(() => {
    if (collection.length && !activeCardId) {
      setActiveCardId(collection[0].id);
      return collection[0];
    }
    return collection.find((i) => i.id === activeCardId);
  }, [collection, activeCardId]);

  const singleCard = activeCardData && (
    <Card
      locales={locales}
      isPreview
      titleColor={titleTabsColor}
      textColor={textColor}
      styleColor={styleColor}
      card={{
        ...card,
        ...activeCardData,
        title: null,
      }}
    />
  );

  const singleImage = activeCardData && (
    <ImageDrop
      locales={locales}
      backgroundImage={backgroundImage}
      titleImageColor={titleColor}
      aspectRatio={getImageAspectRatio(
        isSmallDesktop,
        IMAGE_ASPECT_RATIO_ONE,
        IMAGE_ASPECT_RATIO_CH_BOLD,
      )}
      templateName={BOLD_TEMPLATE_NAME}
      editMode={false}
      card={{
        ...card,
        ...activeCardData,
        title: null,
      }}
    />
  );

  return (
    <div
      className="channel-bold-web section-web"
      style={{ backgroundColor: backgroundSection }}
    >
      {isMobile ? (
        <>
          <SectionHeaderWeb
            templateName={BOLD_TEMPLATE_NAME}
            title={portfolioWeb.channels}
            description={channels.description}
            portfolioWeb={portfolioWeb}
            style={{ color: titleColor }}
            descriptionColor={textColor}
          />

          <div className="cards-section">
            {collection.map((channel) => {
              return (
                <>
                  <ImageDrop
                    locales={locales}
                    editMode={false}
                    backgroundImage={backgroundImage}
                    aspectRatio={getImageAspectRatio(
                      isSmallDesktop,
                      IMAGE_ASPECT_RATIO_ONE,
                      IMAGE_ASPECT_RATIO_CH_BOLD,
                    )}
                    templateName={BOLD_TEMPLATE_NAME}
                    card={{
                      ...card,
                      ...channel,
                      title: null,
                    }}
                  />
                  <Card
                    locales={locales}
                    titleColor={titleTabsColor}
                    textColor={textColor}
                    styleColor={styleColor}
                    card={{
                      ...card,
                      ...channel,
                      title: null,
                    }}
                  />
                </>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <div className="left">{singleImage}</div>
          <div className="right">
            <SectionHeaderWeb
              templateName={BOLD_TEMPLATE_NAME}
              title={portfolioWeb.channels}
              description={channels.description}
              portfolioWeb={portfolioWeb}
              style={{ color: titleColor }}
              descriptionColor={textColor}
            />

            <div className="cards-section">
              <div className="tabs">
                {collection.map(({ id, name }) => (
                  <div
                    key={id}
                    onClick={() => setActiveCardId(id)}
                    className={classnames(
                      'item',
                      {
                        active: activeCardId === id,
                      },
                      'custom-font-body',
                    )}
                    style={
                      activeCardId === id
                        ? {
                            backgroundColor: styleColor,
                            color: backgroundSection,
                          }
                        : { color: styleColor, borderColor: styleColor }
                    }
                  >
                    {truncate(name, { length: 30 })}
                  </div>
                ))}
              </div>
              <div className="main">{singleCard}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ChannelsBoldWeb;
