import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import Bmc from '../containers/bmc';
import Page404 from '../containers/Page404';
import ValueProposition from '../containers/valueProposition';
import CustomerSegment from '../containers/customerSegment';
import GetKeepGrow from '../containers/getKeepGrow';
import Channel from '../containers/channel';
import KeyActivity from '../containers/keyActivity';
import KeyPartners from '../containers/keyPartners';
import KeyResources from '../containers/keyResources';
import Cost from '../containers/cost';
import Revenue from '../containers/revenue';
import Sustainability from '../containers/sustainability';
import Portfolio from '../containers/portfolio';
import Account from '../containers/account/Account';
import Personal from '../containers/account/Personal';
import Password from '../containers/account/Password';
import Email from '../containers/account/Email';
import IdeationList from '../containers/ideation';
import IdeationItem from '../containers/ideation/IdeationItem';
import Testing from '../containers/testing';
import TestItem from '../containers/testing/Containers/TestItem';
import ScrollToTop from '../components/ScrollToTop';
import ActionCableHandler from '../containers/actioncable/ActionCableHandler';
import RouteRedirect from '../components/RouteRedirect';
import GlobalContext from './GlobalContext';
import * as AppRoute from './appRoutes';

const redirectToAvailableSection = (id, settings) => {
  if (settings.bmc) return AppRoute.bmc(id);
  if (settings.ideation) return AppRoute.ideation(id);
  if (settings.testing) return AppRoute.testing(id);
  if (settings.portfolio) return AppRoute.portfolio(id);
  return AppRoute.account();
};

export default function router() {
  const { bmcId, locales, sectionAvailabilitySettings } =
    useContext(GlobalContext);
  const { ideation, bmc, testing, portfolio } = sectionAvailabilitySettings;

  const bmcSections = [
    { section: 'customer_segment', component: CustomerSegment },
    { section: 'value_proposition', component: ValueProposition },
    { section: 'get_keep_grow', component: GetKeepGrow },
    { section: 'channel', component: Channel },
    { section: 'key_activity', component: KeyActivity },
    { section: 'key_partners', component: KeyPartners },
    { section: 'key_resources', component: KeyResources },
    { section: 'cost', component: Cost },
    { section: 'revenue', component: Revenue },
    { section: 'sustainability', component: Sustainability },
  ];

  return (
    <Router>
      <ScrollToTop />
      <ActionCableHandler />
      <Switch>
        <Redirect
          exact
          from="/"
          to={redirectToAvailableSection(bmcId, sectionAvailabilitySettings)}
        />

        {bmc && (
          <Route exact path="/bmcs/:bmcId">
            <Bmc locales={locales} />
          </Route>
        )}

        {bmc && (
          <Route exact path="/bmcs/:bmcId/list">
            <Bmc locales={locales} listMode />
          </Route>
        )}

        {testing && (
          <Route exact path="/bmcs/:bmcId/testing">
            <Testing locales={locales} />
          </Route>
        )}

        {testing && (
          <Route exact path="/bmcs/:bmcId/testing/:id">
            <RouteRedirect to="/bmcs/:bmcId/testing/:id/intro" />
          </Route>
        )}

        {testing && (
          <Route path="/bmcs/:bmcId/testing/:id/:section">
            <TestItem locales={locales} />
          </Route>
        )}

        {ideation && (
          <Route exact path="/bmcs/:bmcId/ideation">
            <IdeationList locales={locales} />
          </Route>
        )}

        {ideation && (
          <Route exact path="/bmcs/:bmcId/ideation/:id">
            <RouteRedirect to="/bmcs/:bmcId/ideation/:id/intro" />
          </Route>
        )}

        {ideation && (
          <Route path="/bmcs/:bmcId/ideation/:id/:section">
            <IdeationItem locales={locales} />
          </Route>
        )}

        {portfolio && (
          <Route path="/bmcs/:bmcId/portfolio">
            <Portfolio locales={locales} />
          </Route>
        )}

        {bmc &&
          bmcSections.map(({ section }) => (
            <Route
              exact
              path={`/bmcs/:bmcId/${section}`}
              key={`${section}-intro`}
            >
              <RouteRedirect to={`/bmcs/:bmcId/${section}/intro`} />
            </Route>
          ))}

        {bmc &&
          bmcSections.map(({ section, component }) => (
            <Route
              path={`/bmcs/:bmcId/${section}/:section`}
              key={`${section}-section`}
            >
              {React.createElement(component, { locales })}
            </Route>
          ))}

        <Route exact path="/account">
          <Account locales={locales} />
        </Route>

        <Route path="/account/personal">
          <Personal locales={locales} />
        </Route>

        <Route path="/account/password">
          <Password locales={locales} />
        </Route>

        <Route path="/account/email">
          <Email locales={locales} />
        </Route>

        <Route path="*" component={Page404} />
      </Switch>
    </Router>
  );
}
