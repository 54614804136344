export const costCalculator = (cost) => {
  switch (cost.period) {
    case 'One Off':
    case 'Yearly':
      return Math.round(cost.amount / 12);
    case 'Monthly':
      return cost.amount;
    case 'Weekly':
      return Math.round(cost.amount * 4.33);
    case 'Daily':
      return Math.round(cost.amount * 30.4);
    default:
      return cost.amount;
  }
};

export const renderMoney = (num) => {
  return new Intl.NumberFormat('en-GB').format(num);
};

export const calculateProgress = (value, max) => (value / max) * 100;
