import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import KeyActivity from '../../keyActivity/Card';
import KeyResources from '../../keyResources/Card';
import {
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  COLOURFUL_TEMPLATE_NAME,
  COMMENT_ACTIVITIES,
  CURRENT_TEMPLATE_NAME,
} from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import { getCurrentCanvasIcon } from '../../../utils/helpers/getCurrentCanvasIcon';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import SvgIcon from '../../../components/SvgIcon';

const KeyActivities = ({
  data: { description },
  locales,
  locales: {
    keyActivity: {
      intro: { card },
    },
    keyResources: {
      intro: { card: resourceCard },
    },
    keyPartners: {
      intro: { card: partnerCard },
    },
    portfolio,
  },
  className,
  sectionHeaderTitleColor,
  descriptionColor,
  styleColor,
  circleActivityColor,
  lineActivityColor,
  circleResourcesColor,
  titleActivityColor,
  textResourcesColor,
  titleResourcesColor,
  textActivityColor,
  backgroundSection,
  firstSvgColor,
  secondSvgColor,
  backgroundActivity,
  backgroundResources,
  borderCardColor,
  templateName,
  disabled = false,
}) => {
  const { collection } = useSelector((state) => state.keyActivity);
  const { collection: partnerCollection } = useSelector(
    (state) => state.keyPartner,
  );
  const { collection: resourcesCollection } = useSelector(
    (state) => state.keyResource,
  );
  const {
    portfolio: { updateSectionField },
  } = useDispatch();

  const isCurrentTemplate = templateName === CURRENT_TEMPLATE_NAME;
  const isColourfulfunTemplate = templateName === COLOURFUL_TEMPLATE_NAME;
  const isArchTemplate = templateName === ARCHITECTURAL_MINIMAL_TEMPLATE_NAME;

  return (
    <div
      className={className}
      style={{ backgroundColor: !isArchTemplate && backgroundSection }}
    >
      {isCurrentTemplate && getCurrentCanvasIcon('KeyThingCanvas', styleColor)}
      {isColourfulfunTemplate && (
        <>
          <div className="svg-funKeyFirst" style={{ fill: firstSvgColor }}>
            <SvgIcon name="funKeyFirst" />
          </div>
          <div className="svg-funKeySecond" style={{ fill: secondSvgColor }}>
            <SvgIcon name="funKeySecond" />
          </div>
        </>
      )}
      <div className="card">
        <PortfolioCommentIcon title={COMMENT_ACTIVITIES} />
        <SectionHeader
          templateName={templateName}
          title={portfolio.keyActivities.sectionName}
          description={description}
          onChange={(e) =>
            updateSectionField({
              sectionName: 'keyActivities',
              field: 'description',
              name: 'operationsText',
              value: e.target.value,
            })
          }
          portfolio={portfolio}
          className={isCurrentTemplate && 'orange-title'}
          style={{ color: sectionHeaderTitleColor }}
          descriptionColor={descriptionColor}
          disabled={disabled}
        />
        <KeyActivity
          locales={locales}
          className="key-activity bmc-page-keycard--portfolio-edit"
          card={{ ...card, steps: collection, title: null }}
          wBlur={false}
          templateName={templateName}
          titleColor={titleActivityColor}
          circleColor={circleActivityColor}
          lineColor={lineActivityColor}
          textColor={textActivityColor}
          background={backgroundActivity}
        />
        <div
          className="key-resources-wrapper"
          style={{ backgroundColor: isArchTemplate && backgroundSection }}
        >
          <KeyResources
            locales={locales}
            className="key-resource"
            card={{ ...partnerCard, list: partnerCollection, title: null }}
            wBlur={false}
            templateName={templateName}
            titleColor={titleResourcesColor}
            circleColor={circleResourcesColor}
            textColor={textResourcesColor}
            background={backgroundResources}
            borderCardColor={borderCardColor}
          />
          <KeyResources
            locales={locales}
            className="key-resource"
            card={{ ...resourceCard, list: resourcesCollection, title: null }}
            wBlur={false}
            templateName={templateName}
            titleColor={titleResourcesColor}
            circleColor={circleResourcesColor}
            textColor={textResourcesColor}
            background={backgroundResources}
            borderCardColor={borderCardColor}
          />
        </div>
      </div>
    </div>
  );
};

export default KeyActivities;
