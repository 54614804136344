import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch, connect } from 'react-redux';

import Intro from './Intro';
import YourPartners from './YourPartners';
import Summary from './Summary';
import Section from '../../components/Section';
import * as AppRoute from '../../utils/appRoutes';
import { prepareComponents } from '../../utils/helpers';
import { select } from '../../rematcher/store';

const components = [Intro, YourPartners, Summary];

function KeyPartners({
  locales,
  locales: {
    keyPartners: { menu, sectionName },
  },
  progress,
}) {
  const {
    keyActivity: { fetchKeyActivities },
    keyPartner: { fetchKeyPartners },
    bmc: { fetchCurrentBmc, updateBmc },
  } = useDispatch();

  const {
    bmc: { current: currentBmc },
  } = useSelector((state) => state);

  const { bmcId } = useParams();
  const preparedComponents = prepareComponents(
    components,
    bmcId,
    menu,
    AppRoute.keyPartners,
  );

  function getData() {
    fetchCurrentBmc(bmcId);
    fetchKeyPartners(bmcId);
    fetchKeyActivities(bmcId);
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (currentBmc.id)
      updateBmc({
        id: currentBmc.id,
        name: 'key_partners_progress',
        value: parseInt(progress),
      });
  }, [progress]);

  return (
    <Section
      components={preparedComponents}
      sectionName={sectionName}
      menuItems={menu}
      progress={progress}
      locales={locales}
      finalDestination={AppRoute.bmc(bmcId)}
      onComponentChange={getData}
    />
  );
}

const mapState = select((models) => ({
  progress: models.progress.keyPartnerProgress,
}));

export default connect(mapState, null)(KeyPartners);
