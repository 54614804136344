import React from 'react';

import TeamCard from './TeamCard';
import {
  BLOG_FRIENDLY_TEMPLATE_NAME,
  COMMENT_TEAM,
} from '../../../../utils/const';
import PortfolioCommentIcon from '../../../../components/PortfolioCommentIcon';
import SectionHeader from '../../../../components/SectionHeader';

const TeamBlog = ({
  data: { description },
  locales,
  locales: { portfolio },
  collection,
  addPortfolioTeammate,
  updateSectionField,
  textColorDependsOnBackground,
  backgroundSection,
  backgroundTeam,
  backgroundModal,
  disabled = false,
}) => {
  return (
    <div
      className="team-blogFriendly section relative"
      style={{ backgroundColor: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_TEAM} />
      <SectionHeader
        templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
        title={portfolio.team.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'team',
            field: 'description',
            name: 'teamDescription',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        descriptionColor={textColorDependsOnBackground}
        style={{ color: textColorDependsOnBackground }}
        disabled={disabled}
      />
      <div className="team-blogFriendly-cards custom-font-body">
        {collection.map((teammate, i) => {
          return (
            <TeamCard
              locales={locales}
              teammate={teammate}
              index={i}
              key={teammate.id}
              backgroundTeam={backgroundTeam}
              backgroundModal={backgroundModal}
              textColor={textColorDependsOnBackground}
              disabled={disabled}
            />
          );
        })}
        {!disabled && (
          <div className="add-team-card-blogFriendly__wrapper custom-font-body">
            <div className="add-team-card-blogFriendly">
              <div className="add-team-card-blogFriendly-hover">
                <div className="center-block" onClick={addPortfolioTeammate}>
                  <i className="icon-Add" />
                  {portfolio.team.add}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamBlog;
