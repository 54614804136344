import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Col, Form, Input, Row, Space, Button } from 'antd';
import Modal from '../../../main/components/Modal';
import ModalContainer from '../../components/ModalContainer';
import ModalHeader from '../../components/ModalHeader';
import DropzoneWithState from '../../components/DropzoneWithState';
import { useFetch } from '../../../main/utils/makeRequest';
import * as Route from '../../support/serverRoutes';
import Select from '../../components/Select';
import SelectEstablishment from '../Selects/SelectEstablishment';
import generatorPassword from '../../../main/utils/generatorPassword';
import passwordValidation from '../../../main/utils/passwordValidation';
import {
  hasAccessChangeClassroom,
  hasAccessResetPassword,
  hasAccessUpdateEmail,
  hasAccessUpdateEstablishment,
  hasAccessUpdateFirstName,
  hasAccessUpdateRole,
  hasAccessUpdateSurname,
  hasAccessUpdateUserImage,
  hasDeleteUser,
} from '../../utils/access';
import ConfirmationDeleteModal from './ConfirmationDeleteModal';
import { Divider } from '../../components/Divider';
import SelectMultipleEstablishments from '../Selects/SelectMultipleEstablishments';

const options = [
  { value: 'admin', label: 'Admin' },
  { value: 'lecturer', label: 'Educator' },
  { value: 'student', label: 'Student' },
];

const ModalUpdateAdminAndLecturer = ({
  userId,
  onClose,
  locales,
  param,
  isAdminLecturerModal,
  reload = () => {},
}) => {
  if (!userId) return <></>;

  const [data, loading] = useFetch({ url: Route.users(userId) });
  const [currentRoleOption, setCurrentRoleOption] = useState({});
  const [defaultEstablishmentOption, setDefaultEstablishmentOption] =
    useState(null);
  const [selectedEstablishmentId, setSelectedEstablishmentId] = useState(null);
  const [currentClassroomValue, setCurrentClassroomValue] = useState(null);
  const [confirmationDeleteVisible, setConfirmationDeleteVisible] =
    useState(false);
  const [avatar, setAvatar] = useState(undefined);

  const [form] = Form.useForm();

  const [establishmentOptions, setEstablishmentOptions] = useState([]);

  const {
    users: { uploading, deleteLoading },
    user,
    classrooms,
    establishments: {
      data: establishmentList,
      loading: establishmentOptionsLoading,
      userEstablishments,
    },
    adminLecturers: { data: adminLecturersList },
  } = useSelector((state) => state);

  const {
    users: { updateUser, deleteUser, updateImage },
    classrooms: {
      fetchParentClassroomsByUser,
      fetchOptionsByUser,
      fetchOptionsByEstablishment,
    },
    blocks: { reload: blocksReload },
    establishments: {
      addEstablishment,
      removeEstablishment,
      setUserEstablishments,
    },
    adminLecturers: { fetchAdminLecturers },
  } = useDispatch();

  useEffect(() => {
    if (!uploading) fetchAdminLecturers(param);
  }, [uploading]);

  useEffect(() => {
    if (adminLecturersList) {
      Object.keys(adminLecturersList).forEach((key) => {
        if (adminLecturersList[key].id === userId)
          setAvatar(adminLecturersList[key].image);
      });
    }
  }, [adminLecturersList]);

  const handleImageChange = (file) => {
    updateImage({
      id: userId,
      image: file,
    });
  };

  const handleChangeRole = (selectedOption) => {
    setCurrentRoleOption(
      options.find((option) => option.value === selectedOption.value),
    );
  };

  useEffect(() => {
    if (establishmentList) {
      setEstablishmentOptions(establishmentList);
    }
  }, [establishmentList]);

  const handleSelect = (establishmentId) => {
    addEstablishment({ establishmentId, userId });
  };

  const handleDelete = (establishmentId) => {
    removeEstablishment({ establishmentId, userId });
  };

  const handleFinish = (values) => {
    const userUpdateArgs = {
      id: userId,
      fields: values,
      setDefaultPassword: values.setDefaultPassword,
    };
    if (selectedEstablishmentId)
      userUpdateArgs.fields.establishmentId = selectedEstablishmentId;
    if (currentRoleOption) userUpdateArgs.fields.role = currentRoleOption.value;
    userUpdateArgs.fields.establishmentId = selectedEstablishmentId;
    if (currentClassroomValue.id)
      userUpdateArgs.classroomId = Number(currentClassroomValue.id);
    if (userUpdateArgs.fields.password === '')
      userUpdateArgs.fields.password = undefined;
    if (currentRoleOption.value === 'admin') {
      userUpdateArgs.establishmentIds = userEstablishments.map((e) => e.id);
    }
    updateUser({
      ...userUpdateArgs,
      afterSuccess: () => {
        reload();
        blocksReload();
      },
    });
    onClose();
  };

  const handleChangeEstablishment = ([id]) => {
    if (data?.attributes?.establishment?.id === id) {
      fetchOptionsByUser({ userId });
    } else if (id) {
      fetchOptionsByEstablishment({ establishmentId: id });
    }
    setSelectedEstablishmentId(id);
  };

  useEffect(() => {
    if (data) {
      const { attributes } = data;
      setCurrentRoleOption(
        options.find((option) => option.value === attributes.role),
      );
      setUserEstablishments({ userEstablishments: attributes.establishments });
      if (attributes?.establishment)
        setDefaultEstablishmentOption({
          value: attributes.establishment.name,
          label: attributes.establishment.name,
          id: attributes.establishment.id,
        });
    }
    fetchParentClassroomsByUser({ userId });
    fetchOptionsByUser({ userId });
  }, [data]);

  useEffect(() => {
    if (classrooms.options.currentValues) {
      setCurrentClassroomValue(classrooms.options.currentValues);
    }
  }, [classrooms]);

  const handleChangeClassroom = (values) => {
    setCurrentClassroomValue(values);
  };

  const setGeneratedPassword = () => {
    form.setFieldsValue({ password: generatorPassword(8) });
  };

  const handleOpenConfirmationModal = () => setConfirmationDeleteVisible(true);

  const handleCloseConfirmationModal = () =>
    setConfirmationDeleteVisible(false);

  const handleDeleteUser = (confirmationPassword) => {
    deleteUser({
      userId,
      confirmationPassword,
      afterSuccess: () => {
        reload();
        blocksReload();
        handleCloseConfirmationModal();
        onClose();
      },
    });
  };

  return (
    <>
      {userId && !loading && currentRoleOption && (
        <Modal onBlackscreenClick={onClose} className="sm">
          <ModalContainer>
            <ModalHeader title="User Settings" onClose={onClose} />
            <Form
              layout="vertical"
              onFinish={handleFinish}
              initialValues={data?.attributes}
              form={form}
            >
              <div className="mt-20">
                <Row gutter={40}>
                  <Col span={14}>
                    <Row gutter={10}>
                      <Col span={12}>
                        <Form.Item
                          label="First Name"
                          className="admin-input-label"
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: 'First name is required',
                            },
                          ]}
                        >
                          <Input
                            placeholder="Add Name of User"
                            className="admin-input"
                            disabled={!hasAccessUpdateFirstName(user)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Surname"
                          className="admin-input-label"
                          name="surname"
                          rules={[
                            {
                              required: true,
                              message: 'Surname is required',
                            },
                          ]}
                        >
                          <Input
                            placeholder="Add surname of User"
                            className="admin-input"
                            disabled={!hasAccessUpdateSurname(user)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={10}>
                      <Col span={24}>
                        <Form.Item
                          label="Email"
                          className="admin-input-label"
                          name="email"
                          rules={[
                            {
                              required: true,
                              type: 'email',
                              message: 'Email is required',
                            },
                          ]}
                        >
                          <Input
                            className="admin-input"
                            disabled={!hasAccessUpdateEmail(user)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={10}>
                      <Col span={10}>
                        <Form.Item
                          label="Password"
                          className="admin-input-label"
                          name="password"
                          disabled={!hasAccessResetPassword(user)}
                          rules={[
                            {
                              validator: passwordValidation,
                            },
                          ]}
                        >
                          <Input
                            className="admin-input"
                            disabled={!hasAccessResetPassword(user)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={14}>
                        <div className="antd-form-item-margin-top">
                          <Button
                            type="primary"
                            onClick={() => setGeneratedPassword()}
                            disabled={!hasAccessResetPassword(user)}
                          >
                            Generate Password
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {(currentRoleOption.value === 'lecturer' ||
                          currentRoleOption.value === 'student') && (
                          <Form.Item
                            label="Establishment"
                            className="admin-input-label"
                            disabled={!hasAccessUpdateEstablishment(user)}
                          >
                            <SelectEstablishment
                              defaultOption={defaultEstablishmentOption}
                              menuPlacement="top"
                              onChange={handleChangeEstablishment}
                              disabled={!hasAccessUpdateEstablishment(user)}
                            />
                          </Form.Item>
                        )}
                        {currentRoleOption.value === 'admin' && (
                          <SelectMultipleEstablishments
                            establishments={establishmentOptions}
                            loading={uploading || establishmentOptionsLoading}
                            labelOfSelect="Invite Admin/Lecturer To Join Your Establishment"
                            placeholderOfSelect="Choose establishment by name"
                            labelOfList="Establishments"
                            selectedIds={userEstablishments.map(
                              (establishment) => establishment.id,
                            )}
                            onSelect={handleSelect}
                            onDelete={handleDelete}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={10}>
                    <Row>
                      <Form.Item
                        label="Avatar"
                        className="admin-input-label"
                        name="file"
                        disabled={!hasAccessUpdateUserImage(user)}
                      >
                        <div className="dropzone-size">
                          <DropzoneWithState
                            onChange={handleImageChange}
                            locales={locales}
                            initUrl={
                              avatar
                                ? avatar.url
                                : data?.attributes?.image?.original
                            }
                            disabled={!hasAccessUpdateUserImage(user)}
                          />
                        </div>
                      </Form.Item>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item label="Role" className="admin-input-label">
                          <Select
                            options={options}
                            value={currentRoleOption}
                            onChange={handleChangeRole}
                            disabled={!hasAccessUpdateRole(user)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item label="Class" className="admin-input-label">
                          <Select
                            options={classrooms.options.collection}
                            value={currentClassroomValue}
                            onChange={handleChangeClassroom}
                            disabled={
                              !hasAccessChangeClassroom(user) ||
                              currentRoleOption.value !== 'student'
                            }
                            menuPlacement="top"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="mt-20">
                  <Divider />
                </div>
                <div className="mt-20">
                  <Row justify="space-between" align="middle">
                    {hasDeleteUser(data, user) && (
                      <Col>
                        <div
                          className={classNames('delete-title', {
                            'delete-title--disabled': deleteLoading,
                          })}
                          onClick={handleOpenConfirmationModal}
                        >
                          Delete
                        </div>
                      </Col>
                    )}
                    {isAdminLecturerModal && (
                      <Col>
                        <Button
                          type="text"
                          onClick={handleOpenConfirmationModal}
                          danger
                        >
                          Delete
                        </Button>
                      </Col>
                    )}
                    <Col>
                      <Space>
                        <Button
                          onClick={onClose}
                          disabled={uploading || deleteLoading}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={uploading}
                          disabled={deleteLoading}
                        >
                          Save
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </div>
            </Form>
          </ModalContainer>
        </Modal>
      )}
      <ConfirmationDeleteModal
        loading={deleteLoading}
        visible={confirmationDeleteVisible}
        onClose={handleCloseConfirmationModal}
        onConfirm={handleDeleteUser}
      />
    </>
  );
};

ModalUpdateAdminAndLecturer.propTypes = {
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.number,
};
export default ModalUpdateAdminAndLecturer;
