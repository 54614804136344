import React from 'react';
import { Table as AntTable } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from './Pagination';
import TableTitle from './TableTitle';

function Table(props) {
  const { rowClickable, onChange, total, current, columns, meta, onSort } =
    props;

  const {
    pagination: { pageSize, pageSizeOptions },
  } = useSelector((state) => state);

  const {
    pagination: { setPageSize },
  } = useDispatch();

  const handleChangePageSize = (values) => {
    // values = {pageSize, page}
    setPageSize(values.pageSize);
    onChange({ current: values.page, pageSize: values.pageSize });

    const section = document.querySelector('.admin-tabs');
    section.scrollIntoView({ block: 'start' });
  };

  return (
    <div>
      <AntTable
        className="admin-table"
        rowClassName={`admin-table__row ${
          rowClickable ? 'ant-table-clickable' : ''
        }`}
        rowKey="id"
        {...props}
        columns={columns.map((column) => {
          if (column === AntTable.EXPAND_COLUMN) return column;
          return {
            ...column,
            title: <TableTitle {...column} meta={meta} onSort={onSort} />,
          };
        })}
        pagination={false}
      />
      {total > 0 && (
        <div className="pagination-position">
          <Pagination
            total={total}
            current={current}
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            onChange={handleChangePageSize}
          />
        </div>
      )}
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.array,
  expandable: PropTypes.shape({
    expandedRowRender: PropTypes.func, // args: (record: object, index: number, index: number, expanded: boolean)
    expandIcon: PropTypes.func, // args: ({ expanded, onExpand, record })
  }),
  dataSource: PropTypes.array,
  total: PropTypes.number,
  current: PropTypes.number,
  loading: PropTypes.bool,
  onChange: PropTypes.func, // args (pagination: any, filters: any, sorter: any)
  rowKey: PropTypes.string,
  onRow: PropTypes.func,
  rowClickable: PropTypes.bool,
  onSort: PropTypes.func,
  meta: PropTypes.object,
};
export default Table;
