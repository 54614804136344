import React from 'react';

import Menu from './Menu';
import BmcCanvasContainer from '../bmc/BmcCanvasContainer';

export default function Section({ locales, children, menuItems }) {
  return (
    <BmcCanvasContainer locales={locales}>
      <div className="bmc-account-container">
        <div className="bmc-account-header">{locales.account.name}</div>

        <Menu menuItems={menuItems} />

        {children}
      </div>
    </BmcCanvasContainer>
  );
}
