import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import CommentListContainer from './comment-list-container';
import CommentItemContainer from './comment-item-container';
import CommentInputContainer from './comment-input-container';
import CommentListHeaderContainer from './comment-list-header-container';
import CommentAppBarContainer from './comment-app-bar-container';
import { CommentContext } from '../context';
import sortMemberList from '../utils/sortMemberList';

export default function CommentContainer({ comments = [], members = [] }) {
  const {
    chatGroup: { selectedChatGroup, currentUser },
  } = useSelector((selector) => selector);

  const [commentState, setCommentState] = useState({
    scrollStopAt: new Date(),
    parentElement: null,
    currentMember: null,
    replyComment: null,
    editComment: null,
    activeAt: null,
    savedActiveAt: null,
  });

  const {
    chatGroup: { createComment, updateComment },
  } = useDispatch();

  const handleSend = ({ value, mentionData }) => {
    const createCommentArg = {
      message: value,
      chatGroupId: selectedChatGroup.id,
      mentions: mentionData.mentions.map((mention) => mention.id),
    };

    if (commentState.replyComment) {
      Object.assign(createCommentArg, {
        commentId: commentState.replyComment.id,
      });
    }

    if (commentState.editComment) {
      updateComment({
        ...createCommentArg,
        id: commentState.editComment.id,
      }).then(() => {
        setCommentState((currentCommentState) => ({
          ...currentCommentState,
          editComment: null,
        }));
      });
    } else {
      createComment(createCommentArg).then(() => {
        setCommentState((currentCommentState) => ({
          ...currentCommentState,
          replyComment: null,
        }));
      });

      setTimeout(() => {
        const element = document.getElementById('comment-list-component');
        element.scrollTop = element.scrollHeight;
      }, 500);
    }

    setCommentState((currentState) => ({
      ...currentState,
      savedActiveAt: moment(),
    }));
  };

  useEffect(() => {
    if (currentUser)
      setCommentState((currentState) => ({
        ...currentState,
        currentMember: members.find((member) => {
          return member.userId === currentUser.id;
        }),
      }));
  }, [JSON.stringify(currentUser), JSON.stringify(members)]);

  useEffect(() => {
    if (currentUser) {
      const newActiveAt = members.find((member) => {
        return member.userId === currentUser.id;
      })?.activeAt;

      setCommentState((currentState) => ({
        ...currentState,
        activeAt: newActiveAt,
        savedActiveAt: currentState.savedActiveAt || newActiveAt,
      }));
    }
  }, []);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (commentState.currentMember) {
        setCommentState((currentCommentState) => {
          return {
            ...currentCommentState,
            activeAt: currentCommentState.currentMember.activeAt,
          };
        });
      }
    }, 5000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [commentState.currentMember]);

  useEffect(() => {
    setTimeout(() => {
      const element =
        document.getElementsByClassName('status-new')[0] ||
        document.getElementById('comment-list-component');
      if (element && element.scrollTop === 0) {
        element.scrollTop = element.scrollHeight;
      }
      element.scrollIntoView(true);
    }, 500);
  }, []);

  return (
    <div className="comment-container">
      <CommentContext.Provider value={{ commentState, setCommentState }}>
        <CommentAppBarContainer />
        <CommentListHeaderContainer />
        <CommentListContainer>
          {comments.map((comment, index) => (
            <CommentItemContainer
              key={comment.id}
              comment={comment}
              members={members}
              isLastComment={index === comments.length - 1}
              nextComment={
                index === comments.length - 1 ? null : comments[index + 1]
              }
            />
          ))}
        </CommentListContainer>
        <CommentInputContainer
          members={sortMemberList(
            members.filter((member) => member.role !== 'super_admin'),
          )}
          onSend={handleSend}
        />
      </CommentContext.Provider>
    </div>
  );
}
