import {
  Paragraph,
  Table,
  TableRow,
  TableCell,
  AlignmentType,
  WidthType,
  HeightRule,
  TableAnchorType,
  OverlapType,
  TableLayoutType,
  PageBreak,
} from 'docx';

import {
  pageSize,
  pageMargin,
  convertPixelToTwip,
  COLORS,
  transparentBorder,
  tableWidth,
  imageRunColourful,
} from '../Constants';
import {
  buildColourfulDecorateImg,
  buildSegmentItemImage,
  emptyCell,
  marginTableRow,
  textRun,
} from '../helpers/index';

export default async (locales, portfolio, customerSegment, options) => {
  const getTopBgImg = async () => {
    if (options.segment?.topImgBg) {
      const img = await buildColourfulDecorateImg(
        options.segment.topImgBg,
        options.segment.topImgColor,
      );

      return new Paragraph({
        children: [imageRunColourful(img, 138, 149, 24, 0, 'paragraph')],
      });
    }
  };

  const buildSegmentsTables = async () => {
    const tables = await Promise.all(
      customerSegment.collection.map(async (segment) => {
        return [
          new Table({
            float: {
              horizontalAnchor: TableAnchorType.TEXT,
              verticalAnchor: TableAnchorType.TEXT,
              overlap: OverlapType.NEVER,
            },
            width: {
              size: convertPixelToTwip(tableWidth - 56),
              type: WidthType.DXA,
            },
            layout: TableLayoutType.AUTOFIT,
            rows: [
              // table with segments
              new TableRow({
                height: {
                  value: convertPixelToTwip(68),
                  rule: HeightRule.ATLEAST,
                },
                children: [
                  new TableCell({
                    width: {
                      size: convertPixelToTwip(tableWidth - 56),
                      type: WidthType.DXA,
                    },
                    columnSpan: 3,
                    borders: transparentBorder(COLORS.WHITE),
                    children: [
                      new Table({
                        float: {
                          horizontalAnchor: TableAnchorType.TEXT,
                          verticalAnchor: TableAnchorType.TEXT,
                          overlap: OverlapType.NEVER,
                        },
                        width: {
                          size: convertPixelToTwip(753),
                          type: WidthType.DXA,
                        },
                        layout: TableLayoutType.AUTOFIT,
                        rows: [
                          new TableRow({
                            height: {
                              value: convertPixelToTwip(24),
                              rule: HeightRule.ATLEAST,
                            },
                            children: [
                              // cell with image
                              new TableCell({
                                width: {
                                  size: convertPixelToTwip(285),
                                  type: WidthType.DXA,
                                },
                                borders: transparentBorder(COLORS.WHITE),
                                children: await buildSegmentItemImage(
                                  segment.image.original,
                                  options.titleColor,
                                  options.titleFont,
                                  'CUSTOMER SEGMENT',
                                  options.segment,
                                ),
                              }),

                              // cell for margin
                              emptyCell(30, COLORS.WHITE),

                              // cell with data
                              new TableCell({
                                width: {
                                  size: convertPixelToTwip(448),
                                  type: WidthType.DXA,
                                },
                                borders: transparentBorder(COLORS.WHITE),
                                children: [
                                  new Table({
                                    float: {
                                      horizontalAnchor: TableAnchorType.TEXT,
                                      verticalAnchor: TableAnchorType.TEXT,
                                      overlap: OverlapType.NEVER,
                                    },
                                    width: {
                                      size: convertPixelToTwip(448),
                                      type: WidthType.DXA,
                                    },
                                    layout: TableLayoutType.AUTOFIT,
                                    rows: [
                                      // title row
                                      new TableRow({
                                        height: {
                                          value: convertPixelToTwip(24),
                                          rule: HeightRule.ATLEAST,
                                        },
                                        children: [
                                          new TableCell({
                                            width: {
                                              size: convertPixelToTwip(448),
                                              type: WidthType.DXA,
                                            },
                                            borders: transparentBorder(
                                              COLORS.WHITE,
                                            ),
                                            children: [
                                              new Paragraph({
                                                children: [
                                                  textRun(segment.name, {
                                                    size: 20,
                                                    allCaps: true,
                                                    bold: true,
                                                    underline: true,
                                                    font: options.bodyFont,
                                                    color:
                                                      options.titleColor ||
                                                      COLORS.BLACK,
                                                  }),
                                                ],
                                              }),
                                            ],
                                          }),
                                        ],
                                      }),

                                      marginTableRow(25, 448, COLORS.WHITE, 1),

                                      // age range & type of segment
                                      new TableRow({
                                        height: {
                                          value: convertPixelToTwip(19),
                                          rule: HeightRule.ATLEAST,
                                        },
                                        children: [
                                          new TableCell({
                                            width: {
                                              size: convertPixelToTwip(448),
                                              type: WidthType.DXA,
                                            },
                                            borders: transparentBorder(
                                              COLORS.WHITE,
                                            ),
                                            children: [
                                              new Table({
                                                rows: [
                                                  new TableRow({
                                                    children: [
                                                      new TableCell({
                                                        width: {
                                                          size: convertPixelToTwip(
                                                            224,
                                                          ),
                                                          type: WidthType.DXA,
                                                        },
                                                        borders:
                                                          transparentBorder(
                                                            COLORS.WHITE,
                                                          ),
                                                        children: [
                                                          new Paragraph({
                                                            children: [
                                                              textRun(
                                                                'Age range',
                                                                {
                                                                  size: 16,
                                                                  allCaps: true,
                                                                  bold: true,
                                                                  font: options.bodyFont,
                                                                  color:
                                                                    options
                                                                      .segment
                                                                      ?.subtitleColor ||
                                                                    options.bodyColor ||
                                                                    COLORS.BLACK,
                                                                },
                                                              ),
                                                            ],
                                                          }),
                                                          new Paragraph({
                                                            spacing: {
                                                              before:
                                                                convertPixelToTwip(
                                                                  5,
                                                                ),
                                                            },
                                                            children: [
                                                              textRun(
                                                                `${segment.ageRange.min} - ${segment.ageRange.max}`,
                                                                {
                                                                  size: 14,
                                                                  font: options.bodyFont,
                                                                  color:
                                                                    options.bodyColor ||
                                                                    COLORS.BLACK,
                                                                },
                                                              ),
                                                            ],
                                                          }),
                                                        ],
                                                      }),

                                                      new TableCell({
                                                        width: {
                                                          size: convertPixelToTwip(
                                                            224,
                                                          ),
                                                          type: WidthType.DXA,
                                                        },
                                                        borders:
                                                          transparentBorder(
                                                            COLORS.WHITE,
                                                          ),
                                                        children: [
                                                          new Paragraph({
                                                            children: [
                                                              textRun(
                                                                'Type of Segment',
                                                                {
                                                                  size: 16,
                                                                  allCaps: true,
                                                                  bold: true,
                                                                  font: options.bodyFont,
                                                                  color:
                                                                    options
                                                                      .segment
                                                                      ?.subtitleColor ||
                                                                    options.bodyColor ||
                                                                    COLORS.BLACK,
                                                                },
                                                              ),
                                                            ],
                                                          }),
                                                          new Paragraph({
                                                            spacing: {
                                                              before:
                                                                convertPixelToTwip(
                                                                  5,
                                                                ),
                                                            },
                                                            children: [
                                                              textRun(
                                                                segment.type,
                                                                {
                                                                  size: 14,
                                                                  font: options.bodyFont,
                                                                  color:
                                                                    options.bodyColor ||
                                                                    COLORS.BLACK,
                                                                },
                                                              ),
                                                            ],
                                                          }),
                                                        ],
                                                      }),
                                                    ],
                                                  }),
                                                ],
                                              }),
                                            ],
                                          }),
                                        ],
                                      }),

                                      marginTableRow(15, 448, COLORS.WHITE, 1),

                                      // description
                                      new TableRow({
                                        children: [
                                          new TableCell({
                                            width: {
                                              size: convertPixelToTwip(448),
                                              type: WidthType.DXA,
                                            },
                                            borders: transparentBorder(
                                              COLORS.WHITE,
                                            ),
                                            children: [
                                              new Paragraph({
                                                children: [
                                                  textRun('Description', {
                                                    size: 16,
                                                    allCaps: true,
                                                    bold: true,
                                                    font: options.bodyFont,
                                                    color:
                                                      options.segment
                                                        ?.subtitleColor ||
                                                      options.bodyColor ||
                                                      COLORS.BLACK,
                                                  }),
                                                ],
                                              }),
                                              new Paragraph({
                                                spacing: {
                                                  before: convertPixelToTwip(5),
                                                },
                                                children: [
                                                  textRun(segment.description, {
                                                    size: 14,
                                                    font: options.bodyFont,
                                                    color:
                                                      options.bodyColor ||
                                                      COLORS.BLACK,
                                                  }),
                                                ],
                                              }),
                                            ],
                                          }),
                                        ],
                                      }),

                                      marginTableRow(15, 448, COLORS.WHITE, 1),

                                      // What are their goals?
                                      new TableRow({
                                        children: [
                                          new TableCell({
                                            width: {
                                              size: convertPixelToTwip(448),
                                              type: WidthType.DXA,
                                            },
                                            borders: transparentBorder(
                                              COLORS.WHITE,
                                            ),
                                            children: [
                                              new Paragraph({
                                                children: [
                                                  textRun(
                                                    'What are their goals?',
                                                    {
                                                      size: 16,
                                                      allCaps: true,
                                                      bold: true,
                                                      font: options.bodyFont,
                                                      color:
                                                        options.segment
                                                          ?.subtitleColor ||
                                                        options.bodyColor ||
                                                        COLORS.BLACK,
                                                    },
                                                  ),
                                                ],
                                              }),
                                              new Paragraph({
                                                spacing: {
                                                  before: convertPixelToTwip(5),
                                                },
                                                children: [
                                                  textRun(segment.goals, {
                                                    size: 14,
                                                    font: options.bodyFont,
                                                    color:
                                                      options.bodyColor ||
                                                      COLORS.BLACK,
                                                  }),
                                                ],
                                              }),
                                            ],
                                          }),
                                        ],
                                      }),

                                      marginTableRow(15, 448, COLORS.WHITE, 1),

                                      // What frustrates them?
                                      new TableRow({
                                        children: [
                                          new TableCell({
                                            width: {
                                              size: convertPixelToTwip(448),
                                              type: WidthType.DXA,
                                            },
                                            borders: transparentBorder(
                                              COLORS.WHITE,
                                            ),
                                            children: [
                                              new Paragraph({
                                                children: [
                                                  textRun(
                                                    'What frustrates them?',
                                                    {
                                                      size: 16,
                                                      allCaps: true,
                                                      bold: true,
                                                      font: options.bodyFont,
                                                      color:
                                                        options.segment
                                                          ?.subtitleColor ||
                                                        options.bodyColor ||
                                                        COLORS.BLACK,
                                                    },
                                                  ),
                                                ],
                                              }),
                                              new Paragraph({
                                                spacing: {
                                                  before: convertPixelToTwip(5),
                                                },
                                                children: [
                                                  textRun(segment.frustrates, {
                                                    size: 14,
                                                    font: options.bodyFont,
                                                    color:
                                                      options.bodyColor ||
                                                      COLORS.BLACK,
                                                  }),
                                                ],
                                              }),
                                            ],
                                          }),
                                        ],
                                      }),

                                      marginTableRow(15, 448, COLORS.WHITE, 1),

                                      // What problem are you solving for them?
                                      new TableRow({
                                        children: [
                                          new TableCell({
                                            width: {
                                              size: convertPixelToTwip(448),
                                              type: WidthType.DXA,
                                            },
                                            borders: transparentBorder(
                                              COLORS.WHITE,
                                            ),
                                            children: [
                                              new Paragraph({
                                                children: [
                                                  textRun(
                                                    'What problem are you solving for them?',
                                                    {
                                                      size: 16,
                                                      allCaps: true,
                                                      bold: true,
                                                      font: options.bodyFont,
                                                      color:
                                                        options.segment
                                                          ?.subtitleColor ||
                                                        options.bodyColor ||
                                                        COLORS.BLACK,
                                                    },
                                                  ),
                                                ],
                                              }),
                                              new Paragraph({
                                                spacing: {
                                                  before: convertPixelToTwip(5),
                                                },
                                                children: [
                                                  textRun(segment.problems, {
                                                    size: 14,
                                                    font: options.bodyFont,
                                                    color:
                                                      options.bodyColor ||
                                                      COLORS.BLACK,
                                                  }),
                                                ],
                                              }),
                                            ],
                                          }),
                                        ],
                                      }),
                                    ],
                                  }),
                                ],
                              }),
                            ],
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
          new Paragraph({ children: [new PageBreak()] }),
        ];
      }),
    );

    return tables.flat(1);
  };

  const segments = await buildSegmentsTables();

  return {
    properties: {
      page: {
        size: pageSize,
        margin: pageMargin,
      },
    },
    children: [
      new Table({
        float: {
          horizontalAnchor: TableAnchorType.TEXT,
          verticalAnchor: TableAnchorType.TEXT,
          overlap: OverlapType.NEVER,
          absoluteVerticalPosition: convertPixelToTwip(0),
          absoluteHorizontalPosition: 0,
        },
        width: {
          size: convertPixelToTwip(tableWidth - 56),
          type: WidthType.DXA,
        },
        layout: TableLayoutType.AUTOFIT,
        rows: [
          // title
          new TableRow({
            height: {
              value: convertPixelToTwip(27),
              rule: HeightRule.ATLEAST,
            },
            children: [
              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth - 56),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.PRIMARY),
                columnSpan: 3,
                children: [
                  // Title
                  new Paragraph({
                    text: '',
                    alignment: AlignmentType.CENTER,

                    children: [
                      textRun(locales.customerSegment.sectionName, {
                        font: options.titleFont,
                        bold: options?.segment?.titleBold,
                        color: options.titleColor || COLORS.BLACK,
                        size: 24,
                        allCaps: true,
                      }),
                      await getTopBgImg(),
                    ],
                  }),
                ],
              }),
            ],
          }),
          marginTableRow(10, tableWidth - 56, COLORS.WHITE, 3),
          // description row
          new TableRow({
            height: {
              value: convertPixelToTwip(68),
              rule: HeightRule.ATLEAST,
            },
            children: [
              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth - 56),
                  type: WidthType.DXA,
                },
                columnSpan: 3,
                borders: transparentBorder(COLORS.WHITE),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      textRun(portfolio.customerSegment.description, {
                        font: options.bodyFont,
                        color: options.bodyColor || COLORS.BLACK,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      ...segments,
    ],
  };
};
