import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Header from '../../../../../../components/Header';
import Text from '../../../../../../components/Text';
import ResponseCard from '../../../../Components/ResponseCard';
import Title from '../../../../../../components/Title';
import List from '../../../../../../components/List';
import Line from '../../../../../../components/Line';
import Tabs from '../../../../../../components/Tabs';
import TabsList from '../../../../../../components/TabsList';
import TabsListItem from '../../../../../../components/TabsListItem';
import Textarea from '../../../../../../components/Textarea';
import CheckBox from '../../../../Components/CheckBox';
import TabsCut from '../../../../../../components/TabsCut';
import Actions from '../../../../../../components/Actions';
import SvgIcon from '../../../../../../components/SvgIcon';
import MobileMenuHeader from '../../../../../../components/MobileMenuHeader';

const icons = ['text', 'ok'];
const iconsComponents = icons.map((e) => <SvgIcon name={e} />);

const HomepageAnalyse = ({
  nextClickHandler,
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
  testObject,
  locales,
  locales: {
    common,
    testing: {
      caseStudies,
      name,
      testAnalyse: {
        header,
        homepage: { text, card, title, list, tabs, actions },
      },
    },
  },
}) => {
  const {
    testing: { updateTest },
  } = useDispatch();
  const onChange = (e) => {
    updateTest({
      id: testObject.id,
      test: {
        analyse: {
          ...testObject.analyse,
          resultsAndLearning: e.target.value,
        },
      },
    });
  };

  const onCheck = (e) => {
    const { name: eName } = e.target;

    const updatedTest = {
      analyse: {
        ...testObject.analyse,
        status: eName,
      },
    };
    updateTest({ id: testObject.id, test: updatedTest });
  };
  const [opened, setOpened] = useState(false);

  return (
    <div className="bmc-page testing">
      <Header
        className="bmc-page-header--blue"
        subtitle={name}
        icon="icon-VP"
        locales={locales}
        title={header}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        componentIndex={componentIndex}
        mobileMenuHeader={
          <MobileMenuHeader icon="testingActive" title={name} color="blue" />
        }
        rules={rules}
        backPath={backPath}
      />
      <Text>{[text[0], text[1], text[2]]}</Text>

      <Title align="left" text={title[0]} />

      <Text>{[text[3]]}</Text>

      <ResponseCard hideAnalyse testId={testObject.id} card={card} />

      <Title align="left" text={title[1]} />

      <List items={list} icons={icons} />

      <Line />
      <h2 className="bmc-page-title">
        <span className="bmc-page-title--blue">{title[2]}</span> {title[3]}
      </h2>

      <Text align="center">{[text[4]]}</Text>

      <Tabs
        tabs={[common.yourStrategy, common.caseStudy]}
        withBlur
        className="margin-top-60 bmc-page-tabs--blue"
      >
        {[
          <div key="strategy">
            <TabsList formStyle>
              <TabsListItem
                title={tabs.resultsAndLearning}
                subtitle={tabs.resultsAndLearningSub}
                icon={iconsComponents[0]}
                text={
                  // eslint-disable-next-line
                  <>
                    <Textarea
                      value={testObject.analyse.resultsAndLearning}
                      onChange={onChange}
                      placeholder="E.g. From the 12 responses to our Homepage we learnt that..."
                      rows={4}
                      delay={700}
                    />
                  </>
                }
              />
            </TabsList>
            <TabsList formStyle>
              <TabsListItem
                title={tabs.assumptionStatus}
                subtitle={tabs.assumptionStatusSub}
                icon={iconsComponents[1]}
                text={
                  // eslint-disable-next-line
                  <div className="checkbox-wrapper column">
                    {['valid', 'invalid', 'unsure'].map((e) => (
                      <CheckBox
                        isChecked={testObject.analyse.status === e}
                        onCheck={onCheck}
                        key={e}
                        title={e}
                        name={e}
                      />
                    ))}
                  </div>
                }
              />
            </TabsList>
          </div>,
          <Tabs
            key="tabs"
            tabs={caseStudies.map((e) => e.name)}
            className="bmc-page-tabs--black bmc-page-tabs--inner"
          >
            {caseStudies.map((item, index) => (
              <div key={index}>
                <TabsCut
                  setOpened={setOpened}
                  opened={opened}
                  caseStudy={item}
                />

                <TabsListItem
                  title={tabs.resultsAndLearning}
                  subtitle={tabs.resultsAndLearningSub}
                  text={item.homepageResult.text}
                  icon={iconsComponents[0]}
                />
                <TabsListItem
                  title={tabs.assumptionStatus}
                  subtitle={tabs.assumptionStatusSub}
                  icon={iconsComponents[1]}
                >
                  <div className="bmc-page-tabs-content-list-item-text">
                    <div className="checkbox-wrapper column">
                      {['valid', 'invalid', 'unsure'].map((e) => (
                        <CheckBox
                          isChecked={item.homepageResult.status === e}
                          key={e}
                          isPreview
                          title={e}
                        />
                      ))}
                    </div>
                  </div>
                </TabsListItem>
              </div>
            ))}
          </Tabs>,
        ]}
      </Tabs>
      <Actions
        nextClickHandler={nextClickHandler}
        buttonName={common.next}
        tooltipIfDisabled={actions.tooltipIfDisabled}
      />
    </div>
  );
};

export default HomepageAnalyse;
