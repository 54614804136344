import React from 'react';
import { useSelector } from 'react-redux';
import { removeNewLines } from '../../main/utils/helpers';
import {
  PORTFOLIO_INTRO_BG_ASPECT_RATIO,
  BOLD_TEMPLATE_NAME,
} from '../../main/utils/const';
import { renderLogo } from '../../main/utils/helpers/renderLogo';
import { renderImage } from '../../main/utils/helpers/renderImage';

const IntroductionBoldWeb = ({
  titleColor,
  textColor,
  backgroundSection,
  backgroundImage,
}) => {
  const { introduction } = useSelector((state) => state.portfolio);
  return (
    <div
      className="introduction-bold-web section-web"
      style={{ backgroundColor: backgroundSection }}
    >
      <div className="introduction-bold-web-header">
        {renderLogo(
          BOLD_TEMPLATE_NAME,
          introduction.introLogo,
          backgroundImage,
          'Logo',
          titleColor,
        )}
        <div className="introduction-bold-web-image">
          {renderImage(
            BOLD_TEMPLATE_NAME,
            introduction.introBg,
            backgroundImage,
            'Introduction',
            PORTFOLIO_INTRO_BG_ASPECT_RATIO,
            titleColor,
          )}
        </div>
        <div className="company-wrapper">
          <div
            className="bold-title-web title custom-font-title"
            style={{ color: titleColor }}
          >
            {introduction.title}
          </div>
          <div
            className="description custom-font-body"
            style={{ color: textColor }}
          >
            {introduction.description &&
              removeNewLines(introduction.description)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroductionBoldWeb;
