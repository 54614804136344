import React from 'react';
import { useSelector } from 'react-redux';

import Header from '../../components/Header';
import Text from '../../components/Text';
import Title from '../../components/Title';
import Card from './Card';
import Line from '../../components/Line';
import Actions from '../../components/Actions';
import MobileMenuHeader from '../../components/MobileMenuHeader';

const Summary = ({
  nextClickHandler,
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
  locales,
  locales: {
    common,
    tipCustomerSegment,
    customerSegment: {
      name,
      summary: { header, title, text },
      intro: { card },
    },
  },
}) => {
  const {
    customerSegment: { collection },
  } = useSelector((state) => state);

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon="icon-customer-segment"
        locales={locales}
        componentIndex={componentIndex}
        tipLocales={tipCustomerSegment}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="CSCanvas" title={name} color="green" />
        }
      />

      <Text>{[text[0]]}</Text>

      <Line />

      <Title text={title[0]} />

      {collection.map((customerSegment, index) => (
        <Card
          className="margin-top-60 margin-wide-40-lg"
          key={index}
          locales={locales}
          dropzoneMode
          card={{
            ...card,
            ...customerSegment,
            subtitle: customerSegment.name,
            title: null,
          }}
        />
      ))}

      <Actions nextClickHandler={nextClickHandler} buttonName={common.done} />
    </div>
  );
};

export default Summary;
