import React from 'react';
import { useSelector } from 'react-redux';
import useMediaQuery from 'react-use-media-query-hook';

import {
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  IMAGE_ASPECT_RATIO_ONE,
  IMAGE_ASPECT_RATIO_VP_ARCH,
} from '../../main/utils/const';
import { renderImage } from '../../main/utils/helpers/renderImage';
import { renderSegmentList } from '../../main/utils/helpers/renderSegmentList';
import { renderText } from '../../main/utils/helpers/renderText';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import { getColorDependsOnBackgroundAndPalette } from '../../main/utils/helpers/portfolioArchTemplate/getColorDependsOnBackgroundAndPalette';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';
import { getImageAspectRatio } from '../../main/utils/helpers/getImageAspectRatio';

const ValueProposition = ({
  locales,
  locales: {
    valueProposition: {
      intro: { card },
    },
  },
  backgroundSection,
  backgroundImage,
  colorPaletteNumber,
}) => {
  const { portfolio } = useSelector((state) => state);
  const {
    valueProposition: { product, reason, image },
    uniqueSellingPoint: { collection },
  } = useSelector((state) => state);

  const isSmallDesktop = useMediaQuery('(max-width: 1024px)');

  const textColor = getColorDependsOnBackgroundAndPalette(
    colorPaletteNumber,
    backgroundSection,
  );

  const textImageColor = getColorDependsOnBackground(backgroundImage);

  return (
    <div
      className="value-propositions-architectural-web"
      style={{ backgroundColor: backgroundSection }}
    >
      <div className="left">
        <SectionHeaderWeb
          templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
          title={locales.portfolioWeb.valueProposition}
          description={portfolio.valueProposition.description}
          portfolioWeb={locales.portfolioWeb}
          colorPaletteNumber={colorPaletteNumber}
          style={{ color: textColor }}
          descriptionColor={textColor}
        />
        {renderSegmentList(card.solutionTitle, textColor)}
        {renderText(product, textColor)}
        {renderSegmentList(card.featuresTitle, textColor)}
        {renderText(reason, textColor)}
        {renderSegmentList(card.pointsTitle, textColor)}
        {renderText(
          collection.map((point, index) => (
            <div className="margin-bottom-10" key={point.id || index}>
              {point.description}
            </div>
          )),
          textColor,
        )}
      </div>
      <div className="right">
        <div className="image">
          {renderImage(
            ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
            image,
            backgroundImage,
            locales.portfolioWeb.valueProposition,
            getImageAspectRatio(
              isSmallDesktop,
              IMAGE_ASPECT_RATIO_ONE,
              IMAGE_ASPECT_RATIO_VP_ARCH,
            ),
            textImageColor,
          )}
        </div>
      </div>
    </div>
  );
};

export default ValueProposition;
