import {
  pageSize,
  noPageMargin,
  getPageBg,
  getIntroLogo,
  titleDescription,
} from '../Constants';
import { buildMainImg } from '../helpers/index';

export default async (locales, portfolio, options = {}) => {
  return {
    properties: {
      page: {
        size: pageSize,
        margin: noPageMargin,
      },
    },
    children: [
      // Page background
      getPageBg(options.mainPage?.pageBg),
      // Logo
      await getIntroLogo(
        options.mainPage,
        portfolio.introduction.introLogo?.cropped,
      ),
      // Image
      await buildMainImg(
        portfolio.introduction.introBg.original,
        options,
        portfolio,
      ),

      titleDescription(
        options,
        portfolio.introduction.title,
        portfolio.introduction.description,
      ),
    ],
  };
};
