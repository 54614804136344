import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import Header from '../../components/Header';
import Tabs from '../../components/Tabs';
import Textarea from '../../components/Textarea';
import Actions from '../../components/Actions';
import Button from '../../components/Button';
import List from '../../components/List';
import Line from '../../components/Line';
import Text from '../../components/Text';
import Title from '../../components/Title';
import { SUSTAINABILITY_MAX_COUNT, SELECT_STYLES } from '../../utils/const';
import RemoveButton from '../../components/RemoveButton';
import Goals from './Goals';
import ConfirmationModal from '../../components/ConfirmationModal';
import TabsCut from '../../components/TabsCut';
import TabsList from '../../components/TabsList';
import TabsListItem from '../../components/TabsListItem';
import SvgIcon from '../../components/SvgIcon';
import { selectLabel, selectValue } from '../../utils/helpers';
import MobileMenuHeader from '../../components/MobileMenuHeader';

const fields = ['sdg', 'problem', 'value'];
const icons = ['angryFace', 'happyFace'];
const iconsComponents = ['goal', 'angryFace', 'happyFace'].map((e) => (
  <SvgIcon name={e} />
));

export default function Strategy(props) {
  const {
    nextClickHandler,
    menuItems,
    selectComponent,
    componentIndex,
    rules,
    backPath,
    locales,
    locales: {
      common,
      tipCustomerSegment,
      caseStudies,
      sustainability: {
        name,
        strategy: { header, title, text, list, tabs },
        sdgs,
      },
    },
  } = props;
  const { collection } = useSelector((state) => state.sustainability);
  const {
    sustainability: {
      addSustainability,
      updateSustainability,
      removeSustainability,
    },
  } = useDispatch();
  const sustainabilitySdgOptions = sdgs.map((e, index) => ({
    label: e.title,
    value: (index + 1).toString(),
  }));
  const [opened, setOpened] = useState(false);
  const mobileHeader = (
    <MobileMenuHeader icon="SustainabilityCanvas" title={name} color="violet" />
  );

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon="icon-sustainability"
        locales={locales}
        componentIndex={componentIndex}
        className="bmc-page-header--violet"
        tipLocales={tipCustomerSegment}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        mobileMenuHeader={mobileHeader}
        rules={rules}
        backPath={backPath}
      />

      <Text>{[text[0]]}</Text>

      <Goals locales={locales} />

      <Title text={title[0]} />

      <List items={list} type="horizontal" icons={icons} />

      <Line />

      <h2 className="bmc-page-title">
        <span className="bmc-page-title--violet">{title[1]}</span> {title[2]}
      </h2>

      <Text align="center">{[text[1]]}</Text>

      <Tabs
        tabs={[common.yourStrategy, common.caseStudy]}
        withBlur
        className="margin-top-60 bmc-page-tabs--violet"
      >
        {[
          <div key="strategy">
            <TabsList horizontal sustainability>
              {fields.map((field, i) => (
                <TabsListItem
                  icon={iconsComponents[i]}
                  title={tabs[field]}
                  subtitle={tabs[`${field}Sub`]}
                  key={i}
                />
              ))}
            </TabsList>

            {collection.map((item, index) => (
              <TabsList key={item.id} horizontal minimalMargin>
                {fields.map((field, i) => (
                  <TabsListItem
                    key={i}
                    icon={iconsComponents[i]}
                    title={tabs[field]}
                    text={
                      i === 0 ? (
                        <Select
                          styles={SELECT_STYLES}
                          className="text-black"
                          options={sustainabilitySdgOptions}
                          value={selectValue(
                            item[field],
                            selectLabel(item[field], sdgs),
                          )}
                          placeholder={tabs[`${field}Placeholder`]}
                          onChange={({ value }) =>
                            updateSustainability({
                              index,
                              name: field,
                              value,
                              id: item.id,
                            })
                          }
                        />
                      ) : (
                        <Textarea
                          rows={2}
                          delay={700}
                          value={item[field]}
                          onChange={(event) =>
                            updateSustainability({
                              index,
                              name: field,
                              value: event.target.value,
                              id: item.id,
                            })
                          }
                          placeholder={tabs[`${field}Placeholder`]}
                        />
                      )
                    }
                  >
                    {i === 2 && collection.length > 1 && (
                      <ConfirmationModal
                        body="Delete this content. Are you sure?"
                        confirmText="Delete"
                        isBlockScroll
                        onConfirm={() =>
                          removeSustainability({ index, id: item.id })
                        }
                      >
                        <RemoveButton
                          className="bmc-page-tabs-content-list-remove"
                          active={collection.length > 1}
                        />
                      </ConfirmationModal>
                    )}
                  </TabsListItem>
                ))}
              </TabsList>
            ))}

            {collection.length < SUSTAINABILITY_MAX_COUNT && (
              <div className="bmc-page-tabs-content-actions">
                <Button
                  onClick={addSustainability}
                  wide
                  icon="icon-Add"
                  title={tabs.addNew}
                  className="bmc-button--violet"
                />
              </div>
            )}
          </div>,

          <Tabs key="tabs" black inner tabs={caseStudies.map((e) => e.name)}>
            {caseStudies.map((item, index) => (
              <div key={index}>
                <TabsCut
                  setOpened={setOpened}
                  opened={opened}
                  caseStudy={item}
                />

                {item.sustainabilities.map((sustainability, i) => (
                  <TabsList key={i} horizontal sustainability>
                    {fields.map((field, ii) => (
                      <TabsListItem
                        key={ii}
                        title={tabs[field]}
                        text={sustainability[field]}
                        icon={iconsComponents[ii]}
                      />
                    ))}
                  </TabsList>
                ))}
              </div>
            ))}
          </Tabs>,
        ]}
      </Tabs>

      <Actions nextClickHandler={nextClickHandler} buttonName={common.next} />
    </div>
  );
}
