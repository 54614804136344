import React from 'react';
import SvgIcon from '../../../components/SvgIcon';

const Footer = ({ header, text, buttons, handlers = [() => {}, () => {}] }) => {
  const [firstButtonHandler, secondButtonHandler] = handlers;
  return (
    <div className="footer">
      <div className="header">
        <div className="icon">
          <SvgIcon name="arrowRightBig" />
        </div>
        {header}
      </div>
      <div className="bottom">
        <div className="text">{text}</div>
        <div className="actions">
          <div onClick={firstButtonHandler} className="btn">
            {buttons[0]}
          </div>
          <div onClick={secondButtonHandler} className="btn">
            {buttons[1]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
