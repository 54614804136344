import React, { useState, useEffect } from 'react';
import InputRange from 'react-input-range';
import debounce from 'lodash/debounce';
import classNames from 'classnames';

import NumberInput from './NumberInput';

const defaultValueData = { min: 1, max: 10 };
const defaultErrors = [false, false];

export default function RangeWithInput({
  locales: {
    common: { from, to },
  },
  value,
  onChange,
  minValue,
  maxValue,
  enableRange = true,
  delay = false,
  textStyle,
}) {
  const [innerValue, setInnerValue] = useState(value || defaultValueData);
  const [errors, setErrors] = useState(defaultErrors);

  useEffect(() => {
    if (value !== innerValue) setInnerValue(value);
  }, [value]);

  function validate({ min, max }) {
    const minInt = parseInt(min) || 1;
    const maxInt = parseInt(max) || 1;

    if (minInt <= maxInt && minValue <= minInt) {
      setErrors(defaultErrors);
      return true;
    }
    setErrors([true, true]);
    return false;
  }

  function onChangeInner(val) {
    if (validate(val)) {
      setInnerValue(val);
      if (delay) {
        debounce(onChange, delay)(val);
      } else {
        onChange(val);
      }
    } else {
      setInnerValue({ ...innerValue });
    }
  }

  return (
    <div className="bmc-page-tabs-content-list-item-range">
      <div className="bmc-page-tabs-content-list-item-range-fields">
        <NumberInput
          className={classNames(
            'bmc-page-tabs-content-list-item-range-field custom-font-body',
            {
              'input--error': errors[0],
            },
            textStyle,
          )}
          onChange={(event) =>
            onChangeInner({ min: event.target.value, max: innerValue.max })
          }
          placeholder={from}
          value={innerValue.min}
          delay={700}
        />

        <NumberInput
          className={classNames(
            'bmc-page-tabs-content-list-item-range-field custom-font-body',
            {
              'input--error': errors[1],
            },
            textStyle,
          )}
          onChange={(event) =>
            onChangeInner({ min: innerValue.min, max: event.target.value })
          }
          placeholder={to}
          value={innerValue.max}
          delay={700}
        />
      </div>

      {enableRange && (
        <InputRange
          step={1}
          minValue={minValue}
          maxValue={maxValue}
          value={innerValue}
          onChange={setInnerValue}
          onChangeComplete={onChange}
          data-hj-allow
        />
      )}
    </div>
  );
}
