import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import useMediaQuery from 'react-use-media-query-hook';
import { truncate } from 'lodash';

import Card from '../../channel/CardBlogArch';
import {
  BLOG_FRIENDLY_TEMPLATE_NAME,
  BUTTON_LIGHT_MODAL_STYLE_BLOG,
  BUTTON_MODAL_STYLE_BLOG,
  COLORS,
  COMMENT_CHANNELS,
  TITLE_MODAL_STYLE_BLOG,
} from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import SectionHeader from '../../../components/SectionHeader';
import { getColorDependsOnBackground } from '../../../utils/helpers/getColorDependsOnBackground';

const { black } = COLORS;

const ChannelsBlog = ({
  data: { description },
  backgroundSection,
  locales,
  locales: {
    portfolio,
    channel: {
      intro: { card },
    },
  },
  thirdColor,
  backgroundModal,
  isFourthColorPaletteNumber = false,
  disabled = false,
}) => {
  const { collection } = useSelector((state) => state.channel);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [activeCardId, setActiveCardId] = useState(null);
  const {
    portfolio: { updateSectionField },
  } = useDispatch();
  const activeCardData = useMemo(() => {
    if (collection.length && !activeCardId) {
      setActiveCardId(collection[0].id);
      return collection[0];
    }
    return collection.find((i) => i.id === activeCardId);
  }, [collection, activeCardId]);

  const textColorDependsOnBackground =
    isFourthColorPaletteNumber &&
    getColorDependsOnBackground(backgroundSection);

  const buttonTextColorDependsOnBackground =
    isFourthColorPaletteNumber && getColorDependsOnBackground(thirdColor);

  const singleCard = activeCardData && (
    <Card
      locales={locales}
      dropzoneMode
      isPreview
      disabled={disabled}
      background={backgroundSection}
      backgroundImage={backgroundSection}
      templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
      titleStyle={TITLE_MODAL_STYLE_BLOG}
      buttonStyle={BUTTON_MODAL_STYLE_BLOG}
      lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_BLOG}
      sliderColor={black}
      backgroundModal={backgroundModal}
      textColor={textColorDependsOnBackground}
      borderImageColor={textColorDependsOnBackground}
      isTemplates
      card={{
        ...card,
        ...activeCardData,
        title: null,
      }}
    />
  );

  return (
    <div
      className="channel-blogFriendly section relative"
      style={{ backgroundColor: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_CHANNELS} />
      <SectionHeader
        templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
        title={portfolio.channels.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'channels',
            field: 'description',
            name: 'channelsText',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        style={{ color: textColorDependsOnBackground }}
        descriptionColor={textColorDependsOnBackground}
        disabled={disabled}
      />

      <div
        className="cards-section"
        style={{ backgroundColor: backgroundSection }}
      >
        <div className="left">
          {isMobile
            ? collection.map((channel) => {
                return (
                  <Card
                    locales={locales}
                    className="margin-top-20"
                    dropzoneMode
                    isPreview
                    disabled={disabled}
                    background={backgroundSection}
                    backgroundImage={backgroundSection}
                    templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
                    titleStyle={TITLE_MODAL_STYLE_BLOG}
                    buttonStyle={BUTTON_MODAL_STYLE_BLOG}
                    lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_BLOG}
                    sliderColor={black}
                    backgroundModal={backgroundModal}
                    textColor={textColorDependsOnBackground}
                    borderImageColor={textColorDependsOnBackground}
                    isTemplates
                    card={{
                      ...card,
                      ...channel,
                      title: null,
                    }}
                  />
                );
              })
            : singleCard}
        </div>
        <div className="right">
          {collection.map(({ id, name }) => (
            <div
              key={id}
              onClick={() => setActiveCardId(id)}
              className={classnames('item', 'custom-font-body', {
                active: activeCardId === id,
              })}
              style={{
                backgroundColor: activeCardId === id && thirdColor,
                color:
                  activeCardId === id
                    ? buttonTextColorDependsOnBackground
                    : textColorDependsOnBackground,
                borderColor: textColorDependsOnBackground,
              }}
            >
              {truncate(name, { length: 30 })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChannelsBlog;
