import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BmcCanvasSection from './BmcCanvasSection';
import CardModal from '../CardModal';
import SvgIcon from '../../../components/SvgIcon';
import ValuePropositionCard from '../../valueProposition/Card';
import CompetitorsCard from '../../valueProposition/CompetitorsCard';
import { collectionEmpty, itemHasContent } from '../../../utils/helpers';

export default function ValueProposition({
  locales,
  locales: { canvas },
  isDark,
  isPreview,
  isProgressBar,
  backgroundIcon,
  backgroundItems,
  backgroundSection,
  borderSectionColor,
  titleBMCSectionColor,
  textColor,
  iconFill,
  iconFillClose,
  templateName,
  sectionClassName,
  firstSvgColor,
  secondSvgColor,
  titleStyle,
  buttonStyle,
  iconStyle,
  backgroundBMC,
  backgroundImage,
  textStyle,
  backgroundRow,
  textWithImage,
  titleColor,
  buttonColor,
  backgroundButton,
  titleImageColor,
  isTemplates = false,
  isColourfulfunTemplate = false,
  disabled = false,
}) {
  const vpFields = ['product', 'reason', 'image'];
  const competitorFields = ['image', 'name', 'doWell', 'doBadly'];

  const {
    valueProposition,
    uniqueSellingPoint,
    competitor,
    bmc: {
      current: { id },
    },
  } = useSelector((state) => state);

  const {
    valueProposition: { fetchValuePropositions },
  } = useDispatch();

  const [showValuePropositionModal, setShowValuePropositionModal] =
    useState(false);

  const vpHasContent = itemHasContent(valueProposition, vpFields);
  const competitorHasContent = !collectionEmpty(
    competitor.collection,
    competitorFields,
  );
  const showButtonCondition =
    !isPreview &&
    collectionEmpty(uniqueSellingPoint.collection) &&
    !vpHasContent &&
    !competitorHasContent;

  return (
    <>
      {showValuePropositionModal && (
        <CardModal
          onBlackscreenClick={() => setShowValuePropositionModal(false)}
          title={canvas.valueProposition}
          icon={
            !templateName ? (
              <SvgIcon name="valueProposition" />
            ) : (
              <SvgIcon name="iconVP" />
            )
          }
          to={`/bmcs/${id}/value_proposition/intro`}
          className="custom-font-body"
          locales={locales}
          onOpen={() => fetchValuePropositions(id)}
          isOpen={showValuePropositionModal}
          isPreview={isPreview}
          titleStyle={titleStyle}
          buttonStyle={buttonStyle}
          firstSvgColor={firstSvgColor}
          secondSvgColor={secondSvgColor}
          iconFill={iconFill}
          iconStyle={iconStyle}
          backgroundIcon={backgroundIcon}
          backgroundBMC={backgroundBMC}
          titleColor={titleColor}
          buttonColor={buttonColor}
          backgroundButton={backgroundButton}
          iconFillClose={iconFillClose}
          isColourfulfunTemplate={isColourfulfunTemplate}
          disabled={disabled}
        >
          <ValuePropositionCard
            locales={locales}
            editMode={!isPreview}
            blur={false}
            isPopUp
            card={{
              ...locales.valueProposition.intro.card,
              title: false,
              image: valueProposition.image,
              solution: valueProposition.product,
              features: valueProposition.reason,
              points: uniqueSellingPoint.collection,
            }}
            textStyle={textStyle}
            buttonStyle={buttonStyle}
            backgroundBMC={backgroundBMC}
            backgroundImage={backgroundImage}
            titleStyle={titleStyle}
            firstSvgColor={firstSvgColor}
            secondSvgColor={secondSvgColor}
            buttonColor={buttonColor}
            backgroundButton={backgroundButton}
            textColor={textColor}
            titleImageColor={titleImageColor}
            isColourfulfunTemplate={isColourfulfunTemplate}
            isTemplates={isTemplates}
          />
          <CompetitorsCard
            locales={locales}
            className="margin-top-40"
            editMode={!isPreview}
            isPreview={isPreview}
            card={{
              ...locales.valueProposition.intro.competitorsCard,
              competitors: competitor.collection,
            }}
            backgroundRow={backgroundRow}
            backgroundImage={backgroundImage}
            firstSvgColor={firstSvgColor}
            secondSvgColor={secondSvgColor}
            titleStyle={titleStyle}
            buttonStyle={buttonStyle}
            textStyle={textStyle}
            textWithImage={textWithImage}
            titleImageColor={titleImageColor}
            isTemplates={isTemplates}
            isColourfulfunTemplate={isColourfulfunTemplate}
          />
        </CardModal>
      )}

      <BmcCanvasSection
        className={`bmc-canvas-section--green bmc-canvas-section-2 ${sectionClassName}`}
        title={canvas.valueProposition}
        dark={isDark}
        showButton={showButtonCondition}
        to={`/bmcs/${id}/value_proposition/intro`}
        locales={locales}
        icon={
          !templateName ? (
            <SvgIcon name="valueProposition" />
          ) : (
            <SvgIcon name="iconVP" />
          )
        }
        isPreview={isPreview}
        isProgressBar={isProgressBar}
        uploading={uniqueSellingPoint.uploading}
        onClick={() => setShowValuePropositionModal(true)}
        backgroundIcon={backgroundIcon}
        backgroundSection={backgroundSection}
        borderSectionColor={borderSectionColor}
        titleBMCSectionColor={titleBMCSectionColor}
        iconFill={iconFill}
        iconStyle={iconStyle}
        templateName={templateName}
      >
        {uniqueSellingPoint.collection.map(
          (item) =>
            (vpHasContent || competitorHasContent || itemHasContent(item)) && (
              <div
                key={item.id}
                className="bmc-canvas-section-items-item custom-font-body"
                style={{ backgroundColor: backgroundItems, color: textColor }}
                onClick={() => setShowValuePropositionModal(true)}
              >
                {item.description || '-'}
              </div>
            ),
        )}
      </BmcCanvasSection>
    </>
  );
}
