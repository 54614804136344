import React from 'react';
import { useDispatch } from 'react-redux';

import Textarea from '../../../components/Textarea';
import FormInput from '../../../components/FormInput';
import { COMMENT_CONTACT } from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import { getColorDependsOnBackground } from '../../../utils/helpers/getColorDependsOnBackground';

const ContactUsColourful = ({
  data: { title, description, fullName, emailOrWebsite },
  locales: { portfolio, portfolioWeb },
  background,
  backgroundButton,
  isFourthColorPaletteNumber = false,
  disabled = false,
}) => {
  const scrollToTop = () => {
    const element = document.getElementById('scroll');
    element.scrollIntoView();
  };

  const {
    portfolio: { updateSectionField },
  } = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateSectionField({
      sectionName: 'contactUs',
      field: name,
      value,
      name,
    });
  };

  const textColorDependsOnBackground =
    isFourthColorPaletteNumber && getColorDependsOnBackground(background);

  return (
    <div
      className="contact-us-colourful relative"
      style={{ backgroundColor: background }}
    >
      <PortfolioCommentIcon title={COMMENT_CONTACT} />
      <div className="header">
        <div className="header-first">
          <div
            className="colourful-title title custom-font-title"
            style={{
              color: textColorDependsOnBackground,
            }}
          >
            {portfolio.contactUs.sectionName}
          </div>
          <div className="textarea-colourful description">
            <Textarea
              name="textarea"
              rows={1}
              delay={700}
              value={description}
              onChange={(e) =>
                updateSectionField({
                  sectionName: 'contactUs',
                  field: 'description',
                  name: 'contactUsText',
                  value: e.target.value,
                })
              }
              className="textarea-colourfulFun textarea-colourfulFun-testing description-contact custom-font-body"
              style={{ color: textColorDependsOnBackground }}
              placeholder={portfolio.page.descriptionPlaceholder}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="header-first">
          <FormInput
            name="fullName"
            defaultValue={fullName}
            delay={700}
            placeholder={portfolio.contactUs.newForm.fullName}
            onChange={handleChange}
            disabled={disabled}
            className="form_input custom-font-body"
            style={{ color: textColorDependsOnBackground }}
          />
          <FormInput
            name="emailOrWebsite"
            defaultValue={emailOrWebsite}
            delay={700}
            placeholder={portfolio.contactUs.newForm.emailOrWebsite}
            onChange={handleChange}
            disabled={disabled}
            className="form_input custom-font-body"
            style={{ color: textColorDependsOnBackground }}
          />
        </div>
        <div
          className="scroll-top custom-font-body"
          style={{
            backgroundColor: backgroundButton,
            color:
              isFourthColorPaletteNumber &&
              getColorDependsOnBackground(backgroundButton),
          }}
          onClick={scrollToTop}
        >
          {portfolioWeb.contactUs.scrollTop}
          <i className="icon-Arrow-Top" />
        </div>
      </div>

      <div
        className="footer custom-font-body"
        style={{
          color: textColorDependsOnBackground,
          borderColor: textColorDependsOnBackground,
        }}
      >
        <div className="left ">
          {`© ${title}. ${portfolioWeb.contactUs.rightsReserve}`}
        </div>
        <div className="right">
          {portfolioWeb.contactUs.buildCreate}
          <span
            className="company-name"
            style={{
              color: textColorDependsOnBackground,
            }}
          >
            {portfolioWeb.contactUs.companyName}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactUsColourful;
