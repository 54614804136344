import { COLORS } from '../const';

const isColorLight = (color) => {
  const hex = color.replace('#', '');
  const colorRed = parseInt(hex.substring(0, 0 + 2), 16);
  const colorGreen = parseInt(hex.substring(2, 2 + 2), 16);
  const colorBlue = parseInt(hex.substring(4, 4 + 2), 16);
  const brightness =
    (colorRed * 299 + colorGreen * 587 + colorBlue * 114) / 1000;
  return brightness > 155;
};
export const getColorDependsOnBackground = (color) =>
  isColorLight(color) ? COLORS.black : COLORS.white;
