import {
  COLORS,
  FIRST_COLOR_PALETTE_NUMBER,
  SECOND_COLOR_PALETTE_NUMBER,
  THIRD_COLOR_PALETTE_NUMBER,
} from '../../const';
import { getColorDependsOnBackground } from '../getColorDependsOnBackground';

const { blue, lightBlack } = COLORS;

export const getColorDependsOnBackgroundSummary = (
  colorPaletteNumber,
  background,
) => {
  const colorDependsOnBackground = {
    [FIRST_COLOR_PALETTE_NUMBER]: blue,
    [SECOND_COLOR_PALETTE_NUMBER]: lightBlack,
    [THIRD_COLOR_PALETTE_NUMBER]: lightBlack,
  };

  return (
    colorDependsOnBackground[colorPaletteNumber] ||
    getColorDependsOnBackground(background)
  );
};
