import * as Route from '../utils/serverRoutes';
import {
  transformResponseCollection,
  updateCollectionById,
  payloadToData,
} from '../utils/helpers';
import makeRequest, { uploadFile } from '../utils/makeRequest';

const competitor = {
  state: {
    collection: [],
    uploading: false,
    uploadingImage: false,
  },
  reducers: {
    fetchCompetitorsReducer(state, { collection }) {
      return { ...state, collection };
    },
    addCompetitorReducer(state, payload) {
      return { ...state, collection: [...state.collection, payload] };
    },
    updateCompetitorReducer(state, payload) {
      return {
        ...state,
        collection: updateCollectionById(state.collection, payload.id, payload),
      };
    },
    removeCompetitorReducer(state, { id }) {
      const collection = state.collection.filter((e) => e.id !== id);

      return { ...state, collection };
    },
    updateAttributeReducer(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    fetchCompetitors(bmcId) {
      dispatch.competitor.updateAttributeReducer({ uploading: true });

      makeRequest({
        dispatch,
        url: Route.competitors(bmcId),
        success: (response) => {
          dispatch.competitor.fetchCompetitorsReducer({
            collection: transformResponseCollection(response.data.data),
          });
        },
        finish: () => {
          dispatch.competitor.updateAttributeReducer({ uploading: false });
        },
      });
    },
    async addCompetitor(_, state) {
      makeRequest({
        dispatch,
        method: 'post',
        url: Route.competitors(state.bmc.current.id),
      });
    },
    async updateCompetitor(payload, state) {
      const data = payloadToData(payload);
      dispatch.competitor.updateCompetitorReducer(data);
      makeRequest({
        dispatch,
        method: 'put',
        url: Route.competitor(state.bmc.current.id, payload.id),
        data: { competitor: data },
        success: payload.success,
        finish: payload.finish,
      });
    },
    async updateCompetitorImage(payload, state) {
      const { id, name, blob, success } = payload;

      dispatch.competitor.updateAttributeReducer({ uploadingImage: id });

      uploadFile({
        url: Route.competitor(state.bmc.current.id, id),
        modelName: 'competitor',
        name,
        blob,
        dispatch,
        success: (res) => {
          dispatch.competitor.updateCompetitorReducer({
            [name]: res.data.data.attributes[name],
            id,
          });

          if (success) success();
        },
        finish: () => {
          dispatch.competitor.updateAttributeReducer({ uploadingImage: false });
        },
      });
    },
    updateCompetitorImageCoords({
      id,
      coords,
      croppedAreaPixels,
      innerCropData,
      zoom,
    }) {
      const name = 'imageCoords';
      const value = {
        ...coords,
        ...croppedAreaPixels,
        cropperData: { ...innerCropData, zoom },
      };

      dispatch.competitor.updateAttributeReducer({
        uploadingImage: id,
      });

      dispatch.competitor.updateCompetitor({
        id,
        name,
        value,
        success: (res) => {
          dispatch.competitor.updateCompetitorReducer({
            id,
            [name]: res.data.data.attributes[name],
          });

          dispatch.competitor.updateCompetitorReducer({
            id,
            image: res.data.data.attributes.image,
          });
        },
        finish: () => {
          dispatch.competitor.updateAttributeReducer({ uploadingImage: false });
        },
      });
    },
    async removeCompetitor(payload, state) {
      makeRequest({
        dispatch,
        method: 'delete',
        url: Route.competitor(state.bmc.current.id, payload.id),
      });
    },
  }),
};

export default competitor;
