import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import BmcMenu from './BmcMenu';
import { nextComponentHandlerFunction } from '../utils/helpers/nextComponentHandlerFunction';

export default function Section({
  components,
  locales,
  menuItems,
  sectionName,
  initComponentIndex = 0,
  rules = false,
  progress = null,
  finalDestination = '/',
  onComponentChange = undefined,
}) {
  const {
    bmc: { view },
  } = useSelector((state) => state);

  const { section } = useParams();
  const sections = menuItems.map((e) => e.sectionName);
  const componentIndexFromSection = section
    ? sections.findIndex((e) => e === section)
    : -1;
  const [componentIndex, setComponentIndex] = useState(initComponentIndex);
  const topRef = React.createRef();
  const history = useHistory();

  useEffect(() => {
    if (componentIndexFromSection !== -1)
      setComponentIndex(componentIndexFromSection);
  }, [section]);

  function selectComponent(index) {
    setComponentIndex(index);
    return onComponentChange && onComponentChange();
  }

  function nextComponentHandler() {
    if (componentIndex < components.length - 1) {
      history.push(components[componentIndex + 1].url);
    } else {
      history.push(nextComponentHandlerFunction(view, finalDestination));
    }
  }

  return (
    <div className="bmc-section-main-container" ref={topRef}>
      <div className="bmc-section-main-container-inner">
        <BmcMenu
          componentIndex={componentIndex}
          sectionName={sectionName}
          menuItems={menuItems}
          locales={locales}
          rules={rules}
          backPath={finalDestination}
          progress={progress}
        />

        {React.createElement(components[componentIndex].component, {
          nextClickHandler: rules
            ? rules[componentIndex + 1] && nextComponentHandler
            : nextComponentHandler,
          locales,
          menuItems,
          selectComponent,
          componentIndex,
          rules,
          backPath: finalDestination,
        })}
      </div>
    </div>
  );
}
