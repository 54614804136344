import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SectionHeader from '../SectionHeader';
import Textarea from '../../../components/Textarea';
import { filterCollection } from '../../../utils/helpers';
import Card from '../../sustainability/Card';

const Sustainability = ({
  data: { description, showStatus },
  locales,
  locales: { portfolio },
  disabled = false,
}) => {
  const {
    portfolio: { updateSectionField, updateSectionFieldReducer },
  } = useDispatch();

  const setShowStatus = (status) => {
    updateSectionFieldReducer({
      sectionName: 'sustainability',
      field: 'showStatus',
      value: status,
    });
  };
  const { collection } = useSelector((state) => state.sustainability);
  const sustainabilityCollection = filterCollection(collection, 'sdg');

  return (
    <div className="sustainability">
      <SectionHeader
        locales={portfolio}
        showStatus={showStatus}
        sectionName={portfolio.sustainability.sectionName}
        setShowStatus={setShowStatus}
        mainColor="purple"
      />
      {showStatus && (
        <>
          <Textarea
            name="textarea"
            rows={5}
            delay={700}
            value={description || ''}
            onChange={(e) =>
              updateSectionField({
                sectionName: 'sustainability',
                field: 'description',
                name: 'sustainabilityText',
                value: e.target.value,
              })
            }
            className="bmc-form-textarea textarea"
            placeholder={portfolio.page.descriptionPlaceholder}
            disabled={disabled}
          />

          <Card
            locales={locales}
            collection={sustainabilityCollection}
            className="bmc-page-suscard--portfolio-edit"
          />
        </>
      )}
    </div>
  );
};

export default Sustainability;
