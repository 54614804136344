const roles = {
  super_admin: 'super admin',
  admin: 'administrator',
  student: 'student',
  lecturer: 'educator',
};

export default function getRoleLabelText(role) {
  return roles[role];
}
