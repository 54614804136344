import {
  Paragraph,
  ImageRun,
  HorizontalPositionAlign,
  VerticalPositionAlign,
  VerticalAlign,
  AlignmentType,
  HeightRule,
  FrameAnchorType,
  ExternalHyperlink,
  Table,
  TableAnchorType,
  OverlapType,
  WidthType,
  TableLayoutType,
  TableRow,
  TableCell,
} from 'docx';
import { isEmpty } from 'lodash';
import Quote from '../assets/icons/quote.png';
import Export from '../assets/icons/export.png';
import {
  pageSize,
  convertPixelToTwip,
  convertPixel,
  getBlobFromUrl,
  transparentBorder,
  COLORS,
  shadingBox,
  tableWidth,
  noPageMargin,
  imageRunColourful,
} from '../Constants';
import {
  buildColourfulDecorateImg,
  coloringQuoteImg,
  emptyCell,
  marginTableRow,
  textRun,
} from '../helpers/index';

export default async (locales, portfolio, portfolioFiles, options) => {
  const buildFileRows = () =>
    portfolioFiles.collection.map((item) => [
      new TableRow({
        height: {
          value: convertPixelToTwip(53),
          rule: HeightRule.ATLEAST,
        },
        children: [
          emptyCell(514, COLORS.WHITE),

          new TableCell({
            verticalAlign: VerticalAlign.CENTER,
            width: {
              size: convertPixelToTwip(262),
              type: WidthType.DXA,
            },
            shading: shadingBox(options?.summary?.btnBg || COLORS.BLACK),
            borders: transparentBorder(COLORS.WHITE),
            children: [
              new Table({
                float: {
                  horizontalAnchor: TableAnchorType.TEXT,
                  verticalAnchor: TableAnchorType.TEXT,
                  overlap: OverlapType.NEVER,
                },
                width: {
                  size: convertPixelToTwip(262),
                  type: WidthType.DXA,
                },
                layout: TableLayoutType.AUTOFIT,
                rows: [
                  new TableRow({
                    height: {
                      value: convertPixelToTwip(53),
                      rule: HeightRule.ATLEAST,
                    },
                    children: [
                      new TableCell({
                        verticalAlign: VerticalAlign.CENTER,
                        width: {
                          size: convertPixelToTwip(46),
                          type: WidthType.DXA,
                        },
                        shading: shadingBox(
                          options.summary.btnBg || COLORS.BLACK,
                        ),
                        borders: transparentBorder(COLORS.WHITE),
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              new ImageRun({
                                data: getBlobFromUrl(
                                  options.summary.download || Export,
                                ),
                                transformation: {
                                  width: convertPixel(25),
                                  height: convertPixel(25),
                                },
                              }),
                            ],
                          }),
                        ],
                      }),

                      new TableCell({
                        verticalAlign: VerticalAlign.CENTER,
                        width: {
                          size: convertPixelToTwip(216),
                          type: WidthType.DXA,
                        },
                        shading: shadingBox(
                          options?.summary?.btnBg || COLORS.BLACK,
                        ),
                        borders: transparentBorder(COLORS.WHITE),
                        children: [
                          new Paragraph({
                            children: [
                              new ExternalHyperlink({
                                children: [
                                  textRun(item.name, {
                                    color:
                                      options.summary.btnColor || COLORS.WHITE,
                                    font: options.bodyFont,
                                    size: 16,
                                  }),
                                ],
                                link: item.file,
                              }),
                            ],
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      marginTableRow(10, 776, COLORS.WHITE),
    ]);

  const filesTable = () => {
    if (isEmpty(portfolioFiles.collection)) return;

    return new Table({
      float: {
        horizontalAnchor: TableAnchorType.TEXT,
        verticalAnchor: TableAnchorType.TEXT,
        overlap: OverlapType.NEVER,
      },
      width: {
        size: convertPixelToTwip(730),
        type: WidthType.DXA,
      },
      layout: TableLayoutType.AUTOFIT,
      rows: buildFileRows().flat(1),
    });
  };

  const getPageBg = () => {
    if (options.summary.pageBg)
      return new Paragraph({
        text: '',
        frame: {
          position: {
            x: 0,
            y: 0,
          },
          rule: HeightRule.EXACT,
          width: convertPixelToTwip(tableWidth),
          height: convertPixelToTwip(539 + 28 + 28),
          anchor: {
            horizontal: FrameAnchorType.TEXT,
            vertical: FrameAnchorType.TEXT,
          },
          alignment: {
            x: HorizontalPositionAlign.CENTER,
            y: VerticalPositionAlign.TOP,
          },
        },
        shading: shadingBox(options.summary.pageBg),
      });
  };

  const getLeftBgImg = async () => {
    if (options.summary?.leftImgBg) {
      const img = await buildColourfulDecorateImg(
        options.summary.leftImgBg,
        options.summary.leftImgColor,
      );

      return imageRunColourful(img, 83, 245, 0, 2);
    }
  };

  const getRightBgImg = async () => {
    if (options.summary?.rightImgBg) {
      const img = await buildColourfulDecorateImg(
        options.summary.rightImgBg,
        options.summary.rightImgColor,
      );

      return imageRunColourful(img, 125, 245, 23, 0);
    }
  };

  const coloredQuoteImg = await coloringQuoteImg(
    Quote,
    options.summary.quoteImgColor || COLORS.BLACK,
  );

  return {
    properties: {
      page: {
        size: pageSize,
        margin: noPageMargin,
      },
    },
    children: [
      // Page background
      getPageBg(),
      // quote
      new Paragraph({
        frame: {
          position: {
            x: convertPixelToTwip(28),
            y: convertPixelToTwip(139 + 10),
          },
          width: convertPixelToTwip(60),
          height: convertPixelToTwip(50),
          rule: HeightRule.ATLEAST,
          anchor: {
            horizontal: FrameAnchorType.TEXT,
            vertical: FrameAnchorType.TEXT,
          },
          alignment: {
            x: HorizontalPositionAlign.CENTER,
            y: VerticalPositionAlign.TOP,
          },
        },
        children: [
          new ImageRun({
            data: coloredQuoteImg,
            transformation: {
              width: convertPixel(60),
              height: convertPixel(50),
            },
          }),
          await getLeftBgImg(),
          await getRightBgImg(),
        ],
      }),
      // summary text
      new Paragraph({
        text: '',
        alignment: AlignmentType.CENTER,
        frame: {
          position: {
            x: convertPixelToTwip(98),
            y: convertPixelToTwip(139 + 21.5),
          },
          rule: HeightRule.EXACT,
          width: convertPixelToTwip(660),
          height: convertPixelToTwip(40),
          anchor: {
            horizontal: FrameAnchorType.TEXT,
            vertical: FrameAnchorType.TEXT,
          },
          alignment: {
            x: HorizontalPositionAlign.CENTER,
            y: VerticalPositionAlign.TOP,
          },
        },

        children: [
          textRun(locales.projectSummary.sectionName, {
            font: options.titleFont,
            color: options.titleColor || COLORS.BLACK,
            size: options?.summary?.titleSize || 24,
            bold: options?.summary?.titleBold,
            allCaps: true,
          }),
        ],
      }),
      // description
      new Table({
        float: {
          horizontalAnchor: TableAnchorType.TEXT,
          verticalAnchor: TableAnchorType.TEXT,
          overlap: OverlapType.NEVER,

          absoluteVerticalPosition: convertPixelToTwip(224 + 10),
          absoluteHorizontalPosition: convertPixelToTwip(28),
        },
        width: {
          size: convertPixelToTwip(tableWidth - 56),
          type: WidthType.DXA,
        },
        layout: TableLayoutType.AUTOFIT,
        rows: [
          new TableRow({
            height: {
              value: convertPixelToTwip(68),
              rule: HeightRule.ATLEAST,
            },
            children: [
              new TableCell({
                width: {
                  size: convertPixelToTwip(28),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.WHITE),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.START,
                    children: [
                      textRun(portfolio.projectSummary.description, {
                        font: options.bodyFont,
                        color: options.bodyColor || COLORS.BLACK,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
          marginTableRow(15, tableWidth - 56, COLORS.PRIMARY),
          new TableRow({
            height: {
              value: convertPixelToTwip(68),
              rule: HeightRule.ATLEAST,
            },
            children: [
              new TableCell({
                width: {
                  size: convertPixelToTwip(28),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.WHITE),
                children: [filesTable()],
              }),
            ],
          }),
        ],
      }),
    ],
  };
};
