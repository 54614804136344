import React from 'react';
import { useSelector } from 'react-redux';
import { TECH_MODERN_TEMPLATE_NAME } from '../../main/utils/const';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';

const Desirability = ({
  locales: { portfolioWeb },
  backgroundSection,
  colorPaletteNumber,
}) => {
  const { desirabilitySection } = useSelector((state) => state.portfolio);

  return (
    <div
      className="desirability-tech-web section-web"
      style={{ background: backgroundSection }}
    >
      <SectionHeaderWeb
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolioWeb.desirability}
        description={desirabilitySection.description}
        portfolioWeb={portfolioWeb}
        colorPaletteNumber={colorPaletteNumber}
      />
    </div>
  );
};

export default Desirability;
