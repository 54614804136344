import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import useMediaQuery from 'react-use-media-query-hook';
import { truncate } from 'lodash';

import CardCurrent from '../../channel/CardCurrent';
import {
  COMMENT_CHANNELS,
  TECH_MODERN_TEMPLATE_NAME,
} from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';

const ChannelsTech = ({
  data: { description },
  locales,
  locales: {
    portfolio,
    channel: {
      intro: { card },
    },
  },
  background,
  titleColor,
  borderCardColor,
  backgroundImage,
}) => {
  const { collection } = useSelector((state) => state.channel);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [activeCardId, setActiveCardId] = useState(null);
  const {
    portfolio: { updateSectionField },
  } = useDispatch();
  const activeCardData = useMemo(() => {
    if (collection.length && !activeCardId) {
      setActiveCardId(collection[0].id);
      return collection[0];
    }
    return collection.find((i) => i.id === activeCardId);
  }, [collection, activeCardId]);

  const singleCard = activeCardData && (
    <CardCurrent
      locales={locales}
      styleColor={titleColor}
      borderCardColor={borderCardColor}
      backgroundImage={backgroundImage}
      templateName={TECH_MODERN_TEMPLATE_NAME}
      dropzoneMode
      isPreview
      card={{
        ...card,
        ...activeCardData,
        title: null,
      }}
    />
  );

  return (
    <div
      className="channel-tech section relative"
      style={{ backgroundColor: background }}
    >
      <PortfolioCommentIcon title={COMMENT_CHANNELS} />
      <SectionHeader
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolio.channels.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'channels',
            field: 'description',
            name: 'channelsText',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        style={{ color: titleColor }}
      />

      <div className="cards-section">
        <div className="left">
          {isMobile
            ? collection.map((channel) => {
                return (
                  <CardCurrent
                    locales={locales}
                    styleColor={titleColor}
                    borderCardColor={borderCardColor}
                    backgroundImage={backgroundImage}
                    templateName={TECH_MODERN_TEMPLATE_NAME}
                    dropzoneMode
                    isPreview
                    card={{
                      ...card,
                      ...channel,
                      title: null,
                    }}
                  />
                );
              })
            : singleCard}
        </div>
        <div className="right">
          {collection.map(({ id, name }) => (
            <div
              key={id}
              onClick={() => setActiveCardId(id)}
              className={classnames('item', { active: activeCardId === id })}
              style={{ backgroundColor: activeCardId === id && titleColor }}
            >
              {truncate(name, { length: 30 })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChannelsTech;
