import { itemHasContent } from '../../utils/helpers';

export function calculateKeyActivityProgress(rootState) {
  const stepsCount = 4;
  const step = 100 / stepsCount;
  let keyActivityProgress = 0;

  rootState.keyActivity.collection.slice(0, stepsCount).forEach((thing) => {
    if (itemHasContent(thing)) keyActivityProgress += step;
  });

  return keyActivityProgress;
}

export function calculateKeyPartnerProgress(rootState) {
  const stepsCount = 3;
  const step = 100 / stepsCount;
  let keyPartnerProgress = 0;

  rootState.keyPartner.collection.slice(0, stepsCount).forEach((thing) => {
    if (itemHasContent(thing)) keyPartnerProgress += step;
  });

  return keyPartnerProgress;
}

export function calculateKeyResourceProgress(rootState) {
  const stepsCount = 3;
  const step = 100 / stepsCount;
  let keyResourceProgress = 0;

  rootState.keyResource.collection.slice(0, stepsCount).forEach((thing) => {
    if (itemHasContent(thing)) keyResourceProgress += step;
  });

  return keyResourceProgress;
}
