import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Spinner from '../../../main/components/Spinner';
import CheckBox from '../../../main/containers/testing/Components/CheckBox';

export default function SectionsSettings({ locales: { admin }, classroomId }) {
  const {
    current: { sectionAvailabilitySettings },
    uploading,
  } = useSelector((state) => state.classroom);

  const {
    classroom: { fetchClassroom, updateClassroomSectionAvailabilitySettings },
  } = useDispatch();

  useEffect(() => {
    fetchClassroom(classroomId);
  }, []);

  const checkboxes = [
    { title: 'Ideation', name: 'ideation' },
    { title: 'Bmc Canvas', name: 'bmc' },
    { title: 'Testing', name: 'testing' },
    { title: 'Portfolio', name: 'portfolio' },
  ];

  const checkboxClick = (name) => {
    const checked = sectionAvailabilitySettings[name];

    updateClassroomSectionAvailabilitySettings({
      id: classroomId,
      data: { [name]: !checked },
    });
  };

  return (
    <div className="section-settings">
      <header className="admin-header">
        <h3>{admin.sectionAvailabilitySettings.title}</h3>
      </header>

      <div className="section-settings__list">
        {uploading ? (
          <Spinner />
        ) : (
          checkboxes.map((checkbox) => (
            <div key={checkbox.name} className="section-settings__item">
              <CheckBox
                title={checkbox.title}
                isChecked={sectionAvailabilitySettings[checkbox.name]}
                onCheck={() => checkboxClick(checkbox.name)}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
}
