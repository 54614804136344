import React, { useRef } from 'react';
import { digitToString } from '../../main/utils/helpers';

const Landing2 = ({ test, locales: { landing2: pageLocales } }) => {
  const learnMoreSection = useRef(null);
  const handleScroll = () => {
    window.scrollTo({
      top: learnMoreSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <div className="desktop-landing2">
      <div
        className="desktop-landing2--main-block"
        style={{ backgroundImage: `url(${test.image.medium.url})` }}
      >
        <h1 className="desktop-landing2--logo">{test.data.company_name}</h1>
        <h2 className="desktop-landing2--title">{test.data.headline}</h2>
        <p className="desktop-landing2--description">{test.data.explain}</p>
        <button
          className="desktop-landing2--learn-more-btn"
          onClick={handleScroll}
        >
          {pageLocales.button}
        </button>
      </div>
      <div className="desktop-landing2--learn-more" ref={learnMoreSection}>
        <h3 className="desktop-landing2--learn-more-title">
          {pageLocales.learnMoreTitle}
        </h3>
        <ul className="desktop-landing2--learn-more-list">
          {pageLocales.list.map((item, index) => (
            <li key={index}>
              <span>{index + 1}</span>
              <div className="desktop-landing2--learn-more-line" />
              <p>{test.data[`thing${digitToString[index + 1]}`]}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Landing2;
