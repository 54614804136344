import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';

const Input = ({
  field,
  form: { errors, touched },
  label = '',
  name,
  locales,
  placeholder = '',
  className = '',
  type = 'text',
  ...props
}) => {
  const error = get(errors, field.name);
  const isTouched = get(touched, field.name);

  return (
    <div className={classNames('sign-up-form__input', className)}>
      {label && <label htmlFor={field.name}>{label}</label>}
      <div className="sign-up-form__input-inner">
        <input
          {...field}
          {...props}
          type={type}
          id={field.name}
          placeholder={placeholder}
          className="bmc-form-input"
        />
        {error && isTouched && (
          <p className="sign-up-form__input-error">
            {get(locales.onboarding.validationMessages, error)}
          </p>
        )}
      </div>
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  locales: PropTypes.object.isRequired,
};

export default Input;
