import React from 'react';

export default function CardColourful({
  card: { name, description, descriptionTitle },
  titleColor,
  textColor,
  styleColor,
}) {
  return (
    <div className="card">
      <div
        className="card-title custom-font-body"
        style={{ color: titleColor, borderBottomColor: styleColor }}
      >
        {name}
      </div>
      <div
        className="card-description-title custom-font-body"
        style={{ color: textColor }}
      >
        {descriptionTitle}
      </div>
      <div
        className="card-description custom-font-body"
        style={{ color: textColor }}
      >
        {description}
      </div>
    </div>
  );
}
