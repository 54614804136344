import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import useMediaQuery from 'react-use-media-query-hook';

import ProgressBar from '../../components/ProgressBar';
import { calculateProgress } from '../../utils/helpers';
import Button from '../../components/Button';
import Img from '../../components/Img';
import ConfirmationModal from '../../components/ConfirmationModal';
import * as AppRoute from '../../utils/appRoutes';

export default function Card({
  className,
  card,
  pickIdeaMode = false,
  showEditButton = false,
  picked,
  onPick,
  locales,
  bmc,
  removeIdea,
}) {
  const isMobile = useMediaQuery('(max-width: 480px)');
  const history = useHistory();
  const {
    id,
    url,
    problemTitle,
    problem,
    solutionTitle,
    solution,
    marketSizeTitle,
    marketSize = 0,
    priceTitle,
    price = 0,
    excitementTitle,
    excitement = 0,
    pickThisIdea,
    pickedWord,
    editIdea,
    deleteIdea,
  } = card;
  const { areYouSure, deleteText } = locales.common;
  const bmcId = bmc.id;

  return (
    <div
      className={classNames('ideation-card', className, {
        'ideation-card--pick-idea': pickIdeaMode,
        'ideation-card--picked': picked,
      })}
      onClick={() => isMobile && history.push(AppRoute.ideation(bmcId, id))}
    >
      {showEditButton && removeIdea && (
        <ConfirmationModal
          body={areYouSure}
          confirmText={deleteText}
          onConfirm={() => removeIdea({ id })}
        >
          <div className="ideation-card-delete-icon">
            <i className="icon-Delete" />
          </div>
        </ConfirmationModal>
      )}
      <div className="ideation-card-left">
        {url ? (
          <Img src={url} alt="ideation-card" aspectRatio={1} />
        ) : (
          <div className="ideation-card-default-image" />
        )}
      </div>

      <div className="ideation-card-right">
        {!pickIdeaMode && (
          <>
            <div className="ideation-card-title margin-bottom-10">
              {problemTitle}
            </div>

            <div className="ideation-card-text margin-bottom-20">{problem}</div>

            <div className="ideation-card-title margin-bottom-10">
              {solutionTitle}
            </div>
          </>
        )}

        <div className="ideation-card-text margin-bottom-20">{solution}</div>

        {pickIdeaMode && <div className="margin-top-40" />}

        <ProgressBar
          title={marketSizeTitle}
          value={marketSize}
          progress={calculateProgress(marketSize, 10)}
          className="bmc-progressbar--green margin-bottom-10"
        />
        <ProgressBar
          title={priceTitle}
          value={price}
          progress={calculateProgress(price, 10)}
          className="bmc-progressbar--orange margin-bottom-10"
        />
        <ProgressBar
          title={excitementTitle}
          value={excitement}
          progress={calculateProgress(excitement, 10)}
          className="bmc-progressbar--violet"
        />

        {pickIdeaMode && (
          <div className="ideation-card-actions margin-top-20">
            {picked ? (
              <Button title={pickedWord} className="bmc-button--with-icon" />
            ) : (
              <Button
                title={pickThisIdea}
                className="bmc-button--with-icon"
                onClick={onPick}
              />
            )}
          </div>
        )}

        {showEditButton && (
          <div className="ideation-card-actions margin-top-40">
            {removeIdea && (
              <>
                <ConfirmationModal
                  body={areYouSure}
                  confirmText={deleteText}
                  onConfirm={() => removeIdea({ id })}
                >
                  <Button
                    title={deleteIdea}
                    className="ideation-card-actions-delete-button margin-right-20"
                  />
                </ConfirmationModal>

                <Button
                  title={editIdea}
                  className="bmc-button--with-icon"
                  onClick={() => history.push(AppRoute.ideation(bmcId, id))}
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
