import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';

const TOOLTIP_DELAY = 500;

const Actions = ({
  nextClickHandler,
  buttonName,
  tooltipIfDisabled,
  finish = false,
}) => (
  <div className="bmc-page-actions margin-top-40">
    <Button
      title={finish || buttonName}
      primary
      className="bmc-button--next"
      onClick={nextClickHandler || undefined}
      disabled={!nextClickHandler}
      tooltipIfDisabled={tooltipIfDisabled}
      tooltipDelayHide={TOOLTIP_DELAY}
    />
  </div>
);

Actions.propTypes = {
  buttonName: PropTypes.string.isRequired,
};

export default Actions;
