// import React, { useState } from "react";
import React from 'react';

export default function AnalysisBlock({ locales: { admin } }) {
  // const [opened, setOpened] = useState(true)

  const filters = [
    { title: 'Total Minutes' },
    { title: 'Active Students' },
    { title: 'Ideas Created' },
    { title: 'Canvas Completion' },
    { title: 'Tests Completed' },
  ];

  return (
    <div className="admin-analysis">
      <div className="admin-analysis-header">{admin.analysis}</div>

      <div className="admin-analysis-filters">
        <div className="admin-analysis-filters__label">{admin.filterBy}</div>
        {filters.map((item, index) => (
          <div key={index} className="admin-analysis-filters__item">
            {item.title}
          </div>
        ))}
      </div>
    </div>
  );
}
