import { collectionSomeItemIsFilled } from '../../utils/helpers';

const sustainabilityFields = ['problem', 'value', 'sdg'];

export default function calculateSustainabilityProgress(rootState) {
  const stepsCount = 1;
  const step = 100 / stepsCount;
  let sustainabilityProgress = 0;

  if (
    collectionSomeItemIsFilled(
      rootState.sustainability.collection,
      sustainabilityFields,
    )
  )
    sustainabilityProgress += step;

  return sustainabilityProgress;
}
