import React from 'react';
import Lazyload from 'react-lazyload';
import { useSelector } from 'react-redux';
import { find } from 'lodash';

import CustomerSegmentCurrent from './CustomerSegmentCurrent';
import ValuePropositionCurrent from './ValuePropositionCurrent';
import ChannelsCurrent from './ChannelsCurent';
import ContactUsCurrent from './ContactUsCurrent';
import { StyledWrapper } from '../../../utils/helpers';
import { getColorTemplate } from '../../../utils/helpers/getColorTemplate';
import {
  BUTTON_MODAL_STYLE_CURRENT,
  COLORS,
  COLOR_PALETTE_ARRAY_CURRENT,
  CURRENT_TEMPLATE_NAME,
  FIRST_COLOR_PALETTE_NUMBER,
} from '../../../utils/const';
import Introduction from '../mainTemplateComponents/Introduction';
import Summary from '../mainTemplateComponents/Summary';
import Team from '../mainTemplateComponents/Team';
import BMC from '../mainTemplateComponents/BMC';
import Testing from '../mainTemplateComponents/Testing';
import { getColorDependsOnBackground } from '../../../utils/helpers/getColorDependsOnBackground';
import Desirability from '../mainTemplateComponents/Desirability';
import Viability from '../mainTemplateComponents/Viability';
import Feasibility from '../mainTemplateComponents/Feasibility';
import CompetitorAnalysis from '../mainTemplateComponents/CompetitorAnalysis';
import CustomerRelationships from '../mainTemplateComponents/CustomerRelationships';
import { getCurrentSubtitleColor } from '../../../utils/helpers/getCurrentSubtitleColor';
import KeyActivities from '../mainTemplateComponents/KeyActivities';
import Finance from '../mainTemplateComponents/Finance';
import Sustainability from '../mainTemplateComponents/Sustainability';

const { lightBlack, black, white, lightGreen, orange, violet } = COLORS;

const CurrentTemplate = ({
  locales,
  portfolio,
  bmc,
  testing,
  disabled = false,
}) => {
  const {
    portfolioStyle: { templateSettings, activeTemplateName },
    portfolioTemplate: { collection },
  } = useSelector((state) => state);

  const currentSettings = templateSettings
    ? templateSettings[activeTemplateName]
    : {};

  const activeTemplate = find(collection, ['sysName', activeTemplateName]);

  const colorTemplate = getColorTemplate(
    templateSettings,
    activeTemplateName,
    activeTemplate,
  );

  if (!colorTemplate) {
    return null;
  }

  const [firstColor, secondColor, thirdColor, fourthColor] = colorTemplate;

  const colorPaletteNumber = COLOR_PALETTE_ARRAY_CURRENT[secondColor];

  const isFourthColorPaletteNumber = !colorPaletteNumber;
  const isFirstColorPallete = colorPaletteNumber === FIRST_COLOR_PALETTE_NUMBER;
  const mainStyleColor = !isFirstColorPallete && secondColor;
  const colorSusDependsOnBackgroundOrWhite = isFourthColorPaletteNumber
    ? getColorDependsOnBackground(fourthColor)
    : white;

  return (
    <div className="bmc-canvas margin-top-30">
      <div className="bmc-page fullwidth" style={{ padding: '0' }}>
        <StyledWrapper
          customtitlefont={currentSettings?.customTitleFont}
          customtitlecolor={currentSettings?.customTitleColor}
          custombodyfont={currentSettings?.customBodyFont}
          custombodycolor={currentSettings?.customBodyColor}
        >
          <Introduction
            locales={locales}
            data={portfolio.introduction}
            templateName={CURRENT_TEMPLATE_NAME}
            disabled={disabled}
          />
          <Summary
            locales={locales}
            data={portfolio.projectSummary}
            backgroundSection={firstColor}
            colorPaletteNumber={colorPaletteNumber}
            isFirstColorPallete={isFirstColorPallete}
            isFourthColorPaletteNumber={isFourthColorPaletteNumber}
            templateName={CURRENT_TEMPLATE_NAME}
            disabled={disabled}
          />
          <Lazyload>
            <Team
              locales={locales}
              data={portfolio.team}
              borderColorName={secondColor}
              isFirstColorPallete={isFirstColorPallete}
              templateName={CURRENT_TEMPLATE_NAME}
              disabled={disabled}
            />
          </Lazyload>

          {bmc && (
            <Lazyload>
              <BMC
                locales={locales}
                data={portfolio.bmc}
                className="bm-current-canvas section"
                iconFill={mainStyleColor}
                backgroundIcon={!isFirstColorPallete && `${secondColor}66`}
                styleColor={mainStyleColor}
                backgroundImage={`${secondColor}80`}
                titleColor={mainStyleColor}
                backgroundButton={mainStyleColor}
                buttonStyle={!isFirstColorPallete && BUTTON_MODAL_STYLE_CURRENT}
                sectionHeaderTitleColor={!isFirstColorPallete && lightBlack}
                templateName={CURRENT_TEMPLATE_NAME}
                isProgressBar
                disabled={disabled}
              />
            </Lazyload>
          )}

          {testing && (
            <Lazyload>
              <Testing
                locales={locales}
                data={portfolio.testing}
                className="testing testing-current section"
                testListStyle="tests-list margin-top-20"
                inputColor={mainStyleColor}
                rateColor={mainStyleColor}
                titleColor={mainStyleColor}
                backgroundButton={mainStyleColor}
                linkColor={mainStyleColor}
                sectionHeaderTitleColor={!isFirstColorPallete && lightBlack}
                templateName={CURRENT_TEMPLATE_NAME}
                disabled={disabled}
              />
            </Lazyload>
          )}
          {bmc && (
            <>
              <Desirability
                locales={locales}
                data={portfolio.desirabilitySection}
                className="desirability-current"
                sectionHeaderTitleColor={
                  isFourthColorPaletteNumber &&
                  getColorDependsOnBackground(secondColor)
                }
                descriptionColor={
                  isFourthColorPaletteNumber &&
                  getColorDependsOnBackground(secondColor)
                }
                backgroundSection={secondColor}
                templateName={CURRENT_TEMPLATE_NAME}
                disabled={disabled}
              />
              <Lazyload>
                <CustomerSegmentCurrent
                  locales={locales}
                  data={portfolio.customerSegment}
                  styleColor={secondColor}
                  isFourthColorPaletteNumber={isFourthColorPaletteNumber}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <ValuePropositionCurrent
                  locales={locales}
                  data={portfolio.valueProposition}
                  styleColor={secondColor}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <CompetitorAnalysis
                  locales={locales}
                  data={portfolio.competitorAnalysis}
                  className="competitor-analysis-current"
                  styleColor={secondColor}
                  sectionHeaderTitleColor={secondColor}
                  templateName={CURRENT_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <CustomerRelationships
                  locales={locales}
                  data={portfolio.customerRelationships}
                  className="customer-relationships-current section"
                  styleColor={secondColor}
                  sectionHeaderTitleColor={secondColor}
                  firstGKGColor={secondColor}
                  secondGKGColor={lightGreen}
                  textColor={black}
                  tabColor={secondColor}
                  backgroundBlock={white}
                  templateName={CURRENT_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <ChannelsCurrent
                  locales={locales}
                  data={portfolio.channels}
                  styleColor={secondColor}
                  isFourthColorPaletteNumber={isFourthColorPaletteNumber}
                  disabled={disabled}
                />
              </Lazyload>
              <Feasibility
                locales={locales}
                data={portfolio.feasibility}
                className="feasibility-current"
                sectionHeaderTitleColor={
                  isFourthColorPaletteNumber &&
                  getColorDependsOnBackground(thirdColor)
                }
                descriptionColor={
                  isFourthColorPaletteNumber &&
                  getColorDependsOnBackground(thirdColor)
                }
                backgroundSection={thirdColor}
                templateName={CURRENT_TEMPLATE_NAME}
                disabled={disabled}
              />
              <Lazyload>
                <KeyActivities
                  locales={locales}
                  data={portfolio.keyActivities}
                  className="key-activities-current section relative"
                  styleColor={thirdColor}
                  sectionHeaderTitleColor={thirdColor}
                  titleActivityColor={getCurrentSubtitleColor(
                    colorPaletteNumber,
                    thirdColor,
                    orange,
                  )}
                  titleResourcesColor={getCurrentSubtitleColor(
                    colorPaletteNumber,
                    thirdColor,
                    orange,
                  )}
                  templateName={CURRENT_TEMPLATE_NAME}
                  circleActivityColor={thirdColor}
                  circleResourcesColor={thirdColor}
                  lineActivityColor={thirdColor}
                  disabled={disabled}
                />
              </Lazyload>
              <Viability
                locales={locales}
                data={portfolio.viability}
                className="viability-current"
                sectionHeaderTitleColor={
                  isFourthColorPaletteNumber &&
                  getColorDependsOnBackground(fourthColor)
                }
                descriptionColor={
                  isFourthColorPaletteNumber &&
                  getColorDependsOnBackground(fourthColor)
                }
                backgroundSection={fourthColor}
                templateName={CURRENT_TEMPLATE_NAME}
                disabled={disabled}
              />
              <Finance
                locales={locales}
                data={portfolio.finance}
                className="portfolio-finance portfolio-finance-temp section"
                sectionHeaderTitleColor={fourthColor}
                activeTabColor={getCurrentSubtitleColor(
                  colorPaletteNumber,
                  fourthColor,
                  violet,
                )}
                styleColor={fourthColor}
                firstChartColor={thirdColor}
                secondChartColor={fourthColor}
                separatorColor={getCurrentSubtitleColor(
                  colorPaletteNumber,
                  fourthColor,
                  violet,
                )}
                templateName={CURRENT_TEMPLATE_NAME}
                disabled={disabled}
              />
              <Lazyload>
                <Sustainability
                  locales={locales}
                  data={portfolio.sustainability}
                  className="sustainability-current section"
                  cardClassName="bmc-page-suscard bmc-page-suscard--section"
                  sliderStyles="sdg-card"
                  sectionHeaderTitleColor={fourthColor}
                  backgroundSliderColor={!isFirstColorPallete && fourthColor}
                  styleColor={fourthColor}
                  colorPaletteNumber={colorPaletteNumber}
                  iconFill={colorSusDependsOnBackgroundOrWhite}
                  iconFillTable={colorSusDependsOnBackgroundOrWhite}
                  textSliderColor={colorSusDependsOnBackgroundOrWhite}
                  backgroundIcon={!isFirstColorPallete && fourthColor}
                  templateName={CURRENT_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
            </>
          )}
          <ContactUsCurrent
            locales={locales}
            data={portfolio.contactUs}
            background={secondColor}
            isFourthColorPaletteNumber={isFourthColorPaletteNumber}
            disabled={disabled}
          />
        </StyledWrapper>
      </div>
    </div>
  );
};

export default CurrentTemplate;
