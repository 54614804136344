import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Input } from 'antd';
import Button from '../../main/components/Button';
import ButtonReport from './ButtonReport';
import icons from '../../main/utils/icons';
import ExpandDropdown from './ExpandDropdown';

export default function Header({
  locales: { admin, common },
  breadcrumbs = [],
  header = null,
  leftButtons = <></>,
  disableBreadcrumbs = false,
  classroomShareLink = null,
  downloadReportButton = false,
  downloadButtonParams,
  modalButton = false,
  onModalButtonClick,
  openModalStatus = 'desc',
  expandDropdownVisible = false,
  expandDropdownItems = [],
  expandDropdownClick = () => {},
}) {
  const history = useHistory();
  return (
    <div className="admin-header">
      {!disableBreadcrumbs && (
        <div className="admin-header__row space-between">
          <div className="admin-header__row">
            <Button
              title={common.back}
              onClick={() => history.goBack()}
              icon="icon-Back"
              withIcon
            />

            <div className="admin-breadcrumbs">
              {breadcrumbs.map((breadcrumb, index) => (
                <Link key={index} to={breadcrumb.to}>
                  {index > 0 && '-'}
                  {breadcrumb.title}
                </Link>
              ))}
            </div>
          </div>
          {classroomShareLink && (
            <div className="admin-header__row end">
              <div className="admin-input-label nowrap h-m-10">Share Link:</div>
              <Input
                className="admin-input"
                style={{ width: 300 }}
                value={classroomShareLink}
              />
            </div>
          )}
        </div>
      )}

      <h1 className="admin-header__row space-between">
        <div className="admin-header__title">
          {header || admin.myClassesDashboard}
          {modalButton && (
            <div
              className={`admin-header__title__modal expand-status ${openModalStatus}`}
              onClick={onModalButtonClick}
            >
              {icons.expandIconLarge}
            </div>
          )}
          {expandDropdownVisible && (
            <ExpandDropdown
              items={expandDropdownItems}
              onClick={expandDropdownClick}
            />
          )}
        </div>
        <div>{leftButtons}</div>
        {downloadReportButton && (
          <ButtonReport
            href={downloadButtonParams.href}
            fileName={downloadButtonParams.fileName}
          >
            Download Report
          </ButtonReport>
        )}
      </h1>
    </div>
  );
}
