import * as Route from '../utils/serverRoutes';
import {
  transformResponseCollection,
  updateCollectionById,
  payloadToData,
} from '../utils/helpers';
import makeRequest from '../utils/makeRequest';

const keyActivity = {
  state: {
    collection: [],
    uploading: false,
  },
  reducers: {
    fetchKeyActivitiesReducer(state, { collection }) {
      return { ...state, collection };
    },
    addKeyActivityReducer(state, payload) {
      return { ...state, collection: [...state.collection, payload] };
    },
    updateKeyActivityReducer(state, payload) {
      return {
        ...state,
        collection: updateCollectionById(state.collection, payload.id, payload),
      };
    },
    removeKeyActivityReducer(state, { id }) {
      const collection = state.collection.filter((e) => e.id !== id);

      return { ...state, collection };
    },
    updateAttributeReducer(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    async fetchKeyActivities(bmcId) {
      dispatch.keyActivity.updateAttributeReducer({ uploading: true });

      makeRequest({
        dispatch,
        url: Route.keyActivities(bmcId),
        success: (response) => {
          dispatch.keyActivity.fetchKeyActivitiesReducer({
            collection: transformResponseCollection(response.data.data),
          });
        },
        finish: () => {
          dispatch.keyActivity.updateAttributeReducer({ uploading: false });
        },
      });
    },
    async addKeyActivity(_, state) {
      makeRequest({
        dispatch,
        method: 'post',
        url: Route.keyActivities(state.bmc.current.id),
      });
    },
    async updateKeyActivity(payload, state) {
      const data = payloadToData(payload);
      dispatch.keyActivity.updateKeyActivityReducer(data);

      makeRequest({
        dispatch,
        method: 'put',
        url: Route.keyActivity(state.bmc.current.id, payload.id),
        data: { keyThing: data },
      });
    },
    async removeKeyActivity(payload, state) {
      makeRequest({
        dispatch,
        method: 'delete',
        url: Route.keyActivity(state.bmc.current.id, payload.id),
      });
    },
  }),
};

export default keyActivity;
