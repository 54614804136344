import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

const Button = ({
  className,
  onClick,
  icon,
  title,
  wide,
  heightAuto,
  alignLeft,
  disabled,
  withIcon,
  preview,
  share,
  tooltipIfDisabled,
  tooltipDelayHide,
  primary = false,
  type = 'button',
  ...props
}) => {
  const classes = {
    'bmc-button--wide': wide,
    'bmc-button--height-auto': heightAuto,
    'bmc-button--align-left': alignLeft,
    'bmc-button--disabled': disabled,
    'bmc-button--primary': primary,
    'bmc-button--with-icon': withIcon,
    'bmc-button--preview': preview,
    'bmc-button--share': share,
  };

  return (
    <button
      className={classNames('bmc-button', className, classes)}
      type={type}
      onClick={disabled ? undefined : onClick}
      data-tip={disabled ? tooltipIfDisabled : undefined}
      data-effect="solid"
      {...props}
    >
      {typeof icon === 'string' ? <i className={icon} /> : icon}
      {title && <span>{title}</span>}
      <ReactTooltip className="button-tooltip" delayHide={tooltipDelayHide} />
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  // icon: PropTypes.string, // temporary disabled
  title: PropTypes.string,
  wide: PropTypes.bool,
  heightAuto: PropTypes.bool,
  alignLeft: PropTypes.bool,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
};

export default Button;
