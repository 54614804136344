import React from 'react';

import Textarea from '../../../components/Textarea';
import UploadFiles from '../../../components/UploadFiles';
import { COMMENT_SUMMARY } from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';

const SummaryBlog = ({
  data: { description },
  locales,
  locales: { portfolio },
  updateSectionField,
  textColorDependsOnBackground,
  backgroundSection,
  disabled = false,
}) => {
  return (
    <div
      className="summary-blogFriendly relative"
      style={{ backgroundColor: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_SUMMARY} />
      <div
        className="blogFriendly-title title custom-font-title"
        style={{
          color: textColorDependsOnBackground,
        }}
      >
        {portfolio.projectSummary.sectionName}
      </div>
      <Textarea
        rows={5}
        delay={700}
        value={description}
        onChange={(e) => {
          updateSectionField({
            sectionName: 'projectSummary',
            field: 'description',
            name: 'projectSummary',
            value: e.target.value,
          });
        }}
        className="textarea-blogFriendly textarea-blogFriendly-summary custom-font-body"
        placeholder={portfolio.page.descriptionPlaceholder}
        style={{
          color: textColorDependsOnBackground,
        }}
        disabled={disabled}
      />
      <UploadFiles
        locales={locales}
        className="margin-top-40"
        disabled={disabled}
        uplouderButtonStyle="portfolio-file-uploader__blog"
        fileStyle="portfolio-file-uploader-file-blog"
        removeIconStyle="portfolio-file-uploader-file__remove-blog"
        textButtonColor={textColorDependsOnBackground}
        fileTextColor={textColorDependsOnBackground}
        borderButtonColor={textColorDependsOnBackground}
        fileBorderColor={textColorDependsOnBackground}
      />
    </div>
  );
};

export default SummaryBlog;
