import React from 'react';
import { useSelector } from 'react-redux';

export default function ConfirmationModalBase({
  confirmText = 'Confirm',
  cancelText = 'Cancel',
}) {
  const {
    confirmationModal: { show, body, handleCancel, handleConfirm },
  } = useSelector((state) => state.common);

  if (show) {
    return (
      <div className="confirmation-modal">
        <div className="confirmation-modal__background" />
        <div className="confirmation-modal__body">
          <p className="confirmation-modal__body-title">{body}</p>
          <div className="confirmation-modal__buttons">
            <span
              className="confirmation-modal__cancel-btn"
              onClick={handleCancel}
            >
              {cancelText}
            </span>
            <span
              className="confirmation-modal__confirm-btn"
              onClick={handleConfirm}
            >
              {confirmText}
            </span>
          </div>
        </div>
      </div>
    );
  }
  return null;
}
