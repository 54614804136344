import React from 'react';
import classNames from 'classnames';

export default function TabsListItem({
  title,
  subtitle,
  text,
  icon = 'icon-Hat',
  children,
  className,
}) {
  return (
    <div className={classNames('bmc-page-tabs-content-list-item', className)}>
      {icon && (
        <div className="bmc-page-tabs-content-list-item-icon">
          <div className="bmc-page-tabs-content-list-item-icon-inner">
            {typeof icon === 'string' ? <i className={icon} /> : icon}
          </div>
        </div>
      )}

      {title && (
        <div className="bmc-page-tabs-content-list-item-title">
          <div className="bmc-page-tabs-content-list-item-title-big">
            {title}
          </div>
          {subtitle && (
            <div className="bmc-page-tabs-content-list-item-title-small">
              {subtitle}
            </div>
          )}
        </div>
      )}

      {text && (
        <div className="bmc-page-tabs-content-list-item-text">{text}</div>
      )}

      {children}
    </div>
  );
}
