import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import Introduction from '../blogFriendlyComponents/Introduction';
import Summary from '../blogFriendlyComponents/Summary';
import Team from '../blogFriendlyComponents/Team';
import BMC from '../mainWebTemplateComponents/BMC';
import Testing from '../mainWebTemplateComponents/Testing';
import CustomerSegment from '../blogFriendlyComponents/CustomerSegment';
import ValueProposition from '../blogFriendlyComponents/ValueProposition';
import CompetitorAnalysis from '../mainWebTemplateComponents/CompetitorAnalysis';
import GKG from '../mainWebTemplateComponents/GKG';
import Channels from '../blogFriendlyComponents/Channels';
import KeyActivities from '../mainWebTemplateComponents/KeyActivities';
import Finance from '../mainWebTemplateComponents/Finance';
import Sustainability from '../mainWebTemplateComponents/Sustainability';
import ContactUs from '../blogFriendlyComponents/ContactUs';
import { StyledWrapper } from '../../main/utils/helpers';
import Desirability from '../mainWebTemplateComponents/Desirability';
import Viability from '../mainWebTemplateComponents/Viability';
import Feasibility from '../mainWebTemplateComponents/Feasibility';
import { getColorTemplate } from '../../main/utils/helpers/getColorTemplate';
import {
  BLOG_FRIENDLY_TEMPLATE_NAME,
  BUTTON_MODAL_STYLE_BLOG,
  COLORS,
  COLOR_PALETTE_ARRAY_BLOG,
  TITLE_MODAL_STYLE_BLOG,
} from '../../main/utils/const';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';

const { white, black, transparentWhite } = COLORS;

const BlogFriendlyTemplate = ({
  locales,
  bmcEnabled,
  testing,
  currentSettings,
}) => {
  const [showAll, setShowAll] = useState(false);

  const { sections } = useParams();

  const showSections = useMemo(() => {
    if (!sections) {
      setShowAll(true);
      return [];
    }
    const sectionArray = sections.split('-');
    if (!sectionArray.length) {
      setShowAll(true);
      return [];
    }
    return sectionArray;
  }, [sections]);

  const { contactUs } = useSelector((state) => state.portfolio);

  const {
    portfolioStyle: { templateSettings, activeTemplateName },
    portfolioTemplate: { collection },
  } = useSelector((state) => state);

  const activeTemplate = find(collection, ['sysName', activeTemplateName]);
  const colorTemplate = getColorTemplate(
    templateSettings,
    activeTemplateName,
    activeTemplate,
  );

  if (!colorTemplate) {
    return null;
  }

  const [firstColor, secondColor, thirdColor] = colorTemplate;
  const { shortcuts } = locales.portfolio.export;

  const {
    introduction,
    projectSummary,
    team,
    bmc,
    testing: test,
    desirabilitySection,
    customerSegment,
    valueProposition,
    competitorAnalysis,
    customerRelationships,
    channels,
    feasibility,
    keyActivities,
    viability,
    finance,
    sustainability,
    contactUs: contact,
  } = shortcuts;

  const isShowSections = (sectionName) => {
    return showAll || showSections.includes(sectionName);
  };

  const isFourthColorPaletteNumber = !COLOR_PALETTE_ARRAY_BLOG[secondColor];

  const textColorDependsOnFirstColor =
    isFourthColorPaletteNumber && getColorDependsOnBackground(firstColor);

  const textColorDependsOnSecondColor =
    isFourthColorPaletteNumber && getColorDependsOnBackground(secondColor);

  const textColorDependsOnThirdColor =
    isFourthColorPaletteNumber && getColorDependsOnBackground(thirdColor);

  return (
    <StyledWrapper
      customtitlefont={currentSettings?.customTitleFont}
      customtitlecolor={currentSettings?.customTitleColor}
      custombodyfont={currentSettings?.customBodyFont}
      custombodycolor={currentSettings?.customBodyColor}
    >
      {isShowSections(introduction) && (
        <Introduction
          backgroundSection={thirdColor}
          backgroundContent={secondColor}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}
      {isShowSections(projectSummary) && (
        <Summary
          backgroundSection={secondColor}
          locales={locales}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}
      {isShowSections(team) && (
        <Team
          locales={locales}
          backgroundSection={firstColor}
          secondColor={secondColor}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}
      {bmcEnabled && isShowSections(bmc) && (
        <BMC
          locales={locales}
          className="bm-blogFriendly-canvas bm-blogFriendly-web-canvas"
          sectionClassName="bmc-canvas-section--blogFriendly"
          textStyle="text-card-arch"
          textWithImage="bmc-page-compcard-item-text-template bmc-page-compcard-item-text-template--with-image"
          sliderStyles="sdg-card sdg-card-modal"
          iconTableStyles="sdg-card-blog-icon"
          backgroundMainSection={secondColor}
          iconFill={textColorDependsOnSecondColor || black}
          backgroundIcon={secondColor}
          backgroundItems={`${thirdColor}4D`}
          backgroundSection={white}
          styleColor={thirdColor}
          backgroundRow={`${thirdColor}4D`}
          backgroundImage={transparentWhite}
          backgroundBMC={`${secondColor}33`}
          sectionHeaderTitleColor={textColorDependsOnSecondColor}
          descriptionColor={textColorDependsOnSecondColor}
          titleStyle={TITLE_MODAL_STYLE_BLOG}
          iconFillTable={thirdColor}
          iconFillSlider={thirdColor}
          backgroundIconTable={secondColor}
          backgroundSliderColor={secondColor}
          textSliderColor={textColorDependsOnSecondColor}
          templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
        />
      )}
      {testing && isShowSections(test) && (
        <Testing
          locales={locales}
          className="testing testing-blogFriendly-web"
          testListStyle="tests-list-blog"
          textStyle="text-card-blog"
          backgroundSection={{ backgroundColor: firstColor }}
          thirdColor={thirdColor}
          backgroundCard={`${secondColor}33`}
          inputColor={thirdColor}
          textColor={black}
          rateColor={black}
          linkColor={black}
          titleStyle={TITLE_MODAL_STYLE_BLOG}
          buttonStyle={BUTTON_MODAL_STYLE_BLOG}
          sectionHeaderTitleColor={textColorDependsOnFirstColor}
          descriptionColor={textColorDependsOnFirstColor}
          noTestsColor={textColorDependsOnFirstColor}
          templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(desirabilitySection) && (
        <Desirability
          locales={locales}
          className="desirability-blogFriendly-web"
          backgroundSection={thirdColor}
          sectionHeaderTitleColor={textColorDependsOnThirdColor}
          descriptionColor={textColorDependsOnThirdColor}
          templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(customerSegment) && (
        <CustomerSegment
          locales={locales}
          backgroundSection={firstColor}
          backgroundCard={secondColor}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}

      {bmcEnabled && isShowSections(valueProposition) && (
        <ValueProposition
          locales={locales}
          backgroundSection={firstColor}
          backgroundCard={secondColor}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}
      {bmcEnabled && isShowSections(competitorAnalysis) && (
        <CompetitorAnalysis
          locales={locales}
          className="competitor-analysis-blogFriendly-web"
          backgroundSection={firstColor}
          backgroundImage={secondColor}
          sectionHeaderTitleColor={textColorDependsOnFirstColor}
          descriptionColor={textColorDependsOnFirstColor}
          textTableColor={textColorDependsOnFirstColor}
          headTableColor={textColorDependsOnFirstColor}
          borderTableColor={textColorDependsOnFirstColor}
          titleImageColor={textColorDependsOnSecondColor}
          borderImageColor={textColorDependsOnFirstColor}
          templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(customerRelationships) && (
        <GKG
          locales={locales}
          className="customer-relationships-blogFriendly-web"
          backgroundSection={secondColor}
          sectionHeaderTitleColor={textColorDependsOnSecondColor}
          descriptionColor={textColorDependsOnSecondColor}
          firstTabColor={
            isFourthColorPaletteNumber ? textColorDependsOnSecondColor : black
          }
          secondTabColor={thirdColor}
          textBlockColor={black}
          tabColor={
            isFourthColorPaletteNumber ? textColorDependsOnSecondColor : black
          }
          backgroundBlock={white}
          borderBlockColor={black}
          templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(channels) && (
        <Channels
          locales={locales}
          backgroundSection={firstColor}
          thirdColor={thirdColor}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}
      {bmcEnabled && isShowSections(feasibility) && (
        <Feasibility
          locales={locales}
          className="feasibility-blogFriendly-web"
          backgroundSection={thirdColor}
          sectionHeaderTitleColor={textColorDependsOnThirdColor}
          descriptionColor={textColorDependsOnThirdColor}
          templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(keyActivities) && (
        <KeyActivities
          locales={locales}
          className="key-activities-blogFriendly-web"
          sectionHeaderTitleColor={textColorDependsOnFirstColor}
          descriptionColor={textColorDependsOnFirstColor}
          backgroundSection={firstColor}
          secondColor={secondColor}
          circleActivityColor={secondColor}
          lineActivityColor={
            isFourthColorPaletteNumber ? textColorDependsOnFirstColor : black
          }
          lineResourcesColor={black}
          textActivityColor={textColorDependsOnFirstColor}
          titleActivityColor={textColorDependsOnFirstColor}
          backgroundResources={white}
          templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(viability) && (
        <Viability
          locales={locales}
          className="viability-blogFriendly-web"
          backgroundSection={thirdColor}
          sectionHeaderTitleColor={textColorDependsOnThirdColor}
          descriptionColor={textColorDependsOnThirdColor}
          templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(finance) && (
        <Finance
          locales={locales}
          className="portfolio-finance-blogFriendly portfolio-finance-blogFriendly-temp section"
          backgroundSection={secondColor}
          sectionHeaderTitleColor={textColorDependsOnSecondColor}
          descriptionColor={textColorDependsOnSecondColor}
          secondChartColor={secondColor}
          firstChartColor={thirdColor}
          backgroundUnitTable={secondColor}
          backgroundRow={`${secondColor}80`}
          secondTitleUnitColor={textColorDependsOnSecondColor}
          backgroundForecastTable={secondColor}
          borderVerticalTableColor={secondColor}
          totalColor={thirdColor}
          titleForecastColor={textColorDependsOnSecondColor}
          titleChartColor={textColorDependsOnSecondColor}
          activeTabColor={textColorDependsOnSecondColor}
          tabColor={textColorDependsOnSecondColor}
          templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(sustainability) && (
        <Sustainability
          locales={locales}
          className="sustainability-blogFriendly-web"
          cardClassName="bmc-page-suscard-blog bmc-page-suscard--section"
          sliderStyles="sdg-card-blog"
          iconTableStyles="sdg-card-blog-icon"
          backgroundSection={firstColor}
          sectionHeaderTitleColor={textColorDependsOnFirstColor}
          descriptionColor={textColorDependsOnFirstColor}
          backgroundSliderColor={secondColor}
          backgroundIcon={secondColor}
          iconFill={thirdColor}
          iconFillTable={thirdColor}
          textTableColor={textColorDependsOnFirstColor}
          textSliderColor={textColorDependsOnSecondColor}
          arrowColor={textColorDependsOnFirstColor}
          titleTableColor={textColorDependsOnFirstColor}
          borderTableColor={textColorDependsOnFirstColor}
          templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
        />
      )}
      {isShowSections(contact) && (
        <ContactUs
          data={contactUs}
          locales={locales}
          backgroundSection={thirdColor}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}
    </StyledWrapper>
  );
};

export default BlogFriendlyTemplate;
