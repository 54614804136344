import React from 'react';
import PageComponent from './Page';

const Interview = ({
  nextClickHandler,
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
  onDone,
  test,
  locales,
  locales: {
    testing: {
      name,
      titles,
      testPlan: {
        header,
        interview: { text, title, footer, cardTitle, rate },
      },
    },
  },
}) => {
  return (
    <PageComponent
      cardName={cardTitle}
      handlers={[nextClickHandler, onDone]}
      title={title}
      locales={locales}
      text={text}
      name={name}
      footer={footer}
      header={header}
      stars={rate}
      test={test}
      titles={titles}
      menuItems={menuItems}
      selectComponent={selectComponent}
      componentIndex={componentIndex}
      rules={rules}
      backPath={backPath}
    />
  );
};

export default Interview;
