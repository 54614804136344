import React from 'react';

export default function Icon({
  pathCount = 0,
  pathPrefix = 'path',
  className,
}) {
  return (
    <i className={className}>
      {Array.from(Array(pathCount).keys()).map((_, index) => (
        <path key={index} className={`${pathPrefix}${index + 1}`} />
      ))}
    </i>
  );
}
