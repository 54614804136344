import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';

import DropzoneArea from './DropzoneArea';
import DropzoneModal from './DropzoneModal';
import DropzoneModalTemplates from './DropzoneModalTemplates';

const processImage = (image, cropper) => {
  if (cropper) {
    return [image.original, image.cropped];
  }
  return [null, image];
};

const Dropzone = ({
  small = false,
  cropper = false,
  uploading = false,
  className,
  image,
  coords,
  aspectRatio,
  onDrop,
  onCropperSave,
  locales,
  background,
  disabled = false,
  firstSvgColor,
  secondSvgColor,
  titleStyle,
  buttonStyle,
  sliderColor,
  lightButtonStyle,
  backgroundModal,
  titleColor,
  buttonSecondColor,
  buttonFirstColor,
  backgroundSecondButton,
  borderImageColor,
  isTemplates = false,
  isColourfulfunTemplate = false,
}) => {
  const [modalOpened, setModalOpened] = useState(false);
  const [originalImage, croppedImage] = processImage(image, cropper);

  const openCropperHandler = () => {
    setModalOpened(true);
  };

  const onDropHander = (files) => {
    if (disabled) return;
    onDrop(files, openCropperHandler);
  };

  const dropzone = useDropzone({ onDrop: onDropHander });

  const onAreaClick = (event) => {
    event.stopPropagation();
    setModalOpened(true);
  };

  return (
    <div
      className={classNames('dndzone', className, {
        'dndzone--small': small,
      })}
      style={{ ...background, borderColor: borderImageColor }}
    >
      {cropper &&
        modalOpened &&
        (isTemplates ? (
          <DropzoneModalTemplates
            dropzone={dropzone}
            onCropperSave={onCropperSave}
            coords={coords}
            aspectRatio={aspectRatio}
            originalImage={originalImage}
            setModalOpened={setModalOpened}
            locales={locales}
            uploading={uploading}
            disabled={disabled}
            firstSvgColor={firstSvgColor}
            secondSvgColor={secondSvgColor}
            titleStyle={titleStyle}
            buttonStyle={buttonStyle}
            sliderColor={sliderColor}
            lightButtonStyle={lightButtonStyle}
            backgroundModal={backgroundModal}
            titleColor={titleColor}
            buttonSecondColor={buttonSecondColor}
            buttonFirstColor={buttonFirstColor}
            backgroundSecondButton={backgroundSecondButton}
            isColourfulfunTemplate={isColourfulfunTemplate}
          />
        ) : (
          <DropzoneModal
            dropzone={dropzone}
            onCropperSave={onCropperSave}
            coords={coords}
            aspectRatio={aspectRatio}
            originalImage={originalImage}
            setModalOpened={setModalOpened}
            locales={locales}
            uploading={uploading}
            disabled={disabled}
          />
        ))}
      <DropzoneArea
        dropzone={dropzone}
        coords={coords}
        onAreaClick={onAreaClick}
        croppedImage={croppedImage}
        aspectRatio={aspectRatio}
        locales={locales}
        small={small}
        uploading={uploading}
        cropper={cropper}
        borderImageColor={borderImageColor}
        disabled={disabled}
      />
    </div>
  );
};

Dropzone.propTypes = {
  small: PropTypes.bool,
  cropper: PropTypes.bool,
  className: PropTypes.string,
  // image: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  locales: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default Dropzone;
