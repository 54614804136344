export const getIndexColor = (i) => {
  if (i === 0 || i % 4 === 0) {
    return 0;
  }
  if (i === 1 || i % 4 === 1) {
    return 1;
  }
  if (i % 2 === 0 && i % 4 !== 0) {
    return 2;
  }
  if (i !== 0 && i % 3 === 0) {
    return 3;
  }
};
