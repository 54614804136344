import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Textarea from '../../components/Textarea';
import Dropzone from '../../components/Dropzone';
import Img from '../../components/Img';
import { IMAGE_ASPECT_RATIO_ONE } from '../../utils/const';
import { getIndexColor } from '../../utils/helpers/getIndexColor';
import { getColorDependsOnBackground } from '../../utils/helpers/getColorDependsOnBackground';

const renderImage = (
  image,
  title = '',
  backgroundImage,
  titleImageColor,
  borderImageColor,
) => {
  const safeTitle = title ? `${title[0]}` : undefined;

  return image.cropped ? (
    <div
      className="bmc-page-compcard-item-image-template"
      style={{ borderColor: borderImageColor }}
    >
      <Img src={image.cropped} aspectRatio={IMAGE_ASPECT_RATIO_ONE} />
    </div>
  ) : (
    <div
      className="bmc-page-compcard-item-image-template bmc-page-compcard-item-image--no-image-templates custom-font-title"
      style={{
        background: backgroundImage,
        color: titleImageColor,
        borderColor: borderImageColor,
      }}
    >
      {safeTitle}
    </div>
  );
};

export default function CompetitorsCardTable({
  editMode = false,
  isPreview = false,
  disabled = false,
  locales,
  card: { name, doWell, doBadly, competitors },
  className,
  colorTemplate,
  backgroundTable,
  backgroundHeadTable,
  borderTableColor,
  backgroundImage,
  headTableColor,
  textColor,
  firstSvgColor,
  secondSvgColor,
  titleStyle,
  buttonStyle,
  lightButtonStyle,
  sliderColor,
  backgroundModal,
  titleColor,
  buttonSecondColor,
  buttonFirstColor,
  backgroundSecondButton,
  titleImageColor,
  borderImageColor,
  isTemplates = false,
  isColourfulfunTemplate = false,
}) {
  const {
    competitor: {
      updateCompetitor,
      updateCompetitorImage,
      updateCompetitorImageCoords,
    },
  } = useDispatch();

  const { uploadingImage } = useSelector((state) => state.competitor);

  const onImageDrop = ([file], openCropperHandler, { id }) => {
    updateCompetitorImage({
      id,
      name: 'image',
      blob: file,
      success: () => {
        openCropperHandler();
      },
    });
  };

  const onImageCropperSave = (
    croppedAreaPixels,
    innerCropData,
    zoom,
    { id, imageCoords },
  ) => {
    updateCompetitorImageCoords({
      id,
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: imageCoords,
    });
  };

  return (
    <table
      className={`${className} table`}
      style={{ backgroundColor: backgroundHeadTable }}
    >
      <thead>
        <tr>
          <th
            className="width-20 custom-font-body"
            style={{ color: headTableColor }}
          >
            {name}
          </th>
          <th
            className="width-40 custom-font-body"
            style={{ color: headTableColor }}
          >
            {doWell}
          </th>
          <th
            className="width-40 custom-font-body"
            style={{ color: headTableColor }}
          >
            {doBadly}
          </th>
        </tr>
      </thead>
      <tbody>
        {competitors.map((competitor, i) => {
          getIndexColor(i);
          return editMode ? (
            <tr key={competitor.id}>
              <td style={{ borderTopColor: borderTableColor }}>
                <div className="bmc-page-compcard-item-text-template bmc-page-compcard-item-text-template--with-image">
                  <div className="bmc-page-compcard-item-image-template-hover">
                    <Dropzone
                      onDrop={(files, openHandler) =>
                        onImageDrop(files, openHandler, competitor)
                      }
                      onCropperSave={(pixels, data, zoom) =>
                        onImageCropperSave(pixels, data, zoom, competitor)
                      }
                      small
                      locales={locales}
                      image={competitor.image}
                      coords={competitor.imageCoords}
                      uploading={uploadingImage === competitor.id}
                      aspectRatio={IMAGE_ASPECT_RATIO_ONE}
                      className="image-upload"
                      background={{
                        backgroundColor:
                          colorTemplate[getIndexColor(i)] || backgroundImage,
                      }}
                      cropper
                      firstSvgColor={firstSvgColor}
                      secondSvgColor={secondSvgColor}
                      titleStyle={titleStyle}
                      buttonStyle={buttonStyle}
                      lightButtonStyle={lightButtonStyle}
                      sliderColor={sliderColor}
                      backgroundModal={backgroundModal}
                      titleColor={titleColor}
                      buttonSecondColor={buttonSecondColor}
                      buttonFirstColor={buttonFirstColor}
                      backgroundSecondButton={backgroundSecondButton}
                      borderImageColor={borderImageColor}
                      isTemplates={isTemplates}
                      isColourfulfunTemplate={isColourfulfunTemplate}
                    />
                  </div>

                  <Textarea
                    value={competitor.name}
                    delay={700}
                    className={classNames(
                      'textarea-current',
                      'textarea-current-analysis-title',
                    )}
                    placeholder={locales.portfolio.page.informationPlaceholder}
                    onChange={(e) =>
                      updateCompetitor({
                        id: competitor.id,
                        name: 'name',
                        value: e.target.value,
                      })
                    }
                  />
                </div>
              </td>

              <td>
                <div className="bmc-page-compcard-item-text-template">
                  <Textarea
                    value={competitor.doWell}
                    delay={700}
                    className={classNames(
                      'textarea-current',
                      'textarea-current-analysis',
                    )}
                    placeholder={locales.portfolio.page.informationPlaceholder}
                    onChange={(e) =>
                      updateCompetitor({
                        id: competitor.id,
                        name: 'doWell',
                        value: e.target.value,
                      })
                    }
                  />
                </div>
              </td>

              <td>
                <div className="bmc-page-compcard-item-text-template">
                  <Textarea
                    value={competitor.doBadly}
                    delay={700}
                    className={classNames(
                      'textarea-current',
                      'textarea-current-analysis',
                    )}
                    placeholder={locales.portfolio.page.informationPlaceholder}
                    onChange={(e) =>
                      updateCompetitor({
                        id: competitor.id,
                        name: 'doBadly',
                        value: e.target.value,
                      })
                    }
                  />
                </div>
              </td>
            </tr>
          ) : (
            <tr
              key={competitor.id}
              style={{
                borderColor: borderTableColor,
                backgroundColor: backgroundTable,
              }}
            >
              <td style={{ borderColor: borderTableColor }}>
                <div className="bmc-page-compcard-item-text-template bmc-page-compcard-item-text-template--with-image">
                  {isPreview ? (
                    renderImage(
                      competitor.image,
                      competitor.name,
                      colorTemplate
                        ? colorTemplate[getIndexColor(i)]
                        : backgroundImage,
                      colorTemplate
                        ? getColorDependsOnBackground(
                            colorTemplate[getIndexColor(i)],
                          )
                        : titleImageColor,
                      borderImageColor,
                    )
                  ) : (
                    <div
                      className={classNames(
                        'bmc-page-compcard-item-image-template-hover',
                      )}
                    >
                      <Dropzone
                        onDrop={(files, openHandler) =>
                          onImageDrop(files, openHandler, competitor)
                        }
                        onCropperSave={(pixels, data, zoom) =>
                          onImageCropperSave(pixels, data, zoom, competitor)
                        }
                        small
                        locales={locales}
                        image={competitor.image}
                        coords={competitor.imageCoords}
                        uploading={uploadingImage === competitor.id}
                        className="image-upload"
                        background={{
                          background: colorTemplate
                            ? colorTemplate[getIndexColor(i)]
                            : backgroundImage,
                        }}
                        aspectRatio={IMAGE_ASPECT_RATIO_ONE}
                        disabled={disabled}
                        cropper
                        firstSvgColor={firstSvgColor}
                        secondSvgColor={secondSvgColor}
                        titleStyle={titleStyle}
                        buttonStyle={buttonStyle}
                        lightButtonStyle={lightButtonStyle}
                        sliderColor={sliderColor}
                        backgroundModal={backgroundModal}
                        titleColor={titleColor}
                        buttonSecondColor={buttonSecondColor}
                        buttonFirstColor={buttonFirstColor}
                        backgroundSecondButton={backgroundSecondButton}
                        borderImageColor={borderImageColor}
                        isTemplates={isTemplates}
                        isColourfulfunTemplate={isColourfulfunTemplate}
                      />
                    </div>
                  )}
                  <div
                    className="custom-font-body"
                    style={{ color: textColor }}
                  >
                    {competitor.name}
                  </div>
                </div>
                <div
                  className="border-right"
                  style={{ backgroundColor: borderTableColor }}
                />
              </td>
              <td style={{ borderColor: borderTableColor }}>
                <div>
                  <div
                    className="bmc-page-compcard-item-title custom-font-body"
                    style={{ color: headTableColor }}
                  >
                    {doWell}
                  </div>
                  <div
                    className="bmc-page-compcard-item-text-template bmc-page-compcard-item-text-template-middle custom-font-body"
                    style={{ color: textColor }}
                  >
                    {competitor.doWell}
                  </div>
                </div>
                <div
                  className="border-right"
                  style={{ backgroundColor: borderTableColor }}
                />
              </td>
              <td style={{ borderColor: borderTableColor }}>
                <div
                  className="bmc-page-compcard-item-title custom-font-body"
                  style={{ color: headTableColor }}
                >
                  {doBadly}
                </div>

                <div
                  className="bmc-page-compcard-item-text-template custom-font-body"
                  style={{ color: textColor }}
                >
                  {competitor.doBadly}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
