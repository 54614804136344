import moment from 'moment';
import React from 'react';

export default function ChatGroupItemStateComponent({
  createdAt = null,
  unreadCommentCount = 0,
  mentionCommentCount = 0,
}) {
  if (createdAt || unreadCommentCount > 0 || mentionCommentCount > 0)
    return (
      <div className="chat-group-item-component__state">
        <div className="chat-group-item-component__last-commit-at">
          {moment(createdAt).format('DD MMM YYYY HH:mm')}
        </div>
        <div className="chat-group-item-component__badges">
          {unreadCommentCount > 0 && (
            <div className="chat-group-item-component__unread-count">
              {unreadCommentCount}
            </div>
          )}
          {mentionCommentCount > 0 && (
            <div className="chat-group-item-component__mention-count">@</div>
          )}
        </div>
      </div>
    );

  return (
    <div className="chat-group-item-component__no-comment">No comments</div>
  );
}
