import React from 'react';
import { useSelector } from 'react-redux';
import BmcCanvas from '../../main/containers/bmc/BmcCanvas';
import { TECH_MODERN_TEMPLATE_NAME } from '../../main/utils/const';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';

const BMC = ({
  locales,
  locales: { portfolioWeb },
  titleColor,
  backgroundCanvas,
  iconFill,
  backgroundIcon,
  backgroundItems,
  backgroundSection,
  background,
}) => {
  const { bmc } = useSelector((state) => state.portfolio);

  return (
    <div
      className="bm-tech-web-canvas section-web"
      style={{ backgroundColor: background }}
    >
      <div className="bmc">
        <SectionHeaderWeb
          templateName={TECH_MODERN_TEMPLATE_NAME}
          title={portfolioWeb.bmc}
          description={bmc.description}
          portfolioWeb={portfolioWeb}
          style={{ color: titleColor }}
        />
        <BmcCanvas
          locales={locales}
          isPreview
          className="bmc-canvas--scrollable-portfolio-web"
          isProgressBar={false}
          backgroundCanvas={backgroundCanvas}
          iconFill={iconFill}
          backgroundIcon={backgroundIcon}
          backgroundItems={backgroundItems}
          backgroundSection={backgroundSection}
          templateName={TECH_MODERN_TEMPLATE_NAME}
          sectionClassName="bmc-canvas-section--tech"
          sliderStyles="sdg-card sdg-card-modal"
        />
      </div>
    </div>
  );
};

export default BMC;
