import classNames from 'classnames';
import React from 'react';
import icons from '../../../../utils/icons';

const IconBlock = ({ active, icon = null, className }) => {
  return (
    <div className={classNames('icon-block', className, { active })}>
      {icon || (active && icons.checkedIcon)}
    </div>
  );
};

export default IconBlock;
