import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BmcCanvasSection from './BmcCanvasSection';
import ChannelCard from '../../channel/Card';
import CardModal from '../CardModal';
import SvgIcon from '../../../components/SvgIcon';
import { collectionEmpty, itemHasContent } from '../../../utils/helpers';

export default function Channels({
  locales,
  locales: { canvas },
  isDark,
  isPreview,
  isProgressBar,
  backgroundIcon,
  backgroundItems,
  backgroundSection,
  borderSectionColor,
  titleBMCSectionColor,
  textColor,
  iconFill,
  iconFillClose,
  templateName,
  sectionClassName,
  firstSvgColor,
  secondSvgColor,
  titleStyle,
  buttonStyle,
  iconStyle,
  styleColor,
  backgroundBMC,
  textStyle,
  backgroundRow,
  backgroundImage,
  titleColor,
  buttonColor,
  backgroundButton,
  titleImageColor,
  isTemplates = false,
  isColourfulfunTemplate = false,
  disabled = false,
}) {
  const channelFields = ['name', 'description', 'image'];

  const {
    channel,
    bmc: {
      current: { id },
    },
  } = useSelector((state) => state);

  const {
    channel: { fetchChannels },
  } = useDispatch();

  const [showChannelModal, setShowChannelModal] = useState(false);

  return (
    <>
      {showChannelModal && (
        <CardModal
          onBlackscreenClick={() => setShowChannelModal(false)}
          title={canvas.channel}
          icon={!templateName ? 'icon-channel' : <SvgIcon name="iconChanel" />}
          to={`/bmcs/${id}/channel/intro`}
          className="custom-font-body"
          locales={locales}
          isPreview={isPreview}
          onOpen={() => fetchChannels(id)}
          isOpen={showChannelModal}
          titleStyle={titleStyle}
          buttonStyle={buttonStyle}
          firstSvgColor={firstSvgColor}
          secondSvgColor={secondSvgColor}
          iconFill={iconFill}
          iconStyle={iconStyle}
          backgroundIcon={backgroundIcon}
          backgroundBMC={backgroundBMC}
          titleColor={titleColor}
          buttonColor={buttonColor}
          backgroundButton={backgroundButton}
          iconFillClose={iconFillClose}
          isColourfulfunTemplate={isColourfulfunTemplate}
          disabled={disabled}
        >
          {channel.collection.map((item) => (
            <ChannelCard
              locales={locales}
              key={item.id}
              blur={false}
              editMode={!isPreview}
              className="margin-bottom-20"
              card={{
                ...locales.channel.intro.card,
                ...item,
                title: null,
              }}
              backgroundCard={backgroundRow}
              textStyle={textStyle}
              styleColor={styleColor}
              backgroundImage={backgroundImage}
              templateName={templateName}
              titleStyle={titleStyle}
              buttonStyle={buttonStyle}
              firstSvgColor={firstSvgColor}
              secondSvgColor={secondSvgColor}
              textColor={textColor}
              titleImageColor={titleImageColor}
              isTemplates={isTemplates}
              isColourfulfunTemplate={isColourfulfunTemplate}
            />
          ))}
        </CardModal>
      )}

      <BmcCanvasSection
        className={`bmc-canvas-section--skinny bmc-canvas-section--short bmc-canvas-section--green bmc-canvas-section-7 ${sectionClassName}`}
        title={canvas.channel}
        dark={isDark}
        showButton={
          !isPreview && collectionEmpty(channel.collection, channelFields)
        }
        to={`/bmcs/${id}/channel/intro`}
        locales={locales}
        icon={!templateName ? 'icon-channel' : <SvgIcon name="iconChanel" />}
        isPreview={isPreview}
        isProgressBar={isProgressBar}
        uploading={channel.uploading}
        onClick={() => setShowChannelModal(true)}
        backgroundIcon={backgroundIcon}
        backgroundSection={backgroundSection}
        borderSectionColor={borderSectionColor}
        titleBMCSectionColor={titleBMCSectionColor}
        iconFill={iconFill}
        iconStyle={iconStyle}
        templateName={templateName}
      >
        {channel.collection.map(
          (item) =>
            itemHasContent(item, channelFields) && (
              <div
                key={item.id}
                className="bmc-canvas-section-items-item custom-font-body"
                style={{ backgroundColor: backgroundItems, color: textColor }}
                onClick={() => setShowChannelModal(true)}
              >
                {item.name || '-'}
              </div>
            ),
        )}
      </BmcCanvasSection>
    </>
  );
}
