import React from 'react';

import Textarea from '../../../components/Textarea';
import UploadFiles from '../../../components/UploadFiles';
import SvgIcon from '../../../components/SvgIcon';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import { COLORS, COMMENT_SUMMARY } from '../../../utils/const';

const { black, white, transparentWhite } = COLORS;

const SummaryArch = ({
  data: { description },
  locales,
  locales: { portfolio },
  updateSectionField,
  textColorDependsOnBackground,
  backgroundSection,
  disabled = false,
}) => {
  return (
    <div
      className="summary-architectural relative"
      style={{ backgroundColor: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_SUMMARY} />
      <div style={{ fill: textColorDependsOnBackground === white && white }}>
        <SvgIcon name="summary" />
      </div>

      <div className="content">
        <div className="right">
          <div
            className="architectural-title title custom-font-title"
            style={{ color: textColorDependsOnBackground }}
          >
            {portfolio.projectSummary.sectionName}
          </div>
          <UploadFiles
            locales={locales}
            className="margin-top-40"
            disabled={disabled}
            uplouderButtonStyle="portfolio-file-uploader__arch"
            fileStyle="portfolio-file-uploader-file-arch-link"
            removeIconStyle="portfolio-file-uploader-file__remove-arch"
            fileTextColor={textColorDependsOnBackground === white && white}
            fileBackgroundColor={
              textColorDependsOnBackground === white ? transparentWhite : black
            }
            fileBorderColor={
              textColorDependsOnBackground === white ? white : black
            }
            borderButtonColor={textColorDependsOnBackground}
            textButtonColor={textColorDependsOnBackground}
          />
        </div>
        <div className="left">
          <Textarea
            rows={5}
            delay={700}
            value={description}
            onChange={(e) => {
              updateSectionField({
                sectionName: 'projectSummary',
                field: 'description',
                name: 'projectSummary',
                value: e.target.value,
              });
            }}
            className="textarea-architectural custom-font-body"
            style={{ color: textColorDependsOnBackground }}
            placeholder={portfolio.page.descriptionPlaceholder}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default SummaryArch;
