import React from 'react';
import classNames from 'classnames';

export default function TabsHeader({ tabs, currentTab, setCurrentTab }) {
  return (
    <div className="bmc-page-tabs-header">
      {tabs.map((tab, index) => (
        <div
          key={index}
          onClick={() => setCurrentTab(index)}
          className={classNames('bmc-page-tabs-header-tab', {
            'bmc-page-tabs-header-tab--active': currentTab === index,
          })}
        >
          {tab}
        </div>
      ))}
    </div>
  );
}
