import React from 'react';
import { useDispatch } from 'react-redux';

import SectionHeader from '../SectionHeader';
import Textarea from '../../../components/Textarea';
import FormInput from '../../../components/FormInput';

const ContactUs = ({
  data: { showStatus, description, fullName, emailOrWebsite },
  locales: { portfolio },
  disabled = false,
}) => {
  const {
    portfolio: { updateSectionField, updateSectionFieldReducer },
  } = useDispatch();

  const setShowStatus = (status) => {
    updateSectionFieldReducer({
      sectionName: 'contactUs',
      field: 'showStatus',
      value: status,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateSectionField({
      sectionName: 'contactUs',
      field: name,
      value,
      name,
    });
  };

  return (
    <div className="contact-us margin-bottom-40">
      <SectionHeader
        locales={portfolio}
        showStatus={showStatus}
        sectionName={portfolio.contactUs.sectionName}
        setShowStatus={setShowStatus}
      />
      {showStatus && (
        <>
          <Textarea
            name="textarea"
            rows={5}
            delay={700}
            value={description}
            onChange={(e) =>
              updateSectionField({
                sectionName: 'contactUs',
                field: 'description',
                name: 'contactUsText',
                value: e.target.value,
              })
            }
            className="bmc-form-textarea textarea"
            placeholder={portfolio.page.descriptionPlaceholder}
            disabled={disabled}
          />
          <div className="inputs">
            <FormInput
              name="fullName"
              label={portfolio.contactUs.form.fullName}
              defaultValue={fullName}
              delay={700}
              placeholder={portfolio.page.descriptionPlaceholder}
              onChange={handleChange}
              disabled={disabled}
            />
            <FormInput
              name="emailOrWebsite"
              label={portfolio.contactUs.form.emailOrWebsite}
              defaultValue={emailOrWebsite}
              delay={700}
              placeholder={portfolio.page.descriptionPlaceholder}
              onChange={handleChange}
              disabled={disabled}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ContactUs;
