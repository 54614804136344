import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BmcCanvasSection from './BmcCanvasSection';
import GKGTabs from '../../getKeepGrow/GKGTabsTemplates';
import CardModal from '../CardModal';
import SvgIcon from '../../../components/SvgIcon';
import { collectionEmpty, itemHasContent } from '../../../utils/helpers';

export default function CustomerRelationships({
  locales,
  locales: { canvas },
  isDark,
  isPreview,
  isProgressBar,
  backgroundIcon,
  backgroundItems,
  backgroundSection,
  borderSectionColor,
  titleBMCSectionColor,
  textColor,
  iconFill,
  iconFillClose,
  templateName,
  sectionClassName,
  firstSvgColor,
  secondSvgColor,
  titleStyle,
  buttonStyle,
  iconStyle,
  styleColor,
  backgroundBMC,
  backgroundBlock,
  textStyle,
  titleColor,
  buttonColor,
  backgroundButton,
  isColourfulfunTemplate = false,
  disabled = false,
}) {
  const strategyFields = ['name', 'description'];
  const [showGKGModal, setShowGKGModal] = useState(false);

  const {
    getKeepGrow,
    getKeepGrow: {
      getStrategies,
      keepStrategies,
      growStrategies,
      referralStrategies,
    },
    bmc: {
      current: { id },
    },
  } = useSelector((state) => state);

  const {
    getKeepGrow: { fetchGetKeepGrows },
  } = useDispatch();

  return (
    <>
      {showGKGModal && (
        <CardModal
          onBlackscreenClick={() => setShowGKGModal(false)}
          title={canvas.getKeepGrow}
          icon={
            !templateName ? (
              <SvgIcon name="gkg" />
            ) : (
              <SvgIcon name="iconGetKeepGrow" />
            )
          }
          to={`/bmcs/${id}/get_keep_grow/intro`}
          className="custom-font-body"
          locales={locales}
          onOpen={() => fetchGetKeepGrows(id)}
          isOpen={showGKGModal}
          isPreview={isPreview}
          titleStyle={titleStyle}
          buttonStyle={buttonStyle}
          firstSvgColor={firstSvgColor}
          secondSvgColor={secondSvgColor}
          iconFill={iconFill}
          iconStyle={iconStyle}
          backgroundIcon={backgroundIcon}
          backgroundBMC={backgroundBMC}
          titleColor={titleColor}
          buttonColor={buttonColor}
          backgroundButton={backgroundButton}
          iconFillClose={iconFillClose}
          isColourfulfunTemplate={isColourfulfunTemplate}
          disabled={disabled}
        >
          <GKGTabs
            showIcons={false}
            editMode={!isPreview}
            content={{
              ...locales.getKeepGrow.intro.gkgTabs,
              title: false,
              getStrategies,
              keepStrategies,
              growStrategies,
              referralStrategies,
            }}
            textStyle={textStyle}
            buttonStyle={buttonStyle}
            backgroundBlock={backgroundBlock}
            tabColor={styleColor}
            textColor={textColor}
            titleTabsColor={textColor}
          />
        </CardModal>
      )}

      <BmcCanvasSection
        className={`"bmc-canvas-section--skinny bmc-canvas-section--short bmc-canvas-section--green bmc-canvas-section-6 ${sectionClassName}`}
        title={canvas.getKeepGrow}
        dark={isDark}
        showButton={
          !isPreview &&
          collectionEmpty(getStrategies, strategyFields) &&
          collectionEmpty(keepStrategies, strategyFields) &&
          collectionEmpty(growStrategies, strategyFields) &&
          collectionEmpty(referralStrategies, strategyFields)
        }
        to={`/bmcs/${id}/get_keep_grow/intro`}
        locales={locales}
        icon={
          !templateName ? (
            <SvgIcon name="gkg" />
          ) : (
            <SvgIcon name="iconGetKeepGrow" />
          )
        }
        isPreview={isPreview}
        isProgressBar={isProgressBar}
        uploading={getKeepGrow.uploading}
        onClick={() => setShowGKGModal(true)}
        backgroundIcon={backgroundIcon}
        backgroundSection={backgroundSection}
        borderSectionColor={borderSectionColor}
        titleBMCSectionColor={titleBMCSectionColor}
        iconFill={iconFill}
        iconStyle={iconStyle}
        templateName={templateName}
      >
        {!collectionEmpty(getStrategies, strategyFields) && (
          <>
            <div
              className="bmc-canvas-section-title custom-font-body"
              style={{ color: titleBMCSectionColor }}
            >
              {canvas.get}
            </div>
            {getStrategies.map(
              (item, index) =>
                itemHasContent(item, strategyFields) && (
                  <div
                    key={index}
                    className="bmc-canvas-section-items-item custom-font-body"
                    style={{
                      backgroundColor: backgroundItems,
                      color: textColor,
                    }}
                    onClick={() => setShowGKGModal(true)}
                  >
                    {item.name || '-'}
                  </div>
                ),
            )}
          </>
        )}

        {!collectionEmpty(keepStrategies, strategyFields) && (
          <>
            <div
              className="bmc-canvas-section-title custom-font-body"
              style={{ color: titleBMCSectionColor }}
            >
              {canvas.keep}
            </div>
            {keepStrategies.map(
              (item, index) =>
                itemHasContent(item, strategyFields) && (
                  <div
                    key={index}
                    className="bmc-canvas-section-items-item custom-font-body"
                    style={{
                      backgroundColor: backgroundItems,
                      color: textColor,
                    }}
                    onClick={() => setShowGKGModal(true)}
                  >
                    {item.name || '-'}
                  </div>
                ),
            )}
          </>
        )}

        {!collectionEmpty(growStrategies, strategyFields) && (
          <>
            <div
              className="bmc-canvas-section-title custom-font-body"
              style={{ color: titleBMCSectionColor }}
            >
              {canvas.grow}
            </div>
            {growStrategies.map(
              (item, index) =>
                itemHasContent(item, strategyFields) && (
                  <div
                    key={index}
                    className="bmc-canvas-section-items-item custom-font-body"
                    onClick={() => setShowGKGModal(true)}
                    style={{
                      backgroundColor: backgroundItems,
                      color: textColor,
                    }}
                  >
                    {item.name || '-'}
                  </div>
                ),
            )}
          </>
        )}

        {!collectionEmpty(referralStrategies, strategyFields) && (
          <>
            <div
              className="bmc-canvas-section-title custom-font-body"
              style={{ color: titleBMCSectionColor }}
            >
              {canvas.referrals}
            </div>
            {referralStrategies.map(
              (item, index) =>
                itemHasContent(item, strategyFields) && (
                  <div
                    key={index}
                    className="bmc-canvas-section-items-item custom-font-body"
                    onClick={() => setShowGKGModal(true)}
                    style={{
                      backgroundColor: backgroundItems,
                      color: textColor,
                    }}
                  >
                    {item.name || '-'}
                  </div>
                ),
            )}
          </>
        )}
      </BmcCanvasSection>
    </>
  );
}
