import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import useMediaQuery from 'react-use-media-query-hook';

import Img from '../../components/Img';
import { STRATEGIES_MAX_COUNT } from '../../utils/const';
import SvgIcon from '../../components/SvgIcon';
import { getSvgGKGTabs } from '../../utils/helpers/getSvgGKGTabs';
import { getRenderBlockGKG } from '../../utils/helpers/getRenderBlockGKG';

export default function GKGTabsTemplates({
  content,
  className,
  showIcons = true,
  editMode = false,
  portfolioWebMode = false,
  firstColor,
  secondColor,
  textColor,
  tabColor,
  titleTabsColor,
  backgroundBlock,
  borderBlockColor,
  content: {
    icon,
    title,
    getStrategies,
    keepStrategies,
    growStrategies,
    referralStrategies,
  },
  textStyle,
}) {
  const isMobile = useMediaQuery('(max-width: 480px)');
  const [tab, setTab] = useState(0);
  const {
    getKeepGrow: { addStrategy, updateStrategy, removeStrategy },
  } = useDispatch();
  const tabs = ['get', 'keep', 'grow', 'referral'];
  const data = [
    getStrategies,
    keepStrategies,
    growStrategies,
    referralStrategies,
  ];
  const items = data[tab];
  const currentTab = tabs[tab];

  const renderItems = (name, type) => {
    return (
      <>
        <div
          className="bmc-page-gkgtabs-content-title"
          style={{ color: textColor }}
        >
          {name}
        </div>
        {getRenderBlockGKG(
          type,
          editMode,
          currentTab,
          updateStrategy,
          removeStrategy,
          backgroundBlock,
          borderBlockColor,
          titleTabsColor,
          textColor,
          textStyle,
        )}
      </>
    );
  };

  return (
    <div
      className={classNames('bmc-page-gkgtabs', className, {
        'bmc-page-gkgtabs--portfolio-web': portfolioWebMode,
        'bmc-page-gkgtabs--portfolio': editMode,
      })}
    >
      {title && (
        <div className="bmc-page-gkgtabs-header">
          <div className="bmc-page-gkgtabs-header-icon">
            <Img src={icon} />
          </div>
          <div className="bmc-page-gkgtabs-header-title custom-font-title">
            {title}
          </div>
        </div>
      )}
      {!isMobile && (
        <div className="bmc-page-gkgtabs-tabs">
          {tabs.map((tabName, index) => (
            <div
              key={index}
              className={classNames('bmc-page-gkgtabs-tabs-tab', {
                'bmc-page-gkgtabs-tabs-tab--active': tab === index,
              })}
              onClick={() => setTab(index)}
            >
              {showIcons && (
                <div className="bmc-page-gkgtabs-tabs-tab-icon">
                  <div
                    className="svg-funCRget"
                    style={{ fill: secondColor, stroke: secondColor }}
                  >
                    {tab === index ? (
                      getSvgGKGTabs(index, firstColor)
                    ) : (
                      <SvgIcon name="funCRget" />
                    )}
                  </div>
                </div>
              )}
              <div
                className={classNames(
                  'bmc-page-gkgtabs-tabs-tab-title bmc-page-gkgtabs-tabs-tab-title-colourful custom-font-body',
                  textStyle,
                  {
                    'bmc-page-gkgtabs-tabs-tab--active': tab === index,
                  },
                )}
                style={{ color: tabColor, borderBottomColor: tabColor }}
              >
                {content[tabName]}
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="bmc-page-gkgtabs-content">
        {portfolioWebMode && isMobile ? (
          <>
            {getStrategies.length > 0 &&
              getStrategies[0].name.length > 0 &&
              renderItems('Get', getStrategies)}
            {keepStrategies.length > 0 &&
              keepStrategies[0].name.length > 0 &&
              renderItems('Keep', keepStrategies)}
            {growStrategies.length > 0 &&
              growStrategies[0].name.length > 0 &&
              renderItems('Grow', growStrategies)}
            {referralStrategies.length > 0 &&
              referralStrategies[0].name.length > 0 &&
              renderItems('Referrals', referralStrategies)}
          </>
        ) : (
          items.length > 0 &&
          items[0].name.length > 0 &&
          getRenderBlockGKG(
            items,
            editMode,
            currentTab,
            updateStrategy,
            removeStrategy,
            backgroundBlock,
            borderBlockColor,
            titleTabsColor,
            textColor,
            textStyle,
          )
        )}

        {editMode && items.length < STRATEGIES_MAX_COUNT && (
          <div
            className="bmc-page-gkgtabs-content-add relative"
            style={{ background: backgroundBlock }}
            onClick={() =>
              addStrategy({ collectionName: `${tabs[tab]}Strategies` })
            }
          >
            <i className="icon-Add" />
          </div>
        )}
      </div>
    </div>
  );
}
