import React from 'react';
import SelectV3 from '../../Components/Select/SelectV3';
import bmcBlockTestedOptions from '../../../../utils/options/bmcBlockTestedOptions';

const SelectBmcBlockTested = ({ value, onChange, textColor, textStyle }) => {
  const handleChange = (selectedValues) => onChange(selectedValues);
  return (
    <SelectV3
      options={bmcBlockTestedOptions}
      value={value}
      onChange={handleChange}
      textColor={textColor}
      textStyle={textStyle}
    />
  );
};

export default SelectBmcBlockTested;
