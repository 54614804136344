import * as Route from '../utils/serverRoutes';
import {
  transformResponseCollection,
  updateCollectionById,
  payloadToData,
} from '../utils/helpers';
import makeRequest from '../utils/makeRequest';

const revenue = {
  state: {
    collection: [],
    uploading: false,
  },
  reducers: {
    fetchRevenuesReducer(state, { collection }) {
      return { ...state, collection };
    },
    addRevenueReducer(state, payload) {
      return { ...state, collection: [...state.collection, payload] };
    },
    updateRevenueReducer(state, payload) {
      return {
        ...state,
        collection: updateCollectionById(state.collection, payload.id, payload),
      };
    },
    removeRevenueReducer(state, { id }) {
      const collection = state.collection.filter((e) => e.id !== id);

      return { ...state, collection };
    },
    updateAttributeReducer(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    async fetchRevenues(bmcId) {
      dispatch.revenue.updateAttributeReducer({ uploading: true });

      makeRequest({
        dispatch,
        url: Route.revenues(bmcId),
        success: (response) => {
          dispatch.revenue.fetchRevenuesReducer({
            collection: transformResponseCollection(response.data.data),
          });
        },
        finish: () => {
          dispatch.revenue.updateAttributeReducer({ uploading: false });
        },
      });
    },
    async addRevenue(_, state) {
      makeRequest({
        dispatch,
        method: 'post',
        url: Route.revenues(state.bmc.current.id),
      });
    },
    async updateRevenue(payload, state) {
      const data = payloadToData(payload);
      dispatch.revenue.updateRevenueReducer(data);

      makeRequest({
        dispatch,
        method: 'put',
        url: Route.revenue(state.bmc.current.id, payload.id),
        data: { revenue: data },
      });
    },
    async removeRevenue(payload, state) {
      makeRequest({
        dispatch,
        method: 'delete',
        url: Route.revenue(state.bmc.current.id, payload.id),
      });
    },
  }),
};

export default revenue;
