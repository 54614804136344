import {
  COLORS,
  FIRST_COLOR_PALETTE_NUMBER,
  SECOND_COLOR_PALETTE_NUMBER,
  THIRD_COLOR_PALETTE_NUMBER,
} from '../../const';
import { getColorDependsOnBackground } from '../getColorDependsOnBackground';

const { white, black } = COLORS;

export const getColorDependsOnBackgroundAndPalette = (
  colorPaletteNumber,
  background,
  isDesirabilityFeasibilityOrViability = false,
) => {
  const colorDependsOnBackground = {
    [FIRST_COLOR_PALETTE_NUMBER]: isDesirabilityFeasibilityOrViability
      ? black
      : white,
    [SECOND_COLOR_PALETTE_NUMBER]: isDesirabilityFeasibilityOrViability
      ? white
      : black,
    [THIRD_COLOR_PALETTE_NUMBER]: black,
  };
  return (
    colorDependsOnBackground[colorPaletteNumber] ||
    getColorDependsOnBackground(background)
  );
};
