import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import styled from 'styled-components';

import Modal from '../Modal';
import Button from '../Button';
import Spinner from '../Spinner';
import SvgIcon from '../SvgIcon';
import { COLORS } from '../../utils/const';

const { blue } = COLORS;

const SLIDER_MIN = 1;
const SLIDER_MAX = 3;
const SLIDER_STEP = 0.1;

const StyledSliderWrapper = styled.path`
  .MuiSlider-root {
    color: ${(props) => props.color};
  }
`;

export default function DropzoneModal({
  onCropperSave,
  dropzone,
  setModalOpened,
  originalImage,
  coords,
  uploading,
  aspectRatio,
  locales: { common },
  disabled = false,
  firstSvgColor,
  secondSvgColor,
  titleStyle,
  buttonStyle,
  sliderColor = blue,
  lightButtonStyle,
  backgroundModal,
  titleColor,
  buttonSecondColor,
  buttonFirstColor,
  backgroundSecondButton,
  isColourfulfunTemplate = false,
}) {
  const { getRootProps, getInputProps } = dropzone;

  const defaultCoords = {
    cropperData: {
      x: 0,
      y: 0,
      zoom: 1,
    },
  };

  const {
    cropperData: { x, y, zoom: zoomValue },
  } = { ...defaultCoords, ...coords };

  const [crop, setCrop] = useState({ x, y });
  const [zoom, setZoom] = useState(zoomValue);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropCompleteHandler = (_, _croppedAreaPixels) => {
    setCroppedAreaPixels(_croppedAreaPixels);
  };

  const onSave = () => {
    onCropperSave(croppedAreaPixels, crop, zoom);
    setModalOpened(false);
  };

  const closeModal = () => setModalOpened(false);

  const heightPercents = (1 / aspectRatio) * 100;
  const maxHeight = 75;
  const objectFit =
    heightPercents < maxHeight ? 'horizontal-cover' : 'vertical-cover';
  const actualHeightPercents =
    heightPercents < maxHeight ? heightPercents : maxHeight;
  const paddingTop = `min(${actualHeightPercents}%, calc(80vh - 170px))`;

  return (
    <Modal onBlackscreenClick={closeModal}>
      <div
        className="dndmodal-templates"
        style={{ background: backgroundModal }}
      >
        {isColourfulfunTemplate && (
          <>
            <div className="svg-modalFirstSvg" style={{ fill: firstSvgColor }}>
              <SvgIcon name="modalFirstSvg" />
            </div>
            <div
              className="svg-modalSecondSvg"
              style={{ fill: secondSvgColor }}
            >
              <SvgIcon name="modalSecondSvg" />
            </div>
          </>
        )}
        <div className="content">
          <div className="dndmodal-templates__header">
            <div
              className={`dndmodal-templates__header-title ${titleStyle} custom-font-title`}
              style={{ color: titleColor }}
            >
              {common.imageSetting}
            </div>

            <div className="dndmodal-templates__header-actions">
              <div {...getRootProps()} className="margin-right-10">
                <Button
                  title="Choose new image"
                  className={
                    lightButtonStyle
                      ? `${lightButtonStyle} custom-font-body`
                      : `bmc-button--blue custom-font-body`
                  }
                  style={{
                    color: buttonFirstColor,
                    borderColor: buttonFirstColor,
                  }}
                  disabled={disabled}
                />
                <input {...getInputProps()} />
              </div>

              <Button
                title={common.save}
                primary
                onClick={onSave}
                disabled={disabled}
                className={`${buttonStyle} custom-font-body`}
                style={{
                  color: buttonSecondColor,
                  background: backgroundSecondButton,
                }}
              />
              <Button
                title={<SvgIcon name="closePortfolioModalIcon" />}
                className="close-modal-button"
                onClick={closeModal}
                style={{ fill: backgroundSecondButton }}
              />
            </div>
          </div>
          {uploading ? (
            <Spinner big />
          ) : (
            <div style={{ paddingTop }} className="dndmodal-templates__cropper">
              <Cropper
                image={originalImage}
                crop={crop}
                zoom={zoom}
                aspect={aspectRatio}
                onCropChange={setCrop}
                onCropComplete={onCropCompleteHandler}
                onZoomChange={setZoom}
                objectFit={objectFit}
              />
            </div>
          )}
          <div className="dndmodal-templates__footer">
            <div className="dndmodal-templates__slider">
              <StyledSliderWrapper color={sliderColor}>
                <Slider
                  value={zoom}
                  min={SLIDER_MIN}
                  max={SLIDER_MAX}
                  step={SLIDER_STEP}
                  aria-labelledby="Zoom"
                  onChange={(_, _zoom) => setZoom(_zoom)}
                />
              </StyledSliderWrapper>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
