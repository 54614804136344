import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector, connect } from 'react-redux';

import Section from '../../../../components/Section';
import Intro from './Intro';
import CriticalAssumption from './CriticalAssumption';
import ChooseTest from './ChooseTest';
import BuildTest from './BuildTest';
import TestPlan from './TestPlan';
import AnalyseResults from './AnalyseResults';
import Summary from './Summary';
import * as AppRoute from '../../../../utils/appRoutes';
import { select } from '../../../../rematcher/store';

const components = [
  Intro,
  CriticalAssumption,
  ChooseTest,
  BuildTest,
  TestPlan,
  AnalyseResults,
  Summary,
];

export const prepareComponents = (bmcId, ideaId, menu, routeHelper) => {
  const sections = menu.map((e) => e.sectionName);

  return components.map((component, i) => ({
    component,
    url: routeHelper(bmcId, ideaId, sections[i]),
  }));
};

const TestItem = ({
  locales,
  locales: {
    testing: { menu, sectionName },
  },
  progress,
}) => {
  const {
    testing: { menuRules },
    bmc: {
      current: { id: bmcId },
    },
  } = useSelector((state) => state);

  const {
    testing: { fetchTest },
    bmc: { fetchCurrentBmc },
  } = useDispatch();

  const { bmcId: bmcIdFromParams, id } = useParams();
  const preparedComponents = prepareComponents(
    bmcId,
    id,
    menu,
    AppRoute.testing,
  );

  function getData() {
    if (bmcId) fetchTest(id);
    else fetchCurrentBmc(bmcIdFromParams);
  }

  useEffect(() => {
    getData();
  }, [bmcId]);

  return (
    <Section
      finalDestination={AppRoute.testing(bmcIdFromParams)}
      components={preparedComponents}
      sectionName={sectionName}
      menuItems={menu}
      progress={progress}
      rules={menuRules}
      locales={locales}
      onComponentChange={getData}
    />
  );
};

const mapState = select((models) => ({
  progress: models.progress.testingProgress,
}));

export default connect(mapState, null)(TestItem);
