import React from 'react';
import classNames from 'classnames';
import Img from '../../components/Img';
import {
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  BLOG_FRIENDLY_TEMPLATE_NAME,
  BOLD_TEMPLATE_NAME,
  COLOURFUL_TEMPLATE_NAME,
  CURRENT_TEMPLATE_NAME,
  TECH_MODERN_TEMPLATE_NAME,
} from '../const';

export const renderImage = (
  templateName = CURRENT_TEMPLATE_NAME,
  image,
  backgroundImage,
  title,
  aspectRatio,
  titleImageColor,
  borderImageColor,
) => {
  const colourfunArchTechStyles = [
    COLOURFUL_TEMPLATE_NAME,
    ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
    TECH_MODERN_TEMPLATE_NAME,
    BOLD_TEMPLATE_NAME,
  ];

  const isColourfunArchTechStyles =
    colourfunArchTechStyles.includes(templateName);

  const isCurrentStyle = templateName === CURRENT_TEMPLATE_NAME;
  const isBlogStyle = templateName === BLOG_FRIENDLY_TEMPLATE_NAME;

  return image.cropped ? (
    <div
      className={classNames(
        {
          'bmc-page-card-image': isCurrentStyle || isColourfunArchTechStyles,
        },
        {
          'bmc-page-card-image-blog': isBlogStyle,
        },
      )}
      style={{ borderColor: borderImageColor }}
    >
      <Img src={image.cropped} aspectRatio={aspectRatio} />
    </div>
  ) : (
    <div className="bmc-page-card-image--no-image-wrapper">
      <div
        className={classNames(
          {
            'bmc-page-card-image bmc-page-card-image--no-image':
              isCurrentStyle || isColourfunArchTechStyles,
          },
          {
            'bmc-page-card-image-blog bmc-page-card-image--no-image-blog':
              isBlogStyle,
          },
        )}
        style={{ background: backgroundImage, borderColor: borderImageColor }}
      >
        <span
          className={classNames(
            { 'bmc-page-card-image-icon-title': isCurrentStyle },
            {
              'bmc-page-card-image-icon-title bmc-page-card-image-icon-title-black title':
                isColourfunArchTechStyles,
            },
            { 'bmc-page-card-image-icon-title-blog': isBlogStyle },
            'custom-font-title',
          )}
          style={{ color: titleImageColor }}
        >
          {title}
        </span>
      </div>
    </div>
  );
};
