import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import Introduction from '../architecturalMinimalComponents/Introduction';
import Summary from '../architecturalMinimalComponents/Summary';
import Team from '../architecturalMinimalComponents/Team';
import BMC from '../mainWebTemplateComponents/BMC';
import Testing from '../mainWebTemplateComponents/Testing';
import CustomerSegment from '../architecturalMinimalComponents/CustomerSegment';
import ValueProposition from '../architecturalMinimalComponents/ValueProposition';
import CompetitorAnalysis from '../mainWebTemplateComponents/CompetitorAnalysis';
import GKG from '../mainWebTemplateComponents/GKG';
import Channels from '../architecturalMinimalComponents/Channels';
import KeyActivities from '../mainWebTemplateComponents/KeyActivities';
import Finance from '../mainWebTemplateComponents/Finance';
import Sustainability from '../mainWebTemplateComponents/Sustainability';
import ContactUs from '../architecturalMinimalComponents/ContactUs';
import { StyledWrapper } from '../../main/utils/helpers';
import Desirability from '../mainWebTemplateComponents/Desirability';
import Viability from '../mainWebTemplateComponents/Viability';
import Feasibility from '../mainWebTemplateComponents/Feasibility';
import {
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  BUTTON_MODAL_STYLE_ARCH,
  COLOR_PALETTE_ARRAY_ARCH,
  COLORS,
  THIRD_COLOR_PALETTE_NUMBER,
  TITLE_MODAL_STYLE_ARCH,
} from '../../main/utils/const';
import { getColorTemplate } from '../../main/utils/helpers/getColorTemplate';
import { getColorDependsOnBackgroundAndPalette } from '../../main/utils/helpers/portfolioArchTemplate/getColorDependsOnBackgroundAndPalette';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';

const { white, black, darkGrey } = COLORS;

const ArchitecturalMinimalTemplate = ({
  locales,
  bmcEnabled,
  testing,
  currentSettings,
}) => {
  const [showAll, setShowAll] = useState(false);

  const { sections } = useParams();

  const showSections = useMemo(() => {
    if (!sections) {
      setShowAll(true);
      return [];
    }
    const sectionArray = sections.split('-');
    if (!sectionArray.length) {
      setShowAll(true);
      return [];
    }
    return sectionArray;
  }, [sections]);

  const { contactUs } = useSelector((state) => state.portfolio);

  const {
    portfolioStyle: { templateSettings, activeTemplateName },
    portfolioTemplate: { collection },
  } = useSelector((state) => state);

  const activeTemplate = find(collection, ['sysName', activeTemplateName]);
  const colorTemplate = getColorTemplate(
    templateSettings,
    activeTemplateName,
    activeTemplate,
  );

  if (!colorTemplate) {
    return null;
  }

  const [firstColor, secondColor, thirdColor, fourthColor] = colorTemplate;
  const { shortcuts } = locales.portfolio.export;
  const {
    introduction,
    projectSummary,
    team,
    bmc,
    testing: test,
    desirabilitySection,
    customerSegment,
    valueProposition,
    competitorAnalysis,
    customerRelationships,
    channels,
    feasibility,
    keyActivities,
    viability,
    finance,
    sustainability,
    contactUs: contact,
  } = shortcuts;

  const colorPaletteNumber = COLOR_PALETTE_ARRAY_ARCH[firstColor];
  const isThirdColorPalette = colorPaletteNumber === THIRD_COLOR_PALETTE_NUMBER;
  const isFourthColorPaletteNumber = !colorPaletteNumber;

  const isShowSections = (sectionName) => {
    return showAll || showSections.includes(sectionName);
  };

  const textColorDependsOnBackgroundAndPaletteDFV =
    getColorDependsOnBackgroundAndPalette(colorPaletteNumber, thirdColor, true);

  const textColorDependsOnFirstColorAndPalette =
    getColorDependsOnBackgroundAndPalette(colorPaletteNumber, firstColor);

  const textColorDependsOnSecondColor =
    isFourthColorPaletteNumber && getColorDependsOnBackground(secondColor);

  const textColorDependsOnFourthColor =
    isFourthColorPaletteNumber && getColorDependsOnBackground(fourthColor);

  return (
    <StyledWrapper
      customtitlefont={currentSettings?.customTitleFont}
      customtitlecolor={currentSettings?.customTitleColor}
      custombodyfont={currentSettings?.customBodyFont}
      custombodycolor={currentSettings?.customBodyColor}
    >
      {isShowSections(introduction) && (
        <Introduction
          backgroundSection={firstColor}
          colorPaletteNumber={colorPaletteNumber}
          backgroundImage={fourthColor}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}
      {isShowSections(projectSummary) && (
        <Summary
          locales={locales}
          backgroundSection={secondColor}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}
      {isShowSections(team) && (
        <Team
          locales={locales}
          backgroundSection={firstColor}
          backgroundImage={fourthColor}
          colorPaletteNumber={colorPaletteNumber}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}
      {bmcEnabled && isShowSections(bmc) && (
        <BMC
          locales={locales}
          className="bm-architectural-canvas"
          sectionClassName="bmc-canvas-section--architectural"
          textStyle="text-card-arch"
          textWithImage="bmc-page-compcard-item-text-template bmc-page-compcard-item-text-template--with-image"
          sliderStyles="sdg-card-arch sdg-card-arch-modal"
          iconTableStyles="sdg-card-arch-icon"
          backgroundBMC={`${secondColor}33`}
          titleStyle={TITLE_MODAL_STYLE_ARCH}
          backgroundSection={`${fourthColor}80`}
          backgroundIcon={secondColor}
          backgroundMainSection={secondColor}
          backgroundItems={white}
          iconFill={textColorDependsOnSecondColor || black}
          iconFillTable={isThirdColorPalette ? black : firstColor}
          backgroundRow={`${fourthColor}80`}
          styleColor={black}
          backgroundImage={fourthColor}
          sectionHeaderTitleColor={textColorDependsOnSecondColor}
          descriptionColor={textColorDependsOnSecondColor}
          titleBMCSectionColor={
            isFourthColorPaletteNumber &&
            getColorDependsOnBackground(`${fourthColor}80`)
          }
          backgroundSliderColor={firstColor}
          backgroundIconTable={
            isThirdColorPalette ? `${firstColor}80` : `${firstColor}33`
          }
          titleImageColor={getColorDependsOnBackground(fourthColor)}
          textSliderColor={getColorDependsOnBackground(firstColor)}
          templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
        />
      )}
      {testing && isShowSections(test) && (
        <Testing
          locales={locales}
          className="testing testing-architectural-web"
          testListStyle="tests-list-arch"
          textStyle="text-card-arch"
          backgroundSection={{
            backgroundImage: `linear-gradient(to bottom,
            ${COLORS.white} 50%,
            ${firstColor} 50%)`,
          }}
          inputColor={thirdColor}
          backgroundCard={`${secondColor}33`}
          rateColor={black}
          textModalColor={black}
          titleStyle={TITLE_MODAL_STYLE_ARCH}
          buttonStyle={BUTTON_MODAL_STYLE_ARCH}
          borderCardColor={fourthColor}
          textRowColor={black}
          linkColor={black}
          templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(desirabilitySection) && (
        <Desirability
          locales={locales}
          colorPaletteNumber={colorPaletteNumber}
          className="desirability-architectural-web"
          backgroundSection={thirdColor}
          sectionHeaderTitleColor={textColorDependsOnBackgroundAndPaletteDFV}
          descriptionColor={textColorDependsOnBackgroundAndPaletteDFV}
          templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(customerSegment) && (
        <CustomerSegment
          locales={locales}
          backgroundSection={secondColor}
          backgroundImage={fourthColor}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}

      {bmcEnabled && isShowSections(valueProposition) && (
        <ValueProposition
          locales={locales}
          backgroundSection={firstColor}
          backgroundImage={fourthColor}
          colorPaletteNumber={colorPaletteNumber}
        />
      )}
      {bmcEnabled && isShowSections(competitorAnalysis) && (
        <CompetitorAnalysis
          locales={locales}
          className="competitor-analysis-architectural-web"
          backgroundSection={secondColor}
          backgroundImage={fourthColor}
          backgroundModal={secondColor}
          backgroundHeadTable={firstColor}
          sectionHeaderTitleColor={textColorDependsOnSecondColor}
          descriptionColor={textColorDependsOnSecondColor}
          backgroundTable={white}
          headTableColor={textColorDependsOnFirstColorAndPalette}
          titleImageColor={textColorDependsOnFourthColor}
          borderTableColor={secondColor}
          templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(customerRelationships) && (
        <GKG
          locales={locales}
          className="customer-relationships-architectural-web"
          backgroundSection={firstColor}
          sectionHeaderTitleColor={textColorDependsOnFirstColorAndPalette}
          descriptionColor={textColorDependsOnFirstColorAndPalette}
          firstTabColor={darkGrey}
          secondTabColor={white}
          textBlockColor={black}
          tabColor={textColorDependsOnFirstColorAndPalette}
          backgroundBlock={white}
          borderBlockColor={firstColor}
          templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(channels) && (
        <Channels
          locales={locales}
          background={firstColor}
          backgroundImage={fourthColor}
          colorPaletteNumber={colorPaletteNumber}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}
      {bmcEnabled && isShowSections(feasibility) && (
        <Feasibility
          locales={locales}
          className="feasibility-architectural-web"
          backgroundSection={thirdColor}
          sectionHeaderTitleColor={textColorDependsOnBackgroundAndPaletteDFV}
          descriptionColor={textColorDependsOnBackgroundAndPaletteDFV}
          templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(keyActivities) && (
        <KeyActivities
          locales={locales}
          className="key-activities-architectural key-activities-architectural-web"
          backgroundSection={firstColor}
          circleActivityColor={firstColor}
          lineActivityColor={black}
          backgroundActivity={white}
          backgroundResources={white}
          lineResourcesColor={black}
          templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(viability) && (
        <Viability
          locales={locales}
          className="viability-architectural-web"
          backgroundSection={thirdColor}
          sectionHeaderTitleColor={textColorDependsOnBackgroundAndPaletteDFV}
          descriptionColor={textColorDependsOnBackgroundAndPaletteDFV}
          templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(finance) && (
        <Finance
          locales={locales}
          className="portfolio-finance-architectural portfolio-finance-architectural-temp section"
          backgroundSection={secondColor}
          sectionHeaderTitleColor={textColorDependsOnSecondColor}
          descriptionColor={textColorDependsOnSecondColor}
          tabColor={textColorDependsOnSecondColor}
          activeTabColor={textColorDependsOnSecondColor}
          firstChartColor={firstColor}
          secondChartColor={thirdColor}
          backgroundUnitTable={fourthColor}
          backgroundRow={`${fourthColor}80`}
          secondTitleUnitColor={textColorDependsOnSecondColor}
          backgroundForecastTable={fourthColor}
          totalColor={black}
          borderVerticalTableColor={fourthColor}
          titleForecastColor={textColorDependsOnSecondColor}
          titleChartColor={textColorDependsOnSecondColor}
          templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(sustainability) && (
        <Sustainability
          locales={locales}
          className="sustainability-architectural section"
          cardClassName="bmc-page-suscard-arch bmc-page-suscard--section"
          sliderStyles="sdg-card-arch"
          iconTableStyles="sdg-card-arch-icon"
          backgroundSliderColor={firstColor}
          iconFill={getColorDependsOnBackgroundAndPalette(
            colorPaletteNumber,
            firstColor,
          )}
          textSliderColor={getColorDependsOnBackgroundAndPalette(
            colorPaletteNumber,
            firstColor,
          )}
          iconFillTable={isThirdColorPalette ? black : firstColor}
          backgroundIcon={
            isThirdColorPalette ? `${firstColor}80` : `${firstColor}33`
          }
          backgroundTable={
            isThirdColorPalette ? `${firstColor}80` : `${firstColor}33`
          }
          backgroundCells={white}
          arrowColor={black}
          colorPaletteNumber={colorPaletteNumber}
          templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
        />
      )}
      {isShowSections(contact) && (
        <ContactUs
          data={contactUs}
          locales={locales}
          backgroundSection={firstColor}
          colorPaletteNumber={colorPaletteNumber}
        />
      )}
    </StyledWrapper>
  );
};

export default ArchitecturalMinimalTemplate;
