import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Avatar from './Avatar';
import PopoverAvatar from './PopoverAvatar';

function Avatars({ images, maxVisibility = 3 }) {
  const [visibleImages, setVisibleImages] = useState([]);
  const [hiddenImages, setHiddenImages] = useState([]);
  useEffect(() => {
    setVisibleImages(images.slice(0, maxVisibility));
    setHiddenImages(images.slice(maxVisibility));
  }, [images, maxVisibility]);
  return (
    <div
      style={{
        display: 'flex',
        gap: 5,
      }}
    >
      {visibleImages &&
        visibleImages.map((item, index) => <Avatar {...item} key={index} />)}
      {hiddenImages.length > 0 && (
        <PopoverAvatar images={hiddenImages} count={hiddenImages.length} />
      )}
    </div>
  );
}

Avatars.propTypes = {
  images: PropTypes.array, // fields: {alt, url, fullName}
};
export default Avatars;
