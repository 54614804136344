import React, { useState } from 'react';
import copy from 'copy-to-clipboard';

import Button from '../../../components/Button';

const WebLink = ({ locales, webLink, slug }) => {
  const [isCopied, setIsCopied] = useState(false);
  const link = `${window.location.origin}/portfolio/${slug}/${webLink}`;

  const handleCopy = () => {
    copy(link);
    setIsCopied(true);
  };

  return (
    <div className="web-link">
      <div className="title">{locales.weblink.description}</div>
      <div className="bottom">
        <div className="text-wrapper">
          <a className="text link" href={link} target="_blank">
            {link}
          </a>
          <div className="line" />
        </div>
        <Button
          onClick={handleCopy}
          primary
          title={locales[isCopied ? 'copied' : 'copy']}
        />
      </div>
    </div>
  );
};

export default WebLink;
