import React from 'react';
import { useSelector } from 'react-redux';

import { removeNewLines } from '../../main/utils/helpers';
import Button from '../../main/components/Button';
import { isSecondOrThirdTemplate } from '../../main/utils/helpers/isSecondOrThirdTemplate';
import { COLORS } from '../../main/utils/const';

const { black } = COLORS;

const SummaryTechWeb = ({
  locales,
  backgroundSummary,
  colorStyle,
  colorPaletteNumber,
}) => {
  const { projectSummary } = useSelector((state) => state.portfolio);
  const { collection } = useSelector((state) => state.portfolioFile);

  return (
    <div className="summary-tech-web" style={{ background: backgroundSummary }}>
      <div
        className="content"
        style={
          !isSecondOrThirdTemplate(colorPaletteNumber)
            ? { borderColor: black }
            : {}
        }
      >
        <div
          className={`tech-title title custom-font-title ${
            !isSecondOrThirdTemplate(colorPaletteNumber) && 'text-black'
          }`}
        >
          {locales.portfolioWeb.summary}
        </div>
        <div
          className={`description-summary custom-font-body ${
            !isSecondOrThirdTemplate(colorPaletteNumber) && 'text-black'
          }`}
        >
          {projectSummary.description &&
            removeNewLines(projectSummary.description)}
        </div>

        <div className="margin-top-40 file">
          {collection.map((file) => (
            <a key={file.id} href={file.file} target="_blank">
              <Button
                icon="icon-Export"
                className="bmc-button button"
                title={file.name}
                style={
                  isSecondOrThirdTemplate(colorPaletteNumber)
                    ? {
                        borderColor: colorStyle,
                        color: colorStyle,
                        backgroundColor: COLORS.white,
                      }
                    : {}
                }
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SummaryTechWeb;
