import { unionWith, isEqual } from 'lodash';
import * as Route from '../support/serverRoutes';
import makeRequest from '../../main/utils/makeRequest';

const classrooms = {
  state: {
    data: [],
    meta: {},
    loading: false,
    by: null,
    byId: null,
    search: {
      text: '',
      collection: [],
      loading: false,
    },
    options: {
      loading: false,
      collection: [],
      currentValueLoading: false,
      currentValues: [],
    },
  },
  reducers: {
    setData(state, data) {
      return { ...state, data };
    },
    setMeta(state, meta) {
      return { ...state, meta };
    },
    setLoading(state, isLoading) {
      return { ...state, loading: isLoading };
    },
    setOwnerModel(state, { by, byId }) {
      return { ...state, by, byId };
    },
    setSearchLoading(state, isLoading) {
      return { ...state, search: { ...state.search, loading: isLoading } };
    },
    setOptionLoading(state, loading) {
      return { ...state, options: { ...state.options, loading } };
    },
    setOptions(state, collection) {
      return {
        ...state,
        options: {
          ...state.options,
          collection,
        },
      };
    },
    addOptions(state, collection) {
      return {
        ...state,
        options: {
          ...state.options,
          collection: unionWith(state.options.collection, collection, isEqual),
        },
      };
    },
    setCurrentValuesLoading(state, currentValueLoading) {
      return { ...state, options: { ...state.options, currentValueLoading } };
    },
    setCurrentValues(state, currentValues) {
      return { ...state, options: { ...state.options, currentValues } };
    },
    deleteItem(state, classroomId) {
      return {
        ...state,
        data: state.data.filter((classroom) => {
          return classroom.id !== classroomId;
        }),
      };
    },
  },
  effects: (dispatch) => ({
    fetchClassrooms(params, rootState) {
      const sort = rootState.classrooms?.meta?.sort;
      dispatch.classrooms.setLoading(true);
      dispatch.classrooms.setOwnerModel(params);
      makeRequest({
        dispatch,
        url: Route.classrooms({ ...params, sort }),
        success: (response) => {
          dispatch.classrooms.setData(response.data.data);
          dispatch.classrooms.setMeta(response.data.meta);
        },
        finish: () => {
          dispatch.classrooms.setLoading(false);
        },
      });
    },
    updateDataItem({ id, newDataItem }, rootState) {
      const {
        classrooms: { data },
      } = rootState;
      const newData = data.map((item) => {
        if (item.id === id) {
          return { ...item, ...newDataItem };
        }
        return item;
      });
      dispatch.classrooms.setData(newData);
    },
    sortClassrooms({ sort }, rootState) {
      const {
        by,
        byId,
        meta: { page, limit, search },
      } = rootState.classrooms;
      dispatch.classrooms.setLoading(true);
      makeRequest({
        dispatch,
        url: Route.classrooms({ page, limit, search, sort, by, byId }),
        success: (response) => {
          dispatch.classrooms.setData(response.data.data);
          dispatch.classrooms.setMeta(response.data.meta);
        },
        finish: () => {
          dispatch.classrooms.setLoading(false);
        },
      });
    },
    fetchParentClassroomsByUser({ userId }) {
      dispatch.classrooms.setCurrentValuesLoading(true);
      makeRequest({
        dispatch,
        url: Route.parentClassrooms({ by: 'users', byId: userId }, false),
        success: (response) => {
          const classroomOptions = response.data.data.map((item) => ({
            value: item.attributes.name,
            label: item.attributes.name,
            id: item.attributes.id,
          }));
          dispatch.classrooms.setCurrentValues(classroomOptions);
          dispatch.classrooms.addOptions(classroomOptions);
        },
        finish: () => {
          dispatch.classrooms.setCurrentValuesLoading(false);
        },
      });
    },
    fetchOptionsByUser({ userId }, rootState) {
      dispatch.classrooms.setOptionLoading(true);
      makeRequest({
        dispatch,
        url: Route.classroomOptions({ by: 'users', byId: userId }, false),
        success: (response) => {
          const classroomOptions = response.data.data.map((item) => ({
            value: item.attributes.name,
            label: item.attributes.name,
            id: item.attributes.id,
          }));
          dispatch.classrooms.setOptions(
            rootState.classrooms.options.currentValues,
          );
          dispatch.classrooms.addOptions(classroomOptions);
        },
        finish: () => {
          dispatch.classrooms.setOptionLoading(false);
        },
      });
    },
    fetchOptionsByEstablishment({ establishmentId }) {
      dispatch.classrooms.setOptionLoading(true);
      makeRequest({
        dispatch,
        url: Route.classroomOptions(
          { by: 'establishments', byId: establishmentId },
          false,
        ),
        success: (response) => {
          const classroomOptions = response.data.data.map((item) => ({
            value: item.attributes.name,
            label: item.attributes.name,
            id: item.attributes.id,
          }));
          dispatch.classrooms.setOptions(classroomOptions);
        },
        finish: () => {
          dispatch.classrooms.setOptionLoading(false);
        },
      });
    },

    deactivateClassroom(classroomId) {
      makeRequest({
        dispatch,
        url: Route.classroom(classroomId),
        method: 'delete',
        showSuccessToast: true,
        success: () => {
          dispatch.classrooms.deleteItem(classroomId);
          dispatch.classrooms.reload();
        },
      });
    },

    reload(_, rootState) {
      const {
        by,
        byId,
        meta: { page, limit },
      } = rootState.classrooms;

      dispatch.classrooms.fetchClassrooms({ by, byId, page, limit });
    },
  }),
};

export default classrooms;
