export * from './calculations';
export * from './collection';
export * from './components';
export * from './customerSegment';
export * from './image';
export * from './maps';
export * from './menu';
export * from './object';
export * from './select';
export * from './text';

export const nameWithDefault = (name, defaultName, index = null) => {
  return name || defaultName + (index ? ` ${index}` : '');
};

export const link = (text, href, target = null) => ({ text, href, target });

export const transformResponseCollection = (responseCollection) =>
  responseCollection.map((item) => ({ ...item, ...item.attributes }));

export const transformResponseData = (responseData) => ({
  ...responseData,
  ...responseData.attributes,
});

export const payloadToData = (payload) => {
  return { id: payload.id, [payload.name]: payload.value };
};

export const buildUserShortName = (user) => {
  return (
    ((user.firstName && user.firstName[0]) || '') +
    ((user.surname && user.surname[0]) || '')
  );
};
