import React from 'react';
import classNames from 'classnames';
import { chunk } from 'lodash';

import { sample } from '../utils/helpers';
import SvgIcon from './SvgIcon';

class List extends React.Component {
  constructor(props) {
    super(props);

    this.colors = props.colors || [
      'blue',
      'green',
      'yellow',
      'violet',
      'rose',
      'red',
    ];
    this.icons = props.icons || [];
  }

  getColor = () => {
    const color = sample(this.colors);

    this.colors = this.colors.filter((e) => e !== color);
    return color;
  };

  getIcon = (index) => {
    return this.icons[index];
  };

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { items, className, type } = this.props;
    let iconIndex = -1;
    let step = 0;

    return (
      <div
        className={classNames('bmc-page-list', className, {
          'bmc-page-list--horizontal': type === 'horizontal',
        })}
      >
        {chunk(items, 2).map((chunkArray, index) => (
          <div key={index}>
            {chunkArray.map((item, i) => (
              <div key={i} className="bmc-page-list-item">
                <div className="bmc-page-list-item-header">
                  <div
                    className={classNames(
                      'bmc-page-list-item-header-icon',
                      `bmc-page-list-item-header-icon--${this.getColor()}`,
                    )}
                  >
                    {this.icons.length > 0 ? (
                      <SvgIcon name={this.getIcon((iconIndex += 1))} />
                    ) : (
                      <div className="bmc-page-list-item-header-icon-number">
                        {(step += 1)}
                      </div>
                    )}
                  </div>
                  <div className="bmc-page-list-item-header-title">
                    {item.title}
                  </div>
                </div>
                <div className="bmc-page-list-item-text">{item.text}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

export default List;
