import React from 'react';

const iconExpand = (
  <svg
    width="9"
    height="17"
    viewBox="0 0 9 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 15.0025L3.43725 8.5L9 1.9975L7.28745 0L0 8.5L7.28745 17L9 15.0025Z"
      fill="#007AFF"
    />
  </svg>
);

const iconClose = (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.70711 0.792893C1.31658 0.402369 0.683418 0.402369 0.292893 0.792893C-0.097631 1.18342 -0.0976312 1.81658 0.292893 2.20711L5.5858 7.50002L0.292922 12.7929C-0.0976021 13.1834 -0.0976021 13.8166 0.292922 14.2071C0.683447 14.5976 1.31661 14.5976 1.70714 14.2071L7.00001 8.91423L12.2929 14.2071C12.6834 14.5976 13.3166 14.5976 13.7071 14.2071C14.0976 13.8166 14.0976 13.1834 13.7071 12.7929L8.41423 7.50002L13.7071 2.20711C14.0977 1.81658 14.0977 1.18342 13.7071 0.792895C13.3166 0.40237 12.6834 0.402371 12.2929 0.792895L7.00001 6.0858L1.70711 0.792893Z"
      fill="#007AFF"
    />
  </svg>
);

export default function CommentAppBarComponent({
  onClickExpand,
  onClickClose,
}) {
  return (
    <div className="comment-app-bar-component">
      <div
        className="comment-app-bar-component__expand"
        onClick={onClickExpand}
      >
        {iconExpand}
      </div>
      <div className="comment-app-bar-component__title">Back to messaging</div>
      <div className="comment-app-bar-component__close" onClick={onClickClose}>
        {iconClose}
      </div>
    </div>
  );
}
