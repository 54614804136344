import React from 'react';
import PropTypes from 'prop-types';
import icons from '../../main/utils/icons';
import { Divider } from './Divider';

// eslint-disable-next-line no-unused-vars
const ModalHeader = ({ title, divider = true, onClose = () => {} }) => {
  return (
    <>
      <header className="admin-modal-header">
        <h2 className="admin-modal-header__title">{title}</h2>
        <div className="admin-modal-header__close" onClick={onClose}>
          {icons.closeIcon}
        </div>
      </header>
      <Divider />
    </>
  );
};

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  divider: PropTypes.bool,
};
export default ModalHeader;
