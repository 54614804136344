import React from 'react';
import { useDispatch } from 'react-redux';

import Textarea from '../../../components/Textarea';
import FormInput from '../../../components/FormInput';
import { COMMENT_CONTACT } from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import { getColorDependsOnBackground } from '../../../utils/helpers/getColorDependsOnBackground';

const ContactUsCurrent = ({
  data: { title, description, fullName, emailOrWebsite },
  locales: { portfolio, portfolioWeb },
  background,
  isFourthColorPaletteNumber = false,
  disabled = false,
}) => {
  const scrollToTop = () => {
    const element = document.getElementById('scroll');
    element.scrollIntoView();
  };

  const {
    portfolio: { updateSectionField },
  } = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateSectionField({
      sectionName: 'contactUs',
      field: name,
      value,
      name,
    });
  };

  const textColorDependsOnBackground =
    isFourthColorPaletteNumber && getColorDependsOnBackground(background);

  return (
    <div
      className="contact-us-current relative"
      style={{
        backgroundColor: background,
      }}
    >
      <PortfolioCommentIcon title={COMMENT_CONTACT} />
      <div
        className="current-title title custom-font-title"
        style={{ color: textColorDependsOnBackground }}
      >
        {portfolio.contactUs.sectionName}
      </div>
      <Textarea
        name="textarea"
        rows={2}
        delay={700}
        value={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'contactUs',
            field: 'description',
            name: 'contactUsText',
            value: e.target.value,
          })
        }
        className="textarea-current textarea-current-contact custom-font-body"
        placeholder={portfolio.page.descriptionPlaceholder}
        style={{ color: textColorDependsOnBackground }}
        disabled={disabled}
      />
      <div className="inputs">
        <FormInput
          name="fullName"
          defaultValue={fullName}
          delay={700}
          placeholder={portfolio.contactUs.newForm.fullName}
          onChange={handleChange}
          disabled={disabled}
          className="input custom-font-body"
          style={{ color: textColorDependsOnBackground }}
        />
        <FormInput
          name="emailOrWebsite"
          defaultValue={emailOrWebsite}
          delay={700}
          placeholder={portfolio.contactUs.newForm.emailOrWebsite}
          onChange={handleChange}
          disabled={disabled}
          className="input custom-font-body"
          style={{ color: textColorDependsOnBackground }}
        />
      </div>
      <div
        className="footer custom-font-body"
        style={{
          color: textColorDependsOnBackground,
          borderColor: textColorDependsOnBackground,
        }}
      >
        <div className="left">
          {`© ${title}. ${portfolioWeb.contactUs.rightsReserve}`}
        </div>
        <div className="right">
          {portfolioWeb.contactUs.buildCreate}
          <span
            className="company-name custom-font-body"
            style={{ color: textColorDependsOnBackground }}
          >
            {portfolioWeb.contactUs.companyName}
          </span>
        </div>
      </div>
      <div
        className="scroll-top custom-font-body"
        onClick={scrollToTop}
        style={{ color: textColorDependsOnBackground }}
      >
        {portfolioWeb.contactUs.scrollTop}
        <i className="icon-Arrow-Top" />
      </div>
    </div>
  );
};

export default ContactUsCurrent;
