import React from 'react';
import { COLORS } from '../const';
import SvgIcon from '../../components/SvgIcon';

const { blue, lightBlue } = COLORS;

export const getStar = (type, rateColor) => {
  return (
    (type === 'full' && (
      <div style={{ fill: rateColor || blue }}>
        <SvgIcon name="testStar" />
      </div>
    )) ||
    (type === 'empty' && (
      <div style={rateColor ? { fill: `${rateColor}80` } : { fill: lightBlue }}>
        <SvgIcon name="testStar" />
      </div>
    ))
  );
};
