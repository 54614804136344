import React from 'react';
import { useHistory } from 'react-router-dom';
import icons from '../../main/utils/icons';

const PrevNextLinks = ({ prev, next }) => {
  const history = useHistory();
  return (
    <div className="prev-next-project-links">
      {prev && (
        <div
          onClick={() => {
            if (prev) history.push(`/admin/bmc/${prev?.id}`);
          }}
          className={`prev-project ${prev !== null && 'active'}`}
        >
          <span className="prev-project-icon">{icons.prevIcon}</span>
          <span className="underline">{prev?.name}</span>
        </div>
      )}
      {next && (
        <div
          onClick={() => {
            if (next) history.push(`/admin/bmc/${next?.id}`);
          }}
          className={`next-project ${next !== null && 'active'}`}
        >
          <span className="underline">{next?.name}</span>
          <span className="next-project-icon">{icons.nextIcon}</span>
        </div>
      )}
    </div>
  );
};

export default PrevNextLinks;
