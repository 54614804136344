import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Checkbox = ({ onClick, title, className, value }) => (
  <div
    className={classNames('bmc-checkbox', className, {
      'bmc-checkbox--checked': value === true,
    })}
    onClick={(event) => onClick(event)}
  >
    <div className="bmc-checkbox-switcher" />
    <div className="bmc-checkbox-title">{title}</div>
  </div>
);

Checkbox.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.bool.isRequired,
};

export default Checkbox;
