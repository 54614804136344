import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import get from 'lodash/get';

import { useDispatch, useSelector } from 'react-redux';
import Input from '../signUp/Input';
import Button from '../../main/components/Button';
import SigninSchema from './validationSchema';
import * as Route from '../../main/utils/serverRoutes';
import { ALERT_TYPES } from '../../main/utils/const';
import UpdatePasswordModal from './UpdatePasswordModal';

const prepareValues = ({ email, password }) => {
  return {
    user: {
      email,
      password,
    },
  };
};

const initialValues = {
  email: '',
  password: '',
};

const SigninForm = ({ locales, locales: { onboarding: pageLocales } }) => {
  const { user } = useSelector((state) => state);

  const {
    notification: { addNotification },
    alert: { addAlert },
    user: { fetchCurrentUser, currentUserPasswordUpdate },
  } = useDispatch();

  const [currentPassword, setCurrentPassword] = useState(null);

  useEffect(() => {
    if (user?.current?.isNewPassword === false) window.location = '/';
  }, [user]);

  return (
    <>
      <UpdatePasswordModal
        locales={locales}
        visible={user?.current?.isNewPassword}
        currentPassword={currentPassword}
        updatePassword={currentUserPasswordUpdate}
        onClose={fetchCurrentUser}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={SigninSchema}
        onSubmit={(values) => {
          axios
            .post(Route.signIn(), prepareValues(values))
            .then(() => {
              setCurrentPassword(values.password);
              fetchCurrentUser();
            })
            .catch((error) => {
              if (error.response.status === 403) {
                addAlert({
                  id: 403,
                  type: ALERT_TYPES.warning,
                  title: error.response.data.error,
                });
              } else {
                addNotification({ text: error.response.data.error });
              }
            });
        }}
      >
        {({ errors }) => {
          const isDisabledCreateAccount = !(!errors.email && !errors.password);

          return (
            <Form className="sign-up-form sign-up-form--sign-in">
              <>
                <h3 className="sign-up-form__title">
                  {pageLocales.titles.signIn}
                </h3>
                <Field
                  locales={locales}
                  name="email"
                  component={Input}
                  label={pageLocales.inputLabels.email}
                  placeholder={pageLocales.inputPlaceholders.email}
                  className="margin-bottom-20"
                />
                <Field
                  locales={locales}
                  name="password"
                  label={pageLocales.inputLabels.password}
                  placeholder={pageLocales.inputPlaceholders.password}
                >
                  {({ field, form: { touched, errors: passwordErrors } }) => {
                    const error = get(passwordErrors, field.name);
                    const isTouched = get(touched, field.name);

                    return (
                      <div className="sign-up-form__input margin-bottom-20">
                        <label htmlFor="email">
                          {pageLocales.inputLabels.password}
                        </label>
                        <div className="sign-up-form__input_link">
                          <Link to="/onboarding/forgot_password">
                            {pageLocales.common.forgotPassword}
                          </Link>
                        </div>

                        <div className="sign-up-form__input-inner">
                          <input
                            {...field}
                            placeholder={pageLocales.inputPlaceholders.password}
                            type="password"
                            id="password"
                            className="bmc-form-input"
                          />
                          {isTouched && error && (
                            <p className="sign-up-form__input-error">
                              {get(
                                locales.onboarding.validationMessages,
                                error,
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    );
                  }}
                </Field>
                <div className="sign-up-form__actions">
                  <Button
                    title={pageLocales.buttons.signIn}
                    className="bmc-button--sign-up"
                    type="submit"
                    disabled={isDisabledCreateAccount}
                  />
                </div>
                <div className="sign-up-form__privacy-information">
                  <h4>{pageLocales.common.privacy}</h4>
                  <p>{pageLocales.common.byCreating}</p>
                  <div>
                    <a target="_blank" href="/terms">
                      {pageLocales.common.terms}
                    </a>
                    <a target="_blank" href="/privacy">
                      {pageLocales.common.privacyPolicy}
                    </a>
                    <a target="_blank" href="/accessibility">
                      {pageLocales.common.accessibility}
                    </a>
                  </div>
                </div>
              </>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

SigninForm.propTypes = {
  locales: PropTypes.object.isRequired,
};

export default SigninForm;
