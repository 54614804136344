import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SectionHeader from '../SectionHeader';
import Textarea from '../../../components/Textarea';
import CompetitorsCard from '../../valueProposition/CompetitorsCard';

const CompetitorAnalysis = ({
  data: { description, showStatus },
  locales,
  locales: { portfolio },
  disabled = false,
}) => {
  const {
    competitor: { collection },
  } = useSelector((state) => state);

  const {
    portfolio: { updateSectionField, updateSectionFieldReducer },
  } = useDispatch();

  const setShowStatus = (status) => {
    updateSectionFieldReducer({
      sectionName: 'competitorAnalysis',
      field: 'showStatus',
      value: status,
    });
  };

  return (
    <div className="competitor-analysis">
      <SectionHeader
        locales={portfolio}
        showStatus={showStatus}
        sectionName={portfolio.competitorAnalysis.sectionName}
        setShowStatus={setShowStatus}
        mainColor="green"
      />
      {showStatus && (
        <>
          <Textarea
            name="textarea"
            rows={5}
            delay={700}
            value={description}
            onChange={(e) =>
              updateSectionField({
                sectionName: 'competitorAnalysis',
                field: 'description',
                name: 'competitorAnalysisText',
                value: e.target.value,
              })
            }
            className="bmc-form-textarea textarea"
            placeholder={portfolio.page.descriptionPlaceholder}
            disabled={disabled}
          />

          <CompetitorsCard
            className="margin-top-40"
            locales={locales}
            isPreview={disabled}
            card={{
              ...locales.valueProposition.intro.competitorsCard,
              competitors: collection,
            }}
          />
        </>
      )}
    </div>
  );
};

export default CompetitorAnalysis;
