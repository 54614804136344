import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import Section from './Section';
import Button from '../../components/Button';

export default function Password({ locales, locales: { account } }) {
  const history = useHistory();
  const {
    user: { currentUserPasswordUpdate },
  } = useDispatch();
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const updatePassword = async () => {
    currentUserPasswordUpdate({
      currentPassword,
      password,
      passwordConfirmation,
      callbackAfterSuccess: [() => history.goBack()],
    });
  };

  return (
    <Section menuItems={account.menu} locales={locales}>
      <div className="bmc-account">
        <Button
          title={account.back}
          className="bmc-button--with-icon"
          icon="icon-Back"
          onClick={() => history.push('/account')}
        />

        <div className="bmc-form-big-title margin-top-20">
          {account.title[2]}
        </div>

        <div className="bmc-form-fieldset margin-top-40 bmc-account-password-field">
          <label htmlFor="currentPassword" className="bmc-form-label">
            {account.currentPassword}
          </label>
          <input
            id="currentPassword"
            className="bmc-form-input"
            value={currentPassword}
            type="password"
            onChange={(e) => setCurrentPassword(e.target.value)}
            data-hj-allow
          />
        </div>

        {/* temporary hide it <div className="bmc-form-text bmc-form-text--small bmc-form-text--upcase margin-top-10">
          <div>{account.cantRemember}</div>
          <Link to="/account/reset_password" className="bmc-form-link">
            {account.resetPassword}
          </Link>
        </div> */}

        <div className="bmc-account-row margin-top-20">
          <div className="bmc-form-fieldset bmc-account-password-field">
            <label htmlFor="password" className="bmc-form-label">
              {account.password}
            </label>
            <input
              id="password"
              className="bmc-form-input"
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="bmc-form-fieldset bmc-account-password-field bmc-account-password-field--confirm">
            <label htmlFor="passwordConfirmation" className="bmc-form-label">
              {account.passwordConfirmation}
            </label>
            <input
              id="passwordConfirmation"
              className="bmc-form-input"
              value={passwordConfirmation}
              type="password"
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
          </div>
        </div>

        <div className="margin-top-20">
          <Button
            title={account.updatePassword}
            className="bmc-button--primary"
            onClick={updatePassword}
          />
        </div>
      </div>
    </Section>
  );
}
