import { portfolioTemplates } from '../utils/serverRoutes';
import makeRequest from '../utils/makeRequest';
import { transformResponseCollection } from '../utils/helpers';

const portfolioTemplate = {
  state: {
    collection: [],
  },
  reducers: {
    fetchPortfolioTemplatesReducer(state, { collection }) {
      return { ...state, collection };
    },
  },
  effects: (dispatch) => ({
    fetchPortfolioTemplates() {
      makeRequest({
        dispatch,
        url: portfolioTemplates(),
        success: (response) => {
          dispatch.portfolioTemplate.fetchPortfolioTemplatesReducer({
            collection: transformResponseCollection(response.data.data),
          });
        },
      });
    },
  }),
};

export default portfolioTemplate;
