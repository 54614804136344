import React from 'react';

import Textarea from '../../../components/Textarea';
import SvgIcon from '../../../components/SvgIcon';
import UploadFiles from '../../../components/UploadFiles';
import { COMMENT_SUMMARY } from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import { getColorDependsOnBackground } from '../../../utils/helpers/getColorDependsOnBackground';

const SummaryColourfulFun = ({
  data: { description },
  locales,
  locales: { portfolio },
  updateSectionField,
  firstSvgColor,
  secondSvgColor,
  fileBackgroundColor,
  backgroundSection,
  colorStyle,
  isFourthColorPaletteNumber = false,
  disabled = false,
}) => {
  return (
    <div
      className="summary-colourfulFun relative"
      style={{ backgroundColor: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_SUMMARY} />
      <div className="svg-funSummaryThird" style={{ fill: firstSvgColor }}>
        <SvgIcon name="funSummaryThird" />
      </div>
      <div className="svg-funSummaryFourth" style={{ fill: secondSvgColor }}>
        <SvgIcon name="funSummaryFourth" />
      </div>
      <div className="content">
        <div className="main">
          <div className="colourful-title title custom-font-title">
            {portfolio.projectSummary.sectionName}
          </div>
          <Textarea
            rows={5}
            delay={700}
            value={description}
            onChange={(e) => {
              updateSectionField({
                sectionName: 'projectSummary',
                field: 'description',
                name: 'projectSummary',
                value: e.target.value,
              });
            }}
            className="textarea-colourfulFun textarea-colourfulFun-summary custom-font-body"
            placeholder={portfolio.page.descriptionPlaceholder}
            disabled={disabled}
          />
          <UploadFiles
            locales={locales}
            className="margin-top-40"
            disabled={disabled}
            fileBackgroundColor={fileBackgroundColor}
            fileTextColor={
              isFourthColorPaletteNumber &&
              getColorDependsOnBackground(fileBackgroundColor)
            }
            uplouderButtonStyle="portfolio-file-uploader__colourful"
            filesWrapperStyle="portfolio-file-uploader-file-colourful"
            fileStyle="portfolio-file-uploader-file-colourful-link"
            removeIconStyle="portfolio-file-uploader-file__remove-colourful"
            borderButtonColor={colorStyle}
            textButtonColor={colorStyle}
          />
        </div>
      </div>
    </div>
  );
};

export default SummaryColourfulFun;
