import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Space } from 'antd';
import Modal from '../../../main/components/Modal';
import ModalContainer from '../../components/ModalContainer';

const ConfirmationDeleteModal = ({
  visible,
  loading = false,
  onClose,
  onConfirm,
}) => {
  const handleFinish = (values) => {
    onConfirm(values);
  };

  return (
    <>
      {visible && (
        <Modal onBlackscreenClick={onClose} className="bmc-modal--small">
          <ModalContainer>
            <div className="confirmation-modal-delete">
              <h2 className="confirmation-modal-delete__header">
                Enter your password to confirm deletion
              </h2>
              <Form layout="vertical" onFinish={handleFinish}>
                <Form.Item
                  label="Password Confirmation"
                  className="admin-input-label"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Password is required',
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    className="admin-input"
                  />
                </Form.Item>
                <Space>
                  <Button onClick={onClose}>Cancel</Button>
                  <Button
                    type="primary"
                    danger
                    disabled={loading}
                    htmlType="submit"
                  >
                    Delete
                  </Button>
                </Space>
              </Form>
            </div>
          </ModalContainer>
        </Modal>
      )}
    </>
  );
};

ConfirmationDeleteModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmationDeleteModal;
