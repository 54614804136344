import _ from 'lodash';

export default function sortMemberList(memberList) {
  return _.sortBy(memberList, (member) => {
    switch (member.role) {
      case 'student':
        return 1;
      case 'lecturer':
        return 2;
      case 'admin':
        return 3;
      case 'super_admin':
        return 4;
      default:
        return 4;
    }
  });
}
