import pica from 'pica';
import axios from 'axios';
import { portfolioFilesDownload } from '../../../../../../utils/serverRoutes';

export const downloadImage = async (src) => {
  const backendEndpoint = portfolioFilesDownload();
  const params = { src };
  let result = '';

  if (src.includes('s3.amazonaws.com')) {
    result = await axios
      .post(backendEndpoint, params)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.error('Error fetching data from the backend:', error);
      });
  } else {
    result = src;
  }

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.crossOrigin = 'anonymous';
    img.src = `data:image/gif;base64,${result}`;
  });
};

export const loadImage = async (src) => {
  if (!src) return;
  if (src.includes('s3.amazonaws.com')) return downloadImage(src);

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.crossOrigin = 'anonymous';
    img.src = src;
  });
};

export async function picaResizeImage(
  img,
  dimensions = 300,
  type = 'cover',
  height = 300,
) {
  const picaInstance = pica();
  const canvas = document.createElement('canvas');
  canvas.width = dimensions;
  canvas.height = height;

  const imgRatio = img.height / img.width;
  const canvasRatio = height / dimensions;

  let newImg;
  if (
    (imgRatio < canvasRatio && type === 'contain') ||
    (imgRatio >= canvasRatio && type === 'cover')
  ) {
    const h = dimensions * imgRatio;
    newImg = await picaInstance.resize(img, canvas, {
      left: 0,
      top: (dimensions - h) / 2,
      width: dimensions,
      height: h,
    });
  }
  if (
    (imgRatio >= canvasRatio && type === 'contain') ||
    (imgRatio < canvasRatio && type === 'cover')
  ) {
    const w = (height * canvasRatio) / imgRatio;
    newImg = await picaInstance.resize(img, canvas, {
      top: 0,
      left: (height - w) / 2,
      width: w,
      height,
    });
  }

  return newImg;
}

export async function resizeImage(
  img,
  dimensions = 300,
  type = 'cover',
  height = 300,
) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = dimensions;
  canvas.height = height;

  const imgRatio = img.height / img.width;
  const canvasRatio = height / dimensions;
  if (
    (imgRatio < canvasRatio && type === 'contain') ||
    (imgRatio >= canvasRatio && type === 'cover')
  ) {
    const h = dimensions * imgRatio;
    ctx.drawImage(img, 0, (dimensions - h) / 2, dimensions, h);
  }
  if (
    (imgRatio >= canvasRatio && type === 'contain') ||
    (imgRatio < canvasRatio && type === 'cover')
  ) {
    const w = (height * canvasRatio) / imgRatio;
    ctx.drawImage(img, (height - w) / 2, 0, w, height);
  }

  const resizedImage = await loadImage(canvas.toDataURL('image/png'));
  return resizedImage;
}

export const roundImage = async (img, dimensions = 300) => {
  const canvas = document.createElement('canvas');
  canvas.width = dimensions;
  canvas.height = dimensions;
  const ctx = canvas.getContext('2d');
  const minSize = Math.min(canvas.width, canvas.height);
  canvas.width = minSize;
  canvas.height = minSize;
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.beginPath();
  ctx.arc(canvas.width / 2, canvas.height / 2, minSize / 2, 0, Math.PI * 2);
  ctx.closePath();
  ctx.clip();
  ctx.drawImage(img, 0, 0, minSize, minSize);

  const image = await loadImage(canvas.toDataURL('image/png'));
  return image;
};

export const borderRadiusImage = async (
  img,
  dimensions = 300,
  borderRadius = 20,
) => {
  const canvas = document.createElement('canvas');
  canvas.width = dimensions;
  canvas.height = dimensions;
  const ctx = canvas.getContext('2d');

  // Create a clipping path with the desired border radius
  ctx.beginPath();
  ctx.moveTo(borderRadius, 0);
  ctx.lineTo(canvas.width - borderRadius, 0);
  ctx.quadraticCurveTo(canvas.width, 0, canvas.width, borderRadius);
  ctx.lineTo(canvas.width, canvas.height - borderRadius);
  ctx.quadraticCurveTo(
    canvas.width,
    canvas.height,
    canvas.width - borderRadius,
    canvas.height,
  );
  ctx.lineTo(borderRadius, canvas.height);
  ctx.quadraticCurveTo(0, canvas.height, 0, canvas.height - borderRadius);
  ctx.lineTo(0, borderRadius);
  ctx.quadraticCurveTo(0, 0, borderRadius, 0);
  ctx.closePath();
  ctx.clip();

  // Draw the image inside the clipping path
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

  // Reset the clipping path
  ctx.restore();

  const image = await loadImage(canvas.toDataURL('image/png'));
  return image;
};

export const cutImage = async (img, dimensions = 300, cutPercentage = 0.3) => {
  const canvas = document.createElement('canvas');
  canvas.width = dimensions;
  canvas.height = dimensions;
  const ctx = canvas.getContext('2d');
  const minSize = Math.min(canvas.width, canvas.height);
  canvas.width = minSize;
  canvas.height = minSize;
  const cutHeight = img.height * cutPercentage;

  canvas.width = img.width;
  canvas.height = img.height - cutHeight;

  ctx.drawImage(
    img,
    0,
    0,
    img.width,
    img.height - cutHeight,
    0,
    0,
    canvas.width,
    canvas.height,
  );

  const image = await loadImage(canvas.toDataURL('image/png'));
  return image;
};

export const mergeImages = async (images, dimensions = 300) => {
  const canvas = document.createElement('canvas');
  canvas.width = dimensions;
  canvas.height = dimensions;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(images[0], 0, 0);
  ctx.drawImage(images[1], 10, 0);

  const mergedImage = canvas.toDataURL('image/png');
  const mergedImageElement = await loadImage(mergedImage);
  return mergedImageElement;
};

export const mergeImagesWithoutShift = async (
  images,
  dimensions = 300,
  dx = 0,
  dy = 0,
) => {
  const canvas = document.createElement('canvas');
  canvas.width = dimensions;
  canvas.height = dimensions;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(images[0], 0, 0);
  ctx.drawImage(images[1], dx, dy);

  const mergedImage = canvas.toDataURL('image/png');
  const mergedImageElement = await loadImage(mergedImage);
  return mergedImageElement;
};

export const mergeImagesWithCentered = async (
  images,
  dimensions = 300,
  height = false,
) => {
  const canvas = document.createElement('canvas');
  canvas.width = dimensions;
  canvas.height = height || dimensions;
  const ctx = canvas.getContext('2d');

  const secondImageDx = images[0].width / 2 - images[1].width / 2;
  const secondImageDy = images[0].height / 2 - images[1].height / 2;

  ctx.drawImage(images[0], 0, 0);
  ctx.drawImage(images[1], secondImageDx, secondImageDy);

  const mergedImage = canvas.toDataURL('image/png');
  const mergedImageElement = await loadImage(mergedImage);
  return mergedImageElement;
};

export async function createBlankImage(
  width = 500,
  height = 500,
  backgroundColor = 'blue',
  border = false,
) {
  // Create a canvas element
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  // Get the canvas 2D rendering context
  const ctx = canvas.getContext('2d');

  // Fill the canvas with the background color
  ctx.fillStyle = backgroundColor;
  ctx.fillRect(0, 0, width, height);
  if (border) {
    ctx.strokeStyle = '#000000';
    ctx.lineWidth = 1;
  }

  // Convert the canvas to a data URL
  const dataURL = canvas.toDataURL('image/png');

  // Create an Image element
  const image = await loadImage(dataURL);

  return image;
}

export const shadowImage = async (
  img,
  dimensions = 300,
  color = 'black',
  blur = 8,
) => {
  const canvas = document.createElement('canvas');
  canvas.width = dimensions + 16;
  canvas.height = dimensions + 16;
  const ctx = canvas.getContext('2d');

  ctx.shadowColor = color;
  ctx.shadowBlur = blur;

  // Draw the image inside the clipping path
  ctx.drawImage(img, 8, 8, dimensions, dimensions);

  const image = await loadImage(canvas.toDataURL('image/png'));
  return image;
};

export const textImage = async (img, dimensions, bodyColor, bodyFont, text) => {
  const canvas = document.createElement('canvas');
  canvas.width = dimensions;
  canvas.height = dimensions;
  const ctx = canvas.getContext('2d');

  // Draw the image inside the clipping path
  ctx.drawImage(img, 0, 0, dimensions, dimensions);

  ctx.font = `24px ${bodyFont}`;
  ctx.fillStyle = `#${bodyColor}`;
  ctx.textBaseline = 'middle';
  ctx.textAlign = 'center';
  ctx.fillText(text, dimensions / 2, dimensions / 2);

  const image = await loadImage(canvas.toDataURL('image/png'));
  return image;
};

export const changeImageColor = async (img, width, height, newColor) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');

  ctx.fillStyle = `#${newColor}`;
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // set composite mode
  ctx.globalCompositeOperation = 'destination-in';

  // Draw the image inside the clipping path
  ctx.drawImage(img, 0, 0, width, height);

  const image = await loadImage(canvas.toDataURL('image/png'));
  return image;
};

export const buildRoundedImg = async (width, height, color, radius) => {
  const blankImage = await createBlankImage(width, height, `#${color}`);
  const roundedImage = await borderRadiusImage(blankImage, width, radius);

  return Uint8Array.from(atob(roundedImage.src?.split(',')[1]), (c) =>
    c.charCodeAt(0),
  );
};
