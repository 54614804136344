import React, { useEffect, useMemo, useState } from 'react';
import InputRange from 'react-input-range';
import ReactStars from 'react-stars';
import axios from 'axios';
import classNames from 'classnames';
import styled from 'styled-components';

import * as Route from '../../../utils/serverRoutes';

export const StyledInputWrapper = styled.path`
  .input-range__track--active {
    background: ${(props) => props.background};
    border-color: ${(props) => props.borderColor};
  }

  .input-range__slider {
    background: ${(props) => props.background};
    border-color: ${(props) => props.borderColor};
  }

  .slider-count {
    color: ${(props) => props.color};
  }
`;

const ResponseCard = ({
  card: { header, subtitle, responses },
  extended = false,
  cName = '',
  testId,
  isDemo = false,
  className,
  inputColor,
  titleColor,
  textColor,
}) => {
  const [responseData, setResponseData] = useState([]);
  useEffect(() => {
    (async () => {
      const {
        data: { data },
      } = await axios.get(Route.testResponseById(testId));
      setResponseData(data);
    })();
  }, []);

  const score = useMemo(() => {
    if (isDemo) return 0;
    if (!responseData.length) return 0;
    const scores = responseData.map((i) => {
      // eslint-disable-next-line
      if (~i.attributes.score) return i.attributes.score;
      return 0;
    });
    return Math.round(
      scores.reduce((acc, sum) => acc + sum, 0) / scores.length,
    );
  }, [responseData]);

  const iLikeText = useMemo(() => {
    if (isDemo) return responses.iLike.text;
    const text = [];
    responseData.forEach((r) => {
      if ((r.attributes.answerOne || '').length)
        text.push(r.attributes.answerOne);
    });
    return text;
  }, [responseData]);

  const iWishText = useMemo(() => {
    if (isDemo) return responses.iWish.text;
    const text = [];
    responseData.forEach((r) => {
      if ((r.attributes.answerTwo || '').length)
        text.push(r.attributes.answerTwo);
    });
    return text;
  }, [responseData]);

  const whatIfText = useMemo(() => {
    if (isDemo) return responses.whatIf.text;
    const text = [];
    responseData.forEach((r) => {
      if ((r.attributes.answerThree || '').length)
        text.push(r.attributes.answerThree);
    });
    return text;
  }, [responseData]);

  return (
    <div className={`response-card ${cName}`}>
      {extended && (
        <div className="bmc-testing-card-wrapper">
          <div className="title">Build A Homepage</div>
          <ReactStars
            className="rate"
            color2="#0984FF"
            color1="rgba(9, 132, 255, 0.2)"
            size={22}
            edit={false}
            value={1}
          />
          <div className="section">
            <div className="left">
              <div className="block">
                <div className="title">Assumption</div>
                <div className="content">
                  Let’s look at Yoga Time. We need to decide whether our
                  Customer Segment Hypothesis is critical. Remember, Critical
                  Hypotheses are those that must be correct for our business to
                  succeed.
                </div>
              </div>
            </div>
            <div className="right">
              <div className="block">
                <div className="title">BMC Blocks Tested</div>
                <div className="content">
                  <div className="bmc-blocks">
                    <div className="bmc-blocks__block bmc-blocks__block-CS">
                      CS
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="left is-full-width">
              <div className="block">
                <div className="title">Test Plan</div>
                <div className="content">
                  {window.location.origin}/tests/999
                </div>
              </div>
            </div>
            {/* <div className="right"> */}
            {/*  <div className="block"> */}
            {/*    <div className="title">Share Link</div> */}
            {/*    <div className="content"> */}
            {/*      <div className="share-link"> */}
            {/*        {window.location.origin}/tests/999 */}
            {/*      </div> */}
            {/*    </div> */}
            {/*  </div> */}
            {/* </div> */}
          </div>
        </div>
      )}
      <div
        className={classNames('response-card__title', className)}
        style={{ color: titleColor }}
      >
        {isDemo
          ? header.replace('%X%', 4)
          : header.replace('%X%', responseData.length)}
      </div>
      <div className="response-card__subtitle" style={{ color: textColor }}>
        {subtitle}
      </div>
      <StyledInputWrapper
        color={inputColor}
        borderColor={inputColor}
        background={inputColor}
      >
        <div className="response-card__slider-wrapper">
          <InputRange
            maxValue={10}
            minValue={0}
            value={score}
            onChange={() => {}}
          />
          <div className="slider-count">{score}/10</div>
        </div>
      </StyledInputWrapper>

      <div
        className={classNames('response-card__title', className)}
        style={{ color: titleColor }}
      >
        {responses.title}
      </div>
      <div className="response-card__responses">
        <div className="i-like">
          <div className="title" style={{ color: textColor }}>
            {responses.iLike.title}
          </div>
          <div className="text-wrapper">
            {iLikeText.map((t, i) => {
              return (
                <div key={i} className="text">
                  {t}
                  {iLikeText.length - 1 !== i && <div className="separator" />}
                </div>
              );
            })}
          </div>
        </div>
        <div className="i-wish">
          <div className="title" style={{ color: textColor }}>
            {responses.iWish.title}
          </div>
          <div className="text-wrapper">
            {iWishText.map((t, i) => {
              return (
                <div key={i} className="text">
                  {t}
                  {iWishText.length - 1 !== i && <div className="separator" />}
                </div>
              );
            })}
          </div>
        </div>
        <div className="what-if">
          <div className="title" style={{ color: textColor }}>
            {responses.whatIf.title}
          </div>
          <div className="text-wrapper">
            {whatIfText.map((t, i) => {
              return (
                <div key={i} className="text">
                  {t}
                  {whatIfText.length - 1 !== i && <div className="separator" />}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponseCard;
