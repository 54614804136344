import React from 'react';
import { useDispatch } from 'react-redux';
import { find } from 'lodash';

const TemplateList = ({
  templateCollection,
  activeTemplateName,
  disabled = false,
}) => {
  const {
    portfolioStyle: { updatePortfolioTemplate },
  } = useDispatch();

  const changeTemplate = (sysName) => {
    const selectedTemplate = find(templateCollection, ['sysName', sysName]);

    updatePortfolioTemplate({ activeTemplateId: selectedTemplate.id });
  };

  return (
    <div className="templates-box">
      <div className="templates-box-text">Style template</div>
      <form className="templates-box-images">
        {templateCollection.map((item, index) => {
          return (
            <div
              className="templates-box-images-container"
              key={`portfolio-template-${index}`}
            >
              <label
                htmlFor={item.title}
                className={
                  item.sysName === activeTemplateName
                    ? 'templates-box-images-item--active'
                    : 'templates-box-images-item'
                }
                style={{
                  backgroundImage: `url(${item.imgSrc})`,
                }}
              >
                <input
                  type="radio"
                  className="templates-box-images-item--input"
                  name="template"
                  id={item.title}
                  onChange={() => changeTemplate(item.sysName)}
                  disabled={disabled}
                />
              </label>
              <div className="templates-box-images-item--div">{item.title}</div>
            </div>
          );
        })}
      </form>
    </div>
  );
};

export default TemplateList;
