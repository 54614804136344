import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import Textarea from '../../../../components/Textarea';
import Dropzone from '../../../../components/Dropzone';
import {
  BUTTON_LIGHT_MODAL_STYLE_ARCH,
  BUTTON_MODAL_STYLE_ARCH,
  COLORS,
  IMAGE_ASPECT_RATIO_ONE,
  TITLE_MODAL_STYLE_ARCH,
} from '../../../../utils/const';

const { black } = COLORS;

const TeamCard = ({
  teammate,
  locales,
  locales: { portfolio },
  backgroundTeam,
  backgroundModal,
  index,
  textColor,
  disabled = false,
}) => {
  const { image, imageCoords, name, description, position, id } = teammate;
  const {
    portfolioTeammate: {
      updatePortfolioTeammate,
      updatePortfolioTeammateImage,
      updatePortfolioTeammateImageCoords,
      removePortfolioTeammate,
    },
  } = useDispatch();

  const { uploadingImage } = useSelector((state) => state.portfolioTeammate);

  const onChange = (e) => {
    updatePortfolioTeammate({
      id,
      name: e.target.name,
      value: e.target.value,
    });
  };

  const onDropImage = ([file], openCropperHandler) => {
    updatePortfolioTeammateImage({
      id,
      name: 'image',
      blob: file,
      success: () => {
        openCropperHandler();
      },
    });
  };

  const onImageCropperSave = (croppedAreaPixels, innerCropData, zoom) => {
    updatePortfolioTeammateImageCoords({
      id,
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: imageCoords,
    });
  };
  const firstCard = index === 0 || index % 4 === 0;
  const secondCard = index === 1 || index % 4 === 1;
  const thirdCard = index % 2 === 0 && index % 4 !== 0;
  const fourthCard = index !== 0 && index % 3 === 0;

  return (
    <div className="team-architectural-card">
      {!disabled && (
        <div className="remove">
          <ConfirmationModal
            body="Delete this content. Are you sure?"
            confirmText="Delete"
            onConfirm={() => removePortfolioTeammate({ id })}
          >
            <div className="icon-section">
              <i className="icon-Delete" />
            </div>
          </ConfirmationModal>
        </div>
      )}

      <div className="image-hover">
        <Dropzone
          className={classNames(
            'image-upload',
            { 'is-first': firstCard },
            { 'is-second': secondCard },
            { 'is-third': thirdCard },
            { 'is-fourth': fourthCard },
          )}
          background={{ backgroundColor: backgroundTeam }}
          onDrop={onDropImage}
          onCropperSave={onImageCropperSave}
          locales={locales}
          image={image}
          coords={imageCoords}
          uploading={uploadingImage === teammate.id}
          aspectRatio={IMAGE_ASPECT_RATIO_ONE}
          disabled={disabled}
          titleStyle={TITLE_MODAL_STYLE_ARCH}
          buttonStyle={BUTTON_MODAL_STYLE_ARCH}
          lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_ARCH}
          sliderColor={black}
          backgroundModal={backgroundModal}
          isTemplates
          cropper
        />
      </div>

      <div className="textarea-arch">
        <Textarea
          id="position"
          type="text"
          name="position"
          rows={1}
          value={position}
          placeholder={portfolio.team.position}
          onChange={onChange}
          className="textarea-architectural textarea-architectural-team-card--position custom-font-body"
          style={{ color: textColor }}
          delay={700}
          disabled={disabled}
        />
        <Textarea
          id="fullName"
          value={name}
          onChange={onChange}
          placeholder={portfolio.team.fullName}
          name="name"
          rows={1}
          type="text"
          className="textarea-architectural textarea-architectural-team-card--name custom-font-title"
          style={{ color: textColor }}
          delay={700}
          disabled={disabled}
        />
      </div>
      <div className="textarea-arch">
        <Textarea
          id="description"
          className="textarea-architectural textarea-architectural-team-card--description custom-font-body"
          style={{ color: textColor }}
          name="description"
          placeholder={portfolio.page.description}
          value={description}
          onChange={onChange}
          rows={5}
          delay={700}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default TeamCard;
