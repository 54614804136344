import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from './Modal';

export default function Alert() {
  const {
    alert: { collection },
  } = useSelector((state) => state);
  const {
    alert: { removeCurrentAlert },
  } = useDispatch();
  const alert = collection[0];
  const link = alert ? alert.link : null;

  return (
    <>
      {alert && (
        <Modal
          onBlackscreenClick={() => removeCurrentAlert()}
          className="bmc-modal--small bmc-modal--on-top"
        >
          <div className="alert">
            <div className="alert-header">{alert.title}</div>

            {link && (
              <div className="alert-link-wrapper margin-top-20">
                <a className="alert-link" href={link.href} target={link.target}>
                  {link.text}
                </a>
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
}
