import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { truncate } from 'lodash';
import useMediaQuery from 'react-use-media-query-hook';

import CustomerSegmentCard from '../../customerSegment/CardBlog';
import {
  BLOG_FRIENDLY_TEMPLATE_NAME,
  BUTTON_LIGHT_MODAL_STYLE_BLOG,
  BUTTON_MODAL_STYLE_BLOG,
  COLORS,
  COMMENT_SEGMENT,
  TITLE_MODAL_STYLE_BLOG,
} from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import SectionHeader from '../../../components/SectionHeader';
import { getColorDependsOnBackground } from '../../../utils/helpers/getColorDependsOnBackground';

const { black } = COLORS;

const CustomerSegmentBlog = ({
  data: { description },
  locales,
  locales: {
    portfolio,
    customerSegment: {
      intro: { card },
    },
  },
  backgroundSection,
  backgroundCard,
  backgroundModal,
  isFourthColorPaletteNumber = false,
  disabled = false,
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [activeCardId, setActiveCardId] = useState(null);

  const {
    customerSegment: { updateCustomerSegment },
    portfolio: { updateSectionField },
  } = useDispatch();

  const { collection } = useSelector((state) => state.customerSegment);

  const activeCardData = useMemo(() => {
    if (collection.length && !activeCardId) {
      setActiveCardId(collection[0].id);
      return collection[0];
    }
    return collection.find((i) => i.id === activeCardId);
  }, [collection, activeCardId]);

  const textColorDependsOnBackground =
    isFourthColorPaletteNumber &&
    getColorDependsOnBackground(backgroundSection);

  const cardTextColorDependsOnBackground =
    isFourthColorPaletteNumber && getColorDependsOnBackground(backgroundCard);

  const singleCard = activeCardData && (
    <CustomerSegmentCard
      locales={locales}
      uploadFileHandler={updateCustomerSegment}
      dropzoneMode
      blur={false}
      background={backgroundCard}
      backgroundImage={backgroundCard}
      borderImageColor={cardTextColorDependsOnBackground}
      disabled={disabled}
      editMode={!disabled}
      titleStyle={TITLE_MODAL_STYLE_BLOG}
      buttonStyle={BUTTON_MODAL_STYLE_BLOG}
      lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_BLOG}
      sliderColor={black}
      backgroundModal={backgroundModal}
      textColor={cardTextColorDependsOnBackground}
      isTemplates
      card={{
        ...card,
        ...activeCardData,
        subtitle: activeCardData.name,
        title: null,
      }}
    />
  );

  return (
    <div
      className="customer-segment-blogFriendly section relative"
      style={{ backgroundColor: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_SEGMENT} />
      <SectionHeader
        templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
        title={portfolio.customerSegment.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'customerSegment',
            field: 'description',
            name: 'csText',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        disabled={disabled}
        style={{ color: textColorDependsOnBackground }}
        descriptionColor={textColorDependsOnBackground}
      />

      <div className="cards-section">
        <div className="left">
          {collection.map(({ id, name }) => (
            <div
              key={id}
              onClick={() => setActiveCardId(id)}
              className={classnames('item', ' custom-font-body', {
                active: activeCardId === id,
              })}
              style={{ color: textColorDependsOnBackground }}
            >
              {truncate(name, { length: 45 })}
            </div>
          ))}
        </div>
        <div className="right">
          {isMobile
            ? collection.map((customerSegmentItem) => {
                return (
                  <CustomerSegmentCard
                    locales={locales}
                    className="margin-top-20"
                    key={customerSegmentItem.id}
                    uploadFileHandler={updateCustomerSegment}
                    dropzoneMode
                    portfolioMode
                    blur={false}
                    background={backgroundCard}
                    backgroundImage={backgroundCard}
                    disabled={disabled}
                    editMode={!disabled}
                    titleStyle={TITLE_MODAL_STYLE_BLOG}
                    buttonStyle={BUTTON_MODAL_STYLE_BLOG}
                    lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_BLOG}
                    sliderColor={black}
                    backgroundModal={backgroundModal}
                    borderImageColor={cardTextColorDependsOnBackground}
                    textColor={cardTextColorDependsOnBackground}
                    isTemplates
                    card={{
                      ...card,
                      ...customerSegmentItem,
                      subtitle: customerSegmentItem.name,
                      title: null,
                    }}
                  />
                );
              })
            : singleCard}
        </div>
      </div>
    </div>
  );
};

export default CustomerSegmentBlog;
