import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../components/Header';
import Tabs from '../../components/Tabs';
import Textarea from '../../components/Textarea';
import TabsCut from '../../components/TabsCut';
import Actions from '../../components/Actions';
import Button from '../../components/Button';
import List from '../../components/List';
import Line from '../../components/Line';
import { UNIQUE_SELLING_POINTS_MAX_COUNT } from '../../utils/const';
import RemoveButton from '../../components/RemoveButton';
import Title from '../../components/Title';
import TabsList from '../../components/TabsList';
import TabsListItem from '../../components/TabsListItem';
import ConfirmationModal from '../../components/ConfirmationModal';
import SvgIcon from '../../components/SvgIcon';
import MobileMenuHeader from '../../components/MobileMenuHeader';

const UniqueValueProposition = ({
  nextClickHandler,
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
  locales,
  locales: {
    common,
    tipCustomerSegment,
    caseStudies,
    valueProposition: {
      name,
      uniqueValueProposition: { header, title, text, list, tabs },
    },
  },
}) => {
  const [opened, setOpened] = useState(false);
  const icons = ['roundStar'];
  const iconsComponents = icons.map((e) => <SvgIcon name={e} />);
  const {
    uniqueSellingPoint: {
      addUniqueSellingPoint,
      updateUniqueSellingPoint,
      removeUniqueSellingPoint,
    },
  } = useDispatch();
  const {
    uniqueSellingPoint: { collection },
  } = useSelector((state) => state);

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon={<SvgIcon name="valueProposition" />}
        locales={locales}
        componentIndex={componentIndex}
        tipLocales={tipCustomerSegment}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="VPCanvas" title={name} color="green" />
        }
      />

      <div className="bmc-page-text">
        <p className="bmc-page-text--bold">{text[0]}</p>
        <p>{text[1]}</p>
        <p>{text[2]}</p>
      </div>

      <Title text={title[0]} align="left" />

      <List items={list} icons={['roundStar']} />

      <Line />

      <h2 className="bmc-page-title">
        <span className="bmc-page-title--green">{title[1]}</span> {title[2]}
      </h2>

      <div className="bmc-page-text">
        <p className="bmc-page-text--center">{text[3]}</p>
      </div>

      <Tabs
        tabs={[common.yourStrategy, common.caseStudy]}
        withBlur
        className="margin-top-60"
      >
        {[
          <TabsList key="strategy">
            {collection.map((item, index) => (
              <TabsListItem
                key={item.id}
                title={`${tabs.uniqueSellingPoint} ${index + 1}`}
                subtitle={tabs.uniqueSellingPointSub}
                icon={iconsComponents[0]}
                text={
                  // eslint-disable-next-line
                  <Textarea
                    rows={4}
                    delay={700}
                    value={item.description}
                    onChange={(event) =>
                      updateUniqueSellingPoint({
                        index,
                        name: 'description',
                        value: event.target.value,
                        id: item.id,
                      })
                    }
                    placeholder={tabs.uniqueSellingPointPlaceholder}
                  />
                }
              >
                {collection.length > 1 && (
                  <ConfirmationModal
                    body="Delete this content. Are you sure?"
                    confirmText="Delete"
                    isBlockScroll
                    onConfirm={() =>
                      removeUniqueSellingPoint({ index, id: item.id })
                    }
                  >
                    <RemoveButton
                      className="bmc-page-tabs-content-list-remove"
                      active={collection.length > 1}
                    />
                  </ConfirmationModal>
                )}
              </TabsListItem>
            ))}

            {collection.length < UNIQUE_SELLING_POINTS_MAX_COUNT && (
              <Button
                wide
                title={tabs.addNewUniqueValueProposition}
                icon="icon-Add"
                onClick={addUniqueSellingPoint}
                className="bmc-button--green"
              />
            )}
          </TabsList>,

          <Tabs key="tabs" tabs={caseStudies.map((e) => e.name)} black inner>
            {caseStudies.map((item, index) => (
              <TabsList key={index} preview>
                <TabsCut
                  setOpened={setOpened}
                  opened={opened}
                  caseStudy={item}
                />

                {item.unuqueSellingPoints.map((point, i) => (
                  <TabsListItem
                    key={i}
                    title={`${i + 1}. ${tabs.uniqueSellingPoint}`}
                    text={point}
                    icon={iconsComponents[0]}
                  />
                ))}
              </TabsList>
            ))}
          </Tabs>,
        ]}
      </Tabs>

      <Actions nextClickHandler={nextClickHandler} buttonName={common.next} />
    </div>
  );
};

export default UniqueValueProposition;
