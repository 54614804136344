import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../components/Header';
import Title from '../../components/Title';
import Text from '../../components/Text';
import Line from '../../components/Line';
import List from '../../components/List';
import Actions from '../../components/Actions';
import Links from '../../components/Links';
import Tabs from '../../components/Tabs';
import { nameWithDefault } from '../../utils/helpers';
import RemoveButton from '../../components/RemoveButton';
import { CHANNELS_MAX_COUNT } from '../../utils/const';
import Button from '../../components/Button';
import Textarea from '../../components/Textarea';
import TabsCut from '../../components/TabsCut';
import TabsList from '../../components/TabsList';
import TabsListTitle from '../../components/TabsListTitle';
import TabsListItem from '../../components/TabsListItem';
import ConfirmationModal from '../../components/ConfirmationModal';
import SvgIcon from '../../components/SvgIcon';
import MobileMenuHeader from '../../components/MobileMenuHeader';

const fields = ['name', 'description'];
const icons = ['buy', 'text'];
const iconsComponents = icons.map((e) => <SvgIcon name={e} />);

export default function CreateYourChannels(props) {
  const {
    nextClickHandler,
    menuItems,
    selectComponent,
    componentIndex,
    rules,
    backPath,
    locales,
    locales: {
      caseStudies,
      common,
      tipCustomerSegment,
      channel: {
        name,
        createYourChannels: { header, title, text, list, tabs },
      },
    },
  } = props;
  const { collection } = useSelector((state) => state.channel);
  const {
    channel: { addChannel, updateChannel, removeChannel },
  } = useDispatch();
  const [opened, setOpened] = useState(false);

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon="icon-channel"
        locales={locales}
        tipLocales={tipCustomerSegment}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        componentIndex={componentIndex}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="ChannelsCanvas" title={name} color="green" />
        }
      />

      <Text>{[text[0]]}</Text>

      <Title text={title[0]} align="left" />

      <List items={list} icons={icons} />

      <Line />

      <h2 className="bmc-page-title">
        <span className="bmc-page-title--green">{title[1]}</span> {title[2]}
      </h2>

      <Text align="center">{[text[1]]}</Text>

      <div className="bmc-page-text bmc-page-text--center margin-top-40">
        <p>
          <span className="bmc-page-text--bold">{text[2]}</span>
          <br />
          {text[3]}
        </p>
      </div>

      <Links />

      <Tabs
        tabs={[common.yourStrategy, common.caseStudy]}
        withBlur
        className="margin-top-40"
      >
        {[
          <div key="strategy">
            {collection.map((channel, index) => (
              <div
                key={channel.id}
                className="bmc-page-tabs-content-list bmc-page-tabs-content-list--form-style"
              >
                <div className="bmc-page-tabs-content-list-title">
                  {nameWithDefault(channel.name, name, index + 1)}
                </div>
                {collection.length > 1 && (
                  <ConfirmationModal
                    body="Delete this content. Are you sure?"
                    confirmText="Delete"
                    isBlockScroll
                    onConfirm={() => removeChannel({ index, id: channel.id })}
                  >
                    <RemoveButton
                      className="bmc-page-tabs-content-list-remove"
                      active={collection.length > 1}
                    />
                  </ConfirmationModal>
                )}

                {fields.map((field, i) => (
                  <TabsListItem
                    title={tabs[field]}
                    subtitle={tabs[`${field}Sub`]}
                    key={i}
                    icon={iconsComponents[i]}
                    text={
                      // eslint-disable-next-line
                      <Textarea
                        rows={i === 0 ? 1 : 4}
                        delay={700}
                        placeholder={tabs[field]}
                        value={channel[field]}
                        onChange={(event) =>
                          updateChannel({
                            name: field,
                            value: event.target.value,
                            id: channel.id,
                          })
                        }
                      />
                    }
                  />
                ))}
              </div>
            ))}

            {collection.length < CHANNELS_MAX_COUNT && (
              <div className="bmc-page-tabs-content-actions margin-top-40">
                <Button
                  onClick={addChannel}
                  wide
                  icon="icon-Add"
                  title={tabs.createNewChannel}
                  className="bmc-button--green"
                />
              </div>
            )}
          </div>,

          <Tabs
            key="tabs"
            tabs={caseStudies.map((e) => e.name)}
            className="bmc-page-tabs--black bmc-page-tabs--inner"
          >
            {caseStudies.map((item, index) => (
              <div key={index}>
                <TabsCut
                  opened={opened}
                  setOpened={setOpened}
                  caseStudy={item}
                />

                {item.channels.map((channel, i) => (
                  <TabsList key={i} formStyle preview>
                    <TabsListTitle>
                      {tabs.name} {i + 1}
                    </TabsListTitle>

                    {fields.map((field, ii) => (
                      <TabsListItem
                        key={ii}
                        title={tabs[field]}
                        text={channel[field]}
                        icon={iconsComponents[ii]}
                      />
                    ))}
                  </TabsList>
                ))}
              </div>
            ))}
          </Tabs>,
        ]}
      </Tabs>

      <Actions nextClickHandler={nextClickHandler} buttonName={common.next} />
    </div>
  );
}
