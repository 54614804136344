import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Tabs } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import Container from '../../../components/Container';
import Header from '../../../components/Header';
import ClassLists from '../../Lists/ClassLists';
import ProjectLists from '../../Lists/ProjectLists';
import StudentLists from '../../Lists/StudentLists';
import AdminLecturerLists from '../../Lists/AdminLecturerLists';
import EstablishmentBlockList from '../../../components/EstablishmentBlockList';
import getTodayDateAsString from '../../../utils/date';

const { TabPane } = Tabs;

export default function EstablishmentDashboard({
  locales: { admin },
  locales,
}) {
  const history = useHistory();
  const { establishmentId, adminId } = useParams();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const {
    // blocks: { collections },
    establishment: { data },
    user: { current },
    admin: { establishments },
  } = useSelector((state) => state);

  const {
    blocks: { fetchBlocks },
    establishment: { fetchEstablishment },
    admin: { fetchEstablishments },
    activeUser: { fetchUserById },
  } = useDispatch();

  const downloadButtonParams = {
    href: `/establishments/${establishmentId}?format=xlsx`,
    fileName: `${
      data?.name || 'establishment'
    }_users_${getTodayDateAsString()}.xlsx`,
  };

  useEffect(() => {
    if (establishmentId) {
      fetchBlocks({
        by: 'establishments',
        byId: establishmentId,
        pageName: 'admin',
      });
      fetchEstablishment({ id: establishmentId });
    }
  }, [establishmentId]);

  useEffect(() => {
    const resultBreadcrumbs = [];
    if (current?.role === 'super_admin')
      resultBreadcrumbs.push({
        title: 'Super Admin',
        to: '/admin',
      });

    if (data?.name) {
      resultBreadcrumbs.push({
        title: `${admin.establishment} “${data?.name}”`,
        to: `/admin/establishment/${establishmentId}`,
      });

      setBreadcrumbs(resultBreadcrumbs);
    }
  }, [data, current]);

  useEffect(() => {
    if (adminId) {
      fetchEstablishments(adminId);
      fetchUserById({ userId: adminId });
    }
  }, [adminId]);

  const handleExpandDropdownClick = (item) => {
    if (adminId)
      history.push(`/admin/establishment/${item.id}/users/${adminId}`);
  };

  return (
    <Container
      locales={locales}
      title={
        data && current
          ? `${data?.name}: ${current?.firstName} ${current?.surname} Admin Panel`
          : ' '
      }
    >
      <Header
        locales={locales}
        breadcrumbs={breadcrumbs}
        header={`${data?.name || ''} Dashboard`}
        downloadReportButton
        downloadButtonParams={downloadButtonParams}
        expandDropdownVisible={
          adminId && establishments?.collection?.length > 1
        }
        expandDropdownItems={establishments?.collection?.map((item) => {
          if (String(item.id) === establishmentId)
            return { ...item, expandDropdownActive: true };
          return { ...item, expandDropdownActive: false };
        })}
        expandDropdownClick={handleExpandDropdownClick}
      />
      <EstablishmentBlockList establishmentId={establishmentId} />
      <br />
      <br />
      <Tabs defaultActiveKey="admin_lecturers" className="admin-tabs">
        <TabPane
          key="admin_lecturers"
          tab="Admin/Educator"
          className="admin-tab-pane"
        >
          <AdminLecturerLists
            locales={locales}
            byId={establishmentId}
            by="establishments"
            rowClick={(record) => {
              if (record.role === 'lecturer')
                history.push(`/admin/lecturer/${record.id}`);
              else if (record.role === 'admin')
                history.push(
                  `/admin/establishment/${establishmentId}/users/${record.id}`,
                );
            }}
            rowClickable
          />
        </TabPane>
        <TabPane key="classroom" tab="Class" className="admin-tab-pane">
          <ClassLists
            locales={locales}
            byId={establishmentId}
            by="establishments"
            rowClick={(record) => {
              history.push(`/admin/classroom/${record.id}`);
            }}
            rowClickable
          />
        </TabPane>
        <TabPane key="project" tab="Project" className="admin-tab-pane">
          <ProjectLists
            byId={establishmentId}
            by="establishments"
            rowClick={(record) =>
              window.open(`/admin/bmc/${record.id}`, '_blank')
            }
            rowClickable
          />
        </TabPane>
        <TabPane key="student" tab="Student" className="admin-tab-pane">
          <StudentLists
            byId={establishmentId}
            by="establishments"
            expandRowClick={(record) => {
              history.push(`/admin/bmc/${record.id}`);
            }}
            locales={locales}
          />
        </TabPane>
      </Tabs>
    </Container>
  );
}
