import ROLES from '../../main/utils/roles';

export function getRole(user) {
  return user?.current?.role || user?.role || user?.attributes?.role;
}

export function isAdminRole(user) {
  return [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.LECTURER].includes(
    getRole(user),
  );
}

export function isSuperAdmin(user) {
  return getRole(user) === ROLES.SUPER_ADMIN;
}

export function isAdmin(user) {
  return getRole(user) === ROLES.ADMIN;
}

export function isLecturer(user) {
  return getRole(user) === ROLES.LECTURER;
}

export function isStudent(user) {
  return getRole(user) === ROLES.STUDENT;
}
