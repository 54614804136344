import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export default function Switcher({
  leftUrl,
  rightUrl,
  leftActive = true,
  rightActive = false,
  leftIcon,
  rightIcon,
  leftText,
  rightText,
  leftOnClick,
  rightOnClick,
}) {
  const leftContent = (
    <>
      {leftIcon && leftIcon}
      {leftText}
    </>
  );

  const rightContent = (
    <>
      {rightIcon && rightIcon}
      {rightText}
    </>
  );

  const leftClassnames = useMemo(() => {
    return classNames('switcher-option switcher-option--left', {
      'switcher-option--active': leftActive,
    });
  }, [leftActive]);

  const rightClassnames = useMemo(() => {
    return classNames('switcher-option switcher-option--right', {
      'switcher-option--active': rightActive,
    });
  }, [rightActive]);

  return (
    <div className="switcher">
      {leftUrl ? (
        <Link to={leftUrl} className={leftClassnames} onClick={leftOnClick}>
          {leftContent}
        </Link>
      ) : (
        <div className={leftClassnames} onClick={leftOnClick}>
          {leftContent}
        </div>
      )}

      {rightUrl ? (
        <Link to={rightUrl} className={rightClassnames} onClick={rightOnClick}>
          {rightContent}
        </Link>
      ) : (
        <div className={rightClassnames} onClick={rightOnClick}>
          {rightContent}
        </div>
      )}
    </div>
  );
}
