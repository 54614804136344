import * as Route from '../support/serverRoutes';
import makeRequest from '../../main/utils/makeRequest';

const establishments = {
  state: {
    data: [],
    meta: null,
    loading: false,
    uploading: false,
    search: {
      text: '',
      collection: [],
      loading: false,
    },
    userEstablishments: [],
    createdEstablishments: [],
  },
  reducers: {
    setData(state, { data, meta }) {
      return { ...state, data, meta };
    },
    setLoading(state, isLoading) {
      return { ...state, loading: isLoading };
    },
    setSearchLoading(state, isLoading) {
      return { ...state, search: { ...state.search, loading: isLoading } };
    },
    setSearchResult(state, { collection, text }) {
      return { ...state, search: { ...state.search, collection, text } };
    },
    deleteItem(state, id) {
      return {
        ...state,
        data: state.data.filter((e) => {
          return e.id !== id;
        }),
      };
    },
    setUserEstablishmentsReducer(state, { data }) {
      return {
        ...state,
        userEstablishments: [...data],
      };
    },
    addCreatedEstablishmentReducer(state, { establishmentId }) {
      const tempAdmin = state.data.find((a) => a.id === establishmentId);
      return {
        ...state,
        createdEstablishments: [...state.createdEstablishments, tempAdmin],
      };
    },
    removeCreatedEstablishmentReducer(state, { establishmentId }) {
      const createdEstablishments = state.createdEstablishments.filter(
        (e) => e.id !== establishmentId,
      );
      return {
        ...state,
        createdEstablishments,
      };
    },
    clearSelectedCreatedEstablishmentsReducer(state) {
      return {
        ...state,
        createdEstablishments: [],
      };
    },
    addEstablishmentReducer(state, payload) {
      const tempAdmin = state.data.find(
        (a) => a.id === payload.establishmentId,
      );
      return {
        ...state,
        userEstablishments: [...state.userEstablishments, tempAdmin],
      };
    },
    removeEstablishmentReducer(state, payload) {
      const collection = state.userEstablishments.filter(
        (e) => e.id !== payload.establishmentId,
      );
      return {
        ...state,
        userEstablishments: [...collection],
      };
    },
  },
  effects: (dispatch) => ({
    fetchEstablishments({ page = 1, limit = 5, search }, rootState) {
      const sort = rootState.establishments?.meta?.sort;
      dispatch.establishments.setLoading(true);
      makeRequest({
        dispatch,
        url: Route.establishments({ page, limit, search, sort }),
        success: (response) => {
          dispatch.establishments.setData({
            data: response.data.data,
            meta: response.data.meta,
          });
        },
        finish: () => {
          dispatch.establishments.setLoading(false);
        },
      });
    },
    searchEstablishment({ text }) {
      dispatch.establishments.setSearchLoading(true);
      makeRequest({
        dispatch,
        url: Route.search('establishments', text),
        success: (response) => {
          dispatch.establishments.setSearchResult({
            collection: response.data.data,
            text,
          });
        },
        finish: () => {
          dispatch.establishments.setSearchLoading(false);
        },
      });
    },
    sortEstablishments({ sort }, rootState) {
      const { page, limit, search } = rootState.establishments.meta;
      dispatch.establishments.setLoading(true);
      makeRequest({
        dispatch,
        url: Route.establishments({ page, limit, search, sort }),
        success: (response) => {
          dispatch.establishments.setData({
            data: response.data.data,
            meta: response.data.meta,
          });
        },
        finish: () => {
          dispatch.establishments.setLoading(false);
        },
      });
    },
    deactivateEstablishment({ id }) {
      makeRequest({
        dispatch,
        url: Route.establishment(id),
        method: 'delete',
        showSuccessToast: true,
        success: () => {
          dispatch.establishments.deleteItem(id);
          dispatch.establishments.reload();
        },
      });
    },
    reload(_, rootState) {
      const { page, limit, search, sort } = rootState.establishments.meta;
      dispatch.establishments.fetchEstablishments({
        page,
        limit,
        search,
        sort,
      });
    },
    setUserEstablishments({ userEstablishments }) {
      dispatch.establishments.setUserEstablishmentsReducer({
        data: userEstablishments,
      });
    },
    clearSelectedCreatedEstablishments() {
      dispatch.establishments.clearSelectedCreatedEstablishmentsReducer();
    },
    addCreatedEstablishment({ establishmentId }) {
      dispatch.establishments.addCreatedEstablishmentReducer({
        establishmentId,
      });
    },
    removeCreatedEstablishment({ establishmentId }) {
      dispatch.establishments.removeCreatedEstablishmentReducer({
        establishmentId,
      });
    },
    addEstablishment({ establishmentId, userId }) {
      dispatch.establishments.addEstablishmentReducer({
        establishmentId,
        userId,
      });
    },
    removeEstablishment({ establishmentId, userId }) {
      dispatch.establishments.removeEstablishmentReducer({
        establishmentId,
        userId,
      });
    },
  }),
};

export default establishments;
