import {
  Paragraph,
  Table,
  TableRow,
  TableCell,
  AlignmentType,
  WidthType,
  HeightRule,
  TableAnchorType,
  OverlapType,
  TableLayoutType,
} from 'docx';

import {
  pageSize,
  pageMargin,
  convertPixelToTwip,
  COLORS,
  transparentBorder,
  tableWidth,
  imageRunColourful,
} from '../Constants';
import {
  buildColourfulDecorateImg,
  buildSegmentItemImage,
  emptyCell,
  marginTableRow,
  textRun,
} from '../helpers/index';

export default async (locales, portfolio, channel, options) => {
  const getTopBgImg = async () => {
    if (options.channels?.topImgBg) {
      const img = await buildColourfulDecorateImg(
        options.channels.topImgBg,
        options.channels.topImgColor,
      );

      return new Paragraph({
        children: [imageRunColourful(img, 138, 149, 23, -1, 'bottom_margin')],
      });
    }
  };

  return {
    properties: {
      page: {
        size: pageSize,
        margin: pageMargin,
      },
    },
    children: [
      // table with data
      new Table({
        float: {
          horizontalAnchor: TableAnchorType.TEXT,
          verticalAnchor: TableAnchorType.TEXT,
          overlap: OverlapType.NEVER,
          absoluteVerticalPosition: convertPixelToTwip(0),
          absoluteHorizontalPosition: 0,
        },
        width: {
          size: convertPixelToTwip(tableWidth - 56),
          type: WidthType.DXA,
        },
        layout: TableLayoutType.AUTOFIT,
        rows: [
          // title
          new TableRow({
            height: {
              value: convertPixelToTwip(27),
              rule: HeightRule.ATLEAST,
            },
            children: [
              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth - 56),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.PRIMARY),
                children: [
                  // Title
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      textRun(locales.channels.sectionName, {
                        font: options.titleFont,
                        color: options.titleColor || COLORS.BLACK,
                        bold: options.channels?.titleBold,
                        size: 24,
                        allCaps: true,
                      }),
                      await getTopBgImg(),
                    ],
                  }),
                ],
              }),
            ],
          }),
          marginTableRow(10, tableWidth - 56, COLORS.PRIMARY, 1),
          // description row
          new TableRow({
            height: {
              value: convertPixelToTwip(28),
              rule: HeightRule.ATLEAST,
            },
            children: [
              new TableCell({
                width: {
                  size: convertPixelToTwip(726),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.PRIMARY),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      textRun(portfolio.channels.description, {
                        font: options.bodyFont,
                        color: options.bodyColor || COLORS.BLACK,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
          marginTableRow(20, tableWidth - 56, COLORS.PRIMARY, 1),

          // data
          new TableRow({
            children: [
              new TableCell({
                borders: transparentBorder(COLORS.PRIMARY),
                children: [
                  new Table({
                    rows: await Promise.all(
                      channel.collection.map(async (channelItem) => {
                        return new TableRow({
                          height: {
                            value: convertPixelToTwip(400),
                            rule: HeightRule.ATLEAST,
                          },
                          children: [
                            // cell with image
                            new TableCell({
                              width: {
                                size: convertPixelToTwip(285),
                                type: WidthType.DXA,
                              },
                              borders: transparentBorder(COLORS.PRIMARY),
                              children: await buildSegmentItemImage(
                                channelItem.image.original,
                                options.titleColor,
                                options.titleFont,
                                'CHANNELS',
                                options.channels,
                              ),
                            }),

                            // cell for margin
                            emptyCell(30, COLORS.PRIMARY),

                            // cell with data
                            new TableCell({
                              width: {
                                size: convertPixelToTwip(448),
                                type: WidthType.DXA,
                              },
                              borders: transparentBorder(COLORS.PRIMARY),
                              children: [
                                new Table({
                                  float: {
                                    horizontalAnchor: TableAnchorType.TEXT,
                                    verticalAnchor: TableAnchorType.TEXT,
                                    overlap: OverlapType.NEVER,
                                  },
                                  width: {
                                    size: convertPixelToTwip(448),
                                    type: WidthType.DXA,
                                  },
                                  layout: TableLayoutType.AUTOFIT,
                                  rows: [
                                    // title row
                                    new TableRow({
                                      height: {
                                        value: convertPixelToTwip(24),
                                        rule: HeightRule.ATLEAST,
                                      },
                                      children: [
                                        new TableCell({
                                          width: {
                                            size: convertPixelToTwip(448),
                                            type: WidthType.DXA,
                                          },
                                          borders: transparentBorder(
                                            COLORS.PRIMARY,
                                          ),
                                          children: [
                                            new Paragraph({
                                              children: [
                                                textRun(channelItem.name, {
                                                  size: 20,
                                                  color:
                                                    options.channels
                                                      ?.subtitleColor ||
                                                    options.bodyColor ||
                                                    COLORS.BLACK,
                                                  allCaps: true,
                                                  bold: true,
                                                  underline: true,
                                                  font: options.bodyFont,
                                                }),
                                              ],
                                            }),
                                          ],
                                        }),
                                      ],
                                    }),

                                    marginTableRow(25, 448, COLORS.PRIMARY, 1),

                                    // strategy
                                    new TableRow({
                                      children: [
                                        new TableCell({
                                          width: {
                                            size: convertPixelToTwip(448),
                                            type: WidthType.DXA,
                                          },
                                          borders: transparentBorder(
                                            COLORS.PRIMARY,
                                          ),
                                          children: [
                                            new Paragraph({
                                              children: [
                                                textRun('Channel strategy', {
                                                  size: 16,
                                                  color:
                                                    options.channels
                                                      ?.subtitleColor ||
                                                    options.bodyColor ||
                                                    COLORS.BLACK,
                                                  allCaps: true,
                                                  bold: true,
                                                  underline: true,
                                                  font: options.bodyFont,
                                                }),
                                              ],
                                            }),
                                            new Paragraph({
                                              spacing: {
                                                before: convertPixelToTwip(5),
                                              },
                                              children: [
                                                textRun(
                                                  channelItem.description,
                                                  {
                                                    size: 14,
                                                    font: options.bodyFont,
                                                    color:
                                                      options.bodyColor ||
                                                      COLORS.BLACK,
                                                  },
                                                ),
                                              ],
                                            }),
                                          ],
                                        }),
                                      ],
                                    }),

                                    marginTableRow(15, 448, COLORS.PRIMARY, 1),
                                  ],
                                }),
                              ],
                            }),
                          ],
                        });
                      }),
                    ),
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  };
};
