import React from 'react';

function ExpandRow({ children }) {
  return (
    <div className="admin-table-expand">
      <div className="admin-table-expand--body">{children}</div>
    </div>
  );
}

export default ExpandRow;
