import React from 'react';
import classNames from 'classnames';

import SvgIcon from '../../../components/SvgIcon';

const testStatusUnsure = 'unsure';
const testStatusValid = 'valid';
const testStatusInvalid = 'invalid';

const iconName = (status) => {
  switch (status) {
    case testStatusUnsure:
      return 'question';
    case testStatusInvalid:
      return 'x';
    case testStatusValid:
      return 'okSmall';
    default:
      return 'incomplete';
  }
};

export default function TestStatus({ status }) {
  const classes = {
    'testing-status--incomplete': !status,
    'testing-status--unsure': status === testStatusUnsure,
    'testing-status--invalid': status === testStatusInvalid,
    'testing-status--valid': status === testStatusValid,
  };

  return (
    <div className={classNames('testing-status', classes)}>
      <div className="testing-status-icon">
        {status ? (
          <SvgIcon name={iconName(status)} />
        ) : (
          <SvgIcon name={iconName('incomplete')} />
        )}
      </div>
      <div className="testing-status-text custom-font-body">
        {status || 'Incomplete'}
      </div>
    </div>
  );
}
