import React from 'react';

import Header from '../../components/Header';
import Text from '../../components/Text';
import Title from '../../components/Title';
import Card from './Card';
import List from '../../components/List';
import Line from '../../components/Line';
import Actions from '../../components/Actions';
import MobileMenuHeader from '../../components/MobileMenuHeader';

const Introduction = ({
  nextClickHandler,
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
  locales,
  locales: {
    common,
    tipCustomerSegment,
    customerSegment: {
      name,
      intro: { header, title, text, list, card },
    },
  },
}) => (
  <div className="bmc-page">
    <Header
      title={header}
      subtitle={name}
      icon="icon-customer-segment"
      tipLocales={tipCustomerSegment}
      locales={locales}
      componentIndex={componentIndex}
      menuItems={menuItems}
      onMenuItemClick={selectComponent}
      rules={rules}
      backPath={backPath}
      mobileMenuHeader={
        <MobileMenuHeader icon="CSCanvas" title={name} color="green" />
      }
    />

    <Text>{[text[0], text[1]]}</Text>

    <Title text={title[0]} align="left" />

    <List items={list} />

    <Line />

    <Title text={title[1]} />

    <Text>{[text[2]]}</Text>

    <Card
      card={card}
      locales={common}
      className="margin-top-60 margin-wide-40-lg"
    />

    <Actions nextClickHandler={nextClickHandler} buttonName={common.next} />
  </div>
);

export default Introduction;
