import {
  Paragraph,
  ImageRun,
  Table,
  TableRow,
  TableCell,
  AlignmentType,
  WidthType,
  HeightRule,
  TableAnchorType,
  OverlapType,
  TableLayoutType,
  PageBreak,
} from 'docx';

import { isEmpty } from 'lodash';
import {
  pageSize,
  pageMargin,
  convertPixelToTwip,
  convertPixel,
  COLORS,
  transparentBorder,
  bottomBorder,
  tableWidth,
} from '../Constants';
import {
  buildRelationshipIcon,
  emptyCell,
  marginTableRow,
  textRun,
} from '../helpers/index';
import RelationshipIcon from '../assets/icons/relationship_icon.svg';

export default async (locales, portfolio, getKeepGrow, options) => {
  const lineRow = new TableRow({
    height: {
      value: convertPixelToTwip(2),
      rule: HeightRule.ATLEAST,
    },
    children: [
      emptyCell(30, COLORS.WHITE),
      new TableCell({
        columnSpan: 4,
        width: {
          size: convertPixelToTwip(726),
          type: WidthType.DXA,
        },
        borders: transparentBorder(COLORS.WHITE),
        children: [
          // Line
          new Paragraph({
            border: {
              top: {
                color: COLORS.PRIMARY,
                space: 2,
                style: 'single',
                size: 6,
              },
            },
          }),
        ],
      }),
      emptyCell(30, COLORS.WHITE),
    ],
  });

  const iconDark = await buildRelationshipIcon(
    RelationshipIcon,
    options.relationships.iconDarkColor,
  );
  const iconLight = await buildRelationshipIcon(
    RelationshipIcon,
    options.relationships.iconLightColor,
  );

  const strategiesRows = (strategies) => {
    const dataRows = strategies.map((strategy) => {
      if (isEmpty(strategy.name) || isEmpty(strategy.description))
        return marginTableRow(1, tableWidth - 56, COLORS.WHITE, 6);

      return [
        new TableRow({
          height: {
            value: convertPixelToTwip(43),
            rule: HeightRule.ATLEAST,
          },
          children: [
            emptyCell(30, COLORS.WHITE),
            new TableCell({
              columnSpan: 4,
              width: {
                size: convertPixelToTwip(726),
                type: WidthType.DXA,
              },
              borders: transparentBorder(COLORS.WHITE),
              margins: {
                top: convertPixelToTwip(10),
                left: convertPixelToTwip(10),
                bottom: convertPixelToTwip(10),
                right: convertPixelToTwip(10),
              },
              children: [
                new Paragraph({
                  children: [
                    textRun(strategy.name, {
                      size: 16,
                      allCaps: true,
                      font: options.bodyFont,
                      color: options.bodyColor || COLORS.BLACK,
                    }),
                  ],
                }),
                new Paragraph({
                  spacing: {
                    before: convertPixelToTwip(10),
                    after: convertPixelToTwip(10),
                  },
                  children: [
                    textRun(strategy.description, {
                      font: options.bodyFont,
                      color: options.bodyColor || COLORS.BLACK,
                    }),
                  ],
                }),
              ],
            }),
            emptyCell(30, COLORS.WHITE),
          ],
        }),
        lineRow,
      ];
    });

    return dataRows.flat(1);
  };

  const buildStrategyPage = (strategies, type) => {
    const getHeaderCell = (headerType) =>
      new TableCell({
        width: {
          size: convertPixelToTwip(112),
          type: WidthType.DXA,
        },
        margins: {
          bottom: convertPixelToTwip(10),
        },
        borders:
          type === headerType
            ? bottomBorder(
                COLORS.WHITE,
                options.relationships?.activeItemBorder || COLORS.BLACK,
              )
            : transparentBorder(COLORS.WHITE),
        children: [
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new ImageRun({
                data: type === headerType ? iconDark : iconLight,
                transformation: {
                  width: convertPixel(72),
                  height: convertPixel(41),
                },
              }),
            ],
          }),
          new Paragraph({
            alignment: AlignmentType.CENTER,
            spacing: {
              before: convertPixelToTwip(24),
            },
            children: [
              textRun(headerType, {
                size: 24,
                allCaps: true,
                color:
                  type === headerType
                    ? options.relationships.activeItemColor?.color
                    : options.relationships.inactiveItemColor?.color,
                bold:
                  type === headerType
                    ? options.relationships.activeItemColor?.bold
                    : options.relationships.inactiveItemColor?.bold,
              }),
            ],
          }),
        ],
      });

    return new Table({
      float: {
        horizontalAnchor: TableAnchorType.TEXT,
        verticalAnchor: TableAnchorType.TEXT,
        overlap: OverlapType.NEVER,
      },
      width: {
        size: convertPixelToTwip(tableWidth - 56),
        type: WidthType.DXA,
      },
      layout: TableLayoutType.AUTOFIT,
      rows: [
        // title and description
        new TableRow({
          height: {
            value: convertPixelToTwip(94),
            rule: HeightRule.ATLEAST,
          },
          children: [
            emptyCell(30, COLORS.WHITE),
            // title cell
            new TableCell({
              width: {
                size: convertPixelToTwip(194),
                type: WidthType.DXA,
              },
              borders: transparentBorder(COLORS.PRIMARY),
              children: [
                // Title
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    textRun(locales.customerRelationships.sectionName, {
                      font: options.titleFont,
                      bold: options?.relationships?.titleBold,
                      color: options.titleColor || COLORS.BLACK,
                      size: 24,
                      allCaps: true,
                    }),
                  ],
                }),
              ],
            }),
            emptyCell(60, COLORS.WHITE),

            // description cell
            new TableCell({
              width: {
                size: convertPixelToTwip(472),
                type: WidthType.DXA,
              },
              columnSpan: 2,
              borders: transparentBorder(COLORS.PRIMARY),
              children: [
                // Title
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    textRun(portfolio.customerRelationships.description, {
                      font: options.bodyFont,
                      color: options.bodyColor || COLORS.BLACK,
                    }),
                  ],
                }),
              ],
            }),
            emptyCell(30, COLORS.WHITE),
          ],
        }),

        marginTableRow(20, tableWidth - 56, COLORS.WHITE, 6),

        // row with getKeepGrow headers
        new TableRow({
          height: {
            value: convertPixelToTwip(73),
            rule: HeightRule.ATLEAST,
          },
          children: [
            new TableCell({
              columnSpan: 6,
              width: {
                size: convertPixelToTwip(tableWidth - 56),
                type: WidthType.DXA,
              },
              borders: transparentBorder(COLORS.WHITE),
              children: [
                new Table({
                  float: {
                    horizontalAnchor: TableAnchorType.TEXT,
                    verticalAnchor: TableAnchorType.TEXT,
                    overlap: OverlapType.NEVER,
                  },
                  width: {
                    size: convertPixelToTwip(tableWidth - 56),
                    type: WidthType.DXA,
                  },
                  layout: TableLayoutType.AUTOFIT,
                  rows: [
                    new TableRow({
                      height: {
                        value: convertPixelToTwip(73),
                        rule: HeightRule.ATLEAST,
                      },
                      children: [
                        emptyCell(169, COLORS.WHITE),
                        getHeaderCell('Get'),
                        getHeaderCell('Keep'),
                        getHeaderCell('Grow'),
                        getHeaderCell('Referrals'),
                        emptyCell(169, COLORS.WHITE),
                      ],
                    }),
                  ],
                }),
              ],
            }),

            // emptyCell(30, COLORS.WHITE),
          ],
        }),

        marginTableRow(20, tableWidth - 56, COLORS.WHITE, 6),

        ...strategiesRows(strategies),
      ],
    });
  };

  const children = () => {
    const getStrategiesPage = buildStrategyPage(
      getKeepGrow.getStrategies,
      'Get',
    );
    const keepStrategiesPage = buildStrategyPage(
      getKeepGrow.keepStrategies,
      'Keep',
    );
    const growStrategiesPage = buildStrategyPage(
      getKeepGrow.growStrategies,
      'Grow',
    );
    const referralStrategiesPage = buildStrategyPage(
      getKeepGrow.referralStrategies,
      'Referrals',
    );

    return [
      getStrategiesPage,
      new Paragraph({ children: [new PageBreak()] }),
      keepStrategiesPage,
      new Paragraph({ children: [new PageBreak()] }),
      growStrategiesPage,
      new Paragraph({ children: [new PageBreak()] }),
      referralStrategiesPage,
    ];
  };

  return {
    properties: {
      page: {
        size: pageSize,
        margin: pageMargin,
      },
    },
    children: children(),
  };
};
