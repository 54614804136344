import React, { useMemo } from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import Dropzone from '../../components/Dropzone';
import Textarea from '../../components/Textarea';
import {
  CUSTOMER_SEGMENT_TYPE_OPTIONS,
  CUSTOMER_SEGMENT_AGE_RANGE_MAX,
  CUSTOMER_SEGMENT_AGE_RANGE_MIN,
  CUSTOMER_SEGMENT_COMPANY_SIZE_MIN,
  CUSTOMER_SEGMENT_COMPANY_SIZE_MAX,
  CUSTOMER_SEGMENT_B2B,
  SELECT_STYLES_SEGMENT,
  CURRENT_TEMPLATE_NAME,
} from '../../utils/const';
import { customerSegmentType, selectValue } from '../../utils/helpers';
import RangeWithInputCurrent from '../../components/RangeWithInputCurrent';
import { renderImage } from '../../utils/helpers/renderImage';

const getName = (type) => {
  return type === CUSTOMER_SEGMENT_B2B ? 'companySize' : 'ageRange';
};

export default function CardCurrent({
  editMode = false,
  dropzoneMode = false,
  portfolioMode = false,
  disabled = false,
  locales,
  locales: { portfolio },
  className,
  background,
  card,
  card: {
    id,
    title,
    subtitle,
    image,
    imageCoords,
    ageRange,
    companySize,
    ageRangeTitle,
    companySizeTitle,
    type,
    typeTitle,
    icon,
  },
  styleColor,
}) {
  const fields = ['description', 'goals', 'frustrates', 'problems'];

  const {
    customerSegment: {
      updateCustomerSegment,
      updateCustomerSegmentImage,
      updateCustomerSegmentImageCoords,
    },
  } = useDispatch();

  const { uploadingImage } = useSelector((state) => state.customerSegment);

  const [cardTitle, cardSizeValue, minValue, maxValue] = useMemo(() => {
    if (type === CUSTOMER_SEGMENT_B2B) {
      return [
        companySizeTitle,
        companySize,
        CUSTOMER_SEGMENT_COMPANY_SIZE_MIN,
        CUSTOMER_SEGMENT_COMPANY_SIZE_MAX,
      ];
    }
    return [
      ageRangeTitle,
      ageRange,
      CUSTOMER_SEGMENT_AGE_RANGE_MIN,
      CUSTOMER_SEGMENT_AGE_RANGE_MAX,
    ];
  }, [type, ageRange, companySize]);

  const onImageDrop = ([file], openCropperHandler) => {
    updateCustomerSegmentImage({
      id,
      name: 'image',
      blob: file,
      success: () => {
        openCropperHandler();
      },
    });
  };

  const onImageCropperSave = (croppedAreaPixels, innerCropData, zoom) => {
    updateCustomerSegmentImageCoords({
      id,
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: imageCoords,
    });
  };

  return (
    <div className="bmc-page-card-wrapper">
      <div
        className={classNames(
          'bmc-page-card bmc-page-card--customer-segment-mode',
          className,
          { 'bmc-page-card--portfolio-mode': portfolioMode },
        )}
      >
        <div className="bmc-page-card-inner">
          {title && (
            <div className="bmc-page-card-row">
              <img src={icon} className="bmc-page-card-icon" alt="" />
              <div className="bmc-page-card-big-title custom-font-title">
                {title}
              </div>
            </div>
          )}

          <div className="bmc-page-card-row">
            {editMode ? (
              <Textarea
                delay={700}
                value={subtitle}
                className="textarea-current textarea-current-segment textarea-current-segment-name custom-font-title"
                placeholder={portfolio.customerSegment.titlePlaceholder}
                onChange={(e) =>
                  updateCustomerSegment({
                    name: 'name',
                    value: e.target.value,
                    id,
                  })
                }
              />
            ) : (
              <div className="bmc-page-card-big-title custom-font-title">
                {subtitle}
              </div>
            )}
          </div>

          <div
            className="bmc-page-card-line"
            style={{
              backgroundColor: styleColor,
            }}
          />
          {editMode || dropzoneMode ? (
            <div className="bmc-page-card-dropzone margin-top-30">
              <Dropzone
                className="bmc-page-card-image"
                onDrop={onImageDrop}
                onCropperSave={onImageCropperSave}
                locales={locales}
                image={image}
                coords={imageCoords}
                uploading={uploadingImage === id}
                aspectRatio={1}
                disabled={disabled}
                cropper
              />
            </div>
          ) : (
            renderImage(
              CURRENT_TEMPLATE_NAME,
              image,
              background,
              locales.portfolio.customerSegment.sectionName,
              1,
            )
          )}
        </div>

        <div className="bmc-page-card-inner">
          <div className="bmc-page-card-row">
            {!!type && (
              <div className="bmc-page-card-inner">
                <div className="bmc-page-card-segmentList custom-font-body">
                  {cardTitle}
                </div>
                {editMode ? (
                  <RangeWithInputCurrent
                    locales={locales}
                    enableRange={false}
                    value={cardSizeValue}
                    minValue={minValue}
                    maxValue={maxValue}
                    delay={700}
                    className="textarea-current textarea-current-segment textarea-current-segment-range textarea-current-segment-range-left custom-font-body"
                    onChange={(value) =>
                      updateCustomerSegment({
                        name: getName(type),
                        value,
                        id,
                      })
                    }
                  />
                ) : (
                  <div className="bmc-page-card-text custom-font-body">
                    {cardSizeValue.min} - {cardSizeValue.max}
                  </div>
                )}
              </div>
            )}

            <div className="bmc-page-card-inner">
              <div className="bmc-page-card-segmentList custom-font-body">
                {typeTitle}
              </div>
              {editMode ? (
                <Select
                  options={CUSTOMER_SEGMENT_TYPE_OPTIONS}
                  styles={SELECT_STYLES_SEGMENT}
                  className="custom-font-body"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    height: '32px',
                  })}
                  value={selectValue(type, customerSegmentType(type))}
                  onChange={({ value }) =>
                    updateCustomerSegment({ id, name: 'type', value })
                  }
                />
              ) : (
                <div className="bmc-page-card-text custom-font-body">
                  {customerSegmentType(type)}
                </div>
              )}
            </div>
          </div>

          {fields.map((field, index) => (
            <div className="bmc-page-card-row" key={index}>
              <div className="bmc-page-card-segmentList custom-font-body">
                {card[`${field}Title`]}
              </div>
              {editMode ? (
                <Textarea
                  value={card[field]}
                  delay={700}
                  className="textarea-current textarea-current-segment custom-font-body"
                  placeholder={portfolio.page.informationPlaceholder}
                  onChange={(e) =>
                    updateCustomerSegment({
                      id,
                      name: field,
                      value: e.target.value,
                    })
                  }
                />
              ) : (
                <div className="bmc-page-card-text custom-font-body">
                  {card[field]}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
