import {
  Paragraph,
  ImageRun,
  Table,
  TableRow,
  TableCell,
  AlignmentType,
  WidthType,
  HeightRule,
  TableAnchorType,
  OverlapType,
  TableLayoutType,
  VerticalAlign,
} from 'docx';

import { concat } from 'lodash';
import {
  pageSize,
  pageMargin,
  convertPixelToTwip,
  convertPixel,
  COLORS,
  transparentBorder,
  getBlobFromUrl,
  shadingBox,
  tableWidth,
} from '../Constants';
import {
  buildAnalysisImg,
  buildBodyNoImg,
  marginTableRow,
  textRun,
} from '../helpers/index';

export default async (locales, portfolio, competitor, options) => {
  const tableHeaders = [
    'Competitors',
    'What they do well?',
    'What they do badly?',
  ];

  const tableBorderColor = options.analysis?.tableBorderColor || COLORS.PRIMARY;

  const buildHeaderCells = () =>
    tableHeaders.map(
      (headerName) =>
        new TableCell({
          width: {
            size: convertPixelToTwip(144),
            type: WidthType.DXA,
          },
          shading: shadingBox(
            options.analysis?.tableBg || options.backgroundDark,
          ),
          borders: transparentBorder(COLORS.BLACK),
          verticalAlign: VerticalAlign.CENTER,
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                textRun(headerName, {
                  size: 16,
                  allCaps: true,
                  bold: true,
                  color: COLORS.WHITE,
                  font: options.bodyFont,
                }),
              ],
            }),
          ],
        }),
    );

  const dataRows = async () => {
    const tableHead = new TableRow({
      height: {
        value: convertPixelToTwip(39),
        rule: HeightRule.ATLEAST,
      },
      children: buildHeaderCells(),
    });

    const tableCellContent = (contentText) =>
      new TableCell({
        width: {
          size: convertPixelToTwip(321),
          type: WidthType.DXA,
        },
        margins: {
          right: convertPixelToTwip(5),
          left: convertPixelToTwip(5),
        },
        verticalAlign: VerticalAlign.CENTER,
        borders: {
          top: { color: tableBorderColor },
          left: { color: tableBorderColor },
          right: { color: tableBorderColor },
          bottom: {
            color: tableBorderColor,
            space: 2,
            style: 'single',
            size: 6,
          },
        },
        children: [
          new Paragraph({
            alignment: AlignmentType.START,
            children: [
              textRun(contentText, {
                size: 14,
                font: options.bodyFont,
                color: options.bodyColor || COLORS.BLACK,
              }),
            ],
          }),
        ],
      });

    const rowsList = await Promise.all(
      competitor.collection.map(async (competitorItem) => {
        let blobImg;
        await getBlobFromUrl(competitorItem.image.cropped).then((res) => {
          blobImg = res;
        });

        const getImgSection = async () => {
          if (!blobImg) blobImg = await buildBodyNoImg(40, options.analysis);
          else
            blobImg = await buildAnalysisImg(
              blobImg,
              competitorItem.image.cropped,
              options.analysis,
            );

          return new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new ImageRun({
                data: blobImg,
                transformation: {
                  width: convertPixel(40),
                  height: convertPixel(40),
                },
              }),
            ],
          });
        };

        return new TableRow({
          height: {
            value: convertPixelToTwip(82),
            rule: HeightRule.ATLEAST,
          },
          children: [
            new TableCell({
              width: {
                size: convertPixelToTwip(144),
                type: WidthType.DXA,
              },
              borders: {
                top: { color: tableBorderColor },
                left: { color: tableBorderColor },
                right: { color: tableBorderColor },
                bottom: {
                  color: tableBorderColor,
                  space: 2,
                  style: 'single',
                  size: 6,
                },
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                await getImgSection(),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  spacing: {
                    before: 200,
                  },
                  children: [
                    textRun(competitorItem.name, {
                      size: 14,
                      color: options.bodyColor || COLORS.BLACK,
                      font: options.bodyFont,
                    }),
                  ],
                }),
              ],
            }),
            tableCellContent(competitorItem.doWell),
            tableCellContent(competitorItem.doBadly),
          ],
        });
      }),
    );

    return concat([tableHead], rowsList);
  };

  return {
    properties: {
      page: {
        size: pageSize,
        margin: pageMargin,
      },
    },
    children: [
      // table with data
      new Table({
        float: {
          horizontalAnchor: TableAnchorType.TEXT,
          verticalAnchor: TableAnchorType.TEXT,
          overlap: OverlapType.NEVER,
        },
        width: {
          size: convertPixelToTwip(tableWidth - 56),
          type: WidthType.DXA,
        },
        layout: TableLayoutType.AUTOFIT,
        rows: [
          marginTableRow(20, tableWidth - 56, COLORS.PRIMARY, 1),
          // title
          new TableRow({
            height: {
              value: convertPixelToTwip(27),
              rule: HeightRule.ATLEAST,
            },
            children: [
              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth - 56),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.PRIMARY),
                children: [
                  // Title
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      textRun(locales.competitorAnalysis.sectionName, {
                        font: options.titleFont,
                        bold: options?.analysis?.titleBold,
                        color: options.titleColor || COLORS.BLACK,
                        size: 24,
                        allCaps: true,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
          marginTableRow(10, tableWidth - 56, COLORS.PRIMARY, 1),
          // description row
          new TableRow({
            height: {
              value: convertPixelToTwip(28),
              rule: HeightRule.ATLEAST,
            },
            children: [
              new TableCell({
                width: {
                  size: convertPixelToTwip(726),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.PRIMARY),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      textRun(portfolio.competitorAnalysis.description, {
                        color: options.bodyColor || COLORS.BLACK,
                        font: options.bodyFont,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
          marginTableRow(30, tableWidth - 56, COLORS.PRIMARY, 1),

          // data
          new TableRow({
            children: [
              new TableCell({
                borders: transparentBorder(COLORS.PRIMARY),
                children: [
                  new Table({
                    rows: await dataRows(),
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  };
};
