import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, find, last } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import SvgIcon from './SvgIcon';

const PortfolioCommentIcon = ({ title, className }) => {
  const {
    chatGroup: { updateChatGroupAttribute },
  } = useDispatch();

  const [isActive, setIsActive] = useState(false);
  const [currentChatGroup, setCurrentChatGroup] = useState(null);

  const {
    chatGroup,
    user: {
      current: { id: userId },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!userId || isEmpty(chatGroup.collection)) return;

    const chatGroupItem = find(chatGroup.collection, ['name', title]);
    setCurrentChatGroup(chatGroupItem);

    const lastComment = last(chatGroupItem?.chatGroupComments);
    if (!lastComment) return;

    const currentChatGroupMember = find(chatGroupItem.chatGroupMembers, [
      'userId',
      userId,
    ]);
    if (!currentChatGroupMember) return;

    const activeAt = moment(currentChatGroupMember?.activeAt);
    const createdAt = moment(lastComment?.createdAt);

    if (
      activeAt.isBefore(createdAt) ||
      currentChatGroupMember.activeAt === null
    ) {
      setIsActive(true);
    } else setIsActive(false);
  }, [chatGroup, userId]);

  const handleIconClick = () => {
    if (currentChatGroup) {
      updateChatGroupAttribute({
        isExpanded: true,
        selectedChatGroup: currentChatGroup,
        commentIsOpened: true,
      });
    }
  };

  return (
    <div
      className={classNames('portfolio-comment-icon', className, {
        active: isActive,
      })}
      onClick={handleIconClick}
    >
      <SvgIcon name="headerComment" />
    </div>
  );
};

export default PortfolioCommentIcon;
