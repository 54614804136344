import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { truncate } from 'lodash';
import useMediaQuery from 'react-use-media-query-hook';

import Card from '../../main/containers/channel/CardBlogArch';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import {
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  IMAGE_ASPECT_RATIO_CH_ARCH,
  IMAGE_ASPECT_RATIO_ONE,
} from '../../main/utils/const';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';
import { getImageAspectRatio } from '../../main/utils/helpers/getImageAspectRatio';
import { getStylesDependsOnBackgroundAndPalleteChannels } from '../../main/utils/helpers/portfolioArchTemplate/getStylesDependsOnBackgroundAndPalleteChannels';

const Channels = ({
  locales,
  locales: {
    portfolioWeb,
    channel: {
      intro: { card },
    },
  },
  background,
  backgroundImage,
  colorPaletteNumber,
  isFourthColorPaletteNumber = false,
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isSmallDesktop = useMediaQuery('(max-width: 1024px)');
  const [activeCardId, setActiveCardId] = useState(null);
  const { channels } = useSelector((state) => state.portfolio);
  const { collection } = useSelector((state) => state.channel);

  const activeCardData = useMemo(() => {
    if (collection.length && !activeCardId) {
      setActiveCardId(collection[0].id);
      return collection[0];
    }
    return collection.find((i) => i.id === activeCardId);
  }, [collection, activeCardId]);

  const textColorDependsOnBackground =
    isFourthColorPaletteNumber && getColorDependsOnBackground(background);

  const tabStyles = getStylesDependsOnBackgroundAndPalleteChannels(
    colorPaletteNumber,
    background,
  );

  const singleCard = activeCardData && (
    <Card
      locales={locales}
      isPreview
      background={background}
      backgroundImage={backgroundImage}
      colorPaletteNumber={colorPaletteNumber}
      textColor={textColorDependsOnBackground}
      textImageColor={
        isFourthColorPaletteNumber &&
        getColorDependsOnBackground(backgroundImage)
      }
      templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
      aspectRatio={getImageAspectRatio(
        isSmallDesktop,
        IMAGE_ASPECT_RATIO_ONE,
        IMAGE_ASPECT_RATIO_CH_ARCH,
      )}
      card={{
        ...card,
        ...activeCardData,
        title: null,
      }}
    />
  );

  return (
    <div className="channel-architectural-web">
      <SectionHeaderWeb
        templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
        title={portfolioWeb.channels}
        description={channels.description}
        portfolioWeb={portfolioWeb}
        colorPaletteNumber={colorPaletteNumber}
      />

      <div className="cards-section">
        <div className="channel-tabs">
          {collection.map(({ id, name }) => (
            <div
              key={id}
              onClick={() => setActiveCardId(id)}
              className={classnames('item', 'custom-font-body', {
                active: activeCardId === id,
              })}
              style={activeCardId === id ? tabStyles : {}}
            >
              {truncate(name, { length: 30 })}
            </div>
          ))}
        </div>
        <div className="channel-card">
          {isMobile
            ? collection.map((channel) => {
                return (
                  <Card
                    locales={locales}
                    className="margin-top-20"
                    isPreview
                    background={background}
                    backgroundImage={backgroundImage}
                    colorPaletteNumber={colorPaletteNumber}
                    textColor={textColorDependsOnBackground}
                    textImageColor={
                      isFourthColorPaletteNumber &&
                      getColorDependsOnBackground(backgroundImage)
                    }
                    templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
                    aspectRatio={getImageAspectRatio(
                      isSmallDesktop,
                      IMAGE_ASPECT_RATIO_ONE,
                      IMAGE_ASPECT_RATIO_CH_ARCH,
                    )}
                    card={{
                      ...card,
                      ...channel,
                      title: null,
                    }}
                  />
                );
              })
            : singleCard}
        </div>
      </div>
    </div>
  );
};

export default Channels;
