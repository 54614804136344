import React from 'react';
import { useSelector } from 'react-redux';

import HomepageTest from './Homepage';
import GeneralPage from './GeneralPage';

const AnalyseResults = ({
  nextClickHandler,
  menuItems,
  selectComponent,
  locales,
  componentIndex,
  rules,
  backPath,
}) => {
  const {
    testing: { test },
  } = useSelector((state) => state);

  const testType = test.test;

  if (testType === 'homepage')
    return (
      <HomepageTest
        testObject={test}
        nextClickHandler={nextClickHandler}
        locales={locales}
        menuItems={menuItems}
        selectComponent={selectComponent}
        componentIndex={componentIndex}
        rules={rules}
        backPath={backPath}
      />
    );
  return (
    <GeneralPage
      testObject={test}
      nextClickHandler={nextClickHandler}
      locales={locales}
      menuItems={menuItems}
      selectComponent={selectComponent}
      componentIndex={componentIndex}
      rules={rules}
      backPath={backPath}
    />
  );
};

export default AnalyseResults;
