import React, { useMemo } from 'react';
import { Pagination as AntPagination } from 'antd';
import PropTypes from 'prop-types';
import Select from './Select';

function Pagination({
  total = 0,
  current,
  disabled = false,
  pageSize = 5,
  pageSizeOptions = [5, 10, 20, 100],
  onChange,
}) {
  const options = useMemo(() => {
    return pageSizeOptions.map((option) => ({
      value: option,
      label: `${option} / page`,
    }));
  }, [pageSizeOptions]);

  const currentPageSizeValue = useMemo(() => {
    return options.find((option) => option.value === pageSize);
  }, [pageSize]);

  const handleChangePageSize = (selectedOption) => {
    const pageCount = Number.parseInt(total / selectedOption.value);
    const page = pageCount > current ? current : 1;
    onChange({
      pageSize: selectedOption.value,
      page,
    });
  };

  const handleChangePagination = (page, currentPageSize) => {
    onChange({
      pageSize: currentPageSize,
      page,
    });
  };

  return (
    <div className="admin-pagination">
      <AntPagination
        current={current}
        total={total}
        disabled={disabled}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        showSizeChanger={false}
        onChange={handleChangePagination}
      />
      <div style={{ width: 135 }}>
        <Select
          options={options}
          value={currentPageSizeValue}
          onChange={handleChangePageSize}
        />
      </div>
    </div>
  );
}

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
};
export default Pagination;
