import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { find, isEmpty } from 'lodash';
import MessageListContainer from './message-list.container';
import MessageItemContainer from './message-item.container';

const iconBell = (
  <svg
    width="17"
    height="21"
    viewBox="0 0 17 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.50025 21C8.649 21 8.78712 20.9892 8.92525 20.9569C9.61587 20.8062 10.179 20.3323 10.4552 19.6862C10.5615 19.4277 10.6146 19.1477 10.6146 18.8462H6.36462C6.37525 20.0308 7.32087 21 8.50025 21ZM14.875 9.15385C14.875 5.84769 13.1325 3.08 10.0938 2.34769V1.61538C10.0938 0.721538 9.38188 0 8.5 0C7.61812 0 6.90625 0.721538 6.90625 1.61538V2.34769C3.85688 3.08 2.125 5.83692 2.125 9.15385V14.5385L0 16.6923V17.7692H17V16.6923L14.875 14.5385V9.15385Z"
      fill="#0984FF"
    />
  </svg>
);

export default function MessageContainer() {
  const {
    message,
    user,
    bmc: { collection },
  } = useSelector((selector) => selector);
  const {
    message: { fetchMessages },
    bmc: { fetchBmcs },
  } = useDispatch();

  const messages = useMemo(() => {
    if (!user?.current?.id) return [];

    const result = {};

    message.collection.forEach((messageItem) => {
      if (result[`${messageItem.bmc.id}`]) {
        result[`${messageItem.bmc.id}`].unreadMessageCount += 1;
        result[`${messageItem.bmc.id}`].unreadMentionCount +=
          messageItem.notificationable.mentions.includes(user?.current?.id);
      } else {
        result[`${messageItem.bmc.id}`] = {
          uniqKey: messageItem.bmc.id,
          title: messageItem.bmc.name,
          unreadMessageCount: 1,
          unreadMentionCount: messageItem.notificationable.mentions.includes(
            user?.current?.id,
          ),
        };
      }
    });

    return Object.values(result);
  }, [message, user]);

  useEffect(() => {
    fetchMessages();
    if (isEmpty(collection)) fetchBmcs();
  }, []);

  const handleClick = (uniqKey) => {
    const currentBmc = find(collection, ['id', uniqKey]);

    if (user.current.role === 'student' || currentBmc) {
      window.location.href = `/bmcs/${uniqKey}/portfolio?openUnreadComment=true`;
    } else {
      window.location.href = `/admin/bmc/${uniqKey}`;
    }
  };
  return (
    <div className="message-container">
      <div className="message-component">
        <div
          className={classNames('message-component__icon', {
            active: message.collection.length > 0,
          })}
        >
          {iconBell}
        </div>
        <MessageListContainer isHaveNotice={messages.length > 0}>
          {messages.map((messageItem) => (
            <MessageItemContainer
              key={messageItem.uniqKey}
              {...messageItem}
              onClick={handleClick}
            />
          ))}
        </MessageListContainer>
      </div>
    </div>
  );
}
