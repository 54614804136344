import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';

import Modal from '../Modal';
import Button from '../Button';
import Spinner from '../Spinner';

const SLIDER_MIN = 1;
const SLIDER_MAX = 3;
const SLIDER_STEP = 0.1;

export default function DropzoneModal({
  onCropperSave,
  dropzone,
  setModalOpened,
  originalImage,
  coords,
  uploading,
  aspectRatio,
  locales: { common },
  disabled = false,
}) {
  const { getRootProps, getInputProps } = dropzone;

  const defaultCoords = {
    cropperData: {
      x: 0,
      y: 0,
      zoom: 1,
    },
  };

  const {
    cropperData: { x, y, zoom: zoomValue },
  } = { ...defaultCoords, ...coords };

  const [crop, setCrop] = useState({ x, y });
  const [zoom, setZoom] = useState(zoomValue);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropCompleteHandler = (_, _croppedAreaPixels) => {
    setCroppedAreaPixels(_croppedAreaPixels);
  };

  const onSave = () => {
    onCropperSave(croppedAreaPixels, crop, zoom);
    setModalOpened(false);
  };

  const heightPercents = (1 / aspectRatio) * 100;
  const maxHeight = 75;
  const objectFit =
    heightPercents < maxHeight ? 'horizontal-cover' : 'vertical-cover';
  const actualHeightPercents =
    heightPercents < maxHeight ? heightPercents : maxHeight;
  const paddingTop = `min(${actualHeightPercents}%, calc(80vh - 170px))`;

  return (
    <Modal onBlackscreenClick={() => setModalOpened(false)}>
      <div className="dndmodal">
        <div className="dndmodal__header">
          <div className="dndmodal__header-title">{common.imageSetting}</div>

          <div className="dndmodal__header-actions">
            <div {...getRootProps()} className="margin-right-10">
              <Button
                title="Choose new image"
                className="bmc-button--blue"
                disabled={disabled}
              />
              <input {...getInputProps()} />
            </div>

            <Button
              title={common.save}
              primary
              onClick={onSave}
              disabled={disabled}
            />
          </div>
        </div>

        {uploading ? (
          <Spinner big />
        ) : (
          <div style={{ paddingTop }} className="dndmodal__cropper">
            <Cropper
              image={originalImage}
              crop={crop}
              zoom={zoom}
              aspect={aspectRatio}
              onCropChange={setCrop}
              onCropComplete={onCropCompleteHandler}
              onZoomChange={setZoom}
              objectFit={objectFit}
            />
          </div>
        )}

        <div className="dndmodal__footer">
          <div className="dndmodal__slider">
            <Slider
              value={zoom}
              min={SLIDER_MIN}
              max={SLIDER_MAX}
              step={SLIDER_STEP}
              aria-labelledby="Zoom"
              onChange={(_, _zoom) => setZoom(_zoom)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
