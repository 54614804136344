import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';

import BmcCanvasContainer from '../bmc/BmcCanvasContainer';
import Button from '../../components/Button';
import Spinner from '../../components/Spinner';
import Card from './Card';
import * as AppRoute from '../../utils/appRoutes';
import FirstTimeTip from '../tips/FirstTimeTip';
import { ENABLE_ONBOARDING } from '../../utils/const';

export default function index({ locales }) {
  const history = useHistory();
  const slick = useRef(null);

  const {
    list: {
      title,
      createNewIdea,
      createAnotherIdea,
      dontHaveBusinessIdea,
      letsBrainstorm,
    },
    intro: { card },
  } = locales.ideation;

  const {
    ideation: { collection, uploading },
    bmc: {
      current: currentBmc,
      current: { id: bmcId },
    },
  } = useSelector((state) => state);

  const {
    bmc: { setBmcView },
    ideation: { fetchIdeas, addIdea, removeIdea },
    user: { updateCurrentUser },
  } = useDispatch();

  useEffect(() => {
    setBmcView('');
  }, []);

  useEffect(() => {
    if (bmcId) {
      updateCurrentUser({ name: 'lastBmcId', value: bmcId });
      fetchIdeas();
    }
  }, [bmcId]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  async function addIdeaHandler() {
    const data = await addIdea();
    return data && history.push(AppRoute.ideation(bmcId, data.id));
  }

  const getIdeaData = (idea) => {
    const {
      data: { ideas, pickedIdeaId },
    } = idea;
    const pickedIdea = ideas && ideas.find((e) => e.id === pickedIdeaId);

    return pickedIdea || {};
  };

  const getCardData = (idea) => {
    return {
      ...card,
      url: null,
      problem: '',
      solution: '',
      marketSize: 0,
      price: 0,
      excitement: 0,
      ...getIdeaData(idea),
      id: idea.id,
    };
  };

  const handlePrevSlide = () => {
    if (slick.current) {
      slick.current.slickPrev();
    }
  };

  const handleNextSlide = () => {
    if (slick.current) {
      slick.current.slickNext();
    }
  };

  return (
    <BmcCanvasContainer locales={locales}>
      {ENABLE_ONBOARDING && (
        <FirstTimeTip page="ideation" tips="ideationTip" locales={locales} />
      )}
      <div className="bmc-main">
        <div className="bmc-main-header">
          <h1 className="bmc-main-header-title">{title}</h1>

          <div className="bmc-main-header-actions bmc-main-header-actions--ideation">
            {/* todo: temporary disabled <Button
              icon="icon-Comment"
              className="bmc-button--blue bmc-button--just-icon margin-right-10"
            />
            <Button
              icon="icon-Hat"
              className="bmc-button--blue bmc-button--just-icon margin-right-10"
            /> */}
            {collection.length > 0 && (
              <Button
                title={createAnotherIdea}
                className="bmc-button--primary"
                onClick={() => addIdeaHandler()}
              />
            )}
          </div>
        </div>

        {uploading ? (
          <Spinner className="margin-top-60" big />
        ) : (
          <div className="ideation-list">
            {collection.length > 0 ? (
              <>
                <div className="ideation-list-items">
                  {collection.length > 1 && (
                    <div
                      onClick={handlePrevSlide}
                      className="ideation-list-items-arrow ideation-list-items-arrow--left"
                    >
                      <i className="icon-Arrow-Top" />
                    </div>
                  )}

                  <Slider ref={slick} {...settings}>
                    {collection.map((idea) => (
                      <Card
                        card={getCardData(idea)}
                        bmc={currentBmc}
                        removeIdea={removeIdea}
                        key={idea.id}
                        className="ideation-card--white"
                        locales={locales}
                        showEditButton
                      />
                    ))}
                  </Slider>

                  {collection.length > 1 && (
                    <div
                      onClick={handleNextSlide}
                      className="ideation-list-items-arrow ideation-list-items-arrow--right"
                    >
                      <i className="icon-Arrow-Top" />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="ideation-list-empty">
                <div className="ideation-list-empty-text">
                  {dontHaveBusinessIdea}
                </div>
                <div className="ideation-list-empty-text margin-bottom-40">
                  {letsBrainstorm}
                </div>
                <Button
                  title={createNewIdea}
                  className="bmc-button--primary"
                  onClick={() => addIdeaHandler()}
                />
              </div>
            )}

            {collection.length > 0 && (
              <Button
                title={createAnotherIdea}
                className="bmc-button--primary ideation-list-create-idea-button"
                onClick={() => addIdeaHandler()}
              />
            )}
          </div>
        )}
      </div>
    </BmcCanvasContainer>
  );
}
