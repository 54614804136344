import * as Route from '../support/serverRoutes';
import makeRequest from '../../main/utils/makeRequest';

const adminLecturers = {
  state: {
    data: [],
    meta: {},
    loading: false,
    uploading: false,
    by: null,
    byId: null,
  },
  reducers: {
    setData(state, { data, meta }) {
      return { ...state, data, meta };
    },
    setLoading(state, loading) {
      return { ...state, loading };
    },
    setUploading(state, uploading) {
      return { ...state, uploading };
    },
    setRouteOptions(state, { by, byId }) {
      return { ...state, by, byId };
    },
    deleteAdminLecturer(state, userId) {
      return {
        ...state,
        data: state.data.filter((user) => {
          return user.id !== userId;
        }),
      };
    },
  },
  effects: (dispatch) => ({
    fetchAdminLecturers(params, rootState) {
      const sort = rootState.adminLecturers?.meta?.sort;
      dispatch.adminLecturers.setLoading(true);
      dispatch.adminLecturers.setRouteOptions(params);
      makeRequest({
        dispatch,
        url: Route.adminLecturers({ ...params, sort }),
        success: (response) => {
          dispatch.adminLecturers.setData({
            ...response.data,
          });
        },
        finish: () => {
          dispatch.adminLecturers.setLoading(false);
        },
      });
    },
    addUser({ values, after = [] }) {
      dispatch.adminLecturers.setUploading(true);
      const data = {};
      data.user = values;
      data.establishment_ids = values.establishment_ids;
      makeRequest({
        dispatch,
        url: Route.users(
          { by: 'establishments', byId: values.establishment_id },
          false,
        ),
        method: 'POST',
        data,
        showSuccessToast: true,
        success: () => {
          dispatch.adminLecturers.reload();
          after.forEach((callback) => callback());
        },
        finish: () => {
          dispatch.adminLecturers.setUploading(false);
          dispatch.establishments.clearSelectedCreatedEstablishments();
        },
      });
    },
    sortAdminLecturers({ sort }, rootState) {
      const {
        by,
        byId,
        meta: { page, limit, search },
      } = rootState.adminLecturers;
      dispatch.adminLecturers.setLoading(true);
      makeRequest({
        dispatch,
        url: Route.adminLecturers({ page, limit, search, sort, by, byId }),
        success: (response) => {
          dispatch.adminLecturers.setData({
            data: response.data.data,
            meta: response.data.meta,
          });
        },
        finish: () => {
          dispatch.adminLecturers.setLoading(false);
        },
      });
    },
    reload(_, rootState) {
      const { page, limit } = rootState.adminLecturers.meta;
      const { by, byId } = rootState.adminLecturers;
      dispatch.adminLecturers.fetchAdminLecturers({ page, limit, by, byId });
    },
  }),
};

export default adminLecturers;
