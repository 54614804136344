const apiPrefix = '/api/v1';

export const connection = () => `${apiPrefix}/connection`;

export const user = () => `${apiPrefix}/user`;
export const userPassword = () => `${apiPrefix}/user/password`;
export const userEmail = () => `${apiPrefix}/user/email`;
export const users = (email = '') => `${apiPrefix}/users?email=${email}`;
export const searchSuitableStudent = (email = '', bmcId) =>
  `${apiPrefix}/bmcs/${bmcId}/search_suitable_student?email=${email}`;
export const session = () => `${apiPrefix}/user/session`;

export const signIn = () => `${apiPrefix}/sign_in`;
export const signUp = () => `${apiPrefix}/sign_up`;
export const passwords = () => `${apiPrefix}/passwords`;

export const bmc = (bmcId) => `${apiPrefix}/bmcs/${bmcId}`;
export const cloneBmc = (bmcId) => `${apiPrefix}/bmcs/${bmcId}/clone`;
export const bmcs = () => `${apiPrefix}/bmcs`;

export const bmcUser = (bmcId, id) => `${apiPrefix}/bmcs/${bmcId}/users/${id}`;
export const bmcOwner = (bmcId, userId, ownerId) =>
  `${apiPrefix}/bmcs/${bmcId}/users/${userId}/owner/${ownerId}`;
export const bmcUsers = (bmcId) => `${apiPrefix}/bmcs/${bmcId}/users`;

export const idea = (bmcId, id) => `${apiPrefix}/bmcs/${bmcId}/ideas/${id}`;
export const ideas = (bmcId, params) => {
  if (params?.completed)
    return `${apiPrefix}/bmcs/${bmcId}/ideas?completed=true`;
  return `${apiPrefix}/bmcs/${bmcId}/ideas`;
};
export const ideasImages = (bmcId) => `${apiPrefix}/bmcs/${bmcId}/idea_images`;

export const ideaImages = (bmcId) => `${apiPrefix}/bmcs/${bmcId}/idea_images`;
export const ideaImage = (bmcId, id) =>
  `${apiPrefix}/bmcs/${bmcId}/idea_images/${id}`;

export const tests = (bmcId) => `${apiPrefix}/bmcs/${bmcId}/tests`;
export const test = (bmcId, id) => `${apiPrefix}/bmcs/${bmcId}/tests/${id}`;

export const testDocuments = (bmcId, testId, parameters = '') =>
  `${apiPrefix}/bmcs/${bmcId}/tests/${testId}/documents?${parameters}`;
export const testDocument = (bmcId, testId, id) =>
  `${apiPrefix}/bmcs/${bmcId}/tests/${testId}/documents/${id}`;

export const testResponse = () => `${apiPrefix}/test_responses`;
export const testResponseById = (id) => `${apiPrefix}/tests/${id}/responses`;

export const portfolio = (bmcId) => `${apiPrefix}/bmcs/${bmcId}/portfolio`;

export const portfolioStyle = (bmcId) =>
  `${apiPrefix}/bmcs/${bmcId}/portfolio/portfolio_style`;

export const portfolioTemplates = () => `${apiPrefix}/portfolio_templates`;

export const portfolioTeammate = (bmcId, id) =>
  `${apiPrefix}/bmcs/${bmcId}/portfolio/teammates/${id}`;
export const portfolioTeammates = (bmcId) =>
  `${apiPrefix}/bmcs/${bmcId}/portfolio/teammates`;

export const portfolioFile = (bmcId, id) =>
  `${apiPrefix}/bmcs/${bmcId}/portfolio/files/${id}`;
export const portfolioFiles = (bmcId) =>
  `${apiPrefix}/bmcs/${bmcId}/portfolio/files`;

export const portfolioFilesDownload = () =>
  `${apiPrefix}/portfolio/files/download`;

export const channel = (bmcId, channelId) =>
  `${apiPrefix}/bmcs/${bmcId}/channels/${channelId}`;
export const channels = (bmcId) => `${apiPrefix}/bmcs/${bmcId}/channels`;

export const customerSegment = (bmcId, customerSegmentId) =>
  `${apiPrefix}/bmcs/${bmcId}/customer_segments/${customerSegmentId}`;
export const customerSegments = (bmcId) =>
  `${apiPrefix}/bmcs/${bmcId}/customer_segments`;

export const getKeepGrow = (bmcId) =>
  `${apiPrefix}/bmcs/${bmcId}/get_keep_grow`;

export const valueProposition = (bmcId) =>
  `${apiPrefix}/bmcs/${bmcId}/value_proposition`;
export const competitors = (bmcId) =>
  `${apiPrefix}/bmcs/${bmcId}/value_proposition/competitors`;
export const competitor = (bmcId, competitorId) =>
  `${apiPrefix}/bmcs/${bmcId}/value_proposition/competitors/${competitorId}`;
export const uniqueSellingPoints = (bmcId) =>
  `${apiPrefix}/bmcs/${bmcId}/value_proposition/unique_selling_points`;
export const uniqueSellingPoint = (bmcId, uniqueSellingPointId) =>
  `${apiPrefix}/bmcs/${bmcId}/value_proposition/unique_selling_points/${uniqueSellingPointId}`;

export const keyActivity = (bmcId, keyActivityId) =>
  `${apiPrefix}/bmcs/${bmcId}/key_activities/${keyActivityId}`;
export const keyActivities = (bmcId) =>
  `${apiPrefix}/bmcs/${bmcId}/key_activities`;

export const keyPartner = (bmcId, keyPartnerId) =>
  `${apiPrefix}/bmcs/${bmcId}/key_partners/${keyPartnerId}`;
export const keyPartners = (bmcId) => `${apiPrefix}/bmcs/${bmcId}/key_partners`;

export const keyResource = (bmcId, keyResourceId) =>
  `${apiPrefix}/bmcs/${bmcId}/key_resources/${keyResourceId}`;
export const keyResources = (bmcId) =>
  `${apiPrefix}/bmcs/${bmcId}/key_resources`;

export const cost = (bmcId, costId) =>
  `${apiPrefix}/bmcs/${bmcId}/costs/${costId}`;
export const costs = (bmcId) => `${apiPrefix}/bmcs/${bmcId}/costs`;

export const product = (bmcId, productId) =>
  `${apiPrefix}/bmcs/${bmcId}/products/${productId}`;
export const products = (bmcId) => `${apiPrefix}/bmcs/${bmcId}/products`;

export const revenue = (bmcId, revenueId) =>
  `${apiPrefix}/bmcs/${bmcId}/revenues/${revenueId}`;
export const revenues = (bmcId) => `${apiPrefix}/bmcs/${bmcId}/revenues`;

export const sustainability = (bmcId, sustainabilityId) =>
  `${apiPrefix}/bmcs/${bmcId}/sustainabilities/${sustainabilityId}`;

export const sustainabilities = (bmcId) =>
  `${apiPrefix}/bmcs/${bmcId}/sustainabilities`;

export const classroomBMCs = (bmcId, params, withPagination = true) => {
  const {
    page = 1,
    limit = 25,
    search = '',
    byId = null,
    sort = null,
  } = params;

  if (byId) {
    if (withPagination)
      return `${apiPrefix}/admin/bmcs/${bmcId}/classroom_bmcs?limit=${limit}&page=${page}${
        search ? `&search=${search}` : ''
      }${sort ? `&sort=${sort}` : ''}`;

    return `${apiPrefix}/admin/bmcs/${bmcId}/classroom_bmcs`;
  }

  if (!withPagination) {
    return `${apiPrefix}/admin/bmcs/${bmcId}/classroom_bmcs`;
  }

  return `${apiPrefix}/admin/bmcs/${bmcId}/classroom_bmcs?limit=${limit}&page=${page}${
    search ? `&search=${search}` : ''
  }${sort ? `&sort=${sort}` : ''}`;
};

export const chatGroups = ({ bmcId, sectionName }) => {
  if (bmcId && sectionName) {
    return `${apiPrefix}/chat_groups?bmc_id=${bmcId}&section_name=${sectionName}`;
  }
  return `${apiPrefix}/chat_groups`;
};

export const chatGroup = ({ chatGroupId }) =>
  `${apiPrefix}/chat_groups/${chatGroupId}`;

export const chatGroupMembers = ({ chatGroupId }) =>
  `${apiPrefix}/chat_groups/${chatGroupId}/members`;

export const chatGroupUpdateActiveAt = ({ chatGroupId }) =>
  `${apiPrefix}/chat_groups/${chatGroupId}/update_active_at`;

export const chatGroupComments = ({
  chatGroupId = null,
  updatedAtGt = null,
}) => {
  if (!chatGroupId && !updatedAtGt) return `${apiPrefix}/chat_group_comments`;

  const params = [`chat_group_id=${chatGroupId}`];
  if (updatedAtGt) params.push(`updated_at_gt=${updatedAtGt}`);
  return `${apiPrefix}/chat_group_comments?${params.join('&')}`;
};

export const chatGroupComment = ({ chatGroupCommentId }) =>
  `${apiPrefix}/chat_group_comments/${chatGroupCommentId}`;

export const messages = () => `${apiPrefix}/notifications`;
