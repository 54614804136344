import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import classNames from 'classnames';
import CustomerSegments from './CustomerSegments';
import Channels from './Channels';
import CustomerRelationships from './CustomerRelationships';
import ValueProposition from './ValueProposition';
import KeyActivities from './KeyActivities';
import KeyPartners from './KeyPartners';
import KeyResources from './KeyResources';
import Costs from './Costs';
import Sustainabilities from './Sustainabilities';
import Revenue from './Revenue';
import {
  sumArrays,
  costCalculator as calculator,
} from '../../../utils/helpers';

export default function BmcCanvas({
  locales,
  isDark = false,
  isPreview = false,
  isProgressBar = true,
  backgroundIcon,
  backgroundCanvas,
  backgroundItems,
  backgroundBMC,
  backgroundSection,
  borderSectionColor,
  titleBMCSectionColor,
  textColor,
  iconFill,
  iconFillClose,
  templateName,
  sectionClassName,
  className,
  firstSvgColor,
  secondSvgColor,
  titleStyle,
  buttonStyle,
  iconStyle,
  styleColor,
  textStyle,
  backgroundRow,
  textWithImage,
  backgroundImage,
  iconFillTable,
  iconFillSlider,
  iconTableStyles,
  backgroundIconTable,
  borderCardColor,
  sliderStyles,
  backgroundSliderColor,
  titleColor,
  buttonColor,
  backgroundButton,
  titleImageColor,
  textSliderColor,
  isTemplates = false,
  isColourfulfunTemplate = false,
  disabled = false,
}) {
  const { cost, product } = useSelector((state) => state);

  const {
    bmc: { fetchBmcCanvasData },
  } = useDispatch();

  const { bmcId: bmcIdFromParams } = useParams();

  const [annualUnitSold, setAnnualUnitSold] = useState(0);
  const [annualRevenue, setAnnualRevenue] = useState(0);

  const [annualFixed, setAnnualFixed] = useState(0);
  const [annualVariable, setAnnualVariable] = useState(0);

  useEffect(() => {
    if (bmcIdFromParams) fetchBmcCanvasData(bmcIdFromParams);
  }, [bmcIdFromParams]);

  useEffect(() => {
    const sumReducer = (sum, x) => sum + x;

    const products = product.collection;
    const sales = products.map((i) => i.sales);
    const annualUnits = sumArrays(...sales).reduce(sumReducer, 0);

    setAnnualUnitSold(annualUnits);

    const productArrays = products.map((item) => {
      return item.sales.map((s) => Math.round(s * +item.endUserPrice));
    });

    const revenueNew = sumArrays(...productArrays).reduce(sumReducer, 0);
    setAnnualRevenue(revenueNew);

    const fixed = cost.collection
      .map((c) => {
        return new Array(12)
          .fill(Math.round(calculator(c)))
          .reduce(sumReducer, 0);
      })
      .reduce(sumReducer, 0);

    const variable = products
      .map((p) => {
        return p.sales
          .map((i) => Math.round(+p.costPrice * i))
          .reduce(sumReducer, 0);
      })
      .reduce(sumReducer, 0);

    setAnnualFixed(fixed);
    setAnnualVariable(variable);
  }, [product, cost]);

  return (
    <div
      className={classNames('bmc-canvas', className)}
      id="docx-bmc-wrapper"
      style={{ backgroundColor: backgroundCanvas }}
    >
      <div className="bmc-canvas-row bmc-canvas-row-1">
        <div className="bmc-canvas-column bmc-canvas-column-0">
          <KeyPartners
            locales={locales}
            isPreview={isPreview}
            isDark={isDark}
            isProgressBar={isProgressBar}
            backgroundIcon={backgroundIcon}
            backgroundItems={backgroundItems}
            backgroundSection={backgroundSection}
            borderSectionColor={borderSectionColor}
            titleBMCSectionColor={titleBMCSectionColor}
            textColor={textColor}
            iconFill={iconFill}
            templateName={templateName}
            sectionClassName={sectionClassName}
            firstSvgColor={firstSvgColor}
            secondSvgColor={secondSvgColor}
            titleStyle={titleStyle}
            buttonStyle={buttonStyle}
            iconStyle={iconStyle}
            styleColor={styleColor}
            backgroundBMC={backgroundBMC}
            textStyle={textStyle}
            titleColor={titleColor}
            buttonColor={buttonColor}
            backgroundButton={backgroundButton}
            iconFillClose={iconFillClose}
            isColourfulfunTemplate={isColourfulfunTemplate}
            disabled={disabled}
          />
        </div>

        <div className="bmc-canvas-column bmc-canvas-column-1">
          <KeyActivities
            locales={locales}
            isPreview={isPreview}
            isDark={isDark}
            isProgressBar={isProgressBar}
            backgroundIcon={backgroundIcon}
            backgroundItems={backgroundItems}
            backgroundSection={backgroundSection}
            borderSectionColor={borderSectionColor}
            titleBMCSectionColor={titleBMCSectionColor}
            textColor={textColor}
            iconFill={iconFill}
            templateName={templateName}
            sectionClassName={sectionClassName}
            firstSvgColor={firstSvgColor}
            secondSvgColor={secondSvgColor}
            titleStyle={titleStyle}
            buttonStyle={buttonStyle}
            iconStyle={iconStyle}
            styleColor={styleColor}
            backgroundBMC={backgroundBMC}
            textStyle={textStyle}
            titleColor={titleColor}
            buttonColor={buttonColor}
            backgroundButton={backgroundButton}
            iconFillClose={iconFillClose}
            isColourfulfunTemplate={isColourfulfunTemplate}
            disabled={disabled}
          />

          <KeyResources
            locales={locales}
            isPreview={isPreview}
            isDark={isDark}
            isProgressBar={isProgressBar}
            backgroundIcon={backgroundIcon}
            backgroundItems={backgroundItems}
            backgroundSection={backgroundSection}
            borderSectionColor={borderSectionColor}
            titleBMCSectionColor={titleBMCSectionColor}
            textColor={textColor}
            iconFill={iconFill}
            templateName={templateName}
            sectionClassName={sectionClassName}
            firstSvgColor={firstSvgColor}
            secondSvgColor={secondSvgColor}
            titleStyle={titleStyle}
            buttonStyle={buttonStyle}
            iconStyle={iconStyle}
            styleColor={styleColor}
            backgroundBMC={backgroundBMC}
            textStyle={textStyle}
            titleColor={titleColor}
            buttonColor={buttonColor}
            backgroundButton={backgroundButton}
            iconFillClose={iconFillClose}
            isColourfulfunTemplate={isColourfulfunTemplate}
            disabled={disabled}
          />
        </div>

        <div className="bmc-canvas-column bmc-canvas-column-3">
          <ValueProposition
            locales={locales}
            isPreview={isPreview}
            isDark={isDark}
            isProgressBar={isProgressBar}
            backgroundIcon={backgroundIcon}
            backgroundItems={backgroundItems}
            backgroundSection={backgroundSection}
            borderSectionColor={borderSectionColor}
            titleBMCSectionColor={titleBMCSectionColor}
            textColor={textColor}
            iconFill={iconFill}
            templateName={templateName}
            sectionClassName={sectionClassName}
            firstSvgColor={firstSvgColor}
            secondSvgColor={secondSvgColor}
            titleStyle={titleStyle}
            buttonStyle={buttonStyle}
            iconStyle={iconStyle}
            styleColor={styleColor}
            backgroundBMC={backgroundBMC}
            textStyle={textStyle}
            backgroundRow={backgroundRow}
            backgroundImage={backgroundImage}
            textWithImage={textWithImage}
            isTemplates={isTemplates}
            titleColor={titleColor}
            buttonColor={buttonColor}
            backgroundButton={backgroundButton}
            titleImageColor={titleImageColor}
            iconFillClose={iconFillClose}
            isColourfulfunTemplate={isColourfulfunTemplate}
            disabled={disabled}
          />
        </div>

        <div className="bmc-canvas-column bmc-canvas-column-2">
          <CustomerRelationships
            locales={locales}
            isPreview={isPreview}
            isDark={isDark}
            isProgressBar={isProgressBar}
            backgroundIcon={backgroundIcon}
            backgroundItems={backgroundItems}
            backgroundSection={backgroundSection}
            borderSectionColor={borderSectionColor}
            titleBMCSectionColor={titleBMCSectionColor}
            textColor={textColor}
            iconFill={iconFill}
            templateName={templateName}
            sectionClassName={sectionClassName}
            firstSvgColor={firstSvgColor}
            secondSvgColor={secondSvgColor}
            titleStyle={titleStyle}
            buttonStyle={buttonStyle}
            iconStyle={iconStyle}
            styleColor={styleColor}
            backgroundBMC={backgroundBMC}
            backgroundBlock={backgroundRow}
            textStyle={textStyle}
            titleColor={titleColor}
            buttonColor={buttonColor}
            backgroundButton={backgroundButton}
            iconFillClose={iconFillClose}
            isColourfulfunTemplate={isColourfulfunTemplate}
            disabled={disabled}
          />

          <Channels
            locales={locales}
            isPreview={isPreview}
            isDark={isDark}
            isProgressBar={isProgressBar}
            backgroundIcon={backgroundIcon}
            backgroundItems={backgroundItems}
            backgroundSection={backgroundSection}
            borderSectionColor={borderSectionColor}
            titleBMCSectionColor={titleBMCSectionColor}
            textColor={textColor}
            iconFill={iconFill}
            templateName={templateName}
            sectionClassName={sectionClassName}
            firstSvgColor={firstSvgColor}
            secondSvgColor={secondSvgColor}
            titleStyle={titleStyle}
            buttonStyle={buttonStyle}
            iconStyle={iconStyle}
            styleColor={styleColor}
            backgroundBMC={backgroundBMC}
            textStyle={textStyle}
            backgroundRow={backgroundRow}
            backgroundImage={backgroundImage}
            isTemplates={isTemplates}
            titleColor={titleColor}
            buttonColor={buttonColor}
            backgroundButton={backgroundButton}
            titleImageColor={titleImageColor}
            iconFillClose={iconFillClose}
            isColourfulfunTemplate={isColourfulfunTemplate}
            disabled={disabled}
          />
        </div>

        <div className="bmc-canvas-column bmc-canvas-column-4">
          <CustomerSegments
            locales={locales}
            isPreview={isPreview}
            isDark={isDark}
            isProgressBar={isProgressBar}
            backgroundIcon={backgroundIcon}
            backgroundItems={backgroundItems}
            backgroundSection={backgroundSection}
            borderSectionColor={borderSectionColor}
            titleBMCSectionColor={titleBMCSectionColor}
            textColor={textColor}
            iconFill={iconFill}
            templateName={templateName}
            sectionClassName={sectionClassName}
            firstSvgColor={firstSvgColor}
            secondSvgColor={secondSvgColor}
            titleStyle={titleStyle}
            buttonStyle={buttonStyle}
            iconStyle={iconStyle}
            styleColor={styleColor}
            backgroundBMC={backgroundBMC}
            textStyle={textStyle}
            backgroundRow={backgroundRow}
            backgroundImage={backgroundImage}
            titleColor={titleColor}
            buttonColor={buttonColor}
            backgroundButton={backgroundButton}
            titleImageColor={titleImageColor}
            isTemplates={isTemplates}
            iconFillClose={iconFillClose}
            isColourfulfunTemplate={isColourfulfunTemplate}
            disabled={disabled}
          />
        </div>
      </div>

      <div className="bmc-canvas-row bmc-canvas-row-1">
        <div className="bmc-canvas-column bmc-canvas-column--bottom">
          <Costs
            locales={locales}
            isPreview={isPreview}
            isDark={isDark}
            annualFixed={annualFixed}
            annualVariable={annualVariable}
            isProgressBar={isProgressBar}
            backgroundIcon={backgroundIcon}
            backgroundItems={backgroundItems}
            backgroundSection={backgroundSection}
            borderSectionColor={borderSectionColor}
            titleBMCSectionColor={titleBMCSectionColor}
            textColor={textColor}
            iconFill={iconFill}
            templateName={templateName}
            sectionClassName={sectionClassName}
            firstSvgColor={firstSvgColor}
            secondSvgColor={secondSvgColor}
            titleStyle={titleStyle}
            buttonStyle={buttonStyle}
            iconStyle={iconStyle}
            textStyle={textStyle}
            backgroundBMC={backgroundBMC}
            backgroundCard={backgroundRow}
            titleColor={titleColor}
            buttonColor={buttonColor}
            backgroundButton={backgroundButton}
            iconFillClose={iconFillClose}
            isColourfulfunTemplate={isColourfulfunTemplate}
            disabled={disabled}
          />
        </div>

        <div className="bmc-canvas-column bmc-canvas-column--bottom">
          <Sustainabilities
            locales={locales}
            isPreview={isPreview}
            isDark={isDark}
            isProgressBar={isProgressBar}
            backgroundIcon={backgroundIcon}
            backgroundItems={backgroundItems}
            backgroundSection={backgroundSection}
            borderSectionColor={borderSectionColor}
            titleBMCSectionColor={titleBMCSectionColor}
            textColor={textColor}
            iconFill={iconFill}
            templateName={templateName}
            sectionClassName={sectionClassName}
            firstSvgColor={firstSvgColor}
            secondSvgColor={secondSvgColor}
            titleStyle={titleStyle}
            buttonStyle={buttonStyle}
            iconStyle={iconStyle}
            textStyle={textStyle}
            backgroundBMC={backgroundBMC}
            backgroundRow={backgroundRow}
            iconTableStyles={iconTableStyles}
            iconFillTable={iconFillTable}
            iconFillSlider={iconFillSlider}
            backgroundIconTable={backgroundIconTable}
            borderCardColor={borderCardColor}
            sliderStyles={sliderStyles}
            backgroundSliderColor={backgroundSliderColor}
            isColourfulfunTemplate={isColourfulfunTemplate}
            titleColor={titleColor}
            buttonColor={buttonColor}
            backgroundButton={backgroundButton}
            textSliderColor={textSliderColor}
            iconFillClose={iconFillClose}
            disabled={disabled}
          />
        </div>

        <div className="bmc-canvas-column bmc-canvas-column--bottom">
          <Revenue
            locales={locales}
            isPreview={isPreview}
            isDark={isDark}
            isProgressBar={isProgressBar}
            backgroundIcon={backgroundIcon}
            backgroundItems={backgroundItems}
            backgroundSection={backgroundSection}
            borderSectionColor={borderSectionColor}
            titleBMCSectionColor={titleBMCSectionColor}
            textColor={textColor}
            iconFill={iconFill}
            annualUnitSold={annualUnitSold}
            annualRevenue={annualRevenue}
            templateName={templateName}
            sectionClassName={sectionClassName}
            firstSvgColor={firstSvgColor}
            secondSvgColor={secondSvgColor}
            titleStyle={titleStyle}
            buttonStyle={buttonStyle}
            iconStyle={iconStyle}
            textStyle={textStyle}
            backgroundBMC={backgroundBMC}
            backgroundCard={backgroundRow}
            titleColor={titleColor}
            buttonColor={buttonColor}
            backgroundButton={backgroundButton}
            iconFillClose={iconFillClose}
            isColourfulfunTemplate={isColourfulfunTemplate}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
}
