import _ from 'lodash';
import * as Route from '../utils/serverRoutes';
import makeRequest from '../utils/makeRequest';
import {
  transformResponseCollection,
  transformResponseData,
} from '../utils/helpers';
import { chatGroupNames } from '../containers/chat/utils/getShortNameOfChatGroup';

const chatGroup = {
  state: {
    collection: [],
    isExpanded:
      JSON.parse(localStorage.getItem('chatState'))?.isExpanded || false,
    selectedChatGroup: null,
    commentIsOpened: false,
    currentUser: null,
    isSettedFromSessionStorage: false,
    isOpenedLastNotification: false,
  },
  reducers: {
    fetchChatGroupCollectionReducer(state, collection) {
      const collectionResult = [];
      chatGroupNames.forEach((chatGroupName) => {
        const findResult = collection.find(
          (item) =>
            chatGroupName.name.toLowerCase() === item.name.toLowerCase(),
        );
        if (findResult) collectionResult.push(findResult);
      });
      return { ...state, collection: _.cloneDeep(collectionResult) };
    },
    mergeChatGroupReducer: (state, attributes) => {
      const itemChatGroup = state.collection.find(
        (item) => item.id === attributes.id,
      );

      if (itemChatGroup) {
        Object.assign(itemChatGroup, attributes);
      } else {
        state.collection.push(attributes);
      }

      const collectionResult = [];
      chatGroupNames.forEach((chatGroupName) => {
        const findResult = state.collection.find(
          (item) =>
            chatGroupName.name.toLowerCase() === item.name.toLowerCase(),
        );
        if (findResult) collectionResult.push(findResult);
      });

      state.collection = _.cloneDeep(collectionResult);
      return { ...state };
    },
    mergeChatGroupCommentsReducer: (state, { collection, chatGroupId }) => {
      const itemChatGroup = state.collection.find(
        (item) => item.id === chatGroupId,
      );

      itemChatGroup.chatGroupComments = _.cloneDeep(
        _.unionBy(collection, itemChatGroup.chatGroupComments, 'id'),
      );

      return { ...state };
    },
    mergeChatGroupCommentReducer: (state, attributes) => {
      const itemChatGroup = state.collection.find(
        (item) => item.id === attributes.chatGroupId,
      );

      const itemChatGroupComment = itemChatGroup.chatGroupComments.find(
        (item) => item.id === attributes.id,
      );

      if (itemChatGroupComment) {
        Object.assign(itemChatGroupComment, attributes);
      } else {
        itemChatGroup.chatGroupComments.push(attributes);
      }

      return { ...state };
    },
    removeChatGroupCommentReducer: (state, attributes) => {
      const itemChatGroup = state.collection.find(
        (item) => item.id === attributes.chatGroupId,
      );

      if (!itemChatGroup) return { ...state };

      const indexChatGroupComment = itemChatGroup.chatGroupComments.findIndex(
        (itemChatGroupComment) => itemChatGroupComment.id === attributes.id,
      );

      if (indexChatGroupComment > -1) {
        itemChatGroup.chatGroupComments.splice(indexChatGroupComment, 1);
      }

      return { ...state };
    },
    setChatGroupMembers: (state, { collection, chatGroupId }) => {
      const itemChatGroup = state.collection.find(
        (item) => item.id === chatGroupId,
      );

      itemChatGroup.chatGroupMembers = collection;

      return { ...state };
    },
    mergeChatGroupMember: (state, { attributes, chatGroupId }) => {
      const itemChatGroup = state.collection.find(
        (item) => item.id === chatGroupId,
      );

      const itemChatGroupMember = itemChatGroup.chatGroupMembers.find(
        (item) => item.id === attributes.id,
      );

      if (itemChatGroupMember) {
        Object.assign(itemChatGroupMember, attributes);
      } else {
        itemChatGroup.chatGroupMembers.push(attributes);
      }

      return { ...state };
    },

    updateChatGroupAttrReducer: (state, payload) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: (dispatch) => ({
    fetchChatGroups: ({ bmcId, sectionName }) => {
      return makeRequest({
        dispatch,
        url: Route.chatGroups({ bmcId, sectionName }),
        success: (response) => {
          dispatch.chatGroup.fetchChatGroupCollectionReducer(
            transformResponseCollection(response.data.data),
          );
        },
      });
    },
    fetchChatGroup: ({ chatGroupId }) => {
      return makeRequest({
        dispatch,
        url: Route.chatGroup({ chatGroupId }),
        success: (response) => {
          dispatch.chatGroup.mergeChatGroupReducer(
            transformResponseData(response.data.data),
          );
        },
      });
    },
    createChatGroup: ({ name, sectionName, bmcId }) => {
      return makeRequest({
        dispatch,
        url: Route.chatGroups({}),
        method: 'POST',
        data: { name, sectionName, bmcId },
        success: (response) => {
          dispatch.chatGroup.mergeChatGroupReducer(
            transformResponseData(response.data.data),
          );
        },
      });
    },
    fetchChatGroupMembers: ({ chatGroupId }) => {
      return makeRequest({
        dispatch,
        url: Route.chatGroupMembers({ chatGroupId }),
        success: (response) => {
          dispatch.chatGroup.setChatGroupMembers({
            collection: transformResponseCollection(response.data.data),
            chatGroupId,
          });
        },
      });
    },
    updateChatGroupMemberActiveAt: ({ chatGroupId, chatGroupCommentId }) => {
      return makeRequest({
        dispatch,
        url: Route.chatGroupUpdateActiveAt({ chatGroupId }),
        method: 'PUT',
        data: { chatGroupCommentId },
        success: (response) => {
          dispatch.chatGroup.mergeChatGroupMember({
            attributes: transformResponseData(response.data.data),
            chatGroupId,
          });
        },
      });
    },
    fetchComments: ({ chatGroupId, updatedAtGt }) => {
      return makeRequest({
        dispatch,
        url: Route.chatGroupComments({ chatGroupId, updatedAtGt }),
        success: (response) => {
          dispatch.chatGroup.mergeChatGroupCommentsReducer({
            collection: transformResponseCollection(response.data.data),
            chatGroupId,
          });
        },
      });
    },
    createComment: ({
      message,
      chatGroupId,
      mentions = [],
      commentId = null,
    }) => {
      const createCommentData = { message, chatGroupId, mentions };

      if (commentId) Object.assign(createCommentData, { commentId });

      return makeRequest({
        dispatch,
        url: Route.chatGroupComments({}),
        method: 'POST',
        data: createCommentData,
        success: (response) => {
          dispatch.chatGroup.mergeChatGroupCommentReducer(
            transformResponseData(response.data.data),
          );
        },
      });
    },
    updateComment: ({ id, message, chatGroupId, mentions = [] }) => {
      return makeRequest({
        dispatch,
        url: Route.chatGroupComment({ chatGroupCommentId: id }),
        method: 'PUT',
        data: { message, chatGroupId, mentions },
        success: (response) => {
          dispatch.chatGroup.mergeChatGroupCommentReducer(
            transformResponseData(response.data.data),
          );
        },
      });
    },
    removeComment: ({ id }) => {
      return makeRequest({
        dispatch,
        url: Route.chatGroupComment({ chatGroupCommentId: id }),
        method: 'DELETE',
        success: (response) => {
          dispatch.chatGroup.removeChatGroupCommentReducer(
            transformResponseData(response.data.data),
          );
        },
      });
    },
    updateChatGroupAttribute(payload) {
      dispatch.chatGroup.updateChatGroupAttrReducer(payload);
    },
  }),
};

export default chatGroup;
