import React from 'react';
import classNames from 'classnames';

export default function Arrows({
  className,
  onRightClick,
  onLeftClick,
  leftActive,
  rightActive,
}) {
  return (
    <div className={classNames('arrows', className)}>
      <div
        className={classNames('arrows__left', {
          'arrows__left--not-active': !leftActive,
        })}
        onClick={onLeftClick}
      >
        <i className="icon-Arrow-Top" />
      </div>

      <div
        className={classNames('arrows__right', {
          'arrows__left--not-active': !rightActive,
        })}
        onClick={onRightClick}
      >
        <i className="icon-Arrow-Top" />
      </div>
    </div>
  );
}
