import React from 'react';
import { useSelector } from 'react-redux';

import Card from '../../main/containers/valueProposition/Card';
import { CURRENT_TEMPLATE_NAME } from '../../main/utils/const';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import { getCurrentCanvasIcon } from '../../main/utils/helpers/getCurrentCanvasIcon';

const ValueProposition = ({
  locales,
  locales: {
    valueProposition: {
      intro: { card },
    },
    portfolioWeb,
  },
  styleColor,
}) => {
  const { portfolio } = useSelector((state) => state);
  const {
    valueProposition: { product, reason, image },
    uniqueSellingPoint,
  } = useSelector((state) => state);

  return (
    <div className="value-proposition section">
      {getCurrentCanvasIcon('VPCanvas', styleColor)}

      <SectionHeaderWeb
        templateName={CURRENT_TEMPLATE_NAME}
        title={portfolioWeb.valueProposition}
        description={portfolio.valueProposition.description}
        className="green-title"
        style={{ color: styleColor }}
      />

      <Card
        locales={locales}
        portfolioMode
        backgroundImage={`${styleColor}80`}
        templateName={CURRENT_TEMPLATE_NAME}
        blur={false}
        card={{
          ...card,
          title: false,
          image,
          solution: product,
          features: reason,
          points: uniqueSellingPoint.collection,
        }}
      />
    </div>
  );
};

export default ValueProposition;
