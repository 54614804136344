export const chatGroupNames = [
  { name: 'Introduction', short: 'I' },
  { name: 'Project Summary', short: 'PS' },
  { name: 'The Team', short: 'TT' },
  { name: 'Business Model Canvas', short: 'B' },
  { name: 'Testing', short: 'T' },
  { name: 'Desirability Section', short: 'DS' },
  { name: 'Customer Segment', short: 'CS' },
  { name: 'Value Proposition', short: 'VP' },
  { name: 'Competitor Analysis', short: 'CA' },
  { name: 'Customer Relationships', short: 'CR' },
  { name: 'Channels', short: 'CH' },
  { name: 'Feasibility Section', short: 'FS' },
  { name: 'Key Activities, Resources & Partners', short: 'KA' },
  { name: 'Viability Section', short: 'VS' },
  { name: 'Finance', short: 'F' },
  { name: 'Sustainability', short: 'S' },
  { name: 'Contact Us', short: 'C' },
];
export default function getShortNameOfChatGroup(name) {
  return chatGroupNames.find(
    (item) => item.name.toLowerCase() === name.toLowerCase(),
  )?.short;
}
