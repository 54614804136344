export const customerSegmentType = (type) => {
  if (type === 'B2cCustomerSegment') {
    return 'Business to Consumer (B2C)';
  }
  if (type === 'B2bCustomerSegment') {
    return 'Business to Business (B2B)';
  }
  return type;
};

export const customerSegmentAgeRange = ({ min, max }) => `${min}-${max}`;
