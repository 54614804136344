import {
  COLORS,
  FIRST_COLOR_PALETTE_NUMBER,
  SECOND_COLOR_PALETTE_NUMBER,
  THIRD_COLOR_PALETTE_NUMBER,
} from '../../const';
import { getColorDependsOnBackground } from '../getColorDependsOnBackground';

const { white, black } = COLORS;

export const getStylesDependsOnBackgroundAndPalleteChannels = (
  colorPaletteNumber,
  background,
) => {
  const stylesDependsOnBackground = {
    [FIRST_COLOR_PALETTE_NUMBER]: { backgroundColor: background },
    [SECOND_COLOR_PALETTE_NUMBER]: { backgroundColor: background },
    [THIRD_COLOR_PALETTE_NUMBER]: {
      backgroundColor: white,
      color: black,
      borderColor: black,
    },
  };

  return (
    stylesDependsOnBackground[colorPaletteNumber] || {
      backgroundColor: background,
      color: getColorDependsOnBackground(background),
    }
  );
};
