import React from 'react';

import Header from '../../../../components/Header';
import Title from '../../../../components/Title';
import Text from '../../../../components/Text';
import ImageBlock from '../../../../components/ImageBlock';
import Line from '../../../../components/Line';
import List from '../../../../components/List';
import Actions from '../../../../components/Actions';
import MobileMenuHeader from '../../../../components/MobileMenuHeader';
import ExampleCard from '../../Components/ExampleCard';

const Intro = ({
  nextClickHandler,
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
  locales,
  locales: {
    common,
    testing: {
      name,
      intro: { header, title, text, imageBlocks, list, card },
    },
  },
}) => {
  return (
    <div className="bmc-page testing">
      <Header
        className="bmc-page-header--blue"
        title={header}
        subtitle={name}
        icon="icon-VP"
        locales={locales}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        componentIndex={componentIndex}
        mobileMenuHeader={
          <MobileMenuHeader icon="testingActive" title={name} color="blue" />
        }
        rules={rules}
        backPath={backPath}
      />
      <Text>{[text[0], text[1], text[2], text[3], text[4]]}</Text>

      <div className="bmc-image-block-wrapper">
        {imageBlocks.map(({ src, text: imageBlockText }, index) => (
          <ImageBlock src={src} text={imageBlockText} key={index} />
        ))}
      </div>

      <Title text={title[0]} align="left" />

      <Text>{[text[5], text[6], text[7]]}</Text>

      <Title text={title[1]} align="center" />

      <List type="horizontal" items={list} />

      <Line />

      <Title text={title[2]} className="center" />

      <Text className="margin-bottom-30">{[text[9]]}</Text>

      <ExampleCard card={card} />

      <Actions nextClickHandler={nextClickHandler} buttonName={common.next} />
    </div>
  );
};

export default Intro;
