import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import SignUp from './signUp';
import SignIn from './signIn';
import ForgotPassword from './forgotPassword';
import NewPassword from './newPassword';

export default function router({ locales, data }) {
  const { classroomShareRedirect, establishmentId } = data;

  return (
    <Router>
      <Switch>
        <Route exact path="/onboarding/sign_in">
          <SignIn locales={locales} />
        </Route>

        {classroomShareRedirect && establishmentId && (
          <Route exact path="/onboarding/sign_up">
            <SignUp locales={locales} data={data} />
          </Route>
        )}

        <Route exact path="/onboarding/forgot_password">
          <ForgotPassword locales={locales} />
        </Route>

        <Route exact path="/onboarding/new_password/:resetPasswordToken">
          <NewPassword locales={locales} />
        </Route>

        <Route path="*">
          <Redirect to="/onboarding/sign_in" />
        </Route>
      </Switch>
    </Router>
  );
}
