import React from 'react';
import { Spin } from 'antd';
import { downloadFileHook } from '../../main/utils/makeRequest';

export default function ButtonReport({ href, fileName, children }) {
  const [startDownload, loading] = downloadFileHook();
  return (
    <button
      className="btn admin-btn download-csv"
      onClick={() => startDownload({ href, fileName })}
    >
      {loading && <Spin style={{ marginRight: '2px' }} />}
      {children}
    </button>
  );
}
