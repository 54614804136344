import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { truncate } from 'lodash';
import useMediaQuery from 'react-use-media-query-hook';
import CustomerSegmentCard from '../../customerSegment/CardTech';
import {
  COMMENT_SEGMENT,
  TECH_MODERN_TEMPLATE_NAME,
  COLORS,
} from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import SectionHeader from '../../../components/SectionHeader';

const { white } = COLORS;
const IMAGE_CUSTOMER_ASPECT_RATIO = 1 / 1.5;

const CustomerSegmentTech = ({
  data: { description },
  disabled = false,
  locales,
  titleColor,
  borderCardColor,
  backgroundImage,
  background,
  locales: {
    portfolio,
    customerSegment: {
      intro: { card },
    },
  },
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [activeCardId, setActiveCardId] = useState(null);

  const {
    customerSegment: { updateCustomerSegment },
    portfolio: { updateSectionField },
  } = useDispatch();

  const { collection } = useSelector((state) => state.customerSegment);

  const activeCardData = useMemo(() => {
    if (collection.length && !activeCardId) {
      setActiveCardId(collection[0].id);
      return collection[0];
    }
    return collection.find((i) => i.id === activeCardId);
  }, [collection, activeCardId]);

  const singleCard = activeCardData && (
    <CustomerSegmentCard
      locales={locales}
      uploadFileHandler={updateCustomerSegment}
      dropzoneMode
      blur={false}
      disabled={disabled}
      editMode={!disabled}
      background={white}
      backgroundImage={backgroundImage}
      borderCardColor={borderCardColor}
      aspectRatio={IMAGE_CUSTOMER_ASPECT_RATIO}
      card={{
        ...card,
        ...activeCardData,
        subtitle: activeCardData.name,
        title: null,
      }}
    />
  );

  return (
    <div
      className="customer-segment-tech section relative"
      style={{ backgroundColor: background }}
    >
      <PortfolioCommentIcon title={COMMENT_SEGMENT} />
      <SectionHeader
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolio.customerSegment.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'customerSegment',
            field: 'description',
            name: 'csText',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        style={{ color: titleColor }}
      />

      <div className="cards-section">
        <div className="left">
          {collection.map(({ id, name }) => (
            <div
              key={id}
              onClick={() => setActiveCardId(id)}
              className={classnames('item', { active: activeCardId === id })}
            >
              {truncate(name, { length: 45 })}
            </div>
          ))}
        </div>
        <div className="right">
          {isMobile
            ? collection.map((customerSegmentItem) => {
                return (
                  <CustomerSegmentCard
                    locales={locales}
                    key={customerSegmentItem.id}
                    uploadFileHandler={updateCustomerSegment}
                    dropzoneMode
                    portfolioMode
                    blur={false}
                    disabled={disabled}
                    editMode={!disabled}
                    background={white}
                    backgroundImage={backgroundImage}
                    borderCardColor={borderCardColor}
                    aspectRatio={IMAGE_CUSTOMER_ASPECT_RATIO}
                    card={{
                      ...card,
                      ...customerSegmentItem,
                      subtitle: customerSegmentItem.name,
                      title: null,
                    }}
                  />
                );
              })
            : singleCard}
        </div>
      </div>
    </div>
  );
};

export default CustomerSegmentTech;
