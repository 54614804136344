export default function isElementVisible(parentEl, childEl) {
  const parentRect = parentEl.getBoundingClientRect();
  const childRect = childEl.getBoundingClientRect();

  return (
    (childRect.top >= parentRect.top && childRect.top <= parentRect.bottom) ||
    (childRect.bottom >= parentRect.top &&
      childRect.bottom <= parentRect.bottom) ||
    (childRect.top <= parentRect.top && childRect.bottom >= parentRect.bottom)
  );
}
