import React, { useState } from 'react';
import classNames from 'classnames';

import TabsHeader from './TabsHeader';

export default function Tabs({
  tabs,
  className,
  children,
  black,
  green,
  form,
  inner,
  withBlur = false,
}) {
  const [currentTab, setCurrentTab] = useState(0);
  const classes = classNames('bmc-page-tabs', className, {
    'bmc-page-tabs--black': black === true || (!form && currentTab === 1),
    'bmc-page-tabs--inner': inner === true,
    'bmc-page-tabs--form': form === true,
    'bmc-page-tabs--green': green === true,
  });

  return (
    <div className={classes}>
      <TabsHeader
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      <div className="bmc-page-tabs-content">{children[currentTab]}</div>
      {withBlur && <div className="bmc-page-tabs--blur" />}
    </div>
  );
}
