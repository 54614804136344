import React from 'react';

const CheckBox = ({
  title,
  isPreview = false,
  isChecked = false,
  name,
  onCheck,
}) => {
  return (
    <div className="checkbox-item">
      <label htmlFor={title.toLowerCase()}>
        {isPreview ? (
          <input
            type="checkbox"
            checked={isChecked}
            id={title.toLowerCase()}
            data-hj-allow
          />
        ) : (
          <input
            type="checkbox"
            name={name}
            checked={isChecked}
            onChange={onCheck}
            id={title.toLowerCase()}
            data-hj-allow
          />
        )}
        <span className="cbx">
          <svg width="16px" height="16px" viewBox="0 0 12 11">
            <polyline points="1 6.29411765 4.5 10 11 1" />
          </svg>
        </span>
      </label>
      <div className="title">{title}</div>
    </div>
  );
};

export default CheckBox;
