import React from 'react';

import Team from './Team';
import UserBar from './UserBar';
import Menu from './Menu';
import MobileMenu from './MobileMenu';
import ChatContainer from '../chat';

export default function BmcCanvasContainer({
  children,
  locales,
  withChat = false,
}) {
  return (
    <div className="bmc-canvas-container">
      <Menu locales={locales} />
      <MobileMenu locales={locales} />

      <div className="bmc-canvas-container-block">
        <div className="bmc-canvas-container-block-top">
          <Team locales={locales} />
          <UserBar locales={locales} />
        </div>
        {withChat ? (
          <div className="bmc-canvas-container-block-section">
            <div className="bmc-canvas-container-block-section__chat">
              <ChatContainer />
            </div>
            <div className="bmc-canvas-container-block-section__content">
              <div className="bmc-canvas-container-block-content">
                {children}
              </div>
            </div>
          </div>
        ) : (
          <div className="bmc-canvas-container-block-content">
            <div style={{ width: '100%' }}>{children}</div>
          </div>
        )}
      </div>
    </div>
  );
}
