import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import copy from 'copy-to-clipboard';

import Header from '../../../../../../components/Header';
import Img from '../../../../../../components/Img';
import Text from '../../../../../../components/Text';
import Title from '../../../../../../components/Title';
import WebsiteCard from '../../../../Components/WebsiteCard';
import List from '../../../../../../components/List';
import Line from '../../../../../../components/Line';
import Tabs from '../../../../../../components/Tabs';
import TabsList from '../../../../../../components/TabsList';
import First from './templates/First';
import Second from './templates/Second';
import Third from './templates/Third';
import Fourth from './templates/Fourth';
import Actions from '../../../../../../components/Actions';
import TabsCut from '../../../../../../components/TabsCut';
import TabsListItem from '../../../../../../components/TabsListItem';
import SvgIcon from '../../../../../../components/SvgIcon';
import Textarea from '../../../../../../components/Textarea';
import Button from '../../../../../../components/Button';
import MobileMenuHeader from '../../../../../../components/MobileMenuHeader';

const icons = ['bigCard', 'picture', 'eye', 'shareLink'];
const iconsComponents = ['eye', 'shareLink'].map((e) => <SvgIcon name={e} />);

const FIRST = 0;
const SECOND = 1;
const THIRD = 2;
const FOURTH = 3;

const HomepageTest = ({
  nextClickHandler,
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
  testObject,
  locales,
  locales: {
    common,
    testing: {
      name,
      caseStudies,
      planHomepage: { header, text, title, sites, list, actions },
    },
  },
}) => {
  const {
    testing: { updateTest, updateTestImage },
  } = useDispatch();

  const setTemplate = (template) => {
    updateTest({
      id: testObject.id,
      test: {
        data: {
          ...testObject.data,
          template,
        },
      },
    });
  };

  const handleChange = (e) => {
    updateTest({
      id: testObject.id,
      test: {
        data: {
          ...testObject.data,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  const handleImageUpload = (id, file) => {
    updateTestImage({
      id,
      name: 'image',
      value: URL.createObjectURL(file),
      blob: file,
    });
  };

  const [opened, setOpened] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const link = testObject.id
    ? `${window.location.origin}/tests/${testObject.id}`
    : null;
  const onCopy = () => {
    if (link) {
      setIsCopied(true);
      copy(link);
      setTimeout(() => setIsCopied(false), 3000);
    }
  };

  return (
    <div className="bmc-page testing">
      <Header
        className="bmc-page-header--blue"
        icon="icon-VP"
        locales={locales}
        subtitle={name}
        title={header}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        componentIndex={componentIndex}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="testingActive" title={name} color="blue" />
        }
      />
      <Text>{[text[0], text[1]]}</Text>
      <Title align="left" text={title[0]} />
      <Text>{[text[2]]}</Text>

      <div className="websites">
        {sites.map((site, i) => {
          return <WebsiteCard key={i} {...site} />;
        })}
      </div>
      <Title text={title[1]} align="left" />
      <List items={list} icons={icons} />

      <Line />

      <h2 className="bmc-page-title">
        <span className="bmc-page-title--blue">{title[2]}</span> {title[3]}
      </h2>

      <Text align="center">{[text[8]]}</Text>

      <Tabs
        tabs={[common.yourStrategy, common.caseStudy]}
        withBlur
        className="margin-top-60 bmc-page-tabs--blue"
      >
        {[
          <div key="strategy">
            <TabsList formStyle>
              <div className="bmc-page-tabs-content-list-title">
                Choose Template
              </div>
              <div className="bmc-page-tabs-content-list-subtitle">
                Choose Template
              </div>
            </TabsList>
            <TabsList formStyle>
              <div className="templates-wrapper">
                {[FIRST, SECOND, THIRD, FOURTH].map((i) => (
                  <div
                    onClick={() => setTemplate(i)}
                    key={i}
                    className={`template ${
                      testObject.data.template === i ? 'active' : ''
                    }`}
                  >
                    <Img
                      className="image"
                      src={`/images/testing/template-${i + 1}.png`}
                      aspectRatio={3 / 2}
                    />
                    <div className="border" />
                  </div>
                ))}
              </div>
            </TabsList>
            {testObject.data.template === FIRST && (
              <First
                testObject={testObject}
                locales={locales}
                handleImageUpload={handleImageUpload}
                handleChange={handleChange}
              />
            )}
            {testObject.data.template === SECOND && (
              <Second
                testObject={testObject}
                locales={locales}
                handleImageUpload={handleImageUpload}
                handleChange={handleChange}
              />
            )}
            {testObject.data.template === THIRD && (
              <Third
                testObject={testObject}
                locales={locales}
                handleImageUpload={handleImageUpload}
                handleChange={handleChange}
              />
            )}
            {testObject.data.template === FOURTH && (
              <Fourth
                testObject={testObject}
                locales={locales}
                handleImageUpload={handleImageUpload}
                handleChange={handleChange}
              />
            )}
            <TabsList formStyle>
              <TabsListItem
                subtitle="Click on the button to Preview your Homepage "
                title="Preview Your Page"
                icon={iconsComponents[0]}
                text={
                  // eslint-disable-next-line
                  <Button
                    onClick={() => {
                      if (link) window.open(link, '_blank');
                    }}
                    wide
                    icon={<SvgIcon name="eye" />}
                    title="See Preview"
                    className="bmc-button--testing-icon"
                  />
                }
              />
            </TabsList>
            <TabsList formStyle>
              <TabsListItem
                subtitle="Share your Homepage. Respondees will be asked 4 questions"
                title="Share"
                icon={iconsComponents[1]}
                text={
                  // eslint-disable-next-line
                  <>
                    <Textarea onChange={() => {}} value={link || ''} rows={1} />

                    <Button
                      onClick={onCopy}
                      wide
                      icon={<SvgIcon name="copy" />}
                      title={isCopied ? 'Copied to clipboard!' : 'Copy'}
                      className="bmc-button--testing-icon"
                    />
                  </>
                }
              />
            </TabsList>
          </div>,
          <Tabs
            key="tabs"
            tabs={caseStudies.map((e) => e.name)}
            className="bmc-page-tabs--black bmc-page-tabs--inner"
          >
            {caseStudies.map((item, index) => (
              <div key={index}>
                <TabsCut
                  setOpened={setOpened}
                  opened={opened}
                  caseStudy={item}
                />

                <TabsListItem icon={null}>
                  <div className="homepage-page-builder-case-study">
                    <div className="top">
                      <div className="title margin-bottom-30">
                        Example Landing Page
                      </div>
                      <img
                        className="margin-bottom-30"
                        src={item.templateImageOne}
                        alt="case-study-1"
                      />
                      <img
                        className="margin-bottom-30"
                        src={item.templateImageTwo}
                        alt="case-study-2"
                      />
                    </div>
                  </div>
                </TabsListItem>
              </div>
            ))}
          </Tabs>,
        ]}
      </Tabs>
      <Actions
        nextClickHandler={nextClickHandler}
        buttonName="Next"
        tooltipIfDisabled={actions.tooltipIfDisabled}
      />
    </div>
  );
};

export default HomepageTest;
