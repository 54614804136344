import { v4 as uuidv4 } from 'uuid';

import { ALERT_TYPES } from '../utils/const';

const alert = {
  state: {
    collection: [],
  },
  reducers: {
    createAlertReducer({ collection }, payload) {
      if (!collection.find((e) => e.id === payload.id)) {
        return { collection: [...collection, payload] };
      }
      return { collection };
    },
    removeAlertReducer({ collection }, id) {
      return { collection: collection.filter((item) => item.id !== id) };
    },
    removeCurrentAlertReducer({ collection }) {
      const newCollection = collection.slice(1);

      return { collection: newCollection };
    },
  },
  effects: (dispatch) => ({
    async addAlert({ id, title, link, type = ALERT_TYPES.danger }) {
      dispatch.alert.createAlertReducer({
        id: id || uuidv4(),
        title,
        link,
        type,
      });
      setTimeout(() => {
        dispatch.alert.removeAlertReducer(id);
      }, 10000);
    },
    async removeCurrentAlert() {
      dispatch.alert.removeCurrentAlertReducer();
    },
  }),
};

export default alert;
