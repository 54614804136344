import React from 'react';
import classNames from 'classnames';
import ChatGroupItemStateComponent from './chat-group-item-state-component';
import getShortNameOfChatGroup from '../utils/getShortNameOfChatGroup';

export default function ChatGroupItemComponent({
  isExpanded = true,
  unreadCommentCount = 0,
  mentionCommentCount = 0,
  lastCommentCreatedAt = null,
  chatGroup,
}) {
  if (!isExpanded)
    return (
      <div className="chat-group-item-component">
        <div className="chat-group-item-component__avatar">
          <span>{getShortNameOfChatGroup(chatGroup.name)}</span>
        </div>
        {(mentionCommentCount > 0 || unreadCommentCount > 0) && (
          <div
            className={classNames('chat-group-item-component__badge', {
              mentioned: mentionCommentCount > 0,
            })}
          >
            {mentionCommentCount > 0 ? '@' : unreadCommentCount}
          </div>
        )}
      </div>
    );

  return (
    <div className="chat-group-item-component">
      <div className="chat-group-item-component__avatar">
        <span>{getShortNameOfChatGroup(chatGroup.name)}</span>
      </div>
      <div className="chat-group-item-component__content">
        <div
          className={classNames('chat-group-item-component__name', {
            unread: unreadCommentCount > 0 || mentionCommentCount > 0,
          })}
        >
          {chatGroup.name}
        </div>
        <ChatGroupItemStateComponent
          unreadCommentCount={unreadCommentCount}
          mentionCommentCount={mentionCommentCount}
          createdAt={lastCommentCreatedAt}
        />
      </div>
    </div>
  );
}
