import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import SectionHeader from '../SectionHeader';
import UnitEconomics from './UnitEconomics';
import Chart from './Chart';
import Forecast from './Forecast';
import Textarea from '../../../components/Textarea';

const Finance = ({
  data: { description, showStatus },
  locales: { portfolio },
  disabled = false,
}) => {
  const {
    portfolio: { updateSectionField, updateSectionFieldReducer },
  } = useDispatch();

  const setShowStatus = (status) => {
    updateSectionFieldReducer({
      sectionName: 'finance',
      field: 'showStatus',
      value: status,
    });
  };

  const [totalCashIn, setTotalCashIn] = useState([]);
  const [totalCashOut, setTotalCashOut] = useState([]);

  return (
    <div className="portfolio-finance">
      <SectionHeader
        locales={portfolio}
        showStatus={showStatus}
        sectionName={portfolio.finance.sectionName}
        setShowStatus={setShowStatus}
        mainColor="purple"
      />
      {showStatus && (
        <div>
          <Textarea
            name="textarea"
            rows={5}
            className="bmc-form-textarea textarea"
            delay={700}
            value={description || ''}
            onChange={(e) =>
              updateSectionField({
                sectionName: 'finance',
                field: 'description',
                name: 'financeText',
                value: e.target.value,
              })
            }
            placeholder={portfolio.page.descriptionPlaceholder}
            disabled={disabled}
          />
          <UnitEconomics locales={portfolio} />
          <Forecast
            setTotalCashOut={setTotalCashOut}
            setTotalCashIn={setTotalCashIn}
            locales={portfolio}
          />
          <Chart
            totalCashIn={totalCashIn}
            totalCashOut={totalCashOut}
            locales={portfolio}
          />
        </div>
      )}
    </div>
  );
};

export default Finance;
