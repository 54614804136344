import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import classnames from 'classnames';

import Button from '../../components/Button';
import usePreload from '../../hooks/preload';
import CloseButton from '../../components/CloseButton';

const FirstTimeTip = ({ page, tips, locales }) => {
  const classes = {
    'first-time-tip-bmc': page === 'bmc',
    'first-time-tip-testing': page === 'testing',
    'first-time-tip-portfolio': page === 'portfolio',
    'first-time-tip-ideation': page === 'ideation',
  };

  const pageLocales = locales[tips];

  const [activeStep, setActiveStep] = useState(0);

  const {
    user: { updateCurrentUser },
  } = useDispatch();
  const userTips = useSelector((state) => state.user.current.tips);
  const visited = get(userTips, 'visited', []);
  const isFirstTime = userTips ? !visited.some((el) => el === page) : false;

  const { files } = usePreload({
    files: pageLocales.tips.map((e) => e.image),
    placeholder: '/images/gifs/loader.webp',
    performDownload: isFirstTime,
  });

  const closeModal = () => {
    updateCurrentUser({
      name: 'tips',
      value: {
        ...userTips,
        visited: [...visited, page],
      },
    });
  };

  const closeModalHandler = (e) => {
    if (e.target.className.includes('first-time-tip ')) {
      closeModal();
    }
  };

  const handleNextAndClose = () => {
    if (activeStep < pageLocales.tips.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      closeModal();
    }
  };

  if (!isFirstTime) return null;

  return (
    <div
      className={classnames('first-time-tip', classes)}
      onClick={closeModalHandler}
    >
      <div className="first-time-tip__container">
        <CloseButton onClick={closeModal} />
        <h3 className="first-time-tip__title">{pageLocales.title}</h3>
        <div className="first-time-tip__content">
          <div className="first-time-tip__content-info">
            {pageLocales.tips.map((tip, index) => (
              <div
                key={index}
                className={classnames('first-time-tip__content-info-step', {
                  'first-time-tip__content-info-step--active':
                    index === activeStep,
                })}
              >
                <h4 onClick={() => setActiveStep(index)}>{tip.title}</h4>
                <p>{tip.description}</p>
              </div>
            ))}
          </div>

          {files[activeStep].loaded ? (
            <img
              src={files[activeStep].url}
              alt=""
              className="first-time-tip__content-image"
            />
          ) : (
            <div className="first-time-tip__content-placeholder">
              <img src={files[activeStep].url} alt="placeholder" />
            </div>
          )}
        </div>

        <div className="first-time-tip__actions">
          <Button
            primary
            title={
              activeStep < pageLocales.tips.length - 1 ? 'Understand' : 'Close'
            }
            className="first-time-tip__button"
            onClick={handleNextAndClose}
          />
        </div>
      </div>
    </div>
  );
};

FirstTimeTip.propTypes = {
  page: PropTypes.oneOf([
    'bmc',
    'testing',
    'portfolio',
    'ideation',
    'adminDashboard',
    'educatorDashboard',
  ]).isRequired,
  locales: PropTypes.object.isRequired,
  tips: PropTypes.oneOf([
    'bmcTip',
    'testingTip',
    'portfolioTip',
    'ideationTip',
    'adminDashboardTip',
    'educatorDashboardTip',
  ]).isRequired,
};

export default FirstTimeTip;
