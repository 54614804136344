import {
  Paragraph,
  AlignmentType,
  HeightRule,
  Table,
  TableAnchorType,
  OverlapType,
  WidthType,
  TableLayoutType,
  TableRow,
  TableCell,
  VerticalAlign,
} from 'docx';

import {
  pageSize,
  convertPixelToTwip,
  COLORS,
  transparentBorder,
  shadingBox,
  tableWidth,
  noPageMargin,
  getPageBg,
} from '../Constants';
import { emptyCell, marginTableRow, textRun } from '../helpers/index';

export default async (sectionName, sectionType, options, generalOptions) => {
  return {
    properties: {
      page: {
        size: pageSize,
        margin: noPageMargin,
      },
    },
    children: [
      // Primary box
      getPageBg(options.backgroundDark),
      // Title
      new Table({
        float: {
          horizontalAnchor: TableAnchorType.TEXT,
          verticalAnchor: TableAnchorType.TEXT,
          overlap: OverlapType.NEVER,

          absoluteVerticalPosition: convertPixelToTwip(0),
          absoluteHorizontalPosition: 0,
        },
        width: {
          size: convertPixelToTwip(tableWidth),
          type: WidthType.DXA,
        },
        layout: TableLayoutType.AUTOFIT,
        rows: [
          // empty row for padding
          marginTableRow(
            50,
            tableWidth,
            COLORS.PRIMARY,
            5,
            options.backgroundDark,
          ),

          // row with data
          new TableRow({
            height: {
              value: convertPixelToTwip(539 - 140),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(28, options.backgroundDark, options.backgroundDark),
              // title Cell
              new TableCell({
                width: {
                  size: convertPixelToTwip(164),
                  type: WidthType.DXA,
                },
                verticalAlign: VerticalAlign.CENTER,
                shading: shadingBox(options.backgroundDark),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.START,
                    children: [
                      textRun(sectionName, {
                        color:
                          options.titleColor ||
                          generalOptions.titleColor ||
                          COLORS.BLACK,
                        font: generalOptions.titleFont,
                        bold: generalOptions.titleBold,
                        size: 24,
                        allCaps: true,
                      }),
                    ],
                  }),
                ],
                borders: transparentBorder(COLORS.PRIMARY),
              }),

              emptyCell(30, options.backgroundDark, options.backgroundDark),

              new TableCell({
                width: {
                  size: convertPixelToTwip(532),
                  type: WidthType.DXA,
                },
                verticalAlign: VerticalAlign.CENTER,
                shading: shadingBox(options.backgroundDark),
                children: [
                  new Paragraph({
                    children: [
                      textRun(sectionType.description, {
                        font: generalOptions.bodyFont,
                        size: 14,
                        color:
                          options.bodyColor ||
                          generalOptions.bodyColor ||
                          COLORS.BLACK,
                      }),
                    ],
                  }),
                ],
                borders: transparentBorder(COLORS.BLACK),
              }),

              emptyCell(30, options.backgroundDark, options.backgroundDark),
            ],
          }),

          // empty row for padding
          marginTableRow(
            50,
            tableWidth,
            COLORS.PRIMARY,
            5,
            options.backgroundDark,
          ),
        ],
      }),
    ],
  };
};
