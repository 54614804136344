const IDEAS_MAX_COUNT = 6;
const PROBLEMS_MIN_COUNT = 3;
const SOLUTIONS_MIN_COUNT = 3;

const problemsRules = (idea) => idea.data.ideas.length === IDEAS_MAX_COUNT;

const solutionRules = (idea) =>
  problemsRules(idea) &&
  idea.data.ideas.filter((e) => e.problem).length >= PROBLEMS_MIN_COUNT;

const selectIdeasRules = (idea) =>
  problemsRules(idea) &&
  idea.data.ideas.filter((e) => e.solution).length >= SOLUTIONS_MIN_COUNT;

const rateYourIdeasRules = ({
  data: { mostEasiestId, mostExcitedId, mostImpactId },
}) => !!(mostEasiestId && mostExcitedId && mostImpactId);

const summaryRules = (idea) => !!idea.data.pickedIdeaId;

export const calculateMenuRules = (state) => {
  const rateRules = rateYourIdeasRules(state.idea);
  return [
    true,
    true,
    problemsRules(state.idea),
    solutionRules(state.idea),
    selectIdeasRules(state.idea),
    rateRules,
    rateRules,
    summaryRules(state.idea),
    true,
  ];
};

export default function calculateIdeationProgress({ ideation: { idea } }) {
  const stepsCount = 5;
  const step = 100 / stepsCount;
  let ideationProgress = 0;

  if (problemsRules(idea)) ideationProgress += step;
  if (solutionRules(idea)) ideationProgress += step;
  if (selectIdeasRules(idea)) ideationProgress += step;
  if (rateYourIdeasRules(idea)) ideationProgress += step;
  if (summaryRules(idea)) ideationProgress += step;

  return ideationProgress;
}
