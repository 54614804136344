import React from 'react';
import Modal from '../../../../../main/components/Modal';
import ModalContainer from '../../../../components/ModalContainer';
import ModalHeader from '../../../../components/ModalHeader';
import Table from '../../../../components/Table';

export const columns = [
  {
    title: 'Project',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Activity Total',
    dataIndex: 'sumTimeTracks',
    key: 'sumTimeTracks',
    render: (projectsTimeTracks) => projectsTimeTracks || 0,
  },
  {
    title: 'Last 30 days',
    dataIndex: 'sumLastTimeTracks',
    key: 'sumLastTimeTracks',
    render: (projectsLastTimeTracks) => projectsLastTimeTracks || 0,
  },
];

const ModalBMCList = ({
  visible,
  onClose,
  fetchClassroomBMCs,
  bmcId,
  bmcs,
  loading = false,
  sort,
  meta,
  rowClick,
  rowClickable = false,
}) => {
  return (
    visible && (
      <Modal onBlackscreenClick={onClose} size="sm">
        <ModalContainer>
          <ModalHeader title="Projects in the class" onClose={onClose} />
          <div className="team-members-modal">
            <Table
              columns={columns}
              dataSource={bmcs}
              loading={loading}
              total={meta?.count || 0}
              current={meta?.page || 1}
              onChange={(pagination) => {
                const { current, pageSize } = pagination;
                fetchClassroomBMCs({
                  bmcId,
                  page: current,
                  limit: pageSize,
                });
              }}
              onRow={(record) => ({ onClick: () => rowClick(record) })}
              rowClickable={rowClickable}
              onSort={sort}
              meta={meta}
            />
          </div>
        </ModalContainer>
      </Modal>
    )
  );
};
export default ModalBMCList;
