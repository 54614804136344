import React from 'react';
import { useSelector } from 'react-redux';

import Img from '../../main/components/Img';
import { removeNewLines } from '../../main/utils/helpers';
import { COLORS, CURRENT_TEMPLATE_NAME } from '../../main/utils/const';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';

const { lightBlack } = COLORS;

const Team = ({
  locales: { portfolioWeb },
  borderColorName,
  isFirstColorPallete = false,
}) => {
  const {
    portfolioTeammate: { collection },
    portfolio: { team },
  } = useSelector((state) => state);

  const getAbbreviation = (source) => {
    return source
      .split(' ')
      .map((n) => n[0])
      .join('');
  };

  const teamFirstPalletteColor = (index) => {
    return (
      ((index === 0 || index % 4 === 0) && 'is-blue') ||
      ((index === 1 || index % 4 === 1) && 'is-green') ||
      (index % 2 === 0 && index % 4 !== 0 && 'is-red') ||
      (index !== 0 && index % 3 === 0 && 'is-orange')
    );
  };

  const teamSecondThirdPalletteColor = (index) => {
    return (
      ((index === 0 || index % 4 === 0) && 'is-brown') ||
      ((index === 1 || index % 4 === 1) && 'is-yellow') ||
      (index % 2 === 0 && index % 4 !== 0 && 'is-darkGreen') ||
      (index !== 0 && index % 3 === 0 && 'is-lightBrown')
    );
  };

  return (
    <div className="section portfolio-teammates-cards section-106">
      <SectionHeaderWeb
        templateName={CURRENT_TEMPLATE_NAME}
        title={portfolioWeb.team}
        description={team.description}
        style={{ color: !isFirstColorPallete && lightBlack }}
      />
      <div className="cards">
        {collection.map(({ id, name, position, description, image }, index) => (
          <div className="card" key={id}>
            <div
              className={`logo with-text ${
                isFirstColorPallete
                  ? teamFirstPalletteColor(index)
                  : teamSecondThirdPalletteColor(index)
              }`}
            >
              {image.cropped ? (
                <Img src={image.cropped} />
              ) : (
                <div className="text custom-font-title">
                  {getAbbreviation(name)}
                </div>
              )}
            </div>
            <div className="position custom-font-body">{position}</div>
            <div className="title custom-font-title">{name}</div>
            <div
              className="separator"
              style={{
                backgroundColor: !isFirstColorPallete && borderColorName,
              }}
            />
            <div className="description custom-font-body">
              {description && removeNewLines(description)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
