import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  BLOG_FRIENDLY_TEMPLATE_NAME,
  BOLD_TEMPLATE_NAME,
  COLOURFUL_TEMPLATE_NAME,
  CURRENT_TEMPLATE_NAME,
} from '../../../utils/const';
import IntroductionCurrent from '../currentTemplate/IntroductionCurrent';
import IntroductionColourfulFun from '../colourfulFunTemplate/IntroductionColourfulFun';
import IntroductionBlog from '../blogFriendly/IntroductionBlog';
import IntroductionArch from '../architecturalMinimal/IntroductionArch';
import IntroductionBold from '../boldTemplate/IntroductionBold';

const Introduction = ({
  data,
  locales,
  firstSvgColor,
  secondSvgColor,
  backgroundSection,
  backgroundContent,
  backgroundModal,
  backgroundImage,
  colorPaletteNumber,
  titleColor,
  textColor,
  sliderColor,
  buttonSecondColor,
  buttonFirstColor,
  backgroundSecondButton,
  isFourthColorPaletteNumber = false,
  templateName,
  disabled = false,
}) => {
  const {
    portfolio: {
      updateIntroductionImage,
      updateIntroductionImageCoords,
      updateSectionField,
    },
  } = useDispatch();

  const { uploadingIntrologo, uploadingIntrobg } = useSelector(
    (state) => state.portfolio,
  );

  const { introLogo, introLogoCoords, introBg, introBgCoords } = data;

  useEffect(() => {
    if (!isEmpty(introLogoCoords)) {
      const introLogoWithoutCrop = introLogo.cropped.slice(
        0,
        introLogo.cropped.lastIndexOf('?'),
      );
      updateSectionField({
        sectionName: 'introduction',
        field: 'introLogo',
        name: 'introLogo',
        value: {
          ...introLogo,
          cropped: `${introLogoWithoutCrop}?${introLogoCoords.x}_${introLogoCoords.y}`,
        },
      });
    }
  }, [introLogoCoords]);

  useEffect(() => {
    if (!isEmpty(introBgCoords)) {
      const introBgWithoutCrop = introBg.cropped.slice(
        0,
        introBg.cropped.lastIndexOf('?'),
      );
      updateSectionField({
        sectionName: 'introduction',
        field: 'introBg',
        name: 'introBg',
        value: {
          ...introBg,
          cropped: `${introBgWithoutCrop}?${introBgCoords.x}_${introBgCoords.y}`,
        },
      });
    }
  }, [introBgCoords]);

  const handleDescriptionSubmit = useCallback((e) => {
    updateSectionField({
      sectionName: 'introduction',
      field: 'description',
      name: 'description',
      value: e.target.value,
    });
  }, []);

  const handleInputSubmit = useCallback((e) => {
    updateSectionField({
      sectionName: 'introduction',
      field: 'title',
      name: 'title',
      value: e.target.value,
    });
  }, []);

  const onLogoDrop = useCallback(([file], openCropperHandler) => {
    updateIntroductionImage({
      name: 'introLogo',
      blob: file,
      value: URL.createObjectURL(file),
      success: () => {
        openCropperHandler();
      },
    });
  }, []);

  const onLogoCropComplete = (croppedAreaPixels, innerCropData, zoom) => {
    updateIntroductionImageCoords({
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: introLogoCoords,
      name: 'introLogo',
    });
  };

  const onBgDrop = useCallback(([file], openCropperHandler) => {
    updateIntroductionImage({
      name: 'introBg',
      blob: file,
      value: URL.createObjectURL(file),
      success: () => {
        openCropperHandler();
      },
    });
  }, []);

  const onBgCropComplete = (croppedAreaPixels, innerCropData, zoom) => {
    updateIntroductionImageCoords({
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: introBgCoords,
      name: 'introBg',
    });
  };

  const introDependsOnTemplateName = {
    [CURRENT_TEMPLATE_NAME]: (
      <IntroductionCurrent
        data={data}
        locales={locales}
        onLogoDrop={onLogoDrop}
        onLogoCropComplete={onLogoCropComplete}
        onBgDrop={onBgDrop}
        onBgCropComplete={onBgCropComplete}
        handleDescriptionSubmit={handleDescriptionSubmit}
        handleInputSubmit={handleInputSubmit}
        uploadingIntrologo={uploadingIntrologo}
        uploadingIntrobg={uploadingIntrobg}
        disabled={disabled}
      />
    ),
    [COLOURFUL_TEMPLATE_NAME]: (
      <IntroductionColourfulFun
        data={data}
        locales={locales}
        onLogoDrop={onLogoDrop}
        onLogoCropComplete={onLogoCropComplete}
        onBgDrop={onBgDrop}
        onBgCropComplete={onBgCropComplete}
        handleDescriptionSubmit={handleDescriptionSubmit}
        handleInputSubmit={handleInputSubmit}
        uploadingIntrologo={uploadingIntrologo}
        uploadingIntrobg={uploadingIntrobg}
        firstSvgColor={firstSvgColor}
        secondSvgColor={secondSvgColor}
        backgroundSection={backgroundSection}
        disabled={disabled}
      />
    ),
    [BLOG_FRIENDLY_TEMPLATE_NAME]: (
      <IntroductionBlog
        data={data}
        locales={locales}
        onLogoDrop={onLogoDrop}
        onLogoCropComplete={onLogoCropComplete}
        onBgDrop={onBgDrop}
        onBgCropComplete={onBgCropComplete}
        handleDescriptionSubmit={handleDescriptionSubmit}
        handleInputSubmit={handleInputSubmit}
        uploadingIntrologo={uploadingIntrologo}
        uploadingIntrobg={uploadingIntrobg}
        backgroundSection={backgroundSection}
        backgroundModal={backgroundModal}
        backgroundContent={backgroundContent}
        isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        disabled={disabled}
      />
    ),
    [ARCHITECTURAL_MINIMAL_TEMPLATE_NAME]: (
      <IntroductionArch
        data={data}
        locales={locales}
        onLogoDrop={onLogoDrop}
        onLogoCropComplete={onLogoCropComplete}
        onBgDrop={onBgDrop}
        onBgCropComplete={onBgCropComplete}
        handleDescriptionSubmit={handleDescriptionSubmit}
        handleInputSubmit={handleInputSubmit}
        uploadingIntrologo={uploadingIntrologo}
        uploadingIntrobg={uploadingIntrobg}
        backgroundSection={backgroundSection}
        backgroundModal={backgroundModal}
        backgroundImage={backgroundImage}
        colorPaletteNumber={colorPaletteNumber}
        disabled={disabled}
      />
    ),
    [BOLD_TEMPLATE_NAME]: (
      <IntroductionBold
        data={data}
        locales={locales}
        onLogoDrop={onLogoDrop}
        onLogoCropComplete={onLogoCropComplete}
        onBgDrop={onBgDrop}
        onBgCropComplete={onBgCropComplete}
        handleDescriptionSubmit={handleDescriptionSubmit}
        handleInputSubmit={handleInputSubmit}
        uploadingIntrologo={uploadingIntrologo}
        uploadingIntrobg={uploadingIntrobg}
        backgroundSection={backgroundSection}
        backgroundModal={backgroundModal}
        titleColor={titleColor}
        textColor={textColor}
        backgroundImage={backgroundImage}
        sliderColor={sliderColor}
        buttonSecondColor={buttonSecondColor}
        buttonFirstColor={buttonFirstColor}
        backgroundSecondButton={backgroundSecondButton}
        disabled={disabled}
      />
    ),
  };

  return introDependsOnTemplateName[templateName];
};

export default Introduction;
