const bmcProgress = {
  state: {
    id: null,
    name: '',
    keyActivitiesProgress: null,
    keyResourcesProgress: null,
    keyPartnersProgress: null,
    valuePropositionsProgress: null,
    getKeepGrowProgress: null,
    channelsProgress: null,
    customerSegmentsProgress: null,
    costsProgress: null,
    sustainabilitiesProgress: null,
    revenuesProgress: null,
  },
  reducers: {
    updateBmcProgressReducer(state, attributes) {
      return { ...state, ...attributes };
    },
  },
  effects: (dispatch) => ({
    initBmcProgress(_, rootState) {
      const {
        id,
        name,
        keyActivitiesProgress,
        keyResourcesProgress,
        keyPartnersProgress,
        valuePropositionsProgress,
        getKeepGrowProgress,
        channelsProgress,
        customerSegmentsProgress,
        costsProgress,
        sustainabilitiesProgress,
        revenuesProgress,
      } = rootState.bmc.current.attributes;

      dispatch.bmcProgress.updateBmcProgressReducer({
        id,
        name,
        keyActivitiesProgress,
        keyResourcesProgress,
        keyPartnersProgress,
        valuePropositionsProgress,
        getKeepGrowProgress,
        channelsProgress,
        customerSegmentsProgress,
        costsProgress,
        sustainabilitiesProgress,
        revenuesProgress,
      });
    },
  }),
};

export default bmcProgress;
