import {
  SECOND_COLOR_PALETTE_NUMBER,
  THIRD_COLOR_PALETTE_NUMBER,
} from '../const';

export const isSecondOrThirdTemplate = (colorPaletteNumber) => {
  if (
    colorPaletteNumber === SECOND_COLOR_PALETTE_NUMBER ||
    colorPaletteNumber === THIRD_COLOR_PALETTE_NUMBER
  ) {
    return true;
  }
};
