import { Button, Form, Input } from 'antd';
import React from 'react';
import Modal from '../../../main/components/Modal';
import ModalContainer from '../../components/ModalContainer';
import ModalHeader from '../../components/ModalHeader';

const ModalInviteStudentsToClass = () => {
  return (
    <>
      <Modal onBlackscreenClick={() => {}}>
        <ModalContainer>
          <ModalHeader title="Invite Student to Class" />
          <div className="jumborton">
            <h3 className="header-classname">Class Name</h3>
          </div>
          <Form layout="vertical">
            <Form.Item
              label="Share Link with Students"
              className="admin-input-label"
            >
              <Input className="admin-input" />
            </Form.Item>
            <p className="description">
              Anyone signing up with this link will be automatically added to
              this class.
            </p>
            <p className="description">
              Remember that students need to sign up with this link, or else
              they won’t appear in your class, and they will only have a DEMO
              account
            </p>
            <div className="flex align-right mt-20">
              <Button type="primary">Copy Link</Button>
            </div>
          </Form>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default ModalInviteStudentsToClass;
