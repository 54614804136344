import React, { useEffect } from 'react';
import TestCard from './TestCard';
import Modal from '../../../components/Modal';
import SvgIcon from '../../../components/SvgIcon';

const ModalWrapper = ({
  isOpen,
  setModalStatus,
  isEditable = true,
  disabled = false,
  ...props
}) => {
  const { isColourfulfunTemplate, firstSvgColor, secondSvgColor } = props;

  useEffect(() => {
    if (!isEditable && !disabled) {
      const body = document.querySelector('body');

      if (isOpen) body.classList.add('body-modal-open');
      else body.classList.remove('body-modal-open');

      return () => {
        body.classList.remove('body-modal-open');
      };
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <Modal
      onBlackscreenClick={() => setModalStatus({ status: false, id: null })}
      className="relative"
    >
      {isColourfulfunTemplate && (
        <>
          <div className="svg-modalFirstSvg" style={{ fill: firstSvgColor }}>
            <SvgIcon name="modalFirstSvg" />
          </div>
          <div className="svg-modalSecondSvg" style={{ fill: secondSvgColor }}>
            <SvgIcon name="modalSecondSvg" />
          </div>
        </>
      )}

      <TestCard
        {...props}
        setModalStatus={setModalStatus}
        isEditable={isEditable}
        modalMode
      />
    </Modal>
  );
};

export default ModalWrapper;
