import React from 'react';
import classNames from 'classnames';
import InputRange from 'react-input-range';

export default function ProgressBar({
  className,
  title,
  onChange,
  value = 1,
  step = 1,
  progress = 50,
  editMode = false,
  minValue = 1,
  maxValue = 100,
}) {
  return (
    <div className={classNames('bmc-progressbar', className)}>
      <div className="bmc-progressbar-title">{title}</div>
      <div className="bmc-progressbar-value">{value}</div>

      {editMode ? (
        <div className="bmc-progressbar-range">
          <InputRange
            step={step}
            minValue={minValue}
            maxValue={maxValue}
            value={value}
            onChange={onChange}
          />
        </div>
      ) : (
        <div className="bmc-progressbar-line">
          <div
            className="bmc-progressbar-line-inner"
            style={{ width: `${progress}%` }}
          />
        </div>
      )}
    </div>
  );
}
