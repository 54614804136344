import React from 'react';
import PropTypes from 'prop-types';

const width = 100;
const height = 100;
const strokeWidth = 8;
const radius = width / 2 - strokeWidth / 2;
const circumference = 2 * Math.PI * radius;

const DonutChart = ({ progress }) => {
  const strokeLength = (circumference / 100) * progress;
  const strokeDasharray = `${strokeLength},${circumference}`;
  const progressInt = parseInt(progress) || 0;

  return (
    <div className="bmc-donut-chart">
      <div className="bmc-donut-chart-inner">
        <div className="bmc-donut-chart-title">{progressInt}%</div>
      </div>
      <svg
        className="bmc-donut-chart-circle"
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="circle-chart__background"
          stroke="#f8f9fa"
          strokeWidth={strokeWidth}
          fill="none"
          cx={width / 2}
          cy={height / 2}
          r={radius}
        />
        <circle
          className="circle-chart__circle"
          stroke="#0984FF"
          strokeWidth={strokeWidth}
          strokeDasharray={strokeDasharray}
          strokeLinecap="round"
          fill="none"
          cx={width / 2}
          cy={height / 2}
          r={radius}
        />
      </svg>
    </div>
  );
};

DonutChart.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default DonutChart;
