import React, { useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import useMediaQuery from 'react-use-media-query-hook';

import UnitEconomicsTabTech from '../../main/components/FinanceTabs/UnitEconomicsTab';
import ForecastTabTech from '../../main/components/FinanceTabs/ForecastTab';
import GraphTabTech from '../../main/components/FinanceTabs/GraphTab';
import { TECH_MODERN_TEMPLATE_NAME } from '../../main/utils/const';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';

const UnitEconomicsTab = 0;
const ForecastTab = 1;
const GraphTab = 2;

const FinanceTechWeb = ({
  locales: { portfolio, portfolioWeb },
  titleColor,
  firstChartColor,
  secondChartColor,
  borderCardColor,
  background,
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [activeTab, setActiveTab] = useState(UnitEconomicsTab);
  const [totalCashIn, setTotalCashIn] = useState([]);
  const [totalCashOut, setTotalCashOut] = useState([]);

  const { finance } = useSelector((state) => state.portfolio);

  const tabs = {
    [UnitEconomicsTab]: (
      <UnitEconomicsTabTech
        locales={portfolio}
        isPreview
        templateName={TECH_MODERN_TEMPLATE_NAME}
        borderCardColor={borderCardColor}
        verticalBorderColor={titleColor}
        backgroundRow={`${background}80`}
      />
    ),
    [ForecastTab]: (
      <ForecastTabTech
        locales={portfolio}
        setTotalCashIn={setTotalCashIn}
        setTotalCashOut={setTotalCashOut}
        totalColor={titleColor}
        templateName={TECH_MODERN_TEMPLATE_NAME}
        borderCardColor={borderCardColor}
        backgroundTable={`${background}80`}
        backgroundRow={`${background}80`}
      />
    ),
    [GraphTab]: (
      <GraphTabTech
        locales={portfolio}
        totalCashIn={totalCashIn}
        totalCashOut={totalCashOut}
        firstChartColor={firstChartColor}
        secondChartColor={secondChartColor}
        templateName={TECH_MODERN_TEMPLATE_NAME}
        borderCardColor={borderCardColor}
      />
    ),
  };
  const allTabs = [tabs[UnitEconomicsTab], tabs[ForecastTab], tabs[GraphTab]];
  const currentTab = tabs[activeTab];

  return (
    <div
      className="portfolio-finance-tech portfolio-finance-tech-temp section-web"
      style={{ backgroundColor: background }}
    >
      <SectionHeaderWeb
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolioWeb.finance.title}
        description={finance.description}
        portfolioWeb={portfolioWeb}
        style={{ color: titleColor }}
      />

      <div className="tabs-wrapper">
        <div
          className={classnames(
            'tab',
            {
              active: activeTab === UnitEconomicsTab,
            },
            'custom-font-body',
          )}
          onClick={() => setActiveTab(UnitEconomicsTab)}
        >
          {portfolioWeb.finance.tabs.unitEconomics}
        </div>
        <div
          className={classnames(
            'tab',
            { active: activeTab === ForecastTab },
            'custom-font-body',
          )}
          onClick={() => setActiveTab(ForecastTab)}
        >
          {portfolioWeb.finance.tabs.forecast}
        </div>
        <div
          className={classnames(
            'tab',
            { active: activeTab === GraphTab },
            'custom-font-body',
          )}
          onClick={() => setActiveTab(GraphTab)}
        >
          {portfolioWeb.finance.tabs.graph}
        </div>
      </div>
      {isMobile ? allTabs : currentTab}
    </div>
  );
};

export default FinanceTechWeb;
