import React from 'react';
import { useSelector } from 'react-redux';

import SvgIcon from '../../main/components/SvgIcon';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import {
  COLOURFUL_TEMPLATE_NAME,
  IMAGE_ASPECT_RATIO_VP_COLOURFUN,
} from '../../main/utils/const';
import { renderImage } from '../../main/utils/helpers/renderImage';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';
import { renderSegmentList } from '../../main/utils/helpers/renderSegmentList';
import { renderText } from '../../main/utils/helpers/renderText';

const ValueProposition = ({
  locales: {
    portfolioWeb,
    valueProposition: {
      intro: { card },
    },
  },
  backgroundImage,
  firstSvgColor,
  secondSvgColor,
  background,
  isFourthColorPaletteNumber = false,
}) => {
  const { portfolio } = useSelector((state) => state);
  const {
    valueProposition: { product, reason, image },
    uniqueSellingPoint: { collection },
  } = useSelector((state) => state);

  const titleImageColor =
    isFourthColorPaletteNumber && getColorDependsOnBackground(backgroundImage);

  return (
    <div
      className="value-propositions-colourful-web section-web"
      style={{ backgroundColor: background }}
    >
      <div className="svg-funVPeleventh" style={{ fill: firstSvgColor }}>
        <SvgIcon name="funVPeleventh" />
      </div>
      <div className="svg-funVPtwelveth" style={{ fill: secondSvgColor }}>
        <SvgIcon name="funVPtwelveth" />
      </div>
      <div className="content">
        <div className="image">
          {renderImage(
            COLOURFUL_TEMPLATE_NAME,
            image,
            backgroundImage,
            portfolioWeb.valueProposition,
            IMAGE_ASPECT_RATIO_VP_COLOURFUN,
            titleImageColor,
          )}
        </div>

        <div className="middle">
          <SectionHeaderWeb
            templateName={COLOURFUL_TEMPLATE_NAME}
            title={portfolioWeb.valueProposition}
            description={portfolio.valueProposition.description}
            portfolioWeb={portfolioWeb}
          />
        </div>
        <div className="bottom">
          <div className="left">
            {renderSegmentList(card.solutionTitle)}
            {renderText(product)}
          </div>
          <div className="bottom-middle">
            {renderSegmentList(card.featuresTitle)}
            {renderText(reason)}
          </div>
          <div className="right">
            {renderSegmentList(card.pointsTitle)}
            {renderText(
              collection.map((point, index) => (
                <div className="margin-bottom-10" key={point.id || index}>
                  {point.description}
                </div>
              )),
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValueProposition;
