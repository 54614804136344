import {
  Paragraph,
  ImageRun,
  TextRun,
  Table,
  TableRow,
  TableCell,
  AlignmentType,
  WidthType,
  HeightRule,
  TableAnchorType,
  OverlapType,
  TableLayoutType,
  FrameAnchorType,
  HorizontalPositionAlign,
  VerticalPositionAlign,
} from 'docx';

import {
  pageSize,
  convertPixelToTwip,
  convertPixel,
  convertFontSize,
  COLORS,
  transparentBorder,
  tableWidth,
  shadingBox,
  noPageMargin,
} from '../Constants';
// eslint-disable-next-line import/no-cycle
import { emptyCell, marginTableRow } from '../helpers/index';

export default async (portfolio, base64String, options) => {
  return {
    properties: {
      page: {
        size: pageSize,
        margin: noPageMargin,
      },
    },
    children: [
      new Paragraph({
        frame: {
          position: {
            x: 0,
            y: 0,
          },
          width: convertPixelToTwip(tableWidth),
          height: convertPixelToTwip(539 + 56),
          rule: HeightRule.EXACT,
          anchor: {
            horizontal: FrameAnchorType.TEXT,
            vertical: FrameAnchorType.TEXT,
          },
          alignment: {
            x: HorizontalPositionAlign.CENTER,
            y: VerticalPositionAlign.TOP,
          },
        },
        shading: shadingBox(COLORS.WHITE),
      }),
      new Table({
        float: {
          horizontalAnchor: TableAnchorType.TEXT,
          verticalAnchor: TableAnchorType.TEXT,
          overlap: OverlapType.NEVER,
          absoluteVerticalPosition: convertPixelToTwip(-20),
          absoluteHorizontalPosition: 0,
        },
        width: {
          size: convertPixelToTwip(tableWidth),
          type: WidthType.DXA,
        },
        layout: TableLayoutType.AUTOFIT,
        rows: [
          marginTableRow(10, tableWidth, COLORS.WHITE, 3),
          new TableRow({
            height: {
              value: convertPixelToTwip(27),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(28, COLORS.WHITE),
              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.WHITE),
                children: [
                  // Title
                  new Paragraph({
                    frame: {
                      position: {
                        x: convertPixelToTwip(20),
                        y: 0,
                      },
                      width: convertPixelToTwip(75),
                      height: convertPixelToTwip(19),
                      anchor: {
                        horizontal: FrameAnchorType.MARGIN,
                        vertical: FrameAnchorType.MARGIN,
                      },
                      alignment: {
                        x: HorizontalPositionAlign.LEFT,
                        y: VerticalPositionAlign.TOP,
                      },
                    },
                    children: [
                      new TextRun({
                        text: 'GRAPH',
                        font: options.bodyFont,
                        color:
                          options.graph?.subtitleColor ||
                          options.bodyColor ||
                          COLORS.BLACK,
                        size: convertFontSize(24),
                        allCaps: true,
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
              emptyCell(28, COLORS.WHITE),
            ],
          }),
          marginTableRow(15, tableWidth, COLORS.WHITE, 3),
          // data row
          new TableRow({
            height: {
              value: convertPixelToTwip(28),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(28, COLORS.WHITE),
              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.WHITE),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new ImageRun({
                        data: base64String,
                        transformation: {
                          width: convertPixel(650),
                          height: convertPixel(455),
                        },
                      }),
                    ],
                  }),
                ],
              }),

              emptyCell(28, COLORS.WHITE),
            ],
          }),
        ],
      }),
    ],
  };
};
