import React, { useCallback, useContext } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import Footer from './Footer';
import Checkbox from './Checkbox';
import GlobalContext from '../../../utils/GlobalContext';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '1200px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow:
      '0px 0px 4px rgba(0, 0, 0, 0.05), 8px 8px 16px rgba(0, 0, 0, 0.05)',
    borderRadius: 16,
    border: 'none',
    background: '#fff',
    padding: '20px 40px',
  },
  overlay: {
    background: 'rgba(51, 66, 73, .5)',
    zIndex: 100,
  },
};

const ExportModal = ({ isOpen, setIsOpen, locales }) => {
  const { portfolio, portfolioWeb } = locales;
  const {
    sectionAvailabilitySettings: { bmc: bmcEnabled, testing: testingEnabled },
  } = useContext(GlobalContext);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const {
    portfolio: { updateSectionFieldReducer },
  } = useDispatch();

  const {
    introduction,
    projectSummary,
    team,
    bmc,
    testing,
    desirabilitySection,
    competitorAnalysis,
    customerSegment,
    valueProposition,
    customerRelationships,
    channels,
    feasibility,
    keyActivities,
    viability,
    finance,
    sustainability,
    contactUs,
    slug,
  } = useSelector((state) => state.portfolio);

  const handleChange = (sectionName, checked) => {
    updateSectionFieldReducer({
      sectionName,
      value: checked,
      field: 'showStatus',
    });
  };

  const portfolioShortCuts = [
    {
      name: portfolio.export.shortcuts.introduction,
      status: introduction.showStatus,
    },
    {
      name: portfolio.export.shortcuts.projectSummary,
      status: projectSummary.showStatus,
    },
    {
      name: portfolio.export.shortcuts.team,
      status: team.showStatus,
    },
    {
      name: portfolio.export.shortcuts.bmc,
      status: bmc.showStatus,
    },
    {
      name: portfolio.export.shortcuts.testing,
      status: testing.showStatus,
    },
    {
      name: portfolio.export.shortcuts.desirabilitySection,
      status: desirabilitySection.showStatus,
    },
    {
      name: portfolio.export.shortcuts.customerSegment,
      status: customerSegment.showStatus,
    },
    {
      name: portfolio.export.shortcuts.valueProposition,
      status: valueProposition.showStatus,
    },
    {
      name: portfolio.export.shortcuts.competitorAnalysis,
      status: competitorAnalysis.showStatus,
    },
    {
      name: portfolio.export.shortcuts.customerRelationships,
      status: customerRelationships.showStatus,
    },
    {
      name: portfolio.export.shortcuts.channels,
      status: channels.showStatus,
    },
    {
      name: portfolio.export.shortcuts.feasibility,
      status: feasibility.showStatus,
    },
    {
      name: portfolio.export.shortcuts.keyActivities,
      status: keyActivities.showStatus,
    },
    {
      name: portfolio.export.shortcuts.viability,
      status: viability.showStatus,
    },
    {
      name: portfolio.export.shortcuts.finance,
      status: finance.showStatus,
    },
    {
      name: portfolio.export.shortcuts.sustainability,
      status: sustainability.showStatus,
    },
    {
      name: portfolio.export.shortcuts.contactUs,
      status: contactUs.showStatus,
    },
  ];

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div className="export-modal">
        <div className="header">
          <div className="title">{portfolio.export.title}</div>
          <i onClick={closeModal} className="icon-Add close" />
        </div>
        <div className="separator" />
        <div className="sections">
          <div className="title">{portfolio.export.subtitle}</div>
          <div className="sections-rows">
            <div className="row blue">
              <Checkbox
                id="introduction"
                value={introduction.showStatus}
                title={portfolio.introduction.sectionName}
                handleChange={handleChange}
              />
              <Checkbox
                id="projectSummary"
                value={projectSummary.showStatus}
                title={portfolio.projectSummary.sectionName}
                handleChange={handleChange}
              />
              <Checkbox
                id="team"
                value={team.showStatus}
                title={portfolio.team.sectionName}
                handleChange={handleChange}
              />
              {bmcEnabled && (
                <Checkbox
                  id="bmc"
                  value={bmc.showStatus}
                  title={portfolio.bmc.sectionName}
                  handleChange={handleChange}
                />
              )}
              {testingEnabled && (
                <Checkbox
                  id="testing"
                  value={testing.showStatus}
                  title={portfolio.testing.sectionName}
                  handleChange={handleChange}
                />
              )}
            </div>

            {bmcEnabled && (
              <div className="row green">
                <Checkbox
                  id="desirabilitySection"
                  value={desirabilitySection.showStatus}
                  title={portfolio.export.desirability}
                  handleChange={handleChange}
                />
                <Checkbox
                  id="customerSegment"
                  value={customerSegment.showStatus}
                  title={portfolio.customerSegment.sectionName}
                  handleChange={handleChange}
                />
                <Checkbox
                  id="valueProposition"
                  value={valueProposition.showStatus}
                  title={portfolio.valueProposition.sectionName}
                  handleChange={handleChange}
                />
                <Checkbox
                  id="competitorAnalysis"
                  value={competitorAnalysis.showStatus}
                  title={portfolio.competitorAnalysis.sectionName}
                  handleChange={handleChange}
                />
                <Checkbox
                  id="customerRelationships"
                  value={customerRelationships.showStatus}
                  title={portfolio.customerRelationships.sectionName}
                  handleChange={handleChange}
                />
                <Checkbox
                  id="channels"
                  value={channels.showStatus}
                  title={portfolio.channels.sectionName}
                  handleChange={handleChange}
                />
              </div>
            )}

            <div className="row">
              {bmcEnabled && (
                <>
                  <Checkbox
                    id="feasibility"
                    value={feasibility.showStatus}
                    title={portfolio.export.feasibility}
                    handleChange={handleChange}
                    color="orange"
                  />
                  <Checkbox
                    id="keyActivities"
                    value={keyActivities.showStatus}
                    title={portfolio.export.keyActivities}
                    handleChange={handleChange}
                    color="orange"
                  />
                  <Checkbox
                    id="viability"
                    value={viability.showStatus}
                    title={portfolio.export.viability}
                    handleChange={handleChange}
                    color="purple"
                  />
                  <Checkbox
                    id="finance"
                    value={finance.showStatus}
                    title={portfolio.finance.sectionName}
                    handleChange={handleChange}
                    color="purple"
                  />
                  <Checkbox
                    id="sustainability"
                    value={sustainability.showStatus}
                    title={portfolio.sustainability.sectionName}
                    handleChange={handleChange}
                    color="purple"
                  />
                </>
              )}
              <Checkbox
                id="contactUs"
                value={contactUs.showStatus}
                title={portfolio.export.contacts}
                handleChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="separator" />
        <Footer
          portfolioShortcuts={portfolioShortCuts}
          slug={slug}
          locales={portfolio}
          portfolioWeb={portfolioWeb}
          sustainability={locales.sustainability}
        />
      </div>
    </ReactModal>
  );
};

export default ExportModal;
