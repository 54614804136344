import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Spinner from '../../../../components/Spinner';
import TestRow from '../../Components/TestRow';
import Modal from '../../Components/Modal';

const TestList = ({
  locales,
  locales: {
    testing: {
      titles,
      list: { title },
    },
  },
}) => {
  const [modal, setModal] = useState({
    status: false,
    test: null,
  });

  const {
    bmc: {
      current: { id: bmcId },
    },
    testing: { collection: tests, uploading },
  } = useSelector((state) => state);

  const {
    testing: { fetchTests, setTestReducer },
  } = useDispatch();

  useEffect(() => {
    if (bmcId) fetchTests(bmcId);
  }, [bmcId, modal.status]);

  const handleEdit = (test) => {
    setModal({ status: true });
    setTestReducer(test);
  };

  return (
    <div className="bmc-main testing">
      <Modal
        locales={locales}
        isOpen={modal.status}
        titles={titles}
        setModalStatus={setModal}
        isEditable={false}
      />
      <div className="bmc-main-header">
        <div className="bmc-main-header-title">{title}</div>

        <div className="bmc-main-header-actions bmc-main-header-actions--testing">
          .
        </div>
      </div>

      {uploading ? (
        <Spinner className="margin-top-60" big />
      ) : (
        <div className="tests-list margin-top-30">
          {tests.map((testItem) => (
            <TestRow
              titles={titles}
              key={testItem.id}
              test={testItem}
              onClick={() => handleEdit(testItem)}
              isPreview
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TestList;
