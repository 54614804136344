import React from 'react';

import Section from '../Section';
import Content from './Content';

export default function Personal({ locales, locales: { account } }) {
  return (
    <Section menuItems={account.menu} locales={locales}>
      <Content locales={locales} />
    </Section>
  );
}
