import React from 'react';
import { useDispatch } from 'react-redux';

import Textarea from '../../../components/Textarea';
import UploadFiles from '../../../components/UploadFiles';
import { COLORS, COMMENT_SUMMARY } from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import { isSecondOrThirdTemplate } from '../../../utils/helpers/isSecondOrThirdTemplate';

const { black, white } = COLORS;

const SummaryTech = ({
  data: { description },
  locales,
  locales: { portfolio },
  backgroundSummary,
  colorStyle,
  colorPaletteNumber,
}) => {
  const {
    portfolio: { updateSectionField },
  } = useDispatch();

  return (
    <div
      className="summary-tech relative"
      style={{ background: backgroundSummary }}
    >
      <PortfolioCommentIcon title={COMMENT_SUMMARY} />
      <div
        className="content"
        style={
          !isSecondOrThirdTemplate(colorPaletteNumber)
            ? { borderColor: black }
            : {}
        }
      >
        <div
          className={`tech-title title custom-font-title ${
            !isSecondOrThirdTemplate(colorPaletteNumber) && 'text-black'
          }`}
        >
          {portfolio.projectSummary.sectionName}
        </div>
        <div className="textarea-techModern">
          <Textarea
            id="DesirabilitySection"
            rows={5}
            delay={700}
            value={description}
            onChange={(e) => {
              updateSectionField({
                sectionName: 'projectSummary',
                field: 'description',
                name: 'projectSummary',
                value: e.target.value,
              });
            }}
            className={`textarea-tech description-summary custom-font-body ${
              !isSecondOrThirdTemplate(colorPaletteNumber) && 'text-black'
            }`}
            placeholder={portfolio.page.descriptionPlaceholder}
          />
        </div>

        <UploadFiles
          locales={locales}
          className="margin-top-40"
          fileBackgroundColor={
            isSecondOrThirdTemplate(colorPaletteNumber) ? white : black
          }
          fileTextColor={
            isSecondOrThirdTemplate(colorPaletteNumber) ? colorStyle : white
          }
          uplouderButtonStyle="portfolio-file-uploader__tech"
          filesWrapperStyle="portfolio-file-uploader-file-tech"
          fileStyle="portfolio-file-uploader-file-tech-link"
          removeIconStyle="portfolio-file-uploader-file__remove-tech"
          borderButtonColor={
            isSecondOrThirdTemplate(colorPaletteNumber) ? colorStyle : black
          }
          backgroundButtonColor={
            isSecondOrThirdTemplate(colorPaletteNumber) ? white : black
          }
          textButtonColor={
            isSecondOrThirdTemplate(colorPaletteNumber) ? colorStyle : black
          }
        />
      </div>
    </div>
  );
};

export default SummaryTech;
