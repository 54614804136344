import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import Textarea from '../../components/Textarea';
import Button from '../../components/Button';
import { KEY_ACTIVITIES_MAX_COUNT } from '../../utils/const';
import ConfirmationModal from '../../components/ConfirmationModal';

export default function KeyActivityCard({
  editMode,
  name,
  className,
  card: { steps, step },
  locales: { common },
  textColor,
  textStyle,
  buttonStyle,
  background,
  circleColor,
  lineColor,
  buttonColor,
  backgroundButton,
}) {
  const {
    keyActivity: { addKeyActivity, updateKeyActivity, removeKeyActivity },
  } = useDispatch();

  return (
    <div className={classNames('bmc-page-keycard-wrapper', className)}>
      <div className="bmc-page-keycard" style={{ backgroundColor: background }}>
        <div className="bmc-page-keycard-steps bmc-page-keycard-steps--vertical">
          {steps.map((item, index) => (
            <div key={item.id} className="bmc-page-keycard-step">
              <div
                className={`bmc-page-keycard-step-title ${textStyle} custom-font-body`}
                style={{ color: textColor }}
              >
                {item.title || `${step} ${index + 1}`}
              </div>

              {editMode ? (
                <>
                  <div
                    className="circle-activity"
                    style={{ backgroundColor: circleColor }}
                  />
                  <div
                    className="line-activity"
                    style={{ backgroundColor: lineColor }}
                  />
                  <Textarea
                    value={item.description}
                    delay={700}
                    className={`bmc-form-textarea ${textStyle} custom-font-body`}
                    onChange={(e) =>
                      updateKeyActivity({
                        id: item.id,
                        name: 'description',
                        value: e.target.value,
                      })
                    }
                  />

                  {steps.length > 1 && (
                    <ConfirmationModal
                      body="Delete this content. Are you sure?"
                      confirmText="Delete"
                      onConfirm={() => removeKeyActivity({ id: item.id })}
                    >
                      <div className="bmc-page-keycard-step-remove">
                        <i className="icon-Delete" />
                      </div>
                    </ConfirmationModal>
                  )}
                </>
              ) : (
                <>
                  <div
                    className="circle-activity"
                    style={{ backgroundColor: circleColor }}
                  />
                  <div
                    className="line-activity"
                    style={{ backgroundColor: lineColor }}
                  />
                  <div
                    className={`bmc-page-keycard-step-text ${textStyle} custom-font-body`}
                    style={{ color: textColor }}
                  >
                    {item.description}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>

        {editMode && steps.length < KEY_ACTIVITIES_MAX_COUNT && (
          <Button
            title={`${common.create} ${name}`}
            wide
            onClick={addKeyActivity}
            className={
              buttonStyle
                ? `${buttonStyle} custom-font-body`
                : `bmc-button--blue custom-font-body`
            }
            icon="icon-Add"
            style={{ color: buttonColor, background: backgroundButton }}
          />
        )}
      </div>
    </div>
  );
}
