import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProgressBar from '../../../components/ProgressBar';
import Img from '../../../components/Img';
import {
  calculateProgress,
  updateCollectionById,
} from '../../../utils/helpers';

export default function RateIdea({
  idea: {
    id,
    url,
    solution,
    marketSizeTitle,
    marketSize,
    priceTitle,
    price,
    excitementTitle,
    excitement,
  },
}) {
  const {
    ideation: {
      idea: {
        data: { ideas },
      },
    },
  } = useSelector((state) => state);
  const {
    ideation: { updateIdeaData },
  } = useDispatch();

  return (
    <div className="ideation-rateidea">
      <div className="ideation-rateidea-image">
        <Img src={url} aspectRatio={1} />
      </div>

      {solution && <div className="ideation-rateidea-text">{solution}</div>}

      <ProgressBar
        editMode
        title={marketSizeTitle}
        value={marketSize || 1}
        minValue={1}
        maxValue={10}
        progress={calculateProgress(marketSize, 10)}
        className="bmc-progressbar--green margin-top-20"
        onChange={(value) =>
          updateIdeaData({
            ideas: updateCollectionById(ideas, id, {
              marketSize: value,
            }),
          })
        }
      />

      <ProgressBar
        editMode
        title={priceTitle}
        value={price}
        minValue={1}
        maxValue={10}
        progress={calculateProgress(price, 10)}
        className="bmc-progressbar--orange margin-top-20"
        onChange={(value) =>
          updateIdeaData({
            ideas: updateCollectionById(ideas, id, {
              price: value,
            }),
          })
        }
      />

      <ProgressBar
        editMode
        title={excitementTitle}
        value={excitement}
        minValue={1}
        maxValue={10}
        progress={calculateProgress(excitement, 10)}
        className="bmc-progressbar--violet margin-top-20"
        onChange={(value) =>
          updateIdeaData({
            ideas: updateCollectionById(ideas, id, {
              excitement: value,
            }),
          })
        }
      />
    </div>
  );
}
