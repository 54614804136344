import React from 'react';
import styled from 'styled-components';
import SvgIcon from '../../components/SvgIcon';
import { TABS } from '../const.js';

const { get, keep, grow, referral } = TABS;

export const StyledSvgWrapper = styled.path`
  .get {
    fill: ${(props) => props.fill};
  }

  .keep {
    fill: ${(props) => props.fill};
  }

  .grow {
    fill: ${(props) => props.fill};
  }

  .referral {
    stroke: ${(props) => props.stroke};
  }
`;

const getSvgIcon = (type, fill, typeFill) => {
  return (
    (typeFill === 'fill' && (
      <StyledSvgWrapper fill={fill}>
        <SvgIcon name={type} />
      </StyledSvgWrapper>
    )) ||
    (typeFill === 'stroke' && (
      <StyledSvgWrapper stroke={fill}>
        <SvgIcon name={type} />
      </StyledSvgWrapper>
    ))
  );
};

export const getSvgGKGTabs = (index, fill) => {
  return (
    (index === get.index && getSvgIcon(get.svg, fill, 'fill')) ||
    (index === keep.index && getSvgIcon(keep.svg, fill, 'fill')) ||
    (index === grow.index && getSvgIcon(grow.svg, fill, 'fill')) ||
    (index === referral.index && getSvgIcon(referral.svg, fill, 'stroke'))
  );
};
