import React from 'react';
import { useSelector } from 'react-redux';

import Img from '../../main/components/Img';
import { removeNewLines } from '../../main/utils/helpers';
import SvgIcon from '../../main/components/SvgIcon';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';
import { getIndexColor } from '../../main/utils/helpers/getIndexColor';

const Team = ({
  locales: { portfolioWeb },
  colorTemplate,
  mainSvgColor,
  isFourthColorPaletteNumber = false,
}) => {
  const {
    portfolioTeammate: { collection },
    portfolio: { team },
  } = useSelector((state) => state);

  const getAbbreviation = (source) => {
    return source
      .split(' ')
      .map((n) => n[0])
      .join('');
  };

  return (
    <div className="team-colourfulFun-web section-web">
      <div className="svg-funTeamFifth" style={{ fill: mainSvgColor }}>
        <SvgIcon name="funTeamFifth" />
      </div>
      <div className="content">
        <div className="colourful-title title custom-font-title">
          {portfolioWeb.team}
        </div>
        <div className="description custom-font-body"> {team.description}</div>

        <div className="team-colourfulFun-web-cards">
          {collection.map(({ id, name, position, description, image }, i) => {
            return (
              <div className="team-colourfulFun-web-card" key={id}>
                <div className="team-colourfulFun-web-card-left">
                  <div className="relative">
                    <div
                      className="svg-iconTeam"
                      style={{ stroke: colorTemplate[getIndexColor(i)] }}
                    >
                      <SvgIcon name="iconTeam" />
                    </div>
                    <div
                      className="image-upload"
                      style={{
                        backgroundColor: colorTemplate[getIndexColor(i)],
                      }}
                    >
                      {image.cropped ? (
                        <Img src={image.cropped} />
                      ) : (
                        <div
                          className="title-image custom-font-title"
                          style={{
                            color:
                              isFourthColorPaletteNumber &&
                              getColorDependsOnBackground(
                                colorTemplate[getIndexColor(i)],
                              ),
                          }}
                        >
                          {getAbbreviation(name)}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="middle">
                  <div className="position custom-font-body">{position}</div>
                  <div className="name custom-font-title">{name}</div>
                </div>
                <div className="member-description custom-font-body">
                  {description && removeNewLines(description)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Team;
