import React from 'react';
import { useDispatch } from 'react-redux';

import SectionHeader from './SectionHeader';
import BmcCanvas from '../bmc/BmcCanvas';
import Textarea from '../../components/Textarea';

const BMC = ({
  locales,
  data: { description, showStatus },
  disabled = false,
}) => {
  const { portfolio } = locales;
  const {
    portfolio: { updateSectionField, updateSectionFieldReducer },
  } = useDispatch();

  const setShowStatus = (status) => {
    updateSectionFieldReducer({
      sectionName: 'bmc',
      field: 'showStatus',
      value: status,
    });
  };

  return (
    <div className="business-modal-canvas">
      <SectionHeader
        locales={portfolio}
        showStatus={showStatus}
        sectionName={portfolio.bmc.sectionName}
        setShowStatus={setShowStatus}
      />
      {showStatus && (
        <>
          <Textarea
            name="textarea"
            rows={5}
            delay={700}
            value={description}
            onChange={(e) =>
              updateSectionField({
                sectionName: 'bmc',
                field: 'description',
                name: 'bmcText',
                value: e.target.value,
              })
            }
            className="bmc-form-textarea textarea"
            placeholder={portfolio.page.descriptionPlaceholder}
            disabled={disabled}
          />
          <BmcCanvas
            isDark
            locales={locales}
            className="bmc-canvas--scrollable-portfolio-edit"
            isPreview={disabled}
          />
        </>
      )}
    </div>
  );
};

export default BMC;
