import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import IntroductionBoldWeb from '../boldComponents/IntroductionBoldWeb';
import SummaryBoldWeb from '../boldComponents/SummaryBoldWeb';
import TeamBoldWeb from '../boldComponents/TeamBoldWeb';
import Testing from '../mainWebTemplateComponents/Testing';
import CustomerSegmentBoldWeb from '../boldComponents/CustomerSegmentBoldWeb';
import ValuePropositionBoldWeb from '../boldComponents/ValuePropositionBoldWeb';
import GKG from '../mainWebTemplateComponents/GKG';
import ChannelsBoldWeb from '../boldComponents/ChannelsBoldWeb';
import KeyActivities from '../mainWebTemplateComponents/KeyActivities';
import Finance from '../mainWebTemplateComponents/Finance';
import Sustainability from '../mainWebTemplateComponents/Sustainability';
import ContactUsBoldWeb from '../boldComponents/ContactUsBoldWeb';
import { StyledWrapper } from '../../main/utils/helpers';
import {
  BOLD_TEMPLATE_NAME,
  BUTTON_MODAL_STYLE_BOLD,
  COLORS,
  TITLE_MODAL_STYLE_BOLD,
} from '../../main/utils/const';
import Desirability from '../mainWebTemplateComponents/Desirability';
import Feasibility from '../mainWebTemplateComponents/Feasibility';
import Viability from '../mainWebTemplateComponents/Viability';
import BMC from '../mainWebTemplateComponents/BMC';
import CompetitorAnalysis from '../mainWebTemplateComponents/CompetitorAnalysis';

const { thirdGreen, white } = COLORS;

const BoldTemplateWeb = ({ locales, currentSettings, bmcEnabled, testing }) => {
  const [showAll, setShowAll] = useState(false);

  const { sections } = useParams();

  const showSections = useMemo(() => {
    if (!sections) {
      setShowAll(true);
      return [];
    }
    const sectionArray = sections.split('-');
    if (!sectionArray.length) {
      setShowAll(true);
      return [];
    }
    return sectionArray;
  }, [sections]);

  const { contactUs } = useSelector((state) => state.portfolio);

  const colorTemplate = currentSettings?.colorPalette?.split('|');
  const [firstColor, secondColor, thirdColor, fourthColor] = colorTemplate;
  const isSecondTemplate = firstColor === thirdGreen;
  const { shortcuts } = locales.portfolio.export;
  const {
    introduction,
    projectSummary,
    team,
    bmc,
    testing: test,
    desirabilitySection,
    customerSegment,
    valueProposition,
    competitorAnalysis,
    customerRelationships,
    channels,
    feasibility,
    keyActivities,
    viability,
    finance,
    sustainability,
    contactUs: contact,
  } = shortcuts;

  const isShowSections = (sectionName) => {
    return showAll || showSections.includes(sectionName);
  };

  return (
    <StyledWrapper
      customtitlefont={currentSettings?.customTitleFont}
      customtitlecolor={currentSettings?.customTitleColor}
      custombodyfont={currentSettings?.customBodyFont}
      custombodycolor={currentSettings?.customBodyColor}
    >
      {isShowSections(introduction) && (
        <IntroductionBoldWeb
          titleColor={firstColor}
          textColor={secondColor}
          backgroundImage={thirdColor}
          backgroundSection={fourthColor}
        />
      )}
      {isShowSections(projectSummary) && (
        <SummaryBoldWeb
          locales={locales}
          titleColor={firstColor}
          textColor={secondColor}
          colorStyle={firstColor}
          backgroundSection={fourthColor}
          fileTextColor={fourthColor}
        />
      )}
      {isShowSections(team) && (
        <TeamBoldWeb
          locales={locales}
          titleColor={firstColor}
          textColor={secondColor}
          teamNameColor={thirdColor}
          positionColor={firstColor}
          backgroundSection={fourthColor}
          backgroundImage={thirdColor}
        />
      )}
      {bmcEnabled && isShowSections(bmc) && (
        <BMC
          locales={locales}
          iconFillTable={firstColor}
          iconFillSlider={firstColor}
          backgroundIconTable={`${firstColor}80`}
          className="bm-bold-canvas"
          sectionClassName="bmc-canvas-section--bold"
          textStyle="text-card-bold"
          textWithImage="bmc-page-compcard-item-text-template bmc-page-compcard-item-text-template--with-image"
          sliderStyles="sdg-card sdg-card-modal"
          iconTableStyles="sdg-card-bold-icon"
          titleStyle={TITLE_MODAL_STYLE_BOLD}
          titleColor={firstColor}
          sectionHeaderTitleColor={firstColor}
          descriptionColor={secondColor}
          titleBMCSectionColor={isSecondTemplate ? firstColor : thirdColor}
          textColor={secondColor}
          borderSectionColor={thirdColor}
          iconFill={isSecondTemplate ? firstColor : fourthColor}
          backgroundIcon={isSecondTemplate ? fourthColor : firstColor}
          backgroundItems={thirdColor}
          backgroundSection={fourthColor}
          backgroundMainSection={fourthColor}
          backgroundBMC={fourthColor}
          styleColor={firstColor}
          backgroundRow={`${thirdColor}80`}
          backgroundImage={thirdColor}
          titleImageColor={firstColor}
          backgroundButton={firstColor}
          borderCardColor={thirdColor}
          templateName={BOLD_TEMPLATE_NAME}
        />
      )}
      {testing && isShowSections(test) && (
        <Testing
          locales={locales}
          className="testing testing-bold-web"
          testListStyle="tests-list-bold"
          textStyle="text-card-bold"
          titleColor={firstColor}
          sectionHeaderTitleColor={firstColor}
          descriptionColor={secondColor}
          noTestsColor={secondColor}
          textModalColor={secondColor}
          textRowColor={secondColor}
          backgroundSection={{ backgroundColor: fourthColor }}
          borderCardColor={thirdColor}
          testTypeColor={thirdColor}
          inputColor={firstColor}
          buttonColor={fourthColor}
          backgroundButton={firstColor}
          linkColor={firstColor}
          rateColor={firstColor}
          backgroundCard={fourthColor}
          backgroundRow={fourthColor}
          titleStyle={TITLE_MODAL_STYLE_BOLD}
          buttonStyle={BUTTON_MODAL_STYLE_BOLD}
          templateName={BOLD_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(desirabilitySection) && (
        <Desirability
          locales={locales}
          className="desirability-bold-web"
          backgroundSection={firstColor}
          sectionHeaderTitleColor={thirdColor}
          descriptionColor={isSecondTemplate ? fourthColor : thirdColor}
          templateName={BOLD_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(customerSegment) && (
        <CustomerSegmentBoldWeb
          locales={locales}
          background={fourthColor}
          titleColor={firstColor}
          textColor={secondColor}
          backgroundImage={thirdColor}
          segmentListTitleStyle={thirdColor}
        />
      )}
      {bmcEnabled && isShowSections(valueProposition) && (
        <ValuePropositionBoldWeb
          locales={locales}
          titleColor={firstColor}
          textColor={secondColor}
          subtitleColor={thirdColor}
          background={fourthColor}
          backgroundImage={thirdColor}
        />
      )}
      {bmcEnabled && isShowSections(competitorAnalysis) && (
        <CompetitorAnalysis
          locales={locales}
          className="competitor-analysis-bold-web"
          sectionHeaderTitleColor={firstColor}
          descriptionColor={secondColor}
          headTableColor={thirdColor}
          backgroundImage={thirdColor}
          backgroundSection={fourthColor}
          backgroundTable={fourthColor}
          backgroundHeadTable={fourthColor}
          borderTableColor={thirdColor}
          textTableColor={secondColor}
          titleImageColor={firstColor}
          templateName={BOLD_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(customerRelationships) && (
        <GKG
          locales={locales}
          className="customer-relationships-bold-web"
          sectionHeaderTitleColor={firstColor}
          descriptionColor={secondColor}
          textBlockColor={secondColor}
          firstTabColor={firstColor}
          secondTabColor={`${firstColor}80`}
          titleTabsColor={thirdColor}
          borderBlockColor={thirdColor}
          backgroundSection={fourthColor}
          tabColor={secondColor}
          backgroundBlock={fourthColor}
          templateName={BOLD_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(channels) && (
        <ChannelsBoldWeb
          locales={locales}
          titleColor={firstColor}
          textColor={secondColor}
          styleColor={firstColor}
          titleTabsColor={thirdColor}
          backgroundSection={fourthColor}
          backgroundImage={thirdColor}
        />
      )}
      {bmcEnabled && isShowSections(feasibility) && (
        <Feasibility
          locales={locales}
          className="feasibility-bold-web"
          backgroundSection={firstColor}
          sectionHeaderTitleColor={thirdColor}
          descriptionColor={isSecondTemplate ? fourthColor : thirdColor}
          templateName={BOLD_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(keyActivities) && (
        <KeyActivities
          locales={locales}
          className="key-activities-bold-web"
          sectionHeaderTitleColor={firstColor}
          descriptionColor={secondColor}
          titleActivityColor={thirdColor}
          textActivityColor={secondColor}
          circleActivityColor={firstColor}
          backgroundSection={fourthColor}
          borderCardColor={thirdColor}
          titleResourcesColor={thirdColor}
          textResourcesColor={secondColor}
          templateName={BOLD_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(viability) && (
        <Viability
          locales={locales}
          className="viability-bold-web"
          backgroundSection={firstColor}
          sectionHeaderTitleColor={thirdColor}
          descriptionColor={isSecondTemplate ? fourthColor : thirdColor}
          templateName={BOLD_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(finance) && (
        <Finance
          locales={locales}
          className="portfolio-finance-bold portfolio-finance-bold-temp section"
          sectionHeaderTitleColor={firstColor}
          descriptionColor={secondColor}
          titleUnitColor={firstColor}
          textUnitColor={secondColor}
          titleForecastColor={firstColor}
          secondTitleForecast={thirdColor}
          textForecastColor={secondColor}
          firstChartColor={firstColor}
          secondChartColor={thirdColor}
          titleChartColor={firstColor}
          textChartColor={secondColor}
          borderCardColor={thirdColor}
          borderVerticalTableColor={thirdColor}
          borderVerticalTableUnitColor={thirdColor}
          backgroundSection={fourthColor}
          totalColor={thirdColor}
          secondTitleUnitColor={thirdColor}
          tableTitleUnitColor={thirdColor}
          tabColor={firstColor}
          activeTabColor={firstColor}
          templateName={BOLD_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(sustainability) && (
        <Sustainability
          locales={locales}
          className="sustainability-bold-web"
          cardClassName="bmc-page-suscard-bold bmc-page-suscard--section"
          sliderStyles="sdg-card-bold"
          iconTableStyles="sdg-card-bold-icon"
          sectionHeaderTitleColor={firstColor}
          descriptionColor={secondColor}
          backgroundSection={fourthColor}
          backgroundSliderColor={fourthColor}
          titleTableColor={thirdColor}
          textTableColor={secondColor}
          textSliderColor={secondColor}
          arrowColor={secondColor}
          iconFill={firstColor}
          iconFillTable={firstColor}
          backgroundIcon={`${firstColor}80`}
          borderTableColor={thirdColor}
          borderCardColor={thirdColor}
          templateName={BOLD_TEMPLATE_NAME}
        />
      )}
      {isShowSections(contact) && (
        <ContactUsBoldWeb
          data={contactUs}
          locales={locales}
          titleColor={isSecondTemplate ? thirdColor : firstColor}
          textColor={isSecondTemplate ? white : secondColor}
          backgroundSection={isSecondTemplate ? firstColor : fourthColor}
          backgroundButton={isSecondTemplate ? thirdColor : firstColor}
          textButtonColor={fourthColor}
        />
      )}
    </StyledWrapper>
  );
};

export default BoldTemplateWeb;
