import React, { useEffect } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useParams } from 'react-router';

import Intro from './Intro';
import GetInspired from './GetInspired';
import Problems from './Problems';
import Solutions from './Solutions';
import SelectThreeIdeas from './SelectThreeIdeas';
import RateYourIdeas from './RateYourIdeas';
import PickIdea from './PickIdea';
import Summary from './Summary';
import Section from '../../components/Section';
import * as AppRoute from '../../utils/appRoutes';
import { select } from '../../rematcher/store';

const components = [
  Intro,
  GetInspired,
  Problems,
  Solutions,
  SelectThreeIdeas,
  RateYourIdeas,
  PickIdea,
  Summary,
];

export const prepareComponents = (bmcId, ideaId, menu, routeHelper) => {
  const sections = menu.map((e) => e.sectionName);

  return components.map((component, i) => ({
    component,
    url: routeHelper(bmcId, ideaId, sections[i]),
  }));
};

function IdeationItem({
  locales,
  locales: {
    ideation: { menu, sectionName },
  },
  progress,
}) {
  const {
    ideation: { menuRules },
    bmc: {
      current: { id: bmcId },
    },
  } = useSelector((state) => state);

  const {
    ideation: { fetchIdea },
    bmc: { fetchCurrentBmc },
  } = useDispatch();

  const { bmcId: bmcIdFromParams, id } = useParams();
  const preparedComponents = prepareComponents(
    bmcId,
    id,
    menu,
    AppRoute.ideation,
  );

  function getData() {
    if (bmcId) fetchIdea(id);
    else fetchCurrentBmc(bmcIdFromParams);
  }

  useEffect(() => {
    getData();
  }, [bmcId]);

  return (
    <Section
      components={preparedComponents}
      sectionName={sectionName}
      menuItems={menu}
      progress={progress}
      locales={locales}
      initComponentIndex={0}
      rules={menuRules}
      finalDestination={AppRoute.ideation(bmcId)}
      onComponentChange={getData}
    />
  );
}

const mapState = select((models) => ({
  progress: models.progress.ideationProgress,
}));

export default connect(mapState, null)(IdeationItem);
