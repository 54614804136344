import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BmcCanvasSection from './BmcCanvasSection';
import CardModal from '../CardModal';
import KeyPartnersCard from '../../keyResources/Card';
import SvgIcon from '../../../components/SvgIcon';
import { collectionEmpty } from '../../../utils/helpers';

import { KEY_PARTNERS_MAX_COUNT } from '../../../utils/const';

export default function KeyPartners({
  locales,
  locales: { canvas },
  isDark,
  isPreview,
  isProgressBar,
  backgroundIcon,
  backgroundItems,
  backgroundSection,
  borderSectionColor,
  titleBMCSectionColor,
  textColor,
  iconFill,
  iconFillClose,
  templateName,
  sectionClassName,
  backgroundBMC,
  firstSvgColor,
  secondSvgColor,
  titleStyle,
  buttonStyle,
  iconStyle,
  styleColor,
  textStyle,
  titleColor,
  buttonColor,
  backgroundButton,
  isColourfulfunTemplate = false,
  disabled = false,
}) {
  const {
    keyPartner,
    bmc: {
      current: { id },
    },
  } = useSelector((state) => state);

  const {
    keyPartner: {
      fetchKeyPartners,
      addKeyPartner,
      updateKeyPartner,
      removeKeyPartner,
    },
  } = useDispatch();

  const [showKeyPartnersModal, setShowKeyPartnersModal] = useState(false);

  return (
    <>
      {showKeyPartnersModal && (
        <CardModal
          onBlackscreenClick={() => setShowKeyPartnersModal(false)}
          title={canvas.keyPartners}
          icon={!templateName ? 'icon-key-partners' : <SvgIcon name="iconKP" />}
          to={`/bmcs/${id}/key_partners/intro`}
          locales={locales}
          className="bmc-modal--orange custom-font-body"
          onOpen={() => fetchKeyPartners(id)}
          isOpen={showKeyPartnersModal}
          isPreview={isPreview}
          titleStyle={titleStyle}
          buttonStyle={buttonStyle}
          firstSvgColor={firstSvgColor}
          secondSvgColor={secondSvgColor}
          iconFill={iconFill}
          iconStyle={iconStyle}
          backgroundIcon={backgroundIcon}
          backgroundBMC={backgroundBMC}
          titleColor={titleColor}
          buttonColor={buttonColor}
          backgroundButton={backgroundButton}
          iconFillClose={iconFillClose}
          isColourfulfunTemplate={isColourfulfunTemplate}
          disabled={disabled}
        >
          <KeyPartnersCard
            locales={locales}
            editMode={!isPreview}
            wBlur={false}
            isPopUp
            addHandler={addKeyPartner}
            updateHandler={updateKeyPartner}
            removeHandler={removeKeyPartner}
            name={canvas.keyPartners}
            maxCount={KEY_PARTNERS_MAX_COUNT}
            card={{
              ...locales.keyActivity.intro.card,
              list: keyPartner.collection,
              title: false,
              subtitle: false,
            }}
            circleColor={styleColor}
            textStyle={textStyle}
            buttonStyle={buttonStyle}
            background={backgroundBMC}
            buttonColor={buttonColor}
            backgroundButton={backgroundButton}
            textColor={textColor}
          />
        </CardModal>
      )}

      <BmcCanvasSection
        className={`bmc-canvas-section--orange bmc-canvas-section-1 ${sectionClassName}`}
        title={canvas.keyPartners}
        dark={isDark}
        showButton={!isPreview && collectionEmpty(keyPartner.collection)}
        to={`/bmcs/${id}/key_partners/intro`}
        locales={locales}
        icon={!templateName ? 'icon-key-partners' : <SvgIcon name="iconKP" />}
        isPreview={isPreview}
        isProgressBar={isProgressBar}
        uploading={keyPartner.uploading}
        onClick={() => setShowKeyPartnersModal(true)}
        backgroundIcon={backgroundIcon}
        backgroundSection={backgroundSection}
        borderSectionColor={borderSectionColor}
        titleBMCSectionColor={titleBMCSectionColor}
        iconFill={iconFill}
        iconStyle={iconStyle}
        templateName={templateName}
      >
        {keyPartner.collection.map(
          (item) =>
            item.description && (
              <div
                key={item.id}
                className="bmc-canvas-section-items-item custom-font-body"
                style={{ backgroundColor: backgroundItems, color: textColor }}
                onClick={() => setShowKeyPartnersModal(true)}
              >
                {item.description}
              </div>
            ),
        )}
      </BmcCanvasSection>
    </>
  );
}
