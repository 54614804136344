import React, { useEffect, useMemo } from 'react';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { useDispatch, useSelector } from 'react-redux';
import humps from 'humps';
import { lowerFirst, upperFirst, camelCase, keys } from 'lodash';

import portfolioMapping from './portfolioMapping';

const getModelFromResponse = (response) =>
  lowerFirst(camelCase(response.model));
const getActionByResponseAndModel = (response, model) =>
  `${response.action + upperFirst(model)}Reducer`;
const getAttributesFromResponse = (response) =>
  humps.camelizeKeys(response.attributes);

const defaultHandler = (
  dispatcher,
  model,
  action,
  attributes,
  sessionId,
  senderSessionId,
) => {
  if (action !== 'update' && sessionId !== senderSessionId) {
    dispatcher[model][action]({ ...attributes, isAnyCable: true });
  }
};

const portfolioHandler = (
  dispatcher,
  attributes,
  sessionId,
  senderSessionId,
) => {
  const key = keys(attributes).find((e) => e !== 'id');
  const portfolioAttributes = portfolioMapping(attributes)[key];

  if (sessionId !== senderSessionId && portfolioAttributes) {
    dispatcher.portfolio.updateSectionFieldReducer(portfolioAttributes);
  }
};

export default function ActionCableHandler() {
  const {
    bmc: {
      current: { id },
    },
    user: {
      current: { sessionId },
    },
  } = useSelector((state) => state);

  const dispatcher = useDispatch();

  const handleReceived = (response) => {
    const { senderSessionId } = humps.camelizeKeys(response);
    const model = getModelFromResponse(response);
    const action = getActionByResponseAndModel(response, model);
    const attributes = getAttributesFromResponse(response);

    if (dispatcher[model] && dispatcher[model][action]) {
      defaultHandler(
        dispatcher,
        model,
        action,
        attributes,
        sessionId,
        senderSessionId,
      );
    } else if (model === 'portfolio') {
      portfolioHandler(dispatcher, attributes, sessionId, senderSessionId);
    }
  };

  useEffect(() => {
    dispatcher.user.fetchCurrentUser();
  }, []);

  const data = useMemo(() => {
    if (id && sessionId) {
      return (
        <ActionCableConsumer
          channel={humps.decamelizeKeys({ channel: 'BmcChannel', bmcId: id })}
          onReceived={handleReceived}
        />
      );
    }
    return null;
  }, [id, sessionId]);

  return data;
}
