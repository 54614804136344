import calculateVPProgress from './valueProposition';
import calculateCSProgress from './customerSegment';
import calculateChannelProgress from './channel';
import calculateGkgProgress from './gkg';
import {
  calculateKeyActivityProgress,
  calculateKeyPartnerProgress,
  calculateKeyResourceProgress,
} from './keyThings';
import calculateCostProgress from './cost';
import calculateSustainabilityProgress from './sustainability';
import calculateRevenueProgress from './revenue';
import calculateIdeationProgress from './ideation';
import calculateTestingProgress from './testing';

const progress = {
  state: null,
  selectors: (_, createSelector) => ({
    valuePropositionProgress() {
      return createSelector((state) => state, calculateVPProgress);
    },
    customerSegmentProgress() {
      return createSelector((state) => state, calculateCSProgress);
    },
    channelProgress() {
      return createSelector((state) => state, calculateChannelProgress);
    },
    gkgProgress() {
      return createSelector((state) => state, calculateGkgProgress);
    },
    keyActivityProgress() {
      return createSelector((state) => state, calculateKeyActivityProgress);
    },
    keyPartnerProgress() {
      return createSelector((state) => state, calculateKeyPartnerProgress);
    },
    keyResourceProgress() {
      return createSelector((state) => state, calculateKeyResourceProgress);
    },
    costProgress() {
      return createSelector((state) => state, calculateCostProgress);
    },
    sustainabilityProgress() {
      return createSelector((state) => state, calculateSustainabilityProgress);
    },
    revenueProgress() {
      return createSelector((state) => state, calculateRevenueProgress);
    },
    ideationProgress() {
      return createSelector((state) => state, calculateIdeationProgress);
    },
    testingProgress() {
      return createSelector((state) => state, calculateTestingProgress);
    },
  }),
};

export default progress;
