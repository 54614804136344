import * as Route from '../utils/serverRoutes';
import {
  transformResponseCollection,
  updateCollectionById,
  payloadToData,
} from '../utils/helpers';
import makeRequest from '../utils/makeRequest';

const keyPartner = {
  state: {
    collection: [],
    uploading: false,
  },
  reducers: {
    fetchKeyPartnersReducer(state, { collection }) {
      return { ...state, collection };
    },
    addKeyPartnerReducer(state, payload) {
      return { ...state, collection: [...state.collection, payload] };
    },
    updateKeyPartnerReducer(state, payload) {
      return {
        ...state,
        collection: updateCollectionById(state.collection, payload.id, payload),
      };
    },
    removeKeyPartnerReducer(state, { id }) {
      const collection = state.collection.filter((e) => e.id !== id);

      return { ...state, collection };
    },
    updateAttributeReducer(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    async fetchKeyPartners(bmcId) {
      dispatch.keyPartner.updateAttributeReducer({ uploading: true });

      makeRequest({
        dispatch,
        url: Route.keyPartners(bmcId),
        success: (response) => {
          dispatch.keyPartner.fetchKeyPartnersReducer({
            collection: transformResponseCollection(response.data.data),
          });
        },
        finish: () => {
          dispatch.keyPartner.updateAttributeReducer({ uploading: false });
        },
      });
    },
    async addKeyPartner(_, state) {
      makeRequest({
        dispatch,
        method: 'post',
        url: Route.keyPartners(state.bmc.current.id),
      });
    },
    async updateKeyPartner(payload, state) {
      const data = payloadToData(payload);
      dispatch.keyPartner.updateKeyPartnerReducer(data);

      makeRequest({
        dispatch,
        method: 'put',
        url: Route.keyPartner(state.bmc.current.id, payload.id),
        data: { keyThing: data },
      });
    },
    async removeKeyPartner(payload, state) {
      makeRequest({
        dispatch,
        method: 'delete',
        url: Route.keyPartner(state.bmc.current.id, payload.id),
      });
    },
  }),
};

export default keyPartner;
