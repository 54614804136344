import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import Avatar from '../Avatar';
import ConfirmationModal from '../../../main/components/ConfirmationModal';

export { default as CellEmail } from './components/CellEmail';
export { default as CellFullName } from './components/CellFullName';
export { default as CellFullNameWithEmail } from './components/CellFullNameWithEmail';

// eslint-disable-next-line no-unused-vars
const UserList = ({
  users = [],
  onDelete,
  columns,
  getAvatarUrl = () => {},
}) => {
  return (
    <div className="user-list">
      {users.map((user, index) => (
        <Row
          gutter={[10, 24]}
          justify="space-between"
          align="middle"
          className="user-list-row"
          key={index}
        >
          <Col span={4}>
            <Avatar
              alt={user.fullName}
              fullName={user.fullName}
              url={getAvatarUrl(user)}
            />
          </Col>
          <Col span={16}>
            <Row gutter={10} align="middle">
              {columns.map((column, key) => {
                if (typeof column.render === 'function') {
                  return <Col key={key}>{column.render(user)}</Col>;
                }
                return user[column.dataIndex];
              })}
            </Row>
          </Col>
          <Col span={4}>
            <ConfirmationModal
              body="Are you sure?"
              confirmText="Delete"
              onConfirm={() => onDelete(user.id)}
            >
              <div className="user-list-action-delete">Delete</div>
            </ConfirmationModal>
          </Col>
        </Row>
      ))}
    </div>
  );
};

UserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
    }),
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataIndex: PropTypes.string.isRequired,
      render: PropTypes.func, // (user, index) => funcBody
    }),
  ),
  // eslint-disable-next-line react/no-unused-prop-types
  avatarVisible: PropTypes.bool,
  getAvatarUrl: PropTypes.func, // (user, index) => return avatar imageUrl
  onDelete: PropTypes.func,
};
export default UserList;
