import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import Section from './Section';
import Button from '../../components/Button';

export default function Email({ locales, locales: { account } }) {
  const history = useHistory();
  const {
    user: { updateUserEmail },
  } = useDispatch();
  const [email, setEmail] = useState('');
  const [emailConfirmation, setEmailConfirmation] = useState('');

  const updateEmail = async () => {
    updateUserEmail({
      email,
      emailConfirmation,
      after: [() => history.goBack()],
    });
  };

  return (
    <Section menuItems={account.menu} locales={locales}>
      <div className="bmc-account">
        <Button
          title={account.back}
          className="bmc-button--with-icon"
          icon="icon-Back"
          onClick={() => history.push('/account')}
        />

        <div className="bmc-form-big-title margin-top-20">
          {account.title[3]}
        </div>

        <div className="bmc-account-row margin-top-20">
          <div className="bmc-form-fieldset bmc-account-email-field">
            <label htmlFor="email" className="bmc-form-label">
              {account.newEmail}
            </label>
            <input
              id="email"
              className="bmc-form-input"
              value={email}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="bmc-form-fieldset bmc-account-email-field bmc-account-email-field--confirm margin-top-20">
          <label htmlFor="emailConfirmation" className="bmc-form-label">
            {account.emailConfirmation}
          </label>
          <input
            id="emailConfirmation"
            className="bmc-form-input"
            value={emailConfirmation}
            type="email"
            onChange={(e) => setEmailConfirmation(e.target.value)}
          />
        </div>

        <div className="margin-top-20">
          <Button
            title={account.updateEmail}
            className="bmc-button--primary"
            onClick={updateEmail}
          />
        </div>
      </div>
    </Section>
  );
}
