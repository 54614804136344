import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Tabs } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import Container from '../../../components/Container';
import Header from '../../../components/Header';
import ClassLists from '../../Lists/ClassLists';
import ProjectLists from '../../Lists/ProjectLists';
import StudentLists from '../../Lists/StudentLists';
import BlockList from '../../../components/BlockList';
import getTodayDateAsString from '../../../utils/date';

const { TabPane } = Tabs;

export default function EducatorDashboard({ locales }) {
  const history = useHistory();
  const { userId } = useParams();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const {
    blocks: { collections },
    activeUser: { data, loading },
    user,
    user: { current },
  } = useSelector((state) => state);

  const [currentEstablishment, setCurrentEstablishment] = useState(null);
  const establishments = data?.attributes?.establishments;
  const establishment = data?.attributes?.establishment;
  const role = data?.attributes?.role === 'admin' ? 'Admin' : 'Educator';

  const {
    blocks: { fetchBlocks },
    activeUser: { fetchUserById },
  } = useDispatch();

  useEffect(() => {
    fetchUserById({ userId });
    if (establishment) {
      fetchBlocks({
        by: 'establishments',
        byId: establishment.id,
        pageName: 'lecturer',
      });

      setCurrentEstablishment(establishment);
    }
  }, [userId, establishment?.id]);

  useEffect(() => {
    if (data) {
      const resultBreadcrumbs = [];
      if (current?.role === 'super_admin')
        resultBreadcrumbs.push({
          title: 'Super Admin',
          to: '/admin',
        });

      if (current?.role === 'admin' || current?.role === 'super_admin')
        resultBreadcrumbs.push({
          title: `Establishment “${currentEstablishment?.name.trim()}”`,
          to: `/admin/establishment/${currentEstablishment?.id}`,
        });

      resultBreadcrumbs.push({
        title: `${role} “${data?.attributes?.firstName} ${data?.attributes?.surname}”`,
        to: `/admin/lecturer/${data?.id}`,
      });

      setBreadcrumbs(resultBreadcrumbs);
    }
  }, [data, current, currentEstablishment]);

  const downloadButtonParams = {
    href: `/lecturers/students_download_report/${userId}?format=xlsx`,
    fileName: `${data?.firstName}_${
      data?.surname
    }_students_${getTodayDateAsString()}.xlsx`,
  };

  const handleExpandDropdownClick = (chosenEstablishment) => {
    fetchBlocks({
      by: 'establishments',
      byId: chosenEstablishment.id,
      pageName: 'lecturer',
    });
    setCurrentEstablishment(chosenEstablishment);
  };

  if (loading || !data) return <h1>Loading....</h1>;

  return (
    <Container
      locales={locales}
      title={user?.current?.name ? `${user?.current?.name} Admin Panel` : ' '}
    >
      <Header
        locales={locales}
        breadcrumbs={breadcrumbs}
        header={`${data?.attributes.firstName} ${data?.attributes.surname} Dashboard`}
        downloadReportButton
        downloadButtonParams={downloadButtonParams}
        expandDropdownVisible={establishments?.length > 1}
        expandDropdownItems={establishments?.map((item) => {
          return { ...item, expandDropdownActive: false };
        })}
        expandDropdownClick={handleExpandDropdownClick}
      />
      <BlockList list={collections} />
      <br />
      <br />
      <Tabs defaultActiveKey="2" className="admin-tabs">
        <TabPane key="classes" tab="Class" className="admin-tab-pane">
          <ClassLists
            locales={locales}
            byId={userId}
            establishmentId={currentEstablishment?.id}
            rowClick={(record) => {
              history.push(`/admin/classroom/${record.id}`);
            }}
            rowClickable
          />
        </TabPane>
        <TabPane key="project" tab="Project" className="admin-tab-pane">
          <ProjectLists
            byId={userId}
            by="users"
            establishmentId={currentEstablishment?.id}
            rowClick={(record) =>
              window.open(`/admin/bmc/${record.id}`, '_blank')
            }
            rowClickable
          />
        </TabPane>
        <TabPane key="student" tab="Student" className="admin-tab-pane">
          <StudentLists
            byId={userId}
            establishmentId={currentEstablishment?.id}
            expandRowClick={(record) => {
              history.push(`/admin/bmc/${record.id}`);
            }}
            locales={locales}
          />
        </TabPane>
      </Tabs>
    </Container>
  );
}
