import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BmcCanvasSection from './BmcCanvasSection';
import CardModal from '../CardModal';
import CostCardTable from '../../cost/CardTable';
import SvgIcon from '../../../components/SvgIcon';
import { collectionEmpty, renderMoney } from '../../../utils/helpers';

import {
  COSTS_MAX_COUNT,
  PRODUCTS_MAX_COUNT,
  PRODUCT_CARD_TABLE_COLLECTION_EDIT_SETTINGS,
  COST_CARD_TABLE_COLLECTION_EDIT_SETTINGS,
} from '../../../utils/const';

export default function Costs({
  locales,
  locales: { canvas },
  isDark,
  isPreview,
  isProgressBar,
  backgroundIcon,
  backgroundItems,
  backgroundSection,
  borderSectionColor,
  titleBMCSectionColor,
  textColor,
  templateName,
  sectionClassName,
  iconFill,
  iconFillClose,
  annualFixed,
  annualVariable,
  firstSvgColor,
  secondSvgColor,
  titleStyle,
  buttonStyle,
  iconStyle,
  textStyle,
  backgroundBMC,
  backgroundCard,
  titleColor,
  buttonColor,
  backgroundButton,
  isColourfulfunTemplate = false,
  disabled = false,
}) {
  const costFields = ['name', 'amount', 'period'];
  const productFields = ['name', 'costPrice', 'endUserPrice', 'unit'];

  const {
    cost,
    product,
    bmc: {
      current: { id },
    },
  } = useSelector((state) => state);

  const {
    cost: { fetchCosts, addCost, updateCost, removeCost },
    product: { fetchProducts, addProduct, updateProduct, removeProduct },
  } = useDispatch();

  const [showCostModal, setShowCostModal] = useState(false);

  return (
    <>
      {showCostModal && (
        <CardModal
          onBlackscreenClick={() => setShowCostModal(false)}
          title={canvas.cost}
          icon={!templateName ? 'icon-cost' : <SvgIcon name="iconCost" />}
          to={`/bmcs/${id}/cost/intro`}
          locales={locales}
          className="bmc-modal--violet bmc-modal--gray-background custom-font-body"
          onOpen={() => {
            fetchCosts(id);
            fetchProducts(id);
          }}
          isOpen={showCostModal}
          isPreview={isPreview}
          titleStyle={titleStyle}
          buttonStyle={buttonStyle}
          firstSvgColor={firstSvgColor}
          secondSvgColor={secondSvgColor}
          iconFill={iconFill}
          iconStyle={iconStyle}
          backgroundIcon={backgroundIcon}
          backgroundBMC={backgroundBMC}
          titleColor={titleColor}
          buttonColor={buttonColor}
          backgroundButton={backgroundButton}
          iconFillClose={iconFillClose}
          isColourfulfunTemplate={isColourfulfunTemplate}
          disabled={disabled}
        >
          <CostCardTable
            title={locales.cost.intro.card.fixedCost}
            className="margin-bottom-20 bmc-page-fincard-content--edit-mode  custom-font-body"
            titles={[
              locales.cost.intro.card.costName,
              locales.cost.intro.card.fixedCost,
              locales.cost.intro.card.period,
            ]}
            collection={cost.collection}
            editMode={!isPreview}
            collectionEditSettings={COST_CARD_TABLE_COLLECTION_EDIT_SETTINGS}
            addNewLabel={locales.cost.intro.card.addNewFixedCost}
            addHandler={addCost}
            updateHandler={updateCost}
            removeHandler={removeCost}
            maxCount={COSTS_MAX_COUNT}
            titleStyle={titleStyle}
            buttonStyle={buttonStyle}
            textStyle={textStyle}
            backgroundCard={backgroundCard}
            textColor={textColor}
            titleColor={titleColor}
            buttonColor={buttonColor}
            backgroundButton={backgroundButton}
          />

          <CostCardTable
            title={locales.cost.intro.card.costPerUnit}
            className="bmc-page-fincard-content--edit-mode"
            titles={[
              locales.cost.intro.card.productName,
              locales.cost.intro.card.unitCost,
              locales.cost.intro.card.unit,
            ]}
            collection={product.collection}
            editMode={!isPreview}
            collectionEditSettings={PRODUCT_CARD_TABLE_COLLECTION_EDIT_SETTINGS}
            addNewLabel={locales.cost.intro.card.addNewCostPerUnit}
            addHandler={addProduct}
            updateHandler={updateProduct}
            removeHandler={removeProduct}
            maxCount={PRODUCTS_MAX_COUNT}
            titleStyle={titleStyle}
            buttonStyle={buttonStyle}
            textStyle={textStyle}
            backgroundCard={backgroundCard}
            textColor={textColor}
            titleColor={titleColor}
            buttonColor={buttonColor}
            backgroundButton={backgroundButton}
          />
        </CardModal>
      )}

      <BmcCanvasSection
        className={`bmc-canvas-section--wide bmc-canvas-section--violet ${sectionClassName}`}
        title={canvas.cost}
        dark={isDark}
        showButton={
          !isPreview &&
          collectionEmpty(cost.collection, costFields) &&
          collectionEmpty(product.collection, productFields)
        }
        to={`/bmcs/${id}/cost/intro`}
        locales={locales}
        icon={!templateName ? 'icon-cost' : <SvgIcon name="iconCost" />}
        isPreview={isPreview}
        isProgressBar={isProgressBar}
        uploading={cost.uploading || product.uploading}
        onClick={() => setShowCostModal(true)}
        backgroundIcon={backgroundIcon}
        backgroundSection={backgroundSection}
        borderSectionColor={borderSectionColor}
        titleBMCSectionColor={titleBMCSectionColor}
        iconFill={iconFill}
        iconStyle={iconStyle}
        templateName={templateName}
      >
        {(!collectionEmpty(cost.collection, costFields) ||
          !collectionEmpty(product.collection, productFields)) && (
          <>
            <div
              className="bmc-canvas-section-items-item custom-font-body"
              style={{ backgroundColor: backgroundItems, color: textColor }}
              onClick={() => setShowCostModal(true)}
            >
              <div
                className="bmc-canvas-section-items-item-title  custom-font-body"
                style={{ color: textColor }}
              >
                {canvas.annualFixed}
              </div>
              {renderMoney(annualFixed)}
            </div>

            <div
              className="bmc-canvas-section-items-item custom-font-body"
              style={{ backgroundColor: backgroundItems, color: textColor }}
              onClick={() => setShowCostModal(true)}
            >
              <div
                className="bmc-canvas-section-items-item-title custom-font-body"
                style={{ color: textColor }}
              >
                {canvas.annualVariable}
              </div>
              {renderMoney(annualVariable)}
            </div>
          </>
        )}
      </BmcCanvasSection>
    </>
  );
}
