import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { concat, fill, slice } from 'lodash';

import ImageUploader from '../../../components/ImageUploader';
import Img from '../../../components/Img';
import {
  IDEATION_SELECTED_IMAGES_COUNT,
  IDEATION_IMAGES_PER_PAGE,
} from '../../../utils/const';
import { defaultData } from '../../../models/ideation';
import ConfirmationModal from '../../../components/ConfirmationModal';
import Arrows from '../../../components/Arrows';

export default function ImageSelector({ locales, className }) {
  const {
    yourChoices,
    uploadImage,
    uploadingImage,
  } = locales.ideation.getInspired.imageSelector;

  const { areYouSure, deleteText } = locales.common;

  const {
    ideation: {
      images,
      idea: {
        data: { ideas },
      },
      isUploadingImages,
      imageSelectorCurrentPage,
    },
  } = useSelector((state) => state);

  const {
    ideation: { updateIdeaData, addImage, removeImage, updateAttributeReducer },
  } = useDispatch();

  const pagesCount = Math.ceil(images.length / IDEATION_IMAGES_PER_PAGE);
  const currentImages = slice(
    images,
    (imageSelectorCurrentPage - 1) * IDEATION_IMAGES_PER_PAGE,
    imageSelectorCurrentPage * IDEATION_IMAGES_PER_PAGE,
  );

  const sixImages = useMemo(() => {
    return concat(
      ideas,
      fill(Array(IDEATION_SELECTED_IMAGES_COUNT - ideas.length), false),
    );
  }, [ideas]);

  const imageSelected = (imageId) => ideas.find((e) => e.id === imageId);

  const selectImage = (imageId) => {
    if (ideas.length < IDEATION_SELECTED_IMAGES_COUNT) {
      const image = images.find((e) => e.id === imageId);
      updateIdeaData({ ideas: [...ideas, image] });
    }
  };

  const deselectImage = (imageId) => {
    if (ideas.length) {
      const newIdeas = ideas.filter((e) => e.id !== imageId);
      updateIdeaData({ ...defaultData, ideas: newIdeas });
    }
  };

  const toggleImage = (imageId) => {
    if (imageSelected(imageId)) {
      deselectImage(imageId);
    } else {
      selectImage(imageId);
    }
  };

  const prevPage = () =>
    imageSelectorCurrentPage !== 1 &&
    updateAttributeReducer({
      imageSelectorCurrentPage: imageSelectorCurrentPage - 1,
    });

  const nextPage = () =>
    imageSelectorCurrentPage !== pagesCount &&
    updateAttributeReducer({
      imageSelectorCurrentPage: imageSelectorCurrentPage + 1,
    });

  return (
    <div className={classNames('bmc-imgsel', className)}>
      <div className="bmc-imgsel-header">
        <span className="bmc-imgsel-text">{yourChoices}</span>

        <div className="bmc-imgsel-images">
          {sixImages.map((image, index) => (
            <div
              className={classNames('bmc-imgsel-images-item', {
                'bmc-imgsel-images-item--empty': !image,
              })}
              key={index}
              onClick={() => deselectImage(image.id)}
            >
              {image && <Img src={image.url} />}
            </div>
          ))}
        </div>

        <ImageUploader
          title={isUploadingImages ? uploadingImage : uploadImage}
          icon="icon-Export"
          className="bmc-button bmc-button--with-icon bmc-imgsel-upload-button"
          onChange={([file]) => {
            addImage(file);
          }}
        />
      </div>

      <div className="bmc-imgsel-list">
        {currentImages.map((image, index) => (
          <div
            className={classNames('bmc-imgsel-list-item', {
              'bmc-imgsel-list-item--selected': imageSelected(image.id),
            })}
            key={index}
            onClick={() => toggleImage(image.id)}
          >
            <div className="bmc-imgsel-list-item-img">
              <Img src={image.url} />
            </div>
            <div className="bmc-imgsel-list-item-selector" />

            {image.userId && (
              <ConfirmationModal
                body={areYouSure}
                confirmText={deleteText}
                onConfirm={() => removeImage(image.id)}
              >
                <div className="bmc-imgsel-list-item-remove">
                  <i className="icon-Cancel" />
                </div>
              </ConfirmationModal>
            )}
          </div>
        ))}
      </div>

      <div className="bmc-imgsel-footer">
        <Arrows
          onLeftClick={prevPage}
          onRightClick={nextPage}
          leftActive={imageSelectorCurrentPage !== 1}
          rightActive={imageSelectorCurrentPage !== pagesCount}
          className="bmc-imgsel-arrows"
        />

        <div className="bmc-imgsel-dots">
          {fill(Array(pagesCount), true).map((_, index) => (
            <div
              className={classNames('bmc-imgsel-dots-dot', {
                'bmc-imgsel-dots-dot--active':
                  index + 1 === imageSelectorCurrentPage,
              })}
              key={index}
              onClick={() =>
                updateAttributeReducer({ imageSelectorCurrentPage: index + 1 })
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
}
