import { COLORS } from '../const';
import { getCurrentSubtitleColor } from './getCurrentSubtitleColor';

const { violet } = COLORS;

export const getActiveTabColor = (
  activeTab,
  tab,
  colorPaletteNumber,
  styleColor,
) => {
  return {
    color:
      activeTab === tab &&
      getCurrentSubtitleColor(colorPaletteNumber, styleColor, violet),
  };
};
