import React from 'react';
import TestStatusSelector from '../TestStatusSelector';
import TestStatus from '../TestStatus';
import SelectBmcBlockTested from '../../Containers/Select/SelectBmcBlockTested';
import { TEST_STATUS_BLOCKS } from '../../../../utils/const';
import bmcBlockTestedOptions from '../../../../utils/options/bmcBlockTestedOptions';

const TestStatusBlock = ({
  isEditable,
  blocks,
  status,
  onBlocksUpdate,
  onStatusUpdate,
  textStyle,
  textColor,
}) => {
  return (
    <div className="right">
      <div className="block custom-font-body">
        {isEditable ? (
          <SelectBmcBlockTested
            value={bmcBlockTestedOptions.filter((option) =>
              blocks.includes(option.value),
            )}
            onChange={onBlocksUpdate}
            textColor={textColor}
            textStyle={textStyle}
          />
        ) : (
          <>
            <div
              className={`title ${textStyle} custom-font-body`}
              style={{ color: textColor }}
            >
              BMC Blocks Tested
            </div>
            <div className="content custom-font-title">
              {TEST_STATUS_BLOCKS.filter((e) => blocks.includes(e)).map((e) => (
                <div
                  className={`bmc-blocks__block bmc-blocks__block-${e} custom-font-title`}
                >
                  {e}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <div className="block">
        <div className="status test-card-section">
          {isEditable ? (
            <>
              <TestStatusSelector
                status={status}
                onStatusUpdate={onStatusUpdate}
                textStyle={textStyle}
                textColor={textColor}
              />
              <TestStatus status={status} textStyle={textStyle} />
            </>
          ) : (
            <>
              <div
                className={`header-status ${textStyle} custom-font-body`}
                style={{ color: textColor }}
              >
                Status
              </div>
              <TestStatus status={status} textStyle={textStyle} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestStatusBlock;
