import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BmcCanvasSection from './BmcCanvasSection';
import CardModal from '../CardModal';
import RevenueModelCard from '../../revenue/RevenueModelCard';
import CostCardTable from '../../cost/CardTable';
import SvgIcon from '../../../components/SvgIcon';
import {
  collectionEmpty,
  itemHasContent,
  renderMoney,
} from '../../../utils/helpers';
import {
  PRODUCTS_MAX_COUNT,
  SECOND_PRODUCT_CARD_TABLE_COLLECTION_EDIT_SETTINGS,
} from '../../../utils/const';

export default function Revenue({
  locales,
  locales: { canvas },
  isDark,
  isPreview,
  isProgressBar,
  backgroundIcon,
  backgroundItems,
  backgroundSection,
  borderSectionColor,
  titleBMCSectionColor,
  textColor,
  iconFill,
  iconFillClose,
  templateName,
  sectionClassName,
  annualUnitSold,
  annualRevenue,
  firstSvgColor,
  secondSvgColor,
  titleStyle,
  buttonStyle,
  iconStyle,
  textStyle,
  backgroundBMC,
  backgroundCard,
  titleColor,
  buttonColor,
  backgroundButton,
  isColourfulfunTemplate = false,
  disabled = false,
}) {
  const revenueFields = ['name', 'description'];
  const productFields = ['unit', 'endUserPrice', 'sales'];

  const {
    product,
    revenue,
    bmc: {
      current: { id },
    },
  } = useSelector((state) => state);

  const {
    product: { fetchProducts, addProduct, updateProduct, removeProduct },
    revenue: { fetchRevenues },
  } = useDispatch();

  const [showRevenueModal, setShowRevenueModal] = useState(false);

  return (
    <>
      {showRevenueModal && (
        <CardModal
          onBlackscreenClick={() => setShowRevenueModal(false)}
          title={canvas.revenue}
          icon={!templateName ? 'icon-revenue' : <SvgIcon name="iconRevenue" />}
          to={`/bmcs/${id}/revenue/intro`}
          locales={locales}
          className="bmc-modal--violet bmc-modal--gray-background custom-font-body"
          onOpen={() => {
            fetchRevenues(id);
            fetchProducts(id);
          }}
          isOpen={showRevenueModal}
          isPreview={isPreview}
          titleStyle={titleStyle}
          buttonStyle={buttonStyle}
          firstSvgColor={firstSvgColor}
          secondSvgColor={secondSvgColor}
          iconFill={iconFill}
          iconStyle={iconStyle}
          backgroundIcon={backgroundIcon}
          backgroundBMC={backgroundBMC}
          titleColor={titleColor}
          buttonColor={buttonColor}
          backgroundButton={backgroundButton}
          iconFillClose={iconFillClose}
          isColourfulfunTemplate={isColourfulfunTemplate}
          disabled={disabled}
        >
          <RevenueModelCard
            revenues={revenue.collection}
            title={locales.revenue.intro.card.revenueModel}
            className="margin-bottom-20"
            addNewLabel={locales.revenue.intro.card.addNewRevenueModel}
            editMode={!isPreview}
            titleStyle={titleStyle}
            buttonStyle={buttonStyle}
            textStyle={textStyle}
            backgroundCard={backgroundCard}
            textColor={textColor}
            titleColor={titleColor}
            buttonColor={buttonColor}
            backgroundButton={backgroundButton}
          />

          <CostCardTable
            title={locales.revenue.intro.card.pricing}
            className="bmc-page-fincard-content--edit-mode"
            titles={[
              locales.revenue.intro.card.product,
              locales.revenue.intro.card.unit,
              locales.revenue.intro.card.endUserPrice,
            ]}
            collection={product.collection}
            editMode={!isPreview}
            collectionEditSettings={
              SECOND_PRODUCT_CARD_TABLE_COLLECTION_EDIT_SETTINGS
            }
            addNewLabel={locales.revenue.intro.card.addNewProduct}
            addHandler={addProduct}
            updateHandler={updateProduct}
            removeHandler={removeProduct}
            maxCount={PRODUCTS_MAX_COUNT}
            titleStyle={titleStyle}
            buttonStyle={buttonStyle}
            textStyle={textStyle}
            backgroundCard={backgroundCard}
            textColor={textColor}
            titleColor={titleColor}
            buttonColor={buttonColor}
            backgroundButton={backgroundButton}
          />
        </CardModal>
      )}

      <BmcCanvasSection
        className={`bmc-canvas-section--wide bmc-canvas-section--violet ${sectionClassName}`}
        title={canvas.revenue}
        dark={isDark}
        showButton={
          !isPreview &&
          collectionEmpty(revenue.collection, revenueFields) &&
          collectionEmpty(product.collection, productFields)
        }
        to={`/bmcs/${id}/revenue/intro`}
        locales={locales}
        icon={!templateName ? 'icon-revenue' : <SvgIcon name="iconRevenue" />}
        isPreview={isPreview}
        isProgressBar={isProgressBar}
        uploading={revenue.uploading}
        onClick={() => setShowRevenueModal(true)}
        backgroundIcon={backgroundIcon}
        backgroundSection={backgroundSection}
        borderSectionColor={borderSectionColor}
        titleBMCSectionColor={titleBMCSectionColor}
        iconFill={iconFill}
        iconStyle={iconStyle}
        templateName={templateName}
      >
        {revenue.collection.map(
          (item) =>
            (itemHasContent(item, revenueFields) ||
              !collectionEmpty(product.collection, productFields)) && (
              <div
                key={item.id}
                className="bmc-canvas-section-items-item custom-font-body"
                style={{ backgroundColor: backgroundItems, color: textColor }}
                onClick={() => setShowRevenueModal(true)}
              >
                <div
                  className="bmc-canvas-section-items-item-title custom-font-body"
                  style={{ color: textColor }}
                >
                  {canvas.revenueModel}
                </div>
                {item.name || '-'}
              </div>
            ),
        )}

        {(!collectionEmpty(revenue.collection, revenueFields) ||
          !collectionEmpty(product.collection, productFields)) && (
          <>
            <div
              className="bmc-canvas-section-items-item custom-font-body"
              style={{ backgroundColor: backgroundItems, color: textColor }}
              onClick={() => setShowRevenueModal(true)}
            >
              <div
                className="bmc-canvas-section-items-item-title custom-font-body"
                style={{ color: textColor }}
              >
                {canvas.annualUnitSold}
              </div>
              {renderMoney(annualUnitSold)}
            </div>

            <div
              className="bmc-canvas-section-items-item custom-font-body"
              style={{ backgroundColor: backgroundItems, color: textColor }}
              onClick={() => setShowRevenueModal(true)}
            >
              <div
                className="bmc-canvas-section-items-item-title custom-font-body"
                style={{ color: textColor }}
              >
                {canvas.annualRevenue}
              </div>
              {renderMoney(annualRevenue)}
            </div>
          </>
        )}
      </BmcCanvasSection>
    </>
  );
}
