import { cloneDeep, some } from 'lodash';
import humps from 'humps';

import styled from 'styled-components';
import * as AppRoute from './appRoutes';

export const nameWithDefault = (name, defaultName, index = null) => {
  return name || defaultName + (index ? ` ${index}` : '');
};

export const link = (text, href, target = null) => ({ text, href, target });

export const sample = (arr) => arr[Math.floor(Math.random() * arr.length)];

export const customerSegmentType = (type) => {
  if (type === 'B2cCustomerSegment') {
    return 'Business to Consumer (B2C)';
  }
  if (type === 'B2bCustomerSegment') {
    return 'Business to Business (B2B)';
  }
  return type;
};

export const removeNewLines = (text) => {
  return text.replace(/\n{3,}/gi, '\n\n');
};

export const customerSegmentAgeRange = ({ min, max }) => `${min}-${max}`;

export const transformResponseCollection = (responseCollection) =>
  responseCollection.map((item) => ({ ...item, ...item.attributes }));

export const transformResponseData = (responseData) => ({
  ...responseData,
  ...responseData.attributes,
});

export const fieldHasContent = (field) => {
  if (field instanceof Array) {
    return field.some((e) => fieldHasContent(e));
  }
  if (field instanceof Object) {
    return some(field);
  }
  return !!field;
};

export const itemHasAllContent = (item, fields = ['description']) => {
  return fields.every((field) => fieldHasContent(item[field]));
};

export const itemHasContent = (item, fields = ['description']) => {
  return fields.some((field) => fieldHasContent(item[field]));
};

export const collectionEmpty = (collection, fieldNames = ['description']) => {
  return (
    collection.filter((item) => itemHasContent(item, fieldNames)).length === 0
  );
};

export const collectionSomeItemIsFilled = (collection, fields) => {
  return collection.some((item) => itemHasAllContent(item, fields));
};

export const countCollectionItemsIsFilled = (collection, fields) => {
  return collection.filter((item) => itemHasAllContent(item, fields)).length;
};

export const filterCollection = (collection, fieldName) => {
  return collection.filter((e) => e[fieldName]);
};

export const sumArrays = (...arrays) => {
  const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
  const result = Array.from({ length: n });
  return result.map((_, i) =>
    arrays.map((xs) => xs[i] || 0).reduce((sum, x) => sum + x, 0),
  );
};

export const diffArray = (...arrays) => {
  const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
  const result = Array.from({ length: n });
  return result.map((_, i) =>
    arrays.map((xs) => xs[i] || 0).reduce((d, j) => d - j),
  );
};

export const costCalculator = (cost) => {
  switch (cost.period) {
    case 'One Off':
    case 'Yearly':
      return Math.round(cost.amount / 12);
    case 'Monthly':
      return cost.amount;
    case 'Weekly':
      return Math.round(cost.amount * 4.33);
    case 'Daily':
      return Math.round(cost.amount * 30.4);
    default:
      return cost.amount;
  }
};

export const renderMoney = (num) => {
  return new Intl.NumberFormat('en-GB').format(num);
};

export const calculateProgress = (value, max) => (value / max) * 100;

export const updateCollectionById = (collection, id, data) => {
  const newCollection = cloneDeep(collection);
  const index = newCollection.findIndex((e) => e.id === id);
  const item = newCollection[index];

  newCollection[index] = { ...item, ...data };

  return newCollection;
};

export const payloadToData = (payload) => {
  return { id: payload.id, [payload.name]: payload.value };
};

export const digitToString = {
  1: '_one',
  2: '_two',
  3: '_three',
};

export const menuIsActive = (path) => {
  return window.location.pathname === path;
};

export const buildMenuItems = (
  actualBmcId,
  locales,
  sectionAvailabilitySettings = {},
) => {
  const {
    menu: { items },
  } = locales;

  const menuItems = [
    {
      title: items[0],
      to: AppRoute.ideation(actualBmcId),
      iconName: 'ideation',
      sectionName: 'ideation',
    },
    {
      title: items[1],
      to: AppRoute.bmc(actualBmcId),
      iconName: 'canvas',
      sectionName: 'bmc',
    },
    {
      title: items[2],
      to: AppRoute.testing(actualBmcId),
      iconName: 'testing',
      sectionName: 'testing',
    },
    {
      title: items[3],
      to: AppRoute.portfolio(actualBmcId),
      iconName: 'portfolio',
      sectionName: 'portfolio',
    },
  ];

  return menuItems.filter((e) => sectionAvailabilitySettings[e.sectionName]);
};

const buildTestRule = (testObject) => {
  return testObject.test;
};

const testPlanRule = (testObject) => {
  return testObject.data && Object.values(testObject.data).some((e) => !!e);
};

const analyseResultRule = (testObject) => {
  return (
    testObject.analyse && Object.values(testObject.analyse).some((e) => !!e)
  );
};

export const makeRules = (testObject, defaultRules) => {
  const rulesCopy = [...defaultRules];

  if (testObject) {
    if (buildTestRule(testObject)) {
      rulesCopy[3] = true;
    }
    if (testPlanRule(testObject)) {
      rulesCopy[4] = true;
      rulesCopy[5] = true;
    }
    if (
      buildTestRule(testObject) &&
      testPlanRule(testObject) &&
      analyseResultRule(testObject)
    ) {
      rulesCopy[6] = true;
    }
  }
  const allowedTo = rulesCopy.findIndex((i) => !i);
  return [rulesCopy, allowedTo];
};

export const defaultTestingRules = [
  true,
  true,
  true,
  false,
  false,
  false,
  false,
  false,
];

export const camelizeKeys = (data) => {
  return humps.camelizeKeys(data);
};

export const sustainabilityNumber = (sdg) => sdg.replace(/[a-zA-Z :,.]/g, '');

export function selectLabel(value, sdgs) {
  if (value) {
    const number = parseInt(sustainabilityNumber(value));
    const obj = sdgs[number - 1];

    if (obj) {
      return obj.title;
    }
    return '';
  }
  return value;
}

export function selectValue(value, _label = null) {
  if (value) {
    return { value, label: _label || value };
  }
  return null;
}

export const buildUserShortName = (user) => {
  return (
    ((user.firstName && user.firstName[0]) || '') +
    ((user.surname && user.surname[0]) || '')
  );
};

export function camelToUnderscore(key) {
  return key.replace(/([A-Z])/g, '_$1').toLowerCase();
}

export function imageAttributes() {
  return { original: '', cropped: '' };
}

export function imageCoordAttributes() {
  return {
    x: 0,
    y: 0,
    width: null,
    height: null,
    cropperData: { zoom: 1, x: 0, y: 0, width: null, height: null },
  };
}

//
// returns array of collections => [{component: SomeComponent, url: "some-url-for-component"}, ...]
//
export const prepareComponents = (components, bmcId, menu, routeHelper) => {
  const sections = menu.map((e) => e.sectionName);

  return components.map((component, i) => ({
    component,
    url: routeHelper(bmcId, sections[i]),
  }));
};

export const setCollectionValueByKey = (collections, keys) => {
  return collections.map((item) => {
    if (Array.isArray(item)) {
      return setCollectionValueByKey(item, keys);
    }

    return {
      ...item,
      children: item.children.map((child) => ({
        ...child,
        value: keys[child.key],
      })),
    };
  });
};

export const StyledWrapper = styled.div`
  .custom-font-title {
    ${(props) =>
      props.customtitlefont &&
      `font-family: '${props.customtitlefont}'${
        props.customtitlefont === 'din-condensed' ? ', sans-serif' : ''
      }  !important;`}
    ${(props) =>
      props.customtitlecolor && `color: ${props.customtitlecolor} !important;`}
  }

  .custom-font-body {
    ${(props) =>
      props.custombodyfont &&
      `font-family: '${props.custombodyfont}'${
        props.custombodyfont === 'din-condensed' ? ', sans-serif' : ''
      }  !important;`}
    ${(props) =>
      props.custombodycolor && `color: ${props.custombodycolor} !important;`}
  }
`;
