import * as Route from '../utils/serverRoutes';
import makeRequest, { uploadFile } from '../utils/makeRequest';
import { imageAttributes, imageCoordAttributes } from '../utils/helpers';
import {
  TEXTAREA_STATUS_DONE_TIMEOUT,
  DEFAULT_STATUS,
  DONE_STATUS,
} from '../utils/const';

const valueProposition = {
  state: {
    product: '',
    productStatus: '',
    reason: '',
    reasonStatus: '',
    image: imageAttributes(),
    imageCoords: imageCoordAttributes(),
    uploadingImage: false,
  },
  reducers: {
    fetchValuePropositionsReducer(state, payload) {
      return { ...state, ...payload };
    },
    updateAttributeReducer(state, payload) {
      return { ...state, [payload.name]: payload.value };
    },
    updateValuePropositionReducer(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    fetchValuePropositions(bmcId) {
      makeRequest({
        dispatch,
        url: Route.valueProposition(bmcId),
        success: (response) => {
          dispatch.valueProposition.updateValuePropositionReducer(
            response.data.data.attributes,
          );
        },
      });
    },
    updateAttribute(payload, state) {
      dispatch.valueProposition.updateAttributeReducer(payload);

      makeRequest({
        dispatch,
        method: 'put',
        url: Route.valueProposition(state.bmc.current.id),
        data: { value_proposition: { [payload.name]: payload.value } },
        success: payload.success,
        finish: (err) => {
          const name = `${payload.name}Status`;

          setTimeout(() => {
            dispatch.valueProposition.updateAttributeReducer({
              name,
              value: DONE_STATUS,
            });
          }, 4300);

          setTimeout(() => {
            dispatch.valueProposition.updateAttributeReducer({
              name,
              value: DEFAULT_STATUS,
            });
          }, 4300 + TEXTAREA_STATUS_DONE_TIMEOUT);

          return payload.finish && payload.finish(err);
        },
      });
    },
    updateAttributeImage(payload, state) {
      const { name, blob, success } = payload;

      dispatch.valueProposition.updateAttributeReducer({
        name: 'uploadingImage',
        value: true,
      });

      uploadFile({
        url: Route.valueProposition(state.bmc.current.id),
        modelName: 'value_proposition',
        name,
        blob,
        dispatch,
        success: (res) => {
          dispatch.valueProposition.updateAttributeReducer({
            name,
            value: res.data.data.attributes[name],
          });

          if (success) success();
        },
        finish: () => {
          dispatch.valueProposition.updateAttributeReducer({
            name: 'uploadingImage',
            value: false,
          });
        },
      });
    },
    updateValuePropositionImageCoords({
      coords,
      croppedAreaPixels,
      innerCropData,
      zoom,
    }) {
      const name = 'imageCoords';
      const value = {
        ...coords,
        ...croppedAreaPixels,
        cropperData: { ...innerCropData, zoom },
      };

      dispatch.valueProposition.updateAttributeReducer({
        name: 'uploadingImage',
        value: true,
      });

      dispatch.valueProposition.updateAttribute({
        name,
        value,
        success: (res) => {
          dispatch.valueProposition.updateAttributeReducer({
            name,
            value: res.data.data.attributes[name],
          });

          dispatch.valueProposition.updateAttributeReducer({
            name: 'image',
            value: res.data.data.attributes.image,
          });
        },
        finish: () => {
          dispatch.valueProposition.updateAttributeReducer({
            name: 'uploadingImage',
            value: false,
          });
        },
      });
    },
  }),
};

export default valueProposition;
