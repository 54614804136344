import React from 'react';
import { useSelector } from 'react-redux';
import BmcListCard from './BmcListCard';

export default function BmcList({
  locales,
  locales: {
    list: { title, text, card },
  },
}) {
  const {
    bmc: {
      current: { id },
    },
    bmcProgress: {
      keyActivitiesProgress,
      keyResourcesProgress,
      keyPartnersProgress,
      valuePropositionsProgress,
      getKeepGrowProgress,
      channelsProgress,
      customerSegmentsProgress,
      costsProgress,
      sustainabilitiesProgress,
      revenuesProgress,
    },
  } = useSelector((state) => state);

  return (
    <div className="bmc-list margin-top-20">
      <div className="bmc-list-title bmc-list-title--green">{title[0]}</div>
      <div className="bmc-list-text">{text[0]}</div>

      <BmcListCard
        data={{
          ...card[0],
          date: 'update 5 days ago by Thanh Do',
          progress: customerSegmentsProgress,
        }}
        className="bmc-list-card--green"
        to={`/bmcs/${id}/customer_segment`}
        locales={locales}
      />

      <BmcListCard
        data={{
          ...card[1],
          date: 'update 5 days ago by Thanh Do',
          progress: valuePropositionsProgress,
        }}
        className="bmc-list-card--green"
        to={`/bmcs/${id}/value_proposition`}
        locales={locales}
      />

      <BmcListCard
        data={{
          ...card[2],
          date: 'update 5 days ago by Thanh Do',
          progress: getKeepGrowProgress,
        }}
        className="bmc-list-card--green"
        to={`/bmcs/${id}/get_keep_grow`}
        locales={locales}
      />

      <BmcListCard
        data={{
          ...card[3],
          date: 'update 5 days ago by Thanh Do',
          progress: channelsProgress,
        }}
        className="bmc-list-card--green"
        to={`/bmcs/${id}/channel`}
        locales={locales}
      />

      <div className="bmc-list-title bmc-list-title--orange margin-top-60">
        {title[1]}
      </div>
      <div className="bmc-list-text">{text[1]}</div>

      <BmcListCard
        data={{
          ...card[4],
          date: 'update 5 days ago by Thanh Do',
          progress: keyActivitiesProgress,
        }}
        className="bmc-list-card--orange"
        to={`/bmcs/${id}/key_activity`}
        locales={locales}
      />

      <BmcListCard
        data={{
          ...card[5],
          date: 'update 5 days ago by Thanh Do',
          progress: keyResourcesProgress,
        }}
        className="bmc-list-card--orange"
        to={`/bmcs/${id}/key_resources`}
        locales={locales}
      />

      <BmcListCard
        data={{
          ...card[6],
          date: 'update 5 days ago by Thanh Do',
          progress: keyPartnersProgress,
        }}
        className="bmc-list-card--orange"
        to={`/bmcs/${id}/key_partners`}
        locales={locales}
      />

      <div className="bmc-list-title bmc-list-title--violet margin-top-60">
        {title[2]}
      </div>
      <div className="bmc-list-text">{text[2]}</div>

      <BmcListCard
        data={{
          ...card[7],
          date: 'update 5 days ago by Thanh Do',
          progress: costsProgress,
        }}
        className="bmc-list-card--violet"
        to={`/bmcs/${id}/cost`}
        locales={locales}
      />

      <BmcListCard
        data={{
          ...card[8],
          date: 'update 5 days ago by Thanh Do',
          progress: revenuesProgress,
        }}
        className="bmc-list-card--violet"
        to={`/bmcs/${id}/revenue`}
        locales={locales}
      />

      <BmcListCard
        data={{
          ...card[9],
          date: 'update 5 days ago by Thanh Do',
          progress: sustainabilitiesProgress,
        }}
        className="bmc-list-card--violet"
        to={`/bmcs/${id}/sustainability`}
        locales={locales}
      />
    </div>
  );
}
