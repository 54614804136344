import React from 'react';

const AnalyseTestResultsBlock = ({ analyseHandler, textStyle, linkColor }) => {
  return (
    <div
      className={`add-field ${textStyle} custom-font-body`}
      onClick={analyseHandler}
      style={{
        color: linkColor,
        borderBottomColor: linkColor,
      }}
    >
      Analyse Test Results
      <div className="border" />
    </div>
  );
};

export default AnalyseTestResultsBlock;
