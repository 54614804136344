import React from 'react';
import PropTypes from 'prop-types';
import icons from '../../main/utils/icons';

const SortStatus = ({ dataIndex, sortStatus = '', onSort }) => {
  const handleClick = () => {
    if (!dataIndex) return;

    let sort;
    if (sortStatus === '') sort = 'asc';
    else if (sortStatus === 'asc') sort = 'desc';
    else if (sortStatus === 'desc') sort = '';

    onSort({ dataIndex, sort });
  };

  if (dataIndex)
    return (
      <div className={`sort-status ${sortStatus}`} onClick={handleClick}>
        {icons.expandIcon}
      </div>
    );

  return <></>;
};

SortStatus.propTypes = {
  sortStatus: PropTypes.oneOf(['asc', 'desc']),
  onSort: PropTypes.func,
};

export default SortStatus;
