import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import ChatGroupItemComponent from './chat-group-item-component';

export default function ChatGroupItemContainer({
  currentChatGroup,
  unreadChatGroupsCallback,
}) {
  const { chatGroup } = useSelector((selector) => selector);
  const {
    chatGroup: { updateChatGroupAttribute },
  } = useDispatch();

  const handleClickItem = (_chatGroup) => {
    updateChatGroupAttribute({
      selectedChatGroup: _chatGroup,
      isExpanded: true,
      commentIsOpened: true,
    });
  };

  const { unreadCommentCount, mentionCommentCount, lastCommentCreatedAt } =
    useMemo(() => {
      if (
        !chatGroup.currentUser?.id ||
        currentChatGroup.chatGroupComments.length === 0
      )
        return {
          unreadCommentCount: 0,
          mentionCommentCount: 0,
          lastCommentCreatedAt: null,
        };

      const result = {
        unreadCommentCount: 0,
        mentionCommentCount: 0,
        lastCommentCreatedAt: currentChatGroup.chatGroupComments[0]?.createdAt,
      };

      const member = currentChatGroup.chatGroupMembers.find(
        (item) => item.userId === chatGroup.currentUser?.id,
      );

      const activeAt = moment(member?.activeAt || 0);

      currentChatGroup.chatGroupComments.forEach((comment) => {
        const createdAt = moment(comment.createdAt);

        if (moment(result.lastCommentCreatedAt).isBefore(createdAt))
          result.lastCommentCreatedAt = comment.createdAt;

        if (activeAt.isBefore(createdAt) || member.activeAt === null)
          result.unreadCommentCount += 1;

        if (
          (activeAt.isBefore(createdAt) || member.activeAt === null) &&
          comment.mentions.includes(chatGroup.currentUser?.id)
        )
          result.mentionCommentCount += 1;
      });

      return result;
    }, [chatGroup.currentUser, currentChatGroup.chatGroupComments]);

  useEffect(() => {
    if (unreadCommentCount)
      unreadChatGroupsCallback((prevState) => [...prevState, currentChatGroup]);
  }, []);

  return (
    <div
      className={classNames('chat-group-item-container', {
        'not-expanded': !chatGroup.isExpanded,
      })}
      onClick={() => handleClickItem(currentChatGroup)}
    >
      <ChatGroupItemComponent
        isExpanded={chatGroup.isExpanded}
        chatGroup={currentChatGroup}
        lastCommentCreatedAt={lastCommentCreatedAt}
        unreadCommentCount={unreadCommentCount}
        mentionCommentCount={mentionCommentCount}
      />
    </div>
  );
}
