import {
  itemHasContent,
  collectionSomeItemIsFilled,
} from '../../utils/helpers';

const VP_FIELDS = ['product', 'reason'];
const COMPETITOR_FIELDS = ['name', 'doWell', 'doBadly'];
const USP_FIELDS = ['description'];

export default function calculateVPProgress(rootState) {
  const stepsCount = 4;
  const step = 100 / stepsCount;
  let vpProgress = 0;

  VP_FIELDS.forEach((field) => {
    if (itemHasContent(rootState.valueProposition, [field])) vpProgress += step;
  });

  if (
    collectionSomeItemIsFilled(
      rootState.competitor.collection,
      COMPETITOR_FIELDS,
    )
  )
    vpProgress += step;

  if (
    collectionSomeItemIsFilled(
      rootState.uniqueSellingPoint.collection,
      USP_FIELDS,
    )
  )
    vpProgress += step;

  return vpProgress;
}
