import React from 'react';

import { TEXTAREA_STATUS_ENABLED, DONE_STATUS } from '../../utils/const';

export default function Status({ status }) {
  if (TEXTAREA_STATUS_ENABLED) {
    if (status === DONE_STATUS) {
      return <span className="textarea-status">Saved</span>;
    }
  }
  return null;
}
