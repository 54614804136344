import React from 'react';
import Select from 'react-select';

import { SELECT_V2_STYLES } from '../../../../utils/const';

const SelectV2 = ({ options, value, onChange, className, titleColor }) => {
  return (
    <div style={{ color: titleColor }}>
      <Select
        options={options}
        value={value || options[0]}
        styles={SELECT_V2_STYLES}
        onChange={onChange}
        isSearchable={false}
        className={className}
      />
    </div>
  );
};

export default SelectV2;
