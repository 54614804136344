import React from 'react';
import { useSelector } from 'react-redux';
import useMediaQuery from 'react-use-media-query-hook';

import {
  BOLD_TEMPLATE_NAME,
  IMAGE_ASPECT_RATIO_ONE,
  IMAGE_ASPECT_RATIO_VP_BOLD,
} from '../../main/utils/const';
import { renderImage } from '../../main/utils/helpers/renderImage';
import { renderSegmentList } from '../../main/utils/helpers/renderSegmentList';
import { renderText } from '../../main/utils/helpers/renderText';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import { getImageAspectRatio } from '../../main/utils/helpers/getImageAspectRatio';

const ValuePropositionBoldWeb = ({
  locales,
  locales: {
    valueProposition: {
      intro: { card },
    },
  },
  titleColor,
  textColor,
  subtitleColor,
  background,
  backgroundImage,
}) => {
  const { portfolio } = useSelector((state) => state);
  const {
    valueProposition: { product, reason, image },
    uniqueSellingPoint: { collection },
  } = useSelector((state) => state);

  const isSmallDesktop = useMediaQuery('(max-width: 1024px)');

  return (
    <div
      className="value-propositions-bold-web"
      style={{ backgroundColor: background }}
    >
      <div className="left">
        <div className="image">
          {renderImage(
            BOLD_TEMPLATE_NAME,
            image,
            backgroundImage,
            locales.portfolioWeb.valueProposition,
            getImageAspectRatio(
              isSmallDesktop,
              IMAGE_ASPECT_RATIO_ONE,
              IMAGE_ASPECT_RATIO_VP_BOLD,
            ),
            titleColor,
          )}
        </div>
      </div>
      <div className="right">
        <SectionHeaderWeb
          templateName={BOLD_TEMPLATE_NAME}
          title={locales.portfolioWeb.valueProposition}
          description={portfolio.valueProposition.description}
          portfolioWeb={locales.portfolioWeb}
          style={{ color: titleColor }}
          descriptionColor={textColor}
        />
        {renderSegmentList(card.solutionTitle, subtitleColor)}
        {renderText(product, textColor)}
        {renderSegmentList(card.featuresTitle, subtitleColor)}
        {renderText(reason, textColor)}
        {renderSegmentList(card.pointsTitle, subtitleColor)}
        {renderText(
          collection.map((point, index) => (
            <div className="margin-bottom-10" key={point.id || index}>
              {point.description}
            </div>
          )),
          textColor,
        )}
      </div>
    </div>
  );
};

export default ValuePropositionBoldWeb;
