import * as Route from '../support/serverRoutes';
import {
  transformResponseCollection,
  transformResponseData,
} from '../../main/utils/helpers';
import makeRequest from '../../main/utils/makeRequest';

const classroomLecturer = {
  state: {
    collection: [],
    uploading: false,
  },
  reducers: {
    fetchClassroomLecturersReducer(state, { collection }) {
      return { ...state, collection };
    },
    addClassroomLecturerReducer(state, payload) {
      return { ...state, collection: [...state.collection, payload] };
    },
    removeClassroomLecturerReducer(state, id) {
      const collection = state.collection.filter((e) => e.id !== id);

      return { ...state, collection };
    },
    updateAttributeReducer(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    fetchClassroomLecturers(classroomId) {
      dispatch.classroomLecturer.updateAttributeReducer({ uploading: true });

      makeRequest({
        dispatch,
        url: Route.classroomLecturers(classroomId),
        success: (response) => {
          dispatch.classroomLecturer.fetchClassroomLecturersReducer({
            collection: transformResponseCollection(response.data.data),
          });
        },
        finish: () => {
          dispatch.classroomLecturer.updateAttributeReducer({
            uploading: false,
          });
        },
      });
    },
    addClassroomLecturer({ classroomId, lecturerId }) {
      makeRequest({
        dispatch,
        method: 'post',
        url: Route.classroomLecturers(classroomId),
        data: { classroomLecturer: { userId: lecturerId } },
        success: (response) => {
          dispatch.classroomLecturer.addClassroomLecturerReducer(
            transformResponseData(response.data.data),
          );
        },
      });
    },
    removeClassroomLecturer({ classroomId, lecturerId }) {
      makeRequest({
        dispatch,
        method: 'delete',
        url: Route.classroomLecturer(classroomId, lecturerId),
        success: () => {
          dispatch.classroomLecturer.removeClassroomLecturerReducer(lecturerId);
        },
      });
    },
  }),
};

export default classroomLecturer;
