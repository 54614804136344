import SdgList1 from './assets/sdg1.svg';
import SdgList2 from './assets/sdg2.svg';
import SdgList3 from './assets/sdg3.svg';
import SdgList4 from './assets/sdg4.svg';
import SdgList5 from './assets/sdg5.svg';
import SdgList6 from './assets/sdg6.svg';
import SdgList7 from './assets/sdg7.svg';
import SdgList8 from './assets/sdg8.svg';
import SdgList9 from './assets/sdg9.svg';
import SdgList10 from './assets/sdg10.svg';
import SdgList11 from './assets/sdg11.svg';
import SdgList12 from './assets/sdg12.svg';
import SdgList13 from './assets/sdg13.svg';
import SdgList14 from './assets/sdg14.svg';
import SdgList15 from './assets/sdg15.svg';
import SdgList16 from './assets/sdg16.svg';
import SdgList17 from './assets/sdg17.svg';

export const sdgListIcon = (sdg) => {
  const iconsMapping = [
    SdgList2,
    SdgList3,
    SdgList1,
    SdgList4,
    SdgList5,
    SdgList6,
    SdgList7,
    SdgList8,
    SdgList9,
    SdgList10,
    SdgList11,
    SdgList12,
    SdgList13,
    SdgList14,
    SdgList15,
    SdgList16,
    SdgList17,
  ];

  return iconsMapping[sdg - 1] ? iconsMapping[sdg - 1] : '';
};
