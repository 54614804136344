import React from 'react';
import { useSelector } from 'react-redux';

import Img from '../../main/components/Img';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import { removeNewLines } from '../../main/utils/helpers';
import { BLOG_FRIENDLY_TEMPLATE_NAME } from '../../main/utils/const';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';

const Team = ({
  locales: { portfolioWeb },
  backgroundSection,
  secondColor,
  isFourthColorPaletteNumber = false,
}) => {
  const {
    portfolioTeammate: { collection },
    portfolio: { team },
  } = useSelector((state) => state);

  const getAbbreviation = (source) => {
    return source
      .split(' ')
      .map((n) => n[0])
      .join('');
  };
  const textColorDependsOnBackground =
    isFourthColorPaletteNumber &&
    getColorDependsOnBackground(backgroundSection);

  return (
    <div
      className="team-blogFriendly-web section-web"
      style={{ backgroundColor: backgroundSection }}
    >
      <SectionHeaderWeb
        templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
        title={portfolioWeb.team}
        description={team.description}
        portfolioWeb={portfolioWeb}
        style={{ color: textColorDependsOnBackground }}
        descriptionColor={textColorDependsOnBackground}
      />

      <div className="team-blogFriendly-web-cards">
        {collection.map(({ id, name, position, description, image }) => {
          return (
            <div className="team-blogFriendly-web-card" key={id}>
              <div
                className="image-upload"
                style={{ backgroundColor: secondColor }}
              >
                {image.cropped ? (
                  <Img src={image.cropped} />
                ) : (
                  <div
                    className="center-block custom-font-title"
                    style={{
                      color:
                        isFourthColorPaletteNumber &&
                        getColorDependsOnBackground(secondColor),
                    }}
                  >
                    {getAbbreviation(name)}
                  </div>
                )}
              </div>

              <div
                className="position custom-font-body"
                style={{ color: textColorDependsOnBackground }}
              >
                {position}
              </div>
              <div
                className="name custom-font-title"
                style={{ color: textColorDependsOnBackground }}
              >
                {name}
              </div>
              <div
                className="member-description custom-font-body"
                style={{ color: textColorDependsOnBackground }}
              >
                {description && removeNewLines(description)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Team;
