import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { ActionCableProvider } from 'react-actioncable-provider';

import '../main/utils/axios';
import store from './store';
import Router from './support/router';
import ConfirmationModalBase from '../main/components/ConfirmationModalBase';
import ConnectionChecker from '../main/components/ConnectionChecker';
import Alert from '../main/components/Alert';
import NotifySystem from '../main/components/NotifySystem';
import GlobalContext from '../main/utils/GlobalContext';
import 'antd/lib/tabs/style/index.css';
import 'antd/lib/table/style/index.css';
import 'antd/lib/pagination/style/index.css';
import 'antd/lib/input/style/index.css';
import 'antd/lib/button/style/index.css';
import 'antd/lib/popover/style/index.css';
import 'antd/lib/spin/style/index.css';
import 'antd/lib/select/style/index.css';
import 'antd/lib/grid/style/index.css';
import 'antd/lib/form/style/index.css';
import 'antd/lib/space/style/index.css';
import 'antd/lib/switch/style/index.css';
import 'antd/lib/tooltip/style/index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import UserCableHandler from '../main/containers/actioncable/UserCableHandler';

export default function App({ locales, flash, anyCableUrl }) {
  const [sectionAvailabilitySettings, setSectionAvailabilitySettings] =
    useState({ bmc: true, testing: true });

  const globalContext = {
    locales,
    sectionAvailabilitySettings,
    setSectionAvailabilitySettings,
  };

  return (
    <ActionCableProvider url={anyCableUrl}>
      <GlobalContext.Provider value={globalContext}>
        <Provider store={store}>
          <ConfirmationModalBase />
          <UserCableHandler />
          <ConnectionChecker />
          <NotifySystem flash={flash} />
          <Alert />
          <Router locales={locales} />
        </Provider>
      </GlobalContext.Provider>
    </ActionCableProvider>
  );
}
