import React from 'react';
import { useSelector } from 'react-redux';

import TabsList from '../../../../../../../components/TabsList';
import Dropzone from '../../../../../../../components/Dropzone';
import Textarea from '../../../../../../../components/Textarea';

const First = ({ handleChange, handleImageUpload, locales, testObject }) => {
  const onDrop = ([file]) => {
    if (file) {
      handleImageUpload(testObject.id, file);
    }
  };

  const { uploadingImage } = useSelector((state) => state.testing);

  return (
    <div className="first">
      <TabsList>
        <div className="page-top-builder">
          <div className="company-name-wrapper">
            <Textarea
              maxLength={20}
              value={testObject.data.companyName}
              delay={300}
              onChange={handleChange}
              placeholder="Your Company Name"
              className="bmc-form-textarea company-name"
              name="companyName"
            />
          </div>
          <div className="bg-color">
            <Dropzone
              onDrop={onDrop}
              locales={locales}
              image={testObject.image}
              uploading={uploadingImage}
            />
          </div>
          <div className="headline-wrapper">
            <Textarea
              value={testObject.data.headline}
              delay={300}
              onChange={handleChange}
              placeholder="Add a Bold Headline"
              maxLength={54}
              className="bmc-form-textarea headline"
              wrapperClassName="margin-bottom-10"
              name="headline"
            />
          </div>
          <div className="explain-wrapper">
            <Textarea
              maxLength={240}
              onChange={handleChange}
              value={testObject.data.explain}
              delay={300}
              placeholder="Explain your business idea and why it’s awesome"
              rows={5}
              className="bmc-form-textarea explain-business"
              wrapperClassName="margin-bottom-20"
              name="explain"
            />
          </div>
        </div>
      </TabsList>
      <br />
      <TabsList>
        <div className="page-bottom-builder">
          <div className="title">Learn More</div>
          <div className="things-wrapper">
            <div className="thing">
              <div className="icon">1</div>
              <div className="thing-wrapper">
                <Textarea
                  value={testObject.data.thingOne}
                  delay={300}
                  onChange={handleChange}
                  maxLength={240}
                  placeholder="What 3 things do you want people to know?"
                  className="thing-explanation"
                  rows={5}
                  name="thingOne"
                />
              </div>
            </div>
            <div className="thing">
              <div className="icon">2</div>
              <div className="thing-wrapper">
                <Textarea
                  value={testObject.data.thingTwo}
                  delay={300}
                  onChange={handleChange}
                  maxLength={240}
                  placeholder="What 3 things do you want people to know?"
                  className="thing-explanation"
                  rows={5}
                  name="thingTwo"
                />
              </div>
            </div>
            <div className="thing">
              <div className="icon">3</div>
              <div className="thing-wrapper">
                <Textarea
                  value={testObject.data.thingThree}
                  delay={300}
                  onChange={handleChange}
                  placeholder="What 3 things do you want people to know?"
                  maxLength={240}
                  className="thing-explanation"
                  rows={5}
                  name="thingThree"
                />
              </div>
            </div>
          </div>
        </div>
      </TabsList>
      <br />
    </div>
  );
};

export default First;
