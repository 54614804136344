import React from 'react';
import classNames from 'classnames';

import Textarea from '../../../components/Textarea';
import UploadFiles from '../../../components/UploadFiles';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import { COMMENT_SUMMARY } from '../../../utils/const';

const SummaryCurrent = ({
  data: { description },
  locales,
  locales: { portfolio },
  updateSectionField,
  backgroundSection,
  textColorDependsOnBackground,
  styleColor,
  isFirstColorPallete = false,
  isFourthColorPaletteNumber = false,
  disabled = false,
}) => {
  return (
    <div
      className="summary-current relative"
      style={{ backgroundColor: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_SUMMARY} />
      <div
        className={classNames('current-title title custom-font-title', {
          'text-black': !isFirstColorPallete && !isFourthColorPaletteNumber,
        })}
        style={{ color: textColorDependsOnBackground }}
      >
        {portfolio.projectSummary.sectionName}
      </div>
      <Textarea
        rows={5}
        delay={700}
        value={description}
        onChange={(e) => {
          updateSectionField({
            sectionName: 'projectSummary',
            field: 'description',
            name: 'projectSummary',
            value: e.target.value,
          });
        }}
        className="textarea-current textarea-current-summary custom-font-body"
        placeholder={portfolio.page.descriptionPlaceholder}
        style={{ color: textColorDependsOnBackground }}
        disabled={disabled}
      />

      <UploadFiles
        locales={locales}
        className="margin-top-40"
        fileTextColor={styleColor}
        fileBorderColor={styleColor}
        uplouderButtonStyle="portfolio-file-uploader__current"
        fileStyle="portfolio-file-uploader-file__title"
        borderButtonColor={styleColor}
        textButtonColor={styleColor}
      />
    </div>
  );
};

export default SummaryCurrent;
