import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import Textarea from '../../components/Textarea';
import Button from '../../components/Button';
import { REVENUE_MAX_COUNT } from '../../utils/const';
import ConfirmationModal from '../../components/ConfirmationModal';

export default function RevenueModelCard({
  title,
  addNewLabel,
  revenues,
  className,
  editMode = false,
  titleStyle,
  buttonStyle,
  textStyle,
  backgroundCard,
  titleColor,
  buttonColor,
  backgroundButton,
  textColor,
}) {
  const {
    revenue: { addRevenue, updateRevenue, removeRevenue },
  } = useDispatch();

  return (
    <div
      className={classNames('bmc-page-revmodel relative', className)}
      style={{ background: backgroundCard }}
    >
      <div
        className={`bmc-page-revmodel-title ${titleStyle} custom-font-title`}
        style={{ color: titleColor }}
      >
        {title}
      </div>

      <div className="bmc-page-revmodel-items">
        {revenues.map(({ id, name, description }) => (
          <div className="bmc-page-revmodel-item" key={id}>
            {editMode ? (
              <>
                <div className="bmc-page-revmodel-item-title">
                  <Textarea
                    value={name}
                    delay={700}
                    className={`bmc-form-textarea ${textStyle} custom-font-body`}
                    onChange={(e) =>
                      updateRevenue({ id, name: 'name', value: e.target.value })
                    }
                  />
                </div>

                <div className="bmc-page-revmodel-item-description">
                  <Textarea
                    value={description}
                    delay={700}
                    className={`bmc-form-textarea ${textStyle} custom-font-body`}
                    rows={2}
                    onChange={(e) =>
                      updateRevenue({
                        id,
                        name: 'description',
                        value: e.target.value,
                      })
                    }
                  />
                </div>

                {revenues.length > 1 && (
                  <ConfirmationModal
                    body="Delete this content. Are you sure?"
                    confirmText="Delete"
                    onConfirm={() => removeRevenue({ id })}
                  >
                    <div className="bmc-page-revmodel-item-remove">
                      <i className="icon-Delete" />
                    </div>
                  </ConfirmationModal>
                )}
              </>
            ) : (
              <>
                <div
                  className={`bmc-page-revmodel-item-title ${textStyle} custom-font-body`}
                  style={{ color: textColor }}
                >
                  {name}
                </div>
                <div
                  className={`bmc-page-revmodel-item-description ${textStyle} custom-font-body`}
                  style={{ color: textColor }}
                >
                  {description}
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      {editMode && revenues.length < REVENUE_MAX_COUNT && (
        <Button
          wide
          title={addNewLabel}
          onClick={addRevenue}
          icon="icon-Add"
          className={classNames(
            'margin-top-5 custom-font-body',
            buttonStyle ? `${buttonStyle}` : 'bmc-button--blue',
          )}
          style={{ color: buttonColor, background: backgroundButton }}
        />
      )}
    </div>
  );
}
