import React from 'react';
import { useSelector } from 'react-redux';
import { find, isEmpty } from 'lodash';
import TemplateList from './TemplateList';
import TemplateColorsList from './TemplateColorsList';
import TemplateFonts from './TemplateFonts';

const ChooseTemplateSection = ({ disabled = false }) => {
  const {
    portfolioTemplate: { collection },
    portfolioStyle: { activeTemplateName, templateSettings },
  } = useSelector((state) => state);

  const activeTemplateSettings = templateSettings
    ? templateSettings[activeTemplateName]
    : {};

  const activeTemplate = find(collection, ['sysName', activeTemplateName]);

  return (
    <div>
      <div className="templates-top-wrapper">Design</div>
      <div className="templates-wrapper fullwidth">
        {!isEmpty(collection) && activeTemplateName && (
          <div className="templates-content">
            <div className="templates-content--left">
              <div className="choose-template-content">
                <div className="choose-template">
                  Select a design for your Portfolio from these templates
                </div>
              </div>
              <div className="templates">
                <TemplateList
                  templateCollection={collection}
                  activeTemplateName={activeTemplateName}
                  disabled={disabled}
                />

                <TemplateColorsList
                  activeTemplate={activeTemplate}
                  templateSettings={templateSettings}
                  customColors={activeTemplateSettings?.customColors}
                  activeColorPalette={activeTemplateSettings?.colorPalette}
                  activeTemplateName={activeTemplateName}
                  disabled={disabled}
                />
              </div>
            </div>
            <div>
              <div className="choose-template choose-template-media">
                Choose Font Styles
              </div>
              <TemplateFonts
                templateSettings={templateSettings}
                settings={activeTemplateSettings}
                activeTemplate={activeTemplate}
                disabled={disabled}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ChooseTemplateSection;
