import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Img = ({
  className,
  src,
  onClick,
  aspectRatio,
  inner,
  borderImageColor,
}) => {
  const renderImg = useCallback(
    (newClassName = '') => (
      <div
        className={classNames('img', className, newClassName)}
        style={{
          backgroundImage: `url(${src})`,
          borderColor: borderImageColor,
        }}
        onClick={onClick}
      />
    ),
    [className, src, onClick],
  );

  return aspectRatio ? (
    <div
      className="img-wrapper"
      style={{ paddingTop: `${(1 / aspectRatio) * 100}%` }}
    >
      {renderImg('img--with-aspect-ratio')}
      {inner && inner}
    </div>
  ) : (
    renderImg()
  );
};

Img.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  aspectRatio: PropTypes.number,
};

export default Img;
