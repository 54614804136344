import { init } from '@rematch/core';

import common from '../../main/models/common';
import alert from '../../main/models/alert';
import notification from '../../main/models/notification';
import connection from '../../main/models/connection';
import user from '../../main/models/user';

import classroomLecturer from '../models/classroomLecturer';
import classrooms from '../models/classrooms';
import classroom from '../models/classroom';
import bmcs from '../models/bmcs';
import blocks from '../models/blocks';
import lecturers from '../models/lecturers';
import users from '../models/users';
import establishments from '../models/establishments';
import establishment from '../models/establishment';
import adminLecturers from '../models/adminLecturers';
import activeUser from '../models/activeUser';
import students from '../models/students';
import pagination from '../models/pagination';
import bmc from '../../main/models/bmc';
import bmcProgress from '../../main/models/bmcProgress';
import keyPartner from '../../main/models/keyPartner';
import keyActivity from '../../main/models/keyActivity';
import keyResource from '../../main/models/keyResource';
import valueProposition from '../../main/models/valueProposition';
import getKeepGrow from '../../main/models/getKeepGrow';
import channel from '../../main/models/channel';
import customerSegment from '../../main/models/customerSegment';
import cost from '../../main/models/cost';
import sustainability from '../../main/models/sustainability';
import revenue from '../../main/models/revenue';
import product from '../../main/models/product';
import uniqueSellingPoint from '../../main/models/uniqueSellingPoint';
import competitor from '../../main/models/competitor';
import ideation from '../../main/models/ideation';
import testing from '../../main/models/testing';
import testDocument from '../../main/models/testDocument';
import portfolio from '../../main/models/portfolio';
import portfolioFile from '../../main/models/portfolioFile';
import portfolioTeammate from '../../main/models/portfolioTeammate';
import portfolioTemplate from '../../main/models/portfolioTemplate';
import portfolioStyle from '../../main/models/portfolioStyle';
import admin from '../models/admin';
import message from '../../main/models/message';
import chatGroup from '../../main/models/chatGroup';

const store = init({
  models: {
    alert,
    classroomLecturer,
    classrooms,
    classroom,
    bmcs,
    common,
    connection,
    notification,
    user,
    blocks,
    lecturers,
    users,
    establishments,
    establishment,
    adminLecturers,
    activeUser,
    students,
    bmc,
    bmcProgress,
    keyPartner,
    keyActivity,
    keyResource,
    valueProposition,
    getKeepGrow,
    channel,
    customerSegment,
    cost,
    sustainability,
    revenue,
    product,
    uniqueSellingPoint,
    competitor,
    ideation,
    testing,
    testDocument,
    pagination,
    portfolio,
    portfolioFile,
    portfolioTeammate,
    portfolioTemplate,
    portfolioStyle,
    admin,
    chatGroup,
    message,
  },
});

export default store;
