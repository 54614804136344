import classNames from 'classnames';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

export default function ExpandDropdown({ items, onClick }) {
  const [opened, setOpened] = useState(false);

  const handleOpen = () => setOpened(true);
  const handleClose = () => setOpened(false);

  const handleToggle = () => {
    if (opened) handleClose();
    else handleOpen();
  };

  const handleClickItem = (item) => {
    onClick(item);
  };

  return (
    <div className="expand-dropdown">
      <i className="expand-dropdown-icon icon-Colaps" onClick={handleToggle} />
      {opened && (
        <OutsideClickHandler
          onOutsideClick={() => {
            handleClose();
          }}
        >
          <ul className="expand-dropdown-list">
            {items.map((item) => (
              <li className="expand-dropdown-item" key={item.id}>
                <div
                  className={classNames('expand-dropdown-link', {
                    active: item.expandDropdownActive,
                  })}
                  onClick={() => handleClickItem(item)}
                >
                  {item.name}
                </div>
              </li>
            ))}
          </ul>
        </OutsideClickHandler>
      )}
    </div>
  );
}
