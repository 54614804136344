import * as Route from '../utils/serverRoutes';
import {
  transformResponseCollection,
  updateCollectionById,
  payloadToData,
} from '../utils/helpers';
import makeRequest, { uploadFile } from '../utils/makeRequest';

const channel = {
  state: {
    collection: [],
    uploading: false,
    uploadingImage: false,
  },
  reducers: {
    fetchChannelsReducer(state, { collection }) {
      return { ...state, collection };
    },
    addChannelReducer(state, payload) {
      return { ...state, collection: [...state.collection, payload] };
    },
    updateChannelReducer(state, payload) {
      return {
        ...state,
        collection: updateCollectionById(state.collection, payload.id, payload),
      };
    },
    removeChannelReducer(state, { id }) {
      const collection = state.collection.filter((e) => e.id !== id);

      return { ...state, collection };
    },
    updateAttributeReducer(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    async fetchChannels(bmcId) {
      dispatch.channel.updateAttributeReducer({ uploading: true });

      makeRequest({
        dispatch,
        url: Route.channels(bmcId),
        success: (response) => {
          dispatch.channel.fetchChannelsReducer({
            collection: transformResponseCollection(response.data.data),
          });
        },
        finish: () => {
          dispatch.channel.updateAttributeReducer({ uploading: false });
        },
      });
    },
    async addChannel(_, state) {
      makeRequest({
        dispatch,
        method: 'post',
        url: Route.channels(state.bmc.current.id),
      });
    },
    async updateChannel(payload, state) {
      const data = payloadToData(payload);
      dispatch.channel.updateChannelReducer(data);

      makeRequest({
        dispatch,
        method: 'put',
        url: Route.channel(state.bmc.current.id, payload.id),
        data: { channel: data },
        success: payload.success,
        finish: payload.finish,
      });
    },
    async updateChannelImage(payload, state) {
      const { id, name, blob, success } = payload;

      dispatch.channel.updateAttributeReducer({ uploadingImage: id });

      uploadFile({
        url: Route.channel(state.bmc.current.id, id),
        modelName: 'channel',
        name,
        blob,
        dispatch,
        success: (res) => {
          dispatch.channel.updateChannelReducer({
            id,
            [name]: res.data.data.attributes[name],
          });

          if (success) success();
        },
        finish: () => {
          dispatch.channel.updateAttributeReducer({ uploadingImage: false });
        },
      });
    },
    updateChannelImageCoords({
      id,
      coords,
      croppedAreaPixels,
      innerCropData,
      zoom,
    }) {
      const name = 'imageCoords';
      const value = {
        ...coords,
        ...croppedAreaPixels,
        cropperData: { ...innerCropData, zoom },
      };

      dispatch.channel.updateAttributeReducer({
        uploadingImage: id,
      });

      dispatch.channel.updateChannel({
        id,
        name,
        value,
        success: (res) => {
          dispatch.channel.updateChannelReducer({
            id,
            [name]: res.data.data.attributes[name],
          });

          dispatch.channel.updateChannelReducer({
            id,
            image: res.data.data.attributes.image,
          });
        },
        finish: () => {
          dispatch.channel.updateAttributeReducer({ uploadingImage: false });
        },
      });
    },
    async removeChannel(payload, state) {
      makeRequest({
        method: 'delete',
        url: Route.channel(state.bmc.current.id, payload.id),
      });
    },
  }),
};

export default channel;
