import React from 'react';
import cellPropTypes from './cellPropTypes';

function CellEmail({ user }) {
  return <div className="user-list__fullname">{user.email}</div>;
}

CellEmail.propTypes = cellPropTypes;

export default CellEmail;
