import React from 'react';
import { useSelector } from 'react-redux';

import Header from '../../components/Header';
import Actions from '../../components/Actions';
import Line from '../../components/Line';
import Title from '../../components/Title';
import Text from '../../components/Text';
import Card from './Card';
import CompetitorsCard from './CompetitorsCard';
import SvgIcon from '../../components/SvgIcon';
import MobileMenuHeader from '../../components/MobileMenuHeader';

export default function Summary(props) {
  const {
    nextClickHandler,
    menuItems,
    selectComponent,
    componentIndex,
    rules,
    backPath,
    locales,
    locales: {
      common,
      tipCustomerSegment,
      valueProposition: {
        name,
        summary: { header, title, text },
        intro: { card, competitorsCard },
      },
    },
  } = props;
  const {
    valueProposition: { product, reason, image },
    uniqueSellingPoint,
    competitor,
  } = useSelector((state) => state);

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon={<SvgIcon name="valueProposition" />}
        locales={locales}
        componentIndex={componentIndex}
        tipLocales={tipCustomerSegment}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="VPCanvas" title={name} color="green" />
        }
      />

      <Text>{[text[0]]}</Text>

      <Line />

      <Title text={title[0]} />

      <Card
        locales={locales}
        className="margin-top-60 margin-wide-40-lg"
        dropzoneMode
        card={{
          ...card,
          title: false,
          image,
          solution: product,
          features: reason,
          points: uniqueSellingPoint.collection,
        }}
      />

      <CompetitorsCard
        className="margin-top-40 margin-wide-40-lg"
        locales={locales}
        card={{
          ...competitorsCard,
          competitors: competitor.collection,
        }}
      />

      <Actions nextClickHandler={nextClickHandler} buttonName={common.done} />
    </div>
  );
}
