import * as Route from '../support/serverRoutes';
import makeRequest from '../../main/utils/makeRequest';

const bmcs = {
  state: {
    data: [],
    meta: null,
    loading: false,
    uploading: false,
    by: null,
    byId: null,
  },
  reducers: {
    setData(state, { data, meta }) {
      return { ...state, data, meta };
    },
    setLoading(state, loading) {
      return { ...state, loading };
    },
    setRouteOptions(state, { by, byId }) {
      return { ...state, by, byId };
    },
  },
  effects: (dispatch) => ({
    fetchBmcs(params, rootState) {
      const sort = rootState.bmcs?.meta?.sort;
      dispatch.bmcs.setLoading(true);
      dispatch.bmcs.setRouteOptions(params);
      makeRequest({
        dispatch,
        url: Route.bmcs({ ...params, sort }),
        success: (response) => {
          dispatch.bmcs.setData({
            data: response.data.data,
            meta: response.data.meta,
          });
        },
        finish: () => {
          dispatch.bmcs.setLoading(false);
        },
      });
    },
    sortBmcs({ sort }, rootState) {
      const {
        by,
        byId,
        meta: { page, limit, search },
      } = rootState.bmcs;
      dispatch.bmcs.setLoading(true);
      makeRequest({
        dispatch,
        url: Route.bmcs({ page, limit, search, sort, by, byId }),
        success: (response) => {
          dispatch.bmcs.setData({
            data: response.data.data,
            meta: response.data.meta,
          });
        },
        finish: () => {
          dispatch.bmcs.setLoading(false);
        },
      });
    },
  }),
};

export default bmcs;
