import React from 'react';
import { useSelector } from 'react-redux';

import HomepageTest from './Homepage';
import GeneralPage from './GeneralPage';

const Summary = ({
  locales,
  goToHandler,
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
}) => {
  const {
    testing: { test },
  } = useSelector((state) => state);

  const selectedTest = test.test;

  if (selectedTest === 'homepage')
    return (
      <HomepageTest
        goToHandler={goToHandler}
        test={test}
        locales={locales}
        menuItems={menuItems}
        selectComponent={selectComponent}
        componentIndex={componentIndex}
        rules={rules}
        backPath={backPath}
      />
    );
  return (
    <GeneralPage
      goToHandler={goToHandler}
      test={test}
      selectedTest={selectedTest}
      locales={locales}
      menuItems={menuItems}
      selectComponent={selectComponent}
      componentIndex={componentIndex}
      rules={rules}
      backPath={backPath}
    />
  );
};

export default Summary;
