import icons from '../icons';

export const TEST_STATUSES = {
  INCOMPLETE: 'incomplete',
  UNSURE: 'unsure',
  INVALID: 'invalid',
  VALID: 'valid',
};

const testStatusOptions = [
  {
    label: 'Incomplete',
    value: TEST_STATUSES.INCOMPLETE,
    icon: icons.incomplete,
  },
  {
    label: 'Unsure',
    value: TEST_STATUSES.UNSURE,
    icon: icons.question,
  },
  {
    label: 'Invalid',
    value: TEST_STATUSES.INVALID,
    icon: icons.x,
  },
  {
    label: 'Valid',
    value: TEST_STATUSES.VALID,
    icon: icons.okSmall,
  },
];

export default testStatusOptions;
