import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import useMediaQuery from 'react-use-media-query-hook';
import { truncate } from 'lodash';

import Card from '../../channel/CardBlogArch';
import {
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  BUTTON_LIGHT_MODAL_STYLE_ARCH,
  BUTTON_MODAL_STYLE_ARCH,
  COLORS,
  COMMENT_CHANNELS,
  IMAGE_ASPECT_RATIO_CH_ARCH,
  IMAGE_ASPECT_RATIO_ONE,
  TITLE_MODAL_STYLE_ARCH,
} from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import { getColorDependsOnBackground } from '../../../utils/helpers/getColorDependsOnBackground';
import { getImageAspectRatio } from '../../../utils/helpers/getImageAspectRatio';
import { getStylesDependsOnBackgroundAndPalleteChannels } from '../../../utils/helpers/portfolioArchTemplate/getStylesDependsOnBackgroundAndPalleteChannels';

const { black } = COLORS;

const ChannelsArch = ({
  data: { description },
  locales,
  locales: {
    portfolio,
    channel: {
      intro: { card },
    },
  },
  background,
  backgroundImage,
  backgroundModal,
  colorPaletteNumber,
  isFourthColorPaletteNumber = false,
  disabled = false,
}) => {
  const { collection } = useSelector((state) => state.channel);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isSmallDesktop = useMediaQuery('(max-width: 1024px)');
  const [activeCardId, setActiveCardId] = useState(null);
  const {
    portfolio: { updateSectionField },
  } = useDispatch();
  const activeCardData = useMemo(() => {
    if (collection.length && !activeCardId) {
      setActiveCardId(collection[0].id);
      return collection[0];
    }
    return collection.find((i) => i.id === activeCardId);
  }, [collection, activeCardId]);

  const textColorDependsOnBackground =
    isFourthColorPaletteNumber && getColorDependsOnBackground(background);

  const singleCard = activeCardData && (
    <Card
      locales={locales}
      dropzoneMode
      isPreview
      disabled={disabled}
      background={background}
      backgroundImage={backgroundImage}
      colorPaletteNumber={colorPaletteNumber}
      textColor={textColorDependsOnBackground}
      templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
      aspectRatio={getImageAspectRatio(
        isSmallDesktop,
        IMAGE_ASPECT_RATIO_ONE,
        IMAGE_ASPECT_RATIO_CH_ARCH,
      )}
      card={{
        ...card,
        ...activeCardData,
        title: null,
      }}
      titleStyle={TITLE_MODAL_STYLE_ARCH}
      buttonStyle={BUTTON_MODAL_STYLE_ARCH}
      lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_ARCH}
      sliderColor={black}
      backgroundModal={backgroundModal}
      isTemplates
    />
  );

  const tabStyles = getStylesDependsOnBackgroundAndPalleteChannels(
    colorPaletteNumber,
    background,
  );

  return (
    <div className="channel-architectural relative">
      <PortfolioCommentIcon title={COMMENT_CHANNELS} />
      <SectionHeader
        templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
        title={portfolio.channels.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'channels',
            field: 'description',
            name: 'channelsText',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        disabled={disabled}
      />

      <div className="cards-section">
        <div className="channel-tabs">
          {collection.map(({ id, name }) => (
            <div
              key={id}
              onClick={() => setActiveCardId(id)}
              className={classnames('item', 'custom-font-body', {
                active: activeCardId === id,
              })}
              style={activeCardId === id ? tabStyles : {}}
            >
              {truncate(name, { length: 30 })}
            </div>
          ))}
        </div>
        <div className="channel-card">
          {isMobile
            ? collection.map((channel) => {
                return (
                  <Card
                    locales={locales}
                    className="margin-top-20"
                    dropzoneMode
                    isPreview
                    disabled={disabled}
                    background={background}
                    backgroundImage={backgroundImage}
                    colorPaletteNumber={colorPaletteNumber}
                    textColor={textColorDependsOnBackground}
                    templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
                    aspectRatio={getImageAspectRatio(
                      isSmallDesktop,
                      IMAGE_ASPECT_RATIO_ONE,
                      IMAGE_ASPECT_RATIO_CH_ARCH,
                    )}
                    card={{
                      ...card,
                      ...channel,
                      title: null,
                    }}
                    titleStyle={TITLE_MODAL_STYLE_ARCH}
                    buttonStyle={BUTTON_MODAL_STYLE_ARCH}
                    lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_ARCH}
                    sliderColor={black}
                    backgroundModal={backgroundModal}
                    isTemplates
                  />
                );
              })
            : singleCard}
        </div>
      </div>
    </div>
  );
};

export default ChannelsArch;
