import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Header from '../../components/Header';
import Text from '../../components/Text';
import Line from '../../components/Line';
import Actions from '../../components/Actions';
import MobileMenuHeader from '../../components/MobileMenuHeader';
import Card from './Card';

export default function PickIdea(props) {
  const {
    nextClickHandler,
    menuItems,
    selectComponent,
    componentIndex,
    rules,
    backPath,
    locales,
    locales: {
      common,
      ideation: {
        name,
        pickIdea: { header, title, text, actions },
        intro: { card },
      },
    },
  } = props;
  const {
    ideation: {
      idea: {
        data: {
          ideas,
          mostEasiestId,
          mostExcitedId,
          mostImpactId,
          pickedIdeaId,
        },
      },
    },
    bmc: { current: currentBmc },
  } = useSelector((state) => state);

  const most = [mostEasiestId, mostExcitedId, mostImpactId];

  const threeIdeas = useMemo(
    () => most.map((id) => ideas.find((e) => e.id === id)),
    most,
  );

  const {
    ideation: { updateIdeaData, removeIdea },
  } = useDispatch();

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon="icon-VP"
        locales={locales}
        componentIndex={componentIndex}
        className="bmc-page-header--blue"
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="ideationActive" title={name} color="blue" />
        }
      />

      <Text>{[text[0], text[1], text[2]]}</Text>

      <Line />

      <h2 className="bmc-page-title">
        <span className="bmc-page-title--blue">{title[0]}</span> {title[1]}
      </h2>

      <div className="bmc-page-text bmc-page-text--center">
        {text[3]}
        <br />
        {text[4]}
      </div>

      <div className="margin-top-60 margin-wide-40-lg">
        {threeIdeas.map(
          (idea, index) =>
            idea && (
              <Card
                key={index}
                locales={locales}
                card={{
                  ...card,
                  ...idea,
                  marketSize: idea.marketSize || 0,
                  price: idea.price || 0,
                  excitement: idea.excitement || 0,
                  solution: idea.solution || '',
                }}
                bmc={currentBmc}
                removeIdea={removeIdea}
                className="margin-top-20"
                pickIdeaMode
                picked={pickedIdeaId === idea.id}
                onPick={() => updateIdeaData({ pickedIdeaId: idea.id })}
              />
            ),
        )}
      </div>

      <Actions
        nextClickHandler={nextClickHandler}
        buttonName={common.next}
        tooltipIfDisabled={actions.tooltipIfDisabled}
      />
    </div>
  );
}
