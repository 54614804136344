import React from 'react';
import classNames from 'classnames';

import DonutChart from './DonutChart';
import SvgIcon from './SvgIcon';

export default function BmcMobileMenu({
  locales: { common },
  menuItems,
  className,
  onMenuItemClick,
  mobileMenuHeader,
  componentIndex,
  rules,
  show,
  progress,
  onCloseClick,
}) {
  return (
    <div className="bmc-menu-wrapper">
      <div className={classNames('bmc-menu bmc-menu--mobile', className)}>
        {mobileMenuHeader && mobileMenuHeader}

        <div className="bmc-menu-items margin-bottom-40">
          {menuItems.map((menuItem, index) => (
            <div
              onClick={() =>
                rules
                  ? rules[index] && onMenuItemClick(index)
                  : onMenuItemClick(index)
              }
              key={index}
              className={classNames('bmc-menu-items-item', {
                'bmc-menu-items-item--active': componentIndex === index,
                'bmc-menu-items-item--disabled':
                  rules && rules[index] === false,
              })}
            >
              <div className="bmc-menu-items-item-active-bar" />
              <div
                className={classNames('bmc-menu-items-item-point', {
                  'bmc-menu-items-item-point--active': componentIndex > index,
                })}
              />
              <div className="bmc-menu-items-item-title">
                <div>{menuItem.name}</div>
              </div>
            </div>
          ))}
        </div>

        <div className="bmc-menu-progress">
          <DonutChart progress={progress} />

          <div className="bmc-menu-progress-title">
            {common.sectionProgress}
          </div>
        </div>

        <div className="bmc-menu-close" onClick={onCloseClick}>
          <SvgIcon name="x" />
        </div>
      </div>
      <div
        className={classNames('bmc-menu-blackscreen', {
          'bmc-menu-blackscreen--visible': show,
        })}
        onClick={onCloseClick}
      />
    </div>
  );
}
