import React from 'react';

import Img from '../../../components/Img';

const WebsiteCard = ({ title, image, url, description }) => {
  return (
    <div className="website-wrapper">
      <Img src={image} alt={title} aspectRatio={1} />
      <div className="title">
        {url ? (
          <a target="_blank" className="website-wrapper__link" href={url}>
            {title}
          </a>
        ) : (
          <>{title}</>
        )}
      </div>
      {description && <div className="description">{description}</div>}
    </div>
  );
};

export default WebsiteCard;
