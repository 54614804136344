import classNames from 'classnames';
import React, { useMemo } from 'react';
import commentToHtml from '../utils/commentToHtml';
import commentCreatedAt from '../utils/commentCreatedAt';
import CommentAvatar from './comment-avatar';
import CommentRoleLabelComponent from './comment-role-label';

const replySVG = (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.227189 4.69083L5.0398 0.534982C5.46106 0.171178 6.125 0.466522 6.125 1.03165V3.2206C10.5172 3.27089 14 4.15118 14 8.31361C14 9.99364 12.9177 11.658 11.7214 12.5282C11.348 12.7997 10.816 12.4589 10.9536 12.0187C12.1935 8.05357 10.3655 7.00088 6.125 6.93984V9.34378C6.125 9.9098 5.46056 10.2038 5.0398 9.84045L0.227189 5.68418C-0.0755195 5.42272 -0.0759395 4.95266 0.227189 4.69083Z"
      fill="#007AFF"
    />
  </svg>
);

function CommentEllipseButton() {
  return (
    <div className="comment-item-component__ellipse-btn">
      <div className="comment-item-component__ellipse" />
      <div className="comment-item-component__ellipse" />
      <div className="comment-item-component__ellipse" />
    </div>
  );
}

export default function CommentItemComponent({
  comment,
  comment: {
    message,
    createdAt,
    sender,
    sender: { id, fullName, image },
  },
  currentUserId,
  members = [],
  hideReply = false,
  onReply,
  onDelete,
  onEdit,
}) {
  const resultMessage = useMemo(() => {
    return commentToHtml(message, members, id);
  }, [message, members]);

  return (
    <li className="comment-item">
      <div className="comment-item-header">
        <div className="comment-item-header-avatar">
          <CommentAvatar url={image?.icon?.url} />
        </div>
        <div className="comment-item-header-sender">
          <div className="comment-item-header-sender__full-name">
            {fullName}
          </div>
          <CommentRoleLabelComponent
            sender={sender}
            currentUserId={currentUserId}
          />
        </div>
        {id === currentUserId && (
          <div className="comment-item-header-actions">
            <CommentEllipseButton />
            <div className="comment-item-header-actions-list">
              <div
                className="comment-item-header-actions-item"
                onClick={() => onEdit(comment)}
              >
                Edit
              </div>
              <div
                className="comment-item-header-actions-item"
                onClick={() => onDelete(comment)}
              >
                Delete
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="comment-item-content">
        <div className="comment-item-content__line" />

        <div className="comment-item-message">
          <div
            className="comment-item-message__text"
            dangerouslySetInnerHTML={{ __html: resultMessage }}
          />
          <div
            className={classNames('comment-item-message-reply', {
              hide: hideReply,
            })}
            onClick={() => onReply(comment)}
          >
            {replySVG}
          </div>
        </div>
        <div className="comment-item-message__created-at">
          {commentCreatedAt(createdAt)}
        </div>
      </div>
    </li>
  );
}
