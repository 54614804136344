import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ClassroomBlockList = ({ classroomId }) => {
  const {
    blocks: { data },
  } = useSelector((state) => state);

  const {
    blocks: { fetchBlock },
  } = useDispatch();

  useEffect(() => {
    if (classroomId) {
      fetchBlock({
        by: 'classrooms',
        byId: classroomId,
        pageName: 'classroom',
      });
    }
  }, [classroomId]);

  return (
    <div className="classroom-block-list">
      <div className="classroom-block-list__row">
        <div className="classroom-block classroom-block__fg3">
          <div className="classroom-block__header">Students</div>
          <div className="classroom-block__items">
            <div className="classroom-block__item">
              <div className="classroom-block__value">{data.totalProject}</div>
              <div className="classroom-block__title">Total projects</div>
            </div>
            <div className="classroom-block__item">
              <div className="classroom-block__value">{data.totalStudents}</div>
              <div className="classroom-block__title">Total students</div>
            </div>
            <div className="classroom-block__item">
              <div className="classroom-block__value">
                {data.totalLastActiveStudents}
              </div>
              <div className="classroom-block__title">Active Last 30 days</div>
            </div>
          </div>
        </div>
        <div className="classroom-block classroom-block__fg3">
          <div className="classroom-block__header">Total progress</div>
          <div className="classroom-block__items">
            <div className="classroom-block__item">
              <div className="classroom-block__value">{data.ideasCount}</div>
              <div className="classroom-block__title">Ideas created</div>
            </div>
            <div className="classroom-block__item">
              <div className="classroom-block__value">
                {data.canvasProgress}
              </div>
              <div className="classroom-block__title">Canvas progress</div>
            </div>
            <div className="classroom-block__item">
              <div className="classroom-block__value">
                {data.testsCompleted}
              </div>
              <div className="classroom-block__title">Tests completed</div>
            </div>
          </div>
        </div>
        <div className="classroom-block classroom-block__fg2">
          <div className="classroom-block__header">Activity</div>
          <div className="classroom-block__items">
            <div className="classroom-block__item">
              <div className="classroom-block__value">
                {data.totalSiteTimeTracks}
              </div>
              <div className="classroom-block__title">Total minutes</div>
            </div>
            <div className="classroom-block__item">
              <div className="classroom-block__value">
                {data.totalSiteLastTotalTracks}
              </div>
              <div className="classroom-block__title">Minutes Last 30 days</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassroomBlockList;
