import React from 'react';

import Textarea from '../../../components/Textarea';
import Dropzone from '../../../components/Dropzone';
import {
  PORTFOLIO_INTRO_LOGO_ASPECT_RATIO,
  PORTFOLIO_INTRO_BG_ASPECT_RATIO,
  TEXTAREA_DELAY,
  COMMENT_INTRODUCTION,
  TITLE_MODAL_STYLE_BOLD,
  BUTTON_MODAL_STYLE_BOLD,
  BUTTON_LIGHT_MODAL_STYLE_BOLD,
} from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';

const IntroductionBold = ({
  data,
  locales,
  locales: { portfolio },
  onLogoDrop,
  onLogoCropComplete,
  onBgDrop,
  onBgCropComplete,
  handleDescriptionSubmit,
  handleInputSubmit,
  uploadingIntrologo,
  uploadingIntrobg,
  titleColor,
  textColor,
  backgroundSection,
  backgroundImage,
  sliderColor,
  backgroundModal,
  buttonSecondColor,
  buttonFirstColor,
  backgroundSecondButton,
  disabled = false,
}) => {
  const {
    title,
    description,
    introLogo,
    introLogoCoords,
    introBg,
    introBgCoords,
  } = data;

  return (
    <div
      className="introduction-bold relative"
      style={{ backgroundColor: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_INTRODUCTION} />
      <div className="introduction-bold-header">
        <div className="logo-hover">
          <Dropzone
            className="logo-upload"
            onDrop={onLogoDrop}
            onCropperSave={onLogoCropComplete}
            locales={locales}
            image={introLogo}
            coords={introLogoCoords}
            aspectRatio={PORTFOLIO_INTRO_LOGO_ASPECT_RATIO}
            uploading={uploadingIntrologo}
            titleStyle={TITLE_MODAL_STYLE_BOLD}
            buttonStyle={BUTTON_MODAL_STYLE_BOLD}
            lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_BOLD}
            sliderColor={sliderColor}
            backgroundModal={backgroundModal}
            titleColor={titleColor}
            buttonSecondColor={buttonSecondColor}
            buttonFirstColor={buttonFirstColor}
            backgroundSecondButton={backgroundSecondButton}
            isTemplates
            disabled={disabled}
            cropper
          />
        </div>
        <div className="introduction-bold-image-hover">
          <Dropzone
            className="introduction-bold-image"
            onDrop={onBgDrop}
            onCropperSave={onBgCropComplete}
            locales={locales}
            image={introBg}
            background={{ backgroundColor: backgroundImage }}
            coords={introBgCoords}
            aspectRatio={PORTFOLIO_INTRO_BG_ASPECT_RATIO}
            uploading={uploadingIntrobg}
            titleStyle={TITLE_MODAL_STYLE_BOLD}
            buttonStyle={BUTTON_MODAL_STYLE_BOLD}
            lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_BOLD}
            sliderColor={sliderColor}
            backgroundModal={backgroundModal}
            titleColor={titleColor}
            buttonSecondColor={buttonSecondColor}
            buttonFirstColor={buttonFirstColor}
            backgroundSecondButton={backgroundSecondButton}
            isTemplates
            disabled={disabled}
            cropper
          />
        </div>
        <div className="company-wrapper">
          <Textarea
            onChange={handleInputSubmit}
            value={title}
            delay={TEXTAREA_DELAY}
            placeholder={portfolio.introduction.projectName}
            className="textarea-bold textarea-bold-name custom-font-title"
            style={{ color: titleColor }}
            disabled={disabled}
          />
          <div className="textarea-boldTemplate">
            <Textarea
              onChange={handleDescriptionSubmit}
              value={description}
              delay={TEXTAREA_DELAY}
              placeholder={portfolio.introduction.projectNamePlaceholder}
              rows={2}
              className="textarea-bold description custom-font-body"
              style={{ color: textColor }}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroductionBold;
