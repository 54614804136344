import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CurrentWebTemplate from './CurrentWebTemplate';
import ColourfulFunWebTemplate from './ColourfulFunWebTemplate';

import {
  TECH_MODERN_TEMPLATE_NAME,
  BOLD_TEMPLATE_NAME,
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  BLOG_FRIENDLY_TEMPLATE_NAME,
  COLOURFUL_TEMPLATE_NAME,
  CURRENT_TEMPLATE_NAME,
} from '../../main/utils/const';
import BlogFriendlyTemplate from './BlogFriendlyTemplate';
import ArchitecturalMinimalTemplate from './ArchitecturalMinimalTemplate';
import TechModernTemplateWeb from './TechModernTemplateWeb';
import BoldTemplateWeb from './BoldTemplateWeb';

const Portfolio = ({
  locales,
  bmc,
  sectionAvailabilitySettings: { bmc: bmcEnabled, testing },
}) => {
  const {
    portfolioStyle: { templateSettings, activeTemplateName },
  } = useSelector((state) => state);

  const currentSettings = templateSettings
    ? templateSettings[activeTemplateName]
    : {};

  const {
    bmc: { setCurrentBmcFromLocal },
    portfolioStyle: { fetchPortfolioStyle },
    portfolioTemplate: { fetchPortfolioTemplates },
  } = useDispatch();

  useEffect(() => {
    setCurrentBmcFromLocal(bmc);
    fetchPortfolioStyle(bmc.current.data.id);
    fetchPortfolioTemplates();
  }, []);

  return (
    <>
      {activeTemplateName === CURRENT_TEMPLATE_NAME && (
        <div className="portfolio-web">
          <CurrentWebTemplate
            locales={locales}
            bmcEnabled={bmcEnabled}
            testing={testing}
            currentSettings={currentSettings}
          />
        </div>
      )}

      {activeTemplateName === COLOURFUL_TEMPLATE_NAME && (
        <div className="portfolio-web-colourful">
          <ColourfulFunWebTemplate
            locales={locales}
            bmcEnabled={bmcEnabled}
            testing={testing}
            currentSettings={currentSettings}
          />
        </div>
      )}
      {activeTemplateName === BLOG_FRIENDLY_TEMPLATE_NAME && (
        <div className="portfolio-web-blogFriendly">
          <BlogFriendlyTemplate
            locales={locales}
            bmcEnabled={bmcEnabled}
            testing={testing}
            currentSettings={currentSettings}
          />
        </div>
      )}
      {activeTemplateName === ARCHITECTURAL_MINIMAL_TEMPLATE_NAME && (
        <div className="portfolio-web-architectural">
          <ArchitecturalMinimalTemplate
            locales={locales}
            bmcEnabled={bmcEnabled}
            testing={testing}
            currentSettings={currentSettings}
          />
        </div>
      )}
      {activeTemplateName === BOLD_TEMPLATE_NAME && (
        <div className="portfolio-web-bold">
          <BoldTemplateWeb
            locales={locales}
            bmcEnabled={bmcEnabled}
            testing={testing}
            currentSettings={currentSettings}
          />
        </div>
      )}
      {activeTemplateName === TECH_MODERN_TEMPLATE_NAME && (
        <div className="portfolio-web-tech">
          <TechModernTemplateWeb
            locales={locales}
            bmcEnabled={bmcEnabled}
            testing={testing}
            currentSettings={currentSettings}
          />
        </div>
      )}
    </>
  );
};

export default Portfolio;
