import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import ImageUploader from './ImageUploader';
import ConfirmationModal from './ConfirmationModal';
import { PORTFOLIO_FILE_MAX_COUNT } from '../utils/const';

export default function UploadFiles({
  className,
  locales: { common },
  fileBackgroundColor,
  fileTextColor,
  fileBorderColor,
  uplouderButtonStyle,
  filesWrapperStyle,
  fileStyle,
  removeIconStyle,
  borderButtonColor,
  backgroundButtonColor,
  textButtonColor,
  disabled = false,
}) {
  const { bmcId: bmcIdFromParams } = useParams();

  const { collection, uploadingFile } = useSelector(
    (state) => state.portfolioFile,
  );

  const {
    portfolioFile: {
      fetchPortfolioFiles,
      addPortfolioFile,
      removePortfolioFile,
    },
  } = useDispatch();

  useEffect(() => {
    fetchPortfolioFiles(bmcIdFromParams);
  }, []);

  return (
    <div className={classNames('portfolio-file-uploader', className)}>
      <div className="portfolio-file-uploader__header portfolio-file-uploader__wrapper">
        {collection.length < PORTFOLIO_FILE_MAX_COUNT && (
          <ImageUploader
            title={uploadingFile ? common.uploading : common.uploadFile}
            icon="icon-Export"
            className={`bmc-button portfolio-file-uploader__button ${uplouderButtonStyle}`}
            borderButtonColor={borderButtonColor}
            backgroundButtonColor={backgroundButtonColor}
            textButtonColor={textButtonColor}
            onChange={([file]) => {
              addPortfolioFile(file);
            }}
            disabled={disabled}
          />
        )}
      </div>

      <div className="portfolio-file-uploader__files">
        {collection.map((file) => (
          <div
            key={file.id}
            className={`portfolio-file-uploader-file ${filesWrapperStyle}`}
          >
            <a
              className={`${fileStyle} custom-font-body`}
              href={file.file}
              style={{
                backgroundColor: fileBackgroundColor,
                color: fileTextColor,
                borderColor: fileBorderColor,
              }}
              target="_blank"
            >
              {file.name}
            </a>
            {!disabled && (
              <ConfirmationModal
                body={common.areYouSure}
                confirmText={common.deleteText}
                onConfirm={() => removePortfolioFile({ id: file.id })}
              >
                <div
                  className={`portfolio-file-uploader-file__remove ${removeIconStyle}`}
                >
                  <i className="icon-Delete" />
                </div>
              </ConfirmationModal>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
