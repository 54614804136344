import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { truncate } from 'lodash';
import useMediaQuery from 'react-use-media-query-hook';

import Card from '../../main/containers/channel/CardCurrent';
import { TECH_MODERN_TEMPLATE_NAME } from '../../main/utils/const';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';

const ChannelsTechWeb = ({
  locales,
  locales: {
    portfolioWeb,
    channel: {
      intro: { card },
    },
  },
  background,
  titleColor,
  borderCardColor,
  backgroundImage,
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [activeCardId, setActiveCardId] = useState(null);
  const { channels } = useSelector((state) => state.portfolio);
  const { collection } = useSelector((state) => state.channel);

  const activeCardData = useMemo(() => {
    if (collection.length && !activeCardId) {
      setActiveCardId(collection[0].id);
      return collection[0];
    }
    return collection.find((i) => i.id === activeCardId);
  }, [collection, activeCardId]);

  const singleCard = activeCardData && (
    <Card
      locales={locales}
      isPreview
      styleColor={titleColor}
      borderCardColor={borderCardColor}
      backgroundImage={backgroundImage}
      templateName={TECH_MODERN_TEMPLATE_NAME}
      card={{
        ...card,
        ...activeCardData,
        title: null,
      }}
    />
  );

  return (
    <div
      className="channel-tech-web section-web"
      style={{ backgroundColor: background }}
    >
      <SectionHeaderWeb
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolioWeb.channels}
        description={channels.description}
        portfolioWeb={portfolioWeb}
        style={{ color: titleColor }}
      />

      <div className="cards-section">
        <div className="left">
          {isMobile
            ? collection.map((channel) => {
                return (
                  <Card
                    locales={locales}
                    styleColor={titleColor}
                    borderCardColor={borderCardColor}
                    backgroundImage={backgroundImage}
                    templateName={TECH_MODERN_TEMPLATE_NAME}
                    isPreview
                    card={{
                      ...card,
                      ...channel,
                      title: null,
                    }}
                  />
                );
              })
            : singleCard}
        </div>
        <div className="right">
          {collection.map(({ id, name }) => (
            <div
              key={id}
              onClick={() => setActiveCardId(id)}
              className={classnames('item', { active: activeCardId === id })}
              style={{ backgroundColor: activeCardId === id && titleColor }}
            >
              {truncate(name, { length: 30 })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChannelsTechWeb;
