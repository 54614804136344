import React from 'react';
import { useSelector } from 'react-redux';

import Header from '../../components/Header';
import Title from '../../components/Title';
import Text from '../../components/Text';
import Line from '../../components/Line';
import Actions from '../../components/Actions';
import Card from './Card';
import MobileMenuHeader from '../../components/MobileMenuHeader';

export default function Summary(props) {
  const {
    nextClickHandler,
    menuItems,
    selectComponent,
    componentIndex,
    rules,
    backPath,
    locales,
    locales: {
      common,
      tipCustomerSegment,
      sustainability: {
        name,
        summary: { header, title, text },
      },
    },
  } = props;
  const { collection } = useSelector((state) => state.sustainability);
  const mobileHeader = (
    <MobileMenuHeader icon="SustainabilityCanvas" title={name} color="violet" />
  );

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon="icon-sustainability"
        locales={locales}
        componentIndex={componentIndex}
        className="bmc-page-header--violet"
        tipLocales={tipCustomerSegment}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        mobileMenuHeader={mobileHeader}
        rules={rules}
        backPath={backPath}
      />

      <Text>{[text[0]]}</Text>

      <Line />

      <Title text={title[0]} className="margin-top-60" />

      <Card
        locales={locales}
        collection={collection}
        className="margin-wide-40-lg bmc-page-suscard bmc-page-suscard--section"
      />

      <Actions nextClickHandler={nextClickHandler} buttonName={common.done} />
    </div>
  );
}
