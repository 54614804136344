import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChatGroupHeaderComponent from './chat-group-header-component';

export default function ChatGroupHeaderContainer({ title }) {
  const { chatGroup } = useSelector((selector) => selector);
  const {
    chatGroup: { updateChatGroupAttribute },
  } = useDispatch();

  const { collection, ...chatState } = chatGroup;
  const withPortfolio = document.querySelector('#portfolio-width');
  const handleClickExpand = () => {
    updateChatGroupAttribute({ isExpanded: true });
    localStorage.setItem(
      'chatState',
      JSON.stringify({ ...chatState, isExpanded: true }),
    );
    withPortfolio.style.zoom = '85%';
  };

  const handleClose = () => {
    updateChatGroupAttribute({ isExpanded: false });
    localStorage.setItem(
      'chatState',
      JSON.stringify({ ...chatState, isExpanded: false }),
    );
    withPortfolio.style.zoom = '100%';
  };

  return (
    <div className="chat-group-header-container">
      <ChatGroupHeaderComponent
        isExpanded={chatGroup.isExpanded}
        title={title}
        onClose={handleClose}
        onClickExpand={handleClickExpand}
      />
    </div>
  );
}
