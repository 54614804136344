import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../../main/components/Modal';
import ModalContainer from '../../components/ModalContainer';
import ModalHeader from '../../components/ModalHeader';
import Steps from './Steps';

const ModalCreateClass = ({ visible = false, onClose = () => {} }) => {
  const {
    classroom: { currentStep },
  } = useSelector((state) => state);

  const {
    classroom: { setCurrentStepIndex },
  } = useDispatch();

  const handleClose = () => {
    onClose();
    setCurrentStepIndex(0);
  };

  return (
    <>
      {visible && (
        <Modal onBlackscreenClick={handleClose}>
          <ModalContainer>
            <ModalHeader title="Create New Class" onClose={handleClose} />
            <Steps stepIndex={currentStep} onCancel={handleClose} />
          </ModalContainer>
        </Modal>
      )}
    </>
  );
};

ModalCreateClass.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
export default ModalCreateClass;
