import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import PDF from './PDF';
import WebLink from './WebLink';
import Docx from './Docx';
// import PPT from './PPT';

const WEB_LINK_TAB = 0;
const PDF_TAB = 1;
const DOCX_TAB = 2;
// const PPT_TAB = 2; // temporary disabled

const Footer = ({
  locales,
  portfolioShortcuts,
  slug,
  portfolioWeb,
  sustainability,
}) => {
  const [currentTab, setCurrentTab] = useState(WEB_LINK_TAB);

  const webLink = useMemo(() => {
    return portfolioShortcuts
      .filter((i) => i.status)
      .map((i) => i.name)
      .join('-');
  }, [portfolioShortcuts]);

  const components = useMemo(() => {
    return {
      [WEB_LINK_TAB]: (
        <WebLink slug={slug} webLink={webLink} locales={locales.export} />
      ),
      [PDF_TAB]: <PDF locales={locales.export} slug={slug} webLink={webLink} />,
      [DOCX_TAB]: (
        <Docx
          locales={locales}
          portfolioWeb={portfolioWeb}
          sustainability={sustainability}
        />
      ),
      // [PPT_TAB]: <PPT locales={locales} slug={slug} webLink={webLink} />,
    };
  }, [locales, portfolioShortcuts]);

  const Tab = components[currentTab];

  return (
    <div className="footer">
      <div className="tab-switcher">
        <div
          className={classNames('tab', { active: currentTab === WEB_LINK_TAB })}
          onClick={() => setCurrentTab(WEB_LINK_TAB)}
        >
          {locales.export.weblink.title}
          <div className="tab-border" />
        </div>

        <div
          className={classNames('tab', { active: currentTab === PDF_TAB })}
          onClick={() => setCurrentTab(PDF_TAB)}
        >
          {locales.export.pdfFile.title}
          <div className="tab-border" />
        </div>

        <div
          className={classNames('tab', { active: currentTab === DOCX_TAB })}
          onClick={() => setCurrentTab(DOCX_TAB)}
        >
          {locales.export.docx.title}
          <div className="tab-border" />
        </div>

        {/* <div
          className={classNames('tab', { active: currentTab === PPT_TAB })}
          onClick={() => setCurrentTab(PPT_TAB)}
        >
          {locales.pptFile.title}
          <div className="tab-border" />
        </div> */}
      </div>
      {Tab}
    </div>
  );
};

export default Footer;
