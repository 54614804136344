import React from 'react';
import classNames from 'classnames';

export default function RemoveButton({ className, onClick, active }) {
  return (
    <div
      className={classNames(className, { [`${className}--active`]: active })}
      onClick={() => active && onClick && onClick()}
    >
      <i className="icon-Delete" />
    </div>
  );
}
