import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BmcCanvasSection from './BmcCanvasSection';
import CardModal from '../CardModal';
import KeyActivityCard from '../KeyActivityCard';
import SvgIcon from '../../../components/SvgIcon';
import { collectionEmpty } from '../../../utils/helpers';

export default function KeyActivities({
  locales,
  locales: { canvas },
  isDark,
  isPreview,
  isProgressBar,
  backgroundIcon,
  backgroundItems,
  backgroundSection,
  borderSectionColor,
  titleBMCSectionColor,
  textColor,
  iconFill,
  iconFillClose,
  templateName,
  sectionClassName,
  backgroundBMC,
  firstSvgColor,
  secondSvgColor,
  titleStyle,
  buttonStyle,
  iconStyle,
  styleColor,
  textStyle,
  titleColor,
  buttonColor,
  backgroundButton,
  isColourfulfunTemplate = false,
  disabled = false,
}) {
  const {
    keyActivity,
    bmc: {
      current: { id },
    },
  } = useSelector((state) => state);

  const {
    keyActivity: { fetchKeyActivities },
  } = useDispatch();

  const [showKeyActivityModal, setShowKeyActivityModal] = useState(false);

  return (
    <>
      {showKeyActivityModal && (
        <CardModal
          onBlackscreenClick={() => setShowKeyActivityModal(false)}
          title={canvas.keyActivity}
          icon={!templateName ? `icon-key-activity` : <SvgIcon name="iconKA" />}
          to={`/bmcs/${id}/key_activity/intro`}
          locales={locales}
          className="bmc-modal--orange custom-font-body"
          onOpen={() => fetchKeyActivities(id)}
          isOpen={showKeyActivityModal}
          isPreview={isPreview}
          titleStyle={titleStyle}
          buttonStyle={buttonStyle}
          firstSvgColor={firstSvgColor}
          secondSvgColor={secondSvgColor}
          iconFill={iconFill}
          iconStyle={iconStyle}
          backgroundIcon={backgroundIcon}
          backgroundBMC={backgroundBMC}
          titleColor={titleColor}
          buttonColor={buttonColor}
          backgroundButton={backgroundButton}
          iconFillClose={iconFillClose}
          isColourfulfunTemplate={isColourfulfunTemplate}
          disabled={disabled}
        >
          <KeyActivityCard
            locales={locales}
            name={canvas.keyActivity}
            editMode={!isPreview}
            card={{
              ...locales.keyActivity.intro.card,
              steps: keyActivity.collection,
              title: false,
            }}
            circleColor={styleColor}
            lineColor={styleColor}
            textStyle={textStyle}
            buttonStyle={buttonStyle}
            background={backgroundBMC}
            buttonColor={buttonColor}
            backgroundButton={backgroundButton}
            textColor={textColor}
          />
        </CardModal>
      )}

      <BmcCanvasSection
        className={`bmc-canvas-section--skinny bmc-canvas-section--short bmc-canvas-section--orange bmc-canvas-section-4 ${sectionClassName}`}
        title={canvas.keyActivity}
        dark={isDark}
        showButton={!isPreview && collectionEmpty(keyActivity.collection)}
        to={`/bmcs/${id}/key_activity/intro`}
        locales={locales}
        icon={!templateName ? `icon-key-activity` : <SvgIcon name="iconKA" />}
        isPreview={isPreview}
        isProgressBar={isProgressBar}
        uploading={keyActivity.uploading}
        onClick={() => setShowKeyActivityModal(true)}
        backgroundIcon={backgroundIcon}
        backgroundSection={backgroundSection}
        borderSectionColor={borderSectionColor}
        titleBMCSectionColor={titleBMCSectionColor}
        iconFill={iconFill}
        iconStyle={iconStyle}
        templateName={templateName}
      >
        {keyActivity.collection.map(
          (item, index) =>
            item.description && (
              <div key={item.id} onClick={() => setShowKeyActivityModal(true)}>
                <div
                  className="bmc-canvas-section-title custom-font-body"
                  style={{ color: titleBMCSectionColor }}
                >
                  {canvas.step} {index + 1}
                </div>
                <div
                  className="bmc-canvas-section-items-item custom-font-body"
                  style={{ backgroundColor: backgroundItems, color: textColor }}
                >
                  {item.description}
                </div>
              </div>
            ),
        )}
      </BmcCanvasSection>
    </>
  );
}
