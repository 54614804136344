import React from 'react';

import TeamCard from './TeamCard';
import SvgIcon from '../../../../components/SvgIcon';
import {
  BUTTON_LIGHT_MODAL_STYLE_COLOURFUL,
  BUTTON_MODAL_STYLE_COLOURFUL,
  COLORS,
  COLOURFUL_TEMPLATE_NAME,
  COMMENT_TEAM,
  TITLE_MODAL_STYLE_COLOURFUL,
} from '../../../../utils/const';
import PortfolioCommentIcon from '../../../../components/PortfolioCommentIcon';
import SectionHeader from '../../../../components/SectionHeader';
import { getIndexColor } from '../../../../utils/helpers/getIndexColor';

const TeamColourfulFun = ({
  data: { description },
  locales,
  locales: { portfolio },
  collection,
  addPortfolioTeammate,
  updateSectionField,
  colorTemplate,
  mainSvgColor,
  firstSvgColor,
  secondSvgColor,
  disabled = false,
}) => {
  return (
    <div className="team-colourfulFun section relative">
      <PortfolioCommentIcon title={COMMENT_TEAM} />
      <div className="svg-funTeamFifth" style={{ fill: mainSvgColor }}>
        <SvgIcon name="funTeamFifth" />
      </div>
      <div className="content">
        <SectionHeader
          templateName={COLOURFUL_TEMPLATE_NAME}
          title={portfolio.team.sectionName}
          description={description}
          onChange={(e) =>
            updateSectionField({
              sectionName: 'team',
              field: 'description',
              name: 'teamDescription',
              value: e.target.value,
            })
          }
          portfolio={portfolio}
          disabled={disabled}
        />
        <div className="team-colourfulFun-cards custom-font-body">
          {collection.map((teammate, i) => {
            return (
              <TeamCard
                locales={locales}
                teammate={teammate}
                index={i}
                key={teammate.id}
                backgroundTeam={colorTemplate[getIndexColor(i)] || COLORS.grey}
                disabled={disabled}
                firstSvgColor={firstSvgColor}
                secondSvgColor={secondSvgColor}
                titleStyle={TITLE_MODAL_STYLE_COLOURFUL}
                buttonStyle={BUTTON_MODAL_STYLE_COLOURFUL}
                lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_COLOURFUL}
                isTemplates
                isColourfulfunTemplate
              />
            );
          })}
          {!disabled && (
            <div className="add-team-card-colourful__wrapper custom-font-body">
              <div className="add-team-card-colourful">
                <div className="add-team-card-colourful-hover">
                  <div className="center-block" onClick={addPortfolioTeammate}>
                    <i className="icon-Add" />
                    {portfolio.team.add}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamColourfulFun;
