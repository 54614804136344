import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmationModal from '../../../../components/ConfirmationModal';
import Textarea from '../../../../components/Textarea';
import Dropzone from '../../../../components/Dropzone';
import SvgIcon from '../../../../components/SvgIcon';
import { IMAGE_ASPECT_RATIO_ONE } from '../../../../utils/const';

const TeamCard = ({
  teammate,
  locales,
  locales: { portfolio },
  backgroundTeam,
  firstSvgColor,
  secondSvgColor,
  titleStyle,
  buttonStyle,
  lightButtonStyle,
  isTemplates = false,
  disabled = false,
  isColourfulfunTemplate,
}) => {
  const { image, imageCoords, name, description, position, id } = teammate;
  const {
    portfolioTeammate: {
      updatePortfolioTeammate,
      updatePortfolioTeammateImage,
      updatePortfolioTeammateImageCoords,
      removePortfolioTeammate,
    },
  } = useDispatch();

  const { uploadingImage } = useSelector((state) => state.portfolioTeammate);

  const onChange = (e) => {
    updatePortfolioTeammate({
      id,
      name: e.target.name,
      value: e.target.value,
    });
  };

  const onDropImage = ([file], openCropperHandler) => {
    updatePortfolioTeammateImage({
      id,
      name: 'image',
      blob: file,
      success: () => {
        openCropperHandler();
      },
    });
  };

  const onImageCropperSave = (croppedAreaPixels, innerCropData, zoom) => {
    updatePortfolioTeammateImageCoords({
      id,
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: imageCoords,
    });
  };

  return (
    <div className="team-colourfulFun-card">
      <div className="team-colourfulFun-card-left">
        <div className="image-hover">
          <div className="svg-iconTeam" style={{ stroke: backgroundTeam }}>
            <SvgIcon name="iconTeam" />
          </div>
          <Dropzone
            className="image-upload"
            background={{ backgroundColor: backgroundTeam }}
            onDrop={onDropImage}
            onCropperSave={onImageCropperSave}
            locales={locales}
            image={image}
            coords={imageCoords}
            uploading={uploadingImage === teammate.id}
            aspectRatio={IMAGE_ASPECT_RATIO_ONE}
            disabled={disabled}
            firstSvgColor={firstSvgColor}
            secondSvgColor={secondSvgColor}
            titleStyle={titleStyle}
            buttonStyle={buttonStyle}
            lightButtonStyle={lightButtonStyle}
            isTemplates={isTemplates}
            isColourfulfunTemplate={isColourfulfunTemplate}
            cropper
          />
        </div>
      </div>

      <div className="textarea-colourful middle">
        <Textarea
          id="position"
          type="text"
          name="position"
          rows={1}
          value={position}
          placeholder={portfolio.team.position}
          onChange={onChange}
          className="textarea-colourfulFun textarea-colourfulFun-team-card--position custom-font-body"
          delay={700}
          disabled={disabled}
        />
        <Textarea
          id="fullName"
          value={name}
          onChange={onChange}
          placeholder={portfolio.team.fullName}
          name="name"
          rows={1}
          type="text"
          className="textarea-colourfulFun textarea-colourfulFun-team-card--name custom-font-title"
          delay={700}
          disabled={disabled}
        />
      </div>
      <div className="textarea-colourful member-description">
        <Textarea
          id="description"
          className="textarea-colourfulFun textarea-colourfulFun-team-card--description custom-font-body"
          name="description"
          placeholder={portfolio.page.description}
          value={description}
          onChange={onChange}
          rows={5}
          delay={700}
          disabled={disabled}
        />
      </div>
      {!disabled && (
        <div className="remove">
          <ConfirmationModal
            body="Delete this content. Are you sure?"
            confirmText="Delete"
            onConfirm={() => removePortfolioTeammate({ id })}
          >
            <div className="icon-section">
              <i className="icon-Delete" />
            </div>
          </ConfirmationModal>
        </div>
      )}
    </div>
  );
};

export default TeamCard;
