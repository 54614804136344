import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Links = ({ items, className }) => (
  <div className={classNames('bmc-page-links', className)}>
    <ul className="bmc-page-links-container">
      {items &&
        items.map((item) => (
          <li key={item.id} className="bmc-page-links-container-link">
            <span>{item.name}</span>
          </li>
        ))}
    </ul>
  </div>
);

Links.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
};

export default Links;
