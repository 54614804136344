import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dropzone from '../../../components/Dropzone';
import {
  COMMENT_PROPOSITION,
  IMAGE_ASPECT_RATIO_VP_TECH,
  TECH_MODERN_TEMPLATE_NAME,
} from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import { renderSegmentList } from '../../../utils/helpers/renderSegmentList';
import { renderText } from '../../../utils/helpers/renderText';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';

const ValuePropositionTech = ({
  data: { description },
  locales: {
    portfolio,
    valueProposition: {
      intro: { card },
    },
  },
  locales,
  titleColor,
  background,
  backgroundImage,
  disabled = false,
}) => {
  const {
    valueProposition: { product, reason, image, imageCoords },
    uniqueSellingPoint: { collection },
  } = useSelector((state) => state);

  const { uploadingImage } = useSelector((state) => state.valueProposition);

  const {
    valueProposition: {
      updateAttributeImage,
      updateValuePropositionImageCoords,
    },
    portfolio: { updateSectionField },
  } = useDispatch();

  const onImageDrop = ([file], openCropperHandler) => {
    updateAttributeImage({
      name: 'image',
      value: URL.createObjectURL(file),
      blob: file,
      success: () => {
        openCropperHandler();
      },
    });
  };

  const onImageCropperSave = (croppedAreaPixels, innerCropData, zoom) => {
    updateValuePropositionImageCoords({
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: imageCoords,
    });
  };

  return (
    <div
      className="value-propositions-tech relative"
      style={{ backgroundColor: background }}
    >
      <PortfolioCommentIcon title={COMMENT_PROPOSITION} />
      <div className="left">
        <SectionHeader
          templateName={TECH_MODERN_TEMPLATE_NAME}
          title={portfolio.valueProposition.sectionName}
          description={description}
          onChange={(e) =>
            updateSectionField({
              sectionName: 'valueProposition',
              field: 'description',
              name: 'vpText',
              value: e.target.value,
            })
          }
          portfolio={portfolio}
          style={{ color: titleColor }}
        />
        {renderSegmentList(card.solutionTitle)}
        {renderText(product)}
        {renderSegmentList(card.featuresTitle)}
        {renderText(reason)}
        {renderSegmentList(card.pointsTitle)}
        {renderText(
          collection.map((point, index) => (
            <div className="margin-bottom-10" key={point.id || index}>
              {point.description}
            </div>
          )),
        )}
      </div>
      <div className="right" style={{ background: backgroundImage }}>
        <div className="drop">
          <Dropzone
            className="drop-image"
            onDrop={onImageDrop}
            onCropperSave={onImageCropperSave}
            locales={locales}
            image={image}
            coords={imageCoords}
            uploading={uploadingImage}
            aspectRatio={IMAGE_ASPECT_RATIO_VP_TECH}
            background={{ background: backgroundImage }}
            disabled={disabled}
            cropper
          />
        </div>
      </div>
    </div>
  );
};

export default ValuePropositionTech;
