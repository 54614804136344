import React from 'react';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';

const ContactUs = ({
  locales,
  data: { title, emailOrWebsite, description, fullName },
  background,
  isFourthColorPaletteNumber = false,
}) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const textColorDependsOnBackground =
    isFourthColorPaletteNumber && getColorDependsOnBackground(background);

  return (
    <div
      className="contact-us"
      style={{
        backgroundColor: background,
      }}
    >
      <div
        className="current-title title custom-font-title"
        style={{ color: textColorDependsOnBackground }}
      >
        {locales.portfolioWeb.contactUs.title}
      </div>
      <div
        className="subtitle custom-font-body"
        style={{ color: textColorDependsOnBackground }}
      >
        {description}
      </div>
      <div
        className="owner_name custom-font-body"
        style={{ color: textColorDependsOnBackground }}
      >
        {fullName}
      </div>
      <div
        className="email custom-font-body"
        style={{ color: textColorDependsOnBackground }}
      >
        {emailOrWebsite}
      </div>
      <div
        className="footer custom-font-body"
        style={{
          color: textColorDependsOnBackground,
          borderColor: textColorDependsOnBackground,
        }}
      >
        <div className="left">
          {`© ${title}. ${locales.portfolioWeb.contactUs.rightsReserve}`}
        </div>
        <div className="right">
          {locales.portfolioWeb.contactUs.buildCreate}
          <span
            className="company-name custom-font-body"
            style={{ color: textColorDependsOnBackground }}
          >
            {locales.portfolioWeb.contactUs.companyName}
          </span>
        </div>
      </div>
      <div
        className="scroll-top custom-font-body"
        onClick={scrollToTop}
        style={{ color: textColorDependsOnBackground }}
      >
        {locales.portfolioWeb.contactUs.scrollTop}
        <i className="icon-Arrow-Top" />
      </div>
    </div>
  );
};

export default ContactUs;
