import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../components/Header';
import Tabs from '../../components/Tabs';
import TabsCut from '../../components/TabsCut';
import Actions from '../../components/Actions';
import List from '../../components/List';
import Line from '../../components/Line';
import Text from '../../components/Text';
import Title from '../../components/Title';
import TabsList from '../../components/TabsList';
import TabsListItem from '../../components/TabsListItem';
import SvgIcon from '../../components/SvgIcon';
import MobileMenuHeader from '../../components/MobileMenuHeader';

const icons = ['path'];
const iconsComponents = icons.map((e) => <SvgIcon name={e} />);

export default function ProjectedSales(props) {
  const {
    nextClickHandler,
    menuItems,
    selectComponent,
    componentIndex,
    rules,
    backPath,
    locales,
    locales: {
      common,
      tipCustomerSegment,
      caseStudies,
      revenue: {
        name,
        projectedSales: { header, title, text, list, tabs },
      },
    },
  } = props;
  const { collection } = useSelector((state) => state.product);
  const {
    product: { updateSales },
  } = useDispatch();
  const [opened, setOpened] = useState(false);

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon="icon-revenue"
        locales={locales}
        componentIndex={componentIndex}
        className="bmc-page-header--violet"
        tipLocales={tipCustomerSegment}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="FinanceCanvas" title={name} color="violet" />
        }
      />

      <Text align="center">{[text[0]]}</Text>

      <Title text={title[0]} align="left" />

      <List items={list} className="margin-top-40" icons={icons} />

      <Line />

      <h2 className="bmc-page-title">
        <span className="bmc-page-title--violet">{title[1]}</span> {title[2]}
      </h2>

      <Text align="center">{[text[1]]}</Text>

      <Tabs
        tabs={[common.yourStrategy, common.caseStudy]}
        withBlur
        className="margin-top-60 bmc-page-tabs--violet"
      >
        {[
          <div key="strategy">
            <TabsList>
              <TabsListItem
                title={tabs.yourProjectedSales}
                subtitle={tabs.yourProjectedSalesSub}
                icon={iconsComponents[0]}
                className="bmc-page-tabs-content-list-item--wide"
              >
                <div className="margin-top-40">
                  {collection.map((product, productIndex) => (
                    <div key={product.id}>
                      <div className="bmc-page-tabs-content-list-item-small-title margin-top-20">
                        {product.name || tabs.productName}
                      </div>

                      <div className="bmc-page-tabs-content-list-item-inputs-wrapper">
                        <div className="bmc-page-tabs-content-list-item-inputs">
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                            (item, i) => (
                              <div
                                key={item}
                                className="bmc-page-tabs-content-list-item-inputs-input"
                              >
                                <div className="bmc-page-tabs-content-list-item-inputs-input-title">
                                  {tabs.month} {item}
                                </div>

                                <input
                                  className="bmc-page-tabs-content-list-item-inputs-input-text"
                                  onChange={(event) =>
                                    updateSales({
                                      index: i,
                                      value: event.target.value,
                                      id: product.id,
                                    })
                                  }
                                  value={product.sales[i]}
                                  placeholder={tabs.monthPlaceholder}
                                  data-hj-allow
                                  test-key={`projected-sales-inputs-${productIndex}-input-${i}`}
                                />
                              </div>
                            ),
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </TabsListItem>
            </TabsList>
          </div>,

          <Tabs key="tabs" tabs={caseStudies.map((e) => e.name)} black inner>
            {caseStudies.map((caseStudy, index) => (
              <div key={index}>
                <TabsCut
                  opened={opened}
                  setOpened={setOpened}
                  caseStudy={caseStudy}
                />

                {caseStudy.products.map((product, i) => (
                  <TabsList key={i} preview>
                    <TabsListItem
                      title={i === 0 ? tabs.title : null}
                      icon={i === 0 ? iconsComponents[0] : null}
                    >
                      <div className="bmc-page-tabs-content-list-item-small-title margin-top-20">
                        {product.name || tabs.productName}
                      </div>

                      <div className="bmc-page-tabs-content-list-item-inputs-wrapper">
                        <div className="bmc-page-tabs-content-list-item-inputs">
                          {product.sales.map((item, ii) => (
                            <div
                              key={ii}
                              className="bmc-page-tabs-content-list-item-inputs-input"
                            >
                              <div className="bmc-page-tabs-content-list-item-inputs-input-title">
                                {tabs.month} {ii + 1}
                              </div>

                              <div className="bmc-page-tabs-content-list-item-inputs-input-text">
                                {item}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </TabsListItem>
                  </TabsList>
                ))}
              </div>
            ))}
          </Tabs>,
        ]}
      </Tabs>

      <Actions nextClickHandler={nextClickHandler} buttonName={common.next} />
    </div>
  );
}
