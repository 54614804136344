import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { find, merge } from 'lodash';
import {
  NOTIFICATION_TYPES,
  COLORS,
  BLOG_FRIENDLY_TEMPLATE_NAME,
} from '../../../utils/const';

const { red } = COLORS;

const TemplateColorsList = ({
  activeTemplate,
  templateSettings,
  customColors,
  activeColorPalette,
  activeTemplateName,
  disabled = false,
}) => {
  const [customColorTemplate, setCustomColorTemplate] = useState([
    '',
    '',
    '',
    '',
  ]);
  const [redIcons, setRedIcons] = useState({});

  const {
    notification: { addNotification },
  } = useDispatch();

  const {
    portfolioStyle: { updatePortfolioStyleSettings },
  } = useDispatch();

  const {
    notification: { collection },
  } = useSelector((state) => state);

  useEffect(() => {
    if (customColors) setCustomColorTemplate(customColors.split('|'));
    else setCustomColorTemplate(['', '', '', '']);
  }, [templateSettings]);

  useEffect(() => {
    const notification = find(collection, ['text', 'Choose all colors']);
    if (notification) {
      setRedIcons({ fill: red, stroke: red });
    } else {
      setRedIcons({});
    }
  }, [collection]);

  const changeColor = (e) => {
    const obj = {};
    obj[activeTemplate.sysName] = {
      colorPalette: e.currentTarget.value,
    };

    updatePortfolioStyleSettings(merge(templateSettings, obj));
  };

  const changeCustomColor = (e) => {
    const colors = e.currentTarget.value.split('|');
    if (activeTemplateName === BLOG_FRIENDLY_TEMPLATE_NAME) {
      colors.pop();
    }
    if (colors.includes('')) {
      addNotification({
        text: 'Choose all colors',
        type: NOTIFICATION_TYPES.warningBlue,
      });
      return;
    }

    const obj = {};
    obj[activeTemplate.sysName] = {
      customColors: e.currentTarget.value,
      colorPalette: e.currentTarget.value,
    };

    updatePortfolioStyleSettings(merge(templateSettings, obj));
  };

  const isCustomColorPalette = () => {
    let isCustomPalette = true;

    activeTemplate.colorPalette.forEach((item) => {
      if (item.join('|') === activeColorPalette) {
        return (isCustomPalette = false);
      }
    });

    if (activeTemplateName === BLOG_FRIENDLY_TEMPLATE_NAME) {
      customColorTemplate.forEach((item, index) => {
        if (item === '' && index < 3) {
          isCustomPalette = false;
        }
      });
    }

    if (activeTemplateName !== BLOG_FRIENDLY_TEMPLATE_NAME) {
      if (customColorTemplate.includes('')) {
        isCustomPalette = false;
      }
    }

    if (isCustomPalette) {
      const obj = {};
      obj[activeTemplate.sysName] = {
        colorPalette: customColorTemplate.join('|'),
      };

      updatePortfolioStyleSettings(merge(templateSettings, obj));
    }
    return isCustomPalette;
  };

  const customColorAdd = (e) => {
    const targetId = Number(e.target.id);

    const colors = customColorTemplate.map((item, index) => {
      if (index === targetId) return e.target.value;

      return item;
    });

    setCustomColorTemplate(colors);

    const obj = {};
    obj[activeTemplate.sysName] = {
      customColors: colors.join('|'),
    };

    updatePortfolioStyleSettings(merge(templateSettings, obj));
  };

  const isActiveColorPalette = (colors, index) => {
    return activeColorPalette
      ? activeColorPalette === colors.join('|')
      : index === 0;
  };

  const isRenderColors = (index) => {
    return (
      activeTemplateName !== BLOG_FRIENDLY_TEMPLATE_NAME ||
      (activeTemplateName === BLOG_FRIENDLY_TEMPLATE_NAME && index < 3)
    );
  };

  return (
    <div className="templates-vertical">
      <div className="templates-colors">
        <div className="templates-colors-text">Style colors</div>
        <form className="templates-colors-radio">
          {activeTemplate.colorPalette.map((colors, index) => {
            return (
              <label
                htmlFor={`colorPalette-${index}`}
                className="templates-colors-radio-label"
              >
                <input
                  type="radio"
                  className="templates-colors-radio-input"
                  name="color"
                  value={colors.join('|')}
                  id={`colorPalette-${index}`}
                  onChange={changeColor}
                  checked={isActiveColorPalette(colors, index)}
                  disabled={disabled}
                />
                <ul className="templates-colors-radio-list">
                  {colors.map((color) => {
                    return (
                      <li
                        key={color}
                        className="templates-colors-radio-item"
                        style={{
                          background: `${color}`,
                        }}
                      />
                    );
                  })}
                </ul>
              </label>
            );
          })}
          <label htmlFor="customColor" className="templates-colors-radio-label">
            <input
              type="radio"
              className="templates-colors-radio-input"
              name="color"
              value={customColorTemplate.join('|')}
              id={customColorTemplate}
              onChange={changeCustomColor}
              checked={
                activeColorPalette === customColorTemplate.join('|') ||
                isCustomColorPalette()
              }
              disabled={disabled}
            />
            <ul className="templates-colors-radio-list">
              {customColorTemplate.map((item, index) => {
                return (
                  isRenderColors(index) && (
                    <li
                      key={index}
                      className="templates-colors-radio-item"
                      style={{ border: 'none' }}
                    >
                      <label
                        htmlFor={index}
                        className="templates-customColor-label"
                      >
                        {item ? (
                          <div
                            className="templates-colors-radio-item"
                            style={{
                              backgroundColor: `${item}`,
                            }}
                          />
                        ) : (
                          <svg
                            className="templates-colors-radio-icon"
                            style={redIcons}
                            width="24"
                            height="24"
                          >
                            <use href="/images/portfolio/iconAdd.svg#icon-addColorIcon" />
                          </svg>
                        )}
                        <input
                          type="color"
                          className="templates-customColor-input"
                          name={index}
                          id={index}
                          value={customColorTemplate[index]}
                          onChange={customColorAdd}
                          disabled={disabled}
                        />
                      </label>
                    </li>
                  )
                );
              })}
            </ul>
          </label>
        </form>
      </div>
    </div>
  );
};

export default TemplateColorsList;
