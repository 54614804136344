import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import GKGTabsTemplates from '../../getKeepGrow/GKGTabsTemplates';
import {
  COLOURFUL_TEMPLATE_NAME,
  COMMENT_RELATIONSHIPS,
  CURRENT_TEMPLATE_NAME,
} from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import { getCurrentCanvasIcon } from '../../../utils/helpers/getCurrentCanvasIcon';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import SvgIcon from '../../../components/SvgIcon';

const CustomerRelationships = ({
  locales,
  data: { description },
  locales: { portfolio },
  className,
  sectionHeaderTitleColor,
  descriptionColor,
  backgroundSection,
  styleColor,
  firstGKGColor,
  secondGKGColor,
  textColor,
  tabColor,
  backgroundBlock,
  borderBlockColor,
  firstSvgColor,
  secondSvgColor,
  titleTabsColor,
  templateName,
  disabled = false,
}) => {
  const {
    portfolio: { updateSectionField },
  } = useDispatch();
  const {
    getKeepGrow: {
      intro: { gkgTabs },
    },
  } = locales;
  const gkg = useSelector((state) => state.getKeepGrow);
  const { getStrategies, keepStrategies, growStrategies, referralStrategies } =
    gkg;

  const isCurrentTemplate = templateName === CURRENT_TEMPLATE_NAME;
  const isColourfulfunTemplate = templateName === COLOURFUL_TEMPLATE_NAME;

  return (
    <div
      className={`${className} relative`}
      style={{ backgroundColor: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_RELATIONSHIPS} />
      {isCurrentTemplate && getCurrentCanvasIcon('CRCanvas', styleColor)}
      {isColourfulfunTemplate && (
        <>
          <div className="svg-funCRfifteenth" style={{ fill: firstSvgColor }}>
            <SvgIcon name="funCRfifteenth" />
          </div>
          <div className="svg-funCRsixteenth" style={{ fill: secondSvgColor }}>
            <SvgIcon name="funCRsixteenth" />
          </div>
        </>
      )}
      <div className="content">
        <SectionHeader
          templateName={templateName}
          title={portfolio.customerRelationships.sectionName}
          description={description}
          onChange={(e) =>
            updateSectionField({
              sectionName: 'customerRelationships',
              field: 'description',
              name: 'gkgText',
              value: e.target.value,
            })
          }
          portfolio={portfolio}
          className={isCurrentTemplate && 'green-title'}
          style={{ color: sectionHeaderTitleColor }}
          descriptionColor={descriptionColor}
          disabled={disabled}
        />
        <GKGTabsTemplates
          className="margin-top-40"
          portfolioWebMode
          firstColor={firstGKGColor}
          secondColor={secondGKGColor}
          textColor={textColor}
          tabColor={tabColor}
          backgroundBlock={backgroundBlock}
          borderBlockColor={borderBlockColor}
          titleTabsColor={titleTabsColor}
          content={{
            ...gkgTabs,
            title: null,
            getStrategies: getStrategies || [],
            keepStrategies: keepStrategies || [],
            growStrategies: growStrategies || [],
            referralStrategies: referralStrategies || [],
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  get: state.getKeepGrow.getStrategies,
  keep: state.getKeepGrow.keepStrategies,
  grow: state.getKeepGrow.growStrategies,
  referral: state.getKeepGrow.referralStrategies,
});

export default connect(mapStateToProps)(CustomerRelationships);
