import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import EstablishmentList from '../../components/EstablishmentList';
import EstablishmentListTitle from '../../components/EstablishmentListTitle';
import { SELECT_STYLES } from '../../../main/utils/const';

const SelectMultipleEstablishments = ({
  establishments,
  selectedIds,
  onSelect = () => {},
  onDelete = () => {},
  loading,
  labelOfList,
  labelOfSelect,
  placeholderOfSelect,
  avatarVisible,
  getAvatarUrl,
  validateStatus = 'success',
  help = null,
}) => {
  const [selectedEstablishments, setSelectedEstablishments] = useState([]);
  const [establishmentList, setUserList] = useState([]);

  useEffect(() => {
    setSelectedEstablishments(
      establishments.filter((establishment) =>
        selectedIds.some((id) => id === establishment.id),
      ),
    );
    setUserList(
      establishments.filter(
        (establishment) => !selectedIds.some((id) => id === establishment.id),
      ),
    );
  }, [selectedIds, establishments]);

  return (
    <>
      <Form.Item
        label={labelOfSelect}
        className="admin-input-label"
        name="establishment_ids"
        validateStatus={validateStatus}
        help={help}
        rules={[
          {
            required: isEmpty(selectedIds),
            message: 'At least one establishment is required',
          },
        ]}
      >
        <Select
          options={establishmentList.map((establishment) => ({
            label: establishment.name,
            value: establishment.name,
            id: establishment.id,
          }))}
          className="bmc-form-select"
          styles={SELECT_STYLES}
          placeholder={placeholderOfSelect}
          isLoading={loading}
          onChange={({ id }) => onSelect(id)}
        />
      </Form.Item>
      <EstablishmentListTitle title={labelOfList} />
      <EstablishmentList
        establishments={selectedEstablishments}
        avatarVisible={avatarVisible}
        getAvatarUrl={getAvatarUrl}
        onDelete={onDelete}
      />
    </>
  );
};

SelectMultipleEstablishments.propTypes = {
  ...EstablishmentList.propTypes,
  labelOfSelect: PropTypes.string,
  labelOfList: PropTypes.string,
};
export default SelectMultipleEstablishments;
