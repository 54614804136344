import React from 'react';
import Forecast from '../../containers/portfolio/Finance/Forecast';
import { COLOURFUL_TEMPLATE_NAME } from '../../utils/const';
import SvgIcon from '../SvgIcon';

const ForecastTab = ({
  locales,
  setTotalCashOut,
  setTotalCashIn,
  backgroundTable,
  totalColor,
  firstSvgColor,
  secondSvgColor,
  borderCardColor,
  borderVerticalTableColor,
  backgroundRow,
  titleColor,
  secondTitleColor,
  textColor,
  templateName,
}) => {
  return (
    <div className="tab-content">
      {templateName === COLOURFUL_TEMPLATE_NAME && (
        <>
          <div className="svg-funForecastFirst" style={{ fill: firstSvgColor }}>
            <SvgIcon name="funForecastFirst" />
          </div>
          <div
            className="svg-funForecastSecond"
            style={{ fill: secondSvgColor }}
          >
            <SvgIcon name="funForecastSecond" />
          </div>
        </>
      )}
      <Forecast
        locales={locales}
        setTotalCashIn={setTotalCashIn}
        setTotalCashOut={setTotalCashOut}
        portfolioWebMode
        background={backgroundTable}
        totalColor={totalColor}
        borderCardColor={borderCardColor}
        borderVerticalTableColor={borderVerticalTableColor}
        backgroundRow={backgroundRow}
        titleColor={titleColor}
        secondTitleColor={secondTitleColor}
        textColor={textColor}
      />
    </div>
  );
};
export default ForecastTab;
