import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Dropzone from '../../components/Dropzone';
import Textarea from '../../components/Textarea';
import ConfirmationModal from '../../components/ConfirmationModal';
import {
  BLOG_FRIENDLY_TEMPLATE_NAME,
  IMAGE_ASPECT_RATIO_VP_BLOG,
  UNIQUE_SELLING_POINTS_MAX_COUNT,
} from '../../utils/const';
import { renderImage } from '../../utils/helpers/renderImage';

export default function CardBlog({
  locales,
  className,
  editMode = false,
  dropzoneMode = false,
  portfolioMode = false,
  disabled = false,
  background,
  titleStyle,
  buttonStyle,
  lightButtonStyle,
  sliderColor,
  backgroundModal,
  borderImageColor,
  textColor,
  isTemplates = false,
  card: {
    title,
    solution,
    solutionTitle,
    features,
    featuresTitle,
    points,
    pointsTitle,
    image,
    imageCoords,
    icon,
  },
}) {
  const {
    valueProposition: {
      updateAttribute,
      updateAttributeImage,
      updateValuePropositionImageCoords,
    },
    uniqueSellingPoint: {
      addUniqueSellingPoint,
      updateUniqueSellingPoint,
      removeUniqueSellingPoint,
    },
  } = useDispatch();

  const { uploadingImage } = useSelector((state) => state.valueProposition);

  const updatePoints = (id, value) => {
    const currentIndex = points.findIndex((i) => i.id === id);
    updateUniqueSellingPoint({
      id,
      name: 'description',
      index: currentIndex,
      value,
    });
  };

  const onImageDrop = ([file], openCropperHandler) => {
    updateAttributeImage({
      name: 'image',
      value: URL.createObjectURL(file),
      blob: file,
      success: () => {
        openCropperHandler();
      },
    });
  };

  const onImageCropperSave = (croppedAreaPixels, innerCropData, zoom) => {
    updateValuePropositionImageCoords({
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: imageCoords,
    });
  };

  return (
    <div className="bmc-page-card-wrapper">
      <div
        className={classNames(
          'bmc-page-card bmc-page-card--value-proposition-mode-blog',
          className,
          {
            'bmc-page-card--portfolio-mode': portfolioMode,
          },
        )}
        style={{ backgroundColor: background }}
      >
        <div className="bmc-page-card-inner">
          {title && (
            <div className="bmc-page-card-row">
              <img src={icon} className="bmc-page-card-icon" alt="" />
              <div className="bmc-page-card-big-title custom-font-body">
                {title}
              </div>
            </div>
          )}

          <div className="bmc-page-card-row">
            <div
              className="bmc-page-card-segmentList cardTitle custom-font-body"
              style={{ color: textColor }}
            >
              {solutionTitle}
            </div>
            {editMode ? (
              <Textarea
                value={solution}
                delay={700}
                placeholder={locales.portfolio.page.informationPlaceholder}
                className="textarea-current textarea-current-value-text custom-font-body"
                onChange={(e) =>
                  updateAttribute({ name: 'product', value: e.target.value })
                }
              />
            ) : (
              <div
                className="bmc-page-card-text custom-font-body"
                style={{ color: textColor }}
              >
                {solution}
              </div>
            )}
          </div>

          <div className="bmc-page-card-row">
            <div
              className="bmc-page-card-segmentList cardTitle custom-font-body"
              style={{ color: textColor }}
            >
              {featuresTitle}
            </div>
            {editMode ? (
              <Textarea
                value={features}
                delay={700}
                placeholder={locales.portfolio.page.informationPlaceholder}
                className="textarea-current textarea-current-value-text"
                onChange={(e) =>
                  updateAttribute({ name: 'reason', value: e.target.value })
                }
              />
            ) : (
              <div
                className="bmc-page-card-text custom-font-body"
                style={{ color: textColor }}
              >
                {features}
              </div>
            )}
          </div>

          <div className="bmc-page-card-row bmc-page-card-usps">
            <div
              className="bmc-page-card-segmentList cardTitle custom-font-body"
              style={{ color: textColor }}
            >
              {pointsTitle}
            </div>
            {editMode ? (
              <div className="is-full-width">
                {points.map(({ id, description }) => (
                  <div className="is-relative" key={id}>
                    <Textarea
                      key={id}
                      value={description}
                      delay={700}
                      placeholder={
                        locales.portfolio.page.informationPlaceholder
                      }
                      className="textarea-current textarea-current-value-text value-propositions-current-remove"
                      onChange={(e) => updatePoints(id, e.target.value)}
                    />

                    {points.length > 1 && (
                      <ConfirmationModal
                        body="Delete this content. Are you sure?"
                        confirmText="Delete"
                        onConfirm={() => removeUniqueSellingPoint({ id })}
                      >
                        <div className="bmc-page-card-remove">
                          <i className="icon-Delete" />
                        </div>
                      </ConfirmationModal>
                    )}
                  </div>
                ))}
                {points.length < UNIQUE_SELLING_POINTS_MAX_COUNT && (
                  <button
                    onClick={addUniqueSellingPoint}
                    className="bmc-button bmc-button--blue bmc-button--wide"
                  >
                    <i className="icon-Add" />
                    <span>Create USP</span>
                  </button>
                )}
              </div>
            ) : (
              <div
                className="bmc-page-card-text custom-font-body"
                style={{ color: textColor }}
              >
                {points.map((point, index) => (
                  <div className="margin-bottom-10" key={point.id || index}>
                    {point.description}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="bmc-page-card-inner cardRight">
          {editMode || dropzoneMode ? (
            <div className="bmc-page-card-dropzone-blog">
              <Dropzone
                className="bmc-page-card-image-blog"
                background={{ backgroundColor: background }}
                borderImageColor={borderImageColor}
                onDrop={onImageDrop}
                onCropperSave={onImageCropperSave}
                locales={locales}
                image={image}
                coords={imageCoords}
                uploading={uploadingImage}
                aspectRatio={IMAGE_ASPECT_RATIO_VP_BLOG}
                disabled={disabled}
                titleStyle={titleStyle}
                buttonStyle={buttonStyle}
                lightButtonStyle={lightButtonStyle}
                sliderColor={sliderColor}
                backgroundModal={backgroundModal}
                isTemplates={isTemplates}
                cropper
              />
            </div>
          ) : (
            renderImage(
              BLOG_FRIENDLY_TEMPLATE_NAME,
              image,
              background,
              locales.portfolio.valueProposition.sectionName,
              IMAGE_ASPECT_RATIO_VP_BLOG,
              textColor,
              borderImageColor,
            )
          )}
        </div>
      </div>
    </div>
  );
}
