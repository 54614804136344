import {
  AlignmentType,
  HeightRule,
  ImageRun,
  OverlapType,
  Paragraph,
  Table,
  TableAnchorType,
  TableCell,
  TableLayoutType,
  TableRow,
  WidthType,
} from 'docx';
import { chunk, filter, isEmpty } from 'lodash';
import {
  COLORS,
  convertPixel,
  convertPixelToTwip,
  pageMargin,
  pageSize,
  tableWidth,
  transparentBorder,
} from '../Constants';
import {
  buildBgCircle,
  buildSdgListIcon,
  emptyCell,
  marginTableRow,
  textRun,
} from '../helpers/index';

export default async (locales, portfolio, sustainability, sdgs, options) => {
  const listCircleBg = await buildBgCircle(
    options.sustainability.listCircleWidth,
    options.sustainability.listCircleHeight,
    options.sustainability.listCircleColor,
  );

  const buildItemCells = (collectionList) => {
    return filter(collectionList, (item) => !isEmpty(item.sdg)).map((item) => {
      const currentSdgs = sdgs[+item.sdg - 1];

      const sdgIconImage = buildSdgListIcon(
        +item.sdg,
        options.sustainability.iconListColor,
      );

      return new TableCell({
        width: {
          size: convertPixelToTwip(196),
          type: WidthType.DXA,
        },
        borders: transparentBorder(COLORS.WHITE),
        children: [
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new ImageRun({
                data: listCircleBg,
                transformation: {
                  width: convertPixel(169),
                  height: convertPixel(169),
                },
                floating: {
                  horizontalPosition: {
                    offset: 180000,
                  },
                  verticalPosition: {
                    offset: 0,
                  },
                  behindDocument: true,
                },
              }),
            ],
          }),
          new Table({
            float: {
              horizontalAnchor: TableAnchorType.TEXT,
              verticalAnchor: TableAnchorType.TEXT,
              overlap: OverlapType.NEVER,
            },
            width: {
              size: convertPixelToTwip(196),
              type: WidthType.DXA,
            },
            layout: TableLayoutType.AUTOFIT,
            rows: [
              new TableRow({
                height: {
                  value: convertPixelToTwip(154),
                  rule: HeightRule.ATLEAST,
                },
                children: [
                  emptyCell(30, COLORS.WHITE),
                  new TableCell({
                    width: {
                      size: convertPixelToTwip(136),
                      type: WidthType.DXA,
                    },
                    borders: transparentBorder(COLORS.WHITE),
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        spacing: {
                          before: convertPixelToTwip(20),
                          after: convertPixelToTwip(5),
                        },
                        children: [
                          new ImageRun({
                            data: sdgIconImage,
                            transformation: {
                              width: convertPixel(35),
                              height: convertPixel(35),
                            },
                          }),
                        ],
                      }),
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        spacing: {
                          after: convertPixelToTwip(5),
                        },
                        children: [
                          textRun(`Sustainable Development Goal ${item.sdg}`, {
                            font: options.bodyFont,
                            color: options.bodyColor || COLORS.BLACK,
                            bold: true,
                          }),
                        ],
                      }),
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        spacing: {
                          before: convertPixelToTwip(10),
                          after: convertPixelToTwip(10),
                        },
                        children: [
                          textRun(currentSdgs?.title, {
                            font: options.bodyFont,
                            color: options.bodyColor || COLORS.BLACK,
                          }),
                        ],
                      }),
                    ],
                  }),
                  emptyCell(30, COLORS.WHITE),
                ],
              }),
            ],
          }),
        ],
      });
    });
  };

  const dataRows = () => {
    const chankedCollection = chunk(sustainability.collection, 3);

    return chankedCollection.map((collectionList) => {
      return [
        new TableRow({
          height: {
            value: convertPixelToTwip(169),
            rule: HeightRule.ATLEAST,
          },
          children: [
            emptyCell(95, COLORS.WHITE),
            ...buildItemCells(collectionList),
            emptyCell(95, COLORS.WHITE),
          ],
        }),
        marginTableRow(30, 778, COLORS.WHITE, 5),
      ];
    });
  };

  return {
    properties: {
      page: {
        size: pageSize,
        margin: pageMargin,
      },
    },
    children: [
      // table with data
      new Table({
        float: {
          horizontalAnchor: TableAnchorType.TEXT,
          verticalAnchor: TableAnchorType.TEXT,
          overlap: OverlapType.NEVER,
          absoluteVerticalPosition: convertPixelToTwip(-20),
          absoluteHorizontalPosition: 0,
        },
        width: {
          size: convertPixelToTwip(tableWidth - 56),
          type: WidthType.DXA,
        },
        layout: TableLayoutType.AUTOFIT,
        rows: [
          marginTableRow(20, tableWidth - 56, COLORS.PRIMARY, 1),
          // title
          new TableRow({
            height: {
              value: convertPixelToTwip(27),
              rule: HeightRule.ATLEAST,
            },
            children: [
              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth - 56),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.PRIMARY),
                children: [
                  // Title
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      textRun(locales.sustainability.sectionName, {
                        font: options.titleFont,
                        bold: options.sustainability.listPageTitleBold,
                        color: options.titleColor || COLORS.BLACK,
                        size: 24,
                        allCaps: true,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
          marginTableRow(10, tableWidth - 56, COLORS.PRIMARY, 1),
          // description row
          new TableRow({
            height: {
              value: convertPixelToTwip(28),
              rule: HeightRule.ATLEAST,
            },
            children: [
              new TableCell({
                width: {
                  size: convertPixelToTwip(726),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.PRIMARY),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      textRun(portfolio.sustainability.description, {
                        font: options.bodyFont,
                        color: options.bodyColor || COLORS.BLACK,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
          marginTableRow(30, tableWidth - 56, COLORS.PRIMARY, 1),

          // data
          new TableRow({
            children: [
              new TableCell({
                borders: transparentBorder(COLORS.PRIMARY),
                children: [
                  new Table({
                    rows: dataRows().flat(1),
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  };
};
