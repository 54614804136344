import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export default function Menu({ menuItems }) {
  return (
    <div className="bmc-menu bmc-menu--default">
      <div className="bmc-menu-items">
        {menuItems.map((menuItem, index) => (
          <Link key={index} to={menuItem.url}>
            <div
              className={classNames('bmc-menu-items-item', {
                'bmc-menu-items-item--active':
                  window.location.pathname === menuItem.url,
              })}
            >
              <div className="bmc-menu-items-item-active-bar" />
              <div className="bmc-menu-items-item-point" />
              <div className="bmc-menu-items-item-title">
                <div>{menuItem.title}</div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
