import React from 'react';
import { useSelector } from 'react-redux';

import { removeNewLines } from '../../main/utils/helpers';
import Button from '../../main/components/Button';
import SvgIcon from '../../main/components/SvgIcon';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';
import { COLORS } from '../../main/utils/const';

const { black, white, transparentWhite } = COLORS;

const Summary = ({
  locales,
  backgroundSection,
  isFourthColorPaletteNumber = false,
}) => {
  const { projectSummary } = useSelector((state) => state.portfolio);
  const { collection } = useSelector((state) => state.portfolioFile);

  const textColorDependsOnBackground =
    isFourthColorPaletteNumber &&
    getColorDependsOnBackground(backgroundSection);

  return (
    <div
      className="summary-architectural-web"
      style={{ backgroundColor: backgroundSection }}
    >
      <div style={{ fill: textColorDependsOnBackground === white && white }}>
        <SvgIcon name="summary" />
      </div>
      <div className="content">
        <div className="right">
          <div
            className="architectural-title title custom-font-title"
            style={{ color: textColorDependsOnBackground }}
          >
            {locales.portfolioWeb.summary}
          </div>
          <div className="margin-top-40 file">
            {collection.map((file) => (
              <a
                key={file.id}
                href={file.file}
                target="_blank"
                className="custom-font-body"
              >
                <Button
                  icon="icon-Export"
                  className="bmc-button button custom-font-body"
                  title={file.name}
                  style={{
                    color: textColorDependsOnBackground === white && white,
                    backgroundColor:
                      textColorDependsOnBackground === white
                        ? transparentWhite
                        : black,
                    borderColor:
                      textColorDependsOnBackground === white ? white : black,
                  }}
                />
              </a>
            ))}
          </div>
        </div>
        <div
          className="left description custom-font-body"
          style={{ color: textColorDependsOnBackground }}
        >
          {projectSummary.description &&
            removeNewLines(projectSummary.description)}
        </div>
      </div>
    </div>
  );
};

export default Summary;
