import React from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import Select from 'react-select';
import Modal from '../../../main/components/Modal';
import ModalContainer from '../../components/ModalContainer';
import ModalHeader from '../../components/ModalHeader';
import { SELECT_STYLES } from '../../../main/utils/const';
import { Divider } from '../../components/Divider';

const options = [
  { value: 'super admin', label: 'Super Admin' },
  { value: 'admin', label: 'Admin' },
  { value: 'Establishment', label: 'Establishment' },
];

const ModalLecturerSettings = () => {
  return (
    <Modal onBlackscreenClick={() => {}}>
      <ModalContainer>
        <ModalHeader title="Educator Settings" />

        <Form layout="vertical">
          <div className="mt-20 mb-40">
            <Form.Item label="Full Name" className="admin-input-label">
              <Input
                placeholder="Add name of establishment"
                className="admin-input"
              />
            </Form.Item>

            <Form.Item label="Email" className="admin-input-label">
              <Input placeholder="Add user email" className="admin-input" />
            </Form.Item>
            <Row gutter={[40, 20]}>
              <Col span={18}>
                <Form.Item label="Role" className="admin-input-label">
                  <Select
                    options={options}
                    className="bmc-form-select"
                    styles={SELECT_STYLES}
                    placeholder="Choose Type"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Password" className="admin-input-label">
                  <Button>Password Reset</Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Divider />
          <div className="mt-20">
            <Button type="text" danger>
              Delete
            </Button>
          </div>
        </Form>
      </ModalContainer>
    </Modal>
  );
};

export default ModalLecturerSettings;
