import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { SELECT_STYLES } from '../../main/utils/const';

function Select({
  options,
  value,
  onChange,
  disabled = false,
  isMulti = false,
  menuPlacement = 'bottom',
}) {
  return (
    <ReactSelect
      options={options}
      className="bmc-form-select"
      value={value}
      onChange={onChange}
      isSearchable={false}
      styles={SELECT_STYLES}
      isDisabled={disabled}
      isMulti={isMulti}
      menuPlacement={menuPlacement}
    />
  );
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired,
  ),
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
export default Select;
