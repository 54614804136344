import classNames from 'classnames';
import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { SELECT_V3_STYLES } from '../../../../utils/const';
import IconBlock from './IconBlock';

const CustomOption = ({ children, data, isSelected, ...props }) => {
  return (
    <components.Option {...props}>
      <div className="select-v3__option">
        <div className={classNames('select-v3__label', data.value)}>
          {children}
        </div>
        <IconBlock active={isSelected} />
      </div>
    </components.Option>
  );
};

const SelectV3 = ({
  options = [],
  value = [],
  onChange = () => {},
  textColor,
  textStyle,
}) => {
  const [isOpened, setIsOpened] = useState(false);

  const handleChange = (selectedOptions) => onChange(selectedOptions);

  const handleOpenMenu = () => {
    setIsOpened(true);
  };

  const handleCloseMenu = () => {
    setIsOpened(false);
  };

  const toggleOpen = () => {
    if (isOpened) handleCloseMenu();
    else handleOpenMenu();
  };

  return (
    <div className="select-v3">
      <div className="select-v3__options">
        <div
          className={`${textStyle} custom-font-body`}
          onClick={toggleOpen}
          style={{ color: textColor }}
        >
          BMC Blocks Tested
        </div>
        <Select
          options={options}
          value={value}
          styles={SELECT_V3_STYLES}
          className={`${textStyle} custom-font-body`}
          onChange={handleChange}
          components={{ Option: CustomOption }}
          isSearchable={false}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isMulti
        />
      </div>
      <div className="bmc-blocks custom-font-title">
        {value.map((option) => (
          <div
            className={`bmc-blocks__block bmc-blocks__block-${option.value} custom-font-title`}
            key={option.value}
          >
            {option.value}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectV3;
