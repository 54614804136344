const bmcBlockTestedOptions = [
  { label: 'Customer Segment', value: 'CS' },
  { label: 'Value Proposition', value: 'VP' },
  { label: 'Get Keep Grow', value: 'CR' },
  { label: 'Channel', value: 'CH' },
  { label: 'Key Partners', value: 'KP' },
  { label: 'Key Activity', value: 'KA' },
  { label: 'Key Resources', value: 'KR' },
  { label: 'Cost', value: 'CO' },
  { label: 'Revenue', value: 'RE' },
  { label: 'Sustainability', value: 'SU' },
];

export default bmcBlockTestedOptions;
