import React, { useRef } from 'react';
import { digitToString } from '../../main/utils/helpers';

const Landing1 = ({ test, locales: { landing1: pageLocales } }) => {
  const learnMoreSection = useRef(null);
  const handleScroll = () => {
    window.scrollTo({
      top: learnMoreSection.current.offsetTop,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <div className="desktop-landing1">
        <div
          className="desktop-landing1--main-block"
          style={{ backgroundImage: `url(${test.image.medium.url})` }}
        >
          <h1 className="desktop-landing1--logo">{test.data.company_name}</h1>
          <div className="desktop-landing1--info-block">
            <h2>{test.data.headline}</h2>
            <p>{test.data.explain}</p>
            <button onClick={handleScroll}>{pageLocales.button}</button>
          </div>
        </div>
        <div className="desktop-landing1--learn-more" ref={learnMoreSection}>
          <h3 className="desktop-landing1--learn-more-title">
            {pageLocales.learnMoreTitle}
          </h3>
          <ul className="desktop-landing1--learn-more-list">
            {pageLocales.list.map((item, index) => (
              <li key={index}>
                <span>{index + 1}</span>
                <p>{test.data[`thing${digitToString[index + 1]}`]}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Landing1;
