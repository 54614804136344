import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Header from '../../components/Header';
import Title from '../../components/Title';
import Line from '../../components/Line';
import List from '../../components/List';
import Tabs from '../../components/Tabs';
import Actions from '../../components/Actions';
import Textarea from '../../components/Textarea';
import Img from '../../components/Img';
import MobileMenuHeader from '../../components/MobileMenuHeader';
import { updateCollectionById } from '../../utils/helpers';

export default function Problems(props) {
  const {
    nextClickHandler,
    menuItems,
    selectComponent,
    componentIndex,
    rules,
    backPath,
    locales,
    locales: {
      common,
      ideation: {
        name,
        tabs: { items },
        problems: { header, title, text, list, tabs, actions },
        caseStudies,
      },
    },
  } = props;
  const {
    ideation: {
      idea: {
        data: { ideas },
      },
    },
  } = useSelector((state) => state);
  const {
    ideation: { updateIdeaData },
  } = useDispatch();

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon="icon-VP"
        locales={locales}
        componentIndex={componentIndex}
        className="bmc-page-header--blue"
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="ideationActive" title={name} color="blue" />
        }
      />

      <div className="bmc-page-text">
        <p>
          <span className="bmc-page-text--bold"> {text[0]}</span>
        </p>
        <p>{text[1]}</p>
        <p>{text[2]}</p>
        <p>{text[3]}</p>
        <p>{text[4]}</p>
      </div>

      <Title text={title[0]} align="left" />

      <List items={list} icons={['cloud']} />

      <Line />

      <h2 className="bmc-page-title">
        <span className="bmc-page-title--blue">{title[1]}</span> {title[2]}
      </h2>

      <div className="bmc-page-text bmc-page-text--center">{text[5]}</div>

      <Tabs
        tabs={items}
        className="margin-wide-40-lg margin-top-60 bmc-page-tabs--blue"
      >
        <div className="ideation-problems">
          {ideas.map((idea) => (
            <div className="ideation-problems-item" key={idea.id}>
              <Img src={idea.url} aspectRatio={1} />
              <Textarea
                rows={3}
                value={idea.problem || ''}
                delay={300}
                onChange={(e) =>
                  updateIdeaData({
                    ideas: updateCollectionById(ideas, idea.id, {
                      problem: e.target.value,
                    }),
                  })
                }
                placeholder={tabs.describeTheProblem}
                className="bmc-form-textarea"
              />
            </div>
          ))}
        </div>

        <div className="ideation-problems">
          {caseStudies.map((idea, index) => (
            <div className="ideation-problems-item" key={index}>
              <Img src={idea.url} aspectRatio={1} />
              <div className="ideation-problems-item-text">{idea.problem}</div>
            </div>
          ))}
        </div>
      </Tabs>

      <Actions
        nextClickHandler={nextClickHandler}
        buttonName={common.next}
        tooltipIfDisabled={actions.tooltipIfDisabled}
      />
    </div>
  );
}
