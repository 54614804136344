import React from 'react';
import Lazyload from 'react-lazyload';
import { useSelector } from 'react-redux';
import { find } from 'lodash';

import CustomerSegmentBold from './CustomerSegmentBold';
import ValuePropositionBold from './ValuePropositionBold';
import ChannelsBold from './ChannelsBold';
import ContactUsBold from './ContactUsBold';
import { StyledWrapper } from '../../../utils/helpers';
import { getColorTemplate } from '../../../utils/helpers/getColorTemplate';
import {
  BOLD_TEMPLATE_NAME,
  BUTTON_LIGHT_MODAL_STYLE_BOLD,
  BUTTON_MODAL_STYLE_BOLD,
  COLORS,
  TITLE_MODAL_STYLE_BOLD,
} from '../../../utils/const';
import Introduction from '../mainTemplateComponents/Introduction';
import Summary from '../mainTemplateComponents/Summary';
import Team from '../mainTemplateComponents/Team';
import BMC from '../mainTemplateComponents/BMC';
import Testing from '../mainTemplateComponents/Testing';
import Desirability from '../mainTemplateComponents/Desirability';
import Feasibility from '../mainTemplateComponents/Feasibility';
import Viability from '../mainTemplateComponents/Viability';
import CompetitorAnalysis from '../mainTemplateComponents/CompetitorAnalysis';
import CustomerRelationships from '../mainTemplateComponents/CustomerRelationships';
import KeyActivities from '../mainTemplateComponents/KeyActivities';
import Finance from '../mainTemplateComponents/Finance';
import Sustainability from '../mainTemplateComponents/Sustainability';

const { thirdGreen } = COLORS;

const BoldTemplate = ({
  locales,
  portfolio,
  bmc,
  testing,
  disabled = false,
}) => {
  const {
    portfolioStyle: { templateSettings, activeTemplateName },
    portfolioTemplate: { collection },
  } = useSelector((state) => state);

  const currentSettings = templateSettings
    ? templateSettings[activeTemplateName]
    : {};

  const activeTemplate = find(collection, ['sysName', activeTemplateName]);
  const colorTemplate = getColorTemplate(
    templateSettings,
    activeTemplateName,
    activeTemplate,
  );

  const [firstColor, secondColor, thirdColor, fourthColor] = colorTemplate;
  const isSecondTemplate = firstColor === thirdGreen;

  return (
    <div className="bmc-canvas margin-top-30">
      <div className="bmc-page fullwidth bold" style={{ padding: '0' }}>
        <StyledWrapper
          customtitlefont={currentSettings?.customTitleFont}
          customtitlecolor={currentSettings?.customTitleColor}
          custombodyfont={currentSettings?.customBodyFont}
          custombodycolor={currentSettings?.customBodyColor}
        >
          <Introduction
            locales={locales}
            data={portfolio.introduction}
            titleColor={firstColor}
            textColor={secondColor}
            backgroundImage={thirdColor}
            backgroundSection={fourthColor}
            sliderColor={firstColor}
            backgroundModal={fourthColor}
            buttonSecondColor={fourthColor}
            buttonFirstColor={firstColor}
            backgroundSecondButton={firstColor}
            templateName={BOLD_TEMPLATE_NAME}
            disabled={disabled}
          />
          <Summary
            locales={locales}
            data={portfolio.projectSummary}
            titleColor={firstColor}
            textColor={secondColor}
            colorStyle={firstColor}
            backgroundSection={fourthColor}
            fileTextColor={fourthColor}
            templateName={BOLD_TEMPLATE_NAME}
            disabled={disabled}
          />
          <Lazyload>
            <Team
              locales={locales}
              data={portfolio.team}
              titleColor={firstColor}
              textColor={secondColor}
              teamNameColor={thirdColor}
              positionColor={firstColor}
              backgroundSection={fourthColor}
              backgroundImage={thirdColor}
              sliderColor={firstColor}
              backgroundModal={fourthColor}
              buttonSecondColor={fourthColor}
              buttonFirstColor={firstColor}
              backgroundSecondButton={firstColor}
              templateName={BOLD_TEMPLATE_NAME}
              disabled={disabled}
            />
          </Lazyload>
          {bmc && (
            <Lazyload>
              <BMC
                locales={locales}
                data={portfolio.bmc}
                className="bm-bold-canvas section"
                sectionClassName="bmc-canvas-section--bold"
                textStyle="text-card-bold"
                textWithImage="bmc-page-compcard-item-text-template bmc-page-compcard-item-text-template--with-image"
                titleStyle={TITLE_MODAL_STYLE_BOLD}
                buttonStyle={BUTTON_MODAL_STYLE_BOLD}
                titleColor={firstColor}
                sectionHeaderTitleColor={firstColor}
                descriptionColor={secondColor}
                titleBMCSectionColor={
                  isSecondTemplate ? firstColor : thirdColor
                }
                textColor={secondColor}
                borderSectionColor={thirdColor}
                iconFill={isSecondTemplate ? firstColor : fourthColor}
                backgroundIcon={isSecondTemplate ? fourthColor : firstColor}
                backgroundItems={thirdColor}
                backgroundSection={fourthColor}
                backgroundMainSection={fourthColor}
                backgroundBMC={fourthColor}
                styleColor={firstColor}
                backgroundRow={`${thirdColor}80`}
                backgroundImage={thirdColor}
                buttonColor={fourthColor}
                backgroundButton={firstColor}
                templateName={BOLD_TEMPLATE_NAME}
                disabled={disabled}
              />
            </Lazyload>
          )}
          {testing && (
            <Lazyload>
              <Testing
                locales={locales}
                data={portfolio.testing}
                className="testing testing-bold section"
                textCardStyle="text-card-bold"
                testListStyle="tests-list-bold"
                sectionHeaderTitleColor={firstColor}
                descriptionColor={secondColor}
                noTestsColor={secondColor}
                titleColor={firstColor}
                textColor={secondColor}
                backgroundSection={{ backgroundColor: fourthColor }}
                backgroundRow={fourthColor}
                backgroundCard={fourthColor}
                borderCardColor={thirdColor}
                testTypeColor={thirdColor}
                inputColor={firstColor}
                buttonCardColor={fourthColor}
                backgroundButton={firstColor}
                linkColor={firstColor}
                rateColor={firstColor}
                titleCardStyle={TITLE_MODAL_STYLE_BOLD}
                buttonCardStyle={BUTTON_MODAL_STYLE_BOLD}
                templateName={BOLD_TEMPLATE_NAME}
                disabled={disabled}
              />
            </Lazyload>
          )}
          {bmc && (
            <>
              <Desirability
                locales={locales}
                data={portfolio.desirabilitySection}
                className="desirability-bold section"
                backgroundSection={firstColor}
                sectionHeaderTitleColor={thirdColor}
                descriptionColor={isSecondTemplate ? fourthColor : thirdColor}
                templateName={BOLD_TEMPLATE_NAME}
                disabled={disabled}
              />
              <Lazyload>
                <CustomerSegmentBold
                  locales={locales}
                  data={portfolio.customerSegment}
                  background={fourthColor}
                  titleColor={firstColor}
                  textColor={secondColor}
                  backgroundImage={thirdColor}
                  segmentListTitleStyle={thirdColor}
                  sliderColor={firstColor}
                  backgroundModal={fourthColor}
                  buttonSecondColor={fourthColor}
                  buttonFirstColor={firstColor}
                  backgroundSecondButton={firstColor}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <ValuePropositionBold
                  locales={locales}
                  data={portfolio.valueProposition}
                  titleColor={firstColor}
                  textColor={secondColor}
                  subtitleColor={thirdColor}
                  background={fourthColor}
                  backgroundImage={thirdColor}
                  sliderColor={firstColor}
                  backgroundModal={fourthColor}
                  buttonSecondColor={fourthColor}
                  buttonFirstColor={firstColor}
                  backgroundSecondButton={firstColor}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <CompetitorAnalysis
                  locales={locales}
                  data={portfolio.competitorAnalysis}
                  className="competitor-analysis-bold section"
                  sectionHeaderTitleColor={firstColor}
                  descriptionColor={secondColor}
                  titleColor={firstColor}
                  textColor={secondColor}
                  headTableColor={thirdColor}
                  borderTableColor={thirdColor}
                  backgroundImage={thirdColor}
                  backgroundSection={fourthColor}
                  backgroundHeadTable={fourthColor}
                  backgroundTable={fourthColor}
                  sliderColor={firstColor}
                  backgroundModal={fourthColor}
                  buttonSecondColor={fourthColor}
                  buttonFirstColor={firstColor}
                  backgroundSecondButton={firstColor}
                  titleStyle={TITLE_MODAL_STYLE_BOLD}
                  buttonStyle={BUTTON_MODAL_STYLE_BOLD}
                  lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_BOLD}
                  templateName={BOLD_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <CustomerRelationships
                  locales={locales}
                  data={portfolio.customerRelationships}
                  className="customer-relationships-bold section"
                  sectionHeaderTitleColor={firstColor}
                  descriptionColor={secondColor}
                  textColor={secondColor}
                  tabColor={secondColor}
                  firstGKGColor={firstColor}
                  secondGKGColor={`${firstColor}80`}
                  titleTabsColor={thirdColor}
                  borderBlockColor={thirdColor}
                  backgroundSection={fourthColor}
                  backgroundBlock={fourthColor}
                  templateName={BOLD_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <ChannelsBold
                  locales={locales}
                  data={portfolio.channels}
                  titleColor={firstColor}
                  textColor={secondColor}
                  styleColor={firstColor}
                  titleTabsColor={thirdColor}
                  backgroundSection={fourthColor}
                  backgroundImage={thirdColor}
                  sliderColor={firstColor}
                  backgroundModal={fourthColor}
                  buttonSecondColor={fourthColor}
                  buttonFirstColor={firstColor}
                  backgroundSecondButton={firstColor}
                  disabled={disabled}
                />
              </Lazyload>
              <Feasibility
                locales={locales}
                data={portfolio.feasibility}
                className="feasibility-bold section"
                backgroundSection={firstColor}
                sectionHeaderTitleColor={thirdColor}
                descriptionColor={isSecondTemplate ? fourthColor : thirdColor}
                templateName={BOLD_TEMPLATE_NAME}
                disabled={disabled}
              />
              <Lazyload>
                <KeyActivities
                  locales={locales}
                  data={portfolio.keyActivities}
                  className="key-activities-bold section relative"
                  sectionHeaderTitleColor={firstColor}
                  descriptionColor={secondColor}
                  titleActivityColor={thirdColor}
                  textActivityColor={secondColor}
                  circleActivityColor={firstColor}
                  backgroundSection={fourthColor}
                  borderCardColor={thirdColor}
                  titleResourcesColor={thirdColor}
                  textResourcesColor={secondColor}
                  templateName={BOLD_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
              <Viability
                locales={locales}
                data={portfolio.viability}
                className="viability-bold section"
                backgroundSection={firstColor}
                sectionHeaderTitleColor={thirdColor}
                descriptionColor={isSecondTemplate ? fourthColor : thirdColor}
                templateName={BOLD_TEMPLATE_NAME}
                disabled={disabled}
              />
              <Lazyload>
                <Finance
                  locales={locales}
                  data={portfolio.finance}
                  className="portfolio-finance-bold portfolio-finance-bold-temp section"
                  sectionHeaderTitleColor={firstColor}
                  descriptionColor={secondColor}
                  titleUnitColor={firstColor}
                  textUnitColor={secondColor}
                  titleForecastColor={firstColor}
                  secondTitleForecast={thirdColor}
                  textForecastColor={secondColor}
                  firstChartColor={firstColor}
                  secondChartColor={thirdColor}
                  titleChartColor={firstColor}
                  textChartColor={secondColor}
                  borderCardColor={thirdColor}
                  borderVerticalTableColor={thirdColor}
                  borderVerticalTableUnitColor={thirdColor}
                  backgroundSection={fourthColor}
                  totalColor={thirdColor}
                  secondTitleUnitColor={thirdColor}
                  tableTitleUnitColor={thirdColor}
                  tabColor={firstColor}
                  activeTabColor={firstColor}
                  templateName={BOLD_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <Sustainability
                  locales={locales}
                  data={portfolio.sustainability}
                  className="sustainability-bold section"
                  cardClassName="bmc-page-suscard-bold bmc-page-suscard--section"
                  sliderStyles="sdg-card-bold"
                  iconTableStyles="sdg-card-bold-icon"
                  sectionHeaderTitleColor={firstColor}
                  descriptionColor={secondColor}
                  backgroundSection={fourthColor}
                  backgroundSliderColor={fourthColor}
                  titleTableColor={thirdColor}
                  textTableColor={secondColor}
                  textSliderColor={secondColor}
                  arrowColor={secondColor}
                  iconFill={firstColor}
                  iconFillTable={firstColor}
                  backgroundIcon={`${firstColor}80`}
                  borderTableColor={thirdColor}
                  borderCardColor={thirdColor}
                  templateName={BOLD_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
            </>
          )}
          <ContactUsBold
            locales={locales}
            data={portfolio.contactUs}
            titleColor={isSecondTemplate ? thirdColor : firstColor}
            textColor={isSecondTemplate ? fourthColor : secondColor}
            backgroundSection={isSecondTemplate ? firstColor : fourthColor}
            backgroundButton={isSecondTemplate ? thirdColor : firstColor}
            textButtonColor={fourthColor}
            disabled={disabled}
          />
        </StyledWrapper>
      </div>
    </div>
  );
};

export default BoldTemplate;
