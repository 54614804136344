import React from 'react';

import UserBar from '../../main/containers/bmc/UserBar';
import ChatContainer from '../../main/containers/chat';

export default function Container({
  title,
  locales,
  children,
  withChat = false,
}) {
  return (
    <div className="bmc-canvas-container">
      <div className="bmc-canvas-menu">
        <a href="/" className="bmc-canvas-menu-logo">
          <i className="icon-logo" />
        </a>
      </div>

      <div className="bmc-canvas-container-block">
        <div className="bmc-canvas-container-block-top">
          <div className="bmc-canvas-container-block-title">{title}</div>
          <UserBar locales={locales} />
        </div>

        <div className="bmc-canvas-container-block-content">
          {withChat && (
            <div className="bmc-canvas-container-block-section__chat">
              <ChatContainer />
            </div>
          )}
          <div className="bmc-canvas-container-block-section__content">
            <div className="admin-main">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
