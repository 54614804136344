import { isAdminRole, isSuperAdmin, isStudent } from './helpers';

export function hasDeleteUser(user, currentUser) {
  return isSuperAdmin(currentUser) && isStudent(user);
}

export function hasAccessResetPassword(user) {
  return isAdminRole(user);
}

export function hasAccessChangeClassroom(user) {
  return isAdminRole(user);
}

export function hasAccessUpdateFirstName(user) {
  return isSuperAdmin(user);
}

export function hasAccessUpdateSurname(user) {
  return isSuperAdmin(user);
}

export function hasAccessUpdateEstablishment(user) {
  return isSuperAdmin(user);
}

export function hasAccessUpdateEmail(user) {
  return isSuperAdmin(user);
}

export function hasAccessUpdateRole(user) {
  return isSuperAdmin(user);
}

export function hasAccessUpdateUserImage(user) {
  return isSuperAdmin(user);
}
export function hasAccessSettingsStudent(user) {
  return isAdminRole(user);
}
