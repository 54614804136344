import { v4 as uuidv4 } from 'uuid';

import { NOTIFICATION_TYPES, NOTIFICATION_DELAY } from '../utils/const';

const buildNotification = ({
  id = uuidv4(),
  text,
  permanent = false,
  type = NOTIFICATION_TYPES.danger,
}) => {
  return { id, type, text, permanent };
};

const notification = {
  state: {
    collection: [],
  },
  reducers: {
    createNotificationReducer({ collection }, payload) {
      return { collection: [...collection, payload] };
    },
    removeNotificationReducer({ collection }, id) {
      return { collection: collection.filter((item) => item.id !== id) };
    },
  },
  effects: (dispatch) => ({
    addNotification(payload, { notification: { collection } }) {
      const newNotification = buildNotification(payload);

      if (!collection.some((e) => e.id === payload.id)) {
        dispatch.notification.createNotificationReducer(newNotification);
      }

      if (!newNotification.permanent) {
        setTimeout(() => {
          dispatch.notification.removeNotificationReducer(newNotification.id);
        }, NOTIFICATION_DELAY);
      }
    },
    removeNotification(id) {
      dispatch.notification.removeNotificationReducer(id);
    },
  }),
};

export default notification;
