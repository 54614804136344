import React from 'react';

import SigninForm from './SigninForm';
import OnboardingInfo from '../signUp/OnboardingInfo';

const SignIn = ({ locales }) => {
  const { onboarding: pageLocales } = locales;

  return (
    <main className="sign-up-page">
      <section className="sign-up-form-section">
        <h1 className="sign-up-form-section__logo">
          {pageLocales.titles.logo}
        </h1>
        <div className="sign-up-form-section__subtitle">
          {pageLocales.subtitles.step1}
        </div>
        <SigninForm locales={locales} />
      </section>
      <section className="sign-up-page__info-section">
        <OnboardingInfo locales={locales} />
      </section>
    </main>
  );
};

export default SignIn;
