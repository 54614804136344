//
// returns array of collections => [{component: SomeComponent, url: "some-url-for-component"}, ...]
//
export const prepareComponents = (components, bmcId, menu, routeHelper) => {
  const sections = menu.map((e) => e.sectionName);

  return components.map((component, i) => ({
    component,
    url: routeHelper(bmcId, sections[i]),
  }));
};
