import React from 'react';
import { useHistory, useParams } from 'react-router';

import Header from '../../../../../../components/Header';
import Text from '../../../../../../components/Text';
import Title from '../../../../../../components/Title';
import Line from '../../../../../../components/Line';
import TestCard from '../../../../Components/TestCard';
import Actions from '../../../../../../components/Actions';
import MobileMenuHeader from '../../../../../../components/MobileMenuHeader';
import * as AppRoute from '../../../../../../utils/appRoutes';

const HomepageAnalyse = ({
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
  locales,
  test,
  goToHandler,
  locales: {
    testing: {
      name,
      titles,
      summary: { header, text, title },
    },
  },
}) => {
  const history = useHistory();
  const { bmcId: bmcIdFromParams } = useParams();

  return (
    <div className="bmc-page testing">
      <Header
        className="bmc-page-header--blue"
        subtitle={name}
        icon="icon-VP"
        locales={locales}
        title={header}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        componentIndex={componentIndex}
        mobileMenuHeader={
          <MobileMenuHeader icon="testingActive" title={name} color="blue" />
        }
        rules={rules}
        backPath={backPath}
      />
      <Text>{[text[0]]}</Text>
      <Line />

      <Title align="center" text={title[0]} />
      {test && test.id && (
        <TestCard
          locales={locales}
          titles={titles}
          goToHandler={goToHandler}
          test={test}
          isPreview
          bmcSectionMode
          isEditable={false}
        />
      )}
      <Actions
        buttonName="Done"
        nextClickHandler={() => history.push(AppRoute.testing(bmcIdFromParams))}
      />
    </div>
  );
};

export default HomepageAnalyse;
