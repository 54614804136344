import React from 'react';

import UserAvatar from '../../components/UserAvatar';
import ConfirmationModal from '../../components/ConfirmationModal';

export default function TeamMembers({
  removeBmcUser,
  users,
  locales: { teams },
  isOwnerChoose = false,
  onBmcOwnerChoose,
  ownerId,
  onChooseConfirm,
}) {
  const handleRemoveConfirm = (userId) => {
    if (ownerId && userId === ownerId) {
      onBmcOwnerChoose(userId);
    } else {
      removeBmcUser(userId);
    }
  };

  return (
    <>
      {users.length > 0 && (
        <>
          <div className="bmc-form-title">{teams.teamMembers}</div>

          <div className="bmc-team-members">
            {users.map((user) => (
              <div key={user.id} className="bmc-team-members-item">
                <UserAvatar
                  user={user}
                  className="bmc-team-members-item-icon user-avatar--orange"
                />

                <div className="bmc-team-members-item-title">
                  <div className="bmc-team-members-item-title-text">
                    {user.name}
                  </div>
                  <div className="bmc-team-members-item-title-sub">
                    {user.email}
                  </div>
                </div>

                {!isOwnerChoose && users.length > 1 && (
                  <ConfirmationModal
                    body="Are you sure?"
                    confirmText="Delete"
                    onConfirm={() => handleRemoveConfirm(user.id)}
                  >
                    <div className="bmc-team-members-item-action">
                      {teams.remove}
                    </div>
                  </ConfirmationModal>
                )}
                {isOwnerChoose && (
                  <ConfirmationModal
                    body="Are you sure to choose this user as an owner ?"
                    confirmText="Choose"
                    onConfirm={() => onChooseConfirm(ownerId, user.id)}
                  >
                    <div className="bmc-team-members-item-action">Choose</div>
                  </ConfirmationModal>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}
