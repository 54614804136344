import React from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

export default function ImageUploader({
  onChange,
  className,
  title,
  icon,
  testKey,
  borderButtonColor,
  backgroundButtonColor,
  textButtonColor,
  disabled = false,
}) {
  const uid = `image-uploader-${uuidv4()}`;

  return (
    <label
      htmlFor={uid}
      className={classNames(className, 'custom-font-body')}
      style={{
        borderColor: borderButtonColor,
        color: textButtonColor,
        backgroundColor: backgroundButtonColor,
      }}
    >
      {icon && <i className={icon} />}
      {title}
      <input
        id={uid}
        type="file"
        // eslint-disable-next-line react/no-unknown-property
        test-key={testKey || 'image-uploader'}
        onChange={(event) => {
          onChange(event.target.files, event);
          event.target.value = null;
        }}
        disabled={disabled}
      />
    </label>
  );
}
