import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import _ from 'lodash';

import Modal from '../../components/Modal';
import Button from '../../components/Button';
import { SELECT_STYLES } from '../../utils/const';
import * as Route from '../../utils/serverRoutes';
import TeamMembers from './TeamMembers';
import { transformResponseData } from '../../utils/helpers';
import makeRequest from '../../utils/makeRequest';

export const inputOptions = (collection, users) =>
  collection
    .filter((item) =>
      users.find((user) => user.email !== item.attributes.email),
    )
    .map((item) => {
      return {
        label: item.attributes.email,
        value: transformResponseData(item),
      };
    });

export default function CreateBmcModal({
  onClose,
  locales,
  locales: { teams, common },
}) {
  const defaultSelectValue = { label: '', value: '' };
  const [projectName, setProjectName] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [selectValue, setSelectValue] = useState(defaultSelectValue);
  const [options, setOptions] = useState([]);
  const [users, setUsers] = useState([]);

  const {
    bmc: { addBmc },
  } = useDispatch();

  const dispatch = useDispatch();

  const {
    user: { current },
    bmc: {
      current: { id: currentBmcId },
    },
  } = useSelector((state) => state);

  const searchBmcUsers = (value) => {
    setInputValue(value);

    if (value) {
      makeRequest({
        dispatch,
        url: Route.searchSuitableStudent(value, currentBmcId),
        success: (response) => {
          setOptions(inputOptions(response.data.data, [...users, current]));
        },
      });
    }
  };

  const removeBmcUser = (id) => {
    if (window.confirm(common.areYouSure)) {
      setUsers(users.filter((e) => e.id !== id));
    }
  };

  const changeSelect = ({ value }) => {
    setInputValue('');
    setSelectValue(defaultSelectValue);
    setUsers(_.uniqBy([...users, value], 'id'));
  };

  const createProject = async () => {
    const newProject = await addBmc({
      name: projectName,
      userEmails: users.map((e) => e.email),
    });

    if (newProject) {
      onClose();
      window.location.href = `/bmcs/${newProject.id}`;
    }
  };

  return (
    <Modal onBlackscreenClick={onClose} className="bmc-modal--form">
      <div className="bmc-modal-container">
        <div className="bmc-modal-header">
          <div className="bmc-modal-header-title">
            {teams.createYourNewProject}
          </div>
          <div className="bmc-modal-header-action" onClick={onClose}>
            <i className="icon-Cancel" />
          </div>
        </div>

        <div className="bmc-form">
          <div className="bmc-form-fieldset">
            <label htmlFor="project-name" className="bmc-form-label">
              <span>*</span> {teams.projectName}
            </label>
            <input
              id="project-name"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              className="bmc-form-input"
              placeholder={teams.projectNamePlaceholder}
              data-hj-allow
            />
          </div>

          <div className="bmc-form-fieldset margin-top-20 margin-bottom-20">
            <label htmlFor="email" className="bmc-form-label">
              {teams.orInviteTeammatesViaEmail}
            </label>
            <div className="bmc-form-label-sub">
              {teams.orInviteTeammatesViaEmailSub}
            </div>
            <div className="bmc-form-label-sub">
              {teams.orInviteTeammatesViaEmailNote}
            </div>
            <Select
              id="email"
              className="bmc-form-select"
              styles={SELECT_STYLES}
              options={options}
              inputValue={inputValue}
              value={selectValue}
              placeholder={teams.orInviteTeammatesViaEmailPlaceholder}
              onInputChange={searchBmcUsers}
              onChange={changeSelect}
              noOptionsMessage={() => teams.noOptionsMessage}
            />
          </div>

          <TeamMembers
            users={users}
            removeBmcUser={removeBmcUser}
            locales={locales}
          />

          <Button
            title={teams.createProject}
            className="bmc-button--primary"
            disabled={!projectName}
            wide
            onClick={createProject}
          />
        </div>
      </div>
    </Modal>
  );
}
