import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const EstablishmentBlockList = ({ establishmentId }) => {
  const {
    blocks: { data },
  } = useSelector((state) => state);

  const {
    blocks: { fetchBlock },
  } = useDispatch();

  useEffect(() => {
    if (establishmentId) {
      fetchBlock({
        by: 'establishments',
        byId: establishmentId,
        pageName: 'admin',
      });
    }
  }, [establishmentId]);

  return (
    <div className="establishment-block-list">
      <div className="establishment-block-list__row">
        <div className="establishment-block establishment-block__fg3">
          <div className="establishment-block__header">Students</div>
          <div className="establishment-block__items">
            <div className="establishment-block__item">
              <div className="establishment-block__value">
                {data.totalProject}
              </div>
              <div className="establishment-block__title">Total projects</div>
            </div>
            <div className="establishment-block__item">
              <div className="establishment-block__value">
                {data.totalStudents}
              </div>
              <div className="establishment-block__title">Total students</div>
            </div>
            <div className="establishment-block__item">
              <div className="establishment-block__value">
                {data.totalLastActiveStudents}
              </div>
              <div className="establishment-block__title">
                Active Last 30 days
              </div>
            </div>
          </div>
        </div>
        <div className="establishment-block establishment-block__fg3">
          <div className="establishment-block__header">Total progress</div>
          <div className="establishment-block__items">
            <div className="establishment-block__item">
              <div className="establishment-block__value">
                {data.ideasCount}
              </div>
              <div className="establishment-block__title">Ideas created</div>
            </div>
            <div className="establishment-block__item">
              <div className="establishment-block__value">
                {data.canvasProgress}
              </div>
              <div className="establishment-block__title">Canvas progress</div>
            </div>
            <div className="establishment-block__item">
              <div className="establishment-block__value">
                {data.testsCompleted}
              </div>
              <div className="establishment-block__title">Tests completed</div>
            </div>
          </div>
        </div>
        <div className="establishment-block establishment-block__fg2">
          <div className="establishment-block__header">Activity</div>
          <div className="establishment-block__items">
            <div className="establishment-block__item">
              <div className="establishment-block__value">
                {data.totalSiteTimeTracks}
              </div>
              <div className="establishment-block__title">Total minutes</div>
            </div>
            <div className="establishment-block__item">
              <div className="establishment-block__value">
                {data.totalSiteLastTotalTracks}
              </div>
              <div className="establishment-block__title">
                Minutes Last 30 days
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="establishment-block-list__row">
        <div className="establishment-block establishment-block__fg2">
          <div className="establishment-block__header">Classes</div>
          <div className="establishment-block__items">
            <div className="establishment-block__item">
              <div className="establishment-block__value">
                {data.totalClassrooms}
              </div>
              <div className="establishment-block__title">Total</div>
            </div>
            <div className="establishment-block__item">
              <div className="establishment-block__value">
                {data.activeClassrooms}
              </div>
              <div className="establishment-block__title">
                Active Last 30 days
              </div>
            </div>
          </div>
        </div>
        <div className="establishment-block establishment-block__fg6">
          <div className="establishment-block__card">
            <div className="establishment-block__card__item">
              <div className="establishment-block__header">Subscription:</div>
              <div className="establishment-block__items">
                <div className="establishment-block__item">
                  <div className="establishment-block__value">
                    {data.licenseStartDate}
                  </div>
                  <div className="establishment-block__title">
                    Licence Start Date
                  </div>
                </div>
                <div className="establishment-block__item">
                  <div className="establishment-block__value">
                    {data.licenseEndDate}
                  </div>
                  <div className="establishment-block__title">
                    Licence End Date
                  </div>
                </div>
              </div>
            </div>
            <div className="establishment-block__card__item">
              <div className="establishment-block__header">Licences:</div>
              <div className="establishment-block__items">
                <div className="establishment-block__item">
                  <div className="establishment-block__value">
                    {data.licensesMaxCount}
                  </div>
                  <div className="establishment-block__title">Total</div>
                </div>
                <div className="establishment-block__item">
                  <div className="establishment-block__value">
                    {data.usedUsersCount}
                  </div>
                  <div className="establishment-block__title">Used</div>
                </div>
                <div className="establishment-block__item">
                  <div className="establishment-block__value">
                    {data.remainingLicensesCount}
                  </div>
                  <div className="establishment-block__title">Remaining</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstablishmentBlockList;
