import React from 'react';
import ReactStars from 'react-stars';

import Img from '../../../components/Img';

const TestCard = ({ data: { title, rate, image, description, bottom } }) => {
  return (
    <div className="test-card-wrapper">
      <div className="top">
        <div className="left">
          <div className="title">{title}</div>
          <div className="rating">
            <div className="title">{rate.title}</div>
            <ReactStars
              className="rate"
              color2="#0984FF"
              color1="rgba(9, 132, 255, 0.2)"
              size={22}
              edit={false}
              value={rate.stars}
            />
          </div>
          <div className="description">
            <div className="title">{description.title}</div>
            {description.text.map((text, index) => (
              <div key={index} className="text">
                {text}
              </div>
            ))}
          </div>
        </div>
        <div className="right">
          <Img src={image} alt={image} aspectRatio={400 / 287} />
        </div>
      </div>
      <div className="bottom">
        <div className="title">{bottom.title}</div>
        <div className="dots-wrapper">
          {bottom.dots.map(({ dot }, index) => (
            <div key={index} className="dot-wrapper">
              <div className="dot" />
              <div className="title">{dot.title}</div>
              <div className="text">{dot.text}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestCard;
