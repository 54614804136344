import React from 'react';
import { useSelector } from 'react-redux';

import { removeNewLines } from '../../main/utils/helpers';
import Button from '../../main/components/Button';

const SummaryBoldWeb = ({
  locales,
  titleColor,
  textColor,
  colorStyle,
  backgroundSection,
  fileTextColor,
}) => {
  const { projectSummary } = useSelector((state) => state.portfolio);
  const { collection } = useSelector((state) => state.portfolioFile);

  return (
    <div
      className="summary-bold-web section-web"
      style={{ backgroundColor: backgroundSection }}
    >
      <div
        className="bold-title title custom-font-title"
        style={{ color: titleColor }}
      >
        {locales.portfolioWeb.summary}
      </div>
      <div
        className="description custom-font-body"
        style={{ color: textColor }}
      >
        {projectSummary.description &&
          removeNewLines(projectSummary.description)}
      </div>

      <div className="margin-top-40 file">
        {collection.map((file) => (
          <a
            key={file.id}
            href={file.file}
            target="_blank"
            className="custom-font-body"
          >
            <Button
              icon="icon-Export"
              className="bmc-button button portfolio-file-uploader-file-bold-link custom-font-body"
              title={file.name}
              style={{ backgroundColor: colorStyle, color: fileTextColor }}
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default SummaryBoldWeb;
