import React from 'react';
import { Provider } from 'react-redux';

import 'antd/lib/input/style/index.css';
import 'antd/lib/button/style/index.css';
import 'antd/lib/form/style/index.css';
import 'react-input-range/lib/css/index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import '../main/utils/axios';
import Router from './Router';
import NotifySystem from '../main/components/NotifySystem';
import Alert from '../main/components/Alert';
import store from './store';

export default function index({ locales, data, flash }) {
  return (
    <Provider store={store}>
      <NotifySystem flash={flash} />
      <Alert />
      <Router locales={locales} data={data} />
    </Provider>
  );
}
