import React from 'react';

import Spinner from '../Spinner';

const dropzoneAreaInner = (
  common,
  small,
  dropzone,
  uploading,
  aspectRatio,
  disabled,
) => {
  const { getRootProps, getInputProps, isDragActive } = dropzone;
  const paddingTop = `${(1 / aspectRatio) * 100}%`;

  const rootProps = disabled
    ? { ...getRootProps(), onClick: () => {} }
    : getRootProps();

  const inputProps = disabled
    ? { ...getInputProps(), onClick: () => {}, onChange: () => {} }
    : getInputProps();

  return (
    <div className="dndzone__inner-wrapper" style={{ paddingTop }}>
      <div className="dndzone__inner">
        {uploading ? (
          <Spinner />
        ) : (
          <div className="dndzone-button" {...rootProps}>
            <input {...inputProps} />

            <i className="icon-Add" />

            <div className="dndzone__title">
              {small ? (
                <>
                  {isDragActive ? (
                    <span>{common.dropImage}</span>
                  ) : (
                    <span>{common.addImage}</span>
                  )}
                </>
              ) : (
                <>
                  <span>
                    {common.dragAndDropTitle}
                    <br /> {common.or}&nbsp;
                  </span>

                  {isDragActive ? (
                    <span className="dndzone__accent">
                      {common.dropFileHere}
                    </span>
                  ) : (
                    <span className="dndzone__accent">
                      {common.browsePhoto}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default dropzoneAreaInner;
