import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

const ConfirmationModal = ({
  body,
  children,
  onCancel,
  onConfirm,
  isBlockScroll = false,
  className,
}) => {
  const {
    common: { setHandlers, openModal, closeModal },
  } = useDispatch();

  const bodyDocument = document.querySelector('body');

  const handleCancel = (event) => {
    event.stopPropagation();
    closeModal();
    if (isBlockScroll) bodyDocument.classList.remove('body-modal-open');
    if (onCancel) onCancel(event);
  };

  const handleConfirm = (event) => {
    event.stopPropagation();
    closeModal();
    if (isBlockScroll) bodyDocument.classList.remove('body-modal-open');
    onConfirm(event);
  };

  const wrapperClick = (event) => {
    event.stopPropagation();
    setHandlers({ handleConfirm, handleCancel, body });
    openModal();
    if (isBlockScroll) bodyDocument.classList.add('body-modal-open');
  };

  return (
    <div className={className} onClick={wrapperClick}>
      {children}
    </div>
  );
};

ConfirmationModal.propTypes = {
  body: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmationModal;
