import {
  Paragraph,
  ImageRun,
  HorizontalPositionAlign,
  VerticalPositionAlign,
  Table,
  TableRow,
  TableCell,
  AlignmentType,
  WidthType,
  HeightRule,
  FrameAnchorType,
  TableAnchorType,
  OverlapType,
  TableLayoutType,
  HorizontalPositionRelativeFrom,
  VerticalPositionRelativeFrom,
} from 'docx';

import {
  pageSize,
  pageMargin,
  convertPixelToTwip,
  convertPixel,
  COLORS,
  transparentBorder,
  tableWidth,
} from '../Constants';
import {
  buildBgCircle,
  emptyCell,
  marginTableRow,
  textRun,
} from '../helpers/index';

export default async (
  locales,
  portfolio,
  portfolioTeammate,
  keyActivity,
  options,
) => {
  const keyStepImgBg = await buildBgCircle(
    60,
    60,
    options.keyActivity.stepImgColor || options.backgroundDark,
  );
  const keyActivitySteps = () =>
    keyActivity.collection.map((activity, index) => {
      return new Table({
        rows: [
          new TableRow({
            height: {
              value: convertPixelToTwip(68),
              rule: HeightRule.ATLEAST,
            },
            children: [
              // empty cell
              emptyCell(20, COLORS.WHITE),
              // cell with STEP image
              new TableCell({
                width: {
                  size: convertPixelToTwip(60),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.WHITE),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new ImageRun({
                        data: keyStepImgBg,
                        transformation: {
                          width: convertPixel(60),
                          height: convertPixel(62),
                        },
                        floating: {
                          horizontalPosition: {
                            offset: 0,
                          },
                          verticalPosition: {
                            offset: 0,
                          },
                          behindDocument: true,
                        },
                      }),
                      new Paragraph({
                        spacing: {
                          before: 450,
                        },
                        children: [
                          textRun(`Step ${index + 1}`, {
                            size: 14,
                            color:
                              options.keyActivity?.stepTextColor ||
                              COLORS.WHITE,
                            font: options.bodyFont,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              // empty cell
              emptyCell(15, COLORS.WHITE),
              // cell with step description
              new TableCell({
                width: {
                  size: convertPixelToTwip(671),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.WHITE),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.START,
                    children: [
                      textRun(activity.description, {
                        font: options.bodyFont,
                        color: options.bodyColor || COLORS.BLACK,
                      }),
                    ],
                  }),
                ],
              }),
              // empty cell
              emptyCell(20, COLORS.WHITE),
            ],
          }),
          // margin table row
          marginTableRow(15, tableWidth - 56, COLORS.WHITE, 5),
          // line between steps
          new TableRow({
            height: {
              value: convertPixelToTwip(2),
              rule: HeightRule.ATLEAST,
            },
            children: [
              new TableCell({
                columnSpan: 5,
                width: {
                  size: convertPixelToTwip(tableWidth - 56),
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    border: {
                      top: {
                        color: COLORS.PRIMARY,
                        space: 2,
                        style: 'single',
                        size: 6,
                      },
                    },
                  }),
                ],
                borders: transparentBorder(COLORS.WHITE),
              }),
            ],
          }),
          // margin table row
          marginTableRow(15, tableWidth - 56, COLORS.WHITE, 5),
        ],
      });
    });

  const imageCircleList = async () => {
    const indentStep = 1770972;
    const result = [];
    const dotImg = await buildBgCircle(
      10,
      10,
      options.keyActivity.dotImgColor || options.backgroundDark,
    );

    for (let i = 0; i < 6; i += 1) {
      result.push(
        new ImageRun({
          data: dotImg,
          transformation: {
            width: convertPixel(10),
            height: convertPixel(10),
          },
          floating: {
            horizontalPosition: {
              relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
              offset: i * indentStep,
            },
            verticalPosition: {
              relative: VerticalPositionRelativeFrom.TOP_MARGIN,
              offset: -60000,
            },
          },
        }),
      );
    }

    return result;
  };

  return {
    properties: {
      page: {
        size: pageSize,
        margin: pageMargin,
      },
    },
    children: [
      // Title
      new Paragraph({
        text: '',
        alignment: AlignmentType.CENTER,
        frame: {
          position: {
            x: convertPixelToTwip(30),
            y: convertPixelToTwip(0),
          },
          rule: HeightRule.ATLEAST,
          width: convertPixelToTwip(726),
          height: convertPixelToTwip(28),
          anchor: {
            horizontal: FrameAnchorType.TEXT,
            vertical: FrameAnchorType.TEXT,
          },
          alignment: {
            x: HorizontalPositionAlign.CENTER,
            y: VerticalPositionAlign.TOP,
          },
        },

        children: [
          textRun(locales.keyActivities.sectionName, {
            font: options.titleFont,
            color: options.titleColor || COLORS.BLACK,
            bold: options.keyActivity.titleBold,
            size: 24,
            allCaps: true,
          }),
        ],
      }),
      // Description
      new Table({
        float: {
          horizontalAnchor: TableAnchorType.TEXT,
          verticalAnchor: TableAnchorType.TEXT,
          overlap: OverlapType.NEVER,

          absoluteVerticalPosition: convertPixelToTwip(27 + 10),
          absoluteHorizontalPosition: 0,
        },
        width: {
          size: convertPixelToTwip(tableWidth - 56),
          type: WidthType.DXA,
        },
        layout: TableLayoutType.AUTOFIT,
        rows: [
          // description row
          new TableRow({
            height: {
              value: convertPixelToTwip(28),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(30, COLORS.WHITE),

              new TableCell({
                width: {
                  size: convertPixelToTwip(726),
                  type: WidthType.DXA,
                },
                columnSpan: 3,
                borders: transparentBorder(COLORS.WHITE),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      textRun(portfolio.keyActivities.description, {
                        font: options.bodyFont,
                        color: options.bodyColor || COLORS.BLACK,
                      }),
                    ],
                  }),
                ],
              }),

              emptyCell(30, COLORS.WHITE),
            ],
          }),

          // margin table row
          marginTableRow(30, 726, COLORS.WHITE, 5),

          // Key Activities title row
          new TableRow({
            height: {
              value: convertPixelToTwip(22),
              rule: HeightRule.ATLEAST,
            },
            children: [
              new TableCell({
                columnSpan: 5,
                width: {
                  size: convertPixelToTwip(726),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.WHITE),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      textRun('Key Activities', {
                        font: options.bodyFont,
                        color: options.bodyColor || COLORS.BLACK,
                        size: 20,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),

          // margin table row
          marginTableRow(30, 726, COLORS.WHITE, 5),

          // grey Line
          new TableRow({
            height: {
              value: convertPixelToTwip(2),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(45, COLORS.WHITE),

              // cell with line and black circles
              new TableCell({
                columnSpan: 5,
                width: {
                  size: convertPixelToTwip(696),
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    border: {
                      top: {
                        color: COLORS.PRIMARY,
                        space: 2,
                        style: 'single',
                        size: 6,
                      },
                    },
                    children: await imageCircleList(),
                  }),
                ],
                borders: transparentBorder(COLORS.WHITE),
              }),

              emptyCell(45, COLORS.WHITE),
            ],
          }),

          // margin table row
          marginTableRow(20, tableWidth - 56, COLORS.WHITE, 5),

          new TableRow({
            height: {
              value: convertPixelToTwip(68),
              rule: HeightRule.ATLEAST,
            },
            children: [
              new TableCell({
                columnSpan: 5,
                width: {
                  size: convertPixelToTwip(726),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.WHITE),
                children: keyActivitySteps(),
              }),
            ],
          }),
        ],
      }),
    ],
  };
};
