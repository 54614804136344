import React from 'react';
import { useSelector } from 'react-redux';

import KeyActivity from '../../main/containers/keyActivity/Card';
import KeyResources from '../../main/containers/keyResources/Card';
import SvgIcon from '../../main/components/SvgIcon';
import {
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  COLOURFUL_TEMPLATE_NAME,
  CURRENT_TEMPLATE_NAME,
} from '../../main/utils/const';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import { getCurrentCanvasIcon } from '../../main/utils/helpers/getCurrentCanvasIcon';

const KeyActivities = ({
  locales,
  locales: {
    keyActivity: {
      intro: { card },
    },
    keyResources: {
      intro: { card: resourceCard },
    },
    keyPartners: {
      intro: { card: partnerCard },
    },
    portfolioWeb,
  },
  className,
  sectionHeaderTitleColor,
  descriptionColor,
  styleColor,
  circleActivityColor,
  lineActivityColor,
  circleResourcesColor,
  titleActivityColor,
  textResourcesColor,
  titleResourcesColor,
  textActivityColor,
  backgroundSection,
  firstSvgColor,
  secondSvgColor,
  backgroundActivity,
  backgroundResources,
  borderCardColor,
  templateName,
}) => {
  const { collection } = useSelector((state) => state.keyActivity);
  const { collection: partnerCollection } = useSelector(
    (state) => state.keyPartner,
  );
  const { collection: resourcesCollection } = useSelector(
    (state) => state.keyResource,
  );
  const { keyActivities } = useSelector((state) => state.portfolio);
  const isColourfulfunTemplate = templateName === COLOURFUL_TEMPLATE_NAME;
  const isCurrentTemplate = templateName === CURRENT_TEMPLATE_NAME;
  const isArchTemplate = templateName === ARCHITECTURAL_MINIMAL_TEMPLATE_NAME;
  return (
    <div
      className={`${className} section-web`}
      style={{ backgroundColor: !isArchTemplate && backgroundSection }}
    >
      {isCurrentTemplate && getCurrentCanvasIcon('KeyThingCanvas', styleColor)}
      {isColourfulfunTemplate && (
        <>
          <div className="svg-funKeyFirst" style={{ fill: firstSvgColor }}>
            <SvgIcon name="funKeyFirst" />
          </div>
          <div className="svg-funKeySecond" style={{ fill: secondSvgColor }}>
            <SvgIcon name="funKeySecond" />
          </div>
        </>
      )}
      <div className="card">
        <SectionHeaderWeb
          templateName={templateName}
          title={portfolioWeb.keyThings}
          description={keyActivities.description}
          className={isCurrentTemplate && 'orange-title'}
          style={{ color: sectionHeaderTitleColor }}
          descriptionColor={descriptionColor}
        />

        <KeyActivity
          locales={locales}
          className="key-activity bmc-page-keycard--portfolio-edit"
          card={{ ...card, steps: collection, title: null }}
          wBlur={false}
          templateName={templateName}
          titleColor={titleActivityColor}
          circleColor={circleActivityColor}
          lineColor={lineActivityColor}
          textColor={textActivityColor}
          background={backgroundActivity}
        />
        <div
          className="key-resources-wrapper"
          style={{ backgroundColor: isArchTemplate && backgroundSection }}
        >
          <KeyResources
            locales={locales}
            className="key-resource"
            card={{ ...partnerCard, list: partnerCollection, title: null }}
            wBlur={false}
            templateName={templateName}
            titleColor={titleResourcesColor}
            circleColor={circleResourcesColor}
            textColor={textResourcesColor}
            background={backgroundResources}
            borderCardColor={borderCardColor}
          />
          <KeyResources
            locales={locales}
            className="key-resource"
            card={{ ...resourceCard, list: resourcesCollection, title: null }}
            wBlur={false}
            templateName={templateName}
            titleColor={titleResourcesColor}
            circleColor={circleResourcesColor}
            textColor={textResourcesColor}
            background={backgroundResources}
            borderCardColor={borderCardColor}
          />
        </div>
      </div>
    </div>
  );
};

export default KeyActivities;
