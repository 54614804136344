import React from 'react';

import Textarea from '../../../components/Textarea';
import Dropzone from '../../../components/Dropzone';
import {
  PORTFOLIO_INTRO_LOGO_ASPECT_RATIO,
  PORTFOLIO_INTRO_BG_ASPECT_RATIO,
  TEXTAREA_DELAY,
  COMMENT_INTRODUCTION,
} from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';

const IntroductionCurrent = ({
  data,
  locales,
  locales: { portfolio },
  onLogoDrop,
  onLogoCropComplete,
  onBgDrop,
  onBgCropComplete,
  handleDescriptionSubmit,
  handleInputSubmit,
  uploadingIntrologo,
  uploadingIntrobg,
  disabled = false,
}) => {
  const {
    title,
    description,
    introLogo,
    introLogoCoords,
    introBg,
    introBgCoords,
  } = data;

  return (
    <div className="introduction-current-wrapper relative">
      <PortfolioCommentIcon title={COMMENT_INTRODUCTION} />
      <div className="logo-hover">
        <Dropzone
          className="logo-upload"
          onDrop={onLogoDrop}
          onCropperSave={onLogoCropComplete}
          locales={locales}
          image={introLogo}
          coords={introLogoCoords}
          aspectRatio={PORTFOLIO_INTRO_LOGO_ASPECT_RATIO}
          uploading={uploadingIntrologo}
          disabled={disabled}
          cropper
        />
      </div>
      <div className="introduction-current-footer-hover">
        <Dropzone
          className="introduction-current-footer"
          onDrop={onBgDrop}
          onCropperSave={onBgCropComplete}
          locales={locales}
          image={introBg}
          coords={introBgCoords}
          aspectRatio={PORTFOLIO_INTRO_BG_ASPECT_RATIO}
          uploading={uploadingIntrobg}
          disabled={disabled}
          cropper
        />
      </div>
      <div className="company-wrapper">
        <Textarea
          onChange={handleInputSubmit}
          value={title}
          delay={TEXTAREA_DELAY}
          placeholder={portfolio.introduction.projectName}
          className="textarea-current textarea-current-name custom-font-title"
          disabled={disabled}
        />

        <div className="description">
          <Textarea
            onChange={handleDescriptionSubmit}
            value={description}
            delay={TEXTAREA_DELAY}
            placeholder={portfolio.introduction.projectNamePlaceholder}
            rows={2}
            className="textarea-current textarea-current-description custom-font-body"
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default IntroductionCurrent;
