import React, { useState } from 'react';
import { Form, Input, Space, Button } from 'antd';
import { useSelector } from 'react-redux';
import { shareClassroomLink } from '../../support/serverRoutes';
import copyToClipboard from '../../../main/utils/helpers/copyToClipboard';

function StepTwo() {
  const {
    classroom: {
      current: { secretToken, name },
    },
  } = useSelector((state) => state);
  const [isCopied, setCopied] = useState(false);

  return (
    <>
      <div className="jumbotron">
        <h3 className="step-two-classname">{name}</h3>
        <div className="step-two-title">Successfully created!</div>
      </div>
      <Form layout="vertical">
        <Form.Item
          label="Share Link with Students"
          className="admin-input-label"
        >
          <Input
            className="admin-input"
            value={shareClassroomLink(secretToken)}
          />
          <p className="description">
            Anyone signing up with this link will be automatically added to this
            class.
          </p>
        </Form.Item>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 40 }}
        >
          <Space align="end">
            <Button
              type="primary"
              onClick={() => {
                copyToClipboard(shareClassroomLink(secretToken));
                setCopied(true);
              }}
            >
              {isCopied ? 'Copied' : 'Copy Link'}
            </Button>
          </Space>
        </div>
      </Form>
    </>
  );
}

export default StepTwo;
