// eslint-disable-next-line import/no-cycle
import { getClearColorVar, getTemplateFonts } from './index';
import { getContrastYIQ } from '../helpers';

export const buildArchitectureOptions = (
  colors,
  fonts,
  templateSettings,
  templateData,
  isCustomPalette,
) => {
  const templateColors = getClearColorVar(templateSettings.colorPalette);

  const { titleFont, titleColor, bodyFont, bodyColor } = getTemplateFonts(
    templateSettings,
    templateData,
  );

  const blackPageTitleColor =
    isCustomPalette && !templateSettings.customTitleColor
      ? getContrastYIQ(`#${templateColors[0]}`)
      : false;
  const blackPageBodyColor =
    isCustomPalette && !templateSettings.customBodyColor
      ? getContrastYIQ(`#${templateColors[0]}`)
      : false;

  return {
    titleFont,
    bodyFont,
    titleColor: titleColor || colors.BLACK,
    bodyColor: bodyColor || colors.BLACK,
    backgroundLight: colors.PRIMARY,
    backgroundDark: templateColors[0],
    noImgBg: templateColors[3],
    mainPage: {
      mainImgType: 'default',
      imgBg: colors.WHITE,
      noImgTextColor: colors.WHITE,
      imgWidth: 840,
      imgHeight: 371,
      titleBg: templateColors[0],
      fontColor: colors.WHITE,
      pageBg: templateColors[0],
      isArchitectural: true,
      isLogo: true,
      logoPositionX: 14000,
      logoPositionY: 0,
      mainImgPositionX: 0,
      mainImgPositionY: 1100,
      paragraphWidth: 840,
      paragraphHeight: 257,
      absoluteVerticalPosition: 340,
    },
    summary: { pageBg: colors.WHITE },
    team: {
      pageBg: colors.PRIMARY,
      noImgBg: templateColors[3],
    },
    analysis: {
      noImgBg: templateColors[3],
      noImgType: 'circle',
    },
    testing: {
      pageBg: colors.PRIMARY,
      statusIconColor: templateColors[0],
    },
    desirability: {
      color: colors.WHITE,
      titleFont: fonts.NIXIE,
      bodyFont: fonts.LATO,
      backgroundDark: templateColors[0],
      titleColor: blackPageTitleColor,
      bodyColor: blackPageBodyColor,
    },
    relationships: {
      iconDarkColor: 'C2C2C2',
      iconLightColor: colors.WHITE,
    },
    segment: {
      noImgBg: templateColors[3],
      noImgType: 'default',
    },
    channels: {
      pageBg: colors.PRIMARY,
      noImgBg: templateColors[3],
      noImgType: 'default',
    },
    feasibility: {
      color: colors.WHITE,
      titleFont: fonts.NIXIE,
      bodyFont: fonts.LATO,
      backgroundDark: templateColors[0],
      titleColor: blackPageTitleColor,
      bodyColor: blackPageBodyColor,
    },
    viability: {
      color: colors.WHITE,
      titleFont: fonts.NIXIE,
      bodyFont: fonts.LATO,
      backgroundDark: templateColors[0],
      titleColor: blackPageTitleColor,
      bodyColor: blackPageBodyColor,
    },
    keyActivity: {
      font: fonts.PRATA,
    },
    keyResources: {
      lineColor: colors.PRIMARY,
      iconBg: templateColors[0],
    },
    valueProposition: {
      pageBg: colors.WHITE,
      noImgBg: templateColors[3],
      noImgType: 'default',
    },
    finance: {
      pageBg: colors.WHITE,
    },
    contact: {
      color: colors.WHITE,
      titleColor: colors.WHITE,
      pageBg: colors.BLACK,
      borderColor: colors.BLACK,
    },
    sustainability: {
      tablePageBg: colors.PRIMARY,
      listCircleWidth: 169,
      listCircleHeight: 169,
      listCircleColor: colors.WHITE,
      iconListColor: templateColors[0],
      iconTableColor: templateColors[0],
      circleTableColor: colors.WHITE,
    },
  };
};
