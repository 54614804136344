import React from 'react';
import CommentAvatar from './comment-avatar';

const CommentInputSuggestionItem = ({ member }) => {
  return (
    <div className="suggestion-modal-item">
      <div
        style={{
          width: 40,
          height: 40,
          background: '#cccccc',
          overflow: 'hidden',
          borderRadius: '4px',
        }}
      >
        <CommentAvatar url={member.image?.icon?.url} />
      </div>
      <div className="suggestion-modal-item-info">
        <div className="suggestion-modal-item-info full-name">
          {member?.user?.firstName} {member?.user?.surname}
        </div>
        <div className="suggestion-modal-item-info email">
          {member?.user?.email}{' '}
        </div>
      </div>

      <div className="suggestion-modal-item-role">{member?.user?.role}</div>
    </div>
  );
};

export default CommentInputSuggestionItem;
