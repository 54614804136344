import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Lazyload, { forceVisible } from 'react-lazyload';

import Introduction from './Introduction';
import ProjectSummary from './ProjectSummary';
import Team from './Team';
import BMC from './BusinessModalCanvas';
import Testing from './Testing';
import DesirabilitySection from './DesirabilitySection';
import CustomerSegment from './CustomerSegment';
import ValueProposition from './ValueProposition';
import CompetitorAnalysis from './CompetitorAnalysis';
import CustomerRelationships from './CustomerRelationships';
import Channels from './Channels';
import Feasibility from './Feasibility';
import KeyActivities from './KeyActivities';
import Viability from './Viability';
import Finance from './Finance';
import Sustainability from './Sustainability';
import ContactUs from './ContactUs';
import ExportModal from './Export';
import FirstTimeTip from '../tips/FirstTimeTip';
import { ENABLE_ONBOARDING } from '../../utils/const';
import Spinner from '../../components/Spinner';
// import Button from '../../components/Button';
import GlobalContext from '../../utils/GlobalContext';

const PortfolioSections = ({ locales, disabled = false }) => {
  const {
    sectionAvailabilitySettings: { bmc, testing },
  } = useContext(GlobalContext);

  const {
    portfolio: { page: pageLocales },
  } = locales;

  const [exportModalIsOpen, setExportModalIsOpen] = useState(false);

  const {
    portfolio: { fetchPortfolioData },
  } = useDispatch();

  const { portfolio } = useSelector((state) => state);
  const { bmcId } = useParams();

  useEffect(() => {
    if (bmcId) {
      fetchPortfolioData(bmcId);
    }
  }, [bmcId]);

  // we have to force visibility of lazy components
  setTimeout(() => forceVisible(), 1000);

  return (
    <>
      {ENABLE_ONBOARDING && (
        <FirstTimeTip page="portfolio" tips="portfolioTip" locales={locales} />
      )}

      <div className="bmc-main portfolio">
        <div className="bmc-main-header">
          <div className="bmc-main-header-title">{pageLocales.title}</div>

          <div className="bmc-main-header-actions">
            <div className="bmc-main-header-actions-view">
              {/* <Button
                primary
                title={pageLocales.export}
                onClick={() => setExportModalIsOpen(true)}
              /> */}
            </div>
          </div>
        </div>

        {portfolio.uploading ? (
          <Spinner className="margin-top-60" big />
        ) : (
          <div className="bmc-canvas margin-top-30">
            <div className="bmc-page fullwidth">
              <Introduction
                locales={locales}
                data={portfolio.introduction}
                disabled={disabled}
              />

              <ProjectSummary
                locales={locales}
                data={portfolio.projectSummary}
                disabled={disabled}
              />

              <Lazyload>
                <Team
                  locales={locales}
                  data={portfolio.team}
                  disabled={disabled}
                />
              </Lazyload>

              {bmc && (
                <Lazyload>
                  <BMC
                    locales={locales}
                    data={portfolio.bmc}
                    disabled={disabled}
                  />
                </Lazyload>
              )}

              {testing && (
                <Lazyload>
                  <Testing
                    locales={locales}
                    data={portfolio.testing}
                    disabled={disabled}
                  />
                </Lazyload>
              )}

              {bmc && (
                <>
                  <DesirabilitySection
                    locales={locales}
                    data={portfolio.desirabilitySection}
                    disabled={disabled}
                  />

                  <Lazyload>
                    <CustomerSegment
                      locales={locales}
                      data={portfolio.customerSegment}
                      disabled={disabled}
                    />
                  </Lazyload>

                  <Lazyload>
                    <ValueProposition
                      locales={locales}
                      data={portfolio.valueProposition}
                      disabled={disabled}
                    />
                  </Lazyload>

                  <Lazyload>
                    <CompetitorAnalysis
                      locales={locales}
                      data={portfolio.competitorAnalysis}
                      disabled={disabled}
                    />
                  </Lazyload>

                  <Lazyload>
                    <CustomerRelationships
                      locales={locales}
                      data={portfolio.customerRelationships}
                      disabled={disabled}
                    />
                  </Lazyload>

                  <Lazyload>
                    <Channels
                      locales={locales}
                      data={portfolio.channels}
                      disabled={disabled}
                    />
                  </Lazyload>

                  <Feasibility
                    locales={locales}
                    data={portfolio.feasibility}
                    disabled={disabled}
                  />

                  <Lazyload>
                    <KeyActivities
                      locales={locales}
                      data={portfolio.keyActivities}
                      disabled={disabled}
                    />
                  </Lazyload>

                  <Viability
                    locales={locales}
                    data={portfolio.viability}
                    disabled={disabled}
                  />

                  <Lazyload>
                    <Finance
                      locales={locales}
                      data={portfolio.finance}
                      disabled={disabled}
                    />
                  </Lazyload>

                  <Lazyload>
                    <Sustainability
                      locales={locales}
                      data={portfolio.sustainability}
                      disabled={disabled}
                    />
                  </Lazyload>
                </>
              )}

              <ContactUs
                locales={locales}
                data={portfolio.contactUs}
                disabled={disabled}
              />
            </div>
          </div>
        )}
      </div>
      <ExportModal
        locales={locales}
        isOpen={exportModalIsOpen}
        setIsOpen={setExportModalIsOpen}
      />
    </>
  );
};

export default PortfolioSections;
