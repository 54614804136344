import React from 'react';

import Header from '../../../../../../components/Header';
import Text from '../../../../../../components/Text';
import Line from '../../../../../../components/Line';
import Title from '../../../../../../components/Title';
import MobileMenuHeader from '../../../../../../components/MobileMenuHeader';
import Footer from '../../../../Components/Footer';
import TestCard from '../../../../Components/TestCard';

const PageComponent = ({
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
  handlers,
  locales,
  header,
  title,
  text,
  footer,
  test,
  name,
  goToHandler,
  titles,
}) => {
  return (
    <div className="bmc-page testing">
      <Header
        className="bmc-page-header--blue"
        icon="icon-VP"
        title={header}
        subtitle={name}
        locales={locales}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        mobileMenuHeader={
          <MobileMenuHeader icon="testingActive" title={name} color="blue" />
        }
        componentIndex={componentIndex}
        rules={rules}
        backPath={backPath}
      />
      <Text>{[text[0], text[1], text[2]]}</Text>
      <Line />
      <Title text={title[0]} />
      {test && test.id && (
        <TestCard
          locales={locales}
          titles={titles}
          goToHandler={goToHandler}
          isPreview
          bmcSectionMode
          isEditable={false}
          test={test}
          hideAnalyse
        />
      )}
      <Footer
        header={footer.title}
        text={footer.text}
        buttons={footer.buttons}
        handlers={handlers}
      />
    </div>
  );
};

export default PageComponent;
