import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TeamCurrent from '../currentTemplate/TeamCurrent';
import {
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  BLOG_FRIENDLY_TEMPLATE_NAME,
  BOLD_TEMPLATE_NAME,
  COLOURFUL_TEMPLATE_NAME,
  CURRENT_TEMPLATE_NAME,
} from '../../../utils/const';
import TeamColourfulFun from '../colourfulFunTemplate/Team/TeamColourfulFun';
import TeamBold from '../boldTemplate/TeamBold';
import { getColorDependsOnBackground } from '../../../utils/helpers/getColorDependsOnBackground';
import TeamBlog from '../blogFriendly/Team/TeamBlog';
import TeamArch from '../architecturalMinimal/Team/TeamArch';

const Team = ({
  data,
  locales,
  borderColorName,
  colorTemplate,
  mainSvgColor,
  firstSvgColor,
  secondSvgColor,
  backgroundSection,
  backgroundModal,
  backgroundTeam,
  backgroundImage,
  colorPaletteNumber,
  titleColor,
  textColor,
  teamNameColor,
  positionColor,
  templateName,
  sliderColor,
  buttonSecondColor,
  buttonFirstColor,
  backgroundSecondButton,
  isFirstColorPallete = false,
  isFourthColorPaletteNumber = false,
  disabled = false,
}) => {
  const {
    portfolioTeammate: { addPortfolioTeammate },
    portfolio: { updateSectionField },
  } = useDispatch();
  const {
    portfolioTeammate: { collection },
  } = useSelector((state) => state);

  const textColorDependsOnBackground =
    isFourthColorPaletteNumber &&
    getColorDependsOnBackground(backgroundSection);

  const teamDependsOnTemplateName = {
    [CURRENT_TEMPLATE_NAME]: (
      <TeamCurrent
        locales={locales}
        data={data}
        collection={collection}
        addPortfolioTeammate={addPortfolioTeammate}
        updateSectionField={updateSectionField}
        borderColorName={borderColorName}
        isFirstColorPallete={isFirstColorPallete}
        disabled={disabled}
      />
    ),
    [COLOURFUL_TEMPLATE_NAME]: (
      <TeamColourfulFun
        locales={locales}
        data={data}
        collection={collection}
        addPortfolioTeammate={addPortfolioTeammate}
        updateSectionField={updateSectionField}
        colorTemplate={colorTemplate}
        mainSvgColor={mainSvgColor}
        firstSvgColor={firstSvgColor}
        secondSvgColor={secondSvgColor}
        disabled={disabled}
      />
    ),
    [BLOG_FRIENDLY_TEMPLATE_NAME]: (
      <TeamBlog
        locales={locales}
        data={data}
        collection={collection}
        addPortfolioTeammate={addPortfolioTeammate}
        updateSectionField={updateSectionField}
        backgroundSection={backgroundSection}
        backgroundTeam={backgroundTeam}
        backgroundModal={backgroundModal}
        textColorDependsOnBackground={textColorDependsOnBackground}
        disabled={disabled}
      />
    ),
    [ARCHITECTURAL_MINIMAL_TEMPLATE_NAME]: (
      <TeamArch
        locales={locales}
        data={data}
        collection={collection}
        addPortfolioTeammate={addPortfolioTeammate}
        updateSectionField={updateSectionField}
        backgroundSection={backgroundSection}
        backgroundImage={backgroundImage}
        backgroundModal={backgroundModal}
        colorPaletteNumber={colorPaletteNumber}
        disabled={disabled}
      />
    ),
    [BOLD_TEMPLATE_NAME]: (
      <TeamBold
        locales={locales}
        data={data}
        collection={collection}
        addPortfolioTeammate={addPortfolioTeammate}
        updateSectionField={updateSectionField}
        titleColor={titleColor}
        textColor={textColor}
        teamNameColor={teamNameColor}
        positionColor={positionColor}
        backgroundSection={backgroundSection}
        backgroundImage={backgroundImage}
        sliderColor={sliderColor}
        backgroundModal={backgroundModal}
        buttonSecondColor={buttonSecondColor}
        buttonFirstColor={buttonFirstColor}
        backgroundSecondButton={backgroundSecondButton}
        disabled={disabled}
      />
    ),
  };

  return teamDependsOnTemplateName[templateName];
};

export default Team;
