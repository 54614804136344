import React from 'react';
import classNames from 'classnames';

import Img from './Img';
import { buildUserShortName } from '../utils/helpers';

export default function UserAvatar({ user, className }) {
  let icon = '';

  if (user.imageIcon !== null && user.role !== 'super_admin') {
    icon = <Img src={user.imageIcon} alt={user.name} />;
  } else {
    icon = buildUserShortName(user);
  }

  return <div className={classNames('user-avatar', className)}>{icon}</div>;
}
