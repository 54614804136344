import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getTitleStyle } from '../utils/helpers/getTitleStyle';

const SectionHeaderWeb = ({
  templateName,
  title,
  description,
  className,
  descriptionColor,
  style,
}) => {
  return (
    <div className="header">
      <div
        className={classNames(getTitleStyle(templateName), className)}
        style={style}
      >
        {title}
      </div>
      <div
        className="description custom-font-body"
        style={{ color: descriptionColor }}
      >
        {description}
      </div>
    </div>
  );
};

SectionHeaderWeb.propTypes = {
  templateName: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
};

export default SectionHeaderWeb;
