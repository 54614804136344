import React from 'react';
import { useSelector } from 'react-redux';

import Img from '../../main/components/Img';
import { removeNewLines } from '../../main/utils/helpers';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import { TECH_MODERN_TEMPLATE_NAME } from '../../main/utils/const';

const TeamTechWeb = ({
  locales: { portfolioWeb },
  styleColor,
  background,
  backgroundImage,
}) => {
  const {
    portfolioTeammate: { collection },
    portfolio: { team },
  } = useSelector((state) => state);

  const getAbbreviation = (source) => {
    return source
      .split(' ')
      .map((n) => n[0])
      .join('');
  };

  return (
    <div className="team-tech-web" style={{ backgroundColor: background }}>
      <SectionHeaderWeb
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolioWeb.team}
        description={team.description}
        portfolioWeb={portfolioWeb}
        style={{ color: styleColor }}
      />
      <div className="team-tech-web-cards">
        {collection.map(({ id, name, position, description, image }) => (
          <div
            className="team-tech-web-card"
            key={id}
            style={{ borderColor: `${styleColor}26` }}
          >
            <div
              className="image-upload"
              style={{ background: backgroundImage }}
            >
              {image.cropped ? (
                <Img src={image.cropped} />
              ) : (
                <div className="center-block custom-font-body">
                  {getAbbreviation(name)}
                </div>
              )}
            </div>
            <div className="position custom-font-body">{position}</div>
            <div className="name custom-font-title">{name}</div>
            <div className="member-description custom-font-body">
              {description && removeNewLines(description)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamTechWeb;
