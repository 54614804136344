import React, { useState } from 'react';

import SignupForm from './SignupForm';
import OnboardingInfo from './OnboardingInfo';

const pageCount = 3;

const SignUp = ({ locales, data }) => {
  const { onboarding: pageLocales } = locales;

  const [step, setStep] = useState(1);

  return (
    <main className="sign-up-page">
      <section className="sign-up-form-section">
        {data.imageUrl && (
          <div className="sign-up-form-section__establishment-image">
            <img src={data.imageUrl} alt="establishment-logo" />
          </div>
        )}
        <h1 className="sign-up-form-section__logo">
          {pageLocales.titles.logo}
        </h1>
        <div className="sign-up-form-section__subtitle">
          {pageLocales.subtitles.step1}
        </div>
        <p className="sign-up-form-section__step-counter">
          {pageLocales.common.step} {step} {pageLocales.common.of} {pageCount}
        </p>
        <div className="sign-up-form-section__step-dots">
          {new Array(3).fill(1).map((_, index) => (
            <div
              key={index}
              className={`sign-up-form-section__step-dots-item ${
                index < step ? 'active' : ''
              }`}
              onClick={() => setStep(index + 1)}
            />
          ))}
        </div>
        <SignupForm
          locales={locales}
          data={data}
          step={step}
          setStep={setStep}
        />
      </section>
      <section className="sign-up-page__info-section">
        <OnboardingInfo locales={locales} step={step} />
      </section>
    </main>
  );
};

export default SignUp;
