import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../../components/Button';
// import closeIcon from '../../../../../assets/images/icons/close.png';
// import customerTips1 from '../../../../../assets/images/tips/customerTips1.png';
// import customerTips2 from '../../../../../assets/images/tips/customerTips2.png';
// import customerTips3 from '../../../../../assets/images/tips/customerTips3.png';
// import customerTips4 from '../../../../../assets/images/tips/customerTips4.png';
// import customerTips5 from '../../../../../assets/images/tips/customerTips5.png';

const closeIcon = '';
const customerTips1 = '';
const customerTips2 = '';
const customerTips3 = '';
const customerTips4 = '';
const customerTips5 = '';

const TipCustomerSegment = ({ locales, onClose }) => {
  const active = 1;

  return (
    <div className="tip-customer-segment">
      <div className="tip-customer-segment-container">
        <div className="tip-customer-segment-navigation">
          <h3 className="tip-customer-segment-navigation__title">
            {locales.navigationTitle}
          </h3>
          <ul className="tip-customer-segment-navigation__list">
            {locales.navigationListTitles.map((title, index) => (
              <li
                key={index}
                className={classnames(
                  'tip-customer-segment-navigation__list-item',
                  {
                    'tip-customer-segment-navigation__list-item--active':
                      active === index,
                  },
                )}
              >
                {title}
              </li>
            ))}
          </ul>
        </div>
        <div className="tip-customer-segment-info">
          <h3 className="tip-customer-segment-info__title">
            {locales.infoTitle}
            <span
              style={{ background: `url(${closeIcon})` }}
              onClick={onClose}
            />
          </h3>
          <div className="tip-customer-segment-info__line" />
          {locales.guideStart.map((text, index) => (
            <p
              key={index}
              className="tip-customer-segment-info__block-description"
            >
              {text}
            </p>
          ))}
          <h4 className="tip-customer-segment-info__block-title">
            {locales.editContent}
          </h4>
          {locales.editContentInfo.map((text, index) => (
            <p
              key={index}
              className="tip-customer-segment-info__block-description"
            >
              {text}
            </p>
          ))}
          <img
            src={customerTips1}
            alt=""
            className="tip-customer-segment-info__block-image"
          />
          <h4 className="tip-customer-segment-info__block-title">
            {locales.deleteContent}
          </h4>
          {locales.deleteContentInfo.map((text, index) => (
            <p
              key={index}
              className="tip-customer-segment-info__block-description"
            >
              {text}
            </p>
          ))}
          <img
            src={customerTips2}
            alt=""
            className="tip-customer-segment-info__block-image"
          />
          <h4 className="tip-customer-segment-info__block-title">
            {locales.addContent}
          </h4>
          {locales.addContentInfo.map((text, index) => (
            <p
              key={index}
              className="tip-customer-segment-info__block-description"
            >
              {text}
            </p>
          ))}
          <img
            src={customerTips3}
            alt=""
            className="tip-customer-segment-info__block-image"
          />
          <h4 className="tip-customer-segment-info__block-title">
            {locales.caseStudy}
          </h4>
          {locales.caseStudyInfo.map((text, index) => (
            <p
              key={index}
              className="tip-customer-segment-info__block-description"
            >
              {text}
            </p>
          ))}
          <img
            src={customerTips4}
            alt=""
            className="tip-customer-segment-info__block-image"
          />
          <h4 className="tip-customer-segment-info__block-title">
            {locales.stacks}
          </h4>
          {locales.stacksInfo.map((text, index) => (
            <p
              key={index}
              className="tip-customer-segment-info__block-description"
            >
              {text}
            </p>
          ))}
          <img
            src={customerTips5}
            alt=""
            className="tip-customer-segment-info__block-image"
          />
          <h4 className="tip-customer-segment-info__block-title">
            {locales.fewThings}
          </h4>
          <ul className="tip-customer-segment-info__few-things">
            {locales.fewThingsInfo.map((text, index) => (
              <li key={index}>{text}</li>
            ))}
          </ul>
          <div className="tip-customer-segment-info__bottom-block">
            <p>{locales.wereUseful}</p>
            <Button
              className="tip-customer-segment-info__btn"
              title={locales.yesBtn}
            />
            <Button
              className="tip-customer-segment-info__btn"
              title={locales.noBtn}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

TipCustomerSegment.propTypes = {
  locales: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TipCustomerSegment;
