import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import IntroductionTechWeb from '../techModernComponents/IntroductionTechWeb';
import SummaryTechWeb from '../techModernComponents/SummaryTechWeb';
import TeamTechWeb from '../techModernComponents/TeamTechWeb';
import BMC from '../techModernComponents/BMC';
import Testing from '../techModernComponents/Testing';
import CustomerSegment from '../techModernComponents/CustomerSegment';
import ValuePropositionTechWeb from '../techModernComponents/ValuePropositionTechWeb';
import CompetitorAnalysisTechWeb from '../techModernComponents/CompetitorAnalysisTechWeb';
import GKG from '../techModernComponents/GKG';
import ChannelsTechWeb from '../techModernComponents/ChannelsTechWeb';
import KeyActivitiesTechWeb from '../techModernComponents/KeyActivitiesTechWeb';
import FinanceTechWeb from '../techModernComponents/FinanceTechWeb';
import SustainAbilityTechWeb from '../techModernComponents/SustainAbilityTechWeb';
import ContactUsTechWeb from '../techModernComponents/ContactUsTechWeb';
import { StyledWrapper } from '../../main/utils/helpers';
import Desirability from '../techModernComponents/Desirability';
import Viability from '../techModernComponents/Viability';
import Feasibility from '../techModernComponents/Feasibility';
import { COLOR_PALETTE_ARRAY_TECH, COLORS } from '../../main/utils/const';

const { white, black } = COLORS;

const TechModernTemplateWeb = ({
  locales,
  bmcEnabled,
  testing,
  currentSettings,
}) => {
  const [showAll, setShowAll] = useState(false);

  const { sections } = useParams();

  const showSections = useMemo(() => {
    if (!sections) {
      setShowAll(true);
      return [];
    }
    const sectionArray = sections.split('-');
    if (!sectionArray.length) {
      setShowAll(true);
      return [];
    }
    return sectionArray;
  }, [sections]);

  const { contactUs } = useSelector((state) => state.portfolio);

  const colorTemplate = currentSettings?.colorPalette?.split('|');
  const [firstColor, secondColor, thirdColor, fourthColor] = colorTemplate;
  const { shortcuts } = locales.portfolio.export;
  const {
    introduction,
    projectSummary,
    team,
    bmc,
    testing: test,
    desirabilitySection,
    customerSegment,
    valueProposition,
    competitorAnalysis,
    customerRelationships,
    channels,
    feasibility,
    keyActivities,
    viability,
    finance,
    sustainability,
    contactUs: contact,
  } = shortcuts;

  const colorPaletteNumber = COLOR_PALETTE_ARRAY_TECH[firstColor];

  const isShowSections = (sectionName) => {
    return showAll || showSections.includes(sectionName);
  };

  return (
    <StyledWrapper
      customtitlefont={currentSettings?.customTitleFont}
      customtitlecolor={currentSettings?.customTitleColor}
      custombodyfont={currentSettings?.customBodyFont}
      custombodycolor={currentSettings?.customBodyColor}
    >
      {isShowSections(introduction) && (
        <IntroductionTechWeb
          titleColor={firstColor}
          backgroundImage={fourthColor}
          background={secondColor}
        />
      )}
      {isShowSections(projectSummary) && (
        <SummaryTechWeb
          locales={locales}
          backgroundSummary={fourthColor}
          colorStyle={firstColor}
          colorPaletteNumber={colorPaletteNumber}
        />
      )}
      {isShowSections(team) && (
        <TeamTechWeb
          locales={locales}
          styleColor={firstColor}
          background={thirdColor}
          backgroundImage={fourthColor}
        />
      )}
      {bmcEnabled && isShowSections(bmc) && (
        <BMC
          locales={locales}
          titleColor={firstColor}
          backgroundCanvas={`${firstColor}33`}
          iconFill={firstColor}
          backgroundIcon={thirdColor}
          backgroundItems={white}
          backgroundSection={white}
          background={secondColor}
        />
      )}
      {testing && isShowSections(test) && (
        <Testing
          locales={locales}
          titleColor={firstColor}
          background={thirdColor}
          borderCardColor={`${firstColor}33`}
          iconFill={black}
          iconStroke={black}
        />
      )}
      {bmcEnabled && isShowSections(desirabilitySection) && (
        <Desirability
          locales={locales}
          backgroundSection={fourthColor}
          colorPaletteNumber={colorPaletteNumber}
        />
      )}
      {bmcEnabled && isShowSections(customerSegment) && (
        <CustomerSegment
          locales={locales}
          titleColor={firstColor}
          borderCardColor={`${firstColor}33`}
          backgroundImage={fourthColor}
          background={secondColor}
        />
      )}

      {bmcEnabled && isShowSections(valueProposition) && (
        <ValuePropositionTechWeb
          locales={locales}
          titleColor={firstColor}
          background={thirdColor}
          backgroundImage={fourthColor}
        />
      )}
      {bmcEnabled && isShowSections(competitorAnalysis) && (
        <CompetitorAnalysisTechWeb
          locales={locales}
          titleColor={firstColor}
          background={secondColor}
          backgroundTable={firstColor}
          backgroundImage={fourthColor}
        />
      )}
      {bmcEnabled && isShowSections(customerRelationships) && (
        <GKG
          locales={locales}
          styleColor={firstColor}
          backgroundSection={fourthColor}
          colorPaletteNumber={colorPaletteNumber}
        />
      )}
      {bmcEnabled && isShowSections(channels) && (
        <ChannelsTechWeb
          locales={locales}
          background={thirdColor}
          titleColor={firstColor}
          borderCardColor={`${firstColor}33`}
          backgroundImage={fourthColor}
        />
      )}
      {bmcEnabled && isShowSections(feasibility) && (
        <Feasibility
          locales={locales}
          backgroundSection={fourthColor}
          colorPaletteNumber={colorPaletteNumber}
        />
      )}
      {bmcEnabled && isShowSections(keyActivities) && (
        <KeyActivitiesTechWeb
          locales={locales}
          background={thirdColor}
          styleColor={firstColor}
        />
      )}
      {bmcEnabled && isShowSections(viability) && (
        <Viability
          locales={locales}
          backgroundSection={fourthColor}
          colorPaletteNumber={colorPaletteNumber}
        />
      )}
      {bmcEnabled && isShowSections(finance) && (
        <FinanceTechWeb
          locales={locales}
          titleColor={firstColor}
          firstChartColor={`${firstColor}80`}
          secondChartColor={firstColor}
          borderCardColor={`${firstColor}33`}
          background={secondColor}
        />
      )}
      {bmcEnabled && isShowSections(sustainability) && (
        <SustainAbilityTechWeb
          locales={locales}
          titleColor={firstColor}
          styleColor={firstColor}
          background={thirdColor}
          backgroundRow={secondColor}
        />
      )}
      {isShowSections(contact) && (
        <ContactUsTechWeb
          data={contactUs}
          locales={locales}
          backgroundSection={fourthColor}
          colorPaletteNumber={colorPaletteNumber}
        />
      )}
    </StyledWrapper>
  );
};

export default TechModernTemplateWeb;
