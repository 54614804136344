import React from 'react';
import PropTypes from 'prop-types';
import StepOne from './StepOne';
import StepTitle from './StepTitle';
import StepTwo from './StepTwo';

const steps = [
  {
    step: StepOne,
    title: 'Create Class Name',
  },
  {
    step: StepTwo,
    title: 'Invite Students To Join',
  },
];
const Steps = ({ stepIndex, onCancel }) => {
  const Step = steps[stepIndex].step;
  return (
    <>
      <StepTitle
        currentStepIndex={stepIndex + 1}
        totalSteps={steps.length}
        title={steps[stepIndex].title}
      />
      <Step stepTitle={steps[stepIndex].title} onCancel={onCancel} />
    </>
  );
};

Steps.propTypes = {
  stepIndex: PropTypes.number,
  // eslint-disable-next-line react/no-unused-prop-types
  nextStep: PropTypes.func,
};
export default Steps;
