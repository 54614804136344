import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import axios from 'axios';

import ConfirmationModal from '../../../main/components/ConfirmationModal';
import Spinner from '../../../main/components/Spinner';
import SvgIcon from '../../../main/components/SvgIcon';
import * as Route from '../../support/serverRoutes';
import { transformResponseCollection } from '../../../main/utils/helpers';

const defaultSelectValue = null;

const inputOptions = (collection, users) =>
  collection
    .filter((item) => !users.find((user) => user.id === item.id))
    .map((item) => {
      return { label: item.name, value: item.id };
    });

export default function Lecturers({
  classroomId,
  locales: {
    common,
    admin: { classroom },
  },
}) {
  const [selectValue, setSelectValue] = useState(defaultSelectValue);
  const [options, setOptions] = useState([]);

  const {
    classroomLecturer: { collection, uploading },
  } = useSelector((state) => state);

  const {
    classroomLecturer: {
      fetchClassroomLecturers,
      addClassroomLecturer,
      removeClassroomLecturer,
    },
  } = useDispatch();

  useEffect(() => {
    fetchClassroomLecturers(classroomId);
  }, []);

  function onOptionClick({ value }) {
    setSelectValue(defaultSelectValue);
    addClassroomLecturer({ classroomId, lecturerId: value });
  }

  function searchLecturers(value, force = false) {
    if (value || force) {
      axios(Route.lecturers(value, classroomId)).then((response) => {
        setOptions(
          inputOptions(
            transformResponseCollection(response.data.data),
            collection,
          ),
        );
      });
    }
  }

  return (
    <div className="lecturers">
      <header className="admin-header">
        <h3>Educators</h3>
      </header>

      <div className="lecturers__search">
        <Select
          options={options}
          value={selectValue}
          placeholder={classroom.enterLecturerName}
          onInputChange={searchLecturers}
          onChange={onOptionClick}
          onMenuOpen={() => searchLecturers('', true)}
          noOptionsMessage={() => classroom.lecturersNotFound}
        />
      </div>

      <div className="lecturers__list">
        {uploading ? (
          <Spinner />
        ) : (
          collection.map((lecturer) => (
            <div className="lecturers-item" key={lecturer.id}>
              <div className="lecturers-item__title">
                {lecturer.name}
                <div className="lecturers-item__email">{lecturer.email}</div>
              </div>

              {collection.length > 1 && (
                <ConfirmationModal
                  body={common.areYouSure}
                  confirmText={common.deleteText}
                  className="lecturers-item__remove"
                  onConfirm={() =>
                    removeClassroomLecturer({
                      classroomId,
                      lecturerId: lecturer.id,
                    })
                  }
                >
                  <SvgIcon name="x" />
                </ConfirmationModal>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
}
