import {
  Paragraph,
  TextRun,
  Table,
  TableRow,
  TableCell,
  WidthType,
  HeightRule,
  TableAnchorType,
  OverlapType,
  TableLayoutType,
} from 'docx';

import {
  pageSize,
  convertPixelToTwip,
  convertFontSize,
  COLORS,
  transparentBorder,
  tableWidth,
  pageMargin,
  getPageBg,
  whiteBgImage,
} from '../Constants';
import { marginTableRow, economicsTableCell } from '../helpers/index';

export default async (locales, portfolio, product, options) => {
  const price = (source, before = '', after = '') => {
    return source == null || Number.isNaN(source)
      ? ''
      : `${before}${source}${after}`;
  };

  const buildTableData = () => {
    const tableHead = new TableRow({
      height: {
        value: convertPixelToTwip(28),
        rule: HeightRule.ATLEAST,
      },
      children: [
        economicsTableCell(
          locales.finance.table.productName,
          true,
          true,
          false,
          false,
          options.economics?.borderColor,
        ),
        economicsTableCell(
          locales.finance.table.unit,
          true,
          true,
          false,
          false,
          options.economics?.borderColor,
        ),
        economicsTableCell(
          locales.finance.table.unitCost,
          true,
          true,
          false,
          false,
          options.economics?.borderColor,
        ),
        economicsTableCell(
          locales.finance.table.salePrice,
          true,
          true,
          false,
          false,
          options.economics?.borderColor,
        ),
        economicsTableCell(
          locales.finance.table.grossProfit,
          true,
          true,
          false,
          false,
          options.economics?.borderColor,
        ),
        economicsTableCell(
          locales.finance.table.grossMargin,
          true,
          true,
          false,
          false,
          options.economics?.borderColor,
        ),
      ],
    });

    const rowsWithData = product.collection.map(
      ({ name, unit, costPrice, endUserPrice }, index) => {
        const grossProfit = parseFloat(+endUserPrice - +costPrice).toFixed(2);
        const grossMargin =
          endUserPrice && costPrice
            ? parseFloat((grossProfit / +endUserPrice) * 100).toFixed(0)
            : 0;
        const isNeedBottomBorder = index + 1 === product.collection.length;
        const isOdd = index % 2 === 0;

        return new TableRow({
          height: {
            value: convertPixelToTwip(28),
            rule: HeightRule.ATLEAST,
          },
          children: [
            economicsTableCell(
              name,
              false,
              false,
              isNeedBottomBorder,
              isOdd,
              options.economics?.borderColor,
            ),
            economicsTableCell(
              unit,
              false,
              false,
              isNeedBottomBorder,
              isOdd,
              options.economics?.borderColor,
            ),
            economicsTableCell(
              price(costPrice),
              false,
              false,
              isNeedBottomBorder,
              isOdd,
              options.economics?.borderColor,
            ),
            economicsTableCell(
              price(endUserPrice),
              false,
              false,
              isNeedBottomBorder,
              isOdd,
              options.economics?.borderColor,
            ),
            economicsTableCell(
              price(grossProfit),
              false,
              false,
              isNeedBottomBorder,
              isOdd,
              options.economics?.borderColor,
            ),
            economicsTableCell(
              price(grossMargin, '', '%'),
              false,
              false,
              isNeedBottomBorder,
              isOdd,
              options.economics?.borderColor,
            ),
          ],
        });
      },
    );

    rowsWithData.unshift(tableHead);

    return rowsWithData;
  };

  return {
    properties: {
      page: {
        size: pageSize,
        margin: pageMargin,
      },
    },
    children: [
      getPageBg(options.economic?.pageBg),
      new Table({
        float: {
          horizontalAnchor: TableAnchorType.TEXT,
          verticalAnchor: TableAnchorType.TEXT,
          overlap: OverlapType.NEVER,
        },
        width: {
          size: convertPixelToTwip(762),
          type: WidthType.DXA,
        },
        layout: TableLayoutType.AUTOFIT,
        rows: [
          marginTableRow(150, 762, COLORS.WHITE, 1),
          new TableRow({
            height: {
              value: convertPixelToTwip(27),
              rule: HeightRule.ATLEAST,
            },
            children: [
              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.WHITE),
                children: [
                  // Title
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Revenue Strategy',
                        color:
                          options.economic?.subtitleColor ||
                          options.bodyColor ||
                          COLORS.BLACK,
                        font: options.bodyFont,
                        size: convertFontSize(16),
                        allCaps: true,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
          // data row
          new TableRow({
            height: {
              value: convertPixelToTwip(28),
              rule: HeightRule.ATLEAST,
            },
            children: [
              new TableCell({
                width: {
                  size: convertPixelToTwip(762),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.WHITE),
                children: [
                  new Table({
                    float: {
                      horizontalAnchor: TableAnchorType.TEXT,
                      verticalAnchor: TableAnchorType.TEXT,
                      overlap: OverlapType.NEVER,
                    },
                    width: {
                      size: convertPixelToTwip(762),
                      type: WidthType.DXA,
                    },
                    layout: TableLayoutType.AUTOFIT,
                    rows: buildTableData(),
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      whiteBgImage(options.economic?.pageBg),
    ],
  };
};
