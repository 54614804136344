import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SectionHeader from './SectionHeader';
import Textarea from '../../components/Textarea';
import Dropzone from '../../components/Dropzone';
import {
  PORTFOLIO_INTRO_LOGO_ASPECT_RATIO,
  PORTFOLIO_INTRO_BG_ASPECT_RATIO,
  TEXTAREA_DELAY,
} from '../../utils/const';

const Introduction = ({
  data,
  locales,
  locales: { portfolio },
  disabled = false,
}) => {
  const {
    portfolio: {
      updateIntroductionImage,
      updateIntroductionImageCoords,
      updateSectionFieldReducer,
      updateSectionField,
    },
  } = useDispatch();

  const { uploadingIntrologo, uploadingIntrobg } = useSelector(
    (state) => state.portfolio,
  );

  const setShowStatus = (status) => {
    updateSectionFieldReducer({
      sectionName: 'introduction',
      field: 'showStatus',
      value: status,
    });
  };

  const {
    title,
    description,
    introLogo,
    introLogoCoords,
    introBg,
    introBgCoords,
    showStatus,
  } = data;

  const handleDescriptionSubmit = useCallback((e) => {
    updateSectionField({
      sectionName: 'introduction',
      field: 'description',
      name: 'description',
      value: e.target.value,
    });
  }, []);

  const handleInputSubmit = useCallback((e) => {
    updateSectionField({
      sectionName: 'introduction',
      field: 'title',
      name: 'title',
      value: e.target.value,
    });
  }, []);

  const onLogoDrop = useCallback(([file], openCropperHandler) => {
    updateIntroductionImage({
      name: 'introLogo',
      blob: file,
      value: URL.createObjectURL(file),
      success: () => {
        openCropperHandler();
      },
    });
  }, []);

  const onLogoCropComplete = (croppedAreaPixels, innerCropData, zoom) => {
    updateIntroductionImageCoords({
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: introLogoCoords,
      name: 'introLogo',
    });
  };

  const onBgDrop = useCallback(([file], openCropperHandler) => {
    updateIntroductionImage({
      name: 'introBg',
      blob: file,
      value: URL.createObjectURL(file),
      success: () => {
        openCropperHandler();
      },
    });
  }, []);

  const onBgCropComplete = (croppedAreaPixels, innerCropData, zoom) => {
    updateIntroductionImageCoords({
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: introBgCoords,
      name: 'introBg',
    });
  };

  return (
    <div>
      <SectionHeader
        locales={portfolio}
        showStatus={showStatus}
        sectionName={portfolio.introduction.sectionName}
        setShowStatus={setShowStatus}
      />
      {showStatus && (
        <div className="introduction-wrapper">
          <div className="introduction-header">
            <Dropzone
              className="logo-upload"
              onDrop={onLogoDrop}
              onCropperSave={onLogoCropComplete}
              locales={locales}
              image={introLogo}
              coords={introLogoCoords}
              aspectRatio={PORTFOLIO_INTRO_LOGO_ASPECT_RATIO}
              uploading={uploadingIntrologo}
              disabled={disabled}
              cropper
            />

            <div className="company-wrapper">
              <div className="company-name">
                <Textarea
                  onChange={handleInputSubmit}
                  value={title}
                  delay={TEXTAREA_DELAY}
                  placeholder={portfolio.introduction.companyNamePlaceholder}
                  className="bmc-form-textarea company-name-input input"
                  disabled={disabled}
                />
              </div>
              <div className="company-description">
                <Textarea
                  onChange={handleDescriptionSubmit}
                  value={description}
                  delay={TEXTAREA_DELAY}
                  placeholder={portfolio.introduction.descriptionPlaceholder}
                  rows={2}
                  className="bmc-form-textarea textarea"
                  disabled={disabled}
                />
              </div>
            </div>
          </div>

          <Dropzone
            className="introduction-footer"
            onDrop={onBgDrop}
            onCropperSave={onBgCropComplete}
            locales={locales}
            image={introBg}
            coords={introBgCoords}
            aspectRatio={PORTFOLIO_INTRO_BG_ASPECT_RATIO}
            uploading={uploadingIntrobg}
            disabled={disabled}
            cropper
          />
        </div>
      )}
    </div>
  );
};

export default Introduction;
