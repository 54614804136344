import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CompetitorsCard from '../../valueProposition/CompetitorsCardTemplates';
import {
  COMMENT_ANALYSIS,
  TECH_MODERN_TEMPLATE_NAME,
  COLORS,
} from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import SectionHeader from '../../../components/SectionHeader';

const { white } = COLORS;

const CompetitorAnalysisTech = ({
  data: { description },
  locales,
  locales: { portfolio },
  titleColor,
  background,
  backgroundTable,
  backgroundImage,
}) => {
  const {
    competitor: { collection },
  } = useSelector((state) => state);

  const {
    portfolio: { updateSectionField },
  } = useDispatch();

  return (
    <div
      className="competitor-analysis-tech section relative"
      style={{ backgroundColor: background }}
    >
      <PortfolioCommentIcon title={COMMENT_ANALYSIS} />

      <SectionHeader
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolio.competitorAnalysis.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'competitorAnalysis',
            field: 'description',
            name: 'competitorAnalysisText',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        style={{ color: titleColor }}
      />

      <CompetitorsCard
        locales={locales}
        className="margin-top-40"
        card={{
          ...locales.valueProposition.intro.competitorsCard,
          competitors: collection,
        }}
        backgroundHeadTable={backgroundTable}
        backgroundTable={white}
        borderTableColor={backgroundTable}
        backgroundImage={backgroundImage}
      />
    </div>
  );
};

export default CompetitorAnalysisTech;
