import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { removeNewLines } from '../../main/utils/helpers';
import Button from '../../main/components/Button';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';
import { getColorDependsOnBackgroundSummary } from '../../main/utils/helpers/portfolioCurrentTemplate/getColorDependsOnBackgroundSummary';

const Summary = ({
  locales,
  background,
  colorPaletteNumber,
  isFirstColorPallete = false,
  isFourthColorPaletteNumber = false,
}) => {
  const { projectSummary } = useSelector((state) => state.portfolio);
  const { collection } = useSelector((state) => state.portfolioFile);

  const textColorDependsOnBackground =
    isFourthColorPaletteNumber && getColorDependsOnBackground(background);

  const styleColor = getColorDependsOnBackgroundSummary(
    colorPaletteNumber,
    background,
  );

  return (
    <div className="summary" style={{ backgroundColor: background }}>
      <div
        className={classNames('current-title title custom-font-title', {
          'text-black': !isFirstColorPallete && !isFourthColorPaletteNumber,
        })}
        style={{ color: textColorDependsOnBackground }}
      >
        {locales.portfolioWeb.summary}
      </div>
      <div
        className="description custom-font-body"
        style={{ color: textColorDependsOnBackground }}
      >
        {projectSummary.description &&
          removeNewLines(projectSummary.description)}
      </div>

      <div className="margin-top-40 file">
        {collection.map((file) => (
          <a key={file.id} href={file.file} target="_blank">
            <Button
              icon="icon-Export"
              className="bmc-button bmc-button--with-icon web-portfolio-files__button custom-font-body"
              title={file.name}
              style={{ color: styleColor, borderColor: styleColor }}
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Summary;
