import axios from 'axios';

import {
  ShadingType,
  convertMillimetersToTwip,
  PageOrientation,
  Paragraph,
  HeightRule,
  FrameAnchorType,
  HorizontalPositionAlign,
  VerticalPositionAlign,
  ImageRun,
  HorizontalPositionRelativeFrom,
  VerticalPositionRelativeFrom,
  Table,
  WidthType,
  TableLayoutType,
  TableRow,
  TableCell,
  VerticalAlign,
  AlignmentType,
  TableAnchorType,
  OverlapType,
  BorderStyle,
} from 'docx';
// eslint-disable-next-line import/no-cycle
import { buildFriendlyOptions } from './FriendlyOptions';
// eslint-disable-next-line import/no-cycle
import { buildArchitectureOptions } from './ArchitectureOptions';
// eslint-disable-next-line import/no-cycle
import { buildCurrentOptions } from './CurrentOptions';
// eslint-disable-next-line import/no-cycle
import { buildColourfunOptions } from './ColourfunOptions';
// eslint-disable-next-line import/no-cycle
import {
  emptyCell,
  isSelectedCustomPalette,
  marginTableRow,
  textRun,
} from '../helpers/index';
import {
  BLOG_FRIENDLY_TEMPLATE_NAME,
  COLOURFUL_TEMPLATE_NAME,
  CURRENT_TEMPLATE_NAME,
  BOLD_TEMPLATE_NAME,
} from '../../../../../utils/const';
// eslint-disable-next-line import/no-cycle
import { buildBoldOptions } from './BoldOptions';
import {
  changeImageColor,
  loadImage,
  mergeImagesWithoutShift,
  picaResizeImage,
  resizeImage,
} from '../helpers/ImageService/utils';
import ColourfunLogoLayer from '../assets/colourfunLogoLayer.png';
import { portfolioFilesDownload } from '../../../../../utils/serverRoutes';

export const convertPixelToTwip = (pixel) => {
  return pixel * 20;
};
export const convertPixel = (pixel) => {
  return pixel * (96 / 72);
};
export const convertPixelToFrameSize = (pixel) => {
  return pixel * 96 * 72;
};
export const convertFontSize = (fontSize) => {
  return fontSize * 2;
};
export const convertCentimeterToEMU = (centimeter) => {
  return centimeter * 360000;
};

export const tableWidth = 840;
const margin = `${28}pt`;
const zeroMargin = `${0}pt`;
export const pageMargin = {
  top: margin,
  right: margin,
  bottom: margin,
  left: margin,
};

export const noPageMargin = {
  top: zeroMargin,
  right: zeroMargin,
  bottom: zeroMargin,
  left: zeroMargin,
};

export const FONTS = {
  LATO: 'Lato',
  NIXIE: 'Nixie One',
  NOTICIA: 'Noticia Text',
  OPENSANS: 'Open Sans',
  PRATA: 'Prata',
  NUNITO: 'Nunito',
  CONTENT: 'Content',
};
export const COLORS = {
  GRAY: 'ACB5BD',
  GRAY2: 'A8A8A8',
  GRAY3: 'F7F6F6',
  GRAY4: 'E9E8E6',
  GREEN: '00FF00',
  WHITE: 'FFFFFF',
  BLACK: '000000',
  RED: 'FF0000',
  BLUE: '0000FF',
  PRIMARY: 'EFEDEA',
  FRIENDLY_LIGHT: 'CCD1C9',
  FRIENDLY_DARK: '788671',
  CURRENT_LIGHT: 'F8F9FA',
  CURRENT_DARK: '00AFC9',
  CURRENT_MIDDLE: 'F9FEFD',
  CURRENT_BLUE: '0984FF',
  CURRENT_LIGHT_GREEN: '8FC5BE',
  CURRENT_BLACK_LIGHT: '3D415F',
  CURRENT_GRAY: 'DDE2E5',
  CURRENT_VIOLET: '615BDC',
  CURRENT_ORANGE: 'FF6D00',
  CURRENT_LIGHT_VIOLET: 'F1F3FD',
  CURRENT_LIGHT_BLUE: 'EAFCFF',
  FUN_LIGHT: 'FEF6F3',
  FUN_DARK: 'F1A387',
  FUN_BLUE: '1F3260',
  FUN_GREEN: '355D3A',
  FUN_ORANGE_LIGHT: 'ECDEC4',
  BOLD_YELLOW2: '8F8051',
};
export const fontsMapping = {
  'Andale Mono': 'Andalé Mono',
  Arial: 'Arial',
  Baskerville: 'Baskerville',
  'Bradley Hand': 'Bradley Hand ITC',
  'Brush Script MT': 'Brush Script MT',
  'Comic Sans MS': 'Comic Sans MS',
  Courier: 'Courier New',
  Georgia: 'Georgia',
  Lato: 'Lato',
  Lucida: 'Lucida Blackletter',
  Luminari: 'Apple Chanceri',
  Monaco: 'Monaco',
  'Nixie One': 'COURIER NEW',
  'Noticia Text': 'News Gothic MT',
  Nunito: 'Microsoft Sans Serif',
  'Open Sans': 'Arial',
  Palatino: 'Palatino Linotype',
  Prata: 'Goudy Old Style',
  Tahoma: 'Tahoma',
  'Times New Roman': 'Times New Roman',
  Verdana: 'Verdana',
  'Bodoni 72': 'Bodoni 72',
  Futura: 'Futura',
  Garamond: 'Garamond',
  'Cooper Black': 'Cooper Black',
  Avenir: 'Avenir',
  STHupo: 'STHupo',
  'Kannada MN': 'Kannada MN',
  DIN: 'Bahnschrift',
  Kanit: 'Montserrat',
  'Martel Sans': 'Arial',
};
export const shadingBox = (background, color) => ({
  type: ShadingType.SOLID,
  color: background,
  fill: color || COLORS.BLACK,
});

export const shadingBlackBox = {
  type: ShadingType.SOLID,
  color: COLORS.BLACK,
  fill: COLORS.WHITE,
};

export const pageSize = {
  orientation: PageOrientation.LANDSCAPE,
  width: convertMillimetersToTwip(210),
  height: convertMillimetersToTwip(297),
};

export const transparentBorder = (color) => ({
  top: { color: color || COLORS.PRIMARY },
  left: { color: color || COLORS.PRIMARY },
  right: { color: color || COLORS.PRIMARY },
  bottom: { color: color || COLORS.PRIMARY },
});

export const singleBorder = (borderColor) => ({
  top: {
    color: borderColor,
    style: 'single',
    size: 6,
  },
  left: {
    color: borderColor,
    style: 'single',
    size: 6,
  },
  right: {
    color: borderColor,
    style: 'single',
    size: 6,
  },
  bottom: {
    color: borderColor,
    style: 'single',
    size: 6,
  },
});

export const bottomBorder = (transparentColor, borderColor) => ({
  top: { color: transparentColor },
  left: { color: transparentColor },
  right: { color: transparentColor },
  bottom: {
    color: borderColor,
    space: 2,
    style: 'single',
    size: 6,
  },
});

// Convert Base64 to Blob
const base64toBlob = (base64String) => {
  if (!base64String) return new Blob([], { type: 'application/octet-stream' });

  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  return new Blob([byteArray], { type: 'application/octet-stream' });
};

export const getBlobFromUrl = async (src) => {
  if (!src) return false;

  const backendEndpoint = portfolioFilesDownload();
  const params = { src };
  let result = '';

  if (src.includes('s3.amazonaws.com')) {
    result = await axios
      .post(backendEndpoint, params)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error('Error fetching data from the backend:', error);
      });
  } else {
    result = await fetch(src, {
      cache: 'no-store',
    }).then((r) => {
      if (r.ok) return r.blob();

      return r.ok;
    });
  }

  if (result instanceof Blob || result instanceof Boolean) return result;

  return base64toBlob(result.data);
};

export const getOptions = (
  templateName,
  templateCustomSettings,
  templateData,
) => {
  const isCustomPalette = isSelectedCustomPalette(templateCustomSettings);

  switch (templateName) {
    case CURRENT_TEMPLATE_NAME:
      return buildCurrentOptions(
        COLORS,
        FONTS,
        templateCustomSettings,
        templateData,
        isCustomPalette,
      );
    case BLOG_FRIENDLY_TEMPLATE_NAME:
      return buildFriendlyOptions(
        COLORS,
        FONTS,
        templateCustomSettings,
        templateData,
        isCustomPalette,
      );
    case COLOURFUL_TEMPLATE_NAME:
      return buildColourfunOptions(
        COLORS,
        FONTS,
        templateCustomSettings,
        templateData,
        isCustomPalette,
      );
    case BOLD_TEMPLATE_NAME:
      return buildBoldOptions(
        COLORS,
        FONTS,
        templateCustomSettings,
        templateData,
        isCustomPalette,
      );
    default:
      return buildArchitectureOptions(
        COLORS,
        FONTS,
        templateCustomSettings,
        templateData,
        isCustomPalette,
      );
  }
};

export const getMeta = (url) => {
  const imgUrl = url;

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = imgUrl;
  });
};

export const countImgSize = (img, options = {}) => {
  const imgW = img.naturalWidth;
  const imgH = img.naturalHeight;
  const isImgHorizontal = imgW > imgH;
  const widthToHeightProp = (imgW / imgH).toFixed(2);
  const heightToWidthProp = (imgH / imgW).toFixed(2);

  if (options.horizontal) {
    return {
      imgWidth: options.blockWidth,
      imgHeight: options.blockWidth / widthToHeightProp,
    };
  }

  if (options.vertical) {
    const imgWidth = isImgHorizontal
      ? options.blockWidth
      : options.blockHeight / heightToWidthProp;

    const imgHeight = isImgHorizontal
      ? options.blockWidth / widthToHeightProp
      : options.blockHeight;

    return {
      imgHeight,
      imgWidth,
    };
  }
};

export const getIntroLogo = async (options, introLogo) => {
  let blobImg;
  await getBlobFromUrl(introLogo).then((res) => {
    blobImg = res;
  });
  if (!blobImg) return;

  if (options.isLogo) {
    const logoImg = await loadImage(introLogo);
    const imgSizeData = countImgSize(logoImg, {
      horizontal: true,
      blockWidth: 112,
    });

    return new Paragraph({
      text: '',
      frame: {
        position: {
          x: options.logoPositionX || 0,
          y: options.logoPositionY,
        },
        rule: HeightRule.EXACT,
        width: convertPixelToTwip(imgSizeData.imgWidth),
        height: convertPixelToTwip(imgSizeData.imgHeight),
        anchor: {
          horizontal: FrameAnchorType.TEXT,
          vertical: FrameAnchorType.TEXT,
        },
        alignment: {
          x: HorizontalPositionAlign.RIGHT,
          y: VerticalPositionAlign.TOP,
        },
      },
      // shading: shadingBox(options.mainPage.imgBg),
      children: [
        new ImageRun({
          data: blobImg,
          transformation: {
            width: convertPixel(imgSizeData.imgWidth),
            height: convertPixel(imgSizeData.imgHeight),
          },
        }),
      ],
    });
  }
};

export const getPageBg = (pageOptionsBg) => {
  if (pageOptionsBg)
    return new Paragraph({
      frame: {
        position: {
          x: 0,
          y: 0,
        },
        width: convertPixelToTwip(tableWidth),
        height: convertPixelToTwip(539 + 56),
        rule: HeightRule.EXACT,
        anchor: {
          horizontal: FrameAnchorType.TEXT,
          vertical: FrameAnchorType.TEXT,
        },
        alignment: {
          x: HorizontalPositionAlign.CENTER,
          y: VerticalPositionAlign.TOP,
        },
      },
      shading: shadingBox(pageOptionsBg),
    });
};

export const getClearColorVar = (colorPaletteString) =>
  colorPaletteString.split('|').map((hex) => hex.slice(1));

export const getTemplateFonts = (customSettings, defaultSettings) => {
  return {
    bodyFont: customSettings.customBodyFont
      ? fontsMapping[customSettings.customBodyFont]
      : fontsMapping[defaultSettings.bodyFont],
    titleFont: customSettings.customTitleFont
      ? fontsMapping[customSettings.customTitleFont]
      : fontsMapping[defaultSettings.titleFont],
    titleColor: customSettings.customTitleColor?.slice(1),
    bodyColor: customSettings.customBodyColor?.slice(1),
  };
};

const colorIndexMapping = {
  modern: 0,
  colourful: 1,
  architectural: 1,
  bold: 3,
};

export const getPageBgColor = (state) => {
  if (state.portfolioStyle.activeTemplateName !== COLOURFUL_TEMPLATE_NAME) {
    const {
      portfolioStyle: { activeTemplateName, templateSettings },
    } = state;

    return {
      color: `${templateSettings[activeTemplateName].colorPalette
        .split('|')
        .at(colorIndexMapping[activeTemplateName])
        .slice(1)}`,
    };
  }

  return { color: 'FFFFFF' };
};

const baseWhiteImg =
  'R0lGODlhAQABAIAAAP7//wAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==';

export const whiteBgImage = (backgroundOption) => {
  if (!backgroundOption) return;

  return new Paragraph({
    frame: {
      position: {
        x: 0,
        y: 0,
      },
      width: convertPixelToTwip(tableWidth),
      height: convertPixelToTwip(2),
      rule: HeightRule.EXACT,
      anchor: {
        horizontal: FrameAnchorType.PAGE,
        vertical: FrameAnchorType.PAGE,
      },
      alignment: {
        x: HorizontalPositionAlign.CENTER,
        y: VerticalPositionAlign.TOP,
      },
    },
    children: [
      new ImageRun({
        data: baseWhiteImg,
        transformation: {
          width: convertPixelToTwip(tableWidth),
          height: convertPixelToTwip(539 + 56),
        },
        floating: {
          horizontalPosition: {
            relative: HorizontalPositionRelativeFrom.PAGE,
            offset: 0,
          },
          verticalPosition: {
            relative: VerticalPositionRelativeFrom.PAGE,
            offset: 0,
          },
          behindDocument: true,
          zIndex: 0,
        },
      }),
    ],
  });
};

export const emptyTableForNoImgBlock = (
  optionsBg,
  width,
  height,
  cellWidth,
  titleText = '',
  emptyCellWidth = 0,
) => {
  return new Table({
    width: {
      size: convertPixelToTwip(width),
      type: WidthType.DXA,
    },
    layout: TableLayoutType.AUTOFIT,
    rows: [
      new TableRow({
        height: {
          value: convertPixelToTwip(height),
          rule: HeightRule.ATLEAST,
        },
        children: [
          emptyCell(emptyCellWidth, COLORS.WHITE),
          new TableCell({
            width: {
              size: convertPixelToTwip(cellWidth),
              type: WidthType.DXA,
            },
            verticalAlign: VerticalAlign.CENTER,
            borders: transparentBorder(COLORS.PRIMARY),
            shading: shadingBox(optionsBg),
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                text: titleText,
              }),
            ],
          }),
        ],
      }),
    ],
  });
};

const getColourfunLogo = async (logo, logoBg) => {
  const layerImg = await loadImage(ColourfunLogoLayer);
  const coloredLayer = await changeImageColor(layerImg, 196, 208, logoBg);
  let result;

  if (logo) {
    const logoImg = await loadImage(logo);
    const logoRatio = logoImg.width / logoImg.height;

    const resizedLayer = await resizeImage(coloredLayer, 208, 'contain');
    const resizedLogo = await picaResizeImage(
      logoImg,
      112,
      'contain',
      112 / logoRatio,
    );
    result = await mergeImagesWithoutShift(
      [resizedLayer, resizedLogo],
      208,
      27,
      27,
    );
  } else {
    result = coloredLayer;
  }

  return Uint8Array.from(atob(result.src?.split(',')[1]), (c) =>
    c.charCodeAt(0),
  );
};

const colourfunLeftPart = (options, introduction, behindDocument) => {
  if (options.templateName === COLOURFUL_TEMPLATE_NAME) {
    const logoParagraph = () => {
      return new Paragraph({
        alignment: AlignmentType.CENTER,
        spacing: { after: convertPixelToTwip(265) },
        children: [
          new ImageRun({
            floating: {
              horizontalPosition: {
                offset: convertCentimeterToEMU(0.8),
              },
              verticalPosition: {
                offset: convertCentimeterToEMU(0),
              },
              zIndex: 10,
              behindDocument,
            },
            data: getColourfunLogo(
              introduction.introLogo?.original,
              options.mainPage.logoBg,
            ),
            transformation: {
              width: convertPixel(options.mainPage.logoImgWidth),
              height: convertPixel(options.mainPage.logoImgHeight),
            },
          }),
        ],
      });
    };

    return new TableCell({
      margins: {
        left: convertPixelToTwip(20),
      },
      width: {
        size: convertPixelToTwip(1200),
        type: WidthType.DXA,
      },
      height: {
        size: convertPixelToTwip(2000),
        rule: HeightRule.ATLEAST,
      },
      borders: transparentBorder(COLORS.PRIMARY),
      verticalAlign: VerticalAlign.CENTER,
      children: [
        logoParagraph(),
        new Paragraph({
          alignment: AlignmentType.START,
          spacing: { after: convertPixelToTwip(15) },
          children: [
            textRun(introduction.title, {
              font: options.bodyFont,
              size: 36,
              color:
                options.mainPage.fontColor || options.bodyColor || COLORS.BLACK,
            }),
          ],
        }),
        new Paragraph({
          alignment: AlignmentType.START,
          children: [
            textRun(introduction.description, {
              font: options.bodyFont,
              size: 16,
              color:
                options.mainPage.fontColor || options.bodyColor || COLORS.BLACK,
            }),
          ],
        }),
      ],
    });
  }
};

export const mainPageImgTableWrapper = (
  mainImg,
  options,
  text,
  behindDocument = false,
  offset = 7.6,
  portfolio = {},
) =>
  new Table({
    float: {
      horizontalAnchor: TableAnchorType.TEXT,
      verticalAnchor: TableAnchorType.TEXT,
      overlap: OverlapType.NEVER,
      absoluteVerticalPosition: convertPixelToTwip(0),
      absoluteHorizontalPosition: convertPixelToTwip(0),
    },
    width: {
      size: convertPixelToTwip(tableWidth),
      type: WidthType.DXA,
    },
    layout: TableLayoutType.AUTOFIT,
    rows: [
      new TableRow({
        height: {
          value: convertPixelToTwip(340),
          rule: HeightRule.ATLEAST,
        },
        children: [
          colourfunLeftPart(options, portfolio?.introduction, behindDocument),
          new TableCell({
            margins: {
              right: convertPixelToTwip(40),
            },
            shading: options?.mainPage.imgBg
              ? shadingBox(options?.mainPage.imgBg)
              : null,
            width: {
              size: convertPixelToTwip(tableWidth),
              type: WidthType.DXA,
            },
            verticalAlign: VerticalAlign.CENTER,
            // shading: !blobImg ? shadingBox(options.noImgBg) : null,
            borders: transparentBorder(COLORS.PRIMARY),
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new ImageRun({
                    floating: {
                      horizontalPosition: {
                        offset: options.mainPage.isColourfun
                          ? convertCentimeterToEMU(2)
                          : convertCentimeterToEMU(offset),
                      },
                      verticalPosition: { offset: 0 },
                      zIndex: 10,
                      behindDocument,
                    },
                    data: mainImg,
                    transformation: {
                      width: convertPixel(options.mainPage.imgWidth),
                      height: convertPixel(options.mainPage.imgHeight),
                    },
                  }),
                ],
              }),
              new Table({
                float: {
                  horizontalAnchor: TableAnchorType.TEXT,
                  verticalAnchor: TableAnchorType.TEXT,
                  overlap: OverlapType.NEVER,
                  absoluteVerticalPosition: convertPixelToTwip(0),
                  absoluteHorizontalPosition: convertPixelToTwip(280),
                },
                width: {
                  size: convertPixelToTwip(280),
                  type: WidthType.DXA,
                },
                layout: TableLayoutType.AUTOFIT,
                rows: [
                  new TableRow({
                    height: {
                      value: convertPixelToTwip(340),
                      rule: HeightRule.ATLEAST,
                    },
                    children: [
                      new TableCell({
                        width: {
                          size: convertPixelToTwip(340),
                          type: WidthType.DXA,
                        },
                        verticalAlign: VerticalAlign.CENTER,
                        borders: transparentBorder(COLORS.PRIMARY),
                        children: [
                          new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                              textRun(text, {
                                font: options.titleFont,
                                color: options.titleColor,
                                size: 24,
                              }),
                            ],
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  });

export const imageRunColourful = (
  img,
  width,
  height,
  hOffset,
  vOffset,
  vPositionType = 'default',
  behindDocument = true,
) => {
  const verticalPosition = {
    default: VerticalPositionRelativeFrom.PAGE,
    bottom_margin: VerticalPositionRelativeFrom.BOTTOM_MARGIN,
    paragraph: VerticalPositionRelativeFrom.PARAGRAPH,
  };

  return new ImageRun({
    data: img,
    transformation: {
      width: convertPixel(width),
      height: convertPixel(height),
    },
    floating: {
      behindDocument,
      horizontalPosition: {
        relative: HorizontalPositionRelativeFrom.PAGE,
        offset: convertCentimeterToEMU(hOffset),
      },
      verticalPosition: {
        relative: verticalPosition[vPositionType],
        offset: convertCentimeterToEMU(vOffset),
      },
    },
  });
};

export const paragraphWithImg = (img, sizeData) =>
  new Paragraph({
    frame: {
      position: { x: 0, y: 0 },
      width: convertPixelToTwip(285),
      height: convertPixelToTwip(400),
      rule: HeightRule.EXACT,
      anchor: {
        horizontal: FrameAnchorType.TEXT,
        vertical: FrameAnchorType.TEXT,
      },
      alignment: {
        x: HorizontalPositionAlign.CENTER,
        y: VerticalPositionAlign.TOP,
      },
    },
    children: [
      new ImageRun({
        data: img,
        transformation: {
          width: convertPixel(sizeData.imgWidth),
          height: convertPixel(sizeData.imgHeight),
        },
        floating: {
          horizontalPosition: { offset: 0 },
          verticalPosition: { offset: 0 },
          zIndex: 10,
          allowOverlap: false,
        },
      }),
    ],
  });

export const noImgTableWithText = (text, bodyColor, bodyFont, size) =>
  new Table({
    float: {
      horizontalAnchor: TableAnchorType.TEXT,
      verticalAnchor: TableAnchorType.TEXT,
      overlap: OverlapType.NEVER,
    },
    width: {
      size: convertPixelToTwip(size),
      type: WidthType.DXA,
    },
    layout: TableLayoutType.AUTOFIT,
    rows: [
      new TableRow({
        height: {
          value: convertPixelToTwip(size),
          rule: HeightRule.ATLEAST,
        },
        children: [
          new TableCell({
            width: {
              size: convertPixelToTwip(size),
              type: WidthType.DXA,
            },
            verticalAlign: VerticalAlign.CENTER,
            borders: transparentBorder(COLORS.PRIMARY),
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  textRun(text, {
                    color: bodyColor,
                    font: bodyFont,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  });

export const titleDescription = (options, title, description) => {
  if (options.mainPage.isColourfun) return;
  const shading = options.mainPage.titleBg
    ? shadingBox(options.mainPage.titleBg)
    : null;
  const borders = options.mainPage.titleBg
    ? {
        left: { color: options.mainPage.titleBg },
        right: { color: options.mainPage.titleBg },
        bottom: { color: options.mainPage.titleBg },
        top: {
          style: BorderStyle.SINGLE,
          size: convertPixelToTwip(20),
          color: options.mainPage.titleBg,
        },
      }
    : transparentBorder(COLORS.WHITE);

  const descBorder = options.mainPage.titleBg
    ? {
        top: { color: options.mainPage.titleBg },
        bottom: {
          style: BorderStyle.SINGLE,
          size: convertPixelToTwip(20),
          color: options.mainPage.titleBg,
        },
        left: {
          style: BorderStyle.SINGLE,
          size: convertPixelToTwip(28),
          color: options.mainPage.titleBg,
        },
        right: {
          style: BorderStyle.SINGLE,
          size: convertPixelToTwip(28),
          color: options.mainPage.titleBg,
        },
      }
    : transparentBorder(COLORS.WHITE);

  const buildContentRows = () => {
    if (options.mainPage.isArchitectural) {
      return [
        new TableRow({
          height: {
            value: convertPixelToTwip(42),
            rule: HeightRule.ATLEAST,
          },
          children: [
            // margin cell
            new TableCell({
              width: {
                size: convertPixelToTwip(28),
                type: WidthType.DXA,
              },
              children: [],
              shading,
              borders,
            }),
            // title cell
            new TableCell({
              width: {
                size: convertPixelToTwip(730 - 28 - 28),
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              borders,
              shading,

              children: [
                new Paragraph({
                  alignment: AlignmentType.START,
                  children: [
                    textRun(title, {
                      font: options.titleFont,
                      color:
                        options.mainPage.fontColor ||
                        options.titleColor ||
                        COLORS.BLACK,
                      size: 36,
                      allCaps: true,
                    }),
                  ],
                }),
              ],
            }),
            // margin cell
            new TableCell({
              width: {
                size: convertPixelToTwip(56),
                type: WidthType.DXA,
              },
              children: [],
              shading,
              borders,
            }),
            // description cell
            new TableCell({
              width: {
                size: convertPixelToTwip(730),
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              shading,
              borders: descBorder,
              children: [
                new Paragraph({
                  alignment: AlignmentType.START,
                  children: [
                    textRun(description, {
                      font: options.bodyFont,
                      size: 16,
                      color:
                        options.mainPage.fontColor ||
                        options.bodyColor ||
                        COLORS.BLACK,
                    }),
                  ],
                }),
              ],
            }),
            // margin cell
            new TableCell({
              width: {
                size: convertPixelToTwip(28),
                type: WidthType.DXA,
              },
              children: [],
              shading,
              borders,
            }),
          ],
        }),
        marginTableRow(
          20,
          786,
          options.mainPage.titleBg,
          5,
          options.mainPage.titleBg,
        ),
      ];
    }

    return [
      new TableRow({
        height: {
          value: convertPixelToTwip(42),
          rule: HeightRule.ATLEAST,
        },
        children: [
          new TableCell({
            width: {
              size: convertPixelToTwip(28),
              type: WidthType.DXA,
            },
            children: [],
            shading: shadingBox(options.mainPage.titleBg),
            borders: {
              left: { color: options.mainPage.titleBg },
              right: { color: options.mainPage.titleBg },
              bottom: { color: options.mainPage.titleBg },
              top: {
                style: BorderStyle.SINGLE,
                size: convertPixelToTwip(20),
                color: options.mainPage.titleBg,
              },
            },
          }),
          new TableCell({
            width: {
              size: convertPixelToTwip(730 - 28 - 28),
              type: WidthType.DXA,
            },
            verticalAlign: VerticalAlign.CENTER,
            borders: {
              left: { color: options.mainPage.titleBg },
              right: { color: options.mainPage.titleBg },
              bottom: { color: options.mainPage.titleBg },
              top: {
                style: BorderStyle.SINGLE,
                size: convertPixelToTwip(20),
                color: options.mainPage.titleBg,
              },
            },

            children: [
              new Paragraph({
                alignment: AlignmentType.START,
                children: [
                  textRun(title, {
                    font: options.titleFont,
                    color:
                      options.mainPage.fontColor ||
                      options.titleColor ||
                      COLORS.BLACK,
                    size: 36,
                    allCaps: true,
                  }),
                ],
              }),
            ],
            shading: shadingBox(options.mainPage.titleBg),
          }),
          new TableCell({
            width: {
              size: convertPixelToTwip(28),
              type: WidthType.DXA,
            },
            children: [],
            shading: shadingBox(options.mainPage.titleBg),
            borders: {
              left: { color: options.mainPage.titleBg },
              right: { color: options.mainPage.titleBg },
              bottom: { color: options.mainPage.titleBg },
              top: {
                style: BorderStyle.SINGLE,
                size: convertPixelToTwip(20),
                color: options.mainPage.titleBg,
              },
            },
          }),
        ],
      }),
      new TableRow({
        height: {
          value: convertPixelToTwip(42),
          rule: HeightRule.ATLEAST,
        },
        children: [
          new TableCell({
            width: {
              size: convertPixelToTwip(28),
              type: WidthType.DXA,
            },
            children: [],
            shading: shadingBox(options.mainPage.titleBg),
            borders: {
              left: { color: options.mainPage.titleBg },
              right: { color: options.mainPage.titleBg },
              top: { color: options.mainPage.titleBg },
              bottom: {
                style: BorderStyle.SINGLE,
                size: convertPixelToTwip(20),
                color: options.mainPage.titleBg,
              },
            },
          }),
          new TableCell({
            width: {
              size: convertPixelToTwip(730),
              type: WidthType.DXA,
            },
            verticalAlign: VerticalAlign.CENTER,
            borders: {
              top: { color: options.mainPage.titleBg },
              bottom: {
                style: BorderStyle.SINGLE,
                size: convertPixelToTwip(20),
                color: options.mainPage.titleBg,
              },
              left: {
                style: BorderStyle.SINGLE,
                size: convertPixelToTwip(28),
                color: options.mainPage.titleBg,
              },
              right: {
                style: BorderStyle.SINGLE,
                size: convertPixelToTwip(28),
                color: options.mainPage.titleBg,
              },
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.START,
                children: [
                  textRun(description, {
                    font: options.bodyFont,
                    size: 16,
                    color:
                      options.mainPage.fontColor ||
                      options.bodyColor ||
                      COLORS.BLACK,
                  }),
                ],
              }),
            ],
            shading: shadingBox(options.mainPage.titleBg),
          }),
          new TableCell({
            width: {
              size: convertPixelToTwip(28),
              type: WidthType.DXA,
            },
            children: [],
            shading: shadingBox(options.mainPage.titleBg),
            borders: {
              left: { color: options.mainPage.titleBg },
              right: { color: options.mainPage.titleBg },
              top: { color: options.mainPage.titleBg },
              bottom: {
                style: BorderStyle.SINGLE,
                size: convertPixelToTwip(20),
                color: options.mainPage.titleBg,
              },
            },
          }),
        ],
      }),
    ];
  };

  return new Table({
    float: {
      horizontalAnchor: TableAnchorType.TEXT,
      verticalAnchor: TableAnchorType.TEXT,
      overlap: OverlapType.NEVER,
      absoluteVerticalPosition: convertPixelToTwip(
        options.mainPage.absoluteVerticalPosition || 340 + 15 + 20,
      ),
      absoluteHorizontalPosition: convertPixelToTwip(0),
    },
    width: {
      size: convertPixelToTwip(tableWidth),
      type: WidthType.DXA,
    },
    layout: TableLayoutType.AUTOFIT,
    rows: buildContentRows(),
  });
};
