import {
  Paragraph,
  ImageRun,
  Table,
  TableRow,
  TableCell,
  AlignmentType,
  WidthType,
  HeightRule,
  TableAnchorType,
  OverlapType,
  TableLayoutType,
  VerticalAlign,
  HorizontalPositionRelativeFrom,
  VerticalPositionRelativeFrom,
} from 'docx';

import {
  pageSize,
  pageMargin,
  convertPixelToTwip,
  convertPixel,
  COLORS,
  transparentBorder,
  convertCentimeterToEMU,
  tableWidth,
  imageRunColourful,
} from '../Constants';
import {
  buildColourfulDecorateImg,
  buildTestingStatusIcon,
  emptyCell,
  marginTableRow,
  textRun,
} from '../helpers/index';
import ValidIcon from '../assets/icons/checked.svg';
import InvalidIcon from '../assets/icons/invalid.svg';
import UnsureIcon from '../assets/icons/unsure.svg';
import IncompleteIcon from '../assets/icons/incomplete.svg';
import { buildRoundedImg } from '../helpers/ImageService/utils';

export default async (locales, portfolio, testing, options) => {
  const capitalizedString = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const testingBg1 = await buildRoundedImg(31 * 30, 31 * 30, 'F29B66', 8 * 30);
  const testingBg2 = await buildRoundedImg(31 * 30, 31 * 30, '908CE1', 8 * 30);
  const testingBg3 = await buildRoundedImg(31 * 30, 31 * 30, '5AB2CA', 8 * 30);

  const testStatus = (status) => {
    if (!status) return 'Incomplete';

    return ` ${capitalizedString(status)}`;
  };

  const currentStatusIcon = async (status) => {
    let result;

    switch (status) {
      case 'valid':
        result = await buildTestingStatusIcon(
          ValidIcon,
          options.testing.statusIconColor,
        );
        break;
      case 'unsure':
        result = await buildTestingStatusIcon(
          UnsureIcon,
          options.testing.statusIconColor,
        );
        break;
      case 'invalid':
        result = await buildTestingStatusIcon(
          InvalidIcon,
          options.testing.statusIconColor,
        );
        break;
      default:
        result = await buildTestingStatusIcon(
          IncompleteIcon,
          options.testing.statusIconColor,
        );
    }

    return result;
  };

  const bmcBlocksList = (blocks) => {
    const itemsCount = blocks.length;

    const testItemBg = (index) => {
      switch (index % 3) {
        case 0:
          return testingBg3;
        case 1:
          return testingBg2;
        default:
          return testingBg1;
      }
    };

    const cellsWithData = blocks.map(
      (blockABBR, index) =>
        new TableCell({
          width: {
            size: convertPixelToTwip(36),
            type: WidthType.DXA,
          },
          borders: transparentBorder(COLORS.WHITE),
          verticalAlign: VerticalAlign.CENTER,
          children: [
            new Paragraph({
              alignment: AlignmentType.START,
              spacing: {
                before: 100,
              },
              children: [
                new ImageRun({
                  data: testItemBg(index + 1),
                  transformation: {
                    width: convertPixel(31),
                    height: convertPixel(31),
                  },
                  floating: {
                    horizontalPosition: {
                      offset: 0,
                    },
                    verticalPosition: {
                      offset: 0,
                    },
                    behindDocument: true,
                    allowOverLap: true,
                    zIndex: 1,
                  },
                }),
                textRun(` ${blockABBR}`, {
                  size: 16,
                  bold: false,
                  color: COLORS.WHITE,
                }),
              ],
            }),
          ],
        }),
    );
    let i = 0;
    while (i < 10 - itemsCount) {
      cellsWithData.push(emptyCell(36, COLORS.WHITE));
      i += 1;
    }

    return cellsWithData;
  };

  const getLeftImgBg = async () => {
    if (options.testing?.leftImgBg) {
      const img = await buildColourfulDecorateImg(
        options.testing.leftImgBg,
        options.testing.leftImgColor,
      );

      return imageRunColourful(img, 213, 339, 1, 8);
    }
  };

  const getRightImgBg = async () => {
    if (options.testing?.rightImgBg) {
      const img = await buildColourfulDecorateImg(
        options.testing.rightImgBg,
        options.testing.rightImgColor,
      );

      return new ImageRun({
        data: img,
        transformation: {
          width: convertPixel(171),
          height: convertPixel(361),
        },
        floating: {
          behindDocument: true,
          zIndex: 0,
          horizontalPosition: {
            relative: HorizontalPositionRelativeFrom.PAGE,
            offset: 8136000,
          },
          verticalPosition: {
            relative: VerticalPositionRelativeFrom.PAGE,
            offset: convertCentimeterToEMU(2),
          },
        },
      });
    }
  };

  return {
    properties: {
      page: {
        size: pageSize,
        margin: pageMargin,
      },
    },
    children: [
      new Paragraph({
        children: [await getLeftImgBg(), await getRightImgBg()],
      }),
      new Table({
        float: {
          horizontalAnchor: TableAnchorType.TEXT,
          verticalAnchor: TableAnchorType.TEXT,
          overlap: OverlapType.NEVER,
          absoluteVerticalPosition: 0,
          absoluteHorizontalPosition: 0,
        },
        width: {
          size: convertPixelToTwip(tableWidth - 56),
          type: WidthType.DXA,
        },
        layout: TableLayoutType.AUTOFIT,
        rows: [
          new TableRow({
            height: {
              value: convertPixelToTwip(27),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(30, COLORS.PRIMARY),

              new TableCell({
                width: {
                  size: convertPixelToTwip(726),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.PRIMARY),
                children: [
                  new Table({
                    float: {
                      horizontalAnchor: TableAnchorType.TEXT,
                      verticalAnchor: TableAnchorType.TEXT,
                      overlap: OverlapType.NEVER,
                      absoluteVerticalPosition: 0,
                      absoluteHorizontalPosition: 0,
                    },
                    width: {
                      size: convertPixelToTwip(726),
                      type: WidthType.DXA,
                    },
                    layout: TableLayoutType.AUTOFIT,
                    rows: [
                      // title
                      new TableRow({
                        height: {
                          value: convertPixelToTwip(27),
                          rule: HeightRule.ATLEAST,
                        },
                        children: [
                          new TableCell({
                            width: {
                              size: convertPixelToTwip(726),
                              type: WidthType.DXA,
                            },
                            borders: transparentBorder(COLORS.PRIMARY),
                            children: [
                              // Title
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                  textRun(locales.testing.sectionName, {
                                    font: options.titleFont,
                                    color: options.titleColor || COLORS.BLACK,
                                    bold: options?.testing?.titleBold,
                                    size: 24,
                                    allCaps: true,
                                  }),
                                ],
                              }),
                            ],
                          }),
                        ],
                      }),
                      marginTableRow(10, tableWidth - 56, COLORS.PRIMARY, 1),
                      // description row
                      new TableRow({
                        height: {
                          value: convertPixelToTwip(28),
                          rule: HeightRule.ATLEAST,
                        },
                        children: [
                          new TableCell({
                            width: {
                              size: convertPixelToTwip(726),
                              type: WidthType.DXA,
                            },
                            borders: transparentBorder(COLORS.PRIMARY),
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                  textRun(portfolio.testing.description, {
                                    font: options.bodyFont,
                                    color: options.bodyColor || COLORS.BLACK,
                                  }),
                                ],
                              }),
                            ],
                          }),
                        ],
                      }),
                      new TableRow({
                        height: {
                          value: convertPixelToTwip(28),
                          rule: HeightRule.ATLEAST,
                        },
                        children: [
                          new TableCell({
                            width: {
                              size: convertPixelToTwip(726),
                              type: WidthType.DXA,
                            },
                            borders: transparentBorder(COLORS.PRIMARY),
                            children: await Promise.all(
                              testing.collection.map(
                                async (testItem) =>
                                  new Table({
                                    float: {
                                      horizontalAnchor: TableAnchorType.TEXT,
                                      verticalAnchor: TableAnchorType.TEXT,
                                      overlap: OverlapType.NEVER,
                                      absoluteVerticalPosition:
                                        convertPixelToTwip(20),
                                      absoluteHorizontalPosition: 0,
                                    },
                                    width: {
                                      size: convertPixelToTwip(726),
                                      type: WidthType.DXA,
                                    },
                                    layout: TableLayoutType.AUTOFIT,
                                    rows: [
                                      marginTableRow(
                                        50,
                                        726,
                                        COLORS.PRIMARY,
                                        2,
                                      ),
                                      // Test Type
                                      new TableRow({
                                        children: [
                                          new TableCell({
                                            columnSpan: 2,
                                            width: {
                                              size: convertPixelToTwip(726),
                                              type: WidthType.DXA,
                                            },
                                            borders: transparentBorder(
                                              COLORS.WHITE,
                                            ),
                                            children: [
                                              new Paragraph({
                                                children: [
                                                  textRun('Test Type', {
                                                    bold: true,
                                                    font: options.bodyFont,
                                                    color:
                                                      options.bodyColor ||
                                                      COLORS.BLACK,
                                                  }),
                                                ],
                                              }),
                                              new Paragraph({
                                                spacing: {
                                                  before: convertPixelToTwip(5),
                                                },
                                                children: [
                                                  textRun(
                                                    capitalizedString(
                                                      testItem.test,
                                                    ),
                                                    {
                                                      font: options.titleFont,
                                                      color:
                                                        options.titleColor ||
                                                        COLORS.BLACK,
                                                      size: 20,
                                                    },
                                                  ),
                                                ],
                                              }),
                                            ],
                                          }),
                                        ],
                                      }),
                                      marginTableRow(5, 726, COLORS.WHITE, 2),
                                      // BMC Blocks Test
                                      new TableRow({
                                        height: {
                                          value: convertPixelToTwip(15),
                                          rule: HeightRule.ATLEAST,
                                        },
                                        children: [
                                          new TableCell({
                                            columnSpan: 2,
                                            width: {
                                              size: convertPixelToTwip(726),
                                              type: WidthType.DXA,
                                            },
                                            borders: transparentBorder(
                                              COLORS.PRIMARY,
                                            ),
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [
                                              new Paragraph({
                                                children: [
                                                  textRun('BMC Blocks Test', {
                                                    bold: true,
                                                    font: options.bodyFont,
                                                    color:
                                                      options.bodyColor ||
                                                      COLORS.BLACK,
                                                  }),
                                                ],
                                              }),
                                            ],
                                          }),
                                        ],
                                      }),
                                      marginTableRow(5, 726, COLORS.WHITE, 2),
                                      // BMC Blocks list with status
                                      new TableRow({
                                        height: {
                                          value: convertPixelToTwip(31),
                                          rule: HeightRule.ATLEAST,
                                        },
                                        children: [
                                          new TableCell({
                                            width: {
                                              size: convertPixelToTwip(363),
                                              type: WidthType.DXA,
                                            },
                                            borders: transparentBorder(
                                              COLORS.PRIMARY,
                                            ),
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [
                                              new Table({
                                                float: {
                                                  horizontalAnchor:
                                                    TableAnchorType.TEXT,
                                                  verticalAnchor:
                                                    TableAnchorType.TEXT,
                                                  overlap: OverlapType.NEVER,
                                                },
                                                width: {
                                                  size: convertPixelToTwip(363),
                                                  type: WidthType.DXA,
                                                },
                                                layout: TableLayoutType.AUTOFIT,
                                                rows: [
                                                  new TableRow({
                                                    height: {
                                                      value:
                                                        convertPixelToTwip(25),
                                                      rule: HeightRule.ATLEAST,
                                                    },
                                                    children: bmcBlocksList(
                                                      testItem.assumption
                                                        .blocks,
                                                    ),
                                                  }),
                                                ],
                                              }),
                                            ],
                                          }),

                                          // status cell
                                          new TableCell({
                                            verticalAlign: VerticalAlign.CENTER,
                                            width: {
                                              size: convertPixelToTwip(363),
                                              type: WidthType.DXA,
                                            },
                                            borders: transparentBorder(
                                              COLORS.WHITE,
                                            ),
                                            children: [
                                              new Table({
                                                float: {
                                                  horizontalAnchor:
                                                    TableAnchorType.TEXT,
                                                  verticalAnchor:
                                                    TableAnchorType.TEXT,
                                                  overlap: OverlapType.NEVER,
                                                },
                                                width: {
                                                  size: convertPixelToTwip(363),
                                                  type: WidthType.DXA,
                                                },
                                                layout: TableLayoutType.AUTOFIT,
                                                rows: [
                                                  new TableRow({
                                                    height: {
                                                      value:
                                                        convertPixelToTwip(25),
                                                      rule: HeightRule.ATLEAST,
                                                    },
                                                    children: [
                                                      emptyCell(
                                                        31,
                                                        COLORS.PRIMARY,
                                                      ),
                                                      emptyCell(
                                                        31,
                                                        COLORS.PRIMARY,
                                                      ),
                                                      emptyCell(
                                                        31,
                                                        COLORS.PRIMARY,
                                                      ),
                                                      emptyCell(
                                                        31,
                                                        COLORS.PRIMARY,
                                                      ),
                                                      emptyCell(
                                                        31,
                                                        COLORS.PRIMARY,
                                                      ),
                                                      emptyCell(
                                                        31,
                                                        COLORS.PRIMARY,
                                                      ),
                                                      emptyCell(
                                                        31,
                                                        COLORS.PRIMARY,
                                                      ),
                                                      new TableCell({
                                                        verticalAlign:
                                                          VerticalAlign.CENTER,
                                                        width: {
                                                          size: convertPixelToTwip(
                                                            31,
                                                          ),
                                                          type: WidthType.DXA,
                                                        },
                                                        borders:
                                                          transparentBorder(
                                                            COLORS.WHITE,
                                                          ),
                                                        children: [
                                                          new Paragraph({
                                                            alignment:
                                                              AlignmentType.CENTER,
                                                            children: [
                                                              textRun(
                                                                'Status',
                                                                {
                                                                  bold: true,
                                                                  font: options.bodyFont,
                                                                  color:
                                                                    options.bodyColor ||
                                                                    COLORS.BLACK,
                                                                },
                                                              ),
                                                            ],
                                                          }),
                                                        ],
                                                      }),
                                                      new TableCell({
                                                        verticalAlign:
                                                          VerticalAlign.CENTER,
                                                        width: {
                                                          size: convertPixelToTwip(
                                                            31,
                                                          ),
                                                          type: WidthType.DXA,
                                                        },
                                                        borders:
                                                          transparentBorder(
                                                            COLORS.WHITE,
                                                          ),
                                                        children: [
                                                          new Paragraph({
                                                            alignment:
                                                              AlignmentType.CENTER,
                                                            children: [
                                                              new ImageRun({
                                                                data: await currentStatusIcon(
                                                                  testItem
                                                                    .analyse
                                                                    .status,
                                                                ),
                                                                transformation:
                                                                  {
                                                                    width:
                                                                      convertPixel(
                                                                        30,
                                                                      ),
                                                                    height:
                                                                      convertPixel(
                                                                        30,
                                                                      ),
                                                                  },
                                                              }),
                                                            ],
                                                          }),
                                                        ],
                                                      }),
                                                      new TableCell({
                                                        verticalAlign:
                                                          VerticalAlign.CENTER,
                                                        width: {
                                                          size: convertPixelToTwip(
                                                            31,
                                                          ),
                                                          type: WidthType.DXA,
                                                        },
                                                        borders:
                                                          transparentBorder(
                                                            COLORS.WHITE,
                                                          ),
                                                        children: [
                                                          new Paragraph({
                                                            alignment:
                                                              AlignmentType.CENTER,
                                                            children: [
                                                              textRun(
                                                                testStatus(
                                                                  testItem
                                                                    .analyse
                                                                    .status,
                                                                ),
                                                                {
                                                                  size: 12,
                                                                  bold: true,
                                                                  color:
                                                                    options
                                                                      .testing
                                                                      .statusColor ||
                                                                    COLORS.BLACK,
                                                                },
                                                              ),
                                                            ],
                                                          }),
                                                        ],
                                                      }),
                                                    ],
                                                  }),
                                                ],
                                              }),
                                            ],
                                          }),
                                        ],
                                      }),
                                      new TableRow({
                                        height: {
                                          value: convertPixelToTwip(2),
                                          rule: HeightRule.ATLEAST,
                                        },
                                        children: [
                                          new TableCell({
                                            width: {
                                              size: convertPixelToTwip(363),
                                              type: WidthType.DXA,
                                            },
                                            columnSpan: 2,
                                            borders: {
                                              top: { color: COLORS.PRIMARY },
                                              left: { color: COLORS.PRIMARY },
                                              right: { color: COLORS.PRIMARY },
                                              bottom: {
                                                color: COLORS.BLACK,
                                                space: 2,
                                                style: 'single',
                                                size: 6,
                                              },
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [],
                                          }),
                                        ],
                                      }),
                                      marginTableRow(10, 726, COLORS.WHITE, 2),
                                      // Assumption
                                      new TableRow({
                                        children: [
                                          new TableCell({
                                            columnSpan: 2,
                                            width: {
                                              size: convertPixelToTwip(726),
                                              type: WidthType.DXA,
                                            },
                                            borders: transparentBorder(
                                              COLORS.WHITE,
                                            ),
                                            children: [
                                              new Paragraph({
                                                children: [
                                                  textRun('Assumption', {
                                                    bold: true,
                                                    size: 12,
                                                    font: options.bodyFont,
                                                    color:
                                                      options.bodyColor ||
                                                      COLORS.BLACK,
                                                  }),
                                                ],
                                              }),
                                              new Paragraph({
                                                spacing: {
                                                  before: convertPixelToTwip(5),
                                                },
                                                children: [
                                                  textRun(
                                                    testItem.assumption.text,
                                                    {
                                                      size: 12,
                                                      font: options.bodyFont,
                                                      color:
                                                        options.bodyColor ||
                                                        COLORS.BLACK,
                                                    },
                                                  ),
                                                ],
                                              }),
                                            ],
                                          }),
                                        ],
                                      }),
                                      marginTableRow(20, 726, COLORS.WHITE, 2),
                                      // Test plan
                                      new TableRow({
                                        children: [
                                          new TableCell({
                                            columnSpan: 2,
                                            width: {
                                              size: convertPixelToTwip(726),
                                              type: WidthType.DXA,
                                            },
                                            borders: transparentBorder(
                                              COLORS.WHITE,
                                            ),
                                            children: [
                                              new Paragraph({
                                                children: [
                                                  textRun('Test Plan', {
                                                    bold: true,
                                                    size: 12,
                                                    font: options.bodyFont,
                                                    color:
                                                      options.bodyColor ||
                                                      COLORS.BLACK,
                                                  }),
                                                ],
                                              }),
                                              new Paragraph({
                                                spacing: {
                                                  before: convertPixelToTwip(5),
                                                },
                                                children: [
                                                  textRun(testItem.data.plan, {
                                                    size: 12,
                                                    font: options.bodyFont,
                                                    color:
                                                      options.bodyColor ||
                                                      COLORS.BLACK,
                                                  }),
                                                ],
                                              }),
                                            ],
                                          }),
                                        ],
                                      }),
                                      marginTableRow(20, 726, COLORS.WHITE, 2),
                                      // Test Result
                                      new TableRow({
                                        children: [
                                          new TableCell({
                                            columnSpan: 2,
                                            width: {
                                              size: convertPixelToTwip(726),
                                              type: WidthType.DXA,
                                            },
                                            borders: transparentBorder(
                                              COLORS.WHITE,
                                            ),
                                            children: [
                                              new Paragraph({
                                                children: [
                                                  textRun('Test Result', {
                                                    size: 12,
                                                    bold: true,
                                                    font: options.bodyFont,
                                                    color:
                                                      options.bodyColor ||
                                                      COLORS.BLACK,
                                                  }),
                                                ],
                                              }),
                                              new Paragraph({
                                                spacing: {
                                                  before: convertPixelToTwip(5),
                                                },
                                                children: [
                                                  textRun(
                                                    testItem.analyse
                                                      .resultsAndLearning,
                                                    {
                                                      size: 12,
                                                      font: options.bodyFont,
                                                      color:
                                                        options.bodyColor ||
                                                        COLORS.BLACK,
                                                    },
                                                  ),
                                                ],
                                              }),
                                            ],
                                          }),
                                        ],
                                      }),
                                    ],
                                  }),
                              ),
                            ),
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),

              emptyCell(30, COLORS.PRIMARY),
            ],
          }),
        ],
      }),
    ],
  };
};
