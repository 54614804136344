import React from 'react';
import MessageItemComponent from './message-item.component';

export default function MessageItemContainer({
  uniqKey,
  title = null,
  unreadMessageCount = 0,
  unreadMentionCount = 0,
  onClick,
}) {
  return (
    <div className="message-item-container" onClick={() => onClick(uniqKey)}>
      <MessageItemComponent
        title={title}
        unreadMessageCount={unreadMessageCount}
        unreadMentionCount={unreadMentionCount}
      />
    </div>
  );
}
