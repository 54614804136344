import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';
import { getOptionsChart } from '../../../utils/helpers/getOptionsChart';
import {
  CURRENT_TEMPLATE_NAME,
  COLORS,
  COLOURFUL_TEMPLATE_NAME,
  BLOG_FRIENDLY_TEMPLATE_NAME,
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  TECH_MODERN_TEMPLATE_NAME,
  BOLD_TEMPLATE_NAME,
} from '../../../utils/const';

const { secondGrey, black } = COLORS;

const Chart = ({
  locales,
  locales: { finance },
  totalCashIn,
  totalCashOut,
  firstChartColor,
  secondChartColor,
  borderCardColor,
  titleColor,
  textColor,
  templateName = CURRENT_TEMPLATE_NAME,
}) => {
  const chartRef = useRef(null);

  const chartStyles = {
    [CURRENT_TEMPLATE_NAME]: {
      fontFamily: 'Nunito',
      borderColor: secondGrey,
      colors: [firstChartColor, secondChartColor],
      themeStyle: 'light',
    },
    [COLOURFUL_TEMPLATE_NAME]: {
      fontFamily: 'Open Sans',
      borderColor: secondGrey,
      colors: [firstChartColor, secondChartColor],
      themeStyle: 'light',
    },
    [BLOG_FRIENDLY_TEMPLATE_NAME]: {
      fontFamily: 'Nunito',
      borderColor: secondChartColor,
      colors: [firstChartColor, black],
      themeStyle: 'light',
    },
    [ARCHITECTURAL_MINIMAL_TEMPLATE_NAME]: {
      fontFamily: 'Lato',
      borderColor: secondChartColor,
      colors: [firstChartColor, secondChartColor],
      themeStyle: 'light',
    },
    [TECH_MODERN_TEMPLATE_NAME]: {
      fontFamily: 'Nunito',
      borderColor: secondChartColor,
      colors: [firstChartColor, secondChartColor],
      themeStyle: 'light',
    },
    [BOLD_TEMPLATE_NAME]: {
      fontFamily: 'Martel Sans',
      borderColor: secondChartColor,
      colors: [firstChartColor, secondChartColor],
      textChartColor: textColor,
      themeStyle: 'dark',
    },
  };

  useEffect(() => {
    const apexChart = new ApexCharts(
      chartRef.current,
      getOptionsChart(
        locales,
        totalCashIn,
        totalCashOut,
        chartStyles,
        templateName,
      ),
    );
    apexChart.render().then(() => {});
    return () => {
      apexChart.destroy();
    };
  }, [totalCashOut, totalCashIn]);

  return (
    <div className="chart custom-font-body">
      <div
        className="main-title custom-font-title"
        style={{ color: titleColor }}
      >
        {finance.chart.title}
      </div>
      <div className="chart-inner">
        <div
          className="chart-wrapper custom-font-body"
          style={{
            borderColor: borderCardColor,
          }}
          ref={chartRef}
        />
      </div>
    </div>
  );
};

export default Chart;
