import React from 'react';
import { useSelector } from 'react-redux';

import Header from '../../components/Header';
import Title from '../../components/Title';
import Text from '../../components/Text';
import Line from '../../components/Line';
import Card from '../keyResources/Card';
import Actions from '../../components/Actions';
import MobileMenuHeader from '../../components/MobileMenuHeader';

export default function Summary(props) {
  const {
    nextClickHandler,
    menuItems,
    selectComponent,
    componentIndex,
    rules,
    backPath,
    locales,
    locales: {
      common,
      tipCustomerSegment,
      keyPartners: {
        name,
        summary: { header, title, text },
        intro: { card },
      },
    },
  } = props;
  const { collection } = useSelector((state) => state.keyPartner);

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon="icon-key-partners"
        locales={locales}
        componentIndex={componentIndex}
        className="bmc-page-header--orange"
        tipLocales={tipCustomerSegment}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="KeyThingCanvas" title={name} color="orange" />
        }
      />

      <Text align="center">{[text[0]]}</Text>

      <Line />

      <Title text={title[0]} className="margin-top-60" />

      <Card
        locales={locales}
        card={{ ...card, title: null, list: collection }}
        className="margin-top-60 bmc-page-keycard--section"
      />

      <Actions nextClickHandler={nextClickHandler} buttonName={common.done} />
    </div>
  );
}
