import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmationModal from '../../../components/ConfirmationModal';
import Textarea from '../../../components/Textarea';
import Dropzone from '../../../components/Dropzone';

const TeamCard = ({
  index,
  teammate,
  locales,
  locales: { portfolio },
  disabled = false,
}) => {
  const { image, imageCoords, name, description, position, id } = teammate;
  const {
    portfolioTeammate: {
      updatePortfolioTeammate,
      updatePortfolioTeammateImage,
      updatePortfolioTeammateImageCoords,
      removePortfolioTeammate,
    },
  } = useDispatch();

  const { uploadingImage } = useSelector((state) => state.portfolioTeammate);

  const onChange = (e) => {
    updatePortfolioTeammate({
      id,
      name: e.target.name,
      value: e.target.value,
    });
  };

  const onDropImage = ([file], openCropperHandler) => {
    updatePortfolioTeammateImage({
      id,
      name: 'image',
      blob: file,
      success: () => {
        openCropperHandler();
      },
    });
  };

  const onImageCropperSave = (croppedAreaPixels, innerCropData, zoom) => {
    updatePortfolioTeammateImageCoords({
      id,
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: imageCoords,
    });
  };

  return (
    <div className="team-card">
      <div className="header">
        <div className="name">
          {portfolio.team.teamMember} {index + 1}
        </div>
        {!disabled && (
          <ConfirmationModal
            body="Delete this content. Are you sure?"
            confirmText="Delete"
            onConfirm={() => removePortfolioTeammate({ id })}
          >
            <div className="icon-section">
              <i className="icon-Delete" />
            </div>
          </ConfirmationModal>
        )}
      </div>

      <Dropzone
        className="image-upload"
        onDrop={onDropImage}
        onCropperSave={onImageCropperSave}
        locales={locales}
        image={image}
        coords={imageCoords}
        uploading={uploadingImage === teammate.id}
        aspectRatio={1}
        disabled={disabled}
        cropper
      />

      <div className="form-field">
        <label htmlFor="position" className="label">
          {portfolio.team.position}
        </label>
        <Textarea
          id="position"
          type="text"
          name="position"
          rows={1}
          value={position}
          placeholder={portfolio.page.informationPlaceholder}
          onChange={onChange}
          className="bmc-form-textarea input"
          delay={700}
          disabled={disabled}
        />
      </div>
      <div className="form-field">
        <label htmlFor="fullName" className="label">
          {portfolio.team.fullName}
        </label>
        <Textarea
          id="fullName"
          value={name}
          onChange={onChange}
          placeholder={portfolio.page.informationPlaceholder}
          name="name"
          rows={1}
          type="text"
          className="bmc-form-textarea input"
          delay={700}
          disabled={disabled}
        />
      </div>
      <div className="form-field">
        <label htmlFor="description" className="label">
          {portfolio.team.description}
        </label>
        <Textarea
          id="description"
          className="bmc-form-textarea textarea"
          name="description"
          placeholder={portfolio.page.informationPlaceholder}
          value={description}
          onChange={onChange}
          rows={5}
          delay={700}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default TeamCard;
