import React, { useRef } from 'react';
import { digitToString } from '../../main/utils/helpers';

const Landing3 = ({ test, locales: { landing3: pageLocales } }) => {
  const learnMoreSection = useRef(null);
  const handleScroll = () => {
    window.scrollTo({
      top: learnMoreSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <div className="desktop-landing3">
      <div className="desktop-landing3--main-block">
        <div className="desktop-landing3--bg" />
        <h1 className="desktop-landing3--logo">{test.data.company_name}</h1>
        <div className="desktop-landing3--info-block">
          <div className="desktop-landing3--info">
            <h2 className="desktop-landing3--title">{test.data.headline}</h2>
            <p className="desktop-landing3--description">{test.data.explain}</p>
            <button
              className="desktop-landing3--learn-more-btn"
              onClick={handleScroll}
            >
              {pageLocales.button}
            </button>
          </div>
          <div className="desktop-landing3--ipad">
            {test.image.medium.url ? (
              <img src={test.image.medium.url} alt="" />
            ) : (
              <div className="placeholder" />
            )}
          </div>
        </div>
      </div>
      <div className="desktop-landing3--learn-more" ref={learnMoreSection}>
        <h3 className="desktop-landing3--learn-more-title">
          {pageLocales.learnMoreTitle}
        </h3>
        <ul className="desktop-landing3--learn-more-list">
          {pageLocales.list.map((item, index) => (
            <li key={index}>
              <span className={`desktop-landing3--polygon${index + 1}`}>
                {index + 1}
              </span>
              <p>{test.data[`thing${digitToString[index + 1]}`]}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Landing3;
