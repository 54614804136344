import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import update from 'immutability-helper';
import _get from 'lodash/get';

import Header from '../../../../components/Header';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import List from '../../../../components/List';
import Line from '../../../../components/Line';
import Actions from '../../../../components/Actions';
import SpecialList from '../../Components/SpecialList';
import Textarea from '../../../../components/Textarea';
import Tabs from '../../../../components/Tabs';
import TabsListItem from '../../../../components/TabsListItem';
import TabsList from '../../../../components/TabsList';
import CheckBox from '../../Components/CheckBox';
import TabsCut from '../../../../components/TabsCut';
import SvgIcon from '../../../../components/SvgIcon';
import MobileMenuHeader from '../../../../components/MobileMenuHeader';

const blocksObject = {
  CS: 'Customer Segment',
  VP: 'Value Proposition',
  CH: 'Channel',
  GKG: 'Get Keep Grow',
  KA: 'Key Activity',
  KP: 'Key Partner',
  KR: 'Key Resources',
  CO: 'Cost',
  RE: 'Revenue',
  SU: 'Sustainability',
  CR: 'Customer Relationships',
};

const icons = ['question', 'canvas'];
const iconsComponents = icons.map((e) => <SvgIcon name={e} />);

// todo: rewrite it
const CriticalAssumption = ({
  nextClickHandler,
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
  locales,
  locales: {
    common,
    testing: {
      caseStudies,
      name,
      assumption: { header, title, text, list, specialList, tabs },
    },
  },
}) => {
  const {
    testing: { test },
  } = useSelector((state) => state);

  const {
    testing: { updateTest },
  } = useDispatch();

  const blocks = _get(test, 'assumption.blocks', []);

  const onChange = (e) => {
    const updatedTest = {
      assumption: {
        ...test.assumption,
        text: e.target.value,
      },
    };
    updateTest({ id: test.id, test: updatedTest });
  };

  const onCheck = (e) => {
    // eslint-disable-next-line
    const { name, checked } = e.target;

    const operation = checked
      ? { $push: [name] }
      : {
          $splice: [[blocks.findIndex((i) => i === name), 1]],
        };
    const updatedTest = {
      assumption: {
        ...test.assumption,
        blocks: update(blocks, operation),
      },
    };

    updateTest({ id: test.id, test: updatedTest });
  };
  const [opened, setOpened] = useState(false);

  return (
    <div className="bmc-page testing">
      <Header
        className="bmc-page-header--blue"
        title={header}
        subtitle={name}
        icon="icon-VP"
        locales={locales}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        componentIndex={componentIndex}
        mobileMenuHeader={
          <MobileMenuHeader icon="testingActive" title={name} color="blue" />
        }
        rules={rules}
        backPath={backPath}
      />
      <Text>{[text[0], text[1], text[2], text[3]]}</Text>

      <Title text={title[0]} align="left" />

      <Text>{[text[4], text[5], text[6]]}</Text>

      <SpecialList title="12 Common Startup Assumptions" items={specialList} />

      <Title text={title[1]} align="left" />

      <List items={list} icons={icons} />

      <Line />

      <h2 className="bmc-page-title">
        <span className="bmc-page-title--blue">{title[2]}</span> {title[3]}
      </h2>

      <Text align="center">{[text[9]]}</Text>

      <Tabs
        tabs={[common.yourStrategy, common.caseStudy]}
        withBlur
        className="margin-top-60 bmc-page-tabs--blue"
      >
        {[
          <div key="strategy">
            <TabsList formStyle>
              <TabsListItem
                subtitle={tabs.whatYourAssumptionSub}
                title={tabs.whatYourAssumption}
                icon={iconsComponents[0]}
                text={
                  // eslint-disable-next-line
                  <>
                    <Textarea
                      value={_get(test, 'assumption.text', '')}
                      delay={300}
                      onChange={onChange}
                      rows={4}
                    />
                  </>
                }
              />
            </TabsList>
            <TabsList formStyle>
              <TabsListItem
                subtitle={tabs.whatBmcBlocksDoesThisApplySub}
                title={tabs.whatBmcBlocksDoesThisApply}
                icon={iconsComponents[1]}
                text={
                  // eslint-disable-next-line
                  <div className="checkbox-wrapper">
                    <CheckBox
                      name="CS"
                      isChecked={blocks.includes('CS')}
                      onCheck={onCheck}
                      title="Customer Segments"
                    />

                    <CheckBox
                      name="VP"
                      isChecked={blocks.includes('VP')}
                      onCheck={onCheck}
                      title="Value Proposition"
                    />

                    <CheckBox
                      name="CH"
                      isChecked={blocks.includes('CH')}
                      onCheck={onCheck}
                      title="Channels"
                    />

                    <CheckBox
                      name="CR"
                      isChecked={blocks.includes('CR')}
                      onCheck={onCheck}
                      title="Customer Relationships"
                    />

                    <CheckBox
                      name="KA"
                      isChecked={blocks.includes('KA')}
                      onCheck={onCheck}
                      title="Key Activities"
                    />

                    <CheckBox
                      name="KR"
                      isChecked={blocks.includes('KR')}
                      onCheck={onCheck}
                      title="Key Resources"
                    />

                    <CheckBox
                      name="KP"
                      isChecked={blocks.includes('KP')}
                      onCheck={onCheck}
                      title="Key Partners"
                    />

                    <CheckBox
                      name="CO"
                      isChecked={blocks.includes('CO')}
                      onCheck={onCheck}
                      title="Costs"
                    />

                    <CheckBox
                      name="RE"
                      isChecked={blocks.includes('RE')}
                      onCheck={onCheck}
                      title="Revenue"
                    />

                    <CheckBox
                      name="SU"
                      isChecked={blocks.includes('SU')}
                      onCheck={onCheck}
                      title="Sustainability"
                    />
                  </div>
                }
              />
            </TabsList>
          </div>,
          <Tabs
            key="tabs"
            tabs={caseStudies.map((e) => e.name)}
            className="bmc-page-tabs--black bmc-page-tabs--inner"
          >
            {caseStudies.map((item, index) => (
              <div key={index}>
                <TabsCut
                  setOpened={setOpened}
                  opened={opened}
                  caseStudy={item}
                />

                <TabsListItem
                  title={tabs.whatYourAssumption}
                  text={item.assumption.text}
                  icon={iconsComponents[0]}
                />
                <TabsListItem
                  title={tabs.whatBmcBlocksDoesThisApply}
                  icon={iconsComponents[1]}
                >
                  <div className="bmc-page-tabs-content-list-item-text">
                    <div className="checkbox-wrapper">
                      {item.assumption.blocks.map((block, i) => (
                        <CheckBox
                          isChecked
                          isPreview
                          key={i}
                          title={blocksObject[block]}
                        />
                      ))}
                    </div>
                  </div>
                </TabsListItem>
              </div>
            ))}
          </Tabs>,
        ]}
      </Tabs>

      <Actions nextClickHandler={nextClickHandler} buttonName={common.next} />
    </div>
  );
};

export default CriticalAssumption;
