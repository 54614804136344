import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch, connect } from 'react-redux';

import Introduction from './Introduction';
import ProductAndFeatures from './ProductAndFeatures';
import Competitors from './Competitors';
import UniqueValueProposition from './UniqueValueProposition';
import Summary from './Summary';
import Section from '../../components/Section';
import * as AppRoute from '../../utils/appRoutes';
import { prepareComponents } from '../../utils/helpers';
import { select } from '../../rematcher/store';

const components = [
  Introduction,
  ProductAndFeatures,
  Competitors,
  UniqueValueProposition,
  Summary,
];

function ValueProposition({
  locales,
  locales: {
    valueProposition: { menu, sectionName },
  },
  progress,
}) {
  const {
    valueProposition: { fetchValuePropositions },
    uniqueSellingPoint: { fetchUniqueSellingPoints },
    competitor: { fetchCompetitors },
    bmc: { fetchCurrentBmc, updateBmc },
  } = useDispatch();

  const {
    bmc: { current: currentBmc },
  } = useSelector((state) => state);

  const { bmcId } = useParams();
  const preparedComponents = prepareComponents(
    components,
    bmcId,
    menu,
    AppRoute.valueProposition,
  );

  function getData() {
    fetchCurrentBmc(bmcId);
    fetchValuePropositions(bmcId);
    fetchUniqueSellingPoints(bmcId);
    fetchCompetitors(bmcId);
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (currentBmc.id)
      updateBmc({
        id: currentBmc.id,
        name: 'value_propositions_progress',
        value: parseInt(progress),
      });
  }, [progress]);

  return (
    <Section
      components={preparedComponents}
      sectionName={sectionName}
      menuItems={menu}
      progress={progress}
      locales={locales}
      finalDestination={AppRoute.bmc(bmcId)}
      onComponentChange={getData}
    />
  );
}

const mapState = select((models) => ({
  progress: models.progress.valuePropositionProgress,
}));

export default connect(mapState, null)(ValueProposition);
