import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import { Document, Packer } from 'docx';
import { useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import pages from './Pages';
import { getPageBgColor } from './Constants';

const Docx = ({ locales, portfolioWeb, sustainability }) => {
  const stateData = useSelector((state) => state);
  const [isDisabled, setIsDisabled] = useState(false);

  const generateDocx = async () => {
    setIsDisabled(true);

    document.querySelector('div[id="docx-forecast-tab"]').click();
    let base64BMCImage;
    html2canvas(document.getElementById('docx-bmc-wrapper'), {
      allowTaint: true,
      logging: true,
    }).then(async (canvas) => {
      base64BMCImage = canvas.toDataURL('image/png');

      document.querySelector('div[id="docx-graph-tab"]').click();

      await new Promise((resolve) => setTimeout(resolve, 1200));

      let base64GRAPHImage;
      html2canvas(document.getElementById('docx-graph-wrapper'), {
        allowTaint: true,
        logging: true,
      })
        .then(async (canvasElement) => {
          base64GRAPHImage = canvasElement.toDataURL('image/png');

          base64GRAPHImage = Uint8Array.from(
            atob(base64GRAPHImage.split(',')[1]),
            (c) => c.charCodeAt(0),
          );

          const doc = new Document({
            styles: {
              default: {
                heading1: {
                  run: {
                    size: 36,
                    bold: true,
                    italics: true,
                    color: 'FF0000',
                  },
                  paragraph: {
                    spacing: {
                      after: 120,
                    },
                  },
                },
              },
              paragraphStyles: [
                {
                  id: 'testStyle',
                  name: 'test style',
                  run: {
                    size: 27,
                    color: 'FFFFFF',
                    italics: true,
                  },
                },
                {
                  id: 'box',
                  name: 'test style',
                  run: {
                    size: 27,
                    color: 'FFFFFF',
                    italics: true,
                  },
                },
              ],
            },
            background: getPageBgColor(stateData),
            sections: await pages(
              locales,
              portfolioWeb,
              stateData,
              sustainability,
              base64BMCImage,
              base64GRAPHImage,
            ),
          });
          Packer.toBlob(doc).then((blob) => {
            // saveAs(blob, 'example.docx');
            saveAs(blob, `${stateData.bmc.current.name}.docx`);
          });
        })
        .finally(() => {
          setIsDisabled(false);
        });
    });
  };

  return (
    <div className="pdf">
      <div className="title">{locales.export.docx.description}</div>
      <div className="bottom">
        <button
          className="bmc-button bmc-button--primary"
          onClick={generateDocx}
          disabled={isDisabled}
        >
          {isDisabled ? locales.export.downloading : locales.export.download}
        </button>
      </div>
    </div>
  );
};

export default Docx;
