import PropTypes from 'prop-types';

const cellPropTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
};
export default cellPropTypes;
