import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../components/Header';
import Tabs from '../../components/Tabs';
import Textarea from '../../components/Textarea';
import TabsCut from '../../components/TabsCut';
import Actions from '../../components/Actions';
import Button from '../../components/Button';
import List from '../../components/List';
import Line from '../../components/Line';
import Text from '../../components/Text';
import Title from '../../components/Title';
import { COMPETITOR_MAX_COUNT } from '../../utils/const';
import RemoveButton from '../../components/RemoveButton';
import TabsList from '../../components/TabsList';
import TabsListItem from '../../components/TabsListItem';
import ConfirmationModal from '../../components/ConfirmationModal';
import SvgIcon from '../../components/SvgIcon';
import MobileMenuHeader from '../../components/MobileMenuHeader';

const fields = ['name', 'doWell', 'doBadly'];
const icons = ['userSpeaking', 'thumbsUp', 'thumbsDown'];
const iconsComponents = icons.map((e) => <SvgIcon name={e} />);

const Competitors = ({
  nextClickHandler,
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
  locales,
  locales: {
    common,
    tipCustomerSegment,
    caseStudies,
    valueProposition: {
      name,
      competitors: { header, title, text, list, tabs },
    },
  },
}) => {
  const [opened, setOpened] = useState(false);
  const {
    competitor: { addCompetitor, updateCompetitor, removeCompetitor },
  } = useDispatch();
  const {
    competitor: { collection },
  } = useSelector((state) => state);

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon={<SvgIcon name="valueProposition" />}
        locales={locales}
        componentIndex={componentIndex}
        tipLocales={tipCustomerSegment}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="VPCanvas" title={name} color="green" />
        }
      />

      <div className="bmc-page-text">
        <p className="bmc-page-text--bold">{text[0]}</p>
        <p>{text[1]}</p>
        <p>{text[2]}</p>
      </div>

      <Title text={title[0]} align="left" />

      <List items={list} className="margin-top-40" icons={icons} />

      <Line />

      <h2 className="bmc-page-title">
        <span className="bmc-page-title--green">{title[1]}</span> {title[2]}
      </h2>

      <Text align="center">{[text[3]]}</Text>

      <Tabs
        tabs={[common.yourStrategy, common.caseStudy]}
        withBlur
        className="margin-top-60"
      >
        {[
          <div key="strategy">
            <TabsList horizontal>
              {fields.map((field, i) => (
                <TabsListItem
                  key={i}
                  title={tabs[field]}
                  icon={iconsComponents[i]}
                />
              ))}
            </TabsList>

            {collection.map((item, index) => (
              <TabsList key={item.id} horizontal minimalMargin>
                <TabsListItem
                  icon={iconsComponents[0]}
                  title={tabs[fields[0]]}
                  text={
                    // eslint-disable-next-line
                    <Textarea
                      rows={1}
                      delay={700}
                      value={item.name}
                      onChange={(event) =>
                        updateCompetitor({
                          index,
                          name: 'name',
                          value: event.target.value,
                          id: item.id,
                        })
                      }
                      placeholder={tabs.namePlaceholder}
                    />
                  }
                />

                <TabsListItem
                  icon={iconsComponents[1]}
                  title={tabs[fields[1]]}
                  text={
                    // eslint-disable-next-line
                    <Textarea
                      rows={4}
                      delay={700}
                      value={item.doWell}
                      onChange={(event) =>
                        updateCompetitor({
                          index,
                          name: 'doWell',
                          value: event.target.value,
                          id: item.id,
                        })
                      }
                      placeholder={tabs.doWellPlaceholder}
                    />
                  }
                />

                <TabsListItem
                  icon={iconsComponents[2]}
                  title={tabs[fields[2]]}
                  text={
                    // eslint-disable-next-line
                    <Textarea
                      rows={4}
                      delay={700}
                      value={item.doBadly}
                      onChange={(event) =>
                        updateCompetitor({
                          index,
                          name: 'doBadly',
                          value: event.target.value,
                          id: item.id,
                        })
                      }
                      placeholder={tabs.doBadlyPlaceholder}
                    />
                  }
                />

                {collection.length > 1 && (
                  <ConfirmationModal
                    body="Delete this content. Are you sure?"
                    confirmText="Delete"
                    isBlockScroll
                    onConfirm={() => removeCompetitor({ index, id: item.id })}
                  >
                    <RemoveButton
                      className="bmc-page-tabs-content-list-remove"
                      active={collection.length > 1}
                    />
                  </ConfirmationModal>
                )}
              </TabsList>
            ))}

            {collection.length < COMPETITOR_MAX_COUNT && (
              <div className="bmc-page-tabs-content-actions">
                <Button
                  onClick={addCompetitor}
                  wide
                  icon="icon-Add"
                  title={tabs.createNewCompetitor}
                  className="bmc-button--green"
                />
              </div>
            )}
          </div>,

          <Tabs key="tabs" tabs={caseStudies.map((e) => e.name)} black inner>
            {caseStudies.map((item, index) => (
              <div key={index}>
                <TabsCut
                  opened={opened}
                  setOpened={setOpened}
                  caseStudy={item}
                />

                {item.competitors.map((competitor, i) => (
                  <TabsList key={i} formStyle horizontal>
                    {fields.map((field, ii) => (
                      <TabsListItem
                        key={ii}
                        title={tabs[field]}
                        text={competitor[field]}
                        icon={iconsComponents[ii]}
                      />
                    ))}
                  </TabsList>
                ))}
              </div>
            ))}
          </Tabs>,
        ]}
      </Tabs>

      <Actions nextClickHandler={nextClickHandler} buttonName={common.next} />
    </div>
  );
};

export default Competitors;
