import * as Route from '../support/serverRoutes';
import { transformResponseData } from '../../main/utils/helpers';
import makeRequest from '../../main/utils/makeRequest';

export const defaultSectionAvailabilitySettings = {
  ideation: true,
  bmc: true,
  testing: true,
  portfolio: true,
};

const defaultClassroom = {
  sectionAvailabilitySettings: defaultSectionAvailabilitySettings,
};

const classroom = {
  state: {
    current: defaultClassroom,
    uploading: false,
    currentStep: 0,
  },
  reducers: {
    updateClassroomReducer(state, payload) {
      return { ...state, current: { ...state.current, ...payload } };
    },
    updateAttributeReducer(state, payload) {
      return { ...state, ...payload };
    },
    setCurrentStep(state, { currentStep }) {
      return { ...state, currentStep };
    },
    nextStep(state) {
      return { ...state, currentStep: state.currentStep + 1 };
    },
  },
  effects: (dispatch) => ({
    fetchClassroom(classroomId) {
      dispatch.classroom.updateAttributeReducer({ uploading: true });

      makeRequest({
        dispatch,
        url: Route.classroom(classroomId),
        success: (response) => {
          dispatch.classroom.updateClassroomReducer(
            transformResponseData(response.data.data),
          );
        },
        finish: () => {
          dispatch.classroom.updateAttributeReducer({
            uploading: false,
          });
        },
      });
    },
    addClassroom({ name, establishmentId, lecturerIds, after = [] }) {
      dispatch.classroom.updateAttributeReducer({ uploading: true });

      makeRequest({
        dispatch,
        url: Route.classroom(),
        method: 'post',
        data: {
          classroom: { name },
          lecturer_ids: lecturerIds,
          establishmentId,
        },
        success: (response) => {
          dispatch.classroom.updateClassroomReducer(
            transformResponseData(response.data.data),
          );
        },
        finish: () => {
          dispatch.classroom.updateAttributeReducer({
            uploading: false,
          });
          dispatch.classroom.nextStep();
          after.forEach((callback) => callback());
        },
      });
    },
    updateClassroomSectionAvailabilitySettings(
      { id, data },
      {
        classroom: {
          current: { sectionAvailabilitySettings },
        },
      },
    ) {
      const newData = { ...sectionAvailabilitySettings, ...data };
      dispatch.classroom.updateClassroomReducer({
        sectionAvailabilitySettings: newData,
      });

      makeRequest({
        dispatch,
        url: Route.classroom(id),
        method: 'put',
        data: { classroom: { sectionAvailabilitySettings: newData } },
      });
    },
    updateClassroomName({ id, name }) {
      makeRequest({
        dispatch,
        url: Route.classroom(id),
        method: 'put',
        data: { classroom: { name } },
        success: (response) => {
          dispatch.classroom.updateClassroomReducer(
            transformResponseData(response.data.data),
          );
          dispatch.classrooms.updateDataItem({
            id: response.data.data.attributes.id,
            newDataItem: { name: response.data.data.attributes.name },
          });
        },
      });
    },
    setCurrentStepIndex(currentStepIndex) {
      dispatch.classroom.setCurrentStep({ currentStep: currentStepIndex });
    },
  }),
};

export default classroom;
