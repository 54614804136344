import * as Route from '../utils/serverRoutes';
import makeRequest from '../utils/makeRequest';
import { transformResponseData } from '../utils/helpers';

const portfolioStyle = {
  state: {
    id: null,
    templateSettings: {},
    activeTemplateName: null,
  },
  reducers: {
    fetchPortfolioStyleReducer(state, payload) {
      return {
        ...state,
        id: payload.id,
        templateSettings: payload.templateSettings,
        activeTemplateName: payload.activeTemplateName,
      };
    },
  },
  effects: (dispatch) => ({
    fetchPortfolioStyle(bmcId) {
      makeRequest({
        dispatch,
        url: Route.portfolioStyle(bmcId),
        method: 'get',
        success: (response) => {
          dispatch.portfolioStyle.fetchPortfolioStyleReducer(
            transformResponseData(response.data.data),
          );
        },
      });
    },
    async updatePortfolioTemplate(params, state) {
      const data = {
        portfolio_template_id: params.activeTemplateId,
      };

      makeRequest({
        dispatch,
        url: Route.portfolioStyle(state.bmc.current.id),
        method: 'put',
        data: { portfolio_style: data },
        success: (response) => {
          dispatch.portfolioStyle.fetchPortfolioStyleReducer(
            transformResponseData(response.data.data),
          );
        },
      });
    },

    async updatePortfolioStyleSettings(templateSettings, state, bmcId = null) {
      const data = {
        template_settings: templateSettings,
      };

      makeRequest({
        dispatch,
        url: Route.portfolioStyle(bmcId || state.bmc.current.id),
        method: 'put',
        data: { portfolio_style: data },
        success: (response) => {
          dispatch.portfolioStyle.fetchPortfolioStyleReducer(
            transformResponseData(response.data.data),
          );
        },
      });
    },
  }),
};

export default portfolioStyle;
