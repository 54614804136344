import React from 'react';
import Lazyload from 'react-lazyload';
import { useSelector } from 'react-redux';
import { find } from 'lodash';

import CustomerSegmentArch from './CustomerSegmentArch';
import ValuePropositionArch from './ValuePropositionArch';
import ChannelsArch from './ChannelsArch';
import ContactUsArch from './ContactUsArch';
import { StyledWrapper } from '../../../utils/helpers';
import {
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  BUTTON_LIGHT_MODAL_STYLE_ARCH,
  BUTTON_MODAL_STYLE_ARCH,
  COLOR_PALETTE_ARRAY_ARCH,
  COLORS,
  THIRD_COLOR_PALETTE_NUMBER,
  TITLE_MODAL_STYLE_ARCH,
} from '../../../utils/const';
import { getColorTemplate } from '../../../utils/helpers/getColorTemplate';
import Introduction from '../mainTemplateComponents/Introduction';
import Summary from '../mainTemplateComponents/Summary';
import Team from '../mainTemplateComponents/Team';
import BMC from '../mainTemplateComponents/BMC';
import { getColorDependsOnBackground } from '../../../utils/helpers/getColorDependsOnBackground';
import Testing from '../mainTemplateComponents/Testing';
import { getColorDependsOnBackgroundAndPalette } from '../../../utils/helpers/portfolioArchTemplate/getColorDependsOnBackgroundAndPalette';
import Viability from '../mainTemplateComponents/Viability';
import Feasibility from '../mainTemplateComponents/Feasibility';
import Desirability from '../mainTemplateComponents/Desirability';
import CompetitorAnalysis from '../mainTemplateComponents/CompetitorAnalysis';
import CustomerRelationships from '../mainTemplateComponents/CustomerRelationships';
import KeyActivities from '../mainTemplateComponents/KeyActivities';
import Finance from '../mainTemplateComponents/Finance';
import Sustainability from '../mainTemplateComponents/Sustainability';

const { white, black, darkGrey } = COLORS;

const ArchitecturalMinimalTemplate = ({
  locales,
  portfolio,
  bmc,
  testing,
  disabled = false,
}) => {
  const {
    portfolioStyle: { templateSettings, activeTemplateName },
    portfolioTemplate: { collection },
  } = useSelector((state) => state);

  const currentSettings = templateSettings
    ? templateSettings[activeTemplateName]
    : {};
  const activeTemplate = find(collection, ['sysName', activeTemplateName]);
  const colorTemplate = getColorTemplate(
    templateSettings,
    activeTemplateName,
    activeTemplate,
  );

  if (!colorTemplate) {
    return null;
  }

  const [firstColor, secondColor, thirdColor, fourthColor] = colorTemplate;

  const colorPaletteNumber = COLOR_PALETTE_ARRAY_ARCH[firstColor];
  const isThirdColorPalette = colorPaletteNumber === THIRD_COLOR_PALETTE_NUMBER;
  const isFourthColorPaletteNumber = !colorPaletteNumber;

  const textColorDependsOnBackgroundAndPaletteDFV =
    getColorDependsOnBackgroundAndPalette(colorPaletteNumber, thirdColor, true);

  const textColorDependsOnSecondColor =
    isFourthColorPaletteNumber && getColorDependsOnBackground(secondColor);

  const textColorDependsOnBackgroundAndPalette =
    getColorDependsOnBackgroundAndPalette(colorPaletteNumber, firstColor);

  return (
    <div className="bmc-canvas margin-top-30">
      <div
        className="bmc-page fullwidth architectural"
        style={{ padding: '0' }}
      >
        <StyledWrapper
          customtitlefont={currentSettings?.customTitleFont}
          customtitlecolor={currentSettings?.customTitleColor}
          custombodyfont={currentSettings?.customBodyFont}
          custombodycolor={currentSettings?.customBodyColor}
        >
          <Introduction
            locales={locales}
            data={portfolio.introduction}
            backgroundSection={firstColor}
            backgroundImage={fourthColor}
            backgroundModal={`${secondColor}80`}
            colorPaletteNumber={colorPaletteNumber}
            templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
            disabled={disabled}
          />
          <Summary
            locales={locales}
            data={portfolio.projectSummary}
            backgroundSection={secondColor}
            isFourthColorPaletteNumber={isFourthColorPaletteNumber}
            templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
            disabled={disabled}
          />
          <Lazyload>
            <Team
              locales={locales}
              data={portfolio.team}
              backgroundSection={firstColor}
              backgroundImage={fourthColor}
              backgroundModal={`${secondColor}80`}
              colorPaletteNumber={colorPaletteNumber}
              templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
              disabled={disabled}
            />
          </Lazyload>
          {bmc && (
            <Lazyload>
              <BMC
                locales={locales}
                data={portfolio.bmc}
                className="bm-architectural-canvas section"
                sectionClassName="bmc-canvas-section--architectural"
                textStyle="text-card-arch"
                textWithImage="bmc-page-compcard-item-text-template bmc-page-compcard-item-text-template--with-image"
                backgroundBMC={`${secondColor}33`}
                titleStyle={TITLE_MODAL_STYLE_ARCH}
                buttonStyle={BUTTON_MODAL_STYLE_ARCH}
                backgroundSection={`${fourthColor}80`}
                backgroundIcon={secondColor}
                backgroundMainSection={secondColor}
                backgroundItems={white}
                iconFill={textColorDependsOnSecondColor || black}
                backgroundRow={`${fourthColor}80`}
                styleColor={black}
                backgroundImage={fourthColor}
                sectionHeaderTitleColor={textColorDependsOnSecondColor}
                descriptionColor={textColorDependsOnSecondColor}
                titleBMCSectionColor={
                  isFourthColorPaletteNumber &&
                  getColorDependsOnBackground(`${fourthColor}80`)
                }
                templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
                disabled={disabled}
              />
            </Lazyload>
          )}
          {testing && (
            <Lazyload>
              <Testing
                locales={locales}
                data={portfolio.testing}
                className="testing testing-architectural section"
                textCardStyle="text-card-arch"
                testListStyle="tests-list-arch"
                borderCardColor={fourthColor}
                backgroundSection={{
                  backgroundImage: `linear-gradient(to bottom,
                  #FFFFFF 50%,
                  ${firstColor} 50%)`,
                }}
                inputColor={thirdColor}
                rateColor={black}
                linkColor={black}
                backgroundCard={`${secondColor}33`}
                textColor={black}
                titleCardStyle={TITLE_MODAL_STYLE_ARCH}
                buttonCardStyle={BUTTON_MODAL_STYLE_ARCH}
                templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
                disabled={disabled}
              />
            </Lazyload>
          )}
          {bmc && (
            <>
              <Desirability
                locales={locales}
                data={portfolio.desirabilitySection}
                className="desirability-architectural section"
                backgroundSection={thirdColor}
                sectionHeaderTitleColor={
                  textColorDependsOnBackgroundAndPaletteDFV
                }
                descriptionColor={textColorDependsOnBackgroundAndPaletteDFV}
                templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
                disabled={disabled}
              />
              <Lazyload>
                <CustomerSegmentArch
                  locales={locales}
                  data={portfolio.customerSegment}
                  backgroundSection={secondColor}
                  backgroundImage={fourthColor}
                  backgroundModal={`${secondColor}80`}
                  isFourthColorPaletteNumber={isFourthColorPaletteNumber}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <ValuePropositionArch
                  locales={locales}
                  data={portfolio.valueProposition}
                  backgroundSection={firstColor}
                  backgroundImage={fourthColor}
                  backgroundModal={`${secondColor}80`}
                  colorPaletteNumber={colorPaletteNumber}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <CompetitorAnalysis
                  locales={locales}
                  data={portfolio.competitorAnalysis}
                  className="competitor-analysis-architectural section"
                  backgroundSection={secondColor}
                  backgroundImage={fourthColor}
                  backgroundModal={`${secondColor}80`}
                  backgroundHeadTable={firstColor}
                  sectionHeaderTitleColor={textColorDependsOnSecondColor}
                  descriptionColor={textColorDependsOnSecondColor}
                  backgroundTable={white}
                  headTableColor={textColorDependsOnBackgroundAndPalette}
                  borderTableColor={secondColor}
                  borderImageColor={textColorDependsOnSecondColor}
                  titleStyle={TITLE_MODAL_STYLE_ARCH}
                  buttonStyle={BUTTON_MODAL_STYLE_ARCH}
                  lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_ARCH}
                  sliderColor={black}
                  templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <CustomerRelationships
                  locales={locales}
                  data={portfolio.customerRelationships}
                  className="customer-relationships-architectural section"
                  backgroundSection={firstColor}
                  sectionHeaderTitleColor={
                    textColorDependsOnBackgroundAndPalette
                  }
                  descriptionColor={textColorDependsOnBackgroundAndPalette}
                  firstGKGColor={darkGrey}
                  secondGKGColor={white}
                  textColor={black}
                  tabColor={textColorDependsOnBackgroundAndPalette}
                  backgroundBlock={white}
                  borderColorBlock={firstColor}
                  templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <ChannelsArch
                  locales={locales}
                  data={portfolio.channels}
                  background={firstColor}
                  backgroundImage={fourthColor}
                  backgroundModal={`${secondColor}80`}
                  colorPaletteNumber={colorPaletteNumber}
                  isFourthColorPaletteNumber={isFourthColorPaletteNumber}
                  disabled={disabled}
                />
              </Lazyload>
              <Feasibility
                locales={locales}
                data={portfolio.feasibility}
                className="feasibility-architectural section"
                backgroundSection={thirdColor}
                sectionHeaderTitleColor={
                  textColorDependsOnBackgroundAndPaletteDFV
                }
                descriptionColor={textColorDependsOnBackgroundAndPaletteDFV}
                templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
                disabled={disabled}
              />
              <Lazyload>
                <KeyActivities
                  locales={locales}
                  data={portfolio.keyActivities}
                  className="key-activities-architectural relative"
                  backgroundSection={firstColor}
                  circleActivityColor={firstColor}
                  lineActivityColor={black}
                  backgroundActivity={white}
                  backgroundResources={white}
                  lineResourcesColor={black}
                  templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
              <Viability
                locales={locales}
                data={portfolio.viability}
                className="viability-architectural section"
                backgroundSection={thirdColor}
                sectionHeaderTitleColor={
                  textColorDependsOnBackgroundAndPaletteDFV
                }
                descriptionColor={textColorDependsOnBackgroundAndPaletteDFV}
                templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
                disabled={disabled}
              />
              <Lazyload>
                <Finance
                  locales={locales}
                  data={portfolio.finance}
                  className="portfolio-finance-architectural portfolio-finance-architectural-temp section"
                  backgroundSection={secondColor}
                  sectionHeaderTitleColor={textColorDependsOnSecondColor}
                  descriptionColor={textColorDependsOnSecondColor}
                  tabColor={textColorDependsOnSecondColor}
                  activeTabColor={textColorDependsOnSecondColor}
                  firstChartColor={firstColor}
                  secondChartColor={thirdColor}
                  backgroundUnitTable={fourthColor}
                  backgroundRow={`${fourthColor}80`}
                  secondTitleUnitColor={textColorDependsOnSecondColor}
                  backgroundForecastTable={fourthColor}
                  totalColor={black}
                  borderVerticalTableColor={fourthColor}
                  titleForecastColor={textColorDependsOnSecondColor}
                  titleChartColor={textColorDependsOnSecondColor}
                  templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <Sustainability
                  locales={locales}
                  data={portfolio.sustainability}
                  className="sustainability-architectural section"
                  cardClassName="bmc-page-suscard-arch bmc-page-suscard--section"
                  sliderStyles="sdg-card-arch"
                  iconTableStyles="sdg-card-arch-icon"
                  backgroundSliderColor={firstColor}
                  iconFill={getColorDependsOnBackgroundAndPalette(
                    colorPaletteNumber,
                    firstColor,
                  )}
                  textSliderColor={getColorDependsOnBackgroundAndPalette(
                    colorPaletteNumber,
                    firstColor,
                  )}
                  iconFillTable={isThirdColorPalette ? black : firstColor}
                  backgroundIcon={
                    isThirdColorPalette ? `${firstColor}80` : `${firstColor}33`
                  }
                  backgroundTable={
                    isThirdColorPalette ? `${firstColor}80` : `${firstColor}33`
                  }
                  backgroundCells={white}
                  arrowColor={black}
                  colorPaletteNumber={colorPaletteNumber}
                  templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
            </>
          )}
          <ContactUsArch
            locales={locales}
            data={portfolio.contactUs}
            backgroundSection={firstColor}
            colorPaletteNumber={colorPaletteNumber}
            disabled={disabled}
          />
        </StyledWrapper>
      </div>
    </div>
  );
};

export default ArchitecturalMinimalTemplate;
