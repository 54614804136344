import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { filterCollection } from '../../../utils/helpers';
import Card from '../../sustainability/Card';
import {
  COLOURFUL_TEMPLATE_NAME,
  COMMENT_SUSTAINABILITY,
  CURRENT_TEMPLATE_NAME,
} from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import { getCurrentCanvasIcon } from '../../../utils/helpers/getCurrentCanvasIcon';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import SvgIcon from '../../../components/SvgIcon';

const Sustainability = ({
  data: { description },
  locales,
  locales: { portfolio },
  className,
  cardClassName,
  sliderStyles,
  backgroundSection,
  sectionHeaderTitleColor,
  descriptionColor,
  styleColor,
  colorPaletteNumber,
  backgroundSliderColor,
  backgroundTable,
  backgroundCells,
  iconFill,
  iconFillTable,
  iconTableStyles,
  textSliderColor,
  backgroundIcon,
  firstSvgColor,
  secondSvgColor,
  borderCardColor,
  titleTableColor,
  textTableColor,
  borderTableColor,
  arrowColor,
  templateName,
  isFourthColorPaletteNumber = false,
  disabled = false,
}) => {
  const {
    portfolio: { updateSectionField },
  } = useDispatch();

  const { collection } = useSelector((state) => state.sustainability);
  const sustainabilityCollection = filterCollection(collection, 'sdg');

  const isCurrentTemplate = templateName === CURRENT_TEMPLATE_NAME;
  const isColourfulfunTemplate = templateName === COLOURFUL_TEMPLATE_NAME;

  return (
    <div
      className={`${className} relative`}
      style={{ backgroundColor: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_SUSTAINABILITY} />
      {isCurrentTemplate &&
        getCurrentCanvasIcon('SustainabilityCanvas', styleColor)}
      {isColourfulfunTemplate && (
        <>
          <div
            className="svg-funSustainabilityFirst"
            style={{ fill: firstSvgColor }}
          >
            <SvgIcon name="funSustainabilityFirst" />
          </div>
          <div
            className="svg-funSustainabilitySecond"
            style={{ fill: secondSvgColor }}
          >
            <SvgIcon name="funSustainabilitySecond" />
          </div>
        </>
      )}
      <div className="content">
        <SectionHeader
          templateName={templateName}
          title={portfolio.sustainability.sectionName}
          description={description}
          onChange={(e) =>
            updateSectionField({
              sectionName: 'sustainability',
              field: 'description',
              name: 'sustainabilityText',
              value: e.target.value,
            })
          }
          portfolio={portfolio}
          className={isCurrentTemplate && 'violet-title'}
          style={{ color: sectionHeaderTitleColor }}
          descriptionColor={descriptionColor}
          disabled={disabled}
        />
        <Card
          locales={locales}
          collection={sustainabilityCollection}
          className={`${cardClassName} custom-font-body`}
          sliderStyles={sliderStyles}
          colorPaletteNumber={colorPaletteNumber}
          backgroundSliderColor={backgroundSliderColor}
          iconFill={iconFill}
          iconFillTable={iconFillTable}
          iconTableStyles={iconTableStyles}
          textSliderColor={textSliderColor}
          backgroundIcon={backgroundIcon}
          borderCardColor={borderCardColor}
          templateName={templateName}
          backgroundTable={backgroundTable}
          backgroundCells={backgroundCells}
          textTableColor={textTableColor}
          arrowColor={arrowColor}
          titleTableColor={titleTableColor}
          borderTableColor={borderTableColor}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      </div>
    </div>
  );
};

export default Sustainability;
