import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import KeyActivity from '../../keyActivity/Card';
import KeyResources from '../../keyResources/Card';
import {
  COLORS,
  COMMENT_ACTIVITIES,
  TECH_MODERN_TEMPLATE_NAME,
} from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';

const { white } = COLORS;

const KeyActivitiesArch = ({
  locales,
  locales: {
    keyActivity: {
      intro: { card },
    },
    keyResources: {
      intro: { card: resourceCard },
    },
    keyPartners: {
      intro: { card: partnerCard },
    },
    portfolio,
  },
  data: { description },
  background,
  styleColor,
}) => {
  const { collection } = useSelector((state) => state.keyActivity);
  const { collection: partnerCollection } = useSelector(
    (state) => state.keyPartner,
  );
  const { collection: resourcesCollection } = useSelector(
    (state) => state.keyResource,
  );
  const {
    portfolio: { updateSectionField },
  } = useDispatch();

  return (
    <div
      className="key-activities-tech section relative"
      style={{ backgroundColor: background }}
    >
      <PortfolioCommentIcon title={COMMENT_ACTIVITIES} />
      <SectionHeader
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolio.keyActivities.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'keyActivities',
            field: 'description',
            name: 'operationsText',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        style={{ color: styleColor }}
      />

      <KeyActivity
        locales={locales}
        className="key-activity bmc-page-keycard--portfolio-edit custom-font-body"
        card={{ ...card, steps: collection, title: null }}
        wBlur={false}
        templateName={TECH_MODERN_TEMPLATE_NAME}
        titleColor={styleColor}
        background={white}
      />
      <div className="key-resources-wrapper">
        <KeyResources
          locales={locales}
          className="key-resource custom-font-body"
          card={{ ...partnerCard, list: partnerCollection, title: null }}
          wBlur={false}
          background={white}
          titleColor={styleColor}
          circleColor={styleColor}
        />
        <KeyResources
          locales={locales}
          className="key-resource custom-font-body"
          card={{ ...resourceCard, list: resourcesCollection, title: null }}
          wBlur={false}
          background={white}
          titleColor={styleColor}
          circleColor={styleColor}
        />
      </div>
    </div>
  );
};

export default KeyActivitiesArch;
