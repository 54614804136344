import React from 'react';
import Spinner from '../../../main/components/Spinner';

export default function Block({ title, data }) {
  if (data[0].children?.length) {
    const fgSize = data
      .map((item) => item.children.length)
      .reduce((a, b) => a + b, 0);
    return (
      <div className={`admin-block fg-${fgSize}`}>
        <div style={{ display: 'flex', gap: 32 }}>
          {data.map((item, key) => {
            return (
              <div style={{ flexGrow: 1 }} key={key}>
                <div className="admin-block__title">
                  {item.title}{' '}
                  <span style={{ fontWeight: 'bold' }}>{item.value}</span>
                </div>
                <div className="admin-block__items">
                  {item.children.map((child, index) => (
                    <div key={index} className="admin-block-item">
                      <div className="admin-block-item__value">
                        {child.value !== undefined ? child.value : <Spinner />}
                      </div>
                      <div className="admin-block-item__title">
                        {child.title}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className={`admin-block fg-${data.length}`}>
      <div className="admin-block__title">{title}</div>
      <div className="admin-block__items">
        {data.map((item, index) => (
          <div key={index} className="admin-block-item">
            <div className="admin-block-item__value">
              {item.value !== undefined ? item.value : <Spinner />}
            </div>
            <div className="admin-block-item__title">{item.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
