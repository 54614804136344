import * as Route from '../support/serverRoutes';
import makeRequest from '../../main/utils/makeRequest';
import { setCollectionValueByKey } from '../../main/utils/helpers';

const establishmentAndClass = {
  title: 'Establishment & Class',
  children: [
    {
      key: 'establishmentsCount',
      title: 'Establishment',
    },
    {
      key: 'totalClassrooms',
      title: 'Classes',
    },
    {
      key: 'activeClassrooms',
      title: 'Active Classes',
    },
  ],
};

const adminsAndEducators = {
  title: 'Admins & Educators',
  children: [
    {
      key: 'totalTracksAdminAndLecturers',
      title: 'Total',
    },
    {
      key: 'totalActiveAdminAndLecturers',
      title: 'Active Last 30 days',
    },
  ],
};

const students = {
  title: 'Students',
  children: [
    {
      key: 'totalProject',
      title: 'Total Projects',
    },
    {
      key: 'totalStudents',
      title: 'Total Students',
    },
    {
      key: 'totalLastActiveStudents',
      title: 'Active Last 30 days',
    },
  ],
};

const activity = {
  title: 'Activity',
  children: [
    {
      key: 'totalSiteTimeTracks',
      title: 'Total Minutes',
    },
    {
      key: 'totalSiteLastTotalTracks',
      title: 'Minutes Last 30 Days',
    },
  ],
};

const totalProgress = {
  title: 'Total Progress',
  children: [
    {
      key: 'ideasCount',
      title: 'Ideas Created',
    },
    {
      key: 'canvasProgress',
      title: 'Canvas Progress',
    },
    {
      key: 'testsCompleted',
      title: 'Tests Completed',
    },
  ],
};

const licences = {
  title: 'Licences',
  children: [
    {
      key: 'totalLiveLicenses',
      title: 'Live Licences',
    },
    {
      key: 'totalExpiredLicenses',
      title: 'Expired Licences',
    },
  ],
};

const classes = {
  title: 'Classes',
  children: [
    {
      key: 'totalClassrooms',
      title: 'Total',
    },
    {
      key: 'activeClassrooms',
      title: 'Active Last 30 days',
    },
  ],
};

const subcriptionAndLicence = [
  {
    title: 'Subscription:',
    value: '',
    children: [
      {
        key: 'licenseStartDate',
        title: 'Licence Start Date',
      },
      {
        key: 'licenseEndDate',
        title: 'Licence End Date',
      },
    ],
  },
  {
    title: 'Licences:',
    value: '',
    children: [
      {
        key: 'purchasedUserCount',
        title: 'Total',
      },
      {
        key: 'usedUsersCount',
        title: 'Used',
      },
      {
        key: 'remainingLicensesCount',
        title: 'Remaining',
      },
    ],
  },
];

const collectionsInitByPageName = {
  super_admin: [
    establishmentAndClass,
    adminsAndEducators,
    students,
    activity,
    totalProgress,
    licences,
  ],
  admin: [students, totalProgress, activity, classes, subcriptionAndLicence],
  lecturer: [students, totalProgress, activity],
  classroom: [students, totalProgress, activity],
  project: [totalProgress, activity],
};

const blocks = {
  state: {
    data: {},
    collections: [],
    fetchParams: {},
    loading: false,
  },
  reducers: {
    setData(state, data) {
      return { ...state, data };
    },
    setCollection(state, collections) {
      return { ...state, collections };
    },
    setLoading(state, loading) {
      return { ...state, loading };
    },
    setFetchParams(state, params) {
      return { ...state, fetchParams: params };
    },
  },
  effects: (dispatch) => ({
    fetchBlocks({ by, byId, pageName }) {
      const collectionsInit = collectionsInitByPageName[pageName];
      dispatch.blocks.setLoading(true);
      dispatch.blocks.setCollection(collectionsInit);
      dispatch.blocks.setFetchParams({ by, byId, pageName });

      makeRequest({
        dispatch,
        url: Route.blocks({ by, byId, pageName }),
        success: (response) => {
          dispatch.blocks.setCollection(
            setCollectionValueByKey(collectionsInit, response.data.data),
          );
        },
        finish: () => {
          dispatch.blocks.setLoading(false);
        },
      });
    },

    fetchBlock({ by, byId, pageName }) {
      dispatch.blocks.setLoading(true);
      dispatch.blocks.setFetchParams({ by, byId, pageName });

      makeRequest({
        dispatch,
        url: Route.blocks({ by, byId, pageName }),
        success: (response) => {
          dispatch.blocks.setData(response.data.data);
        },
        finish: () => {
          dispatch.blocks.setLoading(false);
        },
      });
    },

    reload(_, rootState) {
      dispatch.blocks.fetchBlocks(rootState.blocks.fetchParams);
    },
  }),
};

export default blocks;
