import axios from 'axios';
import humps from 'humps';
import axiosRetry from 'axios-retry';

import { AXIOS_RETRY_DELAY, AXIOS_RETRIES_COUNT } from './const';

axiosRetry(axios, {
  retries: AXIOS_RETRIES_COUNT,
  retryDelay: () => AXIOS_RETRY_DELAY,
});

axios.interceptors.response.use(
  (response) => {
    if (response.data instanceof Blob) return response;
    response.data = humps.camelizeKeys(response.data);
    return response;
  },
  (error) => {
    error.data = humps.camelizeKeys(error.data);
    return Promise.reject(error);
  },
);

axios.interceptors.request.use(
  (config) => {
    if (!(config.data instanceof FormData)) {
      config.data = humps.decamelizeKeys(config.data);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
