import { Col, Form, Input, Row, Button } from 'antd';
import React from 'react';
import Modal from '../../../main/components/Modal';
import Avatars from '../../components/Avatars';
import { Divider } from '../../components/Divider';
import ModalContainer from '../../components/ModalContainer';
import ModalHeader from '../../components/ModalHeader';
import UserList from '../../components/UserList';
import UserListTitle from '../../components/UserListTitle';

const images = [
  { alt: 'demo Student', fullName: 'Demo Student' },
  { alt: 'demo Student', fullName: 'Demo Student' },
  { alt: 'demo Student', fullName: 'Demo Student' },
];
const ModalAddStudentsToClass = () => {
  return (
    <Modal onBlackscreenClick={() => {}}>
      <ModalContainer>
        <ModalHeader title="Class Settings" />
        <Form layout="vertical">
          <div className="mt-20">
            <Row gutter={40}>
              <Col span={12}>
                <Form.Item label="Class Name" className="admin-input-label">
                  <Input className="admin-input" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Students" className="admin-input-label">
                  <Avatars images={images} maxVisibility={5} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Share Link with Students"
              className="admin-input-label"
            >
              <Input
                placeholder="https://validatestartup.com/test/createnewclass"
                className="admin-input"
              />
            </Form.Item>
          </div>

          <div className="flex align-right mt-20">
            <Button type="primary">Done</Button>
          </div>
          <div className="mt-40 mb-40">
            <UserListTitle title="Educators" />
            <UserList />
          </div>
          <Divider />
          <div className="mt-20">
            <Button type="text" danger>
              Deactivate Class
            </Button>
          </div>
        </Form>
      </ModalContainer>
    </Modal>
  );
};

export default ModalAddStudentsToClass;
