import React from 'react';
import { useSelector } from 'react-redux';

import CompetitorsCard from '../../main/containers/valueProposition/CompetitorsCardTemplates';
import SvgIcon from '../../main/components/SvgIcon';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import {
  COLOURFUL_TEMPLATE_NAME,
  CURRENT_TEMPLATE_NAME,
} from '../../main/utils/const';
import { getCurrentCanvasIcon } from '../../main/utils/helpers/getCurrentCanvasIcon';

const CompetitorAnalysis = ({
  locales,
  locales: { portfolioWeb },
  className,
  styleColor,
  firstSvgColor,
  secondSvgColor,
  backgroundHeadTable,
  colorTemplate,
  backgroundImage,
  backgroundSection,
  sectionHeaderTitleColor,
  descriptionColor,
  backgroundTable,
  titleImageColor,
  headTableColor,
  textTableColor,
  borderTableColor,
  borderImageColor,
  templateName,
}) => {
  const { collection } = useSelector((state) => state.competitor);
  const { competitorAnalysis } = useSelector((state) => state.portfolio);
  const isColourfulfunTemplate = templateName === COLOURFUL_TEMPLATE_NAME;
  const isCurrentTemplate = templateName === CURRENT_TEMPLATE_NAME;
  return (
    <div
      className={`${className} section-web`}
      style={{ backgroundColor: backgroundSection }}
    >
      {isCurrentTemplate && getCurrentCanvasIcon('VPCanvas', styleColor)}
      {isColourfulfunTemplate && (
        <>
          <div className="svg-funCAthirteen" style={{ fill: firstSvgColor }}>
            <SvgIcon name="funCAthirteen" />
          </div>
          <div className="svg-funCAfourteen" style={{ fill: secondSvgColor }}>
            <SvgIcon name="funCAfourteen" />
          </div>
        </>
      )}
      <div className="content">
        <SectionHeaderWeb
          templateName={templateName}
          title={portfolioWeb.competitorAnalysis}
          description={competitorAnalysis.description}
          className={isCurrentTemplate && 'green-title'}
          style={{ color: sectionHeaderTitleColor }}
          descriptionColor={descriptionColor}
        />
        <CompetitorsCard
          locales={locales}
          className="margin-top-40"
          isPreview
          card={{
            ...locales.valueProposition.intro.competitorsCard,
            competitors: collection,
          }}
          colorTemplate={colorTemplate}
          backgroundHeadTable={backgroundHeadTable}
          backgroundTable={backgroundTable}
          backgroundImage={backgroundImage}
          titleImageColor={titleImageColor}
          headTableColor={headTableColor}
          textColor={textTableColor}
          borderTableColor={borderTableColor}
          borderImageColor={borderImageColor}
        />
      </div>
    </div>
  );
};

export default CompetitorAnalysis;
