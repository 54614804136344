import React from 'react';
import PropTypes from 'prop-types';
import Textarea from '.';
import {
  BLOG_FRIENDLY_TEMPLATE_NAME,
  COLOURFUL_TEMPLATE_NAME,
  CURRENT_TEMPLATE_NAME,
} from '../../utils/const';

const TextareaDescription = ({
  templateName,
  description,
  onChange,
  portfolio,
  rows = 3,
}) => {
  const getDivCurrentStyle = () => {
    return (
      templateName === CURRENT_TEMPLATE_NAME &&
      'textarea-current-wrapper description'
    );
  };

  const getDivColourfunStyle = () => {
    return (
      templateName === COLOURFUL_TEMPLATE_NAME &&
      'textarea-colourful description'
    );
  };

  const getDivBlogStyle = () => {
    return (
      templateName === BLOG_FRIENDLY_TEMPLATE_NAME &&
      'textarea-blog description'
    );
  };

  const getTextareaCurrentStyle = () => {
    return (
      templateName === CURRENT_TEMPLATE_NAME &&
      'textarea-current-template custom-font-body'
    );
  };

  const getTextareaColourfunStyle = () => {
    return (
      templateName === COLOURFUL_TEMPLATE_NAME &&
      'textarea-colourfulFun textarea-colourfulFun-testing custom-font-body'
    );
  };

  const getTextareaBlogStyle = () => {
    return (
      templateName === BLOG_FRIENDLY_TEMPLATE_NAME &&
      'textarea-blogFriendly textarea-blogFriendly-testing custom-font-body'
    );
  };

  return (
    <div
      className={
        getDivCurrentStyle() || getDivColourfunStyle() || getDivBlogStyle()
      }
    >
      <Textarea
        name="textarea"
        rows={rows}
        delay={700}
        value={description}
        onChange={onChange}
        className={
          getTextareaCurrentStyle() ||
          getTextareaColourfunStyle() ||
          getTextareaBlogStyle()
        }
        placeholder={portfolio.page.descriptionPlaceholder}
      />
    </div>
  );
};

TextareaDescription.propTypes = {
  onChange: PropTypes.func.isRequired,
  portfolio: PropTypes.object,
  templateName: PropTypes.string,
  description: PropTypes.string,
};

export default TextareaDescription;
