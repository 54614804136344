import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import SustainabilityCards from './Slider';
import SustainabilityTable from './Table';
import Button from '../../components/Button';
import { filterCollection } from '../../utils/helpers';
import { CURRENT_TEMPLATE_NAME, COLORS } from '../../utils/const';

const { white } = COLORS;

export default function Card({
  locales,
  className,
  collection,
  title,
  icon,
  editMode = false,
  iconFill = white,
  iconFillTable = white,
  backgroundIcon,
  backgroundTable,
  borderCardColor,
  borderCardTableColor,
  borderTableColor,
  backgroundSliderColor,
  backgroundRow,
  sliderStyles = 'sdg-card',
  iconTableStyles,
  backgroundCells,
  templateName = CURRENT_TEMPLATE_NAME,
  titleTableColor,
  textTableColor,
  textSliderColor,
  buttonStyle,
  textStyle,
  arrowColor,
  buttonColor,
  backgroundButton,
  isFourthColorPaletteNumber = false,
}) {
  const {
    sustainability: { addSustainability },
  } = useDispatch();

  const sustainabilityCollection = editMode
    ? collection
    : filterCollection(collection, 'sdg');

  return (
    <div className={className}>
      {title && (
        <div className="bmc-page-suscard-header">
          <div className="bmc-page-suscard-header-icon">
            <img src={icon} alt="" />
          </div>
          <div className="bmc-page-suscard-header-title custom-font-title">
            {title}
          </div>
        </div>
      )}

      {!editMode && (
        <SustainabilityCards
          locales={locales}
          collection={sustainabilityCollection}
          iconFill={iconFill}
          borderCardColor={borderCardColor}
          sliderStyles={sliderStyles}
          backgroundSliderColor={backgroundSliderColor}
          templateName={templateName}
          textColor={textSliderColor}
          textStyle={textStyle}
          arrowColor={arrowColor}
        />
      )}
      <SustainabilityTable
        editMode={editMode}
        locales={locales}
        collection={sustainabilityCollection}
        iconFillTable={iconFillTable}
        borderCardTableColor={borderCardTableColor}
        borderTableColor={borderTableColor}
        backgroundTable={backgroundTable}
        iconTableStyles={iconTableStyles}
        backgroundIcon={backgroundIcon}
        backgroundCells={backgroundCells}
        backgroundRow={backgroundRow}
        titleTableColor={titleTableColor}
        textColor={textTableColor}
        textStyle={textStyle}
        buttonStyle={buttonStyle}
        templateName={templateName}
        isFourthColorPaletteNumber={isFourthColorPaletteNumber}
      />

      {editMode && (
        <Button
          title={locales.sustainability.intro.card.createNewSdg}
          wide
          onClick={addSustainability}
          className={classNames(
            'margin-top-20 custom-font-body',
            buttonStyle ? `${buttonStyle}` : 'bmc-button--blue',
          )}
          icon="icon-Add"
          style={{ color: buttonColor, background: backgroundButton }}
        />
      )}
    </div>
  );
}
