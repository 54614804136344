/* eslint-disable no-nested-ternary */
// eslint-disable-next-line import/no-cycle
import { customerSegmentType } from './helpers';

export const CUSTOMER_SEGMENT_AGE_RANGE_MIN = 1;
export const CUSTOMER_SEGMENT_AGE_RANGE_MAX = 100;
export const CUSTOMER_SEGMENT_COMPANY_SIZE_MIN = 1;
export const CUSTOMER_SEGMENT_COMPANY_SIZE_MAX = 1000;
export const CUSTOMER_SEGMENT_B2C = 'B2cCustomerSegment';
export const CUSTOMER_SEGMENT_B2B = 'B2bCustomerSegment';
export const CUSTOMER_SEGMENT_MAX_COUNT = 4;
export const UNIQUE_SELLING_POINTS_MAX_COUNT = 4;
export const COMPETITOR_MAX_COUNT = 8;
export const STRATEGIES_MAX_COUNT = 3;
export const CHANNELS_MAX_COUNT = 6;
export const KEY_ACTIVITIES_MAX_COUNT = 6;
export const KEY_RESOURCES_MAX_COUNT = 6;
export const KEY_PARTNERS_MAX_COUNT = 6;
export const COSTS_MAX_COUNT = 10;
export const PRODUCTS_MAX_COUNT = 10;
export const REVENUE_MAX_COUNT = 6;
export const SUSTAINABILITY_MAX_COUNT = 6;
export const IDEATION_SELECTED_IMAGES_COUNT = 6;
export const IDEATION_IMAGES_PER_PAGE = 12;
export const NUMBER_REGEX = /^[0-9.]+$/;
export const PORTFOLIO_SPECIAL_SECTION_DESCRIPTION_MAX_LENGTH = 1000;
export const PORTFOLIO_WEB_LEFT_ALIGN_AFTER_LENGTH = 500;
export const FORCE_SIGNOUT_TIMEOUT = 10000;
export const PORTFOLIO_INTRO_LOGO_ASPECT_RATIO = 250 / 80;
export const PORTFOLIO_INTRO_BG_ASPECT_RATIO = 1440 / 700;
export const PORTFOLIO_FILE_MAX_COUNT = 10;
export const TEXTAREA_DELAY = 700;
export const CHECK_CONNECTION_TIMEOUT = 10000;
export const CHECK_CONNECTION_ENABLED = true;
export const TEXTAREA_STATUS_DONE_TIMEOUT = 3000;
export const TEXTAREA_STATUS_ENABLED = true;
export const TEST_STATUS_BLOCKS = [
  'CS',
  'VP',
  'CR',
  'CH',
  'KA',
  'KP',
  'RE',
  'SU',
  'CO',
  'KR',
];
export const CURRENT_TEMPLATE_NAME = 'modern';
export const COLOURFUL_TEMPLATE_NAME = 'colourful';
export const BLOG_FRIENDLY_TEMPLATE_NAME = 'blog';
export const ARCHITECTURAL_MINIMAL_TEMPLATE_NAME = 'architectural';
export const BOLD_TEMPLATE_NAME = 'bold';
export const TECH_MODERN_TEMPLATE_NAME = 'tech';

// textarea statuses
export const DEFAULT_STATUS = 'default';
export const SENDING_STATUS = 'sending';
export const DONE_STATUS = 'done';

// onboarding
export const ENABLE_ONBOARDING = true;

// axios
export const AXIOS_RETRY_DELAY = 3000;
export const AXIOS_RETRIES_COUNT = 2;

export const CUSTOMER_SEGMENT_TYPE_OPTIONS = [
  CUSTOMER_SEGMENT_B2C,
  CUSTOMER_SEGMENT_B2B,
].map((e) => ({ label: customerSegmentType(e), value: e }));

export const COST_PRICE_OPTIONS = [
  'Daily',
  'Weekly',
  'Monthly',
  'Yearly',
  'One Off',
].map((e) => ({ label: e, value: e }));

export const SELECT_STYLES = {
  control: (provided) => ({
    ...provided,
    height: '42px',
    borderRadius: '8px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 0,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
    color: 'black',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'inherit',
  }),
};

export const SELECT_STYLES_MODAL = {
  control: (provided) => ({
    ...provided,
    height: '45px',
    borderRadius: 'inherit',
    backgroundColor: '#F8F9FA',
    border: '1px solid #DDE2E5',
    ':hover': {
      border: '1px solid #0984FF1A',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 0,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
    color: 'black',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'inherit',
  }),
};

export const SELECT_STYLES_SEGMENT = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#F8F9FA',
    border: '1.09px solid #F8F9FA',
    minHeight: '32px',
    height: '32px',
    margin: 0,
    padding: 0,
    ':hover': {
      border: '1.09px solid #0984FF',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 0,
  }),
  menu: (provided) => ({
    ...provided,
    color: '#334249',
    fontFamily: 'inherit',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    color: 'inherit',
  }),
  valueContainer: (provided) => ({
    ...provided,
    margin: 0,
    padding: '5px',
    color: 'inherit',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    color: '#334249',
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    color: 'inherit',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#334249',
    padding: '5px',
    ':hover': {
      color: '#0984FF',
    },
  }),
};

export const SELECT_STYLES_COLOURFUL = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#FFFFFF00',
    border: '1.09px solid #FFFFFF00',
    minHeight: '32px',
    height: '39px',
    margin: 0,
    padding: '4px',
    fontSize: '16px',
    ':hover': {
      border: '1.09px solid #ACB5BD',
    },
    ':active': { border: '1.09px solid #0984ff' },
    ':focus': { border: '1.09px solid #0984ff' },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 0,
  }),
  menu: (provided) => ({
    ...provided,
    color: '#000000',
    fontSize: '16px',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    color: 'inherit',
  }),
  valueContainer: (provided) => ({
    ...provided,
    margin: 0,
    padding: '5px',
    color: 'inherit',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    color: '#000000',
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    color: 'inherit',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#000000',
    padding: '5px',
    ':hover': {
      color: '#0984FF',
    },
  }),
};

export const SELECT_STYLES_BLOG = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#FFFFFF00',
    border: '1px solid #FFFFFF00',
    minHeight: '32px',
    height: '39px',
    margin: 0,
    padding: '4px',
    fontSize: '16px',
    ':hover': {
      border: '1px solid #ACB5BD',
    },
    ':active': { border: '1px solid #0984ff' },
    ':focus': { border: '1px solid #0984ff' },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 0,
  }),
  menu: (provided) => ({
    ...provided,
    color: '#000000',
    fontSize: '16px',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    color: 'inherit',
  }),
  valueContainer: (provided) => ({
    ...provided,
    margin: 0,
    padding: '5px',
    color: 'inherit',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    color: '#000000',
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    color: 'inherit',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#000000',
    padding: '5px',
    ':hover': {
      color: '#0984FF',
    },
  }),
};

export const ADMIN_SELECT_STYLES = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided) => ({
    ...provided,
    height: '45px',
    borderRadius: '8px',
    border: '2px solid #DDE2E5',
    width: '100%',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 0,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
    color: 'black',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#ACB5BD',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
  }),
};

export const SELECT_V2_STYLES = {
  container: (provided) => ({
    ...provided,
    width: 'auto',
  }),
  control: (provided) => ({
    ...provided,
    height: '60px',
    borderRadius: 'none',
    border: 'none',
    width: 'auto',
    boxShadow: 'none',
    background: 'transparent',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 0,
    color: '#334249',
    backgroundColor: '#334249',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    color: '#334249',
  }),
  menu: (provided) => ({
    ...provided,
    width: 150,
    zIndex: 10,
    right: 0,
    color: 'black',
    padding: 16,
    borderRadius: 16,
    backgroundColor: '#F8F9FA',
    boxShadow:
      '0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.05)',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'inherit',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '38px',
    lineHeight: '52px',
    textAlign: 'left',
    fontFamily: 'inherit',
  }),
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    backgroundColor: isSelected ? '#0984FF' : isFocused ? '#eeeeee' : undefined,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 8,
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    textAlign: 'center',
    cursor: isSelected ? 'default' : 'pointer',
  }),
  menuList: (provided) => ({ ...provided, maxHeight: 700 }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
};

export const SELECT_V3_STYLES = {
  container: (provided) => ({
    ...provided,
    width: 'auto',
  }),
  control: (provided) => ({
    ...provided,
    height: '22px',
    borderRadius: 'none',
    border: 'none',
    width: 'auto',
    boxShadow: 'none',
    background: 'transparent',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 0,
    color: '#334249',
    backgroundColor: '#334249',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    color: '#334249',
  }),
  menu: (provided) => ({
    ...provided,
    width: 260,
    zIndex: 10,
    right: 0,
    color: 'black',
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 10,
    paddingRight: 25,
    borderRadius: 16,
    backgroundColor: '#F8F9FA',
    boxShadow:
      '0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.05)',
  }),
  menuList: (provided) => ({ ...provided, maxHeight: 300, width: 250 }),
  singleValue: (provided) => ({
    ...provided,
    display: 'none',
    color: 'inherit',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'left',
    fontFamily: 'inherit',
  }),
  valueContainer: (provided) => ({
    ...provided,
    width: 0,
    height: 0,
    overflow: 'hidden',
  }),
  input: (provided) => ({
    ...provided,
    display: 'none',
  }),
  clearIndicator: (provided) => ({ ...provided, display: 'none' }),
  placeholder: (provided) => ({
    ...provided,
    color: '#334249',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'left',
    fontFamily: 'Nunito',
    display: 'none',
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    textAlign: 'center',
  }),
};

export const TEST_STATUS_SELECTOR = {
  container: (provided) => ({
    ...provided,
    width: 'auto',
  }),
  control: (provided) => ({
    ...provided,
    height: '22px',
    borderRadius: 'none',
    border: 'none',
    width: 'auto',
    boxShadow: 'none',
    background: 'transparent',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 0,
    color: '#334249',
    backgroundColor: '#334249',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    color: '#334249',
  }),
  menu: (provided) => ({
    ...provided,
    width: 237,
    zIndex: 10,
    right: 0,
    color: 'black',
    padding: 16,
    borderRadius: 16,
    backgroundColor: '#F8F9FA',
    boxShadow:
      '0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.05)',
  }),
  menuList: (provided) => ({ ...provided, maxHeight: 300 }),
  singleValue: (provided) => ({
    ...provided,
    display: 'none',
    color: '#334249',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'left',
    fontFamily: 'Nunito',
  }),
  valueContainer: (provided) => ({
    ...provided,
    width: 0,
    height: 0,
    overflow: 'hidden',
  }),
  clearIndicator: (provided) => ({ ...provided, display: 'none' }),
  placeholder: (provided) => ({
    ...provided,
    color: '#334249',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'left',
    fontFamily: 'Nunito',
    display: 'none',
  }),
  input: (provided) => ({
    ...provided,
    width: 0,
    height: 0,
    overflow: 'hidden',
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    textAlign: 'center',
    padding: 0,
  }),
};

export const PRODUCT_CARD_TABLE_COLLECTION_EDIT_SETTINGS = [
  { name: 'name', type: 'text' },
  { name: 'costPrice', type: 'number' },
  { name: 'unit', type: 'text' },
];

export const SECOND_PRODUCT_CARD_TABLE_COLLECTION_EDIT_SETTINGS = [
  { name: 'name', type: 'text' },
  { name: 'unit', type: 'text' },
  { name: 'endUserPrice', type: 'text' },
];

export const COST_CARD_TABLE_COLLECTION_EDIT_SETTINGS = [
  { name: 'name', type: 'text' },
  { name: 'amount', type: 'number' },
  { name: 'period', type: 'select', options: COST_PRICE_OPTIONS },
];

export const NOTIFICATION_DELAY = 5000;
export const NOTIFICATION_TYPES = {
  primary: 'primary',
  danger: 'danger',
  success: 'success',
  warning: 'warning',
  warningBlue: 'warningBlue',
};

export const ALERT_TYPES = {
  warning: 'warning',
};

export const TEMPLATES = [
  {
    src: '/images/portfolio/template1.png',
    alt: 'Modern',
  },
  {
    src: '/images/portfolio/template2-1.png',
    alt: 'Colourful',
  },
  {
    src: '/images/portfolio/template3.png',
    alt: 'Architectural',
  },
  {
    src: '/images/portfolio/template4.png',
    alt: 'Tech & Modern',
  },
  {
    src: '/images/portfolio/template5.png',
    alt: 'Blog',
  },
  {
    src: '/images/portfolio/template6.png',
    alt: 'Bold',
  },
];

export const COLORS_COLOURFUL_FUN = [
  {
    name: 'firstColorTemplate',
    colors: ['#ECDEC4', '#355D3A', '#F1A387', '#1F3260'],
  },
  {
    name: 'secondColorTemplate',
    colors: ['#FBBCD0', '#FECE4A', '#B6ECFD', '#226EE0'],
  },
  {
    name: 'thirdColorTemplate',
    colors: ['#F4B8B7', '#86CB89', '#948BFF', '#F5A7D6'],
  },
];

export const TEMPLATE_FONTS = [
  { value: 'Andalé Mono', label: 'Andalé Mono' },
  { value: 'Arial', label: 'Arial' },
  { value: 'Baloo2', label: 'Baloo2' },
  { value: 'Baskerville', label: 'Baskerville' },
  { value: 'Bradley Hand', label: 'Bradley Hand' },
  { value: 'Brush Script MT', label: 'Brush Script MT' },
  { value: 'Comic Sans MS', label: 'Comic Sans MS' },
  { value: 'Courier', label: 'Courier' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Kanit', label: 'Kanit' },
  { value: 'Lato', label: 'Lato' },
  { value: 'Lucida', label: 'Lucida' },
  { value: 'Luminari', label: 'Luminari' },
  { value: 'Martel Sans', label: 'Martel Sans' },
  { value: 'Monaco', label: 'Monaco' },
  { value: 'Nixie One', label: 'Nixie One' },
  { value: 'Noticia Text', label: 'Noticia Text' },
  { value: 'Nunito', label: 'Nunito' },
  { value: 'Open Sans', label: 'Open Sans' },
  { value: 'Palatino', label: 'Palatino' },
  { value: 'Prata', label: 'Prata' },
  { value: 'Tahoma', label: 'Tahoma' },
  { value: 'Times New Roman', label: 'Times New Roman' },
  { value: 'Verdana', label: 'Verdana' },
  { value: 'Bodoni 72', label: 'Bodoni 72' },
  { value: 'Futura', label: 'Futura' },
  { value: 'Garamond', label: 'Garamond' },
  { value: 'Cooper Black', label: 'Cooper Black' },
  { value: 'Avenir', label: 'Avenir' },
  { value: 'STHupo', label: 'STHupo' },
  { value: 'Kannada MN', label: 'Kannada MN' },
  { value: 'din-condensed', label: 'DIN' },
];

export const COLORS = {
  black: '#000000',
  lightBlack: '#334249',
  white: '#FFFFFF',
  transparentWhite: '#FFFFFF00',
  lightGreen: '#00AFC91A',
  green: '#00AFC9',
  darkGreen: '#5E939C',
  secondGreen: '#8FC5BE',
  thirdGreen: '#3C6731',
  fourthGreen: '#CCD1C9',
  orange: '#FF6D00',
  grey: '#E7E6E6',
  secondGrey: '#F8F9FA',
  darkViolet: '#404157',
  violet: '#615BDC',
  yellow: '#FCC434',
  superLightGrey: '#F7F6F6',
  lightGrey: '#EFEDEA',
  middleGrey: '#ACB5BD',
  darkGrey: '#C2C2C2',
  purple: '#53699E',
  blue: '#0984ff',
  lightBlue: '#0984ff33',
  red: '#FC3159',
  secondPurple: '#968BFF',
  lightBrown: '#ECDEC4',
  pink: '#FBBCD0',
  darkPink: '#F4B8B7',
  lightBeige: '#E9DDD4',
};

export const TABS = {
  get: { index: 0, svg: 'funCRget' },
  keep: { index: 1, svg: 'funCRkeep' },
  grow: { index: 2, svg: 'funCRgrow' },
  referral: { index: 3, svg: 'funCRreferral' },
};

export const FIRST_COLOR_PALETTE_NUMBER = 1;
export const SECOND_COLOR_PALETTE_NUMBER = 2;
export const THIRD_COLOR_PALETTE_NUMBER = 3;

export const COLOR_PALETTE_ARRAY_ARCH = {
  [COLORS.darkViolet]: 1,
  [COLORS.yellow]: 2,
  [COLORS.lightGrey]: 3,
};

export const COLOR_PALETTE_ARRAY_CURRENT = {
  [COLORS.green]: 1,
  [COLORS.darkGreen]: 2,
  [COLORS.purple]: 3,
};

export const COLOR_PALETTE_ARRAY_COLOURFUL = {
  [COLORS.lightBrown]: 1,
  [COLORS.pink]: 2,
  [COLORS.darkPink]: 3,
};

export const COLOR_PALETTE_ARRAY_BLOG = {
  [COLORS.superLightGrey]: 1,
  [COLORS.lightBeige]: 2,
  [COLORS.fourthGreen]: 3,
};

export const COLOR_PALETTE_ARRAY_TECH = {
  [COLORS.secondGreen]: 1,
  [COLORS.secondPurple]: 2,
  [COLORS.blue]: 3,
};

export const COMMENT_INTRODUCTION = 'Introduction';
export const COMMENT_SUMMARY = 'Project Summary';
export const COMMENT_TEAM = 'The Team';
export const COMMENT_BMC = 'Business Model Canvas';
export const COMMENT_TESTING = 'Testing';
export const COMMENT_DESIRABILITY = 'Desirability Section';
export const COMMENT_SEGMENT = 'Customer Segment';
export const COMMENT_PROPOSITION = 'Value Proposition';
export const COMMENT_ANALYSIS = 'Competitor Analysis';
export const COMMENT_RELATIONSHIPS = 'Customer Relationships';
export const COMMENT_CHANNELS = 'Channels';
export const COMMENT_FEASIBILITY = 'Feasibility Section';
export const COMMENT_ACTIVITIES = 'Key Activities, Resources & Partners';
export const COMMENT_VIABILITY = 'Viability Section';
export const COMMENT_FINANCE = 'Finance';
export const COMMENT_SUSTAINABILITY = 'Sustainability';
export const COMMENT_CONTACT = 'Contact Us';

export const BUTTON_MODAL_STYLE_CURRENT = 'button-current';

export const TITLE_MODAL_STYLE_COLOURFUL = 'header-colourful';
export const BUTTON_MODAL_STYLE_COLOURFUL = 'button-colourful';
export const BUTTON_LIGHT_MODAL_STYLE_COLOURFUL = 'button-colourful-light';

export const TITLE_MODAL_STYLE_ARCH = 'header-arch';
export const BUTTON_MODAL_STYLE_ARCH = 'button-arch';
export const BUTTON_LIGHT_MODAL_STYLE_ARCH = 'button-arch-light';

export const TITLE_MODAL_STYLE_BLOG = 'header-blog';
export const BUTTON_MODAL_STYLE_BLOG = 'button-blog';
export const BUTTON_LIGHT_MODAL_STYLE_BLOG = 'button-blog-light';

export const TITLE_MODAL_STYLE_BOLD = 'header-bold';
export const BUTTON_MODAL_STYLE_BOLD = 'button-bold';
export const BUTTON_LIGHT_MODAL_STYLE_BOLD = 'button-bold-light';

export const BLOCK_NAMES = [
  'CS',
  'VP',
  'CR',
  'CH',
  'KA',
  'KR',
  'KP',
  'CO',
  'RE',
  'SU',
];

export const IMAGE_ASPECT_RATIO_ONE = 1;
export const IMAGE_ASPECT_RATIO_INTRO_COLOURFUN = 1 / 1.06;
export const IMAGE_ASPECT_RATIO_CS_COLOURFUN = 1 / 1.34;
export const IMAGE_ASPECT_RATIO_VP_COLOURFUN = 2.53 / 1;
export const IMAGE_ASPECT_RATIO_CH_COLOURFUN = 1 / 1.34;
export const IMAGE_ASPECT_RATIO_VP_BLOG = 1 / 1.7;
export const IMAGE_ASPECT_RATIO_CS_ARCH = 1 / 1.68;
export const IMAGE_ASPECT_RATIO_VP_ARCH = 1.08 / 1;
export const IMAGE_ASPECT_RATIO_CH_ARCH = 1.83 / 1;
export const IMAGE_ASPECT_RATIO_VP_BOLD = 1 / 1.7;
export const IMAGE_ASPECT_RATIO_CH_BOLD = 1 / 1.3;
export const IMAGE_ASPECT_RATIO_VP_TECH = 1 / 1.15;
export const IMAGE_ASPECT_RATIO_VP_CARD = 1 / 1.2;
