import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import ImageUploader from '../../../components/ImageUploader';
import SvgIcon from '../../../components/SvgIcon';
import ConfirmationModal from '../../../components/ConfirmationModal';

export default function ({ documentType, uploading }) {
  const { bmcId, id: testId } = useParams();
  const {
    testDocument: { collection },
  } = useSelector((state) => state);

  const {
    testDocument: { fetchTestDocuments, addTestDocument, removeTestDocument },
  } = useDispatch();

  useEffect(() => {
    fetchTestDocuments({ bmcId, testId, documentType });
  }, []);

  return (
    <div className="resource-uploader">
      {collection.map((document) => (
        <div
          className="resource-uploader__item"
          key={document.id}
          onClick={() => window.open(document.file, '_black')}
        >
          <i className="icon-Link" />

          <div className="resource-uploader__item-text">{document.name}</div>

          <ConfirmationModal
            body="Are you sure?"
            confirmText="Delete"
            className="resource-uploader__item-close"
            onConfirm={() => removeTestDocument({ testId, id: document.id })}
          >
            <SvgIcon name="x" />
          </ConfirmationModal>
        </div>
      ))}

      {collection.map((e) => e.documentType === documentType).length < 3 && (
        <ImageUploader
          title={uploading ? 'Uploading...' : 'Upload file'}
          icon="icon-Add"
          className="resource-uploader__button"
          onChange={([blob]) => {
            addTestDocument({ documentType, blob, testId });
          }}
        />
      )}
    </div>
  );
}
