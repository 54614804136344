const initialValues = {
  firstName: '',
  surname: '',
  email: '',
  password: '',
  projectName: '',
  teammates: [''],
};

export default initialValues;
