import React from 'react';
import Lazyload from 'react-lazyload';
import { useSelector } from 'react-redux';
import { find } from 'lodash';

import {
  BUTTON_LIGHT_MODAL_STYLE_COLOURFUL,
  BUTTON_MODAL_STYLE_COLOURFUL,
  COLORS,
  COLOR_PALETTE_ARRAY_COLOURFUL,
  COLOURFUL_TEMPLATE_NAME,
  TITLE_MODAL_STYLE_COLOURFUL,
} from '../../../utils/const';
import CustomerSegmentColourful from './CustomerSegmentColourful';
import ValuePropositionColourful from './ValuePropositionColourful';
import ChannelsColourful from './ChannelsColourful';
import ContactUsColourful from './ContactUsColourful';
import { StyledWrapper } from '../../../utils/helpers';
import { getColorTemplate } from '../../../utils/helpers/getColorTemplate';
import Introduction from '../mainTemplateComponents/Introduction';
import Summary from '../mainTemplateComponents/Summary';
import Team from '../mainTemplateComponents/Team';
import BMC from '../mainTemplateComponents/BMC';
import { getColorDependsOnBackground } from '../../../utils/helpers/getColorDependsOnBackground';
import Testing from '../mainTemplateComponents/Testing';
import Viability from '../mainTemplateComponents/Viability';
import Feasibility from '../mainTemplateComponents/Feasibility';
import Desirability from '../mainTemplateComponents/Desirability';
import CompetitorAnalysis from '../mainTemplateComponents/CompetitorAnalysis';
import CustomerRelationships from '../mainTemplateComponents/CustomerRelationships';
import KeyActivities from '../mainTemplateComponents/KeyActivities';
import Finance from '../mainTemplateComponents/Finance';
import Sustainability from '../mainTemplateComponents/Sustainability';

const { white, black } = COLORS;

const ColourfulFunTemplate = ({
  locales,
  portfolio,
  bmc,
  testing,
  disabled = false,
}) => {
  const {
    portfolioStyle: { templateSettings, activeTemplateName },
    portfolioTemplate: { collection },
  } = useSelector((state) => state);

  const currentSettings = templateSettings
    ? templateSettings[activeTemplateName]
    : {};
  const activeTemplate = find(collection, ['sysName', activeTemplateName]);
  const colorTemplate = getColorTemplate(
    templateSettings,
    activeTemplateName,
    activeTemplate,
  );

  if (!colorTemplate) {
    return null;
  }

  const [firstColor, secondColor, thirdColor, fourthColor] = colorTemplate;

  const isFourthColorPaletteNumber = !COLOR_PALETTE_ARRAY_COLOURFUL[firstColor];

  return (
    <div className="bmc-canvas margin-top-30">
      <div className="bmc-page fullwidth colourfulFun" style={{ padding: '0' }}>
        <StyledWrapper
          customtitlefont={currentSettings?.customTitleFont}
          customtitlecolor={currentSettings?.customTitleColor}
          custombodyfont={currentSettings?.customBodyFont}
          custombodycolor={currentSettings?.customBodyColor}
        >
          <Introduction
            locales={locales}
            data={portfolio.introduction}
            firstSvgColor={fourthColor}
            secondSvgColor={thirdColor}
            backgroundSection={`${thirdColor}1A`}
            templateName={COLOURFUL_TEMPLATE_NAME}
            disabled={disabled}
          />
          <Summary
            locales={locales}
            data={portfolio.projectSummary}
            firstSvgColor={thirdColor}
            secondSvgColor={secondColor}
            fileBackgroundColor={fourthColor}
            backgroundSection={`${thirdColor}1A`}
            colorStyle={fourthColor}
            isFourthColorPaletteNumber={isFourthColorPaletteNumber}
            templateName={COLOURFUL_TEMPLATE_NAME}
            disabled={disabled}
          />
          <Lazyload>
            <Team
              locales={locales}
              data={portfolio.team}
              colorTemplate={colorTemplate}
              mainSvgColor={`${thirdColor}1A`}
              firstSvgColor={firstColor}
              secondSvgColor={secondColor}
              templateName={COLOURFUL_TEMPLATE_NAME}
              disabled={disabled}
            />
          </Lazyload>
          {bmc && (
            <Lazyload>
              <BMC
                locales={locales}
                data={portfolio.bmc}
                className="bm-colourful-canvas section"
                sectionClassName="bmc-canvas-section--colourful"
                iconStyle="header-icon-colourful"
                textStyle="text-card-colourful"
                textWithImage="bmc-page-compcard-item-text-template bmc-page-compcard-item-text-template--with-image"
                iconFill={getColorDependsOnBackground(thirdColor)}
                iconFillClose={getColorDependsOnBackground(fourthColor)}
                firstSvgColor={thirdColor}
                secondSvgColor={fourthColor}
                backgroundMainSection={white}
                backgroundBMC={white}
                backgroundCanvas={white}
                backgroundIcon={thirdColor}
                backgroundItems={`${thirdColor}1A`}
                backgroundSection={white}
                borderSectionColor={thirdColor}
                styleColor={thirdColor}
                backgroundRow={white}
                backgroundImage={firstColor}
                titleStyle={TITLE_MODAL_STYLE_COLOURFUL}
                buttonStyle={BUTTON_MODAL_STYLE_COLOURFUL}
                templateName={COLOURFUL_TEMPLATE_NAME}
                disabled={disabled}
              />
            </Lazyload>
          )}

          {testing && (
            <Lazyload>
              <Testing
                locales={locales}
                data={portfolio.testing}
                className="testing testing-colourful section"
                textCardStyle="text-card-colourful"
                sectionHeaderStyle="testing-colourful-left"
                testListStyle="tests-list-colourful testing-colourful-right"
                textColor={black}
                firstSvgColor={firstColor}
                secondSvgColor={secondColor}
                backgroundSection={{ backgroundColor: `${thirdColor}1A` }}
                backgroundCard={`${thirdColor}1A`}
                rateColor={thirdColor}
                inputColor={secondColor}
                titleCardStyle={TITLE_MODAL_STYLE_COLOURFUL}
                buttonCardStyle={BUTTON_MODAL_STYLE_COLOURFUL}
                iconFillClose={getColorDependsOnBackground(secondColor)}
                templateName={COLOURFUL_TEMPLATE_NAME}
                disabled={disabled}
              />
            </Lazyload>
          )}
          {bmc && (
            <>
              <Desirability
                locales={locales}
                data={portfolio.desirabilitySection}
                className="desirability-colourful section"
                mainSvgColor={`${thirdColor}1A`}
                templateName={COLOURFUL_TEMPLATE_NAME}
                disabled={disabled}
              />
              <Lazyload>
                <CustomerSegmentColourful
                  locales={locales}
                  data={portfolio.customerSegment}
                  firstSvgColor={thirdColor}
                  secondSvgColor={fourthColor}
                  background={`${thirdColor}1A`}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <ValuePropositionColourful
                  locales={locales}
                  data={portfolio.valueProposition}
                  firstSvgColor={secondColor}
                  secondSvgColor={firstColor}
                  background={`${thirdColor}1A`}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <CompetitorAnalysis
                  locales={locales}
                  data={portfolio.competitorAnalysis}
                  className="competitor-analysis-colourful section"
                  firstSvgColor={firstColor}
                  secondSvgColor={`${thirdColor}1A`}
                  backgroundHeadTable={fourthColor}
                  colorTemplate={colorTemplate}
                  titleStyle={TITLE_MODAL_STYLE_COLOURFUL}
                  buttonStyle={BUTTON_MODAL_STYLE_COLOURFUL}
                  lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_COLOURFUL}
                  backgroundTable={white}
                  headTableColor={
                    isFourthColorPaletteNumber &&
                    getColorDependsOnBackground(fourthColor)
                  }
                  templateName={COLOURFUL_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <CustomerRelationships
                  locales={locales}
                  data={portfolio.customerRelationships}
                  className="customer-relationships-colourful section"
                  firstSvgColor={thirdColor}
                  secondSvgColor={secondColor}
                  tabColor={fourthColor}
                  firstGKGColor={thirdColor}
                  secondGKGColor={fourthColor}
                  backgroundSection={`${thirdColor}1A`}
                  textColor={black}
                  backgroundBlock={white}
                  templateName={COLOURFUL_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <ChannelsColourful
                  locales={locales}
                  data={portfolio.channels}
                  firstSvgColor={firstColor}
                  secondSvgColor={secondColor}
                  buttonBackgroundColor={thirdColor}
                  background={`${thirdColor}1A`}
                  isFourthColorPaletteNumber={isFourthColorPaletteNumber}
                  disabled={disabled}
                />
              </Lazyload>
              <Feasibility
                locales={locales}
                data={portfolio.feasibility}
                className="feasibility-colourful section"
                mainSvgColor={`${thirdColor}1A`}
                templateName={COLOURFUL_TEMPLATE_NAME}
                disabled={disabled}
              />
              <Lazyload>
                <KeyActivities
                  locales={locales}
                  data={portfolio.keyActivities}
                  className="key-activities-colourful section"
                  firstSvgColor={thirdColor}
                  secondSvgColor={fourthColor}
                  circleActivityColor={thirdColor}
                  circleResourcesColor={thirdColor}
                  lineActivityColor={thirdColor}
                  backgroundResources={`${thirdColor}1A`}
                  templateName={COLOURFUL_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
              <Viability
                locales={locales}
                data={portfolio.viability}
                className="viability-colourful section"
                mainSvgColor={`${thirdColor}1A`}
                templateName={COLOURFUL_TEMPLATE_NAME}
                disabled={disabled}
              />
              <Lazyload>
                <Finance
                  locales={locales}
                  data={portfolio.finance}
                  className="portfolio-finance-colourful portfolio-finance-colourful-temp section"
                  firstSvgColor={secondColor}
                  secondSvgColor={thirdColor}
                  backgroundUnitSvg={fourthColor}
                  firstChartColor={firstColor}
                  secondChartColor={thirdColor}
                  borderCardColor={firstColor}
                  borderVerticalTableUnitColor={firstColor}
                  backgroundRow={`${firstColor}1A`}
                  backgroundForecastTable={firstColor}
                  totalColor={firstColor}
                  borderVerticalTableColor={firstColor}
                  backgroundChart={`${thirdColor}1A`}
                  templateName={COLOURFUL_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <Sustainability
                  locales={locales}
                  data={portfolio.sustainability}
                  className="sustainability-colourful section"
                  cardClassName="bmc-page-suscard-colourful bmc-page-suscard--section"
                  sliderStyles="sdg-card-colourful"
                  iconTableStyles="sdg-card-colourful-icon"
                  borderCardColor={thirdColor}
                  iconFill={thirdColor}
                  iconFillTable={thirdColor}
                  firstSvgColor={fourthColor}
                  secondSvgColor={`${thirdColor}1A`}
                  backgroundTable={thirdColor}
                  backgroundCells={white}
                  backgroundSliderColor={white}
                  backgroundIcon={white}
                  isFourthColorPaletteNumber={isFourthColorPaletteNumber}
                  templateName={COLOURFUL_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
            </>
          )}
          <ContactUsColourful
            locales={locales}
            data={portfolio.contactUs}
            background={firstColor}
            backgroundButton={fourthColor}
            isFourthColorPaletteNumber={isFourthColorPaletteNumber}
            disabled={disabled}
          />
        </StyledWrapper>
      </div>
    </div>
  );
};

export default ColourfulFunTemplate;
