import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import Img from '../../../components/Img';
import Arrows from '../../../components/Arrows';
import Idea from './Idea';

const FIRST_QUESTION = 0;
const LAST_QUESTION = 2;

export default function TheeIdeasSelector({ data, className }) {
  const {
    ideation: {
      idea: {
        data: ideaData,
        data: { ideas },
      },
    },
  } = useSelector((state) => state);

  const {
    ideation: { updateIdeaData },
  } = useDispatch();

  const { question, ofWord, questionsList } = data;
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const currentQuestionTitle = questionsList[currentQuestion];
  const mostKeys = ['mostEasiestId', 'mostExcitedId', 'mostImpactId'];
  const mostValues = mostKeys.map((e) => ideaData[e]);
  const mostKey = mostKeys[currentQuestion];

  const renderSelectedItem = (item) => {
    const idea = ideas.find((e) => e.id === ideaData[item]);

    return (
      <div className="ideation-selected-item-image">
        <Img src={idea ? idea.url : ''} aspectRatio={1} />
      </div>
    );
  };

  const selectIdea = (idea) => {
    updateIdeaData({ [mostKey]: idea.id });
    return (
      currentQuestion < LAST_QUESTION && setCurrentQuestion(currentQuestion + 1)
    );
  };

  const isIdeaSelected = (idea) => {
    return ideaData[mostKey] === idea.id;
  };

  const isIdeaWasSelectedForOtherQuestions = (idea) => {
    return ideaData[mostKey] !== idea.id && mostValues.includes(idea.id);
  };

  const title = (idea) => {
    return data[mostKeys[mostValues.findIndex((e) => e === idea.id)]];
  };

  const prevPage = () => {
    setCurrentQuestion(
      currentQuestion === FIRST_QUESTION
        ? currentQuestion
        : currentQuestion - 1,
    );
  };

  const nextPage = () => {
    setCurrentQuestion(
      currentQuestion === LAST_QUESTION ? currentQuestion : currentQuestion + 1,
    );
  };

  return (
    <div className={className}>
      <div className="ideation-selector">
        <div className="ideation-selector-header">
          <div className="ideation-selector-header-subtitle">
            {question} {currentQuestion + 1} {ofWord} {questionsList.length}
          </div>
          <div className="ideation-selector-header-steps">
            {questionsList.map((_, index) => (
              <div
                className={classNames('ideation-selector-header-steps-step', {
                  'ideation-selector-header-steps-step--active':
                    index <= currentQuestion,
                })}
                key={index}
                onClick={() => setCurrentQuestion(index)}
              />
            ))}
            <Arrows
              onLeftClick={prevPage}
              onRightClick={nextPage}
              leftActive={currentQuestion > FIRST_QUESTION}
              rightActive={currentQuestion < LAST_QUESTION}
              className="ideation-selector-header-arrows"
            />
          </div>
          <div className="ideation-selector-title">{currentQuestionTitle}</div>

          <div className="ideation-selector-ideas">
            {ideas.map((idea, index) => (
              <Idea
                key={index}
                selected={isIdeaSelected(idea)}
                wasSelected={isIdeaWasSelectedForOtherQuestions(idea)}
                onClick={() => selectIdea(idea)}
                idea={idea}
                title={title(idea)}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="ideation-selected margin-top-20">
        {mostKeys.map((item, index) => (
          <div className="ideation-selected-item" key={index}>
            <div className="ideation-selected-item-title">{data[item]}</div>

            {renderSelectedItem(item)}
          </div>
        ))}
      </div>
    </div>
  );
}
