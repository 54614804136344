import React, { useState } from 'react';
import classNames from 'classnames';

import CardTable from './CardTable';
import {
  COST_CARD_TABLE_COLLECTION_EDIT_SETTINGS,
  PRODUCT_CARD_TABLE_COLLECTION_EDIT_SETTINGS,
} from '../../utils/const';
import Forecast from '../portfolio/Finance/Forecast';

export default function Card({
  editMode = false,
  className,
  card,
  locales,
  forecast,
}) {
  const {
    title,
    inputs,
    forecasts,
    costName,
    fixedCost,
    period,
    costPerUnit,
    unitCost,
    unit,
    productName,
    costs,
    products,
  } = card;
  const [currentTab, setCurrentTab] = useState('inputs');

  return (
    <div className={classNames('bmc-page-fincard', className)}>
      {title && (
        <div className="bmc-page-card-row">
          <img src="/images/rxbar.png" className="bmc-page-card-icon" alt="" />
          <div className="bmc-page-card-big-title">{title}</div>
        </div>
      )}
      <div className="bmc-page-fincard-body">
        {forecast && (
          <div className="bmc-page-fincard-header">
            <div
              className={classNames('bmc-page-fincard-header-tab', {
                'bmc-page-fincard-header-tab--active': currentTab === 'inputs',
              })}
              onClick={() => setCurrentTab('inputs')}
            >
              {inputs}
            </div>

            <div
              className={classNames('bmc-page-fincard-header-tab', {
                'bmc-page-fincard-header-tab--active':
                  currentTab === 'forecasts',
              })}
              onClick={() => setCurrentTab('forecasts')}
            >
              {forecasts}
            </div>
          </div>
        )}

        {currentTab === 'inputs' && (
          <>
            <CardTable
              title={fixedCost}
              className="margin-bottom-10"
              titles={[costName, fixedCost, period]}
              editMode={editMode}
              collection={costs}
              collectionEditSettings={COST_CARD_TABLE_COLLECTION_EDIT_SETTINGS}
            />

            <CardTable
              title={costPerUnit}
              titles={[productName, unitCost, unit]}
              editMode={editMode}
              collection={products}
              collectionEditSettings={
                PRODUCT_CARD_TABLE_COLLECTION_EDIT_SETTINGS
              }
            />
          </>
        )}

        {forecast && currentTab === 'forecasts' && (
          <div className="portfolio-finance">
            <Forecast
              locales={locales.portfolio}
              setTotalCashOut={(_) => _}
              setTotalCashIn={(_) => _}
              summaryMode
            />
          </div>
        )}
      </div>
    </div>
  );
}
