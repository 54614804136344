import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import useMediaQuery from 'react-use-media-query-hook';

import Img from '../../components/Img';
import Icon from '../../components/Icon';
import Textarea from '../../components/Textarea';
import { STRATEGIES_MAX_COUNT } from '../../utils/const';
import ConfirmationModal from '../../components/ConfirmationModal';

const renderBlock = (
  items,
  editMode,
  currentTab,
  updateStrategy,
  removeStrategy,
) => {
  return items.map((item, index) => (
    <div key={index} className="bmc-page-gkgtabs-content-block">
      {editMode ? (
        <Textarea
          value={item.name}
          delay={700}
          className="bmc-form-textarea custom-font-body"
          onChange={(e) =>
            updateStrategy({
              index,
              name: 'name',
              value: e.target.value,
              collectionName: `${currentTab}Strategies`,
            })
          }
        />
      ) : (
        <div className="bmc-page-gkgtabs-content-block-title custom-font-body">
          {item.name}
        </div>
      )}

      {editMode ? (
        <Textarea
          value={item.description}
          delay={700}
          className="bmc-form-textarea custom-font-body"
          onChange={(e) =>
            updateStrategy({
              index,
              name: 'description',
              value: e.target.value,
              collectionName: `${currentTab}Strategies`,
            })
          }
        />
      ) : (
        <div className="bmc-page-gkgtabs-content-block-text custom-font-body">
          {item.description}
        </div>
      )}

      {editMode && items.length > 1 && (
        <ConfirmationModal
          body="Delete this content. Are you sure?"
          confirmText="Delete"
          onConfirm={() =>
            removeStrategy({
              index,
              collectionName: `${currentTab}Strategies`,
            })
          }
        >
          <div className="bmc-page-gkgtabs-content-block-remove">
            <i className="icon-Delete" />
          </div>
        </ConfirmationModal>
      )}
    </div>
  ));
};

export default function GKGTabs({
  content,
  className,
  showIcons = true,
  editMode = false,
  portfolioWebMode = false,
  content: {
    icon,
    title,
    getStrategies,
    keepStrategies,
    growStrategies,
    referralStrategies,
  },
}) {
  const isMobile = useMediaQuery('(max-width: 480px)');
  const [tab, setTab] = useState(0);
  const {
    getKeepGrow: { addStrategy, updateStrategy, removeStrategy },
  } = useDispatch();
  const tabs = ['get', 'keep', 'grow', 'referral'];
  const data = [
    getStrategies,
    keepStrategies,
    growStrategies,
    referralStrategies,
  ];
  const items = data[tab];
  const currentTab = tabs[tab];

  return (
    <div
      className={classNames('bmc-page-gkgtabs', className, {
        'bmc-page-gkgtabs--portfolio-web': portfolioWebMode,
      })}
    >
      {title && (
        <div className="bmc-page-gkgtabs-header">
          <div className="bmc-page-gkgtabs-header-icon">
            <Img src={icon} />
          </div>
          <div className="bmc-page-gkgtabs-header-title custom-font-title">
            {title}
          </div>
        </div>
      )}

      <div className="bmc-page-gkgtabs-tabs">
        {tabs.map((tabName, index) => (
          <div
            key={index}
            className={classNames('bmc-page-gkgtabs-tabs-tab', {
              'bmc-page-gkgtabs-tabs-tab--active': tab === index,
            })}
            onClick={() => setTab(index)}
          >
            {showIcons && (
              <div className="bmc-page-gkgtabs-tabs-tab-icon">
                <Icon className={`icon-${tabName}`} pathCount={4} />
              </div>
            )}
            <div className="bmc-page-gkgtabs-tabs-tab-title custom-font-body">
              {content[tabName]}
            </div>
          </div>
        ))}
      </div>

      <div className="bmc-page-gkgtabs-content">
        {portfolioWebMode && isMobile ? (
          <>
            <div className="bmc-page-gkgtabs-content-title custom-font-body">
              Get
            </div>
            {renderBlock(
              data[0],
              editMode,
              currentTab,
              updateStrategy,
              removeStrategy,
            )}
            <div className="bmc-page-gkgtabs-content-title custom-font-body">
              Keep
            </div>
            {renderBlock(
              data[1],
              editMode,
              currentTab,
              updateStrategy,
              removeStrategy,
            )}
            <div className="bmc-page-gkgtabs-content-title custom-font-body">
              Grow
            </div>
            {renderBlock(
              data[2],
              editMode,
              currentTab,
              updateStrategy,
              removeStrategy,
            )}
            <div className="bmc-page-gkgtabs-content-title custom-font-body">
              Referrals
            </div>
            {renderBlock(
              data[3],
              editMode,
              currentTab,
              updateStrategy,
              removeStrategy,
            )}
          </>
        ) : (
          renderBlock(
            items,
            editMode,
            currentTab,
            updateStrategy,
            removeStrategy,
          )
        )}

        {editMode && items.length < STRATEGIES_MAX_COUNT && (
          <div
            className="bmc-page-gkgtabs-content-add"
            onClick={() =>
              addStrategy({ collectionName: `${tabs[tab]}Strategies` })
            }
          >
            <i className="icon-Add" />
            {content.addNewStrategy}
          </div>
        )}
      </div>
    </div>
  );
}
