import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import Avatars from '../../components/Avatars';
import Table from '../../components/Table';
import TableHeader from '../../components/TableHeader';

export const columns = [
  {
    title: 'Project',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Team member',
    key: 'teamMembers',
    render: (row) => {
      const images = row.teamMembers.map((item) => {
        const fullName = `${item.firstName} ${item.surname}`;
        return {
          alt: fullName,
          url: item.image.icon.url,
          fullName,
        };
      });
      return <Avatars images={images} />;
    },
  },
  {
    title: 'Class',
    dataIndex: 'classroomName',
    key: 'classroomName',
  },
  {
    title: 'Ideas Created',
    dataIndex: 'ideasCreated',
    key: 'ideasCreated',
    render: (ideasCreated) => ideasCreated || 0,
  },
  {
    title: 'Canvas Progress',
    dataIndex: 'canvasProgress',
    key: 'canvasProgress',
    render: (canvasProgress) => canvasProgress || '0%',
  },
  {
    title: 'Tests Completed',
    dataIndex: 'testsCreated',
    key: 'testsCreated',
    render: (testsCreated) => testsCreated || 0,
  },
  {
    title: 'Activity Total',
    dataIndex: 'sumTimeTracks',
    key: 'sumTimeTracks',
    render: (sumTimeTracks) => sumTimeTracks || 0,
  },
  {
    title: 'Last 30 days',
    dataIndex: 'sumLastTimeTracks',
    key: 'sumLastTimeTracks',
    render: (sumLastTimeTracks) => sumLastTimeTracks || 0,
  },
  {
    title: 'Portfolio Comments',
    dataIndex: 'commentsTotal',
    key: 'commentsTotal',
    render: (commentsTotal) => commentsTotal || 0,
  },
  {
    title: 'Last Updated',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (updatedAt) => {
      moment.locale('en');
      return moment(new Date(updatedAt)).format('DD MMM YYYY');
    },
  },
];

const ProjectLists = ({
  byId,
  by,
  establishmentId,
  rowClick,
  rowClickable = false,
}) => {
  const [search, setSearch] = useState(undefined);

  const {
    bmcs: { data, meta, loading },
    pagination: { pageSize: initPageSize },
  } = useSelector((state) => state);

  const {
    bmcs: { fetchBmcs, sortBmcs },
  } = useDispatch();

  useEffect(() => {
    fetchBmcs({ byId, by, search, limit: initPageSize, establishmentId });
  }, [byId, by, search, initPageSize, establishmentId]);

  return (
    <>
      <TableHeader
        title="Projects"
        count={loading ? <Spin /> : meta?.count || 0}
        onSearch={(searchValue) => {
          setSearch(searchValue);
        }}
        searchLoading={loading}
      />
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        total={meta?.count || 0}
        current={meta?.page || 1}
        onChange={(pagination) => {
          const { current, pageSize } = pagination;
          fetchBmcs({
            byId,
            by,
            establishmentId,
            search,
            page: current,
            limit: pageSize,
          });
        }}
        onRow={(record) => ({ onClick: () => rowClick(record) })}
        rowClickable={rowClickable}
        onSort={sortBmcs}
        meta={meta}
      />
    </>
  );
};

ProjectLists.propTypes = {
  byId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  by: PropTypes.oneOf(['users', 'classrooms', 'establishments']),
  rowClick: PropTypes.func,
  rowClickable: PropTypes.bool,
};
export default ProjectLists;
