import React from 'react';

import TeamCard from './TeamCard';
import {
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  COMMENT_TEAM,
} from '../../../../utils/const';
import SectionHeader from '../../../../components/SectionHeader';
import PortfolioCommentIcon from '../../../../components/PortfolioCommentIcon';
import { getColorDependsOnBackgroundAndPalette } from '../../../../utils/helpers/portfolioArchTemplate/getColorDependsOnBackgroundAndPalette';

const TeamArch = ({
  data: { description },
  locales,
  locales: { portfolio },
  collection,
  addPortfolioTeammate,
  updateSectionField,
  backgroundSection,
  backgroundImage,
  backgroundModal,
  colorPaletteNumber,
  disabled = false,
}) => {
  const textColor = getColorDependsOnBackgroundAndPalette(
    colorPaletteNumber,
    backgroundSection,
  );
  return (
    <div
      className="team-architectural section relative"
      style={{ backgroundColor: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_TEAM} />
      <SectionHeader
        templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
        title={portfolio.team.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'team',
            field: 'description',
            name: 'teamDescription',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        style={{ color: textColor }}
        descriptionColor={textColor}
        disabled={disabled}
      />
      <div className="team-architectural-cards custom-font-body">
        {collection.map((teammate, i) => {
          return (
            <TeamCard
              locales={locales}
              teammate={teammate}
              index={i}
              key={teammate.id}
              backgroundTeam={backgroundImage}
              backgroundModal={backgroundModal}
              textColor={textColor}
              disabled={disabled}
            />
          );
        })}
        {!disabled && (
          <div className="add-team-card-architectural__wrapper custom-font-body">
            <div className="add-team-card-architectural">
              <div className="add-team-card-architectural-hover">
                <div className="center-block" onClick={addPortfolioTeammate}>
                  <i className="icon-Add" />
                  {portfolio.team.add}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamArch;
