import React, { useContext, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import CommentItemComponent from './comment-item-component';
import isElementVisible from '../../../utils/isElementVisible';

import { CommentContext } from '../context';
import CommentItemReplyContainer from './comment-item-reply-container';

export default function CommentItemContainer({
  comment,
  members,
  isLastComment = false,
  nextComment,
}) {
  const childrenEl = useRef(null);

  const {
    chatGroup: { updateChatGroupMemberActiveAt, removeComment },
  } = useDispatch();

  const {
    commentState: {
      scrollStopAt,
      parentElement,
      currentMember,
      activeAt,
      savedActiveAt,
    },
    setCommentState,
  } = useContext(CommentContext);

  const handleClickReply = (_comment) =>
    setCommentState((currentCommentState) => ({
      ...currentCommentState,
      replyComment: _comment,
    }));

  const handleUpdateLastActivity = () => {
    if (
      currentMember &&
      (currentMember.activeAt === null ||
        moment(currentMember.activeAt).isBefore(moment(comment.createdAt)))
    )
      updateChatGroupMemberActiveAt({
        chatGroupCommentId: comment.id,
        chatGroupId: comment.chatGroupId,
      });
  };

  const handleClickDelete = (_comment) => {
    removeComment({ id: _comment.id });
  };

  const handleClickEdit = (_comment) => {
    setCommentState((currentCommentState) => ({
      ...currentCommentState,
      editComment: _comment,
    }));
  };

  useEffect(() => {
    if (parentElement && isElementVisible(parentElement, childrenEl.current)) {
      handleUpdateLastActivity();
    }
  }, [scrollStopAt]);

  const checkingActiveAt = savedActiveAt || activeAt;

  const isSenderMe = comment.senderId === currentMember?.userId;
  const wasLastActivityInPast =
    checkingActiveAt !== null &&
    moment(checkingActiveAt).isBefore(moment(comment.createdAt));
  const shouldShowAsNewMessage = !isSenderMe && wasLastActivityInPast;

  const isNextSenderMe = nextComment?.senderId === currentMember?.userId;
  const isThisCommentLastReaded =
    checkingActiveAt !== null &&
    moment(checkingActiveAt).isBefore(moment(nextComment?.createdAt));
  const shouldShowNewMessageLabel =
    !shouldShowAsNewMessage &&
    !isLastComment &&
    !isNextSenderMe &&
    isThisCommentLastReaded;

  return (
    <>
      <div
        className={classNames('comment-item-container', {
          'status-new': shouldShowAsNewMessage,
        })}
        ref={childrenEl}
      >
        <CommentItemComponent
          comment={comment}
          members={members}
          onReply={handleClickReply}
          onDelete={handleClickDelete}
          onEdit={handleClickEdit}
          currentUserId={currentMember?.userId}
        />
        {comment.replies && (
          <div className="comment-item-container-reply">
            {comment.replies.map((replyComment) => (
              <CommentItemReplyContainer
                replyComment={replyComment}
                replyCommentId={replyComment.id}
                currentUserId={currentMember?.userId}
                members={members}
                key={replyComment.id}
                parentElement={parentElement}
                currentMember={currentMember}
                onDelete={handleClickDelete}
                onEdit={handleClickEdit}
              />
            ))}
          </div>
        )}
      </div>
      <div
        className={classNames('comment-item-not-readed', {
          hide: !shouldShowNewMessageLabel,
        })}
      >
        <div
          className="comment-item-not-readed__text"
          id={`comment-item-not-readed-${comment.id}`}
        >
          New message
        </div>
      </div>
    </>
  );
}
