import React, { useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import useMediaQuery from 'react-use-media-query-hook';

import UnitEconomicsTab from '../../main/components/FinanceTabs/UnitEconomicsTab';
import GraphTab from '../../main/components/FinanceTabs/GraphTab';
import ForecastTab from '../../main/components/FinanceTabs/ForecastTab';
import { CURRENT_TEMPLATE_NAME } from '../../main/utils/const';
import { getCurrentCanvasIcon } from '../../main/utils/helpers/getCurrentCanvasIcon';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';

const UnitEconomics = 0;
const Forecast = 1;
const Graph = 2;

const Finance = ({
  locales: { portfolio, portfolioWeb },
  className,
  sectionHeaderTitleColor,
  descriptionColor,
  backgroundRow,
  borderVerticalTableColor,
  backgroundForecastTable,
  borderCardColor,
  backgroundUnitSvg,
  borderVerticalTableUnitColor,
  totalColor,
  styleColor,
  tabColor,
  activeTabColor,
  firstChartColor,
  secondChartColor,
  firstSvgColor,
  secondSvgColor,
  separatorColor,
  backgroundChart,
  titleUnitColor,
  secondTitleUnitColor,
  titleForecastColor,
  secondTitleForecast,
  textForecastColor,
  titleChartColor,
  backgroundUnitTable,
  backgroundSection,
  tableTitleUnitColor,
  textUnitColor,
  textChartColor,
  templateName,
}) => {
  const [totalCashIn, setTotalCashIn] = useState([]);
  const [totalCashOut, setTotalCashOut] = useState([]);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [activeTab, setActiveTab] = useState(UnitEconomics);
  const { finance } = useSelector((state) => state.portfolio);

  const tabs = {
    [UnitEconomics]: (
      <UnitEconomicsTab
        locales={portfolio}
        isPreview
        templateName={templateName}
        separatorColor={separatorColor}
        titleColor={titleUnitColor}
        secondTitleColor={secondTitleUnitColor}
        backgroundSvg={backgroundUnitSvg}
        borderCardColor={borderCardColor}
        verticalBorderColor={borderVerticalTableUnitColor}
        backgroundRow={backgroundRow}
        backgroundTable={backgroundUnitTable}
        tableTitleColor={tableTitleUnitColor}
        textColor={textUnitColor}
      />
    ),
    [Forecast]: (
      <ForecastTab
        locales={portfolio}
        setTotalCashIn={setTotalCashIn}
        setTotalCashOut={setTotalCashOut}
        backgroundTable={backgroundForecastTable}
        backgroundRow={backgroundRow}
        totalColor={totalColor}
        firstSvgColor={firstSvgColor}
        secondSvgColor={secondSvgColor}
        borderCardColor={borderCardColor}
        borderVerticalTableColor={borderVerticalTableColor}
        titleColor={titleForecastColor}
        secondTitleColor={secondTitleForecast}
        textColor={textForecastColor}
        templateName={templateName}
      />
    ),
    [Graph]: (
      <GraphTab
        locales={portfolio}
        totalCashIn={totalCashIn}
        totalCashOut={totalCashOut}
        firstChartColor={firstChartColor}
        secondChartColor={secondChartColor}
        background={backgroundChart}
        borderCardColor={borderCardColor}
        titleColor={titleChartColor}
        textColor={textChartColor}
        templateName={templateName}
      />
    ),
  };
  const allTabs = [tabs[UnitEconomics], tabs[Forecast], tabs[Graph]];
  const currentTab = tabs[activeTab];

  const isCurrentTemplate = templateName === CURRENT_TEMPLATE_NAME;

  return (
    <div className={className} style={{ backgroundColor: backgroundSection }}>
      {isCurrentTemplate && getCurrentCanvasIcon('FinanceCanvas', styleColor)}
      <SectionHeaderWeb
        templateName={templateName}
        title={portfolioWeb.finance.title}
        description={finance.description}
        className={isCurrentTemplate && 'violet-title'}
        style={{ color: sectionHeaderTitleColor }}
        descriptionColor={descriptionColor}
      />

      <div className="tabs-wrapper">
        <div
          className={classnames('tab', 'custom-font-body', {
            active: activeTab === UnitEconomics,
          })}
          onClick={() => setActiveTab(UnitEconomics)}
          style={{
            color: activeTab === UnitEconomics ? activeTabColor : tabColor,
          }}
        >
          {portfolio.finance.tabName}
        </div>
        <div
          className={classnames('tab', 'custom-font-body', {
            active: activeTab === Forecast,
          })}
          onClick={() => setActiveTab(Forecast)}
          id="docx-forecast-tab"
          style={{
            color: activeTab === Forecast ? activeTabColor : tabColor,
          }}
        >
          {portfolio.finance.forecast.title}
        </div>
        <div
          className={classnames('tab', 'custom-font-body', {
            active: activeTab === Graph,
          })}
          onClick={() => setActiveTab(Graph)}
          id="docx-graph-tab"
          style={{
            color: activeTab === Graph ? activeTabColor : tabColor,
          }}
        >
          {portfolio.finance.chart.title}
        </div>
      </div>
      {isMobile ? allTabs : currentTab}
    </div>
  );
};

export default Finance;
