import React from 'react';
import { useDispatch } from 'react-redux';
import CommentAppBarComponent from './comment-app-bar-component';

export default function CommentAppBarContainer() {
  const {
    chatGroup: { updateChatGroupAttribute },
  } = useDispatch();

  const handleClickExpand = () => {
    updateChatGroupAttribute({ commentIsOpened: false });
  };

  const handleClickClose = () => {
    updateChatGroupAttribute({
      commentIsOpened: false,
      isExpanded: false,
    });
  };

  return (
    <div className="comment-app-bar-container">
      <CommentAppBarComponent
        onClickClose={handleClickClose}
        onClickExpand={handleClickExpand}
      />
    </div>
  );
}
