import React from 'react';
import classNames from 'classnames';
import { FIRST_COLOR_PALETTE_NUMBER, COLORS } from '../../main/utils/const';

const { white } = COLORS;

const ContactUsTechWeb = ({
  locales: { portfolioWeb },
  data: { title, emailOrWebsite, description, fullName },
  backgroundSection,
  colorPaletteNumber,
}) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const isWhiteText = colorPaletteNumber !== FIRST_COLOR_PALETTE_NUMBER;

  return (
    <div
      className="contact-us-tech-web"
      style={{ background: backgroundSection }}
    >
      <div className="header">
        <div
          className={classNames('tech-title title custom-font-title', {
            'text-white': isWhiteText,
          })}
        >
          {portfolioWeb.contactUs.title}
        </div>
        <div>
          <div
            className={classNames('scroll-top custom-font-body', {
              'text-white': isWhiteText,
            })}
            onClick={scrollToTop}
          >
            {portfolioWeb.contactUs.scrollTop}
            <i
              className="icon-Arrow-Top"
              style={{ borderColor: isWhiteText && white }}
            />
          </div>
        </div>
      </div>
      <div className="fields">
        <div
          className={classNames('description custom-font-body', {
            'text-white': isWhiteText,
          })}
        >
          {description}
        </div>
        <div
          className={classNames('form-field', {
            'text-white': isWhiteText,
          })}
        >
          {fullName}
        </div>
        <div
          className={classNames('form-field email', {
            'text-white': isWhiteText,
          })}
        >
          {emailOrWebsite}
        </div>
      </div>

      <div
        className={classNames('footer custom-font-body', {
          'text-white': isWhiteText,
        })}
        style={{ borderColor: isWhiteText && white }}
      >
        <div className="left ">
          {`© ${title}. ${portfolioWeb.contactUs.rightsReserve}`}
        </div>
        <div className="right">
          {portfolioWeb.contactUs.buildCreate}
          <span className="company-name">
            {portfolioWeb.contactUs.companyName}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactUsTechWeb;
