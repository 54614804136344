import React from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';

import Homepage from './Homepage';
import Research from './Research';
import Survey from './Survey';
import Interview from './Interview';
import Prototype from './Prototype';
import Sell from './Sell';
import * as AppRoute from '../../../../../utils/appRoutes';
import { nextComponentHandlerFunction } from '../../../../../utils/helpers/nextComponentHandlerFunction';

const TestPlan = ({
  nextClickHandler,
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
  locales,
  goToHandler,
}) => {
  const {
    bmc: { view },
    testing: { test },
  } = useSelector((state) => state);

  const history = useHistory();
  const { bmcId: bmcIdFromParams } = useParams();
  const nextPath = AppRoute.testing(bmcIdFromParams);
  const onDone = () =>
    history.push(nextComponentHandlerFunction(view, nextPath));
  const nextClick = () => nextClickHandler(true);
  const selectedTest = test.test;

  if (selectedTest === 'homepage')
    return (
      <Homepage
        locales={locales}
        onDone={onDone}
        goToHandler={goToHandler}
        testObject={test}
        nextClickHandler={nextClick}
        menuItems={menuItems}
        selectComponent={selectComponent}
        componentIndex={componentIndex}
        rules={rules}
        backPath={backPath}
      />
    );
  if (selectedTest === 'research')
    return (
      <Research
        locales={locales}
        onDone={onDone}
        goToHandler={goToHandler}
        test={test}
        nextClickHandler={nextClick}
        menuItems={menuItems}
        selectComponent={selectComponent}
        componentIndex={componentIndex}
        rules={rules}
        backPath={backPath}
      />
    );
  if (selectedTest === 'survey')
    return (
      <Survey
        test={test}
        locales={locales}
        onDone={onDone}
        nextClickHandler={nextClick}
        menuItems={menuItems}
        selectComponent={selectComponent}
        componentIndex={componentIndex}
        rules={rules}
        backPath={backPath}
      />
    );
  if (selectedTest === 'interview')
    return (
      <Interview
        test={test}
        locales={locales}
        onDone={onDone}
        nextClickHandler={nextClick}
        menuItems={menuItems}
        selectComponent={selectComponent}
        componentIndex={componentIndex}
        rules={rules}
        backPath={backPath}
      />
    );
  if (selectedTest === 'prototype')
    return (
      <Prototype
        test={test}
        locales={locales}
        onDone={onDone}
        nextClickHandler={nextClick}
        menuItems={menuItems}
        selectComponent={selectComponent}
        componentIndex={componentIndex}
        rules={rules}
        backPath={backPath}
      />
    );
  if (selectedTest === 'sell')
    return (
      <Sell
        test={test}
        locales={locales}
        onDone={onDone}
        nextClickHandler={nextClick}
        menuItems={menuItems}
        selectComponent={selectComponent}
        componentIndex={componentIndex}
        rules={rules}
        backPath={backPath}
      />
    );
  return null;
};

export default TestPlan;
