import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { merge, find, pick, reduce, sortBy } from 'lodash';
import classNames from 'classnames';

import { SELECT_STYLES, TEMPLATE_FONTS } from '../../../utils/const';
import SvgIcon from '../../../components/SvgIcon';

const TemplateFonts = ({
  templateSettings,
  settings,
  activeTemplate,
  disabled = false,
}) => {
  const customHeadColor = settings?.customTitleColor;
  const customBodyColor = settings?.customBodyColor;

  const activeTitleFont =
    settings?.customTitleFont || activeTemplate?.titleFont;
  const activeBodyFont = settings?.customBodyFont || activeTemplate?.bodyFont;

  const [isDisabled, setIsDisabled] = useState(true);

  const {
    portfolioStyle: { updatePortfolioStyleSettings },
  } = useDispatch();

  useEffect(() => {
    if (templateSettings && templateSettings[activeTemplate.sysName]) {
      const templateStyles = Object.keys(
        templateSettings[activeTemplate.sysName],
      );
      templateStyles.includes('customBodyFont') ||
      templateStyles.includes('customTitleFont') ||
      templateStyles.includes('customBodyColor') ||
      templateStyles.includes('customTitleColor')
        ? setIsDisabled(false)
        : setIsDisabled(true);
    }
  }, [templateSettings]);

  const handleUpdateSettings = (e, settingType) => {
    let value;
    if (['customBodyFont', 'customTitleFont'].includes(settingType))
      value = e.value;
    else value = e.currentTarget.value;

    const data = {};
    data[activeTemplate.sysName] = {};
    data[activeTemplate.sysName][settingType] = value;

    updatePortfolioStyleSettings(merge(templateSettings, data));
  };

  const handleClickResetSettings = () => {
    const res = reduce(
      templateSettings,
      (result, value, key) => {
        if (key === activeTemplate.sysName) {
          result[key] = pick(templateSettings[activeTemplate.sysName], [
            'colorPalette',
            'customColors',
          ]);
        } else result[key] = value;

        return result;
      },
      {},
    );
    updatePortfolioStyleSettings(res);
  };

  const optionStyles = {
    option: (styles, { data }) => {
      return {
        ...styles,
        fontFamily: data.value,
      };
    },
  };

  const sortedFontsList = sortBy(TEMPLATE_FONTS, ['value']);

  return (
    <div className="templates-content--right">
      <div className="main-text">
        <div className="main-text--head">
          Style template
          <button
            onClick={handleClickResetSettings}
            type="button"
            disabled={isDisabled || disabled}
            className={classNames('reset-button', {
              disable: isDisabled,
            })}
          >
            Reset to default
          </button>
        </div>
      </div>
      <div className="templates-divider">
        <div className="templates-space">
          <div className="templates-content-box">
            <div className="templates-content-text">
              <div className="templates-content-text-primary">Heading font</div>

              <Select
                className="font-select"
                styles={merge(SELECT_STYLES, optionStyles)}
                options={sortedFontsList}
                value={find(TEMPLATE_FONTS, ['value', activeTitleFont])}
                onChange={(e) => handleUpdateSettings(e, 'customTitleFont')}
                isDisabled={disabled}
              />
            </div>
          </div>
          <div className="templates-content-color-text">
            <div className="templates-content-text-secondary">Colors</div>
            <label
              htmlFor="customHeadFontColor"
              className="templates-colors-text-label"
            >
              {customHeadColor ? (
                <div
                  className="templates-content-color"
                  style={{
                    backgroundColor: customHeadColor,
                  }}
                />
              ) : (
                <div
                  className="templates-custom-add"
                  style={{ width: '115%', height: '115%' }}
                >
                  <SvgIcon name="addColor" />
                </div>
              )}
              <input
                type="color"
                className="templates-customColor-input"
                name="customHeadFontColor"
                id="customHeadFontColor"
                value={customHeadColor}
                onChange={(e) => handleUpdateSettings(e, 'customTitleColor')}
                disabled={disabled}
              />
            </label>
          </div>
        </div>
      </div>
      <div className="templates-space">
        <div className="templates-content-box">
          <div className="templates-content-text">
            <div className="templates-content-text-primary">Body font</div>
            <Select
              className="font-select"
              styles={merge(SELECT_STYLES, optionStyles)}
              options={sortedFontsList}
              value={find(TEMPLATE_FONTS, ['value', activeBodyFont])}
              onChange={(e) => handleUpdateSettings(e, 'customBodyFont')}
              isDisabled={disabled}
            />
          </div>
        </div>
        <div className="templates-content-color-text">
          <div className="templates-content-text-secondary">Colors</div>
          <label
            htmlFor="customBodyFontColor"
            className="templates-colors-text-label"
          >
            {customBodyColor ? (
              <div
                className="templates-content-color"
                style={{
                  backgroundColor: customBodyColor,
                }}
              />
            ) : (
              <div
                className="templates-custom-add"
                style={{ width: '115%', height: '115%' }}
              >
                <SvgIcon name="addColor" />
              </div>
            )}

            <input
              type="color"
              className="templates-customColor-input"
              name="customBodyFontColor"
              id="customBodyFontColor"
              value={customBodyColor}
              onChange={(e) => handleUpdateSettings(e, 'customBodyColor')}
              disabled={disabled}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default TemplateFonts;
