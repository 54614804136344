import React from 'react';
import { useDispatch } from 'react-redux';

import {
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  BLOG_FRIENDLY_TEMPLATE_NAME,
  BOLD_TEMPLATE_NAME,
  COLOURFUL_TEMPLATE_NAME,
  CURRENT_TEMPLATE_NAME,
} from '../../../utils/const';
import { getColorDependsOnBackground } from '../../../utils/helpers/getColorDependsOnBackground';
import { getColorDependsOnBackgroundSummary } from '../../../utils/helpers/portfolioCurrentTemplate/getColorDependsOnBackgroundSummary';
import SummaryCurrent from '../currentTemplate/SummaryCurrent';
import SummaryColourfulFun from '../colourfulFunTemplate/SummaryColourfulFun';
import SummaryBlog from '../blogFriendly/SummaryBlog';
import SummaryArch from '../architecturalMinimal/SummaryArch';
import SummaryBold from '../boldTemplate/SummaryBold';

const Summary = ({
  data,
  locales,
  backgroundSection,
  colorPaletteNumber,
  firstSvgColor,
  secondSvgColor,
  fileBackgroundColor,
  colorStyle,
  titleColor,
  textColor,
  fileTextColor,
  templateName,
  isFirstColorPallete = false,
  isFourthColorPaletteNumber = false,
  disabled = false,
}) => {
  const {
    portfolio: { updateSectionField },
  } = useDispatch();

  const textColorDependsOnBackground =
    isFourthColorPaletteNumber &&
    getColorDependsOnBackground(backgroundSection);

  const styleColor = getColorDependsOnBackgroundSummary(
    colorPaletteNumber,
    backgroundSection,
  );

  const summaryDependsOnTemplateName = {
    [CURRENT_TEMPLATE_NAME]: (
      <SummaryCurrent
        locales={locales}
        data={data}
        updateSectionField={updateSectionField}
        backgroundSection={backgroundSection}
        textColorDependsOnBackground={textColorDependsOnBackground}
        styleColor={styleColor}
        isFirstColorPallete={isFirstColorPallete}
        isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        disabled={disabled}
      />
    ),
    [COLOURFUL_TEMPLATE_NAME]: (
      <SummaryColourfulFun
        locales={locales}
        data={data}
        updateSectionField={updateSectionField}
        firstSvgColor={firstSvgColor}
        secondSvgColor={secondSvgColor}
        fileBackgroundColor={fileBackgroundColor}
        backgroundSection={backgroundSection}
        colorStyle={colorStyle}
        isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        disabled={disabled}
      />
    ),
    [BLOG_FRIENDLY_TEMPLATE_NAME]: (
      <SummaryBlog
        locales={locales}
        data={data}
        updateSectionField={updateSectionField}
        backgroundSection={backgroundSection}
        textColorDependsOnBackground={textColorDependsOnBackground}
        disabled={disabled}
      />
    ),
    [ARCHITECTURAL_MINIMAL_TEMPLATE_NAME]: (
      <SummaryArch
        locales={locales}
        data={data}
        updateSectionField={updateSectionField}
        backgroundSection={backgroundSection}
        textColorDependsOnBackground={textColorDependsOnBackground}
        disabled={disabled}
      />
    ),
    [BOLD_TEMPLATE_NAME]: (
      <SummaryBold
        locales={locales}
        data={data}
        updateSectionField={updateSectionField}
        titleColor={titleColor}
        textColor={textColor}
        colorStyle={colorStyle}
        backgroundSection={backgroundSection}
        fileTextColor={fileTextColor}
        disabled={disabled}
      />
    ),
  };

  return summaryDependsOnTemplateName[templateName];
};

export default Summary;
