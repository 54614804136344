import React from 'react';
import classNames from 'classnames';

import Img from './Img';

export default function Spinner({ className, big = false }) {
  return (
    <div className={classNames('spinner', className, { 'spinner--big': big })}>
      <Img src="/images/gifs/loader.webp" />
    </div>
  );
}
