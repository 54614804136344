import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Input from './Input';
import Button from '../../main/components/Button';
import initialValues from './initialFormValues';
import SignupSchema from './validationSchema';
import * as Route from '../../main/utils/serverRoutes';
import { ALERT_TYPES } from '../../main/utils/const';

const printErrors = (error) => {
  const {
    errors: { email, password },
    errors,
  } = error.response.data;
  let showErrors = [];
  if (email && email.length) showErrors.push(email.join(', '));
  if (password && password.length)
    showErrors.push('Password', password.join(', '));
  if (Array.isArray(errors)) showErrors = errors;
  return showErrors.join(' ');
};

const prepareValues = (
  { firstName, surname, email, password, projectName },
  { establishmentId },
) => {
  return {
    user: {
      firstName,
      surname,
      email,
      password,
    },
    bmc: {
      name: projectName,
    },
    establishmentId,
  };
};

const SignupForm = ({
  step,
  setStep,
  locales,
  data,
  locales: { onboarding: pageLocales },
}) => {
  const {
    notification: { addNotification },
    alert: { addAlert },
  } = useDispatch();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignupSchema}
      onSubmit={(values) => {
        axios
          .post(Route.signUp(), prepareValues(values, data))
          .then((response) => {
            let redirect = null;

            if (values.haveBusinessIdea) {
              redirect = `/bmcs/${response.data.data.bmcId}`;
            } else {
              redirect = `/bmcs/${response.data.data.bmcId}/ideation`;
            }

            if (data.classroomShareRedirect) {
              window.location = `${data.classroomShareRedirect}?redirect=${redirect}`;
            } else {
              window.location = redirect;
            }
          })
          .catch((error) => {
            setStep(1);
            if (error.response.status === 403) {
              addAlert({
                id: 403,
                type: ALERT_TYPES.warning,
                title: error.response.data.errors.join(' '),
              });
            } else {
              addNotification({ text: printErrors(error) });
            }
          });
      }}
    >
      {({ values: { projectName }, errors, setFieldValue, submitForm }) => {
        const isDisabledCreateAccount = !(
          !errors.firstName &&
          !errors.surname &&
          !errors.email &&
          !errors.password
        );
        const completeForm = (haveBusinessIdea) => {
          setFieldValue('haveBusinessIdea', haveBusinessIdea);
          submitForm();
        };

        return (
          <Form className="sign-up-form">
            {step === 1 && (
              <>
                <h3 className="sign-up-form__title">
                  {pageLocales.titles.step1}
                </h3>
                <div className="sign-up-form__row">
                  <Field
                    locales={locales}
                    component={Input}
                    label={pageLocales.inputLabels.firstName}
                    placeholder={pageLocales.inputPlaceholders.name}
                    name="firstName"
                  />
                  <Field
                    locales={locales}
                    component={Input}
                    label={pageLocales.inputLabels.surname}
                    placeholder={pageLocales.inputPlaceholders.surname}
                    name="surname"
                  />
                </div>
                <Field
                  locales={locales}
                  name="email"
                  component={Input}
                  label={pageLocales.inputLabels.email}
                  placeholder={pageLocales.inputPlaceholders.email}
                  className="margin-bottom-20"
                />
                <Field
                  locales={locales}
                  name="password"
                  component={Input}
                  label={pageLocales.inputLabels.password}
                  placeholder={pageLocales.inputPlaceholders.password}
                  type="password"
                  className="margin-bottom-20"
                />
                <div className="sign-up-form__actions">
                  <Button
                    title={pageLocales.buttons.createAccount}
                    className="bmc-button--sign-up"
                    disabled={isDisabledCreateAccount}
                    onClick={() => setStep(2)}
                  />
                </div>
                <div className="sign-up-form__links">
                  {pageLocales.common.alreadyHaveAccount}
                  <Link to="/onboarding/sign_in">
                    {pageLocales.common.signIn}
                  </Link>
                </div>
                <div className="sign-up-form__privacy-information">
                  <h4>{pageLocales.common.privacy}</h4>
                  <p>{pageLocales.common.byCreating}</p>
                  <div>
                    <Link to="/terms">{pageLocales.common.terms}</Link>
                    <Link to="/policy">{pageLocales.common.privacyPolicy}</Link>
                  </div>
                </div>
              </>
            )}
            {step === 2 && (
              <>
                <h3 className="sign-up-form__title margin-bottom-10">
                  {pageLocales.titles.step2}
                </h3>
                <p className="sign-up-form__description">
                  {pageLocales.descriptions.step2}
                </p>
                <Field
                  locales={locales}
                  name="projectName"
                  component={Input}
                  placeholder={pageLocales.inputPlaceholders.projectName}
                  className="margin-bottom-20"
                />
                <Button
                  title={pageLocales.buttons.createProject}
                  className="margin-left-auto bmc-button--sign-up"
                  disabled={!!errors.projectName || !projectName.trim()}
                  onClick={() => setStep(3)}
                />
              </>
            )}
            {/* todo: temporary disabled
            {step === 3 && (
              <>
                <h3 className="sign-up-form__title">
                  {pageLocales.titles.step3}
                </h3>
                <p className="sign-up-form__description">
                  {pageLocales.descriptions.step3}
                </p>
                <FieldArray
                  name="teammates"
                  render={(arrayHelpers) => {
                    if (teammates.every((el) => el)) {
                      arrayHelpers.push('');
                    }
                    if (teammates.length >= 2) {
                      const lastElement = teammates[teammates.length - 1];
                      const beforeLastElement = teammates[teammates.length - 2];
                      if (!lastElement && !beforeLastElement) {
                        arrayHelpers.remove(teammates.length - 1);
                      }
                    }

                    return (
                      <>
                        {teammates.map((teammate, index) => (
                          <Field
                            key={index}
                            locales={locales}
                            name={`teammates[${index}]`}
                            component={Input}
                            placeholder={
                              pageLocales.inputPlaceholders.inviteSomeone
                            }
                            className="margin-bottom-20"
                          />
                        ))}
                      </>
                    );
                  }}
                />
                <Button
                  title={pageLocales.buttons.sendInvites}
                  className="margin-left-auto bmc-button--sign-up"
                  disabled={errors.teammates && errors.teammates.some((el) => el)}
                  onClick={() => setStep(4)}
                />
              </>
            )} */}
            {step === 3 && (
              <>
                <h3 className="sign-up-form__title">
                  {pageLocales.titles.step4}
                </h3>
                <div className="sign-up-form__row">
                  <Button
                    id="button-no"
                    className="bmc-button--sign-up-outlined"
                    title={pageLocales.buttons.helpCreate}
                    onClick={() => {
                      completeForm(false);
                    }}
                  />
                  <Button
                    id="button-yes"
                    className="bmc-button--sign-up-outlined"
                    title={pageLocales.buttons.iHave}
                    onClick={() => {
                      completeForm(true);
                    }}
                  />
                </div>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

SignupForm.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  locales: PropTypes.object.isRequired,
};

export default SignupForm;
