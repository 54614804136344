import React from 'react';
import { Provider } from 'react-redux';
import { ActionCableProvider } from 'react-actioncable-provider';
import 'antd/lib/grid/style/index.css';

import 'react-input-range/lib/css/index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'antd/lib/button/style/index.css';
import 'antd/lib/input/style/index.css';
import 'antd/lib/form/style/index.css';
import 'antd/lib/space/style/index.css';
import 'antd/lib/progress/style/index.css';

import './utils/axios';
import Router from './utils/Router';
import NotifySystem from './components/NotifySystem';
import Alert from './components/Alert';
import store from './rematcher/store';
import ConfirmationModalBase from './components/ConfirmationModalBase';
import ConnectionChecker from './components/ConnectionChecker';
import GlobalContext from './utils/GlobalContext';
import { defaultSectionAvailabilitySettings as defaultSectionSettings } from '../admin/models/classroom';
import UserCableHandler from './containers/actioncable/UserCableHandler';

export default function App({
  locales,
  flash,
  anyCableUrl,
  sectionAvailabilitySettings,
  bmcId,
}) {
  const globalContext = {
    locales,
    bmcId,
    sectionAvailabilitySettings: {
      ...defaultSectionSettings,
      ...sectionAvailabilitySettings,
    },
  };

  return (
    <ActionCableProvider url={anyCableUrl}>
      <GlobalContext.Provider value={globalContext}>
        <Provider store={store}>
          <ConfirmationModalBase />
          <ConnectionChecker />
          <UserCableHandler />
          <NotifySystem flash={flash} />
          <Alert />
          <Router />
        </Provider>
      </GlobalContext.Provider>
    </ActionCableProvider>
  );
}
