import axios from 'axios';

import * as Route from '../utils/serverRoutes';
import {
  transformResponseCollection,
  transformResponseData,
  updateCollectionById,
} from '../utils/helpers';
import makeRequest, { uploadFile } from '../utils/makeRequest';
import { calculateMenuRules } from './progress/testing';

export const defaultRules = [
  true,
  true,
  true,
  false,
  false,
  false,
  false,
  false,
];

const defaultTest = {
  assumption: {
    text: '',
    blocks: [],
  },
  test: 'homepage',
  data: {
    plan: '',
    template: 0,
    companyName: '',
    headline: '',
    explain: '',
    thingOne: '',
    thingTwo: '',
    thingThree: '',
  },
  analyse: {
    resultsAndLearning: '',
    status: '',
  },
  image: null,
};

const testing = {
  state: {
    collection: [],
    uploading: false,
    uploadingImage: false,
    menuRules: defaultRules,
    test: defaultTest,
  },
  reducers: {
    fetchReducer(state, { collection }) {
      return { ...state, collection };
    },
    fetchTestReducer(state, test) {
      const newState = {
        ...state,
        test: { ...state.test, ...test },
      };

      return { ...newState, menuRules: calculateMenuRules(newState.test) };
    },
    updateTestReducer(state, { test }) {
      const newState = {
        ...state,
        test: { ...state.test, ...test },
      };

      return {
        ...newState,
        menuRules: calculateMenuRules(newState.test),
      };
    },
    updateTestImageReducer(state, { image }) {
      return {
        ...state,
        test: { ...state.test, image },
      };
    },
    updateAttributeReducer(state, data) {
      return { ...state, ...data };
    },
    setTestReducer(state, test) {
      return { ...state, test };
    },
    /* we are using next reducers for multiedition functionality */
    addTestingReducer(state, payload) {
      return { ...state, collection: [...state.collection, payload] };
    },
    updateTestingReducer(state, { id, isAnyCable = false, ...data }) {
      const test =
        (isAnyCable && state.test.id === id) || !isAnyCable
          ? { ...state.test, ...data }
          : state.test;
      const newState = {
        ...state,
        test,
        collection: updateCollectionById(state.collection, id, data),
      };

      return { ...newState, menuRules: calculateMenuRules(newState.test) };
    },
    removeTestingReducer(state, { id }) {
      const collection = state.collection.filter((e) => e.id !== id);

      return { ...state, collection };
    },
  },
  effects: (dispatch) => ({
    fetchTests(id = null, state) {
      dispatch.testing.updateAttributeReducer({ uploading: true });

      makeRequest({
        dispatch,
        url: Route.tests(id || state.bmc.current.id),
        success: (response) => {
          dispatch.testing.fetchReducer({
            collection: transformResponseCollection(response.data.data),
          });
        },
        finish: () => {
          dispatch.testing.updateAttributeReducer({ uploading: false });
        },
      });
    },
    fetchTest(id, state) {
      dispatch.testing.updateAttributeReducer({ uploading: true });

      makeRequest({
        dispatch,
        url: Route.test(state.bmc.current.id, id),
        success: (response) => {
          dispatch.testing.fetchTestReducer(
            transformResponseData(response.data.data),
          );
        },
        finish: () => {
          dispatch.testing.updateAttributeReducer({ uploading: false });
        },
      });
    },
    async addTest(_, state) {
      const { status, response } = await makeRequest({
        dispatch,
        method: 'post',
        url: Route.tests(state.bmc.current.id),
        data: { test: defaultTest },
      });

      if (status === 200) {
        return response;
      }
      return {};
    },
    updateTest(payload, state) {
      const { id, test, bmcId } = payload;
      dispatch.testing.updateTestReducer(payload);

      makeRequest({
        dispatch,
        method: 'put',
        url: Route.test(bmcId || state.bmc.current.id, id),
        data: { test },
      });
    },
    updateTestImage(payload, state) {
      dispatch.testing.updateAttributeReducer({ uploadingImage: true });

      uploadFile({
        url: Route.test(state.bmc.current.id, payload.id),
        modelName: 'test',
        name: payload.name,
        blob: payload.blob,
        dispatch,
        success: () => {
          dispatch.testing.updateTestImageReducer({
            [payload.name]: payload.value,
            id: payload.id,
          });
        },
        finish: () => {
          dispatch.testing.updateAttributeReducer({ uploadingImage: false });
        },
      });
    },
    async deleteTest(id, state) {
      await axios.delete(Route.test(state.bmc.current.id, id));
      await dispatch.testing.fetchTests();
    },
  }),
};

export default testing;
