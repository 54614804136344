import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import Modal from '../../components/Modal';
import Button from '../../components/Button';
import SvgIcon from '../../components/SvgIcon';

export default function CardModal({
  className,
  onBlackscreenClick,
  title,
  icon,
  children,
  to,
  onOpen,
  isOpen,
  locales: { canvas },
  isPreview = false,
  titleStyle,
  buttonStyle,
  iconStyle,
  iconFill,
  iconFillClose,
  backgroundIcon,
  backgroundBMC,
  firstSvgColor,
  secondSvgColor,
  titleColor,
  buttonColor,
  backgroundButton,
  isColourfulfunTemplate = false,
  disabled = false,
}) {
  const history = useHistory();

  useEffect(() => {
    onOpen();
  }, []);

  useEffect(() => {
    if (isPreview && !disabled) {
      const body = document.querySelector('body');

      if (isOpen) body.classList.add('body-modal-open');
      else body.classList.remove('body-modal-open');

      return () => {
        body.classList.remove('body-modal-open');
      };
    }
  }, [isOpen]);

  return (
    <Modal
      onBlackscreenClick={onBlackscreenClick}
      className={classNames('bmc-modal--card relative', className)}
    >
      {isColourfulfunTemplate && (
        <>
          <div className="svg-modalFirstSvg" style={{ fill: firstSvgColor }}>
            <SvgIcon name="modalFirstSvg" />
          </div>
          <div className="svg-modalSecondSvg" style={{ fill: secondSvgColor }}>
            <SvgIcon name="modalSecondSvg" />
          </div>
        </>
      )}
      <div
        className="bmc-modal-card-content"
        style={{ background: backgroundBMC }}
      >
        <div className="bmc-modal-card-header">
          <div className="bmc-modal-card-header-logo">
            <div
              className={`bmc-modal-card-header-logo-icon ${iconStyle} custom-font-body`}
              style={{ fill: iconFill, background: backgroundIcon }}
            >
              {typeof icon === 'string' ? <i className={icon} /> : icon}
            </div>
            <div
              className={`${titleStyle} custom-font-body`}
              style={{ color: titleColor }}
            >
              {title}
            </div>
          </div>
          {!isPreview ? (
            <div className="bmc-modal-card-header-actions">
              <Button
                title={canvas.openSection}
                className={`bmc-button--primary ${buttonStyle} custom-font-body`}
                onClick={() => history.push(to)}
                style={{ color: buttonColor, background: backgroundButton }}
              />
              <Button
                title={<SvgIcon name="closePortfolioModalIcon" />}
                className="close-modal-button"
                onClick={onBlackscreenClick}
                style={{ fill: iconFillClose || backgroundButton }}
              />
            </div>
          ) : (
            <div className="bmc-modal-card-header-actions">
              <Button
                title={<SvgIcon name="closePortfolioModalIcon" />}
                className="close-modal-button"
                onClick={onBlackscreenClick}
                style={{ fill: iconFillClose || backgroundButton }}
              />
            </div>
          )}
        </div>

        {children}
      </div>
    </Modal>
  );
}
