import React from 'react';
import { useSelector } from 'react-redux';

import BmcCanvas from '../../main/containers/bmc/BmcCanvas';
import SvgIcon from '../../main/components/SvgIcon';
import { COLOURFUL_TEMPLATE_NAME } from '../../main/utils/const';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';

const BMC = ({
  locales,
  locales: { portfolioWeb },
  className,
  sectionClassName,
  sliderStyles,
  iconFill,
  iconFillClose,
  iconFillTable,
  iconFillSlider,
  iconStyle,
  backgroundMainSection,
  backgroundBMC,
  backgroundIcon,
  backgroundRow,
  styleColor,
  titleStyle,
  textStyle,
  textWithImage,
  backgroundIconTable,
  backgroundSliderColor,
  backgroundImage,
  titleColor,
  backgroundButton,
  buttonStyle,
  sectionHeaderTitleColor,
  descriptionColor,
  firstSvgColor,
  secondSvgColor,
  backgroundItems,
  backgroundSection,
  borderSectionColor,
  titleBMCSectionColor,
  iconTableStyles,
  buttonColor,
  textColor,
  titleImageColor,
  borderCardColor,
  textSliderColor,
  templateName,
  isProgressBar = false,
}) => {
  const { bmc } = useSelector((state) => state.portfolio);

  const isColourfulfunTemplate = templateName === COLOURFUL_TEMPLATE_NAME;

  return (
    <div
      className={`${className} section-web`}
      style={{ backgroundColor: backgroundMainSection }}
    >
      {isColourfulfunTemplate && (
        <>
          <div className="svg-funBMCsixth" style={{ fill: firstSvgColor }}>
            <SvgIcon name="funBMCsixth" />
          </div>
          <div className="svg-funBMCseventh" style={{ fill: secondSvgColor }}>
            <SvgIcon name="funBMCseventh" />
          </div>
        </>
      )}

      <div className="bmc">
        <SectionHeaderWeb
          templateName={templateName}
          title={portfolioWeb.bmc}
          description={bmc.description}
          style={{ color: sectionHeaderTitleColor }}
          descriptionColor={descriptionColor}
        />

        <BmcCanvas
          locales={locales}
          isPreview
          className="bmc-canvas--scrollable-portfolio-web"
          sectionClassName={sectionClassName}
          isProgressBar={isProgressBar}
          templateName={templateName}
          iconFill={iconFill}
          iconFillClose={iconFillClose}
          iconFillTable={iconFillTable}
          iconFillSlider={iconFillSlider}
          iconTableStyles={iconTableStyles}
          backgroundIcon={backgroundIcon}
          styleColor={styleColor}
          titleColor={titleColor}
          backgroundButton={backgroundButton}
          buttonStyle={buttonStyle}
          backgroundIconTable={backgroundIconTable}
          backgroundSliderColor={backgroundSliderColor}
          backgroundImage={backgroundImage}
          backgroundItems={backgroundItems}
          backgroundSection={backgroundSection}
          borderSectionColor={borderSectionColor}
          firstSvgColor={firstSvgColor}
          secondSvgColor={secondSvgColor}
          titleStyle={titleStyle}
          iconStyle={iconStyle}
          backgroundBMC={backgroundBMC}
          textStyle={textStyle}
          backgroundRow={backgroundRow}
          textWithImage={textWithImage}
          titleBMCSectionColor={titleBMCSectionColor}
          buttonColor={buttonColor}
          textColor={textColor}
          sliderStyles={sliderStyles}
          titleImageColor={titleImageColor}
          borderCardColor={borderCardColor}
          textSliderColor={textSliderColor}
          isTemplates
          isColourfulfunTemplate={isColourfulfunTemplate}
        />
      </div>
    </div>
  );
};

export default BMC;
