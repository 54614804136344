import React from 'react';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';

import Button from '../../../components/Button';
import Spinner from '../../../components/Spinner';

export default function BmcCanvasSection({
  className,
  starting,
  dark,
  showButton,
  children,
  title,
  icon,
  to,
  isPreview,
  isProgressBar,
  onClick,
  titleBMCSectionColor,
  backgroundIcon,
  backgroundSection,
  borderSectionColor,
  iconFill,
  iconStyle,
  uploading = false,
  locales: {
    canvas,
    common: { create },
  },
}) {
  const history = useHistory();
  const showStartingHere = showButton && starting;
  const classes = classNames('bmc-canvas-section', className, {
    'bmc-canvas-section--starting': showStartingHere,
    'bmc-canvas-section--dark': dark,
  });
  const redirect = () => history.push(to);

  return (
    <div
      className={classes}
      onClick={showStartingHere ? redirect : undefined}
      style={{
        borderColor: borderSectionColor,
        backgroundColor: backgroundSection,
      }}
    >
      <div className="bmc-canvas-section-header">
        <div
          className={`bmc-canvas-section-header-icon ${iconStyle}`}
          style={{ backgroundColor: backgroundIcon, fill: iconFill }}
        >
          {typeof icon === 'string' ? <i className={icon} /> : icon}
        </div>
        {isPreview ? (
          <div
            className="bmc-canvas-section-header-title custom-font-body"
            onClick={onClick}
            style={{ color: titleBMCSectionColor }}
          >
            {title}
          </div>
        ) : (
          <Link
            to={to}
            className="bmc-canvas-section-header-title custom-font-body"
            style={{ color: titleBMCSectionColor }}
          >
            {title}
          </Link>
        )}
      </div>
      {isProgressBar && (
        <div className="bmc-canvas-section-progress">
          <div
            className="bmc-canvas-section-progress-bar width-100"
            style={{ backgroundColor: iconFill }}
          />
        </div>
      )}

      <div
        className="bmc-canvas-section-items"
        style={{
          borderColor: borderSectionColor,
        }}
      >
        {uploading ? <Spinner /> : children}

        {showButton && !uploading && (
          <Button
            wide
            heightAuto
            alignLeft
            icon="icon-Add"
            className="bmc-button--blue"
            onClick={redirect}
            title={`${create} ${title}`}
          />
        )}

        {showStartingHere && (
          <div className="bmc-canvas-section-suggestion">
            {canvas.weSuggest}
          </div>
        )}
      </div>
    </div>
  );
}
