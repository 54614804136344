import React from 'react';
import { useSelector } from 'react-redux';

import Img from '../../main/components/Img';
import { removeNewLines } from '../../main/utils/helpers';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import { BOLD_TEMPLATE_NAME } from '../../main/utils/const';

const TeamBoldWeb = ({
  locales: { portfolioWeb },
  titleColor,
  textColor,
  teamNameColor,
  positionColor,
  backgroundSection,
  backgroundImage,
}) => {
  const {
    portfolioTeammate: { collection },
    portfolio: { team },
  } = useSelector((state) => state);

  const getAbbreviation = (source) => {
    return source
      .split(' ')
      .map((n) => n[0])
      .join('');
  };

  return (
    <div
      className="team-bold-web section-web"
      style={{ backgroundColor: backgroundSection }}
    >
      <SectionHeaderWeb
        templateName={BOLD_TEMPLATE_NAME}
        title={portfolioWeb.team}
        description={team.description}
        portfolioWeb={portfolioWeb}
        style={{ color: titleColor }}
        descriptionColor={textColor}
      />
      <div className="cards">
        {collection.map(({ id, name, position, description, image }) => (
          <div className="card" key={id}>
            <div
              className="image-upload"
              style={{ backgroundColor: backgroundImage }}
            >
              {image.cropped ? (
                <Img src={image.cropped} />
              ) : (
                <div
                  className="center-block custom-font-title"
                  style={{ color: titleColor }}
                >
                  {getAbbreviation(name)}
                </div>
              )}
            </div>
            <div
              className="position custom-font-body"
              style={{ color: positionColor }}
            >
              {position}
            </div>
            <div
              className="name custom-font-title"
              style={{ color: teamNameColor }}
            >
              {name}
            </div>
            <div
              className="member-description custom-font-body"
              style={{ color: textColor }}
            >
              {description && removeNewLines(description)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamBoldWeb;
