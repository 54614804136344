import * as Route from '../support/serverRoutes';
import makeRequest from '../../main/utils/makeRequest';

const activeUser = {
  state: {
    data: null,
    loading: false,
  },
  reducers: {
    setData(state, { data }) {
      return { ...state, data };
    },
    setLoading(state, loading) {
      return { ...state, loading };
    },
  },
  effects: (dispatch) => ({
    fetchUserById({ userId }) {
      dispatch.activeUser.setLoading(true);
      makeRequest({
        dispatch,
        url: Route.user(userId),
        success: (response) => {
          dispatch.activeUser.setData({
            data: response.data.data,
          });
        },
        finish: () => {
          dispatch.activeUser.setLoading(false);
        },
      });
    },
  }),
};

export default activeUser;
