import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import TipCustomerSegment from '../containers/tips/TipCustomerSegment';
import Button from './Button';
import BmcMobileMenu from './BmcMobileMenu';

export default function Header({
  title,
  subtitle,
  icon,
  tipLocales,
  locales,
  locales: { tips },
  className,
  menuItems,
  onMenuItemClick,
  mobileMenuHeader,
  componentIndex,
  rules,
  progress = 0,
  backPath,
}) {
  const [showTip, setShowTip] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const menuItemClick = (index) => {
    onMenuItemClick(index);
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <div className={classNames('bmc-page-header', className)}>
      {showTip && (
        <TipCustomerSegment
          onClose={() => setShowTip(false)}
          locales={tipLocales}
        />
      )}

      <BmcMobileMenu
        locales={locales}
        menuItems={menuItems}
        onMenuItemClick={menuItemClick}
        mobileMenuHeader={mobileMenuHeader}
        componentIndex={componentIndex}
        rules={rules}
        progress={progress}
        show={showMobileMenu}
        className={classNames({
          'bmc-menu--mobile-visible': showMobileMenu,
        })}
        onCloseClick={() => setShowMobileMenu(false)}
      />

      <div className="bmc-page-header-topbar">
        <div className="bmc-page-header-small-title">
          <div className="bmc-page-header-small-title-icon">
            {typeof icon === 'string' ? <i className={icon} /> : icon}
          </div>
          <span>{subtitle}</span>
        </div>

        <div className="bmc-page-header-mobile-actions">
          <Link to={backPath}>
            <Button
              className="bmc-button--blue bmc-button--just-icon margin-right-20"
              icon="icon-Back"
            />
          </Link>

          <Button
            className="bmc-button--blue bmc-button--just-icon"
            icon="icon-BMC-View-2"
            onClick={() => setShowMobileMenu(true)}
          />
        </div>

        <div className="bmc-page-header-tips">
          <button
            className="bmc-button bmc-button--with-icon"
            onClick={() => setShowTip(true)}
          >
            <i className="icon-Hat" />
            <span>{tips}</span>
          </button>
        </div>
      </div>
      <div className="bmc-page-header-icon">
        {typeof icon === 'string' ? <i className={icon} /> : icon}
      </div>
      <h1 className="bmc-page-header-big-title">{title}</h1>
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  className: PropTypes.string,
  locales: PropTypes.object,
};
