import React from 'react';
import { useSelector } from 'react-redux';

import { removeNewLines } from '../../main/utils/helpers';
import Button from '../../main/components/Button';
import SvgIcon from '../../main/components/SvgIcon';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';

const Summary = ({
  locales,
  firstSvgColor,
  secondSvgColor,
  fileBackgroundColor,
  background,
  isFourthColorPaletteNumber = false,
}) => {
  const { projectSummary } = useSelector((state) => state.portfolio);
  const { collection } = useSelector((state) => state.portfolioFile);

  return (
    <div
      className="summary-colourfulFun-web"
      style={{ backgroundColor: background }}
    >
      <div className="svg-funSummaryThird" style={{ fill: firstSvgColor }}>
        <SvgIcon name="funSummaryThird" />
      </div>
      <div className="svg-funSummaryFourth" style={{ fill: secondSvgColor }}>
        <SvgIcon name="funSummaryFourth" />
      </div>
      <div className="content">
        <div className="main">
          <div className="colourful-title title custom-font-title">
            {locales.portfolioWeb.summary}
          </div>
          <div className="description custom-font-body">
            {projectSummary.description &&
              removeNewLines(projectSummary.description)}
          </div>

          <div className="margin-top-40 file">
            {collection.map((file) => (
              <a
                key={file.id}
                href={file.file}
                target="_blank"
                className="custom-font-body"
              >
                <Button
                  icon="icon-Export"
                  className="bmc-button button custom-font-body"
                  title={file.name}
                  style={{
                    backgroundColor: fileBackgroundColor,
                    color:
                      isFourthColorPaletteNumber &&
                      getColorDependsOnBackground(fileBackgroundColor),
                  }}
                />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
