import * as Route from '../support/serverRoutes';
import makeRequest from '../../main/utils/makeRequest';

const students = {
  state: {
    data: [],
    meta: {},
    loading: false,
    by: null,
    byId: null,
  },
  reducers: {
    setData(state, data) {
      return { ...state, data };
    },
    setMeta(state, meta) {
      return { ...state, meta };
    },
    setLoading(state, loading) {
      return { ...state, loading };
    },
    setRouteOptions(state, { by, byId }) {
      return { ...state, by, byId };
    },
  },
  effects: (dispatch) => ({
    fetchStudents(params, rootState) {
      const sort = rootState.students?.meta?.sort;
      dispatch.students.setLoading(true);
      dispatch.students.setRouteOptions(params);
      makeRequest({
        dispatch,
        url: Route.students({ ...params, sort }),
        success: (response) => {
          dispatch.students.setData(response.data.data);
          dispatch.students.setMeta(response.data.meta);
        },
        finish: () => {
          dispatch.students.setLoading(false);
        },
      });
    },
    sortStudents({ sort }, rootState) {
      const {
        by,
        byId,
        meta: { page, limit, search },
      } = rootState.students;
      dispatch.students.setLoading(true);
      makeRequest({
        dispatch,
        url: Route.students({ page, limit, search, sort, by, byId }),
        success: (response) => {
          dispatch.students.setData(response.data.data);
          dispatch.students.setMeta(response.data.meta);
        },
        finish: () => {
          dispatch.students.setLoading(false);
        },
      });
    },
    reload(_, rootState) {
      const {
        by,
        byId,
        meta: { page, limit },
      } = rootState.students;

      dispatch.students.fetchStudents({ by, byId, page, limit });
    },
  }),
};

export default students;
