import * as Route from '../utils/serverRoutes';
import {
  transformResponseCollection,
  updateCollectionById,
  payloadToData,
} from '../utils/helpers';
import makeRequest from '../utils/makeRequest';

const product = {
  state: {
    collection: [],
    uploading: false,
  },
  reducers: {
    fetchProductsReducer(state, { collection }) {
      return { ...state, collection };
    },
    addProductReducer(state, payload) {
      return { ...state, collection: [...state.collection, payload] };
    },
    updateProductReducer(state, payload) {
      return {
        ...state,
        collection: updateCollectionById(state.collection, payload.id, payload),
      };
    },
    removeProductReducer(state, { id }) {
      const collection = state.collection.filter((e) => e.id !== id);

      return { ...state, collection };
    },
    updateAttributeReducer(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    async fetchProducts(bmcId) {
      dispatch.product.updateAttributeReducer({ uploading: true });

      makeRequest({
        dispatch,
        url: Route.products(bmcId),
        success: (response) => {
          dispatch.product.fetchProductsReducer({
            collection: transformResponseCollection(response.data.data),
          });
        },
        finish: () => {
          dispatch.product.updateAttributeReducer({ uploading: false });
        },
      });
    },
    async addProduct(_, state) {
      makeRequest({
        dispatch,
        method: 'post',
        url: Route.products(state.bmc.current.id),
      });
    },
    async updateProduct(payload, state) {
      const data = payloadToData(payload);
      dispatch.product.updateProductReducer(data);

      makeRequest({
        method: 'put',
        url: Route.product(state.bmc.current.id, payload.id),
        data: { product: data },
      });
    },
    async updateSales(
      payload,
      {
        bmc: {
          current: { id },
        },
        product: productState,
      },
    ) {
      const productItem = productState.collection.find(
        (e) => e.id === payload.id,
      );
      const { sales } = productItem;
      sales[payload.index] = Number.isNaN(parseInt(payload.value))
        ? ''
        : parseInt(payload.value);

      dispatch.product.updateProductReducer({ id: payload.id, sales });
      makeRequest({
        method: 'put',
        url: Route.product(id, payload.id),
        data: { product: { sales } },
      });
    },
    async removeProduct(payload, state) {
      makeRequest({
        method: 'delete',
        url: Route.product(state.bmc.current.id, payload.id),
      });
    },
  }),
};

export default product;
