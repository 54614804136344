import React from 'react';
import classNames from 'classnames';

export default function Checkbox({
  id,
  title,
  value,
  handleChange,
  color = '',
}) {
  return (
    <div className="item">
      <label htmlFor={id}>
        <input
          type="checkbox"
          onChange={(e) => handleChange(id, e.target.checked)}
          checked={value}
          id={id}
          data-hj-allow
        />
        <span className="cbx">
          <svg width="16px" height="16px" viewBox="0 0 12 11">
            <polyline points="1 6.29411765 4.5 10 11 1" />
          </svg>
        </span>
      </label>
      <div className={classNames('title', color)}>{title}</div>
    </div>
  );
}
