import React from 'react';
import classNames from 'classnames';

import Img from '../../../components/Img';

export default function Idea({ selected, wasSelected, onClick, idea, title }) {
  const imgInner = title ? (
    <div className="ideation-selector-ideas-idea-title">{title}</div>
  ) : undefined;

  return (
    <div
      className={classNames('ideation-selector-ideas-idea', {
        'ideation-selector-ideas-idea--selected': selected,
        'ideation-selector-ideas-idea--selected-for-other-questions': wasSelected,
      })}
      onClick={selected || wasSelected ? undefined : onClick}
    >
      <Img src={idea.url} aspectRatio={1} inner={imgInner} />
      <div className="ideation-selector-ideas-idea-text">{idea.solution}</div>
      <div className="ideation-selector-ideas-idea-selector" />
    </div>
  );
}
