import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SectionHeader from '../SectionHeader';
import TeamCard from './TeamCard';
import Textarea from '../../../components/Textarea';

const Team = ({
  data: { description, showStatus },
  locales,
  locales: { portfolio },
  disabled = false,
}) => {
  const {
    portfolioTeammate: { addPortfolioTeammate },
    portfolio: { updateSectionField, updateSectionFieldReducer },
  } = useDispatch();
  const {
    portfolioTeammate: { collection },
  } = useSelector((state) => state);

  const setShowStatus = (status) => {
    updateSectionFieldReducer({
      sectionName: 'team',
      field: 'showStatus',
      value: status,
    });
  };
  return (
    <div className="portfolio-teammates">
      <SectionHeader
        locales={portfolio}
        showStatus={showStatus}
        setShowStatus={setShowStatus}
        sectionName={portfolio.team.sectionName}
      />
      {showStatus && (
        <>
          <Textarea
            id="teamDescription"
            onChange={(e) =>
              updateSectionField({
                sectionName: 'team',
                field: 'description',
                name: 'teamDescription',
                value: e.target.value,
              })
            }
            delay={700}
            value={description || ''}
            rows={5}
            placeholder={portfolio.page.descriptionPlaceholder}
            className="bmc-form-textarea textarea"
            disabled={disabled}
          />
          <div className="team-cards">
            {collection.map((teammate, i) => (
              <TeamCard
                locales={locales}
                teammate={teammate}
                index={i}
                key={teammate.id}
                disabled={disabled}
              />
            ))}

            {!disabled && (
              <div className="add-team-card__wrapper">
                <div className="add-team-card">
                  <div className="center-block" onClick={addPortfolioTeammate}>
                    <i className="icon-Add" />
                    {portfolio.team.addNewTeamMember}
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Team;
