import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { buildMenuItems, menuIsActive } from '../../utils/helpers';
import SvgIcon from '../../components/SvgIcon';
import GlobalContext from '../../utils/GlobalContext';

export const menuIconName = (item) => {
  const activeSuffix = menuIsActive(item.to) ? 'Active' : '';

  return item.iconName + activeSuffix;
};

export default function Menu() {
  const { locales, sectionAvailabilitySettings } = useContext(GlobalContext);
  const {
    bmc: {
      current: { id: bmcId },
    },
  } = useSelector((state) => state);
  const { bmcId: bmcIdFromParams } = useParams();
  const actualBmcId = bmcIdFromParams || bmcId;
  const menuItems = buildMenuItems(
    actualBmcId,
    locales,
    sectionAvailabilitySettings,
  );

  return (
    <div className="bmc-canvas-menu">
      <a href="/" className="bmc-canvas-menu-logo">
        <i className="icon-logo" />
      </a>

      {menuItems.map((item, index) => (
        <Link key={index} to={item.to}>
          <div
            className={classNames('bmc-canvas-menu-item', {
              'bmc-canvas-menu-item--active': menuIsActive(item.to),
            })}
          >
            <div className="bmc-canvas-menu-item-icon">
              <SvgIcon name={menuIconName(item)} />
            </div>
            <div className="bmc-canvas-menu-item-tooltip">{item.title}</div>
          </div>
        </Link>
      ))}
    </div>
  );
}
