import {
  AlignmentType,
  FrameAnchorType,
  HeightRule,
  HorizontalPositionAlign,
  ImageRun,
  OverlapType,
  Paragraph,
  Table,
  TableAnchorType,
  TableCell,
  TableLayoutType,
  TableRow,
  TextRun,
  VerticalAlign,
  VerticalPositionAlign,
  WidthType,
} from 'docx';
import { isEmpty } from 'lodash';
import {
  COLORS,
  convertFontSize,
  convertPixel,
  convertPixelToTwip,
  pageSize,
  getBlobFromUrl,
  transparentBorder,
  tableWidth,
  noPageMargin,
} from '../Constants';
import {
  buildBodyNoImg,
  buildTeamMateImg,
  emptyCell,
  marginTableRow,
  textRun,
} from '../helpers/index';

export default async (locales, portfolio, portfolioTeammate, options) => {
  return {
    properties: {
      page: {
        size: pageSize,
        margin: noPageMargin,
      },
    },
    children: [
      new Table({
        float: {
          horizontalAnchor: TableAnchorType.TEXT,
          verticalAnchor: TableAnchorType.TEXT,
          overlap: OverlapType.NEVER,

          absoluteVerticalPosition: convertPixelToTwip(20),
          absoluteHorizontalPosition: 0,
        },
        width: {
          size: convertPixelToTwip(tableWidth),
          type: WidthType.DXA,
        },
        layout: TableLayoutType.AUTOFIT,
        rows: [
          new TableRow({
            height: {
              value: convertPixelToTwip(68),
              rule: HeightRule.ATLEAST,
            },
            children: [
              new TableCell({
                width: {
                  size: convertPixelToTwip(768),
                  type: WidthType.DXA,
                },
                verticalAlign: VerticalAlign.CENTER,

                children: [
                  new Table({
                    width: {
                      size: convertPixelToTwip(tableWidth),
                      type: WidthType.DXA,
                    },
                    layout: TableLayoutType.AUTOFIT,
                    rows: [
                      new TableRow({
                        height: {
                          value: convertPixelToTwip(68),
                          rule: HeightRule.ATLEAST,
                        },
                        children: [
                          emptyCell(28, COLORS.PRIMARY),
                          new TableCell({
                            width: {
                              size: convertPixelToTwip(100),
                              type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,

                            children: [
                              new Paragraph({
                                alignment: AlignmentType.START,
                                children: [
                                  textRun(locales.team.newSectionName, {
                                    font: options.titleFont,
                                    size: 24,
                                    color: options.titleColor || COLORS.BLACK,
                                    bold: options?.team?.titleBold,
                                    allCaps: true,
                                  }),
                                ],
                              }),
                            ],
                            borders: transparentBorder(COLORS.PRIMARY),
                          }),
                          emptyCell(30, COLORS.PRIMARY),
                          new TableCell({
                            width: {
                              size: convertPixelToTwip(600),
                              type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,

                            children: [
                              new Paragraph({
                                children: [
                                  textRun(portfolio.team.description, {
                                    font: options.bodyFont,
                                    color: options.bodyColor || COLORS.BLACK,
                                  }),
                                ],
                              }),
                            ],
                            borders: transparentBorder(COLORS.PRIMARY),
                          }),
                          emptyCell(28, COLORS.PRIMARY),
                        ],
                      }),
                    ],
                  }),
                ],
                borders: transparentBorder(COLORS.PRIMARY),
              }),
            ],
          }),

          marginTableRow(40, tableWidth, COLORS.WHITE, 5),

          new TableRow({
            height: {
              value: convertPixelToTwip(68),
              rule: HeightRule.ATLEAST,
            },
            children: [
              new TableCell({
                width: {
                  size: convertPixelToTwip(768),
                  type: WidthType.DXA,
                },
                verticalAlign: VerticalAlign.CENTER,

                children: [
                  new Table({
                    width: {
                      size: convertPixelToTwip(tableWidth),
                      type: WidthType.DXA,
                    },
                    layout: TableLayoutType.AUTOFIT,
                    rows: isEmpty(portfolioTeammate.collection)
                      ? [marginTableRow(2, tableWidth)]
                      : await Promise.all(
                          portfolioTeammate.collection.map(
                            async (teammate, index) => {
                              let blobImg;
                              await getBlobFromUrl(teammate.image.cropped).then(
                                (res) => {
                                  blobImg = res;
                                },
                              );

                              const getImgSection = async () => {
                                if (!blobImg) {
                                  blobImg = await buildBodyNoImg(
                                    100,
                                    options.team,
                                    index,
                                  );
                                } else {
                                  blobImg = await buildTeamMateImg(
                                    blobImg,
                                    teammate.image.cropped,
                                    index,
                                    options.team,
                                  );
                                }

                                return new Paragraph({
                                  alignment: AlignmentType.CENTER,
                                  children: [
                                    new ImageRun({
                                      data: blobImg,
                                      transformation: {
                                        width: convertPixel(
                                          options?.team?.teamMateW || 116,
                                        ),
                                        height: convertPixel(
                                          options?.team?.teamMateH || 116,
                                        ),
                                      },
                                    }),
                                  ],
                                });
                              };
                              return new TableRow({
                                height: {
                                  value: convertPixelToTwip(120),
                                  rule: HeightRule.ATLEAST,
                                },
                                children: [
                                  emptyCell(28, COLORS.PRIMARY),
                                  new TableCell({
                                    width: {
                                      size: convertPixelToTwip(128),
                                      type: WidthType.DXA,
                                    },
                                    borders: transparentBorder(COLORS.PRIMARY),
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [await getImgSection()],
                                  }),
                                  emptyCell(10, COLORS.PRIMARY),

                                  new TableCell({
                                    width: {
                                      size: convertPixelToTwip(277),
                                      type: WidthType.DXA,
                                    },
                                    borders: transparentBorder(COLORS.PRIMARY),
                                    verticalAlign: VerticalAlign.CENTER,

                                    children: [
                                      new Paragraph({
                                        frame: {
                                          rule: HeightRule.ATLEAST,
                                          width: convertPixelToTwip(257),
                                          height: convertPixelToTwip(22),
                                          anchor: {
                                            horizontal: FrameAnchorType.TEXT,
                                            vertical: FrameAnchorType.TEXT,
                                          },
                                          alignment: {
                                            x: HorizontalPositionAlign.CENTER,
                                            y: VerticalPositionAlign.CENTER,
                                          },
                                        },

                                        children: [
                                          textRun(teammate.name, {
                                            font: options.titleFont,
                                            color:
                                              options.titleColor ||
                                              COLORS.BLACK,
                                            size: 16,
                                            bold: true,
                                          }),
                                          new TextRun({
                                            text: teammate.position,
                                            font: options.bodyFont,
                                            color:
                                              options.bodyColor || COLORS.BLACK,
                                            size: convertFontSize(14),
                                            break: 1,
                                          }),
                                        ],
                                      }),
                                    ],
                                  }),
                                  emptyCell(10, COLORS.PRIMARY),
                                  new TableCell({
                                    verticalAlign: VerticalAlign.CENTER,
                                    borders: transparentBorder(COLORS.PRIMARY),
                                    children: [
                                      new Paragraph({
                                        children: [
                                          textRun(teammate.description, {
                                            font: options.bodyFont,
                                            color:
                                              options.bodyColor || COLORS.BLACK,
                                            size: 12,
                                          }),
                                        ],
                                      }),
                                    ],
                                  }),
                                  emptyCell(28, COLORS.PRIMARY),
                                ],
                              });
                            },
                          ),
                        ),
                  }),
                ],
                borders: transparentBorder(COLORS.PRIMARY),
              }),
            ],
          }),
        ],
      }),

      // whiteBgImage(),
    ],
  };
};
