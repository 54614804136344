import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import {
  BUTTON_MODAL_STYLE_COLOURFUL,
  COLORS,
  COLOR_PALETTE_ARRAY_COLOURFUL,
  COLOURFUL_TEMPLATE_NAME,
  TITLE_MODAL_STYLE_COLOURFUL,
} from '../../main/utils/const';
import Introduction from '../colourfulComponents/Introduction';
import Summary from '../colourfulComponents/Summary';
import Team from '../colourfulComponents/Team';
import BMC from '../mainWebTemplateComponents/BMC';
import CustomerSegment from '../colourfulComponents/CustomerSegment';
import ValueProposition from '../colourfulComponents/ValueProposition';
import CompetitorAnalysis from '../mainWebTemplateComponents/CompetitorAnalysis';
import GKG from '../mainWebTemplateComponents/GKG';
import Channels from '../colourfulComponents/Channels';
import KeyActivities from '../mainWebTemplateComponents/KeyActivities';
import Finance from '../mainWebTemplateComponents/Finance';
import Sustainability from '../mainWebTemplateComponents/Sustainability';
import ContactUs from '../colourfulComponents/ContactUs';
import { StyledWrapper } from '../../main/utils/helpers';
import Desirability from '../mainWebTemplateComponents/Desirability';
import Viability from '../mainWebTemplateComponents/Viability';
import Feasibility from '../mainWebTemplateComponents/Feasibility';
import { getColorTemplate } from '../../main/utils/helpers/getColorTemplate';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';
import Testing from '../mainWebTemplateComponents/Testing';

const { white, black } = COLORS;

const ColourfulFunWebTemplate = ({
  locales,
  bmcEnabled,
  testing,
  currentSettings,
}) => {
  const [showAll, setShowAll] = useState(false);

  const { sections } = useParams();

  const showSections = useMemo(() => {
    if (!sections) {
      setShowAll(true);
      return [];
    }
    const sectionArray = sections.split('-');
    if (!sectionArray.length) {
      setShowAll(true);
      return [];
    }
    return sectionArray;
  }, [sections]);

  const { contactUs } = useSelector((state) => state.portfolio);

  const {
    portfolioStyle: { templateSettings, activeTemplateName },
    portfolioTemplate: { collection },
  } = useSelector((state) => state);

  const activeTemplate = find(collection, ['sysName', activeTemplateName]);
  const colorTemplate = getColorTemplate(
    templateSettings,
    activeTemplateName,
    activeTemplate,
  );

  if (!colorTemplate) {
    return null;
  }

  const [firstColor, secondColor, thirdColor, fourthColor] = colorTemplate;

  const isFourthColorPaletteNumber = !COLOR_PALETTE_ARRAY_COLOURFUL[firstColor];

  const { shortcuts } = locales.portfolio.export;

  const {
    introduction,
    projectSummary,
    team,
    bmc,
    testing: test,
    desirabilitySection,
    customerSegment,
    valueProposition,
    competitorAnalysis,
    customerRelationships,
    channels,
    feasibility,
    keyActivities,
    viability,
    finance,
    sustainability,
    contactUs: contact,
  } = shortcuts;

  const isShowSections = (sectionName) => {
    return showAll || showSections.includes(sectionName);
  };

  const textColorDependsOnFirstColor =
    isFourthColorPaletteNumber && getColorDependsOnBackground(firstColor);

  return (
    <StyledWrapper
      customtitlefont={currentSettings?.customTitleFont}
      customtitlecolor={currentSettings?.customTitleColor}
      custombodyfont={currentSettings?.customBodyFont}
      custombodycolor={currentSettings?.customBodyColor}
    >
      {isShowSections(introduction) && (
        <Introduction
          backgroundImage={firstColor}
          firstSvgColor={fourthColor}
          secondSvgColor={thirdColor}
          background={`${thirdColor}1A`}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}
      {isShowSections(projectSummary) && (
        <Summary
          locales={locales}
          firstSvgColor={thirdColor}
          secondSvgColor={secondColor}
          fileBackgroundColor={fourthColor}
          background={`${thirdColor}1A`}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}
      {isShowSections(team) && (
        <Team
          locales={locales}
          colorTemplate={colorTemplate}
          mainSvgColor={`${thirdColor}1A`}
          firstSvgColor={firstColor}
          secondSvgColor={secondColor}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}
      {bmcEnabled && isShowSections(bmc) && (
        <BMC
          locales={locales}
          sliderStyles="sdg-card-colourful sdg-card-colourful-modal"
          className="bm-colourful-web-canvas"
          sectionClassName="bmc-canvas-section--colourful"
          iconStyle="header-icon-colourful"
          iconTableStyles="sdg-card-colourful-icon"
          textStyle="text-card-colourful"
          textWithImage="bmc-page-compcard-item-text-template bmc-page-compcard-item-text-template--with-image"
          iconFill={getColorDependsOnBackground(thirdColor)}
          iconFillClose={getColorDependsOnBackground(fourthColor)}
          firstSvgColor={thirdColor}
          secondSvgColor={fourthColor}
          backgroundMainSection={white}
          backgroundBMC={white}
          backgroundCanvas={white}
          backgroundIcon={thirdColor}
          backgroundItems={`${thirdColor}1A`}
          backgroundSection={white}
          borderSectionColor={thirdColor}
          styleColor={thirdColor}
          backgroundRow={white}
          backgroundImage={firstColor}
          titleStyle={TITLE_MODAL_STYLE_COLOURFUL}
          backgroundIconTable={white}
          backgroundSliderColor={white}
          borderCardColor={thirdColor}
          titleImageColor={textColorDependsOnFirstColor}
          iconFillTable={thirdColor}
          iconFillSlider={thirdColor}
          templateName={COLOURFUL_TEMPLATE_NAME}
        />
      )}
      {testing && isShowSections(test) && (
        <Testing
          locales={locales}
          className="testing testing-colourful-web"
          textStyle="text-card-colourful"
          testListStyle="tests-list-colourful"
          leftSideStyle="testing-colourful-web-left"
          rightSideStyle="testing-colourful-web-right"
          firstSvgColor={firstColor}
          secondSvgColor={secondColor}
          backgroundSection={{ backgroundColor: `${thirdColor}1A` }}
          backgroundCard={`${thirdColor}1A`}
          rateColor={thirdColor}
          inputColor={secondColor}
          textModalColor={black}
          textRowColor={black}
          titleStyle={TITLE_MODAL_STYLE_COLOURFUL}
          buttonStyle={BUTTON_MODAL_STYLE_COLOURFUL}
          iconFillClose={getColorDependsOnBackground(secondColor)}
          templateName={COLOURFUL_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(desirabilitySection) && (
        <Desirability
          locales={locales}
          className="desirability-colourful-web"
          mainSvgColor={`${thirdColor}1A`}
          templateName={COLOURFUL_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(customerSegment) && (
        <CustomerSegment
          locales={locales}
          backgroundImage={firstColor}
          firstSvgColor={thirdColor}
          secondSvgColor={fourthColor}
          background={`${thirdColor}1A`}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}

      {bmcEnabled && isShowSections(valueProposition) && (
        <ValueProposition
          locales={locales}
          backgroundImage={firstColor}
          firstSvgColor={secondColor}
          secondSvgColor={firstColor}
          background={`${thirdColor}1A`}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}
      {bmcEnabled && isShowSections(competitorAnalysis) && (
        <CompetitorAnalysis
          locales={locales}
          className="competitor-analysis-colourful-web"
          firstSvgColor={firstColor}
          secondSvgColor={`${thirdColor}1A`}
          backgroundTable={white}
          backgroundHeadTable={fourthColor}
          colorTemplate={colorTemplate}
          headTableColor={textColorDependsOnFirstColor}
          templateName={COLOURFUL_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(customerRelationships) && (
        <GKG
          locales={locales}
          className="customer-relationships-colourful-web"
          firstSvgColor={thirdColor}
          secondSvgColor={secondColor}
          tabColor={fourthColor}
          firstTabColor={thirdColor}
          secondTabColor={fourthColor}
          backgroundSection={`${thirdColor}1A`}
          textColor={black}
          backgroundBlock={white}
          templateName={COLOURFUL_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(channels) && (
        <Channels
          locales={locales}
          firstSvgColor={firstColor}
          secondSvgColor={secondColor}
          buttonBackgroundColor={thirdColor}
          background={`${thirdColor}1A`}
          backgroundImage={firstColor}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}
      {bmcEnabled && isShowSections(feasibility) && (
        <Feasibility
          locales={locales}
          className="feasibility-colourful-web"
          mainSvgColor={`${thirdColor}1A`}
          templateName={COLOURFUL_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(keyActivities) && (
        <KeyActivities
          locales={locales}
          className="key-activities-colourful-web"
          firstSvgColor={thirdColor}
          secondSvgColor={fourthColor}
          circleActivityColor={thirdColor}
          circleResourcesColor={thirdColor}
          lineActivityColor={thirdColor}
          backgroundResources={`${thirdColor}1A`}
          templateName={COLOURFUL_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(viability) && (
        <Viability
          locales={locales}
          className="viability-colourful-web"
          mainSvgColor={`${thirdColor}1A`}
          templateName={COLOURFUL_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(finance) && (
        <Finance
          locales={locales}
          className="portfolio-finance-colourful portfolio-finance-colourful-temp section"
          firstSvgColor={secondColor}
          secondSvgColor={thirdColor}
          backgroundUnitSvg={fourthColor}
          firstChartColor={firstColor}
          secondChartColor={thirdColor}
          borderCardColor={firstColor}
          borderVerticalTableUnitColor={firstColor}
          backgroundRow={`${firstColor}1A`}
          backgroundForecastTable={firstColor}
          totalColor={firstColor}
          borderVerticalTableColor={firstColor}
          backgroundChart={`${thirdColor}1A`}
          templateName={COLOURFUL_TEMPLATE_NAME}
        />
      )}
      {bmcEnabled && isShowSections(sustainability) && (
        <Sustainability
          locales={locales}
          className="sustainability-colourful-web"
          cardClassName="bmc-page-suscard-colourful bmc-page-suscard--section"
          sliderStyles="sdg-card-colourful"
          iconTableStyles="sdg-card-colourful-icon"
          borderCardColor={thirdColor}
          iconFill={thirdColor}
          iconFillTable={thirdColor}
          firstSvgColor={fourthColor}
          secondSvgColor={`${thirdColor}1A`}
          backgroundTable={thirdColor}
          backgroundCells={white}
          backgroundSliderColor={white}
          backgroundIcon={white}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
          templateName={COLOURFUL_TEMPLATE_NAME}
        />
      )}
      {isShowSections(contact) && (
        <ContactUs
          data={contactUs}
          locales={locales}
          background={firstColor}
          backgroundButton={fourthColor}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      )}
    </StyledWrapper>
  );
};

export default ColourfulFunWebTemplate;
