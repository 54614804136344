import { cloneDeep } from 'lodash';

import * as Route from '../utils/serverRoutes';
import { transformResponseData } from '../utils/helpers';
import makeRequest from '../utils/makeRequest';

async function sendData(data, bmcId, dispatch) {
  const { response } = makeRequest({
    dispatch,
    method: 'put',
    url: Route.getKeepGrow(bmcId),
    data,
  });

  return response;
}

const defaultStrategy = {
  name: '',
  description: '',
};

const getKeepGrow = {
  state: {
    getStrategies: [],
    keepStrategies: [],
    growStrategies: [],
    referralStrategies: [],
    uploading: false,
  },
  reducers: {
    fetchGetKeepGrowsReducer(state, payload) {
      return { ...state, ...payload };
    },
    addStrategyReducer(state, { collectionName, id, dispatch }) {
      const collection = [...state[collectionName], defaultStrategy];

      sendData({ getKeepGrow: { [collectionName]: collection } }, id, dispatch);

      return { ...state, [collectionName]: collection };
    },
    updateStrategyReducer(
      state,
      { collectionName, index, name, value, id, dispatch },
    ) {
      const collection = cloneDeep(state[collectionName]);
      const item = collection[index];

      collection[index] = { ...item, [name]: value };
      sendData({ getKeepGrow: { [collectionName]: collection } }, id, dispatch);

      return { ...state, [collectionName]: collection };
    },
    updateGetKeepGrowReducer(state, payload) {
      return { ...state, ...payload };
    },
    removeStrategyReducer(state, { collectionName, index, id, dispatch }) {
      const collection = state[collectionName].filter((_, i) => index !== i);

      sendData({ getKeepGrow: { [collectionName]: collection } }, id, dispatch);

      return { ...state, [collectionName]: collection };
    },
  },
  effects: (dispatch) => ({
    async fetchGetKeepGrows(bmcId) {
      dispatch.getKeepGrow.updateGetKeepGrowReducer({ uploading: true });

      makeRequest({
        dispatch,
        url: Route.getKeepGrow(bmcId),
        success: (response) => {
          dispatch.getKeepGrow.fetchGetKeepGrowsReducer(
            transformResponseData(response.data.data),
          );
        },
        finish: () => {
          dispatch.getKeepGrow.updateGetKeepGrowReducer({ uploading: false });
        },
      });
    },
    addStrategy(payload, state) {
      dispatch.getKeepGrow.addStrategyReducer({
        ...payload,
        id: state.bmc.current.id,
        dispatch,
      });
    },
    async updateStrategy(payload, state) {
      dispatch.getKeepGrow.updateStrategyReducer({
        ...payload,
        id: state.bmc.current.id,
        dispatch,
      });
    },
    async removeStrategy(payload, state) {
      dispatch.getKeepGrow.removeStrategyReducer({
        ...payload,
        id: state.bmc.current.id,
        dispatch,
      });
    },
  }),
};

export default getKeepGrow;
