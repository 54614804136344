import { FIRST_COLOR_PALETTE_NUMBER } from '../const';

export const getCurrentSubtitleColor = (
  colorPaletteNumber,
  styleColor,
  defaultColor,
) => {
  return colorPaletteNumber === FIRST_COLOR_PALETTE_NUMBER
    ? defaultColor
    : styleColor;
};
