import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch, connect } from 'react-redux';

import Intro from './Intro';
import RevenueModel from './RevenueModel';
import Pricing from './Pricing';
import ProjectedSales from './ProjectedSales';
import Summary from './Summary';
import Section from '../../components/Section';
import * as AppRoute from '../../utils/appRoutes';
import { prepareComponents } from '../../utils/helpers';
import { select } from '../../rematcher/store';

const components = [Intro, RevenueModel, Pricing, ProjectedSales, Summary];

function Revenue({
  locales,
  locales: {
    revenue: { menu, sectionName },
  },
  progress,
}) {
  const {
    product: { fetchProducts },
    revenue: { fetchRevenues },
    bmc: { fetchCurrentBmc, updateBmc },
  } = useDispatch();

  const {
    bmc: { current: currentBmc },
  } = useSelector((state) => state);

  const { bmcId } = useParams();
  const preparedComponents = prepareComponents(
    components,
    bmcId,
    menu,
    AppRoute.revenue,
  );

  function getData() {
    fetchCurrentBmc(bmcId);
    fetchProducts(bmcId);
    fetchRevenues(bmcId);
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (currentBmc.id)
      updateBmc({
        id: currentBmc.id,
        name: 'revenues_progress',
        value: parseInt(progress),
      });
  }, [progress]);

  return (
    <Section
      components={preparedComponents}
      sectionName={sectionName}
      menuItems={menu}
      progress={progress}
      locales={locales}
      finalDestination={AppRoute.bmc(bmcId)}
      onComponentChange={getData}
    />
  );
}

const mapState = select((models) => ({
  progress: models.progress.revenueProgress,
}));

export default connect(mapState, null)(Revenue);
