import React from 'react';

import { getColorDependsOnBackgroundAndPalette } from '../../main/utils/helpers/portfolioArchTemplate/getColorDependsOnBackgroundAndPalette';

const ContactUs = ({
  locales: { portfolioWeb },
  data: { title, emailOrWebsite, description, fullName },
  backgroundSection,
  colorPaletteNumber,
}) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const textColor = getColorDependsOnBackgroundAndPalette(
    colorPaletteNumber,
    backgroundSection,
  );

  return (
    <div
      className="contact-us-architectural-web"
      style={{ backgroundColor: backgroundSection }}
    >
      <div className="header">
        <div
          className="architectural-title title custom-font-title"
          style={{ color: textColor }}
        >
          {portfolioWeb.contactUs.title}
        </div>
        <div>
          <div
            className="scroll-top custom-font-body"
            style={{ backgroundColor: backgroundSection, color: textColor }}
            onClick={scrollToTop}
          >
            {portfolioWeb.contactUs.scrollTop}
            <i className="icon-Arrow-Top" />
          </div>
        </div>
      </div>
      <div className="fields">
        <div
          className="description custom-font-body"
          style={{ color: textColor }}
        >
          {description}
        </div>
        <div
          className="form-field custom-font-body"
          style={{ color: textColor }}
        >
          {fullName}
        </div>
        <div
          className="form-field email custom-font-body"
          style={{ color: textColor }}
        >
          {emailOrWebsite}
        </div>
      </div>

      <div
        className="footer custom-font-body"
        style={{ borderColor: textColor, color: textColor }}
      >
        <div className="left ">
          {`© ${title}. ${portfolioWeb.contactUs.rightsReserve}`}
        </div>
        <div className="right">
          {portfolioWeb.contactUs.buildCreate}
          <span className="company-name">
            {portfolioWeb.contactUs.companyName}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
