import React from 'react';
import SvgIcon from '../../../components/SvgIcon';

export default function CommentAvatar({ url }) {
  if (url)
    return (
      <img
        className="comment-avatar-component"
        src={url}
        alt="avatar of sender"
        onError={(e) => {
          e.currentTarget.style.display = 'none';
        }}
      />
    );

  return <SvgIcon name="defaultUserAvatar" />;
}
