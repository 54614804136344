import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { truncate } from 'lodash';

import CustomerSegmentCard from '../../main/containers/customerSegment/CardColourfulArch';
import ImageDrop from '../../main/components/ImageDrop/ImageDropCS';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import {
  BOLD_TEMPLATE_NAME,
  IMAGE_ASPECT_RATIO_ONE,
} from '../../main/utils/const';

const CustomerSegmentBoldWeb = ({
  locales: {
    common,
    portfolioWeb,
    customerSegment: {
      intro: { card },
    },
  },
  locales,
  background,
  titleColor,
  textColor,
  backgroundImage,
  segmentListTitleStyle,
}) => {
  const [activeCardId, setActiveCardId] = useState(null);
  const { customerSegment } = useSelector((state) => state.portfolio);

  const { collection } = useSelector((state) => state.customerSegment);

  const activeCardData = useMemo(() => {
    if (collection.length && !activeCardId) {
      setActiveCardId(collection[0].id);
      return collection[0];
    }
    return collection.find((i) => i.id === activeCardId);
  }, [collection, activeCardId]);

  const singleCard = activeCardData && (
    <CustomerSegmentCard
      locales={common}
      isPreview
      subtitleColor={titleColor}
      segmentListTitleStyle={segmentListTitleStyle}
      textColor={textColor}
      card={{
        ...card,
        ...activeCardData,
        subtitle: activeCardData.name,
        title: null,
      }}
    />
  );

  const singleImage = activeCardData && (
    <ImageDrop
      locales={locales}
      backgroundImage={backgroundImage}
      titleImageColor={titleColor}
      templateName={BOLD_TEMPLATE_NAME}
      aspectRatio={IMAGE_ASPECT_RATIO_ONE}
      card={{
        ...card,
        ...activeCardData,
        subtitle: activeCardData.name,
        title: null,
      }}
    />
  );

  return (
    <div
      className="customer-segment-bold-web"
      style={{ backgroundColor: background }}
    >
      <SectionHeaderWeb
        templateName={BOLD_TEMPLATE_NAME}
        title={portfolioWeb.customerSegment}
        description={customerSegment.description}
        portfolioWeb={portfolioWeb}
        style={{ color: titleColor }}
        descriptionColor={textColor}
      />
      <div className="card-wrapper">
        <div className="left">
          <div className="cards-section">
            <div className="tabs">
              {collection.map(({ id, name }) => (
                <div
                  key={id}
                  onClick={() => setActiveCardId(id)}
                  className={classnames(
                    'item',
                    {
                      active: activeCardId === id,
                    },
                    'custom-font-body',
                  )}
                  style={{ color: titleColor }}
                >
                  {truncate(name, { length: 45 })}
                </div>
              ))}
            </div>
            <div className="main">{singleCard}</div>
          </div>
        </div>
        <div className="right">
          <div className="image-uploud"> {singleImage}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSegmentBoldWeb;
