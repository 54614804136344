import React, { useRef } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import useMediaQuery from 'react-use-media-query-hook';

import { sustainabilityNumber } from '../../utils/helpers';
import SvgIcon from '../../components/SvgIcon';
import { CURRENT_TEMPLATE_NAME } from '../../utils/const';

const Cards = ({
  collection: collectionCards,
  locales: {
    sustainability: {
      sdgs,
      intro: {
        card: { goalTitle },
      },
    },
  },
  iconFill,
  borderCardColor,
  backgroundSliderColor,
  sliderStyles,
  templateName,
  textColor,
  textStyle,
  arrowColor,
}) => {
  const isMobile = useMediaQuery('(max-width: 940px)');
  const isMiddleMobile = useMediaQuery('(max-width: 660px)');
  const slidesToScroll = 1;
  const slidesToShow = Math.min(
    (isMiddleMobile && 1) || (isMobile && 2) || 3,
    collectionCards.length,
  );
  const slick = useRef(null);

  const isCurrentTemplate = templateName === CURRENT_TEMPLATE_NAME;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll,
    arrows: false,
  };

  const handlePrevSlide = () => {
    if (slick.current) {
      slick.current.slickPrev();
    }
  };

  const handleNextSlide = () => {
    if (slick.current) {
      slick.current.slickNext();
    }
  };

  const sdgCard = (item) => {
    const sdgNum = sustainabilityNumber(item.sdg);
    const sdgLocale = sdgs[parseInt(sdgNum) - 1];
    return (
      <div
        key={item.id}
        className={classNames('card', sliderStyles, textStyle)}
        style={{ color: textColor }}
      >
        <div className="flip-inner">
          <div
            className={classNames(
              'flip-front',
              isCurrentTemplate && `sdg-card-color-${sdgNum}`,
              textStyle,
              'custom-font-body',
            )}
            style={{
              borderColor: borderCardColor,
              backgroundColor: backgroundSliderColor,
              color: textColor,
            }}
          >
            <div
              className="flip-front-icon flip-front-icon--big"
              style={{ fill: iconFill }}
            >
              <SvgIcon name={`sdg${sdgNum}`} />
            </div>
            <div className="arch-content">
              <div className="flip-front-big-title custom-font-body">
                {goalTitle} {sdgNum}
              </div>
              <div className="flip-front-title flip-front-title--margin-top custom-font-body">
                {sdgLocale && sdgLocale.title}
              </div>
            </div>
          </div>
          <div
            className={classNames(
              'flip-back',
              isCurrentTemplate && `sdg-card-color-${sdgNum}`,
              textStyle,
              'custom-font-body',
            )}
            style={{
              borderColor: borderCardColor,
              backgroundColor: backgroundSliderColor,
              color: textColor,
            }}
          >
            {sdgLocale && sdgLocale.description}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="cards-wrapper">
      {collectionCards.length > 1 && (
        <div
          onClick={handlePrevSlide}
          className="arrow left"
          style={{ borderColor: arrowColor }}
        />
      )}
      <Slider ref={slick} {...settings}>
        {collectionCards.map((item) => (
          <div key={item.id}>{sdgCard(item)}</div>
        ))}
      </Slider>
      {collectionCards.length > 1 && (
        <div
          onClick={handleNextSlide}
          className="arrow right"
          style={{ borderColor: arrowColor }}
        />
      )}
    </div>
  );
};

export default Cards;
