import React from 'react';
import classNames from 'classnames';

import Textarea from '../../components/Textarea';
import Button from '../../components/Button';
import ConfirmationModal from '../../components/ConfirmationModal';

export default function Card({
  name,
  className,
  editMode = false,
  addHandler,
  updateHandler,
  background,
  removeHandler,
  locales: { common },
  maxCount,
  card: { icon, title, subtitle, list },
  wBlur = true,
  titleColor,
  textColor,
  circleColor,
  lineColor,
  borderCardColor,
  textStyle,
  buttonStyle,
  buttonColor,
  backgroundButton,
}) {
  return (
    <div
      className={classNames('bmc-page-keycard-wrapper', className, {
        'bmc-page-keycard--edit-mode': editMode,
      })}
    >
      <div
        className="bmc-page-keycard"
        style={{ backgroundColor: background, borderColor: borderCardColor }}
      >
        {title && (
          <div className="bmc-page-keycard-header">
            <div className="bmc-page-keycard-header-icon">
              <img src={icon} alt="" />
            </div>
            <div
              className="bmc-page-keycard-header-title custom-font-title"
              style={{ color: titleColor }}
            >
              {title}
            </div>
          </div>
        )}

        {subtitle && (
          <div
            className="bmc-page-keycard-title custom-font-title"
            style={{ color: titleColor }}
          >
            {subtitle}
          </div>
        )}

        <ul className="bmc-page-keycard-list">
          {list.map((item) => (
            <li key={item.id} className="bmc-page-keycard-list-item">
              {editMode ? (
                <>
                  <div
                    className="circle"
                    style={{ backgroundColor: circleColor }}
                  />
                  <Textarea
                    value={item.description || ''}
                    delay={700}
                    className={`bmc-form-textarea ${textStyle} custom-font-body`}
                    onChange={(e) =>
                      updateHandler({
                        id: item.id,
                        name: 'description',
                        value: e.target.value,
                      })
                    }
                  />
                  {list.length > 1 && (
                    <ConfirmationModal
                      body="Delete this content. Are you sure?"
                      confirmText="Delete"
                      onConfirm={() => removeHandler({ id: item.id })}
                    >
                      <div className="bmc-page-keycard-list-item-remove">
                        <i className="icon-Delete" />
                      </div>
                    </ConfirmationModal>
                  )}
                </>
              ) : (
                <div
                  className="resorses-text custom-font-body"
                  style={{ color: textColor }}
                >
                  <div
                    className="circle"
                    style={{ backgroundColor: circleColor }}
                  />
                  <div
                    className="line"
                    style={{ backgroundColor: lineColor }}
                  />
                  {item.description}
                </div>
              )}
            </li>
          ))}
        </ul>
        {editMode && list.length < maxCount && (
          <Button
            title={`${common.create} ${name}`}
            wide
            onClick={addHandler}
            className={
              buttonStyle
                ? `${buttonStyle} custom-font-body`
                : `bmc-button--blue custom-font-body`
            }
            icon="icon-Add"
            style={{ color: buttonColor, background: backgroundButton }}
          />
        )}
      </div>
      {wBlur && <div className="bmc-page-keycard-blur" />}
    </div>
  );
}
