import { init } from '@rematch/core';

import notification from '../main/models/notification';
import alert from '../main/models/alert';
import user from '../main/models/user';

const store = init({
  models: {
    notification,
    alert,
    user,
  },
});

export default store;
