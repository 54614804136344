const common = {
  state: {
    confirmationModal: {
      show: false,
      body: '',
      handleConfirm: null,
      handleCancel: null,
    },
  },
  reducers: {
    setHandlers(state, { handleCancel, handleConfirm, body }) {
      return {
        ...state,
        confirmationModal: {
          ...state.confirmationModal,
          handleCancel,
          handleConfirm,
          body,
        },
      };
    },
    openModal(state) {
      return {
        ...state,
        confirmationModal: { ...state.confirmationModal, show: true },
      };
    },
    closeModal(state) {
      return {
        ...state,
        confirmationModal: { ...state.confirmationModal, show: false },
      };
    },
  },
};

export default common;
