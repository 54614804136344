import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { forceVisible } from 'react-lazyload';
import { find, isEmpty, merge } from 'lodash';

import FirstTimeTip from '../tips/FirstTimeTip';
import {
  ENABLE_ONBOARDING,
  COLOURFUL_TEMPLATE_NAME,
  BLOG_FRIENDLY_TEMPLATE_NAME,
  CURRENT_TEMPLATE_NAME,
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  BOLD_TEMPLATE_NAME,
} from '../../utils/const';
import Spinner from '../../components/Spinner';
import GlobalContext from '../../utils/GlobalContext';
import ChooseTemplateSection from './ChooseTemplateSection';
import CurrentTemplate from './currentTemplate';
import ColourfulFunTemplate from './colourfulFunTemplate';
import BlogFriendlyTemplate from './blogFriendly';
import ArchitecturalMinimalTemplate from './architecturalMinimal';
import BoldTemplate from './boldTemplate';

const PortfolioSectionAdmin = ({ locales, disabled = false }) => {
  const {
    sectionAvailabilitySettings: { bmc, testing },
  } = useContext(GlobalContext);

  const {
    portfolio: { page: pageLocales },
  } = locales;

  const {
    portfolioStyle: { activeTemplateName, templateSettings },
    portfolioTemplate: { collection },
  } = useSelector((state) => state);

  const {
    bmc: { setBmcView },
    portfolio: { fetchPortfolioData },
    portfolioStyle: { updatePortfolioStyleSettings },
    user: { updateCurrentUser },
  } = useDispatch();

  const {
    portfolio,
    bmc: { view },
  } = useSelector((state) => state);
  const { bmcId } = useParams();

  useEffect(() => {
    setBmcView('portfolio');
    return () => {
      setBmcView('');
    };
  }, []);

  useEffect(() => {
    const adminMain = document.querySelector('.admin-main');
    if (view === 'portfolio') adminMain.classList.add('padding-bottom-0');
    else adminMain.classList.remove('padding-bottom-0');
    return () => {
      adminMain.classList.remove('padding-bottom-0');
    };
  }, [view]);

  useEffect(() => {
    if (bmcId) {
      updateCurrentUser({ name: 'lastBmcId', value: bmcId });
      fetchPortfolioData(bmcId);
    }
  }, [bmcId]);

  useEffect(() => {
    // set up default templateSettings for current template if not exist
    if (
      !templateSettings ||
      (!isEmpty(templateSettings) && !templateSettings[activeTemplateName])
    ) {
      const obj = {};
      const currentTemplate = find(collection, ['sysName', activeTemplateName]);
      obj[activeTemplateName] = {
        colorPalette: currentTemplate?.colorPalette
          ? currentTemplate.colorPalette[0].join('|')
          : null,
      };
      updatePortfolioStyleSettings(merge(templateSettings, obj));
    }
  }, [templateSettings]);

  // we have to force visibility of lazy components
  setTimeout(() => forceVisible(), 1000);

  return (
    <>
      {ENABLE_ONBOARDING && (
        <FirstTimeTip page="portfolio" tips="portfolioTip" locales={locales} />
      )}

      <div className="bmc-main portfolio portfolio-admin">
        <div className="bmc-main-header" id="scroll">
          <div className="bmc-main-header-title">{pageLocales.title}</div>
        </div>
        <div className="bmc-canvas">
          <ChooseTemplateSection disabled={disabled} />
        </div>

        {portfolio.uploading ? (
          <Spinner className="margin-top-60" big />
        ) : (
          (activeTemplateName === CURRENT_TEMPLATE_NAME && (
            <CurrentTemplate
              locales={locales}
              portfolio={portfolio}
              bmc={bmc}
              testing={testing}
              disabled={disabled}
            />
          )) ||
          (activeTemplateName === COLOURFUL_TEMPLATE_NAME && (
            <ColourfulFunTemplate
              locales={locales}
              portfolio={portfolio}
              bmc={bmc}
              testing={testing}
              disabled={disabled}
            />
          )) ||
          (activeTemplateName === BLOG_FRIENDLY_TEMPLATE_NAME && (
            <BlogFriendlyTemplate
              locales={locales}
              portfolio={portfolio}
              bmc={bmc}
              testing={testing}
              disabled={disabled}
            />
          )) ||
          (activeTemplateName === ARCHITECTURAL_MINIMAL_TEMPLATE_NAME && (
            <ArchitecturalMinimalTemplate
              locales={locales}
              portfolio={portfolio}
              bmc={bmc}
              testing={testing}
              disabled={disabled}
            />
          )) ||
          (activeTemplateName === BOLD_TEMPLATE_NAME && (
            <BoldTemplate
              locales={locales}
              portfolio={portfolio}
              bmc={bmc}
              testing={testing}
              disabled={disabled}
            />
          ))
        )}
      </div>
    </>
  );
};

export default PortfolioSectionAdmin;
