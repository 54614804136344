import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BmcCanvasSection from './BmcCanvasSection';
import CardModal from '../CardModal';
import KeyPartnersCard from '../../keyResources/Card';
import SvgIcon from '../../../components/SvgIcon';
import { collectionEmpty } from '../../../utils/helpers';

import { KEY_RESOURCES_MAX_COUNT } from '../../../utils/const';

export default function KeyResources({
  locales,
  locales: { canvas },
  isDark,
  isPreview,
  isProgressBar,
  backgroundIcon,
  backgroundItems,
  backgroundSection,
  borderSectionColor,
  titleBMCSectionColor,
  textColor,
  iconFill,
  iconFillClose,
  templateName,
  sectionClassName,
  backgroundBMC,
  firstSvgColor,
  secondSvgColor,
  titleStyle,
  buttonStyle,
  iconStyle,
  styleColor,
  textStyle,
  titleColor,
  buttonColor,
  backgroundButton,
  isColourfulfunTemplate = false,
  disabled = false,
}) {
  const {
    keyResource,
    bmc: {
      current: { id },
    },
  } = useSelector((state) => state);

  const {
    keyResource: {
      fetchKeyResources,
      addKeyResource,
      updateKeyResource,
      removeKeyResource,
    },
  } = useDispatch();

  const [showKeyResourcesModal, setShowKeyResourcesModal] = useState(false);

  return (
    <>
      {showKeyResourcesModal && (
        <CardModal
          onBlackscreenClick={() => setShowKeyResourcesModal(false)}
          title={canvas.keyResources}
          icon={
            !templateName ? 'icon-key-resources' : <SvgIcon name="iconKR" />
          }
          to={`/bmcs/${id}/key_resources/intro`}
          locales={locales}
          className="bmc-modal--orange custom-font-body"
          onOpen={() => fetchKeyResources(id)}
          isOpen={showKeyResourcesModal}
          isPreview={isPreview}
          titleStyle={titleStyle}
          buttonStyle={buttonStyle}
          firstSvgColor={firstSvgColor}
          secondSvgColor={secondSvgColor}
          iconFill={iconFill}
          iconStyle={iconStyle}
          backgroundIcon={backgroundIcon}
          backgroundBMC={backgroundBMC}
          titleColor={titleColor}
          buttonColor={buttonColor}
          backgroundButton={backgroundButton}
          iconFillClose={iconFillClose}
          isColourfulfunTemplate={isColourfulfunTemplate}
          disabled={disabled}
        >
          <KeyPartnersCard
            locales={locales}
            editMode={!isPreview}
            wBlur={false}
            isPopUp
            addHandler={addKeyResource}
            updateHandler={updateKeyResource}
            removeHandler={removeKeyResource}
            name={canvas.keyPartners}
            maxCount={KEY_RESOURCES_MAX_COUNT}
            card={{
              ...locales.keyResources.intro.card,
              list: keyResource.collection,
              title: false,
              subtitle: false,
            }}
            circleColor={styleColor}
            textStyle={textStyle}
            buttonStyle={buttonStyle}
            background={backgroundBMC}
            buttonColor={buttonColor}
            backgroundButton={backgroundButton}
            textColor={textColor}
          />
        </CardModal>
      )}

      <BmcCanvasSection
        className={`bmc-canvas-section--skinny bmc-canvas-section--short bmc-canvas-section--orange bmc-canvas-section-5 ${sectionClassName}`}
        title={canvas.keyResources}
        dark={isDark}
        showButton={!isPreview && collectionEmpty(keyResource.collection)}
        to={`/bmcs/${id}/key_resources/intro`}
        locales={locales}
        icon={!templateName ? 'icon-key-resources' : <SvgIcon name="iconKR" />}
        isPreview={isPreview}
        isProgressBar={isProgressBar}
        uploading={keyResource.uploading}
        onClick={() => setShowKeyResourcesModal(true)}
        backgroundIcon={backgroundIcon}
        backgroundSection={backgroundSection}
        borderSectionColor={borderSectionColor}
        titleBMCSectionColor={titleBMCSectionColor}
        iconFill={iconFill}
        iconStyle={iconStyle}
        templateName={templateName}
      >
        {keyResource.collection.map(
          (item) =>
            item.description && (
              <div
                key={item.id}
                className="bmc-canvas-section-items-item custom-font-body"
                style={{ backgroundColor: backgroundItems, color: textColor }}
                onClick={() => setShowKeyResourcesModal(true)}
              >
                {item.description}
              </div>
            ),
        )}
      </BmcCanvasSection>
    </>
  );
}
