import * as Route from '../utils/serverRoutes';
import {
  transformResponseCollection,
  updateCollectionById,
  payloadToData,
} from '../utils/helpers';
import makeRequest from '../utils/makeRequest';

const cost = {
  state: {
    collection: [],
    uploading: false,
  },
  reducers: {
    fetchCostsReducer(state, { collection }) {
      return { ...state, collection };
    },
    addCostReducer(state, payload) {
      return { ...state, collection: [...state.collection, payload] };
    },
    updateCostReducer(state, payload) {
      return {
        ...state,
        collection: updateCollectionById(state.collection, payload.id, payload),
      };
    },
    removeCostReducer(state, { id }) {
      const collection = state.collection.filter((e) => e.id !== id);

      return { ...state, collection };
    },
    updateAttributeReducer(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    async fetchCosts(bmcId) {
      dispatch.cost.updateAttributeReducer({ uploading: true });

      makeRequest({
        dispatch,
        url: Route.costs(bmcId),
        success: (response) => {
          dispatch.cost.fetchCostsReducer({
            collection: transformResponseCollection(response.data.data),
          });
        },
        finish: () => {
          dispatch.cost.updateAttributeReducer({ uploading: false });
        },
      });
    },
    async addCost(_, state) {
      makeRequest({
        dispatch,
        method: 'post',
        url: Route.costs(state.bmc.current.id),
      });
    },
    async updateCost(payload, state) {
      const data = payloadToData(payload);
      dispatch.cost.updateCostReducer(data);

      makeRequest({
        dispatch,
        method: 'put',
        url: Route.cost(state.bmc.current.id, payload.id),
        data: { cost: data },
      });
    },
    async removeCost(payload, state) {
      makeRequest({
        dispatch,
        method: 'delete',
        url: Route.cost(state.bmc.current.id, payload.id),
      });
    },
  }),
};

export default cost;
