import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { useHistory } from 'react-router';

import TeamMembers from './TeamMembers';
import Modal from '../../components/Modal';
import Spinner from '../../components/Spinner';
import { SELECT_STYLES } from '../../utils/const';
import * as Route from '../../utils/serverRoutes';
import ConfirmationModal from '../../components/ConfirmationModal';
import * as AppRoute from '../../utils/appRoutes';
import makeRequest from '../../utils/makeRequest';
import DeleteBmcOwnerModal from './DeleteBmcOwnerModal';

const inputOptions = (collection, users) =>
  collection
    .filter(
      (item) => !users.find((user) => user.email === item.attributes.email),
    )
    .map((item) => {
      return { label: item.attributes.email, value: item.attributes.email };
    });

export default function UpdateBmcModal({
  bmcId,
  onClose,
  locales,
  locales: { teams, common },
}) {
  const history = useHistory();
  const defaultSelectValue = { label: '', value: '' };
  const [inputValue, setInputValue] = useState('');
  const [selectValue, setSelectValue] = useState(defaultSelectValue);
  const [options, setOptions] = useState([]);
  const [showBmcOwnerDeleteModal, setShowBmcOwnerDeleteModal] = useState(false);

  const {
    bmc: {
      current: currentBmc,
      collection,
      duplicatingBmc,
      deletingBmc,
      users,
    },
    user: { current },
    chatGroup: { selectedChatGroup },
  } = useSelector((state) => state);

  const {
    bmc: {
      updateBmc,
      removeBmc,
      cloneBmc,
      fetchBmcUsers,
      setCurrentBmcReducer,
      fetchBmcs,
      updateCurrentBmc,
    },
    chatGroup: { fetchChatGroupMembers },
  } = useDispatch();

  const dispatch = useDispatch();

  if (!currentBmc) return null;

  const { id, name } = currentBmc;
  const deleting = deletingBmc === id;
  const duplicating = duplicatingBmc === id;

  useEffect(() => {
    fetchBmcUsers(id);
  }, []);

  const searchBmcUsers = (value) => {
    setInputValue(value);

    if (value) {
      makeRequest({
        dispatch,
        url: Route.searchSuitableStudent(value, id),
        success: (response) => {
          setOptions(inputOptions(response.data.data, users));
        },
      });
    }
  };

  const onBmcOwnerChoose = () => {
    setShowBmcOwnerDeleteModal(true);
  };

  const handleOwnerDelete = (ownerId, userId) => {
    makeRequest({
      dispatch,
      url: Route.bmcOwner(id, userId, ownerId),
      method: 'delete',
      showSuccessToast: true,
      success: () => {
        if (ownerId === current.id) {
          const newCurrentProject = collection.find((e) => e.id !== id);
          onClose();
          history.push(AppRoute.bmc(newCurrentProject.id));
          setCurrentBmcReducer(newCurrentProject);
          updateCurrentBmc(id);
          fetchBmcs();
        } else {
          updateCurrentBmc(id);
          fetchBmcUsers(id);
        }
      },
    });
    setShowBmcOwnerDeleteModal(false);
  };

  const removeBmcUser = (userId) => {
    makeRequest({
      dispatch,
      url: Route.bmcUser(id, userId),
      method: 'delete',
      success: () => {
        if (userId === current.id) {
          const newCurrentProject = collection.find((e) => e.id !== id);
          onClose();
          history.push(AppRoute.bmc(newCurrentProject.id));
          setCurrentBmcReducer(newCurrentProject);
          fetchBmcs();
        } else {
          fetchBmcUsers(id);
        }
        if (selectedChatGroup)
          fetchChatGroupMembers({ chatGroupId: selectedChatGroup.id });
      },
    });
  };

  async function removeProject(projectId) {
    const newCurrentProject = await removeBmc({ id: projectId });

    if (newCurrentProject) {
      onClose();
      history.push(AppRoute.bmc(newCurrentProject.id));
    }
  }

  async function cloneProject(projectId) {
    const newProject = await cloneBmc({ id: projectId });

    if (newProject) {
      onClose();
      history.push(AppRoute.bmc(newProject.id));
    }
  }

  const changeSelect = ({ value }) => {
    setInputValue('');
    setSelectValue(defaultSelectValue);

    makeRequest({
      dispatch,
      url: Route.bmcUsers(bmcId),
      method: 'post',
      data: { user: { email: value } },
      success: () => {
        fetchBmcUsers(id);
        if (selectedChatGroup)
          fetchChatGroupMembers({ chatGroupId: selectedChatGroup.id });
      },
    });
  };

  return (
    <>
      <Modal onBlackscreenClick={onClose} className="bmc-modal--form">
        <div className="bmc-modal-container">
          <div className="bmc-modal-header">
            <div className="bmc-modal-header-title">
              {teams.projectSettings}
            </div>
            <div className="bmc-modal-header-action" onClick={onClose}>
              <i className="icon-Cancel" />
            </div>
          </div>

          <div className="bmc-form">
            <div className="bmc-form-fieldset">
              <label htmlFor="project-name" className="bmc-form-label">
                {teams.projectName}
              </label>
              <input
                id="project-name"
                value={name}
                onChange={(e) =>
                  updateBmc({ id, name: 'name', value: e.target.value })
                }
                className="bmc-form-input"
                placeholder={teams.projectNamePlaceholder}
                data-hj-allow
              />
            </div>

            <div className="bmc-form-fieldset margin-top-20 margin-bottom-20">
              <label htmlFor="email" className="bmc-form-label">
                {teams.orInviteTeammatesViaEmail}
              </label>
              <div className="bmc-form-label-sub">
                {teams.orInviteTeammatesViaEmailSub}
              </div>
              <div className="bmc-form-label-sub">
                {teams.orInviteTeammatesViaEmailNote}
              </div>
              <Select
                id="email"
                className="bmc-form-select"
                styles={SELECT_STYLES}
                options={options}
                inputValue={inputValue}
                value={selectValue}
                placeholder={teams.orInviteTeammatesViaEmailPlaceholder}
                onInputChange={searchBmcUsers}
                onChange={changeSelect}
                noOptionsMessage={() => teams.noOptionsMessage}
              />
            </div>

            <TeamMembers
              users={users}
              removeBmcUser={removeBmcUser}
              locales={locales}
              onBmcOwnerChoose={onBmcOwnerChoose}
              ownerId={currentBmc.userId}
            />

            <div className="bmc-form-line" />

            <div className="bmc-form-actions">
              {collection.length > 1 && (
                <ConfirmationModal
                  body={common.areYouSure}
                  confirmText={common.deleteText}
                  className="bmc-form-action bmc-form-action--warn bmc-form-action--spinner float-left"
                  onConfirm={() => removeProject(id)}
                >
                  {deleting ? (
                    <>
                      {common.deletingLong}
                      <Spinner />
                    </>
                  ) : (
                    teams.deleteProject
                  )}
                </ConfirmationModal>
              )}

              <ConfirmationModal
                body={common.areYouSure}
                confirmText={common.deleteText}
                className="bmc-form-action bmc-form-action--spinner float-right"
                onConfirm={() => cloneProject(id)}
              >
                {duplicating ? (
                  <>
                    <Spinner />
                    {common.duplicatingLong}
                  </>
                ) : (
                  teams.cloneProject
                )}
              </ConfirmationModal>
            </div>
          </div>
        </div>
      </Modal>
      <DeleteBmcOwnerModal
        visible={showBmcOwnerDeleteModal}
        onClose={() => setShowBmcOwnerDeleteModal(false)}
        users={users}
        ownerId={currentBmc.userId}
        onOwnerDelete={handleOwnerDelete}
        locales={locales}
      />
    </>
  );
}
