import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Dropzone from '../../components/Dropzone';
import Textarea from '../../components/Textarea';
import ConfirmationModal from '../../components/ConfirmationModal';
import {
  IMAGE_ASPECT_RATIO_VP_CARD,
  UNIQUE_SELLING_POINTS_MAX_COUNT,
} from '../../utils/const';
import { renderImage } from '../../utils/helpers/renderImage';

const blurComponent = (
  <div className="bmc-page-card-blur-wrapper">
    <div className="bmc-page-card-blur" />
  </div>
);

export default function Card({
  locales,
  className,
  blur = true,
  editMode = false,
  dropzoneMode = false,
  portfolioMode = false,
  disabled = false,
  backgroundImage,
  titleImageColor,
  templateName,
  card: {
    title,
    solution,
    solutionTitle,
    features,
    featuresTitle,
    points,
    pointsTitle,
    image,
    imageCoords,
    icon,
  },
  textStyle,
  buttonStyle,
  backgroundBMC,
  titleStyle,
  firstSvgColor,
  secondSvgColor,
  buttonColor,
  backgroundButton,
  textColor,
  isColourfulfunTemplate = false,
  isTemplates = false,
}) {
  const {
    valueProposition: {
      updateAttribute,
      updateAttributeImage,
      updateValuePropositionImageCoords,
    },
    uniqueSellingPoint: {
      addUniqueSellingPoint,
      updateUniqueSellingPoint,
      removeUniqueSellingPoint,
    },
  } = useDispatch();

  const { uploadingImage } = useSelector((state) => state.valueProposition);

  const updatePoints = (id, value) => {
    const currentIndex = points.findIndex((i) => i.id === id);
    updateUniqueSellingPoint({
      id,
      name: 'description',
      index: currentIndex,
      value,
    });
  };

  const onImageDrop = ([file], openCropperHandler) => {
    updateAttributeImage({
      name: 'image',
      value: URL.createObjectURL(file),
      blob: file,
      success: () => {
        openCropperHandler();
      },
    });
  };

  const onImageCropperSave = (croppedAreaPixels, innerCropData, zoom) => {
    updateValuePropositionImageCoords({
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: imageCoords,
    });
  };

  return (
    <div className="bmc-page-card-wrapper">
      <div
        className={classNames(
          'bmc-page-card bmc-page-card--value-proposition-mode',
          className,
          {
            'bmc-page-card--portfolio-mode': portfolioMode,
          },
        )}
        style={{ backgroundColor: backgroundBMC }}
      >
        <div className="bmc-page-card-inner">
          {editMode || dropzoneMode ? (
            <Dropzone
              className="bmc-page-card-wide-image"
              onDrop={onImageDrop}
              onCropperSave={onImageCropperSave}
              locales={locales}
              image={image}
              coords={imageCoords}
              uploading={uploadingImage}
              aspectRatio={IMAGE_ASPECT_RATIO_VP_CARD}
              disabled={disabled}
              background={{ background: backgroundImage }}
              firstSvgColor={firstSvgColor}
              secondSvgColor={secondSvgColor}
              titleStyle={titleStyle}
              buttonStyle={buttonStyle}
              cropper
              isColourfulfunTemplate={isColourfulfunTemplate}
              isTemplates={isTemplates}
            />
          ) : (
            renderImage(
              templateName,
              image,
              backgroundImage,
              locales.portfolioWeb.valueProposition,
              IMAGE_ASPECT_RATIO_VP_CARD,
              titleImageColor,
            )
          )}
        </div>

        <div className="bmc-page-card-inner">
          {title && (
            <div className="bmc-page-card-row">
              <img src={icon} className="bmc-page-card-icon" alt="" />
              <div
                className={`bmc-page-card-big-title ${textStyle} custom-font-body`}
              >
                {title}
              </div>
            </div>
          )}

          <div className="bmc-page-card-row">
            <div
              className={`bmc-page-card-title ${textStyle} custom-font-body`}
              style={{ color: textColor }}
            >
              {solutionTitle}
            </div>
            {editMode ? (
              <Textarea
                value={solution}
                delay={700}
                className={`bmc-form-textarea ${textStyle} custom-font-body`}
                onChange={(e) =>
                  updateAttribute({ name: 'product', value: e.target.value })
                }
              />
            ) : (
              <div
                className={`bmc-page-card-text ${textStyle} custom-font-body`}
                style={{ color: textColor }}
              >
                {solution}
              </div>
            )}
          </div>

          <div className="bmc-page-card-row">
            <div
              className={`bmc-page-card-title ${textStyle} custom-font-body`}
              style={{ color: textColor }}
            >
              {featuresTitle}
            </div>
            {editMode ? (
              <Textarea
                value={features}
                delay={700}
                className={`bmc-form-textarea ${textStyle} custom-font-body`}
                onChange={(e) =>
                  updateAttribute({ name: 'reason', value: e.target.value })
                }
              />
            ) : (
              <div
                className={`bmc-page-card-text ${textStyle} custom-font-body`}
                style={{ color: textColor }}
              >
                {features}
              </div>
            )}
          </div>

          <div className="bmc-page-card-row bmc-page-card-usps">
            <div
              className={`bmc-page-card-title ${textStyle} custom-font-body`}
              style={{ color: textColor }}
            >
              {pointsTitle}
            </div>
            {editMode ? (
              <div className="is-full-width">
                {points.map(({ id, description }) => (
                  <div className="relative usp" key={id}>
                    <Textarea
                      key={id}
                      value={description}
                      delay={700}
                      className={`bmc-form-textarea ${textStyle} custom-font-body`}
                      onChange={(e) => updatePoints(id, e.target.value)}
                    />

                    {points.length > 1 && (
                      <ConfirmationModal
                        body="Delete this content. Are you sure?"
                        confirmText="Delete"
                        onConfirm={() => removeUniqueSellingPoint({ id })}
                      >
                        <div className="bmc-page-card-remove">
                          <i className="icon-Delete" />
                        </div>
                      </ConfirmationModal>
                    )}
                  </div>
                ))}
                {points.length < UNIQUE_SELLING_POINTS_MAX_COUNT && (
                  <button
                    onClick={addUniqueSellingPoint}
                    className={classNames(
                      'bmc-button bmc-button--wide custom-font-body',
                      buttonStyle ? `${buttonStyle}` : 'bmc-button--blue',
                    )}
                    style={{ color: buttonColor, background: backgroundButton }}
                  >
                    <i className="icon-Add" />
                    <span>Create USP</span>
                  </button>
                )}
              </div>
            ) : (
              <div
                className={`bmc-page-card-text ${textStyle} custom-font-body`}
                style={{ color: textColor }}
              >
                {points.map((point, index) => (
                  <div className="margin-bottom-10" key={point.id || index}>
                    {point.description}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {blur && blurComponent}
    </div>
  );
}
