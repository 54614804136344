import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CompetitorsCard from '../../valueProposition/CompetitorsCardTemplates';
import {
  COLOURFUL_TEMPLATE_NAME,
  COMMENT_ANALYSIS,
  CURRENT_TEMPLATE_NAME,
} from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import { getCurrentCanvasIcon } from '../../../utils/helpers/getCurrentCanvasIcon';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import SvgIcon from '../../../components/SvgIcon';

const CompetitorAnalysis = ({
  data: { description },
  locales,
  locales: { portfolio },
  className,
  backgroundSection,
  colorTemplate,
  headTableColor,
  backgroundHeadTable,
  backgroundTable,
  styleColor,
  sectionHeaderTitleColor,
  descriptionColor,
  firstSvgColor,
  secondSvgColor,
  titleStyle,
  buttonStyle,
  lightButtonStyle,
  backgroundImage,
  sliderColor,
  textColor,
  borderTableColor,
  backgroundModal,
  borderImageColor,
  titleColor,
  buttonSecondColor,
  buttonFirstColor,
  backgroundSecondButton,
  templateName,
  disabled = false,
}) => {
  const {
    competitor: { collection },
  } = useSelector((state) => state);

  const {
    portfolio: { updateSectionField },
  } = useDispatch();

  const isCurrentTemplate = templateName === CURRENT_TEMPLATE_NAME;
  const isColourfulfunTemplate = templateName === COLOURFUL_TEMPLATE_NAME;

  return (
    <div
      className={`${className} relative`}
      style={{ backgroundColor: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_ANALYSIS} />
      {isCurrentTemplate && getCurrentCanvasIcon('VPCanvas', styleColor)}
      {isColourfulfunTemplate && (
        <>
          <div className="svg-funCAthirteen" style={{ fill: firstSvgColor }}>
            <SvgIcon name="funCAthirteen" />
          </div>
          <div className="svg-funCAfourteen" style={{ fill: secondSvgColor }}>
            <SvgIcon name="funCAfourteen" />
          </div>
        </>
      )}
      <div className="content">
        <SectionHeader
          templateName={templateName}
          title={portfolio.competitorAnalysis.sectionName}
          description={description}
          onChange={(e) =>
            updateSectionField({
              sectionName: 'competitorAnalysis',
              field: 'description',
              name: 'competitorAnalysisText',
              value: e.target.value,
            })
          }
          portfolio={portfolio}
          className={isCurrentTemplate && 'green-title'}
          style={{ color: sectionHeaderTitleColor }}
          descriptionColor={descriptionColor}
          disabled={disabled}
        />
        <CompetitorsCard
          locales={locales}
          disabled={disabled}
          className="margin-top-40"
          card={{
            ...locales.valueProposition.intro.competitorsCard,
            competitors: collection,
          }}
          colorTemplate={colorTemplate}
          backgroundHeadTable={backgroundHeadTable}
          backgroundTable={backgroundTable}
          firstSvgColor={firstSvgColor}
          secondSvgColor={secondSvgColor}
          titleStyle={titleStyle}
          buttonStyle={buttonStyle}
          lightButtonStyle={lightButtonStyle}
          headTableColor={headTableColor}
          backgroundImage={backgroundImage}
          sliderColor={sliderColor}
          textColor={textColor}
          borderTableColor={borderTableColor}
          backgroundModal={backgroundModal}
          borderImageColor={borderImageColor}
          titleColor={titleColor}
          buttonSecondColor={buttonSecondColor}
          buttonFirstColor={buttonFirstColor}
          backgroundSecondButton={backgroundSecondButton}
          isTemplates
          isColourfulfunTemplate={isColourfulfunTemplate}
        />
      </div>
    </div>
  );
};

export default CompetitorAnalysis;
