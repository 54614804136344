import * as Route from '../utils/serverRoutes';
import { transformResponseCollection } from '../utils/helpers';
import makeRequest from '../utils/makeRequest';

const portfolioFile = {
  state: {
    collection: [],
    uploading: false,
    uploadingFile: false,
  },
  reducers: {
    fetchPortfolioFilesReducer(state, { collection }) {
      return { ...state, collection };
    },
    addPortfolioFileReducer(state, payload) {
      return { ...state, collection: [...state.collection, payload] };
    },
    removePortfolioFileReducer(state, { id }) {
      const collection = state.collection.filter((e) => e.id !== id);

      return { ...state, collection };
    },
    updateAttributeReducer(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    fetchPortfolioFiles(bmcId) {
      dispatch.portfolioFile.updateAttributeReducer({ uploading: true });

      makeRequest({
        dispatch,
        url: Route.portfolioFiles(bmcId),
        success: (response) => {
          dispatch.portfolioFile.fetchPortfolioFilesReducer({
            collection: transformResponseCollection(response.data.data),
          });
        },
        finish: () => {
          dispatch.portfolioFile.updateAttributeReducer({ uploading: false });
        },
      });
    },
    addPortfolioFile(file, state) {
      dispatch.portfolioFile.updateAttributeReducer({ uploadingFile: true });

      const formData = new FormData();
      formData.append('portfolio_file[file]', file);

      makeRequest({
        dispatch,
        method: 'post',
        data: formData,
        url: Route.portfolioFiles(state.bmc.current.id),
        finish: () => {
          dispatch.portfolioFile.updateAttributeReducer({
            uploadingFile: false,
          });
        },
      });
    },
    removePortfolioFile({ id }, state) {
      makeRequest({
        method: 'delete',
        url: Route.portfolioFile(state.bmc.current.id, id),
      });
    },
  }),
};

export default portfolioFile;
