import {
  BLOG_FRIENDLY_TEMPLATE_NAME,
  COLOURFUL_TEMPLATE_NAME,
  CURRENT_TEMPLATE_NAME,
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  TECH_MODERN_TEMPLATE_NAME,
  BOLD_TEMPLATE_NAME,
} from '../const';

export const getTitleStyle = (templateName) => {
  const titleStyle = {
    [CURRENT_TEMPLATE_NAME]: 'current-title title custom-font-title',
    [COLOURFUL_TEMPLATE_NAME]: 'colourful-title title custom-font-title',
    [BLOG_FRIENDLY_TEMPLATE_NAME]: 'blogFriendly-title title custom-font-title',
    [ARCHITECTURAL_MINIMAL_TEMPLATE_NAME]:
      'architectural-title title custom-font-title',
    [TECH_MODERN_TEMPLATE_NAME]: 'tech-title title custom-font-title',
    [BOLD_TEMPLATE_NAME]: 'bold-title title custom-font-title',
  };
  return titleStyle[templateName];
};
