import React from 'react';
import { useSelector } from 'react-redux';

import KeyActivity from '../../main/containers/keyActivity/Card';
import KeyResources from '../../main/containers/keyResources/Card';
import { COLORS, TECH_MODERN_TEMPLATE_NAME } from '../../main/utils/const';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';

const { white } = COLORS;

const KeyActivitiesTechWeb = ({
  locales,
  locales: {
    keyActivity: {
      intro: { card },
    },
    keyResources: {
      intro: { card: resourceCard },
    },
    keyPartners: {
      intro: { card: partnerCard },
    },
    portfolioWeb,
  },
  background,
  styleColor,
}) => {
  const { collection } = useSelector((state) => state.keyActivity);
  const { collection: partnerCollection } = useSelector(
    (state) => state.keyPartner,
  );
  const { collection: resourcesCollection } = useSelector(
    (state) => state.keyResource,
  );
  const { keyActivities } = useSelector((state) => state.portfolio);

  return (
    <div
      className="key-activities-tech-web section-web"
      style={{ backgroundColor: background }}
    >
      <SectionHeaderWeb
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolioWeb.keyThings}
        description={keyActivities.description}
        portfolioWeb={portfolioWeb}
        style={{ color: styleColor }}
      />

      <KeyActivity
        locales={locales}
        className="key-activity bmc-page-keycard--portfolio-edit custom-font-body"
        card={{ ...card, steps: collection, title: null }}
        wBlur={false}
        templateName={TECH_MODERN_TEMPLATE_NAME}
        titleColor={styleColor}
        background={white}
      />
      <div
        className="key-resources-wrapper"
        style={{ backgroundColor: background }}
      >
        <KeyResources
          locales={locales}
          className="key-resource custom-font-body"
          card={{ ...partnerCard, list: partnerCollection, title: null }}
          wBlur={false}
          background={white}
          titleColor={styleColor}
          circleColor={styleColor}
        />
        <KeyResources
          locales={locales}
          className="key-resource custom-font-body"
          card={{ ...resourceCard, list: resourcesCollection, title: null }}
          wBlur={false}
          background={white}
          titleColor={styleColor}
          circleColor={styleColor}
        />
      </div>
    </div>
  );
};

export default KeyActivitiesTechWeb;
