import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import '../main/utils/axios';
import PortfolioContainer from './containers/Portfolio';
import store from './rematcher/store';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Portfolio = ({ locales, bmc, sectionAvailabilitySettings }) => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route
            path="/portfolio/:portfolioId"
            render={({ match: { url } }) => (
              <>
                <Route
                  path={`${url}/`}
                  component={() => (
                    <PortfolioContainer
                      locales={locales}
                      bmc={bmc}
                      sectionAvailabilitySettings={sectionAvailabilitySettings}
                    />
                  )}
                  exact
                />
                <Route
                  path={`${url}/:sections`}
                  component={() => (
                    <PortfolioContainer
                      locales={locales}
                      bmc={bmc}
                      sectionAvailabilitySettings={sectionAvailabilitySettings}
                    />
                  )}
                />
              </>
            )}
          />
        </Switch>
      </Router>
    </Provider>
  );
};

export default Portfolio;
