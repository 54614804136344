import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TeamCard from './TeamCard';
import {
  COMMENT_TEAM,
  TECH_MODERN_TEMPLATE_NAME,
} from '../../../../utils/const';
import SectionHeader from '../../../../components/SectionHeader';
import PortfolioCommentIcon from '../../../../components/PortfolioCommentIcon';

const TeamTech = ({
  data: { description },
  locales,
  locales: { portfolio },
  styleColor,
  background,
  backgroundImage,
  disabled = false,
}) => {
  const {
    portfolioTeammate: { addPortfolioTeammate },
    portfolio: { updateSectionField },
  } = useDispatch();
  const {
    portfolioTeammate: { collection },
  } = useSelector((state) => state);

  return (
    <div
      className="team-tech section relative"
      style={{ backgroundColor: background }}
    >
      <PortfolioCommentIcon title={COMMENT_TEAM} />
      <SectionHeader
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolio.team.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'team',
            field: 'description',
            name: 'teamDescription',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        style={{ color: styleColor }}
      />

      <div className="team-tech-cards custom-font-body">
        {collection.map((teammate, i) => (
          <TeamCard
            locales={locales}
            teammate={teammate}
            index={i}
            key={teammate.id}
            disabled={disabled}
            backgroundImage={backgroundImage}
            borderColorTeam={`${styleColor}26`}
          />
        ))}

        {!disabled && (
          <div className="add-team-card__wrapper">
            <div className="add-team-card">
              <div className="add-team-card-hover">
                <div className="center-block" onClick={addPortfolioTeammate}>
                  <i className="icon-Add" />
                  {portfolio.team.addNewTeamMember}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamTech;
