import React from 'react';
import { useSelector } from 'react-redux';

import { removeNewLines } from '../../main/utils/helpers';
import { renderImage } from '../../main/utils/helpers/renderImage';
import {
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  PORTFOLIO_INTRO_BG_ASPECT_RATIO,
} from '../../main/utils/const';
import { renderLogo } from '../../main/utils/helpers/renderLogo';
import { getColorDependsOnBackgroundAndPalette } from '../../main/utils/helpers/portfolioArchTemplate/getColorDependsOnBackgroundAndPalette';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';

const Introduction = ({
  backgroundSection,
  colorPaletteNumber,
  backgroundImage,
  isFourthColorPaletteNumber = false,
}) => {
  const { introduction } = useSelector((state) => state.portfolio);

  const textColor = getColorDependsOnBackgroundAndPalette(
    colorPaletteNumber,
    backgroundSection,
  );
  const textImageColor =
    isFourthColorPaletteNumber && getColorDependsOnBackground(backgroundImage);

  return (
    <div
      className="introduction-architectural-web-wrapper"
      style={{ backgroundColor: backgroundSection }}
    >
      <div className="image">
        {renderImage(
          ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
          introduction.introBg,
          backgroundImage,
          'Introduction',
          PORTFOLIO_INTRO_BG_ASPECT_RATIO,
          textImageColor,
        )}
      </div>

      {renderLogo(
        ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
        introduction.introLogo,
        backgroundImage,
        'Logo',
        textImageColor,
      )}

      <div className="content-intro">
        <div
          className="architectural-title-web title custom-font-title"
          style={{ color: textColor }}
        >
          {introduction.title}
        </div>
        <div
          className="description custom-font-body"
          style={{ color: textColor }}
        >
          {introduction.description && removeNewLines(introduction.description)}
        </div>
      </div>
    </div>
  );
};

export default Introduction;
