import React from 'react';
import { useSelector } from 'react-redux';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import { TECH_MODERN_TEMPLATE_NAME } from '../../main/utils/const';

const Viability = ({
  locales: { portfolioWeb },
  backgroundSection,
  colorPaletteNumber,
}) => {
  const { viability } = useSelector((state) => state.portfolio);

  return (
    <div
      className="viability-tech-web section-web"
      style={{ background: backgroundSection }}
    >
      <SectionHeaderWeb
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolioWeb.viability}
        description={viability.description}
        portfolioWeb={portfolioWeb}
        colorPaletteNumber={colorPaletteNumber}
      />
    </div>
  );
};

export default Viability;
