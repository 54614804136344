import React from 'react';
import stc from 'string-to-color';

const HEXtoHSL = (hexColor) => {
  // eslint-disable-next-line react/destructuring-assignment
  let hex = hexColor.replace(/#/g, '');
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((color) => {
        return color + color;
      })
      .join('');
  }
  const result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})[\da-z]{0,0}$/i.exec(
    hex,
  );
  if (!result) {
    return null;
  }
  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h;
  let s;
  let l = (max + min) / 2;
  if (max === min) {
    h = 0;
    s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }
  s *= 100;
  s = Math.round(s);
  l *= 100;
  l = Math.round(l);
  h = Math.round(360 * h);

  return {
    h,
    s,
    l,
  };
};

const getHSLStyle = ({ h, s, l }) => {
  return `hsl(${h},${s}%,${l}%)`;
};

export default function Avatar({ alt, fullName, url, size = 'lg' }) {
  if (url) {
    return (
      <img
        className={`admin-avatar-image size-${size}`}
        src={url}
        title={fullName}
        alt={alt}
      />
    );
  }

  const generatedHLSColor = HEXtoHSL(stc(fullName));
  const hlsTextColor = { ...generatedHLSColor, l: 30 };
  const hlsBgColor = { ...generatedHLSColor, l: 80 };
  const splitFullName = fullName.toUpperCase().split(' ');
  const text =
    splitFullName.length > 1
      ? splitFullName[0][0] + (splitFullName[1][0] || '')
      : splitFullName[0][0] || 'A';

  return (
    <div
      className={`admin-avatar size-${size}`}
      style={{
        color: getHSLStyle(hlsTextColor),
        backgroundColor: getHSLStyle(hlsBgColor),
      }}
      title={alt}
    >
      {text}
    </div>
  );
}
