import 'react-app-polyfill/ie11';
import React, { useState } from 'react';
import InputRange from 'react-input-range';
import classnames from 'classnames';
import axios from 'axios';
import Landing1 from '../Components/Landing1';
import Landing2 from '../Components/Landing2';
import Landing3 from '../Components/Landing3';
import Landing4 from '../Components/Landing4';
import balloonLogo from '../../../../assets/images/test-idea/balloon-logo.png';
import 'react-input-range/lib/css/index.css';
import * as Route from '../../main/utils/serverRoutes';

const Landings = [Landing1, Landing2, Landing3, Landing4];

const Test = ({
  locales,
  test: currentTest,
  locales: {
    ideaGenerator: { showTestPage: pageLocales },
  },
}) => {
  const [step, setStep] = useState(0);
  const [dataSent, setDataSent] = useState(false);
  const [answers, setAnswers] = useState({
    answer1: '',
    answer2: '',
    answer3: '',
    score: 5,
  });

  const nextHandler = (fieldName) => {
    const value = answers[fieldName];
    if (value) {
      setStep(step + 1);
    }
  };

  const answerHandler = (answerName, value) => {
    setAnswers({ ...answers, [answerName]: value });
  };

  const scoreHandler = (value) => {
    setAnswers({ ...answers, score: value });
  };

  const dotClickHandler = (nextStep) => {
    setStep(nextStep);
  };

  const saveHandler = () => {
    if (!dataSent) {
      axios
        .post(Route.testResponse(), {
          answer_one: answers.answer1,
          answer_two: answers.answer2,
          answer_three: answers.answer3,
          score: answers.score,
          test_id: currentTest.id,
        })
        .then(() => {
          setDataSent(true);
          setStep(step + 1);
        });
    } else {
      // eslint-disable-next-line
      alert('Data already sended!');
    }
  };

  if (!currentTest || currentTest.test !== 'homepage')
    return <div className="title">404 Not Found</div>;
  const Landing = Landings[currentTest.data.template];
  return (
    <div className="test-container">
      {step > 0 && step < 5 ? (
        <div>
          <div className="top-block">
            <div className="questions-block">
              <div className="questions-block-left">
                <div className="question">
                  <div className="question-title">
                    {pageLocales[`question${step}`]}
                  </div>
                  <div className="question-answer">
                    {step === 4 ? (
                      <InputRange
                        step={1}
                        minValue={0}
                        maxValue={10}
                        value={answers.score}
                        onChange={scoreHandler}
                        data-hj-allow
                      />
                    ) : (
                      <input
                        placeholder={
                          (step === 1 && 'I like..') ||
                          (step === 2 && 'I wish..') ||
                          (step === 3 && 'What if..')
                        }
                        value={answers[`answer${step}`]}
                        onChange={(event) =>
                          answerHandler(
                            `answer${step}`,
                            event.currentTarget.value,
                          )
                        }
                        data-hj-allow
                      />
                    )}
                    <button
                      className="btn btn-action"
                      onClick={() => {
                        if (step === 4) return saveHandler();
                        nextHandler(`answer${step}`);
                      }}
                    >
                      {step === 4 ? 'SUBMIT' : 'NEXT'}
                    </button>
                  </div>
                </div>
              </div>
              <div className="questions-block-right">
                {[1, 2, 3, 4].map((i) => (
                  <div
                    key={i}
                    onClick={() => dotClickHandler(i)}
                    className={classnames('questions-dot', {
                      'questions-dot-marked': i <= step,
                    })}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="bottom-block">
            <Landing test={currentTest} locales={locales} />
          </div>
        </div>
      ) : (
        <div className="validate-block">
          <div className="validate-block-inner">
            <img src={balloonLogo} alt="" />
            {step === 5 && (
              <div className="validate-title">{pageLocales.finishTitle}</div>
            )}

            {pageLocales[
              step === 0 ? 'introDescriptions' : 'finishDescriptions'
            ].map((description, i) => (
              <div key={i} className="validate-description">
                {description}
              </div>
            ))}

            {step === 0 ? (
              <span
                className="btn btn-action"
                onClick={() => setStep(step + 1)}
              >
                {pageLocales.introButton}
              </span>
            ) : (
              <a
                className="btn btn-action"
                href="https://simventure.com/simventure-validate/"
              >
                {pageLocales.finishButton}
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Test;
