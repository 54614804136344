import React from 'react';

export default function CommentListComponent({ refEl, onScroll, children }) {
  return (
    <div
      className="comment-list-component"
      onScroll={onScroll}
      ref={refEl}
      id="comment-list-component"
    >
      {children}
    </div>
  );
}
