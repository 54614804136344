import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import humps from 'humps';
import SortStatus from './SortStatus';

const stringColumns = ['name', 'fullName', 'classroomName', 'email', 'role'];

const TableTitle = ({ title, onSort, dataIndex, meta }) => {
  const sortStatus = useMemo(() => {
    if (
      dataIndex &&
      humps.decamelize(dataIndex) === meta?.sort?.replace('-', '')
    ) {
      if (stringColumns.includes(dataIndex)) {
        return meta.sort[0] === '-' ? 'desc' : 'asc';
      }
      return meta.sort[0] === '-' ? 'asc' : 'desc';
    }
  }, [meta, dataIndex]);

  const handleSort = ({ sort }) => {
    if (sort === '') onSort({ sort: null });
    else if (stringColumns.includes(dataIndex)) {
      const status = sort === 'asc' ? '' : '-';
      onSort({ sort: `${status}${humps.decamelize(dataIndex)}` });
    } else {
      const status = sort === 'desc' ? '' : '-';
      onSort({ sort: `${status}${humps.decamelize(dataIndex)}` });
    }
  };

  return (
    <div className="table-title">
      <div className="text">{title}</div>{' '}
      <SortStatus
        sortStatus={sortStatus}
        dataIndex={dataIndex}
        onSort={handleSort}
      />{' '}
    </div>
  );
};

TableTitle.propTypes = {
  onSort: PropTypes.func,
  title: PropTypes.string,
  meta: PropTypes.object,
  dataIndex: PropTypes.string,
};
export default TableTitle;
