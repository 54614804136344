import React from 'react';
import { useSelector } from 'react-redux';

import { TECH_MODERN_TEMPLATE_NAME, COLORS } from '../../main/utils/const';
import GKGTabsTemplates from '../../main/containers/getKeepGrow/GKGTabsTemplates';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import { isSecondOrThirdTemplate } from '../../main/utils/helpers/isSecondOrThirdTemplate';

const { black, transparentWhite, white } = COLORS;

const GKG = ({
  locales,
  styleColor,
  backgroundSection,
  colorPaletteNumber,
}) => {
  const {
    getKeepGrow: {
      intro: { gkgTabs },
    },
    portfolioWeb,
  } = locales;
  const gkg = useSelector((state) => state.getKeepGrow);
  const { getStrategies, keepStrategies, growStrategies, referralStrategies } =
    gkg;
  const { customerRelationships } = useSelector((state) => state.portfolio);

  return (
    <div
      className="customer-relationships-tech-web section-web"
      style={{ background: backgroundSection }}
    >
      <SectionHeaderWeb
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolioWeb.customerRelationships}
        description={customerRelationships.description}
        portfolioWeb={portfolioWeb}
        style={{
          color: isSecondOrThirdTemplate(colorPaletteNumber)
            ? white
            : styleColor,
        }}
      />

      <GKGTabsTemplates
        className="margin-top-40"
        portfolioWebMode
        firstColor={black}
        secondColor={
          isSecondOrThirdTemplate(colorPaletteNumber)
            ? `${white}4D`
            : `${styleColor}4D`
        }
        textColor={isSecondOrThirdTemplate(colorPaletteNumber) ? white : black}
        backgroundBlock={
          isSecondOrThirdTemplate(colorPaletteNumber)
            ? `${white}4D`
            : `${styleColor}4D`
        }
        borderColorBlock={transparentWhite}
        content={{
          ...gkgTabs,
          title: null,
          getStrategies: getStrategies || [],
          keepStrategies: keepStrategies || [],
          growStrategies: growStrategies || [],
          referralStrategies: referralStrategies || [],
        }}
      />
    </div>
  );
};

export default GKG;
