import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Tabs, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import Container from '../../../components/Container';
import Header from '../../../components/Header';
import ProjectLists from '../../Lists/ProjectLists';
import StudentLists from '../../Lists/StudentLists';
import ModalClassSettings from '../../CreateClass/ModalClassSettings';
import { shareClassroomLink } from '../../../support/serverRoutes';
import ClassroomBlockList from '../../../components/ClassroomBlockList';

const { TabPane } = Tabs;

export default function ClassroomDashboard({ locales }) {
  const history = useHistory();
  const { classroomId } = useParams();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [modalClassSettings, setModalClassSettings] = useState({
    visible: false,
    classroomId: null,
  });

  const {
    // blocks: { collections },
    classroom: { current },
    user,
  } = useSelector((state) => state);

  const {
    blocks: { fetchBlocks },
    classroom: { fetchClassroom },
  } = useDispatch();

  useEffect(() => {
    if (classroomId) {
      fetchBlocks({
        by: 'classrooms',
        byId: classroomId,
        pageName: 'classroom',
      });
      fetchClassroom(classroomId);
    }
  }, [classroomId]);

  useEffect(() => {
    if (current) {
      const resultBreadcrumbs = [];

      if (user?.current?.role === 'super_admin')
        resultBreadcrumbs.push({
          title: 'Super Admin',
          to: '/admin',
        });

      if (
        user?.current?.role === 'admin' ||
        user?.current?.role === 'super_admin'
      )
        resultBreadcrumbs.push({
          title: `Establishment “${current?.establishment?.name}”`,
          to: `/admin/establishment/${current?.establishment?.id}`,
        });

      resultBreadcrumbs.push({
        title: `Classroom “${current?.name}”`,
        to: `/admin/classroom/${current?.id}`,
      });
      setBreadcrumbs(resultBreadcrumbs);
    }
  }, [current]);

  const handleOpenModalClassSettings = () => {
    setModalClassSettings({
      visible: true,
      classroomId: current?.attributes?.id,
    });
  };

  const handleCloseModalClassSettings = () => {
    setModalClassSettings({
      visible: false,
      classroomId: null,
    });
  };

  let title = 'Admin Panel';

  if (user) {
    const firstName = user.current?.firstName || '';
    const surname = user.current?.surname || '';

    title = `${firstName} ${surname} ${title}`;
  }

  return (
    <Container locales={locales} title={title}>
      <ModalClassSettings
        visible={modalClassSettings.visible}
        classroomId={modalClassSettings.classroomId}
        onClose={handleCloseModalClassSettings}
      />
      {user?.current?.role ? (
        <>
          <Header
            locales={locales}
            breadcrumbs={breadcrumbs}
            header={`${current?.name || ''} Dashboard`}
            classroomShareLink={
              current ? shareClassroomLink(current?.secretToken) : null
            }
            leftButtons={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <>
                <Button type="primary" onClick={handleOpenModalClassSettings}>
                  Class Settings
                </Button>
              </>
            }
          />
          <ClassroomBlockList classroomId={classroomId} />
          <br />
          <br />
          <Tabs defaultActiveKey="project" className="admin-tabs">
            <TabPane key="project" tab="Project" className="admin-tab-pane">
              <ProjectLists
                byId={classroomId}
                by="classrooms"
                rowClick={(record) =>
                  window.open(`/admin/bmc/${record.id}`, '_blank')
                }
                rowClickable
              />
            </TabPane>
            <TabPane key="student" tab="Student" className="admin-tab-pane">
              <StudentLists
                byId={classroomId}
                by="classrooms"
                expandRowClick={(record) => {
                  history.push(`/admin/bmc/${record.id}`);
                }}
                locales={locales}
              />
            </TabPane>
          </Tabs>
        </>
      ) : (
        <div>Loading</div>
      )}
    </Container>
  );
}
