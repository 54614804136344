import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as Route from '../../support/serverRoutes';
import { useFetch } from '../../../main/utils/makeRequest';
import Avatars from '../../components/Avatars';

const columns = [
  {
    title: 'Project',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Team member',
    key: 'teamMembers',
    render: (row) => {
      const images = row.teamMembers.map((item) => {
        const fullName = `${item.firstName} ${item.surname}`;
        return {
          alt: fullName,
          url: item.image.icon.url,
          fullName,
        };
      });
      return <Avatars images={images} />;
    },
  },
  {
    title: 'Class',
    dataIndex: 'classroomName',
    key: 'classroomName',
  },
  {
    title: 'Ideas Created',
    dataIndex: 'ideasCreated',
    key: 'ideasCreated',
    render: (ideasCreated) => ideasCreated || 0,
  },
  {
    title: 'Canvas Progress',
    dataIndex: 'canvasProgress',
    key: 'canvasProgress',
    render: (canvasProgress) => canvasProgress || '0%',
  },
  {
    title: 'Tests Completed',
    dataIndex: 'testsCreated',
    key: 'testsCreated',
    render: (testsCreated) => testsCreated || 0,
  },
  {
    title: 'Activity Total',
    dataIndex: 'userTotalTracksOnBmc',
    key: 'userTotalTracksOnBmc',
    render: (userTotalTracksOnBmc) => userTotalTracksOnBmc || 0,
  },
  {
    title: 'Last 30 days',
    dataIndex: 'userTotalLastTracksOnBmc',
    key: 'userTotalLastTracksOnBmc',
    render: (userTotalLastTracksOnBmc) => userTotalLastTracksOnBmc || 0,
  },
  {
    title: 'Last Updated',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (updatedAt) => {
      moment.locale('en');
      return moment(new Date(updatedAt)).format('DD MMM YYYY');
    },
  },
];

const ProjectsTable = ({ by, byId }) => {
  const [data, loading] = useFetch({
    url: Route.bmcs({ by, byId, limit: 5000 }),
  });

  return (
    <Table
      className="admin-table"
      rowClassName="admin-table__row ant-table-clickable"
      rowKey="id"
      dataSource={data}
      columns={columns}
      loading={loading}
      pagination={false}
      onRow={(row) => {
        return {
          onClick: () => window.open(`/admin/bmc/${row.id}`, '_blank'),
        };
      }}
    />
  );
};
ProjectsTable.propTypes = {
  byId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  by: PropTypes.oneOf(['users', 'classrooms', 'establishments']),
};
export default ProjectsTable;
