import {
  Paragraph,
  ImageRun,
  Table,
  TableRow,
  TableCell,
  AlignmentType,
  WidthType,
  HeightRule,
  TableAnchorType,
  OverlapType,
  TableLayoutType,
  FrameAnchorType,
  HorizontalPositionAlign,
  VerticalPositionAlign,
} from 'docx';

import {
  pageSize,
  convertPixelToTwip,
  convertPixel,
  COLORS,
  transparentBorder,
  tableWidth,
  noPageMargin,
  shadingBox,
  whiteBgImage,
} from '../Constants';
import { emptyCell, marginTableRow, textRun } from '../helpers/index';

export default async (locales, portfolio, base64BMCImage, options) => {
  const bmcBase64Img = Uint8Array.from(
    atob(base64BMCImage.split(',')[1]),
    (c) => c.charCodeAt(0),
  );

  const getPageBg = (color) => {
    if (color)
      return new Paragraph({
        frame: {
          position: {
            x: 0,
            y: 0,
          },
          width: convertPixelToTwip(tableWidth),
          height: convertPixelToTwip(539 + 56),
          rule: HeightRule.EXACT,
          anchor: {
            horizontal: FrameAnchorType.PAGE,
            vertical: FrameAnchorType.PAGE,
          },
          alignment: {
            x: HorizontalPositionAlign.CENTER,
            y: VerticalPositionAlign.TOP,
          },
        },
        shading: shadingBox(color),
      });
  };

  return {
    properties: {
      page: {
        size: pageSize,
        margin: noPageMargin,
      },
    },
    children: [
      // Primary box
      getPageBg(COLORS.WHITE),
      // Description
      new Table({
        float: {
          horizontalAnchor: TableAnchorType.TEXT,
          verticalAnchor: TableAnchorType.TEXT,
          overlap: OverlapType.NEVER,
          absoluteVerticalPosition: convertPixelToTwip(0),
          absoluteHorizontalPosition: 0,
        },
        width: {
          size: convertPixelToTwip(tableWidth),
          type: WidthType.DXA,
        },
        layout: TableLayoutType.AUTOFIT,
        rows: [
          // title row
          new TableRow({
            height: {
              value: convertPixelToTwip(27),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(28, COLORS.WHITE),
              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.WHITE),
                children: [
                  new Paragraph({
                    text: '',
                    alignment: AlignmentType.CENTER,

                    children: [
                      textRun(locales.bmc.sectionName, {
                        font: options.titleFont,
                        bold: options.bmc?.titleBold,
                        color:
                          options.bmc?.titleColor ||
                          options.titleColor ||
                          COLORS.BLACK,
                        size: 24,
                        allCaps: true,
                      }),
                    ],
                  }),
                ],
              }),
              emptyCell(28, COLORS.WHITE),
            ],
          }),
          marginTableRow(5, tableWidth, COLORS.WHITE, 3),

          // description row
          new TableRow({
            height: {
              value: convertPixelToTwip(28),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(28, COLORS.WHITE),
              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.WHITE),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      textRun(portfolio.bmc.description, {
                        font: options.bodyFont,
                        color:
                          options.bmc?.bodyColor ||
                          options.bodyColor ||
                          COLORS.BLACK,
                        size: 12,
                      }),
                    ],
                  }),
                ],
              }),
              emptyCell(28, COLORS.WHITE),
            ],
          }),

          marginTableRow(15, tableWidth, COLORS.WHITE, 3),
          // data row
          new TableRow({
            height: {
              value: convertPixelToTwip(28),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(28, COLORS.WHITE),
              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.WHITE),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new ImageRun({
                        data: bmcBase64Img,
                        transformation: {
                          width: convertPixel(477),
                          height: convertPixel(369),
                        },
                      }),
                    ],
                  }),
                ],
              }),
              emptyCell(28, COLORS.WHITE),
            ],
          }),
        ],
      }),

      whiteBgImage(COLORS.WHITE),
    ],
  };
};
