import React from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';

import Header from '../../../../../components/Header';
import Text from '../../../../../components/Text';
import Title from '../../../../../components/Title';
import Line from '../../../../../components/Line';
import TestCard from '../../../Components/TestCard';
import Actions from '../../../../../components/Actions';
import MobileMenuHeader from '../../../../../components/MobileMenuHeader';
import * as AppRoute from '../../../../../utils/appRoutes';
import { nextComponentHandlerFunction } from '../../../../../utils/helpers/nextComponentHandlerFunction';

const GeneralPage = ({
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
  test,
  locales,
  goToHandler,
  locales: {
    testing: {
      name,
      titles,
      summary: { header, text, title },
    },
  },
}) => {
  const {
    bmc: { view },
  } = useSelector((state) => state);
  const history = useHistory();
  const { bmcId: bmcIdFromParams } = useParams();

  const nextPath = AppRoute.testing(bmcIdFromParams);

  return (
    <div className="bmc-page testing">
      <Header
        className="bmc-page-header--blue"
        subtitle={name}
        icon="icon-VP"
        locales={locales}
        title={header}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        componentIndex={componentIndex}
        mobileMenuHeader={
          <MobileMenuHeader icon="testingActive" title={name} color="blue" />
        }
        rules={rules}
        backPath={backPath}
      />
      <Text>{[text[0]]}</Text>
      <Line />

      <Title align="center" text={title[0]} />
      <div className="summary">
        {test && test.id && (
          <TestCard
            locales={locales}
            titles={titles}
            goToHandler={goToHandler}
            isPreview
            bmcSectionMode
            test={test}
            isEditable={false}
          />
        )}
      </div>
      <Actions
        buttonName="Done"
        nextClickHandler={() => {
          history.push(nextComponentHandlerFunction(view, nextPath));
        }}
      />
    </div>
  );
};

export default GeneralPage;
