import * as AppRoute from '../appRoutes';

export const menuIsActive = (path) => {
  return window.location.pathname === path;
};

export const buildMenuItems = (actualBmcId, locales) => {
  const {
    menu: { items },
  } = locales;

  return [
    {
      title: items[0],
      to: AppRoute.ideation(actualBmcId),
      iconName: 'ideation',
    },
    { title: items[1], to: AppRoute.bmc(actualBmcId), iconName: 'canvas' },
    {
      title: items[2],
      to: AppRoute.testing(actualBmcId),
      iconName: 'testing',
    },
    {
      title: items[3],
      to: AppRoute.portfolio(actualBmcId),
      iconName: 'portfolio',
    },
  ];
};
