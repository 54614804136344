import { useState, useEffect } from 'react';

export default function usePreload({ files, placeholder, performDownload }) {
  const defaultUploadingFiles = new Array(files.length)
    .fill(placeholder)
    .map((e) => ({ loaded: false, url: e }));
  const [uploadingFiles, setUploadingFiles] = useState(defaultUploadingFiles);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);

  useEffect(() => {
    if (performDownload && currentFileIndex < files.length) {
      fetch(files[currentFileIndex]).then((response) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const newUploadingFiles = [...uploadingFiles];

          newUploadingFiles[currentFileIndex] = { url, loaded: true };

          setUploadingFiles(newUploadingFiles);
          setCurrentFileIndex(currentFileIndex + 1);
        });
      });
    }
  }, [uploadingFiles, performDownload]);

  return { files: uploadingFiles };
}
