import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import { NOTIFICATION_TYPES } from '../utils/const';

const classes = {
  [NOTIFICATION_TYPES.primary]: 'notify-system__item--primary',
  [NOTIFICATION_TYPES.danger]: 'notify-system__item--danger',
  [NOTIFICATION_TYPES.success]: 'notify-system__item--success',
  [NOTIFICATION_TYPES.warning]: 'notify-system__item--warning',
  [NOTIFICATION_TYPES.warningBlue]: 'notify-system__item--warning-blue',
};

const NotifySystem = ({ flash }) => {
  const {
    notification: { collection },
  } = useSelector((state) => state);

  const {
    notification: { addNotification, removeNotification },
  } = useDispatch();

  useEffect(() => {
    if (flash && flash.notice)
      addNotification({ text: flash.notice, type: NOTIFICATION_TYPES.success });

    if (flash && flash.alert) addNotification({ text: flash.alert });
  }, []);

  return (
    <div className="notify-system">
      {collection.map(({ id, text, permanent, type }) => (
        <div
          key={id}
          className={classnames('notify-system__item', classes[type])}
          onClick={() => !permanent && removeNotification(id)}
        >
          {text}
        </div>
      ))}
    </div>
  );
};

export default NotifySystem;
