import React from 'react';
import { useSelector } from 'react-redux';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import { TECH_MODERN_TEMPLATE_NAME } from '../../main/utils/const';

const Feasibility = ({
  locales: { portfolioWeb },
  backgroundSection,
  colorPaletteNumber,
}) => {
  const { feasibility } = useSelector((state) => state.portfolio);
  return (
    <div
      className="feasibility-tech-web section-web"
      style={{ background: backgroundSection }}
    >
      <SectionHeaderWeb
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolioWeb.feasibility}
        description={feasibility.description}
        portfolioWeb={portfolioWeb}
        colorPaletteNumber={colorPaletteNumber}
      />
    </div>
  );
};

export default Feasibility;
