import React from 'react';
import classNames from 'classnames';

export default function TabsList({
  className,
  formStyle,
  children,
  horizontal,
  minimalMargin,
  preview,
  cost,
  sustainability,
}) {
  const classes = classNames('bmc-page-tabs-content-list', className, {
    'bmc-page-tabs-content-list--form-style': formStyle === true,
    'bmc-page-tabs-content-list--horizontal': horizontal === true,
    'bmc-page-tabs-content-list--minimal-margin': minimalMargin === true,
    'bmc-page-tabs-content-list--preview': preview === true,
    'bmc-page-tabs-content-list--cost': cost === true,
    'bmc-page-tabs-content-list--sustainability': sustainability === true,
  });

  return <div className={classes}>{children}</div>;
}
