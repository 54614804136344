import humps from 'humps';

import * as Route from '../utils/serverRoutes';
import {
  transformResponseData,
  imageAttributes,
  imageCoordAttributes,
} from '../utils/helpers';
import makeRequest, { uploadFile } from '../utils/makeRequest';

const defaultUserData = () => ({
  image: imageAttributes(),
  imageCoords: imageCoordAttributes(),
});

const user = {
  state: {
    current: defaultUserData(),
    uploadingImage: false,
  },
  reducers: {
    setCurrentUserReducer(state, payload) {
      return { ...state, current: { ...state.current, ...payload } };
    },
    updateCurrentUserReducer(state, { name, value }) {
      return { ...state, current: { ...state.current, [name]: value } };
    },
    updateAttributeReducer(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    async fetchCurrentUser() {
      makeRequest({
        dispatch,
        url: Route.user(),
        success: (response) => {
          dispatch.user.setCurrentUserReducer(
            transformResponseData(response.data.data),
          );
        },
      });

      makeRequest({
        dispatch,
        url: Route.session(),
        success: (response) => {
          dispatch.user.updateCurrentUserReducer({
            name: 'sessionId',
            value: humps.camelizeKeys(response.data.data).sessionId,
          });
        },
      });
    },
    async updateCurrentUser(payload) {
      dispatch.user.updateCurrentUserReducer(payload);

      const { name, value, success, finish } = payload;

      makeRequest({
        dispatch,
        url: Route.user(),
        method: 'put',
        data: { user: { [name]: value } },
        success,
        finish,
      });
    },
    async updateCurrentUserImage(payload) {
      const { name } = payload;

      dispatch.user.updateAttributeReducer({ uploadingImage: true });

      uploadFile({
        url: Route.user(),
        modelName: 'user',
        name,
        blob: payload.blob,
        dispatch,
        success: (res) => {
          dispatch.user.updateCurrentUserReducer({
            name,
            value: res.data.data.attributes[name],
          });

          if (payload.success) payload.success();
        },
        finish: () => {
          dispatch.user.updateAttributeReducer({ uploadingImage: false });

          if (payload.finish) payload.finish();
        },
      });
    },
    updateCurrentUserImageCoords({
      coords,
      croppedAreaPixels,
      innerCropData,
      zoom,
    }) {
      const name = 'imageCoords';
      const value = {
        ...coords,
        ...croppedAreaPixels,
        cropperData: { ...innerCropData, zoom },
      };

      dispatch.user.updateAttributeReducer({ uploadingImage: true });

      dispatch.user.updateCurrentUser({
        name,
        value,
        success: (res) => {
          dispatch.user.updateCurrentUserReducer({
            name,
            value: res.data.data.attributes[name],
          });

          dispatch.user.updateCurrentUserReducer({
            name: 'image',
            value: res.data.data.attributes.image,
          });
        },
        finish: () => {
          dispatch.user.updateAttributeReducer({ uploadingImage: false });
        },
      });
    },
    async updateUserEmail({ email, emailConfirmation, after = [] }) {
      await makeRequest({
        dispatch,
        url: Route.userEmail(),
        method: 'post',
        data: { user: { email, emailConfirmation } },
        success: () => {
          after.forEach((callback) => callback());
          dispatch.user.fetchCurrentUser();
        },
        showSuccessToast: true,
      });
    },
    async currentUserPasswordUpdate({
      currentPassword,
      password,
      passwordConfirmation,
      callbackAfterSuccess = [],
    }) {
      makeRequest({
        dispatch,
        url: Route.userPassword(),
        method: 'post',
        data: {
          user: {
            currentPassword,
            password,
            passwordConfirmation,
          },
        },
        success: () => {
          callbackAfterSuccess.forEach((callback) => callback());
        },
        showSuccessToast: true,
      });
    },
  }),
};

export default user;
