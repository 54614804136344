import React from 'react';

const iconClose = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.70711 0.292893C1.31658 -0.097631 0.683418 -0.0976311 0.292893 0.292893C-0.097631 0.683417 -0.0976312 1.31658 0.292893 1.70711L5.5858 7.00002L0.292922 12.2929C-0.0976021 12.6834 -0.0976021 13.3166 0.292922 13.7071C0.683447 14.0976 1.31661 14.0976 1.70714 13.7071L7.00001 8.41423L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41423 7.00002L13.7071 1.70711C14.0977 1.31658 14.0977 0.683419 13.7071 0.292895C13.3166 -0.0976298 12.6834 -0.0976294 12.2929 0.292895L7.00001 5.5858L1.70711 0.292893Z"
      fill="#007AFF"
    />
  </svg>
);

const iconExpand = (
  <svg
    width="9"
    height="17"
    viewBox="0 0 9 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 15.0025L5.56275 8.5L0 1.9975L1.71255 0L9 8.5L1.71255 17L0 15.0025Z"
      fill="#007AFF"
    />
  </svg>
);

export default function ChatGroupHeaderComponent({
  isExpanded = false,
  title = '',
  onClose,
  onClickExpand,
}) {
  if (!isExpanded)
    return (
      <div className="chat-group-header-component">
        <div
          className="chat-group-header-component__close"
          onClick={onClickExpand}
        >
          {iconExpand}
        </div>
      </div>
    );

  return (
    <div className="chat-group-header-component">
      <div className="chat-group-header-component__title">
        Messaging {title}
      </div>
      <div className="chat-group-header-component__close" onClick={onClose}>
        {iconClose}
      </div>
    </div>
  );
}
