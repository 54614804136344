import React from 'react';
import { useSelector } from 'react-redux';
import SvgIcon from '../../main/components/SvgIcon';
import { COLOURFUL_TEMPLATE_NAME } from '../../main/utils/const';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';

const Viability = ({
  locales: { portfolioWeb },
  className,
  mainSvgColor,
  backgroundSection,
  sectionHeaderTitleColor,
  descriptionColor,
  templateName,
}) => {
  const { viability } = useSelector((state) => state.portfolio);

  return (
    <div
      className={`${className} section-web`}
      style={{ backgroundColor: backgroundSection }}
    >
      {templateName === COLOURFUL_TEMPLATE_NAME && (
        <div className="svg-funViability" style={{ fill: mainSvgColor }}>
          <SvgIcon name="funViability" />
        </div>
      )}

      <SectionHeaderWeb
        templateName={templateName}
        title={portfolioWeb.viability}
        description={viability.description}
        style={{ color: sectionHeaderTitleColor }}
        descriptionColor={descriptionColor}
      />
    </div>
  );
};

export default Viability;
