import React from 'react';
import UnitEconomics from '../../containers/portfolio/Finance/UnitEconomics';
import { COLOURFUL_TEMPLATE_NAME } from '../../utils/const';
import SvgIcon from '../SvgIcon';

const UnitEconomicsTab = ({
  locales,
  isPreview = false,
  backgroundTable,
  backgroundSvg,
  templateName,
  separatorColor,
  borderCardColor,
  verticalBorderColor,
  backgroundRow,
  titleColor,
  textColor,
  secondTitleColor,
  tableTitleColor,
}) => {
  return (
    <div className="tab-content">
      {templateName === COLOURFUL_TEMPLATE_NAME && (
        <div className="svg-funFinancial" style={{ fill: backgroundSvg }}>
          <SvgIcon name="funFinancial" />
        </div>
      )}
      <UnitEconomics
        locales={locales}
        isPreview={isPreview}
        marginTop="margin-top-20"
        background={backgroundTable}
        separatorColor={separatorColor}
        borderCardColor={borderCardColor}
        verticalBorderColor={verticalBorderColor}
        backgroundRow={backgroundRow}
        titleColor={titleColor}
        textColor={textColor}
        secondTitleColor={secondTitleColor}
        tableTitleColor={tableTitleColor}
      />
    </div>
  );
};

export default UnitEconomicsTab;
