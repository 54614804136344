import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { format } from 'd3-format';
import useMediaQuery from 'react-use-media-query-hook';

import SubChapter from './SubChapter';
import {
  sumArrays,
  diffArray,
  costCalculator as calculator,
} from '../../../utils/helpers';

const makeCumulative = (arr) => {
  return arr.map((o, i) =>
    i - 1 >= 0
      ? o + arr.slice(0, i).reduce((sum, x) => Math.round(sum + x))
      : o,
  );
};

const Forecast = ({
  locales: { finance, chart, chartShort },
  setTotalCashOut,
  setTotalCashIn,
  background,
  totalColor,
  borderCardColor,
  backgroundRow,
  titleColor,
  secondTitleColor,
  textColor,
  borderVerticalTableColor,
  summaryMode = false,
  portfolioWebMode = false,
}) => {
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const [cashIn, setCashIn] = useState([]);
  const [cashInTotal, setCashInTotal] = useState([]);
  const [fixed, setFixed] = useState([]);
  const [variable, setVariable] = useState([]);
  const [cashOutTotal, setCashOutTotal] = useState([]);
  const [monthlyCashInCashOut, setMonthlyCashInCashOut] = useState([]);
  const [cumulativeBalance, setCumulativeBalance] = useState([]);

  const { collection: products } = useSelector((state) => state.product);
  const { collection: cost } = useSelector((state) => state.cost);
  const chartNames = summaryMode ? chartShort : chart;

  const isBigNumber = (number) => {
    return Math.abs(number) > 999999;
  };

  const formatNumber = (number) => {
    return isMobile || isBigNumber(number)
      ? format('.2s')(number).replace('G', 'B')
      : number;
  };

  useEffect(() => {
    const newCashIn = products.map((product) => {
      const endUserPrice = +product.endUserPrice || 0;
      const months = product.sales.map((s) => Math.round(+s * endUserPrice));

      return {
        name: product.name,
        months,
      };
    });

    const productArrays = newCashIn.map((i) => i.months);
    const newCashInTotal = sumArrays(...productArrays);

    const newFixed = cost.map((c) => {
      const months = new Array(12).fill(Math.round(calculator(c)));
      return {
        name: c.name,
        months,
      };
    });

    const newVariable = products.map((p) => {
      const months = p.sales.map((i) => Math.round(+p.costPrice * i));
      return {
        name: p.name,
        months,
      };
    });

    const newCashOutTotal = sumArrays(
      ...[
        ...newFixed.map((i) => i.months),
        ...newVariable.map((i) => i.months),
      ],
    );

    const newMonthlyCashInCashOut = diffArray(newCashInTotal, newCashOutTotal);
    const newCumulativeBalance = makeCumulative(newMonthlyCashInCashOut);

    setTotalCashOut(makeCumulative(newCashOutTotal));
    setTotalCashIn(makeCumulative(newCashInTotal));
    setCashIn(newCashIn);
    setCashInTotal(newCashInTotal);
    setFixed(newFixed);
    setVariable(newVariable);
    setCashOutTotal(newCashOutTotal);
    setMonthlyCashInCashOut(newMonthlyCashInCashOut);
    setCumulativeBalance(newCumulativeBalance);
  }, [products, cost]);

  const getBorderRightColor = () => {
    return (
      background && {
        borderRightColor: background,
      }
    );
  };

  const getBorderBottomColor = () => {
    return (
      background && {
        borderBottomColor: background,
      }
    );
  };

  const getBackgroundColor = (index) => {
    return index % 2 !== 0
      ? {
          backgroundColor: backgroundRow,
        }
      : {};
  };

  return (
    <div className="chapter forecast">
      <div
        className="main-title custom-font-title"
        style={{ color: titleColor }}
      >
        {finance.forecast.title}
      </div>
      <div
        className={classNames({
          'forecast-inner margin-top-50': !summaryMode,
          'forecast-inner--web': portfolioWebMode,
        })}
        style={{ borderColor: borderCardColor, color: textColor }}
      >
        <SubChapter
          name={finance.forecast.cashIn}
          textColor={secondTitleColor}
        />

        <div className="table-wrapper margin-top-20">
          <table className="forecast custom-font-body">
            <thead>
              <tr>
                <th> </th>
                {chartNames.map((i, index) => (
                  <th key={index}>{i}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {cashIn.map((c, index) => (
                <tr
                  key={index}
                  style={{
                    ...getBorderBottomColor(),
                    ...getBackgroundColor(index),
                  }}
                >
                  <td
                    className="left-align"
                    style={{
                      ...getBorderRightColor(),
                      borderRightColor: borderVerticalTableColor,
                    }}
                  >
                    {c.name}
                  </td>
                  {c.months.map((p, i) => (
                    <td
                      key={i}
                      style={
                        i + 1 !== c.months.length
                          ? {
                              ...getBorderRightColor(),
                              borderRightColor: borderVerticalTableColor,
                            }
                          : {}
                      }
                    >
                      {formatNumber(p)}
                    </td>
                  ))}
                </tr>
              ))}
              <tr className="total">
                <td
                  className="left-align"
                  style={{
                    ...getBorderRightColor(),
                    borderRightColor: borderVerticalTableColor,
                    borderTopColor: totalColor,
                  }}
                >
                  Total Cash In
                </td>
                {cashInTotal.map((i, index) => (
                  <td
                    key={index}
                    style={{
                      borderTopColor: totalColor,
                      borderRightColor:
                        i + 1 !== cashInTotal.length
                          ? background || borderVerticalTableColor
                          : 'none',
                    }}
                  >
                    {formatNumber(i)}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>

        <SubChapter
          name={finance.forecast.cashOut}
          className="margin-top-50"
          textColor={secondTitleColor}
        />

        <div className="table-wrapper margin-top-20">
          <table className="forecast custom-font-body">
            <thead>
              <tr>
                <th className="left-align">Fixed Cost</th>
                {chartNames.map((i, index) => (
                  <th key={index}>{i}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {fixed.map((c, index) => (
                <tr
                  key={index}
                  style={{
                    ...getBorderBottomColor(),
                    ...getBackgroundColor(index),
                  }}
                >
                  <td
                    className="left-align"
                    style={{
                      ...getBorderRightColor(),
                      borderRightColor: borderVerticalTableColor,
                    }}
                  >
                    {c.name}
                  </td>
                  {c.months.map((m, i) => (
                    <td
                      key={i}
                      style={
                        i !== c.months.length - 1
                          ? {
                              ...getBorderRightColor(),
                              borderRightColor: borderVerticalTableColor,
                            }
                          : {}
                      }
                    >
                      {formatNumber(m)}
                    </td>
                  ))}
                </tr>
              ))}
              <tr className="variable-cost" style={getBorderBottomColor()}>
                <th className="left-align">Variable Cost</th>
              </tr>
              {variable.map((v, index) => (
                <tr
                  key={index}
                  style={{
                    ...getBorderBottomColor(),
                    ...getBackgroundColor(index),
                  }}
                >
                  <td
                    className="left-align"
                    style={{
                      ...getBorderRightColor(),
                      borderRightColor: borderVerticalTableColor,
                    }}
                  >
                    {v.name}
                  </td>
                  {v.months.map((m, i) => (
                    <td
                      key={i}
                      style={
                        i + 1 !== v.months.length
                          ? {
                              ...getBorderRightColor(),
                              borderRightColor: borderVerticalTableColor,
                            }
                          : {}
                      }
                    >
                      {formatNumber(m)}
                    </td>
                  ))}
                </tr>
              ))}
              <tr className="total">
                <td
                  className="left-align"
                  style={{
                    ...getBorderRightColor(),
                    borderRightColor: borderVerticalTableColor,
                    borderTopColor: totalColor,
                  }}
                >
                  Total Cash Out
                </td>
                {cashOutTotal.map((o, i) => (
                  <td
                    key={i}
                    style={{
                      borderTopColor: totalColor,
                      borderRightColor:
                        i + 1 !== cashOutTotal.length
                          ? background || borderVerticalTableColor
                          : 'none',
                    }}
                  >
                    {formatNumber(o)}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>

        <SubChapter
          name={finance.forecast.balance}
          className="margin-top-50"
          textColor={secondTitleColor}
        />

        <div className="table-wrapper margin-top-20">
          <table className="forecast custom-font-body">
            <thead>
              <tr>
                <th> </th>
                {chartNames.map((i, index) => (
                  <th key={index}>{i}</th>
                ))}
              </tr>
            </thead>
            <tbody className="balance">
              <tr style={getBorderBottomColor()}>
                <td
                  className="left-align"
                  style={{
                    ...getBorderRightColor(),
                    borderRightColor: borderVerticalTableColor,
                  }}
                >
                  Monthly Cash In/Out
                </td>
                {monthlyCashInCashOut.map((i, index) => (
                  <td
                    key={index}
                    style={
                      index + 1 !== monthlyCashInCashOut.length
                        ? {
                            ...getBorderRightColor(),
                            borderRightColor: borderVerticalTableColor,
                          }
                        : {}
                    }
                  >
                    {formatNumber(i)}
                  </td>
                ))}
              </tr>
              <tr>
                <td
                  className="left-align"
                  style={{
                    ...getBorderRightColor(),
                    borderRightColor: borderVerticalTableColor,
                  }}
                >
                  Cumulative Balance
                </td>
                {cumulativeBalance.map((i, index) => (
                  <td
                    key={index}
                    style={
                      index + 1 !== cumulativeBalance.length
                        ? {
                            ...getBorderRightColor(),
                            borderRightColor: borderVerticalTableColor,
                          }
                        : {}
                    }
                  >
                    {formatNumber(i)}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Forecast;
