import React from 'react';

import Switcher from '../../components/Switcher';
import SvgIcon from '../../components/SvgIcon';

const SectionHeader = ({
  sectionName,
  setShowStatus,
  showStatus,
  mainColor = 'blue',
  locales,
}) => {
  return (
    <div className="bmc-main-header section-header">
      <div
        className={`bmc-page-title title bmc-page-title--left bmc-page-title--${mainColor}${
          showStatus ? '' : '-light'
        }`}
      >
        {sectionName}
      </div>
      <div className="actions">
        <Switcher
          leftIcon={<SvgIcon name="eyeUnhidden" />}
          leftText={locales.page.sectionActions.unhide}
          leftOnClick={() => setShowStatus(true)}
          leftActive={showStatus}
          rightIcon={<SvgIcon name="eyeHidden" />}
          rightText={locales.page.sectionActions.hide}
          rightOnClick={() => setShowStatus(false)}
          rightActive={!showStatus}
        />
      </div>
    </div>
  );
};

export default SectionHeader;
