import React from 'react';
import { Provider } from 'react-redux';

import '../../../main/utils/axios';
import store from '../../store';
import Lecturers from './Lecturers';
import ConfirmationModalBase from '../../../main/components/ConfirmationModalBase';
import SectionsSettings from './SectionsSettings';

export default function index(props) {
  return (
    <Provider store={store}>
      <ConfirmationModalBase />
      <SectionsSettings {...props} />
      <Lecturers {...props} />
    </Provider>
  );
}
