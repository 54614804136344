import {
  Paragraph,
  Table,
  TableRow,
  TableCell,
  AlignmentType,
  WidthType,
  HeightRule,
  TableAnchorType,
  OverlapType,
  TableLayoutType,
  HorizontalPositionAlign,
  VerticalPositionAlign,
  FrameAnchorType,
} from 'docx';

import { concat } from 'lodash';
import {
  pageSize,
  convertPixelToTwip,
  COLORS,
  transparentBorder,
  shadingBox,
  tableWidth,
  noPageMargin,
  whiteBgImage,
  imageRunColourful,
} from '../Constants';
import {
  buildColourfulDecorateImg,
  buildSegmentItemImage,
  emptyCell,
  marginTableRow,
  textRun,
} from '../helpers/index';

export default async (
  locales,
  portfolio,
  valueProposition,
  uniqueSellingPoint,
  options,
) => {
  const dataUniqueSellingPoints = () => {
    const title = new Paragraph({
      children: [
        textRun('Unique Selling Point', {
          size: 16,
          allCaps: true,
          bold: true,
          color:
            options.valueProposition?.subtitleColor ||
            options.bodyColor ||
            COLORS.BLACK,
          font: options.bodyFont,
        }),
      ],
    });

    const listOfPoints = uniqueSellingPoint.collection.map(
      (item) =>
        new Paragraph({
          spacing: {
            before: convertPixelToTwip(5),
          },
          children: [
            textRun(item.description, {
              size: 14,
              font: options.bodyFont,
              color: options.bodyColor || COLORS.BLACK,
            }),
          ],
        }),
    );

    return concat([title], listOfPoints);
  };

  const dataRows = async () => {
    return new TableRow({
      height: {
        value: convertPixelToTwip(24),
        rule: HeightRule.ATLEAST,
      },
      children: [
        emptyCell(30, COLORS.WHITE),

        // cell with data
        new TableCell({
          width: {
            size: convertPixelToTwip(448 + 20),
            type: WidthType.DXA,
          },
          borders: transparentBorder(COLORS.WHITE),
          children: [
            new Table({
              float: {
                horizontalAnchor: TableAnchorType.TEXT,
                verticalAnchor: TableAnchorType.TEXT,
                overlap: OverlapType.NEVER,
              },
              width: {
                size: convertPixelToTwip(448),
                type: WidthType.DXA,
              },
              layout: TableLayoutType.AUTOFIT,
              rows: [
                // SOLUTION
                new TableRow({
                  children: [
                    emptyCell(15, COLORS.WHITE),
                    new TableCell({
                      width: {
                        size: convertPixelToTwip(448),
                        type: WidthType.DXA,
                      },
                      borders: transparentBorder(COLORS.WHITE),
                      children: [
                        new Paragraph({
                          children: [
                            textRun('Solution', {
                              size: 20,
                              allCaps: true,
                              bold: true,
                              color:
                                options.valueProposition?.subtitleColor ||
                                options.bodyColor ||
                                COLORS.BLACK,
                              font: options.bodyFont,
                            }),
                          ],
                        }),
                        new Paragraph({
                          spacing: {
                            before: convertPixelToTwip(5),
                          },
                          children: [
                            textRun(valueProposition.product, {
                              size: 14,
                              font: options.bodyFont,
                              color: options.bodyColor || COLORS.BLACK,
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),

                marginTableRow(15, 448, COLORS.WHITE, 2),

                // Key Features
                new TableRow({
                  children: [
                    emptyCell(15, COLORS.WHITE),
                    new TableCell({
                      width: {
                        size: convertPixelToTwip(448),
                        type: WidthType.DXA,
                      },
                      borders: transparentBorder(COLORS.WHITE),
                      children: [
                        new Paragraph({
                          children: [
                            textRun('Key Features', {
                              size: 20,
                              allCaps: true,
                              bold: true,
                              color:
                                options.valueProposition?.subtitleColor ||
                                options.bodyColor ||
                                COLORS.BLACK,
                              font: options.bodyFont,
                            }),
                          ],
                        }),
                        new Paragraph({
                          spacing: {
                            before: convertPixelToTwip(5),
                          },
                          children: [
                            textRun(valueProposition.reason, {
                              size: 14,
                              font: options.bodyFont,
                              color: options.bodyColor || COLORS.BLACK,
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),

                marginTableRow(15, 448, COLORS.WHITE, 2),

                // Uniq Selling point
                new TableRow({
                  children: [
                    emptyCell(15, COLORS.WHITE),
                    new TableCell({
                      width: {
                        size: convertPixelToTwip(448),
                        type: WidthType.DXA,
                      },
                      borders: transparentBorder(COLORS.WHITE),
                      children: dataUniqueSellingPoints(),
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        // cell for margin
        emptyCell(30, COLORS.WHITE),

        // cell with image
        new TableCell({
          width: {
            size: convertPixelToTwip(285),
            type: WidthType.DXA,
          },
          borders: transparentBorder(COLORS.WHITE),
          children: await buildSegmentItemImage(
            valueProposition.image.original,
            options.titleColor,
            options.titleFont,
            'VALUE PROPOSITION',
            options.valueProposition,
          ),
        }),

        emptyCell(28, COLORS.WHITE),
      ],
    });
  };

  const getBottomImg = async () => {
    if (options.valueProposition?.bottomImgBg) {
      const img = await buildColourfulDecorateImg(
        options.valueProposition.bottomImgBg,
        options.valueProposition.bottomImgColor,
      );

      return imageRunColourful(img, 45, 79, 0, 16.2, 'default', false);
    }
  };

  return {
    properties: {
      page: {
        size: pageSize,
        margin: noPageMargin,
      },
    },
    children: [
      // Primary box
      new Paragraph({
        frame: {
          position: {
            x: 0,
            y: 0,
          },
          width: convertPixelToTwip(tableWidth),
          height: convertPixelToTwip(539 + 56),
          rule: HeightRule.EXACT,
          anchor: {
            horizontal: FrameAnchorType.TEXT,
            vertical: FrameAnchorType.TEXT,
          },
          alignment: {
            x: HorizontalPositionAlign.CENTER,
            y: VerticalPositionAlign.TOP,
          },
        },
        children: [await getBottomImg()],
        shading: options.valueProposition?.pageBg
          ? shadingBox(options.valueProposition?.pageBg)
          : null,
      }),

      // Title & Description
      new Table({
        float: {
          horizontalAnchor: TableAnchorType.TEXT,
          verticalAnchor: TableAnchorType.TEXT,
          overlap: OverlapType.NEVER,
          absoluteVerticalPosition: convertPixelToTwip(0),
          absoluteHorizontalPosition: 0,
        },
        width: {
          size: convertPixelToTwip(tableWidth),
          type: WidthType.DXA,
        },
        layout: TableLayoutType.AUTOFIT,
        rows: [
          new TableRow({
            height: {
              value: convertPixelToTwip(27),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(28, COLORS.WHITE),
              new TableCell({
                width: {
                  size: convertPixelToTwip(726 + 56),
                  type: WidthType.DXA,
                },
                columnSpan: 5,
                borders: transparentBorder(COLORS.WHITE),
                children: [
                  // Title
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      textRun(locales.valueProposition.sectionName, {
                        font: options.titleFont,
                        bold: options?.valueProposition?.titleBold,
                        color: options.titleColor || COLORS.BLACK,
                        size: 24,
                        allCaps: true,
                      }),
                    ],
                  }),
                ],
              }),
              emptyCell(28, COLORS.WHITE),
            ],
          }),
          marginTableRow(10, tableWidth, COLORS.WHITE, 7),
          // description row
          new TableRow({
            height: {
              value: convertPixelToTwip(28),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(28, COLORS.WHITE),
              new TableCell({
                width: {
                  size: convertPixelToTwip(726 + 56),
                  type: WidthType.DXA,
                },
                columnSpan: 5,
                borders: transparentBorder(COLORS.WHITE),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      textRun(portfolio.valueProposition.description, {
                        font: options.bodyFont,
                        color: options.bodyColor || COLORS.BLACK,
                      }),
                    ],
                  }),
                ],
              }),
              emptyCell(28, COLORS.WHITE),
            ],
          }),
          marginTableRow(40, tableWidth, COLORS.WHITE, 7),
          await dataRows(),
        ],
      }),

      whiteBgImage(options.valueProposition?.pageBg),
    ],
  };
};
