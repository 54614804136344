export const sustainabilityNumber = (sdg) => sdg.replace(/[a-zA-Z :,.]/g, '');

export function selectLabel(value, sdgs) {
  if (value) {
    const number = parseInt(sustainabilityNumber(value));
    const obj = sdgs[number - 1];

    if (obj) {
      return obj.title;
    }
    return '';
  }
  return value;
}

export function selectValue(value, _label = null) {
  if (value) {
    return { value, label: _label || value };
  }
  return null;
}
