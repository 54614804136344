import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TestRow from '../../main/containers/testing/Components/TestRow';
import Modal from '../../main/containers/testing/Components/Modal';
import { TECH_MODERN_TEMPLATE_NAME } from '../../main/utils/const';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';

const Testing = ({
  locales,
  locales: {
    testing: { titles },
    portfolioWeb,
  },
  titleColor,
  background,
  borderCardColor,
  iconFill,
  iconStroke,
}) => {
  const [modal, setModal] = useState({
    status: false,
  });

  const {
    portfolio: { testing },
    testing: { collection: tests },
  } = useSelector((state) => state);

  const {
    testing: { setTestReducer },
  } = useDispatch();

  const onClick = (test) => {
    setModal({ status: true });
    setTestReducer(test);
  };

  return (
    <div
      className="testing testing-tech-web section-web"
      style={{ backgroundColor: background }}
    >
      <Modal
        isOpen={modal.status}
        isEditable={false}
        titles={titles}
        setModalStatus={setModal}
        locales={locales}
        isPreview
      />
      <SectionHeaderWeb
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolioWeb.testing}
        description={testing.description}
        portfolioWeb={portfolioWeb}
        style={{ color: titleColor }}
      />

      <div className="tests-list">
        {tests.map((testItem) => (
          <TestRow
            isPreview
            test={testItem}
            titles={titles}
            key={testItem.id}
            onClick={() => onClick(testItem)}
            borderCardColor={borderCardColor}
            iconFill={iconFill}
            iconStroke={iconStroke}
          />
        ))}
      </div>
    </div>
  );
};

export default Testing;
