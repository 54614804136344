import React, { useEffect, useMemo, useState } from 'react';

import Img from '../Img';
import Spinner from '../Spinner';
import dropzoneAreaInnerCurrent from './DropzoneAreaInnerCurrent';

export default function DropzoneArea({
  dropzone,
  onAreaClick,
  croppedImage,
  aspectRatio,
  small,
  coords,
  uploading,
  cropper,
  borderImageColor,
  disabled,
  locales: { common },
}) {
  const { getRootProps, getInputProps } = dropzone;
  const [suffix, setSuffix] = useState(
    coords ? `?${coords.x}_${coords.y}` : '',
  );
  const [src, setSrc] = useState('');

  useEffect(() => {
    if (coords) setSuffix(`?${coords.x}_${coords.y}`);
    else setSuffix('');
    setSrc(croppedImage + suffix);
  }, [coords]);

  const placeholder = useMemo(() => {
    return uploading ? (
      <div className="dndzone__placeholder">
        <Spinner />
      </div>
    ) : undefined;
  }, [uploading]);

  const areaProps = cropper ? { onClick: onAreaClick } : getRootProps();
  const inputComponent = cropper ? null : <input {...getInputProps()} />;

  const areaPropsWithClick = {
    ...areaProps,
    onClick: disabled ? () => {} : areaProps.onClick,
  };

  return (
    <div className="dndzone__area">
      {croppedImage ? (
        <div className="dndzone__img-wrapper" {...areaPropsWithClick}>
          {inputComponent}
          <Img
            src={src}
            aspectRatio={aspectRatio}
            className="dndzone__img"
            borderImageColor={borderImageColor}
            inner={placeholder}
          />
          <i className="icon-Add" />
        </div>
      ) : (
        dropzoneAreaInnerCurrent(
          common,
          small,
          dropzone,
          uploading,
          aspectRatio,
          disabled,
        )
      )}
    </div>
  );
}
