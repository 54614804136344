import React from 'react';
import getRoleLabelText from '../utils/getRoleLabelText';

export default function CommentRoleLabelComponent({ sender, currentUserId }) {
  if (sender.id === currentUserId)
    return <div className="comment-item-header-sender__role owner">You</div>;

  return (
    <div className="comment-item-header-sender__role">
      {getRoleLabelText(sender.role)}
    </div>
  );
}
