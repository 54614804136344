import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import icons from '../../main/utils/icons';

function InputSearch({
  onSearch,
  searchPlaceholder = 'Search by name',
  loading = false,
}) {
  const [searchValue, setSearchValue] = useState();
  const handleSearch = (e) => {
    setSearchValue(e.currentTarget.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onSearch(searchValue);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);
  return (
    <Input
      allowClear
      className="admin-table-search"
      suffix={icons.searchIcon}
      placeholder={searchPlaceholder}
      width={200}
      onChange={(e) => {
        if (e.currentTarget.value === '') {
          setSearchValue(null);
        }
      }}
      onPressEnter={handleSearch}
      disabled={loading}
    />
  );
}

InputSearch.propTypes = {
  searchPlaceholder: PropTypes.string,
  onSearch: PropTypes.func,
  loading: PropTypes.bool,
};
export default InputSearch;
