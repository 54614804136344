import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import Header from '../../components/Header';
import Tabs from '../../components/Tabs';
import TabsCut from '../../components/TabsCut';
import Actions from '../../components/Actions';
import Button from '../../components/Button';
import List from '../../components/List';
import Line from '../../components/Line';
import Text from '../../components/Text';
import Title from '../../components/Title';
import {
  COSTS_MAX_COUNT,
  COST_PRICE_OPTIONS,
  SELECT_STYLES,
} from '../../utils/const';
import RemoveButton from '../../components/RemoveButton';
import TabsList from '../../components/TabsList';
import TabsListItem from '../../components/TabsListItem';
import ConfirmationModal from '../../components/ConfirmationModal';
import NumberInput from '../../components/NumberInput';
import Textarea from '../../components/Textarea';
import SvgIcon from '../../components/SvgIcon';
import MobileMenuHeader from '../../components/MobileMenuHeader';
import { selectValue } from '../../utils/helpers';

const fields = ['name', 'amount', 'period'];
const icons = ['home', 'dollar', 'graph'];
const iconsComponents = icons.map((e) => <SvgIcon name={e} />);

export default function FixedCost(props) {
  const {
    nextClickHandler,
    menuItems,
    selectComponent,
    componentIndex,
    rules,
    backPath,
    locales,
    locales: {
      common,
      tipCustomerSegment,
      caseStudies,
      cost: {
        name,
        fixedCost: { header, title, text, list, tabs },
      },
    },
  } = props;
  const { collection } = useSelector((state) => state.cost);
  const {
    cost: { addCost, updateCost, removeCost },
  } = useDispatch();
  const [opened, setOpened] = useState(false);

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon="icon-cost"
        locales={locales}
        componentIndex={componentIndex}
        className="bmc-page-header--violet"
        tipLocales={tipCustomerSegment}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="FinanceCanvas" title={name} color="violet" />
        }
      />

      <Text align="center">{[text[0]]}</Text>

      <Title text={title[0]} align="left" />

      <List items={list} className="margin-top-40" icons={icons} />

      <Line />

      <h2 className="bmc-page-title">
        <span className="bmc-page-title--violet">{title[1]}</span> {title[2]}
      </h2>

      <Text align="center">{[text[1]]}</Text>

      <Tabs
        tabs={[common.yourStrategy, common.caseStudy]}
        withBlur
        className="margin-top-60 bmc-page-tabs--violet"
      >
        {[
          <div key="strategy">
            <TabsList horizontal cost>
              {fields.map((field, i) => (
                <TabsListItem
                  key={i}
                  title={tabs[field]}
                  icon={iconsComponents[i]}
                />
              ))}
            </TabsList>

            {collection.map((item, index) => (
              <TabsList key={item.id} horizontal minimalMargin cost>
                <TabsListItem
                  icon={iconsComponents[0]}
                  title={tabs[fields[0]]}
                  text={
                    // eslint-disable-next-line
                    <Textarea
                      rows={1}
                      delay={700}
                      value={item.name || ''}
                      onChange={(event) =>
                        updateCost({
                          index,
                          name: 'name',
                          value: event.target.value,
                          id: item.id,
                        })
                      }
                      placeholder={tabs.namePlaceholder}
                    />
                  }
                />

                <TabsListItem
                  icon={iconsComponents[1]}
                  title={tabs[fields[1]]}
                  text={
                    // eslint-disable-next-line
                    <NumberInput
                      rows={1}
                      value={item.amount}
                      onChange={(event) =>
                        updateCost({
                          index,
                          name: 'amount',
                          value: event.target.value,
                          id: item.id,
                        })
                      }
                      placeholder={tabs.amountPlaceholder}
                    />
                  }
                />

                <TabsListItem
                  icon={iconsComponents[2]}
                  title={tabs[fields[2]]}
                  text={
                    // eslint-disable-next-line
                    <Select
                      styles={SELECT_STYLES}
                      className="text-black"
                      options={COST_PRICE_OPTIONS}
                      value={selectValue(item.period)}
                      placeholder={tabs.periodPlaceholder}
                      onChange={({ value }) =>
                        updateCost({
                          index,
                          name: 'period',
                          value,
                          id: item.id,
                        })
                      }
                    />
                  }
                >
                  {collection.length > 1 && (
                    <ConfirmationModal
                      body="Delete this content. Are you sure?"
                      confirmText="Delete"
                      isBlockScroll
                      onConfirm={() => removeCost({ index, id: item.id })}
                    >
                      <RemoveButton
                        className="bmc-page-tabs-content-list-remove"
                        active={collection.length > 1}
                      />
                    </ConfirmationModal>
                  )}
                </TabsListItem>
              </TabsList>
            ))}

            {collection.length < COSTS_MAX_COUNT && (
              <div className="bmc-page-tabs-content-actions">
                <Button
                  onClick={addCost}
                  wide
                  icon="icon-Add"
                  title={tabs.addNewSomething}
                  className="bmc-button--violet"
                />
              </div>
            )}
          </div>,

          <Tabs
            key="tabs"
            tabs={caseStudies.map((e) => e.name)}
            className="bmc-page-tabs--black bmc-page-tabs--inner"
          >
            {caseStudies.map((item, index) => (
              <div key={index}>
                <TabsCut
                  setOpened={setOpened}
                  opened={opened}
                  caseStudy={item}
                />
                <div>
                  <TabsList horizontal cost>
                    {fields.map((field, i) => (
                      <TabsListItem
                        key={i}
                        title={tabs[field]}
                        icon={iconsComponents[i]}
                      />
                    ))}
                  </TabsList>
                </div>

                {item.costs.map((cost, i) => (
                  <TabsList key={i} horizontal cost>
                    {fields.map((field, ii) => (
                      <TabsListItem
                        key={ii}
                        icon={i === 0 ? iconsComponents[ii] : null}
                        title={i === 0 ? tabs[field] : null}
                        text={cost[field]}
                      />
                    ))}
                  </TabsList>
                ))}
              </div>
            ))}
          </Tabs>,
        ]}
      </Tabs>

      <Actions nextClickHandler={nextClickHandler} buttonName={common.next} />
    </div>
  );
}
