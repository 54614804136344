const buildYourTestRules = (test) => !!test.test;
const testPlanRules = ({ data }) =>
  !!(
    data.plan ||
    data.companyName ||
    data.headline ||
    data.explain ||
    data.thingOne ||
    data.thingTwo ||
    data.thingThree
  );
const summaryRules = ({ analyse }) =>
  !!(analyse.resultsAndLearning || analyse.status);

export const calculateMenuRules = (test) => {
  const testPlanRule = testPlanRules(test);
  const summaryRule = summaryRules(test);

  return [
    true,
    true,
    true,
    buildYourTestRules(test),
    testPlanRule,
    testPlanRule,
    summaryRule,
    summaryRule,
  ];
};

export const getLastTestingPage = (state) => {
  const rules = calculateMenuRules(state);
  const allowedTo = rules.findIndex((i) => !i);

  return allowedTo === -1 ? rules.length - 2 : allowedTo - 1;
};

export default function calculateTestingProgress({ testing: { test } }) {
  const stepsCount = 3;
  const step = 100 / stepsCount;
  let testingProgress = 0;

  if (!test) return testingProgress;

  if (buildYourTestRules(test)) testingProgress += step;
  if (testPlanRules(test)) testingProgress += step;
  if (summaryRules(test)) testingProgress += step;

  return testingProgress;
}
