import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import Textarea from '../../../components/Textarea';
import Dropzone from '../../../components/Dropzone';
import {
  COMMENT_INTRODUCTION,
  PORTFOLIO_INTRO_LOGO_ASPECT_RATIO,
  TEXTAREA_DELAY,
} from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';

const IntroductionTech = ({
  data,
  locales,
  locales: { portfolio },
  titleColor,
  backgroundImage,
  background,
}) => {
  const {
    portfolio: {
      updateIntroductionImage,
      updateIntroductionImageCoords,
      updateSectionField,
    },
  } = useDispatch();

  const { uploadingIntrologo, uploadingIntrobg } = useSelector(
    (state) => state.portfolio,
  );

  const {
    title,
    description,
    introLogo,
    introLogoCoords,
    introBg,
    introBgCoords,
  } = data;

  useEffect(() => {
    if (!isEmpty(introLogoCoords)) {
      const introLogoWithoutCrop = introLogo.cropped.slice(
        0,
        introLogo.cropped.lastIndexOf('?'),
      );
      updateSectionField({
        sectionName: 'introduction',
        field: 'introLogo',
        name: 'introLogo',
        value: {
          ...introLogo,
          cropped: `${introLogoWithoutCrop}?${introLogoCoords.x}_${introLogoCoords.y}`,
        },
      });
    }
  }, [introLogoCoords]);

  useEffect(() => {
    if (!isEmpty(introBgCoords)) {
      const introBgWithoutCrop = introBg.cropped.slice(
        0,
        introBg.cropped.lastIndexOf('?'),
      );
      updateSectionField({
        sectionName: 'introduction',
        field: 'introBg',
        name: 'introBg',
        value: {
          ...introBg,
          cropped: `${introBgWithoutCrop}?${introBgCoords.x}_${introBgCoords.y}`,
        },
      });
    }
  }, [introBgCoords]);

  const handleDescriptionSubmit = useCallback((e) => {
    updateSectionField({
      sectionName: 'introduction',
      field: 'description',
      name: 'description',
      value: e.target.value,
    });
  }, []);

  const handleInputSubmit = useCallback((e) => {
    updateSectionField({
      sectionName: 'introduction',
      field: 'title',
      name: 'title',
      value: e.target.value,
    });
  }, []);

  const onLogoDrop = useCallback(([file], openCropperHandler) => {
    updateIntroductionImage({
      name: 'introLogo',
      blob: file,
      value: URL.createObjectURL(file),
      success: () => {
        openCropperHandler();
      },
    });
  }, []);

  const onLogoCropComplete = (croppedAreaPixels, innerCropData, zoom) => {
    updateIntroductionImageCoords({
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: introLogoCoords,
      name: 'introLogo',
    });
  };

  const onBgDrop = useCallback(([file], openCropperHandler) => {
    updateIntroductionImage({
      name: 'introBg',
      blob: file,
      value: URL.createObjectURL(file),
      success: () => {
        openCropperHandler();
      },
    });
  }, []);

  const onBgCropComplete = (croppedAreaPixels, innerCropData, zoom) => {
    updateIntroductionImageCoords({
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: introBgCoords,
      name: 'introBg',
    });
  };

  return (
    <div
      className="introduction-tech relative"
      style={{ backgroundColor: background }}
    >
      <PortfolioCommentIcon title={COMMENT_INTRODUCTION} />
      <div className="introduction-tech-left">
        <div className="logo-hover">
          <Dropzone
            className="logo-upload"
            onDrop={onLogoDrop}
            onCropperSave={onLogoCropComplete}
            locales={locales}
            image={introLogo}
            coords={introLogoCoords}
            aspectRatio={PORTFOLIO_INTRO_LOGO_ASPECT_RATIO}
            uploading={uploadingIntrologo}
            cropper
          />
        </div>
        <div className="textarea-techModern">
          <Textarea
            onChange={handleInputSubmit}
            value={title}
            delay={TEXTAREA_DELAY}
            placeholder={portfolio.introduction.projectName}
            className="textarea-tech textarea-tech-name title custom-font-title"
            style={{ color: titleColor }}
          />
        </div>
        <div className="textarea-techModern">
          <Textarea
            onChange={handleDescriptionSubmit}
            value={description}
            delay={TEXTAREA_DELAY}
            placeholder={portfolio.introduction.projectNamePlaceholder}
            rows={4}
            className="textarea-tech textarea-tech-description custom-font-body"
          />
        </div>
      </div>
      <div className="introduction-tech-right relative">
        <div className="image-hover">
          <Dropzone
            className="image"
            background={{ background: backgroundImage }}
            onDrop={onBgDrop}
            onCropperSave={onBgCropComplete}
            locales={locales}
            image={introBg}
            coords={introBgCoords}
            aspectRatio={1}
            uploading={uploadingIntrobg}
            cropper
          />
        </div>
      </div>
    </div>
  );
};

export default IntroductionTech;
