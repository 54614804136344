import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../components/Header';
import Title from '../../components/Title';
import Text from '../../components/Text';
import Line from '../../components/Line';
import List from '../../components/List';
import Actions from '../../components/Actions';
import Tabs from '../../components/Tabs';
import RemoveButton from '../../components/RemoveButton';
import { KEY_RESOURCES_MAX_COUNT } from '../../utils/const';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Textarea from '../../components/Textarea';
import Card from '../keyActivity/Card';
import ConfirmationModal from '../../components/ConfirmationModal';
import SvgIcon from '../../components/SvgIcon';
import TabsCut from '../../components/TabsCut';
import TabsList from '../../components/TabsList';
import TabsListItem from '../../components/TabsListItem';
import MobileMenuHeader from '../../components/MobileMenuHeader';

export default function Resources(props) {
  const {
    nextClickHandler,
    menuItems,
    selectComponent,
    componentIndex,
    rules,
    backPath,
    locales,
    locales: {
      caseStudies,
      common,
      tipCustomerSegment,
      keyResources: {
        name,
        resources: { header, step, title, text, list, tabs },
      },
      keyActivity: {
        intro: { card },
      },
    },
  } = props;
  const {
    keyResource: { collection },
    keyActivity,
  } = useSelector((state) => state);
  const {
    keyResource: { addKeyResource, updateKeyResource, removeKeyResource },
  } = useDispatch();
  const [showKeyActivities, setShowKeyActivities] = useState(false);
  const [opened, setOpened] = useState(false);
  const icons = ['gex'];
  const iconsComponents = icons.map((e) => <SvgIcon name={e} />);

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon="icon-key-resources"
        locales={locales}
        componentIndex={componentIndex}
        className="bmc-page-header--orange"
        tipLocales={tipCustomerSegment}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="KeyThingCanvas" title={name} color="orange" />
        }
      />

      <Text align="center">{[text[0]]}</Text>

      <Title text={title[0]} align="left" />

      <List items={list} icons={icons} />

      <Line />

      <h2 className="bmc-page-title">
        <span className="bmc-page-title--orange">{title[1]}</span> {title[2]}
      </h2>

      <Text align="center">{[text[1]]}</Text>

      <div className="bmc-page-text bmc-page-text--center">
        <p className="bmc-page-text--bold">
          {text[2]}
          <span className="bmc-page-text--orange"> {text[3]}</span>
        </p>

        <p>{text[4]}</p>
      </div>

      <div className="bmc-page-links">
        <ul className="bmc-page-links-container">
          <li
            className="bmc-page-links-container-link bmc-page-links-container-link--orange"
            onClick={() => setShowKeyActivities(true)}
          >
            <span>
              {keyActivity.collection
                .map((_, index) => `${step} ${index + 1}`)
                .join('->')}
            </span>
          </li>
        </ul>
      </div>

      {showKeyActivities && (
        <Modal onBlackscreenClick={() => setShowKeyActivities(false)}>
          <Card
            card={{ ...card, steps: keyActivity.collection, title: null }}
            className="bmc-page-keycard--section bmc-page-keycard--section-modal"
          />
        </Modal>
      )}

      <Tabs
        tabs={[common.yourStrategy, common.caseStudy]}
        withBlur
        className="margin-top-60 bmc-page-tabs--orange"
      >
        {[
          <TabsList key="strategy">
            <TabsListItem
              title={tabs.keyResources}
              subtitle={tabs.keyResourcesSub}
              icon={iconsComponents[0]}
            >
              <div className="bmc-page-tabs-content-list-item-texts">
                {collection.map((item, index) => (
                  <div
                    key={item.id}
                    className="bmc-page-tabs-content-list-item-text margin-bottom-5"
                  >
                    {collection.length > 1 && (
                      <ConfirmationModal
                        body="Delete this content. Are you sure?"
                        confirmText="Delete"
                        isBlockScroll
                        onConfirm={() =>
                          removeKeyResource({ index, id: item.id })
                        }
                      >
                        <RemoveButton
                          className="bmc-page-tabs-content-list-remove"
                          active={collection.length > 1}
                        />
                      </ConfirmationModal>
                    )}

                    <Textarea
                      rows={1}
                      delay={700}
                      value={item.description}
                      onChange={(event) =>
                        updateKeyResource({
                          index,
                          name: 'description',
                          value: event.target.value,
                          id: item.id,
                        })
                      }
                      placeholder={tabs.keyResourcesPlaceholder}
                    />
                  </div>
                ))}

                {collection.length < KEY_RESOURCES_MAX_COUNT && (
                  <div className="bmc-page-tabs-content-actions">
                    <Button
                      onClick={addKeyResource}
                      wide
                      icon="icon-Add"
                      title={tabs.addKeyResources}
                      className="bmc-button--orange"
                    />
                  </div>
                )}
              </div>
            </TabsListItem>
          </TabsList>,

          <Tabs
            key="tabs"
            tabs={caseStudies.map((e) => e.name)}
            className="bmc-page-tabs--black bmc-page-tabs--inner"
          >
            {caseStudies.map((item, index) => (
              <div key={index}>
                <TabsCut
                  opened={opened}
                  setOpened={setOpened}
                  caseStudy={item}
                />

                {item.keyResources.map((keyResource, i) => (
                  <TabsList key={i} formStyle preview>
                    <TabsListItem
                      title={keyResource.name}
                      text={keyResource.description}
                      icon={iconsComponents[0]}
                    />
                  </TabsList>
                ))}
              </div>
            ))}
          </Tabs>,
        ]}
      </Tabs>

      <Actions nextClickHandler={nextClickHandler} buttonName={common.next} />
    </div>
  );
}
