import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Header from '../../components/Header';
import Title from '../../components/Title';
import Text from '../../components/Text';
import Line from '../../components/Line';
import Actions from '../../components/Actions';
import MobileMenuHeader from '../../components/MobileMenuHeader';
import Card from './Card';

export default function Summary(props) {
  const {
    nextClickHandler,
    menuItems,
    selectComponent,
    componentIndex,
    rules,
    backPath,
    locales,
    locales: {
      common,
      ideation: {
        name,
        intro: { card },
        summary: { header, title, text },
      },
    },
  } = props;

  const {
    ideation: {
      idea: {
        data: { ideas, pickedIdeaId },
      },
    },
    bmc: { current: currentBmc },
  } = useSelector((state) => state);

  const {
    ideation: { removeIdea },
  } = useDispatch();

  const idea = ideas.find((e) => e.id === pickedIdeaId);

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon="icon-VP"
        locales={locales}
        componentIndex={componentIndex}
        className="bmc-page-header--blue"
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="ideationActive" title={name} color="blue" />
        }
      />

      <Text>{[text[0], text[1], text[2]]}</Text>

      <Line />

      <Title text={title[0]} />

      {idea && (
        <Card
          card={{
            ...card,
            ...idea,
            solution: idea.solution,
            marketSize: idea.marketSize || 0,
            price: idea.price || 0,
            excitement: idea.excitement || 0,
            problem: idea.problem || '',
          }}
          bmc={currentBmc}
          removeIdea={removeIdea}
          className="margin-top-60 margin-wide-40-lg"
          locales={locales}
        />
      )}

      <Actions
        nextClickHandler={nextClickHandler}
        buttonName={common.done}
        finish={common.finish}
      />
    </div>
  );
}
