import React from 'react';
import { useSelector } from 'react-redux';

import { removeNewLines } from '../../main/utils/helpers';
import { renderLogo } from '../../main/utils/helpers/renderLogo';
import { renderImage } from '../../main/utils/helpers/renderImage';
import { TECH_MODERN_TEMPLATE_NAME } from '../../main/utils/const';

const IntroductionTechWeb = ({ titleColor, backgroundImage, background }) => {
  const { introduction } = useSelector((state) => state.portfolio);

  return (
    <div
      className="introduction-tech-web"
      style={{ backgroundColor: background }}
    >
      <div className="introduction-tech-web-left">
        {renderLogo(
          TECH_MODERN_TEMPLATE_NAME,
          introduction.introLogo,
          backgroundImage,
          'Logo',
        )}

        <div
          className="tech-title-web title custom-font-title"
          style={{ color: titleColor }}
        >
          {introduction.title}
        </div>

        <div className="description custom-font-body">
          {introduction.description && removeNewLines(introduction.description)}
        </div>
      </div>
      <div className="introduction-tech-web-right">
        <div className="image">
          {renderImage(
            TECH_MODERN_TEMPLATE_NAME,
            introduction.introBg,
            backgroundImage,
            'Introduction',
            1,
          )}
        </div>
      </div>
    </div>
  );
};

export default IntroductionTechWeb;
