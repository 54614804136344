import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { merge } from 'lodash';

import Textarea from '../../components/Textarea';
import { CURRENT_TEMPLATE_NAME, SELECT_STYLES_MODAL } from '../../utils/const';
import ConfirmationModal from '../../components/ConfirmationModal';
import RemoveButton from '../../components/RemoveButton';
import SvgIcon from '../../components/SvgIcon';
import {
  sustainabilityNumber,
  selectLabel,
  selectValue,
} from '../../utils/helpers';
import { getColorDependsOnBackground } from '../../utils/helpers/getColorDependsOnBackground';

const CustomTable = ({
  editMode = false,
  locales: {
    portfolio: { sustainability },
    sustainability: { sdgs },
  },
  collection: collectionCards,
  iconFillTable,
  backgroundTable,
  borderCardTableColor,
  backgroundCells,
  backgroundIcon,
  backgroundRow,
  iconTableStyles,
  templateName,
  titleTableColor,
  textColor,
  borderTableColor,
  textStyle,
  isFourthColorPaletteNumber = false,
}) => {
  const {
    sustainability: { updateSustainability, removeSustainability },
  } = useDispatch();
  const sustainabilitySdgOptions = sdgs.map((e, index) => ({
    label: e.title,
    value: (index + 1).toString(),
  }));

  const isCurrentTemplate = templateName === CURRENT_TEMPLATE_NAME;

  const optionStyles = {
    option: (styles) => {
      return {
        ...styles,
        fontFamily: 'inherit',
      };
    },
  };

  const colorDependsOnBackgroundAndPallete = isFourthColorPaletteNumber
    ? getColorDependsOnBackground(backgroundTable)
    : titleTableColor;

  const getBackgroundRowStyle = (i) => {
    return backgroundRow
      ? {
          backgroundColor: i % 2 !== 0 ? backgroundRow : backgroundCells,
          borderColor: borderTableColor,
        }
      : {
          backgroundColor: backgroundCells,
          borderColor: borderTableColor,
        };
  };

  return (
    <div
      className="table-wrapper relative"
      style={{ backgroundColor: borderCardTableColor }}
    >
      <table className="table" style={{ backgroundColor: backgroundTable }}>
        <thead>
          <tr>
            <th
              className={`width-20 ${textStyle} custom-font-body`}
              style={{ color: colorDependsOnBackgroundAndPallete }}
            >
              {sustainability.table.sgd}
            </th>
            <th
              className={`width-40 ${textStyle} custom-font-body`}
              style={{ color: colorDependsOnBackgroundAndPallete }}
            >
              {sustainability.table.problem}
            </th>
            <th
              className={`width-40 ${textStyle} custom-font-body`}
              style={{ color: colorDependsOnBackgroundAndPallete }}
            >
              {sustainability.table.value}
            </th>
          </tr>
        </thead>
        <tbody
          className={`${textStyle} custom-font-body`}
          style={{ color: textColor }}
        >
          {collectionCards.map((item, i) =>
            editMode ? (
              <tr key={item.id} style={getBackgroundRowStyle(i)}>
                <td>
                  <Select
                    className={`bmc-form-select ${textStyle} custom-font-body`}
                    styles={merge(SELECT_STYLES_MODAL, optionStyles)}
                    options={sustainabilitySdgOptions}
                    value={selectValue(item.sdg, selectLabel(item.sdg, sdgs))}
                    onChange={({ value }) =>
                      updateSustainability({
                        name: 'sdg',
                        value,
                        id: item.id,
                      })
                    }
                  />
                </td>

                <td>
                  <Textarea
                    value={item.problem}
                    delay={700}
                    className={`bmc-form-textarea ${textStyle} custom-font-body`}
                    onChange={(e) =>
                      updateSustainability({
                        name: 'problem',
                        value: e.target.value,
                        id: item.id,
                      })
                    }
                  />
                </td>

                <td>
                  <Textarea
                    value={item.value}
                    delay={700}
                    className={`bmc-form-textarea ${textStyle} custom-font-body`}
                    onChange={(e) =>
                      updateSustainability({
                        name: 'value',
                        value: e.target.value,
                        id: item.id,
                      })
                    }
                  />

                  {collectionCards.length > 1 && (
                    <ConfirmationModal
                      body="Delete this content. Are you sure?"
                      confirmText="Delete"
                      onConfirm={() => removeSustainability({ id: item.id })}
                    >
                      <RemoveButton className="bmc-page-suscard-remove" />
                    </ConfirmationModal>
                  )}
                </td>
              </tr>
            ) : (
              <tr key={item.id} style={getBackgroundRowStyle(i)}>
                <td style={{ borderColor: borderTableColor }}>
                  <div className="title" style={{ color: titleTableColor }}>
                    SGD
                  </div>
                  <div
                    className={classNames(
                      'icon',
                      isCurrentTemplate &&
                        `sdg-card-color-${sustainabilityNumber(item.sdg)}`,
                      iconTableStyles,
                    )}
                    style={{
                      backgroundColor: backgroundIcon,
                    }}
                  >
                    <div className="icon-size" style={{ fill: iconFillTable }}>
                      <SvgIcon name={`sdg${sustainabilityNumber(item.sdg)}`} />
                    </div>
                  </div>
                  <div
                    className="border-right"
                    style={{ backgroundColor: borderTableColor }}
                  />
                </td>
                <td style={{ borderColor: borderTableColor }}>
                  <div className="title" style={{ color: titleTableColor }}>
                    Social/Environmental Problem
                  </div>
                  <div>{item.problem}</div>
                  <div
                    className="border-right"
                    style={{ backgroundColor: borderTableColor }}
                  />
                </td>
                <td style={{ borderColor: borderTableColor }}>
                  <div className="title" style={{ color: titleTableColor }}>
                    Social/Environmental Value
                  </div>
                  <div>{item.value}</div>
                </td>
              </tr>
            ),
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
