import React from 'react';

import IdeaCarousel from './IdeaCarousel';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function index(props) {
  return <IdeaCarousel {...props} />;
}
