import React from 'react';

import Textarea from '../../components/Textarea';
import ConfirmationModal from '../../components/ConfirmationModal';

export const getRenderBlockGKG = (
  items,
  editMode,
  currentTab,
  updateStrategy,
  removeStrategy,
  backgroundBlock,
  borderColorBlock,
  titleTabsColor,
  textColor,
  textStyle,
) => {
  return items.map((item, index) => (
    <div
      key={index}
      className="bmc-page-gkgtabs-content-block"
      style={{
        backgroundColor: backgroundBlock,
        borderColor: borderColorBlock,
      }}
    >
      {editMode && items.length > 1 && (
        <div className="modal">
          <ConfirmationModal
            body="Delete this content. Are you sure?"
            confirmText="Delete"
            onConfirm={() =>
              removeStrategy({
                index,
                collectionName: `${currentTab}Strategies`,
              })
            }
          >
            <div className="bmc-page-gkgtabs-content-block-remove">
              <i className="icon-Delete" />
            </div>
          </ConfirmationModal>
        </div>
      )}
      {editMode ? (
        <Textarea
          value={item.name}
          delay={700}
          className={`bmc-form-textarea ${textStyle} custom-font-body`}
          placeholder="Add Title"
          onChange={(e) =>
            updateStrategy({
              index,
              name: 'name',
              value: e.target.value,
              collectionName: `${currentTab}Strategies`,
            })
          }
        />
      ) : (
        <div
          className={`bmc-page-gkgtabs-content-block-title ${textStyle} custom-font-body`}
          style={{ color: titleTabsColor }}
        >
          {item.name}
        </div>
      )}

      {editMode ? (
        <div className="textarea-description">
          <Textarea
            value={item.description}
            delay={700}
            className={`bmc-form-textarea ${textStyle} custom-font-body`}
            placeholder="Add Description"
            onChange={(e) =>
              updateStrategy({
                index,
                name: 'description',
                value: e.target.value,
                collectionName: `${currentTab}Strategies`,
              })
            }
          />
        </div>
      ) : (
        <div
          className={`bmc-page-gkgtabs-content-block-text ${textStyle} custom-font-body`}
          style={{ color: textColor }}
        >
          {item.description}
        </div>
      )}
    </div>
  ));
};
