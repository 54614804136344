import React from 'react';
import { useDispatch } from 'react-redux';

import { COLOURFUL_TEMPLATE_NAME, COMMENT_BMC } from '../../../utils/const';
import BmcCanvas from '../../bmc/BmcCanvas';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import SectionHeader from '../../../components/SectionHeader';
import SvgIcon from '../../../components/SvgIcon';

const BMC = ({
  data: { description },
  locales,
  locales: { portfolio },
  className,
  sectionClassName,
  iconFill,
  iconFillClose,
  iconFillTable,
  iconFillSlider,
  iconStyle,
  backgroundMainSection,
  backgroundBMC,
  backgroundIcon,
  backgroundRow,
  styleColor,
  titleStyle,
  textStyle,
  textWithImage,
  backgroundIconTable,
  backgroundSliderColor,
  backgroundImage,
  titleColor,
  backgroundButton,
  buttonStyle,
  sectionHeaderTitleColor,
  descriptionColor,
  firstSvgColor,
  secondSvgColor,
  backgroundItems,
  backgroundSection,
  borderSectionColor,
  titleBMCSectionColor,
  iconTableStyles,
  buttonColor,
  textColor,
  templateName,
  isProgressBar = false,
  disabled = false,
}) => {
  const {
    portfolio: { updateSectionField },
  } = useDispatch();

  const isColourfulfunTemplate = templateName === COLOURFUL_TEMPLATE_NAME;

  return (
    <div
      className={`${className} relative`}
      style={{ backgroundColor: backgroundMainSection }}
    >
      <PortfolioCommentIcon title={COMMENT_BMC} />
      {isColourfulfunTemplate && (
        <>
          <div className="svg-funBMCsixth" style={{ fill: firstSvgColor }}>
            <SvgIcon name="funBMCsixth" />
          </div>
          <div className="svg-funBMCseventh" style={{ fill: secondSvgColor }}>
            <SvgIcon name="funBMCseventh" />
          </div>
        </>
      )}
      <div className="bmc">
        <SectionHeader
          templateName={templateName}
          title={portfolio.bmc.sectionName}
          description={description}
          onChange={(e) =>
            updateSectionField({
              sectionName: 'bmc',
              field: 'description',
              name: 'bmcText',
              value: e.target.value,
            })
          }
          portfolio={portfolio}
          style={{ color: sectionHeaderTitleColor }}
          descriptionColor={descriptionColor}
          disabled={disabled}
        />
        <BmcCanvas
          locales={locales}
          className="bmc-canvas--scrollable-portfolio-edit"
          sectionClassName={sectionClassName}
          isPreview={disabled}
          isProgressBar={isProgressBar}
          templateName={templateName}
          sliderStyles="sdg-card sdg-card-modal"
          iconFill={iconFill}
          iconFillClose={iconFillClose}
          iconFillTable={iconFillTable}
          iconFillSlider={iconFillSlider}
          iconTableStyles={iconTableStyles}
          backgroundIcon={backgroundIcon}
          styleColor={styleColor}
          titleColor={titleColor}
          backgroundButton={backgroundButton}
          buttonStyle={buttonStyle}
          backgroundIconTable={backgroundIconTable}
          backgroundSliderColor={backgroundSliderColor}
          backgroundImage={backgroundImage}
          backgroundItems={backgroundItems}
          backgroundSection={backgroundSection}
          borderSectionColor={borderSectionColor}
          firstSvgColor={firstSvgColor}
          secondSvgColor={secondSvgColor}
          titleStyle={titleStyle}
          iconStyle={iconStyle}
          backgroundBMC={backgroundBMC}
          textStyle={textStyle}
          backgroundRow={backgroundRow}
          textWithImage={textWithImage}
          titleBMCSectionColor={titleBMCSectionColor}
          buttonColor={buttonColor}
          textColor={textColor}
          disabled={disabled}
          isTemplates
          isColourfulfunTemplate={isColourfulfunTemplate}
        />
      </div>
    </div>
  );
};

export default BMC;
