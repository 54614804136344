import React, { useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { MentionsInput, Mention } from 'react-mentions';
import CommentInputSuggestionItem from './comment-input-suggestion-item';

let container;
const TRIGGER_CHARACTER = '@';

export default function CommentInputComponent({
  value = '',
  members = [],
  onChange,
  onSend,
  inputId = 'react-mention-id',
  currentUserId,
}) {
  const { user } = useSelector((selector) => selector);

  const handleChange = (__, newValue, newPlainTextValue, mentions) => {
    if (typeof onChange === 'function')
      onChange({
        value: newValue,
        mentionData: { newValue, newPlainTextValue, mentions },
      });
  };

  useEffect(() => {
    if (document.querySelector(`#${inputId}`))
      document.querySelector(`#${inputId}`).spellcheck = false;
  }, []);

  return (
    <div
      className="comment-input-component"
      ref={(el) => {
        container = el;
      }}
    >
      <MentionsInput
        value={value}
        onChange={handleChange}
        placeholder="Your message"
        className="mentions"
        id={inputId}
        allowSuggestionsAboveCursor
        forceSuggestionsAboveCursor
        suggestionsPortalHost={container}
        customSuggestionsContainer={(children) => {
          return <div>{children}</div>;
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            const cursorPosition = e.target.selectionStart;
            const newValue = `${value.slice(0, cursorPosition)}\n${value.slice(
              cursorPosition,
            )}`;
            onChange({ value: newValue });
            // Set cursor position after updating the value
            e.target.selectionEnd = cursorPosition + 1;
            e.target.selectionStart = cursorPosition + 1;
          } else if (e.key === 'Enter') {
            e.preventDefault();
            onSend();
            onChange({
              value: '',
              mentionData: {
                newValue: '',
                newPlainTextValue: '',
                mentions: [],
              },
            });
          }
        }}
      >
        <Mention
          type="user"
          trigger={TRIGGER_CHARACTER}
          appendSpaceOnAdd
          renderSuggestion={({ member }) => {
            return (
              <CommentInputSuggestionItem
                key={member.id}
                member={member}
                currentUserId={currentUserId}
              />
            );
          }}
          data={_.uniqBy(
            members?.map((member) => ({
              id: member.user.id,
              display: `@${member.firstName} ${member.surname}`,
              member,
            })) || [],
            'id',
          ).filter((item) => item.id !== user.current.id)}
          className="mentions__mention"
        />
      </MentionsInput>
    </div>
  );
}
