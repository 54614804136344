import React from 'react';

export const renderText = (text, textColor) => {
  return (
    <div
      className="bmc-page-card-text custom-font-body"
      style={{ color: textColor }}
    >
      {text}
    </div>
  );
};
