import React from 'react';

export default function MessageListContainer({
  children,
  isHaveNotice = false,
}) {
  return (
    <div className="message-list-container">
      <div className="message-list-container__title">
        {isHaveNotice ? 'Notification' : 'No new notifications for now'}
      </div>
      {children}
    </div>
  );
}
