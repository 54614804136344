import * as Yup from 'yup';

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required('required.firstName'),
  surname: Yup.string().required('required.surname'),
  email: Yup.string().email('invalidEmail').required('required.email'),
  password: Yup.string().required('required.password').min(8, 'min.password'),
  projectName: Yup.string().required('required.projectName'),
  teammates: Yup.array().of(Yup.string().email('invalidEmail')),
});

export default SignupSchema;
