import React from 'react';

const SpecialList = ({ items, title }) => {
  return (
    <div className="testing-special-list-wrapper">
      <div className="header">{title}</div>
      <div className="blocks">
        {items.map((i, index) => (
          <div key={index} className="item">
            <i className={i.icon} />
            <div className="content">
              <div className="title">{i.title}</div>
              {i.text.map((t, idx) => (
                <div key={idx} className="text">
                  {t}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpecialList;
