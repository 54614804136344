import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import SubChapter from './SubChapter';

const UnitEconomics = ({
  locales: { finance },
  isPreview = false,
  marginTop,
  background,
  separatorColor,
  borderCardColor,
  verticalBorderColor,
  backgroundRow,
  titleColor,
  textColor,
  secondTitleColor,
  tableTitleColor,
}) => {
  const { collection: products } = useSelector((state) => state.product);
  const { collection: revenues } = useSelector((state) => state.revenue);

  const price = useCallback((source, before = '', after = '') => {
    return source == null || Number.isNaN(source)
      ? ''
      : `${before}${source}${after}`;
  }, []);

  const getGrossProfit = (endUserPrice, costPrice) => {
    const grossProfit = parseFloat(+endUserPrice - +costPrice).toFixed(2);
    return grossProfit;
  };

  const getGrossMargin = (endUserPrice, costPrice) => {
    const grossMargin =
      endUserPrice && costPrice
        ? parseFloat(
            (getGrossProfit(endUserPrice, costPrice) / +endUserPrice) * 100,
          ).toFixed(0)
        : 0;
    return grossMargin;
  };

  return (
    <div className="chapter">
      {!isPreview && (
        <div className="title margin-top-50" style={{ color: titleColor }}>
          {finance.unitEconomics}
        </div>
      )}

      <SubChapter
        name={finance.revenueStrategy.title}
        className="subchapter--big-title"
        separatorColor={separatorColor}
        textColor={secondTitleColor}
      />
      <div className={`${marginTop} info${isPreview ? '-preview' : ''}`}>
        {revenues.map(({ id, name, description }) => {
          return (
            <div
              key={id}
              className="info-block"
              style={{ borderColor: borderCardColor }}
            >
              <div
                className="title custom-font-body"
                style={{ color: tableTitleColor }}
              >
                {name}
              </div>
              <div
                className="description custom-font-body"
                style={{ color: textColor }}
              >
                {description}
              </div>
            </div>
          );
        })}
      </div>

      <SubChapter
        name="Unit Economics"
        className={`${marginTop} subchapter--big-title`}
        separatorColor={separatorColor}
        textColor={secondTitleColor}
      />
      <div
        className={`table-subchapter ${marginTop}`}
        style={{ borderColor: borderCardColor }}
      >
        <table className="custom-font-body">
          <thead
            className="custom-font-body"
            style={{ color: tableTitleColor }}
          >
            <tr>
              <th
                className="width-20"
                style={{ borderColor: verticalBorderColor }}
              >
                {finance.table.productName}
              </th>
              <th
                className="width-16"
                style={{ borderColor: verticalBorderColor }}
              >
                {finance.table.unit}
              </th>
              <th
                className="width-16"
                style={{ borderColor: verticalBorderColor }}
              >
                {finance.table.unitCost}
              </th>
              <th
                className="width-16"
                style={{ borderColor: verticalBorderColor }}
              >
                {finance.table.salePrice}
              </th>
              <th
                className="width-16"
                style={{ borderColor: verticalBorderColor }}
              >
                {finance.table.grossProfit}
              </th>
              <th className="width-16">{finance.table.grossMargin}</th>
            </tr>
          </thead>
          <tbody className="custom-font-body" style={{ color: textColor }}>
            {products.map(
              ({ id, name, unit, costPrice, endUserPrice }, index) => {
                const grossProfit = getGrossProfit(endUserPrice, costPrice);
                const grossMargin = getGrossMargin(endUserPrice, costPrice);
                return (
                  <tr
                    key={id}
                    style={
                      index % 2 !== 0
                        ? {
                            backgroundColor: backgroundRow,
                            borderTopColor: background,
                            borderBottomColor: background,
                          }
                        : {}
                    }
                  >
                    <td style={{ borderColor: verticalBorderColor }}>
                      {name == null ? '' : name}
                    </td>
                    <td style={{ borderColor: verticalBorderColor }}>
                      {unit == null ? '' : unit}
                    </td>
                    <td style={{ borderColor: verticalBorderColor }}>
                      {price(costPrice)}
                    </td>
                    <td style={{ borderColor: verticalBorderColor }}>
                      {price(endUserPrice)}
                    </td>
                    <td style={{ borderColor: verticalBorderColor }}>
                      {price(grossProfit)}
                    </td>
                    <td>{price(grossMargin, '', '%')}</td>
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UnitEconomics;
