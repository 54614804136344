import React from 'react';

function ExpandIcons({ children }) {
  return (
    <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
      {children}
    </div>
  );
}

export default ExpandIcons;
