import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { truncate } from 'lodash';
import { useMediaQuery } from '@material-ui/core';

import CustomerSegmentCard from '../../customerSegment/CardColourfulArch';
import ImageDrop from '../../../components/ImageDrop/ImageDropCS';
import {
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  BUTTON_LIGHT_MODAL_STYLE_ARCH,
  BUTTON_MODAL_STYLE_ARCH,
  COLORS,
  COMMENT_SEGMENT,
  IMAGE_ASPECT_RATIO_CS_ARCH,
  IMAGE_ASPECT_RATIO_ONE,
  TITLE_MODAL_STYLE_ARCH,
} from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import { getColorDependsOnBackground } from '../../../utils/helpers/getColorDependsOnBackground';
import { getImageAspectRatio } from '../../../utils/helpers/getImageAspectRatio';

const { black } = COLORS;

const CustomerSegmentArch = ({
  data: { description },
  locales,
  locales: {
    portfolio,
    customerSegment: {
      intro: { card },
    },
  },
  backgroundSection,
  backgroundImage,
  backgroundModal,
  isFourthColorPaletteNumber = false,
  disabled = false,
}) => {
  const [activeCardId, setActiveCardId] = useState(null);

  const {
    customerSegment: { updateCustomerSegment },
    portfolio: { updateSectionField },
  } = useDispatch();

  const { collection } = useSelector((state) => state.customerSegment);

  const activeCardData = useMemo(() => {
    if (collection.length && !activeCardId) {
      setActiveCardId(collection[0].id);
      return collection[0];
    }
    return collection.find((i) => i.id === activeCardId);
  }, [collection, activeCardId]);

  const isSmallDesktop = useMediaQuery('(max-width: 1024px)');

  const textColorDependsOnBackground =
    isFourthColorPaletteNumber &&
    getColorDependsOnBackground(backgroundSection);

  const singleCard = activeCardData && (
    <CustomerSegmentCard
      locales={locales}
      uploadFileHandler={updateCustomerSegment}
      dropzoneMode
      blur={false}
      disabled={disabled}
      editMode={!disabled}
      templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
      textareaWrapperStyle="textarea-arch"
      textareaStyle="textarea-architectural"
      rangeStyle="textarea-architectural-cardCS"
      background={backgroundSection}
      textColor={textColorDependsOnBackground}
      subtitleColor={textColorDependsOnBackground}
      segmentListTitleStyle={textColorDependsOnBackground}
      card={{
        ...card,
        ...activeCardData,
        subtitle: activeCardData.name,
        title: null,
      }}
    />
  );

  const singleImage = activeCardData && (
    <ImageDrop
      locales={locales}
      uploadFileHandler={updateCustomerSegment}
      dropzoneMode
      background={{ backgroundColor: backgroundImage }}
      backgroundImage={backgroundImage}
      disabled={disabled}
      editMode={!disabled}
      templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
      aspectRatio={getImageAspectRatio(
        isSmallDesktop,
        IMAGE_ASPECT_RATIO_ONE,
        IMAGE_ASPECT_RATIO_CS_ARCH,
      )}
      titleStyle={TITLE_MODAL_STYLE_ARCH}
      buttonStyle={BUTTON_MODAL_STYLE_ARCH}
      lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_ARCH}
      sliderColor={black}
      backgroundModal={backgroundModal}
      isTemplates
      card={{
        ...card,
        ...activeCardData,
        subtitle: activeCardData.name,
        title: null,
      }}
    />
  );

  return (
    <div
      className="customer-segment-architectural section relative"
      style={{ backgroundColor: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_SEGMENT} />
      <div className="left">{singleImage}</div>
      <div className="right">
        <SectionHeader
          templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
          title={portfolio.customerSegment.sectionName}
          description={description}
          onChange={(e) =>
            updateSectionField({
              sectionName: 'customerSegment',
              field: 'description',
              name: 'csText',
              value: e.target.value,
            })
          }
          portfolio={portfolio}
          style={{ color: textColorDependsOnBackground }}
          descriptionColor={textColorDependsOnBackground}
          disabled={disabled}
        />
        <div className="cards-section">
          <div className="tabs">
            {collection.map(({ id, name }) => (
              <div
                key={id}
                onClick={() => setActiveCardId(id)}
                className={classnames(
                  'item',
                  {
                    active: activeCardId === id,
                  },
                  'custom-font-body',
                )}
                style={{ color: textColorDependsOnBackground }}
              >
                {truncate(name, { length: 45 })}
              </div>
            ))}
          </div>
          <div className="main">{singleCard}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSegmentArch;
