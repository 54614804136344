import { cloneDeep } from 'lodash';

import * as Route from '../utils/serverRoutes';
import {
  transformResponseCollection,
  transformResponseData,
} from '../utils/helpers';
import makeRequest from '../utils/makeRequest';

const bmc = {
  state: {
    current: {},
    collection: [],
    view: '',
    users: [],
    duplicatingBmc: null,
    deletingBmc: null,
    classroomBMCs: [],
    classroomBMCsLoading: false,
    classroomBMCsMeta: {},
  },
  reducers: {
    setCurrentBmcReducer(state, payload) {
      return { ...state, current: payload };
    },
    setBmcsReducer(state, payload) {
      return { ...state, collection: payload };
    },
    setBmcUsersReducer(state, payload) {
      return { ...state, users: payload };
    },
    setBmcViewReducer(state, payload) {
      return { ...state, view: payload };
    },
    setClassroomBMCsDataReducer(state, { data, meta }) {
      return { ...state, classroomBMCs: data, classroomBMCsMeta: meta };
    },
    setClassroomBMCsLoading(state, loading) {
      return { ...state, classroomBMCsLoading: loading };
    },
    addBmcReducer(state, payload) {
      return { ...state, collection: [...state.collection, payload] };
    },
    updateBmcReducer(state, { id, name, value }) {
      const collection = cloneDeep(state.collection);
      const index = collection.findIndex((e) => e.id === id);
      const item = collection[index];

      collection[index] = { ...item, [name]: value };

      return { ...state, collection };
    },
    removeBmcReducer(state, { id }) {
      const collection = state.collection.filter((e) => e.id !== id);

      return { ...state, collection, current: state.collection[0] };
    },
    updateAttributeReducer(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    setCurrentBmc(
      payload,
      {
        bmc: {
          current: { id },
        },
      },
    ) {
      if (id !== payload?.id) {
        dispatch.bmc.setCurrentBmcReducer(payload);
      }
    },
    fetchBmcs() {
      makeRequest({
        dispatch,
        url: Route.bmcs(),
        success: (response) => {
          const bmcs = transformResponseCollection(response.data.data);

          dispatch.bmc.setBmcsReducer(bmcs);
        },
      });
    },
    updateCurrentBmc(id) {
      makeRequest({
        dispatch,
        url: Route.bmcs(),
        success: (response) => {
          const bmcs = transformResponseCollection(response.data.data);
          const currentBmc = id ? bmcs.find((i) => +i.id === +id) : bmcs[0];
          if (currentBmc) {
            dispatch.bmc.setCurrentBmcReducer(currentBmc);
          }
        },
      });
    },
    fetchCurrentBmc(id) {
      makeRequest({
        dispatch,
        url: Route.bmcs(),
        success: (response) => {
          const bmcs = transformResponseCollection(response.data.data);
          const currentBmc = parseInt(id)
            ? bmcs.find((i) => +i.id === +id)
            : bmcs[0];

          dispatch.bmc.setBmcsReducer(bmcs);

          if (currentBmc) {
            dispatch.bmc.setCurrentBmc(currentBmc);
            dispatch.bmcProgress.initBmcProgress();
          }
        },
      });
    },
    fetchBmcById(id) {
      makeRequest({
        dispatch,
        url: Route.bmc(id),
        success: (response) => {
          dispatch.bmc.setCurrentBmc(response.data.data);
          dispatch.bmcProgress.initBmcProgress();
        },
      });
    },
    fetchBmcCanvasData(bmcId) {
      dispatch.channel.fetchChannels(bmcId);
      dispatch.customerSegment.fetchCustomerSegments(bmcId);
      dispatch.getKeepGrow.fetchGetKeepGrows(bmcId);
      dispatch.valueProposition.fetchValuePropositions(bmcId);
      dispatch.uniqueSellingPoint.fetchUniqueSellingPoints(bmcId);
      dispatch.competitor.fetchCompetitors(bmcId);
      dispatch.keyActivity.fetchKeyActivities(bmcId);
      dispatch.keyPartner.fetchKeyPartners(bmcId);
      dispatch.keyResource.fetchKeyResources(bmcId);
      dispatch.cost.fetchCosts(bmcId);
      dispatch.product.fetchProducts(bmcId);
      dispatch.revenue.fetchRevenues(bmcId);
      dispatch.sustainability.fetchSustainabilities(bmcId);
    },
    fetchClassroomBMCs(params) {
      const { sort, page, limit } = params;
      dispatch.bmc.setClassroomBMCsLoading(true);
      makeRequest({
        dispatch,
        url: Route.classroomBMCs(params.bmcId, {
          page,
          limit,
          sort,
        }),
        success: (response) => {
          dispatch.bmc.setClassroomBMCsDataReducer({
            data: response.data.data,
            meta: response.data.meta,
          });
        },
        finish: () => {
          dispatch.bmc.setClassroomBMCsLoading(false);
        },
      });
    },
    sortClassroomBMCs({ sort, bmcId }, rootState) {
      const {
        classroomBMCsMeta: { page, limit },
      } = rootState.bmc;
      dispatch.bmc.setClassroomBMCsLoading(true);
      makeRequest({
        dispatch,
        url: Route.classroomBMCs(bmcId, {
          page,
          limit,
          sort,
        }),
        success: (response) => {
          dispatch.bmc.setClassroomBMCsDataReducer({
            data: response.data.data,
            meta: response.data.meta,
          });
        },
        finish: () => {
          dispatch.bmc.setClassroomBMCsLoading(false);
        },
      });
    },
    setBmcView(view) {
      dispatch.bmc.setBmcViewReducer(view);
    },
    fetchBmcUsers(bmcId) {
      makeRequest({
        dispatch,
        url: Route.bmcUsers(bmcId),
        success: (response) => {
          dispatch.bmc.setBmcUsersReducer(
            transformResponseCollection(response.data.data),
          );
        },
      });
    },
    async addBmc({ name, userEmails }) {
      const { json, status } = await makeRequest({
        dispatch,
        method: 'post',
        url: Route.bmcs(),
        data: { bmc: { name, userEmails } },
      });

      if (status === 200) {
        const currentBmc = transformResponseData(json.data);

        dispatch.bmc.addBmcReducer(currentBmc);
        dispatch.bmc.setCurrentBmc(currentBmc);

        return currentBmc;
      }
      return null;
    },
    updateBmc(payload) {
      dispatch.bmc.updateBmcReducer(payload);
      makeRequest({
        dispatch,
        method: 'put',
        url: Route.bmc(payload.id),
        data: { bmc: { [payload.name]: payload.value } },
        success: (response) => {
          dispatch.bmc.setCurrentBmcReducer(
            transformResponseData(response.data.data),
          );
        },
      });
    },
    async removeBmc(payload) {
      dispatch.bmc.updateAttributeReducer({ deletingBmc: payload.id });

      const { json, status } = await makeRequest({
        method: 'delete',
        url: Route.bmc(payload.id),
        finish: () => {
          dispatch.bmc.updateAttributeReducer({ deletingBmc: null });
        },
      });

      if (status === 200) {
        const currentBmc = transformResponseData(json.data);

        dispatch.bmc.removeBmcReducer(payload);
        dispatch.bmc.setCurrentBmc(currentBmc);

        return currentBmc;
      }
      return null;
    },
    async cloneBmc(payload) {
      dispatch.bmc.updateAttributeReducer({ duplicatingBmc: payload.id });

      const { json, status } = await makeRequest({
        method: 'post',
        url: Route.cloneBmc(payload.id),
        finish: () => {
          dispatch.bmc.updateAttributeReducer({ duplicatingBmc: null });
        },
      });

      if (status === 200) {
        const currentBmc = transformResponseData(json.data);

        dispatch.bmc.addBmcReducer(currentBmc);
        dispatch.bmc.setCurrentBmc(currentBmc);

        return currentBmc;
      }
      return null;
    },
    setCurrentBmcFromLocal(payload) {
      dispatch.bmc.setCurrentBmcReducer(
        transformResponseData(payload.current.data),
      );
      dispatch.testing.fetchReducer({
        collection: transformResponseCollection(payload.tests.data),
      });
      dispatch.portfolio.fetchPortfolioReducer(
        payload.portfolio.data.attributes,
      );
      dispatch.portfolioTeammate.fetchPortfolioTeammatesReducer({
        collection: transformResponseCollection(
          payload.portfolioTeammates.data,
        ),
      });
      dispatch.portfolioFile.fetchPortfolioFilesReducer({
        collection: transformResponseCollection(payload.portfolioFiles.data),
      });
      dispatch.channel.fetchChannelsReducer({
        collection: transformResponseCollection(payload.channels.data),
      });
      dispatch.customerSegment.fetchCustomerSegmentsReducer({
        collection: transformResponseCollection(payload.customerSegments.data),
      });
      dispatch.getKeepGrow.fetchGetKeepGrowsReducer(
        transformResponseData(payload.getKeepGrow.data),
      );
      dispatch.valueProposition.fetchValuePropositionsReducer({
        ...transformResponseData(payload.valueProposition.data),
      });
      dispatch.competitor.fetchCompetitorsReducer({
        collection: transformResponseCollection(payload.competitors.data),
      });
      dispatch.uniqueSellingPoint.fetchUniqueSellingPointsReducer({
        collection: transformResponseCollection(
          payload.uniqueSellingPoints.data,
        ),
      });
      dispatch.keyActivity.fetchKeyActivitiesReducer({
        collection: transformResponseCollection(payload.keyActivities.data),
      });
      dispatch.keyPartner.fetchKeyPartnersReducer({
        collection: transformResponseCollection(payload.keyPartners.data),
      });
      dispatch.keyResource.fetchKeyResourcesReducer({
        collection: transformResponseCollection(payload.keyResources.data),
      });
      dispatch.cost.fetchCostsReducer({
        collection: transformResponseCollection(payload.costs.data),
      });
      dispatch.product.fetchProductsReducer({
        collection: transformResponseCollection(payload.products.data),
      });
      dispatch.revenue.fetchRevenuesReducer({
        collection: transformResponseCollection(payload.revenues.data),
      });
      dispatch.sustainability.fetchSustainabilitiesReducer({
        collection: transformResponseCollection(payload.sustainabilities.data),
      });
    },
  }),
};

export default bmc;
