import React from 'react';
import classNames from 'classnames';

import Icon from '../../components/Icon';
import SvgIcon from '../../components/SvgIcon';

export default function Goals({
  locales: {
    sustainability: { sdgs },
  },
}) {
  return (
    <div className="bmc-page-goals">
      <div className="bmc-page-goals-header">
        <i className="icon-Earth" />
        <i className="icon-SD" />
        <Icon className="icon-Goals" pathCount={20} />
      </div>

      <div className="bmc-page-goals-items-wrapper">
        <div className="bmc-page-goals-items">
          {sdgs.map((item, index) => (
            <div
              key={index}
              className={classNames(
                'bmc-page-goals-items-goal sdg-card sdg-card--small',
              )}
            >
              <div className="flip-inner">
                <div
                  className={classNames(
                    'flip-front ',
                    `sdg-card-color-${index + 1}`,
                  )}
                >
                  <div className="flip-front-icon">
                    <SvgIcon name={`sdg${index + 1}`} />
                  </div>
                  <div className="flip-front-title">{item.title}</div>
                </div>
                <div
                  className={classNames(
                    'flip-back',
                    `sdg-card-color-${index + 1}`,
                  )}
                >
                  {item.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
