import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';
import debounce from 'lodash/debounce';
import classNames from 'classnames';

import Status from './Status';

const Textarea = ({
  value,
  status,
  maxLength,
  onChange,
  wrapperClassName,
  delay = false,
  disabled = false,
  ...props
}) => {
  const [innerValue, setInnerValue] = useState(value);
  const [currentCount, setCurrentCount] = useState(value ? value.length : 0);

  useEffect(() => {
    if (value !== innerValue) setInnerValue(value);
  }, [value]);

  const debouncedOnChange = useCallback(debounce(onChange, delay), [
    onChange,
    delay,
  ]);

  const onTextareaChange = (event) => {
    const val = event.currentTarget.value;
    if (delay) {
      event.persist();
      debouncedOnChange(event);
    } else {
      onChange(event);
    }
    setInnerValue(val);
    setCurrentCount(val ? val.length : 0);
  };

  return (
    <div className={classNames('textarea-wrapper', wrapperClassName)}>
      <TextareaAutosize
        {...props}
        value={innerValue}
        maxLength={maxLength}
        onChange={onTextareaChange}
        data-hj-allow
        disabled={disabled}
      />

      {maxLength && (
        <span className="textarea-counter">
          {currentCount}/{maxLength}
        </span>
      )}

      <Status status={status} />
    </div>
  );
};

Textarea.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  delay: PropTypes.number,
};

export default Textarea;
