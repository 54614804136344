import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Header from '../../../components/Header';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import Line from '../../../components/Line';
import List from '../../../components/List';
import Actions from '../../../components/Actions';
import MobileMenuHeader from '../../../components/MobileMenuHeader';
import RateIdeaCard from './RateIdeaCard';

export default function RateYourIdeas(props) {
  const {
    nextClickHandler,
    menuItems,
    selectComponent,
    componentIndex,
    rules,
    backPath,
    locales,
    locales: {
      common,
      ideation: {
        name,
        intro: { card },
        rateYourIdeas: { header, title, text, list },
      },
    },
  } = props;
  const {
    ideation: {
      idea: {
        data: { ideas, mostEasiestId, mostExcitedId, mostImpactId },
      },
    },
  } = useSelector((state) => state);
  const most = [mostEasiestId, mostExcitedId, mostImpactId];
  const threeIdeas = useMemo(
    () => most.map((id) => ideas.find((e) => e.id === id)),
    [most],
  );

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon="icon-VP"
        locales={locales}
        componentIndex={componentIndex}
        className="bmc-page-header--blue"
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="ideationActive" title={name} color="blue" />
        }
      />

      <Text>{[text[0]]}</Text>

      <Title text={title[0]} align="left" />

      <List items={list} icons={['supervisor', 'cartPlus', 'happyFace']} />

      <Line />

      <h2 className="bmc-page-title">
        <span className="bmc-page-title--blue">{title[1]}</span> {title[2]}
      </h2>

      <div className="bmc-page-text bmc-page-text--center">
        {text[1]}
        <br />
        {text[2]}
        <br />
        {text[3]}
      </div>

      <div className="margin-top-60 margin-wide-40-lg">
        {threeIdeas.map(
          (idea, index) =>
            idea && (
              <RateIdeaCard
                idea={{
                  ...card,
                  marketSize: 1,
                  price: 1,
                  excitement: 1,
                  solution: idea.solution || '',
                  ...idea,
                }}
                key={index}
              />
            ),
        )}
      </div>

      <Actions nextClickHandler={nextClickHandler} buttonName={common.next} />
    </div>
  );
}
