import React, { useState, useEffect } from 'react';
import { unionWith, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { SELECT_STYLES } from '../../../main/utils/const';

const SelectClassroom = ({
  defaultOption = null,
  onChange = () => {},
  menuPlacement = 'bottom',
  disabled = false,
  fetchById,
}) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);

  const {
    establishments: {
      search: { collection = [], loading },
    },
  } = useSelector((state) => state);

  const {
    establishments: { searchEstablishment },
  } = useDispatch();

  const handleTypingEstablishment = (text) => {
    searchEstablishment({ text });
  };

  const handleChange = (id) => {
    setSelectedIds([id]);
  };

  useEffect(() => {
    onChange(selectedIds);
    setValue(options.find((option) => option.id === selectedIds[0]));
  }, [selectedIds]);

  useEffect(() => {
    let establishmentOptions = [];
    if (defaultOption) {
      establishmentOptions.push(defaultOption);
    }
    if (collection)
      establishmentOptions = unionWith(
        establishmentOptions,
        collection.map((establishment) => ({
          value: establishment.name,
          label: establishment.name,
          id: establishment.id,
        })),
        isEqual,
      );
    setOptions(establishmentOptions);
  }, [defaultOption, collection]);

  useEffect(() => {
    if (defaultOption) handleChange(defaultOption.id);
  }, [defaultOption]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleTypingEstablishment(searchText);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  return (
    <Select
      options={options}
      className="bmc-form-select"
      styles={SELECT_STYLES}
      placeholder="Select establishment"
      isLoading={loading}
      onChange={({ id }) => handleChange(id)}
      onInputChange={(newValue) => {
        setSearchText(newValue);
      }}
      value={value}
      menuPlacement={menuPlacement}
      disabled={disabled}
    />
  );
};

SelectClassroom.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  fetchById: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default SelectClassroom;
