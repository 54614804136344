import React from 'react';
import styled from 'styled-components';
import SvgIcon from '../../components/SvgIcon';

const icons = [
  'CSCanvas',
  'VPCanvas',
  'CACanvas',
  'CRCanvas',
  'ChannelsCanvas',
  'KeyThingCanvas',
  'FinanceCanvas',
  'SustainabilityCanvas',
];

export const StyledSvgWrapper = styled.path`
  ${icons.map((icon) => {
    return `${icon} {
  fill: ${(props) => props.fill};
}`;
  })}
`;

export const getCurrentCanvasIcon = (type, fillIcon) => {
  return (
    <div style={{ fill: fillIcon }}>
      <StyledSvgWrapper fill={fillIcon}>
        <SvgIcon name={type} />
      </StyledSvgWrapper>
    </div>
  );
};
