import React from 'react';
import { useSelector } from 'react-redux';

import HomepageTest from './Homepage';
import Research from './Research';
import Survey from './Survey';
import Interview from './Intreview';
import Prototype from './Prototype';
import Sell from './Sell';

const BuildTest = ({
  nextClickHandler,
  locales,
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
}) => {
  const {
    testing: { test },
  } = useSelector((state) => state);

  const selectedTest = test.test;

  if (selectedTest === 'homepage')
    return (
      <HomepageTest
        testObject={test}
        nextClickHandler={nextClickHandler}
        locales={locales}
        menuItems={menuItems}
        selectComponent={selectComponent}
        componentIndex={componentIndex}
        rules={rules}
        backPath={backPath}
      />
    );
  if (selectedTest === 'research')
    return (
      <Research
        testObject={test}
        nextClickHandler={nextClickHandler}
        locales={locales}
        menuItems={menuItems}
        selectComponent={selectComponent}
        componentIndex={componentIndex}
        rules={rules}
        backPath={backPath}
      />
    );
  if (selectedTest === 'survey')
    return (
      <Survey
        testObject={test}
        nextClickHandler={nextClickHandler}
        locales={locales}
        menuItems={menuItems}
        selectComponent={selectComponent}
        componentIndex={componentIndex}
        rules={rules}
        backPath={backPath}
      />
    );
  if (selectedTest === 'interview')
    return (
      <Interview
        testObject={test}
        nextClickHandler={nextClickHandler}
        locales={locales}
        menuItems={menuItems}
        selectComponent={selectComponent}
        componentIndex={componentIndex}
        rules={rules}
        backPath={backPath}
      />
    );
  if (selectedTest === 'prototype')
    return (
      <Prototype
        testObject={test}
        nextClickHandler={nextClickHandler}
        locales={locales}
        menuItems={menuItems}
        selectComponent={selectComponent}
        componentIndex={componentIndex}
        rules={rules}
        backPath={backPath}
      />
    );
  if (selectedTest === 'sell')
    return (
      <Sell
        testObject={test}
        nextClickHandler={nextClickHandler}
        locales={locales}
        menuItems={menuItems}
        selectComponent={selectComponent}
        componentIndex={componentIndex}
        rules={rules}
        backPath={backPath}
      />
    );
  return null;
};

export default BuildTest;
