import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommentInputComponent from './comment-input-component';
import CommentInputReplyContainer from './comment-input-reply-container';
import CommentInputEditContainer from './comment-input-edit-container';
import { CommentContext } from '../context';

const defaultMessageState = {
  value: '',
  mentionData: { newValue: '', newPlainTextValue: '', mentions: [] },
};

export default function CommentInputContainer({
  members = [],
  currentUserId,
  onSend = () => {},
}) {
  const { commentState } = useContext(CommentContext);
  const [message, setMessage] = useState(defaultMessageState);
  const { chatGroup } = useSelector((selector) => selector);
  const {
    chatGroup: { mergeChatGroupReducer },
  } = useDispatch();

  const handleClickSend = () => {
    if (message.value.length === 0) return;

    onSend({ ...message });

    setMessage(defaultMessageState);
  };

  const handleChange = (data) => {
    setMessage((currentMessage) => ({ ...currentMessage, ...data }));
    mergeChatGroupReducer({ id: chatGroup.selectedChatGroup.id, message: '' });
  };

  useEffect(() => {
    if (commentState.editComment) {
      setMessage({
        ...defaultMessageState,
        value: commentState.editComment.message,
      });
    } else {
      setMessage({
        ...defaultMessageState,
        value: '',
      });
    }
  }, [commentState.editComment]);

  useEffect(() => {
    const messageValue = chatGroup.collection.find(
      (chatGroupItem) => chatGroupItem.id === chatGroup.selectedChatGroup.id,
    ).message;
    if (messageValue) {
      setMessage((currentMessage) => ({
        ...currentMessage,
        value: messageValue,
      }));
    }
  }, []);

  return (
    <div className="comment-input-position">
      <div className="comment-input-border">
        <CommentInputReplyContainer
          members={members}
          currentUserId={currentUserId}
        />
        <CommentInputEditContainer
          members={members}
          currentUserId={currentUserId}
        />
        <div className="comment-input-container">
          <CommentInputComponent
            currentUserId={currentUserId}
            members={members}
            value={message.value}
            onChange={handleChange}
            onSend={handleClickSend}
          />
          <div className="comment-input-btn__list">
            {/* <div className="comment-input-btn__sticker">{iconSticker}</div> */}
            <button
              className="comment-input-btn__send"
              onClick={handleClickSend}
              disabled={message.value.trim() === ''}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
