import React, { useMemo } from 'react';
import { Popover } from 'antd';
import Avatar from './Avatar';

function PopoverAvatarGrid({ images = [] }) {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: 5,
      }}
    >
      {images.map((item, index) => (
        <Avatar {...item} key={index} />
      ))}
    </div>
  );
}

export default function PopoverAvatar({ images = [] }) {
  const imagesMemo = useMemo(() => images, [images]);
  if (imagesMemo.length === 0) return <></>;
  return (
    <Popover
      placement="bottomLeft"
      content={<PopoverAvatarGrid images={imagesMemo} />}
    >
      <div
        style={{
          width: 35,
          height: 35,
          borderRadius: 4,
          color: '#0984FF',
          backgroundColor: 'rgba(9, 132, 255, 0.1)',
          fontWeight: '500',
          fontSize: 16,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        +{imagesMemo.length}
      </div>
    </Popover>
  );
}
