import React from 'react';
import { useSelector } from 'react-redux';
import {
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  IMAGE_ASPECT_RATIO_VP_TECH,
  TECH_MODERN_TEMPLATE_NAME,
} from '../../main/utils/const';
import { renderImage } from '../../main/utils/helpers/renderImage';
import { renderSegmentList } from '../../main/utils/helpers/renderSegmentList';
import { renderText } from '../../main/utils/helpers/renderText';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';

const ValuePropositionTechWeb = ({
  locales,
  locales: {
    valueProposition: {
      intro: { card },
    },
  },
  titleColor,
  background,
  backgroundImage,
}) => {
  const { portfolio } = useSelector((state) => state);
  const {
    valueProposition: { product, reason, image },
    uniqueSellingPoint: { collection },
  } = useSelector((state) => state);

  return (
    <div
      className="value-propositions-tech-web"
      style={{ backgroundColor: background }}
    >
      <div className="left">
        <SectionHeaderWeb
          templateName={TECH_MODERN_TEMPLATE_NAME}
          title={locales.portfolioWeb.valueProposition}
          description={portfolio.valueProposition.description}
          portfolioWeb={locales.portfolioWeb}
          style={{ color: titleColor }}
        />
        {renderSegmentList(card.solutionTitle)}
        {renderText(product)}
        {renderSegmentList(card.featuresTitle)}
        {renderText(reason)}
        {renderSegmentList(card.pointsTitle)}
        {renderText(
          collection.map((point, index) => (
            <div className="margin-bottom-10" key={point.id || index}>
              {point.description}
            </div>
          )),
        )}
      </div>
      <div className="right" style={{ background: backgroundImage }}>
        <div className="image">
          {renderImage(
            ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
            image,
            backgroundImage,
            locales.portfolioWeb.valueProposition,
            IMAGE_ASPECT_RATIO_VP_TECH,
          )}
        </div>
      </div>
    </div>
  );
};

export default ValuePropositionTechWeb;
