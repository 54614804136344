import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import { Link, useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import Input from '../signUp/Input';
import Button from '../../main/components/Button';
import ForgotPasswordSchema from './validationSchema';
import * as Route from '../../main/utils/serverRoutes';
import * as AppRoute from '../../main/utils/appRoutes';
import { NOTIFICATION_TYPES } from '../../main/utils/const';

const prepareValues = ({ email }) => {
  return {
    user: {
      email,
    },
  };
};

const initialValues = {
  email: '',
};

const ForgotPasswordForm = ({
  locales,
  locales: { onboarding: pageLocales },
}) => {
  const {
    notification: { addNotification },
  } = useDispatch();
  const history = useHistory();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ForgotPasswordSchema}
      onSubmit={(values) => {
        axios
          .post(Route.passwords(), prepareValues(values))
          .then(() => {
            history.push(AppRoute.signIn());
            addNotification({
              text:
                'You will receive an email with instructions on how to reset your password in a few minutes',
              type: NOTIFICATION_TYPES.success,
            });
          })
          .catch((error) => {
            addNotification({ text: error.response.data.errors });
          });
      }}
    >
      {({ errors }) => {
        return (
          <Form className="sign-up-form sign-up-form--sign-in">
            <>
              <h3 className="sign-up-form__title">
                {pageLocales.titles.forgotPassword}
              </h3>
              <Field
                locales={locales}
                name="email"
                component={Input}
                label={pageLocales.inputLabels.emailResetPassword}
                placeholder={pageLocales.inputPlaceholders.email}
                className="margin-bottom-20"
              />
              <div className="sign-up-form__actions">
                <Button
                  title={pageLocales.buttons.resetPassword}
                  className="bmc-button--sign-up"
                  type="submit"
                  disabled={errors.email}
                />
              </div>
              <div className="sign-up-form__links margin-top-20">
                <Link to="/onboarding/sign_in">
                  {pageLocales.common.signIn}
                </Link>
              </div>
            </>
          </Form>
        );
      }}
    </Formik>
  );
};

ForgotPasswordForm.propTypes = {
  locales: PropTypes.object.isRequired,
};

export default ForgotPasswordForm;
