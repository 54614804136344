import React, { useContext } from 'react';
import { CommentContext } from '../context';
import CommentInputEditComponent from './comment-input-edit-component';

export default function CommentInputEditContainer({ members, currentUserId }) {
  const { commentState, setCommentState } = useContext(CommentContext);

  const handleClickEditCancel = () => {
    setCommentState((currentCommentState) => ({
      ...currentCommentState,
      editComment: null,
    }));
  };

  if (commentState.editComment)
    return (
      <div className="comment-input-reply-container">
        <div className="comment-input-reply-comment-container">
          <CommentInputEditComponent
            members={members}
            comment={commentState.editComment}
            currentUserId={currentUserId}
            onEditCancel={handleClickEditCancel}
          />
        </div>
      </div>
    );

  return <></>;
}
