import React from 'react';

const ContactUsBoldWeb = ({
  locales: { portfolioWeb },
  data: { title, emailOrWebsite, description, fullName },
  titleColor,
  textColor,
  backgroundSection,
  backgroundButton,
  textButtonColor,
}) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className="contact-us-bold-web"
      style={{ backgroundColor: backgroundSection }}
    >
      <div className="header">
        <div
          className="bold-title title custom-font-title"
          style={{ color: titleColor }}
        >
          {portfolioWeb.contactUs.title}
        </div>
        <div>
          <div
            className="scroll-top custom-font-body"
            style={{
              backgroundColor: backgroundButton,
              color: textButtonColor,
            }}
            onClick={scrollToTop}
          >
            {portfolioWeb.contactUs.scrollTop}
            <i className="icon-Arrow-Top" />
          </div>
        </div>
      </div>
      <div className="fields" style={{ color: textColor }}>
        <div
          className="description custom-font-body"
          style={{ color: textColor }}
        >
          {description}
        </div>
        <div className="form-field custom-font-body">{fullName}</div>
        <div className="form-field last custom-font-body">{emailOrWebsite}</div>
      </div>

      <div
        className="footer custom-font-body"
        style={{ borderColor: titleColor, color: textColor }}
      >
        <div className="left">
          {`© ${title}. ${portfolioWeb.contactUs.rightsReserve}`}
        </div>
        <div className="right">
          {portfolioWeb.contactUs.buildCreate}
          <span className="company-name">
            {portfolioWeb.contactUs.companyName}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactUsBoldWeb;
