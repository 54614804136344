import {
  Paragraph,
  TextRun,
  Table,
  TableRow,
  TableCell,
  WidthType,
  HeightRule,
  TableAnchorType,
  OverlapType,
  TableLayoutType,
  AlignmentType,
} from 'docx';

import { concat } from 'lodash';
import {
  pageSize,
  convertPixelToTwip,
  convertFontSize,
  COLORS,
  transparentBorder,
  singleBorder,
  tableWidth,
  getPageBg,
  whiteBgImage,
  noPageMargin,
} from '../Constants';
import {
  marginTableRow,
  forecastsTableCell,
  emptyCell,
} from '../helpers/index';
import {
  costCalculator as calculator,
  diffArray,
  sumArrays,
} from '../../../../../utils/helpers';

export default async (locales, portfolio, products, cost, options) => {
  const { finance, chart } = locales;

  const makeCumulative = (arr) => {
    return arr.map((o, i) =>
      i - 1 >= 0
        ? o + arr.slice(0, i).reduce((sum, x) => Math.round(sum + x))
        : o,
    );
  };

  const cashIn = products.collection.map((product) => {
    const endUserPrice = +product.endUserPrice || 0;
    const months = product.sales.map((s) => Math.round(+s * endUserPrice));

    return {
      name: product.name,
      months,
    };
  });

  const productArrays = cashIn.map((i) => i.months);
  const cashInTotal = sumArrays(...productArrays);

  const fixed = cost.collection.map((c) => {
    const months = new Array(12).fill(Math.round(calculator(c)));
    return {
      name: c.name,
      months,
    };
  });

  const variable = products.collection.map((p) => {
    const months = p.sales.map((i) => Math.round(+p.costPrice * i));
    return {
      name: p.name,
      months,
    };
  });

  const cashOutTotal = sumArrays(
    ...[...fixed.map((i) => i.months), ...variable.map((i) => i.months)],
  );

  const monthlyCashInCashOut = diffArray(cashInTotal, cashOutTotal);
  const cumulativeBalance = makeCumulative(monthlyCashInCashOut);

  const buildRowWithNameCell = (
    items,
    index,
    isBold = false,
    itemName = false,
    fontSize,
  ) => {
    const odd = index % 2 !== 0;

    const firstCell = forecastsTableCell(
      itemName,
      fontSize,
      isBold,
      odd,
      AlignmentType.LEFT,
      { left: convertPixelToTwip(12) },
    );

    const cellsWithData = items.map((item) =>
      forecastsTableCell(String(item), fontSize, isBold, odd),
    );

    cellsWithData.unshift(firstCell);

    return cellsWithData;
  };

  const tableHead = (rowName) =>
    new TableRow({
      height: {
        value: convertPixelToTwip(28),
        rule: HeightRule.ATLEAST,
      },
      children: buildRowWithNameCell(chart, 0, false, rowName || false, 8),
    });

  const buildCashInTableData = () => {
    const rowsWithData = cashIn.map((item, index) => {
      return new TableRow({
        height: {
          value: convertPixelToTwip(28),
          rule: HeightRule.ATLEAST,
        },
        children: buildRowWithNameCell(
          item.months,
          index,
          false,
          item.name,
          10,
        ),
      });
    });

    const cashInTotalRow = new TableRow({
      height: {
        value: convertPixelToTwip(28),
        rule: HeightRule.ATLEAST,
      },
      children: buildRowWithNameCell(cashInTotal, 0, true, 'Total Cash In'),
    });

    rowsWithData.unshift(tableHead());
    rowsWithData.push(cashInTotalRow);

    return rowsWithData;
  };

  const buildCashOutTableData = () => {
    const rowFixedData = fixed.map((item, index) => {
      return new TableRow({
        height: {
          value: convertPixelToTwip(28),
          rule: HeightRule.ATLEAST,
        },
        children: buildRowWithNameCell(
          item.months,
          index,
          false,
          item.name,
          10,
        ),
      });
    });

    const rowVariableData = variable.map((item, index) => {
      return new TableRow({
        height: {
          value: convertPixelToTwip(28),
          rule: HeightRule.ATLEAST,
        },
        children: buildRowWithNameCell(
          item.months,
          index,
          false,
          item.name,
          10,
        ),
      });
    });

    const cashOutTotalRow = new TableRow({
      height: {
        value: convertPixelToTwip(28),
        rule: HeightRule.ATLEAST,
      },
      children: buildRowWithNameCell(cashOutTotal, 0, true, 'Total Cash Out'),
    });

    rowFixedData.unshift(
      new TableRow({
        height: {
          value: convertPixelToTwip(28),
          rule: HeightRule.ATLEAST,
        },
        children: buildRowWithNameCell(chart, 0, false, 'Fixed Cost', 8),
      }),
    );

    rowVariableData.unshift(
      new TableRow({
        height: {
          value: convertPixelToTwip(28),
          rule: HeightRule.ATLEAST,
        },
        children: buildRowWithNameCell([], 0, false, 'Variable Cost', 8),
      }),
    );

    rowVariableData.push(cashOutTotalRow);

    return concat(rowFixedData, rowVariableData);
  };

  const buildBalanceTableData = () => {
    const monthlyCashRow = new TableRow({
      height: {
        value: convertPixelToTwip(28),
        rule: HeightRule.ATLEAST,
      },
      children: buildRowWithNameCell(
        monthlyCashInCashOut,
        0,
        false,
        'Monthly Cash In/Out',
        10,
      ),
    });

    const cumulativeBalanceRow = new TableRow({
      height: {
        value: convertPixelToTwip(28),
        rule: HeightRule.ATLEAST,
      },
      children: buildRowWithNameCell(
        cumulativeBalance,
        0,
        false,
        'Cumulative Balance',
        10,
      ),
    });

    return [monthlyCashRow, cumulativeBalanceRow];
  };

  return {
    properties: {
      page: {
        size: pageSize,
        margin: noPageMargin,
      },
    },
    children: [
      getPageBg(options.forecast?.pageBg),

      new Table({
        float: {
          horizontalAnchor: TableAnchorType.TEXT,
          verticalAnchor: TableAnchorType.TEXT,
          overlap: OverlapType.NEVER,
        },
        width: {
          size: convertPixelToTwip(tableWidth),
          type: WidthType.DXA,
        },
        layout: TableLayoutType.AUTOFIT,
        rows: [
          // main title row
          new TableRow({
            height: {
              value: convertPixelToTwip(27),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(28, COLORS.WHITE),
              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth - 56),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.WHITE),
                children: [
                  // Title
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'FORECASTS',
                        font: options.bodyFont,
                        color:
                          options.forecast?.subtitleColor ||
                          options.bodyColor ||
                          COLORS.BLACK,
                        size: convertFontSize(16),
                        allCaps: true,
                        underline: {
                          color: COLORS.BLACK,
                          type: 'single',
                        },
                      }),
                    ],
                  }),
                ],
              }),
              emptyCell(28, COLORS.WHITE),
            ],
          }),
          marginTableRow(15, tableWidth, COLORS.WHITE, 3),

          // data cash in table row
          new TableRow({
            height: {
              value: convertPixelToTwip(28),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(28, COLORS.WHITE),
              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth - 56),
                  type: WidthType.DXA,
                },
                margins: {
                  top: convertPixelToTwip(12),
                  bottom: convertPixelToTwip(12),
                },
                borders: singleBorder(
                  options.economics?.borderColor || COLORS.BLACK,
                ),
                children: [
                  new Table({
                    float: {
                      horizontalAnchor: TableAnchorType.TEXT,
                      verticalAnchor: TableAnchorType.TEXT,
                      overlap: OverlapType.NEVER,
                    },
                    width: {
                      size: convertPixelToTwip(tableWidth - 56),
                      type: WidthType.DXA,
                    },
                    layout: TableLayoutType.AUTOFIT,
                    rows: [
                      // title cash In row
                      new TableRow({
                        height: {
                          value: convertPixelToTwip(19),
                          rule: HeightRule.ATLEAST,
                        },
                        children: [
                          new TableCell({
                            width: {
                              size: convertPixelToTwip(100),
                              type: WidthType.DXA,
                            },
                            margins: {
                              left: convertPixelToTwip(12),
                            },
                            borders: transparentBorder(COLORS.WHITE),
                            children: [
                              // Title
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: finance.forecast.cashIn,
                                    font: options.bodyFont,
                                    color:
                                      options.forecast?.textColor ||
                                      options.bodyColor ||
                                      COLORS.BLACK,
                                    size: convertFontSize(14),
                                  }),
                                ],
                              }),
                            ],
                          }),
                        ],
                      }),
                      ...buildCashInTableData(),
                      marginTableRow(15, tableWidth - 56, COLORS.WHITE, 1),

                      // title cash out row
                      new TableRow({
                        height: {
                          value: convertPixelToTwip(19),
                          rule: HeightRule.ATLEAST,
                        },
                        children: [
                          new TableCell({
                            width: {
                              size: convertPixelToTwip(100),
                              type: WidthType.DXA,
                            },
                            margins: {
                              left: convertPixelToTwip(12),
                            },
                            borders: transparentBorder(COLORS.WHITE),
                            children: [
                              // Title
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: finance.forecast.cashOut,
                                    font: options.bodyFont,
                                    color:
                                      options.forecast?.textColor ||
                                      options.bodyColor ||
                                      COLORS.BLACK,
                                    size: convertFontSize(14),
                                  }),
                                ],
                              }),
                            ],
                          }),
                        ],
                      }),
                      ...buildCashOutTableData(),
                      marginTableRow(15, tableWidth - 56, COLORS.WHITE, 1),

                      // title balance row
                      new TableRow({
                        height: {
                          value: convertPixelToTwip(19),
                          rule: HeightRule.ATLEAST,
                        },
                        children: [
                          new TableCell({
                            width: {
                              size: convertPixelToTwip(100),
                              type: WidthType.DXA,
                            },
                            margins: {
                              left: convertPixelToTwip(12),
                            },
                            borders: transparentBorder(COLORS.WHITE),
                            children: [
                              // Title
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: finance.forecast.balance,
                                    font: options.bodyFont,
                                    color:
                                      options.forecast?.textColor ||
                                      options.bodyColor ||
                                      COLORS.BLACK,
                                    size: convertFontSize(14),
                                  }),
                                ],
                              }),
                            ],
                          }),
                        ],
                      }),
                      ...buildBalanceTableData(),
                    ],
                  }),
                ],
              }),
              emptyCell(28, COLORS.WHITE),
            ],
          }),
        ],
      }),

      whiteBgImage(options.forecast?.pageBg),
    ],
  };
};
