import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SectionHeader from '../SectionHeader';
import ChannelCard from '../../channel/Card';
import Textarea from '../../../components/Textarea';

const Channels = ({
  data: { description, showStatus },
  locales,
  locales: {
    portfolio,
    channel: {
      intro: { card },
    },
  },
  disabled = false,
}) => {
  const { collection } = useSelector((state) => state.channel);
  const {
    portfolio: { updateSectionField, updateSectionFieldReducer },
  } = useDispatch();

  const setShowStatus = (status) => {
    updateSectionFieldReducer({
      sectionName: 'channels',
      field: 'showStatus',
      value: status,
    });
  };

  return (
    <div className="channel">
      <SectionHeader
        locales={portfolio}
        showStatus={showStatus}
        setShowStatus={setShowStatus}
        mainColor="green"
        sectionName={portfolio.channels.sectionName}
      />
      {showStatus && (
        <>
          <Textarea
            name="textarea"
            rows={5}
            delay={700}
            value={description || ''}
            onChange={(e) =>
              updateSectionField({
                sectionName: 'channels',
                field: 'description',
                name: 'channelsText',
                value: e.target.value,
              })
            }
            className="bmc-form-textarea textarea"
            placeholder={portfolio.page.descriptionPlaceholder}
            disabled={disabled}
          />
          <div className="channels">
            {collection.map((channel) => (
              <ChannelCard
                locales={locales}
                className="margin-top-40"
                dropzoneMode
                portfolioMode
                key={channel.id}
                blur={false}
                disabled={disabled}
                card={{
                  ...card,
                  ...channel,
                  title: null,
                }}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Channels;
