import React, { useRef } from 'react';
import { digitToString } from '../../main/utils/helpers';

const Landing4 = ({ test, locales: { landing4: pageLocales } }) => {
  const learnMoreSection = useRef(null);
  const handleScroll = () => {
    window.scrollTo({
      top: learnMoreSection.current.offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <div className="desktop-landing4">
      <div className="desktop-landing4--main-block">
        <h1 className="desktop-landing4--logo">{test.data.company_name}</h1>
        <div className="desktop-landing4--info-block">
          <h2>{test.data.headline}</h2>
          {test.image.medium.url ? (
            <img src={test.image.medium.url} alt="" />
          ) : (
            <div className="placeholder" />
          )}
          <p>{test.data.explain}</p>
          <button onClick={handleScroll}>{pageLocales.button}</button>
        </div>
      </div>
      <div className="desktop-landing4--learn-more" ref={learnMoreSection}>
        <h3 className="desktop-landing4--learn-more-title">
          {pageLocales.learnMoreTitle}
        </h3>
        <div className="desktop-landing4--learn-more-line" />
        <ul className="desktop-landing4--learn-more-list">
          {pageLocales.list.map((item, index) => (
            <li key={index}>
              <span>{index + 1}</span>
              <p>{test.data[`thing${digitToString[index + 1]}`]}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Landing4;
