import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BmcCanvasSection from './BmcCanvasSection';
import CardModal from '../CardModal';
import SustainabilityCard from '../../sustainability/Card';
import SvgIcon from '../../../components/SvgIcon';
import {
  collectionEmpty,
  selectLabel,
  itemHasContent,
} from '../../../utils/helpers';
import { COLORS } from '../../../utils/const';

const { white } = COLORS;

export default function Sustainabilities({
  locales,
  locales: { canvas },
  isDark,
  isPreview,
  isProgressBar,
  backgroundIcon,
  backgroundItems,
  backgroundSection,
  borderSectionColor,
  titleBMCSectionColor,
  textColor,
  iconFill,
  iconFillClose,
  templateName,
  sectionClassName,
  firstSvgColor,
  secondSvgColor,
  titleStyle,
  buttonStyle,
  iconStyle,
  textStyle,
  backgroundBMC,
  backgroundRow,
  iconFillTable = white,
  iconFillSlider = white,
  iconTableStyles,
  backgroundIconTable,
  borderCardColor,
  sliderStyles = 'sdg-card',
  backgroundSliderColor,
  titleColor,
  buttonColor,
  backgroundButton,
  textSliderColor,
  isColourfulfunTemplate = false,
  disabled = false,
}) {
  const sustainabilityFields = ['problem', 'value', 'sdg'];

  const {
    sustainability,
    bmc: {
      current: { id },
    },
  } = useSelector((state) => state);

  const {
    sustainability: { fetchSustainabilities },
  } = useDispatch();

  const [showSustainabilityModal, setShowSustainabilityModal] = useState(false);

  return (
    <>
      {showSustainabilityModal && (
        <CardModal
          onBlackscreenClick={() => setShowSustainabilityModal(false)}
          title={canvas.sustainability}
          icon={
            !templateName ? (
              'icon-sustainability'
            ) : (
              <SvgIcon name="iconSustainability" />
            )
          }
          to={`/bmcs/${id}/sustainability/intro`}
          locales={locales}
          className="bmc-modal--violet custom-font-body"
          onOpen={() => fetchSustainabilities(id)}
          isOpen={showSustainabilityModal}
          isPreview={isPreview}
          titleStyle={titleStyle}
          buttonStyle={buttonStyle}
          firstSvgColor={firstSvgColor}
          secondSvgColor={secondSvgColor}
          iconFill={iconFill}
          iconStyle={iconStyle}
          backgroundIcon={backgroundIcon}
          backgroundBMC={backgroundBMC}
          titleColor={titleColor}
          buttonColor={buttonColor}
          backgroundButton={backgroundButton}
          iconFillClose={iconFillClose}
          isColourfulfunTemplate={isColourfulfunTemplate}
          disabled={disabled}
        >
          <SustainabilityCard
            locales={locales}
            showCarousel={false}
            editMode={!isPreview}
            collection={sustainability.collection}
            className="bmc-page-suscard bmc-page-suscard--section custom-font-body"
            iconFill={iconFillSlider}
            iconFillTable={iconFillTable}
            iconTableStyles={iconTableStyles}
            backgroundIcon={backgroundIconTable}
            buttonStyle={buttonStyle}
            textStyle={textStyle}
            backgroundRow={backgroundRow}
            borderCardColor={borderCardColor}
            sliderStyles={sliderStyles}
            backgroundSliderColor={backgroundSliderColor}
            buttonColor={buttonColor}
            backgroundButton={backgroundButton}
            textSliderColor={textSliderColor}
            templateName={templateName}
          />
        </CardModal>
      )}

      <BmcCanvasSection
        className={`bmc-canvas-section--wide bmc-canvas-section--violet ${sectionClassName}`}
        title={canvas.sustainability}
        dark={isDark}
        showButton={
          !isPreview &&
          collectionEmpty(sustainability.collection, sustainabilityFields)
        }
        to={`/bmcs/${id}/sustainability/intro`}
        locales={locales}
        icon={
          !templateName ? (
            'icon-sustainability'
          ) : (
            <SvgIcon name="iconSustainability" />
          )
        }
        isPreview={isPreview}
        isProgressBar={isProgressBar}
        uploading={sustainability.uploading}
        onClick={() => setShowSustainabilityModal(true)}
        backgroundIcon={backgroundIcon}
        backgroundSection={backgroundSection}
        borderSectionColor={borderSectionColor}
        titleBMCSectionColor={titleBMCSectionColor}
        iconFill={iconFill}
        iconStyle={iconStyle}
        templateName={templateName}
      >
        {sustainability.collection.map(
          (item) =>
            itemHasContent(item, sustainabilityFields) && (
              <div
                key={item.id}
                className="bmc-canvas-section-items-item custom-font-body"
                style={{ backgroundColor: backgroundItems, color: textColor }}
                onClick={() => setShowSustainabilityModal(true)}
              >
                <div
                  className="bmc-canvas-section-items-item-title custom-font-body"
                  style={{ color: textColor }}
                >
                  {selectLabel(item.sdg, locales.sustainability.sdgs)}
                </div>
                {item.value || '-'}
              </div>
            ),
        )}
      </BmcCanvasSection>
    </>
  );
}
