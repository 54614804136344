import React from 'react';
import Lazyload from 'react-lazyload';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import IntroductionTech from './IntroductionTech';
import SummaryTech from './SummaryTech';
import TeamTech from './Team';
import BusinessModelCanvasTech from './BusinessModelCanvasTech';
import TestingTech from './TestingTech';
import DesirabilityTech from './DesirabilityTech';
import CustomerSegmentTech from './CustomerSegmentTech';
import ValuePropositionTech from './ValuePropositionTech';
import CompetitorAnalysisTech from './CompetitorAnalysisTech';
import CustomerRelationshipsTech from './CustomerRelationshipsTech';
import ChannelsTech from './ChannelsTech';
import FeasibilityTech from './FeasibilityTech';
import KeyActivitiesTech from './KeyActivitiesTech';
import ViabilityTech from './ViabilityTech';
import FinanceTech from './FinanceTech';
import SustainabilityTech from './SustainabilityTech';
import ContactUsTech from './ContactUsTech';
import { StyledWrapper } from '../../../utils/helpers';
import { COLOR_PALETTE_ARRAY_TECH, COLORS } from '../../../utils/const';
import { getColorTemplate } from '../../../utils/helpers/getColorTemplate';

const { white, black } = COLORS;

const TechModernTemplate = ({ locales, portfolio, bmc, testing }) => {
  const {
    portfolioStyle: { templateSettings, activeTemplateName },
    portfolioTemplate: { collection },
  } = useSelector((state) => state);

  const currentSettings = templateSettings
    ? templateSettings[activeTemplateName]
    : {};

  const activeTemplate = find(collection, ['sysName', activeTemplateName]);
  const colorTemplate = getColorTemplate(
    templateSettings,
    activeTemplateName,
    activeTemplate,
  );

  // eslint-disable-next-line no-unused-vars
  const [firstColor, secondColor, thirdColor, fourthColor] = colorTemplate;
  const colorPaletteNumber = COLOR_PALETTE_ARRAY_TECH[firstColor];

  return (
    <div className="bmc-canvas margin-top-30">
      <div className="bmc-page fullwidth" style={{ padding: '0' }}>
        <StyledWrapper
          customtitlefont={currentSettings?.customTitleFont}
          customtitlecolor={currentSettings?.customTitleColor}
          custombodyfont={currentSettings?.customBodyFont}
          custombodycolor={currentSettings?.customBodyColor}
        >
          <IntroductionTech
            locales={locales}
            data={portfolio.introduction}
            titleColor={firstColor}
            backgroundImage={fourthColor}
            background={secondColor}
          />
          <SummaryTech
            locales={locales}
            data={portfolio.projectSummary}
            backgroundSummary={fourthColor}
            colorStyle={firstColor}
            colorPaletteNumber={colorPaletteNumber}
          />
          <Lazyload>
            <TeamTech
              locales={locales}
              data={portfolio.team}
              styleColor={firstColor}
              background={thirdColor}
              backgroundImage={fourthColor}
            />
          </Lazyload>
          {bmc && (
            <Lazyload>
              <BusinessModelCanvasTech
                locales={locales}
                data={portfolio.bmc}
                titleColor={firstColor}
                backgroundCanvas={`${firstColor}33`}
                iconFill={firstColor}
                backgroundIcon={thirdColor}
                backgroundItems={white}
                backgroundSection={white}
                background={secondColor}
              />
            </Lazyload>
          )}
          {testing && (
            <Lazyload>
              <TestingTech
                locales={locales}
                data={portfolio.testing}
                titleColor={firstColor}
                background={thirdColor}
                borderCardColor={`${firstColor}33`}
                iconFill={black}
                iconStroke={black}
              />
            </Lazyload>
          )}
          {bmc && (
            <>
              <DesirabilityTech
                locales={locales}
                data={portfolio.desirabilitySection}
                backgroundSection={fourthColor}
                colorPaletteNumber={colorPaletteNumber}
              />
              <Lazyload>
                <CustomerSegmentTech
                  locales={locales}
                  data={portfolio.customerSegment}
                  background={secondColor}
                  titleColor={firstColor}
                  borderCardColor={`${firstColor}33`}
                  backgroundImage={fourthColor}
                />
              </Lazyload>
              <Lazyload>
                <ValuePropositionTech
                  locales={locales}
                  data={portfolio.valueProposition}
                  titleColor={firstColor}
                  background={thirdColor}
                  backgroundImage={fourthColor}
                />
              </Lazyload>
              <Lazyload>
                <CompetitorAnalysisTech
                  locales={locales}
                  data={portfolio.competitorAnalysis}
                  titleColor={firstColor}
                  background={secondColor}
                  backgroundTable={firstColor}
                  backgroundImage={fourthColor}
                />
              </Lazyload>
              <Lazyload>
                <CustomerRelationshipsTech
                  locales={locales}
                  data={portfolio.customerRelationships}
                  styleColor={firstColor}
                  backgroundSection={fourthColor}
                  colorPaletteNumber={colorPaletteNumber}
                />
              </Lazyload>
              <Lazyload>
                <ChannelsTech
                  locales={locales}
                  data={portfolio.channels}
                  background={thirdColor}
                  titleColor={firstColor}
                  borderCardColor={`${firstColor}33`}
                  backgroundImage={fourthColor}
                />
              </Lazyload>
              <FeasibilityTech
                locales={locales}
                data={portfolio.feasibility}
                backgroundSection={fourthColor}
                colorPaletteNumber={colorPaletteNumber}
              />
              <Lazyload>
                <KeyActivitiesTech
                  locales={locales}
                  data={portfolio.keyActivities}
                  background={thirdColor}
                  styleColor={firstColor}
                />
              </Lazyload>
              <ViabilityTech
                locales={locales}
                data={portfolio.viability}
                backgroundSection={fourthColor}
                colorPaletteNumber={colorPaletteNumber}
              />
              <Lazyload>
                <FinanceTech
                  locales={locales}
                  data={portfolio.finance}
                  titleColor={firstColor}
                  firstChartColor={`${firstColor}80`}
                  secondChartColor={firstColor}
                  borderCardColor={`${firstColor}33`}
                  background={secondColor}
                />
              </Lazyload>
              <Lazyload>
                <SustainabilityTech
                  locales={locales}
                  data={portfolio.sustainability}
                  titleColor={firstColor}
                  styleColor={firstColor}
                  background={thirdColor}
                  backgroundRow={secondColor}
                />
              </Lazyload>
            </>
          )}
          <ContactUsTech
            locales={locales}
            data={portfolio.contactUs}
            backgroundSection={fourthColor}
            colorPaletteNumber={colorPaletteNumber}
          />
        </StyledWrapper>
      </div>
    </div>
  );
};

export default TechModernTemplate;
