export default function commentToHtml(message, members, senderId) {
  let result = message;
  result = result.replace(/\n/g, '<br />');

  members.forEach((member) => {
    const mentionRegExp = new RegExp(
      `@\\[@${member.user.firstName} ${member.user.surname}\\]\\(${member.user.id}\\)`,
      'g',
    );

    const highlightedFullname =
      senderId === member.user.id
        ? `<span class="highlight-mention current-user">@${member.user.firstName} ${member.user.surname}</span>`
        : `<span class="highlight-mention">@${member.user.firstName} ${member.user.surname}</span>`;

    result = result.replace(mentionRegExp, highlightedFullname);
  });

  return result;
}
