import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Table as AntTable } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from '../../components/Table';
import TableHeader from '../../components/TableHeader';
import ExpandIcons from '../../components/ExpandIcons';
import ExpandRow from '../../components/ExpandRow';
import icons from '../../../main/utils/icons';
import ButtonSetting from '../../components/ButtonSetting';
import ProjectsTable from '../Tables/ProjectsTable';
import ModalUpdateAdminAndLecturer from '../ModalUser/ModalUpdateAdminAndLecturer';
import { hasAccessSettingsStudent } from '../../utils/access';
import ROLES from '../../../main/utils/roles';
import pageNames from '../../utils/page_names';

const columns = ({ role, visibleColumnDaysRemaining = false }) => {
  const columnsArray = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Contact',
      dataIndex: 'email',
      key: 'email',
      render: (_, row) => {
        return (
          <>
            <div>{row.phone}</div>
            <div>{row.email}</div>
          </>
        );
      },
    },

    {
      title: 'Project',
      dataIndex: 'bmcsCount',
      key: 'bmcsCount',
    },
    {
      title: 'Activity Total',
      dataIndex: 'sumTimeTracks',
      key: 'sumTimeTracks',
      render: (_, row) => row.sumTimeTracks || 0,
    },
    {
      title: 'Last 30 days (min)',
      dataIndex: 'sumLastTimeTracks',
      key: 'sumLastTimeTracks',
      render: (_, row) => row.sumLastTimeTracks || 0,
    },
    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt) => {
        moment.locale('en');
        return moment(new Date(updatedAt)).format('DD MMM YYYY');
      },
    },
    AntTable.EXPAND_COLUMN,
  ];
  const { length } = columnsArray;
  if (role === ROLES.SUPER_ADMIN && visibleColumnDaysRemaining) {
    columnsArray.splice(length - 2, 0, {
      title: 'Days remaining',
      dataIndex: 'daysRemaining',
      key: 'daysRemaining',
      render: (daysRemaining) => (daysRemaining === null ? '-' : daysRemaining),
    });
  }
  return columnsArray;
};

const StudentLists = ({
  byId,
  by,
  establishmentId,
  locales,
  currentPage = null,
}) => {
  const [search, setSearch] = useState(undefined);
  const [modalVisibleStudent, setModalVisibleStudent] = useState({
    visible: false,
    userId: null,
  });

  const {
    students: { data, meta, loading },
    pagination: { pageSize: initPageSize },
    user,
  } = useSelector((state) => state);

  const {
    students: { fetchStudents, sortStudents, reload },
  } = useDispatch();

  useEffect(() => {
    fetchStudents({ byId, by, search, limit: initPageSize, establishmentId });
  }, [byId, by, search, initPageSize, establishmentId]);

  const handleOpenModalUpdateStudent = (userId) => {
    setModalVisibleStudent({ visible: true, userId });
  };

  const handleCloseModalUpdateStudent = () => {
    setModalVisibleStudent({ visible: false, userId: null });
  };

  return (
    <>
      <ModalUpdateAdminAndLecturer
        {...modalVisibleStudent}
        onClose={handleCloseModalUpdateStudent}
        locales={locales}
        reload={reload}
      />
      <TableHeader
        title="Students"
        count={loading ? <Spin /> : meta?.count || 0}
        onSearch={(searchValue) => {
          setSearch(searchValue);
        }}
        searchLoading={loading}
      />
      <Table
        columns={columns({
          role: user?.current?.role,
          visibleColumnDaysRemaining: currentPage === pageNames.superAdmin,
        })}
        dataSource={data}
        loading={loading}
        total={meta?.count || 0}
        current={meta?.page || 1}
        onChange={(pagination) => {
          const { current, pageSize } = pagination;
          fetchStudents({
            byId,
            by,
            establishmentId,
            search,
            page: current,
            limit: pageSize,
          });
        }}
        expandRowByClick
        rowClickable
        expandable={{
          expandedRowRender: (record) => {
            return (
              <ExpandRow>
                <ProjectsTable byId={record.id} />
              </ExpandRow>
            );
          },
          rowExpandable: (record) => record.bmcsCount > 0,
          expandIcon: ({ expanded, onExpand, record }) => {
            return expanded ? (
              <ExpandIcons>
                {hasAccessSettingsStudent(user) && (
                  <ButtonSetting
                    onClick={(event) => {
                      event.stopPropagation();
                      handleOpenModalUpdateStudent(record.id);
                    }}
                  />
                )}
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => onExpand(record, e)}
                >
                  {icons.rowExpandIconClose}
                </div>
              </ExpandIcons>
            ) : (
              <ExpandIcons>
                {hasAccessSettingsStudent(user) && (
                  <ButtonSetting
                    onClick={(event) => {
                      event.stopPropagation();
                      handleOpenModalUpdateStudent(record.id);
                    }}
                  />
                )}
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => onExpand(record, e)}
                >
                  {icons.rowExpandIcon}
                </div>
              </ExpandIcons>
            );
          },
        }}
        meta={meta}
        onSort={sortStudents}
      />
    </>
  );
};

StudentLists.propTypes = {
  byId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  by: PropTypes.oneOf(['users', 'classrooms', 'establishments']),
};
export default StudentLists;
