import React from 'react';

const icons = {
  unfold: (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0946 7.21142L22.7639 2.61358C23.0787 2.30359 23.0787 1.80107 22.7639 1.49113L21.4857 0.232457C21.1709 -0.0774857 20.6605 -0.0774857 20.3458 0.232457L15.6765 4.8303L14.1109 3.19472C13.3493 2.44472 12.0494 2.9759 12.0494 4.0366V9.59254C12.0494 10.2501 12.5883 10.7831 13.256 10.7831H18.8983C19.9754 10.7831 20.5149 9.50072 19.7532 8.75067L18.0946 7.21142Z"
        fill="#0984FF"
      />
      <path
        d="M4.90536 15.7886L0.236105 20.3864C-0.0787017 20.6964 -0.0787017 21.1989 0.236105 21.5089L1.51432 22.7675C1.82908 23.0775 2.33945 23.0775 2.65421 22.7675L7.32346 18.1697L8.88908 19.8053C9.65073 20.5553 10.9506 20.0241 10.9506 18.9634V13.4075C10.9506 12.7499 10.4117 12.2169 9.74398 12.2169H4.10174C3.02458 12.2169 2.48514 13.4993 3.24684 14.2493L4.90536 15.7886Z"
        fill="#0984FF"
      />
    </svg>
  ),
};
export default icons;
