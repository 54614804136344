import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import Slider from 'react-slick';
import Spinner from '../../../main/components/Spinner';
import Card from '../../../main/containers/ideation/Card';

export default function index({ locales }) {
  const slick = useRef(null);
  const { bmcId } = useParams();
  const {
    list: {
      // title,
      dontHaveBusinessIdea,
      letsBrainstorm,
    },
    intro: { card },
  } = locales.ideation;

  const {
    ideation: { collection, uploading },
    bmc: { current: currentBmc },
  } = useSelector((state) => state);

  const {
    ideation: { fetchIdeasByBmcId },
  } = useDispatch();

  useEffect(() => {
    if (bmcId) fetchIdeasByBmcId({ bmcId, params: { completed: true } });
  }, [bmcId]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const getIdeaData = (idea) => {
    const {
      data: { ideas, pickedIdeaId },
    } = idea;
    const pickedIdea = ideas && ideas.find((e) => e.id === pickedIdeaId);

    return pickedIdea || {};
  };

  const getCardData = (idea) => {
    return {
      ...card,
      url: null,
      problem: '',
      solution: '',
      marketSize: 0,
      price: 0,
      excitement: 0,
      ...getIdeaData(idea),
      id: idea.id,
    };
  };

  const handlePrevSlide = () => {
    if (slick.current) {
      slick.current.slickPrev();
    }
  };

  const handleNextSlide = () => {
    if (slick.current) {
      slick.current.slickNext();
    }
  };

  return (
    <div>
      {uploading ? (
        <Spinner className="margin-top-60" big />
      ) : (
        <div className="ideation-list">
          {collection.length > 0 ? (
            <>
              <div className="ideation-list-items">
                {collection.length > 1 && (
                  <div
                    onClick={handlePrevSlide}
                    className="ideation-list-items-arrow ideation-list-items-arrow--left"
                  >
                    <i className="icon-Arrow-Top" />
                  </div>
                )}

                <Slider ref={slick} {...settings}>
                  {collection.map((idea) => (
                    <Card
                      card={getCardData(idea)}
                      bmc={currentBmc}
                      key={idea.id}
                      className="ideation-card--white"
                      locales={locales}
                    />
                  ))}
                </Slider>

                {collection.length > 1 && (
                  <div
                    onClick={handleNextSlide}
                    className="ideation-list-items-arrow ideation-list-items-arrow--right"
                  >
                    <i className="icon-Arrow-Top" />
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="ideation-list-empty">
              <div className="ideation-list-empty-text">
                {dontHaveBusinessIdea}
              </div>
              <div className="ideation-list-empty-text margin-bottom-40">
                {letsBrainstorm}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
