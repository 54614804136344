import React from 'react';
import { useSelector } from 'react-redux';

import CompetitorsCard from '../../main/containers/valueProposition/CompetitorsCardTemplates';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import { TECH_MODERN_TEMPLATE_NAME, COLORS } from '../../main/utils/const';

const { white } = COLORS;

const CompetitorAnalysisTechWeb = ({
  locales,
  locales: { portfolioWeb },
  titleColor,
  background,
  backgroundTable,
  backgroundImage,
}) => {
  const { collection } = useSelector((state) => state.competitor);
  const { competitorAnalysis } = useSelector((state) => state.portfolio);

  return (
    <div
      className="competitor-analysis-tech-web section-web"
      style={{ backgroundColor: background }}
    >
      <SectionHeaderWeb
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolioWeb.competitorAnalysis}
        description={competitorAnalysis.description}
        portfolioWeb={portfolioWeb}
        style={{ color: titleColor }}
      />

      <CompetitorsCard
        locales={locales}
        className="margin-top-40"
        isPreview
        card={{
          ...locales.valueProposition.intro.competitorsCard,
          competitors: collection,
        }}
        backgroundHeadTable={backgroundTable}
        backgroundTable={white}
        borderTableColor={backgroundTable}
        backgroundImage={backgroundImage}
      />
    </div>
  );
};

export default CompetitorAnalysisTechWeb;
