import React, { useState } from 'react';
import Select, { components } from 'react-select';
import classNames from 'classnames';
import IconBlock from './Select/IconBlock';
import { TEST_STATUS_SELECTOR } from '../../../utils/const';
import testStatusOptions, {
  TEST_STATUSES,
} from '../../../utils/options/testStatusOptions';

const CustomOption = ({ children, data, isSelected, ...props }) => {
  return (
    <components.Option {...props}>
      <div
        className={classNames('test-status-select__options', {
          [data.value]: isSelected,
        })}
      >
        <div
          className={classNames('test-status-select__label', data.value, {
            active: isSelected,
          })}
        >
          {children}
        </div>
        <div className={data.value}>
          <IconBlock
            active={isSelected}
            icon={data.icon}
            className={classNames({ [data.value]: true })}
          />
        </div>
      </div>
    </components.Option>
  );
};

export default function TestStatusSelector({
  status,
  onStatusUpdate,
  textStyle,
  textColor,
}) {
  const [isOpened, setIsOpened] = useState(false);

  const handleOpenMenu = () => {
    setIsOpened(true);
  };

  const handleCloseMenu = () => {
    setIsOpened(false);
  };

  const toggleOpen = () => {
    if (isOpened) handleCloseMenu();
    else handleOpenMenu();
  };

  const handleChange = (option) => {
    if (option.value === TEST_STATUSES.INCOMPLETE)
      onStatusUpdate({ ...option, value: null });
    else onStatusUpdate(option);
  };

  return (
    <div className="test-status-select custom-font-body">
      <div
        className={`${textStyle} custom-font-body`}
        onClick={toggleOpen}
        style={{ color: textColor }}
      >
        Status
      </div>
      <Select
        options={testStatusOptions}
        value={testStatusOptions.find((e) => e.value === status) || null}
        styles={TEST_STATUS_SELECTOR}
        className={`${textStyle} custom-font-body`}
        onChange={handleChange}
        components={{ Option: CustomOption }}
        menuPosition="fixed"
      />
    </div>
  );
}
