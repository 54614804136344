import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import _get from 'lodash/get';
import Textarea from '../../../../components/Textarea';
import UploadsBlock from './UploadsBlock';
import AnalyseTestResultsBlock from './AnalyseTestResultsBlock';

const TestResultsBlock = ({
  hideAnalyse = false,
  analyseHandler,
  handleChange,
  textStyle,
  textColor,
  linkColor,
  isEditable = true,
}) => {
  const {
    testDocument: { collection, uploadingResult },
    testing: { test },
  } = useSelector((state) => state);

  const blocks = _get(test, 'assumption.blocks', []);

  const resultCollection = collection.filter(
    (e) => e.documentType === 'result',
  );
  const isHomepage = test.test === 'homepage';

  return (
    <div className="test-card__section">
      <div className="left">
        {((isHomepage && !hideAnalyse) || !isHomepage) && (
          <div className="block">
            <div
              className={`title ${textStyle} custom-font-body`}
              style={{ color: textColor }}
            >
              Test Results
            </div>
            <div
              className={classNames('is-full-width', { content: isHomepage })}
            >
              {_get(test, 'analyse.resultsAndLearning', '').length > 0 ? (
                <div
                  className={classNames({
                    'content is-full-width': !isHomepage,
                  })}
                >
                  {isEditable ? (
                    <Textarea
                      onChange={handleChange}
                      rows={4}
                      delay={700}
                      value={test.analyse.resultsAndLearning}
                      name="analyse.resultsAndLearning"
                      className={`bmc-form-textarea ${textStyle} custom-font-body`}
                    />
                  ) : (
                    <div
                      className={`text ${textStyle} custom-font-body`}
                      style={{ color: textColor }}
                    >
                      {test.analyse.resultsAndLearning}
                    </div>
                  )}
                </div>
              ) : (
                <div className={classNames({ content: !isHomepage })}>
                  {isEditable &&
                    (test.assumption.text || '').length > 0 &&
                    (blocks || []).length > 0 &&
                    ((!isHomepage && (test.data.plan || '').length > 0) ||
                      isHomepage) && (
                      <AnalyseTestResultsBlock
                        analyseHandler={analyseHandler}
                        textStyle={textStyle}
                        linkColor={linkColor}
                      />
                    )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {!isHomepage && (
        <UploadsBlock
          collectionType={resultCollection}
          uploadsType="result"
          uploadingStatus={uploadingResult}
          textStyle={textStyle}
          textColor={textColor}
          linkColor={linkColor}
          isEditable={isEditable}
        />
      )}
    </div>
  );
};

export default TestResultsBlock;
