import * as Route from '../support/serverRoutes';
import makeRequest from '../../main/utils/makeRequest';

const admin = {
  state: {
    establishments: {
      collection: [],
      loading: false,
    },
    adminOptions: {
      collection: [],
      loading: false,
    },
    admins: {
      collection: [],
      loading: false,
    },
    createdAdmins: [],
  },
  reducers: {
    setEstablishments(state, collection) {
      return {
        ...state,
        establishments: { ...state.establishments, collection },
      };
    },
    setEstablishmentsLoading(state, loading) {
      return {
        ...state,
        establishments: { ...state.establishments, loading },
      };
    },
    setAdminOptions(state, collection) {
      return {
        ...state,
        adminOptions: { ...state.adminOptions, collection },
      };
    },
    setAdminOptionsLoading(state, loading) {
      return {
        ...state,
        adminOptions: { ...state.adminOptions, loading },
      };
    },
    setAdmins(state, collection) {
      return {
        ...state,
        admins: { ...state.admins, collection },
      };
    },
    setAdminsLoading(state, loading) {
      return {
        ...state,
        admins: { ...state.admins, loading },
      };
    },
    addAdminReducer(state, payload) {
      const tempAdmin = state.adminOptions.collection.find(
        (a) => a.id === payload.userId,
      );
      return {
        ...state,
        admins: { collection: [...state.admins.collection, tempAdmin] },
      };
    },
    removeAdminReducer(state, payload) {
      const collection = state.admins.collection.filter(
        (e) => e.id !== payload.userId,
      );
      return {
        ...state,
        admins: { collection },
      };
    },
    addCreatedAdminReducer(state, { userId }) {
      const tempAdmin = state.adminOptions.collection.find(
        (a) => a.id === userId,
      );
      return {
        ...state,
        createdAdmins: [...state.createdAdmins, tempAdmin],
      };
    },
    removeCreatedAdminReducer(state, { userId }) {
      const createdAdmins = state.createdAdmins.filter((e) => e.id !== userId);
      return {
        ...state,
        createdAdmins,
      };
    },
    clearSelectedCreatedAdminsReducer(state) {
      return {
        ...state,
        createdAdmins: [],
      };
    },
  },
  effects: (dispatch) => ({
    fetchEstablishments(adminId) {
      dispatch.admin.setEstablishmentsLoading(true);
      makeRequest({
        dispatch,
        url: Route.establishments({ byId: adminId }, false),
        success: (response) => {
          dispatch.admin.setEstablishments(response.data);
        },
        finish: () => {
          dispatch.admin.setEstablishmentsLoading(false);
        },
      });
    },
    fetchAdmins({ establishmentId }) {
      dispatch.admin.setAdminOptionsLoading(true);
      makeRequest({
        dispatch,
        url: Route.admins(establishmentId),
        success: (response) => {
          dispatch.admin.setAdminOptions(response.data);
        },
        finish: () => {
          dispatch.admin.setAdminOptionsLoading(false);
        },
      });
    },
    fetchEstablishmentAdmins({ establishmentId }) {
      dispatch.admin.setAdminsLoading(true);
      makeRequest({
        dispatch,
        url: Route.getAdminsByEstablishmentId(establishmentId),
        success: (response) => {
          dispatch.admin.setAdmins(response.data);
        },
        finish: () => {
          dispatch.admin.setAdminsLoading(false);
        },
      });
    },
    addAdmin({ establishmentId, userId }) {
      dispatch.admin.addAdminReducer({ establishmentId, userId });
    },
    removeAdmin({ establishmentId, userId }) {
      dispatch.admin.removeAdminReducer({ establishmentId, userId });
    },
    clearSelectedCreatedAdmins() {
      dispatch.admin.clearSelectedCreatedAdminsReducer();
    },
    addCreatedAdmin({ userId }) {
      dispatch.admin.addCreatedAdminReducer({ userId });
    },
    removeCreatedAdmin({ userId }) {
      dispatch.admin.removeCreatedAdminReducer({ userId });
    },
  }),
};

export default admin;
