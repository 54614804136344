import React from 'react';

import Textarea from '../../../components/Textarea';
import UploadFiles from '../../../components/UploadFiles';
import { COMMENT_SUMMARY } from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';

const SummaryBold = ({
  data: { description },
  locales,
  locales: { portfolio },
  updateSectionField,
  titleColor,
  textColor,
  colorStyle,
  backgroundSection,
  fileTextColor,
  disabled = false,
}) => {
  return (
    <div
      className="summary-bold section relative"
      style={{ backgroundColor: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_SUMMARY} />

      <div
        className="bold-title title custom-font-title"
        style={{ color: titleColor }}
      >
        {portfolio.projectSummary.sectionName}
      </div>
      <Textarea
        id="DesirabilitySection"
        rows={5}
        delay={700}
        value={description}
        onChange={(e) => {
          updateSectionField({
            sectionName: 'projectSummary',
            field: 'description',
            name: 'projectSummary',
            value: e.target.value,
          });
        }}
        className="textarea-bold description custom-font-body"
        placeholder={portfolio.page.descriptionPlaceholder}
        style={{ color: textColor }}
        disabled={disabled}
      />
      <UploadFiles
        locales={locales}
        className="margin-top-40"
        fileBackgroundColor={colorStyle}
        fileTextColor={fileTextColor}
        uplouderButtonStyle="portfolio-file-uploader__bold"
        filesWrapperStyle="portfolio-file-uploader-file-bold"
        fileStyle="portfolio-file-uploader-file-bold-link"
        removeIconStyle="portfolio-file-uploader-file__remove-bold"
        borderButtonColor={colorStyle}
        textButtonColor={colorStyle}
        disabled={disabled}
      />
    </div>
  );
};

export default SummaryBold;
