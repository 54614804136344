// eslint-disable-next-line import/no-cycle
import { getClearColorVar, getTemplateFonts } from './index';
import Download from '../assets/bold-download.png';
import { getContrastYIQ } from '../helpers';

export const buildBoldOptions = (
  colors,
  fonts,
  templateSettings,
  templateData,
  isCustomPalette,
) => {
  const { titleFont, titleColor, bodyFont, bodyColor } = getTemplateFonts(
    templateSettings,
    templateData,
  );

  const [mainColor, subMainColor, thirdColor, lastColor] = getClearColorVar(
    templateSettings.colorPalette,
  );

  const blackPageTitleColor =
    isCustomPalette && !templateSettings.customTitleColor
      ? getContrastYIQ(`#${mainColor}`)
      : thirdColor;
  const blackPageBodyColor =
    isCustomPalette && !templateSettings.customBodyColor
      ? getContrastYIQ(`#${mainColor}`)
      : thirdColor;

  return {
    titleFont,
    titleBold: true,
    bodyFont,
    titleColor: titleColor || mainColor,
    bodyColor: bodyColor || subMainColor,
    backgroundLight: subMainColor,
    backgroundDark: thirdColor,
    mainPage: {
      isLogo: true,
      mainImgType: 'default',
      imgBg: colors.WHITE,
      imgWidth: 840,
      imgHeight: 257,
      titleBg: lastColor,
      titleBold: true,
      logoPositionX: 14000,
      logoPositionY: -400,
      mainImgPositionX: 0,
      mainImgPositionY: 1100,
      paragraphWidth: 840,
      paragraphHeight: 257,
      absoluteVerticalPosition: 340,
    },
    summary: {
      quoteImgColor: mainColor,
      titleBold: true,
      btnBg: mainColor,
      download: Download,
      btnColor: lastColor,
    },
    team: {
      titleBold: true,
      nameColor: thirdColor,
      positionColor: mainColor,
      noImgBg: thirdColor,
    },
    bmc: {
      titleBold: true,
      titleColor: colors.CURRENT_BLACK_LIGHT,
      bodyColor: colors.BLACK,
    },
    testing: {
      titleBold: true,
      lineColor: colors.WHITE,
      statusIconColor: mainColor,
      statusColor: mainColor,
    },
    desirability: {
      titleFont,
      titleBold: true,
      color: thirdColor,
      backgroundDark: mainColor,
      titleColor: blackPageTitleColor,
      bodyColor: blackPageBodyColor,
    },
    segment: {
      titleBold: true,
      subtitleColor: thirdColor,
      noImgBg: thirdColor,
    },
    valueProposition: {
      titleBold: true,
      subtitleColor: colors.WHITE,
      noImgBg: thirdColor,
      noImgType: 'default',
    },
    analysis: {
      titleBold: true,
      tableBorderColor: thirdColor,
      tableBg: thirdColor,
      noImgBg: thirdColor,
      noImgType: 'circle',
    },
    relationships: {
      titleBold: true,
      iconDarkColor: mainColor,
      iconLightColor: thirdColor,
      activeItemBorder: colors.CURRENT_GRAY,
      activeItemColor: {
        color: mainColor,
        bold: true,
      },
      inactiveItemColor: {
        color: thirdColor,
        bold: false,
      },
      subtitleColor: thirdColor,
    },
    channels: {
      titleBold: true,
      subtitleColor: colors.BOLD_YELLOW2,
      noImgBg: thirdColor,
      noImgType: 'default',
    },
    feasibility: {
      titleFont,
      titleBold: true,
      color: colors.CURRENT_BLACK_LIGHT,
      backgroundDark: mainColor,
      titleColor: blackPageTitleColor,
      bodyColor: blackPageBodyColor,
    },
    viability: {
      titleFont,
      titleBold: true,
      color: colors.CURRENT_BLACK_LIGHT,
      backgroundDark: mainColor,
      titleColor: blackPageTitleColor,
      bodyColor: blackPageBodyColor,
    },
    keyActivity: {
      titleBold: true,
      stepTextColor: colors.BLACK,
      dotImgColor: mainColor,
      stepImgColor: mainColor,
    },
    keyResources: {
      titleBold: true,
      lineColor: colors.PRIMARY,
      iconBg: mainColor,
    },
    finance: {
      titleBold: true,
      rowBg: thirdColor,
    },
    economic: {
      pageBg: colors.WHITE,
      subtitleColor: colors.BLACK,
      borderColor: thirdColor,
    },
    forecast: {
      pageBg: colors.WHITE,
      subtitleColor: colors.BLACK,
      textColor: colors.BLACK,
    },
    graph: {
      subtitleColor: colors.BLACK,
    },
    contact: {
      titleColor,
      titleBold: true,
      color: bodyColor,
      borderColor: mainColor,
      pageBg: lastColor,
    },
    sustainability: {
      titleBold: true,
      listPageTitleBold: true,
      listTextColor: colors.BLACK,
      tablePageBg: colors.WHITE,
      listPageBg: colors.WHITE,
      listCircleWidth: 169,
      listCircleHeight: 169,
      listCircleColor: colors.WHITE,
      iconListColor: thirdColor,
      iconTableColor: thirdColor,
      circleTableColor: colors.WHITE,
    },
  };
};
