import React from 'react';
import { Redirect } from 'react-router';
import { useParams } from 'react-router-dom';

export default function RouteRedirect({ to }) {
  // just fast custom solution
  // todo: you can improve it!
  const { bmcId, id } = useParams();
  let destination = to.replace(':bmcId', bmcId);

  if (id) {
    destination = destination.replace(':id', id);
  }

  return <Redirect to={destination} />;
}
