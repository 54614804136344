import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Modal from '../../main/containers/testing/Components/Modal';
import SvgIcon from '../../main/components/SvgIcon';
import TestRow from '../../main/containers/testing/Components/TestRow';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import { COLOURFUL_TEMPLATE_NAME } from '../../main/utils/const';

const Testing = ({
  locales,
  locales: {
    testing: { titles },
    portfolioWeb,
  },
  className,
  textStyle,
  testListStyle,
  leftSideStyle,
  rightSideStyle,
  firstSvgColor,
  secondSvgColor,
  backgroundSection,
  backgroundCard,
  rateColor,
  inputColor,
  textModalColor,
  textRowColor,
  titleStyle,
  buttonStyle,
  iconFillClose,
  sectionHeaderTitleColor,
  descriptionColor,
  titleColor,
  backgroundButton,
  linkColor,
  noTestsColor,
  borderCardColor,
  backgroundRow,
  testTypeColor,
  buttonColor,
  templateName,
}) => {
  const [modal, setModal] = useState({
    status: false,
  });

  const {
    portfolio: { testing },
    testing: { collection: tests },
  } = useSelector((state) => state);

  const {
    testing: { setTestReducer },
  } = useDispatch();

  const onClick = (test) => {
    setModal({ status: true });
    setTestReducer(test);
  };
  const isColourfulfunTemplate = templateName === COLOURFUL_TEMPLATE_NAME;
  return (
    <div className={`${className} section-web`} style={backgroundSection}>
      <Modal
        isOpen={modal.status}
        isEditable={false}
        titles={titles}
        setModalStatus={setModal}
        locales={locales}
        isPreview
        backgroundCard={backgroundCard}
        textColor={textModalColor}
        textStyle={textStyle}
        firstSvgColor={firstSvgColor}
        secondSvgColor={secondSvgColor}
        inputColor={inputColor}
        rateColor={rateColor}
        titleStyle={titleStyle}
        buttonStyle={buttonStyle}
        iconFillClose={iconFillClose}
        titleColor={titleColor}
        backgroundButton={backgroundButton}
        linkColor={linkColor}
        buttonColor={buttonColor}
        isColourfulfunTemplate={isColourfulfunTemplate}
      />
      {isColourfulfunTemplate && (
        <>
          <div className="svg-funTestingEight" style={{ fill: firstSvgColor }}>
            <SvgIcon name="funTestingEighth" />
          </div>
          <div className="svg-funTestingNine" style={{ fill: secondSvgColor }}>
            <SvgIcon name="funTestingNineth" />
          </div>
        </>
      )}
      <div className="content">
        <div className={leftSideStyle}>
          <SectionHeaderWeb
            templateName={templateName}
            title={portfolioWeb.testing}
            description={testing.description}
            style={{ color: sectionHeaderTitleColor }}
            descriptionColor={descriptionColor}
          />
        </div>
        <div className={`${testListStyle} ${rightSideStyle}`}>
          {!tests.length && (
            <div
              className="empty-text custom-font-body"
              style={{ color: noTestsColor }}
            >
              No Tests
            </div>
          )}
          {tests.map((testItem) => (
            <TestRow
              titles={titles}
              test={testItem}
              key={testItem.id}
              onClick={() => onClick(testItem)}
              textColor={textRowColor}
              borderCardColor={borderCardColor}
              backgroundRow={backgroundRow}
              testTypeColor={testTypeColor}
              isPreview
              isColourfulfunTemplate={isColourfulfunTemplate}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testing;
