import {
  Paragraph,
  Table,
  TableRow,
  TableCell,
  AlignmentType,
  WidthType,
  HeightRule,
  TableAnchorType,
  OverlapType,
  TableLayoutType,
  VerticalAlign,
  FrameAnchorType,
  HorizontalPositionAlign,
  VerticalPositionAlign,
} from 'docx';

import {
  pageSize,
  convertPixelToTwip,
  COLORS,
  transparentBorder,
  shadingBox,
  tableWidth,
  noPageMargin,
} from '../Constants';
import { emptyCell, marginTableRow, textRun } from '../helpers/index';

export default async (locales, portfolio, portfolioWeb, options) => {
  return {
    properties: {
      page: {
        size: pageSize,
        margin: noPageMargin,
      },
    },
    children: [
      new Paragraph({
        frame: {
          position: {
            x: 0,
            y: convertPixelToTwip(20),
          },
          width: convertPixelToTwip(tableWidth),
          height: convertPixelToTwip(455),
          rule: HeightRule.EXACT,
          anchor: {
            horizontal: FrameAnchorType.TEXT,
            vertical: FrameAnchorType.TEXT,
          },
          alignment: {
            x: HorizontalPositionAlign.CENTER,
            y: VerticalPositionAlign.TOP,
          },
        },
        shading: options.contact.pageBg
          ? shadingBox(options.contact.pageBg)
          : null,
      }),
      new Table({
        float: {
          horizontalAnchor: TableAnchorType.TEXT,
          verticalAnchor: TableAnchorType.TEXT,
          overlap: OverlapType.NEVER,

          absoluteVerticalPosition: convertPixelToTwip(197),
          absoluteHorizontalPosition: 0,
        },
        width: {
          size: convertPixelToTwip(tableWidth),
          type: WidthType.DXA,
        },
        layout: TableLayoutType.AUTOFIT,
        rows: [
          // row title
          new TableRow({
            height: {
              value: convertPixelToTwip(42),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(30, options.contact.pageBg, options.contact.pageBg),
              // title Cell
              new TableCell({
                width: {
                  size: convertPixelToTwip(699 + 56),
                  type: WidthType.DXA,
                },
                verticalAlign: VerticalAlign.CENTER,
                shading: shadingBox(options.contact.pageBg),
                columnSpan: 3,
                children: [
                  new Paragraph({
                    alignment: AlignmentType.START,
                    children: [
                      textRun(locales.contactUs.sectionName, {
                        font: options.titleFont,
                        bold: options.contact.titleBold,
                        color:
                          options.contact.titleColor ||
                          options.titleColor ||
                          COLORS.BLACK,
                        size: 37,
                      }),
                    ],
                  }),
                ],
                borders: transparentBorder(COLORS.PRIMARY),
              }),
              emptyCell(30, options.contact.pageBg, options.contact.pageBg),
            ],
          }),
          // empty row for padding
          marginTableRow(
            20,
            tableWidth,
            options.contact.pageBg,
            5,
            options.contact.pageBg,
          ),
          // row with email
          new TableRow({
            height: {
              value: convertPixelToTwip(11),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(30, options.contact.pageBg, options.contact.pageBg),
              // title Cell
              new TableCell({
                width: {
                  size: convertPixelToTwip(233),
                  type: WidthType.DXA,
                },
                verticalAlign: VerticalAlign.CENTER,
                shading: shadingBox(options.contact.pageBg),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.START,
                    children: [
                      textRun(portfolio.contactUs.description, {
                        color:
                          options.contact.color ||
                          options.bodyColor ||
                          COLORS.BLACK,
                        size: 9.825,
                        font: options.bodyFont,
                      }),
                    ],
                  }),
                ],
                borders: transparentBorder(COLORS.PRIMARY),
              }),
              new TableCell({
                width: {
                  size: convertPixelToTwip(233),
                  type: WidthType.DXA,
                },
                verticalAlign: VerticalAlign.CENTER,
                shading: shadingBox(options.contact.pageBg),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      textRun(portfolio.contactUs.fullName, {
                        color:
                          options.contact.color ||
                          options.bodyColor ||
                          COLORS.BLACK,
                        size: 9.825,
                        font: options.bodyFont,
                      }),
                    ],
                  }),
                ],
                borders: transparentBorder(COLORS.PRIMARY),
              }),
              new TableCell({
                width: {
                  size: convertPixelToTwip(233),
                  type: WidthType.DXA,
                },
                verticalAlign: VerticalAlign.CENTER,
                shading: shadingBox(options.contact.pageBg),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.END,
                    children: [
                      textRun(portfolio.contactUs.emailOrWebsite, {
                        color:
                          options.contact.color ||
                          options.bodyColor ||
                          COLORS.BLACK,
                        size: 9.825,
                        font: options.bodyFont,
                      }),
                    ],
                  }),
                ],
                borders: transparentBorder(COLORS.PRIMARY),
              }),
              emptyCell(30, options.contact.pageBg, options.contact.pageBg),
            ],
          }),
          marginTableRow(
            15,
            tableWidth,
            options.contact.pageBg,
            5,
            options.contact.pageBg,
          ),
          // line
          new TableRow({
            height: {
              value: convertPixelToTwip(2),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(30, options.contact.pageBg, options.contact.pageBg),

              new TableCell({
                width: {
                  size: convertPixelToTwip(699),
                  type: WidthType.DXA,
                },
                verticalAlign: VerticalAlign.CENTER,
                shading: shadingBox(options.contact.pageBg),
                columnSpan: 3,
                children: [
                  new Paragraph({
                    border: {
                      top: {
                        color: options.contact.borderColor,
                        space: 2,
                        style: 'single',
                        size: 6,
                      },
                    },
                  }),
                ],
                borders: transparentBorder(COLORS.PRIMARY),
              }),
              emptyCell(30, options.contact.pageBg, options.contact.pageBg),
            ],
          }),
          // bottom row
          new TableRow({
            height: {
              value: convertPixelToTwip(11),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(30, options.contact.pageBg, options.contact.pageBg),
              new TableCell({
                width: {
                  size: convertPixelToTwip(233),
                  type: WidthType.DXA,
                },
                verticalAlign: VerticalAlign.CENTER,
                shading: shadingBox(options.contact.pageBg),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.START,
                    children: [
                      portfolio.contactUs.title &&
                        textRun(`${portfolio.contactUs.title}. `, {
                          color: options.contact.color || options.titleColor,
                          size: 7.642,
                          font: options.titleFont,
                        }),
                      textRun(portfolioWeb.contactUs.rightsReserve, {
                        color: options.contact.color || options.bodyColor,
                        size: 7.642,
                        font: options.bodyFont,
                      }),
                    ],
                  }),
                ],
                borders: transparentBorder(COLORS.PRIMARY),
              }),
              emptyCell(233, options.contact.pageBg, options.contact.pageBg),
              new TableCell({
                width: {
                  size: convertPixelToTwip(233),
                  type: WidthType.DXA,
                },
                verticalAlign: VerticalAlign.CENTER,
                shading: shadingBox(options.contact.pageBg),
                children: [
                  new Paragraph({
                    alignment: AlignmentType.END,
                    children: [
                      textRun(portfolioWeb.contactUs.buildCreate, {
                        color: options.contact.color || options.bodyColor,
                        size: 7.642,
                        font: options.bodyFont,
                      }),
                      textRun(` ${portfolioWeb.contactUs.companyName}`, {
                        color: options.contact.color || options.bodyColor,
                        size: 13.1,
                        font: options.bodyFont,
                      }),
                    ],
                  }),
                ],
                borders: transparentBorder(COLORS.PRIMARY),
              }),
              emptyCell(30, options.contact.pageBg, options.contact.pageBg),
            ],
          }),
        ],
      }),
    ],
  };
};
