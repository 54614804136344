import React from 'react';
import { Form, Input, Space, Button } from 'antd';
import Modal from '../../main/components/Modal';
import ModalContainer from '../../admin/components/ModalContainer';
import ModalHeader from '../../admin/components/ModalHeader';
import passwordValidation from '../../main/utils/passwordValidation';

const UpdatePasswordModal = ({
  visible,
  onClose,
  currentPassword,
  updatePassword,
}) => {
  const handleUpdatePassword = (values) => {
    updatePassword({
      ...values,
      currentPassword,
      callbackAfterSuccess: [onClose],
    });
  };

  return (
    <>
      {visible && (
        <Modal onBlackscreenClick={() => {}} className="sm">
          <ModalContainer>
            <ModalHeader title="New password required" onClose={onClose} />
            <Form layout="vertical" onFinish={handleUpdatePassword}>
              <div className="mt-20">
                <Form.Item
                  label="Your password has been reset. Please enter a new password"
                  className="admin-input-label"
                  name="password"
                  rules={[
                    {
                      validator: passwordValidation,
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    className="admin-input"
                  />
                </Form.Item>
                <Form.Item
                  label="Password Confirmation"
                  className="admin-input-label"
                  name="passwordConfirmation"
                  rules={[
                    {
                      validator: passwordValidation,
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    className="admin-input"
                  />
                </Form.Item>
              </div>
              <div className="flex align-right mt-20">
                <Space>
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Space>
              </div>
            </Form>
          </ModalContainer>
        </Modal>
      )}
    </>
  );
};

export default UpdatePasswordModal;
