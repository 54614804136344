import React from 'react';

import Header from '../../../components/Header';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import Line from '../../../components/Line';
import List from '../../../components/List';
import Actions from '../../../components/Actions';
import MobileMenuHeader from '../../../components/MobileMenuHeader';
import TheeIdeasSelector from './TheeIdeasSelector';

export default function SelectThreeIdeas(props) {
  const {
    nextClickHandler,
    menuItems,
    selectComponent,
    componentIndex,
    rules,
    backPath,
    locales,
    locales: {
      common,
      ideation: {
        name,
        selectIdeas: { header, title, text, list, selector, actions },
      },
    },
  } = props;

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon="icon-VP"
        locales={locales}
        componentIndex={componentIndex}
        className="bmc-page-header--blue"
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="ideationActive" title={name} color="blue" />
        }
      />

      <Text>{[text[0]]}</Text>

      <Title text={title[0]} align="left" />

      <List items={list} icons={['arrowRight', 'happyFace', 'shield']} />

      <Line />

      <h2 className="bmc-page-title">
        <span className="bmc-page-title--blue">{title[1]}</span> {title[2]}
      </h2>

      <div className="bmc-page-text bmc-page-text--center">
        {text[1]}
        <br />
        {text[2]}
      </div>

      <TheeIdeasSelector
        data={selector}
        className="margin-top-60 margin-wide-40-lg"
      />

      <Actions
        nextClickHandler={nextClickHandler}
        buttonName={common.next}
        tooltipIfDisabled={actions.tooltipIfDisabled}
      />
    </div>
  );
}
