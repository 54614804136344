import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from '../../components/Table';
import TableHeader from '../../components/TableHeader';
import ModalCreateAdminAndLecturer from '../CreateLecturer/ModalCreateAdminAndLecturer';
import ButtonSetting from '../../components/ButtonSetting';
import ModalUpdateAdminAndLecturer from '../ModalUser/ModalUpdateAdminAndLecturer';
import ROLES from '../../../main/utils/roles';

export const columns = ({ openModalUpdateUser, role }) => {
  const columnsArray = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      render: (fullName, row) => (
        <span style={{ whiteSpace: 'pre-line' }}>
          {`${fullName}\n${row.email}`}
        </span>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'role',
      render: (roleAtr, row) => {
        const style = {
          fontFamily: 'Nunito',
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '20px',
        };
        if (row.role === 'admin')
          return (
            <span
              style={{
                color: '#E91E63',
                ...style,
              }}
            >
              Admin
            </span>
          );
        if (row.role === 'super_admin')
          return (
            <span
              style={{
                color: '#f37533',
                ...style,
              }}
            >
              Super Admin
            </span>
          );
        return (
          <span
            style={{
              color: '#00897B',
              ...style,
            }}
          >
            Educator
          </span>
        );
      },
    },
    {
      title: 'Class',
      dataIndex: 'classesCount',
      key: 'classesCount',
      render: (classCount) => classCount || 0,
    },
    {
      title: 'Project',
      dataIndex: 'projectsCount',
      key: 'projectsCount',
      render: (projectsCount) => projectsCount || 0,
    },
    {
      title: 'Student',
      dataIndex: 'studentsCount',
      key: 'studentsCount',
      render: (studentsCount) => studentsCount || 0,
    },
    {
      title: 'Active Student',
      dataIndex: 'activeStudent',
      key: 'activeStudent',
      render: (activeStudent) => activeStudent || 0,
    },
    {
      title: 'Total Time (min)',
      dataIndex: 'totalTimeTracks',
      key: 'totalTimeTracks',
      render: (item) => item || 0,
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt) => {
        moment.locale('en');
        return moment(new Date(updatedAt)).format('DD MMM YYYY');
      },
    },
  ];
  if (role === ROLES.SUPER_ADMIN)
    columnsArray.push({
      title: '',
      key: 'action',
      render: (record) => (
        <ButtonSetting
          onClick={(event) => {
            event.stopPropagation();
            openModalUpdateUser(record.id, record?.classesCount);
          }}
        />
      ),
    });
  return columnsArray;
};

const AdminLecturerLists = ({
  byId,
  by,
  rowClick,
  rowClickable = false,
  locales,
}) => {
  const [search, setSearch] = useState(undefined);

  const [
    visibleModalCreateAdminAndLecturer,
    setVisibleModalCreateAdminAndLecturer,
  ] = useState(false);

  const [
    visibleModalUpdateAdminAndLecturer,
    setVisibleModalUpdateAdminAndLecturer,
  ] = useState(null);

  const {
    adminLecturers: { data, meta, loading, uploading },
    pagination: { pageSize: initPageSize },
    user,
  } = useSelector((state) => state);

  const {
    adminLecturers: {
      fetchAdminLecturers,
      addUser,
      sortAdminLecturers,
      reload,
    },
  } = useDispatch();

  useEffect(() => {
    fetchAdminLecturers({ byId, by, search, limit: initPageSize });
  }, [byId, by, search, initPageSize]);

  const handleCloseModalCreateAdminAndLecturer = () => {
    setVisibleModalCreateAdminAndLecturer(false);
  };

  const handleOpenModalCreateAdminAndLecturer = () =>
    setVisibleModalCreateAdminAndLecturer(true);

  const handleCloseModalUpdateAdminAndLecturer = () => {
    setVisibleModalUpdateAdminAndLecturer(null);
  };

  const handleOpenModalUpdateAdminAndLecturer = (userId) => {
    setVisibleModalUpdateAdminAndLecturer(userId);
  };

  const handleCreateAdminAndLecturer = (values, afterSuccess) => {
    addUser({
      values,
      after: [handleCloseModalCreateAdminAndLecturer, afterSuccess],
    });
  };

  return (
    <>
      <ModalCreateAdminAndLecturer
        onCancel={handleCloseModalCreateAdminAndLecturer}
        onCreate={handleCreateAdminAndLecturer}
        visible={visibleModalCreateAdminAndLecturer}
        uploading={uploading}
      />
      <ModalUpdateAdminAndLecturer
        onClose={handleCloseModalUpdateAdminAndLecturer}
        userId={visibleModalUpdateAdminAndLecturer}
        param={{ byId, by, search, limit: initPageSize }}
        locales={locales}
        reload={reload}
        isAdminLecturerModal
      />
      <TableHeader
        title="Admin/Educator"
        count={loading ? <Spin /> : meta?.count || 0}
        onSearch={(searchValue) => {
          setSearch(searchValue);
        }}
        onCreate={handleOpenModalCreateAdminAndLecturer}
        textCreate="Create new Admin/Educator"
        searchLoading={loading}
      />
      <Table
        columns={columns({
          openModalUpdateUser: handleOpenModalUpdateAdminAndLecturer,
          role: user?.current?.role,
        })}
        dataSource={data}
        loading={loading}
        total={meta?.count || 0}
        current={meta?.page || 1}
        onChange={(pagination) => {
          const { current, pageSize } = pagination;
          fetchAdminLecturers({
            byId,
            by,
            search,
            page: current,
            limit: pageSize,
          });
        }}
        onRow={(record) => ({ onClick: () => rowClick(record) })}
        rowClickable={rowClickable}
        onSort={sortAdminLecturers}
        meta={meta}
      />
    </>
  );
};

AdminLecturerLists.propTypes = {
  byId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  by: PropTypes.oneOf(['users', 'establishments']),
  rowClick: PropTypes.func,
  rowClickable: PropTypes.bool,
};
export default AdminLecturerLists;
