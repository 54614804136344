import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch, connect } from 'react-redux';

import Introduction from './Introduction';
import DefineYourCustomers from './DefineYourCustomers';
import BuildCustomerPersona from './BuildCustomerPersona';
import Summary from './Summary';
import Section from '../../components/Section';
import * as AppRoute from '../../utils/appRoutes';
import { prepareComponents } from '../../utils/helpers';
import { select } from '../../rematcher/store';

const components = [
  Introduction,
  DefineYourCustomers,
  BuildCustomerPersona,
  Summary,
];

function CustomerSegment({
  locales,
  locales: {
    customerSegment: { menu, sectionName },
  },
  progress,
}) {
  const {
    customerSegment: { fetchCustomerSegments },
    bmc: { fetchCurrentBmc, updateBmc },
  } = useDispatch();

  const {
    bmc: { current: currentBmc },
  } = useSelector((state) => state);

  const { bmcId } = useParams();
  const preparedComponents = prepareComponents(
    components,
    bmcId,
    menu,
    AppRoute.customerSegment,
  );

  const getData = () => {
    fetchCurrentBmc(bmcId);
    fetchCustomerSegments(bmcId);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (currentBmc.id)
      updateBmc({
        id: currentBmc.id,
        name: 'customer_segments_progress',
        value: parseInt(progress),
      });
  }, [progress]);

  return (
    <Section
      components={preparedComponents}
      sectionName={sectionName}
      menuItems={menu}
      progress={progress}
      locales={locales}
      finalDestination={AppRoute.bmc(bmcId)}
      onComponentChange={getData}
    />
  );
}

const mapState = select((models) => ({
  progress: models.progress.customerSegmentProgress,
}));

export default connect(mapState, null)(CustomerSegment);
