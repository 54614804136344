import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../../main/components/Modal';
import ModalContainer from '../../components/ModalContainer';
import ModalHeader from '../../components/ModalHeader';
import { Divider } from '../../components/Divider';
import SelectUser from '../Selects/SelectUser';
import { CellFullName } from '../../components/UserList';
import { shareClassroomLink } from '../../support/serverRoutes';
import copyToClipboard from '../../../main/utils/helpers/copyToClipboard';
import ModalDeleteConfirmation from '../../components/ModalDeleteConfirmation';

const ModalClassSettings = ({ classroomId, visible, onClose }) => {
  const [lecturerOptions, setLecturerOptions] = useState([]);
  const [classroomName, setClassroomName] = useState(null);
  const [isCopied, setCopied] = useState(false);
  const [
    visibilityModalDeleteConfirmation,
    setVisibilityModalDeleteConfirmation,
  ] = useState(false);

  const {
    classroom: { current, uploading: classroomLoading },
    classroomLecturer: { collection, uploading },
    lecturers: { collection: lecturers, loading: lecturersLoading },
  } = useSelector((state) => state);

  const {
    classrooms: { deactivateClassroom },
    classroom: { fetchClassroom, updateClassroomName },
    classroomLecturer: {
      fetchClassroomLecturers,
      addClassroomLecturer,
      removeClassroomLecturer,
    },
    lecturers: { fetchLecturers },
  } = useDispatch();

  useEffect(() => {
    if (classroomId) {
      fetchClassroom(classroomId);
      fetchClassroomLecturers(classroomId);
    }
  }, [classroomId]);

  useEffect(() => {
    if (current?.id === classroomId && current?.attributes?.establishment?.id) {
      fetchLecturers({
        establishmentId: current?.attributes?.establishment?.id,
      });
      setClassroomName(current?.name);
    }
  }, [current]);

  useEffect(() => {
    if (lecturers) {
      setLecturerOptions(
        lecturers.map((lecturer) => ({
          ...lecturer,
          ...lecturer.attributes,
          fullName: lecturer.attributes.name,
        })),
      );
    }
  }, [lecturers]);

  const handleOpenModalDeleteConfirmation = () =>
    setVisibilityModalDeleteConfirmation(true);

  const handleCloseModalDeleteConfirmation = () =>
    setVisibilityModalDeleteConfirmation(false);

  const handleSelect = (lecturerId) => {
    addClassroomLecturer({ classroomId, lecturerId });
  };

  const handleDelete = (lecturerId) => {
    removeClassroomLecturer({ classroomId, lecturerId });
  };

  const handleDeactivateClassroom = () => {
    deactivateClassroom(classroomId);
    handleCloseModalDeleteConfirmation();
    onClose();
  };

  const handleChangeClassName = (event) => {
    setClassroomName(event.currentTarget.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (classroomName !== null)
        updateClassroomName({ id: classroomId, name: classroomName });
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [classroomName]);

  return (
    <>
      {visible && (
        <Modal onBlackscreenClick={onClose}>
          <ModalContainer>
            <ModalHeader title="Class Settings" onClose={onClose} />
            {classroomLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 200,
                }}
              >
                <Spin size="large" />
              </div>
            ) : (
              <Form layout="vertical" className="mt-20">
                <div className="mb-20 mt-20">
                  <Form.Item label="Class Name" className="admin-input-label">
                    <Input
                      className="admin-input"
                      value={classroomName}
                      onChange={handleChangeClassName}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Share Link with Students"
                    className="admin-input-label"
                  >
                    <Input
                      className="admin-input"
                      value={shareClassroomLink(current?.secretToken)}
                    />
                  </Form.Item>
                  <div className="flex align-right">
                    <Button
                      type="primary"
                      onClick={() => {
                        copyToClipboard(
                          shareClassroomLink(current?.secretToken),
                        );
                        setCopied(true);
                      }}
                    >
                      {isCopied ? 'Copied' : 'Copy Link'}
                    </Button>
                  </div>
                </div>

                <SelectUser
                  avatarVisible
                  users={lecturerOptions}
                  loading={uploading || lecturersLoading}
                  columns={[
                    {
                      dataIndex: 'email',
                      render: (row) => {
                        return <CellFullName user={row} />;
                      },
                    },
                  ]}
                  labelOfSelect="Invite Other Educators To Join Your Class"
                  placeholderOfSelect="Add the email address they signed up to Validate with"
                  labelOfList="Educators"
                  selectedIds={collection.map((lecturer) => lecturer.id)}
                  onSelect={handleSelect}
                  onDelete={handleDelete}
                />

                <Divider />
                <div className="mt-20">
                  <Button
                    disabled={current?.attributes?.studentsCount > 0}
                    type="text"
                    onClick={handleOpenModalDeleteConfirmation}
                    danger
                  >
                    Delete
                  </Button>
                </div>
              </Form>
            )}
          </ModalContainer>
        </Modal>
      )}
      <ModalDeleteConfirmation
        visible={visibilityModalDeleteConfirmation}
        confirmationTitle="Are you sure to delete this classroom?"
        onClose={handleCloseModalDeleteConfirmation}
        onDelete={handleDeactivateClassroom}
      />
    </>
  );
};

ModalClassSettings.propTypes = {
  classroomId: PropTypes.number,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
export default ModalClassSettings;
