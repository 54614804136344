import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Modal from './Components/Modal';
import Button from '../../components/Button';
import Spinner from '../../components/Spinner';
import BmcCanvasContainer from '../bmc/BmcCanvasContainer';
import TestRow from './Components/TestRow';
import * as AppRoute from '../../utils/appRoutes';
import FirstTimeTip from '../tips/FirstTimeTip';
import { ENABLE_ONBOARDING } from '../../utils/const';

const Testing = ({
  locales,
  locales: {
    testing: {
      titles,
      list: { createNewTest, createQuickTest, title, createNewTestText },
    },
  },
}) => {
  const history = useHistory();
  const [modal, setModal] = useState({
    status: false,
    test: null,
  });

  const {
    bmc: {
      current: { id: bmcId },
    },
    testing: { collection: tests, uploading },
  } = useSelector((state) => state);

  const {
    bmc: { setBmcView },
    testing: { fetchTests, addTest, setTestReducer },
    user: { updateCurrentUser },
  } = useDispatch();

  useEffect(() => {
    setBmcView('testing');
  }, []);

  useEffect(() => {
    if (bmcId) updateCurrentUser({ name: 'lastBmcId', value: bmcId });
  }, [bmcId]);

  useEffect(() => {
    if (bmcId) fetchTests(bmcId);
  }, [bmcId, modal.status]);

  const onCreate = async () => {
    const response = await addTest();
    const {
      data: { data },
    } = response;
    history.push(AppRoute.testing(bmcId, data.id));
  };

  const handleEdit = (test) => {
    setModal({ status: true });
    setTestReducer(test);
  };

  const onFastCreate = async () => {
    const response = await addTest();
    handleEdit(response.data.data.attributes);
  };

  return (
    <BmcCanvasContainer locales={locales}>
      {ENABLE_ONBOARDING && (
        <FirstTimeTip page="testing" tips="testingTip" locales={locales} />
      )}
      <div className="bmc-main testing">
        <Modal
          locales={locales}
          isOpen={modal.status}
          titles={titles}
          setModalStatus={setModal}
        />

        <div className="bmc-main-header">
          <div className="bmc-main-header-title">{title}</div>

          <div className="bmc-main-header-actions bmc-main-header-actions--testing">
            {/* todo: temporary disabled <Button
              icon="icon-Comment"
              className="bmc-button--blue bmc-button--just-icon margin-right-10"
            />
            <Button
              icon="icon-Play"
              className="bmc-button--blue bmc-button--just-icon margin-right-10"
            /> */}
            {!!tests.length && (
              <Button
                className="bmc-button--primary create-test"
                onClick={onCreate}
                title={createNewTest}
              />
            )}
          </div>
        </div>

        {uploading ? (
          <Spinner className="margin-top-60" big />
        ) : (
          <div className="tests-list margin-top-30">
            {tests.length > 0 && (
              <Button
                title={createQuickTest}
                onClick={onFastCreate}
                className="tests-list__create-test-button"
              />
            )}

            {!tests.length && (
              <div className="empty-list">
                <div className="text">
                  {createNewTestText[0]}
                  <br />
                  {createNewTestText[1]}
                </div>
                <Button
                  className="bmc-button--primary"
                  onClick={onCreate}
                  title={createNewTest}
                />
              </div>
            )}

            {tests.map((testItem) => (
              <TestRow
                titles={titles}
                key={testItem.id}
                test={testItem}
                onClick={() => handleEdit(testItem)}
              />
            ))}
          </div>
        )}
      </div>
    </BmcCanvasContainer>
  );
};

export default Testing;
