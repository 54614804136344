import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { truncate } from 'lodash';
import useMediaQuery from 'react-use-media-query-hook';

import CustomerSegmentCard from '../../main/containers/customerSegment/CardBlog';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import { BLOG_FRIENDLY_TEMPLATE_NAME } from '../../main/utils/const';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';

const CustomerSegment = ({
  locales,
  locales: {
    portfolioWeb,
    customerSegment: {
      intro: { card },
    },
  },
  backgroundSection,
  backgroundCard,
  isFourthColorPaletteNumber = false,
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [activeCardId, setActiveCardId] = useState(null);
  const { customerSegment } = useSelector((state) => state.portfolio);

  const { collection } = useSelector((state) => state.customerSegment);

  const activeCardData = useMemo(() => {
    if (collection.length && !activeCardId) {
      setActiveCardId(collection[0].id);
      return collection[0];
    }
    return collection.find((i) => i.id === activeCardId);
  }, [collection, activeCardId]);

  const textColorDependsOnBackground =
    isFourthColorPaletteNumber &&
    getColorDependsOnBackground(backgroundSection);
  const cardTextColorDependsOnBackground =
    isFourthColorPaletteNumber && getColorDependsOnBackground(backgroundCard);

  const singleCard = activeCardData && (
    <CustomerSegmentCard
      locales={locales}
      isPreview
      background={backgroundCard}
      card={{
        ...card,
        ...activeCardData,
        subtitle: activeCardData.name,
        title: null,
      }}
      textColor={cardTextColorDependsOnBackground}
      borderImageColor={cardTextColorDependsOnBackground}
    />
  );
  return (
    <div
      className="customer-segment-blogFriendly-web section-web"
      style={{ backgroundColor: backgroundSection }}
    >
      <SectionHeaderWeb
        templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
        title={portfolioWeb.customerSegment}
        description={customerSegment.description}
        portfolioWeb={portfolioWeb}
        style={{ color: textColorDependsOnBackground }}
        descriptionColor={textColorDependsOnBackground}
      />

      <div className="cards-section">
        <div className="left">
          {collection.map(({ id, name }) => (
            <div
              key={id}
              onClick={() => setActiveCardId(id)}
              className={classnames('item', 'custom-font-body', {
                active: activeCardId === id,
              })}
              style={{ color: textColorDependsOnBackground }}
            >
              {truncate(name, { length: 45 })}
            </div>
          ))}
        </div>
        <div className="right">
          {isMobile
            ? collection.map((customerSegmentItem) => {
                return (
                  <CustomerSegmentCard
                    locales={locales}
                    className="margin-top-20"
                    isPreview
                    background={backgroundCard}
                    card={{
                      ...card,
                      ...customerSegmentItem,
                      subtitle: customerSegmentItem.name,
                      title: null,
                    }}
                    textColor={cardTextColorDependsOnBackground}
                    borderImageColor={cardTextColorDependsOnBackground}
                  />
                );
              })
            : singleCard}
        </div>
      </div>
    </div>
  );
};

export default CustomerSegment;
