import { collectionSomeItemIsFilled } from '../../utils/helpers';

const revenueFields = ['name', 'description'];

const productHasSales = (product) => {
  return product.sales.every((e) => Number.isInteger(e));
};

export default function calculateRevenueProgress(rootState) {
  const stepsCount = 3;
  const step = 100 / stepsCount;
  const productFields = ['name', 'endUserPrice', 'unit'];
  let revenueProgress = 0;

  if (collectionSomeItemIsFilled(rootState.revenue.collection, revenueFields))
    revenueProgress += step;

  if (collectionSomeItemIsFilled(rootState.product.collection, productFields))
    revenueProgress += step;

  if (
    rootState.product.collection.length &&
    productHasSales(rootState.product.collection[0])
  )
    revenueProgress += step;

  return revenueProgress;
}
