import React from 'react';
import _get from 'lodash/get';
import Rating from 'react-rating';
import { useDispatch } from 'react-redux';
import ConfirmationModal from '../../../components/ConfirmationModal';
import TestStatus from './TestStatus';
import { getStar } from '../../../utils/helpers/getStar';
import { BLOCK_NAMES } from '../../../utils/const';

const TestRow = ({
  test: testObject,
  onClick,
  isPreview = false,
  backgroundRow,
  borderCardColor,
  testTypeColor,
  textColor,
  rateColor,
  titles: testText,
  isColourfulfunTemplate = false,
}) => {
  const test = _get(testObject, 'test', '');
  const text = _get(testObject, 'assumption.text', '');
  const blocks = _get(testObject, 'assumption.blocks', []);
  const status = _get(testObject, 'analyse.status');

  const previewTestText = _get(testText, `[${test}.text]`, '');
  const previewTestRating = _get(testText, `[${test}.rating]`, '');

  const {
    testing: { deleteTest },
  } = useDispatch();

  const handleDelete = async (e) => {
    e.stopPropagation();
    deleteTest(testObject.id);
  };

  const getAssumptionBlock = () => {
    return (
      <div className="assumption test-card-section">
        <div
          className="header-assumption custom-font-body"
          style={{ color: textColor }}
        >
          Assumption
        </div>
        <div className="text custom-font-body" style={{ color: textColor }}>
          {text}
        </div>
      </div>
    );
  };

  return (
    <div
      onClick={onClick}
      className="test-card test-card--row card"
      style={{ borderColor: borderCardColor, backgroundColor: backgroundRow }}
    >
      {!isPreview && (
        <ConfirmationModal
          body="Delete this content. Are you sure?"
          confirmText="Delete"
          onConfirm={handleDelete}
        >
          <div className="test-card-delete-icon">
            <i className="icon-Delete" />
          </div>
        </ConfirmationModal>
      )}
      <div className="main">
        <div className="test-type test-card-section">
          <div
            className="header-type custom-font-body"
            style={{ color: textColor }}
          >
            Test Type
          </div>
          <div
            className="type custom-font-title"
            style={{ color: testTypeColor }}
          >
            {isPreview ? previewTestText : test}
          </div>
          {!isPreview && test !== null && test !== '' && (
            <div className="rating">
              <Rating
                className="rate"
                readonly
                initialRating={previewTestRating}
                fullSymbol={getStar('full', rateColor)}
                emptySymbol={getStar('empty', rateColor)}
              />
            </div>
          )}
        </div>
        {!isColourfulfunTemplate && getAssumptionBlock()}
        <div className="bmc-status-wrapper">
          <div className="bmc-blocks-wrapper test-card-section">
            <div
              className="header-blocks custom-font-body"
              style={{ color: textColor }}
            >
              BMC Blocks Tested
            </div>
            <div className="bmc-blocks">
              {BLOCK_NAMES.map((item) => {
                return (
                  blocks.includes(item) && (
                    <div
                      className={`bmc-blocks__block bmc-blocks__block-${item} custom-font-title`}
                    >
                      {item}
                    </div>
                  )
                );
              })}
            </div>
          </div>
          <div className="status test-card-section">
            <div
              className="header-status custom-font-body"
              style={{ color: textColor }}
            >
              Status
            </div>
            <TestStatus status={status} />
          </div>
        </div>
      </div>
      {isColourfulfunTemplate && getAssumptionBlock()}
    </div>
  );
};

export default TestRow;
