import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';
import { useHistory, useParams } from 'react-router-dom';
import { truncate } from 'lodash';
import classNames from 'classnames';

import Button from '../../components/Button';
import UserAvatar from '../../components/UserAvatar';
import CreateBmcModal from './CreateBmcModal';
import UpdateBmcModal from './UpdateBmcModal';

const colorClasses = [
  'bmc-team-users-user--orange',
  'bmc-team-users-user--green',
  'bmc-team-users-user--blue',
  'bmc-team-users-user--violet',
];

export default function Team({ locales, locales: { teams } }) {
  const [menuOpened, setMenuOpened] = useState(false);
  const [createBmcOpened, setCreateBmcOpened] = useState(false);
  const [updateBmcOpened, setUpdateBmcOpened] = useState(false);
  const [bmcId, setBmcId] = useState(null);
  const history = useHistory();
  const { bmcId: bmcIdFromParams } = useParams();

  const {
    bmc: {
      current: { id, name },
      collection,
      users,
    },
  } = useSelector((state) => state);
  const {
    bmc: { fetchCurrentBmc, fetchBmcUsers },
  } = useDispatch();

  useEffect(() => {
    if (!id) fetchCurrentBmc(bmcIdFromParams);
  }, []);

  useEffect(() => {
    if (id) {
      fetchBmcUsers(id);
      setBmcId(id);
    }
  }, [id]);

  const visibleUsers = users.slice(0, 3);
  const nonVisibleUsers = users.slice(3);

  const settingsBmc = (bmc) => {
    setBmcId(bmc.id);
    setMenuOpened(false);
    setUpdateBmcOpened(true);
  };

  const projectName = (project) => {
    return project.name
      ? project.name[0] + (project.name[1] ? project.name[1] : '')
      : project.id;
  };

  const scrollToTop = () => {
    const element = document.getElementById('scroll');
    element.scrollIntoView();
  };

  const switchBmc = (projectId) => {
    const path = window.location.pathname;

    fetchCurrentBmc(projectId);
    fetchBmcUsers(projectId);

    if (path.startsWith('/bmcs')) {
      const newPath = path.replace(/(\/bmcs\/)\d+(\/?\w*)/, `$1${projectId}$2`);
      history.push(newPath);
      scrollToTop();
    }
  };

  const calculateColorClassname = (project) => {
    return colorClasses[project.id % colorClasses.length];
  };

  const onOutsideClickHandler = (event) => {
    if (!['bmc-team-title', 'icon-Colaps'].includes(event.target.className)) {
      setMenuOpened(false);
    }
  };

  return (
    <div className="bmc-team">
      <div
        className="bmc-team-title"
        // eslint-disable-next-line react/no-unknown-property
        test-key="open-projects-modal"
        onClick={() => setMenuOpened(!menuOpened)}
      >
        {truncate(name, { length: 25 })}
      </div>

      <div
        className="bmc-team-title"
        onClick={() => setMenuOpened(!menuOpened)}
      >
        {menuOpened ? (
          <i className="icon-Colaps" />
        ) : (
          <i className="icon-SideBar-right" />
        )}
      </div>

      <div className="bmc-team-users" onClick={() => setUpdateBmcOpened(true)}>
        {visibleUsers.map((user) => (
          <UserAvatar
            key={user.id}
            user={user}
            className="user-avatar--orange bmc-team-users-user"
          />
        ))}
        {nonVisibleUsers.length > 0 && (
          <div className="user-avatar user-avatar--blue">
            +{nonVisibleUsers.length}
          </div>
        )}
      </div>

      {menuOpened && (
        <OutsideClickHandler onOutsideClick={onOutsideClickHandler}>
          <div className="bmc-team-menu">
            <div className="bmc-team-menu-items">
              {collection.map((bmc, index) => (
                <div
                  key={bmc.id}
                  className="bmc-team-menu-item"
                  onClick={() => switchBmc(bmc.id)}
                >
                  <div className="bmc-team-menu-item-icon">
                    <div
                      className={classNames(
                        'bmc-team-users-user',
                        calculateColorClassname(bmc),
                      )}
                    >
                      {projectName(bmc)}
                    </div>
                  </div>
                  <div className="bmc-team-menu-item-title">{bmc.name}</div>
                  <div
                    className="bmc-team-menu-item-action"
                    onClick={() => settingsBmc(bmc)}
                  >
                    <i
                      className="icon-Setting"
                      // eslint-disable-next-line react/no-unknown-property
                      test-key={`project-settings-${index}-button`}
                    />
                  </div>
                </div>
              ))}
            </div>

            <Button
              wide
              title={teams.createNewProject}
              className="bmc-button--blue"
              icon="icon-Add"
              onClick={() => {
                setMenuOpened(false);
                setCreateBmcOpened(true);
              }}
            />
          </div>
        </OutsideClickHandler>
      )}

      {createBmcOpened && (
        <CreateBmcModal
          locales={locales}
          onClose={() => setCreateBmcOpened(false)}
        />
      )}

      {updateBmcOpened && (
        <UpdateBmcModal
          locales={locales}
          bmcId={bmcId}
          onClose={() => setUpdateBmcOpened(false)}
        />
      )}
    </div>
  );
}
