import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import InputSearch from './InputSearch';

const TableHeader = ({
  title,
  count,
  textCreate = 'Create',
  searchPlaceholder,
  searchLoading = false,
  disableCreate = false,
  onCreate,
  onSearch,
}) => {
  return (
    <div className="admin-table-header">
      <div className="admin-table-header__partial">
        <div className="admin-table-header__title">
          {title}:{' '}
          {typeof count === 'number' ? (
            <span className="admin-table-header__count">
              {count.toLocaleString()}
            </span>
          ) : (
            count
          )}
        </div>
      </div>
      <div className="admin-table-header__partial">
        {typeof onSearch !== 'undefined' && (
          <InputSearch
            onSearch={onSearch}
            searchPlaceholder={searchPlaceholder}
            loading={searchLoading}
          />
        )}
        {typeof onCreate !== 'undefined' && !disableCreate && (
          <Button type="primary" onClick={onCreate}>
            {textCreate}
          </Button>
        )}
      </div>
    </div>
  );
};

TableHeader.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  textCreate: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  searchLoading: PropTypes.bool,
  onCreate: PropTypes.func,
  onSearch: PropTypes.func,
};
export default TableHeader;
