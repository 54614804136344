import React from 'react';
import { useDispatch } from 'react-redux';

import SectionHeader from '../SectionHeader';
import Textarea from '../../../components/Textarea';
import UploadFiles from './UploadFiles';

const ProjectSummary = ({
  data: { showStatus, description },
  locales,
  locales: { portfolio },
  disabled = false,
}) => {
  const {
    portfolio: { updateSectionField, updateSectionFieldReducer },
  } = useDispatch();

  const setShowStatus = (status) => {
    updateSectionFieldReducer({
      sectionName: 'projectSummary',
      field: 'showStatus',
      value: status,
    });
  };

  return (
    <div className="desirability">
      <SectionHeader
        locales={portfolio}
        setShowStatus={setShowStatus}
        showStatus={showStatus}
        sectionName={portfolio.projectSummary.sectionName}
      />
      {showStatus && (
        <Textarea
          id="DesirabilitySection"
          rows={5}
          delay={700}
          value={description || ''}
          onChange={(e) => {
            updateSectionField({
              sectionName: 'projectSummary',
              field: 'description',
              name: 'projectSummary',
              value: e.target.value,
            });
          }}
          className="bmc-form-textarea textarea"
          placeholder={portfolio.page.descriptionPlaceholder}
          disabled={disabled}
        />
      )}

      <UploadFiles
        locales={locales}
        className="margin-top-40"
        disabled={disabled}
      />
    </div>
  );
};

export default ProjectSummary;
