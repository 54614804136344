import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../../../../components/Header';
import Text from '../../../../../components/Text';
import Title from '../../../../../components/Title';
import List from '../../../../../components/List';
import Line from '../../../../../components/Line';
import Tabs from '../../../../../components/Tabs';
import TabsList from '../../../../../components/TabsList';
import TabsListItem from '../../../../../components/TabsListItem';
import Textarea from '../../../../../components/Textarea';
import CheckBox from '../../../Components/CheckBox';
import TabsCut from '../../../../../components/TabsCut';
import Actions from '../../../../../components/Actions';
import SvgIcon from '../../../../../components/SvgIcon';
import MobileMenuHeader from '../../../../../components/MobileMenuHeader';
import ResourceUploader from '../../../Components/ResourceUploader';

const icons = ['text', 'ok', 'clip'];
const iconsComponents = icons.map((e) => <SvgIcon name={e} />);

const GeneralPage = ({
  nextClickHandler,
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
  testObject,
  locales,
  locales: {
    common,
    testing: {
      name,
      caseStudies,
      testAnalyse: {
        header,
        general: { text, title, list, actions },
      },
    },
  },
}) => {
  const testType = `${testObject.test || 'homepage'}Result`;
  const [opened, setOpened] = useState(false);

  const { uploadingResult } = useSelector((state) => state.testDocument);

  const {
    testing: { updateTest },
  } = useDispatch();

  const onChange = (e) => {
    const updatedTest = {
      analyse: {
        ...testObject.analyse,
        resultsAndLearning: e.target.value,
      },
    };
    updateTest({ id: testObject.id, test: updatedTest });
  };

  const onCheck = (e) => {
    const { name: eName } = e.target;

    const updatedTest = {
      analyse: {
        ...testObject.analyse,
        status: eName,
      },
    };
    updateTest({ id: testObject.id, test: updatedTest });
  };

  return (
    <div className="bmc-page testing">
      <Header
        className="bmc-page-header--blue"
        subtitle={name}
        icon="icon-VP"
        locales={locales}
        title={header}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        componentIndex={componentIndex}
        mobileMenuHeader={
          <MobileMenuHeader icon="testingActive" title={name} color="blue" />
        }
        rules={rules}
        backPath={backPath}
      />
      <Text>{[text[0], text[1]]}</Text>

      <Title align="left" text={title[0]} />
      <List items={list} icons={icons} />
      <Line />
      <h2 className="bmc-page-title">
        <span className="bmc-page-title--blue">{title[2]}</span> {title[3]}
      </h2>

      <Text align="center">{[text[4]]}</Text>

      <Tabs
        tabs={[common.yourStrategy, common.caseStudy]}
        withBlur
        className="margin-top-60 bmc-page-tabs--blue"
      >
        {[
          <div key="strategy">
            <TabsList formStyle>
              <TabsListItem
                title="Enter Results and Learning"
                subtitle="What have you learnt? What are the key insights? What do you need to do next?"
                icon={iconsComponents[0]}
                text={
                  // eslint-disable-next-line
                  <Textarea
                    onChange={onChange}
                    value={testObject.analyse.resultsAndLearning}
                    delay={700}
                    placeholder="E.g. From the 12 responses to our Homepage we learnt that..."
                    rows={4}
                  />
                }
              />
            </TabsList>

            <TabsList formStyle>
              <TabsListItem
                title="Is this assumption Validated?"
                subtitle="Does the evidence collected Validate or Invalidate your Critical Assumption?"
                icon={iconsComponents[1]}
                text={
                  // eslint-disable-next-line
                  <div className="checkbox-wrapper column">
                    <CheckBox
                      name="valid"
                      isChecked={testObject.analyse.status === 'valid'}
                      onCheck={onCheck}
                      title="Valid"
                    />

                    <CheckBox
                      name="invalid"
                      isChecked={testObject.analyse.status === 'invalid'}
                      onCheck={onCheck}
                      title="Invalid"
                    />

                    <CheckBox
                      name="unsure"
                      isChecked={testObject.analyse.status === 'unsure'}
                      onCheck={onCheck}
                      title="Unsure"
                    />
                  </div>
                }
              />
            </TabsList>

            <TabsList formStyle>
              <TabsListItem
                title="Supporting Documents (optional)"
                subtitle="Add any supporting documents e.g. interview recordings, photos, videos etc."
                icon={iconsComponents[2]}
                text={
                  // eslint-disable-next-line
                  <ResourceUploader
                    documentType="result"
                    uploading={uploadingResult}
                  />
                }
              />
            </TabsList>
          </div>,
          <Tabs
            key="tabs"
            tabs={caseStudies.map((e) => e.name)}
            className="bmc-page-tabs--black bmc-page-tabs--inner"
          >
            {caseStudies.map((item, index) => (
              <div key={index}>
                <TabsCut
                  opened={opened}
                  setOpened={setOpened}
                  caseStudy={item}
                />

                <TabsListItem
                  title="Enter Results and Learning"
                  text={item[testType].text}
                  icon={iconsComponents[0]}
                />
                <TabsListItem
                  title="What BMC Block(s) does this apply to?"
                  icon={iconsComponents[2]}
                >
                  <div className="bmc-page-tabs-content-list-item-text">
                    <div className="checkbox-wrapper column">
                      {['valid', 'invalid', 'unsure'].map((e) => (
                        <CheckBox
                          isChecked={item[testType].status === e}
                          key={e}
                          isPreview
                          title={e}
                        />
                      ))}
                    </div>
                  </div>
                </TabsListItem>
              </div>
            ))}
          </Tabs>,
        ]}
      </Tabs>
      <Actions
        nextClickHandler={nextClickHandler}
        buttonName={common.next}
        tooltipIfDisabled={actions.tooltipIfDisabled}
      />
    </div>
  );
};

export default GeneralPage;
