import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import ChatGroupContainer from './chat-group/chat-group-container';
import CommentContainer from './comment/comment-container';
import ChatGroupCableHandler from '../actioncable/ChatGroupCableHandler';
import {
  COMMENT_ACTIVITIES,
  COMMENT_ANALYSIS,
  COMMENT_BMC,
  COMMENT_CHANNELS,
  COMMENT_CONTACT,
  COMMENT_DESIRABILITY,
  COMMENT_FEASIBILITY,
  COMMENT_FINANCE,
  COMMENT_INTRODUCTION,
  COMMENT_PROPOSITION,
  COMMENT_RELATIONSHIPS,
  COMMENT_SEGMENT,
  COMMENT_SUMMARY,
  COMMENT_SUSTAINABILITY,
  COMMENT_TEAM,
  COMMENT_TESTING,
  COMMENT_VIABILITY,
} from '../../utils/const';

function ChatComponent({
  commentIsOpened = false,
  chatGroup,
  chatGroups = [],
  comments = [],
  members = [],
}) {
  if (commentIsOpened)
    return (
      <CommentContainer
        chatGroup={chatGroup}
        comments={comments}
        members={members}
      />
    );

  return <ChatGroupContainer chatGroups={chatGroups} />;
}

export default function ChatContainer() {
  const { chatGroup, user, bmc } = useSelector((selector) => selector);
  const { selectedChatGroup } = chatGroup;
  const {
    chatGroup: { fetchChatGroups, createChatGroup, updateChatGroupAttribute },
    user: { fetchCurrentUser },
  } = useDispatch();

  const comments = useMemo(() => {
    if (!selectedChatGroup) return [];

    const sortedComments = _.sortBy(
      _.cloneDeep(selectedChatGroup.chatGroupComments),
      (obj) => new Date(obj.createdAt),
    );

    return sortedComments
      .filter((comment) => comment.commentId === 0)
      .map((comment) => {
        return {
          ...comment,
          replies: sortedComments.filter(
            (itemComment) => itemComment.commentId === comment.id,
          ),
        };
      });
  }, [selectedChatGroup, chatGroup]);

  const members = useMemo(() => {
    if (!selectedChatGroup?.chatGroupMembers) return [];

    return selectedChatGroup?.chatGroupMembers.map((member) => ({
      ...member,
      ...member.user,
    }));
  }, [selectedChatGroup?.chatGroupMembers]);

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    if (user.current) updateChatGroupAttribute({ currentUser: user.current });
  }, [user.current]);

  useEffect(() => {
    if (selectedChatGroup) {
      const chatGroupItem = _.cloneDeep(
        chatGroup.collection.find((item) => item.id === selectedChatGroup.id),
      );

      if (chatGroupItem) {
        updateChatGroupAttribute({ selectedChatGroup: chatGroupItem });
      } else {
        updateChatGroupAttribute({
          selectedChatGroup: null,
          commentIsOpened: false,
          isSettedFromSessionStorage: false,
        });
      }
    } else if (
      localStorage.getItem('chatState') &&
      !chatGroup.isSettedFromSessionStorage
    ) {
      const chatStateFromSessionStorage = JSON.parse(
        localStorage.getItem('chatState'),
      );

      if (chatStateFromSessionStorage.lastSelectedChatGroupId) {
        const currentSelectedChatGroup = chatGroup.collection.find(
          (item) =>
            item.id === chatStateFromSessionStorage.lastSelectedChatGroupId,
        );
        if (currentSelectedChatGroup)
          updateChatGroupAttribute({
            isExpanded: chatStateFromSessionStorage.isExpanded,
            commentIsOpened: chatStateFromSessionStorage.commentIsOpened,
            isSettedFromSessionStorage: true,
            selectedChatGroup: _.cloneDeep(currentSelectedChatGroup),
          });
      } else {
        updateChatGroupAttribute({
          isExpanded: chatStateFromSessionStorage.isExpanded,
          commentIsOpened: chatStateFromSessionStorage.commentIsOpened,
          isSettedFromSessionStorage: true,
        });
      }
    }
  }, [chatGroup.collection]);

  useEffect(() => {
    if (localStorage.getItem('chatState')) {
      updateChatGroupAttribute({
        isExpanded:
          JSON.parse(localStorage.getItem('chatState'))?.isExpanded || false,
      });
    }
  }, []);

  useEffect(() => {
    if (bmc.current.id)
      fetchChatGroups({ bmcId: bmc.current.id, sectionName: 'portfolio' }).then(
        ({ json }) => {
          const chatGroupNames = [
            COMMENT_INTRODUCTION,
            COMMENT_SUMMARY,
            COMMENT_TEAM,
            COMMENT_BMC,
            COMMENT_TESTING,
            COMMENT_DESIRABILITY,
            COMMENT_SEGMENT,
            COMMENT_PROPOSITION,
            COMMENT_ANALYSIS,
            COMMENT_RELATIONSHIPS,
            COMMENT_CHANNELS,
            COMMENT_FEASIBILITY,
            COMMENT_ACTIVITIES,
            COMMENT_VIABILITY,
            COMMENT_FINANCE,
            COMMENT_SUSTAINABILITY,
            COMMENT_CONTACT,
          ];

          const currentChatGroupNames = json.data.map(
            (item) => item.attributes.name,
          );
          if (bmc.current.id) {
            chatGroupNames.forEach((name) => {
              if (!currentChatGroupNames.includes(name)) {
                createChatGroup({
                  name,
                  sectionName: 'portfolio',
                  bmcId: bmc.current.id,
                });
              }
            });
          }
        },
      );
  }, [bmc.current.id]);

  useEffect(() => {
    if (selectedChatGroup?.id) {
      localStorage.setItem(
        'chatState',
        JSON.stringify({
          lastSelectedChatGroupId: selectedChatGroup?.id,
          isExpanded: chatGroup.isExpanded,
          commentIsOpened: chatGroup.commentIsOpened,
        }),
      );
    }
  }, [chatGroup.collection]);

  return (
    <div className="chat-container">
      {chatGroup.collection.map((item) => (
        <ChatGroupCableHandler key={item.id} chatGroupId={item.id} />
      ))}

      <ChatComponent
        commentIsOpened={chatGroup.commentIsOpened}
        chatGroup={selectedChatGroup}
        chatGroups={chatGroup.collection}
        comments={comments}
        members={members}
      />
    </div>
  );
}
