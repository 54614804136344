import React, { useMemo } from 'react';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { useDispatch } from 'react-redux';
import humps from 'humps';

export default function ChatGroupCableHandler({ chatGroupId }) {
  const dispatcher = useDispatch();

  const handleReceived = (response) => {
    dispatcher.chatGroup[response.method](humps.camelizeKeys(response.args));
  };

  const data = useMemo(() => {
    return (
      <ActionCableConsumer
        channel={humps.decamelizeKeys({
          channel: 'ChatGroupChannel',
          chatGroupId,
        })}
        onReceived={handleReceived}
      />
    );
  }, [chatGroupId]);

  return data;
}
