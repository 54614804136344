import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import useMediaQuery from 'react-use-media-query-hook';
import { truncate } from 'lodash';

import CardCurrent from '../../channel/CardCurrent';
import { COMMENT_CHANNELS, CURRENT_TEMPLATE_NAME } from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import { getCurrentCanvasIcon } from '../../../utils/helpers/getCurrentCanvasIcon';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import { getColorDependsOnBackground } from '../../../utils/helpers/getColorDependsOnBackground';

const ChannelsCurrent = ({
  data: { description },
  locales,
  locales: {
    portfolio,
    channel: {
      intro: { card },
    },
  },
  styleColor,
  isFourthColorPaletteNumber = false,
  disabled = false,
}) => {
  const { collection } = useSelector((state) => state.channel);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [activeCardId, setActiveCardId] = useState(null);
  const {
    portfolio: { updateSectionField },
  } = useDispatch();
  const activeCardData = useMemo(() => {
    if (collection.length && !activeCardId) {
      setActiveCardId(collection[0].id);
      return collection[0];
    }
    return collection.find((i) => i.id === activeCardId);
  }, [collection, activeCardId]);

  const singleCard = activeCardData && (
    <CardCurrent
      locales={locales}
      styleColor={styleColor}
      templateName={CURRENT_TEMPLATE_NAME}
      dropzoneMode
      isPreview
      disabled={disabled}
      card={{
        ...card,
        ...activeCardData,
        title: null,
      }}
    />
  );

  return (
    <div className="channel-current section relative">
      <PortfolioCommentIcon title={COMMENT_CHANNELS} />
      {getCurrentCanvasIcon('ChannelsCanvas', styleColor)}
      <SectionHeader
        templateName={CURRENT_TEMPLATE_NAME}
        title={portfolio.channels.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'channels',
            field: 'description',
            name: 'channelsText',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        className="green-title"
        style={{ color: styleColor }}
        disabled={disabled}
      />

      <div className="cards-section">
        <div className="left">
          {isMobile
            ? collection.map((channel) => {
                return (
                  <CardCurrent
                    locales={locales}
                    className="margin-top-20"
                    styleColor={styleColor}
                    templateName={CURRENT_TEMPLATE_NAME}
                    backgroundImage={`${styleColor}80`}
                    dropzoneMode
                    isPreview
                    disabled={disabled}
                    card={{
                      ...card,
                      ...channel,
                      title: null,
                    }}
                  />
                );
              })
            : singleCard}
        </div>
        <div className="right">
          {collection.map(({ id, name }) => (
            <div
              key={id}
              onClick={() => setActiveCardId(id)}
              className={classnames('item custom-font-body', {
                active: activeCardId === id,
              })}
              style={{
                backgroundColor: activeCardId === id && styleColor,
                color:
                  isFourthColorPaletteNumber &&
                  activeCardId === id &&
                  getColorDependsOnBackground(styleColor),
              }}
            >
              {truncate(name, { length: 30 })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChannelsCurrent;
