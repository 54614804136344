import { find } from 'lodash';
import main from './main';
import summary from './summary';
import team from './teamMate';
import bmc from './bmc';
import testing from './testing';
import blackPage from './blackPage';
import customerSegments from './customerSegment.js';
import valuePropositions from './valueProposition.js';
import competitorAnalysis from './competitorAnalysis';
import customerRelationships from './customerRelationships';
import finance from './finance';
import sustainabilityList from './sustainabilityList';
import sustainabilityTable from './sustainabilityTable';
import keyActivity from './key';
import channels from './channels.js';
import keyPartners from './keyPartners';
import graph from './graph';
import economics from './economics';
import forecast from './forecast';
import contactsUs from './contactUs';
import { getOptions } from '../Constants';

export default async (
  locales,
  portfolioWeb,
  state,
  sustainability,
  base64BMCImage,
  base64GRAPHImage,
) => {
  const currentTemplateName = state.portfolioStyle.activeTemplateName;
  const templateOptions = getOptions(
    currentTemplateName,
    state.portfolioStyle.templateSettings[currentTemplateName],
    find(state.portfolioTemplate.collection, ['sysName', currentTemplateName]),
  );
  const { portfolio } = state;

  const mainPage =
    portfolio.introduction.showStatus &&
    (await main(locales, portfolio, templateOptions));

  const summaryPage =
    portfolio.projectSummary.showStatus &&
    (await summary(locales, portfolio, state.portfolioFile, templateOptions));

  const teamPage =
    portfolio.team.showStatus &&
    (await team(locales, portfolio, state.portfolioTeammate, templateOptions));

  const bmcPage =
    portfolio.bmc.showStatus &&
    (await bmc(locales, portfolio, base64BMCImage, templateOptions));

  const testingPage =
    portfolio.testing.showStatus &&
    (await testing(locales, portfolio, state.testing, templateOptions));

  const desirabilityPage =
    portfolio.desirabilitySection.showStatus &&
    (await blackPage(
      locales.desirabilitySection.newSectionName,
      portfolio.desirabilitySection,
      templateOptions.desirability,
      templateOptions,
    ));

  const channelsPage =
    portfolio.channels.showStatus &&
    (await channels(locales, portfolio, state.channel, templateOptions));

  const feasibilityPage =
    portfolio.feasibility.showStatus &&
    (await blackPage(
      locales.feasibility.newSectionName,
      portfolio.feasibility,
      templateOptions.feasibility,
      templateOptions,
    ));

  const keyActivityPage =
    portfolio.keyActivities.showStatus &&
    (await keyActivity(
      locales,
      portfolio,
      state.portfolioTeammate,
      state.keyActivity,
      templateOptions,
    ));

  const customerPage =
    portfolio.customerSegment.showStatus &&
    (await customerSegments(
      locales,
      portfolio,
      state.customerSegment,
      templateOptions,
    ));

  const valuePropPage =
    portfolio.valueProposition.showStatus &&
    (await valuePropositions(
      locales,
      portfolio,
      state.valueProposition,
      state.uniqueSellingPoint,
      templateOptions,
    ));

  const competitorPage =
    portfolio.competitorAnalysis.showStatus &&
    (await competitorAnalysis(
      locales,
      portfolio,
      state.competitor,
      templateOptions,
    ));

  const customerRelationshipsPage =
    portfolio.customerRelationships.showStatus &&
    (await customerRelationships(
      locales,
      portfolio,
      state.getKeepGrow,
      templateOptions,
    ));

  const keyPartnersPage =
    portfolio.keyActivities.showStatus &&
    (await keyPartners(
      portfolio,
      state.keyPartner,
      state.keyResource,
      templateOptions,
    ));

  const viabilityPage =
    portfolio.viability.showStatus &&
    (await blackPage(
      locales.viability.newSectionName,
      portfolio.viability,
      templateOptions.viability,
      templateOptions,
    ));

  const financePage =
    portfolio.finance.showStatus &&
    (await finance(locales, portfolio, state.revenue, templateOptions));

  const sustainabilityListPage =
    portfolio.sustainability.showStatus &&
    (await sustainabilityList(
      locales,
      portfolio,
      state.sustainability,
      sustainability.sdgs,
      templateOptions,
    ));

  const sustainabilityTablePage =
    portfolio.sustainability.showStatus &&
    (await sustainabilityTable(
      locales,
      portfolio,
      state.sustainability,
      templateOptions,
    ));

  const graphPage =
    portfolio.finance.showStatus &&
    (await graph(portfolio, base64GRAPHImage, templateOptions));

  const economicsPage =
    portfolio.finance.showStatus &&
    (await economics(locales, portfolio, state.product, templateOptions));

  const forecastsPage =
    portfolio.finance.showStatus &&
    (await forecast(
      locales,
      portfolio,
      state.product,
      state.cost,
      templateOptions,
    ));

  const contactUsPage = await contactsUs(
    locales,
    portfolio,
    portfolioWeb,
    templateOptions,
  );

  return Promise.all(
    [
      mainPage,
      summaryPage,
      teamPage,
      bmcPage,
      testingPage,
      desirabilityPage,
      customerPage,
      valuePropPage,
      competitorPage,
      customerRelationshipsPage,
      channelsPage,
      feasibilityPage,
      keyActivityPage,
      keyPartnersPage,
      viabilityPage,
      financePage,
      economicsPage,
      forecastsPage,
      graphPage,
      sustainabilityListPage,
      sustainabilityTablePage,
      contactUsPage,
    ].filter(Boolean),
  );
};
