import React, { useEffect, useMemo } from 'react';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { useDispatch, useSelector } from 'react-redux';
import humps from 'humps';

export default function UserCableHandler() {
  const { user } = useSelector((selector) => selector);
  const dispatcher = useDispatch();

  const handleReceived = (response) => {
    dispatcher[response.model][response.method](
      humps.camelizeKeys(response.args),
    );
  };

  useEffect(() => {
    dispatcher.user.fetchCurrentUser();
  }, []);

  const data = useMemo(() => {
    return (
      <>
        {user?.current?.id && (
          <ActionCableConsumer
            channel={humps.decamelizeKeys({
              channel: 'UserChannel',
              userId: user?.current?.id,
            })}
            onReceived={handleReceived}
          />
        )}
      </>
    );
  }, [user?.current?.id]);

  return data;
}
