import React from 'react';

import Modal from '../../components/Modal';
import TeamMembers from './TeamMembers';

const DeleteBmcOwnerModal = ({
  visible,
  onClose,
  onOwnerDelete,
  ownerId,
  users,
  locales,
}) => {
  const filteredUsers = users.filter((u) => u.id !== ownerId);

  return (
    <>
      {visible && (
        <Modal onBlackscreenClick={onClose} className="bmc-modal--form">
          <div className="bmc-modal-container">
            <div className="bmc-modal-header">
              <div className="bmc-modal-header-title">Choose project owner</div>
              <div className="bmc-modal-header-action" onClick={onClose}>
                <i className="icon-Cancel" />
              </div>
            </div>

            <div className="bmc-form-fieldset margin-top-20 margin-bottom-20">
              <div className="bmc-form-label-sub">
                The user is project owner. Therefore you need choose another
                project owner instead.
              </div>
            </div>

            <div className="bmc-form">
              <TeamMembers
                locales={locales}
                users={filteredUsers}
                onChooseConfirm={onOwnerDelete}
                ownerId={ownerId}
                isOwnerChoose
              />

              <div className="bmc-form-line" />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DeleteBmcOwnerModal;
