export function imageAttributes() {
  return { original: '', cropped: '' };
}

export function imageCoordAttributes() {
  return {
    x: 0,
    y: 0,
    width: null,
    height: null,
    cropperData: { zoom: 1, x: 0, y: 0, width: null, height: null },
  };
}
