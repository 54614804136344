import {
  Paragraph,
  ImageRun,
  Table,
  TableRow,
  TableCell,
  AlignmentType,
  WidthType,
  HeightRule,
  TableAnchorType,
  OverlapType,
  TableLayoutType,
  HorizontalPositionRelativeFrom,
  VerticalPositionRelativeFrom,
  FrameAnchorType,
  HorizontalPositionAlign,
  VerticalPositionAlign,
  VerticalAlign,
} from 'docx';

import { filter } from 'lodash';
import {
  pageSize,
  convertPixelToTwip,
  convertPixel,
  COLORS,
  getBlobFromUrl,
  transparentBorder,
  convertCentimeterToEMU,
  tableWidth,
  shadingBox,
  noPageMargin,
  whiteBgImage,
} from '../Constants';
import {
  buildBgCircle,
  buildKeyActivityTitleIcon,
  emptyCell,
  marginTableRow,
  textRun,
} from '../helpers/index';
import KeyResourceIcon from '../assets/icons/KR.svg';
import KeyPartnerIcon from '../assets/icons/KP.svg';

export default async (portfolio, keyPartner, keyResource, options) => {
  const keyResourcesImage = await buildKeyActivityTitleIcon(
    KeyResourceIcon,
    options.keyResources.iconBg,
  );
  const keyPartnersImage = await buildKeyActivityTitleIcon(
    KeyPartnerIcon,
    options.keyResources.iconBg,
  );

  const dotImg = await buildBgCircle(15, 15, options.keyResources.iconBg);

  const rowsDataList = (dataType) =>
    filter(dataType.collection, (item) => item.description).map(
      (resource) =>
        new TableRow({
          height: {
            value: convertPixelToTwip(28),
            rule: HeightRule.ATLEAST,
          },
          children: [
            emptyCell(28, COLORS.WHITE),
            // dot cell
            new TableCell({
              width: {
                size: convertPixelToTwip(15),
                type: WidthType.DXA,
              },
              borders: transparentBorder(COLORS.WHITE),
              children: [
                new Paragraph({
                  children: [
                    new ImageRun({
                      // data: getBlobFromUrl(options.keyResources.dotImg),
                      data: dotImg,
                      transformation: {
                        width: convertPixel(15),
                        height: convertPixel(16),
                      },
                    }),
                  ],
                }),
              ],
            }),

            emptyCell(15, COLORS.WHITE),

            new TableCell({
              width: {
                size: convertPixelToTwip(712),
                type: WidthType.DXA,
              },
              borders: transparentBorder(COLORS.WHITE),
              children: [
                new Paragraph({
                  children: [
                    textRun(resource.description, {
                      size: 14,
                      font: options.bodyFont,
                      color: options.bodyColor || COLORS.BLACK,
                    }),
                  ],
                }),
              ],
            }),

            emptyCell(28, COLORS.WHITE),
          ],
        }),
    );

  const getLeftImgBg = () => {
    if (options.keyResources?.leftImgBg)
      return new ImageRun({
        data: getBlobFromUrl(options.keyResources?.leftImgBg),
        transformation: {
          width: convertPixel(147),
          height: convertPixel(203),
        },
        floating: {
          behindDocument: true,
          horizontalPosition: {
            relative: HorizontalPositionRelativeFrom.PAGE,
            offset: convertCentimeterToEMU(1),
          },
          verticalPosition: {
            relative: VerticalPositionRelativeFrom.PAGE,
            offset: convertCentimeterToEMU(9),
          },
        },
      });
  };

  const getRightImgBg = () => {
    if (options.keyResources?.rightImgBg)
      return new ImageRun({
        data: getBlobFromUrl(options.keyResources?.rightImgBg),
        transformation: {
          width: convertPixel(126),
          height: convertPixel(295),
        },
        floating: {
          behindDocument: true,
          horizontalPosition: {
            relative: HorizontalPositionRelativeFrom.PAGE,
            offset: convertCentimeterToEMU(24.3),
          },
          verticalPosition: {
            relative: VerticalPositionRelativeFrom.PAGE,
            offset: convertCentimeterToEMU(2),
          },
        },
      });
  };

  return {
    properties: {
      page: {
        size: pageSize,
        margin: noPageMargin,
      },
    },
    children: [
      new Paragraph({
        frame: {
          position: {
            x: 0,
            y: 0,
          },
          width: convertPixelToTwip(tableWidth),
          height: convertPixelToTwip(539 + 56),
          rule: HeightRule.EXACT,
          anchor: {
            horizontal: FrameAnchorType.TEXT,
            vertical: FrameAnchorType.TEXT,
          },
          alignment: {
            x: HorizontalPositionAlign.CENTER,
            y: VerticalPositionAlign.TOP,
          },
        },
        shading: options.keyResources.pageBg
          ? shadingBox(options.keyResources.pageBg)
          : null,
        children: [getLeftImgBg(), getRightImgBg()],
      }),

      new Table({
        float: {
          horizontalAnchor: TableAnchorType.TEXT,
          verticalAnchor: TableAnchorType.TEXT,
          overlap: OverlapType.NEVER,
        },
        width: {
          size: convertPixelToTwip(tableWidth),
          type: WidthType.DXA,
        },
        layout: TableLayoutType.AUTOFIT,
        rows: [
          // title row
          new TableRow({
            height: {
              value: convertPixelToTwip(38),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(28, COLORS.WHITE),
              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth - 56),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.WHITE),
                columnSpan: 3,
                verticalAlign: VerticalAlign.CENTER,
                children: [
                  // Key Resources Title
                  new Table({
                    float: {
                      horizontalAnchor: TableAnchorType.TEXT,
                      verticalAnchor: TableAnchorType.TEXT,
                      overlap: OverlapType.NEVER,
                    },
                    width: {
                      size: convertPixelToTwip(tableWidth - 56),
                      type: WidthType.DXA,
                    },
                    layout: TableLayoutType.AUTOFIT,
                    rows: [
                      new TableRow({
                        height: {
                          value: convertPixelToTwip(38),
                          rule: HeightRule.ATLEAST,
                        },
                        children: [
                          emptyCell(297, COLORS.WHITE),
                          new TableCell({
                            width: {
                              size: convertPixelToTwip(46),
                              type: WidthType.DXA,
                            },
                            borders: transparentBorder(COLORS.WHITE),
                            verticalAlign: VerticalAlign.CENTER,
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                  new ImageRun({
                                    data: keyResourcesImage,
                                    transformation: {
                                      width: convertPixel(38.5),
                                      height: convertPixel(38.5),
                                    },
                                  }),
                                ],
                              }),
                            ],
                          }),
                          new TableCell({
                            width: {
                              size: convertPixelToTwip(141),
                              type: WidthType.DXA,
                            },
                            borders: transparentBorder(COLORS.WHITE),
                            verticalAlign: VerticalAlign.CENTER,
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.END,
                                children: [
                                  textRun('Key Resources', {
                                    size: 20,
                                    font: options.bodyFont,
                                    color: options.bodyColor || COLORS.BLACK,
                                  }),
                                ],
                              }),
                            ],
                          }),
                          emptyCell(297, COLORS.WHITE),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              emptyCell(28, COLORS.WHITE),
            ],
          }),
          marginTableRow(15, tableWidth, COLORS.WHITE, 5),
          // line row
          new TableRow({
            height: {
              value: convertPixelToTwip(2),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(28, COLORS.WHITE),
              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth - 56),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.WHITE),
                columnSpan: 3,
                children: [
                  // Line
                  new Paragraph({
                    border: {
                      top: {
                        color: options.keyResources.lineColor,
                        space: 2,
                        style: 'single',
                        size: 6,
                      },
                    },
                  }),
                ],
              }),
              emptyCell(28, COLORS.WHITE),
            ],
          }),
          marginTableRow(15, tableWidth, COLORS.WHITE, 5),

          ...rowsDataList(keyResource),

          marginTableRow(35, tableWidth, COLORS.WHITE, 5),

          // title row
          new TableRow({
            height: {
              value: convertPixelToTwip(38),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(28, COLORS.WHITE),
              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth - 56),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.WHITE),
                columnSpan: 3,
                children: [
                  // Key Resources
                  // Key Resources Title
                  new Table({
                    float: {
                      horizontalAnchor: TableAnchorType.TEXT,
                      verticalAnchor: TableAnchorType.TEXT,
                      overlap: OverlapType.NEVER,
                    },
                    width: {
                      size: convertPixelToTwip(tableWidth - 56),
                      type: WidthType.DXA,
                    },
                    layout: TableLayoutType.AUTOFIT,
                    rows: [
                      new TableRow({
                        height: {
                          value: convertPixelToTwip(38),
                          rule: HeightRule.ATLEAST,
                        },
                        children: [
                          emptyCell(297, COLORS.WHITE),
                          new TableCell({
                            width: {
                              size: convertPixelToTwip(46),
                              type: WidthType.DXA,
                            },
                            borders: transparentBorder(COLORS.WHITE),
                            verticalAlign: VerticalAlign.CENTER,
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                  new ImageRun({
                                    data: keyPartnersImage,
                                    transformation: {
                                      width: convertPixel(38.5),
                                      height: convertPixel(38.5),
                                    },
                                  }),
                                ],
                              }),
                            ],
                          }),
                          new TableCell({
                            width: {
                              size: convertPixelToTwip(141),
                              type: WidthType.DXA,
                            },
                            borders: transparentBorder(COLORS.WHITE),
                            verticalAlign: VerticalAlign.CENTER,
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.END,
                                children: [
                                  textRun('Key Partners', {
                                    size: 20,
                                    font: options.bodyFont,
                                    color: options.bodyColor || COLORS.BLACK,
                                  }),
                                ],
                              }),
                            ],
                          }),
                          emptyCell(297, COLORS.WHITE),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              emptyCell(28, COLORS.WHITE),
            ],
          }),
          marginTableRow(15, tableWidth, COLORS.WHITE, 5),
          // line row
          new TableRow({
            height: {
              value: convertPixelToTwip(2),
              rule: HeightRule.ATLEAST,
            },
            children: [
              emptyCell(28, COLORS.WHITE),
              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth - 56),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.WHITE),
                columnSpan: 3,
                children: [
                  // Line
                  new Paragraph({
                    border: {
                      top: {
                        color: options.keyResources.lineColor,
                        space: 2,
                        style: 'single',
                        size: 6,
                      },
                    },
                  }),
                ],
              }),
              emptyCell(28, COLORS.WHITE),
            ],
          }),
          marginTableRow(15, tableWidth, COLORS.WHITE, 5),
          // list of resources data
          ...rowsDataList(keyPartner),
        ],
      }),
      whiteBgImage(options.keyResources.pageBg),
    ],
  };
};
