import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Img from '../../main/components/Img';
import { removeNewLines } from '../../main/utils/helpers';
import { ARCHITECTURAL_MINIMAL_TEMPLATE_NAME } from '../../main/utils/const';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import { getColorDependsOnBackgroundAndPalette } from '../../main/utils/helpers/portfolioArchTemplate/getColorDependsOnBackgroundAndPalette';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';

const Team = ({
  locales: { portfolioWeb },
  backgroundSection,
  backgroundImage,
  colorPaletteNumber,
  isFourthColorPaletteNumber = false,
}) => {
  const {
    portfolioTeammate: { collection },
    portfolio: { team },
  } = useSelector((state) => state);

  const getAbbreviation = (source) => {
    return source
      .split(' ')
      .map((n) => n[0])
      .join('');
  };

  const firstCard = (index) => {
    return index === 0 || index % 4 === 0;
  };
  const secondCard = (index) => {
    return index === 1 || index % 4 === 1;
  };
  const thirdCard = (index) => {
    return index % 2 === 0 && index % 4 !== 0;
  };
  const fourthCard = (index) => {
    return index !== 0 && index % 3 === 0;
  };

  const textColor = getColorDependsOnBackgroundAndPalette(
    colorPaletteNumber,
    backgroundSection,
  );

  return (
    <div
      className="team-architectural-web section-web"
      style={{ backgroundColor: backgroundSection }}
    >
      <SectionHeaderWeb
        templateName={ARCHITECTURAL_MINIMAL_TEMPLATE_NAME}
        title={portfolioWeb.team}
        description={team.description}
        portfolioWeb={portfolioWeb}
        colorPaletteNumber={colorPaletteNumber}
        style={{ color: textColor }}
        descriptionColor={textColor}
      />

      <div className="team-architectural-web-cards">
        {collection.map(({ id, name, position, description, image }, index) => {
          return (
            <div className="team-architectural-web-card" key={id}>
              <div
                className={classNames(
                  'image-upload',
                  { 'is-first': firstCard(index) },
                  { 'is-second': secondCard(index) },
                  { 'is-third': thirdCard(index) },
                  { 'is-fourth': fourthCard(index) },
                )}
                style={{ backgroundColor: backgroundImage }}
              >
                {image.cropped ? (
                  <Img src={image.cropped} />
                ) : (
                  <div
                    className="center-block custom-font-title"
                    style={{
                      color:
                        isFourthColorPaletteNumber &&
                        getColorDependsOnBackground(backgroundImage),
                    }}
                  >
                    {getAbbreviation(name)}
                  </div>
                )}
              </div>

              <div
                className="position custom-font-body"
                style={{ color: textColor }}
              >
                {position}
              </div>
              <div
                className="name custom-font-title"
                style={{ color: textColor }}
              >
                {name}
              </div>

              <div
                className="member-description custom-font-body"
                style={{ color: textColor }}
              >
                {description && removeNewLines(description)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Team;
