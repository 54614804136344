import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Tabs } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import Container from '../../../components/Container';
import ClassLists from '../../Lists/ClassLists';
import ProjectLists from '../../Lists/ProjectLists';
import StudentLists from '../../Lists/StudentLists';
import EstablishmentLists from '../../Lists/EstablishmentLists';
import AdminLecturerLists from '../../Lists/AdminLecturerLists';
import BlockList from '../../../components/BlockList';
import ButtonReport from '../../../components/ButtonReport';
import getTodayDateAsString from '../../../utils/date';
import pageNames from '../../../utils/page_names';

const { TabPane } = Tabs;

export default function SuperAdminDashboard({ locales }) {
  const history = useHistory();
  const { userId } = useParams();
  const [currentUserId, setCurrentUserId] = useState(null);

  const {
    blocks: { collections },
    user,
  } = useSelector((state) => state);

  const {
    blocks: { fetchBlocks },
  } = useDispatch();

  useEffect(() => {
    if (currentUserId !== null) return;

    if (userId !== undefined) {
      setCurrentUserId(parseInt(userId));
    } else if (user?.current?.id !== undefined) {
      setCurrentUserId(user?.current?.id);
    }
  }, [userId, user]);

  useEffect(() => {
    fetchBlocks({ by: 'users', byId: userId, pageName: 'super_admin' });
  }, []);

  const downloadButtonParams = {
    href: `/admin/users?format=xlsx`,
    fileName: `users_${getTodayDateAsString()}.xlsx`,
  };

  return (
    <Container
      locales={locales}
      title={`Super Admin: ${user?.current?.firstName} ${user?.current?.surname}`}
    >
      <div className="admin-header-button__row">
        <h1>Super Admin Dashboard</h1>
        <ButtonReport
          href={downloadButtonParams.href}
          fileName={downloadButtonParams.fileName}
        >
          Download Report
        </ButtonReport>
      </div>
      <BlockList list={collections} />
      <br />
      <br />
      {currentUserId && (
        <Tabs defaultActiveKey="establishments" className="admin-tabs">
          <TabPane
            key="establishments"
            tab="Establishment"
            className="admin-tab-pane"
          >
            <EstablishmentLists
              rowClick={(record) =>
                history.push(`/admin/establishment/${record.id}`)
              }
              locales={locales}
              rowClickable
            />
          </TabPane>
          <TabPane
            key="admin_lecturers"
            tab="Admin/Educator"
            className="admin-tab-pane"
          >
            <AdminLecturerLists
              locales={locales}
              byId={currentUserId}
              rowClick={(record) => {
                if (record.role !== 'super_admin')
                  history.push(`/admin/lecturer/${record.id}`);
              }}
              rowClickable
            />
          </TabPane>
          <TabPane key="classes" tab="Class" className="admin-tab-pane">
            <ClassLists
              locales={locales}
              byId={currentUserId}
              rowClick={(record) => {
                history.push(`/admin/classroom/${record.id}`);
              }}
              rowClickable
              disableCreateClassroom
            />
          </TabPane>
          <TabPane key="projects" tab="Project" className="admin-tab-pane">
            <ProjectLists
              byId={currentUserId}
              rowClick={(record) =>
                window.open(`/admin/bmc/${record.id}`, '_blank')
              }
              rowClickable
            />
          </TabPane>
          <TabPane key="students" tab="Student" className="admin-tab-pane">
            <StudentLists
              byId={currentUserId}
              expandRowClick={(record) => {
                history.push(`/admin/bmc/${record.id}`);
              }}
              locales={locales}
              currentPage={pageNames.superAdmin}
            />
          </TabPane>
        </Tabs>
      )}
    </Container>
  );
}
