import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dropzone from '../../../components/Dropzone';
import SvgIcon from '../../../components/SvgIcon';
import {
  BUTTON_MODAL_STYLE_COLOURFUL,
  COLOURFUL_TEMPLATE_NAME,
  COMMENT_PROPOSITION,
  IMAGE_ASPECT_RATIO_VP_COLOURFUN,
  TITLE_MODAL_STYLE_COLOURFUL,
} from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import SectionHeader from '../../../components/SectionHeader';
import { renderSegmentList } from '../../../utils/helpers/renderSegmentList';
import { renderText } from '../../../utils/helpers/renderText';

const ValuePropositionColourful = ({
  data: { description },
  locales,
  locales: {
    portfolio,
    valueProposition: {
      intro: { card },
    },
  },
  firstSvgColor,
  secondSvgColor,
  background,
  disabled = false,
}) => {
  const {
    valueProposition: { product, reason, image, imageCoords },
    uniqueSellingPoint: { collection },
  } = useSelector((state) => state);

  const { uploadingImage } = useSelector((state) => state.valueProposition);

  const {
    valueProposition: {
      updateAttributeImage,
      updateValuePropositionImageCoords,
    },
    portfolio: { updateSectionField },
  } = useDispatch();

  const onImageDrop = ([file], openCropperHandler) => {
    updateAttributeImage({
      name: 'image',
      value: URL.createObjectURL(file),
      blob: file,
      success: () => {
        openCropperHandler();
      },
    });
  };

  const onImageCropperSave = (croppedAreaPixels, innerCropData, zoom) => {
    updateValuePropositionImageCoords({
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: imageCoords,
    });
  };

  return (
    <div
      className="value-propositions-colourful section"
      style={{ backgroundColor: background }}
    >
      <div className="svg-funVPeleventh" style={{ fill: firstSvgColor }}>
        <SvgIcon name="funVPeleventh" />
      </div>
      <div className="svg-funVPtwelveth" style={{ fill: secondSvgColor }}>
        <SvgIcon name="funVPtwelveth" />
      </div>
      <div className="content">
        <div className="drop">
          <Dropzone
            className="drop-image"
            onDrop={onImageDrop}
            onCropperSave={onImageCropperSave}
            locales={locales}
            image={image}
            coords={imageCoords}
            uploading={uploadingImage}
            aspectRatio={IMAGE_ASPECT_RATIO_VP_COLOURFUN}
            disabled={disabled}
            cropper
            firstSvgColor={firstSvgColor}
            secondSvgColor={secondSvgColor}
            titleStyle={TITLE_MODAL_STYLE_COLOURFUL}
            buttonStyle={BUTTON_MODAL_STYLE_COLOURFUL}
            isTemplates
            isColourfulfunTemplate
          />
        </div>

        <div className="middle relative">
          <PortfolioCommentIcon title={COMMENT_PROPOSITION} />
          <SectionHeader
            templateName={COLOURFUL_TEMPLATE_NAME}
            title={portfolio.valueProposition.sectionName}
            description={description}
            onChange={(e) =>
              updateSectionField({
                sectionName: 'valueProposition',
                field: 'description',
                name: 'vpText',
                value: e.target.value,
              })
            }
            portfolio={portfolio}
            disabled={disabled}
          />
        </div>
        <div className="bottom">
          <div className="left">
            {renderSegmentList(card.solutionTitle)}
            {renderText(product)}
          </div>
          <div className="bottom-middle">
            {renderSegmentList(card.featuresTitle)}
            {renderText(reason)}
          </div>
          <div className="right">
            {renderSegmentList(card.pointsTitle)}
            {renderText(
              collection.map((point, index) => (
                <div className="margin-bottom-10" key={point.id || index}>
                  {point.description}
                </div>
              )),
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValuePropositionColourful;
