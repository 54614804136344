import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useMediaQuery from 'react-use-media-query-hook';

import { Col, Row } from 'antd';
import Section from './Section';
import Button from '../../components/Button';
import PersonalContent from './Personal/Content';
import ConfirmationDeleteModal from '../../../admin/containers/ModalUser/ConfirmationDeleteModal';
import ROLES from '../../utils/roles';

export default function Account({ locales, locales: { account } }) {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const history = useHistory();
  const {
    user: {
      current: { email, id, role },
    },
    users: { deleteLoading },
  } = useSelector((state) => state);
  const {
    user: { updateCurrentUser },
    users: { deactivateAccount },
  } = useDispatch();

  const [confirmationDeleteVisible, setConfirmationDeleteVisible] =
    useState(false);

  const handleOpenConfirmationModal = () => setConfirmationDeleteVisible(true);

  const handleCloseConfirmationModal = () =>
    setConfirmationDeleteVisible(false);

  const handleDeleteUser = (confirmationPassword) => {
    deactivateAccount({
      userId: id,
      confirmationPassword,
      afterSuccess: () => {
        handleCloseConfirmationModal();
        window.location.reload();
      },
    });
  };

  return (
    <Section menuItems={account.menu} locales={locales} showAll={isMobile}>
      <div className="bmc-account">
        <div className="bmc-form-big-title">{account.title[0]}</div>

        <div className="margin-top-10">{account.subtitle}</div>
        <Row align="bottom">
          <Col span={10}>
            <div className="bmc-form-fieldset margin-top-40 bmc-account-email-field-100">
              <label htmlFor="email" className="bmc-form-label">
                {account.email}
              </label>
              <input
                id="email"
                className="bmc-form-input"
                value={email}
                disabled
                onChange={(e) =>
                  updateCurrentUser({ name: 'email', value: e.target.value })
                }
                data-hj-allow
              />
            </div>
          </Col>
          <Col span={6} className="margin-left-10">
            <div>
              <Button
                title={account.updateEmail}
                className="bmc-button--primary"
                onClick={() => history.push('/account/email')}
              />
            </div>
          </Col>
        </Row>

        <div className="bmc-form-fieldset margin-top-20">
          <span className="bmc-form-label">{account.password}</span>
          <Button
            title={account.updatePassword}
            className="bmc-button--primary"
            onClick={() => history.push('/account/password')}
          />
        </div>

        {/* temporary hide it <div className="bmc-form-text bmc-form-text--small bmc-form-text--upcase margin-top-10">
          {account.cantRemember}
          <Link
            to="/account/reset_password"
            className="bmc-form-link margin-left-10"
          >
            {account.resetPassword}
          </Link>
        </div> */}

        <div className="bmc-form-fieldset margin-top-40">
          <span className="bmc-form-label">{account.privacyInformation}</span>
          <div>{account.byCreatingAccount}</div>
          <div className="margin-top-15">
            <a
              href="/terms"
              target="_blank"
              className="bmc-form-link bmc-account-link"
            >
              {account.termsOfService}
            </a>
            <a
              href="/privacy"
              className="bmc-form-link bmc-account-link"
              target="_blank"
            >
              {account.privacyPolicy}
            </a>
            <a
              href="/accessibility"
              className="bmc-form-link bmc-account-link"
              target="_blank"
            >
              {account.accessibility}
            </a>
          </div>
        </div>

        {role !== ROLES.SUPER_ADMIN && (
          <div>
            <div className="bmc-form-line bmc-form-line--thin margin-top-30" />

            <div className="bmc-form-actions">
              <span
                className="bmc-form-action bmc-form-action--warn"
                onClick={handleOpenConfirmationModal}
              >
                {account.deactivateAccount}
              </span>
            </div>
          </div>
        )}
      </div>

      {isMobile && (
        <PersonalContent locales={locales} className="margin-top-20" />
      )}

      <ConfirmationDeleteModal
        loading={deleteLoading}
        visible={confirmationDeleteVisible}
        onClose={handleCloseConfirmationModal}
        onConfirm={handleDeleteUser}
      />
    </Section>
  );
}
