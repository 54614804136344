import React, { useEffect } from 'react';
import ReactStars from 'react-stars';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import _get from 'lodash/get';
import classNames from 'classnames';
import useMediaQuery from 'react-use-media-query-hook';

import ResponseCard from './ResponseCard';
import { getLastTestingPage } from '../../../models/progress/testing';
import { COLORS } from '../../../utils/const';
import * as AppRoute from '../../../utils/appRoutes';
import SvgIcon from '../../../components/SvgIcon';
import SelectV2 from './Select/SelectV2';
import Button from '../../../components/Button';
import TestStatusBlock from './TectCardBlocks/TestStatusBlock';
import AssumptionBlock from './TectCardBlocks/AssumptionBlock';
import TestPlanBlock from './TectCardBlocks/TestPlanBlock';
import TestResultsBlock from './TectCardBlocks/TestResultsBlock';

const { blue, lightBlue } = COLORS;

export default function TestCard({
  isEditable = true,
  hideAnalyse = false,
  modalMode = false,
  bmcSectionMode = false,
  setModalStatus,
  titles,
  locales: {
    testing: {
      menu,
      testAnalyse: {
        homepage: { card },
      },
    },
  },
  textColor,
  textStyle,
  titleStyle,
  buttonStyle,
  inputColor,
  backgroundCard,
  titleColor,
  buttonColor,
  backgroundButton,
  linkColor,
  rateColor,
  iconFillClose,
}) {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const testingPageSections = menu.map((e) => e.sectionName);
  const history = useHistory();
  const { bmcId: bmcIdFromParams } = useParams();

  const {
    testing: { updateTest },
    testDocument: { fetchTestDocuments },
  } = useDispatch();

  const {
    testing: { test },
  } = useSelector((state) => state);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [sectionName, fieldName] = name.split('.');

    const updatedTest = {
      [sectionName]: {
        ...test[sectionName],
        [fieldName]: value,
      },
    };
    updateTest({ id: test.id, test: updatedTest });
  };

  const handleContinue = () => {
    const continueTo = getLastTestingPage(test);
    const section = testingPageSections[continueTo];

    history.push(AppRoute.testing(bmcIdFromParams, test.id, section));
  };

  const analyseHandler = () => {
    const section = testingPageSections[5];

    history.push(AppRoute.testing(bmcIdFromParams, test.id, section));
  };

  useEffect(() => {
    if (test.id) fetchTestDocuments({ bmcIdFromParams, testId: test.id });
  }, [test.id]);

  const blocks = _get(test, 'assumption.blocks', []);

  const testTypeOptions = [
    { label: 'Homepage', value: 'homepage' },
    { label: 'Research', value: 'research' },
    { label: 'Survey', value: 'survey' },
    { label: 'Interview', value: 'interview' },
    { label: 'Prototype', value: 'prototype' },
    { label: 'Sell', value: 'sell' },
  ];

  const onTestTypeUpdate = ({ value }) => {
    updateTest({ id: test.id, test: { test: value } });
  };

  const onStatusUpdate = ({ value }) => {
    updateTest({
      id: test.id,
      test: {
        analyse: {
          status: value,
          resultsAndLearning: test.analyse.resultsAndLearning,
        },
      },
    });
  };

  const onBlocksUpdate = (_blocks) => {
    updateTest({
      id: test.id,
      test: {
        assumption: {
          blocks: _blocks.map((e) => e.value),
          text: test.assumption.text,
        },
      },
    });
  };

  const getTestStatusBlock = () => {
    return (
      <TestStatusBlock
        status={test.analyse.status}
        blocks={blocks}
        onStatusUpdate={onStatusUpdate}
        onBlocksUpdate={onBlocksUpdate}
        isEditable={isEditable}
        textColor={textColor}
        textStyle={textStyle}
      />
    );
  };

  const getAssumptionBlock = () => {
    return (
      <AssumptionBlock
        text={test.assumption.text}
        isEditable={isEditable}
        handleChange={handleChange}
        textStyle={textStyle}
        textColor={textColor}
      />
    );
  };

  return (
    <div
      style={{ background: backgroundCard }}
      className={classNames('test-card', {
        'test-card--modal-mode': modalMode,
        'test-card--bmc-section-mode': bmcSectionMode,
      })}
    >
      <div className="relative">
        <div
          className={`test-card__title with-button ${titleStyle} custom-font-title`}
        >
          {isEditable ? (
            <SelectV2
              options={testTypeOptions}
              value={testTypeOptions.find((e) => e.value === test.test) || null}
              onChange={onTestTypeUpdate}
              className={`test-card__title-name ${titleStyle} custom-font-title`}
              titleColor={titleColor}
            />
          ) : (
            <div
              className="text custom-font-title"
              style={{ color: titleColor }}
            >
              {test.test ? titles[test.test].text : ''}
            </div>
          )}
          <div className="test-card__title-actions">
            {isEditable && (
              <button
                className={`bmc-button ${buttonStyle} custom-font-body`}
                onClick={handleContinue}
                style={{
                  color: buttonColor,
                  backgroundColor: backgroundButton,
                }}
              >
                Continue
              </button>
            )}
            {modalMode && (
              <Button
                title={<SvgIcon name="closePortfolioModalIcon" />}
                className="close-modal-button"
                onClick={() => setModalStatus({ status: false, id: null })}
                style={{
                  fill: iconFillClose || backgroundButton,
                }}
              />
            )}
          </div>
        </div>
        {test.test && (
          <ReactStars
            className="test-card__rate custom-font-body"
            color2={rateColor || blue}
            color1={rateColor ? `${rateColor}80` : lightBlue}
            size={22}
            edit={false}
            value={titles[test.test].rating}
            textColor={textColor}
          />
        )}
        <div className="test-card__section">
          {isMobile ? (
            <>
              {getTestStatusBlock()}
              {getAssumptionBlock()}
            </>
          ) : (
            <>
              {getAssumptionBlock()}
              {getTestStatusBlock()}
            </>
          )}
        </div>
        {test.test === 'homepage' && (
          <>
            <TestPlanBlock
              handleChange={handleChange}
              textStyle={textStyle}
              textColor={textColor}
              linkColor={linkColor}
              isEditable={isEditable}
            />
            <TestResultsBlock
              hideAnalyse={hideAnalyse}
              analyseHandler={analyseHandler}
              handleChange={handleChange}
              textStyle={textStyle}
              textColor={textColor}
              linkColor={linkColor}
              isEditable={isEditable}
            />

            {_get(test, 'analyse.resultsAndLearning', '').length > 0 && (
              <ResponseCard
                testId={test.id}
                cName={`is-marginless is-paddingless ${textStyle} custom-font-body`}
                className={`${titleStyle} custom-font-title`}
                inputColor={inputColor}
                titleColor={titleColor}
                textColor={textColor}
                card={card}
              />
            )}
          </>
        )}
        {test.test !== 'homepage' && (
          <>
            <TestPlanBlock
              handleChange={handleChange}
              textStyle={textStyle}
              textColor={textColor}
              linkColor={linkColor}
              isEditable={isEditable}
            />
            <TestResultsBlock
              hideAnalyse={hideAnalyse}
              analyseHandler={analyseHandler}
              handleChange={handleChange}
              textStyle={textStyle}
              textColor={textColor}
              linkColor={linkColor}
              isEditable={isEditable}
            />
          </>
        )}
      </div>
    </div>
  );
}
