import * as Route from '../utils/serverRoutes';
import {
  transformResponseCollection,
  transformResponseData,
  updateCollectionById,
} from '../utils/helpers';
import makeRequest, { uploadFile } from '../utils/makeRequest';
import { calculateMenuRules } from './progress/ideation';

export const defaultData = {
  ideas: [],
  mostEasiestId: null,
  mostExcitedId: null,
  mostImpactId: null,
  pickedIdeaId: null,
};

const defaultRules = [true, true, false, false, false, false, false, false];

const ideation = {
  state: {
    collection: [],
    images: [],
    menuRules: defaultRules,
    idea: {
      id: null,
      data: defaultData,
    },
    isUploadingImages: false,
    imageSelectorCurrentPage: 1,
    uploading: false,
  },
  reducers: {
    fetchIdeasReducer(state, { collection }) {
      return { ...state, collection };
    },
    fetchIdeaReducer(state, idea) {
      const newState = {
        ...state,
        idea: { ...idea, data: { ...defaultData, ...idea.data } },
      };

      return { ...newState, menuRules: calculateMenuRules(newState) };
    },
    fetchImagesReducer(state, { images }) {
      return { ...state, images };
    },
    updateIdeaReducer(state, { name, value }) {
      const newState = { ...state, idea: { ...state.idea, [name]: value } };

      return { ...newState, menuRules: calculateMenuRules(newState) };
    },
    updateAttributeReducer(state, data) {
      return { ...state, ...data };
    },
    addIdeationReducer(state, payload) {
      return { ...state, collection: [payload, ...state.collection] };
    },
    updateIdeationReducer(state, { id, isAnyCable = false, data }) {
      const idea =
        (isAnyCable && state.idea.id === id) || !isAnyCable
          ? { ...state.idea, data }
          : state.idea;

      const newState = {
        ...state,
        idea,
        collection: updateCollectionById(state.collection, id, { data }),
      };

      return { ...newState, menuRules: calculateMenuRules(newState) };
    },
    removeIdeationReducer(state, { id }) {
      const collection = state.collection.filter((e) => e.id !== id);

      return { ...state, collection };
    },
  },
  effects: (dispatch) => ({
    fetchIdeas(_, state) {
      dispatch.ideation.updateAttributeReducer({ uploading: true });

      makeRequest({
        dispatch,
        url: Route.ideas(state.bmc.current.id),
        success: (response) => {
          dispatch.ideation.fetchIdeasReducer({
            collection: transformResponseCollection(response.data.data),
          });
        },
        finish: () => {
          dispatch.ideation.updateAttributeReducer({ uploading: false });
        },
      });
    },
    fetchIdeasByBmcId({ bmcId, params }) {
      dispatch.ideation.updateAttributeReducer({ uploading: true });

      makeRequest({
        dispatch,
        url: Route.ideas(bmcId, params),
        success: (response) => {
          dispatch.ideation.fetchIdeasReducer({
            collection: transformResponseCollection(response.data.data),
          });
        },
        finish: () => {
          dispatch.ideation.updateAttributeReducer({ uploading: false });
        },
      });
    },
    fetchIdea(id, state) {
      dispatch.ideation.fetchImages();
      makeRequest({
        dispatch,
        url: Route.idea(state.bmc.current.id, id),
        success: (response) => {
          dispatch.ideation.fetchIdeaReducer(
            transformResponseData(response.data.data),
          );
        },
      });
    },
    fetchImages(_, state) {
      makeRequest({
        dispatch,
        url: Route.ideasImages(state.bmc.current.id),
        success: (response) => {
          dispatch.ideation.fetchImagesReducer({
            images: transformResponseCollection(response.data.data),
          });
        },
      });
    },
    addImage(
      file,
      {
        ideation: { images },
        bmc: {
          current: { id: bmcId },
        },
      },
    ) {
      dispatch.ideation.updateAttributeReducer({ isUploadingImages: true });

      uploadFile({
        url: Route.ideaImages(bmcId),
        method: 'post',
        modelName: 'idea_image',
        name: 'image',
        blob: file,
        dispatch,
        success: (response) => {
          const newItem = transformResponseData(response.data.data);
          const newImages = [newItem, ...images];

          dispatch.ideation.updateAttributeReducer({
            images: newImages,
            imageSelectorCurrentPage: 1,
          });
        },
        finish: () => {
          dispatch.ideation.updateAttributeReducer({
            isUploadingImages: false,
          });
        },
      });
    },
    removeImage(
      imageId,
      {
        ideation: {
          images,
          idea: {
            data,
            data: { ideas },
          },
        },
        bmc: {
          current: { id: bmcId },
        },
      },
    ) {
      makeRequest({
        dispatch,
        method: 'delete',
        url: Route.ideaImage(bmcId, imageId),
        success: () => {
          const newImages = images.filter((e) => e.id !== imageId);
          const newIdeas = ideas.filter((e) => e.id !== imageId);
          const newData = { ...data, ideas: newIdeas };

          dispatch.ideation.updateAttributeReducer({ images: newImages });
          dispatch.ideation.updateIdeaReducer({ name: 'data', value: newData });
        },
      });
    },
    async addIdea(_, state) {
      const { json, status } = await makeRequest({
        dispatch,
        method: 'post',
        url: Route.ideas(state.bmc.current.id),
      });

      if (status === 200) {
        dispatch.ideation.addIdeationReducer(transformResponseData(json.data));
        return json.data;
      }
      return null;
    },
    updateIdeation(payload, state) {
      dispatch.ideation.updateIdeaReducer(payload);

      makeRequest({
        dispatch,
        method: 'put',
        url: Route.idea(state.bmc.current.id, payload.id),
        data: { idea: { [payload.name]: payload.value } },
      });
    },
    updateIdeaData(
      data,
      {
        ideation: {
          idea: { id, data: oldData },
        },
        bmc: {
          current: { id: bmcId },
        },
      },
    ) {
      const newData = { ...oldData, ...data };
      dispatch.ideation.updateIdeaReducer({ name: 'data', value: newData });

      makeRequest({
        dispatch,
        method: 'put',
        url: Route.idea(bmcId, id),
        data: { idea: { data: newData } },
      });
    },
    removeIdea(payload, state) {
      makeRequest({
        dispatch,
        method: 'delete',
        url: Route.idea(state.bmc.current.id, payload.id),
        success: () => {
          dispatch.ideation.removeIdeationReducer(payload);
        },
      });
    },
  }),
};

export default ideation;
