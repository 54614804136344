import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  CHECK_CONNECTION_ENABLED,
  CHECK_CONNECTION_TIMEOUT,
} from '../utils/const';

export default function ConnectionChecker() {
  const {
    connection: { checkConnection },
  } = useDispatch();

  if (!CHECK_CONNECTION_ENABLED) return null;

  useEffect(() => {
    setInterval(() => {
      checkConnection();
    }, CHECK_CONNECTION_TIMEOUT);
  }, []);

  return null;
}
