import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import ImageUploader from '../../../components/ImageUploader';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { PORTFOLIO_FILE_MAX_COUNT } from '../../../utils/const';

export default function UploadFiles({
  className,
  locales: {
    common,
    portfolio: {
      page: { uploadFiles },
    },
  },
  disabled = false,
}) {
  const { bmcId: bmcIdFromParams } = useParams();

  const { collection, uploadingFile } = useSelector(
    (state) => state.portfolioFile,
  );

  const {
    portfolioFile: {
      fetchPortfolioFiles,
      addPortfolioFile,
      removePortfolioFile,
    },
  } = useDispatch();

  useEffect(() => {
    fetchPortfolioFiles(bmcIdFromParams);
  }, []);

  return (
    <div className={classNames('portfolio-file-uploader', className)}>
      <div className="portfolio-file-uploader__header">
        <div className="portfolio-file-uploader__title">
          {uploadFiles.title}
        </div>

        {collection.length < PORTFOLIO_FILE_MAX_COUNT && (
          <ImageUploader
            title={uploadingFile ? common.uploading : common.uploadFile}
            icon="icon-Export"
            className="bmc-button bmc-button--primary portfolio-file-uploader__button"
            onChange={([file]) => {
              addPortfolioFile(file);
            }}
            disabled={disabled}
          />
        )}
      </div>

      <div className="portfolio-file-uploader__files">
        {collection.map((file) => (
          <div key={file.id} className="portfolio-file-uploader-file">
            <a
              className="portfolio-file-uploader-file__title"
              href={file.file}
              target="_blank"
            >
              {file.name}
            </a>

            <ConfirmationModal
              body={common.areYouSure}
              confirmText={common.deleteText}
              onConfirm={() => removePortfolioFile({ id: file.id })}
            >
              <div className="portfolio-file-uploader-file__remove">
                <i className="icon-Delete" />
              </div>
            </ConfirmationModal>
          </div>
        ))}
      </div>
    </div>
  );
}
