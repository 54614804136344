export const nextComponentHandlerFunction = (view, path) => {
  let nextPathByView;
  switch (view) {
    case 'list':
      nextPathByView = `${path}/list`;
      break;
    case 'portfolio':
      nextPathByView = path.includes('testing')
        ? `${path.replace('testing', 'portfolio')}`
        : `${path}/portfolio`;
      break;
    case 'testing':
      nextPathByView = path;
      break;
    default:
      nextPathByView = path;
      break;
  }
  return nextPathByView;
};
