import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../../../../../components/Header';
import Text from '../../../../../../components/Text';
import Title from '../../../../../../components/Title';
import WebsiteCard from '../../../../Components/WebsiteCard';
import List from '../../../../../../components/List';
import Line from '../../../../../../components/Line';
import Tabs from '../../../../../../components/Tabs';
import TabsList from '../../../../../../components/TabsList';
import TabsListItem from '../../../../../../components/TabsListItem';
import Textarea from '../../../../../../components/Textarea';
import Actions from '../../../../../../components/Actions';
import TabsCut from '../../../../../../components/TabsCut';
import SvgIcon from '../../../../../../components/SvgIcon';
import MobileMenuHeader from '../../../../../../components/MobileMenuHeader';
import ResourceUploader from '../../../../Components/ResourceUploader';

const icons = ['plan', 'clip'];
const iconsComponents = icons.map((e) => <SvgIcon name={e} />);

const Survey = ({
  nextClickHandler,
  menuItems,
  selectComponent,
  componentIndex,
  rules,
  backPath,
  testObject,
  locales,
  locales: {
    common,
    testing: {
      name,
      caseStudies,
      planSurveys: { header, text, list, title, sites, tabs, actions },
    },
  },
}) => {
  const {
    testing: { updateTest },
  } = useDispatch();

  const { uploadingPlanning } = useSelector((state) => state.testDocument);

  const onChange = (e) => {
    updateTest({
      id: testObject.id,
      test: {
        data: {
          ...testObject.data,
          [e.target.name]: e.target.value,
        },
      },
    });
  };
  const [opened, setOpened] = useState(false);
  return (
    <div className="bmc-page testing">
      <Header
        className="bmc-page-header--blue"
        icon="icon-VP"
        locales={locales}
        title={header}
        subtitle={name}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        componentIndex={componentIndex}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="testingActive" title={name} color="blue" />
        }
      />
      <Text>{[text[0], text[1]]}</Text>
      <Title align="left" text={title[0]} />
      <Text>{[text[2]]}</Text>

      <div className="websites">
        {sites.map(({ header: siteHeader, url, logo, description }, i) => {
          return (
            <WebsiteCard
              key={i}
              title={siteHeader}
              description={description}
              image={logo}
              url={url}
            />
          );
        })}
      </div>
      <Title align="left" text={title[1]} />
      <List items={list} icons={icons} />

      <Line />

      <h2 className="bmc-page-title">
        <span className="bmc-page-title--blue">{title[2]}</span> {title[3]}
      </h2>

      <Tabs
        tabs={[common.yourStrategy, common.caseStudy]}
        withBlur
        className="margin-top-60 bmc-page-tabs--blue"
      >
        {[
          <div key="strategy">
            <TabsList formStyle>
              <TabsListItem
                subtitle={tabs[0].subtitle}
                title={tabs[0].title}
                icon={iconsComponents[0]}
                text={
                  // eslint-disable-next-line
                  <Textarea
                    name="plan"
                    value={testObject.data.plan || ''}
                    onChange={onChange}
                    placeholder={tabs[0].placeholder}
                    rows={4}
                    delay={700}
                  />
                }
              />
            </TabsList>

            <TabsList formStyle>
              <TabsListItem
                title="Supporting Documents (optional)"
                subtitle="Attach any additional documents to support this plan"
                icon={iconsComponents[1]}
                text={
                  // eslint-disable-next-line
                  <ResourceUploader
                    documentType="planning"
                    uploading={uploadingPlanning}
                  />
                }
              />
            </TabsList>
          </div>,
          <Tabs
            key="tabs"
            tabs={caseStudies.map((e) => e.name)}
            className="bmc-page-tabs--black bmc-page-tabs--inner"
          >
            {caseStudies.map((item, index) => (
              <div key={index}>
                <TabsCut
                  opened={opened}
                  setOpened={setOpened}
                  caseStudy={item}
                />

                <TabsListItem
                  title={tabs[0].title}
                  text={item.surveyPlan}
                  icon={iconsComponents[0]}
                />
              </div>
            ))}
          </Tabs>,
        ]}
      </Tabs>
      <Actions
        nextClickHandler={nextClickHandler}
        buttonName="Next"
        tooltipIfDisabled={actions.tooltipIfDisabled}
      />
    </div>
  );
};

export default Survey;
