import { capitalize } from 'lodash';

import * as Route from '../utils/serverRoutes';
import { transformResponseCollection } from '../utils/helpers';
import makeRequest from '../utils/makeRequest';

const testDocument = {
  state: {
    collection: [],
    uploadingPlanning: false,
    uploadingResult: false,
    uploading: false,
  },
  reducers: {
    fetchTestDocumentsReducer(state, { collection }) {
      return { ...state, collection };
    },
    addTestDocumentReducer(state, payload) {
      return { ...state, collection: [...state.collection, payload] };
    },
    removeTestDocumentReducer(state, { id }) {
      const collection = state.collection.filter((e) => e.id !== id);

      return { ...state, collection };
    },
    updateAttributeReducer(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    fetchTestDocuments({ bmcId, testId, documentType = '' }, state) {
      dispatch.testDocument.updateAttributeReducer({ uploading: true });

      makeRequest({
        dispatch,
        url: Route.testDocuments(
          bmcId || state.bmc.current.id,
          testId,
          `document_type=${documentType}`,
        ),
        success: (response) => {
          dispatch.testDocument.fetchTestDocumentsReducer({
            collection: transformResponseCollection(response.data.data),
          });
        },
        finish: () => {
          dispatch.testDocument.updateAttributeReducer({ uploading: false });
        },
      });
    },
    addTestDocument({ blob, testId, documentType = '' }, state) {
      const uploadingType = `uploading${capitalize(documentType)}`;
      dispatch.testDocument.updateAttributeReducer({ [uploadingType]: true });

      const formData = new FormData();
      formData.append('test_document[file]', blob);
      formData.append('test_document[document_type]', documentType);

      makeRequest({
        dispatch,
        method: 'post',
        data: formData,
        url: Route.testDocuments(state.bmc.current.id, testId),
        finish: () => {
          dispatch.testDocument.updateAttributeReducer({
            [uploadingType]: false,
          });
        },
      });
    },
    removeTestDocument({ testId, id }, state) {
      makeRequest({
        method: 'delete',
        url: Route.testDocument(state.bmc.current.id, testId, id),
        success: () => {
          dispatch.testDocument.removeTestDocumentReducer({ id });
        },
      });
    },
  }),
};

export default testDocument;
