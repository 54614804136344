import React from 'react';
import { useSelector } from 'react-redux';

import { filterCollection } from '../../main/utils/helpers';
import Card from '../../main/containers/sustainability/Card';
import {
  COLOURFUL_TEMPLATE_NAME,
  CURRENT_TEMPLATE_NAME,
} from '../../main/utils/const';
import { getCurrentCanvasIcon } from '../../main/utils/helpers/getCurrentCanvasIcon';
import SvgIcon from '../../main/components/SvgIcon';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';

const Sustainability = ({
  locales,
  locales: { portfolioWeb },
  className,
  cardClassName,
  sliderStyles,
  backgroundSection,
  sectionHeaderTitleColor,
  descriptionColor,
  styleColor,
  colorPaletteNumber,
  backgroundSliderColor,
  backgroundTable,
  backgroundCells,
  iconFill,
  iconFillTable,
  iconTableStyles,
  textSliderColor,
  backgroundIcon,
  firstSvgColor,
  secondSvgColor,
  borderCardColor,
  titleTableColor,
  textTableColor,
  borderTableColor,
  arrowColor,
  templateName,
  isFourthColorPaletteNumber = false,
}) => {
  const {
    portfolio: { sustainability },
    sustainability: { collection },
  } = useSelector((state) => state);
  const sustainabilityCollection = filterCollection(collection, 'sdg');

  const isCurrentTemplate = templateName === CURRENT_TEMPLATE_NAME;
  const isColourfulfunTemplate = templateName === COLOURFUL_TEMPLATE_NAME;

  return (
    <div
      className={`${className} section-web`}
      style={{ backgroundColor: backgroundSection }}
    >
      {isCurrentTemplate &&
        getCurrentCanvasIcon('SustainabilityCanvas', styleColor)}
      {isColourfulfunTemplate && (
        <>
          <div
            className="svg-funSustainabilityFirst"
            style={{ fill: firstSvgColor }}
          >
            <SvgIcon name="funSustainabilityFirst" />
          </div>
          <div
            className="svg-funSustainabilitySecond"
            style={{ fill: secondSvgColor }}
          >
            <SvgIcon name="funSustainabilitySecond" />
          </div>
        </>
      )}
      <div className="content">
        <SectionHeaderWeb
          templateName={templateName}
          title={portfolioWeb.sustainability}
          description={sustainability.description}
          className={isCurrentTemplate && 'violet-title'}
          style={{ color: sectionHeaderTitleColor }}
          descriptionColor={descriptionColor}
        />

        <Card
          locales={locales}
          collection={sustainabilityCollection}
          className={`${cardClassName} custom-font-body`}
          sliderStyles={sliderStyles}
          colorPaletteNumber={colorPaletteNumber}
          backgroundSliderColor={backgroundSliderColor}
          iconFill={iconFill}
          iconFillTable={iconFillTable}
          iconTableStyles={iconTableStyles}
          textSliderColor={textSliderColor}
          backgroundIcon={backgroundIcon}
          borderCardColor={borderCardColor}
          templateName={templateName}
          backgroundTable={backgroundTable}
          backgroundCells={backgroundCells}
          textTableColor={textTableColor}
          arrowColor={arrowColor}
          titleTableColor={titleTableColor}
          borderTableColor={borderTableColor}
          isFourthColorPaletteNumber={isFourthColorPaletteNumber}
        />
      </div>
    </div>
  );
};

export default Sustainability;
