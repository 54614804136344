import TopImgItem from '../assets/colourCustomerTopBg.png';
import BottomImgBg from '../assets/valuePropBottomBg.png';
import SumLeftBg from '../assets/sumLeftRight.png';
import SumRightBg from '../assets/sumRightBg.png';
import TestLeftBg from '../assets/testingLeftBottomBg.png';
import TestRightBg from '../assets/testingRightTopBg.png';
import OrangeLeftBg from '../assets/orangeLeftBottomBg.png';
import BlueRightBg from '../assets/blueRightTopBg.png';
import TeamRightBg from '../assets/teamRigthBg.png';
import { COLOURFUL_TEMPLATE_NAME } from '../../../../../utils/const';
// eslint-disable-next-line import/no-cycle
import { getClearColorVar, getTemplateFonts } from './index';
import { getContrastYIQ } from '../helpers';

export const buildColourfunOptions = (
  colors,
  fonts,
  templateSettings,
  templateData,
  isCustomPalette,
) => {
  const templateColors = getClearColorVar(templateSettings.colorPalette);

  const { titleFont, titleColor, bodyFont, bodyColor } = getTemplateFonts(
    templateSettings,
    templateData,
  );

  const blackPageTitleColor =
    isCustomPalette && !templateSettings.customTitleColor
      ? getContrastYIQ(`#${templateColors[0]}`)
      : false;
  const blackPageBodyColor =
    isCustomPalette && !templateSettings.customBodyColor
      ? getContrastYIQ(`#${templateColors[0]}`)
      : false;

  return {
    titleFont,
    bodyFont,
    titleColor: titleColor || colors.BLACK,
    bodyColor: bodyColor || colors.BLACK,
    fontColor: colors.BLACK,
    backgroundLight: colors.FUN_LIGHT,
    backgroundDark: templateColors[2],
    noImgBg: templateColors[0],
    templateName: COLOURFUL_TEMPLATE_NAME,
    mainPage: {
      mainImgType: 'round',
      imgBg: templateColors[2],
      imgWidth: 320,
      imgHeight: 320,
      titleBg: templateColors[0],
      isColourfun: true,
      logoImgWidth: 170,
      logoImgHeight: 200,
      logoBg: templateColors[3],
    },
    summary: {
      titleSize: 36,
      leftImgColor: templateColors[2],
      leftImgBg: SumLeftBg, // color 3 from palette
      rightImgBg: SumRightBg, // color 1 from palette
      rightImgColor: templateColors[0],
    },
    team: {
      rightImgBg: TeamRightBg,
      noImgBg: templateColors[0],
      noImgType: 'color_circle',
      imgType: 'color_circle',
      colorList: templateColors,
      teamMateW: 117,
      teamMateH: 106,
    },
    testing: {
      pageBg: templateColors[0],
      rightImgColor: templateColors[1],
      rightImgBg: TestRightBg, // color 2 from palette
      leftImgBg: TestLeftBg, // color 1 from palette
      leftImgColor: templateColors[0],
    },
    desirability: {
      backgroundDark: templateColors[0],
      titleFont: fonts.NOTICIA,
      bodyFont: fonts.OPENSANS,
      color: colors.BLACK,
      titleBold: false,
      titleColor: blackPageTitleColor,
      bodyColor: blackPageBodyColor,
    },
    segment: {
      topImgBg: TopImgItem,
      topImgColor: templateColors[2],
      noImgType: 'round',
      noImgBg: templateColors[0],
      imgType: 'round',
    },
    valueProposition: {
      bottomImgBg: BottomImgBg,
      bottomImgColor: templateColors[1],
      pageBg: templateColors[0],
      noImgType: 'round',
      noImgBg: templateColors[2],
      imgType: 'round',
    },
    analysis: {
      tableBorderColor: colors.FUN_BLUE,
      tableFont: fonts.OPENSANS,
      tableBg: colors.FUN_BLUE,
      noImgBg: templateColors[0],
      noImgType: 'circle',
      imgType: 'circle',
    },
    relationships: {
      pageBg: templateColors[0],
      activeItemBorder: colors.BLACK,
      iconDarkColor: templateColors[2],
      iconLightColor: templateColors[3],
      activeItemColor: { color: colors.BLACK, bold: false },
      inactiveItemColor: { color: colors.FUN_BLUE, bold: false },
    },
    channels: {
      topImgBg: TopImgItem,
      topImgColor: templateColors[2],
      noImgType: 'round',
      noImgBg: templateColors[0],
      imgType: 'round',
    },
    feasibility: {
      backgroundDark: templateColors[0],
      titleFont: fonts.NOTICIA,
      bodyFont: fonts.OPENSANS,
      color: colors.BLACK,
      titleBold: false,
      titleColor: blackPageTitleColor,
      bodyColor: blackPageBodyColor,
    },
    keyActivity: {},
    keyResources: {
      lineColor: colors.FUN_GREEN,
      leftImgBg: OrangeLeftBg,
      rightImgBg: BlueRightBg,
      iconBg: templateColors[0],
    },
    viability: {
      backgroundDark: templateColors[0],
      titleFont: fonts.NOTICIA,
      bodyFont: fonts.OPENSANS,
      color: colors.BLACK,
      titleBold: false,
      titleColor: blackPageTitleColor,
      bodyColor: blackPageBodyColor,
    },
    finance: {
      pageBg: colors.WHITE,
      borderColor: colors.FUN_ORANGE_LIGHT,
      descriptionFont: fonts.NUNITO,
      leftImgBg: OrangeLeftBg,
      rightImgBg: BlueRightBg,
    },
    economics: {
      borderColor: colors.FUN_ORANGE_LIGHT,
    },
    contact: {
      pageBg: templateColors[2],
      color: colors.BLACK,
      titleColor: colors.BLACK,
      borderColor: colors.FUN_DARK,
    },
    sustainability: {
      tablePageBg: colors.WHITE,
      listCircleWidth: 169,
      listCircleHeight: 169,
      listCircleColor: colors.FUN_LIGHT,
      iconListColor: templateColors[2],
      iconTableColor: templateColors[2],
      circleTableColor: colors.FUN_LIGHT,
    },
  };
};
