import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from '../../main/components/Dropzone';

function DropzoneWithState({
  locales,
  disabled = false,
  onChange = () => {},
  initUrl = null,
  coords = { x: 0, y: 0 },
}) {
  const handleDrop = ([file]) => {
    onChange(file);
  };

  return (
    <div className="dropzone-size dropzone-size__with-state">
      <Dropzone
        image={initUrl}
        onDrop={handleDrop}
        coords={coords}
        locales={locales}
        disabled={disabled}
      />
    </div>
  );
}
DropzoneWithState.propTypes = {
  locales: PropTypes.object,
  onChange: PropTypes.func,
  initUrl: PropTypes.string,
};
export default DropzoneWithState;
