import * as Route from '../utils/serverRoutes';
import makeRequest from '../utils/makeRequest';
import { transformResponseCollection } from '../utils/helpers';

const message = {
  state: { collection: [] },
  reducers: {
    fetchMessagesReducer: (state, collection) => {
      return { ...state, collection };
    },
  },
  effects: (dispatch) => ({
    fetchMessages() {
      return makeRequest({
        dispatch,
        url: Route.messages(),
        success: (response) => {
          dispatch.message.fetchMessagesReducer(
            transformResponseCollection(response.data.data),
          );
        },
      });
    },
  }),
};

export default message;
