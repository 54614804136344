import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

const Input = ({ onChange, value, delay = false, validate, ...props }) => {
  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    if (value !== innerValue) setInnerValue(value);
  }, [value]);

  const debouncedOnChange = useCallback(debounce(onChange, delay), [
    onChange,
    delay,
  ]);

  const onInputChange = (event) => {
    if (validate(event)) {
      setInnerValue(event.target.value);

      if (delay) {
        event.persist();
        debouncedOnChange(event);
      } else {
        onChange(event);
      }
    }
  };

  return (
    <input
      {...props}
      onChange={onInputChange}
      value={innerValue}
      data-hj-allow
    />
  );
};

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  delay: PropTypes.number,
};

export default Input;
