import React, { useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import Avatar from '../../../components/Avatar';
import { useFetch } from '../../../../main/utils/makeRequest';
import * as Route from '../../../support/serverRoutes';
import Spinner from '../../../../main/components/Spinner';
import ModalBmcTeamMembers from '../../ModalBmcTeamMembers';
import IconButton from '../../../components/IconButton';
import icons from '../../../utils/icons';

function BlockSection() {
  const [visibleModalBmcTeamMembers, setVisibleModalBmcTeamMembers] =
    useState(false);
  const { bmcId = null } = useParams();
  const [teamMembersData, teamMembersLoading] = useFetch({
    url: Route.teamMembers(bmcId),
  });
  const [blocksData, blocksLoading] = useFetch({
    url: Route.blocks({ by: 'bmcs', byId: bmcId, pageName: 'project' }),
  });
  const userList = teamMembersData?.slice(0, 3);
  const countAdditionalListOfUsers = teamMembersData?.length - 3;

  const handleOpenModalBmcTeamMembers = () =>
    setVisibleModalBmcTeamMembers(true);
  const handleCloseModalBmcTeamMembers = () =>
    setVisibleModalBmcTeamMembers(false);

  return (
    <>
      <ModalBmcTeamMembers
        visible={visibleModalBmcTeamMembers}
        onClose={handleCloseModalBmcTeamMembers}
        users={teamMembersData || []}
        loading={teamMembersLoading}
      />
      <div className="admin-project-block">
        <div className="item">
          <IconButton
            className="admin-project-block__btn-unfold"
            icon={icons.unfold}
            onClick={handleOpenModalBmcTeamMembers}
          />
          <div className="header">Team members</div>
          <div className="user-list">
            {teamMembersLoading && <Spinner />}
            {userList?.map((item) => {
              return (
                <div className="user-list-row" key={item.id}>
                  <div>
                    <Avatar
                      alt={item.attributes.email}
                      fullName={item.attributes.name}
                      size="sm"
                    />
                  </div>
                  <div className="user-list-name">
                    {item.attributes.firstName}
                  </div>
                  <div className="user-list-minute">
                    {item.attributes.siteLastTotalUsage}{' '}
                    <span className="unit">min</span>
                  </div>
                </div>
              );
            })}
            {countAdditionalListOfUsers > 0 && (
              <div className="user-list-row" key="p">
                <div>
                  <Avatar
                    alt="others"
                    fullName={`${countAdditionalListOfUsers}+ `}
                    size="sm"
                  />
                </div>
                <div className="user-list-name">
                  {countAdditionalListOfUsers}+ users...
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="item">
          <div className="header">Total Progress</div>
          {blocksLoading ? (
            <Spinner />
          ) : (
            <div className="collection">
              <div className="collection--item">
                <div className="text-primary">{blocksData?.ideasCount}</div>
                <div className="title">Ideas Created</div>
              </div>
              <div className="collection--item">
                <div className="text-primary">{blocksData?.canvasProgress}</div>
                <div className="title">Canvas Progress</div>
              </div>
              <div className="collection--item">
                <div className="text-primary">{blocksData?.testsCompleted}</div>
                <div className="title">Tests Completed</div>
              </div>
            </div>
          )}
        </div>
        <div className="item">
          <div className="header">Activity</div>
          {blocksLoading ? (
            <Spinner />
          ) : (
            <div className="collection">
              <div className="collection--item">
                <div className="text-primary">
                  {blocksData?.totalSiteTimeTracks}
                </div>
                <div className="title">Total Minutes</div>
              </div>
              <div className="collection--item">
                <div className="text-primary">
                  {blocksData?.totalSiteLastTotalTracks}
                </div>
                <div className="title">Minutes Last 30 Days</div>
              </div>
            </div>
          )}
        </div>
        <div className="item">
          <div className="header">Last Update</div>
          {blocksLoading && <Spinner />}
          {blocksData?.bmcLastUpdatedDate ? (
            <div className="collection">
              <div className="collection--item mb-10">
                <div className="text">
                  {moment(blocksData.bmcLastUpdatedDate.updatedAt).format(
                    'HH:mm A',
                  )}
                </div>
                <div className="text mb-10">
                  {moment(blocksData.bmcLastUpdatedDate.updatedAt).format(
                    'DD MMM YYYY',
                  )}
                </div>
                <div className="title">
                  By {blocksData.bmcLastUpdatedDate.user.firstName}{' '}
                  {blocksData.bmcLastUpdatedDate.user.surname}
                </div>
              </div>
            </div>
          ) : (
            <div className="collection">
              <div className="text">-</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default BlockSection;
