import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SectionHeader from './SectionHeader';
import KeyActivity from '../keyActivity/Card';
import KeyResources from '../keyResources/Card';
import Textarea from '../../components/Textarea';

const KeyActivities = ({
  locales,
  locales: {
    keyActivity: {
      intro: { card },
    },
    keyResources: {
      intro: { card: resourceCard },
    },
    keyPartners: {
      intro: { card: partnerCard },
    },
    portfolio,
  },
  data: { description, showStatus },
  disabled = false,
}) => {
  const { collection } = useSelector((state) => state.keyActivity);
  const { collection: partnerCollection } = useSelector(
    (state) => state.keyPartner,
  );
  const { collection: resourcesCollection } = useSelector(
    (state) => state.keyResource,
  );
  const {
    portfolio: { updateSectionField, updateSectionFieldReducer },
  } = useDispatch();

  const setShowStatus = (status) => {
    updateSectionFieldReducer({
      sectionName: 'keyActivities',
      field: 'showStatus',
      value: status,
    });
  };

  return (
    <div className="key-activities">
      <SectionHeader
        locales={portfolio}
        showStatus={showStatus}
        sectionName={portfolio.keyActivities.sectionName}
        setShowStatus={setShowStatus}
        mainColor="orange"
      />
      {showStatus && (
        <>
          <Textarea
            name="textarea"
            rows={5}
            delay={700}
            value={description || ''}
            onChange={(e) =>
              updateSectionField({
                sectionName: 'keyActivities',
                field: 'description',
                name: 'operationsText',
                value: e.target.value,
              })
            }
            className="bmc-form-textarea textarea"
            placeholder={portfolio.page.descriptionPlaceholder}
            disabled={disabled}
          />
          <KeyActivity
            locales={locales}
            className="key-activity bmc-page-keycard--portfolio-edit"
            card={{ ...card, steps: collection, title: null }}
            wBlur={false}
          />
          <div className="key-resources-wrapper">
            <KeyResources
              locales={locales}
              className="key-resource"
              card={{ ...partnerCard, list: partnerCollection, title: null }}
              wBlur={false}
            />
            <KeyResources
              locales={locales}
              className="key-resource"
              card={{ ...resourceCard, list: resourcesCollection, title: null }}
              wBlur={false}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default KeyActivities;
