import React from 'react';
import Textarea from '../../../../components/Textarea';

const AssumptionBlock = (props) => {
  const { isEditable, handleChange, text, textStyle, textColor } = props;

  return (
    <div className="left">
      <div className="block">
        <div
          className={`title ${textStyle} custom-font-body`}
          style={{ color: textColor }}
        >
          Assumption
        </div>
        <div className="content is-full-width">
          {isEditable ? (
            <Textarea
              onChange={handleChange}
              name="assumption.text"
              rows={4}
              delay={700}
              value={text}
              className={`bmc-form-textarea ${textStyle} custom-font-body`}
            />
          ) : (
            <div
              className={`text ${textStyle} custom-font-body`}
              style={{ color: textColor }}
            >
              {text}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssumptionBlock;
