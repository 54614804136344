import React from 'react';
import classNames from 'classnames';

export default function Text({ align, className, children }) {
  return (
    <div
      className={classNames('bmc-page-text', className, {
        'bmc-page-text--center': align === 'center',
      })}
    >
      {children.map((item, index) => (
        <p key={index}>{item}</p>
      ))}
    </div>
  );
}
