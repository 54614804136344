import * as Route from '../utils/serverRoutes';
import {
  transformResponseCollection,
  updateCollectionById,
  payloadToData,
} from '../utils/helpers';
import makeRequest from '../utils/makeRequest';

const sustainability = {
  state: {
    collection: [],
    uploading: false,
  },
  reducers: {
    fetchSustainabilitiesReducer(state, { collection }) {
      return { ...state, collection };
    },
    addSustainabilityReducer(state, payload) {
      return { ...state, collection: [...state.collection, payload] };
    },
    updateSustainabilityReducer(state, payload) {
      return {
        ...state,
        collection: updateCollectionById(state.collection, payload.id, payload),
      };
    },
    removeSustainabilityReducer(state, { id }) {
      const collection = state.collection.filter((e) => e.id !== id);

      return { ...state, collection };
    },
    updateAttributeReducer(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    async fetchSustainabilities(bmcId) {
      dispatch.sustainability.updateAttributeReducer({ uploading: true });

      makeRequest({
        dispatch,
        url: Route.sustainabilities(bmcId),
        success: (response) => {
          dispatch.sustainability.fetchSustainabilitiesReducer({
            collection: transformResponseCollection(response.data.data),
          });
        },
        finish: () => {
          dispatch.sustainability.updateAttributeReducer({ uploading: false });
        },
      });
    },
    async addSustainability(_, state) {
      makeRequest({
        dispatch,
        method: 'post',
        url: Route.sustainabilities(state.bmc.current.id),
      });
    },
    async updateSustainability(payload, state) {
      const data = payloadToData(payload);
      dispatch.sustainability.updateSustainabilityReducer(data);
      makeRequest({
        dispatch,
        method: 'put',
        url: Route.sustainability(state.bmc.current.id, payload.id),
        data: { sustainability: data },
      });
    },
    async removeSustainability(payload, state) {
      makeRequest({
        dispatch,
        method: 'delete',
        url: Route.sustainability(state.bmc.current.id, payload.id),
      });
    },
  }),
};

export default sustainability;
