import React from 'react';

const PDF = ({ locales, slug, webLink }) => {
  const link = `${window.location.origin}/portfolio/${slug}/${webLink}.pdf`;

  return (
    <div className="pdf">
      <div className="title">{locales.pdfFile.description}</div>
      <div className="bottom">
        <div className="text-wrapper">
          <div className="text">{link}</div>
        </div>
        <a
          className="bmc-button bmc-button--primary"
          href={link}
          target="_blank"
        >
          {locales.download}
        </a>
      </div>
    </div>
  );
};

export default PDF;
