import React from 'react';
import Spinner from '../Spinner';

const dropzoneAreaInnerCurrent = (
  common,
  small,
  dropzone,
  uploading,
  aspectRatio,
  disabled,
) => {
  const { getRootProps, getInputProps } = dropzone;
  const paddingTop = `${(1 / aspectRatio) * 100}%`;

  const rootProps = disabled
    ? { ...getRootProps(), onClick: () => {} }
    : getRootProps();

  const inputProps = disabled
    ? { ...getInputProps(), onClick: () => {}, onChange: () => {} }
    : getInputProps();

  return (
    <div className="dndzone__inner-wrapper" style={{ paddingTop }}>
      <div className="dndzone__inner-current">
        {uploading ? (
          <Spinner />
        ) : (
          <div className="dndzone-button" {...rootProps}>
            <input {...inputProps} />

            <i className="icon-Add" />
          </div>
        )}
      </div>
    </div>
  );
};

export default dropzoneAreaInnerCurrent;
