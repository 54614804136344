import React from 'react';

import Input from './Input';
import { NUMBER_REGEX } from '../utils/const';

const NumberInput = ({ value, ...rest }) => {
  const validate = (e) => {
    if (e.target.value === '' || NUMBER_REGEX.test(e.target.value)) {
      return true;
    }
    return false;
  };

  return <Input value={value} validate={validate} {...rest} />;
};

export default NumberInput;
