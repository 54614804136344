import React from 'react';
import { useDispatch } from 'react-redux';

import BmcCanvas from '../../bmc/BmcCanvas';
import { COMMENT_BMC, TECH_MODERN_TEMPLATE_NAME } from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';

const BMCTech = ({
  locales,
  data: { description },
  titleColor,
  backgroundCanvas,
  iconFill,
  backgroundIcon,
  backgroundItems,
  backgroundSection,
  background,
  disabled = false,
}) => {
  const { portfolio } = locales;
  const {
    portfolio: { updateSectionField },
  } = useDispatch();

  return (
    <div
      className="bm-tech-canvas section relative"
      style={{ backgroundColor: background }}
    >
      <PortfolioCommentIcon title={COMMENT_BMC} />
      <SectionHeader
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolio.bmc.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'bmc',
            field: 'description',
            name: 'bmcText',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        style={{ color: titleColor }}
      />

      <BmcCanvas
        locales={locales}
        className="bmc-canvas--scrollable-portfolio-edit"
        isPreview={disabled}
        isProgressBar={false}
        templateName={TECH_MODERN_TEMPLATE_NAME}
        backgroundCanvas={backgroundCanvas}
        iconFill={iconFill}
        backgroundIcon={backgroundIcon}
        backgroundItems={backgroundItems}
        backgroundSection={backgroundSection}
        sectionClassName="bmc-canvas-section--tech"
      />
    </div>
  );
};

export default BMCTech;
