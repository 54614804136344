import React from 'react';
import ChatGroupListComponent from './chat-group-list-component';

export default function ChatGroupListContainer({ children }) {
  return (
    <div className="chat-group-list-container">
      <ChatGroupListComponent>{children}</ChatGroupListComponent>
    </div>
  );
}
