import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../main/components/ConfirmationModal';
import Avatar from './Avatar';

const EstablishmentList = ({
  establishments = [],
  onDelete,
  getAvatarUrl = () => {},
}) => {
  return (
    <div className="user-list">
      {establishments.map((establishment, index) => (
        <Row
          gutter={[10, 24]}
          justify="space-between"
          align="middle"
          className="user-list-row"
          key={index}
        >
          <Col span={2}>
            <Avatar
              alt={establishment.name}
              fullName={establishment.name}
              url={getAvatarUrl(establishment)}
            />
          </Col>
          <Col span={16}>{establishment.name}</Col>
          <Col span={4}>
            <ConfirmationModal
              body="Are you sure?"
              confirmText="Delete"
              onConfirm={() => onDelete(establishment.id)}
            >
              <div className="user-list-action-delete">Delete</div>
            </ConfirmationModal>
          </Col>
        </Row>
      ))}
    </div>
  );
};

EstablishmentList.propTypes = {
  establishments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  // eslint-disable-next-line react/no-unused-prop-types
  avatarVisible: PropTypes.bool,
  getAvatarUrl: PropTypes.func, // (establishment, index) => return avatar imageUrl
  onDelete: PropTypes.func,
};
export default EstablishmentList;
