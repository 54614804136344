import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TestRow from '../../testing/Components/TestRow';
import Modal from '../../testing/Components/Modal';
import { COLOURFUL_TEMPLATE_NAME, COMMENT_TESTING } from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import SvgIcon from '../../../components/SvgIcon';

const Testing = ({
  data: { description },
  locales,
  locales: {
    portfolio,
    testing: { titles },
  },
  className,
  testListStyle,
  sectionHeaderStyle,
  sectionHeaderTitleColor,
  descriptionColor,
  inputColor,
  rateColor,
  titleColor,
  backgroundButton,
  linkColor,
  firstSvgColor,
  secondSvgColor,
  backgroundSection,
  backgroundCard,
  textColor,
  textCardStyle,
  titleCardStyle,
  buttonCardStyle,
  iconFillClose,
  noTestsColor,
  borderCardColor,
  buttonCardColor,
  backgroundRow,
  testTypeColor,
  templateName,
  disabled = false,
}) => {
  const {
    bmc: {
      current: { id: bmcId },
    },
    testing: { collection: tests },
  } = useSelector((state) => state);

  const {
    portfolio: { updateSectionField },
    testing: { fetchTests, setTestReducer },
  } = useDispatch();

  const [modal, setModal] = useState({
    status: false,
    test: null,
  });

  useEffect(() => {
    if (bmcId) fetchTests(bmcId);
  }, [bmcId, modal.status]);

  const onClick = (test) => {
    setModal({
      status: true,
      test,
    });
    setTestReducer(test);
  };
  const isColourfulfunTemplate = templateName === COLOURFUL_TEMPLATE_NAME;
  return (
    <div className={`${className} relative`} style={backgroundSection}>
      <PortfolioCommentIcon title={COMMENT_TESTING} />
      <Modal
        locales={locales}
        isOpen={modal.status}
        isEditable={!disabled}
        titles={titles}
        setModalStatus={setModal}
        backgroundCard={backgroundCard}
        inputColor={inputColor}
        rateColor={rateColor}
        titleColor={titleColor}
        backgroundButton={backgroundButton}
        linkColor={linkColor}
        textColor={textColor}
        textStyle={textCardStyle}
        firstSvgColor={firstSvgColor}
        secondSvgColor={secondSvgColor}
        titleStyle={titleCardStyle}
        buttonStyle={buttonCardStyle}
        iconFillClose={iconFillClose}
        buttonColor={buttonCardColor}
        isColourfulfunTemplate={isColourfulfunTemplate}
        disabled={disabled}
      />
      {isColourfulfunTemplate && (
        <>
          <div className="svg-funTestingEight" style={{ fill: firstSvgColor }}>
            <SvgIcon name="funTestingEighth" />
          </div>
          <div className="svg-funTestingNine" style={{ fill: secondSvgColor }}>
            <SvgIcon name="funTestingNineth" />
          </div>
        </>
      )}
      <div className="content">
        <div className={sectionHeaderStyle}>
          <SectionHeader
            templateName={templateName}
            title={portfolio.testing.sectionName}
            description={description}
            onChange={(e) =>
              updateSectionField({
                sectionName: 'testing',
                field: 'description',
                name: 'testingText',
                value: e.target.value,
              })
            }
            portfolio={portfolio}
            style={{ color: sectionHeaderTitleColor }}
            descriptionColor={descriptionColor}
            disabled={disabled}
          />
        </div>

        <div className={testListStyle}>
          {!tests.length && (
            <div
              className="empty-text custom-font-body"
              style={{ color: noTestsColor }}
            >
              No Tests
            </div>
          )}
          {tests.map((testItem) => (
            <TestRow
              titles={titles}
              test={testItem}
              key={testItem.id}
              onClick={() => onClick(testItem)}
              isPreview={disabled}
              rateColor={rateColor}
              backgroundRow={backgroundRow}
              textColor={textColor}
              testTypeColor={testTypeColor}
              borderCardColor={borderCardColor}
              isColourfulfunTemplate={isColourfulfunTemplate}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testing;
