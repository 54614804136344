import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Space } from 'antd';
import { union } from 'lodash';
// import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CellFullName } from '../../components/UserList';
import SelectUser from '../Selects/SelectUser';
import SelectEstablishment from '../Selects/SelectEstablishment';

function StepOne({ onCancel }) {
  const [lecturerOptions, setLecturerOptions] = useState([]);
  const [selectedLecturerIds, setSelectedLecturerId] = useState([]);
  const [establishmentId, setEstablishmentId] = useState(null);
  const [errorLecturerIds, setErrorLecturerIds] = useState('success');
  const { establishmentId: establishmentIdFromParams, userId } = useParams();

  const {
    lecturers: { collection, loading },
    classroom: { uploading },
    user,
    activeUser,
  } = useSelector((state) => state);

  const {
    lecturers: { fetchLecturers },
    classroom: { addClassroom },
    classrooms: { reload },
  } = useDispatch();

  const handleSelect = (id) => {
    setSelectedLecturerId((ids) => union(ids, [id]));
  };

  const handleDelete = (id) => {
    setSelectedLecturerId((ids) => ids.filter((oldId) => oldId !== id));
  };

  useEffect(() => {
    if (collection) {
      let lecturerOptionsArray = collection.map((item) => ({
        alt: item.attributes.name,
        email: item.attributes.email,
        fullName: item.attributes.name,
        id: item.attributes.id,
      }));
      if (activeUser) {
        lecturerOptionsArray = lecturerOptionsArray.filter(
          (lecturer) => lecturer.id !== activeUser?.data?.id,
        );
      }
      setLecturerOptions(lecturerOptionsArray);
    }
  }, [collection, activeUser]);

  const reloadTableClassrooms = () => reload();

  const handleChangeEstablishment = ([id]) => {
    setEstablishmentId(id);
  };

  useEffect(() => {
    if (establishmentIdFromParams) {
      handleChangeEstablishment([establishmentIdFromParams]);
    } else if (user?.current?.role !== 'super_admin') {
      handleChangeEstablishment([user?.current?.attributes.establishmentId]);
    } else if (activeUser?.data?.establishment?.id) {
      handleChangeEstablishment([activeUser?.data?.establishment?.id]);
    }
  }, [user, activeUser]);

  useEffect(() => {
    if (establishmentId)
      fetchLecturers({
        establishmentId,
      });
  }, [establishmentId]);

  return (
    <>
      <Form
        layout="vertical"
        onFinish={(value) => {
          const lecturerIds = userId
            ? union(selectedLecturerIds, [userId])
            : selectedLecturerIds;
          if (lecturerIds.length > 0) {
            setErrorLecturerIds('success');
            addClassroom({
              ...value,
              establishmentId,
              lecturerIds,
              after: [reloadTableClassrooms],
            });
          } else {
            addClassroom({
              ...value,
              establishmentId,
              after: [reloadTableClassrooms],
            });
          }
        }}
      >
        {user?.current?.role === 'super_admin' && !userId && (
          <Form.Item
            label="Select establishment"
            name="establishment_id"
            className="admin-input-label"
          >
            <SelectEstablishment onChange={handleChangeEstablishment} />
          </Form.Item>
        )}

        <Form.Item
          label="Class Name"
          className="admin-input-label"
          name="name"
          rules={[{ required: true, message: 'Please input class name!' }]}
        >
          <Input
            className="admin-input"
            placeholder="Add the name of your class"
          />
        </Form.Item>

        <SelectUser
          avatarVisible
          users={lecturerOptions}
          loading={loading}
          columns={[
            {
              dataIndex: 'email',
              render: (row) => {
                return <CellFullName user={row} />;
              },
            },
          ]}
          labelOfSelect="Invite Other Educators To Join Your Class"
          placeholderOfSelect="Add the email address they signed up to Validate with"
          labelOfList="Educators"
          selectedIds={selectedLecturerIds}
          onSelect={handleSelect}
          onDelete={handleDelete}
          validateStatus={errorLecturerIds}
          help={errorLecturerIds === 'success' ? null : 'Please select'}
        />

        <div className="flex align-right mt-40">
          <Space>
            <Button onClick={onCancel}>Cancel</Button>
            <Button loading={uploading} type="primary" htmlType="submit">
              Create
            </Button>
          </Space>
        </div>
      </Form>
    </>
  );
}

// StepOne.propTypes = {
//   nextStep: PropTypes.func,
//   data: PropTypes.object,
// };
export default StepOne;
