import React from 'react';
import classNames from 'classnames';

export default function Modal({ className, onBlackscreenClick, children }) {
  return (
    <div className={classNames('bmc-modal', className)}>
      <div className="bmc-modal-blackscreen" onClick={onBlackscreenClick}>
        <div className="bmc-modal-content" onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </div>
  );
}
