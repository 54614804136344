import React from 'react';
import { useSelector } from 'react-redux';
import { removeNewLines } from '../../main/utils/helpers';
import { renderLogo } from '../../main/utils/helpers/renderLogo';
import {
  BLOG_FRIENDLY_TEMPLATE_NAME,
  IMAGE_ASPECT_RATIO_ONE,
} from '../../main/utils/const';
import { renderImage } from '../../main/utils/helpers/renderImage';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';

const Introduction = ({
  backgroundSection,
  backgroundContent,
  isFourthColorPaletteNumber = false,
}) => {
  const { introduction } = useSelector((state) => state.portfolio);

  const textColorDependsOnBackground =
    isFourthColorPaletteNumber &&
    getColorDependsOnBackground(backgroundSection);

  return (
    <div
      className="introduction-blogFriendly-web-wrapper"
      style={{ backgroundColor: backgroundSection }}
    >
      {renderLogo(
        BLOG_FRIENDLY_TEMPLATE_NAME,
        introduction.introLogo,
        backgroundSection,
        'Logo',
        textColorDependsOnBackground,
        textColorDependsOnBackground,
      )}
      <div className="image">
        {renderImage(
          BLOG_FRIENDLY_TEMPLATE_NAME,
          introduction.introBg,
          backgroundSection,
          'Introduction',
          IMAGE_ASPECT_RATIO_ONE,
          textColorDependsOnBackground,
          textColorDependsOnBackground,
        )}
      </div>
      <div
        className="content-intro"
        style={{ backgroundColor: backgroundContent }}
      >
        <div
          className="blogFriendly-title title custom-font-title"
          style={{ color: textColorDependsOnBackground }}
        >
          {introduction.title}
        </div>
        <div
          className="description custom-font-body"
          style={{ color: textColorDependsOnBackground }}
        >
          {introduction.description && removeNewLines(introduction.description)}
        </div>
      </div>
    </div>
  );
};

export default Introduction;
