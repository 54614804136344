// eslint-disable-next-line import/no-cycle
import { getClearColorVar, getTemplateFonts } from './index';
import { getContrastYIQ } from '../helpers';

export const buildFriendlyOptions = (
  colors,
  fonts,
  templateSettings,
  templateData,
  isCustomPalette,
) => {
  const [pageBgColor, lightBgColor, middleBgColor] = getClearColorVar(
    templateSettings.colorPalette,
  );

  const { titleFont, titleColor, bodyFont, bodyColor } = getTemplateFonts(
    templateSettings,
    templateData,
  );

  const blackPageTitleColor =
    isCustomPalette && !templateSettings.customTitleColor
      ? getContrastYIQ(`#${middleBgColor}`)
      : false;
  const blackPageBodyColor =
    isCustomPalette && !templateSettings.customBodyColor
      ? getContrastYIQ(`#${middleBgColor}`)
      : false;

  return {
    titleFont,
    bodyFont,
    titleColor: titleColor || colors.BLACK,
    bodyColor: bodyColor || colors.BLACK,
    fontColor: colors.BLACK,
    backgroundLight: lightBgColor,
    backgroundDark: middleBgColor,
    noImgBg: middleBgColor,
    mainPage: {
      mainImgType: 'circle',
      imgBg: middleBgColor,
      imgWidth: 400,
      imgHeight: 375,
      titleBg: lightBgColor,
      pageBg: middleBgColor,
      isLogo: true,
      logoPositionX: 600,
      logoPositionY: 0,
    },
    summary: {
      pageBg: lightBgColor,
      // btnBg: middleBgColor,
    },
    team: {
      pageBg: pageBgColor,
      noImgBg: middleBgColor,
      noImgType: 'circle',
      imgType: 'circle',
    },
    analysis: {
      noImgBg: middleBgColor,
      noImgType: 'circle',
      imgType: 'circle',
    },
    bmc: {
      pageBg: lightBgColor,
    },
    testing: {
      pageBg: lightBgColor,
      statusIconColor: middleBgColor,
    },
    desirability: {
      color: colors.BLACK,
      titleFont: fonts.PRATA,
      bodyFont: fonts.NUNITO,
      backgroundDark: middleBgColor,
      titleColor: blackPageTitleColor,
      bodyColor: blackPageBodyColor,
    },
    segment: {
      pageBg: lightBgColor,
      noImgType: 'circle',
      imgType: 'circle_blog',
    },
    valueProposition: {
      pageBg: colors.WHITE,
      noImgType: 'circle',
      imgType: 'vertical_round',
    },
    relationships: {
      iconDarkColor: colors.BLACK,
      iconLightColor: middleBgColor,
    },
    channels: {
      noImgType: 'circle',
      imgType: 'circle_blog',
    },
    feasibility: {
      color: colors.BLACK,
      titleFont: fonts.PRATA,
      bodyFont: fonts.NUNITO,
      backgroundDark: middleBgColor,
      titleColor: blackPageTitleColor,
      bodyColor: blackPageBodyColor,
    },
    viability: {
      color: colors.BLACK,
      titleFont: fonts.PRATA,
      bodyFont: fonts.NUNITO,
      backgroundDark: middleBgColor,
      titleColor: blackPageTitleColor,
      bodyColor: blackPageBodyColor,
    },
    keyActivity: {
      font: fonts.NUNITO,
    },
    keyResources: {
      pageBg: colors.WHITE,
      lineColor: lightBgColor,
      iconBg: middleBgColor,
    },
    finance: {
      pageBg: lightBgColor,
    },
    contact: {
      color: colors.BLACK,
      titleColor: colors.BLACK,
      pageBg: middleBgColor,
      borderColor: middleBgColor,
    },
    sustainability: {
      listCircleWidth: 169,
      listCircleHeight: 169,
      listCircleColor: colors.PRIMARY,
      tablePageBg: colors.WHITE,
      iconListColor: middleBgColor,
      iconTableColor: middleBgColor,
      circleTableColor: colors.WHITE,
    },
  };
};
