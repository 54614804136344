import React from 'react';

import Textarea from '../../../components/Textarea';
import Dropzone from '../../../components/Dropzone';
import SvgIcon from '../../../components/SvgIcon';
import {
  BUTTON_LIGHT_MODAL_STYLE_COLOURFUL,
  BUTTON_MODAL_STYLE_COLOURFUL,
  COMMENT_INTRODUCTION,
  IMAGE_ASPECT_RATIO_INTRO_COLOURFUN,
  PORTFOLIO_INTRO_LOGO_ASPECT_RATIO,
  TEXTAREA_DELAY,
  TITLE_MODAL_STYLE_COLOURFUL,
} from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';

const IntroductionColourfulFun = ({
  data,
  locales,
  locales: { portfolio },
  onLogoDrop,
  onLogoCropComplete,
  onBgDrop,
  onBgCropComplete,
  handleDescriptionSubmit,
  handleInputSubmit,
  uploadingIntrologo,
  uploadingIntrobg,
  firstSvgColor,
  secondSvgColor,
  backgroundSection,
  disabled = false,
}) => {
  const {
    title,
    description,
    introLogo,
    introLogoCoords,
    introBg,
    introBgCoords,
  } = data;

  return (
    <div className="introduction-colourfulFun-wrapper relative">
      <PortfolioCommentIcon title={COMMENT_INTRODUCTION} />
      <div className="introduction-colourfulFun-left">
        <div className="svg-funFirst" style={{ fill: firstSvgColor }}>
          <SvgIcon name="funFirst" />
        </div>
        <div className="logo-hover">
          <Dropzone
            className="logo-upload"
            onDrop={onLogoDrop}
            onCropperSave={onLogoCropComplete}
            locales={locales}
            image={introLogo}
            coords={introLogoCoords}
            aspectRatio={PORTFOLIO_INTRO_LOGO_ASPECT_RATIO}
            uploading={uploadingIntrologo}
            disabled={disabled}
            cropper
            firstSvgColor={firstSvgColor}
            secondSvgColor={secondSvgColor}
            titleStyle={TITLE_MODAL_STYLE_COLOURFUL}
            buttonStyle={BUTTON_MODAL_STYLE_COLOURFUL}
            lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_COLOURFUL}
            isTemplates
            isColourfulfunTemplate
          />
        </div>
        <div className="textarea-colourful">
          <Textarea
            onChange={handleInputSubmit}
            value={title}
            delay={TEXTAREA_DELAY}
            placeholder={portfolio.introduction.projectName}
            className="textarea-colourfulFun textarea-colourfulFun-name custom-font-title"
            disabled={disabled}
          />
        </div>
        <div className="textarea-colourful">
          <Textarea
            onChange={handleDescriptionSubmit}
            value={description}
            delay={TEXTAREA_DELAY}
            placeholder={portfolio.introduction.projectNamePlaceholder}
            rows={4}
            className="textarea-colourfulFun textarea-colourfulFun-description custom-font-body"
            disabled={disabled}
          />
        </div>
      </div>
      <div
        className="introduction-colourfulFun-right relative"
        style={{ backgroundColor: backgroundSection }}
      >
        <div className="svg-funSecond" style={{ fill: secondSvgColor }}>
          <SvgIcon name="funSecond" />
        </div>
        <div className="image-hover">
          <Dropzone
            className="image"
            onDrop={onBgDrop}
            onCropperSave={onBgCropComplete}
            locales={locales}
            image={introBg}
            coords={introBgCoords}
            aspectRatio={IMAGE_ASPECT_RATIO_INTRO_COLOURFUN}
            uploading={uploadingIntrobg}
            disabled={disabled}
            cropper
            firstSvgColor={firstSvgColor}
            secondSvgColor={secondSvgColor}
            titleStyle={TITLE_MODAL_STYLE_COLOURFUL}
            buttonStyle={BUTTON_MODAL_STYLE_COLOURFUL}
            lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_COLOURFUL}
            isTemplates
            isColourfulfunTemplate
          />
        </div>
      </div>
    </div>
  );
};

export default IntroductionColourfulFun;
