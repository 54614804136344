import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CardCurrent from '../../valueProposition/CardCurrent';
import {
  COMMENT_PROPOSITION,
  CURRENT_TEMPLATE_NAME,
} from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import { getCurrentCanvasIcon } from '../../../utils/helpers/getCurrentCanvasIcon';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';

const ValuePropositionCurrent = ({
  data: { description },
  locales,
  locales: {
    portfolio,
    valueProposition: {
      intro: { card },
    },
  },
  styleColor,
  disabled = false,
}) => {
  const {
    valueProposition: { id, product, reason, image, imageCoords },
    uniqueSellingPoint: { collection },
  } = useSelector((state) => state);

  const {
    portfolio: { updateSectionField },
  } = useDispatch();

  return (
    <div className="value-propositions-current section relative">
      <PortfolioCommentIcon title={COMMENT_PROPOSITION} />
      {getCurrentCanvasIcon('VPCanvas', styleColor)}
      <SectionHeader
        templateName={CURRENT_TEMPLATE_NAME}
        title={portfolio.valueProposition.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'valueProposition',
            field: 'description',
            name: 'vpText',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        className="green-title"
        style={{ color: styleColor }}
        disabled={disabled}
      />

      <CardCurrent
        locales={locales}
        className="value-propositions-current-card"
        dropzoneMode
        portfolioMode
        blur={false}
        disabled={disabled}
        card={{
          ...card,
          id,
          title: false,
          image,
          imageCoords,
          solution: product,
          features: reason,
          points: collection,
        }}
      />
    </div>
  );
};

export default ValuePropositionCurrent;
