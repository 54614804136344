import { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import {
  NOTIFICATION_TYPES,
  ALERT_TYPES,
  FORCE_SIGNOUT_TIMEOUT,
} from './const';
import { link } from './helpers';

const forceSignout = () => {
  setTimeout(() => {
    window.location = '/users/sign_out';
  }, FORCE_SIGNOUT_TIMEOUT);
};

const handleSuccess = (
  response,
  successHandler,
  finishHandler,
  dispatch,
  showSuccessToast,
) => {
  if (showSuccessToast) {
    dispatch.notification.addNotification({
      type: NOTIFICATION_TYPES.success,
      text: response.data.message || 'Success',
    });
  }
  if (successHandler) successHandler(response);
  if (finishHandler) finishHandler(response);
  return response;
};

const handleError = (err, errorHandler, finishHandler, dispatch) => {
  switch (err?.response?.status) {
    case 401: {
      dispatch.alert.addAlert({
        id: 401,
        type: ALERT_TYPES.warning,
        title: err.response.data.error,
        link: link('Please log in again.', '/users/sign_out'),
      });
      forceSignout();
      break;
    }
    case 403: {
      dispatch.alert.addAlert({
        id: 403,
        type: ALERT_TYPES.warning,
        title: err.response.data.error,
        link: link('Please log in again.', '/users/sign_out'),
      });
      forceSignout();
      break;
    }
    case 422: {
      dispatch.notification.addNotification({
        type: NOTIFICATION_TYPES.warning,
        text: err.response.data.errors.join(' '),
      });
      break;
    }
    default: {
      dispatch.notification.addNotification({
        type: NOTIFICATION_TYPES.warningBlue,
        text: 'Something went wrong. Please, reload the page and try again',
      });
    }
  }
  if (finishHandler) finishHandler(err);
  return errorHandler && errorHandler(err);
};

export default async function makeRequest({
  url,
  method,
  data,
  success,
  error,
  finish,
  dispatch,
  showSuccessToast = false,
}) {
  const response = await axios({ url, method, data })
    .then((res) =>
      handleSuccess(res, success, finish, dispatch, showSuccessToast),
    )
    .catch((err) => handleError(err, error, finish, dispatch));

  return {
    json: response && response.data,
    status: response && response.status,
    response,
  };
}

export async function uploadFile({
  url,
  method = 'put',
  modelName,
  name,
  blob,
  success,
  error,
  finish,
  dispatch,
}) {
  const formData = new FormData();
  const fieldName = `${modelName}[${name}]`;
  formData.append(fieldName, blob);

  const response = await axios({
    url,
    method,
    data: formData,
  })
    .then((res) => handleSuccess(res, success, finish, dispatch))
    .catch((err) => handleError(err, error, finish, dispatch));

  return {
    json: response && response.data,
    status: response && response.status,
    response,
  };
}

export function useFetch({ url }) {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const success = (response) => {
    setData(response.data.data || response.data);
  };

  const startFetch = () => {
    setLoading(true);
  };

  const finish = () => {
    setLoading(false);
  };

  const error = () => {};

  useEffect(() => {
    startFetch();
    axios({ url, data })
      .then((res) => handleSuccess(res, success, finish))
      .catch((err) => handleError(err, error, finish, dispatch));
  }, [url]);

  return [data, loading];
}

export function downloadFileHook() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [downloadParams, setDownloadParams] = useState(undefined);

  const startFetch = () => {
    setLoading(true);
  };

  const finish = () => {
    setLoading(false);
  };

  const error = () => {};

  const startDownloading = ({ href, fileName }) => {
    setDownloadParams({ href, fileName });
  };

  useEffect(() => {
    if (downloadParams && !loading) {
      startFetch();
      axios({
        url: downloadParams.href,
        method: 'GET',
        responseType: 'blob',
      })
        .then((response) => {
          const hrefBlob = URL.createObjectURL(response.data);

          const linkElement = document.createElement('a');
          linkElement.href = hrefBlob;
          linkElement.setAttribute('download', downloadParams.fileName);
          document.body.appendChild(linkElement);
          linkElement.click();

          document.body.removeChild(linkElement);
          URL.revokeObjectURL(hrefBlob);
        })
        .catch((err) => handleError(err, error, finish, dispatch))
        .finally(finish);
    }
  }, [downloadParams]);

  return [startDownloading, loading];
}
