import React from 'react';

import Textarea from '../../../components/Textarea';
import Dropzone from '../../../components/Dropzone';
import {
  PORTFOLIO_INTRO_LOGO_ASPECT_RATIO,
  TEXTAREA_DELAY,
  PORTFOLIO_INTRO_BG_ASPECT_RATIO,
  COMMENT_INTRODUCTION,
  TITLE_MODAL_STYLE_ARCH,
  BUTTON_MODAL_STYLE_ARCH,
  BUTTON_LIGHT_MODAL_STYLE_ARCH,
  COLORS,
} from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import { getColorDependsOnBackgroundAndPalette } from '../../../utils/helpers/portfolioArchTemplate/getColorDependsOnBackgroundAndPalette';

const { black } = COLORS;

const IntroductionArch = ({
  data,
  locales,
  locales: { portfolio },
  onLogoDrop,
  onLogoCropComplete,
  onBgDrop,
  onBgCropComplete,
  handleDescriptionSubmit,
  handleInputSubmit,
  uploadingIntrologo,
  uploadingIntrobg,
  backgroundSection,
  backgroundImage,
  colorPaletteNumber,
  backgroundModal,
  disabled = false,
}) => {
  const {
    title,
    description,
    introLogo,
    introLogoCoords,
    introBg,
    introBgCoords,
  } = data;

  const textColor = getColorDependsOnBackgroundAndPalette(
    colorPaletteNumber,
    backgroundSection,
  );

  return (
    <div
      className="introduction-architectural-wrapper"
      style={{ backgroundColor: backgroundSection }}
    >
      <div className="image-hover">
        <Dropzone
          className="image"
          background={{ backgroundColor: backgroundImage }}
          onDrop={onBgDrop}
          onCropperSave={onBgCropComplete}
          locales={locales}
          image={introBg}
          coords={introBgCoords}
          aspectRatio={PORTFOLIO_INTRO_BG_ASPECT_RATIO}
          uploading={uploadingIntrobg}
          disabled={disabled}
          titleStyle={TITLE_MODAL_STYLE_ARCH}
          buttonStyle={BUTTON_MODAL_STYLE_ARCH}
          lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_ARCH}
          sliderColor={black}
          backgroundModal={backgroundModal}
          isTemplates
          cropper
        />
      </div>

      <div className="logo-hover">
        <Dropzone
          className="logo-upload"
          onDrop={onLogoDrop}
          onCropperSave={onLogoCropComplete}
          locales={locales}
          image={introLogo}
          coords={introLogoCoords}
          aspectRatio={PORTFOLIO_INTRO_LOGO_ASPECT_RATIO}
          uploading={uploadingIntrologo}
          disabled={disabled}
          titleStyle={TITLE_MODAL_STYLE_ARCH}
          buttonStyle={BUTTON_MODAL_STYLE_ARCH}
          lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_ARCH}
          sliderColor={black}
          backgroundModal={backgroundModal}
          isTemplates
          cropper
        />
      </div>
      <div className="content-intro relative">
        <PortfolioCommentIcon title={COMMENT_INTRODUCTION} />
        <div className="textarea-arch title">
          <Textarea
            onChange={handleInputSubmit}
            value={title}
            delay={TEXTAREA_DELAY}
            placeholder={portfolio.introduction.projectName}
            className="textarea-architectural textarea-architectural-name architectural-title custom-font-title"
            style={{ color: textColor }}
            disabled={disabled}
          />
        </div>
        <div className="textarea-arch description">
          <Textarea
            onChange={handleDescriptionSubmit}
            value={description}
            delay={TEXTAREA_DELAY}
            placeholder={portfolio.introduction.projectNamePlaceholder}
            rows={4}
            className="textarea-architectural textarea-architectural-description custom-font-body"
            style={{ color: textColor }}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default IntroductionArch;
