import React from 'react';

import Header from '../../components/Header';
import Title from '../../components/Title';
import Text from '../../components/Text';
import Line from '../../components/Line';
import List from '../../components/List';
import Card from '../keyResources/Card';
import Actions from '../../components/Actions';
import MobileMenuHeader from '../../components/MobileMenuHeader';

export default function Intro(props) {
  const {
    nextClickHandler,
    menuItems,
    selectComponent,
    componentIndex,
    rules,
    backPath,
    locales,
    locales: {
      common,
      tipCustomerSegment,
      keyPartners: {
        name,
        intro: { header, title, text, list, card },
      },
    },
  } = props;

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon="icon-key-partners"
        locales={locales}
        componentIndex={componentIndex}
        className="bmc-page-header--orange"
        tipLocales={tipCustomerSegment}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="KeyThingCanvas" title={name} color="orange" />
        }
      />

      <Text>{[text[0]]}</Text>

      <Title text={title[0]} align="left" />

      <List items={list} />

      <Line />

      <Title text={title[1]} className="margin-top-60" />

      <Text align="center">{[text[1]]}</Text>

      <Card
        locales={locales}
        card={card}
        className="bmc-page-keycard--section margin-top-60"
      />

      <Actions nextClickHandler={nextClickHandler} buttonName={common.next} />
    </div>
  );
}
