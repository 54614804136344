import classNames from 'classnames';
import React from 'react';

const IconButton = ({ icon, className, ...props }) => {
  return (
    <button
      className={classNames('btn admin-btn primary-bordered', className)}
      {...props}
    >
      {icon}
    </button>
  );
};

export default IconButton;
