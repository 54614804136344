import React from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import { merge } from 'lodash';

import Textarea from '../../components/Textarea';
import NumberInput from '../../components/NumberInput';
import { SELECT_STYLES_MODAL } from '../../utils/const';
import Button from '../../components/Button';
import ConfirmationModal from '../../components/ConfirmationModal';
import { selectValue } from '../../utils/helpers';

export default function CardTable({
  editMode = false,
  className,
  titles,
  collection,
  collectionEditSettings,
  title,
  maxCount,
  addNewLabel,
  addHandler,
  updateHandler,
  removeHandler,
  titleStyle,
  buttonStyle,
  textStyle,
  backgroundCard,
  titleColor,
  buttonColor,
  backgroundButton,
  textColor,
}) {
  const optionStyles = {
    option: (styles) => {
      return {
        ...styles,
        fontFamily: 'inherit',
      };
    },
  };

  const buildField = (data, { name, type, options }) => {
    switch (type) {
      case 'text':
        return (
          <Textarea
            value={data[name]}
            delay={700}
            className={`bmc-form-textarea ${textStyle} custom-font-body`}
            onChange={(e) =>
              updateHandler({ id: data.id, name, value: e.target.value })
            }
          />
        );
      case 'number':
        return (
          <NumberInput
            value={data[name]}
            className={`bmc-form-textarea ${textStyle} custom-font-body`}
            onChange={(e) =>
              updateHandler({ id: data.id, name, value: e.target.value })
            }
          />
        );
      case 'select':
        return (
          <div className="custom-font-body">
            <Select
              className={`bmc-form-select ${textStyle} custom-font-body`}
              styles={merge(SELECT_STYLES_MODAL, optionStyles)}
              options={options}
              value={selectValue(data[name])}
              onChange={({ value }) =>
                updateHandler({ id: data.id, name, value })
              }
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={classNames('bmc-page-fincard-content relative', className)}
      style={{ background: backgroundCard }}
    >
      <div
        className={`bmc-page-fincard-title ${titleStyle} custom-font-title`}
        style={{ color: titleColor }}
      >
        {title}
      </div>

      <table
        className={classNames(
          'bmc-page-fincard-table custom-font-body',
          textStyle,
          {
            'bmc-page-fincard-table--show': !editMode,
          },
        )}
      >
        <thead>
          <tr>
            {titles.map((item, index) => (
              <th key={index} style={{ color: textColor }}>
                {item}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {collection.map((item) => (
            <tr key={item.id}>
              {collectionEditSettings.map((settings, i) => (
                <td key={i}>
                  <div className="bmc-page-fincard-table-title">
                    {titles[i]}
                  </div>
                  {editMode ? buildField(item, settings) : item[settings.name]}
                  {editMode &&
                    i === collectionEditSettings.length - 1 &&
                    collection.length > 1 && (
                      <ConfirmationModal
                        body="Delete this content. Are you sure?"
                        confirmText="Delete"
                        onConfirm={() => removeHandler({ id: item.id })}
                      >
                        <div className="bmc-page-fincard-table-remove">
                          <i className="icon-Delete" />
                        </div>
                      </ConfirmationModal>
                    )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {editMode && collection.length < maxCount && (
        <Button
          wide
          title={addNewLabel}
          onClick={addHandler}
          icon="icon-Add"
          className={classNames(
            'button-costs-width margin-top-5 margin-left-10 custom-font-body',
            buttonStyle ? `${buttonStyle}` : 'bmc-button--blue',
          )}
          style={{ color: buttonColor, background: backgroundButton }}
        />
      )}
    </div>
  );
}
