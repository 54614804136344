import React from 'react';
import { useSelector } from 'react-redux';

import { removeNewLines } from '../../main/utils/helpers';
import SvgIcon from '../../main/components/SvgIcon';
import { renderLogo } from '../../main/utils/helpers/renderLogo';
import {
  COLOURFUL_TEMPLATE_NAME,
  IMAGE_ASPECT_RATIO_INTRO_COLOURFUN,
} from '../../main/utils/const';
import { renderImage } from '../../main/utils/helpers/renderImage';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';

const Introduction = ({
  backgroundImage,
  firstSvgColor,
  secondSvgColor,
  background,
  isFourthColorPaletteNumber = false,
}) => {
  const { introduction } = useSelector((state) => state.portfolio);

  const titleImageColor =
    isFourthColorPaletteNumber && getColorDependsOnBackground(backgroundImage);

  return (
    <div className="introduction-colourfulFun-web-wrapper">
      <div className="introduction-colourfulFun-web-left">
        <div className="svg-funFirst" style={{ fill: firstSvgColor }}>
          <SvgIcon name="funFirst" />
        </div>
        {renderLogo(
          COLOURFUL_TEMPLATE_NAME,
          introduction.introLogo,
          backgroundImage,
          'Logo',
          titleImageColor,
        )}

        <div className="colourful-title-web title custom-font-title">
          {introduction.title}
        </div>

        <div className="description custom-font-body">
          {introduction.description && removeNewLines(introduction.description)}
        </div>
      </div>
      <div
        className="introduction-colourfulFun-web-right"
        style={{ backgroundColor: background }}
      >
        <div className="svg-funSecond" style={{ fill: secondSvgColor }}>
          <SvgIcon name="funSecond" />
        </div>
        <div className="image">
          {renderImage(
            COLOURFUL_TEMPLATE_NAME,
            introduction.introBg,
            backgroundImage,
            'Introduction',
            IMAGE_ASPECT_RATIO_INTRO_COLOURFUN,
            titleImageColor,
          )}
        </div>
      </div>
    </div>
  );
};

export default Introduction;
