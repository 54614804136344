export const bmc = (bmcId) => `/bmcs/${bmcId}`;
export const bmcList = (bmcId) => `/bmcs/${bmcId}/list`;

export const customerSegment = (bmcId, section = '') =>
  `/bmcs/${bmcId}/customer_segment/${section}`;
export const channel = (bmcId, section = '') =>
  `/bmcs/${bmcId}/channel/${section}`;
export const getKeepGrow = (bmcId, section = '') =>
  `/bmcs/${bmcId}/get_keep_grow/${section}`;
export const valueProposition = (bmcId, section = '') =>
  `/bmcs/${bmcId}/value_proposition/${section}`;
export const keyPartners = (bmcId, section = '') =>
  `/bmcs/${bmcId}/key_partners/${section}`;
export const keyResources = (bmcId, section = '') =>
  `/bmcs/${bmcId}/key_resources/${section}`;
export const keyActivity = (bmcId, section = '') =>
  `/bmcs/${bmcId}/key_activity/${section}`;
export const cost = (bmcId, section = '') => `/bmcs/${bmcId}/cost/${section}`;
export const sustainability = (bmcId, section = '') =>
  `/bmcs/${bmcId}/sustainability/${section}`;
export const revenue = (bmcId, section = '') =>
  `/bmcs/${bmcId}/revenue/${section}`;

export const ideation = (bmcId, id = null, section = '') =>
  `/bmcs/${bmcId}/ideation${id ? `/${id}/${section}` : ''}`;

export const testing = (bmcId, id = null, section = '') =>
  `/bmcs/${bmcId}/testing${id ? `/${id}/${section}` : ''}`;

export const portfolio = (bmcId) => `/bmcs/${bmcId}/portfolio`;

export const newPassword = () => `/onboarding/new_password`;
export const signIn = () => `/onboarding/sign_in`;
export const signUp = () => `/onboarding/sign_up`;
export const account = () => `/account`;
