import React from 'react';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';

const ContactUs = ({
  locales,
  data: { title, emailOrWebsite, description, fullName },
  background,
  backgroundButton,
  isFourthColorPaletteNumber = false,
}) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const textColorDependsOnBackground =
    isFourthColorPaletteNumber && getColorDependsOnBackground(background);
  return (
    <div
      className="contact-us-colourful-web"
      style={{ backgroundColor: background }}
    >
      <div className="header">
        <div className="header-first">
          <div
            className="colourful-title title custom-font-title"
            style={{
              color: textColorDependsOnBackground,
            }}
          >
            {locales.portfolioWeb.contactUs.title}
          </div>
          <div
            className="description custom-font-body"
            style={{
              color: textColorDependsOnBackground,
            }}
          >
            {description}
          </div>
        </div>
        <div className="header-first">
          <div
            className="form-field custom-font-body"
            style={{
              color: textColorDependsOnBackground,
            }}
          >
            {fullName}
          </div>
          <div
            className="form-field custom-font-body"
            style={{
              color: textColorDependsOnBackground,
            }}
          >
            {emailOrWebsite}
          </div>
        </div>
        <div
          className="scroll-top custom-font-body"
          style={{
            backgroundColor: backgroundButton,
            color:
              isFourthColorPaletteNumber &&
              getColorDependsOnBackground(backgroundButton),
          }}
          onClick={scrollToTop}
        >
          {locales.portfolioWeb.contactUs.scrollTop}
          <i className="icon-Arrow-Top" />
        </div>
      </div>

      <div
        className="footer custom-font-body"
        style={{
          color: textColorDependsOnBackground,
          borderColor: textColorDependsOnBackground,
        }}
      >
        <div className="left ">
          {`© ${title}. ${locales.portfolioWeb.contactUs.rightsReserve}`}
        </div>
        <div className="right">
          {locales.portfolioWeb.contactUs.buildCreate}
          <span
            className="company-name"
            style={{
              color: textColorDependsOnBackground,
            }}
          >
            {locales.portfolioWeb.contactUs.companyName}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
