import React from 'react';
import CustomerRelationshipsCard from './CustomerRelationshipsCard';

const CustomerRelationshipsRow = ({ rowName, blocks }) => {
  return (
    <div className="row">
      <div className="row-title">{rowName}</div>
      <div className="blocks">
        {blocks.map((block, index) => {
          if (!block.name.length && !block.description.length) return false;
          return <CustomerRelationshipsCard key={index} block={block} />;
        })}
      </div>
    </div>
  );
};

export default CustomerRelationshipsRow;
