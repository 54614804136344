const apiPrefix = '/api/v1/admin';

const routeMaker = (modelName) => {
  return (params, withPagination = true) => {
    if (typeof params !== 'object')
      return `${apiPrefix}/${modelName}/${params}`;

    const {
      page = 1,
      limit = 5,
      search = '',
      byId = null,
      by = 'users',
      establishmentId = null,
      sort = null,
    } = params;

    if (byId) {
      if (withPagination)
        return `${apiPrefix}/${by}/${byId}/${modelName}?establishment_id=${establishmentId}&limit=${limit}&page=${page}${
          search ? `&search=${search}` : ''
        }${sort ? `&sort=${sort}` : ''}`;

      return `${apiPrefix}/${by}/${byId}/${modelName}`;
    }

    if (!withPagination) {
      return `${apiPrefix}/${modelName}`;
    }

    return `${apiPrefix}/${modelName}?limit=${limit}&page=${page}${
      search ? `&search=${search}` : ''
    }${sort ? `&sort=${sort}` : ''}`;
  };
};
export const lecturers = ({
  search = '',
  classroomId = null,
  establishmentId = null,
}) =>
  `${apiPrefix}/lecturers?search=${search}&classroom_id=${classroomId}&establishment_id=${establishmentId}`;

export const usersByParentId = (userId, page = 1, limit = 5, search = null) =>
  `${apiPrefix}/users/${userId}/children?limit=${limit}&page=${page}${
    search ? `&search=${search}` : ''
  }`;

export const usersDeactivate = (userId) =>
  `${apiPrefix}/users/${userId}/deactivate`;

export const establishments = routeMaker('establishments');

export const admins = (establishmentId) =>
  `${apiPrefix}/admins/${establishmentId}`;

export const getAdminsByEstablishmentId = (establishmentId) =>
  `${apiPrefix}/admins/own/${establishmentId}`;

export const getEstablishmentsByUserId = (userId) =>
  `${apiPrefix}/establishments/own/${userId}`;

export const adminEstablishmentCreate = (userId) =>
  `${apiPrefix}/admins/${userId}`;

export const adminEstablishmentDelete = (establishmentId, userId) =>
  `${apiPrefix}/admins/${userId}/establishment/${establishmentId}`;

export const search = (modelName, text, byId = null, byOwnerModelName = null) =>
  byId
    ? `${apiPrefix}/${byOwnerModelName}/${byId}/${modelName}/search?text=${text}`
    : `${apiPrefix}/${modelName}/search?search=${text}`;

export const classroom = (classroomId = null) =>
  classroomId
    ? `${apiPrefix}/classrooms/${classroomId}`
    : `${apiPrefix}/classrooms`;

export const shareClassroomLink = (secretToken) =>
  `${window.location.origin}/classes/share/${secretToken}`;

export const classroomLecturers = (classroomId) =>
  `${apiPrefix}/classrooms/${classroomId}/lecturers`;

export const classroomLecturer = (classroomId, lecturerId) =>
  `${apiPrefix}/classrooms/${classroomId}/lecturers/${lecturerId}`;

export const classrooms = routeMaker('classrooms');

export const childClassrooms = routeMaker('child_classrooms');

export const parentClassrooms = routeMaker('parent_classrooms');

export const classroomOptions = routeMaker('classroom_options');

export const students = routeMaker('students');

export const blocks = ({ by, byId, pageName }) =>
  `${apiPrefix}/${by}/${byId}/block?page_name=${pageName}`;

export const bmc = (bmcId) => `${apiPrefix}/bmcs/${bmcId}`;

export const bmcs = routeMaker('bmcs');

export const users = routeMaker('users');

export const usersDownloadReport = '/admin/users?format=xlsx';

export const user = (userId) => `${apiPrefix}/users/${userId}`;

export const establishment = (id = null) =>
  id ? `${apiPrefix}/establishments/${id}` : `${apiPrefix}/establishments`;

export const adminLecturers = routeMaker('admin_and_lecturers');

export const teamMembers = (bmcId) => `${apiPrefix}/bmcs/${bmcId}/team_members`;
