import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Dropzone from '../../components/Dropzone';
import Textarea from '../../components/Textarea';
import { renderImage } from '../../utils/helpers/renderImage.js';
import {
  CURRENT_TEMPLATE_NAME,
  IMAGE_ASPECT_RATIO_ONE,
} from '../../utils/const';

const blurComponent = (
  <div className="bmc-page-card-blur-wrapper">
    <div className="bmc-page-card-blur" />
  </div>
);

export default function Card({
  className,
  locales,
  blur = false,
  editMode = false,
  disabled = false,
  dropzoneMode = false,
  portfolioMode = false,
  backgroundCard,
  textStyle,
  styleColor,
  backgroundImage,
  templateName = CURRENT_TEMPLATE_NAME,
  titleStyle,
  buttonStyle,
  firstSvgColor,
  secondSvgColor,
  textColor,
  titleImageColor,
  isTemplates = false,
  isColourfulfunTemplate = false,
  card: { id, title, name, image, imageCoords, description, descriptionTitle },
}) {
  const {
    channel: { updateChannel, updateChannelImage, updateChannelImageCoords },
  } = useDispatch();

  const { uploadingImage } = useSelector((state) => state.channel);

  const onImageDrop = ([file], openCropperHandler) => {
    updateChannelImage({
      id,
      name: 'image',
      blob: file,
      success: () => {
        openCropperHandler();
      },
    });
  };

  const onImageCropperSave = (croppedAreaPixels, innerCropData, zoom) => {
    updateChannelImageCoords({
      id,
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: imageCoords,
    });
  };

  return (
    <div className="bmc-page-card-wrapper">
      <div
        className={classNames(
          'bmc-page-card bmc-page-card--channel-mode',
          className,
          { 'bmc-page-card--portfolio-mode': portfolioMode },
        )}
        style={{ background: backgroundCard }}
      >
        <div className="bmc-page-card-inner">
          {editMode || dropzoneMode ? (
            <Dropzone
              className="bmc-page-card-image"
              onDrop={onImageDrop}
              onCropperSave={onImageCropperSave}
              locales={locales}
              image={image}
              coords={imageCoords}
              uploading={uploadingImage === id}
              aspectRatio={IMAGE_ASPECT_RATIO_ONE}
              disabled={disabled}
              background={{ background: backgroundImage }}
              firstSvgColor={firstSvgColor}
              secondSvgColor={secondSvgColor}
              titleStyle={titleStyle}
              buttonStyle={buttonStyle}
              cropper
              isColourfulfunTemplate={isColourfulfunTemplate}
              isTemplates={isTemplates}
            />
          ) : (
            renderImage(
              templateName,
              image,
              backgroundImage,
              locales.portfolio.channels.sectionName,
              IMAGE_ASPECT_RATIO_ONE,
              titleImageColor,
            )
          )}
        </div>

        <div className="bmc-page-card-inner">
          {title && (
            <div className="bmc-page-card-row">
              <img
                src="/images/mpesa_Ch.png"
                className="bmc-page-card-icon"
                alt=""
              />
              <div className="bmc-page-card-big-title">{title}</div>
            </div>
          )}

          <div className="bmc-page-card-row">
            {editMode ? (
              <Textarea
                value={name}
                delay={700}
                className={`bmc-form-textarea ${textStyle} custom-font-body`}
                onChange={(e) =>
                  updateChannel({ name: 'name', value: e.target.value, id })
                }
                disabled={disabled}
              />
            ) : (
              <div
                className={`bmc-page-card-big-title bmc-page-card-big-title--with-underline bmc-page-card-big-title--customer-title ${textStyle} custom-font-body`}
                style={{ borderBottomColor: styleColor, color: textColor }}
              >
                {name}
              </div>
            )}
          </div>

          <div className="bmc-page-card-row">
            <div
              className={`bmc-page-card-title ${textStyle} custom-font-body`}
              style={{ color: textColor }}
            >
              {descriptionTitle}
            </div>
            {editMode ? (
              <Textarea
                value={description}
                delay={700}
                className={`bmc-form-textarea ${textStyle} custom-font-body`}
                onChange={(e) =>
                  updateChannel({
                    name: 'description',
                    value: e.target.value,
                    id,
                  })
                }
                disabled={disabled}
              />
            ) : (
              <div
                className="bmc-page-card-text custom-font-body"
                style={{ color: textColor }}
              >
                {description}
              </div>
            )}
          </div>
        </div>
      </div>
      {blur && blurComponent}
    </div>
  );
}
