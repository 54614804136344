import React, { useMemo } from 'react';
import commentToHtml from '../utils/commentToHtml';
import commentCreatedAt from '../utils/commentCreatedAt';
import CommentAvatar from './comment-avatar';
import CommentRoleLabelComponent from './comment-role-label';

const editCancel = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.70711 0.292893C1.31658 -0.097631 0.683418 -0.0976311 0.292893 0.292893C-0.097631 0.683417 -0.0976312 1.31658 0.292893 1.70711L5.5858 7.00002L0.292922 12.2929C-0.0976021 12.6834 -0.0976021 13.3166 0.292922 13.7071C0.683447 14.0976 1.31661 14.0976 1.70714 13.7071L7.00001 8.41423L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41423 7.00002L13.7071 1.70711C14.0977 1.31658 14.0977 0.683419 13.7071 0.292895C13.3166 -0.0976298 12.6834 -0.0976294 12.2929 0.292895L7.00001 5.5858L1.70711 0.292893Z"
      fill="#ACB5BD"
    />
  </svg>
);

export default function CommentInputEditComponent({
  comment,
  comment: {
    message,
    createdAt,
    sender,
    sender: { id, image },
  },
  currentUserId,
  members = [],
  onEditCancel = () => {},
}) {
  const resultMessage = useMemo(() => {
    return commentToHtml(message, members, id);
  }, [message, members]);

  return (
    <div className="comment-item">
      <div className="comment-item-header">
        <div className="comment-item-header-avatar">
          <CommentAvatar url={image?.icon?.url} />
        </div>
        <div className="comment-item-header-sender">
          <div className="comment-item-header-sender__full-name">
            {comment?.sender?.firstName} {comment?.sender?.surname}
          </div>
          <CommentRoleLabelComponent
            sender={sender}
            currentUserId={currentUserId}
          />
        </div>
        <div className="comment-item-header-actions">
          <div
            className="comment-item-header-actions__reply-cancel"
            onClick={onEditCancel}
          >
            {editCancel}
          </div>
        </div>
      </div>
      <div className="comment-item-content">
        <div className="comment-item-content__line" />

        <div className="comment-item-message">
          <div
            className="comment-item-message__text"
            dangerouslySetInnerHTML={{ __html: resultMessage }}
          />
        </div>
        <div className="comment-item-message__created-at">
          {commentCreatedAt(createdAt)}
        </div>
      </div>
    </div>
  );
}
