const pageNames = {
  superAdmin: 'SUPER_ADMIN_PAGE',
  admin: 'ADMIN_PAGE',
  lecturer: 'LECTURER_PAGE',
  establishment: 'ESTABLISHMENT_PAGE',
  classroom: 'CLASSROOM_PAGE',
  project: 'PROJECT_PAGE',
};

export default pageNames;
