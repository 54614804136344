import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';

import Container from '../../../components/Container';
import Header from '../../../components/Header';
import BmcCanvas from '../../../../main/containers/bmc/BmcCanvas';
import Ideation from '../../Ideation';
import TestList from '../../../../main/containers/testing/Containers/TestList';
import BlockSection from './BlockSection';
import PortfolioSectionAdmin from '../../../../main/containers/portfolio/PortfolioSectionAdmin';
import PrevNextLinks from '../../../components/PrevNextLinks';
import ModalBMCList from './ModalBMCList/ModalBMCList';
import { CLASSROOM_LOCAL_STORAGE_SORT_KEY } from '../../../utils/const';
import ExportModal from '../../../../main/containers/portfolio/Export';
import Button from '../../../../main/components/Button';
import { getPortfolioShortCuts } from '../../../../main/utils/helpers/getPortfolioShortCuts';

const { TabPane } = Tabs;

export default function BmcDashboard({ locales: { admin }, locales }) {
  const {
    portfolio: { page: pageLocales },
  } = locales;
  const { bmcId } = useParams();

  const [visibleModalBmcList, setVisibleModalBmcList] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState('desc');
  const [exportModalIsOpen, setExportModalIsOpen] = useState(false);

  const breadcrumbs = [
    { title: admin.establishment, to: '' },
    { title: admin.educators, to: '' },
  ];
  const {
    portfolio,
    user,
    bmc,
    bmc: { view },
  } = useSelector((state) => state);
  const { classroomBMCs, classroomBMCsLoading, classroomBMCsMeta } =
    useSelector((state) => state.bmc);

  const {
    bmc: { fetchBmcById, fetchClassroomBMCs, sortClassroomBMCs },
    portfolio: { fetchPortfolioData },
  } = useDispatch();

  useEffect(() => {
    if (bmcId) {
      fetchBmcById(bmcId);
      fetchPortfolioData(bmcId);
    }
  }, [bmcId]);
  const buttons = ({ prev, next }) => {
    return <PrevNextLinks prev={prev} next={next} />;
  };

  const handleFetchClassroomBMCs = (params) => {
    let sort;
    const localStorageSort = localStorage.getItem(
      CLASSROOM_LOCAL_STORAGE_SORT_KEY,
    );
    if (localStorageSort && localStorageSort !== 'null') {
      sort = localStorageSort;
    } else {
      sort = 'name';
    }
    fetchClassroomBMCs({ ...params, bmcId, sort });
  };

  const onModalButtonClick = () => {
    handleFetchClassroomBMCs({ bmcId });
    setVisibleModalBmcList(true);
    setOpenModalStatus('asc');
  };

  const handleSortClassroomBMCs = ({ sort }) => {
    localStorage.setItem(CLASSROOM_LOCAL_STORAGE_SORT_KEY, sort);
    sortClassroomBMCs({ sort, bmcId });
  };

  const handleCloseModalBmcList = () => {
    setVisibleModalBmcList(false);
    setOpenModalStatus('desc');
  };

  const portfolioShortCuts = getPortfolioShortCuts(locales);

  const webLink = useMemo(() => {
    return portfolioShortCuts.map((i) => i.name).join('-');
  }, [portfolioShortCuts]);

  return (
    <Container
      locales={locales}
      title={user?.current?.name ? `${user?.current?.name} Admin Panel` : ' '}
      withChat
    >
      <ModalBMCList
        visible={visibleModalBmcList}
        onClose={handleCloseModalBmcList}
        fetchClassroomBMCs={handleFetchClassroomBMCs}
        loading={classroomBMCsLoading}
        bmcId={bmcId}
        bmcs={classroomBMCs || []}
        sort={handleSortClassroomBMCs}
        meta={classroomBMCsMeta}
        rowClickable
        rowClick={(record) =>
          (window.location.href = `/admin/bmc/${record.id}`)
        }
      />
      <Header
        locales={locales}
        breadcrumbs={breadcrumbs}
        header={bmc?.current?.attributes?.name}
        leftButtons={buttons({
          prev: bmc?.current?.attributes?.previous,
          next: bmc?.current?.attributes?.next,
        })}
        disableBreadcrumbs
        modalButton
        onModalButtonClick={onModalButtonClick}
        openModalStatus={openModalStatus}
      />
      <div className="view-portfolio-section">
        <div className="header-portfolio">View Portfolio by:</div>
        <div className="button-links">
          {portfolio?.slug && (
            <Button
              preview
              title={pageLocales.preview}
              className="download-pdf"
              onClick={() => {
                window.open(
                  `/portfolio/${portfolio?.slug}/${webLink}`,
                  '_blank',
                );
              }}
            />
          )}
          {view === 'portfolio' && (
            <Button
              primary
              share
              title={pageLocales.export}
              onClick={() => setExportModalIsOpen(true)}
            />
          )}
        </div>
      </div>
      <BlockSection />
      <br />
      <br />
      <Tabs
        defaultActiveKey="ideation"
        className="admin-tabs"
        destroyInactiveTabPane
      >
        <TabPane key="ideation" tab="Ideation" className="admin-tab-pane">
          <Ideation locales={locales} />
        </TabPane>
        <TabPane key="canvas" tab="Canvas" className="admin-tab-pane">
          <BmcCanvas locales={locales} listMode="canvas" isPreview />
        </TabPane>
        <TabPane key="testing" tab="Testing" className="admin-tab-pane">
          <TestList locales={locales} />
        </TabPane>
        <TabPane key="portfolio" tab="Portfolio" className="admin-tab-pane">
          <PortfolioSectionAdmin locales={locales} disabled />
        </TabPane>
      </Tabs>
      <ExportModal
        locales={locales}
        isOpen={exportModalIsOpen}
        setIsOpen={setExportModalIsOpen}
      />
    </Container>
  );
}
