import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';

import Img from '../../components/Img';
import { buildUserShortName } from '../../utils/helpers';
import MessageContainer from '../message/message.container';

const adminRoles = ['super_admin', 'admin', 'lecturer'];
const rolesNamesMapping = {
  super_admin: 'super admin',
  admin: 'admin',
  lecturer: 'educator',
};

export default function UserBar({ locales: { userbar } }) {
  const [menuOpened, setMenuOpened] = useState(false);
  const {
    user: { current },
  } = useSelector((state) => state);
  const {
    user: { fetchCurrentUser },
  } = useDispatch();

  useEffect(() => {
    if (!current.id) fetchCurrentUser();
  }, []);

  const userbarLabelClick = () => {
    if (adminRoles.includes(current.role)) {
      window.location = '/admin';
    }
  };

  const onOutsideClickHandler = (e) => {
    if (
      ![
        'bmc-userbar-avatar',
        'img bmc-userbar-avatar-img',
        'icon-Colaps',
      ].includes(e.target.className)
    ) {
      setMenuOpened(false);
    }
  };

  return (
    <div className="bmc-userbar">
      {rolesNamesMapping[current.role] && (
        <div className="bmc-userbar-label" onClick={userbarLabelClick}>
          {rolesNamesMapping[current.role]}
        </div>
      )}

      {/* temporary off notifications <div className="bmc-userbar-notifications">
        <i className="icon-Notification"/>
      </div> */}

      <MessageContainer />

      <div
        className="bmc-userbar-avatar"
        onClick={() => setMenuOpened(!menuOpened)}
      >
        {current.imageIcon && current.role !== 'super_admin' ? (
          <Img src={current.imageIcon} />
        ) : (
          <div className="bmc-userbar-avatar-nophoto">
            {buildUserShortName(current)}
          </div>
        )}
        <div className="bmc-userbar-avatar-arrow">
          <i className="icon-Colaps" />
        </div>
      </div>

      {menuOpened && (
        <OutsideClickHandler onOutsideClick={onOutsideClickHandler}>
          <div className="bmc-userbar-menu">
            <a href="/account">
              <div className="bmc-userbar-menu-item">
                <i className="icon-CS green" />
                {userbar.account}
              </div>
            </a>

            <div
              className="bmc-userbar-menu-item"
              onClick={() => (window.location = '/users/sign_out')}
            >
              <i className="icon-VP green" />
              {userbar.logout}
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
}
