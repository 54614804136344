import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { truncate } from 'lodash';
import useMediaQuery from 'react-use-media-query-hook';

import CustomerSegmentCard from '../../customerSegment/CardCurrent';
import { COMMENT_SEGMENT, CURRENT_TEMPLATE_NAME } from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import { getCurrentCanvasIcon } from '../../../utils/helpers/getCurrentCanvasIcon';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import { getColorDependsOnBackground } from '../../../utils/helpers/getColorDependsOnBackground';

const CustomerSegmentCurrent = ({
  data: { description },
  locales,
  locales: {
    portfolio,
    customerSegment: {
      intro: { card },
    },
  },
  styleColor,
  isFourthColorPaletteNumber = false,
  disabled = false,
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [activeCardId, setActiveCardId] = useState(null);

  const {
    customerSegment: { updateCustomerSegment },
    portfolio: { updateSectionField },
  } = useDispatch();

  const { collection } = useSelector((state) => state.customerSegment);

  const activeCardData = useMemo(() => {
    if (collection.length && !activeCardId) {
      setActiveCardId(collection[0].id);
      return collection[0];
    }
    return collection.find((i) => i.id === activeCardId);
  }, [collection, activeCardId]);

  const singleCard = activeCardData && (
    <CustomerSegmentCard
      locales={locales}
      uploadFileHandler={updateCustomerSegment}
      dropzoneMode
      blur={false}
      disabled={disabled}
      editMode={!disabled}
      card={{
        ...card,
        ...activeCardData,
        subtitle: activeCardData.name,
        title: null,
      }}
      styleColor={styleColor}
      isFourthColorPaletteNumber={isFourthColorPaletteNumber}
    />
  );

  return (
    <div className="customer-segment-current section relative">
      <PortfolioCommentIcon title={COMMENT_SEGMENT} />
      {getCurrentCanvasIcon('CSCanvas', styleColor)}
      <SectionHeader
        templateName={CURRENT_TEMPLATE_NAME}
        title={portfolio.customerSegment.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'customerSegment',
            field: 'description',
            name: 'csText',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        className="green-title"
        style={{ color: styleColor }}
        disabled={disabled}
      />

      <div className="cards-section">
        <div className="left">
          {collection.map(({ id, name }) => (
            <div
              key={id}
              onClick={() => setActiveCardId(id)}
              className={classnames('item custom-font-body', {
                active: activeCardId === id,
              })}
              style={{
                backgroundColor: activeCardId === id && styleColor,
                color:
                  isFourthColorPaletteNumber &&
                  activeCardId === id &&
                  getColorDependsOnBackground(styleColor),
              }}
            >
              {truncate(name, { length: 45 })}
            </div>
          ))}
        </div>
        <div className="right">
          {isMobile
            ? collection.map((customerSegmentItem) => {
                return (
                  <CustomerSegmentCard
                    locales={locales}
                    className="margin-top-20"
                    key={customerSegmentItem.id}
                    uploadFileHandler={updateCustomerSegment}
                    dropzoneMode
                    portfolioMode
                    blur={false}
                    disabled={disabled}
                    editMode={!disabled}
                    card={{
                      ...card,
                      ...customerSegmentItem,
                      subtitle: customerSegmentItem.name,
                      title: null,
                    }}
                    styleColor={styleColor}
                    isFourthColorPaletteNumber={isFourthColorPaletteNumber}
                  />
                );
              })
            : singleCard}
        </div>
      </div>
    </div>
  );
};

export default CustomerSegmentCurrent;
