import React from 'react';
import { connect, useDispatch } from 'react-redux';

import SectionHeader from '../SectionHeader';
import CustomerRelationshipsRow from './CustomerRelationshipsRow';
import Textarea from '../../../components/Textarea';

const CustomerRelationships = ({
  get,
  keep,
  grow,
  referral,
  data: { description, showStatus },
  locales: { portfolio },
  disabled = false,
}) => {
  const {
    portfolio: { updateSectionField, updateSectionFieldReducer },
  } = useDispatch();

  const setShowStatus = (status) => {
    updateSectionFieldReducer({
      sectionName: 'customerRelationships',
      field: 'showStatus',
      value: status,
    });
  };

  return (
    <div className="customer-relationships">
      <SectionHeader
        locales={portfolio}
        setShowStatus={setShowStatus}
        showStatus={showStatus}
        mainColor="green"
        sectionName={portfolio.customerRelationships.sectionName}
      />
      {showStatus && (
        <div>
          <Textarea
            name="textarea"
            rows={5}
            delay={700}
            value={description || ''}
            onChange={(e) =>
              updateSectionField({
                sectionName: 'customerRelationships',
                field: 'description',
                name: 'gkgText',
                value: e.target.value,
              })
            }
            className="bmc-form-textarea textarea"
            placeholder={portfolio.page.descriptionPlaceholder}
            disabled={disabled}
          />

          <div className="board">
            <CustomerRelationshipsRow
              rowName={portfolio.customerRelationships.table.get}
              blocks={get}
            />
            <CustomerRelationshipsRow
              rowName={portfolio.customerRelationships.table.keep}
              blocks={keep}
            />
            <CustomerRelationshipsRow
              rowName={portfolio.customerRelationships.table.grow}
              blocks={grow}
            />
            <CustomerRelationshipsRow
              rowName={portfolio.customerRelationships.table.referral}
              blocks={referral}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  get: state.getKeepGrow.getStrategies,
  keep: state.getKeepGrow.keepStrategies,
  grow: state.getKeepGrow.growStrategies,
  referral: state.getKeepGrow.referralStrategies,
});

export default connect(mapStateToProps)(CustomerRelationships);
