const initPageSizeOptions = [25, 50, 100, 200, 300];

const pagination = {
  state: {
    pageSizeOptions: initPageSizeOptions,
    pageSize: localStorage.getItem('pageSize')
      ? Number(localStorage.getItem('pageSize'))
      : initPageSizeOptions[0],
  },
  reducers: {
    setPageSizeOptions(state, pageSizeOptions) {
      return { ...state, pageSizeOptions };
    },
    setPageSize(state, pageSize) {
      localStorage.setItem('pageSize', pageSize);
      return { ...state, pageSize };
    },
  },
};

export default pagination;
