import React from 'react';
import { useDispatch } from 'react-redux';

import SectionHeader from './SectionHeader';
import Textarea from '../../components/Textarea';

const DesirabilitySection = ({
  data: { showStatus, description },
  locales: { portfolio },
  disabled = false,
}) => {
  const {
    portfolio: { updateSectionField, updateSectionFieldReducer },
  } = useDispatch();

  const setShowStatus = (status) => {
    updateSectionFieldReducer({
      sectionName: 'desirabilitySection',
      field: 'showStatus',
      value: status,
    });
  };

  return (
    <div className="desirability">
      <SectionHeader
        locales={portfolio}
        showStatus={showStatus}
        sectionName="Desirability Section"
        setShowStatus={setShowStatus}
        mainColor="green"
      />
      {showStatus && (
        <Textarea
          name="textarea"
          rows={5}
          delay={700}
          value={description}
          onChange={(e) =>
            updateSectionField({
              sectionName: 'desirabilitySection',
              field: 'description',
              name: 'desirabilityText',
              value: e.target.value,
            })
          }
          className="bmc-form-textarea textarea"
          placeholder={portfolio.page.descriptionPlaceholder}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default DesirabilitySection;
