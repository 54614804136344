import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Preload from 'react-preload';

const images = [
  '/images/ideation1.png',
  '/images/canvas2.png',
  '/images/testing3.png',
  '/images/portfolio4.png',
];

const timerTimeout = 6000;

const SignUpInfo = ({ locales: { onboarding: pageLocales } }) => {
  const [activeImage, setActiveImage] = useState(0);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setActiveImage(activeImage < images.length - 1 ? activeImage + 1 : 0);
    }, timerTimeout);
    return () => {
      clearTimeout(timerId);
    };
  }, [activeImage]);

  return (
    <div className="sign-up-info-section">
      <h2 className="sign-up-info-section__title">{pageLocales.infoTitle}</h2>
      <div className="sign-up-info-section__steps">
        {pageLocales.infoTabTitles.map((item, index) => (
          <div
            key={index}
            className={classnames('sign-up-info-section__steps-title', {
              'sign-up-info-section__steps-title--active':
                index === activeImage,
            })}
            onClick={() => setActiveImage(index)}
          >
            {item}
          </div>
        ))}
      </div>
      <p className="sign-up-info-section__description-title">Description:</p>
      <p className="sign-up-info-section__description">
        {pageLocales.infoTabDescriptions[activeImage]}
      </p>
      <Preload images={images}>
        <img
          src={images[activeImage]}
          alt="bmc-screen"
          className="sign-up-info-section__image"
        />
      </Preload>
    </div>
  );
};

SignUpInfo.propTypes = {
  locales: PropTypes.object.isRequired,
};

export default SignUpInfo;
