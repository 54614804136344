import React from 'react';
import classNames from 'classnames';

const SubChapter = ({ name, className, separatorColor, textColor }) => {
  return (
    <div className={classNames('subchapter', className)}>
      <div className="title custom-font-body" style={{ color: textColor }}>
        {name}
      </div>
      <div className="separator" style={{ backgroundColor: separatorColor }} />
    </div>
  );
};

export default SubChapter;
