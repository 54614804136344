const map = (attributes) => ({
  title: {
    sectionName: 'introduction',
    field: 'title',
    value: attributes.title,
  },
  description: {
    sectionName: 'introduction',
    field: 'description',
    value: attributes.description,
  },
  introLogo: {
    sectionName: 'introduction',
    field: 'introLogo',
    value: attributes.introLogo,
  },
  introBg: {
    sectionName: 'introduction',
    field: 'introBg',
    value: attributes.introBg,
  },
  introLogoCoords: {
    sectionName: 'introduction',
    field: 'introLogoCoords',
    value: attributes.introLogoCoords,
  },
  introBgCoords: {
    sectionName: 'introduction',
    field: 'introBgCoords',
    value: attributes.introBgCoords,
  },
  projectSummary: {
    sectionName: 'projectSummary',
    field: 'description',
    value: attributes.projectSummary,
  },
  teamDescription: {
    sectionName: 'team',
    field: 'description',
    value: attributes.teamDescription,
  },
  bmcText: {
    sectionName: 'bmc',
    field: 'description',
    value: attributes.bmcText,
  },
  testingText: {
    sectionName: 'testing',
    field: 'description',
    value: attributes.testingText,
  },
  desirabilityText: {
    sectionName: 'desirabilitySection',
    field: 'description',
    value: attributes.desirabilityText,
  },
  csText: {
    sectionName: 'customerSegment',
    field: 'description',
    value: attributes.csText,
  },
  vpText: {
    sectionName: 'valueProposition',
    field: 'description',
    value: attributes.vpText,
  },
  competitorAnalysisText: {
    sectionName: 'competitorAnalysis',
    field: 'description',
    value: attributes.competitorAnalysisText,
  },
  gkgText: {
    sectionName: 'customerRelationships',
    field: 'description',
    value: attributes.gkgText,
  },
  channelsText: {
    sectionName: 'channels',
    field: 'description',
    value: attributes.channelsText,
  },
  feasibilityText: {
    sectionName: 'feasibility',
    field: 'description',
    value: attributes.feasibilityText,
  },
  operationsText: {
    sectionName: 'keyActivities',
    field: 'description',
    value: attributes.operationsText,
  },
  viabilityText: {
    sectionName: 'viability',
    field: 'description',
    value: attributes.viabilityText,
  },
  financeText: {
    sectionName: 'finance',
    field: 'description',
    value: attributes.financeText,
  },
  sustainabilityText: {
    sectionName: 'sustainability',
    field: 'description',
    value: attributes.sustainabilityText,
  },
  contactUsText: {
    sectionName: 'contactUs',
    field: 'description',
    value: attributes.contactUsText,
  },
  fullName: {
    sectionName: 'contactUs',
    field: 'fullName',
    value: attributes.fullName,
  },
  emailOrWebsite: {
    sectionName: 'contactUs',
    field: 'emailOrWebsite',
    value: attributes.emailOrWebsite,
  },
});

export default map;
