import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BmcCanvasSection from './BmcCanvasSection';
import CustomerSegmentCard from '../../customerSegment/Card';
import CardModal from '../CardModal';
import { collectionEmpty, itemHasContent } from '../../../utils/helpers';
import SvgIcon from '../../../components/SvgIcon';

export default function CustomerSegments({
  locales,
  locales: { canvas },
  isDark,
  isPreview,
  isProgressBar,
  backgroundIcon,
  backgroundItems,
  backgroundSection,
  borderSectionColor,
  titleBMCSectionColor,
  textColor,
  iconFill,
  iconFillClose,
  templateName,
  sectionClassName,
  firstSvgColor,
  secondSvgColor,
  titleStyle,
  buttonStyle,
  iconStyle,
  styleColor,
  backgroundBMC,
  textStyle,
  backgroundRow,
  backgroundImage,
  titleColor,
  buttonColor,
  backgroundButton,
  titleImageColor,
  isTemplates = false,
  isColourfulfunTemplate = false,
  disabled = false,
}) {
  const csFields = [
    'name',
    'description',
    'frustrates',
    'goals',
    'problems',
    'type',
    'image',
  ];

  const {
    customerSegment,
    bmc: {
      current: { id },
    },
  } = useSelector((state) => state);

  const {
    customerSegment: { fetchCustomerSegments },
  } = useDispatch();

  const [showCustomerSegment, setShowCustomerSegment] = useState(false);

  return (
    <>
      {showCustomerSegment && (
        <CardModal
          onBlackscreenClick={() => setShowCustomerSegment(false)}
          title={canvas.customerSegment}
          icon={
            !templateName ? (
              'icon-customer-segment'
            ) : (
              <SvgIcon name="iconCustomerSegment" />
            )
          }
          to={`/bmcs/${id}/customer_segment/intro`}
          className="custom-font-body"
          locales={locales}
          isPreview={isPreview}
          onOpen={() => fetchCustomerSegments(id)}
          isOpen={showCustomerSegment}
          titleStyle={titleStyle}
          buttonStyle={buttonStyle}
          firstSvgColor={firstSvgColor}
          secondSvgColor={secondSvgColor}
          iconFill={iconFill}
          iconStyle={iconStyle}
          backgroundIcon={backgroundIcon}
          backgroundBMC={backgroundBMC}
          titleColor={titleColor}
          buttonColor={buttonColor}
          backgroundButton={backgroundButton}
          iconFillClose={iconFillClose}
          isColourfulfunTemplate={isColourfulfunTemplate}
          disabled={disabled}
        >
          {customerSegment.collection.map((item) => (
            <CustomerSegmentCard
              key={item.id}
              locales={locales}
              blur={false}
              className="margin-bottom-20"
              editMode={!isPreview}
              card={{
                ...locales.customerSegment.intro.card,
                ...item,
                subtitle: item.name,
                title: null,
              }}
              backgroundCard={backgroundRow}
              textStyle={textStyle}
              styleColor={styleColor}
              backgroundImage={backgroundImage}
              templateName={templateName}
              titleStyle={titleStyle}
              buttonStyle={buttonStyle}
              firstSvgColor={firstSvgColor}
              secondSvgColor={secondSvgColor}
              textColor={textColor}
              titleImageColor={titleImageColor}
              isTemplates={isTemplates}
              isColourfulfunTemplate={isColourfulfunTemplate}
            />
          ))}
        </CardModal>
      )}

      <BmcCanvasSection
        className={`bmc-canvas-section--green bmc-canvas-section-3 ${sectionClassName}`}
        title={canvas.customerSegment}
        dark={isDark}
        starting
        showButton={
          !isPreview && collectionEmpty(customerSegment.collection, csFields)
        }
        to={`/bmcs/${id}/customer_segment/intro`}
        locales={locales}
        icon={
          !templateName ? (
            'icon-customer-segment'
          ) : (
            <SvgIcon name="iconCustomerSegment" />
          )
        }
        isPreview={isPreview}
        isProgressBar={isProgressBar}
        uploading={customerSegment.uploading}
        onClick={() => setShowCustomerSegment(true)}
        backgroundIcon={backgroundIcon}
        backgroundSection={backgroundSection}
        borderSectionColor={borderSectionColor}
        titleBMCSectionColor={titleBMCSectionColor}
        iconFill={iconFill}
        iconStyle={iconStyle}
        templateName={templateName}
      >
        {customerSegment.collection.map((item) => {
          const [title, field] =
            item.type === 'B2bCustomerSegment'
              ? [canvas.companySize, 'companySize']
              : [canvas.ageRange, 'ageRange'];

          return (
            itemHasContent(item, csFields) && (
              <div
                key={item.id}
                className="bmc-canvas-section-items-item custom-font-body"
                style={{ backgroundColor: backgroundItems, color: textColor }}
                onClick={() => setShowCustomerSegment(true)}
              >
                <div
                  className="bmc-canvas-section-items-item-title custom-font-body"
                  style={{ color: textColor }}
                >
                  {canvas.customerName}
                </div>
                <div className="margin-bottom-10 custom-font-body">
                  {item.name || '-'}
                </div>
                {!!item.type && (
                  <>
                    <div
                      className="bmc-canvas-section-items-item-title custom-font-body"
                      style={{ color: textColor }}
                    >
                      {title}
                    </div>
                    {item[field].min} - {item[field].max}
                  </>
                )}
              </div>
            )
          );
        })}
      </BmcCanvasSection>
    </>
  );
}
