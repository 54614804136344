import React from 'react';
import cellPropTypes from './cellPropTypes';

function CellFullNameWithEmail({ user }) {
  return (
    <div className="user-list-item-info">
      <div className="user-list__fullname">{user.fullName}</div>
      <div className="user-list__email">{user.email}</div>
    </div>
  );
}

CellFullNameWithEmail.propTypes = cellPropTypes;

export default CellFullNameWithEmail;
