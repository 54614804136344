import React from 'react';
import Lazyload from 'react-lazyload';
import { useSelector } from 'react-redux';
import { find } from 'lodash';

import CustomerSegmentBlog from './CustomerSegmentBlog';
import ValuePropositionBlog from './ValuePropositionBlog';
import ChannelsBlog from './ChannelsBlog';
import ContactUsBlog from './ContactUsBlog';
import { StyledWrapper } from '../../../utils/helpers';
import { getColorTemplate } from '../../../utils/helpers/getColorTemplate';
import {
  BLOG_FRIENDLY_TEMPLATE_NAME,
  BUTTON_LIGHT_MODAL_STYLE_BLOG,
  BUTTON_MODAL_STYLE_BLOG,
  COLORS,
  COLOR_PALETTE_ARRAY_BLOG,
  TITLE_MODAL_STYLE_BLOG,
} from '../../../utils/const';
import Introduction from '../mainTemplateComponents/Introduction';
import Summary from '../mainTemplateComponents/Summary';
import Team from '../mainTemplateComponents/Team';
import { getColorDependsOnBackground } from '../../../utils/helpers/getColorDependsOnBackground';
import BMC from '../mainTemplateComponents/BMC';
import Testing from '../mainTemplateComponents/Testing';
import Desirability from '../mainTemplateComponents/Desirability';
import Viability from '../mainTemplateComponents/Viability';
import Feasibility from '../mainTemplateComponents/Feasibility';
import CompetitorAnalysis from '../mainTemplateComponents/CompetitorAnalysis';
import CustomerRelationships from '../mainTemplateComponents/CustomerRelationships';
import KeyActivities from '../mainTemplateComponents/KeyActivities';
import Finance from '../mainTemplateComponents/Finance';
import Sustainability from '../mainTemplateComponents/Sustainability';

const { white, black, transparentWhite } = COLORS;

const BlogFriendlyTemplate = ({
  locales,
  portfolio,
  bmc,
  testing,
  disabled = false,
}) => {
  const {
    portfolioStyle: { templateSettings, activeTemplateName },
    portfolioTemplate: { collection },
  } = useSelector((state) => state);

  const currentSettings = templateSettings
    ? templateSettings[activeTemplateName]
    : {};
  const activeTemplate = find(collection, ['sysName', activeTemplateName]);
  const colorTemplate = getColorTemplate(
    templateSettings,
    activeTemplateName,
    activeTemplate,
  );

  if (!colorTemplate) {
    return null;
  }

  const [firstColor, secondColor, thirdColor] = colorTemplate;

  const isFourthColorPaletteNumber = !COLOR_PALETTE_ARRAY_BLOG[secondColor];

  const textColorDependsOnFirstColor =
    isFourthColorPaletteNumber && getColorDependsOnBackground(firstColor);

  const textColorDependsOnSecondColor =
    isFourthColorPaletteNumber && getColorDependsOnBackground(secondColor);

  const textColorDependsOnThirdColor =
    isFourthColorPaletteNumber && getColorDependsOnBackground(thirdColor);

  return (
    <div className="bmc-canvas margin-top-30">
      <div className="bmc-page fullwidth blog" style={{ padding: '0' }}>
        <StyledWrapper
          customtitlefont={currentSettings?.customTitleFont}
          customtitlecolor={currentSettings?.customTitleColor}
          custombodyfont={currentSettings?.customBodyFont}
          custombodycolor={currentSettings?.customBodyColor}
        >
          <Introduction
            locales={locales}
            data={portfolio.introduction}
            backgroundSection={thirdColor}
            backgroundContent={secondColor}
            backgroundModal={`${secondColor}80`}
            isFourthColorPaletteNumber={isFourthColorPaletteNumber}
            templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
            disabled={disabled}
          />
          <Summary
            locales={locales}
            data={portfolio.projectSummary}
            backgroundSection={secondColor}
            isFourthColorPaletteNumber={isFourthColorPaletteNumber}
            templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
            disabled={disabled}
          />
          <Lazyload>
            <Team
              locales={locales}
              data={portfolio.team}
              backgroundSection={firstColor}
              backgroundTeam={secondColor}
              backgroundModal={`${secondColor}80`}
              isFourthColorPaletteNumber={isFourthColorPaletteNumber}
              templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
              disabled={disabled}
            />
          </Lazyload>
          {bmc && (
            <Lazyload>
              <BMC
                locales={locales}
                data={portfolio.bmc}
                className="bm-blogFriendly-canvas section"
                sectionClassName="bmc-canvas-section--blogFriendly"
                textStyle="text-card-arch"
                textWithImage="bmc-page-compcard-item-text-template bmc-page-compcard-item-text-template--with-image"
                backgroundMainSection={secondColor}
                iconFill={textColorDependsOnSecondColor || black}
                backgroundIcon={secondColor}
                backgroundItems={`${thirdColor}4D`}
                backgroundSection={white}
                styleColor={thirdColor}
                backgroundRow={`${thirdColor}4D`}
                backgroundImage={transparentWhite}
                backgroundBMC={`${secondColor}33`}
                sectionHeaderTitleColor={textColorDependsOnSecondColor}
                descriptionColor={textColorDependsOnSecondColor}
                titleStyle={TITLE_MODAL_STYLE_BLOG}
                buttonStyle={BUTTON_MODAL_STYLE_BLOG}
                templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
                disabled={disabled}
              />
            </Lazyload>
          )}
          {testing && (
            <Lazyload>
              <Testing
                locales={locales}
                data={portfolio.testing}
                className="testing testing-blogFriendly section"
                textCardStyle="text-card-blog"
                testListStyle="tests-list-blog"
                thirdColor={thirdColor}
                backgroundSection={{ backgroundColor: firstColor }}
                backgroundCard={`${secondColor}33`}
                inputColor={thirdColor}
                textColor={black}
                rateColor={black}
                linkColor={black}
                titleCardStyle={TITLE_MODAL_STYLE_BLOG}
                buttonCardStyle={BUTTON_MODAL_STYLE_BLOG}
                sectionHeaderTitleColor={textColorDependsOnFirstColor}
                descriptionColor={textColorDependsOnFirstColor}
                noTestsColor={textColorDependsOnFirstColor}
                isFourthColorPaletteNumber={isFourthColorPaletteNumber}
                templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
                disabled={disabled}
              />
            </Lazyload>
          )}
          {bmc && (
            <>
              <Desirability
                locales={locales}
                data={portfolio.desirabilitySection}
                className="desirability-blogFriendly section"
                backgroundSection={thirdColor}
                sectionHeaderTitleColor={textColorDependsOnThirdColor}
                descriptionColor={textColorDependsOnThirdColor}
                templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
                disabled={disabled}
              />
              <Lazyload>
                <CustomerSegmentBlog
                  locales={locales}
                  data={portfolio.customerSegment}
                  backgroundSection={firstColor}
                  backgroundCard={secondColor}
                  backgroundModal={`${secondColor}80`}
                  isFourthColorPaletteNumber={isFourthColorPaletteNumber}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <ValuePropositionBlog
                  locales={locales}
                  data={portfolio.valueProposition}
                  backgroundSection={firstColor}
                  backgroundCard={secondColor}
                  backgroundModal={`${secondColor}80`}
                  isFourthColorPaletteNumber={isFourthColorPaletteNumber}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <CompetitorAnalysis
                  locales={locales}
                  data={portfolio.competitorAnalysis}
                  className="competitor-analysis-blogFriendly section"
                  backgroundSection={firstColor}
                  backgroundImage={secondColor}
                  backgroundModal={`${secondColor}80`}
                  sectionHeaderTitleColor={textColorDependsOnFirstColor}
                  descriptionColor={textColorDependsOnFirstColor}
                  titleStyle={TITLE_MODAL_STYLE_BLOG}
                  buttonStyle={BUTTON_MODAL_STYLE_BLOG}
                  lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_BLOG}
                  sliderColor={black}
                  textColor={textColorDependsOnFirstColor}
                  borderTableColor={textColorDependsOnFirstColor}
                  headTableColor={textColorDependsOnFirstColor}
                  borderImageColor={textColorDependsOnFirstColor}
                  templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <CustomerRelationships
                  locales={locales}
                  data={portfolio.customerRelationships}
                  className="customer-relationships-blogFriendly section"
                  backgroundSection={secondColor}
                  sectionHeaderTitleColor={textColorDependsOnSecondColor}
                  descriptionColor={textColorDependsOnSecondColor}
                  firstGKGColor={
                    isFourthColorPaletteNumber
                      ? textColorDependsOnSecondColor
                      : black
                  }
                  secondGKGColor={thirdColor}
                  textColor={black}
                  tabColor={
                    isFourthColorPaletteNumber
                      ? textColorDependsOnSecondColor
                      : black
                  }
                  backgroundBlock={white}
                  borderColorBlock={black}
                  templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <ChannelsBlog
                  locales={locales}
                  data={portfolio.channels}
                  backgroundSection={firstColor}
                  thirdColor={thirdColor}
                  backgroundModal={`${secondColor}80`}
                  isFourthColorPaletteNumber={isFourthColorPaletteNumber}
                  disabled={disabled}
                />
              </Lazyload>
              <Feasibility
                locales={locales}
                data={portfolio.feasibility}
                className="feasibility-blogFriendly section"
                backgroundSection={thirdColor}
                sectionHeaderTitleColor={textColorDependsOnThirdColor}
                descriptionColor={textColorDependsOnThirdColor}
                templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
                disabled={disabled}
              />
              <Lazyload>
                <KeyActivities
                  locales={locales}
                  data={portfolio.keyActivities}
                  className="key-activities-blogFriendly section relative"
                  sectionHeaderTitleColor={textColorDependsOnFirstColor}
                  descriptionColor={textColorDependsOnFirstColor}
                  backgroundSection={firstColor}
                  secondColor={secondColor}
                  circleActivityColor={secondColor}
                  lineActivityColor={
                    isFourthColorPaletteNumber
                      ? textColorDependsOnFirstColor
                      : black
                  }
                  lineResourcesColor={black}
                  textActivityColor={textColorDependsOnFirstColor}
                  titleActivityColor={textColorDependsOnFirstColor}
                  backgroundResources={white}
                  templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
              <Viability
                locales={locales}
                data={portfolio.viability}
                className="viability-blogFriendly section"
                backgroundSection={thirdColor}
                sectionHeaderTitleColor={textColorDependsOnThirdColor}
                descriptionColor={textColorDependsOnThirdColor}
                templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
                disabled={disabled}
              />
              <Lazyload>
                <Finance
                  locales={locales}
                  data={portfolio.finance}
                  className="portfolio-finance-blogFriendly portfolio-finance-blogFriendly-temp section"
                  backgroundSection={secondColor}
                  sectionHeaderTitleColor={textColorDependsOnSecondColor}
                  descriptionColor={textColorDependsOnSecondColor}
                  secondChartColor={secondColor}
                  firstChartColor={thirdColor}
                  backgroundUnitTable={secondColor}
                  backgroundRow={`${secondColor}80`}
                  secondTitleUnitColor={textColorDependsOnSecondColor}
                  backgroundForecastTable={secondColor}
                  borderVerticalTableColor={secondColor}
                  totalColor={thirdColor}
                  templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
                  titleForecastColor={textColorDependsOnSecondColor}
                  titleChartColor={textColorDependsOnSecondColor}
                  activeTabColor={textColorDependsOnSecondColor}
                  tabColor={textColorDependsOnSecondColor}
                  disabled={disabled}
                />
              </Lazyload>
              <Lazyload>
                <Sustainability
                  locales={locales}
                  data={portfolio.sustainability}
                  className="sustainability-blogFriendly section"
                  cardClassName="bmc-page-suscard-blog bmc-page-suscard--section"
                  sliderStyles="sdg-card-blog"
                  iconTableStyles="sdg-card-blog-icon"
                  backgroundSection={firstColor}
                  sectionHeaderTitleColor={textColorDependsOnFirstColor}
                  descriptionColor={textColorDependsOnFirstColor}
                  backgroundSliderColor={secondColor}
                  backgroundIcon={secondColor}
                  iconFill={thirdColor}
                  iconFillTable={thirdColor}
                  textTableColor={textColorDependsOnFirstColor}
                  textSliderColor={textColorDependsOnSecondColor}
                  arrowColor={textColorDependsOnFirstColor}
                  titleTableColor={textColorDependsOnFirstColor}
                  borderTableColor={textColorDependsOnFirstColor}
                  templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
                  disabled={disabled}
                />
              </Lazyload>
            </>
          )}
          <ContactUsBlog
            locales={locales}
            data={portfolio.contactUs}
            backgroundSection={thirdColor}
            isFourthColorPaletteNumber={isFourthColorPaletteNumber}
            disabled={disabled}
          />
        </StyledWrapper>
      </div>
    </div>
  );
};

export default BlogFriendlyTemplate;
