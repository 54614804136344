import React from 'react';
import { useSelector } from 'react-redux';

import CardBlog from '../../main/containers/valueProposition/CardBlog';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import { BLOG_FRIENDLY_TEMPLATE_NAME } from '../../main/utils/const';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';

const ValueProposition = ({
  locales,
  locales: {
    valueProposition: {
      intro: { card },
    },
    portfolioWeb,
  },
  backgroundSection,
  backgroundCard,
  isFourthColorPaletteNumber = false,
}) => {
  const { portfolio } = useSelector((state) => state);
  const {
    valueProposition: { product, reason, image },
    uniqueSellingPoint,
  } = useSelector((state) => state);

  const textColorDependsOnBackground =
    isFourthColorPaletteNumber &&
    getColorDependsOnBackground(backgroundSection);

  const cardTextColorDependsOnBackground =
    isFourthColorPaletteNumber && getColorDependsOnBackground(backgroundCard);

  return (
    <div
      className="value-propositions-blogFriendly-web"
      style={{ backgroundColor: backgroundSection }}
    >
      <SectionHeaderWeb
        templateName={BLOG_FRIENDLY_TEMPLATE_NAME}
        title={portfolioWeb.valueProposition}
        description={portfolio.valueProposition.description}
        portfolioWeb={portfolioWeb}
        style={{ color: textColorDependsOnBackground }}
        descriptionColor={textColorDependsOnBackground}
      />

      <CardBlog
        locales={locales}
        portfolioMode
        background={backgroundCard}
        card={{
          ...card,
          title: false,
          image,
          solution: product,
          features: reason,
          points: uniqueSellingPoint.collection,
        }}
        textColor={cardTextColorDependsOnBackground}
        borderImageColor={cardTextColorDependsOnBackground}
      />
    </div>
  );
};

export default ValueProposition;
