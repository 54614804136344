import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { truncate } from 'lodash';
import useMediaQuery from 'react-use-media-query-hook';

import CustomerSegmentCard from '../../main/containers/customerSegment/CardCurrent';
import { CURRENT_TEMPLATE_NAME } from '../../main/utils/const';
import { getCurrentCanvasIcon } from '../../main/utils/helpers/getCurrentCanvasIcon';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';
import { getColorDependsOnBackground } from '../../main/utils/helpers/getColorDependsOnBackground';

const CustomerSegment = ({
  locales,
  locales: {
    portfolioWeb,
    customerSegment: {
      intro: { card },
    },
  },
  styleColor,
  isFourthColorPaletteNumber = false,
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [activeCardId, setActiveCardId] = useState(null);
  const { customerSegment } = useSelector((state) => state.portfolio);

  const { collection } = useSelector((state) => state.customerSegment);

  const activeCardData = useMemo(() => {
    if (collection.length && !activeCardId) {
      setActiveCardId(collection[0].id);
      return collection[0];
    }
    return collection.find((i) => i.id === activeCardId);
  }, [collection, activeCardId]);

  const singleCard = activeCardData && (
    <CustomerSegmentCard
      locales={locales}
      isPreview
      styleColor={styleColor}
      background={`${styleColor}80`}
      card={{
        ...card,
        ...activeCardData,
        subtitle: activeCardData.name,
        title: null,
      }}
    />
  );

  return (
    <div className="customer-segment section">
      {getCurrentCanvasIcon('CSCanvas', styleColor)}
      <SectionHeaderWeb
        templateName={CURRENT_TEMPLATE_NAME}
        title={portfolioWeb.customerSegment}
        description={customerSegment.description}
        className="green-title"
        style={{ color: styleColor }}
      />
      <div className="cards-section">
        <div className="left">
          {collection.map(({ id, name }) => (
            <div
              key={id}
              onClick={() => setActiveCardId(id)}
              className={classnames('item custom-font-body', {
                active: activeCardId === id,
              })}
              style={{
                backgroundColor: activeCardId === id && styleColor,
                color:
                  isFourthColorPaletteNumber &&
                  activeCardId === id &&
                  getColorDependsOnBackground(styleColor),
              }}
            >
              {truncate(name, { length: 45 })}
            </div>
          ))}
        </div>
        <div className="right">
          {isMobile
            ? collection.map((customerSegmentItem) => {
                return (
                  <CustomerSegmentCard
                    locales={locales}
                    className="margin-top-20"
                    isPreview
                    styleColor={styleColor}
                    background={`${styleColor}80`}
                    card={{
                      ...card,
                      ...customerSegmentItem,
                      subtitle: customerSegmentItem.name,
                      title: null,
                    }}
                  />
                );
              })
            : singleCard}
        </div>
      </div>
    </div>
  );
};

export default CustomerSegment;
