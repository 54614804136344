import React, { useEffect, useState } from 'react';
import { Col, Form, Input } from 'antd';
import getTodayDateAsString, { getFutureDateAsString } from '../../utils/date';

const DateRangeComponent = ({
  form,
  isUpdate = false,
  initialValues = null,
}) => {
  const [licenceStartDate, setLicenceStartDate] = useState(
    isUpdate && initialValues.licenceStartDate
      ? initialValues.licenceStartDate
      : undefined,
  );
  const [licenceEndDate, setLicenceEndDate] = useState(
    isUpdate && initialValues.expireAt ? initialValues.expireAt : undefined,
  );
  const [isDateRangeValid, setIsDateRangeValid] = useState(true);

  const onStartDateChange = (event) => {
    setLicenceStartDate(event.target.value);
  };

  const onEndDateChange = (event) => {
    setLicenceEndDate(event.target.value);
  };

  const dateRangeValidator = () => {
    if (!isDateRangeValid) {
      return Promise.reject(new Error('Invalid date range'));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    let tempStartDate = getTodayDateAsString();
    let tempEndDate = getFutureDateAsString();
    if (licenceStartDate) {
      tempStartDate = licenceStartDate;
    }
    if (licenceEndDate) {
      tempEndDate = licenceEndDate;
    }
    const isValid = tempStartDate < tempEndDate;
    if (isValid) {
      form.validateFields(['licenceStartDate', 'expireAt']).then();
    }
    setIsDateRangeValid(isValid);
  }, [licenceStartDate, licenceEndDate]);

  return (
    <>
      <Col span={12}>
        <Form.Item
          label="Licence: Start Date"
          className="admin-input-label"
          name="licenceStartDate"
          rules={[
            {
              validator: dateRangeValidator,
            },
          ]}
        >
          <Input
            type="date"
            placeholder="dd/mm/yy"
            className="admin-input"
            onChange={onStartDateChange}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="End Date"
          className="admin-input-label"
          name="expireAt"
          rules={[
            {
              validator: dateRangeValidator,
            },
          ]}
        >
          <Input
            type="date"
            placeholder="dd/mm/yy"
            className="admin-input"
            onChange={onEndDateChange}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default DateRangeComponent;
