import { init } from '@rematch/core';

import alert from '../../main/models/alert';
import bmc from '../../main/models/bmc';
import valueProposition from '../../main/models/valueProposition';
import competitor from '../../main/models/competitor';
import uniqueSellingPoint from '../../main/models/uniqueSellingPoint';
import customerSegment from '../../main/models/customerSegment';
import getKeepGrow from '../../main/models/getKeepGrow';
import channel from '../../main/models/channel';
import keyActivity from '../../main/models/keyActivity';
import keyPartner from '../../main/models/keyPartner';
import keyResource from '../../main/models/keyResource';
import cost from '../../main/models/cost';
import product from '../../main/models/product';
import revenue from '../../main/models/revenue';
import portfolio from '../../main/models/portfolio';
import portfolioTeammate from '../../main/models/portfolioTeammate';
import portfolioFile from '../../main/models/portfolioFile';
import sustainability from '../../main/models/sustainability';
import testing from '../../main/models/testing';
import testDocument from '../../main/models/testDocument';
import common from '../../main/models/common';
import portfolioStyle from '../../main/models/portfolioStyle';
import portfolioTemplate from '../../main/models/portfolioTemplate';

const store = init({
  models: {
    alert,
    bmc,
    channel,
    common,
    competitor,
    cost,
    customerSegment,
    getKeepGrow,
    keyActivity,
    keyPartner,
    keyResource,
    portfolio,
    portfolioTeammate,
    portfolioTemplate,
    portfolioFile,
    portfolioStyle,
    product,
    revenue,
    sustainability,
    testing,
    testDocument,
    uniqueSellingPoint,
    valueProposition,
  },
});

export default store;
