import React from 'react';

export default function MessageItemComponent({
  title = null,
  unreadMessageCount = 0,
  unreadMentionCount = 0,
}) {
  return (
    <div className="message-item-component">
      <div className="message-item-component__avatar">P</div>
      <div className="message-item-component__title">{title}</div>
      <div className="message-item-component__badges">
        {unreadMessageCount && (
          <div className="message-item-component__unread-message-count">
            {unreadMessageCount}
          </div>
        )}
        {unreadMentionCount > 0 && (
          <div className="message-item-component__unread-mention">@</div>
        )}
      </div>
    </div>
  );
}
