import React from 'react';
import { Row, Col } from 'antd';
import Avatar from '../../components/Avatar';
import CellFullNameWithEmail from '../../components/UserList/components/CellFullNameWithEmail';
import Modal from '../../../main/components/Modal';
import ModalContainer from '../../components/ModalContainer';
import ModalHeader from '../../components/ModalHeader';
import { Divider } from '../../components/Divider';
import Spinner from '../../../main/components/Spinner';

const ModalBmcTeamMembers = ({ visible, onClose, users, loading = false }) => {
  return (
    visible && (
      <Modal onBlackscreenClick={onClose} size="sm">
        <ModalContainer>
          <ModalHeader title="Team member time" onClose={onClose} />
          <div className="team-members-modal">
            {loading && (
              <div className="team-members-modal__loading">
                <Spinner />
              </div>
            )}
            {!loading && (
              <>
                <Row>
                  <Col span={16}>
                    <div className="team-members-modal__title">
                      Team members:
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className="team-members-modal__title">
                      Activity Total
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className="team-members-modal__title">
                      Last 30 days
                    </div>
                  </Col>
                </Row>
                {users.map((user) => (
                  <Row
                    align="middle"
                    className="team-members-modal__row"
                    key={user.id}
                  >
                    <Col span={16}>
                      <div className="team-members-modal__avatar">
                        <Avatar
                          size="lg"
                          alt={user.attributes.email}
                          fullName={user.attributes.name}
                          url={user.attributes.image.cropped}
                        />
                        <CellFullNameWithEmail
                          user={{
                            fullName: user.attributes.name,
                            email: user.attributes.email,
                          }}
                        />
                      </div>
                    </Col>
                    <Col span={4}>
                      <span className="team-members-modal__value">
                        {user.attributes.siteTotalUsage} min
                      </span>
                    </Col>
                    <Col span={4}>
                      <span className="team-members-modal__value">
                        {user.attributes.siteLastTotalUsage} min
                      </span>
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </div>
          <Divider />
        </ModalContainer>
      </Modal>
    )
  );
};
export default ModalBmcTeamMembers;
