import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { truncate } from 'lodash';
import { useMediaQuery } from '@material-ui/core';

import CustomerSegmentCard from '../../customerSegment/CardColourfulArch';
import SvgIcon from '../../../components/SvgIcon';
import ImageDrop from '../../../components/ImageDrop/ImageDropCS';
import {
  BUTTON_LIGHT_MODAL_STYLE_COLOURFUL,
  BUTTON_MODAL_STYLE_COLOURFUL,
  COLOURFUL_TEMPLATE_NAME,
  COMMENT_SEGMENT,
  IMAGE_ASPECT_RATIO_CS_COLOURFUN,
  IMAGE_ASPECT_RATIO_ONE,
  TITLE_MODAL_STYLE_COLOURFUL,
} from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import SectionHeader from '../../../components/SectionHeader';
import { getImageAspectRatio } from '../../../utils/helpers/getImageAspectRatio';

const CustomerSegmentColourful = ({
  data: { description },
  locales,
  locales: {
    portfolio,
    customerSegment: {
      intro: { card },
    },
  },
  background,
  firstSvgColor,
  secondSvgColor,
  disabled = false,
}) => {
  const [activeCardId, setActiveCardId] = useState(null);

  const {
    customerSegment: { updateCustomerSegment },
    portfolio: { updateSectionField },
  } = useDispatch();

  const { collection } = useSelector((state) => state.customerSegment);

  const activeCardData = useMemo(() => {
    if (collection.length && !activeCardId) {
      setActiveCardId(collection[0].id);
      return collection[0];
    }
    return collection.find((i) => i.id === activeCardId);
  }, [collection, activeCardId]);

  const isSmallDesktop = useMediaQuery('(max-width: 1024px)');

  const singleCard = activeCardData && (
    <CustomerSegmentCard
      locales={locales}
      uploadFileHandler={updateCustomerSegment}
      dropzoneMode
      blur={false}
      disabled={disabled}
      editMode={!disabled}
      textareaWrapperStyle="textarea-colourful"
      textareaStyle="textarea-colourfulFun"
      rangeStyle="textarea-colourfulFun-cardCS"
      card={{
        ...card,
        ...activeCardData,
        subtitle: activeCardData.name,
        title: null,
      }}
    />
  );

  const singleImage = activeCardData && (
    <ImageDrop
      locales={locales}
      uploadFileHandler={updateCustomerSegment}
      dropzoneMode
      disabled={disabled}
      editMode={!disabled}
      templateName={COLOURFUL_TEMPLATE_NAME}
      aspectRatio={getImageAspectRatio(
        isSmallDesktop,
        IMAGE_ASPECT_RATIO_ONE,
        IMAGE_ASPECT_RATIO_CS_COLOURFUN,
      )}
      firstSvgColor={firstSvgColor}
      secondSvgColor={secondSvgColor}
      titleStyle={TITLE_MODAL_STYLE_COLOURFUL}
      buttonStyle={BUTTON_MODAL_STYLE_COLOURFUL}
      lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_COLOURFUL}
      isTemplates
      isColourfulfunTemplate
      card={{
        ...card,
        ...activeCardData,
        subtitle: activeCardData.name,
        title: null,
      }}
    />
  );

  return (
    <div className="customer-segment-colourful section">
      <div className="left" style={{ backgroundColor: background }}>
        <div className="svg-funCSfirst" style={{ fill: firstSvgColor }}>
          <SvgIcon name="funCSfirst" />
        </div>
        <div className="svg-funCSsecond" style={{ fill: secondSvgColor }}>
          <SvgIcon name="funCSsecond" />
        </div>
        {singleImage}
      </div>
      <div className="right relative">
        <PortfolioCommentIcon title={COMMENT_SEGMENT} />
        <SectionHeader
          templateName={COLOURFUL_TEMPLATE_NAME}
          title={portfolio.customerSegment.sectionName}
          description={description}
          onChange={(e) =>
            updateSectionField({
              sectionName: 'customerSegment',
              field: 'description',
              name: 'csText',
              value: e.target.value,
            })
          }
          portfolio={portfolio}
          disabled={disabled}
        />

        <div className="cards-section">
          <div className="tabs">
            {collection.map(({ id, name }) => (
              <div
                key={id}
                onClick={() => setActiveCardId(id)}
                className={classnames(
                  'item',
                  {
                    active: activeCardId === id,
                  },
                  'custom-font-body',
                )}
              >
                {truncate(name, { length: 45 })}
              </div>
            ))}
          </div>
          <div className="main">{singleCard}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSegmentColourful;
