import React from 'react';

const CustomerRelationshipsCard = ({ block: { name, description } }) => {
  return (
    <div className="block">
      <div className="block-title">{name}</div>
      <div className="block-description">{description}</div>
    </div>
  );
};

export default CustomerRelationshipsCard;
