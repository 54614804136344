import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import classNames from 'classnames';

const FormInput = ({
  name,
  onChange,
  defaultValue,
  value,
  className,
  delay = false,
  label = '',
  ...props
}) => {
  const debouncedOnChange = useCallback(debounce(onChange, delay), [
    onChange,
    delay,
  ]);

  const onInputChange = (event) => {
    if (delay) {
      event.persist();
      debouncedOnChange(event);
    } else {
      onChange(event);
    }
  };

  if (typeof defaultValue !== 'undefined') {
    props.defaultValue = defaultValue;
  } else {
    props.value = value || '';
  }

  return (
    <div className="form-field">
      {label && (
        <label className="label" htmlFor={name}>
          {label}
        </label>
      )}
      <input
        {...props}
        id={name}
        name={name}
        onChange={onInputChange}
        className={classNames('bmc-form-textarea', 'input', className)}
      />
    </div>
  );
};

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  delay: PropTypes.number,
  className: PropTypes.string,
};

export default FormInput;
