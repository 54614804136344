import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import TableHeader from '../../components/TableHeader';
import Table from '../../components/Table';
import ModalCreateEstablishment from '../ModalEstablishment/ModalCreateEstablishment';
import ButtonSetting from '../../components/ButtonSetting';
import ModalUpdateEstablishment from '../ModalEstablishment/ModalUpdateEstablishment';
import { MAX_LICENSES_COUNT } from '../../utils/const';

const columns = ({ openUpdateEstablishmentModal }) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Educator',
    dataIndex: 'lecturersCount',
    key: 'lecturersCount',
  },

  {
    title: 'Class',
    dataIndex: 'classesCount',
    key: 'classesCount',
  },
  {
    title: 'Project',
    dataIndex: 'bmcCount',
    key: 'bmcCount',
  },
  {
    title: 'Student',
    dataIndex: 'studentTotal',
    key: 'studentTotal',
  },
  {
    title: 'Active Student',
    dataIndex: 'activeStudents',
    key: 'activeStudents',
  },
  {
    title: 'License expire',
    dataIndex: 'expireAt',
    key: 'expireAt',
    render: (expireAt) => {
      const date = new Date(expireAt);
      moment.locale('en');
      return moment(date).format('DD MMM YYYY');
    },
  },
  {
    title: 'Licenses',
    dataIndex: 'maxLicensesCount',
    key: 'maxLicensesCount',
    render: (maxLicensesCount) =>
      maxLicensesCount === MAX_LICENSES_COUNT ? '-' : maxLicensesCount,
  },
  {
    title: '',
    key: 'id',
    render: (record) => (
      <ButtonSetting
        onClick={(event) => {
          event.stopPropagation();
          openUpdateEstablishmentModal(record.id);
        }}
      />
    ),
  },
];

const EstablishmentLists = ({ locales, rowClick, rowClickable }) => {
  const [search, setSearch] = useState(undefined);

  const [visibleCreateEstablishmentModal, setVisibleCreateEstablishmentModal] =
    useState(false);

  const [updateEstablishmentId, setUpdateEstablishmentId] = useState(null);

  const {
    establishments: { data, meta, loading },
    establishment: { uploading },
    pagination: { pageSize: initPageSize },
  } = useSelector((state) => state);

  const {
    establishments: { fetchEstablishments, reload, sortEstablishments },
    establishment: { createEstablishment },
    admin: { clearSelectedCreatedAdmins },
  } = useDispatch();

  const handleCloseCreateEstablishmentModal = () => {
    setVisibleCreateEstablishmentModal(false);
    clearSelectedCreatedAdmins();
  };

  const handleOpenCreateEstablishmentModal = () => {
    setVisibleCreateEstablishmentModal(true);
  };

  const handleCloseUpdateEstablishmentModal = () => {
    setUpdateEstablishmentId(null);
  };

  const handleOpenUpdateEstablishmentModal = (establishmentId) => {
    setUpdateEstablishmentId(establishmentId);
  };

  const reloadList = () => reload();

  const handleCreateEstablishment = (values, afterSuccess) => {
    createEstablishment({
      values,
      after: [handleCloseCreateEstablishmentModal, reloadList, afterSuccess],
    });
  };

  useEffect(() => {
    fetchEstablishments({ search, limit: initPageSize });
  }, [search, initPageSize]);
  const handleSort = (values) => {
    sortEstablishments(values);
  };
  return (
    <>
      <ModalCreateEstablishment
        visible={visibleCreateEstablishmentModal}
        onClose={handleCloseCreateEstablishmentModal}
        onFinish={handleCreateEstablishment}
        uploading={uploading}
      />
      <ModalUpdateEstablishment
        establishmentId={updateEstablishmentId}
        onClose={handleCloseUpdateEstablishmentModal}
        locales={locales}
      />
      <TableHeader
        title="Establishments"
        count={loading ? <Spin /> : meta?.count || 0}
        textCreate="Create New Establishment"
        onCreate={handleOpenCreateEstablishmentModal}
        onSearch={(searchValue) => {
          setSearch(searchValue);
        }}
      />
      <Table
        columns={columns({
          openUpdateEstablishmentModal: handleOpenUpdateEstablishmentModal,
        })}
        dataSource={data}
        loading={loading}
        total={meta?.count || 0}
        current={meta?.page || 1}
        onChange={(pagination) => {
          const { current, pageSize } = pagination;
          fetchEstablishments({
            page: current,
            limit: pageSize,
          });
        }}
        onRow={(record) => ({
          onClick: () => rowClick(record),
        })}
        rowClickable={rowClickable}
        onSort={handleSort}
        meta={meta}
      />
    </>
  );
};

EstablishmentLists.propTypes = {
  rowClick: PropTypes.func,
  locales: PropTypes.object.isRequired,
  rowClickable: PropTypes.bool,
};
export default EstablishmentLists;
