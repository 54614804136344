import React, { useRef } from 'react';
import Slider from 'react-slick';

import Card from '../main/containers/ideation/Card';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

export default function index({ locales, ideas }) {
  const slick = useRef(null);

  const {
    list: { userDontHaveBusinessIdeas },
    intro: { card },
  } = locales.ideation;

  const getIdeaData = (idea) => {
    const {
      data: { ideas: _ideas, pickedIdeaId },
    } = idea;
    const pickedIdea = _ideas && _ideas.find((e) => e.id === pickedIdeaId);

    return pickedIdea || {};
  };

  const getCardData = (idea) => {
    return {
      ...card,
      url: null,
      problem: '',
      solution: '',
      marketSize: 0,
      price: 0,
      excitement: 0,
      ...getIdeaData(idea),
      id: idea.id,
    };
  };

  const handlePrevSlide = () => {
    if (slick.current) {
      slick.current.slickPrev();
    }
  };

  const handleNextSlide = () => {
    if (slick.current) {
      slick.current.slickNext();
    }
  };

  return (
    <div className="bmc-main">
      <div className="ideation-list">
        {ideas.length > 0 ? (
          <>
            <div className="ideation-list-items">
              {ideas.length > 1 && (
                <div
                  onClick={handlePrevSlide}
                  className="ideation-list-items-arrow ideation-list-items-arrow--left"
                >
                  <i className="icon-Arrow-Top" />
                </div>
              )}

              <Slider ref={slick} {...settings}>
                {ideas.map((idea) => (
                  <Card
                    card={getCardData(idea)}
                    bmc={{}}
                    key={idea.id}
                    className="ideation-card--white"
                    locales={locales}
                    showEditButton
                  />
                ))}
              </Slider>

              {ideas.length > 1 && (
                <div
                  onClick={handleNextSlide}
                  className="ideation-list-items-arrow ideation-list-items-arrow--right"
                >
                  <i className="icon-Arrow-Top" />
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="ideation-list-empty">
            <div className="ideation-list-empty-text">
              {userDontHaveBusinessIdeas}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
