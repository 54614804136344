import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import humps from 'humps';

import Input from '../signUp/Input';
import Button from '../../main/components/Button';
import NewPasswordSchema from './validationSchema';
import * as Route from '../../main/utils/serverRoutes';
import { NOTIFICATION_TYPES } from '../../main/utils/const';

const redirectAfter = 3000;

const printErrors = (error) => {
  const {
    errors: { resetPasswordToken, password, passwordConfirmation },
  } = humps.camelizeKeys(error.response.data);
  const errors = [];

  if (resetPasswordToken && resetPasswordToken.length)
    errors.push('Reset Password Token', resetPasswordToken.join(', '));
  if (password && password.length) errors.push('Password', password.join(', '));
  if (passwordConfirmation && passwordConfirmation.length)
    errors.push('Password Confirmation', passwordConfirmation.join(', '));

  return errors.join(' ');
};

const prepareValues = ({
  password,
  passwordConfirmation,
  resetPasswordToken,
}) => {
  return {
    user: {
      password,
      passwordConfirmation,
      resetPasswordToken,
    },
  };
};

const NewPasswordForm = ({ locales, locales: { onboarding: pageLocales } }) => {
  const {
    notification: { addNotification },
  } = useDispatch();
  const history = useHistory();
  const { resetPasswordToken } = useParams();

  const initialValues = {
    password: '',
    passwordConfirmation: '',
    resetPasswordToken,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={NewPasswordSchema}
      onSubmit={(values) => {
        axios
          .put(Route.passwords(), prepareValues(values))
          .then((response) => {
            addNotification({
              text: response.data.message,
              type: NOTIFICATION_TYPES.success,
            });
            setTimeout(() => {
              history.push('/');
            }, redirectAfter);
          })
          .catch((error) => {
            addNotification({ text: printErrors(error) });
          });
      }}
    >
      {({ errors }) => {
        return (
          <Form className="sign-up-form sign-up-form--sign-in">
            <>
              <h3 className="sign-up-form__title">
                {pageLocales.titles.newPassword}
              </h3>
              <Field
                locales={locales}
                name="password"
                component={Input}
                label={pageLocales.inputLabels.password}
                placeholder={pageLocales.inputPlaceholders.password}
                type="password"
                className="margin-bottom-20"
              />
              <Field
                locales={locales}
                name="passwordConfirmation"
                component={Input}
                label={pageLocales.inputLabels.passwordConfirmation}
                placeholder={pageLocales.inputPlaceholders.passwordConfirmation}
                type="password"
                className="margin-bottom-20"
              />
              <div className="sign-up-form__actions">
                <Button
                  title={pageLocales.buttons.submit}
                  className="bmc-button--sign-up"
                  type="submit"
                  disabled={errors.email}
                />
              </div>
              <div className="sign-up-form__actions margin-top-20">
                <Link to="/onboarding/sign_in">
                  {pageLocales.common.signIn}
                </Link>
              </div>
            </>
          </Form>
        );
      }}
    </Formik>
  );
};

NewPasswordForm.propTypes = {
  locales: PropTypes.object.isRequired,
};

export default NewPasswordForm;
