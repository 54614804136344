import React from 'react';
import classNames from 'classnames';

import SvgIcon from './SvgIcon';

export default function MobileMenuHeader({ icon, title, color }) {
  return (
    <div className="bmc-menu-header">
      <SvgIcon name={icon} />
      <div className={classNames('bmc-menu-header-title', color)}>{title}</div>
    </div>
  );
}
