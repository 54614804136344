import { init } from '@rematch/core';
import selectPlugin from '@rematch/select';

import valueProposition from '../models/valueProposition';
import uniqueSellingPoint from '../models/uniqueSellingPoint';
import competitor from '../models/competitor';
import customerSegment from '../models/customerSegment';
import getKeepGrow from '../models/getKeepGrow';
import channel from '../models/channel';
import bmc from '../models/bmc';
import keyActivity from '../models/keyActivity';
import keyPartner from '../models/keyPartner';
import keyResource from '../models/keyResource';
import cost from '../models/cost';
import product from '../models/product';
import revenue from '../models/revenue';
import portfolio from '../models/portfolio';
import portfolioTeammate from '../models/portfolioTeammate';
import portfolioTemplate from '../models/portfolioTemplate';
import portfolioStyle from '../models/portfolioStyle';
import portfolioFile from '../models/portfolioFile';
import sustainability from '../models/sustainability';
import user from '../models/user';
import notification from '../models/notification';
import ideation from '../models/ideation';
import testing from '../models/testing';
import testDocument from '../models/testDocument';
import alert from '../models/alert';
import common from '../models/common';
import connection from '../models/connection';
import progress from '../models/progress';
import users from '../../admin/models/users';
import bmcProgress from '../models/bmcProgress';
import chatGroup from '../models/chatGroup';
import message from '../models/message';

const store = init({
  models: {
    alert,
    bmc,
    channel,
    cost,
    competitor,
    common,
    connection,
    customerSegment,
    getKeepGrow,
    keyActivity,
    keyPartner,
    keyResource,
    ideation,
    notification,
    portfolio,
    portfolioTeammate,
    portfolioFile,
    portfolioTemplate,
    portfolioStyle,
    product,
    revenue,
    sustainability,
    testing,
    testDocument,
    user,
    valueProposition,
    uniqueSellingPoint,
    progress,
    users,
    bmcProgress,
    chatGroup,
    message,
  },
  plugins: [selectPlugin()],
});

export const { select } = store;

export default store;
