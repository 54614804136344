import React, { useMemo } from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { merge } from 'lodash';

import Dropzone from '../../components/Dropzone';
import Textarea from '../../components/Textarea';
import {
  CUSTOMER_SEGMENT_TYPE_OPTIONS,
  CUSTOMER_SEGMENT_AGE_RANGE_MAX,
  CUSTOMER_SEGMENT_AGE_RANGE_MIN,
  CUSTOMER_SEGMENT_COMPANY_SIZE_MIN,
  CUSTOMER_SEGMENT_COMPANY_SIZE_MAX,
  CUSTOMER_SEGMENT_B2B,
  SELECT_STYLES_MODAL,
  CURRENT_TEMPLATE_NAME,
  IMAGE_ASPECT_RATIO_ONE,
} from '../../utils/const';
import { customerSegmentType, selectValue } from '../../utils/helpers';
import RangeWithInput from '../../components/RangeWithInputs';
import { renderImage } from '../../utils/helpers/renderImage';

const getName = (type) => {
  return type === CUSTOMER_SEGMENT_B2B ? 'companySize' : 'ageRange';
};

const blurComponent = (
  <div className="bmc-page-card-blur-wrapper">
    <div className="bmc-page-card-blur" />
  </div>
);

export default function Card({
  blur = false,
  editMode = false,
  dropzoneMode = false,
  portfolioMode = false,
  disabled = false,
  locales,
  className,
  backgroundCard,
  textStyle,
  styleColor,
  backgroundImage,
  templateName = CURRENT_TEMPLATE_NAME,
  titleStyle,
  buttonStyle,
  firstSvgColor,
  secondSvgColor,
  textColor,
  titleImageColor,
  isTemplates = false,
  isColourfulfunTemplate = false,
  card,
  card: {
    id,
    title,
    subtitle,
    image,
    imageCoords,
    ageRange,
    companySize,
    ageRangeTitle,
    companySizeTitle,
    type,
    typeTitle,
    icon,
  },
}) {
  const fields = ['description', 'goals', 'frustrates', 'problems'];

  const {
    customerSegment: {
      updateCustomerSegment,
      updateCustomerSegmentImage,
      updateCustomerSegmentImageCoords,
    },
  } = useDispatch();

  const { uploadingImage } = useSelector((state) => state.customerSegment);

  const [cardTitle, cardSizeValue, minValue, maxValue] = useMemo(() => {
    if (type === CUSTOMER_SEGMENT_B2B) {
      return [
        companySizeTitle,
        companySize,
        CUSTOMER_SEGMENT_COMPANY_SIZE_MIN,
        CUSTOMER_SEGMENT_COMPANY_SIZE_MAX,
      ];
    }
    return [
      ageRangeTitle,
      ageRange,
      CUSTOMER_SEGMENT_AGE_RANGE_MIN,
      CUSTOMER_SEGMENT_AGE_RANGE_MAX,
    ];
  }, [type, ageRange, companySize]);

  const onImageDrop = ([file], openCropperHandler) => {
    updateCustomerSegmentImage({
      id,
      name: 'image',
      blob: file,
      success: () => {
        openCropperHandler();
      },
    });
  };

  const onImageCropperSave = (croppedAreaPixels, innerCropData, zoom) => {
    updateCustomerSegmentImageCoords({
      id,
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: imageCoords,
    });
  };

  const optionStyles = {
    option: (styles) => {
      return {
        ...styles,
        fontFamily: 'inherit',
      };
    },
  };

  return (
    <div className="bmc-page-card-wrapper">
      <div
        className={classNames(
          'bmc-page-card bmc-page-card--customer-segment-mode',
          className,
          { 'bmc-page-card--portfolio-mode': portfolioMode },
        )}
        style={{ background: backgroundCard }}
      >
        <div className="bmc-page-card-inner">
          {title && (
            <div className="bmc-page-card-row">
              <img src={icon} className="bmc-page-card-icon" alt="" />
              <div className="bmc-page-card-big-title">{title}</div>
            </div>
          )}

          <div className="bmc-page-card-row">
            {editMode ? (
              <Textarea
                delay={700}
                value={subtitle}
                className={`bmc-form-textarea ${textStyle} custom-font-title`}
                onChange={(e) =>
                  updateCustomerSegment({
                    name: 'name',
                    value: e.target.value,
                    id,
                  })
                }
              />
            ) : (
              <div
                className={`bmc-page-card-big-title bmc-page-card-big-title--customer-title ${textStyle} custom-font-title`}
                style={{ color: textColor }}
              >
                {subtitle}
              </div>
            )}
          </div>

          <div
            className="bmc-page-card-line"
            style={{ background: styleColor }}
          />
          {editMode || dropzoneMode ? (
            <Dropzone
              className="bmc-page-card-image margin-top-30"
              onDrop={onImageDrop}
              onCropperSave={onImageCropperSave}
              locales={locales}
              image={image}
              coords={imageCoords}
              uploading={uploadingImage === id}
              aspectRatio={IMAGE_ASPECT_RATIO_ONE}
              disabled={disabled}
              cropper
              background={{ background: backgroundImage }}
              firstSvgColor={firstSvgColor}
              secondSvgColor={secondSvgColor}
              titleStyle={titleStyle}
              buttonStyle={buttonStyle}
              isColourfulfunTemplate={isColourfulfunTemplate}
              isTemplates={isTemplates}
            />
          ) : (
            renderImage(
              templateName,
              image,
              backgroundImage,
              locales.portfolio?.customerSegment?.sectionName,
              IMAGE_ASPECT_RATIO_ONE,
              titleImageColor,
            )
          )}
        </div>

        <div className="bmc-page-card-inner">
          <div className="bmc-page-card-row">
            {!!type && (
              <div className="bmc-page-card-inner">
                <div
                  className={`bmc-page-card-title ${textStyle} custom-font-body`}
                  style={{ color: textColor }}
                >
                  {cardTitle}
                </div>
                {editMode ? (
                  <RangeWithInput
                    locales={locales}
                    enableRange={false}
                    value={cardSizeValue}
                    minValue={minValue}
                    maxValue={maxValue}
                    delay={700}
                    onChange={(value) =>
                      updateCustomerSegment({
                        name: getName(type),
                        value,
                        id,
                      })
                    }
                    textStyle={textStyle}
                  />
                ) : (
                  <div
                    className={`bmc-page-card-text ${textStyle} custom-font-body`}
                    style={{ color: textColor }}
                  >
                    {cardSizeValue.min} - {cardSizeValue.max}
                  </div>
                )}
              </div>
            )}

            <div className="bmc-page-card-inner">
              <div
                className={`bmc-page-card-title ${textStyle} custom-font-body`}
                style={{ color: textColor }}
              >
                {typeTitle}
              </div>
              {editMode ? (
                <Select
                  styles={merge(SELECT_STYLES_MODAL, optionStyles)}
                  options={CUSTOMER_SEGMENT_TYPE_OPTIONS}
                  value={selectValue(type, customerSegmentType(type))}
                  className={`bmc-form-select ${textStyle} custom-font-body`}
                  onChange={({ value }) =>
                    updateCustomerSegment({ id, name: 'type', value })
                  }
                />
              ) : (
                <div
                  className={`bmc-page-card-text ${textStyle} custom-font-body`}
                  style={{ color: textColor }}
                >
                  {customerSegmentType(type)}
                </div>
              )}
            </div>
          </div>

          {fields.map((field, index) => (
            <div className="bmc-page-card-row" key={index}>
              <div
                className={`bmc-page-card-title ${textStyle} custom-font-body`}
                style={{ color: textColor }}
              >
                {card[`${field}Title`]}
              </div>
              {editMode ? (
                <Textarea
                  value={card[field]}
                  delay={700}
                  className={`bmc-form-textarea ${textStyle} custom-font-body`}
                  onChange={(e) =>
                    updateCustomerSegment({
                      id,
                      name: field,
                      value: e.target.value,
                    })
                  }
                />
              ) : (
                <div
                  className={`bmc-page-card-text ${textStyle} custom-font-body`}
                  style={{ color: textColor }}
                >
                  {card[field]}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {blur && blurComponent}
    </div>
  );
}
