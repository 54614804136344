import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import Select from 'react-select';
import UserList from '../../components/UserList';
import UserListTitle from '../../components/UserListTitle';
import { SELECT_STYLES } from '../../../main/utils/const';

const SelectUser = ({
  users,
  selectedIds,
  columns,
  onSelect = () => {},
  onDelete = () => {},
  loading,
  labelOfList,
  labelOfSelect,
  placeholderOfSelect,
  avatarVisible,
  getAvatarUrl,
  validateStatus = 'success',
  help = null,
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    setSelectedUsers(
      users.filter((user) => selectedIds.some((id) => id === user.id)),
    );
    setUserList(
      users.filter((user) => !selectedIds.some((id) => id === user.id)),
    );
  }, [selectedIds, users]);

  return (
    <>
      <Form.Item
        label={labelOfSelect}
        className="admin-input-label"
        validateStatus={validateStatus}
        help={help}
      >
        <Select
          options={userList.map((user) => ({
            label: user.email,
            value: user.email,
            id: user.id,
          }))}
          className="bmc-form-select"
          styles={SELECT_STYLES}
          placeholder={placeholderOfSelect}
          isLoading={loading}
          onChange={({ id }) => onSelect(id)}
        />
      </Form.Item>
      <UserListTitle title={labelOfList} />
      <UserList
        users={selectedUsers}
        columns={columns}
        avatarVisible={avatarVisible}
        getAvatarUrl={getAvatarUrl}
        onDelete={onDelete}
      />
    </>
  );
};

SelectUser.propTypes = {
  ...UserList.propTypes,
  onSelect: PropTypes.func,
  loading: PropTypes.bool,
  labelOfSelect: PropTypes.string,
  placeholderOfSelect: PropTypes.string,
  labelOfList: PropTypes.string,
  selectedIds: PropTypes.array,
};
export default SelectUser;
