export const getOptionsChart = (
  locales,
  totalCashIn,
  totalCashOut,
  chartStyles,
  templateName,
) => {
  const { finance, chart } = locales;

  const options = {
    chart: {
      type: 'area',
      toolbar: {
        show: false,
      },
      fontFamily: chartStyles[templateName].fontFamily,
      zoom: {
        enabled: false,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: [],
        },
      },
      selection: {
        enabled: false,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      fontFamily: chartStyles[templateName].fontFamily,
      fontSize: 16,
      itemMargin: {
        vertical: 10,
      },
      labels: {
        colors: chartStyles[templateName].textChartColor,
      },
      markers: {
        height: 4,
        width: 80,
        radius: 8,
      },
    },
    stroke: {
      curve: 'straight',
    },
    grid: {
      borderColor: chartStyles[templateName].borderColor,
      clipMarkers: true,
    },
    dataLabels: {
      textAnchor: 'middle',
      style: {
        fontSize: 12,
        fontFamily: chartStyles[templateName].fontFamily,
        fontWeight: 'bold',
      },
      background: {
        enabled: false,
      },
      offsetY: -10,
    },

    markers: {
      strokeColors: chartStyles[templateName].colors,
      size: 3,
    },

    series: [
      {
        name: finance.chart.totalCashIn,
        data: totalCashIn,
      },
      {
        name: finance.chart.totalCashOut,
        data: totalCashOut,
      },
    ],

    colors: chartStyles[templateName].colors,

    xaxis: {
      categories: chart,
      labels: {
        style: { colors: chartStyles[templateName].textChartColor },
      },
    },
    yaxis: {
      labels: {
        style: { colors: chartStyles[templateName].textChartColor },
      },
    },
    tooltip: {
      theme: chartStyles[templateName].themeStyle,
    },
  };
  return options;
};
