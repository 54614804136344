import React from 'react';
import { useDispatch } from 'react-redux';

import Textarea from '../../../components/Textarea';
import FormInput from '../../../components/FormInput';
import { COMMENT_CONTACT } from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import { getColorDependsOnBackgroundAndPalette } from '../../../utils/helpers/portfolioArchTemplate/getColorDependsOnBackgroundAndPalette';

const ContactUsArch = ({
  data: { title, description, fullName, emailOrWebsite },
  locales: { portfolio, portfolioWeb },
  backgroundSection,
  colorPaletteNumber,
  disabled = false,
}) => {
  const scrollToTop = () => {
    const element = document.getElementById('scroll');
    element.scrollIntoView();
  };

  const {
    portfolio: { updateSectionField },
  } = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateSectionField({
      sectionName: 'contactUs',
      field: name,
      value,
      name,
    });
  };

  const textColor = getColorDependsOnBackgroundAndPalette(
    colorPaletteNumber,
    backgroundSection,
  );

  return (
    <div
      className="contact-us-architectural relative"
      style={{ backgroundColor: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_CONTACT} />
      <div className="header">
        <div
          className="architectural-title title custom-font-title"
          style={{ color: textColor }}
        >
          {portfolio.contactUs.sectionName}
        </div>
        <div>
          <div
            className="scroll-top custom-font-body"
            style={{ backgroundColor: backgroundSection, color: textColor }}
            onClick={scrollToTop}
          >
            {portfolioWeb.contactUs.scrollTop}
            <i className="icon-Arrow-Top" />
          </div>
        </div>
      </div>
      <div className="fields">
        <div className="textarea-arch description">
          <Textarea
            name="textarea"
            rows={1}
            delay={700}
            value={description}
            onChange={(e) =>
              updateSectionField({
                sectionName: 'contactUs',
                field: 'description',
                name: 'contactUsText',
                value: e.target.value,
              })
            }
            className="textarea-architectural textarea-architectural-testing custom-font-body"
            style={{ color: textColor }}
            placeholder={portfolio.page.descriptionPlaceholder}
            disabled={disabled}
          />
        </div>
        <FormInput
          name="fullName"
          defaultValue={fullName}
          delay={700}
          placeholder={portfolio.contactUs.newForm.fullName}
          onChange={handleChange}
          disabled={disabled}
          className="form_input custom-font-body"
          style={{ color: textColor }}
        />
        <FormInput
          name="emailOrWebsite"
          defaultValue={emailOrWebsite}
          delay={700}
          placeholder={portfolio.contactUs.newForm.emailOrWebsite}
          onChange={handleChange}
          disabled={disabled}
          className="form_input custom-font-body"
          style={{ color: textColor }}
        />
      </div>

      <div
        className="footer custom-font-body"
        style={{ borderColor: textColor, color: textColor }}
      >
        <div>{`© ${title}. ${portfolioWeb.contactUs.rightsReserve}`}</div>
        <div>
          {portfolioWeb.contactUs.buildCreate}
          <span className="company-name" style={{ color: textColor }}>
            {portfolioWeb.contactUs.companyName}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactUsArch;
