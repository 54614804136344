import React from 'react';
import { useDispatch } from 'react-redux';

import {
  COMMENT_FEASIBILITY,
  TECH_MODERN_TEMPLATE_NAME,
} from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';

const FeasibilityTech = ({
  data: { description },
  locales: { portfolio },
  backgroundSection,
  colorPaletteNumber,
}) => {
  const {
    portfolio: { updateSectionField },
  } = useDispatch();

  return (
    <div
      className="feasibility-tech relative"
      style={{ background: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_FEASIBILITY} />
      <SectionHeader
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolio.feasibility.newSectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'feasibility',
            field: 'description',
            name: 'feasibilityText',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        colorPaletteNumber={colorPaletteNumber}
        rows={5}
      />
    </div>
  );
};

export default FeasibilityTech;
