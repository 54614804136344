import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Textarea from '../../../../components/Textarea';
import UploadsBlock from './UploadsBlock';

const TestPlanBlock = ({
  handleChange,
  textStyle,
  textColor,
  linkColor,
  isEditable = true,
}) => {
  const {
    testDocument: { collection, uploadingPlanning },
    testing: { test },
  } = useSelector((state) => state);

  const planningCollection = collection.filter(
    (e) => e.documentType === 'planning',
  );
  const isHomepage = test.test === 'homepage';

  return (
    <div className="test-card__section">
      <div className={classNames('left', { 'is-full-width': isHomepage })}>
        <div className="block">
          <div
            className={`title ${textStyle} custom-font-body`}
            style={{ color: textColor }}
          >
            Test Plan
          </div>
          <div
            className={classNames('content', { 'is-full-width': !isHomepage })}
          >
            {isHomepage && test?.id && (
              <a
                target="_blank"
                href={`${window.location.origin}/tests/${test.id}`}
                className={`${textStyle} custom-font-body`}
                style={{ color: linkColor }}
              >
                {`${window.location.origin}/tests/${test.id}`}
              </a>
            )}
            {!isHomepage &&
              (isEditable ? (
                <Textarea
                  onChange={handleChange}
                  name="data.plan"
                  rows={4}
                  delay={700}
                  value={test.data.plan}
                  className={`bmc-form-textarea ${textStyle} custom-font-body`}
                />
              ) : (
                <div
                  className={`text ${textStyle} custom-font-body`}
                  style={{ color: textColor }}
                >
                  {test.data.plan}
                </div>
              ))}
          </div>
        </div>
      </div>
      {!isHomepage && (
        <UploadsBlock
          collectionType={planningCollection}
          uploadsType="planning"
          uploadingStatus={uploadingPlanning}
          textStyle={textStyle}
          textColor={textColor}
          linkColor={linkColor}
          isEditable={isEditable}
        />
      )}
    </div>
  );
};

export default TestPlanBlock;
