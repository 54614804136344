import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { filterCollection } from '../../../utils/helpers';
import Card from '../../sustainability/Card';
import {
  COMMENT_SUSTAINABILITY,
  TECH_MODERN_TEMPLATE_NAME,
  COLORS,
} from '../../../utils/const';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import SectionHeader from '../../../components/SectionHeader';

const { white } = COLORS;

const SustainabilityTech = ({
  data: { description },
  locales,
  background,
  titleColor,
  styleColor,
  backgroundRow,
  locales: { portfolio },
}) => {
  const {
    portfolio: { updateSectionField },
  } = useDispatch();

  const { collection } = useSelector((state) => state.sustainability);
  const sustainabilityCollection = filterCollection(collection, 'sdg');

  return (
    <div
      className="sustainability-tech section relative"
      style={{ backgroundColor: background }}
    >
      <PortfolioCommentIcon title={COMMENT_SUSTAINABILITY} />
      <SectionHeader
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolio.sustainability.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'sustainability',
            field: 'description',
            name: 'sustainabilityText',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        style={{ color: titleColor }}
      />

      <Card
        locales={locales}
        collection={sustainabilityCollection}
        className="bmc-page-suscard-tech margin-top-40 custom-font-body"
        iconFill={styleColor}
        iconFillTable={styleColor}
        iconTableStyles="sdg-card-tech-icon"
        borderCardColor={`${styleColor}33`}
        borderCardTableColor={`${styleColor}33`}
        backgroundTable={styleColor}
        backgroundCells={white}
        backgroundRow={backgroundRow}
        sliderStyles="sdg-card-tech"
      />
    </div>
  );
};

export default SustainabilityTech;
