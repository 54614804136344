import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImageUploader from '../../../../components/ImageUploader';
import Spinner from '../../../../components/Spinner';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import SvgIcon from '../../../../components/SvgIcon';

const UploadsBlock = ({
  collectionType,
  uploadsType,
  uploadingStatus,
  textStyle,
  textColor,
  linkColor,
  isEditable = true,
}) => {
  const {
    testDocument: { uploading },
    testing: { test },
  } = useSelector((state) => state);

  const {
    testDocument: { addTestDocument, removeTestDocument },
  } = useDispatch();

  return (
    <div className="right">
      <div className="content">
        <div className="block">
          <div
            className={classNames('title', {
              'title--column': collectionType.length === 0,
            })}
          >
            <div
              className={`text ${textStyle} custom-font-body`}
              style={{ color: textColor }}
            >
              Uploads ({uploadsType})
            </div>

            {isEditable && (
              <ImageUploader
                title={uploadingStatus ? 'Uploading...' : 'Upload file'}
                className="add-field custom-font-body"
                textButtonColor={linkColor}
                borderButtonColor={linkColor}
                onChange={([blob]) => {
                  addTestDocument({
                    documentType: uploadsType,
                    blob,
                    testId: test.id,
                  });
                }}
              />
            )}
          </div>

          <div className="content">
            {uploading ? (
              <Spinner className="margin-top-20" />
            ) : (
              <div className="uploaded-files">
                {collectionType.map((document) => (
                  <div
                    key={document.id}
                    className="file"
                    style={{
                      color: linkColor,
                      borderColor: linkColor,
                    }}
                    onClick={() => window.open(document.file, '_blank')}
                  >
                    <i className="icon-Link" />
                    <div className={`text ${textStyle} custom-font-body`}>
                      {document.name}
                    </div>
                    {isEditable && (
                      <ConfirmationModal
                        body="Are you sure?"
                        confirmText="Delete"
                        className="remove-button"
                        onConfirm={() =>
                          removeTestDocument({
                            testId: test.id,
                            id: document.id,
                          })
                        }
                      >
                        <div style={{ fill: linkColor }}>
                          <SvgIcon name="x" />
                        </div>
                      </ConfirmationModal>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadsBlock;
