import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Dropzone from '../../components/Dropzone';
import Textarea from '../../components/Textarea';
import { COLORS, FIRST_COLOR_PALETTE_NUMBER } from '../../utils/const';
import { renderImage } from '../../utils/helpers/renderImage';

const { blue } = COLORS;

export default function CardBlogArch({
  className,
  locales,
  editMode = false,
  disabled = false,
  dropzoneMode = false,
  portfolioMode = false,
  background,
  backgroundImage,
  colorPaletteNumber,
  templateName,
  aspectRatio = 1,
  card: { id, title, name, image, imageCoords, description, descriptionTitle },
  titleStyle,
  buttonStyle,
  lightButtonStyle,
  backgroundModal,
  textColor,
  textImageColor,
  borderImageColor,
  sliderColor = blue,
  isTemplates = false,
}) {
  const {
    channel: { updateChannel, updateChannelImage, updateChannelImageCoords },
  } = useDispatch();

  const { uploadingImage } = useSelector((state) => state.channel);

  const onImageDrop = ([file], openCropperHandler) => {
    updateChannelImage({
      id,
      name: 'image',
      blob: file,
      success: () => {
        openCropperHandler();
      },
    });
  };

  const onImageCropperSave = (croppedAreaPixels, innerCropData, zoom) => {
    updateChannelImageCoords({
      id,
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: imageCoords,
    });
  };

  return (
    <div className="bmc-page-card-wrapper">
      <div
        className={classNames(
          'bmc-page-card bmc-page-card--channel-mode-current',
          className,
          { 'bmc-page-card--portfolio-mode': portfolioMode },
        )}
        style={{ backgroundColor: background }}
      >
        <div className="bmc-page-card-inner">
          {editMode || dropzoneMode ? (
            <div className="image-hover">
              <Dropzone
                className="image"
                background={{ backgroundColor: backgroundImage }}
                borderImageColor={borderImageColor}
                onDrop={onImageDrop}
                onCropperSave={onImageCropperSave}
                locales={locales}
                image={image}
                coords={imageCoords}
                uploading={uploadingImage === id}
                aspectRatio={aspectRatio}
                disabled={disabled}
                titleStyle={titleStyle}
                buttonStyle={buttonStyle}
                lightButtonStyle={lightButtonStyle}
                sliderColor={sliderColor}
                backgroundModal={backgroundModal}
                isTemplates={isTemplates}
                cropper
              />
            </div>
          ) : (
            renderImage(
              templateName,
              image,
              backgroundImage,
              locales.portfolio.channels.sectionName,
              aspectRatio,
              textImageColor,
              borderImageColor,
            )
          )}
        </div>

        <div className="bmc-page-card-inner">
          {title && (
            <div className="bmc-page-card-row">
              <img
                src="/images/mpesa_Ch.png"
                className="bmc-page-card-icon"
                alt=""
              />
              <div
                className={classNames(
                  'bmc-page-card-big-title--with-underline custom-font-body',
                  {
                    'text-white':
                      colorPaletteNumber === FIRST_COLOR_PALETTE_NUMBER,
                  },
                )}
              >
                {title}
              </div>
            </div>
          )}

          <div
            className="bmc-page-card-row bmc-page-card-big-title--with-underline"
            style={{ borderColor: textColor }}
          >
            {editMode ? (
              <Textarea
                value={name}
                delay={700}
                className="textarea-current textarea-current-channel textarea-current-channel-title custom-font-body"
                onChange={(e) =>
                  updateChannel({ name: 'name', value: e.target.value, id })
                }
                disabled={disabled}
              />
            ) : (
              <div
                className={classNames(
                  'bmc-page-card-big-title bmc-page-card-big-title--customer-title custom-font-body',
                  {
                    'text-white':
                      colorPaletteNumber === FIRST_COLOR_PALETTE_NUMBER,
                  },
                )}
                style={{ color: textColor }}
              >
                {name}
              </div>
            )}
          </div>

          <div className="bmc-page-card-row">
            <div
              className={classNames(
                'bmc-page-card-title title custom-font-body',
                {
                  'text-white':
                    colorPaletteNumber === FIRST_COLOR_PALETTE_NUMBER,
                },
              )}
              style={{ color: textColor }}
            >
              {descriptionTitle}
            </div>
            {editMode ? (
              <Textarea
                value={description}
                delay={700}
                className="textarea-current textarea-current-channel custom-font-body"
                onChange={(e) =>
                  updateChannel({
                    name: 'description',
                    value: e.target.value,
                    id,
                  })
                }
                disabled={disabled}
              />
            ) : (
              <div
                className={classNames('bmc-page-card-text custom-font-body', {
                  'text-white':
                    colorPaletteNumber === FIRST_COLOR_PALETTE_NUMBER,
                })}
                style={{ color: textColor }}
              >
                {description}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
