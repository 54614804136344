import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Input, Row, Space, Switch } from 'antd';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Modal from '../../../main/components/Modal';
import ModalContainer from '../../components/ModalContainer';
import ModalHeader from '../../components/ModalHeader';
import { SELECT_STYLES } from '../../../main/utils/const';
import SelectEstablishment from '../Selects/SelectEstablishment';
import generatorPassword from '../../../main/utils/generatorPassword';
import SelectMultipleEstablishments from '../Selects/SelectMultipleEstablishments';

const options = [
  { value: 'admin', label: 'Admin' },
  { value: 'lecturer', label: 'Educator' },
];

const ModalCreateAdminAndLecturer = ({
  onCancel = () => {},
  visible = false,
  uploading = false,
  onCreate = () => {},
}) => {
  const [form] = Form.useForm();
  const { establishmentId = null } = useParams();
  const { user } = useSelector((state) => state);

  const [establishmentOptions, setEstablishmentOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');

  const {
    establishments: {
      data: establishmentList,
      loading: establishmentOptionsLoading,
      createdEstablishments,
    },
    user: { current },
  } = useSelector((state) => state);

  const {
    establishments: {
      fetchEstablishments,
      addCreatedEstablishment,
      removeCreatedEstablishment,
    },
  } = useDispatch();

  useEffect(() => {
    if (current.role === 'super_admin')
      fetchEstablishments({
        page: 1,
        limit: 100000000,
      });
  }, [current]);

  useEffect(() => {
    if (establishmentList) {
      setEstablishmentOptions(establishmentList);
    }
  }, [establishmentList]);

  const handleSelect = (eId) => {
    addCreatedEstablishment({ establishmentId: eId });
  };

  const handleDelete = (eId) => {
    removeCreatedEstablishment({ establishmentId: eId });
  };

  const handleRoleChange = (role) => {
    setSelectedRole(role.value);
  };

  const handleFinish = (values) => {
    if (Array.isArray(values.establishment_id)) {
      const [selectedEstablishmentId] = values.establishment_id;
      values.establishment_id = selectedEstablishmentId;
    }

    if (typeof values.role === 'object') {
      values.role = values.role.value;
    }

    values.establishment_ids = createdEstablishments.map((e) => e.id);
    const afterSuccess = () => {
      form.resetFields();
    };
    onCreate(values, afterSuccess);
  };

  const handleAutoGeneratePassword = (value) => {
    if (value) form.setFieldsValue({ password: generatorPassword() });
  };

  if (visible)
    return (
      <Modal onBlackscreenClick={onCancel}>
        <ModalContainer>
          <ModalHeader title="Create New Admin/Educator" onClose={onCancel} />
          <Form
            layout="vertical"
            onFinish={handleFinish}
            initialValues={{
              establishment_id: Number(establishmentId),
              role: establishmentId ? 'lecturer' : null,
            }}
            form={form}
          >
            <div className="mt-20">
              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    className="admin-input-label"
                    rules={[
                      { required: true, message: 'First name is required' },
                    ]}
                  >
                    <Input placeholder="First name" className="admin-input" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Surname"
                    name="surname"
                    className="admin-input-label"
                    rules={[
                      { required: true, message: 'Surname name is required' },
                    ]}
                  >
                    <Input placeholder="Surname" className="admin-input" />
                  </Form.Item>
                </Col>
              </Row>
              {user?.current?.role === 'super_admin' ? (
                <Form.Item
                  label="Role"
                  name="role"
                  className="admin-input-label"
                  rules={[{ required: true, message: 'Role is required' }]}
                >
                  <Select
                    options={options}
                    className="bmc-form-select"
                    styles={SELECT_STYLES}
                    placeholder="Choose Type"
                    onChange={handleRoleChange}
                  />
                </Form.Item>
              ) : (
                <Form.Item name="role" hidden>
                  <Input />
                </Form.Item>
              )}
              <Form.Item
                label="Email"
                name="email"
                className="admin-input-label"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Email is required',
                  },
                ]}
              >
                <Input
                  placeholder="Add user email"
                  className="admin-input"
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                className="admin-input-label"
                rules={[
                  { required: true, message: 'Password is required' },
                  {
                    min: 8,
                    message: 'Password must be minimum 8 characters.',
                  },
                ]}
              >
                <Input.Password
                  placeholder="Create user password"
                  className="admin-input"
                  autoComplete="new-password"
                />
              </Form.Item>
              <Space>
                <Switch
                  className="admin-switch"
                  onChange={handleAutoGeneratePassword}
                />
                <span className="admin-switch-title">
                  Automatically generate a password
                </span>
              </Space>
            </div>
            {establishmentId === null && selectedRole === 'lecturer' ? (
              <div className="mt-20">
                <Form.Item
                  label="Establishment"
                  name="establishment_id"
                  className="admin-input-label"
                  rules={[
                    { required: true, message: 'Establishment is required' },
                  ]}
                >
                  <SelectEstablishment />
                </Form.Item>
              </div>
            ) : (
              <Form.Item
                name="establishment_id"
                className="admin-input-label"
                hidden
              >
                <Input />
              </Form.Item>
            )}
            {selectedRole === 'admin' && (
              <div className="mt-20">
                <SelectMultipleEstablishments
                  establishments={establishmentOptions}
                  loading={uploading || establishmentOptionsLoading}
                  labelOfSelect="Invite Admin/Lecturer To Join Your Establishment"
                  placeholderOfSelect="Choose establishment by name"
                  labelOfList="Establishments"
                  selectedIds={createdEstablishments.map(
                    (establishment) => establishment.id,
                  )}
                  onSelect={handleSelect}
                  onDelete={handleDelete}
                />
              </div>
            )}
            <div className="flex align-right mt-40">
              <Space>
                <Button onClick={onCancel} disabled={uploading}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={uploading}>
                  Create
                </Button>
              </Space>
            </div>
          </Form>
        </ModalContainer>
      </Modal>
    );

  return <></>;
};

ModalCreateAdminAndLecturer.propTypes = {
  onCancel: PropTypes.func,
  visible: PropTypes.bool,
  uploading: PropTypes.bool,
};
export default ModalCreateAdminAndLecturer;
