import React from 'react';
import { useSelector } from 'react-redux';

import Card from '../../main/containers/sustainability/Card';
import { COLORS, TECH_MODERN_TEMPLATE_NAME } from '../../main/utils/const';
import SectionHeaderWeb from '../../main/components/SectionHeaderWeb';

const { white } = COLORS;

const SustainAbilityTechWeb = ({
  locales,
  locales: { portfolioWeb },
  titleColor,
  styleColor,
  background,
  backgroundRow,
}) => {
  const {
    portfolio: { sustainability },
    sustainability: { collection },
  } = useSelector((state) => state);

  return (
    <div
      className="sustainability-tech-web section-web"
      style={{ backgroundColor: background }}
    >
      <SectionHeaderWeb
        templateName={TECH_MODERN_TEMPLATE_NAME}
        title={portfolioWeb.sustainability}
        description={sustainability.description}
        portfolioWeb={portfolioWeb}
        style={{ color: titleColor }}
      />

      <Card
        locales={locales}
        collection={collection}
        className="bmc-page-suscard-tech margin-top-40 custom-font-body"
        iconFill={styleColor}
        iconFillTable={styleColor}
        iconTableStyles="sdg-card-tech-icon"
        borderCardColor={`${styleColor}33`}
        borderCardTableColor={`${styleColor}33`}
        backgroundTable={styleColor}
        backgroundCells={white}
        backgroundRow={backgroundRow}
        sliderStyles="sdg-card-tech"
      />
    </div>
  );
};

export default SustainAbilityTechWeb;
