import { capitalize } from 'lodash';

import makeRequest, { uploadFile } from '../utils/makeRequest';
import * as Route from '../utils/serverRoutes';
import {
  camelToUnderscore,
  imageAttributes,
  imageCoordAttributes,
} from '../utils/helpers';

const portfolio = {
  state: {
    introduction: {
      showStatus: true,
      title: '',
      description: '',
      introLogo: imageAttributes(),
      introLogoCoords: imageCoordAttributes(),
      introBg: imageAttributes(),
      introBgCoords: imageCoordAttributes(),
    },
    projectSummary: {
      showStatus: true,
      description: '',
    },
    team: {
      showStatus: true,
      description: '',
    },
    bmc: {
      showStatus: true,
      description: '',
    },
    testing: {
      showStatus: true,
      description: '',
    },
    desirabilitySection: {
      showStatus: true,
      description:
        'This section focuses on our customers and the right side of the Business Model Canvas. It presents how we will be desirable to our customers and create and deliver value.',
    },
    competitorAnalysis: {
      showStatus: true,
      description: '',
    },
    customerSegment: {
      showStatus: true,
      description: '',
    },
    valueProposition: {
      showStatus: true,
      description: '',
    },
    customerRelationships: {
      showStatus: true,
      description: '',
    },
    channels: {
      showStatus: true,
      description: '',
    },
    feasibility: {
      showStatus: true,
      description: '',
    },
    keyActivities: {
      showStatus: true,
      description: '',
    },
    viability: {
      showStatus: true,
      description:
        'Viability has traditionally just been about money. Making money is important to run a viable business. But the extreme inequalities and climate crisis we face requires us to think bigger. True viability requires financial, environmental, and social sustainability.',
    },
    finance: {
      showStatus: true,
      description: '',
    },
    sustainability: {
      showStatus: true,
      description: '',
    },
    contactUs: {
      showStatus: true,
      title: '',
      description: '',
      fullName: '',
      emailOrWebsite: '',
    },
    uploading: false,
    uploadingIntrologo: false,
    uploadingIntrobg: false,
  },
  reducers: {
    fetchPortfolioReducer(state, payload) {
      return {
        ...state,
        slug: payload.slug,
        introduction: {
          ...state.introduction,
          title: payload.title,
          description: payload.description,
          introLogo: payload.introLogo,
          introLogoCoords: payload.introLogoCoords,
          introBg: payload.introBg,
          introBgCoords: payload.introBgCoords,
        },
        projectSummary: {
          ...state.projectSummary,
          description: payload.projectSummary,
        },
        team: {
          ...state.team,
          description: payload.teamDescription,
        },
        bmc: {
          ...state.bmc,
          description: payload.bmcText,
        },
        testing: {
          ...state.testing,
          description: payload.testingText,
        },
        desirabilitySection: {
          ...state.desirabilitySection,
          description: payload.desirabilityText,
        },
        competitorAnalysis: {
          ...state.competitorAnalysis,
          description: payload.competitorAnalysisText,
        },
        customerSegment: {
          ...state.customerSegment,
          description: payload.csText,
        },
        valueProposition: {
          ...state.valueProposition,
          description: payload.vpText,
        },
        customerRelationships: {
          ...state.customerRelationships,
          description: payload.gkgText,
        },
        channels: {
          ...state.channels,
          description: payload.channelsText,
        },
        feasibility: {
          ...state.feasibility,
          description: payload.feasibilityText,
        },
        keyActivities: {
          ...state.keyActivities,
          description: payload.operationsText,
        },
        viability: {
          ...state.viability,
          description: payload.viabilityText,
        },
        finance: {
          ...state.finance,
          description: payload.financeText,
        },
        sustainability: {
          ...state.sustainability,
          description: payload.sustainabilityText,
        },
        contactUs: {
          ...state.contactUs,
          title: payload.title,
          description: payload.contactUsText,
          fullName: payload.fullName,
          emailOrWebsite: payload.emailOrWebsite,
        },
      };
    },
    updateSectionFieldReducer(state, { sectionName, field, value }) {
      if (sectionName === 'introduction' && field === 'title') {
        return {
          ...state,
          [sectionName]: {
            ...state[sectionName],
            [field]: value,
          },
          contactUs: {
            title: value,
          },
        };
      }

      return {
        ...state,
        [sectionName]: {
          ...state[sectionName],
          [field]: value,
        },
      };
    },
    updateAttributeReducer(state, data) {
      return { ...state, ...data };
    },
  },
  effects: (dispatch) => ({
    fetchPortfolio(bmcId) {
      dispatch.portfolio.updateAttributeReducer({ uploading: true });

      makeRequest({
        dispatch,
        url: Route.portfolio(bmcId),
        success: (response) => {
          dispatch.portfolio.fetchPortfolioReducer(
            response.data.data.attributes,
          );
        },
        finish: () => {
          dispatch.portfolio.updateAttributeReducer({ uploading: false });
        },
      });
    },
    fetchPortfolioData(bmcId = null, state) {
      const portfolioBmcId = bmcId || state.bmc.current.id;

      dispatch.portfolio.fetchPortfolio(portfolioBmcId);
      dispatch.portfolioTeammate.fetchPortfolioTeammates(portfolioBmcId);
      dispatch.testing.fetchTests(portfolioBmcId);
      dispatch.portfolioTemplate.fetchPortfolioTemplates();
      dispatch.portfolioStyle.fetchPortfolioStyle(bmcId);
    },
    updateIntroductionImage(payload, state) {
      const key = payload.name;
      const coordsKey = `${key}Coords`;
      const uploadingKey = `uploading${capitalize(key)}`;

      dispatch.portfolio.updateAttributeReducer({ [uploadingKey]: true });

      uploadFile({
        url: Route.portfolio(state.bmc.current.id),
        modelName: 'portfolio',
        name: camelToUnderscore(payload.name),
        blob: payload.blob,
        dispatch,
        success: (res) => {
          dispatch.portfolio.updateSectionFieldReducer({
            sectionName: 'introduction',
            field: key,
            value: res.data.data.attributes[key],
          });

          dispatch.portfolio.updateSectionFieldReducer({
            sectionName: 'introduction',
            field: coordsKey,
            value: res.data.data.attributes[coordsKey],
          });

          if (payload.success) payload.success();
        },
        finish: () => {
          dispatch.portfolio.updateAttributeReducer({ [uploadingKey]: false });
        },
      });
    },
    updateIntroductionImageCoords({
      name,
      coords,
      croppedAreaPixels,
      innerCropData,
      zoom,
    }) {
      const nameKey = `${name}Coords`;
      const uploadingKey = `uploading${capitalize(name)}`;
      const value = {
        ...coords,
        ...croppedAreaPixels,
        cropperData: { ...innerCropData, zoom },
      };

      dispatch.portfolio.updateAttributeReducer({ [uploadingKey]: true });

      dispatch.portfolio.updateSectionField({
        sectionName: 'introduction',
        field: nameKey,
        name: nameKey,
        value,
        success: (res) => {
          dispatch.portfolio.updateSectionFieldReducer({
            sectionName: 'introduction',
            field: nameKey,
            value: res.data.data.attributes[nameKey],
          });
        },
        finish: () => {
          dispatch.portfolio.updateAttributeReducer({ [uploadingKey]: false });
        },
      });
    },
    updateSectionField(payload, state) {
      const data = { portfolio: { [payload.name]: payload.value } };
      dispatch.portfolio.updateSectionFieldReducer(payload, state);
      makeRequest({
        dispatch,
        method: 'put',
        url: Route.portfolio(state.bmc.current.id),
        data,
        success: payload.success,
        finish: payload.finish,
      });
    },
  }),
};

export default portfolio;
