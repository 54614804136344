import React from 'react';
import { useDispatch } from 'react-redux';

import {
  COLOURFUL_TEMPLATE_NAME,
  COMMENT_VIABILITY,
} from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import SvgIcon from '../../../components/SvgIcon';

const Viability = ({
  data: { description },
  locales: { portfolio },
  className,
  backgroundSection,
  sectionHeaderTitleColor,
  descriptionColor,
  mainSvgColor,
  templateName,
  disabled = false,
}) => {
  const {
    portfolio: { updateSectionField },
  } = useDispatch();

  return (
    <div
      className={`${className} relative`}
      style={{ backgroundColor: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_VIABILITY} />
      {templateName === COLOURFUL_TEMPLATE_NAME && (
        <div className="svg-funViability" style={{ fill: mainSvgColor }}>
          <SvgIcon name="funViability" />
        </div>
      )}
      <SectionHeader
        templateName={templateName}
        title={portfolio.viability.newSectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'viability',
            field: 'description',
            name: 'viabilityText',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        rows={5}
        style={{ color: sectionHeaderTitleColor }}
        descriptionColor={descriptionColor}
        disabled={disabled}
      />
    </div>
  );
};

export default Viability;
