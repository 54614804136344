import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Progress } from 'antd';

export default function BmcListCard({
  className,
  to,
  data: { title, description, date, progress },
  locales: { common },
}) {
  return (
    <Link className={classNames('bmc-list-card', className)} to={to}>
      <div className="bmc-list-card-left">
        <div className="bmc-list-card-title">{title}</div>
        <div className="bmc-list-card-description">{description}</div>
        <div className="bmc-list-card-percent-progress">
          {progress === null ? (
            <div>...loading</div>
          ) : (
            <Progress
              percent={progress}
              size="default"
              strokeColor="#00AFC9"
              trailColor="#DDE2E5"
            />
          )}
        </div>
        {/* todo: <div className="bmc-list-card-progress">
          <div
            className="bmc-list-card-progress-bar"
            style={{ width: `${progress}%` }}
          />
          <div className="bmc-list-card-progress-number">{progress}%</div>
        </div> */}
      </div>

      <div className="bmc-list-card-right">
        {/* todo: temporary off date */}
        <div className="bmc-list-card-date" style={{ visibility: 'hidden' }}>
          {date}
        </div>

        <Link to={to} className="bmc-button bmc-button--blue bmc-button--start">
          {progress > 0 ? common.resume : common.start}
        </Link>
      </div>
    </Link>
  );
}
