import React from 'react';
import { COLOURFUL_TEMPLATE_NAME } from '../../utils/const';
import Chart from '../../containers/portfolio/Finance/Chart';
import SvgIcon from '../SvgIcon';

const GraphTab = ({
  locales,
  totalCashIn,
  totalCashOut,
  firstChartColor,
  secondChartColor,
  background,
  borderCardColor,
  titleColor,
  textColor,
  templateName,
}) => {
  return (
    <div className="tab-content" id="docx-graph-wrapper">
      {!(templateName === COLOURFUL_TEMPLATE_NAME) && (
        <Chart
          locales={locales}
          totalCashIn={totalCashIn}
          totalCashOut={totalCashOut}
          firstChartColor={firstChartColor}
          secondChartColor={secondChartColor}
          borderCardColor={borderCardColor}
          titleColor={titleColor}
          textColor={textColor}
          templateName={templateName}
        />
      )}
      {templateName === COLOURFUL_TEMPLATE_NAME && (
        <>
          <div className="svg-funGraph" style={{ fill: background }}>
            <SvgIcon name="funGraph" />
          </div>
          <Chart
            locales={locales}
            totalCashIn={totalCashIn}
            totalCashOut={totalCashOut}
            firstChartColor={firstChartColor}
            secondChartColor={secondChartColor}
            borderCardColor={borderCardColor}
            templateName={templateName}
          />
        </>
      )}
    </div>
  );
};

export default GraphTab;
