import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import ChatGroupListContainer from './chat-group-list-container';
import ChatGroupItemContainer from './chat-group-item-container';
import ChatGroupHeaderContainer from './chat-group-header-container';

export default function ChatGroupContainer({ chatGroups }) {
  const {
    bmc,
    chatGroup: { isExpanded, isOpenedLastNotification },
  } = useSelector((selector) => selector);
  const {
    chatGroup: { updateChatGroupAttribute },
  } = useDispatch();

  const [unreadChatGroups, setUnreadChatGroups] = useState([]);

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  useEffect(() => {
    if (
      query.get('openUnreadComment') &&
      !isOpenedLastNotification &&
      !isEmpty(unreadChatGroups)
    ) {
      updateChatGroupAttribute({
        selectedChatGroup: unreadChatGroups[0],
        isExpanded: true,
        commentIsOpened: true,
        isOpenedLastNotification: true,
      });
    }
  }, [unreadChatGroups]);

  return (
    <div
      className={classNames('chat-group-container', {
        'not-expanded': !isExpanded,
      })}
    >
      <ChatGroupHeaderContainer title={bmc?.current?.attributes?.name} />
      <ChatGroupListContainer>
        {chatGroups.map((chatGroup) => (
          <ChatGroupItemContainer
            key={chatGroup.id}
            currentChatGroup={chatGroup}
            unreadChatGroupsCallback={setUnreadChatGroups}
          />
        ))}
      </ChatGroupListContainer>
    </div>
  );
}
