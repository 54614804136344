import React from 'react';

import TeamCard from './TeamCard';
import {
  BOLD_TEMPLATE_NAME,
  BUTTON_LIGHT_MODAL_STYLE_BOLD,
  BUTTON_MODAL_STYLE_BOLD,
  COMMENT_TEAM,
  TITLE_MODAL_STYLE_BOLD,
} from '../../../../utils/const';
import SectionHeader from '../../../../components/SectionHeader';
import PortfolioCommentIcon from '../../../../components/PortfolioCommentIcon';

const TeamBold = ({
  data: { description },
  locales,
  locales: { portfolio },
  collection,
  addPortfolioTeammate,
  updateSectionField,
  titleColor,
  textColor,
  teamNameColor,
  positionColor,
  backgroundSection,
  backgroundImage,
  sliderColor,
  backgroundModal,
  buttonSecondColor,
  buttonFirstColor,
  backgroundSecondButton,
  disabled = false,
}) => {
  return (
    <div
      className="team-bold section relative"
      style={{ backgroundColor: backgroundSection }}
    >
      <PortfolioCommentIcon title={COMMENT_TEAM} />
      <SectionHeader
        templateName={BOLD_TEMPLATE_NAME}
        title={portfolio.team.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'team',
            field: 'description',
            name: 'teamDescription',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        style={{ color: titleColor }}
        descriptionColor={textColor}
        disabled={disabled}
      />

      <div className="team-bold-cards custom-font-body">
        {collection.map((teammate, i) => (
          <TeamCard
            locales={locales}
            teammate={teammate}
            index={i}
            key={teammate.id}
            backgroundImage={backgroundImage}
            teamNameColor={teamNameColor}
            positionColor={positionColor}
            memberDescriptionColor={textColor}
            titleStyle={TITLE_MODAL_STYLE_BOLD}
            buttonStyle={BUTTON_MODAL_STYLE_BOLD}
            lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_BOLD}
            sliderColor={sliderColor}
            backgroundModal={backgroundModal}
            titleColor={titleColor}
            buttonSecondColor={buttonSecondColor}
            buttonFirstColor={buttonFirstColor}
            backgroundSecondButton={backgroundSecondButton}
            isTemplates
            disabled={disabled}
          />
        ))}
        {!disabled && (
          <div className="add-team-card__wrapper">
            <div className="add-team-card">
              <div className="add-team-card-hover">
                <div className="center-block" onClick={addPortfolioTeammate}>
                  <i className="icon-Add" />
                  {portfolio.team.addNewTeamMember}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamBold;
