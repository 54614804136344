import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Textarea from './Textarea';
import {
  BLOG_FRIENDLY_TEMPLATE_NAME,
  COLOURFUL_TEMPLATE_NAME,
  CURRENT_TEMPLATE_NAME,
  ARCHITECTURAL_MINIMAL_TEMPLATE_NAME,
  TECH_MODERN_TEMPLATE_NAME,
  BOLD_TEMPLATE_NAME,
} from '../utils/const';
import { getTitleStyle } from '../utils/helpers/getTitleStyle';

const getDivStyle = (templateName) => {
  const divStyle = {
    [CURRENT_TEMPLATE_NAME]: 'textarea-current-wrapper description',
    [COLOURFUL_TEMPLATE_NAME]: 'textarea-colourful description',
    [BLOG_FRIENDLY_TEMPLATE_NAME]: 'textarea-blog description',
    [ARCHITECTURAL_MINIMAL_TEMPLATE_NAME]: 'textarea-arch description',
    [TECH_MODERN_TEMPLATE_NAME]: 'textarea-techModern description',
    [BOLD_TEMPLATE_NAME]: 'textarea-boldTemplate description',
  };
  return divStyle[templateName];
};

const getTextareaStyle = (templateName) => {
  const textareaStyle = {
    [CURRENT_TEMPLATE_NAME]: 'textarea-current-template custom-font-body',
    [COLOURFUL_TEMPLATE_NAME]:
      'textarea-colourfulFun textarea-colourfulFun-testing custom-font-body',
    [BLOG_FRIENDLY_TEMPLATE_NAME]:
      'textarea-blogFriendly textarea-blogFriendly-testing custom-font-body',
    [ARCHITECTURAL_MINIMAL_TEMPLATE_NAME]:
      'textarea-architectural textarea-architectural-testing custom-font-body',
    [TECH_MODERN_TEMPLATE_NAME]: 'textarea-tech custom-font-body',
    [BOLD_TEMPLATE_NAME]: 'textarea-bold custom-font-body',
  };
  return textareaStyle[templateName];
};

const SectionHeader = ({
  templateName,
  title,
  description,
  onChange,
  portfolio,
  rows = 3,
  className,
  descriptionColor,
  style,
  disabled = false,
}) => {
  return (
    <div className="header">
      <div
        className={classNames(getTitleStyle(templateName), className)}
        style={style}
      >
        {title}
      </div>
      <div className={getDivStyle(templateName)}>
        <Textarea
          name="textarea"
          rows={rows}
          delay={700}
          value={description}
          onChange={onChange}
          className={getTextareaStyle(templateName)}
          placeholder={portfolio.page.descriptionPlaceholder}
          disabled={disabled}
          style={{ color: descriptionColor }}
        />
      </div>
    </div>
  );
};

SectionHeader.propTypes = {
  onChange: PropTypes.func.isRequired,
  portfolio: PropTypes.object,
  templateName: PropTypes.string,
  description: PropTypes.string,
  rows: PropTypes.number,
  title: PropTypes.string,
};

export default SectionHeader;
