import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SectionHeader from './SectionHeader';
import CustomerSegmentCard from '../customerSegment/Card';
import Textarea from '../../components/Textarea';

const CustomerSegment = ({
  locales,
  data: { description, showStatus },
  disabled = false,
}) => {
  const {
    portfolio,
    customerSegment: {
      intro: { card },
    },
  } = locales;

  const { collection: cards } = useSelector((state) => state.customerSegment);
  const {
    customerSegment: { updateCustomerSegment },
    portfolio: { updateSectionField, updateSectionFieldReducer },
  } = useDispatch();

  const setShowStatus = (status) => {
    updateSectionFieldReducer({
      sectionName: 'customerSegment',
      field: 'showStatus',
      value: status,
    });
  };

  return (
    <div className="customer-segment">
      <SectionHeader
        locales={portfolio}
        showStatus={showStatus}
        sectionName={portfolio.customerSegment.sectionName}
        setShowStatus={setShowStatus}
        mainColor="green"
      />
      {showStatus && (
        <div>
          <Textarea
            name="textarea"
            rows={5}
            className="bmc-form-textarea textarea"
            delay={700}
            value={description || ''}
            onChange={(e) =>
              updateSectionField({
                sectionName: 'customerSegment',
                field: 'description',
                name: 'csText',
                value: e.target.value,
              })
            }
            placeholder={portfolio.page.descriptionPlaceholder}
            disabled={disabled}
          />
          {cards.map((collectionCard) => (
            <CustomerSegmentCard
              className="margin-top-40"
              locales={locales}
              key={collectionCard.id}
              uploadFileHandler={updateCustomerSegment}
              dropzoneMode
              portfolioMode
              blur={false}
              disabled={disabled}
              editMode={!disabled}
              card={{
                ...card,
                ...collectionCard,
                subtitle: collectionCard.name,
                title: null,
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomerSegment;
