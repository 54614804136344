import React from 'react';
import ReactStars from 'react-stars';

import TestStatus from './TestStatus';

const Card = ({
  card: {
    title,
    name,
    icon,
    rate,
    assumption,
    bmcBlockTest,
    status,
    testPlan,
    // planingUploads,
    testResult,
    // resultsUploads,
  },
}) => {
  return (
    <div className="test-card">
      {title && (
        <div className="test-card__header">
          <img src={icon} alt="cafe" />
          <div className="title">{title}</div>
        </div>
      )}
      <div className="test-card__title">{name}</div>
      <ReactStars
        className="test-card__rate"
        color2="#0984FF"
        color1="rgba(9, 132, 255, 0.2)"
        size={22}
        edit={false}
        value={rate}
      />
      <div className="test-card__section">
        <div className="left">
          <div className="block">
            <div className="title">{assumption.title}</div>
            <div className="content">{assumption.text}</div>
          </div>
        </div>
        <div className="right">
          <div className="block">
            <div className="title">{bmcBlockTest.title}</div>
            <div className="content">
              <div className="bmc-blocks">
                {bmcBlockTest.blocks.map((b, i) => {
                  return (
                    <div
                      key={i}
                      className={`bmc-blocks__block bmc-blocks__block-${b}`}
                    >
                      {b}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="block">
            <div className="title">{status.title}</div>
            <div className="content">
              <TestStatus status={status.status} />
            </div>
          </div>
        </div>
      </div>
      <div className="test-card__section">
        <div className="left is-full-width">
          <div className="block">
            <div className="title">{testPlan.title}</div>
            <div className="content">{testPlan.text}</div>
          </div>
        </div>
        {/* <div className="right"> */}
        {/*  <div className="block"> */}
        {/*    <div className="title">{planingUploads.title}</div> */}
        {/*    <div className="content"> */}
        {/*      <div className="uploaded-files"> */}
        {/*        {planingUploads.files.map((f, i) => { */}
        {/*          return ( */}
        {/*            <div key={i} className="file"> */}
        {/*              <i className="icon-Channel" /> */}
        {/*              <div className="text">{f}</div> */}
        {/*            </div> */}
        {/*          ); */}
        {/*        })} */}
        {/*      </div> */}
        {/*    </div> */}
        {/*  </div> */}
        {/* </div> */}
      </div>
      <div className="test-card__section">
        <div className="left is-full-width">
          <div className="block">
            <div className="title">{testResult.title}</div>
            <div className="content">{testResult.text}</div>
          </div>
        </div>
        {/* <div className="right"> */}
        {/* <div className="block"> */}
        {/*  <div className="title">{resultsUploads.title}</div> */}
        {/*  <div className="content"> */}
        {/*    <div className="uploaded-files"> */}
        {/*      {resultsUploads.files.map((f, i) => { */}
        {/*        return ( */}
        {/*          <div key={i} className="file"> */}
        {/*            <i className="icon-Channel" /> */}
        {/*            <div className="text">{f}</div> */}
        {/*          </div> */}
        {/*        ); */}
        {/*      })} */}
        {/*    </div> */}
        {/*  </div> */}
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default Card;
