import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { truncate } from 'lodash';
import CustomerSegmentCard from '../../customerSegment/CardColourfulArch';
import ImageDrop from '../../../components/ImageDrop/ImageDropCS';
import {
  BOLD_TEMPLATE_NAME,
  BUTTON_LIGHT_MODAL_STYLE_BOLD,
  BUTTON_MODAL_STYLE_BOLD,
  COMMENT_SEGMENT,
  IMAGE_ASPECT_RATIO_ONE,
  TITLE_MODAL_STYLE_BOLD,
} from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';

const CustomerSegmentBold = ({
  data: { description },
  locales,
  locales: {
    portfolio,
    customerSegment: {
      intro: { card },
    },
  },
  titleColor,
  textColor,
  backgroundImage,
  background,
  segmentListTitleStyle,
  sliderColor,
  backgroundModal,
  buttonSecondColor,
  buttonFirstColor,
  backgroundSecondButton,
  disabled = false,
}) => {
  const [activeCardId, setActiveCardId] = useState(null);

  const {
    customerSegment: { updateCustomerSegment },
    portfolio: { updateSectionField },
  } = useDispatch();

  const { collection } = useSelector((state) => state.customerSegment);

  const activeCardData = useMemo(() => {
    if (collection.length && !activeCardId) {
      setActiveCardId(collection[0].id);
      return collection[0];
    }
    return collection.find((i) => i.id === activeCardId);
  }, [collection, activeCardId]);

  const singleCard = activeCardData && (
    <CustomerSegmentCard
      locales={locales}
      uploadFileHandler={updateCustomerSegment}
      dropzoneMode
      blur={false}
      disabled={disabled}
      editMode={!disabled}
      textareaWrapperStyle="textarea-boldTemplate"
      textareaStyle="textarea-bold"
      rangeStyle="textarea-bold-cardCS"
      subtitleColor={titleColor}
      segmentListTitleStyle={segmentListTitleStyle}
      textColor={textColor}
      card={{
        ...card,
        ...activeCardData,
        subtitle: activeCardData.name,
        title: null,
      }}
    />
  );

  const singleImage = activeCardData && (
    <ImageDrop
      locales={locales}
      uploadFileHandler={updateCustomerSegment}
      dropzoneMode
      background={{ backgroundColor: backgroundImage }}
      backgroundImage={backgroundImage}
      disabled={disabled}
      editMode={!disabled}
      aspectRatio={IMAGE_ASPECT_RATIO_ONE}
      templateName={BOLD_TEMPLATE_NAME}
      titleStyle={TITLE_MODAL_STYLE_BOLD}
      buttonStyle={BUTTON_MODAL_STYLE_BOLD}
      lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_BOLD}
      sliderColor={sliderColor}
      backgroundModal={backgroundModal}
      titleColor={titleColor}
      buttonSecondColor={buttonSecondColor}
      buttonFirstColor={buttonFirstColor}
      backgroundSecondButton={backgroundSecondButton}
      isTemplates
      card={{
        ...card,
        ...activeCardData,
        subtitle: activeCardData.name,
        title: null,
      }}
    />
  );

  return (
    <div
      className="customer-segment-bold relative"
      style={{ backgroundColor: background, color: textColor }}
    >
      <PortfolioCommentIcon title={COMMENT_SEGMENT} />
      <SectionHeader
        templateName={BOLD_TEMPLATE_NAME}
        title={portfolio.customerSegment.sectionName}
        description={description}
        onChange={(e) =>
          updateSectionField({
            sectionName: 'customerSegment',
            field: 'description',
            name: 'csText',
            value: e.target.value,
          })
        }
        portfolio={portfolio}
        style={{ color: titleColor }}
        descriptionColor={textColor}
        disabled={disabled}
      />
      <div className="card-wrapper">
        <div className="left">
          <div className="cards-section">
            <div className="tabs">
              {collection.map(({ id, name }) => (
                <div
                  key={id}
                  onClick={() => setActiveCardId(id)}
                  className={classnames(
                    'item',
                    {
                      active: activeCardId === id,
                    },
                    'custom-font-body',
                  )}
                  style={{ color: titleColor }}
                >
                  {truncate(name, { length: 45 })}
                </div>
              ))}
            </div>
            <div className="main">{singleCard}</div>
          </div>
        </div>
        <div className="right">{singleImage}</div>
      </div>
    </div>
  );
};

export default CustomerSegmentBold;
