import {
  Paragraph,
  TextRun,
  Table,
  TableRow,
  TableCell,
  AlignmentType,
  WidthType,
  HeightRule,
  TableAnchorType,
  OverlapType,
  TableLayoutType,
  HorizontalPositionAlign,
  VerticalPositionAlign,
  FrameAnchorType,
  UnderlineType,
  ImageRun,
  HorizontalPositionRelativeFrom,
  VerticalPositionRelativeFrom,
} from 'docx';

import {
  pageSize,
  convertPixelToTwip,
  convertFontSize,
  COLORS,
  transparentBorder,
  shadingBox,
  singleBorder,
  getBlobFromUrl,
  convertPixel,
  convertCentimeterToEMU,
  tableWidth,
  noPageMargin,
  whiteBgImage,
} from '../Constants';
import { emptyCell, marginTableRow, textRun } from '../helpers/index';

export default async (locales, portfolio, revenue, options) => {
  const descriptionWithLineBreaks = portfolio.finance.description
    ?.split('\n')
    ?.map((line) =>
      textRun(line, {
        break: 1,
        font: options.bodyFont,
        color: options.bodyColor || COLORS.BLACK,
      }),
    );

  const dataRows = () => {
    const rowList = revenue.collection.map((revenueItem) => [
      marginTableRow(20, tableWidth, COLORS.PRIMARY, 3),
      new TableRow({
        height: {
          value: convertPixelToTwip(19),
          rule: HeightRule.ATLEAST,
        },
        children: [
          emptyCell(28, COLORS.WHITE),
          new TableCell({
            width: {
              size: convertPixelToTwip(tableWidth - 56),
              type: WidthType.DXA,
            },
            margins: {
              top: convertPixelToTwip(10),
              left: convertPixelToTwip(10),
              bottom: convertPixelToTwip(10),
              right: convertPixelToTwip(10),
            },
            shading: shadingBox(options.finance?.rowBg || COLORS.WHITE),
            borders: singleBorder(options.finance?.borderColor || COLORS.BLACK),
            children: [
              new Paragraph({
                alignment: AlignmentType.START,
                spacing: {
                  after: convertPixelToTwip(15),
                },
                children: [
                  textRun(revenueItem.name, {
                    size: 16,
                    bold: true,
                    color: options.bodyColor || COLORS.BLACK,
                    font: options.bodyFont,
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  textRun(revenueItem.description, {
                    font: options.bodyFont,
                    color: options.bodyColor || COLORS.BLACK,
                    size: 14,
                  }),
                ],
              }),
            ],
          }),
          emptyCell(28, COLORS.WHITE),
        ],
      }),
    ]);

    return rowList.flat(1);
  };

  const getLeftImgBg = () => {
    if (options.finance?.leftImgBg)
      return new ImageRun({
        data: getBlobFromUrl(options.finance?.leftImgBg),
        transformation: {
          width: convertPixel(213),
          height: convertPixel(339),
        },
        floating: {
          behindDocument: true,
          horizontalPosition: {
            relative: HorizontalPositionRelativeFrom.PAGE,
            offset: convertCentimeterToEMU(-4),
          },
          verticalPosition: {
            relative: VerticalPositionRelativeFrom.PAGE,
            offset: convertCentimeterToEMU(8),
          },
        },
      });
  };

  const getRightImgBg = () => {
    if (options.finance?.rightImgBg)
      return new ImageRun({
        data: getBlobFromUrl(options.finance?.rightImgBg),
        transformation: {
          width: convertPixel(171),
          height: convertPixel(361),
        },
        floating: {
          behindDocument: true,
          horizontalPosition: {
            relative: HorizontalPositionRelativeFrom.PAGE,
            offset: convertCentimeterToEMU(26),
          },
          verticalPosition: {
            relative: VerticalPositionRelativeFrom.PAGE,
            offset: convertCentimeterToEMU(2),
          },
        },
      });
  };

  return {
    properties: {
      page: {
        size: pageSize,
        margin: noPageMargin,
      },
    },
    children: [
      // Primary box
      new Paragraph({
        frame: {
          position: {
            x: 0,
            y: 0,
          },
          width: convertPixelToTwip(tableWidth),
          height: convertPixelToTwip(539 + 56),
          rule: HeightRule.EXACT,
          anchor: {
            horizontal: FrameAnchorType.TEXT,
            vertical: FrameAnchorType.TEXT,
          },
          alignment: {
            x: HorizontalPositionAlign.CENTER,
            y: VerticalPositionAlign.TOP,
          },
        },
        shading: options.finance?.pageBg
          ? shadingBox(options.finance?.pageBg)
          : null,
        children: [getLeftImgBg(), getRightImgBg()],
      }),
      // Description
      new Table({
        float: {
          horizontalAnchor: TableAnchorType.TEXT,
          verticalAnchor: TableAnchorType.TEXT,
          overlap: OverlapType.NEVER,
          absoluteVerticalPosition: convertPixelToTwip(0),
          absoluteHorizontalPosition: 0,
        },
        width: {
          size: convertPixelToTwip(tableWidth),
          type: WidthType.DXA,
        },
        layout: TableLayoutType.AUTOFIT,
        rows: [
          new TableRow({
            height: {
              value: convertPixelToTwip(27),
              rule: HeightRule.ATLEAST,
            },
            children: [
              new TableCell({
                width: {
                  size: convertPixelToTwip(tableWidth),
                  type: WidthType.DXA,
                },
                borders: transparentBorder(COLORS.PRIMARY),
                children: [
                  new Table({
                    float: {
                      horizontalAnchor: TableAnchorType.TEXT,
                      verticalAnchor: TableAnchorType.TEXT,
                      overlap: OverlapType.NEVER,
                    },
                    width: {
                      size: convertPixelToTwip(tableWidth),
                      type: WidthType.DXA,
                    },
                    layout: TableLayoutType.AUTOFIT,
                    rows: [
                      // title
                      new TableRow({
                        height: {
                          value: convertPixelToTwip(27),
                          rule: HeightRule.ATLEAST,
                        },
                        children: [
                          new TableCell({
                            width: {
                              size: convertPixelToTwip(tableWidth),
                              type: WidthType.DXA,
                            },
                            borders: transparentBorder(COLORS.PRIMARY),
                            columnSpan: 3,
                            children: [
                              // Title
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                  new TextRun({
                                    text: locales.finance.sectionName,
                                    font: options.titleFont,
                                    color: options.titleColor || COLORS.BLACK,
                                    bold: options.finance?.titleBold,
                                    size: convertFontSize(24),
                                    allCaps: true,
                                  }),
                                ],
                              }),
                            ],
                          }),
                        ],
                      }),
                      marginTableRow(10, tableWidth, COLORS.PRIMARY, 3),
                      // description row
                      new TableRow({
                        height: {
                          value: convertPixelToTwip(28),
                          rule: HeightRule.ATLEAST,
                        },
                        children: [
                          emptyCell(28, COLORS.WHITE),

                          new TableCell({
                            width: {
                              size: convertPixelToTwip(tableWidth - 56),
                              type: WidthType.DXA,
                            },
                            borders: transparentBorder(COLORS.PRIMARY),
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: descriptionWithLineBreaks,
                              }),
                            ],
                          }),

                          emptyCell(28, COLORS.WHITE),
                        ],
                      }),
                      marginTableRow(30, tableWidth, COLORS.PRIMARY, 3),
                      // head row
                      new TableRow({
                        height: {
                          value: convertPixelToTwip(19),
                          rule: HeightRule.ATLEAST,
                        },
                        children: [
                          emptyCell(28, COLORS.WHITE),
                          new TableCell({
                            width: {
                              size: convertPixelToTwip(tableWidth - 56),
                              type: WidthType.DXA,
                            },
                            borders: transparentBorder(COLORS.PRIMARY),
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.START,
                                children: [
                                  textRun('REVENUE STRATEGY', {
                                    size: 16,
                                    allCaps: true,
                                    bold: true,
                                    color: options.bodyColor || COLORS.BLACK,
                                    font: options.bodyFont,
                                    underline: {
                                      color: COLORS.BLACK,
                                      type: UnderlineType.SINGLE,
                                    },
                                  }),
                                ],
                              }),
                            ],
                          }),
                          emptyCell(28, COLORS.WHITE),
                        ],
                      }),
                      // marginTableRow(20, tableWidth, COLORS.PRIMARY, 3),

                      ...dataRows(),
                    ],
                  }),
                ],
              }),
            ],
          }),
        ],
      }),

      whiteBgImage(),
    ],
  };
};
