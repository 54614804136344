import React from 'react';

import Actions from '../../components/Actions';
import Header from '../../components/Header';
import Title from '../../components/Title';
import List from '../../components/List';
import Line from '../../components/Line';
import Text from '../../components/Text';
import GKGTabs from './GKGTabs';
import SvgIcon from '../../components/SvgIcon';
import MobileMenuHeader from '../../components/MobileMenuHeader';

export default function Intro(props) {
  const {
    nextClickHandler,
    menuItems,
    selectComponent,
    componentIndex,
    rules,
    backPath,
    locales,
    locales: {
      common,
      tipCustomerSegment,
      getKeepGrow: {
        name,
        intro: { header, title, text, list, gkgTabs },
      },
    },
  } = props;

  return (
    <div className="bmc-page">
      <Header
        title={header}
        subtitle={name}
        icon={<SvgIcon name="gkg" />}
        locales={locales}
        componentIndex={componentIndex}
        tipLocales={tipCustomerSegment}
        menuItems={menuItems}
        onMenuItemClick={selectComponent}
        rules={rules}
        backPath={backPath}
        mobileMenuHeader={
          <MobileMenuHeader icon="CRCanvas" title={name} color="green" />
        }
      />

      <Text>{[text[0], text[1]]}</Text>

      <Title text={title[0]} align="left" />

      <List items={list} />

      <Line />

      <Title text={title[1]} />

      <Text>{[text[2]]}</Text>

      <GKGTabs className="margin-top-60" content={gkgTabs} />

      <Actions nextClickHandler={nextClickHandler} buttonName={common.next} />
    </div>
  );
}
