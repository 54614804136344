import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from 'react-use-media-query-hook';

import Dropzone from '../../../components/Dropzone';
import {
  BOLD_TEMPLATE_NAME,
  BUTTON_LIGHT_MODAL_STYLE_BOLD,
  BUTTON_MODAL_STYLE_BOLD,
  COMMENT_PROPOSITION,
  IMAGE_ASPECT_RATIO_ONE,
  IMAGE_ASPECT_RATIO_VP_BOLD,
  TITLE_MODAL_STYLE_BOLD,
} from '../../../utils/const';
import SectionHeader from '../../../components/SectionHeader';
import { renderSegmentList } from '../../../utils/helpers/renderSegmentList';
import { renderText } from '../../../utils/helpers/renderText';
import PortfolioCommentIcon from '../../../components/PortfolioCommentIcon';
import { getImageAspectRatio } from '../../../utils/helpers/getImageAspectRatio';

const ValuePropositionBold = ({
  data: { description },
  locales,
  locales: {
    portfolio,
    valueProposition: {
      intro: { card },
    },
  },
  titleColor,
  textColor,
  subtitleColor,
  background,
  backgroundImage,
  sliderColor,
  backgroundModal,
  buttonSecondColor,
  buttonFirstColor,
  backgroundSecondButton,
  disabled = false,
}) => {
  const {
    valueProposition: { product, reason, image, imageCoords },
    uniqueSellingPoint: { collection },
  } = useSelector((state) => state);

  const { uploadingImage } = useSelector((state) => state.valueProposition);

  const {
    valueProposition: {
      updateAttributeImage,
      updateValuePropositionImageCoords,
    },
    portfolio: { updateSectionField },
  } = useDispatch();

  const isDesktop = useMediaQuery('(max-width: 1280px)');

  const onImageDrop = ([file], openCropperHandler) => {
    updateAttributeImage({
      name: 'image',
      value: URL.createObjectURL(file),
      blob: file,
      success: () => {
        openCropperHandler();
      },
    });
  };

  const onImageCropperSave = (croppedAreaPixels, innerCropData, zoom) => {
    updateValuePropositionImageCoords({
      croppedAreaPixels,
      innerCropData,
      zoom,
      coords: imageCoords,
    });
  };

  return (
    <div
      className="value-propositions-bold relative"
      style={{ backgroundColor: background }}
    >
      <PortfolioCommentIcon title={COMMENT_PROPOSITION} />
      <div className="left">
        <div className="drop">
          <Dropzone
            className="drop-image"
            onDrop={onImageDrop}
            onCropperSave={onImageCropperSave}
            locales={locales}
            image={image}
            coords={imageCoords}
            uploading={uploadingImage}
            aspectRatio={getImageAspectRatio(
              isDesktop,
              IMAGE_ASPECT_RATIO_ONE,
              IMAGE_ASPECT_RATIO_VP_BOLD,
            )}
            background={{ backgroundColor: backgroundImage }}
            titleStyle={TITLE_MODAL_STYLE_BOLD}
            buttonStyle={BUTTON_MODAL_STYLE_BOLD}
            lightButtonStyle={BUTTON_LIGHT_MODAL_STYLE_BOLD}
            sliderColor={sliderColor}
            backgroundModal={backgroundModal}
            titleColor={titleColor}
            buttonSecondColor={buttonSecondColor}
            buttonFirstColor={buttonFirstColor}
            backgroundSecondButton={backgroundSecondButton}
            isTemplates
            disabled={disabled}
            cropper
          />
        </div>
      </div>
      <div className="right">
        <SectionHeader
          templateName={BOLD_TEMPLATE_NAME}
          title={portfolio.valueProposition.sectionName}
          description={description}
          onChange={(e) =>
            updateSectionField({
              sectionName: 'valueProposition',
              field: 'description',
              name: 'vpText',
              value: e.target.value,
            })
          }
          portfolio={portfolio}
          style={{ color: titleColor }}
          descriptionColor={textColor}
          disabled={disabled}
        />
        {renderSegmentList(card.solutionTitle, subtitleColor)}
        {renderText(product, textColor)}
        {renderSegmentList(card.featuresTitle, subtitleColor)}
        {renderText(reason, textColor)}
        {renderSegmentList(card.pointsTitle, subtitleColor)}
        {renderText(
          collection.map((point, index) => (
            <div className="margin-bottom-10" key={point.id || index}>
              {point.description}
            </div>
          )),
          textColor,
        )}
      </div>
    </div>
  );
};

export default ValuePropositionBold;
