import {
  AlignmentType,
  BorderStyle,
  FrameAnchorType,
  HeightRule,
  HorizontalPositionAlign,
  ImageRun,
  Paragraph,
  TableCell,
  TableRow,
  TextRun,
  VerticalAlign,
  VerticalPositionAlign,
  WidthType,
} from 'docx';
// eslint-disable-next-line import/no-cycle
import {
  COLORS,
  convertFontSize,
  convertPixel,
  convertPixelToTwip,
  countImgSize,
  FONTS,
  getBlobFromUrl,
  getMeta,
  mainPageImgTableWrapper,
  noImgTableWithText,
  paragraphWithImg,
  shadingBox,
  tableWidth,
  transparentBorder,
} from '../Constants';
import {
  borderRadiusImage,
  changeImageColor,
  createBlankImage,
  cutImage,
  loadImage,
  mergeImages,
  mergeImagesWithCentered,
  mergeImagesWithoutShift,
  resizeImage,
  roundImage,
  shadowImage,
  textImage,
} from './ImageService/utils.js';
import CircleBgImg from './ImageService/assets/half.png';
import Circle280 from './ImageService/assets/circle280.png';
import Circle340 from './ImageService/assets/circle340.png';
import TeamCircle from '../assets/colourful_team_circle.svg';
import { sdgListIcon } from './SdgIcons';

const sizeMultiplier = (imageSrc, multiplierNum) => {
  const iconExtension = imageSrc.split('.').at(-1);
  return iconExtension === 'svg' ? multiplierNum : 1;
};

export const emptyCell = (width, borderColor, shadingBoxColor = null) => {
  return new TableCell({
    width: {
      size: convertPixelToTwip(width),
      type: WidthType.DXA,
    },
    shading: shadingBoxColor ? shadingBox(shadingBoxColor) : null,
    borders: transparentBorder(borderColor),
    children: [],
  });
};

export const economicsTableCell = (
  text,
  bold = false,
  borderTop = false,
  borderBottom = false,
  odd = false,
  borderColor,
) => {
  return new TableCell({
    verticalAlign: VerticalAlign.CENTER,
    shading: odd
      ? shadingBox(borderColor || COLORS.GRAY3)
      : shadingBox(COLORS.WHITE),
    width: {
      size: convertPixelToTwip(125),
      type: WidthType.DXA,
    },
    borders: {
      left: {
        style: BorderStyle.SINGLE,
        size: 1,
        color: borderColor || COLORS.BLACK,
      },
      top: {
        style: BorderStyle.SINGLE,
        size: 1,
        color: borderTop ? borderColor : COLORS.WHITE,
      },
      right: {
        style: BorderStyle.SINGLE,
        size: 1,
        color: borderColor || COLORS.BLACK,
      },
      bottom: {
        style: BorderStyle.SINGLE,
        size: 1,
        color: borderBottom ? borderColor : COLORS.WHITE,
      },
    },
    children: [
      new Paragraph({
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text,
            font: FONTS.NUNITO,
            size: convertFontSize(11),
            bold,
          }),
        ],
      }),
    ],
  });
};

export const forecastsTableCell = (
  text,
  size = 10,
  bold = false,
  odd = false,
  alignment = AlignmentType.CENTER,
  margins = {},
) => {
  return new TableCell({
    verticalAlign: VerticalAlign.CENTER,
    width: {
      size: convertPixelToTwip(130),
      type: WidthType.DXA,
    },
    shading: odd ? shadingBox(COLORS.GRAY3) : shadingBox(COLORS.WHITE),
    borders: {
      left: {
        style: BorderStyle.SINGLE,
        size: 1,
        color: COLORS.GRAY3,
      },
      top: {
        style: BorderStyle.SINGLE,
        size: 0,
        color: COLORS.WHITE,
      },
      right: {
        style: BorderStyle.SINGLE,
        size: 0,
        color: COLORS.WHITE,
      },
      bottom: {
        style: BorderStyle.SINGLE,
        size: 1,
        color: COLORS.GRAY3,
      },
    },
    margins,
    children: [
      new Paragraph({
        alignment,
        children: [
          new TextRun({
            text,
            font: FONTS.NUNITO,
            size: convertFontSize(size),
            bold,
          }),
        ],
      }),
    ],
  });
};

export const marginTableRow = (
  height,
  width,
  borderColor,
  columnCount,
  shadingBoxColor = null,
) =>
  new TableRow({
    height: {
      value: convertPixelToTwip(height),
      rule: HeightRule.ATLEAST,
    },
    children: [
      new TableCell({
        columnSpan: columnCount,
        width: {
          size: convertPixelToTwip(width),
          type: WidthType.DXA,
        },
        shading: shadingBoxColor ? shadingBox(shadingBoxColor) : null,
        children: [],
        borders: transparentBorder(borderColor),
      }),
    ],
  });

export const textRun = (text, options = {}) =>
  new TextRun({
    text,
    bold: options.bold || false,
    font: options.font || FONTS.LATO,
    size: convertFontSize(options.size || 12),
    color: options.color || COLORS.BLACK,
    allCaps: options.allCaps || false,
    underline: options.underline || false,
    break: options.break || false,
  });

export const buildBgCircle = async (width, height, color) => {
  const blankImage = await createBlankImage(
    width * 30,
    height * 30,
    `#${color}`,
  );
  const roundedImage = await roundImage(blankImage, width * 30);

  return Uint8Array.from(atob(roundedImage.src?.split(',')[1]), (c) =>
    c.charCodeAt(0),
  );
};

export const buildBgCircleBordered = async (color) => {
  const blankImage = await createBlankImage(76, 76, `#${color}`);
  const roundedImage = await roundImage(blankImage, 76 * 20);

  const circle = await loadImage(TeamCircle);
  const coloredCircle = await changeImageColor(
    circle,
    circle.width * 20,
    circle.height * 20,
    color,
  );

  const result = await mergeImagesWithCentered(
    [coloredCircle, roundedImage],
    coloredCircle.width,
    coloredCircle.height,
  );

  return Uint8Array.from(atob(result.src?.split(',')[1]), (c) =>
    c.charCodeAt(0),
  );
};

export const buildBlankRound = async (
  width,
  height,
  color,
  radius,
  bodyFont,
  bodyColor,
  text = false,
) => {
  const blankImage = await createBlankImage(width, height, `#${color}`);
  let roundedImage = await borderRadiusImage(blankImage, width, radius);

  if (text) {
    roundedImage = await textImage(
      roundedImage,
      width,
      bodyFont,
      bodyColor,
      text,
    );
  }

  return Uint8Array.from(atob(roundedImage.src?.split(',')[1]), (c) =>
    c.charCodeAt(0),
  );
};

const buildBlankSquare = async (
  width,
  height,
  color,
  bodyFont,
  bodyColor,
  text = false,
) => {
  let blankImage = await createBlankImage(width, height, `#${color}`);

  if (text) {
    blankImage = await textImage(blankImage, width, bodyFont, bodyColor, text);
  }
  return Uint8Array.from(atob(blankImage.src?.split(',')[1]), (c) =>
    c.charCodeAt(0),
  );
};

const buildBlogNoImgCircle = async (size, img = CircleBgImg) => {
  const circleBg = await loadImage(img);
  const blankImage = await createBlankImage(size, size, 'transparent', true);
  const resizedImage = await resizeImage(blankImage, size, 'contain');
  const roundedImage = await roundImage(resizedImage, size);
  const mergedImage = await mergeImagesWithoutShift(
    [circleBg, roundedImage],
    size,
  );

  return Uint8Array.from(atob(mergedImage.src?.split(',')[1]), (c) =>
    c.charCodeAt(0),
  );
};

const circleMainImg = async (originalImage, options) => {
  const img = await loadImage(originalImage);

  const circleBg = await loadImage(CircleBgImg);
  const resizedImage = await resizeImage(img, 360, 'cover', 360);
  const roundedImage = await roundImage(resizedImage, 360);
  const mergedImage = await mergeImages([circleBg, roundedImage], 370);
  const cuttedImage = await cutImage(mergedImage, 370, 0.08);

  const mainImg = Uint8Array.from(atob(cuttedImage.src?.split(',')[1]), (c) =>
    c.charCodeAt(0),
  );

  return mainPageImgTableWrapper(mainImg, options, '');
};

const blogImgWithCircleBg = async (img) => {
  const loadedCircle = await loadImage(Circle280);
  const circleBg = await resizeImage(loadedCircle, 275, 'contain', 275);
  const resizedImage = await resizeImage(img, 275, 'cover', 275);
  const roundedImage = await roundImage(resizedImage, 275);
  const mergedImage = await mergeImages([circleBg, roundedImage], 285);

  return mergedImage;
};

const blogVerticalRoundImg = async (img) => {
  const resizedImage = await resizeImage(img, 275, 'cover', 350);
  const borderedImage = await borderRadiusImage(resizedImage, 275, 60);

  return borderedImage;
};

const segmentIsPresentImageProcess = async (originalImage, sectionOptions) => {
  let newImg = await loadImage(originalImage);
  let imgSizeData = {
    imgWidth: 285,
    imgHeight: 285,
  };

  switch (sectionOptions.imgType) {
    case 'round':
      newImg = await borderRadiusImage(
        await resizeImage(newImg, 275, 'cover', 275),
        275,
        75,
      );
      break;
    case 'circle_blog':
      newImg = await blogImgWithCircleBg(newImg);
      break;
    case 'vertical_round':
      newImg = await blogVerticalRoundImg(newImg);
      imgSizeData = {
        imgWidth: 200,
        imgHeight: 325,
      };
      break;
    default:
      imgSizeData = countImgSize(newImg, {
        vertical: true,
        blockWidth: 285,
        blockHeight: 375,
      });
      newImg = await cutImage(newImg, newImg.width, 0);
  }

  return [
    paragraphWithImg(
      Uint8Array.from(atob(newImg.src?.split(',')[1]), (c) => c.charCodeAt(0)),
      imgSizeData,
    ),
  ];
};

const segmentIsNoImgProcess = async (
  sectionOptions,
  bodyColor,
  bodyFont,
  text,
) => {
  const size = 280;
  let blobImg;

  switch (sectionOptions?.noImgType) {
    case 'circle':
      blobImg = await buildBlogNoImgCircle(size, Circle280);
      break;
    case 'round':
      blobImg = await buildBlankRound(
        size,
        size,
        sectionOptions?.noImgBg,
        16,
        bodyColor,
        bodyFont,
        text,
      );
      break;
    default:
      blobImg = await buildBlankSquare(
        size,
        size,
        sectionOptions?.noImgBg,
        bodyColor,
        bodyFont,
        text,
      );
  }

  const imgSizeData = {
    imgWidth: size,
    imgHeight: size,
  };

  return [
    paragraphWithImg(blobImg, imgSizeData),
    noImgTableWithText(text, bodyColor, bodyFont, size),
  ];
};

export const buildSegmentItemImage = async (
  originalImage,
  bodyColor,
  bodyFont,
  text,
  sectionOptions,
) => {
  let blobImg;
  let result;

  await getBlobFromUrl(originalImage).then((res) => {
    blobImg = res;
  });

  if (blobImg)
    result = segmentIsPresentImageProcess(originalImage, sectionOptions);
  else
    result = segmentIsNoImgProcess(sectionOptions, bodyColor, bodyFont, text);

  return result;
};

const roundMainImg = async (originalImage, options, portfolio) => {
  const img = await loadImage(originalImage);
  const resizedImage = await resizeImage(img, 350, 'cover', 350);
  const borderedImage = await borderRadiusImage(resizedImage, 320, 44);
  const cuttedImage = await shadowImage(borderedImage, 320);

  const mainImg = Uint8Array.from(atob(cuttedImage.src?.split(',')[1]), (c) =>
    c.charCodeAt(0),
  );

  return mainPageImgTableWrapper(mainImg, options, '', false, 0, portfolio);
};

const defaultMainImg = async (originalImage, options, blobImg) => {
  const imgSizeData = await getMeta(originalImage)
    .then((img) => {
      return countImgSize(img, {
        horizontal: true,
        blockWidth: options.imgWidth,
        blockHeight: options.imgHeight,
      });
    })
    .catch(() => {
      return {
        imgWidth: options.imgWidth,
        imgHeight: options.imgHeight,
      };
    });

  return new Paragraph({
    text: '',
    frame: {
      position: {
        x: options.mainImgPositionX || 0,
        y: options.mainImgPositionY || 0,
      },
      rule: HeightRule.EXACT,
      width: convertPixelToTwip(options.paragraphWidth || tableWidth),
      height: convertPixelToTwip(options.paragraphHeight || 340),
      anchor: {
        horizontal: FrameAnchorType.TEXT,
        vertical: FrameAnchorType.TEXT,
      },
      alignment: {
        x: HorizontalPositionAlign.CENTER,
        y: VerticalPositionAlign.TOP,
      },
    },
    // shading: shadingBox(options.mainPage.imgBg),
    children: [
      new ImageRun({
        data: blobImg,
        transformation: {
          width: convertPixel(imgSizeData.imgWidth),
          height: convertPixel(imgSizeData.imgHeight),
        },
      }),
    ],
  });
};

const circleMainNoImg = async (options) => {
  const mainImg = await buildBlogNoImgCircle(340, Circle340);

  return mainPageImgTableWrapper(mainImg, options, 'Introduction');
};

const roundMainNoImg = async (options, portfolio) => {
  const mainImg = await buildBlankRound(
    options.imgWidth,
    options.imgWidth,
    options.noImgBg,
    44,
  );

  return mainPageImgTableWrapper(
    mainImg,
    options,
    'Introduction',
    true,
    7.6,
    portfolio,
  );
};

const defaultMainNoImg = async (options) => {
  const mainImg = await buildBlankSquare(tableWidth, 340, options.noImgBg);

  return mainPageImgTableWrapper(mainImg, options, 'Introduction', true, 0);
};

export const buildMainImg = async (originalImage, options, portfolio) => {
  let blobImg;
  await getBlobFromUrl(originalImage).then((res) => {
    blobImg = res;
  });

  let result;
  switch (options.mainPage?.mainImgType) {
    case 'circle':
      result = blobImg
        ? await circleMainImg(originalImage, options)
        : await circleMainNoImg(options);
      break;
    case 'round':
      result = blobImg
        ? await roundMainImg(originalImage, options, portfolio)
        : await roundMainNoImg(options, portfolio);
      break;
    default:
      result = blobImg
        ? await defaultMainImg(originalImage, options.mainPage, blobImg)
        : await defaultMainNoImg(options);
  }

  return result;
};

export const buildBodyNoImg = async (size, sectionOptions, index = false) => {
  let result;

  switch (sectionOptions?.noImgType) {
    case 'circle':
      result = await buildBgCircle(size, size, sectionOptions?.noImgBg);
      break;
    case 'color_circle':
      result = await buildBgCircleBordered(sectionOptions.colorList[index]);
      break;
    case 'round':
      result = await buildBlankRound(size, size, sectionOptions?.noImgBg, 16);
      break;
    default:
      result = await buildBlankSquare(size, size, sectionOptions?.noImgBg);
  }

  return result;
};

export const buildColourfulDecorateImg = async (sourceImg, color) => {
  const img = await loadImage(sourceImg);
  const changedImg = await changeImageColor(img, img.width, img.height, color);

  return Uint8Array.from(atob(changedImg.src?.split(',')[1]), (c) =>
    c.charCodeAt(0),
  );
};

export const buildSdgListIcon = async (sdgNumber, color) => {
  const currentIcon = sdgListIcon(sdgNumber);
  const img = await loadImage(currentIcon);
  const iconSize = sizeMultiplier(img.currentSrc, 10);
  const changedImg = await changeImageColor(
    img,
    img.width * iconSize,
    img.height * iconSize,
    color,
  );

  return Uint8Array.from(atob(changedImg.src?.split(',')[1]), (c) =>
    c.charCodeAt(0),
  );
};

export const buildSdgTableIcon = async (sdgNumber, iconColor, circleColor) => {
  const currentIcon = sdgListIcon(sdgNumber);
  const icon = await loadImage(currentIcon);
  const iconSize = sizeMultiplier(icon.currentSrc, 20);
  const changedWidth = icon.width * iconSize;
  const changedIcon = await changeImageColor(
    icon,
    changedWidth,
    changedWidth,
    iconColor,
  );

  const blankImage = await createBlankImage(
    changedWidth,
    icon.height * iconSize,
    `#${circleColor}`,
  );
  const circledImage = await roundImage(blankImage, changedWidth);

  const mergedImage = await mergeImages(
    [circledImage, changedIcon],
    changedWidth,
  );

  return Uint8Array.from(atob(mergedImage.src?.split(',')[1]), (c) =>
    c.charCodeAt(0),
  );
};

export const buildKeyActivityTitleIcon = async (icon, backgroundColor) => {
  const currentIcon = await loadImage(icon);
  const iconSize = sizeMultiplier(currentIcon.currentSrc, 20);
  const changedWidth = currentIcon.width * iconSize;
  const changedHeight = currentIcon.height * iconSize;
  const coloredCurrentIcon = await changeImageColor(
    currentIcon,
    changedWidth,
    changedHeight,
    'FFFFFF',
  );

  const blankImage = await createBlankImage(
    changedWidth,
    changedHeight,
    `#${backgroundColor}`,
  );
  const borderedImage = await borderRadiusImage(blankImage, changedHeight, 8);

  const mergedImage = await mergeImagesWithCentered(
    [borderedImage, coloredCurrentIcon],
    changedHeight,
  );

  return Uint8Array.from(atob(mergedImage.src?.split(',')[1]), (c) =>
    c.charCodeAt(0),
  );
};

export const buildTestingStatusIcon = async (icon, color) => {
  const currentIcon = await loadImage(icon);
  const blankImage = await createBlankImage(30, 30, `#${color}`);
  const borderedImage = await borderRadiusImage(blankImage, 30, 4);
  const mergedImage = await mergeImagesWithCentered(
    [borderedImage, currentIcon],
    30,
  );

  return Uint8Array.from(atob(mergedImage.src?.split(',')[1]), (c) =>
    c.charCodeAt(0),
  );
};

export const buildRelationshipIcon = async (icon, color) => {
  const currentIcon = await loadImage(icon);
  const iconSize = sizeMultiplier(currentIcon.currentSrc, 10);
  const coloredCurrentIcon = await changeImageColor(
    currentIcon,
    currentIcon.width * iconSize,
    currentIcon.height * iconSize,
    color,
  );

  return Uint8Array.from(atob(coloredCurrentIcon.src?.split(',')[1]), (c) =>
    c.charCodeAt(0),
  );
};

export const coloringQuoteImg = async (img, color) => {
  const currentImg = await loadImage(img);
  const coloredCurrentIcon = await changeImageColor(
    currentImg,
    currentImg.width,
    currentImg.height,
    color,
  );

  return Uint8Array.from(atob(coloredCurrentIcon.src?.split(',')[1]), (c) =>
    c.charCodeAt(0),
  );
};

export const colourfulTeamMateImg = async (src, index, templateColors) => {
  const img = await loadImage(src);
  const roundedImg = await roundImage(img, 76 * 10);
  const circle = await loadImage(TeamCircle);
  const iconSize = sizeMultiplier(circle.currentSrc, 10);
  const coloredCircle = await changeImageColor(
    circle,
    circle.width * iconSize,
    circle.height * iconSize,
    templateColors[index],
  );
  const result = await mergeImagesWithCentered(
    [coloredCircle, roundedImg],
    coloredCircle.width,
    coloredCircle.height,
  );

  return result;
};

export const buildTeamMateImg = async (
  blobImg,
  src,
  index,
  sectionSettings,
) => {
  let result = await loadImage(src);

  switch (sectionSettings.imgType) {
    case 'round':
      result = await borderRadiusImage(result, result.width, 16);
      break;
    case 'circle':
      result = await roundImage(result, result.width);
      break;
    case 'color_circle':
      result = await colourfulTeamMateImg(
        src,
        index,
        sectionSettings.colorList,
      );
      break;
    default:
      return blobImg;
  }

  return Uint8Array.from(atob(result.src.split('?')[0].split(',')[1]), (c) =>
    c.charCodeAt(0),
  );
};

export const buildAnalysisImg = async (blobImg, src, sectionSettings) => {
  let result = await loadImage(src);

  switch (sectionSettings.imgType) {
    case 'round':
      result = await borderRadiusImage(result, result.width, 46);
      break;
    case 'circle':
      result = await roundImage(result, result.width);
      break;
    default:
      return blobImg;
  }

  return Uint8Array.from(atob(result.src.split('?')[0].split(',')[1]), (c) =>
    c.charCodeAt(0),
  );
};

export const getContrastYIQ = (hexColor) => {
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 128 ? '000000' : 'FFFFFF';
};

export const isSelectedCustomPalette = (settings) =>
  settings.colorPalette === settings.customColors;
